import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-refresh-dialog',
  templateUrl: './refresh-dialog.component.html',
  styleUrls: ['./refresh-dialog.component.css']
})
export class RefreshDialogComponent implements OnInit {
  @Input() displayModal: any;
  public onContinue: EventEmitter<any> = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<RefreshDialogComponent>,
    private bnIdle: BnNgIdleService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.displayModal = false;
    // // setTimeout(() => {
    // //   this.displayModal = false;
    // // }, 60000);
  }

  setTimer() {
    this.bnIdle.resetTimer();
    this.onContinue.emit({ isContinue: true });
  }

  refreshPage() {
    window.location.reload();
    // //this.onContinue.emit({ isContinue: true });
  }

}
