
<div class="custom-modal-header">
    <button type="button" style="color: white !important;" (click)="onDismiss()" class="close-btn" aria-hidden="true">×</button>
    <h4 mat-dialog-title>
        <div style="text-align:left;">
        {{title}}
        </div>
    </h4>
</div>
<hr>
<div mat-dialog-content>
    <h5 style="color:#0B2D71;font-size: 14px;margin-bottom: 15px;">
        <div style="text-align:left;">
            Guidance:
        </div>
    </h5>
    <p [innerHTML]="message" style="margin-bottom: 20px!important;"></p>
</div>
<hr>
<div mat-dialog-actions style="float: right;">
    <button mat-button (click)="onDismiss()" class="btn btn-default"> Close </button> &nbsp;&nbsp;
</div>