import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppsettingsService } from 'src/app/Shared/appsettings.service';

@Injectable({
  providedIn: 'root'
})
export class LessonsLearnedService {
  getLessonLearnedUrl = 'api/LessonLearned/getLessonsLearnedData?formId=';
  saveLessonsLearnedUrl = 'api/LessonLearned/saveLessonsLearnedData';
  getLLCategoryUrl = 'api/LessonLearned/getLLCategoryData';
  getLLTypeUrl = 'api/LessonLearned/getLLTypeData';
  getMaxOrderUrl = 'api/LessonLearned/getMaxOrder?formId=';
  getLLPhasesUrl = 'api/LessonLearned/getLLPhases?projectId=';
  getLLPhasesByProjectIdUrl = 'api/LessonLearned/getLLPhasesByProjectId?projectId=';
  getLLSelectedPhaseUrl = 'api/LessonLearned/getLLSelectedPhase?projectId='
  getLLFormIdOnPhaseChangeUrl = 'api/LessonLearned/getLLFormIdOnPhaseChange?projectId=';
  archiveLLFormUrl = 'api/LessonLearned/CreateLLNamedVersion';
  getLLFormIdOnVersionChangeUrl = 'api/LessonLearned/getLLFormIdOnVersionChange?projectId=';
  getLatestFormVersionLLByTypeUrl= 'api/LessonLearned/GetLatestFormVersionLLByType?projectId=';
  GetAllSupplementsByProjectIdUrl= 'api/LessonLearned/GetAllSupplementsByProjectId?projectId=';
  GetSupplementFormIdOnFormChangeUrl = 'api/LessonLearned/GetSupplementFormIdOnFormChange?projectId=';
  getLLPhasePDFUrl = 'api/LessonLearned/GetLLPhasePDF?projectId=';
  constructor(private http: HttpClient,
    private appsettings: AppsettingsService) { }

  getLessonLearnedData(formId: number,projectId: number,typeId: number) {
      return this.http.get<any>(this.appsettings.apiBaseUrl + this.getLessonLearnedUrl + formId + "&projectId=" + projectId+ "&typeId=" + typeId);
  }
  saveLessonsLearnedData(llData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.saveLessonsLearnedUrl, llData);
  }
  getLLCategoryData() {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getLLCategoryUrl);
}
  getLLTypeData() {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getLLTypeUrl);
  }
  getMaxOrder(formId: number,projectId: number,typeId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getMaxOrderUrl + formId + "&projectId=" + projectId+ "&typeId=" + typeId);
  }
  getLLPhases(projectId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getLLPhasesUrl + projectId);
  }
  getLLPhasesByProjectId(projectId: number, formId: number, phaseId: number){
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getLLPhasesByProjectIdUrl + projectId + "&formId="+formId + "&phaseId="+phaseId);
  }
  getLLSelectedPhase(projectId: number,formId: number){
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getLLSelectedPhaseUrl + projectId+ "&formId=" + formId);
  }
  getLLFormIdOnPhaseChange(projectId: number,version: number,order: number){
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getLLFormIdOnPhaseChangeUrl + projectId+ "&version="+version+ "&order=" + order);
  }
  archiveLLForm(formId: number,oldVersionId:number, versionName: string, userName: string){
    const params = new HttpParams()
    .append('formId', formId)
    .append('oldVersionId',oldVersionId)
    .append('versionName',versionName)
    .append('userName',userName)
  return this.http.post<any>(this.appsettings.apiBaseUrl + this.archiveLLFormUrl, '', {
    params: params
  });
  }
  getLLFormIdOnVersionChange(projectId: number,formId: number,version: number , order: number){
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getLLFormIdOnVersionChangeUrl + projectId+ "&formId="+formId+"&version="+version+"&order=" + order);
  }
  GetLatestFormVersionLLByType(projectId: number,type : string){
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getLatestFormVersionLLByTypeUrl + projectId+ "&type="+type);
  }
  GetAllSupplementsByProjectId(projectId: number,type : string){
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetAllSupplementsByProjectIdUrl + projectId + "&type="+type);
  }
  GetSupplementFormIdOnFormChange(projectId: number,type : string,order: number){
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetSupplementFormIdOnFormChangeUrl + projectId+ "&type="+type+ "&order=" +order);
  }
  getLLPhasePDF(projectId: number,formTableFormId:number){
    const url = this.appsettings.apiBaseUrl + this.getLLPhasePDFUrl + projectId+"&formTableFormId="+formTableFormId;
    return this.http.get<any>(url, {responseType: 'blob' as 'json'});      
  }

}
