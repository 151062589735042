<div class="box-container" id="page-body">
    <h3>New User</h3>
   

    <div class="row">
        <div class="col-md-12">
            <hr>
            <div class="validation-summary-errors text-danger" data-valmsg-summary="true" *ngIf="userExist">
                <ul>
                    <li> {{ errorMessage }}</li>
                </ul>
            </div>
            <form [formGroup]='formValue'>
                <div class="form-horizontal">
                    <div class="form-group">
                        <label class="control-label col-xs-2" for="User_Name">First Name</label>
                        <div class="col-xs-3">
                            <input class="form-control col-xs-2" formControlName="firstname" id="User_firstName"
                                type="text" value="">
                        </div>
                        <label class="control-label col-xs-2" for="User_Name">Last Name</label>
                        <div class="col-xs-3">
                            <input class="form-control col-xs-2" formControlName="lastname" id="User_lastName"
                                type="text" value="">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-2" for="User_Name">Email</label>
                        <div class="col-xs-3">
                            <input class="form-control col-xs-2" formControlName="email" id="User_email" type="text"
                                value="">
                        </div>
                        <label class="control-label col-xs-2" for="User_Name">Role</label>
                        <div class="col-xs-3">
                            <select formControlName="roleId" class="form-control"
                                (change)="onRoleChange($any($event.target).value)">
                                <option *ngFor="let role of RoleList" [value]="role.roleId">
                                    {{role.roleDescription}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group text-center" *ngIf="poweruserSection">
                        <span>Power User will be granted access across all Segment's, RU's and BU's in the
                            organization</span>
                    </div>
                    <div id="commonSelectionAdministrator" *ngIf="commonSelectionAdministrator" class="form-group">
                        <label class="control-label col-xs-2">Segment</label>
                        <div class="col-xs-3">
                            <div class="dropdown dropdown-tree" id="ddlOpcoAdministrator"></div>
                        </div>
                        <label class="control-label col-xs-2">RU & BU</label>
                        <div class="col-xs-3">
                            <div class="dropdown dropdown-tree" id="ddlSbuBuAdministrator"></div>
                            <div class="dropdown dropdown-tree" id="ddlSbuBuHiddenAdministrator" style="display:none"></div>
                        </div>
                    </div>
                    <div id="commonSelectionLeadership" *ngIf="commonSelectionLeadership" class="form-group">
                        <label class="control-label col-xs-2">Segment</label>
                        <div class="col-xs-3">
                            <div class="dropdown dropdown-tree" id="ddlOpcoLeadership"></div>
                        </div>
                        <label class="control-label col-xs-2">RU & BU</label>
                        <div class="col-xs-3">
                            <div class="dropdown dropdown-tree" id="ddlSbuBuLeadership"></div>
                            <div class="dropdown dropdown-tree" id="ddlSbuBuHiddenLeadership" style="display:none"></div>
                        </div>
                    </div>
                    <div id="commonSelectionConsultant" *ngIf="commonSelectionConsultant" class="form-group">
                        <label class="control-label col-xs-2">Segment</label>
                        <div class="col-xs-3">
                            <div class="dropdown dropdown-tree" id="ddlOpcoConsultant"></div>
                        </div>
                        <label class="control-label col-xs-2">RU & BU</label>
                        <div class="col-xs-3">
                            <div class="dropdown dropdown-tree" id="ddlSbuBuConsultant"></div>
                            <div class="dropdown dropdown-tree" id="ddlSbuBuHiddenConsultant" style="display:none"></div>
                        </div>
                    </div>
                    <div id="basicmemberSection" *ngIf="basicmemberSection" class="form-horizontal">
                        <div class="form-group">
                            <label class="control-label col-xs-2" for="OpcoId">Segment</label>
                            <div class="col-xs-3">
                                <select formControlName="OpcoId" class="form-control"
                                    (change)="fillSbuDDL($any($event.target).value)">
                                    <option *ngFor="let opco of OpcoList" [value]="opco.id">
                                        {{opco.name}}</option>
                                </select>
                            </div>
                            <label class="control-label col-xs-2" for="sbuId">RU</label>
                            <div class="col-xs-3">
                                <select formControlName="sbuId" class="form-control"
                                    (change)="fillBuDdl($any($event.target).value)">
                                    <option *ngFor="let sbu of sbuList" [value]="sbu.id">
                                        {{sbu.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-xs-4" for="sbuId">BU</label>
                            <div class="col-xs-3">
                                <select formControlName="buId" class="form-control">
                                    <option *ngFor="let bu of buList" [value]="bu.id">
                                        {{bu.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" style="height:50px">
                        <label class="control-label col-xs-2">Active</label>
                        <div class="col-xs-3">
                            <input class="form-checkbox activeChecbox" formControlName="active" type="checkbox">
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-xs-6 padding-1 text-right">
                        <a class="btn btn-default" routerLink="/manage/useraccesses">Cancel</a>
                    </div>
                    <div class="col-xs-6 padding-0 text-left" >
                        <button type="submit" class="btn btn-primary" style="color:white !important;" [disabled]="!this.formValue.valid"
                            (click)="postDetails()">Create User</button>
                    </div>                 
                </div>
            </form>
        </div>
    </div>
</div>