
<div >
    <div>
        <h4 class="title">Warning</h4>
    </div>
    <hr>
    <div>
        <p class="pbody" style="padding-left: 20px;">{{message}}</p>
    </div>
    
    <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="onDismiss()">Close</button>
    </div>

</div>
