import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EAP4LookbackDocument, EAP4LookbackHeaderModel, EAP4LookbackPerformance, EAPart4ViewModel } from 'src/app/Model/EAPart4LookBack.model';
import { Form } from 'src/app/Model/Form.model';
import { Versions, ViewFormModel } from '../../go36project/Model/viewform.model';
import { Go36projectService } from '../../go36project/service/go36project.service';
import { EaPart4LookbackService } from '../service/ea-part4-lookback.service';
import { EaSupplementService } from '../service/ea-supplement.service';
import { EaprojectService } from '../service/eaproject.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-ea-viewformlist',
  templateUrl: './ea-viewformlist.component.html',
  styleUrls: ['./ea-viewformlist.component.css']
})

export class EaViewformlistComponent implements OnInit {

  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  formData: ViewFormModel[] = [];
  pID: any;
  projectName: string = "";
  formId: number = 0;
  actualformId: number = 0;
  versionList: Versions[] = [];
  form = new Form();
  objectId: string = '';
  provId: string = '';
  emailId: string = '';
  openRoutePath: string = '';
  selectedVersion: any = 0;
  part4Data: EAPart4ViewModel[] = [];
  Part4Header = new EAP4LookbackHeaderModel;
  Part4Performance: EAP4LookbackPerformance[] = [];
  Part4Document: EAP4LookbackDocument[] = [];
  userAccessDetails: any;
  userRole: string = '';
  userAccessListByProjectId: any;

  constructor(
    private projectservice: Go36projectService,
    private route: ActivatedRoute,
    private router: Router,
    private go36ProjectService: Go36projectService,
    private eapart4lookbackService: EaPart4LookbackService,
    private eaService: EaprojectService,
    private supplementService: EaSupplementService,
    private utilityServcie: UtilityService) { }

  ngAfterViewInit(): void {
    // // $('#tblOperatingCompany').DataTable();
  }

  ngOnInit(): void {
    this.getEAAllViewForms();
    this.objectId = this.utilityServcie.GetuserObjectId();
    this.provId = this.utilityServcie.GetuserProvisioningId();
    this.emailId = this.utilityServcie.GetuserEmailId();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2,
      processing: true
    };

    this.projectservice.GetUserAccessListByProjectId(this.pID, this.objectId, this.provId).subscribe(res => {
      if(res)
      {
        this.userAccessListByProjectId = res;
      }
    }) 
  }

  clear(table: any) {
    table.clear();
  }

  getEAAllViewForms() {
    this.route.queryParams
      .subscribe(params => {
        this.pID = params['projectId'];
        this.projectservice.getEAViewForm(this.pID).subscribe((res) => {
          this.formData = res;
          if (this.formData.length > 0) {
            this.formData.forEach(obj => {
              obj.selectedVersion = obj.Version;
            });
          }
        });
        this.projectservice.getProjectNameByProjectId(this.pID).subscribe((res) => {
          this.projectName = res;
        });
      })
  }

  onVersionChange(event: any) {
    this.selectedVersion = Number(event.target.value);
  }

  CreatePart4LookBack() {
    this.route.queryParams
      .subscribe(params => {
        this.pID = params['projectId'];
        this.eapart4lookbackService.CreatePart4LookBack(this.pID, this.objectId, this.provId).subscribe((res) => {
          if (res.go36EaP4LookbackHeaders != null) {
            this.part4Data = res;
            this.Part4Header = res.go36EaP4LookbackHeaders;
            this.Part4Performance = res.go36EaP4LookbackPerformance;
            this.router.navigate(["/ea/forms/eapart4"], { queryParams: { projectId: this.pID, formId: this.Part4Header.originalId, versionName: this.Part4Header.version, formCase: 'Create' } });
          }
        }
        );
      });
  }

  CreateEaNewAR() {
    this.route.queryParams
      .subscribe(params => {
        this.pID = params['projectId'];
        this.eaService.CreateEaNewARPart1(this.pID, this.objectId, this.provId).subscribe((res) => {
          if (res)
            this.router.navigate(["/ea/new-ar-nav"], { queryParams: { projectId: this.pID, formId: res.part1HeaderData.originalId, versionName: res.part1HeaderData.version, formCase: 'Create' } });
        }
        );
      });
  }
  CreateEaNewRescope() {
    this.route.queryParams
      .subscribe(params => {
        this.pID = params['projectId'];
        this.supplementService.CreateEaNewRescope(this.pID, 0, 0, this.provId).subscribe((res) => {
          this.router.navigate(["/ea/supplement-nav"], { queryParams: { projectId: this.pID, supplementId: res.part1.originalId, versionName: res.part1.version, formCase: 'Create' } });
        }
        );
      });
  }
  CreateEaNewSupplement() {
    this.route.queryParams
      .subscribe(params => {
        this.pID = params['projectId'];
        this.supplementService.CreateEaNewSupplement(this.pID, this.provId, true).subscribe((res) => {
          this.router.navigate(["/ea/supplement-nav"], { queryParams: { projectId: this.pID, supplementId: res.part1.originalId, versionName: res.part1.version, formCase: 'Create' } });
        }
        );
      });
  }

  DeleteProject(action: string) {
    this.route.queryParams
      .subscribe(params => {
        // //let projectId = params['projectId'];
        const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
        this.userAccessDetails = JSON.parse(userData);

        if (this.userAccessDetails?.data?.isPowerUser) {
          this.userRole = "Power User";
        }
        else if (this.userAccessDetails?.data?.isAdminUser) {
          this.userRole = "Administrator";
        }
        else if (this.userAccessDetails?.data?.isBasicMember) {
          this.userRole = "Basic Member";
        }
        else if (this.userAccessDetails?.data?.isConsultantUser) {
          this.userRole = "Consultant";
        }
        else if (this.userAccessDetails?.data?.isLeadershipUser) {
          this.userRole = "Leadership";
        }

     if(this.userAccessListByProjectId?.data?.canRemove)
     {
       this.router.navigate(['/forms/deleteproject'], { queryParams: { projectId: this.pID } });
     }
     else
     {
       localStorage.setItem('errorpage', 'true');
       this.router.navigate(["errorpage"]);
     }
  });
}

}
