export class Part4ViewModel {
    Part4Header = new Go36ArP4LookbackHeaderModel();
    Part4Performance = new Go36ArP4LookbackPerformance();
    LLTypeData: LessonLearnedDetail[] = [];
    Part4Document: Go36P4LookbackDocument[] = [];
    signOff: LookbackSignOff[] = [];
}

export class Go36ArP4LookbackHeaderModel {
    arP4LookbackId: number = 0;
    ArId: number = 0;
    supplementId?: number;
    lookbackDate?: Date;
    cvxShare?: number;
    lookbackType1: boolean = false;
    lookbackType2: boolean = false;
    lookbackType3: boolean = false;
    lookbackType4: boolean = false;
    singleProject: boolean = false;
    multiProject: boolean = false;
    projectCompletionSummary: string = '';
    keyInsights: string = '';
    defintionAsset: string = ''
    definitionFacilities: string = '';
    definitionWells: string = '';
    definitionReservoir: string = '';
    attachIpa: boolean = false;
    attachL1RootCause: boolean = false;
    attachL1MidPhase: boolean = false;
    attachL1ReviewTeam: boolean = false;
    attachL1ExplorationProspect: boolean = false;
    attachL1SubsurfaceBasisDesign: boolean = false;
    operationalResults: string = '';
    expenditureResults: string = '';
    scheduleResults: string = '';
    commercialAgreementResults: string = '';
    earlyOperationalResults: string = '';
    midPhase4Results: string = '';
    createdBy: string = '';
    createdDateTime: Date = new Date;
    modifiedBy: string = '';
    modifiedDateTime: Date = new Date;
    hideFromList: boolean = false;
    reportingOfficer: string = '';
    delegationOfAuthority: string = '';
    p1De: string = '';
    p1ProjectLead: string = '';
    p1Drb: string = '';
    p2De: string = '';
    p2ProjectLead: string = '';
    p2Drb: string = '';
    p3De: string = '';
    p3ProjectLead: string = '';
    p3Drb: string = '';
    p4De: string = '';
    p4ProjectLead: string = '';
    p4Drb: string = '';
    teamMember1Name: string = '';
    teamMember1Title: string = '';
    teamMember1Affiliation: string = '';
    teamMember2Name: string = '';
    teamMember2Title: string = '';
    teamMember2Affiliation: string = '';
    teamMember3Name: string = '';
    teamMember3Title: string = '';
    teamMember3Affiliation: string = '';
    teamMember4Name: string = '';
    teamMember4tTitle: string = '';
    teamMember4Affiliation: string = '';
    teamMember5Name: string = '';
    teamMember5Title: string = '';
    teamMember5Affiliation: string = '';
    teamMember6Name: string = '';
    teamMember6Title: string = '';
    teamMember6Affiliation: string = '';
    teamMember7Name: string = '';
    teamMember7Title: string = '';
    teamMember7Affiliation: string = '';
    teamMember8Name: string = '';
    teamMember8Title: string = '';
    teamMember8Affiliation: string = '';
    teamMember9Name: string = '';
    teamMember9Title: string = '';
    teamMember9Affiliation: string = '';
    teamMember10Name: string = '';
    teamMember10Title: string = '';
    teamMember10Affiliation: string = '';
    projectId: number = 0;
    projectLocation: string = '';
    ardDate?: Date = new Date;
    nojv: boolean = false;
    operatorName: string = '';
    statusId: number = 0;
    program: boolean = false;
    businessResults: string = '';
    economicResults: string = '';
    originalId: number = 0;
    version: number = 0;
    versionName: string = '';
    approvedDateTime?: Date = new Date;
    isonHold: boolean = false;
    isReadOnly: boolean = false;
    subsurfaceBasisofDesignId?: number;
    active: boolean = true;

}

export class Go36ArP4LookbackPerformance {
    p4LookbackPerformanceId: number = 0;
    p4LookbackId: number = 0;
    dataDate: Date = new Date;
    trirActualOutcomes: number = 0;
    dafwrActualOutcomes: number = 0;
    executionScheduleEv?: string = '';
    executionScheduleP10?: string = '';
    executionScheduleP90?: string = '';
    executionScheduleActualOutcomes?: string = '';
    executionScheduleBenchTopQuartile?: string = '';
    executionScheduleBenchIndustryAverage?: string = '';
    startupEv?: string = '';
    startupP10?: string = '';
    startupP90?: string = '';
    startupActualOutcomes?: string = '';
    ceProbabilisticEv?: any;
    ceProbabilisticP10?: any;
    ceProbabilisticP90?: any;
    fullCeCostTargetPhase3: number = 0;
    fullCeCostTargetTotal: number = 0;
    fullCeCostTargetActualOutcomes: number = 0;
    fullCeCostTargetNormalized: number = 0;
    benchCeEv: number = 0;
    benchCeActualOutcomes: number = 0;
    benchCeNormalized: number = 0;
    benchCeBenchTopQuartile: number = 0;
    benchCeBenchIndustryAverage: number = 0;
    expenseExecuteProjectEv: number = 0;
    expenseExecuteProjectP10: number = 0;
    expenseExecuteProjectP90: number = 0;
    expenseExecuteProjectActualOutcomes: number = 0;
    estUltimateRecoveryMmboEv: number = 0;
    estUltimateRecoveryMmboP10: number = 0;
    estUltimateRecoveryMmboP90: number = 0;
    estUltimateRecoveryMmboActualOutcomes: number = 0;
    estUltimateRecoveryBcfEv: number = 0;
    estUltimateRecoveryBcfP10: number = 0;
    estUltimateRecoveryBcfP90: number = 0;
    estUltimateRecoveryBcfActualOutcomes: number = 0;
    estUltimateRecoveryMmboeEv: number = 0;
    estUltimateRecoveryMmboeP10: number = 0;
    estUltimateRecoveryMmboeP90: number = 0;
    estUltimateRecoveryMmboeActualOutcomes: number = 0;
    estUltimateRecovery88MmboeEv: number = 0;
    estUltimateRecovery88MmboeP10: number = 0;
    estUltimateRecovery88MmboeP90: number = 0;
    estUltimateRecovery88MmboeActualOutcomes: number = 0;
    PeakProductionMbpdEv?:number=0;
    peakProductionMbpdP10: number = 0;
    peakProductionMbpdP90: number = 0;
    peakProductionMbpdActualOutcomes: number = 0;
    peakProductionMmcfdEv: number = 0;
    peakProductionMmcfdP10: number = 0;
    peakProductionMmcfdP90: number = 0;
    peakProductionMmcfdActualOutcomes: number = 0;
    month12MmboEv: number = 0;
    month12MmboActualOutcomes: number = 0;
    month1288MmboEv: number = 0;
    month1288MmboActualOutcomes: number = 0;
    month12BcfEv: number = 0;
    month12BcfActualOutcomes: number = 0;
    month1288BcfEv: number = 0;
    month1288BcfActualOutcomes: number = 0;
    key_Performance_Drivers_EV:  any;
    key_Performance_Drivers_P10:  any;
    key_Performance_Drivers_P90:  any;
    key_Performance_Drivers_ACTUAL_OUTCOMES:  any; 
    footnotes: string = '';
    createdBy?: string = '';
    createdDatetime: Date = new Date;
    modifiedBy: string = '';
    modifiedDatetime: Date = new Date;
    hidefromlist: boolean = false;
    
    keyPerformanceDriversUnit1:  any;
    keyPerformanceDriversUnit2:  any;
    keyPerformanceDrivers1:  any;
    keyPerformanceDrivers2:  any;
    keyPerformanceDriversP101:  any;
    keyPerformanceDriversEV1:   any;
    keyPerformanceDriversP901:  any;
    keyPerformanceDriversActualOutcomes1:   any;

}

export class LessonLearnedDetail {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn: Date = new Date();
    modifiedOn: Date = new Date();
    order: number = 0;
    lessonLearnedTypeId: number = 0;
    formId: number = 0;
    description?: string = '';
    title?: string = '';
    projectId: number = 0;
    lessonLearnedCategoryId?: number = 0;
    formType?: string = '';
    nakeyDecision: boolean = false;
    naLessonLearned: boolean = true;
}

export class Go36P4LookbackDocument {
    documentId: number = 0;
    p4LookbackId: number = 0;
    docName: string = '';
    docContentType: string = '';
    docContent?: Blob;
    createdBy: string = '';
    createdByName: string = '';
    createdDatetime: Date = new Date();
    modifiedBy: string = '';
    modifiedDatetime: Date = new Date();
    hidefromlist: boolean = false;
}

export class LookbackSignOff {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn?: Date;
    modifiedOn?: Date;
    order: number = 0;
    formId: number = 0;
    signoffName?: string = '';
    name?: string = '';
    position?: string = '';
    signature: string = '';
    signoffDate: Date=new Date();
    overviewId: number = 0;
    comment: string = '';
    formType: string = '';
}