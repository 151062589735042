import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { CalAngularService } from '@cvx/cal-angular';
import { MatDialog } from '@angular/material/dialog';
import { RefreshDialogComponent } from '../Modals/refresh-dialog/refresh-dialog.component';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  scopes: string[] = [];
  constructor(private authService: CalAngularService, public dialog: MatDialog) {

    this.scopes = environment.scopes;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let tokenFound = false;
    let token = "";
    let expiryDate = 0;
    let requireTokenRefresh = false;

    for (let keyt in localStorage) {
      try {
        let AccessObj = JSON.parse(localStorage[keyt])
        if (environment.scopes.includes(AccessObj["target"])) {
          tokenFound = true;
          token = AccessObj["secret"];
          expiryDate = AccessObj["expiresOn"] * 1000;

          if(((expiryDate - (Date.now()/1)) / 60000) < 10){
            requireTokenRefresh = true;
          }
        }
      } catch (e) {
      }
    }

    if (tokenFound) {

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });

      
    if(requireTokenRefresh){
      this.authService.getAADToken(this.scopes)
      .subscribe(tokenProperty => {
      });
    }

      return next.handle(request).pipe(catchError(err => {
        if (err instanceof HttpErrorResponse && err.status == 401) {
          this.authService.getAADToken(this.scopes)
            .subscribe(tokenProperty => {
            });

            const dialogRef = this.dialog.open(RefreshDialogComponent);
            dialogRef.componentInstance.displayModal = true;
    
            dialogRef.componentInstance.onContinue.subscribe((data) => {
              if (data.isContinue) {
                this.dialog.closeAll();
              }
            });
        }


        return throwError(() => err);
      }))

    } else {
      return next.handle(request);
    }
  }
}
