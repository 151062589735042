export class ExecutionTargetAssuranceModel
{
    executionTargetAssurceId: number = 0;
    arId: number = 0;
    phase1: ExecutionTargetAssuranceFeature1 = new ExecutionTargetAssuranceFeature1();
    phase2: ExecutionTargetAssuranceFeature1 = new ExecutionTargetAssuranceFeature1();
    phase3: ExecutionTargetAssuranceFeature1 = new ExecutionTargetAssuranceFeature1();
    phase4: ExecutionTargetAssuranceFeature1 = new ExecutionTargetAssuranceFeature1();
    projectExecutionPart4Date: string = "";
    projectExecutionPart5Date: string = "";
    projectExecutionPlan: string = "";
    permittingPlan: string = "";
    scheduleEstimate: string = "";
    ceCostEstimate: string = "";
    sharingKeyInsights: string = "";
    attachmentsCostEstimates: boolean = false;
    attachmentsMilestoneProject: boolean = false;
    performanceTarget: string = "";
    modifiedBy: string = "";
}
export class ExecutionTargetAssuranceFeature1
{
    reviewType: string = "";
    reviewDate: string = "";
    nameSbu: string = "";
}