export class EAPart1Model {
    arId: number = 0;
    projectId: number = 0;
    projectLocation: string = '';
    arDate?: Date = new Date;
    exploration : boolean = false;
    appraisal : boolean = false;
    program : boolean = false;
    scopeChange : boolean = false;
    supplement : boolean = false;
    originalDate?: Date = new Date;
    cpdepPhase: number = 0;
    nojv: boolean = false;
    operatorName: string = '';
    cvxShare: any = 0;
    appropriatedAmount: number = 0;
    totalCommitment: number = 0;
    section2aInformation: string = '';
    section3Information: string = '';
    statusId: number = 0;
    projectManager: string = '';
    drbMembers: string = '';
    decisionExecutive: string = '';
    functionalEndorsement: string = '';
    functionalEndorsementPosition: string = '';
    individualDoa: string = '';
    reportingOfficer: string = '';
    corporateSecretary: string = '';
    createdBy: string = '';
    createdByName: string = '';
    createdDatetime?: Date = new Date;
    modifiedBy: string = '';
    modifiedBy_FullName: string = '';
    modifiedDatetime?: Date = new Date;
    hidefromlist: boolean = false;
    originalId: number = 0;
    version: number = 0;
    individualDoaDate?: Date = new Date;
    reportingOfficerDate?: Date = new Date;
    functionalEndorsementsDate?: Date = new Date;
    versionName: string = '';
    approvedDatetime?: Date = new Date;
    isOnHold: boolean = false;
    isReadOnly: boolean = false;
}