import { Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appAddNumberPrecision]'
})
export class AddNumberPrecisionDirective {
  @Input("rowValue") rowValue: number = 0;
  constructor(private el: ElementRef) {}
  checkPrecision(res:any, rowValue:any):any{
    if(res!=null){
      res.forEach(item => {
        if(rowValue != 4){
          item.P10 = (item.P10 != null && item.P10!= "")?Number(item.P10).toFixed(2).toString() : null;
          item.P50 = (item.P50 != null && item.P50!= "")?Number(item.P50).toFixed(2).toString() : null;
          item.P90 = (item.P90 != null && item.P90!= "")?Number(item.P90).toFixed(2).toString() : null;
          item.ExpectedValue = (item.ExpectedValue != null && item.ExpectedValue!= "")?Number(item.ExpectedValue).toFixed(2).toString() : null;
        }
        else{
          item.P10 = (item.P10 != null && item.P10!= "")?Number(item.P10).toFixed(0) : null;
          item.P50 = (item.P50 != null && item.P50!= "")?Number(item.P50).toFixed(0) : null;
          item.P90 = (item.P90 != null && item.P90!= "")?Number(item.P90).toFixed(0) : null;
          item.ExpectedValue = (item.ExpectedValue != null && item.ExpectedValue!= "")?Number(item.ExpectedValue).toFixed(0) : null;
        }
      });
    }
    return res;
  }
  // @HostListener('blur', ["$event"])
  // onBlur() {
  //   let inputVal: number = +this.el.nativeElement.value;
  //   if(this.el.nativeElement.value != "" && this.el.nativeElement.value != null){
  //     if(this.rowValue!=4)
  //       this.el.nativeElement.value = inputVal.toFixed(2).toString();
  //     else
  //       this.el.nativeElement.value = Number(inputVal.toFixed(0));
  //   }
  // }  
}
