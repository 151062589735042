export class FormRisks {
    id: number = 0;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn: Date=new Date();
    modifiedOn: Date=new Date();
    formId: number = 0;
    economicSensitivityEurTitle?: string = '';
    netPresentValueSensitivityLow?: string = '';
    netPresentValueSensitivityMid?: string = '';
    netPresentValueSensitivityHigh?: string = '';
    netPresentValueTornadoImage?: string = '';
    netPresentValueScurveImage?: string = '';
    discountedProfitabilityIndexScurveImage?: string = '';
    npvFullCycle: number = 0;
    flatRealPointForward: number = 0;
    flatRealFeedForward: number = 0;
    remainingCostIncrease: number = 0;
    commodityTestedBy?: string = '';
    feedForwardCommodityTestedBy?: string = '';
    npvFullCycleComment?: string = '';
    flatRealPointForwardComment ?: string = '';
    flatRealFeedForwardComment?: string = '';
    remainingCostIncreaseComment?: string = '';
    commodityTestedByComment?: string = '';
    feedForwardCommodityTestedByComment?: string = '';
    riskUncertainty?: string = '';
    noveltyRisk?: string = '';
    nanoveltyRisk: boolean = true;
    formDate: Date=new Date();
    projectId: number = 0;
    operatorName?: string = '';
    location?: string = '';
    originalId: number = 0;
    version: number = 0;
    versionName?: string = '';
    status: number = 0;
    assetMegaFriendlyNameId?: number = 0;
    assetMegaFriendlyNameCustom?: string = '';
    decision?: string = '';
    characteristics?: string = '';
    formType?: string = '';
    isReadOnly: boolean = false;
    subsurfaceBasisOfDesignId?: number = 0;
    isSsbodrequired?: boolean = false;
    ssbodimportDate: Date=new Date();
    isOnHold: boolean = false;
    approvedOn: Date=new Date();
    Name?:string = '';
    BUName?:string = '';
}