<div class="box-container">
    <div class="row">
        <div class="col">
            <h3>Part 4 Lookback</h3>
            <a class="no-underline" [routerLink]="['/go36project/viewform']"
                [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked}"
                [queryParams]="{projectId: projectId}">
                <h5 style="padding: 0; font-size: 14px;">
                    {{projectName}}</h5>
            </a>
            <h5 class="text-danger margin-0 padding-0"></h5>

            <h5 class="text-danger margin-0 padding-0" style="font-size: 14px !important;" *ngIf="Part4Header.isOnHold">
                Form is on Hold. No editing can be performed</h5>
        </div>
    </div>

    <div class="row" *ngIf="!Part4Header.isReadOnly"
        [ngClass]="{'disabledNoOfCasesDiv': !this.part4LookBackAccessList?.data?.canHold}">
        <span>
            <mat-slide-toggle (change)="onChange($event, Part4Header)" [checked]="Part4Header.isOnHold">
                <b class="app-font">On Hold</b>
            </mat-slide-toggle>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowOnHoldModal()"></span>
        </span>
    </div>

    <div class="row">
        <div class="tabbed tabbed-content-control tabbed-side-nav clearfix">
            <mat-sidenav-container>
                <mat-sidenav mode="side" class="sidenav" opened *ngIf="!Part4Header.isOldVersionOfForms">
                    <mat-nav-list class=" nav-tabs font-gotham">
                        <a mat-list-item (click)="showSection(1)" style="color:white">
                            <strong class="sub-menu-font"
                                style="font-size: 14px;" [ngClass]="{active:showSection1}">Section
                                1-2</strong>
                        </a>

                        <a mat-list-item (click)="showSection(3)" style="color:white">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection3}">Section 3</strong></a>

                        <a mat-list-item (click)="showSection(4)" style="color:white">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection4}">Section 4</strong></a>
                        <a mat-list-item (click)="showSection(5)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection5}">Section 5</strong></a>
                        <!-- <a mat-list-item (click)="showSection(6)" class="menu-item-color">
                            <strong style="font-size: 0.88em;" [ngClass]="{active:showSection6}">Section 6</strong></a> -->
                        <a mat-list-item (click)="showSection(8)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection8a}">Section
                                6a</strong></a>
                        <a mat-list-item (click)="showSection(9)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection8b}">Section
                                6b</strong></a>
                        <a mat-list-item (click)="showSection(10)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection8c}">Section
                                6c</strong></a>
                        <a mat-list-item (click)="showSection(11)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection8d}">Section
                                6d</strong></a>
                        <a mat-list-item (click)="showSection(12)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection8e}">Section
                                6e</strong></a>
                        <a mat-list-item (click)="showSection(13)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection9}">Section 7</strong></a>
                        <a mat-list-item (click)="showSection(14)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection10}">Section
                                8</strong></a>
                        <a mat-list-item (click)="showSection(15)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection11}">Section
                                9</strong></a>
                        <a mat-list-item (click)="showSection(7)" class="menu-item-color">
                            <strong class="sub-menu-font" style="font-size: 14px; text-align: left; padding-top: 15px;"
                                [ngClass]="{active:showSection7}">Project Documentation</strong></a>
                        <br />
                        <a mat-list-item (click)="showSection(16)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection12}">Form Reviewer
                            </strong></a>
                    </mat-nav-list>
                </mat-sidenav>
                <mat-sidenav mode="side" class="sidenav" opened *ngIf="Part4Header.isOldVersionOfForms">
                    <mat-nav-list class=" nav-tabs font-gotham">
                        <a mat-list-item (click)="showSection(1)" style="color:white">
                            <strong *ngIf="this.features?.hasLookbacksLLFeature" class="sub-menu-font"
                                style="font-size: 14px;" [ngClass]="{active:showSection1}">Section
                                1-2</strong>

                            <strong *ngIf="!this.features?.hasLookbacksLLFeature" class="sub-menu-font"
                                style="font-size: 14px;" [ngClass]="{active:showSection1}">Section
                                1-2-3</strong>
                        </a>
                        <a mat-list-item (click)="showSection(3)" style="color:white"
                            *ngIf="this.features?.hasLookbacksLLFeature">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection3}">Section 3</strong></a>

                        <a mat-list-item (click)="showSection(4)" style="color:white">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection4}">Section 4</strong></a>
                        <a mat-list-item (click)="showSection(5)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection5}">Section 5</strong></a>
                        <a mat-list-item (click)="showSection(6)" class="menu-item-color">
                            <strong style="font-size: 0.88em;" [ngClass]="{active:showSection6}">Section 6</strong></a>
                        <a mat-list-item (click)="showSection(7)" class="menu-item-color">
                            <strong class="sub-menu-font" style="font-size: 14px; text-align: left;"
                                [ngClass]="{active:showSection7}">Section 7</strong></a>
                        <a mat-list-item (click)="showSection(8)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection8a}">Section
                                8a</strong></a>
                        <a mat-list-item (click)="showSection(9)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection8b}">Section
                                8b</strong></a>
                        <a mat-list-item (click)="showSection(10)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection8c}">Section
                                8c</strong></a>
                        <a mat-list-item (click)="showSection(11)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection8d}">Section
                                8d</strong></a>
                        <a mat-list-item (click)="showSection(12)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection8e}">Section
                                8e</strong></a>
                        <a mat-list-item (click)="showSection(13)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection9}">Section 9</strong></a>
                        <a mat-list-item (click)="showSection(14)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection10}">Section
                                10</strong></a>
                        <a mat-list-item (click)="showSection(15)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection11}">Section
                                11</strong></a>
                        <a mat-list-item (click)="showSection(16)" class="menu-item-color"
                            *ngIf="this.features?.hasWorkFlowProcessFeature">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection12}">Form Reviewer
                            </strong></a>
                    </mat-nav-list>
                </mat-sidenav>
                <div class="row" style="margin-left:2rem">
                    <!-- --------------------Section 1-2-------------------------------->
                    <div *ngIf="showSection1" class="tab-content tabs nav nav-tabs font-gotham">
                        <div class="content-container">
                            <div class="row">
                                <div class="col-xs-12">
                                    <hr />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>1. Completion Lookback</strong>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1a. Segment: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.opcoName" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1b. RU: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.sbuName" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1c. BU: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.buName" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1d. Asset Class: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.assetName" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1e. Project Location:</label>
                                    <input type="text" class="form-control"
                                        (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                        [(ngModel)]="Part4Header.projectLocation"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1f. Date (Part 4):</label>
                                    <input type="button" name="txtDate" [(ngModel)]="Part4Header.lookbackDate" style="display: flex;        
                                    cursor: auto;
                                    border-radius: 0.5rem;"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                        (dateChange)=" UpdatePart4LookBackHeaderDates(Part4Header)" class="form-control"
                                        [matDatepicker]="picker5"
                                        (click)="picker5.open();utilityService.removeCDKAnouncer()" />
                                    <mat-datepicker #picker5></mat-datepicker>

                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1g. AR Date / Number:</label>
                                    <input type="button" name="txtDate" class="form-control" style="display: flex;        
                                    cursor: auto;
                                    border-radius: 0.5rem;" [(ngModel)]="Part4Header.arDate"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                        (dateChange)=" UpdatePart4LookBackHeaderDates(Part4Header)" class="form-control"
                                        [matDatepicker]="picker6"
                                        (click)="picker6.open();utilityService.removeCDKAnouncer()" />
                                    <mat-datepicker #picker6></mat-datepicker>
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1h.Special Project Type: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.SpecialProjectTypeName" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1i. Facility (megafriendly) Name: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.assetMegaFriendlyName" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    1h. Lookback Type
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xs-6">
                                    <div class="row">
                                        <div class="col-xs-1">
                                            <input type="checkbox" class="form-check-input" id="chkLevel1a"
                                                [checked]="Part4Header.lookbackType1"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'lookbackType1')" />
                                        </div>
                                        <div class="col-xs-11">
                                            <label class="form-check-label" for="chkLevel1a"> <strong>Level
                                                    1</strong>
                                                Total Chevron Commitment &ge;&nbsp;$500 MM</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-1">
                                            <input type="checkbox" class="form-check-input" id="chkLevel2a"
                                                [checked]="Part4Header.lookbackType3"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'lookbackType3')" />
                                        </div>
                                        <div class="col-xs-11">
                                            <label class="form-check-label" for="chkLevel2a"> <strong>Level
                                                    2</strong>
                                                Total Chevron Commitment &ge;&nbsp;$75 MM Level 1 not
                                                required</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-xs-6">
                                    <div class="row">
                                        <div class="col-xs-1">
                                            <input type="checkbox" class="form-check-input" id="chkLevel1b"
                                                [checked]="Part4Header.lookbackType2"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'lookbackType2')" />
                                        </div>
                                        <div class="col-xs-11">
                                            <label class="form-check-label" for="chkLevel1b"> <strong>Level
                                                    1</strong>
                                                Total Chevron Commitment &ge;&nbsp;$250 MM and substandard outcome
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-1">
                                            <input type="checkbox" class="form-check-input" id="chkLevel2b"
                                                [checked]="Part4Header.lookbackType4"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'lookbackType4')" />
                                        </div>
                                        <div class="col-xs-11">
                                            <label class="form-check-label" for="chkLevel2b"> <strong>Level
                                                    3</strong>
                                                Total Chevron Commitment is &lt;&nbsp;$75 MM.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-4">
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <input type="checkbox" class="form-check-input" id="chkSingleProject"
                                                [checked]="Part4Header.singleProject" style=" margin-right: 1rem;"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'singleProject')" />
                                            <label class="form-check-label" for="chkSingleProject"> Single Project
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <input type="checkbox" class="form-check-input" id="chkMultiProgram"
                                                [checked]="Part4Header.multiProject" style=" margin-right: 1rem;"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'multiProject')" />
                                            <label class="form-check-label" for="chkMultiProgram">Multiple ARs or
                                                Program</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-2">
                                    <input type="checkbox" class="form-check-input" id="chkNOJV"
                                        [checked]="Part4Header.nojv" style=" margin-right: 1rem;"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                        (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'nojv')" />
                                    <label class="form-check-label" for="chkNOJV">NOJV</label>
                                </div>
                                <div class="col-xs-3">
                                    Operator: <br />
                                    <input type="text" [(ngModel)]="Part4Header.operatorName"
                                        (change)=" UpdatePart4LookBackHeaderData(Part4Header)" class="form-control"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                </div>
                                <div class="col-xs-3">
                                    Chevron Share %:
                                    <input type="text" [(ngModel)]="Part4Header.cvxShare"
                                        (change)=" UpdatePart4LookBackHeaderData(Part4Header)" class="form-control"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12">
                                    <hr />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong> 2. Project Completion Summary. </strong> Summarize the project’s
                                    objectives, scope and high-level execution results versus expectations at the
                                    time
                                    of authorization. Highlight root causes for major deviations. Include a brief
                                    discussion of any significant scope changes since authorization.
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 padding-0">
                                    <div class="col-sm-12">
                                        <ckeditor #section8a [editor]="Editor" [config]="config" id="section1-2"
                                            name="section1-2" (ready)="onReady($event,'section1-2')"
                                            [(ngModel)]="Part4Header.projectCompletionSummary"
                                            [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}"
                                            [disabled]="this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                                        </ckeditor>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!this.features?.hasLookbacksLLFeature">
                                <div class="col-xs-12">
                                    <hr />
                                </div>
                            </div>
                            <div class="row" *ngIf="!this.features?.hasLookbacksLLFeature">
                                <div class="col-xs-12">
                                    <strong> 3. Summarize: the top (3-5) key insights/recommended practices </strong> identified during project execution and the plan to share them.
                                </div>
                            </div>

                            <div class="row" *ngIf="!this.features?.hasLookbacksLLFeature">
                                <div class="col-xs-12 padding-0">
                                    <div class="col-sm-12">
                                        <ckeditor #section8a [editor]="Editor" [config]="config" id="section1-2"
                                            name="section1-2" (ready)="onReady($event,'section1-2')"
                                            [(ngModel)]="Part4Header.keyInsights"
                                            [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}"
                                            [disabled]="this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                                        </ckeditor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- --------------------Section 3 lesson learned ------------------>
                    <div class="col-xs-12 form-group" *ngIf="showSection3">
                        <div class="content-container">
                            <div class="row">
                                <div class="col-xs-12">
                                    <hr />
                                </div>
                            </div>
                            <div class="row sub-section">
                                <div class="col-xs-12 form-group">
                                    <strong><span>3.1 </span>Lessons Learned:</strong>
                                    <i> Describe key insights identified during work on this project </i>
                                    <span class="glyphicon glyphicon-question-sign"
                                        (click)="ShowLessonLearnedModal()"></span>
                                </div>
                                <form [formGroup]="formLL">
                                    <ng-container formArrayName="lessonsLearned">
                                        <div *ngFor="let control of lessonsLearned.controls ;let i = index;">
                                            <div [formGroupName]="i">
                                                <div class="row form-group">
                                                    <div class="col-sm-6 col-xs-12"
                                                        [ngClass]="{'disabledNoOfCasesDiv':this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}">
                                                        <span class="col-sm-4 col-xs-12">
                                                            <span>{{i+1}}</span>
                                                            .Lesson Learned
                                                        </span>
                                                        <span class="col-sm-8 col-xs-12" style="text-align:left">
                                                            <select formControlName="lessonLearnedCategoryId"
                                                                class="form-control"
                                                                readonly="this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                                (change)="onCategoryChange($event,i+1,control)">
                                                                <option value="-1" selected>Please select category for
                                                                    lesson learned</option>
                                                                <option *ngFor="let category of LLCategoryData"
                                                                    [value]="category.id"
                                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                                                                    {{category.name}}</option>
                                                            </select>
                                                        </span>
                                                    </div>

                                                    <div class="col-sm-6 col-xs-12">
                                                        <span class="col-sm-8 col-xs-12" style="text-align:left">
                                                            <input formControlName="title"
                                                                placeholder="Enter title for lesson learned"
                                                                class="form-control"
                                                                (blur)="onBlurTitleUpdate($event,i+1,'LessonLearned')"
                                                                style="text-align:left;width:104%!important" />
                                                        </span>
                                                        <span class="col-sm-4 col-xs-12" style="text-align:right">
                                                            <span
                                                                style="font-family: 'Glyphicons Halflings'!important;cursor: pointer!important;"
                                                                class="	glyphicon glyphicon-trash"
                                                                [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}"
                                                                (click)="Delete(i+1,'LessonLearned')">
                                                                <span class="delete-btn-txt-font">Delete</span>
                                                            </span>

                                                        </span>
                                                    </div>

                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-sm-12"
                                                        [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly  || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}">
                                                        <div class="col-xs-12">
                                                            <ckeditor [editor]="Editor" [config]="config"
                                                                formControlName="description" #lessonLearned
                                                                id="lessonLearned" name="lessonLearned"
                                                                [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly  || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}"
                                                                [disabled]="LLTypeData[i].naLessonLearned"
                                                                (ready)="onReadyLLCK($event,i+1,'LessonLearned')">
                                                            </ckeditor>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-xs-12">
                                            <button type="button" class="btn btn-sm btn-primary"
                                                style="color: white !important;" (click)="addNewLL('LessonLearned')"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly  || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">Add
                                            </button>
                                        </div>
                                    </ng-container>
                                </form>
                            </div>
                            <div class="row sub-section">
                                <div class="col-xs-12 form-group">
                                    <strong><span>3.2 </span>Plan:</strong>
                                    <i> Include a sharing plan for the insights, the target Chevron organizations for
                                        receiving the information and approximate timing. Also, if available, include a
                                        summary of how any significant gap will be closed</i>
                                    <span class="glyphicon glyphicon-question-sign" (click)="ShowPlanModal()"></span>
                                </div>
                                <div>
                                    <div>
                                        <div class="row form-group">
                                            <div class="col-sm-12 col-xs-12">
                                                <span class="col-sm-12 col-xs-12" style="text-align:right">
                                                    <span
                                                        style="font-family: 'Glyphicons Halflings'!important;cursor: pointer!important;"
                                                        class="glyphicon glyphicon-trash glyphicon-custom-trash trash-button "
                                                        [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}"
                                                        (click)="Delete(1,'Plan')">
                                                        <span class="delete-btn-txt-font">Delete</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-sm-12">
                                                <div></div>
                                                <div class="col-xs-12">
                                                    <ckeditor #plan [editor]="Editor" [config]="config" id="plan"
                                                        name="plan" [(ngModel)]="PlanTypeData.description"
                                                        [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly  || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}"
                                                        [disabled]="this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                        (ready)="onReadyLLCK($event,1,'Plan')"></ckeditor>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row sub-section">
                                <div class="col-xs-12 form-group">
                                    <div class="col-xs-11" style="padding: 0">
                                        <strong><span>3.3 </span>Key Decisions:</strong>
                                        <i> Describe key decisions taken during work on this project phase</i>
                                        <span class="glyphicon glyphicon-question-sign"
                                            (click)="ShowKeyDecisionsModal()"></span>
                                        <div class="row pl-16">
                                            <!-- <input type="checkbox" [checked]="naKeyDecisionDisabled" [(ngModel)]="naKeyDecisionDisabled"
                                                (change)="NAKeyDecisionChange($event)" class="form-check-input"  [disabled]="isOnHold || readOnly || isReadOnlyGlobalProjectLevel">
                                                <label style="padding-left: 8px;"><b>N/A</b></label> -->
                                            <span *ngIf="!Part4Header.isOldVersionOfForms">
                                                <mat-slide-toggle (change)="NAKeyDecisionChange($event)"
                                                    [checked]="naKeyDecisionDisabled"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly  || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                                                    <span class="app-font">N/A</span>
                                                </mat-slide-toggle>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xs-1" style="text-align:right">
                    <input type="checkbox" [checked]="naKeyDecisionDisabled" [(ngModel)]="naKeyDecisionDisabled"
                    (change)="NAKeyDecisionChange($event)" class="form-check-input"  >
                    <label style="padding-left: 8px;"><b>N/A</b></label>
                </div>   -->
                                </div>
                                <form [formGroup]="formKD" *ngIf="!naKeyDecisionDisabled">
                                    <ng-container formArrayName="keyDecision">
                                        <div *ngFor="let control of keyDecision.controls ;let i = index;">
                                            <div [formGroupName]="i">
                                                <div class="row form-group">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <span class="col-sm-4 col-xs-12">
                                                            <span>{{i+1}}</span>
                                                            . Decision :
                                                        </span>
                                                        <span
                                                            [ngClass]="{'disabledNoOfCasesDiv': this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}"
                                                            class="col-sm-8 col-xs-12" style="text-align:left">
                                                            <input placeholder="Enter title for key decision"
                                                                formControlName="title"
                                                                style="text-align:left;width:104%!important"
                                                                [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}"
                                                                class="form-control"
                                                                (blur)="onBlurTitleUpdate($event,i+1,'KeyDecision')" />
                                                        </span>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="col-sm-8 col-xs-12"></div>
                                                        <span class="col-sm-4 col-xs-12" style="text-align:right">
                                                            <span
                                                                style="font-family: 'Glyphicons Halflings'!important;cursor: pointer!important;"
                                                                class="glyphicon glyphicon-trash glyphicon-custom-trash"
                                                                [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}"
                                                                (click)="Delete(i+1,'KeyDecision')">
                                                                <span class="delete-btn-txt-font">Delete</span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-sm-12">
                                                        <div class="col-xs-12">
                                                            <ckeditor [editor]="Editor" [config]="config"
                                                                formControlName="description" #keyDecision
                                                                id="keyDecision" name="keyDecision"
                                                                [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}"
                                                                [disabled]="this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                                (ready)="onReadyLLCK($event,i+1,'KeyDecision')">
                                                            </ckeditor>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-xs-12">
                                            <button type="button" style="color: white !important;"
                                                class="btn btn-sm btn-primary" style="color: white !important;"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                (click)="addNewLL('KeyDecision')">Add </button>
                                        </div>
                                    </ng-container>
                                </form>
                            </div>


                        </div>
                    </div>

                    <!-- <--------------------Section 4 Performance--------------------------------->
                    <div class="col-xs-12 form-group" *ngIf="showSection4">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <strong>4. Performance Measures (All Chevron Share)</strong>
                                <br /> &nbsp;
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">

                                <table class="table ">
                                    <tbody>
                                        <tr class="bg-table-default">
                                            <td colspan="2" class="text-center col-xs-4">
                                                All Upstream volumes are net revenue interest
                                            </td>
                                            <td colspan="3" class="text-center">
                                                <strong>AR Data</strong>
                                            </td>
                                            <td class="text-center col-xs-1" rowspan="2">
                                                <strong>Actual outcomes / <br />updated estimates</strong>
                                            </td>
                                            <td class="text-center col-xs-1" rowspan="2">
                                                <strong>Normalized</strong> <br />Actual outcome with original AR
                                                price/fiscal terms/FX guidelines
                                            </td>
                                            <td class="text-center col-xs-1" rowspan="2">
                                                <strong>Benchmark Top Quartile</strong>
                                            </td>
                                            <td class="text-center col-xs-1" rowspan="2">
                                                <strong>Benchmark Industry Average</strong>
                                            </td>
                                        </tr>
                                        <tr class="bg-table-default border-top">

                                            <td colspan="2">
                                                <div class="row">
                                                    <div class="col-xs-7">
                                                        <strong>Part 4 Lookback Data Date:</strong>
                                                    </div>
                                                    <div class="col-xs-5">
                                                        <input type="button" name="txtDate" class="form-control" style="display: flex;        
                                                        cursor: auto;
                                                        border-radius: 0.5rem;" [(ngModel)]="Part4Performance.dataDate"
                                                            (dateChange)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                            [matDatepicker]="picker4"
                                                            (click)="picker4.open();utilityService.removeCDKAnouncer()"
                                                            [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                        <mat-datepicker #picker4></mat-datepicker>

                                                    </div>
                                                </div>
                                            </td>

                                            <td class="text-center text-bold col-xs-1">EV</td>
                                            <td class="text-center text-bold col-xs-1">P10</td>
                                            <td class="text-center text-bold col-xs-1">P90</td>
                                        </tr>

                                        <tr class="border-top">
                                            <td class="bg-table-default" colspan="2">
                                                4a. <strong>TRIR </strong> (total workforce incid. /200k hours)
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.trirActualOutcomes"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr class="border-top">
                                            <td class="bg-table-default" colspan="2">
                                                4b. <strong>DAFWR </strong> (total workforce incid. /200k hours)
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.dafwrActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr class="border-top">
                                            <td class="bg-table-default" colspan="2">
                                                4c. <strong>Execution schedule </strong> months
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.executionScheduleEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.executionScheduleP10"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.executionScheduleP90"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.executionScheduleActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.executionScheduleBenchTopQuartile"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.executionScheduleBenchIndustryAverage"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                        </tr>
                                        <tr class="border-top">
                                            <td class="bg-table-default" colspan="2">
                                                4d. <strong>Startup/First Oil/Gas </strong> months/year
                                            </td>
                                            <td  *ngIf="Part4Header.statusId!=5 && !Part4Header.isReadOnly"><input type="button" name="txtDate" class="form-control hasDatepicker"
                                                    style="display: flex;        
                                                cursor: auto;
                                                border-radius: 0.5rem;" [matDatepicker]="picker"
                                                    (click)="picker.open();utilityService.removeCDKAnouncer()"
                                                    [(ngModel)]="Part4Performance.startupEv"
                                                    (dateChange)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />

                                                <mat-datepicker #picker></mat-datepicker>
                                            </td>

                                            <!--old form approved startupev-->
                                            <td *ngIf="Part4Header.statusId==5 || Part4Header.isReadOnly"><input type="button" name="txtDate" class="form-control hasDatepicker" 
                                                style="display: flex;        
                                            cursor: auto;
                                            border-radius: 0.5rem;" 
                                                [(ngModel)]="Part4Performance.startupEv"                                               
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />               
                                        </td>
                                        <td  *ngIf="Part4Header.statusId!=5 && !Part4Header.isReadOnly"><input type="button" name="txtDate" class="form-control" style="display: flex;        
                                            cursor: auto;
                                            border-radius: 0.5rem;" [(ngModel)]="Part4Performance.startupP10"
                                                [matDatepicker]="picker1"
                                                (click)="picker1.open();utilityService.removeCDKAnouncer()"
                                                (dateChange)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </td>

                                        <!--old form approved- startup -10-->
                                            <td *ngIf="Part4Header.statusId==5 || Part4Header.isReadOnly"><input type="button" name="txtDate" class="form-control" style="display: flex;        
                                                cursor: auto;
                                                border-radius: 0.5rem;" [(ngModel)]="Part4Performance.startupP10"
                                                    (dateChange)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td *ngIf="Part4Header.statusId!=5  && !Part4Header.isReadOnly"><input type="button" name="txtDate" class="form-control" style="display: flex;        
                                                cursor: auto;
                                                border-radius: 0.5rem;" [(ngModel)]="Part4Performance.startupP90"
                                                    (dateChange)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [matDatepicker]="picker2"
                                                    (click)="picker2.open();utilityService.removeCDKAnouncer()"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                <mat-datepicker #picker2></mat-datepicker>

                                            </td>
                                           <!--old form approved- startup -90-->
                                           <td *ngIf="Part4Header.statusId==5|| Part4Header.isReadOnly"><input type="button" name="txtDate" class="form-control" style="display: flex;        
                                            cursor: auto;
                                            border-radius: 0.5rem;" [(ngModel)]="Part4Performance.startupP90"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                        </td>
                                            <td *ngIf="Part4Header.statusId!=5 && !Part4Header.isReadOnly"><input type="button" name="txtDate" class="form-control" style="display: flex;        
                                                cursor: auto;
                                                border-radius: 0.5rem;"
                                                    [(ngModel)]="Part4Performance.startupActualOutcomes"
                                                    (dateChange)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [matDatepicker]="picker3"
                                                    (click)="picker3.open();utilityService.removeCDKAnouncer()"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                <mat-datepicker #picker3></mat-datepicker>

                                            </td>
                                         <!--old form approved- startup -startupActualOutcomes-->
                                         <td *ngIf="Part4Header.statusId==5|| Part4Header.isReadOnly"><input type="button" name="txtDate" class="form-control" style="display: flex;        
                                            cursor: auto;
                                            border-radius: 0.5rem;"
                                                [(ngModel)]="Part4Performance.startupActualOutcomes"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                        </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr class="border-top">
                                          <td class="bg-table-default" colspan="2">
                                            4e. <strong>{{ (this.features?.isNewCEisApplicableForms || Part4Header.isNewCEisApplicableForms) ? "Capex": "C&amp;E" }} probabilistic $MM</strong>
                                            <br />{{ (this.features?.isNewCEisApplicableForms || Part4Header.isNewCEisApplicableForms) ? "Capex": "C&amp;E" }} for all phase 3 ARs and FID AR
                                          </td>

                                            <td>
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarceProbabilisticEv">
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.ceProbabilisticEv"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceProbabilisticEv>
                                                    <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.ceProbabilisticEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarceProbabilisticP10">
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.ceProbabilisticP10"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceProbabilisticP10>
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.ceProbabilisticP10"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarceProbabilisticP90">
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.ceProbabilisticP90"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceProbabilisticP90>
                                                    <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.ceProbabilisticP90"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr class="border-top">
                                            <td class="bg-table-default" colspan="2">
                                                <div class="row">
                                                    4f. <strong> Full {{ (this.features?.isNewCEisApplicableForms || Part4Header.isNewCEisApplicableForms) ? "Capex": "C&E" }} cost target basis </strong> $MM
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                FID
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarfullCeCostTargetFid">
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.fullCeCostTargetFid"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarfullCeCostTargetFid>
                                                    <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.fullCeCostTargetFid"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td class="text-center">
                                                Phase 3
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarfullCeCostTargetPhase3">
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.fullCeCostTargetPhase3"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarfullCeCostTargetPhase3>
                                                    <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.fullCeCostTargetPhase3"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td class="text-center">
                                                TOTAL
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarfullCeCostTargetTotal">
                                                    <input type="number" class="form-control" readonly
                                                        [(ngModel)]="Part4Performance.fullCeCostTargetTotal"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarfullCeCostTargetTotal>
                                                    <input type="number" class="form-control" readonly
                                                        [(ngModel)]="Part4Performance.fullCeCostTargetTotal"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                &nbsp;
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarfullCeCostTargetActualOutcomes">
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.fullCeCostTargetActualOutcomes"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                 <ng-template #noDollarfullCeCostTargetActualOutcomes>
                                                    <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.fullCeCostTargetActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                &nbsp;
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarfullCeCostTargetNormalized">
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.fullCeCostTargetNormalized"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarfullCeCostTargetNormalized>
                                                    <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.fullCeCostTargetNormalized"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        <tr class="border-top">
                                            <td class="bg-table-default" colspan="2">
                                                4g. <strong>Benchmarked {{ (this.features?.isNewCEisApplicableForms || Part4Header.isNewCEisApplicableForms) ? "Capex": "C&amp;E" }} </strong> $MM
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarbenchCeEv">
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.benchCeEv"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarbenchCeEv>
                                                    <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.benchCeEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarbenchCeActualOutcomes">
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.benchCeActualOutcomes"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarbenchCeActualOutcomes>
                                                    <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.benchCeActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarbenchCeNormalized">
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.benchCeNormalized"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarbenchCeNormalized>
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.benchCeNormalized"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td> 
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarbenchCeBenchTopQuartile">
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.benchCeBenchTopQuartile"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarbenchCeBenchTopQuartile>
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.benchCeBenchTopQuartile"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarbenchCeBenchIndustryAverage"> 
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.benchCeBenchIndustryAverage"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarbenchCeBenchIndustryAverage>
                                                    <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.benchCeBenchIndustryAverage"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                        </tr>

                                        <tr class="border-top">
                                            <td class="bg-table-default" colspan="2">
                                                4h. <strong>Expense to execute project </strong> $MM
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarexpenseExecuteProjectEv"> 
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.expenseExecuteProjectEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarexpenseExecuteProjectEv>
                                                    <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.expenseExecuteProjectEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarexpenseExecuteProjectP10"> 
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.expenseExecuteProjectP10"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarexpenseExecuteProjectP10>
                                                    <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.expenseExecuteProjectP10"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarexpenseExecuteProjectP90"> 
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.expenseExecuteProjectP90"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarexpenseExecuteProjectP90>
                                                    <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.expenseExecuteProjectP90"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part4Header.isOldVersionOfForms; else noDollarexpenseExecuteProjectActualOutcomes"> 
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="Part4Performance.expenseExecuteProjectActualOutcomes"
                                                        (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarexpenseExecuteProjectActualOutcomes>
                                                    <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.expenseExecuteProjectActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        <tr class="border-top">
                                            <td class="bg-table-default" rowspan="4">
                                                4i. <strong>Estimated Ultimate Recovery</strong>
                                                <br />(if available)
                                            </td>
                                            <td class="bg-table-default text-bold">Net MMBO</td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />

                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboP10"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboP90"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        <tr>
                                            <td style="border: none" class="bg-table-default text-bold">Net BCF</td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryBcfEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryBcfP10"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryBcfP90"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryBcfActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                        <tr>
                                            <td style="border: none" class="bg-table-default text-bold">Net MMBOE</td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboeEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboeP10"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboeP90"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboeActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td style="border: none" class="bg-table-default text-bold">8/8ths MMBOE
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecovery88MmboeEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecovery88MmboeP10"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecovery88MmboeP90"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.estUltimateRecovery88MmboeActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                        <!-- ----------------------------4J------------ -->
                                        <tr class="border-top">
                                            <td class="bg-table-default" rowspan="2">
                                                4j. <strong>Peak Production Rate</strong>
                                                <br />annual avg. (if available)
                                            </td>
                                            <td class="bg-table-default text-bold">Net MBPD</td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.peakProductionMbpdEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.peakProductionMbpdP10"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.peakProductionMbpdP90"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.peakProductionMbpdActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td style="border: none" class="bg-table-default text-bold">Net MMCFD</td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.peakProductionMmcfdEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.peakProductionMmcfdP10"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.peakProductionMmcfdP90"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.peakProductionMmcfdActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                        <!-- <------------------4K---------------------------- -->
                                        <tr class="border-top">
                                            <td class="bg-table-default" rowspan="4">
                                                4k. <strong>12-month production attainment</strong>
                                            </td>
                                            <td class="bg-table-default text-bold">Net MMBO</td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.month12MmboEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.month12MmboActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style="border: none" class="bg-table-default text-bold">8/8 MMBO</td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.month1288MmboEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.month1288MmboActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                        <tr>
                                            <td style="border: none" class="bg-table-default text-bold">Net BCF</td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.month12BcfEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.month12BcfActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td style="border: none" class="bg-table-default text-bold">8/8 BCF</td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.month1288BcfEv"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.month1288BcfActualOutcomes"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                        <!-- <--------key Drivers only for NON -UPstream>>>>> -->
                                        <tr *ngIf="!Part4Header.isOldVersionOfForms">
                                            <td class="bg-table-default" style="border: none" colspan="1">
                                                4l. <strong>Key Performance Drivers</strong>
                                            </td>
                                            <td class="bg-table-default" style="border: none" colspan="1">
                                                <strong> Units</strong>
                                            </td>
                                        </tr>

                                        <tr *ngIf="!Part4Header.isOldVersionOfForms">
                                            <td class="bg-table-default" style="border: none">
                                                <input class="form-control"
                                                    [(ngModel)]="Part4Performance.keyPerformanceDrivers1"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />

                                            </td>
                                            <td class="bg-table-default" style="border: none">
                                                <input class="form-control"
                                                    [(ngModel)]="Part4Performance.keyPerformanceDriversUnit1"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none">
                                                <input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.key_Performance_Drivers_EV"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>

                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.key_Performance_Drivers_P10"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.key_Performance_Drivers_P90"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.key_Performance_Drivers_ACTUAL_OUTCOMES"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>


                                        <tr class="border-bottom"*ngIf="!Part4Header.isOldVersionOfForms">
                                            <td class="bg-table-default" style="border: none">
                                                <input class="form-control"
                                                    [(ngModel)]="Part4Performance.keyPerformanceDrivers2"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />

                                            </td>
                                            <td class="bg-table-default" style="border: none">
                                                <input class="form-control"
                                                    [(ngModel)]="Part4Performance.keyPerformanceDriversUnit2"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.keyPerformanceDriversEV1"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.keyPerformanceDriversP101"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.keyPerformanceDriversP901"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part4Performance.keyPerformanceDriversActualOutcomes1"
                                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <strong>Footnotes</strong>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <textarea [(ngModel)]="Part4Performance.footnotes"
                                    (change)=" UpdatePart4LookBacPerformanceData(Part4Performance)" rows="5"
                                    class="col-xs-12 form-control"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                        </div>

                    </div>
                    <!-- <---------------------Section 5--------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection5">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong class="heading">5.Front-end loading indices at appropriation </strong>
                                    Summarize impact of FEL work on project results.
                                </div>
                            </div>
                            <br />

                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>Asset</strong>
                                </div>
                            </div>
                            <div class="row pl-16" *ngIf="!Part4Header.isOldVersionOfForms">
                                <span>
                                    <mat-slide-toggle (change)="NAAssetEventCheck($event, Part4Header)"
                                        [checked]="Part4Header.assetNa"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                                        <span class="app-font">N/A</span>
                                    </mat-slide-toggle>
                                </span>
                            </div>
                            <div class="row" *ngIf="!Part4Header.assetNa">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold|| Part4Header.isReadOnly
                                    || Part4Header.assetNa || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #asset [editor]="Editor" [config]="config" id="asset" name="asset"
                                        [(ngModel)]="Part4Header.defintionAsset" (ready)="onReady($event,'asset')">
                                    </ckeditor>
                                </div>
                            </div>
                            <br />

                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>Facilities definition</strong>
                                </div>
                            </div>
                            <div class="row pl-16" *ngIf="!Part4Header.isOldVersionOfForms">
                                <span>
                                    <mat-slide-toggle (change)="NAFDEventCheck($event, Part4Header)"
                                        [checked]="Part4Header.facilitiesDefinitionNa"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                                        <span class="app-font">N/A</span>
                                    </mat-slide-toggle>
                                </span>
                            </div>
                            <div class="row" *ngIf="!Part4Header.facilitiesDefinitionNa">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold|| Part4Header.isReadOnly
                                    || Part4Header.facilitiesDefinitionNa || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #FD [editor]="Editor" [config]="config" id="FD" name="FD"
                                        [(ngModel)]="Part4Header.definitionFacilities" (ready)="onReady($event,'FD')">
                                    </ckeditor>
                                </div>
                            </div>
                            <br />

                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>Wells definition</strong>
                                </div>
                            </div>
                            <div class="row pl-16" *ngIf="!Part4Header.isOldVersionOfForms">
                                <span>
                                    <mat-slide-toggle (change)="NAWDEventCheck($event, Part4Header)"
                                        [checked]="Part4Header.wellsDifinitionNa"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                                        <span class="app-font">N/A</span>
                                    </mat-slide-toggle>
                                </span>
                            </div>
                            <div class="row" *ngIf="!Part4Header.wellsDifinitionNa">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold|| Part4Header.isReadOnly
                                    || Part4Header.wellsDifinitionNa || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #WD [editor]="Editor" [config]="config" id="WD" name="WD"
                                        [(ngModel)]="Part4Header.definitionWells" (ready)="onReady($event,'WD')">
                                    </ckeditor>
                                </div>
                            </div>
                            <br />

                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>Reservoir definition</strong>
                                </div>
                            </div>
                            <div class="row pl-16" *ngIf="!Part4Header.isOldVersionOfForms">
                                <span>
                                    <mat-slide-toggle (change)="NARDEventCheck($event, Part4Header)"
                                        [checked]="Part4Header.reservoirDifinitionNa"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                                        <span class="app-font">N/A</span>
                                    </mat-slide-toggle>
                                </span>
                            </div>
                            <div class="row" *ngIf="!Part4Header.reservoirDifinitionNa">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold|| Part4Header.isReadOnly
                                    || Part4Header.reservoirDifinitionNa || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #RD [editor]="Editor" [config]="config" id="RD" name="RD"
                                        [(ngModel)]="Part4Header.definitionReservoir" (ready)="onReady($event,'RD')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 6------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection6">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>6. Endorsement</strong>
                                    <br> &nbsp;
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-6">
                                    <label for="staticEmail" class="form-label">Reporting Officer:</label>
                                    <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.reportingOfficer"
                                        (change)="UpdatePart4LookBackHeaderData(Part4Header)"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                                    </textarea>
                                </div>
                                <div class="col-xs-6">
                                    <label for="staticEmail" class="form-label">Individual with delegation of
                                        authority:</label>
                                    <textarea rows="5" class="form-control"
                                        [(ngModel)]="Part4Header.delegationOfAuthority"
                                        (change)="UpdatePart4LookBackHeaderData(Part4Header)"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 7------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection7">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong *ngIf="!Part4Header.isOldVersionOfForms">Project Documentation</strong>
                                    <strong *ngIf="Part4Header.isOldVersionOfForms">7. Attachments</strong>
                                    <br> &nbsp;
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-12">
                                    <input type="checkbox" class="form-check-input" id="chk7a"
                                        [(ngModel)]="Part4Header.attachIpa" [checked]="Part4Header.attachIpa"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part4Header,'IPA')" />
                                    <label class="form-check-label pl-5" for="chk7a"> IPA close out report </label>
                                </div>
                                <div class="form-group col-xs-12">

                                    <input type="checkbox" class="form-check-input" id="chk7b"
                                        [(ngModel)]="Part4Header.attachL1RootCause"
                                        [checked]="Part4Header.attachL1RootCause"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part4Header,'rootCause')" />
                                    <label class="form-check-label pl-5" for="chk7b"> Level 1 root cause analysis report
                                        of major variances </label>
                                </div>
                                <div class="form-group col-xs-12">
                                    <input type="checkbox" class="form-check-input" id="chk7c"
                                        [(ngModel)]="Part4Header.attachL1MidPhase"
                                        [checked]="Part4Header.attachL1MidPhase"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part4Header,'midPhase')" />
                                    <label class="form-check-label pl-5" for="chk7c"> Level 1 mid phase 4 Lookback
                                        report
                                    </label>
                                </div>
                                <div class="form-group col-xs-12">

                                    <input type="checkbox" class="form-check-input" id="chk7d"
                                        [(ngModel)]="Part4Header.attachL1ReviewTeam"
                                        [checked]="Part4Header.attachL1ReviewTeam"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part4Header,'reviewTeam')" />
                                    <label class="form-check-label pl-5" for="chk7d"> Level 1 review team Lookback
                                        report
                                    </label>
                                </div>
                                <div class="form-group col-xs-12">
                                    <input type="checkbox" class="form-check-input" id="chk7e"
                                        [(ngModel)]="Part4Header.attachL1ExplorationProspect"
                                        [checked]="Part4Header.attachL1ExplorationProspect"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part4Header,'exploration')" />
                                    <label class="form-check-label pl-5" for="chk7e"> Exploration prospect data sheet
                                    </label>
                                </div>
                                <div class="form-group col-xs-12">

                                    <input type="checkbox" class="form-check-input" id="chk7f"
                                        [(ngModel)]="Part4Header.attachL1SubsurfaceBasisDesign"
                                        [checked]="Part4Header.attachL1SubsurfaceBasisDesign"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part4Header,'basisOfDesign')" />
                                    <label class="form-check-label pl-5" for="chk7f"> Subsurface basis of design
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 8 a------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection8a">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong class="heading">Project Completion Results</strong>
                                    <br> Summarize results versus AR expectations. Include a discussion of the root
                                    causes of outcomes with significant variance. Comment on the link between results
                                    and the decision quality at the time of AR sanction. Summarize key
                                    insights/recommended practices identified during project execution.
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong class="heading" *ngIf="!Part4Header.isOldVersionOfForms">
                                        6a. Operational Excellence Results During Project Execution : </strong>
                                        <strong class="heading" *ngIf="Part4Header.isOldVersionOfForms">
                                            8a. Operational Excellence Results During Project Execution : </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #section8a [editor]="Editor" [config]="config" id="section8a"
                                        name="section8a" [(ngModel)]="Part4Header.operationalResults"
                                        (ready)="onReady($event,'section8a')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <--------------------Section 8 b-------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection8b">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                  <strong class="heading" *ngIf="!Part4Header.isOldVersionOfForms">
                                    6b. {{ (this.features?.isNewCEisApplicableForms || Part4Header.isNewCEisApplicableForms) ? "Capex": "C&amp;E" }} Expenditure Results :
                                  </strong>
                                  <strong class="heading" *ngIf="Part4Header.isOldVersionOfForms">
                                    8b. {{ (this.features?.isNewCEisApplicableForms || Part4Header.isNewCEisApplicableForms) ? "Capex": "C&amp;E" }} Expenditure Results :
                                  </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #section8b [editor]="Editor" [config]="config" id="section8b"
                                        name="section8b" [(ngModel)]="Part4Header.expenditureResults"
                                        (ready)="onReady($event,'section8b')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <--------------------Section 8 c-------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection8c">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong *ngIf="!Part4Header.isOldVersionOfForms">6c. Schedule Results : </strong>
                                    <strong *ngIf="Part4Header.isOldVersionOfForms">8c. Schedule Results : </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #section8c [editor]="Editor" [config]="config" id="section8c"
                                        name="section8c" [(ngModel)]="Part4Header.scheduleResults"
                                        (ready)="onReady($event,'section8c')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 8 d------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection8d">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong *ngIf="!Part4Header.isOldVersionOfForms">6d. Commercial Agreement Results : </strong>
                                    <strong *ngIf="Part4Header.isOldVersionOfForms">8d. Commercial Agreement Results : </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #section8d [editor]="Editor" [config]="config" id="section8d"
                                        name="section8d" [(ngModel)]="Part4Header.commercialAgreementResults"
                                        (ready)="onReady($event,'section8d')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 8 e------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection8e">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong *ngIf="!Part4Header.isOldVersionOfForms">6e. Early Operational Results (hydrocarbon recovery estimates) : </strong>
                                    <strong *ngIf="Part4Header.isOldVersionOfForms">8e. Early Operational Results (hydrocarbon recovery estimates) : </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #section8e [editor]="Editor" [config]="config" id="section8e"
                                        name="section8e" [(ngModel)]="Part4Header.earlyOperationalResults"
                                        (ready)="onReady($event,'section8e')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 7--------------------------------->
                    <div class="col-xs-12 form-group mt-20 fs-16" *ngIf="showSection9">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12" *ngIf="!Part4Header.isOldVersionOfForms">
                                <strong class="heading">7. Mid-phase 4 Lookback (Level 1 Lookbacks only)</strong>
                                Summarize results and how they were shared.
                            </div>
                            <div class="col-xs-12" *ngIf="Part4Header.isOldVersionOfForms">
                                <strong class="heading">9. Mid-phase 4 Lookback (Level 1 Lookbacks only)</strong>
                                Summarize results and how they were shared.
                            </div>
                            <div class="row pl-16" *ngIf="!Part4Header.isOldVersionOfForms">
                                <span>
                                    <mat-slide-toggle (change)="NAMidPhase4EventCheck($event, Part4Header)"
                                        [checked]="Part4Header.midPhase4Na"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                                        <span class="app-font">N/A</span>
                                    </mat-slide-toggle>
                                </span>
                            </div>
                        </div>
                        <div class="row" *ngIf="!Part4Header.midPhase4Na">
                            <div class="col-xs-12"
                                [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.midPhase4Na || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit}">
                                <ckeditor #section9 [editor]="Editor" [config]="config" id="section9" name="section9"
                                    [(ngModel)]="Part4Header.midPhase4Results" (ready)="onReady($event,'section9')">
                                </ckeditor>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 8-------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection10">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <strong *ngIf="!Part4Header.isOldVersionOfForms">8. Decision Executive, DRB and Project Manager</strong>
                                <strong *ngIf="Part4Header.isOldVersionOfForms">10. Decision Executive, DRB and Project Manager</strong>
                                <br> &nbsp;
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 text-bold">
                                Phase 1
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Decision Executive</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p1De"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Project Manager</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p1ProjectLead"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">DRB</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p1Drb"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 text-bold">
                                Phase 2
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Decision Executive</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p2De"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Project Manager</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p2ProjectLead"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">DRB</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p2Drb"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 text-bold">
                                Phase 3
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Decision Executive</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p3De"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Project Manager</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p3ProjectLead"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">DRB</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p3Drb"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 text-bold">
                                Phase 4
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Decision Executive</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p4De"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Project Manager</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p4ProjectLead"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">DRB</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p4Drb"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 9--------------------------------->
                    <div class="col-xs-12 form-group mt-20 fs-16" *ngIf="showSection11">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <strong *ngIf="!Part4Header.isOldVersionOfForms">9. Lookback team member names/BUs</strong>
                                <strong *ngIf="Part4Header.isOldVersionOfForms">11. Lookback team member names/BUs</strong>
                                <br> &nbsp;
                            </div>
                        </div>
                        <br>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Name</label>
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember1Name"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Job Title</label>
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember1Title"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Affiliation</label>
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember1Affiliation"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember2Name"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember2Title"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember2Affiliation"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember3Name"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember3Title"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember3Affiliation"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember4Name"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember4Title"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember4Affiliation"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember5Name"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember5Title"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember5Affiliation"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember6Name"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember6Title"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember6Affiliation"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember7Name"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember7Title"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember7Affiliation"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember8Name"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember8Title"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember8Affiliation"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember9Name"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember9Title"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember9Affiliation"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember10Name"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember10Title"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part4Header.teamMember10Affiliation"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Form Reviewer------------------------------>
                    <div class="row form-group fs-16" *ngIf="showSection12">
                        <div class="box-container">
                            <!-- <div class="row"> -->
                            <!-- <div class="col-xs-12"> -->
                            <hr />
                            <div class="form-horizontal">
                                <div class="row">
                                    <div class="col-xs-12 padding-0">
                                        <ng2-completer [(ngModel)]="searchStr"
                                            [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canSubmit"
                                            [datasource]="dataService" [placeholder]="placeholder" [minSearchLength]="0"
                                            (selected)="addUserToAccessList($event)" [autoMatch]="true"
                                            (keyup)="SearchUser($event)">

                                        </ng2-completer>
                                    </div>
                                    <div class="col-xs-3">
                                        &nbsp;
                                    </div>
                                </div>
                                <div class="row" *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">
                                    <div *ngIf="name.errors?.['minlength']" class="alert alert-danger" role="alert">
                                        Name must be at least 4 characters long.
                                    </div>
                                </div>
                                <div class="col-xs-3">
                                    &nbsp;
                                </div>
                                <table class="table border-bottom table-striped table-responsive table-bordered">
                                    <tbody>
                                        <tr class="border-top border-bottom">
                                            <th></th>
                                            <th class="text-center">
                                                <div>Sender</div>
                                            </th>
                                            <th class="text-center">
                                                <div>Receiver</div>
                                            </th>
                                            <th class="text-center">
                                                <div>Latest Status</div>
                                            </th>
                                            <th class="text-center">
                                                <div>Modified Date</div>
                                            </th>
                                            <th class="text-center">
                                                <div>Latest Version</div>
                                            </th>
                                            <th class="text-center">
                                                <div>Comments</div>
                                            </th>
                                            <th class="text-center" style="width: 34% !important;">Form Action</th>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr *ngFor="let reviewerItem of reviewerData let i = index"
                                            class="border-top border-bottom table-header">
                                            <td [(ngModel)]="reviewerItem.id" hidden class="ml-10">
                                                {{reviewerItem.id}}</td>
                                            <td class="text-center col-xs-offset-0">
                                                <button type="button" style="color: white !important;"
                                                    class="btn btn-sm btn-danger"
                                                    (click)="DeleteReviewer(reviewerItem,'delete')" [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly
                                                                || this.part4LookBackAccessList?.data?.isLocked
                                                                || reviewerItem.deleteFlag">
                                                    X
                                                </button>
                                            </td>
                                            <td class="text-center col-xs-1">
                                                <span>{{reviewerItem.submitterName}}</span>
                                            </td>
                                            <td class="text-center col-xs-1">
                                                <span>{{reviewerItem.reviewerName}}</span>
                                            </td>
                                            <td class="text-center col-xs-1">

                                                <span></span>
                                                <span>{{reviewerItem.currentStatusName}}</span>

                                            </td>
                                            <td class="text-center col-xs-1">
                                                <span>{{reviewerItem.modifiedOn}}</span>
                                            </td>
                                            <td class="text-center col-xs-1">
                                                <span>{{reviewerItem.versionId}}</span>
                                            </td>
                                            <td class="text-center col-xs-2">

                                                <textarea class="form-control" rows="2" [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly
                                                            || this.part4LookBackAccessList?.data?.isLocked
                                                            || reviewerItem.commentFlag"
                                                    [(ngModel)]="reviewerItem.comments">
                                                            </textarea>
                                            </td>
                                            <td style="width: 36% !important;">
                                                <button type="button" style="color: white !important;"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly
                                                                || this.part4LookBackAccessList?.data?.isLocked || reviewerItem.submitterFlag ||!this.part4LookBackAccessList?.data?.canSubmit"
                                                    class="btn btn-sm btn-success"
                                                    (click)="UpdateReviewer(reviewerItem.id,'forreview',reviewerItem,reviewerItem.comments)">
                                                    For Review
                                                </button>
                                                <button type="button" style="color: white !important;"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly
                                                                || this.part4LookBackAccessList?.data?.isLocked || reviewerItem.reviewerFlag"
                                                    class="btn btn-sm btn-warning"
                                                    (click)="UpdateReviewer(reviewerItem.id,'sendback',reviewerItem,reviewerItem.comments)">
                                                    Send Back
                                                </button>
                                                <button type="button" style="color: white !important;" *ngIf="!Part4Header.isOldVersionOfForms"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly
                                                                || this.part4LookBackAccessList?.data?.isLocked || reviewerItem.reviewerFlag"
                                                    class="btn btn-sm btn-success"
                                                    (click)="UpdateReviewer(reviewerItem.id,'endorsed',reviewerItem,reviewerItem.comments)">
                                                    Endorse
                                                </button>
                                                <button type="button" style="color: white !important;"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly
                                                                || this.part4LookBackAccessList?.data?.isLocked || reviewerItem.reviewerFlag || !this.part4LookBackAccessList?.data?.canApprove"
                                                    class="btn btn-sm btn-success"
                                                    (click)="OpenApprovalDialog(reviewerItem.id,reviewerItem.comments)">
                                                    Concur
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="reviewerCount==false" class="col-xs-12 padding-0 text-center">
                                    &nbsp;
                                    <b>No reviewers available. Please use the search bar to add
                                        reviewers.</b>
                                </div>
                            </div>
                            <!-- </div> -->
                            <!-- </div> -->
                            <hr />

                            <div class="row">
                                <!-- <div class="col-xs-12"> -->
                                <div class="form-horizontal">
                                    <!-- <div> -->
                                    <div class="row" style="text-align:center; display:block">
                                        Form Logs
                                    </div>
                                    <table class="table border-bottom table-striped table-responsive table-bordered">
                                        <tbody>
                                            <tr class="border-top border-bottom">
                                                <th class="text-center">
                                                    <div>Action Performed</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Sender</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Receiver</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Status From</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Status To</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Sender Comments</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Receiver Comments</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Version</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Date</div>
                                                </th>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr *ngFor="let formsLogItem of formsLogData let i = index"
                                                class="border-top border-bottom table-header">
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.formAction}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.submittedByUserName}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.submittedToUserName}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.oldStatusName}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.currentStatusName}}</span>
                                                </td>
                                                <td class="text-center col-xs-3">
                                                    <span>{{formsLogItem.submitterComments}}</span>
                                                </td>
                                                <td class="text-center col-xs-3">
                                                    <span>{{formsLogItem.reviewerComments}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.versionNumber}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.createdOn}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <!-- </div> -->
                                </div>
                                <!-- </div> -->
                                <div class="col-xs-3">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group" *ngIf="!Part4Header.isOldVersionOfForms">
                            <strong>Signoffs </strong>
                            <span class="glyphicon glyphicon-question-sign" (click)="ShowSignoffsPopUp()"></span>
                        </div>
                        <div class="col-xs-12 sub-section" *ngIf="!Part4Header.isOldVersionOfForms">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr class="bg-table-default table-header">
                                        <td></td>
                                        <td width="20%">Name</td>
                                        <td width="20%">Position</td>
                                        <td width="20%">Signature</td>
                                        <td width="20%">Date</td>
                                    </tr>
                                    <tr *ngFor="let signOffItem of signOffData let i = index">
                                        <td [(ngModel)]="signOffItem.id" hidden class="ml-10">{{signOffItem.id}}</td>
                                        <!-- <td [(ngModel)]="signOffItem.signoffName" hidden class="ml-10">
                                            {{signOffItem.signoffName}}</td> -->
                                        <td [(ngModel)]="signOffItem.formType" hidden class="ml-10">
                                            {{signOffItem.formType}}</td>
                                            <td>{{signOffItem.signoffName}}</td>
                                        <td>
                                            <textarea type="text" rows="3"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                class="form-control" [(ngModel)]="signOffItem.name"
                                                (change)="UpdateSignOff(signOffItem,2)">
                                            </textarea>
                                        </td>
                                        <td>
                                            <textarea type="text" rows="3"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                class="form-control" [(ngModel)]="signOffItem.position"
                                                (change)="UpdateSignOff(signOffItem,2)">
                                            </textarea>
                                        </td>
                                        <td>
                                            <textarea type="text" rows="3"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                class="form-control" [(ngModel)]="signOffItem.signature"
                                                (change)="UpdateSignOff(signOffItem,2)">
                                            </textarea>
                                        </td>
                                        <td class="form-date">
                                            <input class="form-control" [(ngModel)]="signOffItem.signoffDate"
                                                (dateChange)="UpdateSignOff(signOffItem,2)" [matDatepicker]="picker"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canEdit"
                                                (click)="picker.open();utilityService.removeCDKAnouncer()">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-sidenav-container>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            &nbsp;
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 padding-0">
            <div class="text-left">
                <h5 style="color: #0B2D71;
            font-size: 14px;" class="text-left">Upload Attachments :</h5>
                <input type="file" class="form-control-file upload" #file (change)="uploadFile($event)"
                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 padding-0 ">
            <table class="table table-bordered table-striped table-responsive">
                <thead>
                    <tr>
                        <td class="text-center text-bold">&nbsp;</td>
                        <td class="text-center text-bold">Document ID</td>
                        <td class="text-left text-bold">File Name</td>
                        <td class="text-left text-bold">Uploaded By</td>
                        <td class="text-left text-bold">&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-bordered" *ngFor="let document of part4Document let i = index">
                        <td class="text-center">
                            <button type="button" style="color: white !important;" class="btn btn-sm btn-primary"
                                (click)="DownloadFile(document)">
                                Download
                            </button>
                        </td>
                        <td class="text-center">
                            <span>{{document.documentId}}</span>
                        </td>
                        <td class="text-left">
                            <span>{{document.docName}}</span>
                        </td>
                        <td class="text-left">
                            <span>{{document.createdByName}}</span>
                        </td>
                        <td class="text-left">
                            <span>
                                <button type="button" style="color: white !important;" class="btn btn-sm btn-danger"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.part4LookBackAccessList?.data?.isLocked"
                                    (click)="RemoveFile(document, i)">X</button>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12">
            <hr>
        </div>
    </div>
    <div class="row" style="margin-top: 15px;">
        <div class="col-xs-12 padding-0">
            <div class="row">
                <div class="form-group col-sm-6 col-xs-12 text-left padding-0 ">

                    <button type="button" style="color: white !important;" class="btn btn-sm btn-primary"
                        style="color: white !important" [routerLink]="['/go36project/viewform']"
                        [queryParams]="{projectId: projectId}">Close</button>
                    <button type="button" style="color: white !important" class="btn btn-sm btn-danger"
                        *ngIf="!Part4Header.isReadOnly "
                        [disabled]="Part4Header.isOnHold || !this.part4LookBackAccessList?.data?.canDeleteForm"
                        [routerLink]="['/forms/feed/delete']"
                        [queryParams]="{projectId: Part4Header.projectId,formId: Part4Header.arP4LookbackId,versionName: Part4Header.version,formType:'AR_LOOKBACK_PART4'}">
                        Delete</button>
                </div>
                <!-- <div class="form-group col-xs-4 text-center">
                    <div>
                        <button type="button" style="color: white !important" class="btn btn-sm btn-primary btn-dark"
                            (click)="CreatePart5LookBack()">Part 5 Lookback</button>
                    </div>
                </div> -->
                <div class="form-group col-sm-6 col-xs-12 text-right padding-0">
                    <!-- <button type="button" style="color: white !important"
                        class="btn btn-sm btn-primary btn-dark" (click)="ExportPDF()">PDF</button> -->
                    <a [href]="pdfUrl" class="btn btn-sm btn-primary btn-dark" target="_blank"
                        style="color: white !important;margin-right: 0.5em;margin-bottom: 0.5em;">PDF</a>
                    <button type="button" style="color: white !important" class="btn btn-sm btn-success btn-dark"
                        *ngIf="!Part4Header.isReadOnly" (click)="OpenArchiveDialog()"
                        [disabled]="Part4Header.isOnHold || this.part4LookBackAccessList?.data?.isLocked || !this.part4LookBackAccessList?.data?.canArchive || Part4Header.statusId==5">Archive</button>

                        <button *ngIf="!Part4Header.isReadOnly && this.part4LookBackAccessList?.data?.isLatestVersion && !this.features?.hasWorkFlowProcessFeature" type="button"
                        class="btn btn-sm btn-success btn-dark" (click)="performFormAction('forreview')"
                        style="color: white !important;"
                        [disabled]="isOnHold || !this.part4LookBackAccessList?.data?.canSubmit || Part4Header.isReadOnly ||  this.part4LookBackAccessList?.data?.isLocked || this.forReviewLockByFormState">Send For Review</button>
              
                        <button *ngIf="!Part4Header.isReadOnly && this.part4LookBackAccessList?.data?.isLatestVersion && !this.features?.hasWorkFlowProcessFeature" type="button"
                        class="btn btn-sm btn-warning btn-dark" (click)="performFormAction('sendback')"
                        style="color: white !important;"
                        [disabled]="isOnHold || !this.part4LookBackAccessList?.data?.canSendBack || Part4Header.isReadOnly ||  this.part4LookBackAccessList?.data?.isLocked || this.sendBackLockByFormState">Send Back</button>
              
                        <button *ngIf="!Part4Header.isReadOnly && this.part4LookBackAccessList?.data?.isLatestVersion && !this.features?.hasWorkFlowProcessFeature" type="button"
                        class="btn btn-sm btn-success btn-dark" (click)="performFormAction('approved')"
                        style="color: white !important;"
                        [disabled]="isOnHold || !this.part4LookBackAccessList?.data?.canApprove || Part4Header.isReadOnly ||  this.part4LookBackAccessList?.data?.isLocked || this.concurLockByFormState">Concur</button>
                </div>
            </div>
        </div>
    </div>
</div>
