<div class="row text-center">
    <h4  style="font-size: 1.13em;line-height: 1.22em;">investment overview & endorsement</h4>
</div>
<hr style="margin-bottom: 0;"/>
<div class="row">
    <div class="tabbed tabbed-content-control tabbed-side-nav clearfix">
        <mat-sidenav-container>
            <mat-sidenav mode="side" class="sidenav" opened>
                <mat-nav-list class=" nav-tabs font-gotham">
                    <a mat-list-item (click)="showSection(1)" style="color:white">
                        <strong [ngClass]="{active:showSection1}">1. Project Overview Information</strong></a>
                    <a mat-list-item (click)="showSection(2)" style="color:white">
                        <strong [ngClass]="{active:showSection2}">2. Commitment Summary</strong></a>
                    <a mat-list-item (click)="showSection(3)" style="color:white">
                        <strong [ngClass]="{active:showSection3}">3. Investment Description</strong></a>
                    <a mat-list-item (click)="showSection(4)" style="color:white" *ngIf="IsOldVersionOfForms">
                        <strong [ngClass]="{active:showSection4}">4. Endorsements</strong></a>
                </mat-nav-list>
            </mat-sidenav>
            <fieldset [disabled]="isOnHold  || Part1Model.isReadOnly" *ngIf="projectData!=undefined">                
                <div class="row" style="padding: 20px 15px;">
                    <!-- --------------------Section 1-------------------------------->
                    <div *ngIf="showSection1" class="tab-content tabs nav nav-tabs font-gotham">
                        <div class="content-container">
                            <div class="row">
                                <div class="form-group col-xs-2">
                                    <label class="form-label">Project ID:</label>
                                    <input type="text" class="form-control" readonly="readonly" [(ngModel)]="Part1Model.projectId"/>
                                </div>
                                <div class="form-group col-xs-4">
                                    <label class="form-label">Project Name / Title:</label>
                                    <input type="text" class="form-control" [(ngModel)]="projectData.projectName" readonly="readonly" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">AR Created By:</label>
                                    <input type="text" class="form-control" readonly="readonly" [(ngModel)]="Part1Model.createdBy_FullName" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">AR Last Modified By:</label>
                                    <input type="text" class="form-control" readonly="readonly" [(ngModel)]="Part1Model.modifiedBy_FullName" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-3">
                                    <label for="opco">1a. Segment:</label>
                                    <input type="text" class="form-control" [(ngModel)]="projectData.opcoName" readonly="readonly" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label for="businessUnit">1b. RU:</label>
                                    <input type="text" class="form-control" [(ngModel)]="projectData.sbuName" readonly="readonly" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label for="projectLocation">1c. BU:</label>
                                    <input type="text" class="form-control" [(ngModel)]="projectData.buName" readonly="readonly" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label for="opco">1d. Asset Class:</label>
                                    <input type="text" class="form-control" [(ngModel)]="projectData.assetName"  readonly="readonly" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-3">
                                    <label for="projectLocation">1d. Project Location:</label>
                                    <input [disabled]="isReadOnlyGlobalProjectLevel" type="text" class="form-control" [(ngModel)]="Part1Model.projectLocation" maxlength="50" (change)="UpdatePart1Data()"/>
                                </div>                    
                                <div class="form-group col-xs-3">
                                    <label for="date">1e. Date:</label>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="Part1Model.arDate" [matDatepicker]="arDatepicker"
                                    (click)="arDatepicker.open();utilityService.removeCDKAnouncer()" (dateChange)=" UpdatePart1Data()">
                                    <mat-datepicker #arDatepicker></mat-datepicker>
                                </div>
                                <div class="form-group col-xs-3">
                                    <label for="arNumber">1g. AR #:</label>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="text" class="form-control" [(ngModel)]="Part1Model.arId" maxlength="50" readonly="readonly" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label for="arNumber">1h. Special Project Type:</label>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="text" class="form-control" [(ngModel)]="projectData.SpecialProjectTypeName" maxlength="50" readonly="readonly" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-3">
                                    <label for="projectLocation">1i. Facility (megafriendly) Name:</label>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="text" class="form-control" [(ngModel)]="projectData.assetMegaFriendlyName" maxlength="50" readonly="readonly"/>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-12">
                                    <span>1j. AR characteristics (check all that apply)</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-2">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="checkbox" class="form-check-input" id="chkExploration" 
                                    (change)="UpdateArCharacteristics($event,'Exploration')" [checked]="Part1Model.exploration">
                                    <label class="form-check-label" style="padding-left: 4px;" for="chkExploration">Exploration</label>
                                </div>
                                <div class="form-group col-xs-2">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="checkbox" class="form-check-input" id="chkAppraisal"
                                    (change)="UpdateArCharacteristics($event,'Appraisal')" [checked]="Part1Model.appraisal">
                                    <label class="form-check-label" style="padding-left: 4px;" for="chkAppraisal">Appraisal</label>
                                </div>
                                <div class="form-group col-xs-2">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="checkbox" class="form-check-input" id="chkProgram"
                                    (change)="UpdateArCharacteristics($event,'Program')" [checked]="Part1Model.program">
                                    <label class="form-check-label" style="padding-left: 4px;" for="chkProgram">Program</label>
                                </div>
                                <div class="form-group col-xs-2">
                                    <input type="checkbox" class="form-check-input" id="chkScopeChange" disabled
                                    [checked]="Part1Model.scopeChange">
                                    <label class="form-check-label" style="padding-left: 4px;" for="chkScopeChange">Scope Change</label>
                                </div>
                                <div class="form-group col-xs-2">
                                    <input type="checkbox" class="form-check-input" id="chkSupplement" disabled
                                    [checked]="Part1Model.supplement">
                                    <label class="form-check-label" style="padding-left: 4px;" for="chkSupplement">Supplement</label>
                                </div>
                                <div class="form-group col-xs-2">
                                    <input type="checkbox" class="form-check-input" id="chkNOJV" disabled
                                    [checked]="Part1Model.nojv">
                                    <label class="form-check-label" style="padding-left: 4px;" for="chkNOJV">NOJV</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-3">
                                    <label for="originalDate">Original AR date:</label>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="Part1Model.originalDate" [matDatepicker]="originalDatepicker" 
                                    (click)="originalDatepicker.open();utilityService.removeCDKAnouncer()" (dateChange)=" UpdatePart1Data()" [disabled]="Part1Model.arId > 0">
                                    <mat-datepicker #originalDatepicker></mat-datepicker>
                                </div>
                                <div class="form-group col-xs-3">
                                    <label for="cpdepPhase">CPDEP Phase:</label>
                                    <select  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="Part1Model.cpdepPhase" (change)="UpdatePart1Data()" >
                                        <option *ngFor="let CPDEP of CPDEPPhases" [value]="CPDEP.value">{{CPDEP.text}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-xs-3">
                                    <label for="operatorName">Operator Name:</label>
                                    <input [disabled]="isReadOnlyGlobalProjectLevel"  id="operatorName" [(ngModel)]="Part1Model.operatorName" type="text" class="form-control" maxlength="50" (change)="UpdatePart1Data()">
                                </div>
                                <div class="form-group col-xs-3">
                                    <label for="cvxShare">Chevron Share %:</label>
                                    <div class="input-group">
                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" type="text" [(ngModel)]="Part1Model.cvxShare" class="form-control" (change)="UpdatePart1Data()">
                                        <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('Overview','CVXShare')" (click)="GetComments('Overview','CVXShare')"></i>
                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('Overview','CVXShare')" (click)="GetComments('Overview','CVXShare')"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- --------------------Section 2 ------------------>
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection2">
                        <div class="content-container">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>2. Commitment Summary</strong>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="form-group col-xs-6">
                                    <label for="appropriatedAmount">2a. Appropriated amount $MM (AR Part3, line 1):</label>
                                        <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                            <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarAppropriatedAmount">
                                                <input type="number" [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="Part1Model.appropriatedAmount" class="form-control" (change)="UpdatePart1Data()">
                                                <i style="z-index: 1111;">$</i>  
                                            </div> 
                                            <ng-template #noDollarAppropriatedAmount>
                                                <input type="number" [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="Part1Model.appropriatedAmount" class="form-control" (change)="UpdatePart1Data()">
                                            </ng-template>
                                            <div class="input-group-addon" >
                                                <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('CommitmentSummary','AppropriatedAmount')" (click)="GetComments('CommitmentSummary','AppropriatedAmount')"></i>
                                                <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('CommitmentSummary','AppropriatedAmount')" (click)="GetComments('CommitmentSummary','AppropriatedAmount')"></i>
                                            </div>
                                        </div>
                                </div>
                                <div class="form-group col-xs-6">
                                    <label for="totalCommitment">2b. Total Commitment $MM (AR Part3, line 4):</label>
                                        <div class="input-group">
                                            <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarTotalCommitment">
                                                <input type="number" [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="Part1Model.totalCommitment" class="form-control" (change)="UpdatePart1Data()">
                                                <i style="z-index: 1111;">$</i>  
                                            </div> 
                                            <ng-template #noDollarTotalCommitment>
                                                <input type="number" [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="Part1Model.totalCommitment" class="form-control" (change)="UpdatePart1Data()">
                                            </ng-template>
                                            <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('CommitmentSummary','TotalCommitment')" (click)="GetComments('CommitmentSummary','TotalCommitment')"></i>
                                                <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('CommitmentSummary','TotalCommitment')" (click)="GetComments('CommitmentSummary','TotalCommitment')"></i>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-12">
                                    <ckeditor #section2aInformation [editor]="Editor" [config]="config" id="section2aInformation" name="section2aInformation"
                                    [(ngModel)]="Part1Model.section2aInformation" (ready)="onReady($event)" [disabled]="isOnHold  || Part1Model.isReadOnly || isReadOnlyGlobalProjectLevel"></ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <--------------------Section 3--------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection3">
                        <div class="content-container">
                            <div class="row">
                                <div class="col-xs-12">
                                    <p><strong>3. Investment description, rationale and strategic alignment.</strong>
                                    Describe the scope and major business drivers for the proposed exploration/appraisal investment and summarize the present situation. Describe why we should make this investment now and how it is aligned with Chevron/Business Unit strategic objectives. Describe additional significant opportunities that may be created or enabled by investing in this opportunity and what provisions you have made in the current investment to capture these opportunities. Discuss the funding plan and how it is aligned with the current business plan.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-12">
                                    <ckeditor #section3Information [editor]="Editor" [config]="config" id="section3Information" name="section3Information"
                                    [(ngModel)]="Part1Model.section3Information" (ready)="onReady($event)" [disabled]="isOnHold  || Part1Model.isReadOnly || isReadOnlyGlobalProjectLevel"></ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <---------------------Section 4--------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection4 && IsOldVersionOfForms">
                        <div class="content-container">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>4. Endorsements</strong>
                                </div>
                            </div>
                            <br>
                            <div class="row endorsement-grid-alignment">
                                <div class="col-xs-6">
                                    <label for="Project" class="form-label">Project Manager or Lead:</label>
                                    <textarea  [disabled]="isReadOnlyGlobalProjectLevel" rows="5" class="form-control" [(ngModel)]="Part1Model.projectManager" (change)="UpdatePart1Data()">
                                    </textarea>
                                </div>
                                <div class="col-xs-4">
                                    <label for="Invidividual" class="form-label">Invidividual with delegation of authority
                                        (for ARs below Reporting Officer threshold):</label>
                                    <textarea  [disabled]="isReadOnlyGlobalProjectLevel"  rows="5" class="form-control" [(ngModel)]="Part1Model.individualDoa" (change)="UpdatePart1Data()">
                                    </textarea>
                                </div>
                                <div class="col-xs-2">
                                    <label for="Date" class="form-label">Date:</label>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="Part1Model.individualDoaDate" [matDatepicker]="individualDoaDatepicker" 
                                    (click)="individualDoaDatepicker.open();utilityService.removeCDKAnouncer()" (dateChange)="UpdatePart1Data()">
                                    <mat-datepicker #individualDoaDatepicker></mat-datepicker>
                                </div>
                            </div>
                            <br>
                            <div class="row endorsement-grid-alignment">
                                <div class="col-xs-6">
                                    <label for="Project" class="form-label">DRB Members:</label>
                                    <textarea  [disabled]="isReadOnlyGlobalProjectLevel"  rows="5" class="form-control" [(ngModel)]="Part1Model.drbMembers">
                                    </textarea>
                                </div>
                                <div class="col-xs-4">
                                    <label for="Invidividual" class="form-label">Reporting Officer:</label>
                                    <textarea  [disabled]="isReadOnlyGlobalProjectLevel"  rows="5" class="form-control" [(ngModel)]="Part1Model.reportingOfficer" (change)="UpdatePart1Data()">
                                    </textarea>
                                </div>
                                <div class="col-xs-2">
                                    <label for="Date" class="form-label">Date:</label>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="Part1Model.reportingOfficerDate" [matDatepicker]="reportingOfficerDatepicker"
                                    (click)="reportingOfficerDatepicker.open();utilityService.removeCDKAnouncer()" (dateChange)="UpdatePart1Data()">
                                    <mat-datepicker #reportingOfficerDatepicker></mat-datepicker>
                                </div>
                            </div>
                            <br>
                            <div class="row endorsement-grid-alignment">
                                <div class="col-xs-6">
                                    <label for="Project" class="form-label">Decision Executive:</label>
                                    <textarea  [disabled]="isReadOnlyGlobalProjectLevel"  rows="5" class="form-control" [(ngModel)]="Part1Model.decisionExecutive" (change)="UpdatePart1Data()">
                                    </textarea>
                                </div>
                                <div class="col-xs-4">
                                    <label for="Invidividual" class="form-label">Corporate Secretary:</label>
                                    <textarea  [disabled]="isReadOnlyGlobalProjectLevel"  rows="5" class="form-control" [(ngModel)]="Part1Model.corporateSecretary" (change)="UpdatePart1Data()">
                                    </textarea>
                                </div>
                            </div>
                            <br>
                            <div class="row endorsement-grid-alignment">
                                <div class="col-xs-4">
                                    <label for="Project" class="form-label">Functional Endorsements:</label>
                                    <textarea  [disabled]="isReadOnlyGlobalProjectLevel"  rows="5" class="form-control" [(ngModel)]="Part1Model.functionalEndorsementPosition" (change)="UpdatePart1Data()">
                                    </textarea>
                                </div>
                                <div class="col-xs-2">
                                    <label for="Invidividual" class="form-label">Date Received:</label>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="Part1Model.functionalEndorsementsDate" [matDatepicker]="functionalEndorsementsDatepicker" 
                                    (click)="functionalEndorsementsDatepicker.open();utilityService.removeCDKAnouncer()" (dateChange)="UpdatePart1Data()">
                                    <mat-datepicker #functionalEndorsementsDatepicker></mat-datepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </mat-sidenav-container>
    </div>
</div>