import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AssetListComponent } from './admin/asset/asset-list/asset-list.component';
import { AssetCreateComponent } from './admin/asset/asset-create/asset-create.component';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DataTablesModule } from 'angular-datatables';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RippleModule } from 'primeng/ripple';
import { PrimeNGConfig } from 'primeng/api';
import { Go36projectComponent } from './project/go36project/go36project.component';
import { EaComponent } from './project/ea/ea.component';
import { Go36projectCreateComponent } from './project/go36project/go36project-create/go36project-create.component';
import { Go36projectlistComponent } from './project/go36project/go36project-list/go-36-project-list.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CalAngularModule } from '@cvx/cal-angular';
import { BnNgIdleService } from 'bn-ng-idle';
import { UseraccessComponent } from './project/go36project/useraccess/useraccess.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RefreshDialogComponent } from './Modals/refresh-dialog/refresh-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ViewformlistComponent } from './project/go36project/viewform/viewformlist/viewformlist.component';
import { OverviewComponent } from './forms/feed/overview/overview/overview.component';
import { FeedNavComponent } from './forms/feed/feed-nav/feed-nav.component';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BusinessCaseComponent } from './forms/feed/business-case/business-case.component';
import { ExecutionComponent } from './forms/feed/execution/execution.component';
import { BasisOfEvaluationComponent } from './forms/feed/basis-of-evaluation/basis-of-evaluation.component';
import { EaProjectListComponent } from './project/ea/ea-project-list/ea-project-list.component';
import { EaProjectCreateComponent } from './project/ea/ea-project-create/ea-project-create.component'
import { EAUseraccessComponent } from './project/ea/useraccess/ea-useraccess.component'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CopyProjectComponent } from './project/go36project/copy-project/copy-project.component';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmDialogComponent } from './Modals/confirm-dialog/confirm-dialog.component';
import { CommentDialogComponent } from './Modals/comment-dialog/comment-dialog.component';
import { InfoDialogComponent } from './Modals/info-dialog/info-dialog.component';
import { DataImportComponent } from './forms/feed/data-import/data-import.component';
import { FeedDialogComponent } from './Modals/feed-dialog/feed-dialog.component';
import { AlternativeSelectionComponent } from './forms/feed/alternative-selection/alternative-selection.component';
import { LoaderComponent } from './layout/loader/loader.component';
import { LoaderInterceptor } from './layout/loader/loader.interceptor';
import { PopupActiveUsersComponent } from './forms/popup-active-users/popup-active-users.component';
import { RisksComponent } from './forms/feed/risks/risks.component';
import { environment } from 'src/environments/environment';
import { DeleteComponent } from './forms/feed/delete/delete.component';
import { ProjectDocumentationComponent } from './forms/feed/project-documentation/project-documentation.component';
import { ArchiveDialogComponent } from './Modals/archive-dialog/archive-dialog.component';
import { FeedSupplementNavComponent } from './forms/feed_Supplement/feed-supplement-nav/feed-supplement-nav.component';
import { FidNavComponent } from './forms/fid/fid-nav/fid-nav.component';
import { FidSupplementNavComponent } from './forms/fid_supplement/fid-supplement-nav/fid-supplement-nav.component';
import { SupplementOverviewComponent } from './forms/feed_Supplement/supplement-overview/supplement-overview.component';
import { LessonsLearnedComponent } from './forms/lessons-learned/lessons-learned.component';
import { FidOverviewComponent } from './forms/fid/fid-nav/fid-overview/fid-overview.component';
import { FidSupplementOverviewComponent } from './forms/fid_supplement/fid-supplement-nav/fid-supplement-overview/fid-supplement-overview.component';
import { LessonlearnedDialogComponent } from './Modals/lessonlearned-dialog/lessonlearned-dialog.component';
import { FeedSupplementRisksComponent } from './forms/feed_Supplement/feed-supplement-risks/feed-supplement-risks.component';
import { LessonsLearnedNavComponent } from './forms/lessons-learned/lessons-learned-nav/lessons-learned-nav.component';
import { Part4LookBackNavComponent } from './forms/oldstyle_part4lookback/part4lookback-nav/part4lookback-nav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { EaViewformlistComponent } from './project/ea/ea-viewformlist/ea-viewformlist.component';
import { SsbodprojectListComponent } from './project/ssbodproject/ssbodproject-list/ssbodproject-list.component';
import { SsbodNavComponent } from './project/ssbodproject/ssbod/ssbod-nav/ssbod-nav.component';
import { ReferenceComponent } from './project/ssbodproject/ssbod/reference/reference.component';
import { TotalComponent } from './project/ssbodproject/ssbod/total/total.component';
import { IncrementalComponent } from './project/ssbodproject/ssbod/incremental/incremental.component';
import { ForecastComponent } from './project/ssbodproject/ssbod/forecast/forecast.component';
import { ManagementOfChangeComponent } from './project/ssbodproject/ssbod/management-of-change/management-of-change.component';
import { SsbodLessonsLearnedComponent } from './project/ssbodproject/ssbod/ssbod-lessons-learned/ssbod-lessons-learned.component';
import { GeneralComponent } from './project/ssbodproject/ssbod/general/general.component';
import { SubsurfaceBasisComponent } from './project/ssbodproject/ssbod/subsurface-basis/subsurface-basis.component';
import { PurposeAndLeadingAlternativeComponent } from './project/ssbodproject/ssbod/purpose-and-leading-alternative/purpose-and-leading-alternative.component';
import { FacilitiesConceptSummaryComponent } from './project/ssbodproject/ssbod/facilities-concept-summary/facilities-concept-summary.component';
import { ScopeOfWorkComponent } from './project/ssbodproject/ssbod/scope-of-work/scope-of-work.component';
import { DesignParameterRowComponent } from './project/ssbodproject/ssbod/design-parameter-row/design-parameter-row.component';
import { MatListModule } from '@angular/material/list';
import { DeleteprojectComponent } from './project/deleteproject/deleteproject.component';
import { MyprojectformComponent } from './quicklinks/myprojectform/myprojectform.component';
import { MyrsidprojectsComponent } from './quicklinks/myrsidprojects/myrsidprojects.component';
import { MylookbackformsComponent } from './quicklinks/mylookbackforms/mylookbackforms.component';
import { AddNumberPrecisionDirective } from './project/ssbodproject/directive/add-number-precision.directive';
import { RegisteruserComponent } from './registeruser/registeruser.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { Part5LookbackComponent } from './forms/part5-lookback/part5-lookback.component';
import { FormReviewerComponent } from './forms/feed/form-reviewer/form-reviewer.component';
import { SsbodArchiveDialogComponent } from './project/ssbodproject/modal/ssbod-archive-dialog/ssbod-archive-dialog.component';
import { AttachSsbodFormComponent } from './project/ssbodproject/ssbod/attach-ssbod-form/attach-ssbod-form.component';
import { AttachSsbodFormDialogComponent } from './project/ssbodproject/modal/attach-ssbod-form-dialog/attach-ssbod-form-dialog.component';
import { NewArNavComponent } from './project/ea/ea-newform/new-ar-nav/new-ar-nav.component';
import { NewArComponent } from './project/ea/ea-newform/new-ar/new-ar.component';
import { ArPart1Component } from './project/ea/ea-newform/ar-part1/ar-part1.component';
import { ArPart2Component } from './project/ea/ea-newform/ar-part2/ar-part2.component';
import { ArPart3Component } from './project/ea/ea-newform/ar-part3/ar-part3.component';
import { ArFormreviewerComponent } from './project/ea/ea-newform/ar-formreviewer/ar-formreviewer.component';
import { EaPart4LookbackComponent } from './project/ea/forms/ea-part4-lookback/ea-part4-lookback.component';
import { ApprovalDialogComponent } from './Modals/approval-dialog/approval-dialog.component';
import { EaDeleteComponent } from './project/ea/forms/ea-delete/ea-delete.component';
import { OperatingcompanyListComponent } from './admin/asset/operatingcompany/operatingcompany-list/operatingcompany-list.component';
import { OperatingcompanyCreateComponent } from './admin/asset/operatingcompany/operatingcompany-create/operatingcompany-create.component';
import { StrategicbusinesunitCreateComponent } from './admin/asset/strategicbusinessunit/strategicbusinesunit-create/strategicbusinesunit-create.component';
import { StrategicbusinesunitListComponent } from './admin/asset/strategicbusinessunit/strategicbusinesunit-list/strategicbusinesunit-list.component';
import { BusinessunitListComponent } from './admin/asset/businessunit/businessunit-list/businessunit-list.component';
import { BusinessunitCreateComponent } from './admin/asset/businessunit/businessunit-create/businessunit-create.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { ChartsComponent } from './forms/feed/charts/charts.component';
import { ChartModule } from 'primeng/chart';
import { Ng2CompleterModule } from "ng2-completer";
import { MatSelectModule } from '@angular/material/select';
// //import { DropDownListModule, DropDownTreeModule } from '@syncfusion/ej2-angular-dropdowns';
import { SupplementNavComponent } from './project/ea/ea-newform/supplement-nav/supplement-nav.component';
import { SupplementPart1Component } from './project/ea/ea-newform/supplement-part1/supplement-part1.component';
import { SupplementPart2Component } from './project/ea/ea-newform/supplement-part2/supplement-part2.component';
import { SupplementPart3Component } from './project/ea/ea-newform/supplement-part3/supplement-part3.component';
import { UseraccessCreateComponent } from './admin/asset/useraccess/useraccess-create/useraccess-create.component';
import { UseraccessListComponent } from './admin/asset/useraccess/useraccess-list/useraccess-list.component';
import { MatIconModule } from "@angular/material/icon";
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from "@angular/material/checkbox";
// // import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { UseraccessViewComponent } from './admin/asset/useraccess/useraccess-view/useraccess-view.component';
import { DeleteDialogComponent } from './Modals/delete-dialog/delete-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { AlertDialogComponent } from './Modals/alert-dialog/alert-dialog.component';
import { FormSettingsComponent } from './form-settings/form-settings.component'
import { LessonsLearnedDashboardComponent } from './project/go36project/lessons-learned-dashboard/lessons-learned-dashboard.component';
import { LessonsLearnedDashboardNavComponent } from './project/go36project/lessons-learned-dashboard/lessons-learned-dashboard-nav/lessons-learned-dashboard-nav.component';
import { UseraccessEditComponent } from './admin/asset/useraccess/useraccess-edit/useraccess-edit.component';
import { DecimalCommaPipe } from './forms/feed/data-import/decimal-comma.pipe';
import { ReplacePipe } from './forms/feed/data-import/replace.pipe';
import { FeedSupplementChartComponent } from './forms/feed_Supplement/feed-supplement-chart/feed-supplement-chart.component';
import { SupplementFormreviewerComponent } from './project/ea/ea-newform/supplement-formreviewer/supplement-formreviewer.component';
import { SubmitApprovalDialogComponent } from './Modals/submit-approval-dialog/submit-approval-dialog.component';
import { MyearsidprojectsComponent } from './quicklinks/myearsidprojects/myearsidprojects.component';
import { MyeaprojectformsComponent } from './quicklinks/myeaprojectforms/myeaprojectforms.component';
import { MyformsinworkflowComponent } from './quicklinks/myformsinworkflow/myformsinworkflow.component';
import { AuthInterceptor } from './http_interceptors/auth.interceptor';
import { OldTemplateFormsComponent } from './forms/old-template-forms/old-template-forms.component';
import { OldTemplateSupplementComponent } from './forms/old-template-supplement/old-template-supplement.component';
import { ExecutionOldComponent } from './forms/feed/execution/execution-old/execution-old.component';
import { OverviewOldComponent } from './forms/feed/overview/overview-old/overview-old.component';
import { ChartsOldComponent } from './forms/feed/charts/charts-old/charts-old.component';
import { OldformDialogComponent } from './Modals/alertoldform-dialog/oldform-dialog.component';
import { SupplementOverviewOldComponent } from './forms/feed_Supplement/supplement-overview-old/supplement-overview-old.component';
import { FidOverviewOldComponent } from './forms/fid/fid-nav/fid-overview-old/fid-overview-old.component';
import { FidSupplementOverviewOldComponent } from './forms/fid_supplement/fid-supplement-nav/fid-supplement-overview-old/fid-supplement-overview-old.component';

import { CalAngularService } from '@cvx/cal-angular';
import { AuthenticationInitializerService } from './Shared/authentication-initializer.service';
import { AlertFormexistDialogComponent } from './Modals/alert-formexist-dialog/alert-formexist-dialog.component';
import { UserprofileComponent } from './admin/asset/userprofile/userprofile.component';

export function AccessTokenInitializer(authInitService :AuthenticationInitializerService){
  let scopes = environment.scopes;
  return () => {
    return authInitService.checkAccount().then((isUserLoggedIn:boolean) => {
      if(isUserLoggedIn){
        return authInitService.getCALAccessToken().then(()=>{
          // console.log("Token Renewed");
        })
      }else{
        return;
      }
    })
  }
}


@NgModule({
    declarations: [
        AppComponent,
        AssetListComponent,
        LayoutComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        AssetCreateComponent,
        routingComponents,
        Go36projectComponent,
        EaComponent,
        Go36projectCreateComponent,
        Go36projectlistComponent,
        UseraccessComponent,
        ViewformlistComponent,
        OverviewComponent,
        FeedNavComponent,
        ViewformlistComponent,
        RefreshDialogComponent,
        BusinessCaseComponent,
        BasisOfEvaluationComponent,
        ExecutionComponent,
        EaProjectListComponent,
        EaProjectCreateComponent,
        EAUseraccessComponent,
        EAUseraccessComponent,
        CopyProjectComponent,
        ConfirmDialogComponent,
        CommentDialogComponent,
        InfoDialogComponent,
        DataImportComponent,
        FeedDialogComponent,
        AlternativeSelectionComponent,
        LoaderComponent,
        PopupActiveUsersComponent,
        RisksComponent,
        DeleteComponent,
        ProjectDocumentationComponent,
        ArchiveDialogComponent,
        ProjectDocumentationComponent,
        FeedSupplementNavComponent,
        FidNavComponent,
        FidSupplementNavComponent,
        SupplementOverviewComponent,
        LessonsLearnedComponent,
        LessonlearnedDialogComponent,
        FeedSupplementRisksComponent,
        FidOverviewComponent,
        FidSupplementOverviewComponent,
        LessonlearnedDialogComponent,
        LessonsLearnedNavComponent,
        Part4LookBackNavComponent,
        EaViewformlistComponent,
        SsbodprojectListComponent,
        SsbodNavComponent,
        ReferenceComponent,
        TotalComponent,
        IncrementalComponent,
        ForecastComponent,
        ManagementOfChangeComponent,
        SsbodLessonsLearnedComponent,
        GeneralComponent,
        SubsurfaceBasisComponent,
        PurposeAndLeadingAlternativeComponent,
        FacilitiesConceptSummaryComponent,
        ScopeOfWorkComponent,
        DesignParameterRowComponent,
        DeleteprojectComponent,
        MyprojectformComponent,
        MyrsidprojectsComponent,
        MylookbackformsComponent,
        AddNumberPrecisionDirective,
        RegisteruserComponent,
        AddNumberPrecisionDirective,
        Part5LookbackComponent,
        FormReviewerComponent,
        SsbodArchiveDialogComponent,
        AttachSsbodFormComponent,
        AttachSsbodFormDialogComponent,
        NewArNavComponent,
        NewArComponent,
        ArPart1Component,
        ArPart2Component,
        ArPart3Component,
        ArFormreviewerComponent,
        EaPart4LookbackComponent,
        ApprovalDialogComponent,
        EaDeleteComponent,
        OperatingcompanyListComponent,
        OperatingcompanyCreateComponent,
        ChartsComponent,
        StrategicbusinesunitCreateComponent,
        StrategicbusinesunitListComponent,
        BusinessunitListComponent,
        BusinessunitCreateComponent,
        ErrorpageComponent,
        UseraccessCreateComponent,
        UseraccessListComponent,
        SupplementNavComponent,
        SupplementPart1Component,
        SupplementPart2Component,
        SupplementPart3Component,
        AlertDialogComponent,
        FormSettingsComponent,
        LessonsLearnedDashboardComponent,
        LessonsLearnedDashboardNavComponent,
        UseraccessViewComponent,
        DeleteDialogComponent,
        UseraccessEditComponent,
        DecimalCommaPipe,
        ReplacePipe,
        FeedSupplementChartComponent,
        SupplementFormreviewerComponent,
        SubmitApprovalDialogComponent,
        MyearsidprojectsComponent,
        MyeaprojectformsComponent,
        MyformsinworkflowComponent,
        OldTemplateFormsComponent,
        OldTemplateSupplementComponent,
        ExecutionOldComponent,
        OverviewOldComponent,
        ChartsOldComponent,
        OldformDialogComponent,
        SupplementOverviewOldComponent,
        FidOverviewOldComponent,
        FidSupplementOverviewOldComponent,
        AlertFormexistDialogComponent,
        UserprofileComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        DataTablesModule,
        TableModule,
        ButtonModule,
        BrowserAnimationsModule,
        RippleModule,
        AutoCompleteModule,
        MatDialogModule,
        MatTableModule,
        MatTabsModule,
        CKEditorModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        Ng2CompleterModule,
        MatMenuModule,
        MatIconModule,
        CalAngularModule.forRoot({
            "autoSignIn": true,
            "popupForLogin": false,
            "instance": environment.instance,
            "tenantId": environment.tenantId,
            "clientId": environment.clientId,
            "redirectUri": environment.redirectUri,
            "oidcScopes": ["openid", "profile", "User.Read", "offline_access"],
            "graphScopes": ["User.Read", "Directory.Read.All"],
            "cacheLocation": "localStorage"
        }),
        // // CalAngularModule.forRoot('model/config.json'),
        DialogModule,
        FormsModule,
        MatSidenavModule,
        ToastrModule.forRoot(),
        MatListModule,
        MatExpansionModule,
        MatSelectModule,
        MatIconModule,
        MatTreeModule,
        MatCheckboxModule,
        ChartModule
    ],
    providers: [BnNgIdleService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: APP_INITIALIZER, useFactory: AccessTokenInitializer, multi: true, deps: [AuthenticationInitializerService] },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true, }
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private primengConfig: PrimeNGConfig) { }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
