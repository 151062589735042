<div class="fid-form">
    <div class="row sub-section" *ngIf="form.formType !== 'LLE' && form.formType !== 'EPC'">
        <div class="col-xs-12">
            <strong>7.1 Risks</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowRisksModal()"></span>
        </div>
        <div class="col-xs-12">
            <table class="table table-bordered">
                <tbody>
                    <tr class="table-header">
                        <td colspan="8" class="bg-table-default">
                            <i>
                                Clearly describe relevant risks and any potential mitigations. Consider risks around
                                cost, key liabilities that could lead to
                                cost escalation, schedule, critical path items to meet timeline, foreign exchange
                                exposure, labor, OC, OE, subsurface,
                                project execution, new technology, regulatory, permitting, geopolitical, cultural,
                                commercial, co-owner alignment,
                                other stakeholders, greenhouse gas, commodity pricing, other business environmental
                                effects, key assumptions that
                                are critical to business success, etc.
                                <br>Add additional rows, if needed. Include all major risk items identified in assurance
                                reviews.
                            </i>
                        </td>
                    </tr>
                    <tr class="bg-table-default">
                        <td width="15%" class="text-center">Risks</td>
                        <td class="text-center">Mitigations(if any)</td>
                        <td width="8%" class="text-center">Included in economics?</td>
                        <td width="15%" class="text-center">If yes, how?</td>
                        <td width="15%" class="text-center">Potential impact</td>
                        <td width="8%" class="text-center">Identified in Assurance Review</td>
                        <td width="15%" class="text-center">Review Type</td>
                        <td class="trash-icon"></td>
                    </tr>
                    <tr *ngFor="let risk of risks let i = index">
                        <td [(ngModel)]="risk.id" hidden class="ml-10">{{risk.id}}</td>
                        <td>
                            <textarea rows="3" type="text" class="form-control"
                                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                [(ngModel)]="risk.riskName" (change)="UpdateRisksData(risk)">
                            </textarea>
                        </td>
                        <td>
                            <textarea rows="3" type="text" class="form-control"
                                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                [(ngModel)]="risk.mitigation" (change)="UpdateRisksData(risk)">
                            </textarea>
                        </td>
                        <td class="text-center">
                            <input type="checkbox" class="form-check-input" id="chkIncludedInEconomics"
                                [(ngModel)]="risk.includedInEconomics"
                                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                [checked]="risk.includedInEconomics" (change)="IncludedInEconomicsCheck($event, risk)">
                        </td>
                        <td>
                            <textarea rows="3" type="text" class="form-control"
                                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                [(ngModel)]="risk.reflectedInEconomics" (change)="UpdateRisksData(risk)">
                            </textarea>
                        </td>
                        <td>
                            <textarea rows="3" type="text" class="form-control"
                                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                [(ngModel)]="risk.potentialImpact" (change)="UpdateRisksData(risk)">
                            </textarea>
                        </td>
                        <td class="text-center">
                            <input type="checkbox" class="form-check-input" id="chkIdentifiedInAssuranceReview"
                                [(ngModel)]="risk.identifiedInAssuranceReview"
                                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                [checked]="risk.identifiedInAssuranceReview"
                                (change)="IdentifiedInAssuranceReviewCheck($event, risk)">
                        </td>
                        <td>
                            <textarea rows="3" type="text" class="form-control"
                                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                [(ngModel)]="risk.reviewType" (change)="UpdateRisksData(risk)">
                            </textarea>
                        </td>
                        <td>
                            <div [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <i class="glyphicon glyphicon-comment-outline" *ngIf="!risk.comment"
                                    (click)="SaveCommentForRisks(risk)"></i>
                                <i class="glyphicon glyphicon-comment commentColor" *ngIf="risk.comment"
                                    (click)="SaveCommentForRisks(risk)"></i>
                            </div>
                            <br>
                            <div *ngIf="!isOnHold || !form.isReadOnly"
                                [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <i class="glyphicon glyphicon-trash glyphicon-custom-trash trash-button"
                                    (click)="DeleteRisk(risk, i)"></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-xs-12">
            <button type="button" class="btn btn-sm btn-primary" (click)="AddNewRisk()"
                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                style="color: white !important;">Add New Row</button>
        </div>
    </div>

    <div class="row sub-section">
        <div class="col-xs-12">
            <strong>7.2 Economic Sensitivity - Impact of Largest Uncertainties on FEED Forward Project Economic
                Returns</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowEcoSensitivityModal()"></span>
        </div>

        <div class="col-xs-12 col-sm-6" *ngIf="form.formType !== 'EPC'">
            <div class="row padding-bottom-10">
                <div class="col-xs-12">
                    <span class="image-label">NPV S-Curves at 3 price scenarios: (FEED Forward) </span>
                </div>
                <div class="col-xs-12"
                    [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
                    <span>
                        <ckeditor #netPresentValueScurveImage [editor]="Editor" [config]="config"
                            id="netPresentValueScurveImage" name="netPresentValueScurveImage"
                            [(ngModel)]="formRisks.netPresentValueScurveImage" [disabled]="isOnHold"
                            (ready)="onReady($event,'netPresentValueScurveImage')">
                        </ckeditor>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-xs-12 col-sm-6">
            <div class="row padding-bottom-10">
                <div class="col-xs-12">
                    <span class="image-label">NPV Tornado : (FEED Forward)</span>
                </div>
                <div class="col-xs-12"
                    [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
                    <span>
                        <ckeditor #netPresentValueTornadoImage [editor]="Editor" [config]="config"
                            id="netPresentValueTornadoImage" name="netPresentValueTornadoImage"
                            [(ngModel)]="formRisks.netPresentValueTornadoImage" [disabled]="isOnHold"
                            (ready)="onReady($event,'netPresentValueTornadoImage')">
                        </ckeditor>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-xs-12 col-sm-6" *ngIf="form.IsOldVersionOfForms">
            <div class="row padding-bottom-10">
                <div class="col-xs-12">
                    <span class="image-label">DPI S-Curves at 3 price scenarios : (FEED Forward) </span>
                </div>
                <div class="col-xs-12"
                    [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
                    <span>
                        <ckeditor #discountedProfitabilityIndexScurveImage [editor]="Editor" [config]="config"
                            id="discountedProfitabilityIndexScurveImage" name="discountedProfitabilityIndexScurveImage"
                            [(ngModel)]="formRisks.discountedProfitabilityIndexScurveImage" [disabled]="isOnHold"
                            (ready)="onReady($event,'discountedProfitabilityIndexScurveImage')">
                        </ckeditor>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-xs-12" style="margin-top: 10px;" *ngIf="form.formType !== 'EPC'">
            <strong>Deterministic sensitivities around price and largest uncertainty</strong>
        </div>
        <div class="col-xs-12" *ngIf="form.formType !== 'EPC'">
            <table class="table table-bordered">
                <tbody>
                    <tr rowspan="9" class="table-header bg-table-default">
                        <div class="row form-group">
                            <div class="col-sm-3 col-xs-12">
                                <span class="col-sm-5 col-xs-12" style="padding-top: 10px;">
                                    Largest uncertainty:
                                </span>
                                <span class="col-sm-7 col-xs-12" style="text-align:left">
                                    <input type="text" value="EUR(mboe)" class="form-control"
                                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                        [(ngModel)]="formRisks.economicSensitivityEurTitle"
                                        (change)="UpdateFormRisksData(formRisks)">
                                </span>
                            </div>
                            <div class="col-sm-3 col-xs-12">
                                <span class="col-sm-4 col-xs-12" style="padding-top: 10px;">
                                    P10 value:
                                </span>
                                <span class="col-sm-6 col-xs-12" style="text-align:left">
                                    <div class="input-group">
                                        <input class="form-control" type="number"
                                            [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                            [(ngModel)]="formRisks.netPresentValueSensitivityLow"
                                            (change)="UpdateFormRisksData(formRisks)">
                                        <div class="input-group-addon"
                                            [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                            <i class="glyphicon glyphicon-comment-outline"
                                                *ngIf="!formRisks.netPresentValueSensitivityLowComment"
                                                (click)="SaveCommentForFormRisks(formRisks,formRisks.netPresentValueSensitivityLowComment,'netPresentValueSensitivityLowComment')">
                                            </i>
                                            <i class="glyphicon glyphicon-comment commentColor"
                                                *ngIf="formRisks.netPresentValueSensitivityLowComment"
                                                (click)="SaveCommentForFormRisks(formRisks,formRisks.netPresentValueSensitivityLowComment,'netPresentValueSensitivityLowComment')">
                                            </i>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div class="col-sm-3 col-xs-12">
                                <span class="col-sm-4 col-xs-12" style="padding-top: 10px;">
                                    P50 value:
                                </span>
                                <span class="col-sm-6 col-xs-12" style="text-align:left">
                                    <div class="input-group">
                                        <input class="form-control" type="number"
                                            [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                            [(ngModel)]="formRisks.netPresentValueSensitivityMid"
                                            (change)="UpdateFormRisksData(formRisks)">
                                        <div class="input-group-addon"
                                            [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                            <i class="glyphicon glyphicon-comment-outline"
                                                *ngIf="!formRisks.netPresentValueSensitivityMidComment"
                                                (click)="SaveCommentForFormRisks(formRisks,formRisks.netPresentValueSensitivityMidComment,'netPresentValueSensitivityMidComment')">
                                            </i>
                                            <i class="glyphicon glyphicon-comment commentColor"
                                                *ngIf="formRisks.netPresentValueSensitivityMidComment"
                                                (click)="SaveCommentForFormRisks(formRisks,formRisks.netPresentValueSensitivityMidComment,'netPresentValueSensitivityMidComment')">
                                            </i>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div class="col-sm-3 col-xs-12">
                                <span class="col-sm-4 col-xs-12" style="padding-top: 10px;">
                                    P90 value:
                                </span>
                                <span class="col-sm-6 col-xs-12" style="text-align:left">
                                    <div class="input-group">
                                        <input class="form-control" type="number"
                                            [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                            [(ngModel)]="formRisks.netPresentValueSensitivityHigh"
                                            (change)="UpdateFormRisksData(formRisks)">
                                        <div class="input-group-addon"
                                            [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                            <i class="glyphicon glyphicon-comment-outline"
                                                *ngIf="!formRisks.netPresentValueSensitivityHighComment"
                                                (click)="SaveCommentForFormRisks(formRisks,formRisks.netPresentValueSensitivityHighComment,'netPresentValueSensitivityHighComment')">
                                            </i>
                                            <i class="glyphicon glyphicon-comment commentColor"
                                                *ngIf="formRisks.netPresentValueSensitivityHighComment"
                                                (click)="SaveCommentForFormRisks(formRisks,formRisks.netPresentValueSensitivityHighComment,'netPresentValueSensitivityHighComment')">
                                            </i>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- NPV Sensitivity -->
        <div class="col-xs-12 col-sm-5 risk-section" style="padding-right: 0px !important;"
            *ngIf="form.formType !== 'EPC'">
            <table class="table table-bordered">
                <tbody>
                    <tr class="table-header bg-table-default">
                        <td colspan="4" style="text-align: center;">NPV Sensitivity ($MM)</td>
                    </tr>
                    <tr class="table-header bg-table-default">
                        <td></td>
                        <td class="text-center">Low (P10)</td>
                        <td class="text-center">Mid (P50)</td>
                        <td class="text-center">High (P90)</td>
                    </tr>
                    <tr *ngFor="let npv of NPV let i = index">
                        <td [(ngModel)]="npv.id" hidden class="ml-10">{{npv.id}}</td>
                        <td style="width: 19%;">{{npv.title}}</td>
                        <td>
                            <div class="input-group">
                                <div class="input-icon" style="display:flex;" *ngIf="!form.IsOldVersionOfForms; else noDollarnpvlowPrice">
                                    <input class="form-control" type="number"
                                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                        [(ngModel)]="npv.lowPrice" (change)="UpdateEcoSensitivityData(npv)">
                                    <i style="z-index: 1111;">$</i>  
                                </div> 
                                <ng-template #noDollarnpvlowPrice>
                                    <input class="form-control" type="number"
                                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                        [(ngModel)]="npv.lowPrice" (change)="UpdateEcoSensitivityData(npv)">
                                </ng-template>
                                <div class="input-group-addon"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!npv.lowPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(npv,npv.lowPriceComment,'lowPriceComment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="npv.lowPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(npv,npv.lowPriceComment,'lowPriceComment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="input-group">
                                <div class="input-icon" style="display:flex;" *ngIf="!form.IsOldVersionOfForms; else noDollarnpvmidPrice">
                                    <input class="form-control" readonly="" type="number"
                                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                        [(ngModel)]="npv.midPrice" (change)="UpdateEcoSensitivityData(npv)">
                                    <i style="z-index: 1111;">$</i>  
                                </div> 
                                <ng-template #noDollarnpvmidPrice>
                                    <input class="form-control" readonly="" type="number"
                                    [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                    [(ngModel)]="npv.midPrice" (change)="UpdateEcoSensitivityData(npv)">
                                </ng-template>
                                <div class="input-group-addon"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!npv.midPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(npv,npv.midPriceComment,'midPriceComment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="npv.midPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(npv,npv.midPriceComment,'midPriceComment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group">
                                <div class="input-icon" style="display:flex;" *ngIf="!form.IsOldVersionOfForms; else noDollarnpvhighPrice">
                                    <input class="form-control" type="number"
                                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                        [(ngModel)]="npv.highPrice" (change)="UpdateEcoSensitivityData(npv)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarnpvhighPrice>
                                    <input class="form-control" type="number"
                                    [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                    [(ngModel)]="npv.highPrice" (change)="UpdateEcoSensitivityData(npv)">
                                </ng-template>
                                <div class="input-group-addon"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!npv.highPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(npv,npv.highPriceComment,'highPriceComment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="npv.highPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(npv,npv.highPriceComment,'highPriceComment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- DPI Sensitivity -->
        <div class="col-xs-12 col-sm-3 risk-section" *ngIf="form.formType !== 'EPC'"
            style="padding-right: 0px !important; padding-left: 0px !important;">
            <table class="table table-bordered">
                <tbody>
                    <tr class="table-header bg-table-default">
                        <td colspan="4" style="text-align: center;">DPI Sensitivity</td>
                    </tr>
                    <tr class="table-header bg-table-default">
                        <td class="text-center">Low (P10)</td>
                        <td class="text-center">Mid (P50)</td>
                        <td class="text-center">High (P90)</td>
                    </tr>
                    <tr *ngFor="let dpi of DPI let i = index">
                        <td [(ngModel)]="dpi.id" hidden class="ml-10">{{dpi.id}}</td>
                        <td>
                            <div class="input-group">
                                <input class="form-control" type="number"
                                    [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                    [(ngModel)]="dpi.lowPrice" (change)="UpdateEcoSensitivityDataDPI(dpi)">
                                <div class="input-group-addon"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!dpi.lowPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(dpi,dpi.lowPriceComment,'lowPriceComment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="dpi.lowPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(dpi,dpi.lowPriceComment,'lowPriceComment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="input-group">
                                <input class="form-control" readonly="" type="number"
                                    [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                    [(ngModel)]="dpi.midPrice" (change)="UpdateEcoSensitivityDataDPI(dpi)">
                                <div class="input-group-addon"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!dpi.midPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(dpi,dpi.midPriceComment,'midPriceComment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="dpi.midPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(dpi,dpi.midPriceComment,'midPriceComment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group">
                                <input class="form-control" type="number"
                                    [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                    [(ngModel)]="dpi.highPrice" (change)="UpdateEcoSensitivityDataDPI(dpi)">
                                <div class="input-group-addon"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!dpi.highPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(dpi,dpi.highPriceComment,'highPriceComment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="dpi.highPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(dpi,dpi.highPriceComment,'highPriceComment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- ROR Sensitivity (%) -->
        <div class="col-xs-12 col-sm-4 risk-section" style="padding-left: 0px !important;"
            *ngIf="form.formType !== 'EPC'">
            <table class="table table-bordered">
                <tbody>
                    <tr class="table-header bg-table-default">
                        <td colspan="4" style="text-align: center;">ROR Sensitivity (%)</td>
                    </tr>
                    <tr class="table-header bg-table-default">
                        <td class="text-center">Low (P10)</td>
                        <td class="text-center">Mid (P50)</td>
                        <td class="text-center">High (P90)</td>
                    </tr>
                    <tr *ngFor="let ror of ROR let i = index">
                        <td [(ngModel)]="ror.id" hidden class="ml-10">{{ror.id}}</td>
                        <td>
                            <div class="input-group">
                                <input class="form-control"
                                    [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                    [(ngModel)]="ror.lowPrice" (change)="UpdateEcoSensitivityDataROR(ror)">
                                <div class="input-group-addon"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!ror.lowPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(ror,ror.lowPriceComment,'lowPriceComment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="ror.lowPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(ror,ror.lowPriceComment,'lowPriceComment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="input-group">
                                <input class="form-control" type="text" readonly=""
                                    [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                    [(ngModel)]="ror.midPrice" (change)="UpdateEcoSensitivityDataROR(ror)">
                                <div class="input-group-addon"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!ror.midPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(ror,ror.midPriceComment,'midPriceComment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="ror.midPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(ror,ror.midPriceComment,'midPriceComment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group">
                                <input class="form-control"
                                    [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                    [(ngModel)]="ror.highPrice" (change)="UpdateEcoSensitivityDataROR(ror)">
                                <div class="input-group-addon"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!ror.highPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(ror,ror.highPriceComment,'highPriceComment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="ror.highPriceComment"
                                        (click)="SaveCommentForEcoSensitivity(ror,ror.highPriceComment,'highPriceComment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- 7.3 -->
        <div class="sub-section" *ngIf="form.formType !== 'EPC'">
            <div class="col-xs-12">
                <strong *ngIf="!form.IsOldVersionOfForms">Project Sensitivity:</strong>
                <strong *ngIf="form.IsOldVersionOfForms">7.3 Project Sensitivity:</strong>
                <span class="glyphicon glyphicon-question-sign" (click)="ShowProjectSensitivityModal()"></span>
            </div>
            <div class="col-xs-12">
                <table class="table table-bordered margin-bottom-0">
                    <tbody>
                        <tr class="table-header">
                            <td colspan="4">
                                <div style="display: flex; flex-direction: row">
                                    <label class="padding-top-5 padding-right-5">Project has a</label>
                                    <div>
                                        <div class="input-group">
                                            <input class="form-control" [(ngModel)]="formRisks.npvFullCycle"
                                                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                                (change)="UpdateFormRisksData(formRisks)">
                                            <div class="input-group-addon"
                                                [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnlyGlobalProjectLevel}">
                                                <i class="glyphicon glyphicon-comment-outline"
                                                    *ngIf="!formRisks.npvFullCycleComment"
                                                    (click)="SaveCommentForFormRisks(formRisks,formRisks.npvFullCycleComment,'npvFullCycleComment')">
                                                </i>
                                                <i class="glyphicon glyphicon-comment commentColor"
                                                    *ngIf="formRisks.npvFullCycleComment"
                                                    (click)="SaveCommentForFormRisks(formRisks,formRisks.npvFullCycleComment,'npvFullCycleComment')">
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <label class="padding-top-5 padding-left-5">chance of having an NPV &lt;0 in the
                                        mid-price scenario</label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- 7.4 -->
        <div class="sub-section" *ngIf="form.formType !== 'EPC'">
            <div class="col-xs-12" style="margin-top: 15px;">
                <strong *ngIf="!form.IsOldVersionOfForms">Breakeven Sensitivities for NPV10 = 0</strong>
                <strong *ngIf="form.IsOldVersionOfForms">7.4 Breakeven Sensitivities for NPV10 = 0</strong>
                <span class="glyphicon glyphicon-question-sign" (click)="ShowBreakevenSensitivityModal()"></span>
            </div>
            <div class="col-xs-12">
                <table class="table table-bordered">
                    <tbody>
                        <tr class="table-header">
                            <td></td>
                            <td>Point Forward</td>
                            <td>Commodity (e.g. Brent, WTI, HH) Varied</td>
                        </tr>
                        <tr>
                            <td>Breakeven Price or Margin</td>
                            <td>
                                <div class="input-group">
                                    <div class="input-icon" style="display:flex;" *ngIf="!form.IsOldVersionOfForms; else noDollarflatRealPointForward">
                                        <input class="form-control" [(ngModel)]="formRisks.flatRealPointForward"
                                            [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                            (change)="UpdateFormRisksData(formRisks)">
                                        <i style="z-index: 1111;">$</i>  
                                    </div>  
                                    <ng-template #noDollarflatRealPointForward>
                                        <input class="form-control" [(ngModel)]="formRisks.flatRealPointForward"
                                            [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                            (change)="UpdateFormRisksData(formRisks)">
                                    </ng-template>
                                    <div class="input-group-addon"
                                        [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnlyGlobalProjectLevel}">
                                        <i class="glyphicon glyphicon-comment-outline"
                                            *ngIf="!formRisks.flatRealPointForwardComment"
                                            (click)="SaveCommentForFormRisks(formRisks,formRisks.flatRealPointForwardComment,'flatRealPointForwardComment')">
                                        </i>
                                        <i class="glyphicon glyphicon-comment commentColor"
                                            *ngIf="formRisks.flatRealPointForwardComment"
                                            (click)="SaveCommentForFormRisks(formRisks,formRisks.flatRealPointForwardComment,'flatRealPointForwardComment')">
                                        </i>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" class="form-control" [(ngModel)]="formRisks.commodityTestedBy"
                                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                        (change)="UpdateFormRisksData(formRisks)">
                                    <div class="input-group-addon"
                                        [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnlyGlobalProjectLevel}">
                                        <i class="glyphicon glyphicon-comment-outline"
                                            *ngIf="!formRisks.commodityTestedByComment"
                                            (click)="SaveCommentForFormRisks(formRisks,formRisks.commodityTestedByComment,'commodityTestedByComment')">
                                        </i>
                                        <i class="glyphicon glyphicon-comment commentColor"
                                            *ngIf="formRisks.commodityTestedByComment"
                                            (click)="SaveCommentForFormRisks(formRisks,formRisks.commodityTestedByComment,'commodityTestedByComment')">
                                        </i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="table-header" *ngIf="form.IsOldVersionOfForms && form.formType !== 'Phase 3 to 4 (FID)'">
                            <td></td>
                            <td>FEED Forward</td>
                            <td>Commodity (e.g. Brent, WTI, HH) Varied</td>
                        </tr>
                        <tr *ngIf="form.IsOldVersionOfForms && (form.formType !== 'Phase 3 to 4 (FID)')">
                            <td></td>
                            <td>
                                <div class="input-group">
                                    <input class="form-control" [(ngModel)]="formRisks.flatRealFeedForward"
                                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                        (change)="UpdateFormRisksData(formRisks)">
                                    <div class="input-group-addon"
                                        [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnlyGlobalProjectLevel}">
                                        <i class="glyphicon glyphicon-comment-outline"
                                            *ngIf="!formRisks.flatRealFeedForwardComment"
                                            (click)="SaveCommentForFormRisks(formRisks,formRisks.flatRealFeedForwardComment,'flatRealFeedForwardComment')">
                                        </i>
                                        <i class="glyphicon glyphicon-comment commentColor"
                                            *ngIf="formRisks.flatRealFeedForwardComment"
                                            (click)="SaveCommentForFormRisks(formRisks,formRisks.flatRealFeedForwardComment,'flatRealFeedForwardComment')">
                                        </i>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" class="form-control"
                                        [(ngModel)]="formRisks.feedForwardCommodityTestedBy"
                                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                        (change)="UpdateFormRisksData(formRisks)">
                                    <div class="input-group-addon"
                                        [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnlyGlobalProjectLevel}">
                                        <i class="glyphicon glyphicon-comment-outline"
                                            *ngIf="!formRisks.feedForwardCommodityTestedByComment"
                                            (click)="SaveCommentForFormRisks(formRisks,formRisks.feedForwardCommodityTestedByComment,'feedForwardCommodityTestedByComment')">
                                        </i>
                                        <i class="glyphicon glyphicon-comment commentColor"
                                            *ngIf="formRisks.feedForwardCommodityTestedByComment"
                                            (click)="SaveCommentForFormRisks(formRisks,formRisks.feedForwardCommodityTestedByComment,'feedForwardCommodityTestedByComment')">
                                        </i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="table-header" *ngIf="!form.IsOldVersionOfForms">
                            <td></td>
                            <td>FEED Forward</td>
                            <td>Commodity (e.g. Brent, WTI, HH) Varied</td>
                        </tr>
                        <tr *ngIf="!form.IsOldVersionOfForms">
                            <td></td>
                            <td>
                                <div class="input-group">
                                    <div class="input-icon" style="display:flex;" *ngIf="!form.IsOldVersionOfForms; else noDollarflatRealFeedForward">
                                        <input class="form-control" [(ngModel)]="formRisks.flatRealFeedForward"
                                            [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                            (change)="UpdateFormRisksData(formRisks)">
                                        <i style="z-index: 1111;">$</i>  
                                    </div>
                                    <ng-template #noDollarflatRealFeedForward>
                                        <input class="form-control" [(ngModel)]="formRisks.flatRealFeedForward"
                                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                        (change)="UpdateFormRisksData(formRisks)">
                                    </ng-template>
                                    <div class="input-group-addon"
                                        [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnlyGlobalProjectLevel}">
                                        <i class="glyphicon glyphicon-comment-outline"
                                            *ngIf="!formRisks.flatRealFeedForwardComment"
                                            (click)="SaveCommentForFormRisks(formRisks,formRisks.flatRealFeedForwardComment,'flatRealFeedForwardComment')">
                                        </i>
                                        <i class="glyphicon glyphicon-comment commentColor"
                                            *ngIf="formRisks.flatRealFeedForwardComment"
                                            (click)="SaveCommentForFormRisks(formRisks,formRisks.flatRealFeedForwardComment,'flatRealFeedForwardComment')">
                                        </i>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" class="form-control"
                                        [(ngModel)]="formRisks.feedForwardCommodityTestedBy"
                                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                        (change)="UpdateFormRisksData(formRisks)">
                                    <div class="input-group-addon"
                                        [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnlyGlobalProjectLevel}">
                                        <i class="glyphicon glyphicon-comment-outline"
                                            *ngIf="!formRisks.feedForwardCommodityTestedByComment"
                                            (click)="SaveCommentForFormRisks(formRisks,formRisks.feedForwardCommodityTestedByComment,'feedForwardCommodityTestedByComment')">
                                        </i>
                                        <i class="glyphicon glyphicon-comment commentColor"
                                            *ngIf="formRisks.feedForwardCommodityTestedByComment"
                                            (click)="SaveCommentForFormRisks(formRisks,formRisks.feedForwardCommodityTestedByComment,'feedForwardCommodityTestedByComment')">
                                        </i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="table-header">
                            <td colspan="3">
                                <div style="display: flex; flex-direction: row">
                                  <label class="padding-top-5 padding-right-5">
                                    Remaining {{ (form.features?.isNewCEisApplicableForms || form.isNewCEisApplicableForms) ? "Capex": "C&amp;E" }} costs must be multiplied by a factor of
                                  </label>
                                    <div>
                                        <div class="input-group">
                                            <input class="form-control" [(ngModel)]="formRisks.remainingCostIncrease"
                                                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                                (change)="UpdateFormRisksData(formRisks)">
                                            <div class="input-group-addon"
                                                [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnlyGlobalProjectLevel}">
                                                <i class="glyphicon glyphicon-comment-outline"
                                                    *ngIf="!formRisks.remainingCostIncreaseComment"
                                                    (click)="SaveCommentForFormRisks(formRisks,formRisks.remainingCostIncreaseComment,'remainingCostIncreaseComment')">
                                                </i>
                                                <i class="glyphicon glyphicon-comment commentColor"
                                                    *ngIf="formRisks.remainingCostIncreaseComment"
                                                    (click)="SaveCommentForFormRisks(formRisks,formRisks.remainingCostIncreaseComment,'remainingCostIncreaseComment')">
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <label class="padding-top-5 padding-left-5">to get NPV10=0 with all other inputs at
                                        the
                                        mid-case.</label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="row sub-section" *ngIf="form.formType !== 'LLE' && form.formType !== 'EPC'">
        <div class="col-xs-12">
            <strong *ngIf="!form.IsOldVersionOfForms">7.3 Insights of Economic Risk Analysis</strong>
            <strong *ngIf="form.IsOldVersionOfForms">7.5 Insights of Economic Risk Analysis</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowInsightsModal()"></span>
        </div>
        <div class="col-xs-12"
            [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <span>
                <ckeditor #riskUncertainty [editor]="Editor" [config]="config" id="riskUncertainty"
                    name="riskUncertainty" [(ngModel)]="formRisks.riskUncertainty"
                    [disabled]="isOnHold || isReadOnlyGlobalProjectLevel" (ready)="onReady($event,'riskUncertainty')">
                </ckeditor>
            </span>
        </div>
    </div>

    <div class="row sub-section" *ngIf="form.formType !== 'LLE' && form.formType !== 'EPC'">
        <div class="col-xs-12">
            <span>
                <strong *ngIf="!form.IsOldVersionOfForms">7.4 Novelty Risk</strong>
                <strong *ngIf="form.IsOldVersionOfForms && form.formType !== 'Phase 3 to 4 (FID)'">7.6 Novelty Risk</strong>
                <span class="glyphicon glyphicon-question-sign" (click)="ShowNoveltyRiskModal()"></span>
                <!--*ngIf="form.IsOldVersionOfForms && form.formType !== 'Phase 3 to 4 (FID)'">-->
            </span>
            <div class="row pl-16" *ngIf="!form.IsOldVersionOfForms">
                <span>
                    <mat-slide-toggle (change)="NANoveltyRiskEventCheck($event, formRisks)"
                        [checked]="formRisks.nanoveltyRisk"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                        <span class="app-font">N/A</span>
                    </mat-slide-toggle>
                </span>
            </div>
        </div>
        <div class="col-xs-12" *ngIf="!formRisks.nanoveltyRisk"
            [ngClass]="{'disabledNoOfCasesDiv': isOnHold || formRisks.nanoveltyRisk || form.isReadOnly}">
            <span *ngIf="form.IsOldVersionOfForms && form.formType !== 'Phase 3 to 4 (FID)'">
                <ckeditor #noveltyRisk [editor]="Editor" [config]="config" id="noveltyRisk" name="noveltyRisk"
                    [(ngModel)]="formRisks.noveltyRisk" [disabled]="isOnHold || isReadOnlyGlobalProjectLevel"
                    (ready)="onReady($event,'noveltyRisk')">
                </ckeditor>
            </span>
        </div>
        <div class="col-xs-12" *ngIf="!formRisks.nanoveltyRisk"
            [ngClass]="{'disabledNoOfCasesDiv': isOnHold || formRisks.nanoveltyRisk || form.isReadOnly}">
            <span *ngIf="!form.IsOldVersionOfForms">
                <ckeditor #noveltyRisk [editor]="Editor" [config]="config" id="noveltyRisk" name="noveltyRisk"
                    [(ngModel)]="formRisks.noveltyRisk" [disabled]="isOnHold || isReadOnlyGlobalProjectLevel"
                    (ready)="onReady($event,'noveltyRisk')">
                </ckeditor>
            </span>
        </div>
    </div>
</div>
