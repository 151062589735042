import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'src/app/Shared/utility.service';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';

@Component({
  selector: 'app-myformsinworkflow',
  templateUrl: './myformsinworkflow.component.html',
  styleUrls: ['./myformsinworkflow.component.css']
})
export class MyformsinworkflowComponent implements OnInit {
  role: string = '';
  objectId: string = '';
  provId: string = '';
  formData: any;
  projectlength: number = 0;

  constructor(private route: ActivatedRoute, private go36ProjectService: Go36projectService,public utilityService: UtilityService) { }

  ngOnInit(): void {
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.route.queryParams
      .subscribe(params => {
        this.role = params['role'];
        this.getFormsInWorkFlow();
      });
  }
  
  getFormsInWorkFlow() {
    this.route.queryParams
      .subscribe(params => {
        this.go36ProjectService.getFormsInWorkFlow(this.role, this.objectId, this.provId).subscribe((res) => {
          this.formData = res;
          this.projectlength = this.formData.length;
          this.onShowEntryDropdownChange({ first: 0, rows: this.projectlength });
        });
      })
  }

  onShowEntryDropdownChange(event) {
    if (event.rows === this.projectlength)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "All";
    else if (event.rows === 10)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "10";
    else if (event.rows === 20)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "20";
    else if (event.rows === 50)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "50";
    else if (event.rows === 100)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "100";
  }
}
