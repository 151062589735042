export class Go36ArSupplementModel {
    supplementId: number = 0;
    arId: number = 0;
    parentSupplementId: number = 0;
    arDate: Date = new Date();
    scopeChange: boolean = false;
    supplement: boolean = false;
    cpdepPhase: number = 0;
    svxShare: number = 0;
    appropriatedAmount: number = 0;
    yotalCommitment: number = 0;
    section2aInformation: string = '';
    section3Information: string = '';
    createdBy: string = '';
    createdDatetime: Date = new Date();
    modifiedBy: string = '';
    modifiedDatetime: Date = new Date();
    hidefromlist: boolean = false;
    projectManager: string = '';
    drbMembers: string = '';
    decisionExecutive: string = '';
    functionalEndorsements: string = '';
    delegationOfAuthority: string = '';
    reportingOfficer: string = '';
    corporateSecretary: string = '';
    projectId: number = 0;
    projectLocation: string = '';
    acquisition: boolean = false;
    feed: boolean = false;
    longLeadEquipment: boolean = false;
    finalInvestDecision: boolean = false;
    program: boolean = false;
    complianceMaintReplacement: boolean = false;
    nojv: boolean = false;
    originalDate: Date = new Date();
    operatorName: string = '';
    statusId: number = 0;
    originalId: number = 0;
    version: number = 0;
    functionalEndorsementsDate: Date = new Date();
    delegationOfAuthorityDate: Date = new Date();
    reportingOfficerDate: Date = new Date();
    versionName: string = '';
    approvedDatetime: Date = new Date();
    isOnHold: boolean = false;
    docName: string = '';
    docContentType: string = '';
    docContent?: Blob;
}