import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { SsbodConstants } from '../../constants/SsbodConstants';
import { EvFormulaViewModel } from '../../Model/evFormulaView.model';
import { ManagementOfChangeModel } from '../../Model/managementOfChange.model';
import { MocDesignParameterViewModel } from '../../Model/mocDesignParameterView.model';
import { SubsurfaceViewModel } from '../../Model/subsurfaceView.model';
import { SsbodService } from '../../service/ssbod.service';
@Component({
  selector: 'app-management-of-change',
  templateUrl: './management-of-change.component.html',
  styleUrls: ['./management-of-change.component.css']
})
export class ManagementOfChangeComponent implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
    ],
    autosave: {
      save(editor) {
      }
    }
  }
  @Input() viewModel: SubsurfaceViewModel = new SubsurfaceViewModel();
  @Input() formType: any = undefined;
  managementOfChangeViewModel: ManagementOfChangeModel = new ManagementOfChangeModel();
  evFormulaViewModel: EvFormulaViewModel[] = [];
  eurViewModel: any[] = [];
  dccViewModel: any[] = [];
  mmtViewModel: any[] = [];
  eurCalculated: any[] = [];
  dccCalculated: any[] = [];
  mmtCalculated: any[] = [];
  formId: number = 0;
  version: number = 0;
  emailId: string = '';
  provId: string = '';
  selectedEvFormula: any = 1;
  @Input() type: string = "";
  panelOpenState = false;
  MocCalculationLimitValue = SsbodConstants.MocCalculationLimitValue;
  SsbodProjectName: any = "";
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;

  constructor(private ssbodService: SsbodService, private route: ActivatedRoute, private go36ProjectService: Go36projectService, private notifyService: NotificationService, public utilityService: UtilityService) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formId = this.viewModel.Id;
    this.GetManagementOfChangeData();
    this.GetEVFormula();
    this.GetMOCDesignSection();
    this.GetMOCDesignSectionCalculations();
  }

  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }

  GetManagementOfChangeData() {
    this.ssbodService.GetManagementOfChangeData(this.formId).subscribe((res: any) => {
      this.managementOfChangeViewModel = res.MocData;
      this.SsbodProjectName = res.ProjectName;
    });
  }
  GetEVFormula() {
    this.ssbodService.GetEVFormula().subscribe(res => {
      this.evFormulaViewModel = res;
      this.GetSelectedEvFormula();
    });
  }
  GetSelectedEvFormula() {
    this.ssbodService.GetSelectedEvFormula(this.formId, this.type).subscribe((res: any) => {
      this.selectedEvFormula = res;
    });
  }
  onReady(editor) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.SaveManagementOfChangeData();
        }
      }
    });
  }
  SaveManagementOfChangeData() {
    const date = new Date(this.managementOfChangeViewModel.Date!);
    const date1 = new Date(this.managementOfChangeViewModel.EurDate!);
    const date2 = new Date(this.managementOfChangeViewModel.DccDate!);

    if (this.managementOfChangeViewModel.Date != null) {
      this.managementOfChangeViewModel.Date = this.notifyService.ConvertDateBeforeSave(date);
    }
    if (this.managementOfChangeViewModel.EurDate != null) {
      this.managementOfChangeViewModel.EurDate = this.notifyService.ConvertDateBeforeSave(date1);
    }
    if (this.managementOfChangeViewModel.DccDate != null) {
      this.managementOfChangeViewModel.DccDate = this.notifyService.ConvertDateBeforeSave(date2);
    }

    this.ssbodService.SaveManagementOfChangeData(this.formId, this.managementOfChangeViewModel, this.provId).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
      // //this.managementOfChangeViewModel = res;
    });

    if (this.managementOfChangeViewModel.Date != null) {
      this.managementOfChangeViewModel.Date = this.notifyService.ConvertDateAfterSave(date);
    }
    if (this.managementOfChangeViewModel.EurDate != null) {
      this.managementOfChangeViewModel.EurDate = this.notifyService.ConvertDateAfterSave(date1);
    }
    if (this.managementOfChangeViewModel.DccDate != null) {
      this.managementOfChangeViewModel.DccDate = this.notifyService.ConvertDateAfterSave(date2);
    }
  }
  GetMOCDesignSection() {
    this.ssbodService.GetMOCDesignSection(this.formId).subscribe(res => {
      this.eurViewModel = [];
      this.dccViewModel = [];
      this.mmtViewModel = [];
      res.eurViewModel.forEach((element, index) => {
        this.eurViewModel.push(this.ssbodService.checkMocPrecision(element, false));
      });
      res.dccViewModel.forEach((element, index) => {
        this.dccViewModel.push(this.ssbodService.checkMocPrecision(element, false));
      });
      res.mmtViewModel.forEach((element, index) => {
        this.mmtViewModel.push(this.ssbodService.checkMocPrecision(element, false));
      });
    });
  }
  GetMOCDesignSectionCalculations() {
    this.ssbodService.GetMOCDesignSectionCalculations(this.formId).subscribe(res => {
      this.eurCalculated = [];
      this.dccCalculated = [];
      this.mmtCalculated = [];
      res.eurCalculated.forEach((element, index) => {
        this.eurCalculated.push(this.ssbodService.checkMocPrecision(element, true));
      });
      res.dccCalculated.forEach((element, index) => {
        this.dccCalculated.push(this.ssbodService.checkMocPrecision(element, true));
      });
      res.mmtCalculated.forEach((element, index) => {
        this.mmtCalculated.push(this.ssbodService.checkMocPrecision(element, true));
      });
    });
  }
  onDesignSectionUpdate(item: MocDesignParameterViewModel) {
    item.ModifiedBy = this.provId;
    let resarr = this.ssbodService.checkMocPrecision([item], false);
    item = resarr[0];
    this.ssbodService.SaveMOCDesignSectionData(this.formId, item).subscribe((res: any) => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
      this.ssbodService.GetMOCDesignSection(this.formId).subscribe(res => {
        this.eurViewModel = [];
        this.dccViewModel = [];
        this.mmtViewModel = [];
        res.eurViewModel.forEach((element, index) => {
          this.eurViewModel.push(this.ssbodService.checkMocPrecision(element, false));
        });
        res.dccViewModel.forEach((element, index) => {
          this.dccViewModel.push(this.ssbodService.checkMocPrecision(element, false));
        });
        res.mmtViewModel.forEach((element, index) => {
          this.mmtViewModel.push(this.ssbodService.checkMocPrecision(element, false));
        });
      });
      this.ssbodService.GetMOCDesignSectionCalculations(this.formId).subscribe(res => {
        this.eurCalculated = [];
        this.dccCalculated = [];
        this.mmtCalculated = [];
        res.eurCalculated.forEach((element, index) => {
          this.eurCalculated.push(this.ssbodService.checkMocPrecision(element, true));
        });
        res.dccCalculated.forEach((element, index) => {
          this.dccCalculated.push(this.ssbodService.checkMocPrecision(element, true));
        });
        res.mmtCalculated.forEach((element, index) => {
          this.mmtCalculated.push(this.ssbodService.checkMocPrecision(element, true));
        });
      });
    });
  }
  onEvFormulaChange(event: any) {
    this.selectedEvFormula = Number(event.target.value);
    this.ssbodService.SaveDesignSectionEVFormula(this.formId, this.type, event.target.value).subscribe((res: any) => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
      this.eurViewModel = [];
      this.dccViewModel = [];
      this.mmtViewModel = [];

      res.eurViewModel.forEach((element, index) => {
        this.eurViewModel.push(this.ssbodService.checkMocPrecision(element, false));
      });
      res.dccViewModel.forEach((element, index) => {
        this.dccViewModel.push(this.ssbodService.checkMocPrecision(element, false));
      });
      res.mmtViewModel.forEach((element, index) => {
        this.mmtViewModel.push(this.ssbodService.checkMocPrecision(element, false));
      });
      this.ssbodService.GetMOCDesignSectionCalculations(this.formId).subscribe(res => {
        this.eurCalculated = [];
        this.dccCalculated = [];
        this.mmtCalculated = [];
        res.eurCalculated.forEach((element, index) => {
          this.eurCalculated.push(this.ssbodService.checkMocPrecision(element, true));
        });
        res.dccCalculated.forEach((element, index) => {
          this.dccCalculated.push(this.ssbodService.checkMocPrecision(element, true));
        });
        res.mmtCalculated.forEach((element, index) => {
          this.mmtCalculated.push(this.ssbodService.checkMocPrecision(element, true));
        });
      });
    });
  }
}
