import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Form } from 'src/app/Model/Form.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { OverviewService } from 'src/app/forms/FEEDServices/overview.service';
import { FeedNavService } from 'src/app/forms/FEEDServices/feed-nav.service';
import { ArPart3Service } from 'src/app/project/ea/service/ar-part3.service';
import { SsbodService } from 'src/app/project/ssbodproject/service/ssbod.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-archive-dialog',
  templateUrl: './archive-dialog.component.html',
  styleUrls: ['./archive-dialog.component.css']
})

export class ArchiveDialogComponent implements OnInit {
  form = new Form();
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  versionName: string = '';
  formId: number = 0;
  oldVersionId: number = 0;
  newVersionId: number = 0;
  formType: string = '';
  public version: EventEmitter<any> = new EventEmitter();
  constructor(public dialogRef: MatDialogRef<ArchiveDialogModel>, private router: Router, @Inject(MAT_DIALOG_DATA) public data: ArchiveDialogModel, private overviewService: OverviewService, private go36ProjectService: Go36projectService, private feedNavService: FeedNavService,private ssbodService: SsbodService,
  private arpart3: ArPart3Service, public utilityService: UtilityService) {
    dialogRef.disableClose = true;
    this.versionName = data.versionName;
  }
  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
  }
  onDismiss(): void {
    this.dialogRef.close();
  }
  onCreate(): void {
    this.dialogRef.close();
    this.form.projectId = this.data.projectId;
    this.version.emit(this.versionName);
    this.formId = this.data.formId;
    this.oldVersionId = this.data.oldVersionId;
    this.formType = this.data.formType;
    if (this.formType == "Part4") {
      this.feedNavService.ArchiveFormPart4(this.formId, this.oldVersionId, this.versionName, this.objectId, this.provId).subscribe(res => {
        this.newVersionId = res;
        this.router.navigate(["/forms/part4"], { queryParams: { projectId: this.form.projectId, formId: this.formId, versionName: this.newVersionId, formCase: 'Open' } }).then(() => {
          window.location.reload();
        });

      })
    }
    else if (this.formType == "Part5") {
      this.feedNavService.ArchiveFormPart5(this.formId, this.oldVersionId, this.versionName, this.objectId, this.provId).subscribe(res => {
        this.newVersionId = res;
        this.router.navigate(["/forms/part5"], { queryParams: { projectId: this.form.projectId, formId: this.formId, versionName: this.newVersionId, formCase: 'Open' } }).then(() => {
          window.location.reload();
        });

      })
    }
    else if (this.formType == "EAPart4") {
      this.feedNavService.ArchiveEAFormPart4(this.formId, this.oldVersionId, this.versionName, this.objectId, this.provId).subscribe(res => {
        this.newVersionId = res;
        this.router.navigate(["/ea/forms/eapart4"], { queryParams: { projectId: this.form.projectId, formId: this.formId, versionName: this.newVersionId, formCase: 'Open' } }).then(() => {
          window.location.reload();
          // this.router.navigateByUrl('/ea/forms/eapart4?projectId=' + this.form.projectId + '&formId=' + this.formId + '&versionName=' + this.newVersionId + '&formCase=Open').then(() => {
          //   window.location.reload();
        });

      })
    }
    else if (this.formType == "EA") {
      this.arpart3.ArchiveEANewAR(this.formId, this.oldVersionId, this.versionName, this.objectId, this.provId).subscribe(res => {
        this.newVersionId = res;
        this.router.navigate(["/ea/new-ar-nav"], { queryParams: { projectId: this.form.projectId, formId: this.formId, versionName: this.newVersionId, formCase: 'Open' } }).then(() => {
          window.location.reload();
          // this.router.navigateByUrl('/ea/forms/eapart4?projectId=' + this.form.projectId + '&formId=' + this.formId + '&versionName=' + this.newVersionId + '&formCase=Open').then(() => {
          //   window.location.reload();
        });

      })
    }
    else if (this.formType == "EA Supplement") {
      this.arpart3.ArchiveEASupplement(this.formId, this.oldVersionId, this.versionName, this.objectId, this.provId).subscribe(res => {
        this.newVersionId = res;
        this.router.navigate(["/ea/supplement-nav"], { queryParams: { projectId: this.form.projectId, supplementId: this.formId, versionName: this.newVersionId, formCase: 'Open' } }).then(() => {
          window.location.reload();
        });
      })
    }
    else if (this.formType == "SSBOD_OLDSTYLE_ARCHIVE"){
      this.ssbodService.CreateNamedVersion(this.formId,this.versionName,this.provId).subscribe(res => {
        this.onDismiss();
        this.router.navigate([ "/forms/ssbod" ], { queryParams: { formId: Number(res)} }).then(() =>{
          window.location.reload();
        });
      });
    }
    else {
      this.feedNavService.ArchiveForm(this.formId, this.oldVersionId, this.versionName, this.objectId, this.provId).subscribe(res => {
        this.newVersionId = res;
        if (this.formType == "LL") {
          this.router.navigate(["/forms/lessonlearned"], { queryParams: { projectId: this.form.projectId, formId: this.formId, versionName: this.newVersionId, formCase: 'LLOpen' } }).then(() => {
            window.location.reload();
          });
        }
        else if (this.formType == "Phase 3 to 4 (FID)") {
          this.router.navigateByUrl('/forms/fid?projectId=' + this.form.projectId + '&formId=' + this.formId + '&versionName=' + this.newVersionId + '&formCase=Open').then(() => {
            window.location.reload();
          });
        }     
        else if (this.formType == "FEED Supplement") {
          this.router.navigateByUrl('/forms/feed_Supplement?projectId=' + this.form.projectId + '&formId=' + this.formId + '&versionName=' + this.newVersionId + '&formCase=Open').then(() => {
            window.location.reload();
          });
        }   
        else if (this.formType == "FID Supplement") {
          this.router.navigateByUrl('/forms/fid_Supplement?projectId=' + this.form.projectId + '&formId=' + this.formId + '&versionName=' + this.newVersionId + '&formCase=Open').then(() => {
            window.location.reload();
          });
        }     
        else {
          this.router.navigateByUrl('/forms/feed?projectId=' + this.form.projectId + '&formId=' + this.formId + '&versionName=' + this.newVersionId + '&formCase=Open').then(() => {
            window.location.reload();
          });
        }
      })
    }
  }
}
export class ArchiveDialogModel {
  constructor(public projectId: number, public oldVersionId: number, public versionName: string, public formId: number, public formType: string) {
  }
}