import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LessonsLearnedService } from '../../FEEDServices/lessons-learned.service';
import { LessonLearnedCategory } from 'src/app/Model/LessonLearnedCategory.model';
import { LessonLearnedDetail } from 'src/app/Model/LessonsLearned.model';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { ActivatedRoute, Router } from '@angular/router';
import { Part4LookbackService } from 'src/app/forms/FEEDServices/Part4LookBack_Service/part4-lookback.service';
import { Go36P4LookbackDocument, Part4ViewModel } from 'src/app/Model/Part4LookBack/Part4LookBack.model';
import { Go36ArP4LookbackHeaderModel } from 'src/app/Model/Part4LookBack/Part4LookBackHeader.model';
import { Go36ArP4LookbackPerformance } from 'src/app/Model/Part4LookBack/Part4LookBackPerformance.model';
import { Go36projectService } from '../../../project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/Modals/confirm-dialog/confirm-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ArchiveDialogComponent } from 'src/app/Modals/archive-dialog/archive-dialog.component';
import { Part5LookbackService } from '../../FEEDServices/part5-lookback.service';
import { Go36ArP5LookbackHeaderModel, Part5ViewModel } from 'src/app/Model/Part5LookBack.model';
import { UtilityService } from 'src/app/Shared/utility.service';
import { FormAction } from 'src/app/Model/FormAction.model';
import { FormsLog } from 'src/app/Model/FormsLog.model';
import { User } from 'src/app/Model/User.model';
import { CompleterService } from 'ng2-completer';
import { FormReviewerService } from '../../FEEDServices/form-reviewer.service';
import { ApprovalDialogComponent } from 'src/app/Modals/approval-dialog/approval-dialog.component';
// //import { SignOffsModel } from 'src/app/Model/Signoffs.model';
import { environment } from 'src/environments/environment';
import { LookBackFormSignoffs } from 'src/app/Model/LookBackFormSignoffs.model';
import { FeaturesImplemented } from 'src/app/Model/features-implemented';
import { FormStatus } from 'src/app/Model/Form.model';
import { AuthorizationService } from 'src/app/admin/service/authorization.service';


@Component({
  selector: 'app-part4lookback-nav',
  templateUrl: './part4lookback-nav.component.html',
  styleUrls: ['./part4lookback-nav.component.css']
})

export class Part4LookBackNavComponent implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [
      // 'fontfamily','fontsize','fontColor','fontBackgroundColor', '|',
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
    ],
    autosave: {
      save(editor) {
        // The saveData() function must return a promise
        // which should be resolved when the data is successfully saved.
      }
    }
  }

  ckeditorText: string = '';
  checkbox: boolean = false;
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  formId: number = 0;
  isOptOut = false;
  isOnHold = false;
  versionName: number = 0;
  formType: string = '';
  options = this._formBuilder.group({
    bottom: 0,
    fixed: true,
    top: 0,
  });

  LLMaxOrder: number = 0;
  KDMaxOrder: number = 0;
  showSection1: boolean = true;
  showSection2: boolean = false;
  showSection3: boolean = false;
  showSection4: boolean = false;
  showSection5: boolean = false;
  showSection6: boolean = false;
  showSection7: boolean = false;
  showSection8a: boolean = false;
  showSection8b: boolean = false;
  showSection8c: boolean = false;
  showSection8d: boolean = false;
  showSection8e: boolean = false;
  showSection9: boolean = false;
  showSection10: boolean = false;
  showSection11: boolean = false;
  showSection12: boolean = false;

  LLCategoryData: LessonLearnedCategory[] = [];
  formLL: UntypedFormGroup;
  formKD: UntypedFormGroup;
  lessonForm: UntypedFormGroup;
  keyDecisionForm: UntypedFormGroup;
  naKeyDecisionDisabled: boolean = false;
  LLTypeData: LessonLearnedDetail[] = [];
  KeyTypeData: LessonLearnedDetail[] = [];
  PlanTypeData = new LessonLearnedDetail();
  LLSaveModel = new LessonLearnedDetail();
  LLUpdateModel = new LessonLearnedDetail();
  KDUpdateModel = new LessonLearnedDetail();
  KDSaveModel = new LessonLearnedDetail();
  PlanUpdateModel = new LessonLearnedDetail();
  projectId: number = 0;
  Part4Model = new Part4ViewModel;
  Part4Header = new Go36ArP4LookbackHeaderModel;
  Part4Performance = new Go36ArP4LookbackPerformance;
  projectData: any;
  Part4Performancedata: any;
  part4Document: Go36P4LookbackDocument[] = [];
  part5Data: Part5ViewModel[] = [];
  Part5Header = new Go36ArP5LookbackHeaderModel;
  userAccessDetails: any;
  userRole: string = '';
  part4LookBackAccessList: any;
  projectName: string = '';

  // Form Reviewer Fields
  formActionModel = new FormAction();
  selectedUser: any;
  filteredUsers: any = [];
  searchuser: any;
  userinfo: any = [];
  data: any;
  name: any = '';
  fromPage: boolean = true;
  formLink: any = '/ar/request/index/';
  features?:FeaturesImplemented;
  role: string = '';
  ProjectId: number = 0;
  reviewerData: FormAction[] = [];
  rowData: any = [];
  UserName = this.projectservice.GetuserName();
  showDelete: boolean = false;
  editMode: boolean = localStorage.getItem('editMode') == 'true';
  AreMultipleAdmins: boolean = true;
  @Input() formsLogData: FormsLog[] = [];
  reviewerCount: boolean = false;
  selectedUserObjectId: string = '';
  selectedUserProvId: string = '';
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;
  @Input() formReviewData: any = null;
  dataService: any;
  searchStr: string = '';
  placeholder: string = '';
  searchData: Array<User> = [];
  href: string = "";
  bUrl: string = "";
  signOffData: LookBackFormSignoffs[] = [];
  baseUrl: any = environment.apiBaseUrl;
  pdfUrl: any = "";

  isPowerUser?:boolean = false;

  sendBackLockByFormState:boolean = false;
  forReviewLockByFormState:boolean = false;
  concurLockByFormState:boolean = false;

  constructor(private _formBuilder: UntypedFormBuilder, private lessonLearnedService: LessonsLearnedService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute, private router: Router,
    private part4lookbackService: Part4LookbackService,
    private projectservice: Go36projectService,
    private notifyService: NotificationService,
    private go36ProjectService: Go36projectService,
    private part5lookbackService: Part5LookbackService,
    public dialog: MatDialog,
    private formReviewerService: FormReviewerService,
    private completerService: CompleterService, 
    public utilityService: UtilityService,
    private userRoleService: AuthorizationService) {

    this.formLL = this.fb.group({
      lessonsLearned: this.fb.array([])
    })
    this.lessonForm = this.fb.group({
    })
    this.formKD = this.fb.group({
      keyDecision: this.fb.array([])
    })
    this.keyDecisionForm = this.fb.group({
    })

    this.formReviewerService.GetUserBySearch("Hasa").subscribe(res => {
      this.filteredUsers = res;
      this.dataService = completerService.local(
        this.searchData,
        'name',
        'name'
      );
      for (let i = 0; i < res.length; i++) {
        let p = new User(i, res[i].lastName + ' ' + res[i].firstName, res[i].objectId, res[i].provId);
        this.searchData.push(p);
      }

    })
    this.placeholder = "last name, first name";
  }

  ngOnInit(): void {
    this.isPowerUser = this.userRoleService.isPowerUser();
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.route.queryParams
      .subscribe(params => {
        this.projectId = params['projectId'];
        this.formId = params['formId'];
        this.versionName = params['versionName'];
        this.formType = params['formType']
      });

    this.getProjectInfobyId();
    this.LoadPart4LookBack();

    // Form Reviewer
    const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")));
    this.data = { goprojectmodel: JSON.parse(projectDetail) };
    this.href = this.router.url;
    this.bUrl = window.location.origin;
  }
  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }

  getProjectInfobyId() {
    this.projectservice.GetProjectInfobyId(this.projectId).subscribe(res => {
      this.projectData = res;
      if (res) {
        this.projectName = this.projectData.buName + ' - ' + ' ' + this.projectData.projectName + ' - ' + '(version #' + this.versionName + ')';
      }
    });
  }

  
  setGetFormStatus(formStatus:number){
    if(formStatus == FormStatus.FORM_DRAFT_STATE || formStatus == FormStatus.FORM_REVISION_STATE){
      this.forReviewLockByFormState = false;
      this.sendBackLockByFormState = true;
      this.concurLockByFormState = true;
    }else if(formStatus == FormStatus.FORM_REVIEW_STATE){
      this.forReviewLockByFormState = true;
      this.sendBackLockByFormState = false;
      this.concurLockByFormState = false;
    }else{
      this.forReviewLockByFormState = false;
      this.sendBackLockByFormState = false;
      this.concurLockByFormState = false;
    }
  }

  performFormAction(action:string){
    let formActionModel = new FormAction();
    formActionModel.projectId = this.projectId;
    formActionModel.formId = this.formId;
    formActionModel.versionId = this.versionName;
    formActionModel.action = action;
    formActionModel.formType = "AR_LOOKBACK_PART4";
    formActionModel.loggedInUser = this.provId;
    this.formReviewerService.processFormAction(formActionModel).subscribe(res => {
      //getting form data again on successful for-action
      this.getProjectInfobyId();
      this.LoadPart4LookBack();
    })
  }

  showSection(sectionNumber: number) {
    this.showSection1 = this.showSection3 = this.showSection4 = this.showSection5 =
      this.showSection6 = this.showSection7 = this.showSection8a = this.showSection8b = this.showSection8c =
      this.showSection8d = this.showSection8e = this.showSection9 = this.showSection10 =
      this.showSection11 = this.showSection12 = false;

    if (sectionNumber == 1) {
      this.showSection1 = true;
    }
    else if (sectionNumber == 3) {
      this.showSection3 = true;
      this.ClearLLData();
      this.LoadLessonLearnedData();
    }
    else if (sectionNumber == 4) {
      this.showSection4 = true;
    }
    else if (sectionNumber == 5) {
      this.showSection5 = true;
    }
    else if (sectionNumber == 6) {
      this.showSection6 = true;
    }
    else if (sectionNumber == 7) {
      this.showSection7 = true;
    }
    else if (sectionNumber == 8) {
      this.showSection8a = true;
    }
    else if (sectionNumber == 9) {
      this.showSection8b = true;
    }
    else if (sectionNumber == 10) {
      this.showSection8c = true;
    }
    else if (sectionNumber == 11) {
      this.showSection8d = true;
    }
    else if (sectionNumber == 12) {
      this.showSection8e = true;
    }
    else if (sectionNumber == 13) {
      this.showSection9 = true;
    }
    else if (sectionNumber == 14) {
      this.showSection10 = true;
    }
    else if (sectionNumber == 15) {
      this.showSection11 = true;
    }
    else if (sectionNumber == 16) {
      this.showSection12 = true;
      this.getSignData();
    }
   

  }

  ClearLLData() {
    this.LLTypeData = [];
    this.KeyTypeData = [];
    this.clearFormArray(this.lessonsLearned);
    this.clearFormArray(this.keyDecision);
  }
  
  ShowPlanModal() {
    const message = "It is extremely relevant to capture information and lessons learned but if these are not shared within the BU or across SBUs and OPCOs, their potential value is not maximized." +
      "<br><br> This field is reserved for the Project Team to share what actions have already be taken and the ones that will be taken to share the Lessons Learned and Key Insights above with the rest of the Chevron organization."
    const dialogData = new InfoDialogModel("Plan", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }

  ShowKeyDecisionsModal() {
    const message = "Throughout the lifecycle of the project, there are several decisions that are taken and registered into the project’s Decision Register. Some of these decisions are critical for the outcome of the project as well as to enable the project lookback to be performed using the appropriate amount of information." +
      "<br><br> These fields are to be utilized to capture the key Decision that were taken recently during the project Phase and led to the current status of the project or the request being made." +
      "<br><br> Use these fields to share the more relevant and the strategical decisions that influenced the opportunity being pursued, alternative selected, execution strategy and any other project team feel relevant."
    const dialogData = new InfoDialogModel("Key Decisions", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }

  UpdateLLCkeditorChanges(event: string, order: any, type: any) {
    const value = event;
    if (type == "LessonLearned") {
      this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
      if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && (value.trim().length > 0 || this.LLUpdateModel.id > 0)) {
        this.LLUpdateModel.modifiedBy = this.provId;
        this.LLUpdateModel.description = value;
        this.UpdateLLData(this.LLUpdateModel);
      }
    }
    else if (type == "Plan") {
      if (this.PlanTypeData != undefined && this.PlanTypeData != null && (value.trim().length > 0 || this.PlanTypeData.id > 0)) {
        this.PlanTypeData.modifiedBy = this.provId;
        this.PlanTypeData.description = value;
        this.UpdateLLData(this.PlanTypeData);
      }
    }
    else if (type == "KeyDecision") {
      this.KDUpdateModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
      if (this.KDUpdateModel != undefined && this.KDUpdateModel != null && this.KDUpdateModel.id > 0) {
        this.KDUpdateModel.modifiedBy = this.provId;
        this.KDUpdateModel.description = value;
        this.UpdateLLData(this.KDUpdateModel);
      }
      else {
        this.KDSaveModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
        this.KDSaveModel.description = value;
        if (value.trim().length != 0) {
          this.KDSaveModel.order = this.KDMaxOrder + 1;
          this.SaveLLData(this.KDSaveModel);
        }
      }
    }
  }

  UpdateckeditorChanges(event: string, textName: string) {
    this.ckeditorText = event;
    this.Part4Header.modifiedBy = this.provId;

    if (textName == 'section1-2') {
      this.Part4Header.projectCompletionSummary = this.ckeditorText;
    }
    else if (textName == 'projectCompletionSummary') {
      // //this.businessCase.primaryBusinessCase = this.ckeditorText;
    }
    else if (textName == 'section8a') {
      this.Part4Header.operationalResults = this.ckeditorText;
    }
    else if (textName == 'section8b') {
      this.Part4Header.expenditureResults = this.ckeditorText;
    }
    else if (textName == 'section8c') {
      this.Part4Header.scheduleResults = this.ckeditorText;
    }
    else if (textName == 'section8d') {
      this.Part4Header.commercialAgreementResults = this.ckeditorText;
    }
    else if (textName == 'section8e') {
      this.Part4Header.earlyOperationalResults = this.ckeditorText;
    }
    else if (textName == 'section9') {
      this.Part4Header.midPhase4Results = this.ckeditorText;
    }

    //Section 5
    else if (textName == 'asset') {
      this.Part4Header.defintionAsset = this.ckeditorText;
    }
    else if (textName == 'FD') {
      this.Part4Header.definitionFacilities = this.ckeditorText;
    }
    else if (textName == 'WD') {
      this.Part4Header.definitionWells = this.ckeditorText;
    }
    else if (textName == 'RD') {
      this.Part4Header.definitionReservoir = this.ckeditorText;
    }

    this.UpdatePart4LookBackHeaderData(this.Part4Header);
  }

  ShowLessonLearnedModal() {
    const message = "Through the lifecycle of the project, there are several opportunities to capture leanings that can be valuable for future projects. These fields are to be utilized to capture Lessons Learned / Key Insights that were identified in a certain Phase of the project." +
      "<br><br> Majority of Lessons Learned are usually more technical or tactical and they are captured in the project’s Lessons Learned Register / iPIMS" +
      "<br><br> Use these fields to share the more relevant and the strategical learnings that can either have the potential to be incorporated in the future into Chevron Project Management IP or learnings that are more strategic in their nature, and valuable for future generations of projects to come"
    const dialogData = new InfoDialogModel("Lesson Learned", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }

  RemoveOnCategoryChange(order: any) {
    this.lessonsLearned.controls[order - 1].get("title")?.setValue("");
    this.lessonsLearned.controls[order - 1].get("description")?.setValue("");
    this.lessonsLearned.controls[order - 1].get("title")?.disable();
    this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
    this.LLUpdateModel.naLessonLearned = true;
    this.LLUpdateModel.title = "";
    this.LLUpdateModel.description = "";
    this.LLUpdateModel.lessonLearnedCategoryId = undefined;
    this.UpdateLLData(this.LLUpdateModel);
  }

  UpdateLLData(UpdateModel: LessonLearnedDetail) {
    // //UpdateModel.formType = this.form.formType;
    this.lessonLearnedService.saveLessonsLearnedData(UpdateModel).subscribe(res => {
      if (UpdateModel.lessonLearnedTypeId == 1) {
        this.clearFormArray(this.lessonsLearned);
        this.LoadLLData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      if (UpdateModel.lessonLearnedTypeId == 3) {
        this.clearFormArray(this.keyDecision);
        this.LoadKeyData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      if (UpdateModel.lessonLearnedTypeId == 2) {
        this.LoadPlanData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    })
  }

  clearFormArray = (formArray: UntypedFormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  LoadLLData() {
    this.lessonLearnedService.getLessonLearnedData(this.Part4Header.arP4LookbackId, this.projectId, 1).subscribe(res => {
      this.LLTypeData = res as LessonLearnedDetail[];
      this.lessonLearnedService.getLLCategoryData().subscribe((res: LessonLearnedCategory[]) => {
        this.LLCategoryData = res;
        if (this.LLTypeData.length > 0) {
          this.LLTypeData.forEach((element, index) => {
            let formLLNew = this.fb.group({
              lessonLearnedCategoryId: (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) ? -1 : element.lessonLearnedCategoryId,
              title: element.title,
              description: element.description
            });
            this.lessonsLearned.push(formLLNew);
            this.LLMaxOrder = element.order;
            if (this.LLTypeData[index].lessonLearnedCategoryId == undefined || this.LLTypeData[index].lessonLearnedCategoryId == null) {
              this.LLTypeData[index].lessonLearnedCategoryId = -1;
              this.lessonsLearned.controls[index].get("title")?.disable();
              this.LLTypeData[index].naLessonLearned = true;
            } else
              this.LLTypeData[index].naLessonLearned = false;
          });
        }
        else {
          this.LLDataIntialization(1);
          let formLLNew = this.fb.group({
            lessonLearnedCategoryId: this.LLTypeData[0].lessonLearnedCategoryId,
            title: this.LLTypeData[0].title,
            description: this.LLTypeData[0].description
          });
          this.lessonsLearned.push(formLLNew);
          this.getMaxOrder(1);
          this.lessonsLearned.controls[0].get("title")?.disable();
        }
      });
    });
  }

  LoadPlanData() {
    this.lessonLearnedService.getLessonLearnedData(this.Part4Header.arP4LookbackId, this.projectId, 2).subscribe(res => {
      this.PlanTypeData = res[0] as LessonLearnedDetail;
      if (this.PlanTypeData == undefined && this.PlanTypeData == null)
        this.PlanDataIntialization();
    })
  }

  LoadKeyData() {
    this.lessonLearnedService.getLessonLearnedData(this.Part4Header.arP4LookbackId, this.projectId, 3).subscribe(res => {
      this.KeyTypeData = res as LessonLearnedDetail[];
      if (this.KeyTypeData.length > 0) {
        if (this.KeyTypeData[0].nakeyDecision)
          this.naKeyDecisionDisabled = true;
        else
          this.naKeyDecisionDisabled = false;
        this.KeyTypeData.forEach(element => {
          let formKDNew = this.fb.group({
            title: element.title,
            description: element.description
          });
          this.keyDecision.push(formKDNew);
          this.KDMaxOrder = element.order;
        });
      }
      else {
        this.KeyDataIntialization(1);
        if (this.KeyTypeData[0].nakeyDecision)
          this.naKeyDecisionDisabled = true;
        else
          this.naKeyDecisionDisabled = false;
        let formKDNew = this.fb.group({
          title: this.KeyTypeData[0].title,
          description: this.KeyTypeData[0].description
        });
        this.keyDecision.push(formKDNew);
        this.getMaxOrder(3);
      }
    })
  }

  getMaxOrder(typeId: any) {
    this.lessonLearnedService.getMaxOrder(this.Part4Header.arP4LookbackId, this.projectId, typeId).subscribe(res => {
      if (typeId == 1)
        this.LLMaxOrder = res;
      else if (typeId == 3)
        this.KDMaxOrder = res;
    });
  }

  onCategoryChange(event: any, order: any, control: UntypedFormControl) {
    const value = event.target.value;
    this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order && x.active == true)[0];
    if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && this.LLUpdateModel.id > 0) {
      this.LLUpdateModel.modifiedBy = this.provId;
      this.LLUpdateModel.lessonLearnedCategoryId = value;
      if (value <= -1)
        this.RemoveOnCategoryChange(order);
      else {
        this.lessonsLearned.controls[order - 1].get("title")?.enable();
        this.LLUpdateModel.naLessonLearned = false;
        this.UpdateLLData(this.LLUpdateModel);
      }
    }
    else {
      this.LLSaveModel = this.LLTypeData.filter(x => x.order == order && x.active == true)[0];
      this.LLSaveModel.lessonLearnedCategoryId = value;
      if (value <= -1) {
        this.lessonsLearned.controls[order - 1].get("title")?.disable();
        this.LLSaveModel.naLessonLearned = true;
      }
      else {
        this.lessonsLearned.controls[order - 1].get("title")?.enable();
        this.LLSaveModel.naLessonLearned = false;
        this.LLSaveModel.order = this.LLMaxOrder + 1;
        this.SaveLLData(this.LLSaveModel);
      }
    }
  }

  SaveLLData(SaveModel: LessonLearnedDetail) {
    // // SaveModel.formType = this.form.formType;
    this.lessonLearnedService.saveLessonsLearnedData(SaveModel).subscribe(res => {
      if (SaveModel.lessonLearnedTypeId == 1) {
        this.clearFormArray(this.lessonsLearned);
        this.LoadLLData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      else if (SaveModel.lessonLearnedTypeId == 3) {
        this.clearFormArray(this.keyDecision);
        this.LoadKeyData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    })
  }

  LoadLLCategoryData() {
    this.lessonLearnedService.getLLCategoryData().subscribe((res: LessonLearnedCategory[]) => {
      this.LLCategoryData = res;
      ;
    });
  }

  onBlurTitleUpdate(event: any, order: any, type: any) {
    const value = event.target.value;
    if (type == "LessonLearned") {
      this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
      if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && (value.trim().length != 0 || this.LLUpdateModel.id > 0)) {
        this.LLUpdateModel.modifiedBy = this.provId;
        this.LLUpdateModel.title = value;
        this.UpdateLLData(this.LLUpdateModel);
      }
    }
    else if (type == "KeyDecision") {
      this.KDUpdateModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
      if (this.KDUpdateModel != undefined && this.KDUpdateModel != null && this.KDUpdateModel.id > 0) {
        this.KDUpdateModel.modifiedBy = this.provId;
        this.KDUpdateModel.title = value;
        this.UpdateLLData(this.KDUpdateModel);
      }
      else {
        this.KDSaveModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
        this.KDSaveModel.title = value;
        if (value.trim().length != 0) {
          this.KDSaveModel.order = this.KDMaxOrder + 1;
          this.SaveLLData(this.KDSaveModel);
        }
      }
    }
  }

  get lessonsLearned(): any {
    return this.formLL.get('lessonsLearned') as UntypedFormArray;
  }

  DeleteLLData(DeleteModel: LessonLearnedDetail) {
    this.lessonLearnedService.saveLessonsLearnedData(DeleteModel).subscribe(res => {
      if (DeleteModel.lessonLearnedTypeId == 1) {
        this.getMaxOrder(1);
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      else if (DeleteModel.lessonLearnedTypeId == 3) {
        this.getMaxOrder(3);
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    })
  }

  DeleteEmptyLLTypeData(order: any) {
    this.clearFormArray(this.lessonsLearned);
    this.LLTypeData.forEach((element, index) => {
      if (index > order - 1) {
        element.order = element.order - 1;
        if (element.id > 0) {
          if (element.lessonLearnedCategoryId == -1) { element.lessonLearnedCategoryId = undefined; }
          this.DeleteLLData(element);
          if (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) { element.lessonLearnedCategoryId = -1; }
        }
      }
      if (index == order - 1 && element.id > 0) {
        element.active = false;
        if (element.lessonLearnedCategoryId == -1) { element.lessonLearnedCategoryId = undefined; }
        this.DeleteLLData(element);
        if (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) { element.lessonLearnedCategoryId = -1; }
      }
      if (index != order - 1) {
        let formLLNew = this.fb.group({
          lessonLearnedCategoryId: (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) ? -1 : element.lessonLearnedCategoryId,
          title: element.title,
          description: element.description
        });
        this.lessonsLearned.push(formLLNew);
      }
    });
  }

  DeleteLLTypeData(order: any) {
    this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
    if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && this.LLUpdateModel.lessonLearnedCategoryId != -1) {
      this.LLUpdateModel.active = false;
      this.DeleteLLData(this.LLUpdateModel);
      this.clearFormArray(this.lessonsLearned);
      this.LLTypeData.forEach((element, index) => {
        if (index > order - 1) {
          element.order = element.order - 1;
          if (element.id > 0) {
            if (element.lessonLearnedCategoryId == -1) { element.lessonLearnedCategoryId = undefined; }
            this.DeleteLLData(element);
            if (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) { element.lessonLearnedCategoryId = -1; }
          }
        }
        if (index != order - 1) {
          let formLLNew = this.fb.group({
            lessonLearnedCategoryId: (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) ? -1 : element.lessonLearnedCategoryId,
            title: element.title,
            description: element.description
          });
          this.lessonsLearned.push(formLLNew);
        }
      });
    }
  }

  DeleteEmptyKeyTypeData(order: any) {
    this.clearFormArray(this.keyDecision);
    this.KeyTypeData.forEach((element, index) => {
      if (index > order - 1) {
        element.order = element.order - 1;
        if (element.id > 0) {
          this.DeleteLLData(element);
        }
      }
      if (index == order - 1 && element.id > 0) {
        element.active = false;
        this.DeleteLLData(element);
      }
      if (index != order - 1) {
        let formKDNew = this.fb.group({
          title: element.title,
          description: element.description
        });
        this.keyDecision.push(formKDNew);
      }
    });
  }

  DeleteKeyTypeData(order: any) {
    this.KDUpdateModel = this.KeyTypeData.filter(x => x.order == order)[0];
    if (this.KDUpdateModel != undefined && this.KDUpdateModel != null) {
      this.KDUpdateModel.active = false;
      this.DeleteLLData(this.KDUpdateModel);
      this.clearFormArray(this.keyDecision);
      this.KeyTypeData.forEach((element, index) => {
        if (index > order - 1) {
          element.order = element.order - 1;
          this.DeleteLLData(element);
        }
        if (index != order - 1) {
          let formKDNew = this.fb.group({
            title: element.title,
            description: element.description
          });
          this.keyDecision.push(formKDNew);
        }
      });
    }
  }

  Delete(order: any, type: any) {
    const message = 'Are you sure you want to delete this item?';
    const dialogData = new ConfirmDialogModel("Confirm item deletion", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (type == "LessonLearned") {
          if (this.LLTypeData.length >= 1 && this.LLTypeData[order - 1].lessonLearnedCategoryId == -1)
            this.DeleteEmptyLLTypeData(order);
          else if (this.LLTypeData.length >= 1 && this.LLTypeData[order - 1].lessonLearnedCategoryId != -1)
            this.DeleteLLTypeData(order);
          if (this.LLTypeData.length == 1) {
            this.LLTypeData.splice(order - 1, 1);
            this.LLDataIntialization(1);
            let formLLNew = this.fb.group({
              lessonLearnedCategoryId: this.LLTypeData[0].lessonLearnedCategoryId,
              title: this.LLTypeData[0].title,
              description: this.LLTypeData[0].description
            });
            this.lessonsLearned.push(formLLNew);
            this.lessonsLearned.controls[0].get("title")?.disable();
          }
          else if (this.LLTypeData.length > 1) {
            this.LLTypeData.splice(order - 1, 1);
            this.LLTypeData.forEach((element: any, index: any) => {
              if (element.lessonLearnedCategoryId == -1)
                this.lessonsLearned.controls[index].get("title")?.disable();
            });
          }
        }
        else if (type == "Plan") {
          if (this.PlanTypeData != undefined && this.PlanTypeData != null && (this.PlanTypeData.description?.trim().length != 0 || this.PlanTypeData.id > 0)) {
            this.PlanTypeData.active = false;
            this.UpdateLLData(this.PlanTypeData);
          }
        }
        else if (type == "KeyDecision") {
          if (this.KeyTypeData.length >= 1 && this.KeyTypeData[order - 1].description?.trim().length == 0 && this.KeyTypeData[order - 1].title?.trim().length == 0)
            this.DeleteEmptyKeyTypeData(order);
          else if (this.KeyTypeData.length >= 1 && (this.KeyTypeData[order - 1].description?.trim().length != 0 || this.KeyTypeData[order - 1].title?.trim().length != 0))
            this.DeleteKeyTypeData(order);
          if (this.KeyTypeData.length == 1) {
            this.KeyTypeData.splice(order - 1, 1);
            this.KeyDataIntialization(1);
            let formKDNew = this.fb.group({
              title: this.KeyTypeData[0].title,
              description: this.KeyTypeData[0].description
            });
            this.keyDecision.push(formKDNew);
          }
          else if (this.KeyTypeData.length > 1)
            this.KeyTypeData.splice(order - 1, 1);
        }
      }
    });
  }

  get keyDecision(): any {
    return this.formKD.get('keyDecision') as UntypedFormArray;
  }

  LLDataIntialization(order: any) {
    this.LLTypeData.push(new LessonLearnedDetail());
    this.LLTypeData[order - 1].lessonLearnedCategoryId = -1;
    this.LLTypeData[order - 1].formId = this.Part4Header.arP4LookbackId;
    this.LLTypeData[order - 1].projectId = this.projectId;
    this.LLTypeData[order - 1].lessonLearnedTypeId = 1;
    this.LLTypeData[order - 1].createdBy = this.provId;
    this.LLTypeData[order - 1].order = order;
    this.LLTypeData[order - 1].formType = "AR_LOOKBACK_PART4";
    this.LLTypeData[order - 1].title = "";
    this.LLTypeData[order - 1].description = "";
    this.LLTypeData[order - 1].naLessonLearned = true;
  }

  PlanDataIntialization() {
    this.PlanTypeData = new LessonLearnedDetail();
    this.PlanTypeData.formId = this.Part4Header.arP4LookbackId;
    this.PlanTypeData.lessonLearnedCategoryId = undefined;
    this.PlanTypeData.projectId = this.projectId;
    this.PlanTypeData.lessonLearnedTypeId = 2;
    this.PlanTypeData.createdBy = this.provId;
    this.PlanTypeData.order = 1;
    this.PlanTypeData.description = "";
    this.PlanTypeData.formType = "AR_LOOKBACK_PART4";
  }

  KeyDataIntialization(order: any) {
    this.KeyTypeData.push(new LessonLearnedDetail());
    this.KeyTypeData[order - 1].lessonLearnedCategoryId = undefined;
    this.KeyTypeData[order - 1].formId = this.Part4Header.arP4LookbackId;
    this.KeyTypeData[order - 1].projectId = this.projectId;
    this.KeyTypeData[order - 1].lessonLearnedTypeId = 3;
    this.KeyTypeData[order - 1].createdBy = this.provId;
    this.KeyTypeData[order - 1].order = order;
    this.KeyTypeData[order - 1].formType = "AR_LOOKBACK_PART4";
    this.KeyTypeData[order - 1].title = "";
    this.KeyTypeData[order - 1].description = "";
    this.KeyTypeData[order - 1].nakeyDecision = false;
  }

  addNewLL(type: any): void {
    if (type == "LessonLearned") {
      this.lessonForm = this.fb.group({
        lessonLearnedCategoryId: [-1],
        title: [''],
        description: ['']
      });
      this.LLDataIntialization(this.LLTypeData.length + 1);
      this.lessonsLearned.push(this.lessonForm);
      this.lessonsLearned.controls[this.lessonsLearned.length - 1].get("title")?.disable();
    }
    else if (type == "KeyDecision") {
      this.keyDecisionForm = this.fb.group({
        title: [''],
        description: ['']
      });
      this.KeyDataIntialization(this.KeyTypeData.length + 1);
      this.keyDecision.push(this.keyDecisionForm);
    }
  }

  NAKeyDecisionChange(event: MatSlideToggleChange) {
    this.KDUpdateModel = this.KeyTypeData[0];
    if (this.KDUpdateModel != undefined && this.KDUpdateModel != null) {
      this.KDUpdateModel.modifiedBy = this.provId;
      this.KDUpdateModel.nakeyDecision = event.checked;
      this.UpdateLLData(this.KDUpdateModel);
    }
  }

  LoadPart4LookBack() {
    this.part4lookbackService.GetPart4LookBack(this.formId, this.versionName, this.objectId, this.provId).subscribe((res: any) => {

      if (res) {
        
        this.Part4Model = res;
        this.Part4Header = res.go36ArP4LookbackHeaders;
       // this.signOffData = res.lookBackFormSignoff;
        this.setGetFormStatus(this.Part4Header.statusId);
        if (this.Part4Header != null &&  this.Part4Header.cvxShare != null) {
          this.Part4Header.cvxShare = Number(this.Part4Header.cvxShare).toString();
          this.Part4Header.cvxShare =  this.Part4Header.cvxShare + '%';
        }
        // // this.projectName = this.projectData.buName + ' - ' + ' ' + this.projectData.projectName + ' - ' + '(version #' + this.Part4Header.version + ')';
        this.Part4Performance = res.go36ArP4LookbackPerformance;
        this.LLTypeData = res.lessonLearned;
        this.part4Document = res.go36ArP4LookbackDocument;
        this.ConvertoOneDecimal(this.Part4Performance);

        this.features = res.go36ArP4LookbackHeaders.features;

    
        this.reviewerData = res.formActionList;
        this.formsLogData = res.formLog;
        this.pdfUrl = `${this.baseUrl}api/LookbackPart4/GetPart4PDF?projectId=${this.projectId}&formId=${this.formId}&version=${this.versionName}&objectId=${this.objectId}&provId=${this.provId}`;

        if (this.reviewerData?.length != 0) {
          this.reviewerCount = true;
        }

        this.GetUserRoleFromLocalStorage();
        let map = new Map<string, string>();
        map.set("objectId", this.objectId);
        map.set("provId", this.provId);
        map.set("emailId", this.emailId);
        map.set("projectId", this.Part4Header.projectId.toString());
        map.set("originalId", this.Part4Header.originalId.toString());
        map.set("formStatus", String(this.Part4Header.statusId));
        map.set("formVersion", String(this.Part4Header.version));
        map.set("formVersionName", String(this.Part4Header.versionName));
        map.set("formIsReadOnly", String(this.Part4Header.isReadOnly));
        map.set("formIsOnHold", String(this.Part4Header.isOnHold));
        map.set("formCreatedBy", this.Part4Header.createdBy);
        map.set("formCreatedOn", String(this.Part4Header.createdDateTime));
        map.set("userRole", this.userRole);
        map.set("part4LookBack", "true");
        map.set("part5LookBack", "false");

        let jsonObject = {};
        map.forEach((value, key) => { jsonObject[key] = value; });
        this.projectservice.GetUserAccessListForLookBackForms(JSON.stringify(jsonObject)).subscribe((res: any) => {
          if (res) { this.part4LookBackAccessList = res; 
            // Making Form Editable for Power User.
            if(this.isPowerUser && this.projectData.IsSampleForm){
              this.Part4Header.isReadOnly = false;
              this.part4LookBackAccessList.data.isLocked = false;
            }

            if(!this.part4LookBackAccessList?.data?.canViewProject){
              this.router.navigate(["errorpage"]);
            }
          }
        });
      }
      this.notifyService.showSuccess("Data Load/Save successfully", "Status");
    });
    // // this.LoadLLCategoryData();
    // // this.LoadLLData();
    // // this.LoadPlanData();
    // // this.LoadKeyData();
  }

  LoadLessonLearnedData() {
    this.LoadLLCategoryData();
    this.LoadLLData();
    this.LoadPlanData();
    this.LoadKeyData();
  }

  UpdatePart4LookBackHeaderData(Part4Headerdata: Go36ArP4LookbackHeaderModel) {
    Part4Headerdata.modifiedBy = this.provId;
    const date = new Date(Part4Headerdata.lookbackDate!);
    Part4Headerdata.cvxShare = this.Part4Header.cvxShare == '' || this.Part4Header.cvxShare == null ? '' : parseFloat(Part4Headerdata.cvxShare);
    Part4Headerdata.lookbackDate = this.notifyService.ConvertDateBeforeSave(date);
    this.part4lookbackService.UpdatePart4LookBack(Part4Headerdata).subscribe(res => {
      res;
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
    if (this.Part4Header != null &&  this.Part4Header.cvxShare != null) {
      this.Part4Header.cvxShare = Number(this.Part4Header.cvxShare).toString();
      this.Part4Header.cvxShare =  this.Part4Header.cvxShare + '%';
    }
    Part4Headerdata.lookbackDate = this.notifyService.ConvertDateBeforeSave(date);
  }

  UpdatePart4LookBackHeaderDates(Part4Headerdata: Go36ArP4LookbackHeaderModel) {
    const date = new Date(Part4Headerdata.lookbackDate!);
    Part4Headerdata.lookbackDate = this.notifyService.ConvertDateBeforeSave(date);

    const ardate = new Date(Part4Headerdata.arDate!);
    Part4Headerdata.arDate = this.notifyService.ConvertDateBeforeSave(ardate);

    this.part4lookbackService.UpdatePart4LookBack(Part4Headerdata).subscribe(res => {
      res;
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    Part4Headerdata.lookbackDate = this.notifyService.ConvertDateAfterSave(date);
    Part4Headerdata.arDate = this.notifyService.ConvertDateAfterSave(ardate);
  }
  NAMidPhase4EventCheck(event: MatSlideToggleChange, row: Go36ArP4LookbackHeaderModel) {
    row.modifiedBy = this.provId;
    row.midPhase4Na = event.checked;
    this.part4lookbackService.UpdatePart4LookBack(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  IdentifiedLookBackTypeCheck(event: any, Part4Header: Go36ArP4LookbackHeaderModel, checktype: string) {
    Part4Header.modifiedBy = this.provId;
    if (checktype == "lookbackType1") { Part4Header.lookbackType1 = event.target.checked; }
    else if (checktype == "lookbackType2") { Part4Header.lookbackType2 = event.target.checked; }
    else if (checktype == "lookbackType3") { Part4Header.lookbackType3 = event.target.checked; }
    else if (checktype == "lookbackType4") { Part4Header.lookbackType4 = event.target.checked; }

    else if (checktype == "singleProject") { Part4Header.singleProject = event.target.checked; }
    else if (checktype == "multiProject") { Part4Header.multiProject = event.target.checked; }
    else if (checktype == "nojv") { Part4Header.nojv = event.target.checked; }

    // // Part4Header.lookbackType4 = event.target.checked;
    // // Part4Header.singleProject = event.target.checked;
    // // Part4Header.multiProject = event.target.checked;
    // // Part4Header.nojv = event.target.checked;
    this.part4lookbackService.UpdatePart4LookBack(Part4Header).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  ShowOnHoldModal() {
    const message = "When the HOLD button is activated, the form for this project will be placed on a “Hold” status. Fields on the forms cannot be edited while form is on “HOLD” status. Only users with administrator rights can put a form on HOLD or remove it."
      + "<br/>" + "You should use HOLD status when you want to keep the information entered in a form for future use. Otherwise, you may consider deleting the form";
    const dialogData = new InfoDialogModel("Hold", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  onChange(value: MatSlideToggleChange, Part4Headerdata: Go36ArP4LookbackHeaderModel) {
    this.isOnHold = value.checked;

    Part4Headerdata.modifiedBy = this.provId;
    Part4Headerdata.isOnHold = this.isOnHold;

    this.part4lookbackService.UpdateOnholdPart4LookBack(Part4Headerdata).subscribe(res => {
      //update value in form reviewer log
      this.getLogData();
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  OpenArchiveDialog() {
    this.dialog.open(ArchiveDialogComponent, {
      width: "600px",
      data: { projectId: this.projectId, formId: this.Part4Header.originalId, oldVersionId: this.Part4Header.version, formType: 'Part4' },
    });
  }

  Section7Attachments(event: any, Part4Header: Go36ArP4LookbackHeaderModel, checktype: string) {
    Part4Header.modifiedBy = this.provId;

    if (checktype == "IPA") { Part4Header.attachIpa = event.target.checked; }
    else if (checktype == "rootCause") { Part4Header.attachL1RootCause = event.target.checked; }
    else if (checktype == "midPhase") { Part4Header.attachL1MidPhase = event.target.checked; }
    else if (checktype == "reviewTeam") { Part4Header.attachL1ReviewTeam = event.target.checked; }
    else if (checktype == "exploration") { Part4Header.attachL1ExplorationProspect = event.target.checked; }
    else if (checktype == "basisOfDesign") { Part4Header.attachL1SubsurfaceBasisDesign = event.target.checked; }

    this.part4lookbackService.UpdatePart4LookBack(Part4Header).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }
  UpdatePart4LookBacPerformanceData(Part4Performance: Go36ArP4LookbackPerformance) {
    this.Part4Performance.modifiedBy = this.provId;
    // //const dataDate = new Date(Part4Performance.dataDate!);
    // //Part4Performance.dataDate=new Date(Date.UTC(dataDate.getFullYear(), dataDate.getMonth(), dataDate.getDate()));
    this.CheckAndParse(this.Part4Performance);

    this.Part4Performance.fullCeCostTargetTotal = this.Part4Performance.fullCeCostTargetFid + this.Part4Performance.fullCeCostTargetPhase3
    this.Part4Performance.p4LookbackId = this.Part4Header.arP4LookbackId;

    this.part4lookbackService.UpdatePart4LookBackPerformance(this.Part4Performance).subscribe(res => {
      res;
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
    this.ConvertoOneDecimal(this.Part4Performance);

  }

  // // UpdatePart4LookBacPerformanceExecutionData(Part4Performancedata: any, columnname: string) {
  // //   this.Part4Performance.p4LookbackId = this.Part4Header.arP4LookbackId;
  // //   if (columnname == 'executionScheduleEv') {
  // //     this.Part4Performance.executionScheduleEv = Number(this.Part4Performance.executionScheduleEv).toFixed(0).toString();
  // //   }
  // //   else if (columnname == 'executionScheduleP10') {
  // //     this.Part4Performance.executionScheduleP10 = Number(this.Part4Performance.executionScheduleP10).toFixed(0).toString();
  // //   }
  // //   else if (columnname == 'executionScheduleP90') {
  // //     this.Part4Performance.executionScheduleP90 = Number(this.Part4Performance.executionScheduleP90).toFixed(0).toString();
  // //   }
  // //   else if (columnname == 'executionScheduleActualOutcomes') {
  // //     this.Part4Performance.executionScheduleActualOutcomes = Number(this.Part4Performance.executionScheduleActualOutcomes).toFixed(0).toString();
  // //   }
  // //   else if (columnname == 'executionScheduleBenchTopQuartile') {
  // //     this.Part4Performance.executionScheduleBenchTopQuartile = Number(this.Part4Performance.executionScheduleBenchTopQuartile).toFixed(0).toString();
  // //   }
  // //   else if (columnname == 'executionScheduleBenchIndustryAverage') {
  // //     this.Part4Performance.executionScheduleBenchIndustryAverage = Number(this.Part4Performance.executionScheduleBenchIndustryAverage).toFixed(0).toString();

  // //   }
  // //   this.part4lookbackService.UpdatePart4LookBackPerformance(this.Part4Performance).subscribe(res => {
  // //     res;
  // //     this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
  // //   });
  // // }

  uploadFile = (event: any) => {
    const file = event.target.files[0];
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.part4lookbackService.saveDocument(this.Part4Header.arP4LookbackId, this.provId, formData).subscribe((res: any) => {
      this.notifyService.showSuccess("File uploaded successfully.", "");
      this.part4Document = res;
    });
  }

  DownloadFile(lookbackDocument: Go36P4LookbackDocument) {

    this.part4lookbackService.downloadFile(lookbackDocument.documentId).subscribe((data: any) => {
      let downloadURL = window.URL.createObjectURL(data);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = lookbackDocument.docName;// //name == null ? '' : name;
      link.click();
    });
  }

  RemoveFile(document: Go36P4LookbackDocument, index: any) {
    document.modifiedBy = this.provId;
    document.hidefromlist = true;
    this.part4Document.splice(index, 1);

    this.part4lookbackService.DeleteDocument(document).subscribe(res => {
      // //this.part4Document = res;
    });
  }

  CreatePart5LookBack() {
    this.route.queryParams
      .subscribe(params => {
        this.projectId = params['projectId'];
        this.part5lookbackService.CreatePart5LookBack(this.projectId, this.objectId, this.provId).subscribe((res) => {
          if (res.go36ArP5LookbackHeaders != null) {
            this.part5Data = res;
            this.Part5Header = res.go36ArP5LookbackHeaders;
            this.router.navigate(["/forms/part5"], { queryParams: { projectId: this.projectId, formId: this.Part5Header.originalId, versionName: this.Part5Header.version, formCase: 'Create' } });
          }
        }
        );
      });
  }

  GetUserRoleFromLocalStorage() {
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);

    // we can create the constants of the users role
    if (this.userAccessDetails?.data?.isPowerUser) {
      this.userRole = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      this.userRole = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      this.userRole = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      this.userRole = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      this.userRole = "Leadership";
    }
  }


  onReady(editor, name) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.UpdateckeditorChanges(editor.getData(), name);
        }
      }
    });
  }

  onReadyLLCK(editor, name, type) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        this.UpdateLLCkeditorChanges(editor.getData(), name, type);
      }
    });
  }

  NAAssetEventCheck(event: MatSlideToggleChange, row: Go36ArP4LookbackHeaderModel) {
    row.modifiedBy = this.provId;
    row.assetNa = event.checked;
    this.part4lookbackService.UpdatePart4LookBack(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  NAFDEventCheck(event: MatSlideToggleChange, row: Go36ArP4LookbackHeaderModel) {
    row.modifiedBy = this.provId;
    row.facilitiesDefinitionNa = event.checked;
    this.part4lookbackService.UpdatePart4LookBack(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  NAWDEventCheck(event: MatSlideToggleChange, row: Go36ArP4LookbackHeaderModel) {
    row.modifiedBy = this.provId;
    row.wellsDifinitionNa = event.checked;
    this.part4lookbackService.UpdatePart4LookBack(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  NARDEventCheck(event: MatSlideToggleChange, row: Go36ArP4LookbackHeaderModel) {
    row.modifiedBy = this.provId;
    row.reservoirDifinitionNa = event.checked;
    this.part4lookbackService.UpdatePart4LookBack(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  // Form Reviewer Methods

  getReviewerData() {
    this.formReviewerService.getReviewerData(this.formId, this.projectId, this.versionName, this.objectId, this.provId, "AR_LOOKBACK_PART4").subscribe(res => {
      this.reviewerData = res;
      if (this.reviewerData?.length != 0) {
        this.reviewerCount = true;
      }
    });
  }

  getLogData() {
    this.formReviewerService.getLogData(this.formId, this.projectId, this.versionName, "AR_LOOKBACK_PART4").subscribe(res => {
      this.formsLogData = res;
    });
  }

  getSignData() {
    this.formReviewerService.getPartFourSignOffData(this.Part4Header.arP4LookbackId, this.Part4Header.version).subscribe(res => {
      this.signOffData = res;
      this.signOffData.forEach(obj => {
        this.UpdateSignOff(obj,1);
      });


  
    }
    );
  }

  UpdateSignOff(signOff: LookBackFormSignoffs,count:any) {
    signOff.modifiedBy = this.provId;
    const date = new Date(signOff.signoffDate!);

    if (signOff.signoffDate != null) {
      signOff.signoffDate = this.notifyService.ConvertDateBeforeSave(date);
    }
    this.formReviewerService.postLookBackSignOffData(signOff).subscribe(res => {
      if(count==2){
        this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
      }
 
    });
    if (signOff.signoffDate != null) {
      signOff.signoffDate = this.notifyService.ConvertDateAfterSave(date);
    }
  }

  SearchUser(event: any) {
    // // let name = this.searchStr;
  }

  addUserToAccessList(event: any) {
    this.selectedUserObjectId = event.originalObject.objectId;
    this.selectedUserProvId = event.originalObject.provId;
    this.AddNewUserAccess(this.projectId, this.formId, this.versionName, this.objectId, this.provId, this.selectedUserObjectId, this.selectedUserProvId);
  }

  AddNewUserAccess(ProjectId: number, formId: number, versionId: number, objectId: string, provId: string, selectedUserObjectId: string, selectedUserProvId: string) {
    this.formReviewerService.PostUseraccess(ProjectId, formId, versionId, objectId, provId, selectedUserObjectId, selectedUserProvId, "AR_LOOKBACK_PART4").subscribe(res => {
      this.getReviewerData()
      this.getLogData();
    });
  }

  UpdateReviewer(id: number, action: string,reviewItem:FormAction, comments?: string) {
    if (comments == null || comments == '') {
      this.notifyService.showError("Please enter comments before Form Action", "Warning");
    }
    else {
      this.formActionModel.projectId = this.projectId;
      this.formActionModel.projectName = this.projectData.projectName;
      this.formActionModel.formId = this.formId;
      this.formActionModel.versionId = this.versionName;
      this.formActionModel.formType = "AR_LOOKBACK_PART4";
      this.formActionModel.comments = comments;
      this.formActionModel.formActionId = id;
      this.formActionModel.action = action;
      this.formActionModel.objectId = this.objectId;
      this.formActionModel.provId = this.provId;
      this.formActionModel.currentUrl = this.href;
      this.formActionModel.currentBUrl = this.bUrl;

      this.formActionModel.submitterUserId = reviewItem.submitterUserId;
      this.formActionModel.reviewerUserId = reviewItem.reviewerUserId;

      this.formReviewerService.approvalForm(this.formActionModel).subscribe(res => {
        this.getLogData();
        this.getReviewerData();
      });
    }
  }

  DeleteReviewer(formAction: FormAction, opertionType: string) {
    formAction.action = opertionType;
    formAction.active = false;

    formAction.submitterComments = formAction.submitterComments || '';
    this.formReviewerService.postDeleteReviewerData(formAction).subscribe(res => {
      this.getLogData();
      this.getReviewerData();
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
  }

  deleteRow(index: any) {
    this.reviewerData.splice(index, 1);
  }

  addRow(event: any) {
    this.AddNewUserAccess(this.projectId, this.formId, this.versionName, this.objectId, this.provId, this.selectedUserObjectId, this.selectedUserProvId);
  }

  ShowSignoffsPopUp() {
    const message = 'Outline approvals needed for investment appropriation. Wet signatures, where still applicable,'
      + '\n' + 'can be done in signature & date boxes on final printout';
    const dialogData = new InfoDialogModel("Signoffs", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  addDefaultUserAccess() {
    if (localStorage.getItem("goProject")) {
      const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")))
      this.data = { goprojectmodel: JSON.parse(projectDetail) };
    }
    this.AddNewUserAccess(this.projectId, this.formId, this.versionName,  this.objectId, this.provId, this.selectedUserObjectId, this.selectedUserProvId);
  }

  GetUserAccesslist() {
    if (this.data.goprojectmodel.ID == null) {
      this.ProjectId = this.data.goprojectmodel.ProjectId;
    }
    else {
      this.ProjectId = this.data.goprojectmodel.ID;
    }

    this.projectservice.getUserAccess(this.ProjectId).subscribe(res => {
      this.reviewerData = res;
    });
  }

  OpenApprovalDialog(reviewerId: number, reviewerComments?: string) {
    if (reviewerComments == null || reviewerComments == '') {
      this.notifyService.showError("Please enter comments before Form Action", "Warning");
    }
    else {
      this.dialog.open(ApprovalDialogComponent, {
        width: "600px",
        data: { projectId: this.projectId, projectName: this.projectData.projectName, formId: this.formId, versionId: this.versionName, formType: "AR_LOOKBACK_PART4", reviewerId: reviewerId, reviewerComments },
      });
    }
  }

  ConvertoOneDecimal(Part4Performance: Go36ArP4LookbackPerformance) {
    Part4Performance.ceProbabilisticEv = Number(Part4Performance.ceProbabilisticEv).toFixed(1).toString();
    Part4Performance.ceProbabilisticP10 = Number(Part4Performance.ceProbabilisticP10).toFixed(1).toString();
    Part4Performance.ceProbabilisticP90 = Number(Part4Performance.ceProbabilisticP90).toFixed(1).toString();
    Part4Performance.fullCeCostTargetFid = Number(Part4Performance.fullCeCostTargetFid).toFixed(1).toString();
    Part4Performance.fullCeCostTargetPhase3 = Number(Part4Performance.fullCeCostTargetPhase3).toFixed(1).toString();
    Part4Performance.fullCeCostTargetTotal = Number(Part4Performance.fullCeCostTargetTotal).toFixed(1).toString();
    Part4Performance.fullCeCostTargetActualOutcomes = Number(Part4Performance.fullCeCostTargetActualOutcomes).toFixed(1).toString();
    Part4Performance.fullCeCostTargetNormalized = Number(Part4Performance.fullCeCostTargetNormalized).toFixed(1).toString();
    Part4Performance.benchCeEv = Number(Part4Performance.benchCeEv).toFixed(1).toString();
    Part4Performance.benchCeActualOutcomes = Number(Part4Performance.benchCeActualOutcomes).toFixed(1).toString();
    Part4Performance.benchCeNormalized = Number(Part4Performance.benchCeNormalized).toFixed(1).toString();
    Part4Performance.benchCeBenchTopQuartile = Number(Part4Performance.benchCeBenchTopQuartile).toFixed(1).toString();
    Part4Performance.benchCeBenchIndustryAverage = Number(Part4Performance.benchCeBenchIndustryAverage).toFixed(1).toString();
    Part4Performance.expenseExecuteProjectP90 = Number(Part4Performance.expenseExecuteProjectP90).toFixed(1).toString();
    Part4Performance.expenseExecuteProjectP10 = Number(Part4Performance.expenseExecuteProjectP10).toFixed(1).toString();
    Part4Performance.expenseExecuteProjectEv = Number(Part4Performance.expenseExecuteProjectEv).toFixed(1).toString();
    Part4Performance.expenseExecuteProjectActualOutcomes = Number(Part4Performance.expenseExecuteProjectActualOutcomes).toFixed(1).toString();
    // //Part4Performance.keyPerformanceDriversUnit1 = Number(Part4Performance.keyPerformanceDriversUnit1).toFixed(1).toString();
    Part4Performance.key_Performance_Drivers_EV = Number(Part4Performance.key_Performance_Drivers_EV).toFixed(1).toString();
    Part4Performance.key_Performance_Drivers_P10 = Number(Part4Performance.key_Performance_Drivers_P10).toFixed(1).toString();
    Part4Performance.key_Performance_Drivers_P90 = Number(Part4Performance.key_Performance_Drivers_P90).toFixed(1).toString();
    Part4Performance.key_Performance_Drivers_ACTUAL_OUTCOMES = Number(Part4Performance.key_Performance_Drivers_ACTUAL_OUTCOMES).toFixed(1).toString();
    // //Part4Performance.keyPerformanceDrivers2=Number(Part4Performance.keyPerformanceDrivers2).toFixed(1).toString();
    // //Part4Performance.keyPerformanceDriversUnit2 = Number(Part4Performance.keyPerformanceDriversUnit2).toFixed(1).toString();
    Part4Performance.keyPerformanceDriversEV1 = Number(Part4Performance.keyPerformanceDriversEV1).toFixed(1).toString();
    Part4Performance.keyPerformanceDriversP101 = Number(Part4Performance.keyPerformanceDriversP101).toFixed(1).toString();
    Part4Performance.keyPerformanceDriversP901 = Number(Part4Performance.keyPerformanceDriversP901).toFixed(1).toString();
    Part4Performance.keyPerformanceDriversActualOutcomes1 = Number(Part4Performance.keyPerformanceDriversActualOutcomes1).toFixed(1).toString();
  }

  CheckAndParse(Part4Performance: Go36ArP4LookbackPerformance) {
    Part4Performance.ceProbabilisticEv = Part4Performance.ceProbabilisticEv == '' || Part4Performance.ceProbabilisticEv == null ? '' : parseFloat(Part4Performance.ceProbabilisticEv);
    Part4Performance.ceProbabilisticP10 = Part4Performance.ceProbabilisticP10 == '' || Part4Performance.ceProbabilisticP10 == null ? '' : parseFloat(Part4Performance.ceProbabilisticP10);
    Part4Performance.ceProbabilisticP90 = Part4Performance.ceProbabilisticP90 == '' || Part4Performance.ceProbabilisticP90 == null ? '' : parseFloat(Part4Performance.ceProbabilisticP90);
    Part4Performance.fullCeCostTargetFid = Part4Performance.fullCeCostTargetFid == '' || Part4Performance.fullCeCostTargetFid == null ? '' : parseFloat(Part4Performance.fullCeCostTargetFid);
    Part4Performance.fullCeCostTargetPhase3 = Part4Performance.fullCeCostTargetPhase3 == '' || Part4Performance.fullCeCostTargetPhase3 == null ? '' : parseFloat(Part4Performance.fullCeCostTargetPhase3);
    Part4Performance.fullCeCostTargetTotal = Part4Performance.fullCeCostTargetTotal == '' || Part4Performance.fullCeCostTargetTotal == null ? '' : parseFloat(Part4Performance.fullCeCostTargetTotal);
    Part4Performance.fullCeCostTargetActualOutcomes = Part4Performance.fullCeCostTargetActualOutcomes == '' || Part4Performance.fullCeCostTargetActualOutcomes == null ? '' : parseFloat(Part4Performance.fullCeCostTargetActualOutcomes);
    Part4Performance.fullCeCostTargetNormalized = Part4Performance.fullCeCostTargetNormalized == '' || Part4Performance.fullCeCostTargetNormalized == null ? '' : parseFloat(Part4Performance.fullCeCostTargetNormalized);
    Part4Performance.benchCeEv = Part4Performance.benchCeEv == '' || Part4Performance.benchCeEv == null ? '' : parseFloat(Part4Performance.benchCeEv);
    Part4Performance.benchCeActualOutcomes = Part4Performance.benchCeActualOutcomes == '' || Part4Performance.benchCeActualOutcomes == null ? '' : parseFloat(Part4Performance.benchCeActualOutcomes);
    Part4Performance.benchCeNormalized = Part4Performance.benchCeNormalized == '' || Part4Performance.benchCeNormalized == null ? '' : parseFloat(Part4Performance.benchCeNormalized);
    Part4Performance.benchCeBenchTopQuartile = Part4Performance.benchCeBenchTopQuartile == '' || Part4Performance.benchCeBenchTopQuartile == null ? '' : parseFloat(Part4Performance.benchCeBenchTopQuartile);
    Part4Performance.benchCeBenchIndustryAverage = Part4Performance.benchCeBenchIndustryAverage == '' || Part4Performance.benchCeBenchIndustryAverage == null ? '' : parseFloat(Part4Performance.benchCeBenchIndustryAverage);
    Part4Performance.expenseExecuteProjectEv = Part4Performance.expenseExecuteProjectEv == '' || Part4Performance.expenseExecuteProjectEv == null ? '' : parseFloat(Part4Performance.expenseExecuteProjectEv);
    Part4Performance.expenseExecuteProjectP10 = Part4Performance.expenseExecuteProjectP10 == '' || Part4Performance.expenseExecuteProjectP10 == null ? '' : parseFloat(Part4Performance.expenseExecuteProjectP10);
    Part4Performance.expenseExecuteProjectP90 = Part4Performance.expenseExecuteProjectP90 == '' || Part4Performance.expenseExecuteProjectP90 == null ? '' : parseFloat(Part4Performance.expenseExecuteProjectP90);
    Part4Performance.expenseExecuteProjectActualOutcomes = Part4Performance.expenseExecuteProjectActualOutcomes == '' || Part4Performance.expenseExecuteProjectActualOutcomes == null ? '' : parseFloat(Part4Performance.expenseExecuteProjectActualOutcomes);
    // //Part4Performance.keyPerformanceDrivers1 =  Part4Performance.keyPerformanceDrivers1 == ''||  Part4Performance.keyPerformanceDrivers1 == null ? '' : parseFloat( Part4Performance.keyPerformanceDrivers1);
    // //Part4Performance.keyPerformanceDriversUnit1 = Part4Performance.keyPerformanceDriversUnit1 == '' || Part4Performance.keyPerformanceDriversUnit1 == null ? '' : parseFloat(Part4Performance.keyPerformanceDriversUnit1);
    Part4Performance.key_Performance_Drivers_EV = Part4Performance.key_Performance_Drivers_EV == '' || Part4Performance.key_Performance_Drivers_EV == null ? '' : parseFloat(Part4Performance.key_Performance_Drivers_EV);
    Part4Performance.key_Performance_Drivers_P10 = Part4Performance.key_Performance_Drivers_P10 == '' || Part4Performance.key_Performance_Drivers_P10 == null ? '' : parseFloat(Part4Performance.key_Performance_Drivers_P10);
    Part4Performance.key_Performance_Drivers_P90 = Part4Performance.key_Performance_Drivers_P90 == '' || Part4Performance.key_Performance_Drivers_P90 == null ? '' : parseFloat(Part4Performance.key_Performance_Drivers_P90);
    Part4Performance.key_Performance_Drivers_ACTUAL_OUTCOMES = Part4Performance.key_Performance_Drivers_ACTUAL_OUTCOMES == '' || Part4Performance.key_Performance_Drivers_ACTUAL_OUTCOMES == null ? '' : parseFloat(Part4Performance.key_Performance_Drivers_ACTUAL_OUTCOMES);
    // //Part4Performance.keyPerformanceDrivers2 =  Part4Performance.keyPerformanceDrivers2 == ''||  Part4Performance.keyPerformanceDrivers2 == null ? '' : parseFloat( Part4Performance.keyPerformanceDrivers2);
    // //Part4Performance.keyPerformanceDriversUnit2 = Part4Performance.keyPerformanceDriversUnit2 == '' || Part4Performance.keyPerformanceDriversUnit2 == null ? '' : parseFloat(Part4Performance.keyPerformanceDriversUnit2);
    Part4Performance.keyPerformanceDriversEV1 = Part4Performance.keyPerformanceDriversEV1 == '' || Part4Performance.keyPerformanceDriversEV1 == null ? '' : parseFloat(Part4Performance.keyPerformanceDriversEV1);
    Part4Performance.keyPerformanceDriversP101 = Part4Performance.keyPerformanceDriversP101 == '' || Part4Performance.keyPerformanceDriversP101 == null ? '' : parseFloat(Part4Performance.keyPerformanceDriversP101);
    Part4Performance.keyPerformanceDriversP901 = Part4Performance.keyPerformanceDriversP901 == '' || Part4Performance.keyPerformanceDriversP901 == null ? '' : parseFloat(Part4Performance.keyPerformanceDriversP901);
    Part4Performance.keyPerformanceDriversActualOutcomes1 = Part4Performance.keyPerformanceDriversActualOutcomes1 == '' || Part4Performance.keyPerformanceDriversActualOutcomes1 == null ? '' : parseFloat(Part4Performance.keyPerformanceDriversActualOutcomes1);

    Part4Performance.trirActualOutcomes = this.utilityService.AllowTwoDecimal(Number(Part4Performance.trirActualOutcomes));
    Part4Performance.dafwrActualOutcomes = this.utilityService.AllowTwoDecimal(Number(Part4Performance.dafwrActualOutcomes));
    Part4Performance.executionScheduleEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.executionScheduleEv));
    Part4Performance.executionScheduleP10 = this.utilityService.AllowNoDecimal(Number(Part4Performance.executionScheduleP10));
    Part4Performance.executionScheduleP90 = this.utilityService.AllowNoDecimal(Number(Part4Performance.executionScheduleP90));
    Part4Performance.executionScheduleActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.executionScheduleActualOutcomes));
    Part4Performance.executionScheduleBenchTopQuartile = this.utilityService.AllowNoDecimal(Number(Part4Performance.executionScheduleBenchTopQuartile));
    Part4Performance.executionScheduleBenchIndustryAverage = this.utilityService.AllowNoDecimal(Number(Part4Performance.executionScheduleBenchIndustryAverage));

    ////4i
    Part4Performance.estUltimateRecoveryMmboActualOutcomes = this.utilityService.AllowNoDecimal(Number(this.Part4Performance.estUltimateRecoveryMmboActualOutcomes));
    Part4Performance.estUltimateRecoveryMmboEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboEv));
    Part4Performance.estUltimateRecoveryMmboP10 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboP10));
    Part4Performance.estUltimateRecoveryMmboP90 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboP90));

    Part4Performance.estUltimateRecoveryMmboeActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboeActualOutcomes));
    Part4Performance.estUltimateRecoveryMmboeEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboeEv));
    Part4Performance.estUltimateRecoveryMmboeP10 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboeP10));
    Part4Performance.estUltimateRecoveryMmboeP90 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboeP90));

    Part4Performance.estUltimateRecoveryBcfActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryBcfActualOutcomes));
    Part4Performance.estUltimateRecoveryBcfEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryBcfEv));
    Part4Performance.estUltimateRecoveryBcfP10 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryBcfP10));
    Part4Performance.estUltimateRecoveryBcfP90 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryBcfP90));

    Part4Performance.estUltimateRecovery88MmboeActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecovery88MmboeActualOutcomes));
    Part4Performance.estUltimateRecovery88MmboeP10 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecovery88MmboeP10));
    Part4Performance.estUltimateRecovery88MmboeEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecovery88MmboeEv));
    Part4Performance.estUltimateRecovery88MmboeP90 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecovery88MmboeP90));

    Part4Performance.peakProductionMbpdActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.peakProductionMbpdActualOutcomes));
    Part4Performance.peakProductionMbpdEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.peakProductionMbpdEv));
    Part4Performance.peakProductionMbpdP10 = this.utilityService.AllowNoDecimal(Number(Part4Performance.peakProductionMbpdP10));
    Part4Performance.peakProductionMbpdP90 = this.utilityService.AllowNoDecimal(Number(Part4Performance.peakProductionMbpdP90));

    Part4Performance.peakProductionMmcfdActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.peakProductionMmcfdActualOutcomes));
    Part4Performance.peakProductionMmcfdEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.peakProductionMmcfdEv));
    Part4Performance.peakProductionMmcfdP10 = this.utilityService.AllowNoDecimal(Number(Part4Performance.peakProductionMmcfdP10));
    Part4Performance.peakProductionMmcfdP90 = this.utilityService.AllowNoDecimal(Number(Part4Performance.peakProductionMmcfdP90));

    Part4Performance.month12MmboActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.month12MmboActualOutcomes));
    Part4Performance.month12MmboEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.month12MmboEv));
    Part4Performance.month1288MmboActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.month1288MmboActualOutcomes));
    Part4Performance.month1288MmboEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.month1288MmboEv));

    Part4Performance.month12BcfActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.month12BcfActualOutcomes));
    Part4Performance.month12BcfEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.month12BcfEv));
    Part4Performance.month1288BcfActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.month1288BcfActualOutcomes));
    Part4Performance.month1288BcfEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.month1288BcfEv));
  }
}