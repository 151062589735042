import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppsettingsService } from 'src/app/Shared/appsettings.service';
import { AppendicesModel } from 'src/app/Model/Appendices.model';

@Injectable({
  providedIn: 'root'
})

export class ProjectDocumentationService {

  GetUrl = 'api/ProjectDocumentation/getAppendixData?formId=';
  FormRisksUrl = 'api/Risks/SaveFormRiskData';
  Deleteurl = 'api/ProjectDocumentation/DeletePhase';
  UploadUrl = 'api/ProjectDocumentation/Upload';
  CreateDefaultUrl = 'api/ProjectDocumentation/CreateAppendixData';
  DownloadUrl = 'api/ProjectDocumentation/Download';
  SaveUrl = 'api/ProjectDocumentation/SaveAppendixData';

  constructor(private http: HttpClient,
    private appsettings: AppsettingsService) { }

  getData(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetUrl + formId);
  }

  createDefaultData(formId: number, provId: string, isOldVersionOfForms:boolean) {
    const params = new HttpParams()
      .append('formId', formId)
      .append('provId', provId)
      .append('isOldVersionOfForms', isOldVersionOfForms);

    return this.http.post<any>(this.appsettings.apiBaseUrl + this.CreateDefaultUrl, '', {
      params: params
    });
  }

  deletePhase(formId: number, originalId: number, provId: string) {
    const params = new HttpParams()
      .append('formId', formId)
      .append('originalId', originalId)
      .append('provId', provId);
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.Deleteurl, '', {
      params: params
    });
  }

  uploadFile(id: number, file: FormData) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UploadUrl + '?id=' + id, file);
  }

  downloadFile(id: number, versionId: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get<AppendicesModel>(this.appsettings.apiBaseUrl + this.DownloadUrl + '/' + id + '/' + versionId, httpOptions);
  }

  SaveData(formData: any) {
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.SaveUrl, formData);
  }
}
