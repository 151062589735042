import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CalAngularService } from '@cvx/cal-angular';
import * as signalR from '@microsoft/signalr';
import { UserActivity } from '../Model/UserActivity.model';
import { AppsettingsService } from './appsettings.service';
@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  userActivityDataList : UserActivity[] = [];
  formId: number = 0;
  formCase: string | null = '';
  constructor(private authService: CalAngularService, private appsettingsService: AppsettingsService, private route: ActivatedRoute) { }
  private hubConnection!: signalR.HubConnection;
  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.appsettingsService.apiBaseUrl + 'userActivity', {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();
    return this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  public broadcastActiveUsersData = (data: UserActivity) => {
    this.hubConnection.invoke('Send', data)
    .catch(err => this.startConnection());
  }
  public addBroadcastActiveUsersListener = () => {
    return this.hubConnection.on('BroadcastActiveUsers', (userActivityData) => {
        if(userActivityData.formId == this.formId){
          if(userActivityData.provisioningId != null && userActivityData.provisioningId != 'undefined' && this.authService.cvxClaimsPrincipal.provisioningId != null && this.authService.cvxClaimsPrincipal.provisioningId != 'undefined' && this.authService.cvxClaimsPrincipal.provisioningId != userActivityData.provisioningId){
              let newItem = new UserActivity();
              newItem.objectId = userActivityData.objectId;
              newItem.firstName = userActivityData.firstName;
              newItem.lastName = userActivityData.lastName;
              newItem.provisioningId = userActivityData.provisioningId;
              newItem.email = userActivityData.email;
              newItem.timestamp = userActivityData.timestamp;
              let existingItem = this.userActivityDataList.find((data) => {
                return userActivityData.objectId == data.objectId;
              });
              if (existingItem && existingItem.objectId == newItem.objectId) {
                  let existingIndex = this.userActivityDataList.indexOf(existingItem);
                  this.userActivityDataList.splice(existingIndex,1, newItem);
              } 
              else {
                this.userActivityDataList.push(newItem);
              } 
          }
          let timeDiff = 0;
          this.userActivityDataList.forEach((element,index) => {
              timeDiff = (userActivityData.timestamp - element.timestamp);
              if(timeDiff > 30){
                this.userActivityDataList.splice(index,1);
              }
          });
        }
     });
  }
}
