import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LessonsLearnedService } from 'src/app/forms/FEEDServices/lessons-learned.service';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { LessonLearnedCategory } from 'src/app/Model/LessonLearnedCategory.model';
import { LessonLearnedType } from 'src/app/Model/LessonLearnedType.model';
import { LessonLearnedDetail } from 'src/app/Model/LessonsLearned.model';
import { ProjectData } from 'src/app/Model/ProjectData.model';
import { Go36projectService } from '../service/go36project.service';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { UtilityService } from 'src/app/Shared/utility.service';
@Component({
  selector: 'app-lessons-learned-dashboard',
  templateUrl: './lessons-learned-dashboard.component.html',
  styleUrls: ['./lessons-learned-dashboard.component.css']
})

export class LessonsLearnedDashboardComponent implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
    ],
    autosave: {
      save(editor) {
      }
    }
  }
  projectId: number = 0;
  projectData = new ProjectData();
  selectedPhase: any = 0;
  formId: number = 0;
  latestPhase: number = 0;
  LLType = new LessonLearnedType();
  PlanType = new LessonLearnedType();
  KeyType = new LessonLearnedType();
  LLTypeData: LessonLearnedDetail[] = [];
  PlanTypeData = new LessonLearnedDetail();
  KeyTypeData: LessonLearnedDetail[] = [];
  LLCategoryData: LessonLearnedCategory[] = [];
  formLL: UntypedFormGroup;
  formKD: UntypedFormGroup;
  lessonForm: UntypedFormGroup;
  keyDecisionForm: UntypedFormGroup;
  LLPhaseData: any[] = [];
  SupplementData: any[] = [];
  selectedSupplement: number = 0;
  @Input() type: string = "";
  ARType: string = "";
  isNAEnabled: boolean = false;
  constructor(public dialog: MatDialog, private route: ActivatedRoute,
    private projectservice: Go36projectService, private lessonLearnedService: LessonsLearnedService,
    private fb: UntypedFormBuilder, public utilityService: UtilityService) {
    this.formLL = this.fb.group({
      lessonsLearned: this.fb.array([])
    })
    this.lessonForm = this.fb.group({
    })
    this.formKD = this.fb.group({
      keyDecision: this.fb.array([])
    })
    this.keyDecisionForm = this.fb.group({
    })
  }
  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.projectId = params['projectId'];
      });
    this.getProjectInfobyId();
    this.getLatestFormByType();
  }

  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }

  ShowLessonLearnedModal() {
    const message = "Through the lifecycle of the project, there are several opportunities to capture leanings that can be valuable for future projects. These fields are to be utilized to capture Lessons Learned / Key Insights that were identified in a certain Phase of the project." +
      "<br><br> Majority of Lessons Learned are usually more technical or tactical and they are captured in the project’s Lessons Learned Register / iPIMS" +
      "<br><br> Use these fields to share the more relevant and the strategical learnings that can either have the potential to be incorporated in the future into Chevron Project Management IP or learnings that are more strategic in their nature, and valuable for future generations of projects to come"
    const dialogData = new InfoDialogModel("Lesson Learned", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }
  ShowPlanModal() {
    const message = "It is extremely relevant to capture information and lessons learned but if these are not shared within the BU or across SBUs and OPCOs, their potential value is not maximized." +
      "<br><br> This field is reserved for the Project Team to share what actions have already be taken and the ones that will be taken to share the Lessons Learned and Key Insights above with the rest of the Chevron organization."
    const dialogData = new InfoDialogModel("Plan", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }
  ShowKeyDecisionsModal() {
    const message = "Throughout the lifecycle of the project, there are several decisions that are taken and registered into the project’s Decision Register. Some of these decisions are critical for the outcome of the project as well as to enable the project lookback to be performed using the appropriate amount of information." +
      "<br><br> These fields are to be utilized to capture the key Decision that were taken recently during the project Phase and led to the current status of the project or the request being made." +
      "<br><br> Use these fields to share the more relevant and the strategical decisions that influenced the opportunity being pursued, alternative selected, execution strategy and any other project team feel relevant."
    const dialogData = new InfoDialogModel("Key Decisions", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }
  getProjectInfobyId() {
    this.projectservice.GetProjectInfobyId(this.projectId).subscribe(res => {
      this.projectData = res;
    });
  }
  getLatestFormByType() {
    this.lessonLearnedService.GetLatestFormVersionLLByType(this.projectId, this.type).subscribe(res => {
      this.formId = res.formId;
      this.isNAEnabled = res.isNAEnabled;
      if (this.type == 'Phase' && this.formId > 0 && res.phase > 0) {
        this.getLLPhasesByProjectId(res.phase);
      }
      else if ((this.type == 'FEED Supplement' || this.type == 'FID Supplement') && this.formId > 0 && res.phase > 0) {
        this.getAllSupplementsByProjectId(res.phase);
      }
      else
        this.ARType = this.type;
      if (this.formId > 0) {
        this.LoadLLData();
        this.LoadPlanData();
        this.LoadKeyData();
      }
    });
  }
  ClearLLData() {
    this.LLTypeData = [];
    this.KeyTypeData = [];
    this.clearFormArray(this.lessonsLearned);
    this.clearFormArray(this.keyDecision);
  }
  clearFormArray = (formArray: UntypedFormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }
  onLLPhaseChange(event: any) {
    this.selectedPhase = Number(event.target.value);
    this.ARType = this.selectedPhase == 5 ? `Phase-5 - P4` : (this.selectedPhase == 6 ? `Phase-5 - P5` : `Phase-${this.selectedPhase}`);
    this.lessonLearnedService.getLLFormIdOnPhaseChange(this.projectId, 1, event.target.value).subscribe((res: any) => {
      this.formId = Number(res.formId);
      this.isNAEnabled = res.isNAEnabled;
      this.ClearLLData();
      this.LoadLLData();
      this.LoadPlanData();
      this.LoadKeyData();
    });
  }
  onSupplementChange(event: any) {
    this.selectedSupplement = event.target.value;
    this.ARType = `${this.type} #${this.selectedSupplement}`;
    this.lessonLearnedService.GetSupplementFormIdOnFormChange(this.projectId, this.type, event.target.value).subscribe((res: any) => {
      this.formId = Number(res.formId);
      this.isNAEnabled = res.isNAEnabled;
      this.ClearLLData();
      this.LoadLLData();
      this.LoadPlanData();
      this.LoadKeyData();
    });
  }
  getLLPhasesByProjectId(phase: any) {
    this.lessonLearnedService.getLLPhasesByProjectId(this.projectId, this.formId, phase).subscribe((res: any) => {
      if (this.LLPhaseData == undefined || this.LLPhaseData == null || this.LLPhaseData.length <= 0)
        this.LLPhaseData = res.phases;
      this.selectedPhase = phase;
      this.ARType = phase == 5 ? `Phase-5 - P4` : (phase == 6 ? `Phase-5 - P5` : `Phase-${this.selectedPhase}`);
    });
  }
  getAllSupplementsByProjectId(phase: any) {
    this.lessonLearnedService.GetAllSupplementsByProjectId(this.projectId, this.type).subscribe((res: any) => {
      if (this.SupplementData == undefined || this.SupplementData == null || this.SupplementData.length <= 0)
        this.SupplementData = res.supplements;
      this.selectedSupplement = phase;
      this.ARType = `${this.type} #${this.selectedSupplement}`;
    });
  }
  get lessonsLearned(): any {
    return this.formLL.get('lessonsLearned') as UntypedFormArray;
  }
  get keyDecision(): any {
    return this.formKD.get('keyDecision') as UntypedFormArray;
  }
  LoadLLData() {
    this.lessonLearnedService.getLessonLearnedData(this.formId, this.projectId, 1).subscribe(res => {
      this.LLTypeData = res as LessonLearnedDetail[];
      this.lessonLearnedService.getLLCategoryData().subscribe((res: LessonLearnedCategory[]) => {
        this.LLCategoryData = res;
        if (this.LLTypeData.length > 0) {
          this.LLTypeData.forEach((element, index) => {
            let formLLNew = this.fb.group({
              lessonLearnedCategoryId: (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) ? -1 : element.lessonLearnedCategoryId,
              title: element.title,
              description: element.description
            });
            this.lessonsLearned.push(formLLNew);
            // //  this.LLMaxOrder = element.order;
            if (this.LLTypeData[index].lessonLearnedCategoryId == undefined || this.LLTypeData[index].lessonLearnedCategoryId == null) {
              this.LLTypeData[index].lessonLearnedCategoryId = -1;
              this.lessonsLearned.controls[index].get("title")?.disable();
              this.LLTypeData[index].naLessonLearned = true;
            } else
              this.LLTypeData[index].naLessonLearned = false;
          });
        }
        else {
          this.LLDataIntialization(1);
          let formLLNew = this.fb.group({
            lessonLearnedCategoryId: this.LLTypeData[0].lessonLearnedCategoryId,
            title: this.LLTypeData[0].title,
            description: this.LLTypeData[0].description
          });
          this.lessonsLearned.push(formLLNew);
          this.lessonsLearned.controls[0].get("title")?.disable();
        }
      });
    });
  }
  LoadPlanData() {
    this.lessonLearnedService.getLessonLearnedData(this.formId, this.projectId, 2).subscribe(res => {
      this.PlanTypeData = res[0] as LessonLearnedDetail;
      if (this.PlanTypeData == undefined && this.PlanTypeData == null)
        this.PlanDataIntialization();
    })
  }
  LoadKeyData() {
    this.lessonLearnedService.getLessonLearnedData(this.formId, this.projectId, 3).subscribe(res => {
      this.KeyTypeData = res as LessonLearnedDetail[];
      if (this.KeyTypeData.length > 0) {
        // // if(this.KeyTypeData[0].nakeyDecision)
        // //   this.naKeyDecisionDisabled = true;
        // // else
        // //   this.naKeyDecisionDisabled = false;
        this.KeyTypeData.forEach(element => {
          let formKDNew = this.fb.group({
            title: element.title,
            description: element.description
          });
          this.keyDecision.push(formKDNew);
          // // this.KDMaxOrder = element.order;
        });
      }
      else {
        this.KeyDataIntialization(1);
        // // if(this.KeyTypeData[0].nakeyDecision)
        // //   this.naKeyDecisionDisabled = true;
        // // else
        // //   this.naKeyDecisionDisabled = false;
        let formKDNew = this.fb.group({
          title: this.KeyTypeData[0].title,
          description: this.KeyTypeData[0].description
        });
        this.keyDecision.push(formKDNew);
        // // this.getMaxOrder(3);
      }
    })
  }
  LLDataIntialization(order: any) {
    this.LLTypeData.push(new LessonLearnedDetail());
    this.LLTypeData[order - 1].lessonLearnedCategoryId = -1;
    this.LLTypeData[order - 1].formId = this.formId;
    this.LLTypeData[order - 1].projectId = this.projectId;
    this.LLTypeData[order - 1].lessonLearnedTypeId = 1;
    this.LLTypeData[order - 1].order = order;
    this.LLTypeData[order - 1].title = "";
    this.LLTypeData[order - 1].description = "";
    this.LLTypeData[order - 1].naLessonLearned = true;
  }
  PlanDataIntialization() {
    this.PlanTypeData = new LessonLearnedDetail();
    this.PlanTypeData.formId = this.formId;
    this.PlanTypeData.lessonLearnedCategoryId = undefined;
    this.PlanTypeData.projectId = this.projectId;
    this.PlanTypeData.lessonLearnedTypeId = 2;
    this.PlanTypeData.order = 1;
    this.PlanTypeData.description = "";
  }
  KeyDataIntialization(order: any) {
    this.KeyTypeData.push(new LessonLearnedDetail());
    this.KeyTypeData[order - 1].lessonLearnedCategoryId = undefined;
    this.KeyTypeData[order - 1].formId = this.formId;
    this.KeyTypeData[order - 1].projectId = this.projectId;
    this.KeyTypeData[order - 1].lessonLearnedTypeId = 3;
    this.KeyTypeData[order - 1].order = order;
    this.KeyTypeData[order - 1].title = "";
    this.KeyTypeData[order - 1].description = "";
    this.KeyTypeData[order - 1].nakeyDecision = false;
  }
}