import { Component, OnInit } from '@angular/core';
import { AssetModel } from '../asset.model';
import { AssetService } from '../../service/asset.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-asset-create',
  templateUrl: './asset-create.component.html',
  styleUrls: ['./asset-create.component.css']
})
export class AssetCreateComponent implements OnInit {
  assetData: AssetModel = new AssetModel();
  id: number = 0;
  data: any;
  editMode: boolean = false;
  assetId: number=0;
  formValue: UntypedFormGroup;
  

  constructor(private formbuilder: UntypedFormBuilder, private route: ActivatedRoute,
     private router: Router,
     private projectservice: Go36projectService,
     private apiService: AssetService,
     private utilityServcie: UtilityService) 
  {
    this.formValue = this.formbuilder.group ({
        name: ['', Validators.required],
        active: ['']
      })
  }

  ngOnInit(): void 
  {  
    this.route.queryParams
    .subscribe(params => {    
      this.assetId = Number(params['Id']);
      if(this.assetId > 0)
      this.editMode = true;
    });
    if (this.editMode) {
      this.apiService.getById("Asset/GetAssetDetailsById?Id=",this.assetId).subscribe(res => {
        this.formValue.controls["name"].setValue(res.name);
        this.formValue.controls["active"].setValue(res.active);
        this.assetData.id=res.id;
      });
    
    }
  }
  postAssetDetails() {
    this.assetData.active = true;
    this.assetData.name = this.formValue.value.name;
    this.assetData.createdBy = this.utilityServcie.GetuserProvisioningId();
    this.apiService.post("asset",this.assetData).subscribe((res) => {
      let ref = document.getElementById('cancel');
      ref?.click();
      this.formValue.reset();
      this.router.navigateByUrl("/manage/assets");
    });
  }

  updateAssetDetails() {
    this.assetData.active = this.formValue.value.active;
    this.assetData.name = this.formValue.value.name;
    this.assetData.modifiedBy = this.utilityServcie.GetuserProvisioningId();
    this.apiService.update("asset",this.assetData.id, this.assetData).subscribe(res => {
      let ref = document.getElementById('cancel');
      ref?.click();
      this.formValue.reset();
      this.router.navigateByUrl("/manage/assets");
    });
  }
}