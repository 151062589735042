import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { SubsurfaceViewModel } from '../../Model/subsurfaceView.model';
import { SsbodService } from '../../service/ssbod.service';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { LessonLearnedCategory } from 'src/app/Model/LessonLearnedCategory.model';
import { LessonLearnedDetail } from 'src/app/Model/LessonsLearned.model';
import { Form } from 'src/app/Model/Form.model';
import { LessonLearnedType } from 'src/app/Model/LessonLearnedType.model';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LessonsLearnedService } from 'src/app/forms/FEEDServices/lessons-learned.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/Modals/confirm-dialog/confirm-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UtilityService } from 'src/app/Shared/utility.service';
@Component({
  selector: 'app-ssbod-lessons-learned',
  templateUrl: './ssbod-lessons-learned.component.html',
  styleUrls: ['./ssbod-lessons-learned.component.css']
})
export class SsbodLessonsLearnedComponent implements OnInit {
  @Input() viewModel: SubsurfaceViewModel = new SubsurfaceViewModel();
  formId: number = 0;
  emailId: string = '';
  provId: string = '';
  @Input() type: string = '';
  @Input() formType: any = undefined;
  public Editor = customBuild;
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;

  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
    ],
    autosave: {
      save(editor) { }
    }
  }

  LLCategoryData: LessonLearnedCategory[] = [];
  form = new Form();
  LLTypeData: LessonLearnedDetail[] = [];
  LLSaveModel = new LessonLearnedDetail();
  LLUpdateModel = new LessonLearnedDetail();
  KDUpdateModel = new LessonLearnedDetail();
  KDSaveModel = new LessonLearnedDetail();
  PlanUpdateModel = new LessonLearnedDetail();
  LLType = new LessonLearnedType();
  PlanType = new LessonLearnedType();
  KeyType = new LessonLearnedType();
  PlanTypeData = new LessonLearnedDetail();
  KeyTypeData: LessonLearnedDetail[] = [];
  formLL: UntypedFormGroup;
  formKD: UntypedFormGroup;
  lessonForm: UntypedFormGroup;
  keyDecisionForm: UntypedFormGroup;
  naKeyDecisionDisabled: boolean = false;
  LLMaxOrder: number = 0;
  KDMaxOrder: number = 0;
  projectName: string = '';
  @Input() originalId: number = 0;
  projectId: number = 0;
  constructor(private ssbodService: SsbodService, public dialog: MatDialog, private route: ActivatedRoute, private go36ProjectService: Go36projectService
    , private lessonLearnedService: LessonsLearnedService, private notifyService: NotificationService,
    private fb: UntypedFormBuilder, public utilityService: UtilityService) {
    this.formLL = this.fb.group({
      lessonsLearned: this.fb.array([])
    })
    this.lessonForm = this.fb.group({
    })
    this.formKD = this.fb.group({
      keyDecision: this.fb.array([])
    })
    this.keyDecisionForm = this.fb.group({
    })
  }
  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formId = this.viewModel.Id;
    this.projectId = this.viewModel.SsbodProjectId;
    this.LoadLLData();
    this.LoadPlanData();
    this.LoadKeyData();
  }
  get lessonsLearned(): any {
    return this.formLL.get('lessonsLearned') as UntypedFormArray;
  }
  get keyDecision(): any {
    return this.formKD.get('keyDecision') as UntypedFormArray;
  }
  addNewLL(type: any): void {
    if (type == "LessonLearned") {
      this.lessonForm = this.fb.group({
        lessonLearnedCategoryId: [-1],
        title: [''],
        description: ['']
      });
      this.LLDataIntialization(this.LLTypeData.length + 1);
      this.lessonsLearned.push(this.lessonForm);
      this.lessonsLearned.controls[this.lessonsLearned.length - 1].get("title")?.disable();
    }
    else if (type == "KeyDecision") {
      this.keyDecisionForm = this.fb.group({
        title: [''],
        description: ['']
      });
      this.KeyDataIntialization(this.KeyTypeData.length + 1);
      this.keyDecision.push(this.keyDecisionForm);
    }
  }
  ShowLessonLearnedModal() {
    const message = "Through the lifecycle of the project, there are several opportunities to capture leanings that can be valuable for future projects. These fields are to be utilized to capture Lessons Learned / Key Insights that were identified in a certain Phase of the project." +
      "<br><br> Majority of Lessons Learned are usually more technical or tactical and they are captured in the project’s Lessons Learned Register / iPIMS" +
      "<br><br> Use these fields to share the more relevant and the strategical learnings that can either have the potential to be incorporated in the future into Chevron Project Management IP or learnings that are more strategic in their nature, and valuable for future generations of projects to come"
    const dialogData = new InfoDialogModel("Lesson Learned", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }
  ShowPlanModal() {
    const message = "It is extremely relevant to capture information and lessons learned but if these are not shared within the BU or across SBUs and OPCOs, their potential value is not maximized." +
      "<br><br> This field is reserved for the Project Team to share what actions have already be taken and the ones that will be taken to share the Lessons Learned and Key Insights above with the rest of the Chevron organization."
    const dialogData = new InfoDialogModel("Plan", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }
  ShowKeyDecisionsModal() {
    const message = "Throughout the lifecycle of the project, there are several decisions that are taken and registered into the project’s Decision Register. Some of these decisions are critical for the outcome of the project as well as to enable the project lookback to be performed using the appropriate amount of information." +
      "<br><br> These fields are to be utilized to capture the key Decision that were taken recently during the project Phase and led to the current status of the project or the request being made." +
      "<br><br> Use these fields to share the more relevant and the strategical decisions that influenced the opportunity being pursued, alternative selected, execution strategy and any other project team feel relevant."
    const dialogData = new InfoDialogModel("Key Decisions", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }
  LoadLLData() {
    this.lessonLearnedService.getLessonLearnedData(this.formId, this.projectId, 1).subscribe(res => {
      this.LLTypeData = res as LessonLearnedDetail[];
      this.lessonLearnedService.getLLCategoryData().subscribe((res: LessonLearnedCategory[]) => {
        this.LLCategoryData = res;
        if (this.LLTypeData.length > 0) {
          this.LLTypeData.forEach((element, index) => {
            let formLLNew = this.fb.group({
              lessonLearnedCategoryId: (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) ? -1 : element.lessonLearnedCategoryId,
              title: element.title,
              description: element.description
            });
            this.lessonsLearned.push(formLLNew);
            this.LLMaxOrder = element.order;
            if (this.LLTypeData[index].lessonLearnedCategoryId == undefined || this.LLTypeData[index].lessonLearnedCategoryId == null) {
              this.LLTypeData[index].lessonLearnedCategoryId = -1;
              this.lessonsLearned.controls[index].get("title")?.disable();
              this.LLTypeData[index].naLessonLearned = true;
            } else
              this.LLTypeData[index].naLessonLearned = false;
          });
        }
        else {
          this.LLDataIntialization(1);
          let formLLNew = this.fb.group({
            lessonLearnedCategoryId: this.LLTypeData[0].lessonLearnedCategoryId,
            title: this.LLTypeData[0].title,
            description: this.LLTypeData[0].description
          });
          this.lessonsLearned.push(formLLNew);
          this.getMaxOrder(1);
          this.lessonsLearned.controls[0].get("title")?.disable();
        }
      });
    });
  }
  LoadPlanData() {
    this.lessonLearnedService.getLessonLearnedData(this.formId, this.projectId, 2).subscribe(res => {
      this.PlanTypeData = res[0] as LessonLearnedDetail;
      if (this.PlanTypeData == undefined && this.PlanTypeData == null)
        this.PlanDataIntialization();
    })
  }
  LoadKeyData() {
    this.lessonLearnedService.getLessonLearnedData(this.formId, this.projectId, 3).subscribe(res => {
      this.KeyTypeData = res as LessonLearnedDetail[];
      if (this.KeyTypeData.length > 0) {
        if (this.KeyTypeData[0].nakeyDecision)
          this.naKeyDecisionDisabled = true;
        else
          this.naKeyDecisionDisabled = false;
        this.KeyTypeData.forEach(element => {
          let formKDNew = this.fb.group({
            title: element.title,
            description: element.description
          });
          this.keyDecision.push(formKDNew);
          this.KDMaxOrder = element.order;
        });
      }
      else {
        this.KeyDataIntialization(1);
        if (this.KeyTypeData[0].nakeyDecision)
          this.naKeyDecisionDisabled = true;
        else
          this.naKeyDecisionDisabled = false;
        let formKDNew = this.fb.group({
          title: this.KeyTypeData[0].title,
          description: this.KeyTypeData[0].description
        });
        this.keyDecision.push(formKDNew);
        this.getMaxOrder(3);
      }
    })
  }
  LLDataIntialization(order: any) {
    this.LLTypeData.push(new LessonLearnedDetail());
    this.LLTypeData[order - 1].lessonLearnedCategoryId = -1;
    this.LLTypeData[order - 1].formId = this.formId;
    this.LLTypeData[order - 1].projectId = this.projectId;
    this.LLTypeData[order - 1].lessonLearnedTypeId = 1;
    this.LLTypeData[order - 1].createdBy = this.provId;
    this.LLTypeData[order - 1].order = order;
    this.LLTypeData[order - 1].formType = "SSBOD";
    this.LLTypeData[order - 1].title = "";
    this.LLTypeData[order - 1].description = "";
    this.LLTypeData[order - 1].naLessonLearned = true;
  }
  PlanDataIntialization() {
    this.PlanTypeData = new LessonLearnedDetail();
    this.PlanTypeData.formId = this.formId;
    this.PlanTypeData.lessonLearnedCategoryId = undefined;
    this.PlanTypeData.projectId = this.projectId;
    this.PlanTypeData.lessonLearnedTypeId = 2;
    this.PlanTypeData.createdBy = this.provId;
    this.PlanTypeData.order = 1;
    this.PlanTypeData.description = "";
    this.PlanTypeData.formType = "SSBOD";
  }
  KeyDataIntialization(order: any) {
    this.KeyTypeData.push(new LessonLearnedDetail());
    this.KeyTypeData[order - 1].lessonLearnedCategoryId = undefined;
    this.KeyTypeData[order - 1].formId = this.formId;
    this.KeyTypeData[order - 1].projectId = this.projectId;
    this.KeyTypeData[order - 1].lessonLearnedTypeId = 3;
    this.KeyTypeData[order - 1].createdBy = this.provId;
    this.KeyTypeData[order - 1].order = order;
    this.KeyTypeData[order - 1].formType = "SSBOD";
    this.KeyTypeData[order - 1].title = "";
    this.KeyTypeData[order - 1].description = "";
    this.KeyTypeData[order - 1].nakeyDecision = false;
  }
  onCategoryChange(event: any, order: any, control: UntypedFormControl) {
    const value = event.target.value;
    this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order && x.active == true)[0];
    if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && this.LLUpdateModel.id > 0) {
      this.LLUpdateModel.modifiedBy = this.provId;
      this.LLUpdateModel.lessonLearnedCategoryId = value;
      if (value <= -1)
        this.RemoveOnCategoryChange(order);
      else {
        this.lessonsLearned.controls[order - 1].get("title")?.enable();
        this.LLUpdateModel.naLessonLearned = false;
        this.UpdateLLData(this.LLUpdateModel);
      }
    }
    else {
      this.LLSaveModel = this.LLTypeData.filter(x => x.order == order && x.active == true)[0];
      this.LLSaveModel.lessonLearnedCategoryId = value;
      if (value <= -1) {
        this.lessonsLearned.controls[order - 1].get("title")?.disable();
        this.LLSaveModel.naLessonLearned = true;
      }
      else {
        this.lessonsLearned.controls[order - 1].get("title")?.enable();
        this.LLSaveModel.naLessonLearned = false;
        this.LLSaveModel.order = this.LLMaxOrder + 1;
        this.SaveLLData(this.LLSaveModel);
      }
    }
  }
  RemoveOnCategoryChange(order: any) {
    this.lessonsLearned.controls[order - 1].get("title")?.setValue("");
    this.lessonsLearned.controls[order - 1].get("description")?.setValue("");
    this.lessonsLearned.controls[order - 1].get("title")?.disable();
    this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
    this.LLUpdateModel.naLessonLearned = true;
    this.LLUpdateModel.title = "";
    this.LLUpdateModel.description = "";
    this.LLUpdateModel.lessonLearnedCategoryId = undefined;
    this.UpdateLLData(this.LLUpdateModel);
  }
  onReady(editor, order, name) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.UpdateCkeditorChanges(editor.getData(), order, name);
        }
      }
    });
  }
  UpdateCkeditorChanges(event: customBuild.EventInfo, order: any, type: any) {
    const value = event;
    if (type == "LessonLearned") {
      this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
      if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && (value.trim().length > 0 || this.LLUpdateModel.id > 0)) {
        this.LLUpdateModel.modifiedBy = this.provId;
        this.LLUpdateModel.description = value;
        this.UpdateLLData(this.LLUpdateModel);
      }
    }
    else if (type == "Plan") {
      if (this.PlanTypeData != undefined && this.PlanTypeData != null && (value.trim().length > 0 || this.PlanTypeData.id > 0)) {
        this.PlanTypeData.modifiedBy = this.provId;
        this.PlanTypeData.description = value;
        this.UpdateLLData(this.PlanTypeData);
      }
    }
    else if (type == "KeyDecision") {
      this.KDUpdateModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
      if (this.KDUpdateModel != undefined && this.KDUpdateModel != null && this.KDUpdateModel.id > 0) {
        this.KDUpdateModel.modifiedBy = this.provId;
        this.KDUpdateModel.description = value;
        this.UpdateLLData(this.KDUpdateModel);
      }
      else {
        this.KDSaveModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
        this.KDSaveModel.description = value;
        if (value.trim().length != 0) {
          this.KDSaveModel.order = this.KDMaxOrder + 1;
          this.SaveLLData(this.KDSaveModel);
        }
      }
    }
  }
  SaveLLData(SaveModel: LessonLearnedDetail) {
    this.lessonLearnedService.saveLessonsLearnedData(SaveModel).subscribe(res => {
      if (SaveModel.lessonLearnedTypeId == 1) {
        this.clearFormArray(this.lessonsLearned);
        this.LoadLLData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      else if (SaveModel.lessonLearnedTypeId == 3) {
        this.clearFormArray(this.keyDecision);
        this.LoadKeyData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    })
  }
  UpdateLLData(UpdateModel: LessonLearnedDetail) {
    this.lessonLearnedService.saveLessonsLearnedData(UpdateModel).subscribe(res => {
      if (UpdateModel.lessonLearnedTypeId == 1) {
        this.clearFormArray(this.lessonsLearned);
        this.LoadLLData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      if (UpdateModel.lessonLearnedTypeId == 3) {
        this.clearFormArray(this.keyDecision);
        this.LoadKeyData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      if (UpdateModel.lessonLearnedTypeId == 2) {
        this.LoadPlanData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    })
  }
  DeleteLLData(DeleteModel: LessonLearnedDetail) {
    this.lessonLearnedService.saveLessonsLearnedData(DeleteModel).subscribe(res => {
      if (DeleteModel.lessonLearnedTypeId == 1) {
        this.getMaxOrder(1);
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      else if (DeleteModel.lessonLearnedTypeId == 3) {
        this.getMaxOrder(3);
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    })
  }
  clearFormArray = (formArray: UntypedFormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }
  onBlurTitleUpdate(event: any, order: any, type: any) {
    const value = event.target.value;
    if (type == "LessonLearned") {
      this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
      if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && (value.trim().length != 0 || this.LLUpdateModel.id > 0)) {
        this.LLUpdateModel.modifiedBy = this.provId;
        this.LLUpdateModel.title = value;
        this.UpdateLLData(this.LLUpdateModel);
      }
    }
    else if (type == "KeyDecision") {
      this.KDUpdateModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
      if (this.KDUpdateModel != undefined && this.KDUpdateModel != null && this.KDUpdateModel.id > 0) {
        this.KDUpdateModel.modifiedBy = this.provId;
        this.KDUpdateModel.title = value;
        this.UpdateLLData(this.KDUpdateModel);
      }
      else {
        this.KDSaveModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
        this.KDSaveModel.title = value;
        if (value.trim().length != 0) {
          this.KDSaveModel.order = this.KDMaxOrder + 1;
          this.SaveLLData(this.KDSaveModel);
        }
      }
    }
  }
  DeleteEmptyLLTypeData(order: any) {
    this.clearFormArray(this.lessonsLearned);
    this.LLTypeData.forEach((element, index) => {
      if (index > order - 1) {
        element.order = element.order - 1;
        if (element.id > 0) {
          if (element.lessonLearnedCategoryId == -1) { element.lessonLearnedCategoryId = undefined; }
          this.DeleteLLData(element);
          if (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) { element.lessonLearnedCategoryId = -1; }
        }
      }
      if (index == order - 1 && element.id > 0) {
        element.active = false;
        if (element.lessonLearnedCategoryId == -1) { element.lessonLearnedCategoryId = undefined; }
        this.DeleteLLData(element);
        if (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) { element.lessonLearnedCategoryId = -1; }
      }
      if (index != order - 1) {
        let formLLNew = this.fb.group({
          lessonLearnedCategoryId: (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) ? -1 : element.lessonLearnedCategoryId,
          title: element.title,
          description: element.description
        });
        this.lessonsLearned.push(formLLNew);
      }
    });
  }
  DeleteLLTypeData(order: any) {
    this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
    if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && this.LLUpdateModel.lessonLearnedCategoryId != -1) {
      this.LLUpdateModel.active = false;
      this.DeleteLLData(this.LLUpdateModel);
      this.clearFormArray(this.lessonsLearned);
      this.LLTypeData.forEach((element, index) => {
        if (index > order - 1) {
          element.order = element.order - 1;
          if (element.id > 0) {
            if (element.lessonLearnedCategoryId == -1) { element.lessonLearnedCategoryId = undefined; }
            this.DeleteLLData(element);
            if (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) { element.lessonLearnedCategoryId = -1; }
          }
        }
        if (index != order - 1) {
          let formLLNew = this.fb.group({
            lessonLearnedCategoryId: (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) ? -1 : element.lessonLearnedCategoryId,
            title: element.title,
            description: element.description
          });
          this.lessonsLearned.push(formLLNew);
        }
      });
    }
  }
  DeleteEmptyKeyTypeData(order: any) {
    this.clearFormArray(this.keyDecision);
    this.KeyTypeData.forEach((element, index) => {
      if (index > order - 1) {
        element.order = element.order - 1;
        if (element.id > 0) {
          this.DeleteLLData(element);
        }
      }
      if (index == order - 1 && element.id > 0) {
        element.active = false;
        this.DeleteLLData(element);
      }
      if (index != order - 1) {
        let formKDNew = this.fb.group({
          title: element.title,
          description: element.description
        });
        this.keyDecision.push(formKDNew);
      }
    });
  }
  DeleteKeyTypeData(order: any) {
    this.KDUpdateModel = this.KeyTypeData.filter(x => x.order == order)[0];
    if (this.KDUpdateModel != undefined && this.KDUpdateModel != null) {
      this.KDUpdateModel.active = false;
      this.DeleteLLData(this.KDUpdateModel);
      this.clearFormArray(this.keyDecision);
      this.KeyTypeData.forEach((element, index) => {
        if (index > order - 1) {
          element.order = element.order - 1;
          this.DeleteLLData(element);
        }
        if (index != order - 1) {
          let formKDNew = this.fb.group({
            title: element.title,
            description: element.description
          });
          this.keyDecision.push(formKDNew);
        }
      });
    }
  }
  Delete(order: any, type: any) {
    const message = 'Are you sure you want to delete this item?';
    const dialogData = new ConfirmDialogModel("Confirm item deletion", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (type == "LessonLearned") {
          if (this.LLTypeData.length >= 1 && this.LLTypeData[order - 1].lessonLearnedCategoryId == -1)
            this.DeleteEmptyLLTypeData(order);
          else if (this.LLTypeData.length >= 1 && this.LLTypeData[order - 1].lessonLearnedCategoryId != -1)
            this.DeleteLLTypeData(order);
          if (this.LLTypeData.length == 1) {
            this.LLTypeData.splice(order - 1, 1);
            this.LLDataIntialization(1);
            let formLLNew = this.fb.group({
              lessonLearnedCategoryId: this.LLTypeData[0].lessonLearnedCategoryId,
              title: this.LLTypeData[0].title,
              description: this.LLTypeData[0].description
            });
            this.lessonsLearned.push(formLLNew);
            this.lessonsLearned.controls[0].get("title")?.disable();
          }
          else if (this.LLTypeData.length > 1) {
            this.LLTypeData.splice(order - 1, 1);
            this.LLTypeData.forEach((element: any, index: any) => {
              if (element.lessonLearnedCategoryId == -1)
                this.lessonsLearned.controls[index].get("title")?.disable();
            });
          }
        }
        else if (type == "Plan") {
          if (this.PlanTypeData != undefined && this.PlanTypeData != null && (this.PlanTypeData.description?.trim().length != 0 || this.PlanTypeData.id > 0)) {
            this.PlanTypeData.active = false;
            this.UpdateLLData(this.PlanTypeData);
          }
        }
        else if (type == "KeyDecision") {
          if (this.KeyTypeData.length >= 1 && this.KeyTypeData[order - 1].description?.trim().length == 0 && this.KeyTypeData[order - 1].title?.trim().length == 0)
            this.DeleteEmptyKeyTypeData(order);
          else if (this.KeyTypeData.length >= 1 && (this.KeyTypeData[order - 1].description?.trim().length != 0 || this.KeyTypeData[order - 1].title?.trim().length != 0))
            this.DeleteKeyTypeData(order);
          if (this.KeyTypeData.length == 1) {
            this.KeyTypeData.splice(order - 1, 1);
            this.KeyDataIntialization(1);
            let formKDNew = this.fb.group({
              title: this.KeyTypeData[0].title,
              description: this.KeyTypeData[0].description
            });
            this.keyDecision.push(formKDNew);
          }
          else if (this.KeyTypeData.length > 1)
            this.KeyTypeData.splice(order - 1, 1);
        }
      }
    });
  }
  NAKeyDecisionChange(event: MatSlideToggleChange) {
    this.KDUpdateModel = this.KeyTypeData[0];
    if (this.KDUpdateModel != undefined && this.KDUpdateModel != null) {
      this.KDUpdateModel.modifiedBy = this.provId;
      this.KDUpdateModel.nakeyDecision = event.checked;
      this.UpdateLLData(this.KDUpdateModel);
    }
  }
  getMaxOrder(typeId: any) {
    this.lessonLearnedService.getMaxOrder(this.formId, this.projectId, typeId).subscribe(res => {
      if (typeId == 1)
        this.LLMaxOrder = res;
      else if (typeId == 3)
        this.KDMaxOrder = res;
    });
  }
}
