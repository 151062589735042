<h2 mat-dialog-title>
Set version name
</h2>

<div mat-dialog-content>
  <!-- <p>{{name}}</p> -->
    <!-- <text type="text" class="form-control" [(ngModel)]="name"></text> -->

    <input type="text" class="form-control" id="versionName" name="versionName"
   [(ngModel)]="versionName" /> 
</div>

<div mat-dialog-actions style="float: right;">
  <button mat-button (click)="onDismiss()" class="btn btn-default"> Cancel </button> &nbsp;&nbsp;
  <button mat-raised-button class="btn btn-primary" style="color: white !important;" (click)="onCreate()"> Save </button>
</div>