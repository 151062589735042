import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CommentDialogComponent, CommentDialogModel } from 'src/app/Modals/comment-dialog/comment-dialog.component';
import { EAPart1Model } from 'src/app/Model/EAPart1.model';
import { EaCommentModel } from 'src/app/Model/EaComment.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { EaprojectService } from '../../service/eaproject.service';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-ar-part1',
  templateUrl: './ar-part1.component.html',
  styleUrls: ['./ar-part1.component.css']
})
export class ArPart1Component implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [
      // 'fontfamily','fontsize','fontColor','fontBackgroundColor', '|',
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',

    ],
    autosave: {
      save(editor) {
        // The saveData() function must return a promise
        // which should be resolved when the data is successfully saved.
      }
    }

  }
  @Input() formId: number = 0;
  projectId: number = 0;
  versionName: number = 0;
  Part1Model = new EAPart1Model;
  projectData: any = undefined;
  CPDEPPhases: any = [];
  showSection1: boolean = true;
  showSection2: boolean = false;
  showSection3: boolean = false;
  showSection4: boolean = false;
  emailId: string = "";
  provId: string = "";
  CvxShareComment: boolean = false;
  AppropriatedAmountComment: boolean = false;
  TotalCommitmentComment: boolean = false;
  @Input() comments: any = [];
  @Input() isOnHold: boolean = false;
  originalId: number = 0;
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;
  @Output() changeHeader = new EventEmitter;
  @Input() IsOldVersionOfForms: boolean = false;
  constructor(private eaprojectservice: EaprojectService, private route: ActivatedRoute
    , private projectservice: Go36projectService,
    public dialog: MatDialog, private notifyService: NotificationService,
    private go36ProjectService: Go36projectService, public utilityService: UtilityService) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.route.queryParams
      .subscribe(params => {
        this.originalId = params['formId'];
        this.projectId = params['projectId'];
        this.versionName = params['versionName'];
      });
    this.getProjectInfobyId();
    this.LoadPart1Data();
  }
  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }
  getProjectInfobyId() {
    this.projectservice.GetProjectInfobyId(this.projectId).subscribe(res => {
      this.projectData = res;
    });
  }

  LoadPart1Data() {
    this.eaprojectservice.GetPart1Data(this.originalId, this.versionName).subscribe((res: any) => {
      if (res) {
        this.Part1Model = res.part1HeaderData;
        if (this.Part1Model != null &&  this.Part1Model.cvxShare != null) {
          this.Part1Model.cvxShare = Number(this.Part1Model.cvxShare).toString();
          this.Part1Model.cvxShare =  this.Part1Model.cvxShare + '%';
        }
        this.Part1Model.appropriatedAmount = this.utilityService.AllowOneDecimal(this.Part1Model.appropriatedAmount);
        this.Part1Model.totalCommitment = this.utilityService.AllowOneDecimal(this.Part1Model.totalCommitment);
        this.CPDEPPhases = res.lstCPDEP;
      }
    });
  }
  hasComment(section: string, controlId: string) {
    return this.comments.filter(x => x.section == section && x.controlId == controlId)[0] == undefined ? false : this.comments.filter(x => x.section == section && x.controlId == controlId)[0].comment.length > 0;
  }

  GetComments(section: string, controlId: string) {
    let res = this.comments.filter(x => x.section == section && x.controlId == controlId)[0];
    res = res == undefined ? new EaCommentModel() : res;
    const dialogData = new CommentDialogModel("Update Comment", res.comment, this.isOnHold, this.Part1Model.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });
    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        res.modifiedBy = this.provId;
        res.comment = comment;
        res.arId = this.formId;
        res.section = section;
        res.controlId = controlId;
        this.eaprojectservice.UpdateComments(res).subscribe(result => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
          this.comments = result;
        });
      }
    });
  }


  onReady(editor) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.UpdatePart1Data();
        }
      }
    });
  }
  showSection(sectionNumber: number) {
    this.showSection1 = this.showSection2 = this.showSection3 = this.showSection4 = false;
    if (sectionNumber == 1) {
      this.showSection1 = true;
    }
    else if (sectionNumber == 2) {
      this.showSection2 = true;
    }
    else if (sectionNumber == 3) {
      this.showSection3 = true;
    }
    else if (sectionNumber == 4) {
      this.showSection4 = true;
    }
  }
  UpdatePart1Data() {
    const date = new Date(this.Part1Model.arDate!);
    const orgDate = new Date(this.Part1Model.originalDate!);
    this.Part1Model.cvxShare = this.Part1Model.cvxShare == '' || this.Part1Model.cvxShare == null ? '' : parseFloat(this.Part1Model.cvxShare);
    if (this.Part1Model.arDate != null) {
      this.Part1Model.arDate = this.notifyService.ConvertDateBeforeSave(date);
    }
    if (this.Part1Model.originalDate != null) {
      this.Part1Model.originalDate = this.notifyService.ConvertDateBeforeSave(orgDate);
    }
    this.Part1Model.appropriatedAmount = this.utilityService.AllowOneDecimal(Number(this.Part1Model.appropriatedAmount));
    this.Part1Model.totalCommitment = this.utilityService.AllowOneDecimal(Number(this.Part1Model.totalCommitment));
    this.Part1Model.modifiedBy = this.provId;
    this.eaprojectservice.UpdatePart1Data(this.Part1Model).subscribe(res => {
      this.changeHeader.emit(this.Part1Model.projectLocation);
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
    if (this.Part1Model != null &&  this.Part1Model.cvxShare != null) {
      this.Part1Model.cvxShare = Number(this.Part1Model.cvxShare).toString();
      this.Part1Model.cvxShare =  this.Part1Model.cvxShare + '%';
    }
    if (this.Part1Model.arDate != null) {
      this.Part1Model.arDate = this.notifyService.ConvertDateAfterSave(date);
    }
    if (this.Part1Model.originalDate != null) {
      this.Part1Model.originalDate = this.notifyService.ConvertDateAfterSave(orgDate);
    }
  }
  UpdateArCharacteristics(event: any, checktype: string) {
    if (checktype == "Exploration") { this.Part1Model.exploration = event.target.checked; }
    else if (checktype == "Appraisal") { this.Part1Model.appraisal = event.target.checked; }
    else if (checktype == "Program") { this.Part1Model.program = event.target.checked; }
    else if (checktype == "Scope") { this.Part1Model.scopeChange = event.target.checked; }
    else if (checktype == "Supplement") { this.Part1Model.supplement = event.target.checked; }
    else if (checktype == "NOJV") { this.Part1Model.nojv = event.target.checked; }
    this.UpdatePart1Data();
  }
}
