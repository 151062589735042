export const environment = {
  production: true,
  clientId: '7f164678-6c1e-4529-bb55-10df2aa3e824',
  instance: "https://login.microsoftonline.com/",
  tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  redirectUri: 'https://go36-dev.azure.chevron.com/', 
  apiBaseUrl: 'https://go36-api-dev.azure.chevron.com/',
  scopes : ['https://dgo36-test.azure.chevron.com/user_impersonation'],
  name: 'Developement'
};

//run command ng build --configuration=dev 