import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Form } from 'src/app/Model/Form.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { OverviewService } from 'src/app/forms/FEEDServices/overview.service';
import { ViewFormModel } from 'src/app/project/go36project/Model/viewform.model';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-feed-dialog',
  templateUrl: './feed-dialog.component.html',
  styleUrls: ['./feed-dialog.component.css']
})

export class FeedDialogComponent implements OnInit {
  form = new Form();
  emailId: string = '';
  provId: string = '';
  selectedTeam: string = '';
  newData: any;
  options1: string[] = ["Please Select Phase", "Front-End Engineering & Design (FEED)",
    "Long-Lead Equipment", "Early EPC"];
  formData: ViewFormModel[] = [];
  formDataItem?= new ViewFormModel();

  options: FormType[] = [];

  constructor(public dialogRef: MatDialogRef<FeedDialogModel>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private overviewService: OverviewService,
    private go36ProjectService: Go36projectService,
    private notifyService: NotificationService, public utilityService: UtilityService) {
    dialogRef.disableClose = true;
    this.options = [
      { id: 0, name: "Please Select Phase" },
      { id: 1, name: "Front-End Engineering & Design (FEED)" },
      { id: 2, name: "Long-Lead Equipment" },
      { id: 3, name: "Early EPC" }
    ];
  }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.go36ProjectService.getViewFormTypes(this.data.projectId).subscribe((res) => {
      this.formData = res;

      if (this.formData.length > 0) {
        this.filterFormtype(this.formData)
      }
    });
  }

  filterFormtype(formData: ViewFormModel[]) {
    if (this.formData.length > 0) {
      this.formData.forEach((value, index) => {
        if (value.FormType == 'FEED') {
          this.RemoveElementFromStringArray('Front-End Engineering & Design (FEED)', index);
        }
        if (value.FormType == 'LLE') {
          this.RemoveElementFromStringArray('Long-Lead Equipment', index);
        }
        if (value.FormType == 'EPC') {
          this.RemoveElementFromStringArray('Early EPC', index);
        }
      });
    }
  }

  RemoveElementFromStringArray(element: string, index: number) {
    this.options.forEach((value, index) => {
      if (value.name == element) {
        this.options.splice(index, 1);
      }
    });
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  onCreate() {
    if (this.selectedTeam == "" || this.selectedTeam == '0') {
      this.notifyService.showWarning("Please Select Phase", "");
    }
    else {
      if (this.selectedTeam == "1") {
        this.form.formType = 'FEED';
      }
      else if (this.selectedTeam == "2") {
        this.form.formType = 'LLE';
      }
      else if (this.selectedTeam == "3") {
        this.form.formType = 'EPC';
      }
      
      this.dialogRef.close();
      this.form.projectId = this.data.projectId;
      this.form.active = true;
      this.form.createdBy = this.provId;
      // // this.form.formType='FEED';
      this.form.order = 1;
      this.form.id = 0
      this.form.ownership = [];
      this.form.milestone = [];
      this.form.keyEconomicMetrics = [];
      this.form.capitalAndExpenditures = [];

      this.overviewService.saveFormData(this.form).subscribe(res => {
        this.newData = res;
        this.form.originalId = this.newData.originalId;
        this.form.version = this.newData.version;
        this.router.navigateByUrl('/forms/feed?projectId=' + this.data.projectId + '&formId=' + this.form.originalId + '&versionName=' + this.form.version);
        // // this.router.navigate(["/forms/feed", this.data.projectId, this.form.originalId, this.form.version,'Create']);
      });
    }
  }

  onSelected(value: string): void {
    this.selectedTeam = value;
  }
}
export class FeedDialogModel {

}

interface FormType {
  id: number;
  name: string;
}