import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/Modals/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { AppendicesModel, Versions } from 'src/app/Model/Appendices.model';
import { Form } from 'src/app/Model/Form.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';
import { ProjectDocumentationService } from '../../FEEDServices/project-documentation.service';

@Component({
  selector: 'app-project-documentation',
  templateUrl: './project-documentation.component.html',
  styleUrls: ['./project-documentation.component.css']
})

export class ProjectDocumentationComponent implements OnInit {
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  @Input() isOnHold: boolean = false;
  formId: number = 0;
  formCase: string | null = '';
  appendicesViewModel: AppendicesModel[] = [];
  Phase1: AppendicesModel[] = [];
  Phase2: AppendicesModel[] = [];
  Phase3: AppendicesModel[] = [];
  FEED_FID: AppendicesModel[] = [];

  Phase1Versions: Versions[] = [];
  Phase2Versions: Versions[] = [];
  Phase3Versions: Versions[] = [];
  FEED_FIDVersions: Versions[] = [];
  newRecord: AppendicesModel[] = [];
  @Input() form: Form = new Form();
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;
  displayPhase1: boolean = false;

  constructor(private projectService: ProjectDocumentationService,
    private go36ProjectService: Go36projectService,
    public dialog: MatDialog, private utilityService: UtilityService,
    private notifyService: NotificationService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formCase = (this.route.snapshot.paramMap.get("formCase"));
    this.displayPhase1 = this.form.IsOldVersionOfForms ? true : false;

    if (this.formCase == 'Create') {
      this.formId = this.form.id;// //Number(this.route.snapshot.paramMap.get("formId"));
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          this.formId = this.form.id;// //params['formId'];
        });
    }
    this.getProjectDocData();
  }

  getProjectDocData() {
    this.projectService.getData(this.formId).subscribe((res: AppendicesModel[]) => {
      this.appendicesViewModel = res;

      if (this.appendicesViewModel.length > 0) {
        this.SetProjectDocumentationData();
      }
      if (this.appendicesViewModel.length == 0 || (this.form.isUpstream && this.form.IsOldVersionOfForms)) {
        this.projectService.createDefaultData(this.formId, this.provId, this.form.IsOldVersionOfForms).subscribe((res: any) => {
          this.appendicesViewModel = res;
          if (this.appendicesViewModel.length > 0) {
            this.SetProjectDocumentationData();
          }
        });
      }
    });
  }

  SetProjectDocumentationData() {
    this.Phase1 = this.appendicesViewModel.filter(x => x.phase == 1 && x.order > 0);
    this.Phase1.sort((a, b) => {
      return a.id - b.id;
    });

    this.Phase2 = this.appendicesViewModel.filter(x => x.phase == 2 && x.order > 0);
    this.Phase2.sort((a, b) => {
      return a.id - b.id;
    });

    this.Phase3 = this.appendicesViewModel.filter(x => x.phase == 3 && x.order > 0);
    this.Phase3.sort((a, b) => {
      return a.id - b.id;
    });

    this.FEED_FID = this.appendicesViewModel.filter(x => x.phase == 4 && x.order > 0);
    this.FEED_FID.sort((a, b) => {
      return a.id - b.id;
    });

    //sort all versions
    // this.Phase2.forEach(obj => {
    //   obj.versions.sort((a, b) => {
    //     return a.id - b.id;
    //   });
    // });

    this.Phase1.forEach(obj => {
      if (obj.fileName == null || obj.fileName == "") {
        this.Phase1Versions = []
        this.Phase1Versions = this.Phase1.find(x => x.originalId == obj.originalId)?.versions!;
        let name = this.Phase1Versions[0];
        if (name != undefined) {
          obj.fileName = name.fileName;
          obj.createdOn = name.dateUploaded;
          obj.version = name.id;
        }
      }
    });

    this.Phase2.forEach(obj => {
      if (obj.fileName == null || obj.fileName == "") {
        this.Phase2Versions = []
        this.Phase2Versions = this.Phase2.find(x => x.originalId == obj.originalId)?.versions!;
        let name = this.Phase2Versions[0];
        if (name != undefined) {
          obj.fileName = name.fileName;
          obj.createdOn = name.dateUploaded;
          obj.version = name.id;
        }
      }
    });

    // // this.Phase3.forEach(obj => {
    // //   obj.versions.sort((a, b) => {
    // //     return a.id - b.id;
    // //   });
    // // });

    this.Phase3.forEach(obj => {
      if (obj.fileName == null || obj.fileName == "") {
        this.Phase3Versions = []
        this.Phase3Versions = this.Phase3.find(x => x.originalId == obj.originalId)?.versions!;
        let name = this.Phase3Versions[0];
        if (name != undefined) {
          obj.fileName = name.fileName;
          obj.createdOn = name.dateUploaded;
          obj.version = name.id;
        }
      }
    });

    // // this.FEED_FID.forEach(obj => {
    // //   obj.versions.sort((a, b) => {
    // //     return a.id - b.id;
    // //   });
    // // });

    this.FEED_FID.forEach(obj => {
      if (obj.fileName == null || obj.fileName == "") {
        this.FEED_FIDVersions = []
        this.FEED_FIDVersions = this.FEED_FID.find(x => x.originalId == obj.originalId)?.versions!;
        let name = this.FEED_FIDVersions[0];
        if (name != undefined) {
          obj.fileName = name.fileName;
          obj.createdOn = name.dateUploaded;
          obj.version = name.id;
        }
      }
    });
  }

  ShowAppendixModal() {
    const message = "Attach relevent documentation for current phase of the project (Phase 2 documents for FEED AR and Phase 3 for FID, plus Opportunity Selection Study). Documents not specifically included in the list can be attached using the ‘add other documents’ button."
    // //+ "<br/> <br/>" + "NPV tornado should use the Deterministic mid case";

    const dialogData = new InfoDialogModel("Economic Sensitivity", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  uploadFile = (event: any, appendix: AppendicesModel) => {
    const file = event.target.files[0];
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.projectService.uploadFile(appendix.id, formData).subscribe((res: any) => {
      this.notifyService.showSuccess("File uploaded successfully.", "");
      this.appendicesViewModel = res;
      if (this.appendicesViewModel.length > 0) {
        this.SetProjectDocumentationData();
      }
    });
  }

  DownloadFile(appendix: AppendicesModel) {
    this.projectService.downloadFile(appendix.originalId, appendix.version).subscribe((data: any) => {
      let downloadURL = window.URL.createObjectURL(data);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = appendix.fileName;// //name == null ? '' : name;
      link.click();
    });
  }

  DeletePhases(appendix: AppendicesModel, index: any) {
    const message = 'Are you sure you want to delete this item?';
    const dialogData = new ConfirmDialogModel("Confirm item deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.appendicesViewModel.splice(index, 1);
        appendix.modifiedBy = this.provId;
        appendix.active = false;
        this.projectService.deletePhase(appendix.formId, appendix.originalId, this.provId).subscribe(res => {
          this.notifyService.showSuccess("Item deleted successfully...!", "");
          this.appendicesViewModel = res;
          if (this.appendicesViewModel.length > 0) {
            this.SetProjectDocumentationData();
          }
        });
      }
    });
  }

  identify(index: any, item: any) {
    return item.label;
  }

  onVersionChangePhase1(id: any, originalId: number, sectionName?: string) {
    let name = this.appendicesViewModel.find(x => x.phase == 1 && x.sectionName == sectionName && x.version == id);

    this.Phase1.forEach(obj => {
      if (obj.phase == 1 && obj.sectionName == sectionName) {
        obj.fileName = name?.fileName!;
        obj.createdOn = name?.createdOn;
        obj.version = name?.version!;
        obj.originalId = name?.originalId!;
      }
    });
    return this.Phase1;
  }

  onVersionChangePhase2(id: any, originalId: number, sectionName?: string) {
    // //let name = this.appendicesViewModel.find(x => x.originalId == originalId && x.version == id);
    let name = this.appendicesViewModel.find(x => x.phase == 2 && x.sectionName == sectionName && x.version == id);

    this.Phase2.forEach(obj => {
      if (obj.phase == 2 && obj.sectionName == sectionName) {
        obj.fileName = name?.fileName!; //== null ? '' : name;
        obj.createdOn = name?.createdOn;
        obj.version = name?.version!;
        obj.originalId = name?.originalId!;
      }
    });
    return this.Phase2;
  }

  onVersionChangePhase3(id: any, originalId: number, sectionName?: string) {
    let name = this.appendicesViewModel.find(x => x.phase == 3 && x.sectionName == sectionName && x.version == id);

    this.Phase3.forEach(obj => {
      if (obj.phase == 3 && obj.sectionName == sectionName) {
        obj.fileName = name?.fileName!; //== null ? '' : name;
        obj.createdOn = name?.createdOn;
        obj.version = name?.version!;
        obj.originalId = name?.originalId!;
      }
    });
    return this.Phase3;
  }

  onVersionChangeFEED(id: any, originalId: number, sectionName?: string) {
    let name = this.appendicesViewModel.find(x => x.phase == 4 && x.sectionName == sectionName && x.version == id);

    this.FEED_FID.forEach(obj => {
      if (obj.phase == 4 && obj.sectionName == sectionName) {
        obj.fileName = name?.fileName!; //== null ? '' : name;
        obj.createdOn = name?.createdOn;
        obj.version = name?.version!;
        obj.originalId = name?.originalId!;
      }
    });
    return this.FEED_FID;
  }

  AddPhase1Document() {
    ////let row = this.Phase1.slice(-1)[0];
    const newDocument = new AppendicesModel();
    this.newRecord = [];
    this.newRecord = this.appendicesViewModel.filter(x => x.order > 0);
    this.newRecord.sort((a, b) => {
      return a.id - b.id;
    });
    let data = this.newRecord[this.newRecord.length - 1];

    newDocument.id = 0;
    newDocument.active = true;
    newDocument.createdBy = this.provId;
    newDocument.modifiedBy = this.provId;
    newDocument.createdOn = new Date();
    newDocument.modifiedOn = new Date();
    newDocument.order = data.order + 1;
    newDocument.formId = this.formId;
    newDocument.phase = 1;
    newDocument.sectionName = '';
    newDocument.fileName = '';
    newDocument.version = 0;
    newDocument.isEditable = true;

    this.Phase1.splice(this.Phase1.length + 1, 0, newDocument);
  }

  AddPhase2Document() {
    ////let row = this.Phase2.slice(-1)[0];
    const newDocument = new AppendicesModel();
    this.newRecord = [];
    this.newRecord = this.appendicesViewModel.filter(x => x.order > 0);
    this.newRecord.sort((a, b) => {
      return a.id - b.id;
    });
    let data = this.newRecord[this.newRecord.length - 1];

    newDocument.id = 0;
    newDocument.active = true;
    newDocument.createdBy = this.provId;
    newDocument.modifiedBy = this.provId;
    newDocument.createdOn = new Date();
    newDocument.modifiedOn = new Date();
    newDocument.order = data.order + 1;
    newDocument.formId = this.formId;
    newDocument.phase = 2;
    newDocument.sectionName = '';
    newDocument.fileName = '';
    newDocument.version = 0;
    newDocument.isEditable = true;

    this.Phase2.splice(this.Phase2.length + 1, 0, newDocument);
  }

  AddPhase3Document() {
    ////let row = this.Phase3.slice(-1)[0];
    const newDocument = new AppendicesModel();
    this.newRecord = [];
    this.newRecord = this.appendicesViewModel.filter(x => x.order > 0);
    this.newRecord.sort((a, b) => {
      return a.id - b.id;
    });
    let data = this.newRecord[this.newRecord.length - 1];

    newDocument.id = 0;
    newDocument.active = true;
    newDocument.createdBy = this.provId;
    newDocument.modifiedBy = this.provId;
    newDocument.createdOn = new Date();
    newDocument.modifiedOn = new Date();
    newDocument.order = data.order + 1;
    newDocument.formId = this.formId;
    newDocument.phase = 3;
    newDocument.sectionName = '';
    newDocument.fileName = '';
    newDocument.version = 0;
    newDocument.isEditable = true;

    this.Phase3.splice(this.Phase3.length + 1, 0, newDocument);
  }

  AddFEEDDocument() {
    ////let row = this.FEED_FID.slice(-1)[0];
    const newDocument = new AppendicesModel();
    this.newRecord = [];
    this.newRecord = this.appendicesViewModel.filter(x => x.order > 0);
    this.newRecord.sort((a, b) => {
      return a.id - b.id;
    });
    let data = this.newRecord[this.newRecord.length - 1];

    newDocument.id = 0;
    newDocument.active = true;
    newDocument.createdBy = this.provId;
    newDocument.modifiedBy = this.provId;
    newDocument.createdOn = new Date();
    newDocument.modifiedOn = new Date();
    newDocument.order = data.order + 1;
    newDocument.formId = this.formId;
    newDocument.phase = 4;
    newDocument.sectionName = '';
    newDocument.fileName = '';
    newDocument.version = 0;
    newDocument.isEditable = true;

    this.FEED_FID.splice(this.FEED_FID.length + 1, 0, newDocument);
  }

  SaveAppendixData(phase: AppendicesModel) {
    phase.modifiedBy = this.provId;
    this.projectService.SaveData(phase).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      //get data again to update list
      this.projectService.getData(this.formId).subscribe((res: AppendicesModel[]) => {
        this.appendicesViewModel = res;
        if (this.appendicesViewModel.length > 0) {
          this.SetProjectDocumentationData();
        }
      });
    });
  }
}
