import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private authService: CalAngularService, public projectservice: Go36projectService, private router: Router) { }

  currentUserProfile!: ICvxClaimsPrincipal;
  loggedIn: boolean = false;
  userData: any;
  userName: string = "";
  firstName: string = "";
  lastName: string = "";
  userAccessDetails: any;
  userId: number = 0;
  showArrow: boolean = false;
  SetEnv:any='';
  // // isHeaderVisible: boolean = true;  
  // //@Input() isHeaderVisible: boolean = true;  ;
  // //@Output() isVisible= new EventEmitter;
  ngOnInit() {
        if(environment.name=='Testing')
    {
this.SetEnv='test';
    }
    this.authService.isUserSignedIn().subscribe((value: boolean) => {
      this.loggedIn = value;
      if (this.loggedIn) {
        this.currentUserProfile = this.authService.cvxClaimsPrincipal;
        let userObjectId = this.currentUserProfile.objectId;
        let userProvId = this.currentUserProfile.provisioningId;
        let uName = this.currentUserProfile.name.split("[")[0];
        uName = uName.replace(/\s/g, "");
        // // this.firstName = uName.split(",")[0];
        this.firstName = uName.substring(uName.indexOf(',') + 1);
        this.lastName = uName.substring(0, uName.indexOf(','));
        this.lastName = this.lastName.split(' ').map(n => n[0]).join('');
        this.userName = this.firstName + " " + this.lastName;

        this.projectservice.GetActiveUser(userObjectId, userProvId).subscribe(res => {
          let response = res;
          if (!response) {
            // // this.isHeaderVisible=false;
            this.projectservice.isHeaderVisible = false;
            this.router.navigate(['/registeruser']);
          }
          else {
            // //this.isHeaderVisible=true;
            this.userId= res.userId;
            this.projectservice.isHeaderVisible = true;
          }
          this.projectservice.GetUserDetailsByObjectProvId(userObjectId, userProvId).subscribe(res1 => {
            this.userAccessDetails = res1;
            localStorage.setItem('userAccessDetails', JSON.stringify(this.userAccessDetails));
          });

        });
      }
      else {
      }
    });
  }
  showMenu() {
    this.showArrow = !this.showArrow;
  }
}