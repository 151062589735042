import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { SubsurfaceViewModel } from '../../Model/subsurfaceView.model';
import { SsbodService } from '../../service/ssbod.service';
import { UtilityService } from 'src/app/Shared/utility.service';
@Component({
  selector: 'app-facilities-concept-summary',
  templateUrl: './facilities-concept-summary.component.html',
  styleUrls: ['./facilities-concept-summary.component.css']
})
export class FacilitiesConceptSummaryComponent implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [
      // 'fontfamily','fontsize','fontColor','fontBackgroundColor', '|',
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
    ],
    autosave: {
      save(editor) {
      }
    }
  }

  @Input() viewModel: SubsurfaceViewModel = new SubsurfaceViewModel();
  formId: number = 0;
  emailId: string = '';
  provId: string = '';
  ckeditorText: any = "";
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;

  constructor(private ssbodService: SsbodService, private route: ActivatedRoute, private go36ProjectService: Go36projectService,
    private notifyService: NotificationService, public utilityService: UtilityService) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formId = this.viewModel.Id;
    // // for(let key in this.CKControlIds){
    // //   this.GetCKEditorText(this.CKControlIds[key]);
    // // }
  }

  // // GetCKEditorText(sectionId: any) {
  // //   this.ssbodService.GetCKEditorText(this.formId,sectionId).subscribe((res: any) => {
  // //   })
  // // }
  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }
  onReady(editor, name) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.UpdateCkeditorChanges(editor.getData(), name);
        }
      }
    });
  }
  UpdateCkeditorChanges(event: customBuild.EventInfo, textName: string) {
    this.ckeditorText = event;
    let postModel = { ...this.viewModel };
    postModel.ModifiedBy = this.provId;
    postModel.Id = this.formId;
    postModel.BusinessUnits = [];
    postModel.Countries = [];
    postModel.Events = [];
    postModel.ProjectDevelopmentStages = [];
    postModel.Comments = postModel.Comments == null ? "" : postModel.Comments;
    postModel.Event = postModel.Event == null ? "" : postModel.Event;
    postModel.Phase = postModel.Phase == null ? "" : postModel.Phase;
    postModel.VersionName = postModel.VersionName == null ? "" : postModel.VersionName;
    if (textName == 'FacilitiesConceptSummary') {
      postModel.FacilitiesConceptSummary = this.ckeditorText;
    } else if (textName == 'DevelopmentPlanDescription') {
      postModel.DevelopmentPlanDescription = this.ckeditorText;
    } else if (textName == 'SupportingImage') {
      postModel.SupportingImage = this.ckeditorText;
    }
    this.ssbodService.UpdateCkeditorChanges(this.formId, postModel).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }
}