import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AssetService } from 'src/app/admin/service/asset.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent, DeleteDialogModel } from 'src/app/Modals/delete-dialog/delete-dialog.component';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { UtilityService } from 'src/app/Shared/utility.service';
import { data } from 'jquery';
import * as XLSX from 'xlsx';

declare let $;

@Component({
  selector: 'app-useraccess-list',
  templateUrl: './useraccess-list.component.html',
  styleUrls: ['./useraccess-list.component.css']
})
export class UseraccessListComponent implements OnInit {
  Data: any;
  jsonResponse: any = [];
  OpcoList: any;
  sbuList: any;
  buList: any;
  formValue!: UntypedFormGroup;
  opcofields: Object = {};
  public sbufields: Object = {};
  public selecteddataToPost: any;
  isDisplaytotalCount : boolean = false;
  displaytotalCount : number = 0;
  myControl = new UntypedFormControl();
  options: string[] = ["One", "Two", "Three"];
  filteredOptions: Observable<string[]> | undefined;
  userAccessDetails: any;
  userRole : string ='';
  CAI : string = '';
  formDataLength:number=0;
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  userExport: any;
  @ViewChild('TABLE', { read: ElementRef })
  table!: ElementRef;
  constructor(private formbuilder: UntypedFormBuilder, private apiService: AssetService, public dialog: MatDialog, private projectservice: Go36projectService, private utilityService: UtilityService
    //,private _database: ChecklistDatabase
  ) {
    this.formValue = this.formbuilder.group({
        OpcoId: [''],
        sbuId: ['']
      })
  }

  ngOnInit(): void 
  {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.isDisplaytotalCount = false;
    this.CAI = this.projectservice.GetuserCai();
    // // this.fillOpcoDDL(0);
    this.getAll();
  }

  getAll() 
  {
    // // this.apiService.get("user/GetAllUsers").subscribe(res => {
    // //   this.Data = res;
    // // })

    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
     this.userAccessDetails =  JSON.parse(userData);
     if(this.userAccessDetails?.data?.isPowerUser)
     {
        this.userRole = "Power User";
     }
     else if(this.userAccessDetails?.data?.isAdminUser)
     {
      this.userRole = "Administrator";
     }
     else if(this.userAccessDetails?.data?.isBasicMember)
     {
      this.userRole = "Basic Member";
     }
     else if(this.userAccessDetails?.data?.isConsultantUser)
     {
      this.userRole = "Consultant";
     }
     else if(this.userAccessDetails?.data?.isLeadershipUser)
     {
      this.userRole = "Leadership";
     }
    this.apiService.getUserListByRole(this.objectId, this.provId, this.userRole).subscribe(res => {
      this.Data = res.userAccesses;
      this.OpcoList = res.operatingCompanies; 
      this.formDataLength = this.Data.length;
      this.onShowEntryDropdownChange({first: 0, rows: this.formDataLength});
      this.fillOpcoDDL(0);
    })
  }
  
  ExportUserList() :void
  {
    console.log(this.Data);
    console.log(this.table)
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);//converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'User List.xlsx');
  }

  

  exportToExcel() {
    for(var i=0; i<this.Data.length; i++) {
      var item1 = {
        'FirstName' : this.Data[i].firstName,
        'LastName' : this.Data[i].lastName,
        'EmailId' : this.Data[i].emailId,
        'Global Role' : this.Data[i].role,
        'CreatedBy' : this.Data[i].createdBy,
        'Created Date' : this.Data[i].createdDate,
        'Active' : this.Data[i].active
      }
      console.log(item1);
      this.jsonResponse.push(item1);
    }
    console.log(this.jsonResponse);
    const worksheet = XLSX.utils.json_to_sheet(this.jsonResponse);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'data.xlsx');
  }

  Delete(userId) 
  {
    const message = 'Are you sure you want to delete the user?';
    const dialogData = new DeleteDialogModel("Delete User", message);
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "500px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.apiService.delete("user?userId=", userId).subscribe(res => {
          this.getAll();
        });
      }
    });
  }

  fillOpcoDDL(OpcoId: any) {
    const component = this;
    if (OpcoId == 0) {
      // //this.apiService.get("operatingcompanies").subscribe(res => {
      // // this.OpcoList = res;
        const select_options = {
          title: " ",
          data: this.GetOpcos(),
          closedArrow: '<i class="fa fa-caret-right" aria-hidden="true"></i>',
          openedArrow: '<i class="fa fa-caret-down" aria-hidden="true"></i>',
          maxHeight: 300,
          maxWidth: 98,
          multiSelect: true,
          selectChildren: true,
          clickHandler: function (element) {
          },
          checkHandler: function (element, checked) {
            
            let opcoValue = element.data("opcoid");
            let isOpcoChecked = checked.currentTarget.classList.contains("fa-check-square-o");
            let getSelectedOPCO = $("#ddlOpco").GetSelected();
            if (getSelectedOPCO.length == 1) {
              $("#ddlOpco").SetTitle(getSelectedOPCO[0].text());
            } else if (getSelectedOPCO.length > 1) {
              $("#ddlOpco").SetTitle("Multiple Selected");
            } else {
              $("#ddlOpco").SetTitle(" ");
            }
            component.GetSBU(opcoValue, isOpcoChecked);
          }
        }
        $("#ddlOpco").DropDownTree(select_options);
        $("#ddlSbuBu").DropDownTree({
          title: "",
          multiSelect: true,
          selectChildren: true,
          clickHandler: function (element) { },
          checkHandler: function (element, checked) {
            const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
            this.userAccessDetails =  JSON.parse(userData);
            if(this.userAccessDetails?.data?.isPowerUser)
            {
                this.userRole = "Power User";
            }
            else if(this.userAccessDetails?.data?.isAdminUser)
            {
              this.userRole = "Administrator";
            }

            let dataToPost: any = [];
            this.selecteddataToPost = [];
            let getSelectedSbu = $("#ddlSbuBu>ul>li>ul>li> .fa-check-square-o")
            if (getSelectedSbu.length == 1) {
              $("#ddlSbuBu").SetTitle($($("#ddlSbuBu>ul>li>ul>li> .fa-check-square-o")).next().text());
            } else if (getSelectedSbu.length > 1) {
              $("#ddlSbuBu").SetTitle("Multiple Selected");
            } else {
              $("#ddlSbuBu").SetTitle(" ");
            }
            let selectedData = $("#ddlSbuBu").GetSelected();
            for (let data of selectedData) {
              if (data.prevObject.length == 3) {
                let item = data.prevObject;
                dataToPost.push(
                  {
                    OpcoId: $(item[1]).data("opcoid"),
                    SbuId: $(item[1]).data("sbuid"),
                    BuId: $(item[0]).data("buid"),
                    UserRole : this.userRole,
                    objectId: component.objectId,
                    provId: component.provId
                  });
                // // this.selecteddataToPost.push({ OpcoId: $(item[1]).data("opcoid"), SbuId: $(item[1]).data("sbuid"), BuId: $(item[0]).data("buid") });
              }
            }
            component.SetValues(dataToPost);
          }
        });

        $("#ddlSbuBu button").prop('disabled', true).addClass("disable-button");
     // })
    }
    else {
      this.formValue.controls['OpcoId'].setValue(OpcoId);
    }
    this.opcofields = { dataSource: this.OpcoList, text: "text", value: "value" };
  }


  GetOpcos() {
    const _arrOpco: any = [];
    $.each(this.OpcoList, function (i, businessUnit) {
      _arrOpco.push({ title: businessUnit.name, href: "#" + (i + 1), dataAttrs: [{ title: "opcoid", data: businessUnit.id }] });
    });
    return _arrOpco;
  };

  GetSBU(selectedOpco, isOpcoChecked) {
    const component = this;
    let _arrOpcoSbu: any = [];
    this.apiService.get("StrategicBU/GetSBUByObjProv?objectId="+this.objectId+"&provId=" + this.provId).subscribe(res => {
      this.sbuList = res.filter(r => r.operatingCompanyId == selectedOpco);
      this.apiService.get("BusinessUnit/GetBUByObjProv?objectId="+this.objectId+"&provId=" + this.provId).subscribe(res => {
        this.buList = res.filter(r => r.operatingCompanyId == selectedOpco);
        $.each(this.sbuList, (i, strategicBusinessUnit) => {
          _arrOpcoSbu.push(
            {
              title: strategicBusinessUnit.name,
              href: "#" + (i + 1),
              dataAttrs: [
                { title: "opcoid", data: selectedOpco },
                { title: "sbuid", data: strategicBusinessUnit.id }],
              data: component.GetBU(strategicBusinessUnit.id)
            });
        });
        let arrSbuBu = _arrOpcoSbu;
        if (!isOpcoChecked) {
          $.each(arrSbuBu, function (i, sbuData) {
            $("#ddlSbuBu a").each(function (j, fdata) {
              if ($(fdata).text() == sbuData.title) {
                $(fdata).parent().remove();
                let getSelectedSbu = $("#ddlSbuBu>ul>li>ul>li> .fa-check-square-o")
                if (getSelectedSbu.length == 1) {
                  $("#ddlSbuBu").SetTitle($($("#ddlSbuBu>ul>li>ul>li> .fa-check-square-o")).next().text());
                } else if (getSelectedSbu.length > 1) {
                  $("#ddlSbuBu").SetTitle("Multiple Selected");
                } else {
                  $("#ddlSbuBu").SetTitle("");
                }
                // Remove the Select All element
                if ($("#ddlSbuBu").find("ul li").length == 1) {
                  $("#ddlSbuBu").find("ul li").remove();
                  $("#ddlSbuBu").SetTitle(" ");
                  $("#ddlSbuBu button").prop('disabled', true).addClass("disable-button");
                }
              }
            });
          });
          return false;
        }
        let arrOpcoSbu1: any = [];
        if ($("#ddlSbuBu").find("li").length == 0) {
          arrOpcoSbu1.push({ title: "All", href: "#", dataAttrs: [{ title: "sbuid", data: "All" }], data: arrSbuBu });
        }
        else {
          arrOpcoSbu1 = arrSbuBu;
        }
        if ($("#ddlSbuBu").html.length > 0) {
          $("#ddlSbuBuHidden").DropDownTree({
            data: arrOpcoSbu1,
            multiSelect: true,
            selectChildren: true,
          });
        }
        if ($("#ddlSbuBu").find("ul").length == 1) {
          $("#ddlSbuBu").find("ul").first().append($("#ddlSbuBuHidden").find("ul").first().html());
          $("#ddlSbuBuHidden").empty();
        }
        else if ($("#ddlSbuBu").find("ul").length > 1) {
          $("#ddlSbuBu").find("ul").eq(1).append($("#ddlSbuBuHidden").find("ul").eq(0).html());
          $("#ddlSbuBuHidden").empty();
        }
        if ($("#ddlOpco i").hasClass("fa-check-square-o")) {
          $("#ddlSbuBu button").prop('disabled', false).removeClass("disable-button");
          $("#ddlSbuBu ul a i").first().addClass("fa-caret-down").removeClass("fa-caret-right");
        }
        $("#ddlSbuBu").find("li ul").first().css("display", "block");
        $("#ddlSbuBu").addClass("open");
        return true;
      });
    });
  }

  GetBU(selectedSbu) {
    let _arrSbuBu: any = [];
    let bunitsList = this.buList.filter(r => r.strategicBusinessUnitId == selectedSbu);
    $.each(bunitsList, function (i, businessUnit) {
      _arrSbuBu.push({ title: businessUnit.name, dataAttrs: [{ title: "buid", data: businessUnit.id }] });
    });
    return _arrSbuBu;
  }


  SetValues(dataToPost: any) {
    this.selecteddataToPost = dataToPost;
  }

  OnSearch() {
    this.apiService.post("user/SearchUsers",this.selecteddataToPost).subscribe(res => {
      this.Data = res;
      this.displaytotalCount = this.Data.length;      
    })
  }
onShowEntryDropdownChange(event){
    if(event.rows === this.formDataLength) 
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "All";
    else if(event.rows === 10)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "10" ;
    else if(event.rows === 20)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML  = "20" ;
    else if(event.rows === 50)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "50" ;
    else if(event.rows === 100)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "100" ;
  }
  OnReset(){
     window.location.reload();
  }
}

