<div class="row fid-form">
    <div class="row" style="padding-left: 16px; margin-bottom: 15px;"
        *ngIf="form.formType === 'FEED Supplement' || form.formType === 'FID Supplement'">
        <span *ngIf="!form.IsOldVersionOfForms">
            <mat-slide-toggle (change)="onChange($event)" [checked]="form.optOutBusinessCase"
                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                <b>Opt Out</b>
                <!-- <b *ngIf="form.optOutBusinessCase">Opt In</b> -->
            </mat-slide-toggle>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowOptOutModal()"></span>
        </span>
    </div>

    <div class="row sub-section">
        <div class="col-xs-12">
            <span [(ngModel)]="businessCase.id" style="display: none;"></span>
            <strong>2.1 Primary Business Case: </strong>
            <i>Describe the rationale for this project. Include regional and asset class competitiveness,
                diversification, compliance, government requirement, other goals, etc.&nbsp;</i>
            <i>Certain regional and competitiveness data to be developed by Segment staff. Highlight any divergent
                co-owner views as applicable</i>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowPrimaryBusinessCaseModal()"></span>
        </div>
        <div class="col-xs-12" *ngIf="!form.optOutBusinessCase"
            [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.optOutBusinessCase || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <span>
                <ckeditor #primaryBusinessCase [editor]="Editor" [config]="config" id="primaryBusinessCase"
                    name="primaryBusinessCase" [(ngModel)]="businessCase.primaryBusinessCase"
                    (ready)="onReady($event,'PrimaryBusinessCase')">
                </ckeditor>
            </span>
        </div>
    </div>

    <div class="row sub-section" *ngIf="form.formType !== 'LLE'">
        <div class="col-xs-12">
            <strong>2.2 External Competitiveness and Internal Benchmarking: </strong>
            <i *ngIf="!form.IsOldVersionOfForms">Describe the external competitiveness of the opportunity.</i>
            <i *ngIf="form.IsOldVersionOfForms">Describe the external competitiveness of the opportunity. Describe how the major risks identified in Section 6 impact competitiveness.</i>
            <i>Work with Cost Engineering and Benchmarking for internal benchmarking.</i>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowCompetitivenessModal()"></span>
            <div class="row pl-16" *ngIf="!form.IsOldVersionOfForms">
                <span>
                    <mat-slide-toggle (change)="NACompetitivenessEventCheck($event, businessCase)"
                        [checked]="businessCase.naStatusCompetitiveness"
                        [disabled]="isOnHold || form.optOutBusinessCase || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                        <span class="app-font">N/A</span>
                    </mat-slide-toggle>
                </span>
            </div>
        </div>
        <div class="col-xs-12"
            [ngClass]="{'disabledNoOfCasesDiv': isOnHold || 
                businessCase.naStatusCompetitiveness || form.optOutBusinessCase || form.isReadOnly || isReadOnlyGlobalProjectLevel}"
            *ngIf="!businessCase.naStatusCompetitiveness">
            <span *ngIf="!form.optOutBusinessCase">
                <ckeditor #externalCompetitiveness [editor]="Editor" [config]="config" id="externalCompetitiveness"
                    name="externalCompetitiveness" [(ngModel)]="businessCase.competitiveness" [disabled]="isOnHold"
                    (ready)="onReady($event,'ExternalCompetitiveness')">
                </ckeditor>
            </span>
        </div>
    </div>

    <div class="row sub-section" *ngIf="form.formType !== 'LLE'">
        <div class="col-xs-12">
            <strong>2.3 Future opportunities enabled by this investment</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowfutureOpportunitiesModal()"></span>
            <div class="row pl-16" *ngIf="!form.IsOldVersionOfForms">
                <span>
                    <mat-slide-toggle (change)="NAFutureOpportunitiesEventCheck($event, businessCase)"
                        [checked]="businessCase.naStatusFutureopportunities"
                        [disabled]="isOnHold || form.optOutBusinessCase || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                        <span class="app-font">N/A</span>
                    </mat-slide-toggle>
                </span>
            </div>
        </div>
        <div class="col-xs-12"
            [ngClass]="{'disabledNoOfCasesDiv': isOnHold ||
                    businessCase.naStatusFutureopportunities || form.optOutBusinessCase || form.isReadOnly || isReadOnlyGlobalProjectLevel}"
            *ngIf="!businessCase.naStatusFutureopportunities">

            <span *ngIf="!form.optOutBusinessCase">
                <ckeditor #futureOpportunities [editor]="Editor" [config]="config" id="futureOpportunities"
                    name="futureOpportunities" [(ngModel)]="businessCase.futureOpportunities" [disabled]="isOnHold"
                    (ready)="onReady($event,'FutureOpportunities')">
                </ckeditor>
            </span>
        </div>
    </div>
</div>