export class ExecutionModel
{
    executionPerformanceId: number = 0;
    arId: number = 0;
    ceAppropriatedAmount: any = "";
    ceAppropriatedBasis: string = "";
    ceProbabilistic: ExecutionPerformanceFeature1A = new ExecutionPerformanceFeature1A();
    executeSchedule: ExecutionPerformanceFeature1A = new ExecutionPerformanceFeature1A();
    startupProduction: ExecutionPerformanceFeature1C = new ExecutionPerformanceFeature1C();
    benchCapitalCost: ExecutionPerformanceFeature1B = new ExecutionPerformanceFeature1B();
    benchExecute: ExecutionPerformanceFeature1B = new ExecutionPerformanceFeature1B();
    footNotes: string = "";
    modifiedBy: string = "";
}
export class ExecutionPerformanceFeature1A 
{
    ev: any = null;
    p10: any = null;
    p50: any = null;
    p90: any = null;
}
export class ExecutionPerformanceFeature1B 
{
    ar: any = null;
    topQuartile: any = null;
    industryAverage: any = null;
}
export class ExecutionPerformanceFeature1C 
{
    ev: any = null;
    p10: any = null;
    p90: any = null;
}