<div style="background-color: #fff;">
    <div>
        <h3>Copy Project and Forms</h3>
        <h4>{{ projectName }}</h4>
    </div>
    <form [formGroup]="formValue" (ngSubmit)="submitForm()" novalidate>
        <div class="row sub-section">
            <div style="padding-left: 6% ;padding-right: 6%;">
                <hr>
                <div class="col-sm-6 col-xs-12">
                    <div class="row form-group">
                        <div class="col-sm-4 col-xs-4">
                            <label style="float: right;" class="control-label form-label">Project ID:</label>
                        </div>
                        <div class="col-sm-5 col-xs-12">
                            <input class="form-control col-xs-4" formControlName="ProjectId" id="ProjectId_Value"
                                readonly="readonly" type="text" value="">

                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-sm-4 col-xs-12">
                            <label style="float: right;" class="control-label form-label">RU:</label>
                        </div>
                        <div class="col-sm-5 col-xs-12">
                            <select formControlName="SbuId" [attr.disabled]="butDisabled" class="form-control">
                                <!-- <option disabled>Select SBU</option> -->
                                <option *ngFor="let web of SbuList" [value]="web.SbuId">{{web.SbuName}}</option>
                            </select>
                            <!-- <input type="text" class="form-control" readonly="readonly"
                        data-bind="textInput: Project.StrategicBusinessUnit.Name" /> -->
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-sm-4 col-xs-12">
                            <label style="float: right;" class="control-label form-label">Asset Class:</label>
                        </div>
                        <div class="col-sm-5 col-xs-12">
                            <select formControlName="AssetClassId" [attr.disabled]="butDisabled" class="form-control">
                                <!-- <option disabled >Select Asset</option> -->
                                <option *ngFor="let web of AssetList" [selected]="web.AssetId=== 1"
                                    [value]="web.AssetId">{{web.AssetName}}</option>
                            </select>
                            <!-- <input type="text" class="form-control" readonly="readonly"
                        data-bind="textInput: Project.Asset.Name" /> -->
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-sm-4 col-xs-12">
                            <label style="float: right;" class="control-label form-label">New Project Name:</label>
                        </div>
                        <div class="col-sm-8 col-xs-12">
                            <input class="form-control col-xs-4" formControlName="ProjectName" id="Project_Name"
                                type="text" value="">
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xs-12">
                    <div class="row form-group">
                        <div class="col-sm-4 col-xs-12">
                            <label style="float: right;" class="control-label form-label">Segment:</label>
                        </div>
                        <div class="col-sm-5 col-xs-12">
                            <select formControlName="OpcoId" [attr.disabled]="butDisabled" class="form-control">
                                <!-- <option disabled>Select OPCO</option> -->
                                <option *ngFor="let web of OpcoList" [value]="web.OpcoId">
                                    {{web.OpcoName}}</option>
                            </select>
                            <!-- <input type="text" class="form-control" formControlName="opco" readonly="readonly"
                        data-bind="textInput: Project.OperatingCompany.Name" /> -->
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-sm-4 col-xs-12">
                            <label style="float: right;" class="control-label form-label">BU:</label>
                        </div>
                        <div class="col-sm-5 col-xs-12">
                            <select formControlName="BuId" [attr.disabled]="butDisabled" class="form-control">
                                <!-- <option disabled>Select BU</option> -->
                                <option *ngFor="let web of BuList" [value]="web.BuId">{{web.BuName}}</option>
                            </select>
                            <!-- <input type="text" class="form-control" formControlName="bu" readonly="readonly"
                        data-bind="textInput: Project.BusinessUnit.Name" /> -->
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-sm-4 col-xs-12">
                            <label style="float: right;" class="control-label form-label">RSID:</label>
                        </div>
                        <div class="col-sm-5 col-xs-12">
                            <input class="form-control col-xs-4" formControlName="Rsid" id="Rsid_Value" type="text"
                                value="">
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>
 <div style="color:#ec0e0e; margin-left: 7% ;margin-right: 6%;">
        NOTE: Copy Project is one time activity, when you create copy of project second time it will create new project.
        <hr style="margin-left: 6% ;margin-right: 6%;">
</div>
    
    <div class="row">
        <h4 style="float: left; padding-left: 7%;">Select forms to copy</h4>
    </div>
    <div class="row" style="padding-left: 6% ;padding-right: 6%;">
        <hr>
        <div class="table table-hover table-responsive table-striped !important">

            <p-table #projectdt selectionMode="single" [value]="projects" stateStorage="local"
                stateKey="statedemo-local">

                <ng-template pTemplate="header">
                    <tr  class="p-table table-hover table-responsive table-striped !important"  style="background-color: lightskyblue;height: 40px;font-size: 0.88em;">
                        <th></th>
                        <th pSortableColumn="Version">
                            Version
                        </th>
                        <th pSortableColumn="Version">
                            Version Name
                        </th>
                        <th pSortableColumn="RequestType">
                            Request Type
                        </th>
                        <th pSortableColumn="DocumentType">Document Type
                        </th>
                        <th pSortableColumn="Status" style="margin-right: 5% ;">Status
                        </th>
                        <th pSortableColumn="ModifiedDateTime">
                            Date Modified
                        </th>

                        <th pSortableColumn="CreatedBy">
                            Created By
                        </th>

                    </tr>
                    <!-- <tr>
                <th style=" background-color:#efefef !important;"></th>
                <th style="height:6rem; background-color:#efefef !important;">
                  <input style="max-width:10em" pInputText type="text" #searchVersion
                    (input)="projectdt.filter(searchVersion.value,'Version', 'contains')" placeholder="Search..."
                    class="p-column-filter" />
                </th>
    
                <th style=" background-color:#efefef !important;">
                  <input style="max-width:5em" pInputText type="text" #searchRequestType
                    (input)="projectdt.filter(searchRequestType.value,'RequestType', 'contains')" placeholder="Search..."
                    class="p-column-filter" />
                </th>
    
                <th style=" background-color:#efefef !important;">
                  <input style="max-width:5em" pInputText type="text" #searchDocumentType
                    (input)="projectdt.filter(searchDocumentType.value,'DocumentType', 'contains')" placeholder="Search..."
                    class="p-column-filter" />
                </th>
    
    
                <th style=" background-color:#efefef !important;">
                  <input pInputText style="max-width:5em" type="text" #searchStatus
                    (input)="projectdt.filter(searchStatus.value,'Status', 'contains')" placeholder="Search..."
                    class="p-column-filter" />
                </th>
                <th style=" background-color:#efefef !important;">
                  <input pInputText style="max-width:5em" type="text" #searchDateModified
                    (input)="projectdt.filter(searchDateModified.value,'DateModified', 'contains')" placeholder="Search..."
                    class="p-column-filter" />
                </th>
                <th style=" background-color:#efefef !important;">
                  <input pInputText style="max-width:5em" type="text" #searchApprovedDate
                    (input)="projectdt.filter(searchApprovedDate.value,'ApprovedDate', 'contains')" placeholder="Search..."
                    class="p-column-filter" />
                </th>
                <th style=" background-color:#efefef !important;">
                  <input pInputText style="max-width:5em" type="text" #searchCreatedBy
                    (input)="projectdt.filter(searchCreatedBy.value,'CreatedBy', 'contains')" placeholder="Search..."
                    class="p-column-filter" />
                </th>
                <th style=" background-color:#efefef !important;">
                  <input pInputText style="max-width:5em" type="text" #searchID
                    (input)="projectdt.filter(searchID.value,'ID', 'contains')" placeholder="Search..."
                    class="p-column-filter" />
                </th>
              </tr> -->
                </ng-template>

                <ng-template pTemplate="body" let-project>
                    <tr  style="font-size: .99em;" class="p-table table-hover table-responsive table-striped !important">
                        <td><input type="checkbox" name="myData" [value]="project.OriginalId" checked="true" (change)="onCheckboxChange($event, project.OriginalId, project.RequestType, project.DocumentType, project.Version)"></td>
                        <td>{{ project.Version }}</td>
                        <td *ngIf="(project.Name != null) && (project.Version != project.Name)">{{ project.Name }}</td>
                        <td *ngIf="(project.Name != null) && (project.Version == project.Name)"></td>
                        <td>{{ project.RequestType }}</td>
                        <td>{{ project.DocumentType }}</td>
                        <td  style="margin-right: 5% ;">{{ project.Status }}</td>
                        <td>{{ project.ModifiedDateTime | date: 'MM/dd/yyyy'}}</td>
                        <td>{{ project.CreatedBy }}</td>

                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="row">
            &nbsp;
        </div>
        <div>
            <hr>
            <button style="width:10em; color: white !important; background-color: #5bc0de;border-color: #46b8da;"
                class="btn btn-primary" routerLink= "/go36project/project">
                <div>Cancel </div>
            </button>
            <button style="max-width:8em; width:10em;float: right; color: white !important; text-align: center; background-color: #337ab7;border-color: #337ab7; margin-right: 0.9rem;" class="btn btn-primary" (click)="SaveCopyForm()" >
                <div>Copy Project</div>
            </button>
        </div>
    </div>
</div>