import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ArchiveDialogComponent } from 'src/app/Modals/archive-dialog/archive-dialog.component';
import { Form } from 'src/app/Model/Form.model';
import { ProjectData } from 'src/app/Model/ProjectData.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { environment } from 'src/environments/environment';
import { FeedNavService } from '../../FEEDServices/feed-nav.service';
import { LessonsLearnedService } from '../../FEEDServices/lessons-learned.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-lessons-learned-nav',
  templateUrl: './lessons-learned-nav.component.html',
  styleUrls: ['./lessons-learned-nav.component.css']
})
export class LessonsLearnedNavComponent implements OnInit {
  baseUrl: any = environment.apiBaseUrl;
  pdfUrl: any ="";
  selectedPhaseHeader : any = "";
  versionText : any = "";
  readOnly : boolean = false;
  selectedPhase : any = 0;
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  formCase: string | null = '';
  projectId: number = 0;
  formId: number = 0;
  versionName:number=0;
  form = new Form();
  projectName: string = '';
  projectData = new ProjectData();
  ARType: string | undefined = '';
  formToDeleteId : number = 0;
  userAccessList : any;
  userAccessDetails: any;
  formTableFormId : number = 0;

  constructor(private lessonLearnedService: LessonsLearnedService,private go36ProjectService: Go36projectService
    ,private router: Router, private route: ActivatedRoute,private feednavService: FeedNavService,
     public dialog: MatDialog, public utilityService: UtilityService) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formCase = (this.route.snapshot.paramMap.get("formCase"));
    if (this.formCase == 'Create' || this.formCase == 'LLCreate') {
      this.projectId = Number(this.route.snapshot.paramMap.get("projectId"));
      this.formId = Number(this.route.snapshot.paramMap.get("formId"));
      this.versionName = Number(this.route.snapshot.paramMap.get("versionName"));
    }
    else {
      this.route.queryParams
          .subscribe(params => {
            this.projectId = Number(params['projectId']);
            this.formId = Number(params['formId']);
            this.versionName = Number(params['versionName']);
          });
      }
      this.getFEEDData();
      this.getLLSelectedPhase();
  }
  getFEEDData() {
    this.feednavService.getData(this.formId,this.versionName).subscribe((res: Form) => {
      this.form = res;
      this.formTableFormId = this.form.id;
      this.readOnly = res.isReadOnly;
      this.formToDeleteId = this.form.id;
      this.projectName = this.form.BUName + ' - ' + ' ' + this.form.Name + ' - ' +'(version #' + this.versionName + ')';
      this.pdfUrl = `${this.baseUrl}api/LessonLearned/GetLLPhasePDF?projectId=${this.projectId}&formId=${this.formToDeleteId}`;
      this.GetUserAccessList();
    });    
  }
  getLLSelectedPhase(){
    this.lessonLearnedService.getLLSelectedPhase(this.projectId,this.formId).subscribe((res: any) => {
     this.selectedPhaseHeader = res.text;
     this.selectedPhase = Number(res.value);
    });
  }
  OpenArchiveDialog() {
    this.dialog.open(ArchiveDialogComponent, {
      width: "600px",
      data:{projectId:this.projectId,formId:this.formId,oldVersionId:this.versionName,formType: this.form.formType},
    });
  }
  changeHeader($event:any) {  
    this.selectedPhaseHeader = $event.headerText;
    this.versionText = $event.versionText;
    this.readOnly = $event.readOnly;
  } 
  changeQueryData($event:any) {  
    this.formId = $event.formId == undefined ? this.formId : $event.formId;
    this.versionName = $event.versionName;
    this.formToDeleteId = $event.formToDeleteId;
    this.projectName = this.form.BUName + ' - ' + ' ' + this.form.Name + ' - ' +'(version #' + this.versionName + ')';
    this.pdfUrl = `${this.baseUrl}api/LessonLearned/GetLLPhasePDF?projectId=${this.projectId}&formId=${this.formToDeleteId}`;
  }
  
  GetUserAccessList()
  {
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
     this.userAccessDetails =  JSON.parse(userData);
     let userRole : string = '';
     // we can create the constants of the users role
     if(this.userAccessDetails?.data?.isPowerUser)
     {
        userRole = "Power User";
     }
     else if(this.userAccessDetails?.data?.isAdminUser)
     {
        userRole = "Administrator";
     }
     else if(this.userAccessDetails?.data?.isBasicMember)
     {
        userRole = "Basic Member";
     }
     else if(this.userAccessDetails?.data?.isConsultantUser)
     {
        userRole = "Consultant";
     }
     else if(this.userAccessDetails?.data?.isLeadershipUser)
     {
        userRole = "Leadership";
     }
      this.feednavService.GetUserAccessList(this.formTableFormId, this.objectId, this.provId).subscribe(res => {
      this.userAccessList = res;
      if(!this.userAccessList?.data?.canViewProject){
        this.router.navigate(["errorpage"]);
      }
    });
  }
  
  // ExportPDF(){
  //   this.lessonLearnedService.getLLPhasePDF(this.projectId,this.formId,this.formToDeleteId).subscribe((response)=>{
  //   let file = new Blob([response], { type: 'application/pdf' });            
  //   let fileURL = URL.createObjectURL(file);
  //   window.open(fileURL);
  // })
  // }
}
