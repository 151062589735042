import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { UserActivity } from 'src/app/Model/UserActivity.model';
import { SignalRService } from 'src/app/Shared/signal-r.service';

@Component({
  selector: 'app-popup-active-users',
  templateUrl: './popup-active-users.component.html',
  styleUrls: ['./popup-active-users.component.css']
})
export class PopupActiveUsersComponent implements OnInit {
  userActivityData = new UserActivity();
  currentUserProfile!: ICvxClaimsPrincipal;
  formId: number = 0;
  formCase: string | null = '';
  intervalReference: any;
  constructor(public signalRService: SignalRService, private authService: CalAngularService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.startHubConnection();
  }
  ngOnDestroy() {
    if (this.intervalReference) {
      clearInterval(this.intervalReference);
      this.signalRService.userActivityDataList = [];
    }
  }
  startHubConnection() {
    this.signalRService.startConnection().then(() => {
      this.formCase = (this.route.snapshot.paramMap.get("formCase"));
      if (this.formCase == 'Create' || this.formCase == 'LLCreate') {
        this.formId = Number(this.route.snapshot.paramMap.get("formId"));
        this.signalRService.formId = this.formId;
        this.signalRService.broadcastActiveUsersData(this.getUserActivityData());
        this.intervalReference = setInterval(() => {
          this.signalRService.broadcastActiveUsersData(this.getUserActivityData());
        }, 30000);
        this.signalRService.addBroadcastActiveUsersListener();
      }
      else {
        this.route.queryParams
          .subscribe(params => {
            this.formId = Number(params['formId']);
            this.signalRService.formId = this.formId;
            this.signalRService.broadcastActiveUsersData(this.getUserActivityData());
            this.intervalReference = setInterval(() => {
              this.signalRService.broadcastActiveUsersData(this.getUserActivityData());
            }, 30000);
            this.signalRService.addBroadcastActiveUsersListener();
          });
      }
    })
      .catch((err) => console.log("Connection Error: " + err + " Trying to reconnect"));
  }
  getUserActivityData(): UserActivity {
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    this.userActivityData.objectId = this.currentUserProfile.objectId;
    this.userActivityData.provisioningId = this.currentUserProfile.provisioningId;
    this.userActivityData.email = this.currentUserProfile.email;
    this.userActivityData.formId = this.formId;
    //  // this.userActivityData.formType = 'FEED';
    let userName = this.currentUserProfile.name.split(",");
    this.userActivityData.lastName = userName[0].trim();
    this.userActivityData.firstName = userName[1].split('[')[0].trim();
    return this.userActivityData;
  }
}