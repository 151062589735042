import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-go36project',
  templateUrl: './go36project.component.html',
  styleUrls: ['./go36project.component.css']
})

export class Go36projectComponent implements OnInit {
  ngOnInit(): void {
    // This is not implemented
  }
}