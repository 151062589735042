export class Operational {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn: Date=new Date();
    modifiedOn: Date=new Date();
    order: number = 0;
    formId: number = 0;
    operationalName?: string = '';
    oil: any;
    gas: any;
    total:any;
    total8: any;
    partnerView:any;
    comments: string = '';
    gasComments: string = '';
    oilComments: string = '';
    totalComments: string = '';
    total8Comments: string = '';
    partnerViewComments: string = '';
    originalTotalComments: string = '';
    originalPartnerView: any;
    originalTotal: any;
        
}