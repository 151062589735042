<div class="box-container">
    <div class="row" >
        <div class="col">
            <h3>Lessons Learned - Dashboard</h3>
        </div>
    </div>
    <div class="row tabbed tabbed-content-control centered">
        <div class="filter-bar tab-filter-bar font-gotham centered-xs">
            <mat-tab-group  animationDuration="0ms" style='min-height:300px' [disableRipple]='true' disablepagination="true">
                <mat-tab label="LL - Project Phases">
                    <ng-template matTabContent>
                        <app-lessons-learned-dashboard [type]="Phase"></app-lessons-learned-dashboard>
                    </ng-template>
                </mat-tab>
                <mat-tab label="LL - FEED">
                    <ng-template matTabContent>
                        <app-lessons-learned-dashboard [type]="Feed"></app-lessons-learned-dashboard>
                    </ng-template>
                </mat-tab>
                <mat-tab label="LL - FEED Supplements">
                    <ng-template matTabContent>
                        <app-lessons-learned-dashboard [type]="FeedSupplement"></app-lessons-learned-dashboard>
                    </ng-template>
                </mat-tab>
                <mat-tab label="LL - FID">
                    <ng-template matTabContent>
                        <app-lessons-learned-dashboard [type]="Fid"></app-lessons-learned-dashboard>
                    </ng-template>
                </mat-tab>
                <mat-tab label="LL - FID Supplements">
                    <ng-template matTabContent>
                        <app-lessons-learned-dashboard [type]="FidSupplement"></app-lessons-learned-dashboard>
                    </ng-template>
                </mat-tab>
                <mat-tab label="LL - SSBOD">
                    <ng-template matTabContent>
                        <app-lessons-learned-dashboard [type]="Ssbod"></app-lessons-learned-dashboard>
                    </ng-template>
                </mat-tab>
                <mat-tab label="LL - P4">
                    <ng-template matTabContent>
                        <app-lessons-learned-dashboard [type]="LookbackPart4"></app-lessons-learned-dashboard>
                    </ng-template>
                </mat-tab>
                <mat-tab label="LL - P5">
                    <ng-template matTabContent>
                        <app-lessons-learned-dashboard [type]="LookbackPart5"></app-lessons-learned-dashboard>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>                   
        </div>
    </div>
    <div class="row">
        <hr>
    </div>
    <div class="row" style="margin-top: 15px;">
        <div class="col-xs-12 padding-0">
            <div class="row">
                <div class="form-group col-sm-6 col-xs-12 text-left padding-0 ">
                    <button type="button"  style="color: white !important;" class="btn btn-sm btn-primary" [routerLink]="['/go36project/project']">Close</button>
                </div>
                <div class="form-group col-sm-6 col-xs-12 text-right padding-0">
                    <a [href]="pdfUrl" class="btn btn-sm btn-primary btn-dark" target="_blank" style="color: white !important;margin-right: 0.5em;margin-bottom: 0.5em;">PDF</a>
                </div>
            </div>
        </div>
    </div>       
</div>