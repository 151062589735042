export class AlternativeSelectionModel {
    id: number = 0;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn?: Date;
    modifiedOn?: Date;
    formId: number = 0;
    alterativePreferredDescription?: string = "";
    alternativeMinimumFunctionalityCase?: string = "";
    alternativeReferenceCase?: string = '';
    tradeoffsAndConcept?: string = "";
    walkawayPoint?: string = "";
    naalternatDescription: boolean = false;
}