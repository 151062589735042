import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { AppsettingsService } from '../../../Shared/appsettings.service'
import { Observable } from 'rxjs';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { BnNgIdleService } from 'bn-ng-idle';

@Injectable({
  providedIn: 'root'
})

export class Go36projectService {
  isHeaderVisible: boolean = true;
  constructor(private http: HttpClient,
    private appsettings: AppsettingsService,
    private authService: CalAngularService,
    private bnIdle: BnNgIdleService) { }

  currentUserProfile!: ICvxClaimsPrincipal;
  list: any;
  Listurl = "api/Project?objectId=";
  DropdatadownUrl = "api/Project/dropdown";
  CreateUrl = 'api/Project/AddProject/';
  updateurl = 'api/Project/UpdateProject/';
  PostuserAssetUrl = 'api/UserAccess/SaveUserAccessbyId';
  PostuserAssetUrl1 = 'api/UserAccess';
  Listformurl = "api/Form/GetFormsByProjectId?projectId=";
  GetUserAccessUrl = "api/UserAccess/";
  SearchUserUrl = "api/UserAccess/SearchUser?name=";
  UpdateUserAccessUrl = "api/UserAccess/UpdateUserAccess";
  RemoveUserAccessUrl = "api/UserAccess/RemoveUserAccess";
  getProjectNameByProjectIdUrl = "api/Form/GetProjectNameByProjectId?projectId=";
  getProjectByProjectIdUrl = "api/Project/EditProject?projectId=";
  getFormDetailsUrl = "api/Form/GetFormDetailsByProjectId?projectId=";
  Listformtypesurl = "api/Form/GetFormTypesByProjectId?projectId=";
  Listeaformurl = "api/Form/GetEaFormsByProjectId?projectId=";
  deleteProjectUrl = 'api/Project/DeleteProject';
  getUserDetailsByObjectProvIdURL = "api/UserAccess/GetUserDetailsByObjectProvId?objectId=";
  getProjectInformationByProjectIdUrl = "api/Project/GetProjectInformationByProjectId?projectid=";
  getmyprojectformurl = "api/Form/GetMyFormsByUserLogin?objectId=";
  getmylookbackformurl = "api/Form/GetMyLookBackFormsByUser?objectId=";
  ActiveUserUrl = "api/Register/GetActiveUser?objectId=";
  registerUserUrl = 'api/Register/RegisterUser/';
  RolesdataUrl = 'api/Register/GetRoles';
  getUserAccessListForLookBackFormsUrl = "api/UserAccess/GetUserAccessListForLookBackForms?inputData=";
  getUserAccessListByProjectIdURL = "api/UserAccess/GetUserAccessListByProjectId?projectId=";
  SaveCopyFormURL = 'api/Form/SaveCopyForm';
  CopyListFormURL = "api/Form/GetFormsByProjectIdCopyForm?projectId=";
  getOpcoName = "OperatingCompanies/GetOpcoById?opcoId=";
  getmyrsidprojecturl = "api/Form/GetAllRSIDProjects?objectId=";
  getmyEArsidprojecturl = "api/Form/GetAllEARSIDProjects?objectId=";
  getmyEAprojectformurl = "api/Form/GetAllEAForms?objectId=";
  getFormsInWorkFlowurl = "api/Form/GetFormsInWorkflow?role=";
  generateRsidReporturl = "api/Report/GenerateRSIDReport";
  getEditUserAccessPermissionCheckURL = "api/UserAccess/CheckEditUserAccess?userid="

  getProject(objectId: string, provId:string, userRole: string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.Listurl + objectId + "&provId=" + provId + "&userRole=" + userRole)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getDropdownData(): Observable<any[]> {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.DropdatadownUrl)
      .pipe(map((res1: any) => {
        return res1;
      }))
  }

  postProject(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.CreateUrl, formData)
  }

  updateProject(formData: any) {
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.updateurl, formData);
  }
  GetuserCai() {
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    let userCai = this.currentUserProfile.cai;
    return userCai;
  }

  GetuserName() {
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    let username = this.currentUserProfile.name;
    return username;
  }

  PostUseraccess(objectId: string, provId: string, projectId: number, assetid: number, CreatedBy: string, ModifiedBy: string, role: string) {
    const params = new HttpParams()
      .append('objectId', objectId)
      .append('provId', provId)
      .append('projectId', projectId)
      .append('assetid', assetid)
      .append('CreatedBy', CreatedBy)
      .append('ModifiedBy', ModifiedBy)
      .append('role', role);
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.PostuserAssetUrl, '', {
      params: params
    });
  }

  getUserAccess(projectid: number): Observable<any[]> {
    const params = new HttpParams()
      .append('projectid', projectid)

    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetUserAccessUrl, {
      params: params
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  GetUserBySearch(name: string) {

    return this.http.get<any>(this.appsettings.apiBaseUrl + this.SearchUserUrl + name);
  }


  getViewForm(projectID: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.Listformurl + projectID);

  }

  getEAViewForm(projectID: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.Listeaformurl + projectID);

  }

  getViewFormTypes(projectID: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.Listformtypesurl + projectID);
  }

  UpdateUserAccess(objectId: string, provId: string, role: string, projectid: number, modifiedby: string) {
    const params = new HttpParams()
      .append('objectId', objectId)
      .append('provId', provId)
      .append('role', role)
      .append('projectid', projectid)
      .append('modifiedby', modifiedby)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.UpdateUserAccessUrl, '', { params: params }).subscribe(res => {

    });;
  }

  RemoveUserAccess(objectId: string, provId: string, projectid: number, modifiedby: string) {
    const params = new HttpParams()
      .append('objectId', objectId)
      .append('provId', provId)
      .append('projectid', projectid)
      .append('modifiedby', modifiedby)

    return this.http.post<any>(this.appsettings.apiBaseUrl + this.RemoveUserAccessUrl, '', { params: params }).subscribe(res => {

    });
  }
  getProjectNameByProjectId(projectId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getProjectNameByProjectIdUrl + projectId);
  }

  GetEditProjectData(projectId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getProjectByProjectIdUrl + projectId);
  }

  getFormDetails(projectId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getProjectNameByProjectIdUrl + projectId);
  }

  deleteProjectData(projectId: number, userName: string) {
    const params = new HttpParams()
      .append('projectId', projectId)
      .append('userName', userName)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.deleteProjectUrl, '', { params: params });
  }

  GetUserDetailsByObjectProvId(objectId: string, provId: string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getUserDetailsByObjectProvIdURL + objectId + "&provId=" + provId);
  }

  GetProjectInfobyId(projectId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getProjectInformationByProjectIdUrl + projectId);
  }
  getMyProjectForms(objectId: string, provId: string, userRole: string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getmyprojectformurl + objectId + "&provId=" + provId + "&userRole=" + userRole);

  }
  getMyLookBackForms(objectId: string, provId: string, userRole: string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getmylookbackformurl + objectId + "&provId=" + provId + "&userRole=" + + userRole);

  }

  GetActiveUser(objectId: string, provId: string) {

    return this.http.get<any>(this.appsettings.apiBaseUrl + this.ActiveUserUrl + objectId + "&provId=" + provId);
  }
  NewUserRegister(userData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.registerUserUrl, userData)
  }
  getRolesData(): Observable<any[]> {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.RolesdataUrl)
      .pipe(map((res1: any) => {
        return res1;
      }))
  }
  GetUserAccessListForLookBackForms(inputData: any) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getUserAccessListForLookBackFormsUrl + inputData);
  }
  // public ShowHeaderForRegisterUser = () => {
  //   this.isHeaderVisible=false;
  //   return this.isHeaderVisible;
  // }
  // public ShowHeaderDefault = () => {
  //   this.isHeaderVisible=true;
  //   return this.isHeaderVisible;
  // }

    GetUserAccessListByProjectId(projectId: number, objectId: string, provId: string) {
      return this.http.get<any>(this.appsettings.apiBaseUrl + this.getUserAccessListByProjectIdURL +projectId+"&objectId="+objectId+"&provId="+provId)
    }
    SaveCopyForm(formId: number, projectName:string, objectId: string, provId: string, currentProjectId: number, inputData:any) {
      const params = new HttpParams()
        .append('formId', formId)
        .append('projectName',projectName)        
        .append('objectId',objectId)
        .append('provId',provId)
        .append('currentProjectId',currentProjectId)
        .append('inputData',inputData)        
      return this.http.post<any>(this.appsettings.apiBaseUrl + this.SaveCopyFormURL, '', {
        params: params
      });
    }

  GetFormsByProjectIdCopyForm(projectID: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.CopyListFormURL + projectID);

  }
  GetOpcoNameById(opcoId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getOpcoName + opcoId);
  }

  getMyRSIDProject(objectId: string, provId: string, userRole: string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getmyrsidprojecturl + objectId+ '&provId=' + provId + '&userRole=' + userRole);

  }
  getMyEAProjectForm(objectId: string, provId: string, userRole: string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getmyEAprojectformurl + objectId + '&provId=' + provId + '&userRole=' + userRole);

  }
  getMyEARSIDProject(objectId: string,provId: string, userRole: string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getmyEArsidprojecturl + objectId + '&provId=' + provId + '&userRole=' + userRole);

  }
  getFormsInWorkFlow(role: string, objectId: string,provId: string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getFormsInWorkFlowurl + role + '&objectId=' + objectId + '&provId=' + provId);
  }

  checkViewingPermissionForEditUser(userid: number, objectId: string,provId: string) {
    return this.http.get<boolean>(this.appsettings.apiBaseUrl + this.getEditUserAccessPermissionCheckURL + userid + '&objectId=' + objectId + '&provId=' + provId);
  }

  generateRsidReport() {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.generateRsidReporturl, httpOptions);
  }
}

