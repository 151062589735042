import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.css']
})
export class InfoDialogComponent implements OnInit {
  title: string = '';
  message: string = '';

  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoDialogModel) { 
      this.title = data.title;
    this.message = data.message;
    dialogRef.disableClose = true;
    setTimeout(() => {
      let element = document.querySelector(".mat-dialog-container");
      element?.setAttribute("style","padding: 0!important;border-radius: 6px!important;box-shadow: 0 5px 15px rgb(0 0 0 / 50%);");
      let element2 = document.querySelector("html");
      element2?.setAttribute("style","top: 0!important;");
      let element3 = document.querySelector(".cdk-overlay-container");
      element3?.setAttribute("style","z-index: 10040!important;");
      let element4 = document.querySelector(".cdk-global-overlay-wrapper");
      element4?.setAttribute("style","justify-content: center!important;align-items: center!important;");
      let element5 = document.querySelector(".cdk-overlay-pane");
      element5?.setAttribute("style","max-width: 80vw;width: 900px;position: static;");
      let element6 = document.querySelector(".cdk-overlay-dark-backdrop");
      element6?.setAttribute("style","background: rgba(0,0,0,.55)!important;");
    },0);
    }

  ngOnInit(): void {
    // This will be used on initialization
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class InfoDialogModel {
  constructor(public title: string, public message: string) {
  }
}
