<div class="box-container box-shadow" id="page-body">

  <div class="row">
    <div class="col">
      <h3>My Forms</h3>
    </div>
  </div>

  <div class="row">
    &nbsp;
  </div>
  <div class="row">
    <div class="col-sm-12">

      <p-table #formDatadt selectionMode="single" [value]="formData" [rows]="projectlength" [totalRecords]="projectlength" [paginator]="true"
        styleClass="p-datatable-projects p-datatable-sm" [rowsPerPageOptions]="[projectlength, 10, 20, 50, 100]"  stateStorage="session"
        stateKey="statedemo-projectform" paginatorPosition="both" (onPage)="onShowEntryDropdownChange($event)" [globalFilterFields]="[
          'Project Name',
              'RequestType',
              'DocumentType',
              'StatusName',
              'ModifiedDate',
              'ApprovedDate',
              'CreatedBy',
              'global'
        ]">
        <ng-template pTemplate="caption">
          <span><b>Show Entries</b></span>
          <div class="flex" style="float:right;margin-bottom: 1%;">
            <span class="p-input-icon-left ml-auto">
              <b>Search: </b><input #searchText pInputText type="text"
                (input)="formDatadt.filterGlobal(searchText.value, 'contains')"
                [value]="formDatadt.filters['global']? $any(formDatadt.filters['global'])?.value :''" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr style="background-color: lightskyblue;height: 40px;">
            <th></th>
            <th pSortableColumn="ProjectName">
              ProjectName <p-sortIcon field="ProjectName"></p-sortIcon>
            </th>
            <th pSortableColumn="RequestType">
              RequestType <p-sortIcon field="RequestType"></p-sortIcon>
            </th>
            <th pSortableColumn="DocumentType">DocumentType <p-sortIcon field="DocumentType"></p-sortIcon>
            </th>
            <th pSortableColumn="StatusName">Status <p-sortIcon field="StatusName"></p-sortIcon>
            </th>
            <th pSortableColumn="ModifiedDate">
              DateModified <p-sortIcon field="ModifiedDate"></p-sortIcon>
            </th>
            <th pSortableColumn="ApprovedDate">
              ApprovedDate <p-sortIcon field="ApprovedDate"></p-sortIcon>
            </th>
            <th pSortableColumn="CreatedBy">
              CreatedBy <p-sortIcon field="CreatedBy"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th style=" background-color:#efefef !important;"></th>
            <th style="height:6rem; background-color:#efefef !important;">
              <input style="max-width:10em" pInputText type="text" #searchProjectName
                (input)="formDatadt.filter(searchProjectName.value,'ProjectName', 'contains')" placeholder="Search..."
                [value]="formDatadt.filters['ProjectName']? $any(formDatadt.filters['ProjectName'])?.value :''"
                class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input style="max-width:5em" pInputText type="text" #searchRequestType
                (input)="formDatadt.filter(searchRequestType.value,'RequestType', 'contains')" placeholder="Search..."
                [value]="formDatadt.filters['RequestType']? $any(formDatadt.filters['RequestType'])?.value :''"
                class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input style="max-width:5em" pInputText type="text" #searchDocumentType
                (input)="formDatadt.filter(searchDocumentType.value,'DocumentType', 'contains')" placeholder="Search..."
                [value]="formDatadt.filters['DocumentType']? $any(formDatadt.filters['DocumentType'])?.value :''"
                class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchStatus
                (input)="formDatadt.filter(searchStatus.value,'StatusName', 'contains')" placeholder="Search..."
                [value]="formDatadt.filters['StatusName']? $any(formDatadt.filters['StatusName'])?.value :''"
                class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchDateModified
                (input)="formDatadt.filter(searchDateModified.value,'ModifiedDate', 'contains')" placeholder="Search..."
                [value]="formDatadt.filters['ModifiedDate']? $any(formDatadt.filters['ModifiedDate'])?.value :''"
                class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchApprovedDate
                (input)="formDatadt.filter(searchApprovedDate.value,'ApprovedDate', 'contains')" placeholder="Search..."
                [value]="formDatadt.filters['ApprovedDate']? $any(formDatadt.filters['ApprovedDate'])?.value :''"
                class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchCreatedBy
                (input)="formDatadt.filter(searchCreatedBy.value,'CreatedBy', 'contains')" placeholder="Search..."
                [value]="formDatadt.filters['CreatedBy']? $any(formDatadt.filters['CreatedBy'])?.value :''"
                class="p-column-filter" />
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="100" style="text-align: center;">
              No records found in the table. Please search for other text
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-form>
          <tr>
            <td style="padding: 2px 0px;">
              <div>
                <a *ngIf="form.FormType=='FEED'" style="width:5em;margin-bottom: 0.5rem; background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/feed']"
                  [queryParams]="{ projectId: form.ProjectId,formId: form.OriginalId,versionName:form.VersionNo,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='LL'" style="width:5em;margin-bottom: 0.5rem; background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/lessonlearned']"
                  [queryParams]="{ projectId: form.ProjectId,formId: form.OriginalId,versionName:form.VersionNo,formCase:'LLOpen'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='FEED Supplement'"
                  style="width:5em;margin-bottom: 0.5rem; background-color: royalblue;margin-bottom: 0.5rem;"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/feed_Supplement']"
                  [queryParams]="{ projectId: form.ProjectId,formId: form.OriginalId,versionName:form.VersionNo,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='Phase 3 to 4 (FID)'"
                  style="width:5em;margin-bottom: 0.5rem; background-color: royalblue" class="btn  btn-sm btn-info"
                  [routerLink]="['/forms/fid']"
                  [queryParams]="{ projectId: form.ProjectId,formId: form.OriginalId,versionName:form.VersionNo,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='FID Supplement'"
                  style="width:5em;margin-bottom: 0.5rem; background-color: royalblue" class="btn  btn-sm btn-info"
                  [routerLink]="['/forms/fid_Supplement']"
                  [queryParams]="{ projectId: form.ProjectId,formId: form.OriginalId,versionName:form.VersionNo,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='LLE'" style="width:5em;margin-bottom: 0.5rem; background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/feed']"
                  [queryParams]="{ projectId: form.ProjectId,formId: form.OriginalId,versionName:form.VersionNo,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='EPC'" style="width:5em;margin-bottom: 0.5rem; background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/feed']"
                  [queryParams]="{ projectId: form.ProjectId,formId: form.OriginalId,versionName:form.VersionNo,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <!-- <a *ngIf="form.FormType=='Part4'" style="width:5em; font-size:0.75em; background-color: royalblue" class="btn  btn-sm btn-info"
               [routerLink]="['/forms/feed']" [queryParams]="{ projectId: form.ProjectId,formId: form.OriginalId,versionName:form.VersionNo,formCase:'Open'}">
                 <div>Open </div>
              </a>
              <a *ngIf="form.FormType=='Part5'" style="width:5em; font-size:0.75em; background-color: royalblue" class="btn  btn-sm btn-info"
               [routerLink]="['/forms/feed']" [queryParams]="{ projectId: form.ProjectId,formId: form.OriginalId,versionName:form.VersionNo,formCase:'Open'}">
                 <div>Open </div>
              </a> -->
                <router-outlet></router-outlet>
                <button style="margin-left: 0.5rem; color: white !important;" class="btn btn-sm btn-success"
                  (click)="OpenFormSettings( form.ProjectId, form.OriginalId, form.VersionNo, form.FormType, form.Location, form.OpcoId)">
                  <div>Settings </div>
                </button>
              </div>
            </td>
            <td>{{ form.ProjectName }}</td>
            <td>{{ form.RequestType }}</td>
            <td>{{ form.DocumentType }}</td>
            <td>{{ form.StatusName }}</td>
            <td>{{ form.ModifiedDate | date: 'MM/dd/yyyy'}}</td>
            <td>{{ form.ApprovedDate | date: 'MM/dd/yyyy'}}</td>
            <td>{{ form.CreatedBy }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorleft" let-state>
          Showing {{(state.first) + 1}} to {{(state.totalRecords >
            (state.rows * (state.page + 1))) ? state.rows * (state.page + 1) : state.totalRecords }}
            of {{state.totalRecords}} entries
        </ng-template>
      </p-table>
    </div>
  </div>
</div>