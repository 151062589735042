<div class="box-container" id="page-body">

    <div class="row text-center">
        <h3>Form Settings</h3>
    </div>

    <form [formGroup]='formValue'>
    <div class="row">
            <div class="col-xs-offset-2 col-xs-8">
                <hr />
                <div data-bind="with: form">
                    <div class="row">
                        <div class="form-group col-xs-6">
                            <label class="control-label col-xs-4" for="opco">Project ID:</label>
                            <div class="col-xs-8">
                                <input type="text" class="form-control" formControlName="ProjectId"  id="ProjectId_Value" readonly="readonly" />
                            </div>
                        </div>
                        <div class="form-group col-xs-6">
                            <label class="control-label col-xs-4" for="opco">Project Location:</label>
                            <div class="col-xs-8">
                                <input type="text" class="form-control" formControlName="ProjectLocation" id="ProjectLocation_id" readonly="readonly" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-xs-6">
                            <label class="control-label col-xs-4" for="opco">Project Name:</label>
                            <div class="col-xs-8">
                                <input type="text" class="form-control" formControlName="ProjectName" readonly="readonly" />
                            </div>
                        </div>
                        <div class="form-group col-xs-6">
                            <label class="control-label col-xs-4" for="opco">Form Type:</label>
                            <div class="col-xs-8">
                                <input type="text" class="form-control col-xs-6"  formControlName="FormType" readonly="readonly" />
                            </div>
                        </div>
                    </div>
                    <hr />
                    
                    <h4 data-bind="visible: !$root.isLessonLearned()"> Chart Display Settings</h4>
                    <!-- <div data-bind="with: FormConfiguration, visible: (IsUpstream === true && !$root.isLessonLearned())"> -->
                        <div  *ngIf="isUpStream==true">
                        
                        <div class="row">
                            <div class="col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultCapitalAndExpenditureChartName" id="DefaultCapitalAndExpenditureChartName_id" value="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control"  formControlName="CapitalAndExpenditureChartName"  id="CapitalAndExpenditureChartName_Value"  />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxChangeCapitalAndExpenditureChart($event)" formControlName="ShowCapitalAndExpenditureChart" id="ShowCapitalAndExpenditureChart_id"  value="" /> Show Chart
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultProductionChartName" id="DefaultProductionChartName_id" value="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="ProductionChartName" id="ProductionChartName_id" value="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowProductionChart($event)" formControlName="ShowProductionChart" id="ShowProductionChart_id" value="" /> Show Chart
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultAfterTaxCashflowChartName" id="DefaultAfterTaxCashflowChartName_id" value="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="AfterTaxCashflowChartName" id="AfterTaxCashflowChartName" value="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowAfterTaxCashflowChart($event)" formControlName="ShowAfterTaxCashflowChart" id="ShowAfterTaxCashflowChart_id" value="" /> Show Chart
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultEarningPerBarrelChartName" id="DefaultEarningPerBarrelChartName_id" value="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="EarningPerBarrelChartName" id="EarningPerBarrelChartName_id" value="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowEarningPerBarrelChart($event)" formControlName="ShowEarningPerBarrelChart" id="ShowEarningPerBarrelChart_id" value="" /> Show Chart
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultCumulativeATCashflowChartName" id="DefaultCumulativeATCashflowChartName" value="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="CumulativeATCashflowChartName" id="CumulativeATCashflowChartName_id"  value="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowCumulativeATCashflowChart($event)" formControlName="ShowCumulativeATCashflowChart" id="ShowCumulativeATCashflowChart_id" value="" /> Show Chart
                                    </div>
                                </div>
                            </div>


                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultNriReservesBookingsChartName" id="DefaultNriReservesBookingsChartName_id" value="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="NriReservesBookingsChartName" id="NriReservesBookingsChartName_id" value="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowNriReservesBookingsChart($event)" formControlName="ShowNriReservesBookingsChart" id="ShowNriReservesBookingsChart_id" value="" /> Show Chart
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultEarningsChartName" id="DefaultEarningsChartName_id" value="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="EarningsChartName" value="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowEarningsChart($event)" value="" formControlName="ShowEarningsChart" id="ShowEarningsChart_id" /> Show Chart
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultOperatingCashMarginChartName" id="DefaultOperatingCashMarginChartName" value="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="OperatingCashMarginChartName" id="OperatingCashMarginChartName_id" value="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowOperatingCashMarginChart($event)" formControlName="ShowOperatingCashMarginChart" id="ShowOperatingCashMarginChart_id" value="" /> Show Chart
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- <div data-bind="with: FormConfiguration, visible: (IsUpstream === false && !$root.isLessonLearned())"> -->
                        <div *ngIf="isUpStream==false">
                        <div class="row">
                            <div class="col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultNonUpstreamVolumeChartName" id="DefaultNonUpstreamVolumeChartName_id" value="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="NonUpstreamVolumeChartName" id="NonUpstreamVolumeChartName_id" value="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowNonUpstreamVolumeChart($event)" value="" formControlName="ShowNonUpstreamVolumeChart" id="ShowNonUpstreamVolumeChart_id" /> Show Chart
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultNonUpstreamOperatingExpenseChartName" id="DefaultNonUpstreamOperatingExpenseChartName_id" value= "" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="NonUpstreamOperatingExpenseChartName" id="NonUpstreamOperatingExpenseChartName_id" value="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowNonUpstreamOperatingExpenseChart($event)" formControlName="ShowNonUpstreamOperatingExpenseChart" id="ShowNonUpstreamOperatingExpenseChart_id" value="" /> Show Chart
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultNonUpstreamCapitalAndExpenditureChartName" id="DefaultNonUpstreamCapitalAndExpenditureChartName_id" value ="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="NonUpstreamCapitalAndExpenditureChartName"  id="NonUpstreamCapitalAndExpenditureChartName_id" value="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowNonUpstreamCapitalAndExpenditureChart($event)" formControlName="ShowNonUpstreamCapitalAndExpenditureChart" id="ShowNonUpstreamCapitalAndExpenditureChart_id" value="" /> Show Chart
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultNonUpstreamEarningsBeforeInterestChartName" id="DefaultNonUpstreamEarningsBeforeInterestChartName_id" value= "" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="NonUpstreamEarningsBeforeInterestChartName" id="NonUpstreamEarningsBeforeInterestChartName_id" value ="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowNonUpstreamEarningsBeforeInterestChart($event)" formControlName="ShowNonUpstreamEarningsBeforeInterestChart" id="ShowNonUpstreamEarningsBeforeInterestChart_id" value="" /> Show Chart
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultNonUpstreamAfterTaxCashFlowChartName" id="DefaultNonUpstreamAfterTaxCashFlowChartName_id" value ="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="NonUpstreamAfterTaxCashFlowChartName" id="NonUpstreamAfterTaxCashFlowChartName_id" value="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowNonUpstreamAfterTaxCashFlowChart($event)" formControlName="ShowNonUpstreamAfterTaxCashFlowChart" id="ShowNonUpstreamAfterTaxCashFlowChart_id" value="" /> Show Chart
                                    </div>
                                </div>
                            </div>


                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultNonUpstreamOperationalEarningsAfterTaxChartName" id="DefaultNonUpstreamOperationalEarningsAfterTaxChartName_id" value ="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="NonUpstreamOperationalEarningsAfterTaxChartName" id="NonUpstreamOperationalEarningsAfterTaxChartName_id" value ="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowNonUpstreamOperationalEarningsAfterTaxChart($event)" formControlName="ShowNonUpstreamOperationalEarningsAfterTaxChart" id="ShowNonUpstreamOperationalEarningsAfterTaxChart_id" value="" /> Show Chart
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultNonUpstreamReturnOnCapitalEmployedChartName" id="DefaultNonUpstreamReturnOnCapitalEmployedChartName_id" value ="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="NonUpstreamReturnOnCapitalEmployedChartName" id="NonUpstreamReturnOnCapitalEmployedChartName_id" value="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowNonUpstreamReturnOnCapitalEmployedChart($event)"  formControlName="ShowNonUpstreamReturnOnCapitalEmployedChart" id="ShowNonUpstreamReturnOnCapitalEmployedChart_id" value="" /> Show Chart
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xs-6">
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">Default Title:</label>
                                    <div class="col-xs-8 p">
                                        <input type="text" class="form-control" formControlName="DefaultNonUpstreamCumulativeCashChartName" id="DefaultNonUpstreamCumulativeCashChartName_id" value="" readonly />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco">New Title:</label>
                                    <div class="col-xs-8">
                                        <input type="text" class="form-control" formControlName="NonUpstreamCumulativeCashChartName" id="NonUpstreamCumulativeCashChartName_id" value="" />
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="control-label col-xs-4" for="opco"></label>
                                    <div class="col-xs-8">
                                        <input type="checkbox" class="checkbox-inline" (change)="onCheckboxShowNonUpstreamCumulativeCashChart($event)" formControlName="ShowNonUpstreamCumulativeCashChart" id="ShowNonUpstreamCumulativeCashChart_id" value="" /> Show Chart
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 text-right">
                        <div class="form-group col-xs-12">
                            <!-- <a href="" role="button"  class="btn btn-info" style="margin-right: 2% ; margin-bottom: 1%;" (click)="cancelFormSettings">Cancel</a> -->
                            <button type="button" class="btn btn-info" style="margin-right: 2% ; margin-bottom: 1%; background-color: #5bc0de; color: #fff !important; border-color: #46b8da;"  (click)="cancelFormSettings()">Cancel</button>
                            <button type="button" class="btn btn-primary btn-light" style="color: #fff !important" (click)="saveFormSettings()">Save Settings</button>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-3">
                &nbsp;
            </div>
    </div>
    </form>
</div>