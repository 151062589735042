import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetService } from 'src/app/admin/service/asset.service';
import { UserAccess } from '../../asset.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';

declare let $;
@Component({
  selector: 'app-useraccess-edit',
  templateUrl: './useraccess-edit.component.html',
  styleUrls: ['./useraccess-edit.component.css']
})

export class UseraccessEditComponent implements OnInit {
  sbuListFiltered: any[] = [];
  buListFiltered: any[] = [];
  roles: any;
  basicSelection: boolean = false;
  powerUserSelection: boolean = false;
  commonSelectionAdministrator = false;
  commonSelectionLeadership = false;
  commonSelectionConsultant = false;
  userExist: boolean = false;
  orgData: any[] =[];
  user: any= {};
  projectData: any =[];
  dropdowns: any= {};
  userId: number=0;
  selecteddataToPost: any;
  currentRole: number = 0;
  opcoId: any = undefined;
  sbuId: any = undefined;
  buId: any = undefined;
  userUpdateData:UserAccess = new UserAccess();
  userAccessDetails: any;
  userRole : string ='';
  objectId:string = "";
  provisioningId:string = "";
  constructor(private formbuilder: UntypedFormBuilder,private route:ActivatedRoute,private cvxClaims:Go36projectService,
    private apiService: AssetService,private router: Router,private notifyService: NotificationService,private projectservice: Go36projectService, private utilityService: UtilityService) {  }

  ngOnInit(): void {
    this.objectId = this.utilityService.GetuserObjectId();
    this.provisioningId = this.utilityService.GetuserProvisioningId();

    this.route.queryParams
    .subscribe(params => {
      this.userId = Number(params['userId']);
      this.CheckIfAuthorizedToEdit(this.userId, this.objectId, this.provisioningId);
      this.fillRolesDDL();
      this.loadUserData();
    });
  }

  CheckIfAuthorizedToEdit(userid:number, objectId:string, provisioningId:string){
    this.projectservice.checkViewingPermissionForEditUser(userid, objectId, provisioningId).subscribe(res => {
      if(!res){
        this.router.navigate(["errorpage"]);
      }
    });
  }

  fillRolesDDL() {
    this.apiService.get("user/GetRoles").subscribe(res => {      
     const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
     this.userAccessDetails =  JSON.parse(userData);
     if(this.userAccessDetails?.data?.isPowerUser)
     {
        this.userRole = "Power User";
     }
     else if(this.userAccessDetails?.data?.isAdminUser)
     {
      this.userRole = "Administrator";
     }
     if(this.userRole == "Administrator")
      {
        res.splice(0, 1);
      }
      this.roles = res;
    })
  }
  onRoleChange(roleId: any) {
    this.currentRole = Number(roleId);
    this.basicSelection = false;
    this.powerUserSelection = false;
    this.commonSelectionAdministrator = false;
    this.commonSelectionLeadership = false;
    this.commonSelectionConsultant = false;

    if (this.currentRole == 8) {
      this.powerUserSelection = true;
    }
    if (this.currentRole == 12) {
      this.basicSelection = true;
      //change filter list
      this.changeFilterListForSbuBu();
    }
    if (this.currentRole == 9) {
      this.commonSelectionAdministrator = true;
      setTimeout(()=> {this.fillOpcoSbuBuDropdowns("#ddlOpcoAdministrator","#ddlSbuBuAdministrator","#ddlSbuBuHiddenAdministrator");},1)
    }
    if(this.currentRole == 10){
      this.commonSelectionLeadership = true;
      setTimeout(()=> {this.fillOpcoSbuBuDropdowns("#ddlOpcoLeadership","#ddlSbuBuLeadership","#ddlSbuBuHiddenLeadership");},1)
    }
    if(this.currentRole ==11){
      this.commonSelectionConsultant = true;
      setTimeout(()=> {this.fillOpcoSbuBuDropdowns("#ddlOpcoConsultant","#ddlSbuBuConsultant","#ddlSbuBuHiddenConsultant");},1)
    }
  }
  changeFilterListForSbuBu(){
    this.sbuListFiltered  = this.dropdowns.sbu.filter(r => r.operatingCompanyId == this.opcoId);
    this.buListFiltered = this.dropdowns.bu .filter(r => r.strategicBusinessUnitId == this.sbuId && r.operatingCompanyId == this.opcoId);
  }
  loadUserData(){
    this.apiService.getViewDetailsRole(this.userId, this.objectId, this.provisioningId).subscribe(res => {
      this.user = res.user;
      this.orgData = res.orgData; 
      this.projectData = res.projectData;
      this.dropdowns = res.dropdowns;
      this.selecteddataToPost = this.orgData;
      if(this.orgData.length > 0){
        this.opcoId = this.orgData[0].opcoId;
        this.sbuId = this.orgData[0].sbuId; 
        this.buId = this.orgData[0].buId;
        this.sbuListFiltered = this.dropdowns.sbu.filter(r => r.operatingCompanyId == res.orgData[0].opcoId);
        this.buListFiltered = this.dropdowns.bu .filter(r => r.strategicBusinessUnitId == res.orgData[0].sbuId && r.operatingCompanyId == res.orgData[0].opcoId);
      }
      this.onRoleChange(this.user.roleId);
    });
  }
  fillOpcoSbuBuDropdowns(ddlOpco, ddlSbuBu, ddlSbuBuHidden) {
    $(ddlOpco).empty();
    $(ddlSbuBu).empty();
    $(ddlSbuBuHidden).empty();
    const component = this;
    // to print opco dropdwon
    $(ddlOpco).DropDownTree({
      data: component.GetOpcos(),
      title: " ",
      closedArrow: '<i class="fa fa-caret-right" aria-hidden="true"></i>',
      openedArrow: '<i class="fa fa-caret-down" aria-hidden="true"></i>',
      maxHeight: 300,
      maxWidth: 98,
      multiSelect: true,
      clickHandler: function (element) {
        // // $("#ddlOpco").SetTitle($(element).find("a").first().text());
      },
      checkHandler: function (element,e, checked) {
          e.stopPropagation();
          let opcoValue = element.data("opcoid");
          let isOpcoChecked = e.currentTarget.classList.contains("fa-check-square-o");
          let getSelectedOPCO = $(ddlOpco).GetSelected();
          if (getSelectedOPCO.length == 1) {
              $(ddlOpco).SetTitle(getSelectedOPCO[0].text());
          } else if (getSelectedOPCO.length > 1) {
              $(ddlOpco).SetTitle("Multiple Selected");
          } else {
              $(ddlOpco).SetTitle(" ");
          }
          component.CheckedOpco(opcoValue, isOpcoChecked,ddlOpco,ddlSbuBu,ddlSbuBuHidden);
      }
    });
    //make unique orgData for opcoid
    let opcoIds = [...new Set(this.orgData.map((o)=> o.opcoId))];
    //to check default opco cehckboxes
    let sbuData: any[] = [];
    $.each(opcoIds, function (i, opcodata) {
        sbuData = sbuData.concat( component.GetSBU(opcodata));
        let selectedField = $('li').filter(function (i, element) {
            return $(element).data("opcoid") == opcodata;
        });
        selectedField.find('i').first().addClass("fa-check-square-o");
        selectedField.find('i').first().removeClass("fa-square-o");
    });
    let getSelectedOPCO = $(ddlOpco).GetSelected();
    if (getSelectedOPCO.length == 1) {
      $(ddlOpco).SetTitle(getSelectedOPCO[0].text());
    } else if (getSelectedOPCO.length > 1) {
        $(ddlOpco).SetTitle("Multiple Selected");
    } else {
        $(ddlOpco).SetTitle(" ");
    }
    //push all default sbu and bu and print sbu&bu dropdown
    let arrOpcoSbu1: any[] = [];
    arrOpcoSbu1.push({ title: "All", href: "#00", dataAttrs: [{ title: "sbuid", data: "All" }], data: sbuData });
    $(ddlSbuBu).DropDownTree({
      data: arrOpcoSbu1,
      title: "",
      closedArrow: '<i class="fa fa-caret-right" aria-hidden="true"></i>',
      openedArrow: '<i class="fa fa-caret-down" aria-hidden="true"></i>',
      multiSelect: true,
      selectChildren: true,
      clickHandler: function (element) {
        // // $("#ddlSbuBu").SetTitle($(element).find("a").first().text());
      },
      expandHandler: function (target, expanded) { },
      checkHandler: function (element, checked) {
          let dataToPost: any[] = [];
          let getSelectedSbu = $(ddlSbuBu+">ul>li>ul>li> .fa-check-square-o")
          if (getSelectedSbu.length == 1) {
              $(ddlSbuBu).SetTitle($($(ddlSbuBu+">ul>li>ul>li> .fa-check-square-o")).next().text());
          } else if (getSelectedSbu.length > 1) {
              $(ddlSbuBu).SetTitle("Multiple Selected");
          } else {
              $(ddlSbuBu).SetTitle(" ");
          }
          let selectedData = $(ddlSbuBu).GetSelected();
          for (let data of selectedData) {
              if (data.prevObject.length == 3) {
                  let item = data.prevObject;
                  dataToPost.push({ OpcoId: $(item[1]).data("opcoid"), SbuId: $(item[1]).data("sbuid"), BuId: $(item[0]).data("buid") });
              }
          }
          component.SetValues(dataToPost);
      }
    });
    // select all default sbu&bu
    $.each(this.orgData, function (i, sbudata) {
      let selectedField = $(ddlSbuBu+' li').filter(function (i,element) {
          return $(element).data("sbuid") == sbudata.sbuId;
      });
      selectedField.find('i').first().addClass("fa-check-square-o");
      selectedField.find('i').first().removeClass("fa-square-o");
    });
    let getSelectedSbu = $(ddlSbuBu+">ul>li>ul>li> .fa-check-square-o")
    if (getSelectedSbu.length == 1) {
      $(ddlSbuBu).SetTitle($($(ddlSbuBu+">ul>li>ul>li> .fa-check-square-o")).next().text());
    } else if (getSelectedSbu.length > 1) {
        $(ddlSbuBu).SetTitle("Multiple Selected");
    } else {
        $(ddlSbuBu).SetTitle(" ");
    }
    $.each(this.orgData, function (i, budata) {
      let selectedField = $('li').filter(function (i,element) {
          return $(element).data("buid") == budata.buId;
      });
      selectedField.find('i').first().addClass("fa-check-square-o");
      selectedField.find('i').first().removeClass("fa-square-o");
      // //component.selecteddataToPost.push({ OpcoId: selectedField.parent().parent().data("opcoid"), SbuId: selectedField.parent().parent().data("sbuid"), BuId: budata.buId });
    });
    if (this.dropdowns.opco.length == 0) {
      $(ddlSbuBu+" button").prop('disabled', true).addClass("disable-button");
    } else {
      $(ddlSbuBu+" button").prop('disabled', false).removeClass("disable-button");
      $(ddlSbuBu+" ul a i").first().addClass("fa-caret-down").removeClass("fa-caret-right");
      $(ddlOpco).addClass("dropdown dropdown-tree open");
    }
    $(ddlSbuBu).find("li ul").first().css("display", "block")
    $(ddlSbuBu).addClass("open");
    return;
  }
  GetOpcos() {
    const _arrOpco: any = [];
    $.each(this.dropdowns.opco, function (i, businessUnit) {
      _arrOpco.push({ title: businessUnit.name, href: "#" + (i + 1), dataAttrs: [{ title: "opcoid", data: businessUnit.id }] });
    });
    return _arrOpco;
  };
  CheckedOpco(selectedOpco, isOpcoChecked,ddlOpco,ddlSbuBu,ddlSbuBuHidden){
    const component = this;
    let _arrOpcoSbu: any = [];
    this.sbuListFiltered = this.dropdowns.sbu.filter(r => r.operatingCompanyId == selectedOpco);
    $.each(this.sbuListFiltered, (i, strategicBusinessUnit) => {
      _arrOpcoSbu.push({title: strategicBusinessUnit.name,href: "#0" + (i + 1),dataAttrs: [{ title: "opcoid", data: selectedOpco },{ title: "sbuid", data: strategicBusinessUnit.id }],data: component.GetBU(strategicBusinessUnit.id)});
    });
    let arrSbuBu = _arrOpcoSbu;
    if (!isOpcoChecked) {
        $.each(arrSbuBu, function (i, sbuData) {
            $(ddlSbuBu+" a").each(function (i, element) {
                if ($(element).text() == sbuData.title) {
                    let getSelectedSbu = $(ddlSbuBu+">ul>li>ul>li> .fa-check-square-o")
                    if (getSelectedSbu.length == 1) {
                        $(ddlSbuBu).SetTitle($($(ddlSbuBu+">ul>li>ul>li> .fa-check-square-o")).next().text());
                    } else if (getSelectedSbu.length > 1) {
                        $(ddlSbuBu).SetTitle("Multiple Selected");
                    } else {
                        $(ddlSbuBu).SetTitle("");
                    }
                    $(element).parent().remove();
                    if ($(ddlSbuBu).find("ul li").length == 1) {
                        $(ddlSbuBu).find("ul li").remove();
                        $(ddlSbuBu).SetTitle("");
                        $(ddlSbuBu+" button").prop('disabled', true).addClass("disable-button");
                    }
                    return;
                }
                return;
            });
        });
        return false;
    }
    let arrOpcoSbu1: any[] = [];
    if ($(ddlSbuBu).find("li").length == 0) {
        arrOpcoSbu1.push({ title: "All", href: "#", dataAttrs: [{ title: "sbuid", data: "All" }], data: arrSbuBu });
    }
    else {
        arrOpcoSbu1 = arrSbuBu;
    }
    if ($(ddlSbuBu).html.length > 0) {
        $(ddlSbuBuHidden).DropDownTree({
            data: arrOpcoSbu1,
            multiSelect: true,
            selectChildren: true,
        });
    }

    if ($(ddlSbuBu).find("ul").length == 1) {
        $(ddlSbuBu).find("ul").first().append($(ddlSbuBuHidden).find("ul").first().html());
        $(ddlSbuBuHidden).empty();
    }
    else if ($(ddlSbuBu).find("ul").length > 1) {
        $(ddlSbuBu).find("ul").eq(1).append($(ddlSbuBuHidden).find("ul").eq(0).html());
        $(ddlSbuBuHidden).empty();
    }

    if ($(ddlOpco+" i").hasClass("fa-check-square-o")) {
        $(ddlSbuBu+" button").prop('disabled', false).removeClass("disable-button");
        $(ddlSbuBu+" ul a i").first().addClass("fa-caret-down").removeClass("fa-caret-right");
    } else {
        $(ddlSbuBu+" button").prop('disabled', true).addClass("disable-button");
        $(ddlSbuBu).css("cursor", "not-allowed");
    }
    $(ddlSbuBu).find("li ul").first().css("display", "block")
    $(ddlSbuBu).addClass("open");
    $(ddlSbuBu).removeClass("loading");
    return;
  }
  GetBU(selectedSbu) {
    this.buListFiltered = this.dropdowns.bu.filter(r => r.strategicBusinessUnitId == selectedSbu);
    let _arrSbuBu: any[] = [];
    $.each(this.buListFiltered, function (i, businessUnit) {
        _arrSbuBu.push({ title: businessUnit.name, dataAttrs: [{ title: "buid", data: businessUnit.id }] });
    });
    return _arrSbuBu;
  }
  GetSBU(selectedOpco) {
    const component = this;
    let _arrOpcoSbu: any[] = [];
    this.sbuListFiltered = this.dropdowns.sbu.filter(r => r.operatingCompanyId == selectedOpco);
    $.each(this.sbuListFiltered, function (i, strategicBusinessUnit) {
        _arrOpcoSbu.push({ title: strategicBusinessUnit.name, href: "#" + (i + 1), dataAttrs: [{ title: "opcoid", data: selectedOpco }, { title: "sbuid", data: strategicBusinessUnit.id }], data: component.GetBU(strategicBusinessUnit.id) });
    });
    return _arrOpcoSbu;
  }
  SetValues(dataToPost: any) {
    this.selecteddataToPost = dataToPost;    
  }
  cancel(){
    this.router.navigateByUrl("/manage/useraccesses");
  }
  updateDetails() {
    if(this.currentRole == 12){
      let orgDataToPost: any[] = [];
      orgDataToPost.push({
        opcoId: this.opcoId,
        sbuId: this.sbuId,
        buId: this.buId
      });
      this.SetValues(orgDataToPost);
      if(this.opcoId == undefined || this.sbuId == undefined || this.buId == undefined){
        this.notifyService.showError("Segment, RU & BU field is required", "");
        return;
      }
    }
    if(this.selecteddataToPost.length == 0 && this.currentRole != 8){
      this.notifyService.showError("Segment, RU & BU field is required", "");
      return;
    }
    this.userUpdateData.Active = this.user.active;
    this.userUpdateData.FirstName = this.user.firstName;
    this.userUpdateData.LastName = this.user.lastName;
    this.userUpdateData.Email = this.user.emailId;
    this.userUpdateData.RoleId = this.user.roleId;
    this.userUpdateData.CreatedBy = this.provisioningId;
    this.userUpdateData.UserId = this.userId;
    this.userUpdateData.OrgData = this.selecteddataToPost;
    this.apiService.update("user", this.userUpdateData.UserId, this.userUpdateData).subscribe(res => {
      this.router.navigateByUrl("/manage/useraccesses");
    });
  }
  fillBuFilteredList(sbuId: any) {
    this.buListFiltered = this.dropdowns.bu .filter(r => r.strategicBusinessUnitId == sbuId && r.operatingCompanyId == this.opcoId);
    if (this.buListFiltered.length >= 1)
      this.buId = this.buListFiltered[0].id;
  }
  fillSbuFilteredList(opcoId: any) {
    this.sbuListFiltered  = this.dropdowns.sbu.filter(r => r.operatingCompanyId == opcoId);
    if (this.sbuListFiltered.length >= 1){
      this.sbuId = this.sbuListFiltered[0].id;
      this.fillBuFilteredList(this.sbuId);
    }
  }

}
