import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent, CommentDialogModel } from 'src/app/Modals/comment-dialog/comment-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/Modals/confirm-dialog/confirm-dialog.component';
import { BasisOfEvaluation, Leaderships, KeyAssumptions, Guidances } from 'src/app/Model/BasisOfEvaluation.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { BasisofEvaluationService } from '../../FEEDServices/basisof-evaluation.service';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { NotificationService } from 'src/app/Shared/notification.service';
import { Form } from 'src/app/Model/Form.model';
import { ActivatedRoute } from '@angular/router';
import { FeedNavService } from '../../FEEDServices/feed-nav.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-basis-of-evaluation',
  templateUrl: './basis-of-evaluation.component.html',
  styleUrls: ['./basis-of-evaluation.component.css']
})

export class BasisOfEvaluationComponent implements OnInit {
  basisOfEvaluation = new BasisOfEvaluation();
  guidance: Guidances[] = [];
  newGuidance = new Guidances();
  leadership: Leaderships[] = [];
  keyAssumptions: KeyAssumptions[] = [];
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  isLeadershipVisible: boolean = false;
  @Input() isOnHold: boolean = false;
  @Input() form: Form = new Form();
  formId: number = 0;
  projectId: number = 0;
  formCase: string | null = '';
  isOptOut = false;
  disableComment = false;
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;

  constructor(private basisofEvaluationService: BasisofEvaluationService,
    private go36ProjectService: Go36projectService,
    public dialog: MatDialog,
    private notifyService: NotificationService,
    private route: ActivatedRoute,
    private feednavService: FeedNavService,
    public utilityService: UtilityService) {
  }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formCase = (this.route.snapshot.paramMap.get("formCase"));
    if (this.formCase == 'Create') {
      this.formId = this.form.id; 
      // //Number(this.route.snapshot.paramMap.get("formId"));
      this.projectId = this.form.projectId; 
      // //Number(this.route.snapshot.paramMap.get("projectId"));
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          this.formId = this.form.id;
          // //params['formId'];
          this.projectId = this.form.projectId;
          // //params['projectId'];
        });
    }
    this.getBasisofEvaluationData();
  }
  
  getBasisofEvaluationData() {
    this.basisofEvaluationService.getData(this.formId).subscribe((res: BasisOfEvaluation) => {
      this.basisOfEvaluation = res;
      this.keyAssumptions = res.keyAssumptions;
      this.guidance = res.guidance;
      this.leadership = res.leadership;

      if (this.keyAssumptions.length == 0) {
        let supplement = (this.form.formType == "FEED Supplement" || this.form.formType == "FID Supplement")
          ? true : false;

        let oldFormId = 0;
        if (this.formCase == 'Create') {
          oldFormId = Number(this.route.snapshot.paramMap.get("formId"));
        }
        else {
          this.route.queryParams
            .subscribe(params => {
              oldFormId = this.form.id;
            });
        }

        this.basisofEvaluationService.createDefaultData(supplement ? oldFormId : this.formId, this.provId, this.projectId, supplement).subscribe((res: any) => {
          this.keyAssumptions = res.keyAssumptions;
          this.guidance = res.guidance;
          this.leadership = res.leadership;
          if (this.keyAssumptions.length > 0) {
            this.setLastRow(this.keyAssumptions);
          }
        })
      }
      this.setLastRow(this.keyAssumptions);
    })
  }

  setLastRow(keyAssumptions: KeyAssumptions[]) {
    if (keyAssumptions.length > 0) {
      keyAssumptions[keyAssumptions.length - 1].isLastRow = true;
    }
  }

  UpdateKeyAssumption(key: KeyAssumptions) {
    key.modifiedBy = this.provId;
    this.basisofEvaluationService.postData(key).subscribe(res => {
      // //let data = res;
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  UpdateGuidance(guidance: Guidances) {
    guidance.modifiedBy = this.provId;
    this.basisofEvaluationService.postGuidanceData(guidance).subscribe((res: Guidances[]) => {
      if (res) {
        this.guidance = res;
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    });
  }

  UpdateDateOfIssuance(guidance: Guidances) {
    guidance.modifiedBy = this.provId;
    const date = new Date(guidance.dateOfIssuance!);
    guidance.dateOfIssuance = this.notifyService.ConvertDateBeforeSave(date);

    this.basisofEvaluationService.postGuidanceData(guidance).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    guidance.dateOfIssuance = this.notifyService.ConvertDateAfterSave(date);
  }

  UpdateLeadership(leadership: Leaderships) {
    leadership.modifiedBy = this.provId;
    this.basisofEvaluationService.postLeadershipData(leadership).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  AddNewGuidance() {
    if (this.guidance != null && this.guidance != undefined && this.guidance.length > 0) {
    let guide = this.guidance.slice(-1)[0];
    const newGuidance = new Guidances();
    newGuidance.id = 0;
    newGuidance.active = true;
    newGuidance.createdBy = this.provId;
    newGuidance.createdOn = new Date();
    newGuidance.order = guide.order + 1;
    newGuidance.formId = guide.formId;
    newGuidance.guidanceName = '';
    newGuidance.name = '';
    this.guidance.splice(this.guidance.length + 1, 0, newGuidance);
    }
    else{
      const newGuidance = new Guidances();
      newGuidance.id = 0;
      newGuidance.active = true;
      newGuidance.createdBy = this.provId;
      newGuidance.createdOn = new Date();
      newGuidance.order =  1;
      newGuidance.formId = this.formId;
      newGuidance.guidanceName = '';
      newGuidance.name = '';
      this.guidance.splice(this.guidance.length + 1, 0, newGuidance);
    }
  }

  DeleteGuidance(guide: Guidances, index: any) {
    const message = 'Are you sure you want to delete this item?';
    const dialogData = new ConfirmDialogModel("Confirm item deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.guidance.splice(index, 1);
        guide.modifiedBy = this.provId;
        guide.active = false;
        this.basisofEvaluationService.postGuidanceData(guide).subscribe(res => {
          this.notifyService.showSuccess("Item deleted successfully...!", "");
        });
      }
    });
  }

  SaveCommentForGuidance(guidance: Guidances) {
    const dialogData = new CommentDialogModel("Update Comment", guidance.comment, this.isOnHold, this.form.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        guidance.modifiedBy = this.provId;
        guidance.comment = comment;
        this.basisofEvaluationService.postGuidanceData(guidance).subscribe(res => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  SaveCommentKeyAssumptions(key: KeyAssumptions) {
    if (this.isOnHold || key.naStatus) {
      this.disableComment = true;
    }
    else {
      this.disableComment = false;
    }
    const dialogData = new CommentDialogModel("Update Comment", key.comment, this.disableComment, this.form.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data; //isOnHold = this.isOnHold
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        key.modifiedBy = this.provId;
        key.comment = comment;
        this.basisofEvaluationService.postData(key).subscribe(res => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  ShowGuidanceModal() {
    const message = "Specify the guidance and date issued to enable future clarity around base assumptions utilized in the economics."
      + "<br/> <br/>" + "Common guidance is pre-populated (price/COGS, tax, FX); add new rows as needed";
    const dialogData = new InfoDialogModel("Guidance", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowKeyAssumptionsModal() {
    const message = "highlight any divergent NOJV co-owner views as applicable." + "<br/> <br/>" +
      "Key assumptions form the basis for the evaluation and/or the context within which the project makes sense. Consider the impact of being wrong about these assumptions, and how that would impact the scope and/or viability of the project. Is there additional upside potential?"
      + "<br/> <br/>" + "Include discussion of what you’ve learned in Phase 2 about key assumptions and where additional information may be developed in Phase 3."
      //+ "<br/> <br/>" + "For subsurface assumptions, address the non-normal distribution and most likely outcome vs. P10/50/90 and explain how this drives project economics."
      + "<br/> <br/>" + "Don’t describe how you developed or tracked assumptions, rather what the assumptions ARE for decision makers and future documentation."
      + "<br/> <br/>" + "Describe briefly the technical, commercial market and geopolitical assumptions which form the basis of the mid-price project case uploaded in Data Import. Note which are independent, and which are contingent on other assumptions. Note which of these are described as major risks in Section 6 (Risks). Include any relevant differences from the reference case."
      /*+ '<p></p>' + '<h4 class="text-center">Subsurface/Technology</h4>'
      + '<p></p><ul><li>' + 'What subsurface assumptions have been used?</li>'
      + '\n<li>' + 'Any assumptions around connectivity of the reservoir? Flowability? Permeability & porosity </li>'
      + '\n<li>' + 'Do shale/tight production playbooks assume the best wells or the average wells put on production recently?</li>'
      + '\n<li>' + 'Which shale/tight playbook has been used? Is this the first pad drilled with that playbook?</li></ul>'
      + '<p></p>' + '<h4 class="text-center">Production Efficiency/Uptime/Ramp-up profiles</h4>'
      + '<p></p><ul><li>' + 'What assumptions have been made on well reliability/availability/maintainability?</li>'
      + '\n<li>' + 'How many wells will be pre-drilled and available at first oil and how many remaining wells are included in the appropriation? </li>'
      + '\n<li>' + 'Briefly describe ramp-up assumptions, facility constraints and takeaway constraints </li></ul>'
      + '<p></p>' + '<h4 class="text-center">Project Execution</h4>'
      + '<p></p><ul><li>' + 'What assumptions have been made about project execution? Top quartile performance or average Chevron performance on productivity and schedule?</li></ul>'
      + '<p></p>' + '<h4 class="text-center">Existing facilities</h4>'
      + '<p></p><ul><li>' + 'What assumptions have been made on facility and pipeline reliability/ availability/ maintainability?</li></ul>'
      + '<p></p>' + '<h4 class="text-center">Geopolitical</h4>'
      + '<p></p><ul><li>' + 'Are there any assumptions included around governmental rulings, tax regulations, election results, public opinion that are contained in the reference case?</li></ul>'
      + '<p></p>' + '<h4 class="text-center">Commercial</h4>'
      + '<p></p><ul><li>' + 'List any assumptions on commercial agreements or outcomes of negotiations</li></ul>'
      + '<p></p>' + '<h4 class="text-center">Market/Competitiveness</h4>'
      + '<p></p><ul><li>' + 'How did you use the price guidance? What benchmarks did you use and how did you adjust them?</li>'
      + '\n<li>' + 'Did you make any assumptions about the market that differed from the price & cost guidance or were not covered by it?</li>'
      + '\n<li>' + 'What product quality adjustment and marketing/transportation assumptions have been made in arriving at a product wellhead price?</li></ul>'
      + '<p></p>' + '<h4 class="text-center">Regulatory</h4>'
      + '<p></p><ul><li>' + 'What assumptions did you make on timing and ease of obtaining key operating, environmental and other regulatory permits</li></ul>';*/

    const dialogData = new InfoDialogModel("Key Assumptions in P50 Case", message);

    this.dialog.open(InfoDialogComponent, {
      width: "800px",
      data: dialogData
    });
  }

  ShowKeyAssumptionsModalEach(key: KeyAssumptions) {
    let message = '<p></p><ul><li>' + 'What assumptions have been made about project execution? Top quartile performance or average Chevron performance on productivity and schedule?</li></ul>'
    + '<p></p>'
    switch(key.assumptionName) {
      case 'Subsurface/Technology' : message = '<p></p><ul><li>' + 'What subsurface assumptions have been used?</li>'
      + '\n<li>' + 'Any assumptions around connectivity of the reservoir? Flowability? Permeability & porosity </li>'
      + '\n<li>' + 'Do shale/tight production playbooks assume the best wells or the average wells put on production recently?</li>'
      + '\n<li>' + 'Which shale/tight playbook has been used? Is this the first pad drilled with that playbook?</li></ul>'
      + '<p></p>';
      break;

      case "Production Efficiency/Uptime/Rampup profiles:" : message = '<p></p><ul><li>' + 'What assumptions have been made on well reliability/availability/maintainability?</li>'
      + '\n<li>' + 'How many wells will be pre-drilled and available at first oil and how many remaining wells are included in the appropriation? </li>'
      + '\n<li>' + 'Briefly describe ramp-up assumptions, facility constraints and takeaway constraints </li></ul>'
      + '<p></p>';
      break;

      case "Existing facilities" : message = '<p></p><ul><li>' + 'What assumptions have been made on facility and pipeline reliability/ availability/ maintainability?</li></ul>'
      break;

      case "Geopolitical" : message = '<p></p><ul><li>' + 'Are there any assumptions included around governmental rulings, tax regulations, election results, public opinion that are contained in the reference case?</li></ul>'
      break;

      case "Commercial" : message = '<p></p><ul><li>' + 'List any assumptions on commercial agreements or outcomes of negotiations</li></ul>'
      break;

      case "Market / Competitiveness" : message = '<p></p><ul><li>' + 'How did you use the price guidance? What benchmarks did you use and how did you adjust them?</li>'
      + '\n<li>' + 'Did you make any assumptions about the market that differed from the price & cost guidance or were not covered by it?</li>'
      + '\n<li>' + 'What product quality adjustment and marketing/transportation assumptions have been made in arriving at a product wellhead price?</li></ul>'
      + '<p></p>'
      break;

      case "Regulatory" : message = '<p></p><ul><li>' + 'What assumptions did you make on timing and ease of obtaining key operating, environmental and other regulatory permits</li></ul>';
      break;
    }
    const dialogData = new InfoDialogModel(key.assumptionName, message);
    this.dialog.open(InfoDialogComponent, {
      width: "800px",
      data: dialogData
    });
  }

  ShowLeadershipModal() {
    const message = 'Quick reference of project leadership and roles at different stages of the project';
    const dialogData = new InfoDialogModel("Leadership", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  NAEventCheck(event: MatSlideToggleChange, key: KeyAssumptions) {
    key.modifiedBy = this.provId;
    key.naStatus = event.checked;
    this.basisofEvaluationService.postData(key).subscribe(res => {
    });
  }

  onChange(value: MatSlideToggleChange) {
    this.isOptOut = value.checked;
    this.form.id = this.formId;
    this.form.modifiedBy = this.provId;
    this.form.optOutBasisOfEvaluation = this.isOptOut;
    this.form.projectId = this.projectId;

    this.feednavService.postOptOutBasisOfEvaluation(this.form).subscribe(res => {
    });
  }

  ShowOptOutModal() {
    const message = "If a discussion on alternative selection was included in a previous AR and there have been no significant changes, the project team can opt to exclude this section. When Opt Out is selected, this tab will be disabled."
    const dialogData = new InfoDialogModel("Opt Out", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }
}