export class Milestone {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn: Date=new Date();
    modifiedOn: Date=new Date();
    order: number = 0;
    formId: number = 0;
    milestoneName?: string = '';
    date?: Date;
    currentDate?: Date;
    partnerView?: Date=new Date();
    milestoneType?: string = '';
    overviewId: number = 0;
    comment: string = '';
    isEditable: boolean = false;
    originalFeedArDate?: Date;
    feedItem: boolean = false;
    originalSuppArDate?: Date;
}