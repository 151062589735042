<div class="row sub-section">
    <div class="col-xs-12 form-group">
        <strong *ngIf="type != constIncremental && type != constLL">{{type}} Case (only required for Brownfield)</strong>
        <strong *ngIf="type == constIncremental">{{type}} Case (required for Brownfield and Greenfield)</strong>
        <strong *ngIf="type == constLL">{{type}}</strong>
    </div>
    <div class="col-xs-12">
        <div class="row form-group">
            <div class="col-sm-3 col-xs-12">
                <label class="control-label form-label">Project Name</label>
            </div>
            <div class="col-sm-9 col-xs-12">
                <input class="form-control" [(ngModel)]="viewModel.ProjectName" disabled/>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-sm-3 col-xs-12">
                <label class="control-label form-label">Date:</label>
            </div>
            <div class="col-sm-3 col-xs-12">
                <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="viewModel.Date" (dateChange)="SaveSSBODCommonDetails()"
                 [matDatepicker]="picker" placeholder="Choose a date" (click)="picker.open();utilityService.removeCDKAnouncer()">
                <mat-datepicker #picker></mat-datepicker>
            </div>
            <div class="col-sm-3 col-xs-12">
                <label class="control-label form-label">Existing/Active Wells:</label>
            </div>
            <div class="col-sm-3 col-xs-12">
                <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" (change)="SaveSSBODCommonDetails()" [(ngModel)]="viewModel.ActiveWells"/>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-sm-3 col-xs-12">
                <label class="control-label form-label">CPDEP Phase: </label>
            </div>
            <div class="col-sm-3 col-xs-12">
                <select class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="viewModel.Phase" (change)="SaveSSBODCommonDetails()">
                    <option *ngFor="let CPDEP of viewModel.ProjectDevelopmentStages" [value]="CPDEP.Name">{{CPDEP.Name}}</option>
                </select>
            </div>
            <div class="col-sm-3 col-xs-12">
                <label class="control-label form-label">Penetrations:</label>
            </div>
            <div class="col-sm-3 col-xs-12">
                <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" (change)="SaveSSBODCommonDetails()" [(ngModel)]="viewModel.Penetrations"/>
            </div>
        </div>
        <div class="row form-group" *ngIf="(formType!='AR_LOOKBACK_PART5')">
            <div class="col-sm-3 col-xs-12">
                <label class="control-label form-label">Events:</label>
            </div>
            <div class="col-sm-3 col-xs-12">
                <select class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="viewModel.Event" (change)="SaveSSBODCommonDetails()">
                    <option *ngFor="let event of viewModel.Events" [value]="event.Name">{{event.Name}}</option>
                </select>
            </div>
            <div class="col-sm-3 col-xs-12">
                <label class="control-label form-label">Country:</label>
            </div>
            <div class="col-sm-3 col-xs-12">
                <select class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="viewModel.CountryId" (change)="SaveSSBODCommonDetails()">
                    <option *ngFor="let country of viewModel.Countries" [value]="country.Id">{{country.Name}}</option>
                </select>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-sm-3 col-xs-12" *ngIf="(formType=='AR_LOOKBACK_PART5')">
                <label class="control-label form-label">Country:</label>
            </div>
            <div class="col-sm-3 col-xs-12"  *ngIf="(formType=='AR_LOOKBACK_PART5')">
                <select class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="viewModel.CountryId" (change)="SaveSSBODCommonDetails()">
                    <option *ngFor="let country of viewModel.Countries" [value]="country.Id">{{country.Name}}</option>
                </select>
            </div>
            <div class="col-sm-3 col-xs-12">
                <label class="control-label form-label">Segment: </label>
            </div>
            <div class="col-sm-3 col-xs-12">
                <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="viewModel.OperatingCompany" disabled/>
            </div>
            <div class="col-sm-3 col-xs-12" *ngIf="(formType!='AR_LOOKBACK_PART5')">
                <label class="control-label form-label">RU: </label>
            </div>
            <div class="col-sm-3 col-xs-12" *ngIf="(formType!='AR_LOOKBACK_PART5')">
                <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="viewModel.StrategicBusinessUnit" disabled/>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-sm-3 col-xs-12" *ngIf="(formType=='AR_LOOKBACK_PART5')">
                <label class="control-label form-label">SBU: </label>
            </div>
            <div class="col-sm-3 col-xs-12" *ngIf="(formType=='AR_LOOKBACK_PART5')">
                <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="viewModel.StrategicBusinessUnit" disabled/>
            </div>
            <div class="col-sm-3 col-xs-12">
                <label class="control-label form-label">BU: </label>
            </div>
            <div class="col-sm-3 col-xs-12">
                <select class="form-control"  [(ngModel)]="viewModel.BusinessUnitId" disabled>
                    <option *ngFor="let bu of viewModel.BusinessUnits" [value]="bu.Id">{{bu.Name}}</option>
                </select>
            </div>
        </div>
    </div>
</div>