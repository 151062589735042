import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { AppsettingsService } from 'src/app/Shared/appsettings.service';
import { Observable } from 'rxjs';
import { CalAngularService } from '@cvx/cal-angular';

@Injectable({
  providedIn: 'root'
})
export class FormReviewerService {

  constructor(private http: HttpClient, private appsettings: AppsettingsService, private authService: CalAngularService,) { }
  SearchUserUrl = "api/FormReviewer/GetUserSearchByName?name=";
  PostuserAssetUrl = 'api/FormReviewer/AddReviewer';
  GetReviewerUrl = "api/FormReviewer/GetFormReviewers";
  GetUrl = 'api/BusinessCase/copyBusinessCaseData';
  // //CopyUrl = 'api/BusinessCase/copyBusinessCaseData';
  postReviewerUrl = 'api/FormReviewer/SaveReviewerData';
  processFormActionURL = 'api/FormReviewer/PerformFormReviewAction';
  DeleteReviewerUrl = 'api/FormReviewer/DeleteReviewer';
  getLogDataUrl = "api/FormReviewer/GetFormActionLogs";
  getSignOffDataUrl = "api/FormReviewer/GetSignOffData";
  UpdateSignOffUrl = 'api/FormReviewer/SaveSignOffData';
  UpdateLookBackSignOffUrl = 'api/FormReviewer/SaveLookBackSignOffData';
  getLookBackFormSignOffDataUrl = "api/Part1/GetLookBackFormSignoffData";
  postDeleteReviewerUrl = 'api/FormReviewer/DeleteReviewerData';
  getLookBackFormSignOffEASupplementDataUrl = "api/SupplementPart1/GetLookBackFormSignoffData";
  getEALogDataUrl = "api/SupplementPart1/GetFormActionLogs";
  getPartFourSignOffDataUrl = "api/LookbackPart4/GetPartSignOffData";
  getPartFiveSignOffDataUrl = "api/LookbackPart5/GetPartSignOffData";

  GetUserBySearch(name: string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.SearchUserUrl + name);
  }

  getData(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetUrl + formId);
  }

  getReviewerData(originalId: number, projectId: number, versionId: number, objectId: string, provId: string, formType: string): Observable<any[]> {
    const params = new HttpParams()
      .append('projectId', projectId)
      .append('originalId', originalId)
      .append('formVersion', versionId)
      .append('objectId', objectId)
      .append('provId', provId)
      .append('formType', formType)
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetReviewerUrl, {
      params: params
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  getLogData(originalId: number, projectId: number, versionId: number, formType: string): Observable<any[]> {
    const params = new HttpParams()
      .append('projectId', projectId)
      .append('originalId', originalId)
      .append('versionId', versionId)
      .append('formType', formType)
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getLogDataUrl, {
      params: params
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  getSignOffData(formId: number, versionId: number): Observable<any[]> {
    const params = new HttpParams()
      .append('formId', formId)
      .append('versionId', versionId)
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getSignOffDataUrl, {
      params: params
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  postSignOffData(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateSignOffUrl, formData);
  }

  postLookBackSignOffData(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateLookBackSignOffUrl, formData);
  }

  PostUseraccess(projectId: number, formId: number, versionId: number, objectId: string, provId: string, selectedUserObjectId: string, selectedUserProvId: string, formType: string) {
    const params = new HttpParams()
      .append('projectId', projectId)
      .append('formId', formId)
      .append('versionId', versionId)
      .append('objectId', objectId)
      .append('provId', provId)
      .append('selectedUserObjectId', selectedUserObjectId)
      .append('selectedUserProvId', selectedUserProvId)
      .append('formType', formType);
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.PostuserAssetUrl, '', {
      params: params
    });
  }

  // // postReviewerData(comments: string, action: string, id: number) {
  // //   const params = new HttpParams()
  // //     .append('comments', comments)
  // //     .append('action', action)
  // //     .append('id', id)
  // //   return this.http.post<any>(this.appsettings.apiBaseUrl + this.postReviewerUrl, '', {
  // //     params: params
  // //   });
  // // }

  postDeleteReviewerData(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.postDeleteReviewerUrl, formData);
  }

  // // deleteReviewer(comments: string, action: string, id: number) {
  // //   const params = new HttpParams()
  // //     .append('comments', comments)
  // //     .append('action', action)
  // //     .append('id', id)
  // //   return this.http.post<any>(this.appsettings.apiBaseUrl + this.SaveCommentUrl, '', {
  // //     params: params
  // //   });
  // // }

  processFormAction(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.processFormActionURL, formData);
  }

  approvalForm(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.postReviewerUrl, formData);
  }

  getLookBackSignOffData(formId: number, versionId: number): Observable<any[]> {
    const params = new HttpParams()
      .append('formId', formId)
      .append('versionId', versionId)
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getLookBackFormSignOffDataUrl, {
      params: params
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  getLookBackSignOffEASupplementData(formId: number, versionId: number, formType: string): Observable<any[]> {
    const params = new HttpParams()
      .append('formId', formId)
      .append('versionId', versionId)
      .append('formType', formType)
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getLookBackFormSignOffEASupplementDataUrl, {
      params: params
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  getEALogData(originalId: number, projectId: number, versionId: number, formType: string): Observable<any[]> {
    const params = new HttpParams()
      .append('projectId', projectId)
      .append('originalId', originalId)
      .append('versionId', versionId)
      .append('formType', formType)
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getEALogDataUrl, {
      params: params
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  getPartFourSignOffData(formId: number, versionId: number): Observable<any[]> {
    const params = new HttpParams()
      .append('formId', formId)
      .append('versionId', versionId)
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getPartFourSignOffDataUrl, {
      params: params
    }).pipe(map((res: any) => {
      return res;
    }))
  }

  getPartFiveSignOffData(formId: number, versionId: number): Observable<any[]> {
    const params = new HttpParams()
      .append('formId', formId)
      .append('versionId', versionId)
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getPartFiveSignOffDataUrl, {
      params: params
    }).pipe(map((res: any) => {
      return res;
    }))
  }
}