export class ManagementOfChangeModel {
    Id: number=0;
    Active:boolean = false;
    CreatedBy:string='';
    ModifiedBy:string='';
    CreatedOn:Date= new Date;
    ModifiedOn:Date= new Date;
    Date:Date= new Date;
    SubsurfaceBasisOfDesignId: number=0;
    ProjectTeamMoc:string='';
    Initiator:string='';
    Affiliation:string='';
    Title:string='';
    SummaryComments:string='';
    EurManagementOfChangeRequired:string='';
    EurFunctionalAuthorityName:string='';
    EurAffiliation:string='';
    EurTitle:string='';
    EurDate:Date= new Date;
    EurAuthoritySignOff:string='';
    EurAuthorityComments:string='';
    DccManagementOfChangeRequired:string='';
    DccFunctionalAuthorityName:string='';
    DccAffiliation:string='';
    DccTitle:string='';
    DccDate:Date= new Date;
    DccAuthoritySignOff:string='';
    DccAuthorityComments:string='';
    EurDifferenceSummaryComments:string='';
    DccDifferenceSummaryComments:string='';
}
export interface ManagementOfChangeModel
{
    Id: number;
    Active:boolean;
    CreatedBy:string;
    ModifiedBy:string;
    CreatedOn:Date;
    ModifiedOn:Date;
    Date:Date;
    SubsurfaceBasisOfDesignId: number;
    ProjectTeamMoc:string;
    Initiator:string;
    Affiliation:string;
    Title:string;
    SummaryComments:string
    EurManagementOfChangeRequired:string;
    EurFunctionalAuthorityName:string;
    EurAffiliation:string;
    EurTitle:string;
    EurDate:Date;
    EurAuthoritySignOff:string;
    EurAuthorityComments:string;
    DccManagementOfChangeRequired:string;
    DccFunctionalAuthorityName:string;
    DccAffiliation:string;
    DccTitle:string;
    DccDate:Date;
    DccAuthoritySignOff:string;
    DccAuthorityComments:string;
    EurDifferenceSummaryComments:string;
    DccDifferenceSummaryComments:string;
}