export class ExecutionMilestone {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn: Date=new Date();
    modifiedOn: Date=new Date();
    order: number = 0;
    formId: number = 0;
    projectMilestoneName?: string = '';
    projectMilestoneDate?: Date;
    comment: string = '';
    originalProjectMilestoneDate?: Date;
}