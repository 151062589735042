import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UtilityService } from 'src/app/Shared/utility.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private utilityService: UtilityService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.utilityService.pendingRequests.push(1);
     this.utilityService.showLoader();

     return next.handle(request).pipe(
           finalize(() => {
            
            if(this.utilityService.pendingRequests.length > 0){
              this.utilityService.pendingRequests.pop();
            }

            if(this.utilityService.pendingRequests.length == 0){
              this.utilityService.hideLoader();
            }
           }),
     );
  }
}
