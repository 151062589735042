import { Component, Input, OnInit } from '@angular/core';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { ExecutionService } from '../../../FEEDServices/execution.service';
import { ExecutionModel } from 'src/app/Model/Execution.model';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { NotificationService } from 'src/app/Shared/notification.service';
import { ActivatedRoute } from '@angular/router';
import { Milestone } from 'src/app/Model/Milestone.model';
import { KeyMilestone } from 'src/app/Model/KeyMilestone.model';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/Modals/confirm-dialog/confirm-dialog.component';
import { CommentDialogComponent, CommentDialogModel } from 'src/app/Modals/comment-dialog/comment-dialog.component';
import { OverviewService } from '../../../FEEDServices/overview.service';
import { Form } from 'src/app/Model/Form.model';
import { UtilityService } from 'src/app/Shared/utility.service';
import { ExecutionMilestone } from 'src/app/Model/ExecutionMilestone.model';

@Component({
  selector: 'app-execution-old',
  templateUrl: './execution-old.component.html',
  styleUrls: ['./execution-old.component.css']
})
export class ExecutionOldComponent implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [
      // 'fontfamily','fontsize','fontColor','fontBackgroundColor', '|',
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',

    ],
    autosave: {
      save(editor) {
      }
    }

  }
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  ckeditorText: string = '';
  execution = new ExecutionModel();
  formCase: string | null = '';
  formId: number = 0;
  @Input() isOnHold: boolean = false;
  @Input() form: Form = new Form();
  phasemileStone: Milestone[] = [];
  keymileStone: KeyMilestone[] = [];
  executionMileStone: ExecutionMilestone[] = [];
  projectId: number = 0;
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;
  constructor(public dialog: MatDialog, private go36ProjectService: Go36projectService,
    private executionService: ExecutionService, private notifyService: NotificationService,
    private route: ActivatedRoute, private overviewService: OverviewService, public utilityService: UtilityService) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formCase = (this.route.snapshot.paramMap.get("formCase"));
    if (this.formCase == 'Create') {
      this.formId = this.form.id;
      this.projectId = this.form.projectId;
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          this.formId = this.form.id;
          this.projectId = this.form.projectId;
        });
    }
    this.getExecutionData();
  }
  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }
  onReady(editor, name) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.UpdateCkeditorChanges(editor.getData(), name);
        }
      }
    });
  }
  removeCDKAnouncer() {
    setTimeout(() => {
      let element = document.querySelector(".cdk-live-announcer-element");
      element?.setAttribute("style", "display:none!important;");
    }, 0)
  }
  ShowGuidanceModal() {
    const message = "Attach the Project Execution Plan for Phase 3 through the document upload function. Use this space to summarize or highlight key aspects only. Key execution risks should be extracted into risk table 6.1. Highlight any divergent NOJV views as applicable. For supplement, describe any changes in Project Execution Plan."
    const dialogData = new InfoDialogModel("Project Execution Plan Including Key Contracting Strategy, Staffing, and Complexity", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }
  ShowCostEstimateOverviewModal() {
    const message = "  include cost and schedule sensitivity cumulative probability chart images here. Upload the CSRA and ESAR reports and summarize key findings here. Include any divergent NOJV views as applicable."
    const dialogData = new InfoDialogModel("Cost Estimate Overview and Basis", message);
    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }
  ShowScheduleEstimateModal() {
    const message = "Key Project Milestones are imported here from section 1.3 but not printed. Include any additional project progression milestones you are measuring. These are tracked from FEED to FID and potential Supplements. Highlight any unusual or critical items. Include NOJV views as applicable. For supplement, include waterfall of schedule variance for update vs. AR.";
    const dialogData = new InfoDialogModel("Schedule Estimate and Basis", message);
    this.dialog.open(InfoDialogComponent, {
      width: "800px",
      data: dialogData
    });
  }
  ShowProjectScheduleModal() {
    const message = "Highlight any unusual or critical items. Include NOJV views as applicable. For supplement, include waterfall of schedule variance for update vs. AR."
    const dialogData = new InfoDialogModel("Project Schedule or Gantt Chart", message);
    this.dialog.open(InfoDialogComponent, {
      width: "800px",
      data: dialogData
    });
  }
  getExecutionData() {
    this.executionService.getData(this.formId).subscribe((res: ExecutionModel) => {
      this.execution = res;
      this.phasemileStone = res.phaseGateMilestones.filter(x => x.milestoneType == 'Company');
      this.executionMileStone = res.executionMilestones;
      if (this.execution.id == 0) {
        let supplement = (this.form.formType == "FEED Supplement" || this.form.formType == "FID Supplement")
          ? true : false;
        let oldFormId = 0;
        if (this.formCase == 'Create') {
          oldFormId = Number(this.route.snapshot.paramMap.get("formId"));
        }
        else {
          this.route.queryParams
            .subscribe(params => {
              oldFormId = this.form.id;
            });
        }
        if (supplement) {
          this.executionService.copyData(supplement ? oldFormId : this.formId, this.provId, this.projectId, supplement).subscribe((res: ExecutionModel) => {
            this.execution = res;
            this.phasemileStone = res.phaseGateMilestones.filter(x => x.milestoneType == 'Company');
          });
        }
      }
    })
  }
  UpdateCkeditorChanges(event: string, textName: string) {
    this.ckeditorText = event;
    this.execution.createdBy = this.provId;
    this.execution.modifiedBy = this.provId;
    this.execution.formId = this.formId;
    if (textName == 'ProjectExecutionPlan') {
      this.execution.projectExecutionPlan = this.ckeditorText;
    } else if (textName == 'CostEstimate') {
      this.execution.costEstimate = this.ckeditorText;
    } else if (textName == 'ProjectSchedule') {
      this.execution.projectSchedule = this.ckeditorText;
    } else if (textName == 'ScheduleWaterFallImage') {
      this.execution.scheduleWaterfallImage = this.ckeditorText;
    } else if (textName == 'CapitalAndExpendituresWaterfallImage') {
      this.execution.capitalAndExpendituresWaterfallImage = this.ckeditorText;
    }
    this.UpdateExecutionData(this.execution);
  }

  UpdateExecutionData(execution: ExecutionModel) {
    this.executionService.postData(execution).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

   UpdateExecutionMileStone(executionMilestone: ExecutionMilestone) {
    executionMilestone.modifiedBy = this.provId;
    const date = new Date(executionMilestone.projectMilestoneDate!);
    const originaldate = new Date(executionMilestone.originalProjectMilestoneDate!);
    if (executionMilestone.projectMilestoneDate != null) {
      executionMilestone.projectMilestoneDate = this.notifyService.ConvertDateBeforeSave(date);
    }
    if (executionMilestone.originalProjectMilestoneDate != null) {
      executionMilestone.originalProjectMilestoneDate = this.notifyService.ConvertDateBeforeSave(originaldate);
    }
    this.executionService.postExecutionMileStoneData(executionMilestone).subscribe(res => {
      this.executionMileStone = res as ExecutionMilestone[];
      this.notifyService.showSuccess("Data Load/Save successfully!", "Status");
    });
    if (executionMilestone.projectMilestoneDate != null) {
      executionMilestone.projectMilestoneDate = this.notifyService.ConvertDateAfterSave(date);
    }
    if (executionMilestone.originalProjectMilestoneDate != null) {
      executionMilestone.originalProjectMilestoneDate = this.notifyService.ConvertDateAfterSave(originaldate);
    }
 }

 addNewExecutionMilestone(): void {
      if (this.executionMileStone != null && this.executionMileStone != undefined && this.executionMileStone.length > 0) {
        let executionmileStone = this.executionMileStone.slice(-1)[0];
        const updateExecutionMilestone = new ExecutionMilestone();
        updateExecutionMilestone.id = 0;
        updateExecutionMilestone.active = true;
        updateExecutionMilestone.createdBy = this.provId;
        updateExecutionMilestone.createdOn = new Date();
        updateExecutionMilestone.order = executionmileStone.order + 1;
        updateExecutionMilestone.formId = executionmileStone.formId;
        updateExecutionMilestone.projectMilestoneName = '';
        this.executionMileStone.splice(this.executionMileStone.length + 1, 0, updateExecutionMilestone);
      }
      else {
        const newExecutionMilestone = new ExecutionMilestone();
        newExecutionMilestone.id = 0;
        newExecutionMilestone.active = true;
        newExecutionMilestone.createdBy = this.provId;
        newExecutionMilestone.createdOn = new Date();
        newExecutionMilestone.order = 1;
        newExecutionMilestone.formId = this.formId;
        newExecutionMilestone.projectMilestoneName = '';
        this.executionMileStone.splice(this.executionMileStone.length + 1, 0, newExecutionMilestone);
      }
 }
  SaveCommentForPhaseMilestone(milestone: Milestone) {
    const dialogData = new CommentDialogModel("Update Comment", milestone.comment, this.isOnHold, this.form.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });
    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        milestone.modifiedBy = this.provId;
        milestone.comment = comment;
        this.overviewService.postMileStoneData(milestone).subscribe(res => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }
   SaveCommentForExecutionMilestone(executionMilestone: ExecutionMilestone) {
    const dialogData = new CommentDialogModel("Update Comment", executionMilestone.comment, this.isOnHold, this.form.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });
    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        executionMilestone.modifiedBy = this.provId;
        executionMilestone.comment = comment;
        this.executionService.postExecutionMileStoneData(executionMilestone).subscribe(res => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
   }
}
