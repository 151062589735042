<div class="box-container">
    <div class="row">
        <div class="col">
            <h3>Part 5 Lookback</h3>
            <a class="no-underline" [routerLink]="['/go36project/viewform']"
                [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked }"
                [queryParams]="{projectId: projectId}">
                <h5 style="padding: 0; font-size: 14px;">
                    {{projectName}}</h5>
            </a>

            <h5 class="text-danger margin-0 padding-0"></h5>
            <h5 class="text-danger margin-0 padding-0" style="font-size: 14px !important;" *ngIf="Part5Header.isOnHold">
                Form is on Hold. No editing can be performed</h5>
        </div>
    </div>

    <div class="row" *ngIf="!Part5Header.isReadOnly"
        [ngClass]="{'disabledNoOfCasesDiv': !this.part5LookBackAccessList?.data?.canHold}">
        <span>
            <mat-slide-toggle (change)="onChange($event, Part5Header)" [checked]="Part5Header.isOnHold">
                <b class="app-font">On Hold</b>
            </mat-slide-toggle>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowOnHoldModal()"></span>
        </span>
    </div>

    <div class="row">
        <div class="tabbed tabbed-content-control tabbed-side-nav clearfix">
            <mat-sidenav-container>
                <mat-sidenav mode="side" class="sidenav" opened *ngIf="!Part5Header.isOldVersionOfForms">
                    <mat-nav-list class=" nav-tabs font-gotham">
                        <a mat-list-item (click)="showSection(1)" style="color:white">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection1}">Section 1-2</strong></a>
                        <a mat-list-item (click)="showSection(3)" style="color:white">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection3}">Section 3</strong></a>

                        <a mat-list-item (click)="showSection(4)" style="color:white">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection4}">Section 4</strong></a>
                        <!-- <a mat-list-item (click)="showSection(5)" class="menu-item-color">
                            <strong class="sub-menu-font"  [ngClass]="{active:showSection5}">Section 5</strong></a> -->
                        <!-- <a mat-list-item (click)="showSection(6)" class="menu-item-color">
                            <strong style="font-size: 0.88em;"  [ngClass]="{active:showSection6}">Section 6</strong></a> -->

                        <a mat-list-item (click)="showSection(8)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection8}">Section 5</strong></a>
                        <a mat-list-item (click)="showSection(9)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection9}">Section 6</strong></a>
                        <a mat-list-item (click)="showSection(10)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection10}">Section 7</strong></a>
                        <a mat-list-item (click)="showSection(11)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection11}">Section 8</strong></a>
                        <a mat-list-item (click)="showSection(12)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection12}">Section 9</strong></a>
                        <a mat-list-item (click)="showSection(13)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection13}">SSBOD</strong></a>
                        <a mat-list-item (click)="showSection(7)" class="menu-item-color">
                            <strong class="sub-menu-font" style="font-size: 14px; text-align: left; padding-top: 15px;"
                                [ngClass]="{active:showSection7}">Project Documentation</strong></a>
                        <br />
                        <a mat-list-item (click)="showSection(14)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection14}">Form Reviewer</strong></a>
                    </mat-nav-list>
                </mat-sidenav>
                <mat-sidenav mode="side" class="sidenav" opened *ngIf="Part5Header.isOldVersionOfForms">
                    <mat-nav-list class=" nav-tabs font-gotham">
                        <a mat-list-item (click)="showSection(1)" style="color:white">
                            <strong class="sub-menu-font" *ngIf="this.features?.hasLookbacksLLFeature" [ngClass]="{active:showSection1}">Section 1-2</strong>
                            <strong class="sub-menu-font" *ngIf="!this.features?.hasLookbacksLLFeature" [ngClass]="{active:showSection1}">Section 1-2-3</strong>
                        </a>
                        <a mat-list-item (click)="showSection(3)" style="color:white" *ngIf="this.features?.hasLookbacksLLFeature">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection3}">Section 3</strong></a>
                        <a mat-list-item (click)="showSection(4)" style="color:white">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection4}">Section 4</strong></a>
                        <a mat-list-item (click)="showSection(5)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection5}">Section 5</strong>
                        </a>
                        <a mat-list-item (click)="showSection(6)" class="menu-item-color">
                            <strong style="font-size: 0.88em;" [ngClass]="{active:showSection6}">Section 6</strong>
                        </a>
                        <a mat-list-item (click)="showSection(7)" class="menu-item-color">
                            <strong class="sub-menu-font" style="font-size: 14px; text-align: left;"
                                [ngClass]="{active:showSection7}">Section 7</strong>
                        </a>
                        <a mat-list-item (click)="showSection(8)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection8}">Section 8</strong>
                        </a>
                        <a mat-list-item (click)="showSection(9)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection9}">Section 9</strong>
                        </a>
                        <a mat-list-item (click)="showSection(10)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection10}">Section 10</strong>
                        </a>
                        <a mat-list-item (click)="showSection(11)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection11}">Section 11</strong>
                        </a>
                        <a mat-list-item (click)="showSection(12)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection12}">Section 12</strong>
                        </a>
                        <a mat-list-item (click)="showSection(13)" class="menu-item-color">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection13}">SSBOD</strong>
                        </a>
                        <a mat-list-item (click)="showSection(14)" class="menu-item-color" *ngIf="this.features?.hasWorkFlowProcessFeature">
                            <strong class="sub-menu-font" [ngClass]="{active:showSection14}">Form Reviewer</strong>
                        </a>
                    </mat-nav-list>
                </mat-sidenav>
                <div class="row" style="margin-left:2rem">
                    <!-- --------------------Section 1-2-------------------------------->
                    <div *ngIf="showSection1" class="tab-content tabs nav nav-tabs font-gotham">
                        <div class="content-container">
                            <div class="row">
                                <div class="col-xs-12">
                                    <hr />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>1. Business Evaluation Lookback</strong>

                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1a. Segment: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.opcoName" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1b. RU: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.sbuName" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1c. BU: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.buName" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1d. Asset Class: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.assetName" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1e. Project Location:</label>
                                    <input type="text" class="form-control"
                                        (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                        [(ngModel)]="Part5Header.projectLocation"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1f. Date (Part 5):</label>
                                    <input type="text" name="txtDate" [(ngModel)]="Part5Header.lookbackDate"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                        (dateChange)=" UpdatePart4LookBackHeaderDates(Part5Header)" class="form-control"
                                        [matDatepicker]="picker5" (click)="picker5.open()" />
                                    <mat-datepicker #picker5></mat-datepicker>
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1g. AR Date / Number:</label>
                                    <input type="text" name="txtDate" class="form-control"
                                        [(ngModel)]="Part5Header.arDate"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                        (dateChange)=" UpdatePart4LookBackHeaderDates(Part5Header)" class="form-control"
                                        [matDatepicker]="picker6" (click)="picker6.open()" />
                                    <mat-datepicker #picker6></mat-datepicker>
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1h.Special Project Type: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.SpecialProjectTypeName" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1i. Facility (megafriendly) Name: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.assetMegaFriendlyName" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    1j. Lookback Type
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xs-6">
                                    <div class="row">
                                        <div class="col-xs-1">
                                            <input type="checkbox" class="form-check-input" id="chkLevel1a"
                                                [checked]="Part5Header.lookbackType1"
                                                [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part5Header,'lookbackType1')" />
                                        </div>
                                        <div class="col-xs-11">
                                            <label class="form-check-label" for="chkLevel1a"> <strong>Level
                                                    1</strong>
                                                Total Chevron Commitment &ge;&nbsp;$500 MM</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-1">
                                            <input type="checkbox" class="form-check-input" id="chkLevel2a"
                                                [checked]="Part5Header.lookbackType3"
                                                [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part5Header,'lookbackType3')" />
                                        </div>
                                        <div class="col-xs-11">
                                            <label class="form-check-label" for="chkLevel2a"> <strong>Level
                                                    2</strong>
                                                Total Chevron Commitment &ge;&nbsp;$75 MM Level 1 not
                                                required</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-xs-6">
                                    <div class="row">
                                        <div class="col-xs-1">
                                            <input type="checkbox" class="form-check-input" id="chkLevel1b"
                                                [checked]="Part5Header.lookbackType2"
                                                [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part5Header,'lookbackType2')" />
                                        </div>
                                        <div class="col-xs-11">
                                            <label class="form-check-label" for="chkLevel1b"> <strong>Level
                                                    1</strong>
                                                Total Chevron Commitment &ge;&nbsp;$250 MM and substandard outcome
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-1">
                                            <input type="checkbox" class="form-check-input" id="chkLevel2b"
                                                [checked]="Part5Header.lookbackType4"
                                                [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part5Header,'lookbackType4')" />
                                        </div>
                                        <div class="col-xs-11">
                                            <label class="form-check-label" for="chkLevel2b"> <strong>Level
                                                    3</strong>
                                                Total Chevron Commitment is &lt;&nbsp;$75 MM.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-4">
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <input type="checkbox" class="form-check-input" id="chkSingleProject"
                                                [checked]="Part5Header.singleProject" style=" margin-right: 1rem;"
                                                [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part5Header,'singleProject')" />
                                            <label class="form-check-label" for="chkSingleProject"> Single Project
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <input type="checkbox" class="form-check-input" id="chkMultiProgram"
                                                [checked]="Part5Header.program" style=" margin-right: 1rem;"
                                                [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part5Header,'program')" />
                                            <label class="form-check-label" for="chkMultiProgram">Multiple ARs or
                                                Program</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-2">
                                    <input type="checkbox" class="form-check-input" id="chkNOJV"
                                        style="margin-right: 1rem;" [checked]="Part5Header.nojv"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                        (change)="IdentifiedLookBackTypeCheck($event, Part5Header,'nojv')" />
                                    <label class="form-check-label" for="chkNOJV">NOJV</label>
                                </div>
                                <div class="col-xs-3">
                                    Operator: <br />
                                    <input type="text" [(ngModel)]="Part5Header.operatorName"
                                        (change)=" UpdatePart5LookBackHeaderData(Part5Header)" class="form-control"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                </div>
                                <div class="col-xs-3">
                                    Chevron Share %:
                                    <input type="text" [(ngModel)]="Part5Header.cvxShare"
                                        (change)=" UpdatePart5LookBackHeaderData(Part5Header)" class="form-control"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12">
                                    <hr />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong> 2. Business Results. </strong>
                                    Summarize the project’s objectives, scope and high-level operating, financial and
                                    economic performance versus expectations at the time of authorization. Describe any
                                    significant scope changes since the Part 4 Lookback.
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 padding-0">
                                    <div class="col-sm-12">
                                        <ckeditor #section8a [editor]="Editor" [config]="config" id="section1-2"
                                            name="section1-2" (ready)="onReady($event,'section1-2')"
                                            [(ngModel)]="Part5Header.businessResults"
                                            [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit}">
                                        </ckeditor>
                                    </div>
                                </div>
                            </div>

                            <!--Old form Key insight-->
                            <div class="row"*ngIf="!this.features?.hasLookbacksLLFeature">
                                <div class="col-xs-12">
                                    <hr />
                                </div>
                            </div>
                            <div class="row" *ngIf="!this.features?.hasLookbacksLLFeature">
                                <div class="col-xs-12">
                                    <strong>3. Summarize the top (3-5) key insights/recommended practices </strong>
                                    identified since startup and the plan to share them.
                                </div>
                            </div>

                            <div class="row" *ngIf="!this.features?.hasLookbacksLLFeature">
                                <div class="col-xs-12 padding-0">
                                    <div class="col-sm-12">
                                        <ckeditor #section8a [editor]="Editor" [config]="config" id="section1-2"
                                            name="section1-2" (ready)="onReady($event,'section1-2')"
                                            [(ngModel)]="Part5Header.keyInsights"
                                            [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit}">
                                        </ckeditor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- --------------------Section 3 lesson learned ------------------>
                    <div class="col-xs-12 form-group" *ngIf="showSection3">
                        <div class="content-container">
                            <div class="row">
                                <div class="col-xs-12">
                                    <hr />
                                </div>
                            </div>
                            <div class="row sub-section">
                                <div class="col-xs-12 form-group">
                                    <strong><span>3.1 </span>Lessons Learned:</strong>
                                    <i> Describe key insights identified during work on this project </i>
                                    <span class="glyphicon glyphicon-question-sign"
                                        (click)="ShowLessonLearnedModal()"></span>
                                </div>
                                <form [formGroup]="formLL">
                                    <ng-container formArrayName="lessonsLearned">
                                        <div *ngFor="let control of lessonsLearned.controls ;let i = index;">
                                            <div [formGroupName]="i">
                                                <div class="row form-group">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <span class="col-sm-4 col-xs-12">
                                                            <span>{{i+1}}</span>
                                                            . Lesson Learned :
                                                        </span>
                                                        <span class="col-sm-8 col-xs-12" style="text-align:left">
                                                            <select formControlName="lessonLearnedCategoryId"
                                                                [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit}"
                                                                class="form-control"
                                                                readonly="this.part5LookBackAccessList?.data?.isLocked"
                                                                (change)="onCategoryChange($event,i+1,control)">
                                                                <option value="-1" selected>Please select category for
                                                                    lesson learned</option>
                                                                <option *ngFor="let category of LLCategoryData"
                                                                    [value]="category.id"
                                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit">
                                                                    {{category.name}}</option>
                                                            </select>
                                                        </span>
                                                    </div>

                                                    <div class="col-sm-6 col-xs-12">
                                                        <span class="col-sm-8 col-xs-12" style="text-align:left">
                                                            <input formControlName="title"
                                                                placeholder="Enter title for lesson learned"
                                                                class="form-control"
                                                                (blur)="onBlurTitleUpdate($event,i+1,'LessonLearned')"
                                                                style="text-align:left;width:104%!important" />
                                                        </span>
                                                        <span class="col-sm-4 col-xs-12" style="text-align:right">
                                                            <span
                                                                style="font-family: 'Glyphicons Halflings'!important;cursor: pointer!important;"
                                                                class="	glyphicon glyphicon-trash"
                                                                [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit}"
                                                                (click)="Delete(i+1,'LessonLearned')">
                                                                <span class="delete-btn-txt-font">Delete</span>
                                                            </span>

                                                        </span>
                                                    </div>

                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-sm-12">
                                                        <div class="col-xs-12">
                                                            <ckeditor [editor]="Editor" [config]="config"
                                                                formControlName="description" #lessonLearned
                                                                id="lessonLearned" name="lessonLearned"
                                                                [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit}"
                                                                [disabled]="LLTypeData[i].naLessonLearned"
                                                                (ready)="onReadyLLCK($event,i+1,'LessonLearned')">
                                                            </ckeditor>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-xs-12">
                                            <button type="button" class="btn btn-sm btn-primary"
                                                style="color: white !important;" (click)="addNewLL('LessonLearned')"
                                                [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit">Add
                                            </button>
                                        </div>
                                    </ng-container>
                                </form>
                            </div>
                            <div class="row sub-section">
                                <div class="col-xs-12 form-group">
                                    <strong><span>3.2 </span>Plan:</strong>
                                    <i> Include a sharing plan for the insights, the target Chevron organizations for
                                        receiving the information and approximate timing. Also, if available, include a
                                        summary of how any significant gap will be closed</i>
                                    <span class="glyphicon glyphicon-question-sign" (click)="ShowPlanModal()"></span>
                                </div>
                                <div>
                                    <div>
                                        <div class="row form-group">
                                            <div class="col-sm-12 col-xs-12">
                                                <span class="col-sm-12 col-xs-12" style="text-align:right">
                                                    <span
                                                        style="font-family: 'Glyphicons Halflings'!important;cursor: pointer!important;"
                                                        class="glyphicon glyphicon-trash glyphicon-custom-trash trash-button "
                                                        [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit}"
                                                        (click)="Delete(1,'Plan')">
                                                        <span class="delete-btn-txt-font">Delete</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-sm-12">
                                                <div></div>
                                                <div class="col-xs-12"
                                                    [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit}">
                                                    <ckeditor #plan [editor]="Editor" [config]="config" id="plan"
                                                        name="plan" [(ngModel)]="PlanTypeData.description"
                                                        [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit}"
                                                        (ready)="onReadyLLCK($event,1,'Plan')"></ckeditor>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row sub-section">
                                <div class="col-xs-12 form-group">
                                    <div class="col-xs-11" style="padding: 0">
                                        <strong><span>3.3 </span>Key Decisions:</strong>
                                        <i> Describe key decisions taken during work on this project phase</i>
                                        <span class="glyphicon glyphicon-question-sign"
                                            (click)="ShowKeyDecisionsModal()"></span>
                                        <div class="row pl-16">
                                            <!-- <input type="checkbox" [checked]="naKeyDecisionDisabled" [(ngModel)]="naKeyDecisionDisabled"
                                                (change)="NAKeyDecisionChange($event)" class="form-check-input"  [disabled]="isOnHold || readOnly || isReadOnlyGlobalProjectLevel">
                                                <label style="padding-left: 8px;"><b>N/A</b></label> -->
                                            <span *ngIf="!Part5Header.isOldVersionOfForms">
                                                <mat-slide-toggle (change)="NAKeyDecisionChange($event)"
                                                    [checked]="naKeyDecisionDisabled"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly  || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit">
                                                    <span class="app-font">N/A</span>
                                                </mat-slide-toggle>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <form [formGroup]="formKD" *ngIf="!naKeyDecisionDisabled">
                                    <ng-container formArrayName="keyDecision">
                                        <div *ngFor="let control of keyDecision.controls ;let i = index;">
                                            <div [formGroupName]="i">
                                                <div class="row form-group">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <span class="col-sm-4 col-xs-12">
                                                            <span>{{i+1}}</span>
                                                            . Decision :
                                                        </span>
                                                        <span class="col-sm-8 col-xs-12" style="text-align:left"
                                                            [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit}">
                                                            <input placeholder="Enter title for key decision"
                                                                formControlName="title"
                                                                style="text-align:left;width:104%!important"
                                                                [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit}"
                                                                class="form-control"
                                                                [disabled]="this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit"
                                                                (blur)="onBlurTitleUpdate($event,i+1,'KeyDecision')" />
                                                        </span>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="col-sm-8 col-xs-12"></div>
                                                        <span class="col-sm-4 col-xs-12" style="text-align:right">
                                                            <span
                                                                style="font-family: 'Glyphicons Halflings'!important;cursor: pointer!important;"
                                                                class="glyphicon glyphicon-trash glyphicon-custom-trash"
                                                                [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit}"
                                                                (click)="Delete(i+1,'KeyDecision')">
                                                                <span class="delete-btn-txt-font">Delete</span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-sm-12">
                                                        <div class="col-xs-12">
                                                            <ckeditor [editor]="Editor" [config]="config"
                                                                formControlName="description" #keyDecision
                                                                id="keyDecision" name="keyDecision"
                                                                [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit}"
                                                                (ready)="onReadyLLCK($event,i+1,'KeyDecision')">
                                                            </ckeditor>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-xs-12">
                                            <button type="button" class="btn btn-sm btn-primary"
                                                style="color: white !important;"
                                                [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit"
                                                (click)="addNewLL('KeyDecision')">Add </button>
                                        </div>
                                    </ng-container>
                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- <--------------------Section 4 Performance--------------------------------->
                    <div class="col-xs-12 form-group" *ngIf="showSection4">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <strong>4. Performance Measures (All Chevron Share)</strong><br />
                                All Chevron share except 8/8ths cases <br />
                                All volumes are net revenue interest <br />
                                &nbsp;
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">

                                <table class="table ">

                                    <tbody>
                                        <tr class="bg-table-default">
                                            <td colspan="2" class="text-center col-xs-4" rowspan="2">
                                                <div class="row">
                                                    <div class="col-xs-6">
                                                        <strong>FID AR Discount Date:</strong>
                                                    </div>
                                                    <div class="col-xs-6">
                                                        <input type="text" name="txtDate" class="form-control"
                                                            [(ngModel)]="Part5Performance.fidDate"
                                                            (dateChange)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                            [matDatepicker]="picker" (click)="picker.open()"
                                                            [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                        <mat-datepicker #picker></mat-datepicker>

                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-6">
                                                        <strong>Startup, First Oil Date:</strong>
                                                    </div>
                                                    <div class="col-xs-6">
                                                        <input type="text" name="txtDate" class="form-control"
                                                            [(ngModel)]="Part5Performance.startupDate"
                                                            (dateChange)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                            [matDatepicker]="picker1" (click)="picker1.open()"
                                                            [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                        <mat-datepicker #picker1></mat-datepicker>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-6">
                                                        <strong>Lookback Data Date: </strong>
                                                    </div>
                                                    <div class="col-xs-6">
                                                        <input type="text" name="txtDate" class="form-control"
                                                            [(ngModel)]="Part5Performance.lookbackDate"
                                                            (dateChange)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                            [matDatepicker]="picker2" (click)="picker2.open()"
                                                            [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                        <mat-datepicker #picker2></mat-datepicker>
                                                    </div>
                                                </div>
                                            </td>
                                            <td colspan="3" class="text-center">
                                                <strong>FID AR Data</strong>
                                            </td>
                                            <td class="text-center">
                                                <strong>Actual outcomes </strong> <br />
                                                with forecast using current Corporate guidelines
                                            </td>
                                            <td class="text-center">
                                                <strong>Normalized</strong> <br />
                                                Actual outcome with forecast using original AR price/FX/COGS guidelines
                                                & fiscal terms
                                            </td>
                                            <td class="text-center">
                                                <strong>Test Case</strong> <br />
                                                Actual outcome with forecast using low-trend price/COGS guidelines
                                            </td>
                                        </tr>

                                        <tr class="bg-table-default">
                                            <td class="col-xs-1 text-center text-bold">EV</td>
                                            <td class="col-xs-1 text-center text-bold">P10</td>
                                            <td class="col-xs-1 text-center text-bold">P90</td>
                                            <td class="col-xs-1 text-center text-bold">EV</td>
                                            <td class="col-xs-2 text-center text-bold">EV</td>
                                            <td class="col-xs-2 text-center text-bold">EV</td>
                                        </tr>

                                        <tr>
                                            <td class="bg-table-default" rowspan="2">4a. <strong>NPV </strong> @ 10%
                                                $MM</td>
                                            <td class="bg-table-default text-bold">FEED Forward</td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarnpvFeedEv">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.npvFeedEv"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarnpvFeedEv>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.npvFeedEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarnpvFeedP10">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.npvFeedP10"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarnpvFeedP10>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.npvFeedP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarnpvFeedP90">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.npvFeedP90"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarnpvFeedP90>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.npvFeedP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarnpvFeedActualOutcomes">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.npvFeedActualOutcomes"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarnpvFeedActualOutcomes>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.npvFeedActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarnpvFeedNormalized">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.npvFeedNormalized"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarnpvFeedNormalized>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.npvFeedNormalized"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style="border: none" class="bg-table-default text-bold">Full Cycle</td>
                                            <td style="border: none">
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarnpvFullEv">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.npvFullEv"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarnpvFullEv>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.npvFullEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td style="border: none">
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarnpvFullP10">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.npvFullP10"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarnpvFullP10>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.npvFullP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td style="border: none">
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarnpvFullP90">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.npvFullP90"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarnpvFullP90>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.npvFullP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td style="border: none">
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarnpvFullActualOutcomes">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.npvFullActualOutcomes"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarnpvFullActualOutcomes>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.npvFullActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td style="border: none">
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarnpvFullNormalized">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.npvFullNormalized"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarnpvFullNormalized>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.npvFullNormalized"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td style="border: none"></td>
                                        </tr>

                                        <tr class="border1-top">
                                            <td class="bg-table-default" rowspan="2">4b. <strong>DPI </strong> @ 10%
                                            </td>
                                            <td class="bg-table-default text-bold">FEED Forward</td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.dpiFeedEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.dpiFeedP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.dpiFeedP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.dpiFeedActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.dpiFeedNormalized"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style="border: none" class="bg-table-default text-bold">Full Cycle</td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.dpiFullEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.dpiFullP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.dpiFullP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.dpiFullActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.dpiFullNormalized"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                        </tr>

                                        <tr class="border1-top">
                                            <td class="bg-table-default" rowspan="2">4c. <strong>ROR </strong> % </td>
                                            <td class="bg-table-default text-bold">FEED Forward</td>
                                            <td><input type="text" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.rorFeedEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="text" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.rorFeedP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="text" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.rorFeedP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="text" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.rorFeedActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="text" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.rorFeedNormalized"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style="border: none" class="bg-table-default text-bold">Full Cycle</td>
                                            <td style="border: none"><input type="text" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.rorFullEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="text" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.rorFullP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="text" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.rorFullP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="text" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.rorFullActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="text" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.rorFullNormalized"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="text" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.rorFullTestCase"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                        </tr>

                                        <tr class="border-top">
                                            <td class="bg-table-default" rowspan="2">4d. <strong>{{ (this.features?.isNewCEisApplicableForms || Part5Header.isNewCEisApplicableForms) ? "Capex": "C&amp;E" }} </strong> in
                                                economics $MM </td>
                                            <td class="bg-table-default text-bold">FEED Forward</td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarfceFeedEv">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.ceFeedEv"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarfceFeedEv>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFeedEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarceFeedP10">
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFeedP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceFeedP10>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFeedP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarceFeedP90">
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFeedP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceFeedP90>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFeedP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarceFeedActualOutcomes">
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFeedActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceFeedActualOutcomes>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFeedActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarceFeedNormalized">
                                                    <input typnoDollarceFullEve="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFeedNormalized"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceFeedNormalized>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFeedNormalized"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style="border: none" class="bg-table-default text-bold">Full Cycle</td>
                                            <td style="border: none">
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarceFullEv">
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFullEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceFullEv>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFullEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td style="border: none">
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarceFullP10">
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFullP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceFullP10>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFullP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td style="border: none">
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarceFullP90">
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFullP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceFullP90>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFullP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td style="border: none">
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarceFullActualOutcomes">
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFullActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceFullActualOutcomes>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFullActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td style="border: none">
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarceFullNormalized">
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFullNormalized"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceFullNormalized>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFullNormalized"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td style="border: none">
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarceFullTestCase">
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFullTestCase"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceFullTestCase>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.ceFullTestCase"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                        </tr>


                                        <tr class="border-top">
                                            <td class="bg-table-default" colspan="2">4e. <strong>FID AR Total Chevron
                                                    Commitment </strong> $MM</td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarfidArEv">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.fidArEv"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarfidArEv>
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.fidArEv"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <div class="input-icon" *ngIf="!Part5Header.isOldVersionOfForms; else noDollarfidArActualOutcomes">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                        [(ngModel)]="Part5Performance.fidArActualOutcomes"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarfidArActualOutcomes>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.fidArActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        <tr class="border-top">
                                            <td class="bg-table-default" rowspan="4">
                                                4f. <strong>Estimated Ultimate Recovery</strong>
                                            </td>
                                            <td class="bg-table-default text-bold">Net MMBO</td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecoveryMmboEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecoveryMmboP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecoveryMmboP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecoveryMmboActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style="border: none" class="bg-table-default text-bold">Net BCF</td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecoveryBcfEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecoveryBcfP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecoveryBcfP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecoveryBcfActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                        <tr>
                                            <td style="border: none" class="bg-table-default text-bold">Net MMBOE</td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecoveryMmboeEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none">
                                                <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecoveryMmboeP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none">
                                                <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecoveryMmboeP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none">
                                                <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecoveryMmboeActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>

                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td style="border: none" class="bg-table-default text-bold">8/8ths MMBOE
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecovery88MmboeEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none">
                                                <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecovery88MmboeP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none">
                                                <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecovery88MmboeP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.estUltimateRecovery88MmboeActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>

                                        <tr class="border-top">
                                            <td class="bg-table-default" rowspan="2">
                                                4g. <strong>Peak Production Rate</strong>
                                                <br />annual average
                                            </td>
                                            <td class="bg-table-default text-bold">Net MBPD</td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.peakProductionMbpdEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.peakProductionMbpdP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.peakProductionMbpdP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.peakProductionMbpdActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>

                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td style="border: none" class="bg-table-default text-bold">Net MMCFD</td>
                                            <td style="border: none">
                                                <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.peakProductionMmcfdEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none">
                                                <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.peakProductionMmcfdP10"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none">
                                                <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.peakProductionMmcfdP90"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none">
                                                <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.peakProductionMmcfdActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>

                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>

                                        <tr class="border-top">
                                            <td class="bg-table-default" rowspan="4">
                                                4h. <strong>12-Month Production Attainment</strong>
                                            </td>
                                            <td class="bg-table-default text-bold">Net MMBO</td>
                                            <td>
                                                <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.month12MmboEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td> <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.month12MmboActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style="border: none" class="bg-table-default text-bold">8/8 MMBO</td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.month1288MmboEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.month1288MmboActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                        <tr>
                                            <td style="border: none" class="bg-table-default text-bold">Net BCF</td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.month12BcfEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.month12BcfActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td style="border: none" class="bg-table-default text-bold">8/8 BCF</td>
                                            <td style="border: none">
                                                <input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.month1288BcfEv"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [(ngModel)]="Part5Performance.month1288BcfActualOutcomes"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>

                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                        <!-- <--------key Drivers >>>>> -->
                                        <tr *ngIf="!Part5Header.isOldVersionOfForms">
                                            <td class="bg-table-default" style="border: none" colspan="1">
                                                4i.<strong>Key Performance Drivers</strong>
                                            </td>
                                            <td class="bg-table-default" style="border: none" colspan="1">
                                                <strong> Units</strong>
                                            </td>
                                        </tr>
                                        <tr *ngIf="!Part5Header.isOldVersionOfForms">
                                            <td class="bg-table-default" style="border: none">
                                                <input class="form-control"
                                                    [(ngModel)]="Part5Performance.keyPerformanceDrivers1"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />

                                            </td>
                                            <td class="bg-table-default" style="border: none">
                                                <input class="form-control"
                                                    [(ngModel)]="Part5Performance.keyPerformanceDriversUnit1"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part5Performance.keyPerformanceDriversEv"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part5Performance.keyPerformanceDriversP10"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part5Performance.keyPerformanceDriversP90"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part5Performance.keyPerformanceDriversActualOutcomes"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        <tr class="border-bottom"*ngIf="!Part5Header.isOldVersionOfForms">
                                            <td class="bg-table-default" style="border: none">
                                                <input class="form-control"
                                                    [(ngModel)]="Part5Performance.keyPerformanceDrivers2"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />

                                            </td>
                                            <td class="bg-table-default" style="border: none">
                                                <input class="form-control"
                                                    [(ngModel)]="Part5Performance.keyPerformanceDriversUnit2"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part5Performance.keyPerformanceDriversEV1"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part5Performance.keyPerformanceDriversP101"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part5Performance.keyPerformanceDriversP901"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    [(ngModel)]="Part5Performance.keyPerformanceDriversActualOutcomes1"
                                                    (change)=" UpdatePart5LookBacPerformanceData(Part5Performance)"
                                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>

                                </table>

                            </div>
                        </div>

                    </div>

                    <!-- <---------------------Section 5--------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection5">

                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong class="heading">5.Front-end loading indices at appropriation </strong>
                                    Summarize impact of FEL work on project results.
                                </div>
                            </div>

                            <br />
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>Asset</strong>
                                </div>
                            </div>
                            <div class="row pl-16" *ngIf="!Part5Header.isOldVersionOfForms">
                                <span>
                                    <mat-slide-toggle (change)="NAAssetEventCheck($event, Part5Header)"
                                        [checked]="Part5Header.assetNa"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                                        <span class="app-font">N/A</span>
                                    </mat-slide-toggle>
                                </span>
                            </div>
                            <div class="row" *ngIf="!Part5Header.assetNa">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold|| Part5Header.isReadOnly
                                    || Part5Header.assetNa || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #asset [editor]="Editor" [config]="config" id="asset" name="asset"
                                        [(ngModel)]="Part5Header.defintionAsset" (ready)="onReady($event,'asset')">
                                    </ckeditor>
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>Facilities definition</strong>
                                </div>
                            </div>
                            <div class="row pl-16" *ngIf="!Part5Header.isOldVersionOfForms">
                                <span>
                                    <mat-slide-toggle (change)="NAFDEventCheck($event, Part5Header)"
                                        [checked]="Part5Header.facilitiesDefinitionNa"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                                        <span class="app-font">N/A</span>
                                    </mat-slide-toggle>
                                </span>
                            </div>
                            <div class="row" *ngIf="!Part5Header.facilitiesDefinitionNa">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold|| Part5Header.isReadOnly
                                    || Part5Header.facilitiesDefinitionNa || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #FD [editor]="Editor" [config]="config" id="FD" name="FD"
                                        [(ngModel)]="Part5Header.definitionFacilities" (ready)="onReady($event,'FD')">
                                    </ckeditor>
                                </div>
                            </div>
                            <br />

                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>Wells definition</strong>
                                </div>
                            </div>
                            <div class="row pl-16" *ngIf="!Part5Header.isOldVersionOfForms">
                                <span>
                                    <mat-slide-toggle (change)="NAWDEventCheck($event, Part5Header)"
                                        [checked]="Part5Header.wellsDifinitionNa"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                                        <span class="app-font">N/A</span>
                                    </mat-slide-toggle>
                                </span>
                            </div>
                            <div class="row" *ngIf="!Part5Header.wellsDifinitionNa">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold|| Part5Header.isReadOnly
                                    || Part5Header.wellsDifinitionNa || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #WD [editor]="Editor" [config]="config" id="WD" name="WD"
                                        [(ngModel)]="Part5Header.definitionWells" (ready)="onReady($event,'WD')">
                                    </ckeditor>
                                </div>
                            </div>
                            <br />

                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>Reservoir definition</strong>
                                </div>
                            </div>
                            <div class="row pl-16" *ngIf="!Part5Header.isOldVersionOfForms">
                                <span>
                                    <mat-slide-toggle (change)="NARDEventCheck($event, Part5Header)"
                                        [checked]="Part5Header.reservoirDifinitionNa"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                                        <span class="app-font">N/A</span>
                                    </mat-slide-toggle>
                                </span>
                            </div>
                            <div class="row" *ngIf="!Part5Header.reservoirDifinitionNa">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold|| Part5Header.isReadOnly
                                    || Part5Header.reservoirDifinitionNa || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #RD [editor]="Editor" [config]="config" id="RD" name="RD"
                                        [(ngModel)]="Part5Header.definitionReservoir" (ready)="onReady($event,'RD')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--<---------------------Section 6 removed------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection6">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>6. Endorsement</strong>
                                    <br> &nbsp;
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-6">
                                    <label for="staticEmail" class="form-label">
                                        <strong>Reporting Officer:</strong>
                                    </label>
                                    <textarea rows="5" class="form-control" [(ngModel)]="Part5Header.reportingOfficer"
                                        (change)="UpdatePart5LookBackHeaderData(Part5Header)"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                                    </textarea>
                                </div>
                                <div class="col-xs-6">
                                    <label for="staticEmail" class="form-label">
                                        <strong>Individual with delegation of authority:</strong>
                                    </label>
                                    <textarea rows="5" class="form-control"
                                        [(ngModel)]="Part5Header.delegationOfAuthority"
                                        (change)="UpdatePart5LookBackHeaderData(Part5Header)"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 7 -- project documentation-------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection7">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong *ngIf="!Part5Header.isOldVersionOfForms">Project Documentation</strong>
                                    <strong *ngIf="Part5Header.isOldVersionOfForms">7. Attachments</strong>
                                    <br> &nbsp;
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-12">
                                    <input type="checkbox" class="form-check-input" id="chk7a"
                                        [(ngModel)]="Part5Header.attachIpa" [checked]="Part5Header.attachIpa"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part5Header,'IPA')" />
                                    <label class="form-check-label pl-5" for="chk7a" *ngIf="!Part5Header.isOldVersionOfForms">
                                        a. Lookback Part 4 Summary of Outcomes
                                    </label>
                                    <label class="form-check-label pl-5" for="chk7a" *ngIf="Part5Header.isOldVersionOfForms">
                                        7a. Lookback Part 4 Summary of Outcomes
                                    </label>
                                </div>
                                <div class="form-group col-xs-12">
                                    <input type="checkbox" class="form-check-input" id="chk7b"
                                        [(ngModel)]="Part5Header.attachL1SubsurfaceBasisDesign"
                                        [checked]="Part5Header.attachL1SubsurfaceBasisDesign"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part5Header,'basisOfDesign')" />
                                    <label class="form-check-label pl-5" for="chk7b" *ngIf="!Part5Header.isOldVersionOfForms">
                                        b. Subsurface Basis of Design
                                    </label>
                                    <label class="form-check-label pl-5" for="chk7b" *ngIf="Part5Header.isOldVersionOfForms">
                                        7b. Subsurface Basis of Design
                                    </label>
                                </div>
                                <div class="form-group col-xs-12">
                                    <input type="checkbox" class="form-check-input" id="chk7d"
                                        [(ngModel)]="Part5Header.attachL1RootCause"
                                        [checked]="Part5Header.attachL1RootCause"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part5Header,'rootCause')" />
                                    <label class="form-check-label pl-5" for="chk7d" *ngIf="!Part5Header.isOldVersionOfForms">
                                        c. Level 1 Root Cause Analysis for Financial Incidents
                                    </label>
                                    <label class="form-check-label pl-5" for="chk7d" *ngIf="Part5Header.isOldVersionOfForms">
                                        7c. Level 1 Root Cause Analysis for Financial Incidents
                                    </label>
                                </div>
                                <div class="form-group col-xs-12">
                                    <input type="checkbox" class="form-check-input" id="chk7e"
                                        [(ngModel)]="Part5Header.attachL1ReviewTeam"
                                        [checked]="Part5Header.attachL1ReviewTeam"
                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part5Header,'reviewTeam')" />
                                    <label class="form-check-label pl-5" for="chk7e" *ngIf="!Part5Header.isOldVersionOfForms">
                                        d. Level 1 Review Team Lookback Report
                                    </label>
                                    <label class="form-check-label pl-5" for="chk7e" *ngIf="Part5Header.isOldVersionOfForms">
                                        7d. Level 1 Review Team Lookback Report
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 6------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection8">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12" *ngIf="!Part5Header.isOldVersionOfForms">
                                    <strong>5. Operational/financial results </strong> Summarize performance since
                                    startup versus FID AR expectations. Include updated graphs of key performance
                                    metrics. Include a summary of relevant OE results. Summarize key
                                    insights/recommended practices identified.
                                </div>
                                <div class="col-xs-12" *ngIf="Part5Header.isOldVersionOfForms">
                                    <strong>8. Operational/financial results </strong> Summarize performance since
                                    startup versus FID AR expectations. Include updated graphs of key performance
                                    metrics. Include a summary of relevant OE results. Summarize key
                                    insights/recommended practices identified.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #section8 [editor]="Editor" [config]="config" id="section8"
                                        name="section8" [(ngModel)]="Part5Header.operationalResults"
                                        (ready)="onReady($event,'section8')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 7------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection9">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12" *ngIf="!Part5Header.isOldVersionOfForms">
                                    <strong>6. Commercial Agreement Results </strong> Summarize results of commercial
                                    agreements versus FID AR expectations. Include a discussion of root causes of
                                    outcomes with significant variance. Summarize key insights/recommended practices
                                    identified since startup.
                                </div>
                                <div class="col-xs-12" *ngIf="Part5Header.isOldVersionOfForms">
                                    <strong>9. Commercial Agreement Results </strong> Summarize results of commercial
                                    agreements versus FID AR expectations. Include a discussion of root causes of
                                    outcomes with significant variance. Summarize key insights/recommended practices
                                    identified since startup.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #section8d [editor]="Editor" [config]="config" id="section8d"
                                        name="section8d" [(ngModel)]="Part5Header.commercialAgreementResults"
                                        (ready)="onReady($event,'section9')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 8------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection10">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12" *ngIf="!Part5Header.isOldVersionOfForms">
                                    <strong>7. Economic results and variance analysis </strong> Summarize economic
                                    results versus FID AR expectations. Include an updated NPV cumulative probability
                                    curve, tornado diagram and NPV variance table and chart. Summarize findings from the
                                    root cause analysis work. Comment on the link between results and the decision
                                    quality at the time of AR sanction. Summarize key insights/ recommended practices
                                    identified since startup.
                                </div>
                                <div class="col-xs-12" *ngIf="Part5Header.isOldVersionOfForms">
                                    <strong>10. Economic results and variance analysis </strong> Summarize economic
                                    results versus FID AR expectations. Include an updated NPV cumulative probability
                                    curve, tornado diagram and NPV variance table and chart. Summarize findings from the
                                    root cause analysis work. Comment on the link between results and the decision
                                    quality at the time of AR sanction. Summarize key insights/ recommended practices
                                    identified since startup.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked||!this.part5LookBackAccessList?.data?.canEdit}">
                                    <ckeditor #section10 [editor]="Editor" [config]="config" id="section10"
                                        name="section10" [(ngModel)]="Part5Header.economicResults"
                                        (ready)="onReady($event,'section10')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 9-------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection11">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <strong *ngIf="!Part5Header.isOldVersionOfForms">8. Decision Executive, DRB and Project Manager</strong>
                                <strong *ngIf="Part5Header.isOldVersionOfForms">11. Decision Executive, DRB and Project Manager</strong>
                                <br> &nbsp;
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 text-bold">
                                Phase 1
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Decision Executive</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part5Header.p1De"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Project Manager</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part5Header.p1ProjectLead"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">DRB</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part5Header.p1Drb"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 text-bold">
                                Phase 2
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Decision Executive</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part5Header.p2De"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Project Manager</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part5Header.p2ProjectLead"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">DRB</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part5Header.p2Drb"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 text-bold">
                                Phase 3
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Decision Executive</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part5Header.p3De"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Project Manager</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part5Header.p3ProjectLead"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">DRB</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part5Header.p3Drb"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 text-bold">
                                Phase 4
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Decision Executive</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part5Header.p4De"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Project Manager</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part5Header.p4ProjectLead"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">DRB</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part5Header.p4Drb"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"></textarea>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 10-------------------------------->
                    <div class="col-xs-12 form-group mt-20 fs-16" *ngIf="showSection12">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <strong *ngIf="!Part5Header.isOldVersionOfForms">9. Lookback team member names/BUs</strong>
                                <strong *ngIf="Part5Header.isOldVersionOfForms">12. Lookback team member names/BUs</strong>
                                <br> &nbsp;
                            </div>
                        </div>
                        <br>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Name</label>
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember1Name"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Job Title</label>
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember1Title"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Affiliation</label>
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember1Affiliation"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember2Name"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember2Title"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember2Affiliation"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember3Name"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember3Title"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember3Affiliation"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly|| this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember4Name"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember4Title"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember4Affiliation"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember5Name"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember5Title"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember5Affiliation"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember6Name"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember6Title"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember6Affiliation"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember7Name"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember7Title"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember7Affiliation"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember8Name"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember8Title"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember8Affiliation"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember9Name"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember9Title"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember9Affiliation"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember10Name"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember10Title"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                            <div class="col-xs-4">
                                <input class="form-control" [(ngModel)]="Part5Header.teamMember10Affiliation"
                                    (change)=" UpdatePart5LookBackHeaderData(Part5Header)"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit">
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 13--------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection13">
                        <app-ssbod-nav *ngIf="parentData != undefined" [parentData]="parentData"
                            (refreshAfterSsbodLink)="refreshAfterSsbodLink()">
                        </app-ssbod-nav>
                    </div>

                    <!-- <---------------------Section 14 Form Reviewer------------------------------>
                    <div class="form-group fs-16" *ngIf="showSection14">
                        <div class="box-container">
                            <hr />
                            <div class="form-horizontal">
                                <div>
                                    <div class="row">
                                        <div class="col-xs-12 padding-0">
                                            <ng2-completer [(ngModel)]="searchStr"
                                                [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked || !this.part5LookBackAccessList?.data?.canSubmit"
                                                [datasource]="dataService" [placeholder]="placeholder"
                                                [minSearchLength]="0" (selected)="addUserToAccessList($event)"
                                                [autoMatch]="true" (keyup)="SearchUser($event)">

                                            </ng2-completer>
                                        </div>
                                        <div class="col-xs-3">
                                            &nbsp;
                                        </div>

                                    </div>
                                    <div class="row" *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">
                                        <div *ngIf="name.errors?.['minlength']" class="alert alert-danger" role="alert">
                                            Name must be at least 4 characters long.
                                        </div>
                                    </div>
                                    <div class="col-xs-3">
                                        &nbsp;
                                    </div>
                                    <table class="table border-bottom table-striped table-responsive table-bordered">
                                        <tbody>
                                            <tr class="border-top border-bottom">
                                                <th></th>
                                                <th class="text-center">
                                                    <div>Sender</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Receiver</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Latest Status</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Modified Date</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Latest Version</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Comments</div>
                                                </th>
                                                <th class="text-center" style="width: 34% !important;">Form Action</th>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr *ngFor="let reviewerItem of reviewerData let i = index"
                                                class="border-top border-bottom table-header">
                                                <td [(ngModel)]="reviewerItem.id" hidden class="ml-10">
                                                    {{reviewerItem.id}}</td>
                                                <td class="text-center col-xs-offset-0">
                                                    <button type="button" style="color: white !important;"
                                                        class="btn btn-sm btn-danger"
                                                        (click)="DeleteReviewer(reviewerItem,'delete')" [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly
                                                                    || this.part5LookBackAccessList?.data?.isLocked 
                                                                    || reviewerItem.deleteFlag">
                                                        X
                                                    </button>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{reviewerItem.submitterName}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{reviewerItem.reviewerName}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">

                                                    <span></span>
                                                    <span>{{reviewerItem.currentStatusName}}</span>

                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{reviewerItem.modifiedOn}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{reviewerItem.versionId}}</span>
                                                </td>
                                                <td class="text-center col-xs-2">

                                                    <textarea class="form-control" rows="2"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly
                                                                || this.part5LookBackAccessList?.data?.isLocked
                                                                || reviewerItem.commentFlag"
                                                        [(ngModel)]="reviewerItem.comments">
                                                                </textarea>
                                                </td>
                                                <td style="width: 36% !important;">
                                                    <button type="button" style="color: white !important;"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly
                                                                    || this.part5LookBackAccessList?.data?.isLocked || reviewerItem.submitterFlag || !this.part5LookBackAccessList?.data?.canSubmit"
                                                        class="btn btn-sm btn-success"
                                                        (click)="UpdateReviewer(reviewerItem.id,'forreview',reviewerItem,reviewerItem.comments)">
                                                        For Review
                                                    </button>
                                                    <button type="button" style="color: white !important;"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly
                                                                    || this.part5LookBackAccessList?.data?.isLocked || reviewerItem.reviewerFlag"
                                                        class="btn btn-sm btn-warning"
                                                        (click)="UpdateReviewer(reviewerItem.id,'sendback',reviewerItem,reviewerItem.comments)">
                                                        Send Back
                                                    </button>
                                                    <button type="button" style="color: white !important;" *ngIf="!Part5Header.isOldVersionOfForms"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly
                                                                    || this.part5LookBackAccessList?.data?.isLocked || reviewerItem.reviewerFlag"
                                                        class="btn btn-sm btn-success"
                                                        (click)="UpdateReviewer(reviewerItem.id,'endorsed',reviewerItem,reviewerItem.comments)">
                                                        Endorse
                                                    </button>
                                                    <button type="button" style="color: white !important;"
                                                        [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly
                                                                    || this.part5LookBackAccessList?.data?.isLocked || reviewerItem.reviewerFlag ||  !this.part5LookBackAccessList?.data?.canApprove"
                                                        class="btn btn-sm btn-success"
                                                        (click)="OpenApprovalDialog(reviewerItem.id,reviewerItem.comments)">
                                                        Concur
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngIf="reviewerCount==false" class="col-xs-12 padding-0 text-center">
                                        &nbsp;
                                        <b>No reviewers available. Please use the search bar to add
                                            reviewers.</b>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div class="row">
                                <div class="form-horizontal">
                                    <div class="row" style="text-align:center; display:block">
                                        Form Logs
                                    </div>
                                    <table class="table border-bottom table-striped table-responsive table-bordered">
                                        <tbody>
                                            <tr class="border-top border-bottom">
                                                <th class="text-center">
                                                    <div>Action Performed</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Sender</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Receiver</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Status From</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Status To</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Sender Comments</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Receiver Comments</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Version</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Date</div>
                                                </th>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr *ngFor="let formsLogItem of formsLogData let i = index"
                                                class="border-top border-bottom table-header">
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.formAction}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.submittedByUserName}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.submittedToUserName}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.oldStatusName}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.currentStatusName}}</span>
                                                </td>
                                                <td class="text-center col-xs-3">
                                                    <span>{{formsLogItem.submitterComments}}</span>
                                                </td>
                                                <td class="text-center col-xs-3">
                                                    <span>{{formsLogItem.reviewerComments}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.versionNumber}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.createdOn}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-xs-3">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group" *ngIf="!Part5Header.isOldVersionOfForms">
                            <strong>Signoffs </strong>
                            <span class="glyphicon glyphicon-question-sign" (click)="ShowSignoffsPopUp()"></span>
                        </div>
                        <div class="col-xs-12 sub-section" *ngIf="!Part5Header.isOldVersionOfForms">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr class="bg-table-default table-header">
                                        <td></td>
                                        <td width="20%">Name</td>
                                        <td width="20%">Position</td>
                                        <td width="20%">Signature</td>
                                        <td width="20%">Date</td>
                                    </tr>
                                    <tr *ngFor="let signOffItem of signOffData let i = index">
                                        <td [(ngModel)]="signOffItem.id" hidden class="ml-10">{{signOffItem.id}}</td>
                                        <!-- <td [(ngModel)]="signOffItem.signoffName" hidden class="ml-10">
                                            {{signOffItem.signoffName}}</td> -->
                                        <td [(ngModel)]="signOffItem.formType" hidden class="ml-10">
                                            {{signOffItem.formType}}</td>
                                        <td>{{signOffItem.signoffName}}</td>
                                        <td>
                                            <textarea type="text" rows="3"
                                                [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                                class="form-control" [(ngModel)]="signOffItem.name"
                                                (change)="UpdateSignOff(signOffItem,2)">
                                                </textarea>
                                        </td>
                                        <td>
                                            <textarea type="text" rows="3"
                                                [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                                class="form-control" [(ngModel)]="signOffItem.position"
                                                (change)="UpdateSignOff(signOffItem,2)">
                                                </textarea>
                                        </td>
                                        <td>
                                            <textarea type="text" rows="3"
                                                [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                                class="form-control" [(ngModel)]="signOffItem.signature"
                                                (change)="UpdateSignOff(signOffItem,2)">
                                                </textarea>
                                        </td>
                                        <td class="form-date">
                                            <input class="form-control" [(ngModel)]="signOffItem.signoffDate"
                                                (dateChange)="UpdateSignOff(signOffItem,2)" [matDatepicker]="picker"
                                                [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked ||!this.part5LookBackAccessList?.data?.canEdit"
                                                (click)="picker.open();utilityService.removeCDKAnouncer()">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-sidenav-container>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12">
            &nbsp;
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 padding-0">
            <div class="text-left">
                <h5 class="text-left">Upload Attachments :</h5>
                <input type="file" class="form-control-file upload" #file (change)="uploadFile($event)"
                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 padding-0 ">
            <table class="table table-bordered table-striped table-responsive">
                <thead>
                    <tr>
                        <td class="text-center text-bold">&nbsp;</td>
                        <td class="text-center text-bold">Document ID</td>
                        <td class="text-left text-bold">File Name</td>
                        <td class="text-left text-bold">Uploaded By</td>
                        <td class="text-left text-bold">&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-bordered" *ngFor="let document of part5Document let i = index">
                        <td class="text-center">
                            <button type="button" style="color: white !important;"
                                class="btn btn-sm btn-primary" (click)="DownloadFile(document)">
                                Download
                            </button>
                        </td>
                        <td class="text-center">
                            <span>{{document.documentId}}</span>
                        </td>
                        <td class="text-left">
                            <span>{{document.docName}}</span>
                        </td>
                        <td class="text-left">
                            <span>{{document.createdByName}}</span>
                        </td>
                        <td class="text-left">
                            <span>
                                <button type="button" style="color: white !important;" class="btn btn-sm btn-danger"
                                    [disabled]="Part5Header.isOnHold || Part5Header.isReadOnly || this.part5LookBackAccessList?.data?.isLocked"
                                    (click)="RemoveFile(document, i)">X</button>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12">
            <hr>
        </div>
    </div>
    <div class="row" style="margin-top: 15px;">
        <div class="col-xs-12 padding-0">
            <div class="row">
                <div class="form-group col-sm-4 col-xs-12 text-left padding-0 ">

                    <button type="button" class="btn btn-sm btn-primary" style="color: white !important"
                        [routerLink]="['/go36project/viewform']" [queryParams]="{projectId: projectId}">Close</button>
                    <button type="button" class="btn btn-sm btn-danger" style="color: white !important"
                        *ngIf="!Part5Header.isReadOnly"
                        [disabled]="Part5Header.isOnHold || !this.part5LookBackAccessList?.data?.canDeleteForm"
                        [routerLink]="['/forms/feed/delete']"
                        [queryParams]="{projectId: Part5Header.projectId,formId: Part5Header.arP5LookbackId,versionName: Part5Header.version,formType:'AR_LOOKBACK_PART5'}">
                        Delete</button>
                </div>
                <div class="form-group col-sm-4 col-xs-12 text-right padding-0">
                </div>
                <div class="form-group col-sm-4 col-xs-12 text-right padding-0">
                    <!-- <button type="button" class="btn btn-sm btn-primary btn-dark"
                style="color: white !important" (click)="ExportPDF()">PDF</button> -->
                    <a [href]="pdfUrl" class="btn btn-sm btn-primary btn-dark" target="_blank"
                        style="color: white !important;margin-right: 0.5em;margin-bottom: 0.5em;">PDF</a>
                    <button type="button" class="btn btn-sm btn-success btn-dark" style="color: white !important"
                        *ngIf="!Part5Header.isReadOnly" (click)="OpenArchiveDialog()"
                        [disabled]="Part5Header.isOnHold || this.part5LookBackAccessList?.data?.isLocked || !this.part5LookBackAccessList?.data?.canArchive|| Part5Header.statusId==5">Archive</button>

                        <button *ngIf="!Part5Header.isReadOnly && this.part5LookBackAccessList?.data?.isLatestVersion && !this.features?.hasWorkFlowProcessFeature" type="button"
                        class="btn btn-sm btn-success btn-dark" (click)="performFormAction('forreview')"
                        style="color: white !important;"
                        [disabled]="isOnHold || !this.part5LookBackAccessList?.data?.canSubmit || Part5Header.isReadOnly ||  this.part5LookBackAccessList?.data?.isLocked || this.forReviewLockByFormState">Send For Review</button>
              
                        <button *ngIf="!Part5Header.isReadOnly && this.part5LookBackAccessList?.data?.isLatestVersion && !this.features?.hasWorkFlowProcessFeature" type="button"
                        class="btn btn-sm btn-warning btn-dark" (click)="performFormAction('sendback')"
                        style="color: white !important;"
                        [disabled]="isOnHold || !this.part5LookBackAccessList?.data?.canSendBack || Part5Header.isReadOnly ||  this.part5LookBackAccessList?.data?.isLocked || this.sendBackLockByFormState">Send Back</button>
              
                        <button *ngIf="!Part5Header.isReadOnly && this.part5LookBackAccessList?.data?.isLatestVersion && !this.features?.hasWorkFlowProcessFeature" type="button"
                        class="btn btn-sm btn-success btn-dark" (click)="performFormAction('approved')"
                        style="color: white !important;"
                        [disabled]="isOnHold || !this.part5LookBackAccessList?.data?.canApprove || Part5Header.isReadOnly ||  this.part5LookBackAccessList?.data?.isLocked || this.concurLockByFormState">Concur</button>
                </div>
            </div>
        </div>
    </div>
</div>
