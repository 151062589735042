export class Part5ViewModel {
  Part5Header = new Go36ArP5LookbackHeaderModel();
  Part5Performance = new Go36ArP5LookbackPerformance();
  Part5Document: Go36P5LookbackDocument[] = [];
  signOff: LookbackSignOff[] = [];
}

export class Go36ArP5LookbackHeaderModel {
  arP5LookbackId: number = 0;
  arId: number = 0;
  supplementId?: number;
  lookbackDate?: Date;
  cvxShare?: any;
  lookbackType1: boolean = false;
  lookbackType2: boolean = false;
  lookbackType3: boolean = false;
  lookbackType4: boolean = false;
  singleProject: boolean = false;
  program: boolean = false;
  businessResults: string = '';
  keyInsights: string = '';
  defintionAsset: string = ''
  definitionFacilities: string = '';
  definitionWells: string = '';
  definitionReservoir: string = '';
  attachIpa: boolean = false;
  attachL1RootCause: boolean = false;
  attachL1ReviewTeam: boolean = false;
  attachL1SubsurfaceBasisDesign: boolean = false;
  operationalResults: string = '';
  commercialAgreementResults: string = '';
  economicResults: string = '';
  createdBy: string = '';
  createdDateTime: Date = new Date;
  modifiedBy: string = '';
  modifiedDateTime: Date = new Date;
  hideFromList: boolean = false;
  reportingOfficer: string = '';
  delegationOfAuthority: string = '';
  p1De: string = '';
  p1ProjectLead: string = '';
  p1Drb: string = '';
  p2De: string = '';
  p2ProjectLead: string = '';
  p2Drb: string = '';
  p3De: string = '';
  p3ProjectLead: string = '';
  p3Drb: string = '';
  p4De: string = '';
  p4ProjectLead: string = '';
  p4Drb: string = '';
  teamMember1Name: string = '';
  teamMember1Title: string = '';
  teamMember1Affiliation: string = '';
  teamMember2Name: string = '';
  teamMember2Title: string = '';
  teamMember2Affiliation: string = '';
  teamMember3Name: string = '';
  teamMember3Title: string = '';
  teamMember3Affiliation: string = '';
  teamMember4Name: string = '';
  teamMember4Title: string = '';
  teamMember4Affiliation: string = '';
  teamMember5Name: string = '';
  teamMember5Title: string = '';
  teamMember5Affiliation: string = '';
  teamMember6Name: string = '';
  teamMember6Title: string = '';
  teamMember6Affiliation: string = '';
  teamMember7Name: string = '';
  teamMember7Title: string = '';
  teamMember7Affiliation: string = '';
  teamMember8Name: string = '';
  teamMember8Title: string = '';
  teamMember8Affiliation: string = '';
  teamMember9Name: string = '';
  teamMember9Title: string = '';
  teamMember9Affiliation: string = '';
  teamMember10Name: string = '';
  teamMember10Title: string = '';
  teamMember10Affiliation: string = '';
  projectId: number = 0;
  projectLocation: string = '';
  arDate?: Date = new Date;
  nojv: boolean = false;
  operatorName: string = '';
  statusId: number = 0;
  originalId: number = 0;
  version: number = 0;
  versionName: string = '';
  approvedDateTime?: Date = new Date;
  isOnHold: boolean = false;
  isReadOnly: boolean = false;
  subsurfaceBasisofDesignId?: number;
  active: boolean = true;
  assetNa: boolean = false;
  facilitiesDefinitionNa: boolean = false;
  wellsDifinitionNa: boolean = false;
  reservoirDifinitionNa: boolean = false;
  isOldVersionOfForms: boolean = false;
  isNewCEisApplicableForms: boolean = true;
}

export class Go36ArP5LookbackPerformance {

  p5LookbackPerformanceId: number = 0;
  p5LookbackId: number = 0;
  fidDate?: Date = new Date;
  startupDate?: Date = new Date;
  lookbackDate?: Date = new Date;
  npvFeedEv?: any;
  npvFeedP10?: any;
  npvFeedP90?: any;
  npvFeedActualOutcomes?: any;
  npvFeedNormalized?: any;
  npvFullEv?: any;
  npvFullP10?: any;
  npvFullP90?: any;
  npvFullActualOutcomes?: any;
  npvFullNormalized?: any;
  dpiFeedEv?: any;
  dpiFeedP10?: any;
  dpiFeedP90?: any;
  dpiFeedActualOutcomes?: any;
  dpiFeedNormalized?: any;
  dpiFullEv?: any;
  dpiFullP10?: any;
  dpiFullP90?: any;
  dpiFullActualOutcomes?: any;
  dpiFullNormalized?: any;
  rorFeedEv?: any;
  rorFeedP10?: any;
  rorFeedP90?: any;
  rorFeedActualOutcomes?: any;
  rorFeedNormalized?: any;
  rorFullEv?: any;
  rorFullP10?: any;
  rorFullP90?: any;
  rorFullActualOutcomes?: any;
  rorFullNormalized?: any;
  rorFullTestCase?: any;
  ceFeedEv?: any;
  ceFeedP10?: any;
  ceFeedP90?: any;
  ceFeedActualOutcomes?: any;
  ceFeedNormalized?: any;
  ceFullEv?: any;
  ceFullP10?: any;
  ceFullP90?: any;
  ceFullActualOutcomes?: any;
  ceFullNormalized?: any;
  ceFullTestCase?: any;
  fidArEv?: any;
  fidArActualOutcomes?: any;
  estUltimateRecoveryMmboEv: any;
  estUltimateRecoveryMmboP10: any;
  estUltimateRecoveryMmboP90: any;
  estUltimateRecoveryMmboActualOutcomes: any;
  estUltimateRecoveryBcfEv: any;
  estUltimateRecoveryBcfP10: any;
  estUltimateRecoveryBcfP90: any;
  estUltimateRecoveryBcfActualOutcomes: any;
  estUltimateRecoveryMmboeEv: any;
  estUltimateRecoveryMmboeP10: any;
  estUltimateRecoveryMmboeP90: any;
  estUltimateRecoveryMmboeActualOutcomes: any;
  estUltimateRecovery88MmboeEv: any;
  estUltimateRecovery88MmboeP10: any;
  estUltimateRecovery88MmboeP90: any;
  estUltimateRecovery88MmboeActualOutcomes: any;
  peakProductionMbpdEv?: any;
  peakProductionMbpdP10: any;
  peakProductionMbpdP90: any;
  peakProductionMbpdActualOutcomes: any;
  peakProductionMmcfdEv: any;
  peakProductionMmcfdP10: any;
  peakProductionMmcfdP90: any;
  peakProductionMmcfdActualOutcomes: any;
  month12MmboEv: any;
  month12MmboActualOutcomes: any;
  month1288MmboEv: any;
  month1288MmboActualOutcomes: any;
  month12BcfEv: any;
  month12BcfActualOutcomes: any;
  month1288BcfEv: any;
  month1288BcfActualOutcomes: any;
  createdBy?: string = '';
  createdDatetime: Date = new Date;
  modifiedBy: string = '';
  modifiedDatetime: Date = new Date;
  hidefromlist: boolean = false;
  keyPerformanceDriversUnit1: any;
  keyPerformanceDriversUnit2: any;
  keyPerformanceDrivers1: any;
  keyPerformanceDrivers2: any;
  keyPerformanceDriversP101: any;
  keyPerformanceDriversEV1: any;
  keyPerformanceDriversP901: any;
  keyPerformanceDriversActualOutcomes1: any;
  keyPerformanceDriversEv: any;
  keyPerformanceDriversP10: any;
  keyPerformanceDriversP90: any;
  keyPerformanceDriversActualOutcomes: any;
}

export class LessonLearnedDetail {
  id: number = 0;
  active: boolean = true;
  createdBy?: string = '';
  modifiedBy?: string = '';
  createdOn: Date = new Date();
  modifiedOn: Date = new Date();
  order: number = 0;
  lessonLearnedTypeId: number = 0;
  formId: number = 0;
  description?: string = '';
  title?: string = '';
  projectId: number = 0;
  lessonLearnedCategoryId?: number = 0;
  formType?: string = '';
  nakeyDecision: boolean = false;
  naLessonLearned: boolean = true;
}

export class Go36P5LookbackDocument {
  documentId: number = 0;
  p5LookbackId: number = 0;
  docName: string = '';
  docContentType: string = '';
  docContent?: Blob;
  createdBy: string = '';
  createdByName: string = '';
  createdDatetime: Date = new Date();
  modifiedBy: string = '';
  modifiedDatetime: Date = new Date();
  hidefromlist: boolean = false;
}

export class LookbackSignOff {
  id: number = 0;
  active: boolean = true;
  createdBy?: string = '';
  modifiedBy?: string = '';
  createdOn?: Date;
  modifiedOn?: Date;
  order: number = 0;
  formId: number = 0;
  signoffName?: string = '';
  name?: string = '';
  position?: string = '';
  signature: string = '';
  signoffDate: Date = new Date();
  overviewId: number = 0;
  comment: string = '';
  formType: string = '';
}
