<div class="box-container" id="page-body">
    <h3>View User</h3>

    <div class="row">
        <div class="col-md-6 col-md-offset-3">
            <hr>
            <form [formGroup]='formValue'>
                <div class="form-horizontal">
                    <div class="form-group">
                        <label class="control-label col-xs-2" for="User_Name">First Name</label>
                        <div class="col-xs-4">
                            <input class="form-control col-xs-4" formControlName="firstname" readonly="true" id="User_firstName"
                                type="text" value="">
                        </div>
                        <label class="control-label col-xs-2" for="User_Name">Last Name</label>
                        <div class="col-xs-4">
                            <input class="form-control col-xs-4" formControlName="lastname" readonly="true" id="User_lastName"
                                type="text" value="">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-2" for="User_Name" >Email</label>
                        <div class="col-xs-4">
                            <input class="form-control col-xs-4" formControlName="email" id="User_Email" type="text" readonly="true"
                                value="">
                        </div>
                        <label class="control-label col-xs-2" for="User_Name">Role</label>
                        <div class="col-xs-4">
                            <!-- <select formControlName="roleId" class="form-control"
                                (change)="onRoleChange($any($event.target).value)">
                                <option *ngFor="let role of RoleList" [value]="role.roleId">
                                    {{role.roleDescription}}</option>
                            </select> -->
                            <input class="form-control col-xs-4" formControlName="roleDescription" id="roleDescription" readonly="true"
                                type="text" value="">
                        </div>
                    </div>
                </div>
                <hr>
                <div class="form-horizontal">
                    <h4> Organization Level Access </h4>
                    <div class="form-horizontal" *ngIf="userRole != 'Power User' && OrgData.length > 0">
                        <div id="tblOrgDetails_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div class="row">
                                <div class="col-sm-12">
                                    <p-table #dt1 [value]="OrgData" dataKey="id" [rows]="10" [paginator]="true"
                                   [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"  paginatorPosition="both"
                                    [globalFilterFields]="['opcoName','sbuName','buName']"  styleClass="p-datatable-projects p-datatable-sm">
                                        <ng-template pTemplate="caption">
                                            <span><b>Show Entries</b></span>
                                            <div class="flex" style="float:right;margin-bottom: 1%;">
                                                <span class="p-input-icon-left ml-auto">
                                                    <b>Search: </b><input #searchText pInputText type="text"
                                                        (input)="dt1.filterGlobal(searchText.value, 'contains')" />
                                                </span>
                                            </div>
                                    </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr style="background-color: lightskyblue;height: 40px;">
                                                <th class="text-left text-bold sorting" tabindex="0" rowspan="1" colspan="1" style="width:142px;" pSortableColumn="opco">Segment
                                                    <p-sortIcon  field="opco"></p-sortIcon>
                                                </th>
                                                <th class="text-left text-bold sorting"  tabindex="0" rowspan="1" colspan="1" style="width:117px;" pSortableColumn="sbu">RU<p-sortIcon
                                                         field="sbu"></p-sortIcon>
                                                </th>
                                                <th class="text-left text-bold sorting" tabindex="0" rowspan="1" colspan="1" style="width:100px;" pSortableColumn="bu">BU<p-sortIcon
                                                        field="bu"></p-sortIcon>
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-org>
                                            <tr>
                                                <td class="text-bold"><strong>{{org.opcoName}}</strong></td>
                                                <td class="text-bold">{{org.sbuName}}</td>
                                                <td class="text-bold">{{org.buName}}</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-horizontal text-center" *ngIf="userRole == 'Power User'">
                        Power User has access across all Segment's, RU's and BU's in the organization
                    </div>
                    <div class="form-horizontal text-center" *ngIf="userRole != 'Power User' && OrgData.length == 0">
                        No access has been granted to the user at a Organization level !
                    </div>
                </div>
                <hr>
                <div class="form-horizontal">
                    <h4> Project Level Access </h4>
                    <div class="form-horizontal" *ngIf="ProjectData.length > 0">
                        <div id="tblOrgDetails_wrapper1" class="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div class="row">
                                <div class="col-sm-12">
                                    <p-table #dt2 [value]="ProjectData" selectionMode="single"  [globalFilterFields]="['projectName','opcoName','sbuName','projectRoleName']"
                                        [rows]="10" [paginator]="true"    
                                        [rowsPerPageOptions]="[10, 20, 50, 100, { showAll: 'All' }]"  paginatorPosition="both"  styleClass="p-datatable-projects p-datatable-sm">
                                        <ng-template pTemplate="caption">
                                            <span><b>Show Entries</b></span>
                                            <div class="flex" style="float:right;margin-bottom: 1%;">
                                                    <span class="p-input-icon-left ml-auto">
                                                        <b>Search: </b><input #searchText pInputText type="text"
                                                            (input)="dt2.filterGlobal(searchText.value, 'contains')" />
                                                    </span>
                                            </div>
                                       </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr style="background-color: lightskyblue;height: 40px;">
                                                <th class="text-left text-bold sorting" style="width:130px;" pSortableColumn="projectName">Project Name
                                                    <p-sortIcon  field="projectName"></p-sortIcon>
                                                </th>
                                                <th class="text-left text-bold sorting" style="width:37px;" pSortableColumn="opcoName">OPCO
                                                    <p-sortIcon field="opcoName"></p-sortIcon>
                                                </th>
                                                <th class="text-left text-bold sorting" style="width:58px;" pSortableColumn="sbuName">SBU<p-sortIcon
                                                         field="sbuName"></p-sortIcon>
                                                </th>
                                                <th class="text-left text-bold sorting" style="width:72px;" pSortableColumn="projectRoleName">Project Role<p-sortIcon
                                                         field="projectRoleName"></p-sortIcon>
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-proj>
                                            <tr>
                                                <td class="text-bold"><strong>{{proj.projectName}}</strong></td>
                                                <td class="text-bold"><strong>{{proj.opcoName}}</strong></td>
                                                <td class="text-bold">{{proj.sbuName}}</td>
                                                <td class="text-bold">{{proj.projectRoleName}}</td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="paginatorleft" let-state>
                                            Showing {{(state.first) + 1}} to {{(state.totalRecords >
                                              (state.rows * (state.page + 1))) ? state.rows * (state.page + 1) : state.totalRecords }}
                                              of {{state.totalRecords}} entries
                                          </ng-template>
                                    </p-table>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-horizontal text-center" *ngIf="ProjectData.length == 0">
                        No access has been granted to the user at a Project level !
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-xs-6 padding-0 text-left">
                        <a class="btn btn-default" routerLink="/manage/useraccesses">Back</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>