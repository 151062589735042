<div class="row fid-form" data-bind="with: FormFid">
    <div class="row sub-section">
        <div class="col-xs-12 form-group">
            <strong>9. Appendix (Listing of Supporting Attachments):</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowAppendixModal()"></span>
        </div>
        <div class="col-xs-12" data-bind="with: appendix">
            <table class="table table-bordered margin-bottom-0">
                <tbody>
                    <tr class="table-header">
                        <td colspan="7" class="bg-table-default">
                            <i *ngIf="!form.IsOldVersionOfForms">Attach any relevent documentation for current phase of
                                the project (Phase 2 documents for
                                FEED AR and Phase 3 for FID, plus Opportunity Selection study).</i>
                            <i *ngIf="form.IsOldVersionOfForms">Attach any relevant documentation as files
                                electronically, including:</i>
                        </td>
                    </tr>
                    <tr class="table-header bg-table-default">
                        <td></td>
                        <td></td>
                        <td class="text-center">Document Control</td>
                        <td>Document Name</td>
                        <td>Date Uploaded</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr *ngIf="form.IsOldVersionOfForms">
                        <td colspan="7">
                            <h4 class="text-left" style="font-size: 16px;">Phase 1:</h4>
                        </td>
                    </tr>
                    <tr *ngFor="let phase1 of Phase1 let i = index"
                        [ngClass]="{'hidephase1': !form.IsOldVersionOfForms}">
                        <td [(ngModel)]="phase1.id" hidden class="ml-10">{{phase1.id}}</td>
                        <td valign="middle" width="25%">
                            <!-- <span *ngIf="!phase1.isEditable">Decision Support Package</span> -->
                            <input *ngIf="phase1.isEditable" name="sectionName" class="form-control"
                                placeholder="Attachment Name" [(ngModel)]="phase1.sectionName" [disabled]="(isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel) ||
                                (form.isUpstream && phase1.order < 32)" (change)="SaveAppendixData(phase1)">
                            <!-- <span *ngIf="form.isUpstream">{{phase1.sectionName}}</span> -->
                        </td>
                        <td valign="middle" class="text-center" width="15%">
                            <div *ngIf="!form.isReadOnly">
                                <div class="fileUpload btn btn-primary margin-bottom-0 margin-right-0" *ngIf="!isOnHold"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <span>Upload Attachment</span>
                                    <input type="file" class="form-control-file upload" #file
                                        (change)="uploadFile($event, phase1)">
                                </div>
                            </div>
                        </td>
                        <td valign="middle" width="15%" class="text-center">
                            <div class="appendix-version">
                                <select class="form-control" *ngIf="phase1.versions && phase1.versions.length > 0"
                                    (change)="onVersionChangePhase1($any($event.target).value, phase1.id, phase1.sectionName)">
                                    <option *ngFor="let item of phase1.versions" value="{{item.id}}">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </td>
                        <td width="25%">
                            <span>{{phase1.fileName}}</span>
                        </td>
                        <td width="10%">
                            <span *ngIf="phase1.versions && phase1.versions.length > 0">
                                {{phase1.createdOn | date:'MM/dd/yyyy'}}
                            </span>
                        </td>
                        <td width="10%">
                            <button type="button" style="color: white !important;" class="btn btn-sm btn-primary"
                                *ngIf="phase1.versions && phase1.versions.length > 0" (click)="DownloadFile(phase1)">
                                Download
                            </button>
                        </td>
                        <td>
                            <div *ngIf="!form.isReadOnly">
                                <span class="glyphicon glyphicon-trash glyphicon-custom-trash" *ngIf="!isOnHold"
                                    (click)="DeletePhases(phase1, i)"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}"></span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="form.IsOldVersionOfForms">
                        <td colspan="7">
                            <button type="button" class="btn btn-sm btn-primary" *ngIf="!isOnHold"
                                (click)="AddPhase1Document()"
                                [disabled]="form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                style="color: white !important;">Add Other Documents</button>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="7">
                            <h4 class="text-left" style="font-size: 16px;">Phase 2:</h4>
                        </td>
                    </tr>
                    <tr *ngFor="let phase2 of Phase2 let i = index">
                        <td [(ngModel)]="phase2.id" hidden class="ml-10">{{phase2.id}}</td>
                        <td valign="middle" width="25%">
                            <!-- <span *ngIf="!phase2.isEditable">Decision Support Package</span> -->
                            <input *ngIf="form.IsOldVersionOfForms" name="sectionName" class="form-control"
                                placeholder="Attachment Name" [(ngModel)]="phase2.sectionName" [disabled]="(isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel) ||
                                (form.isUpstream && phase2.order < 32)" (change)="SaveAppendixData(phase2)">
                            <input *ngIf="!form.IsOldVersionOfForms" name="sectionName" class="form-control"
                                placeholder="Attachment Name" [(ngModel)]="phase2.sectionName"
                                [disabled]="(isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel)"
                                (change)="SaveAppendixData(phase2)">
                        </td>
                        <td valign="middle" class="text-center" width="15%">
                            <div *ngIf="!form.isReadOnly">
                                <div class="fileUpload btn btn-primary margin-bottom-0 margin-right-0" *ngIf="!isOnHold"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <span>Upload Attachment</span>
                                    <input type="file" class="form-control-file upload" #file
                                        (change)="uploadFile($event, phase2)">
                                </div>
                            </div>
                        </td>
                        <td valign="middle" width="15%" class="text-center">
                            <div class="appendix-version">
                                <!-- <select class="form-control" (change)="onVersionChange($any($event.target).value, phase2.id)">
                                    <option *ngFor="let item of getPhase2Data(phase2.originalId); trackBy:identify"
                                        value="{{item.versionId}}">
                                        {{item.name}}
                                    </option>
                                </select> -->
                                <select class="form-control" *ngIf="phase2.versions && phase2.versions.length > 0"
                                    (change)="onVersionChangePhase2($any($event.target).value, phase2.id, phase2.sectionName)">
                                    <option *ngFor="let item of phase2.versions" value="{{item.id}}">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </td>
                        <td width="25%">
                            <span>{{phase2.fileName}}</span>
                        </td>
                        <td width="10%">
                            <span *ngIf="phase2.versions && phase2.versions.length > 0">
                                {{phase2.createdOn | date:'MM/dd/yyyy'}}
                            </span>
                        </td>
                        <td width="10%">
                            <button type="button" style="color: white !important;" class="btn btn-sm btn-primary"
                                *ngIf="phase2.versions && phase2.versions.length > 0" (click)="DownloadFile(phase2)">
                                Download
                            </button>
                        </td>
                        <td>
                            <div *ngIf="!form.isReadOnly">
                                <span class="glyphicon glyphicon-trash glyphicon-custom-trash" *ngIf="!isOnHold"
                                    (click)="DeletePhases(phase2, i)"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="7">
                            <button type="button" class="btn btn-sm btn-primary" *ngIf="!isOnHold"
                                (click)="AddPhase2Document()"
                                [disabled]="form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                style="color: white !important;">Add Other Documents</button>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="7">
                            <h4 class="text-left" style="font-size: 16px;">Phase 3:</h4>
                        </td>
                    </tr>
                    <tr *ngFor="let phase3 of Phase3 let j = index">
                        <td [(ngModel)]="phase3.id" hidden class="ml-10" name="id">
                            {{phase3.id}}
                        </td>
                        <td valign="middle" width="25%">
                            <!-- <span *ngIf="!phase3.isEditable">Decision Support Package</span> -->
                            <input *ngIf="form.IsOldVersionOfForms" class="form-control" name="sectionName"
                                placeholder="Attachment Name" [(ngModel)]="phase3.sectionName" [disabled]="(isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel) ||
                                (form.isUpstream && phase3.order < 32)" (change)="SaveAppendixData(phase3)">
                            <input *ngIf="!form.IsOldVersionOfForms" name="sectionName" class="form-control"
                                placeholder="Attachment Name" [(ngModel)]="phase3.sectionName"
                                [disabled]="(isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel)"
                                (change)="SaveAppendixData(phase3)">
                        </td>
                        <td valign="middle" class="text-center" width="15%">
                            <div *ngIf="!form.isReadOnly">
                                <div class="fileUpload btn btn-primary margin-bottom-0 margin-right-0" *ngIf="!isOnHold"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <span>Upload Attachment</span>
                                    <input type="file" class="form-control-file upload" #file
                                        (change)="uploadFile($event, phase3)">
                                </div>
                            </div>
                        </td>
                        <td valign="middle" width="15%" class="text-center">
                            <div class="appendix-version">
                                <!-- <select class="form-control">
                                    <option *ngFor="let item of getPhase3Data(phase3.originalId)"
                                        [value]="item.versionId">
                                        {{item.name}}
                                    </option>
                                </select> -->
                                <select class="form-control" *ngIf="phase3.versions && phase3.versions.length > 0"
                                    (change)="onVersionChangePhase3($any($event.target).value, phase3.id, phase3.sectionName)">
                                    <option *ngFor="let item of phase3.versions" value="{{item.id}}">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </td>
                        <td width="25%">
                            <span>{{phase3.fileName}}</span>
                        </td>
                        <td width="10%">
                            <span *ngIf="phase3.versions && phase3.versions.length > 0">
                                {{phase3.createdOn | date:'MM/dd/yyyy'}}
                            </span>
                        </td>
                        <td width="10%">
                            <button type="button" style="color: white !important;" class="btn btn-sm btn-primary"
                                *ngIf="phase3.versions && phase3.versions.length > 0"
                                (click)="DownloadFile(phase3)">Download</button>
                        </td>
                        <td>
                            <div *ngIf="!form.isReadOnly">
                                <span class="glyphicon glyphicon-trash glyphicon-custom-trash" *ngIf="!isOnHold"
                                    (click)="DeletePhases(phase3, j)"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="7">
                            <button type="button" class="btn btn-sm btn-primary" *ngIf="!isOnHold"
                                (click)="AddPhase3Document()"
                                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                style="color: white !important;"> Add Other Documents</button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="7">
                            <h4 class="text-left" style="font-size: 15px;">FEED/FID Documents:</h4>
                        </td>
                    </tr>
                    <tr *ngFor="let feed of FEED_FID let k = index">
                        <td [(ngModel)]="feed.id" hidden class="ml-10" name="id">
                            {{feed.id}}
                        </td>
                        <td valign="middle" width="25%">
                            <!-- <span *ngIf="!feed.isEditable">Presentation</span> -->
                            <input class="form-control" *ngIf="form.IsOldVersionOfForms" name="sectionName"
                                placeholder="Attachment Name" [(ngModel)]="feed.sectionName" [disabled]="(isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel) ||
                                (form.isUpstream && feed.order < 32)" (change)="SaveAppendixData(feed)">
                            <input *ngIf="!form.IsOldVersionOfForms" name="sectionName" class="form-control"
                                placeholder="Attachment Name" [(ngModel)]="feed.sectionName"
                                [disabled]="(isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel)"
                                (change)="SaveAppendixData(feed)">
                        </td>
                        <td valign="middle" class="text-center" width="15%">
                            <div *ngIf="!form.isReadOnly">
                                <div class="fileUpload btn btn-primary margin-bottom-0 margin-right-0" *ngIf="!isOnHold"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <span>Upload Attachment</span>
                                    <input type="file" class="form-control-file upload" #file
                                        (change)="uploadFile($event, feed)">
                                </div>
                            </div>
                        </td>
                        <td valign="middle" width="15%" class="text-center">
                            <div class="appendix-version">
                                <select class="form-control" *ngIf="feed.versions && feed.versions.length > 0"
                                    (change)="onVersionChangeFEED($any($event.target).value, feed.id, feed.sectionName)">
                                    <option *ngFor="let item of feed.versions" value="{{item.id}}">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </td>
                        <td width="25%" data-bind="with: SelectedItem">
                            <span>{{feed.fileName}}</span>
                        </td>
                        <td width="10%" data-bind="with: SelectedItem">
                            <span *ngIf="feed.versions && feed.versions.length > 0">
                                {{feed.createdOn | date:'MM/dd/yyyy'}}
                            </span>
                        </td>
                        <td width="10%">
                            <button type="button" style="color: white !important;" class="btn btn-sm btn-primary"
                                *ngIf="feed.versions && feed.versions.length > 0"
                                (click)="DownloadFile(feed)">Download</button>
                        </td>
                        <td>
                            <div *ngIf="!form.isReadOnly">
                                <span class="glyphicon glyphicon-trash glyphicon-custom-trash" *ngIf="!isOnHold"
                                    (click)="DeletePhases(feed, k)"
                                    [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}"></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="7">
                            <button type="button" class="btn btn-sm btn-primary" *ngIf="!isOnHold"
                                (click)="AddFEEDDocument()"
                                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                style="color: white !important;">Add Other Documents</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>