import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ArchiveDialogModel } from 'src/app/Modals/archive-dialog/archive-dialog.component';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { SsbodService } from '../../service/ssbod.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-ssbod-archive-dialog',
  templateUrl: './ssbod-archive-dialog.component.html',
  styleUrls: ['./ssbod-archive-dialog.component.css']
})
export class SsbodArchiveDialogComponent implements OnInit {
  SsbodVersionsDropdown:any[] = [];
  formId: number = 0;
  versionName: string = '';
  selectedVersion: string = 'SSBOD Phase 2 SSTR Entry';
  provId: string = '';
  constructor(public dialogRef: MatDialogRef<ArchiveDialogModel>, private router: Router, 
    private route: ActivatedRoute,private ssbodService: SsbodService,
    private go36ProjectService: Go36projectService,public utilityService: UtilityService,
     @Inject(MAT_DIALOG_DATA) public data: ArchiveDialogModel) {
    dialogRef.disableClose = true;
    this.versionName = data.versionName;
   }
  ngOnInit(): void {
    this.provId = this.utilityService.GetuserProvisioningId();
    this.route.queryParams
    .subscribe(params => {
      this.formId = params['formId'];
    })
    this.GetAllSsbodVersions();
  }
  onDismiss(): void {
    this.dialogRef.close();
  }
  GetAllSsbodVersions(){
    this.ssbodService.GetSSBODVersions(this.formId).subscribe(res => {
        this.SsbodVersionsDropdown = res;
    });
  }
  onSsbodVersionChange(event: any){
    this.selectedVersion = event.target.value;
  } 
  OnCreate(){
    if(this.selectedVersion != ""){
      this.ssbodService.CreateNamedVersion(this.formId,this.selectedVersion,this.provId).subscribe(res => {
        this.onDismiss();
        this.router.navigate([ "/forms/ssbod" ], { queryParams: { formId: Number(res)} }).then(() =>{
          window.location.reload();
        });
      });
    }
  } 
}
