import { HttpClient,HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppsettingsService } from 'src/app/Shared/appsettings.service';

@Injectable({
  providedIn: 'root'
})

export class FeedNavService {

  IsOnHoldUrl = 'api/Form/SaveIsOnHoldValue';
  GetUrl = 'api/Form/GetFEEDData?formId=';
  getFormDataUrl = 'api/Form/GetFormData?formId=';
  deleteFormUrl = 'api/Form/DeleteForm?formId=';
  archiveFormUrl = 'api/Form/ArchiveForm';
  archiveFormPart4Url = 'api/LookbackPart4/ArchiveFormPart4';
  archiveFormPart5Url = 'api/LookbackPart5/ArchiveFormPart5';
  getUserAccessListURL = "api/UserAccess/GetUserAccessList?formId=";
  archiveEAFormPart4Url = 'api/EAPart4/ArchiveEAFormPart4';
  GetFeedPDFUrl = 'api/Overview/GetFeedPDF?projectId=';
  OptOutBusinessCaseUrl = 'api/Form/SaveOptOutBusinessCase';
  OptOutAlternativeSelectionUrl = 'api/Form/SaveOptOutAlternativeSelection';
  OptOutBasisOfEvaluationUrl = 'api/Form/SaveOptOutBasisOfEvaluation';
  OptOutLessonsLearnedUrl = 'api/Form/SaveOptOutLessonsLearned';
  constructor(private http: HttpClient,
    private appsettings: AppsettingsService,) { }

  getData(formId: number,versionName:number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetUrl + formId +'&versionName='+versionName);
  }

  postIsOnHoldValue(form: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.IsOnHoldUrl, form);
  }
  postOptOutBusinessCase(form: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.OptOutBusinessCaseUrl, form);
  }
  postOptOutAlternativeSelection(form: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.OptOutAlternativeSelectionUrl, form);
  }
  postOptOutBasisOfEvaluation(form: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.OptOutBasisOfEvaluationUrl, form);
  }
  postOptOutLessonsLearned(form: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.OptOutLessonsLearnedUrl, form);
  }
  getFormData(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getFormDataUrl + formId);
  }

  deleteForm(formId: number,versionName: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.deleteFormUrl + formId + "&version="+versionName);
  }
  ArchiveForm(formId: number,oldVersionId:number, versionName: string, objectId: string, provId: string) {
    const params = new HttpParams()
      .append('formId', formId)
      .append('oldVersionId',oldVersionId)
      .append('versionName',versionName)
      .append('objectId',objectId)
      .append('provId',provId)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.archiveFormUrl, '', {
      params: params
    });
  } 
  ArchiveFormPart4(formId: number,oldVersionId:number, versionName: string, objectId: string, provId: string) {
    const params = new HttpParams()
      .append('formId', formId)
      .append('oldVersionId',oldVersionId)
      .append('versionName',versionName)
      .append('objectId',objectId)
      .append('provId',provId)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.archiveFormPart4Url, '', {
      params: params
    });
  } 
  ArchiveFormPart5(formId: number,oldVersionId:number, versionName: string, objectId: string, provId: string) {
    const params = new HttpParams()
      .append('formId', formId)
      .append('oldVersionId',oldVersionId)
      .append('versionName',versionName)
      .append('objectId',objectId)
      .append('provId',provId)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.archiveFormPart5Url, '', {
      params: params
    });
  } 
  
  GetUserAccessList(formId: number, objectId: string, provId: string) {         
   return this.http.get<any>(this.appsettings.apiBaseUrl + this.getUserAccessListURL + formId +'&objectId='+objectId +'&provId='+provId);
  }
  ArchiveEAFormPart4(formId: number,oldVersionId:number, versionName: string, objectId: string, provId: string) {
    const params = new HttpParams()
      .append('formId', formId)
      .append('oldVersionId',oldVersionId)
      .append('versionName',versionName)
      .append('objectId',objectId)
      .append('provId',provId)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.archiveEAFormPart4Url, '', {
      params: params
    });
  } 
  GetFeedPDF(projectId: number,formId:number,version:number){
    const url = this.appsettings.apiBaseUrl + this.GetFeedPDFUrl + projectId+"&formId="+formId+"&version="+version;
    return this.http.get<any>(url, {responseType: 'blob' as 'json'});      
  }

}
