import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AssetService } from 'src/app/admin/service/asset.service';
import { AssetModel } from '../../asset.model';

@Component({
  selector: 'app-strategicbusinesunit-list',
  templateUrl: './strategicbusinesunit-list.component.html',
  styleUrls: ['./strategicbusinesunit-list.component.css']
})
export class StrategicbusinesunitListComponent implements OnInit {
  Data: any; 
  modelObj: AssetModel = new AssetModel();
  formValue!: UntypedFormGroup;
  showAdd!: boolean;
  showUpdate!: boolean;
  formDataLength:number=0;
   constructor(private formbuilder: UntypedFormBuilder, private apiService: AssetService) { }
 
   ngOnInit(): void {
     this.getAll();
   }
 
   getAll() {
     this.apiService.get("strategicbu").subscribe(res => {
       this.Data = res;
       this.formDataLength = this.Data.length;
       this.onShowEntryDropdownChange({first: 0, rows: this.formDataLength});
     })
   }
   onShowEntryDropdownChange(event){
    if(event.rows === this.formDataLength) 
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "All";
    else if(event.rows === 10)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "10" ;
    else if(event.rows === 20)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML  = "20" ;
    else if(event.rows === 50)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "50" ;
    else if(event.rows === 100)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "100" ;
  }
 }
