<div class="row fid-form font-gotham" style="background-color: white;">
    <div class="row sub-section">
        <div class="col-xs-12">
            <span [(ngModel)]="execution.id" style="display: none;"></span>
            <strong>6.1 Project Execution Plan including key contracting strategy, staffing and complexity</strong>            
            <span class="glyphicon glyphicon-question-sign" (click)="ShowGuidanceModal()"></span>
        </div>
        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <span>
                <ckeditor #projectExecutionPlan [editor]="Editor" [config]="config" id="projectExecutionPlan" name="projectExecutionPlan"
                [(ngModel)]="execution.projectExecutionPlan" (ready)="onReady($event,'ProjectExecutionPlan')"
                ></ckeditor>
            </span>
        </div>
    </div>
    <div class="row sub-section" *ngIf="form.formType !== 'EPC'">
        <div class="col-xs-12">
            <span  style="display: none;"></span>
            <strong *ngIf="form.formType !='FEED Supplement' && form.formType !='FID Supplement'">6.2 Cost Estimate Overview and Basis:</strong>
            <strong *ngIf="form.formType =='FEED Supplement' || form.formType =='FID Supplement'">6.2 Cost Estimate Overview and Basis. Include cost waterfall chart to highlight the changes:</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowCostEstimateOverviewModal()"></span>
        </div>
        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <table class="table table-bordered margin-bottom-0">
                <tbody>
                    <tr class="table-header">
                        <td class="bg-table-default">
                            <i>Include cost sensitivity cumulative probability chart here. Summarize highlights and risks, including diverging co-owner views. Include table with high-level costs associated with the project.</i>

                        </td>
                    </tr>
                </tbody>
            </table>
            <span>
                <ckeditor #costEstimate [editor]="Editor" [config]="config" id="costEstimate" name="costEstimate"
                    [(ngModel)]="execution.costEstimate"  (ready)="onReady($event,'CostEstimate')"
                     ></ckeditor>
            </span>
        </div>
    </div>
    <div class="row sub-section" *ngIf="form.formType !== 'EPC'">
            <div class="col-xs-12">
                <strong *ngIf="form.formType !='FEED Supplement' && form.formType !='FID Supplement'">6.3 Schedule Estimate and Basis</strong>
                <strong *ngIf="form.formType =='FEED Supplement' || form.formType =='FID Supplement'">6.3 Schedule Estimate and Basis. Include schedule waterfall chart to highlight the changes:</strong>
                <span class="glyphicon glyphicon-question-sign" (click)="ShowScheduleEstimateModal()"></span>
            </div>
            <div class="col-xs-12 col-sm-6">
                <table class="table table-bordered">
                    <tbody>
                        <tr class="bg-table-default table-header">
                            <td><strong>Phase Gate Milestones</strong></td>
                            <td style="text-align:center;" *ngIf="form.formType !='FEED Supplement' && form.formType !='FID Supplement'">Date</td>
                            <td style="text-align:center;" *ngIf="form.formType =='FEED Supplement' || form.formType =='FID Supplement'">Supplement Date</td>
                            <td style="text-align:center;" *ngIf="form.formType =='FEED Supplement'">Original FEED AR Date</td>
                            <td style="text-align:center;" *ngIf="form.formType =='FID Supplement'">Original FID AR Date</td>
                            <td width="1%"></td>
                        </tr>
                        <tr *ngFor="let phasemile of phasemileStone let i = index">
                            <td>
                                <span>{{phasemile.milestoneName}}</span>                              
                            </td>
                            <td>
                                <input class="form-control" [matDatepicker]="picker" 
                                [(ngModel)]="phasemile.currentDate" readonly [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"/>
                                <mat-datepicker #picker ></mat-datepicker>
                            </td>
                            <td *ngIf="form.formType =='FEED Supplement' || form.formType =='FID Supplement'">
                                <input class="form-control read-only-ar-dates" [matDatepicker]="arpicker" [(ngModel)]="phasemile.originalFeedArDate" readonly/>
                                <mat-datepicker #arpicker ></mat-datepicker>
                            </td>
                            <td>
                                <div [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!phasemile.comment"
                                        (click)="SaveCommentForPhaseMilestone(phasemile)"></i>
                                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="phasemile.comment"
                                        (click)="SaveCommentForPhaseMilestone(phasemile)"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-xs-12 col-sm-6">
                <div class="row">
                    <div class="col-xs-12">
                        <table class="table table-bordered">
                            <tbody>
                                <tr class="bg-table-default table-header">
                                    <td><strong>Key Milestones</strong></td>
                                    <td style="text-align:center;" *ngIf="form.formType !='FEED Supplement' && form.formType !='FID Supplement'">Date</td>
                                    <td style="text-align:center;" *ngIf="form.formType =='FEED Supplement' || form.formType =='FID Supplement'">Supplement Date</td>
                                    <td style="text-align:center;" *ngIf="form.formType =='FEED Supplement'">Original FEED AR Date</td>
                                    <td style="text-align:center;" *ngIf="form.formType =='FID Supplement'">Original FID AR Date</td>
                                    <td width="1%"></td>
                                    <td width="1%" class="trash-icon"></td>
                                </tr>
                                <tr *ngFor="let keymile of keymileStone let i = index">
                                    <td class="no-border-td" [(ngModel)]="keymile.id" hidden class="ml-10">{{keymile.id}}</td>
                                    <td class="no-border-td" *ngIf="(form.formType =='FEED Supplement' || form.formType =='FID Supplement') && !keymile.isEditable;else keymilestonename">
                                        <input type="text" class="form-control" [(ngModel)]="keymile.keyMilestoneName" readonly/>
                                    </td>
                                    <ng-template #keymilestonename>
                                        <td class="no-border-td">
                                            <input type="text" class="form-control" [(ngModel)]="keymile.keyMilestoneName"
                                            (change)="UpdateKeyMileStone(keymile)" [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"/>
                                        </td>
                                    </ng-template>
                                    <td class="no-border-td" *ngIf="form.formType =='FEED Supplement' || form.formType =='FID Supplement';else currentDate">
                                        <input class="form-control" [(ngModel)]="keymile.currentDate"
                                        (dateChange)="UpdateKeyMileStone(keymile)"  [matDatepicker]="picker"
                                        (click)="picker.open();utilityService.removeCDKAnouncer()"  [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </td>
                                    <ng-template #currentDate>
                                        <td class="no-border-td">
                                            <input class="form-control" [(ngModel)]="keymile.currentDate" 
                                            (dateChange)="UpdateKeyMileStone(keymile)" [matDatepicker]="picker"
                                            (click)="picker.open();utilityService.removeCDKAnouncer()"  [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </td>
                                    </ng-template>
                                    <td *ngIf="(form.formType =='FEED Supplement' || form.formType =='FID Supplement') && !keymile.isEditable;else ardate">
                                        <input class="form-control read-only-ar-dates" [matDatepicker]="picker" readonly [(ngModel)]="keymile.date" />
                                        <mat-datepicker #picker ></mat-datepicker>
                                    </td>
                                    <ng-template #ardate>
                                        <td *ngIf="form.formType =='FEED Supplement' || form.formType =='FID Supplement'">
                                            <input class="form-control read-only-ar-dates" [matDatepicker]="picker" readonly />
                                            <mat-datepicker #picker ></mat-datepicker>
                                        </td>
                                    </ng-template>
                                    <td [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel,'no-border-td':true}">
                                        <div>
                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!keymile.comment"
                                                (click)="SaveCommentForKeyMilestone(keymile)"></i>
                                            <i class="glyphicon glyphicon-comment commentColor" *ngIf="keymile.comment"
                                                (click)="SaveCommentForKeyMilestone(keymile)"></i>
                                        </div>
                                    </td>
                                    <td [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel, 'no-border-td':true}">
                                        <i class="glyphicon glyphicon-trash glyphicon-custom-trash" (click)="DeleteKeyMilestone(keymile, i)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-xs-12">
                            <button type="button" (click)="addNewKeyMilestone()" [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                             class="btn btn-sm btn-primary" style="color: white !important;">Add New Row</button>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
                <table class="table table-bordered margin-bottom-0">
                    <tbody>
                        <tr class="table-header">
                            <td class="bg-table-default">
                                <i>Include schedule sensitivity cumulative probability chart here. Summarize highlights and risks, including divergent co-owner views. 
                                </i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span>
                    <ckeditor #scheduleSensitivity [editor]="Editor" [config]="config" id="scheduleSensitivity" name="scheduleSensitivity"
                    [(ngModel)]="execution.scheduleSensitivity" (ready)="onReady($event,'ScheduleSensitivity')"
                      ></ckeditor>
                </span>
            </div> 
    </div>
    
    <div class="row sub-section">
        <div class="col-xs-12">
            <span  style="display: none;"></span>
            <strong>6.4 Project Schedule or Gantt Chart. Include co-owner views as applicable</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowProjectScheduleModal()"></span>
        </div>
        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <span>
                <ckeditor #projectSchedule [editor]="Editor" [config]="config" id="projectSchedule" name="projectSchedule"
                [(ngModel)]="execution.projectSchedule" (ready)="onReady($event,'ProjectSchedule')"
                 ></ckeditor>
            </span>
        </div>
    </div>
</div>