export class User {
    id: number;
    name: string;
    objectId: string;
    provId: string;
    constructor(id:number, name:string, objectId:string, provId:string ) {
      this.id = id;
      this.name = name;
      this.objectId = objectId;
      this.provId = provId;
    }
}
export class UserForAccessSecurity {
  id: number;
  name: string;
  FirstName: string;
  LastName: string;
  ObjectId: string;
  ProvisioningId: string;
  EmailId: string;
  UserId: number;
  constructor(id:number, name:string,FirstName: string, LastName: string, ObjectId:string, ProvisioningId:string, EmailId:string, UserId:number ) {
    this.id = id;
    this.name = name;
    this.FirstName = FirstName;
    this.LastName = LastName;
    this.ObjectId = ObjectId;
    this.ProvisioningId = ProvisioningId;
    this.EmailId = EmailId;
    this.UserId = UserId;
  }
}