import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AssetListComponent } from './admin/asset/asset-list/asset-list.component';
import { AssetCreateComponent } from './admin/asset/asset-create/asset-create.component';
import { HomeComponent } from './home/home.component';
import { Go36projectCreateComponent } from './project/go36project/go36project-create/go36project-create.component';
import { Go36projectlistComponent } from './project/go36project/go36project-list/go-36-project-list.component';
import { ViewformlistComponent } from './project/go36project/viewform/viewformlist/viewformlist.component';
import { UseraccessComponent } from './project/go36project/useraccess/useraccess.component';
import { FeedNavComponent } from './forms/feed/feed-nav/feed-nav.component';
import { EaProjectListComponent } from './project/ea/ea-project-list/ea-project-list.component';
import { EaProjectCreateComponent } from './project/ea/ea-project-create/ea-project-create.component';
import { EAUseraccessComponent } from './project/ea/useraccess/ea-useraccess.component';
import { CopyProjectComponent } from './project/go36project/copy-project/copy-project.component';
import { LoaderComponent } from './layout/loader/loader.component';
import { DeleteComponent } from './forms/feed/delete/delete.component';
import { ArchiveDialogComponent } from './Modals/archive-dialog/archive-dialog.component';
import { FeedSupplementNavComponent } from './forms/feed_Supplement/feed-supplement-nav/feed-supplement-nav.component';
import { FidNavComponent } from './forms/fid/fid-nav/fid-nav.component';
import { FidSupplementNavComponent } from './forms/fid_supplement/fid-supplement-nav/fid-supplement-nav.component';
import { LessonsLearnedNavComponent } from './forms/lessons-learned/lessons-learned-nav/lessons-learned-nav.component';
import { Part4LookBackNavComponent } from './forms/oldstyle_part4lookback/part4lookback-nav/part4lookback-nav.component';
import { EaViewformlistComponent } from './project/ea/ea-viewformlist/ea-viewformlist.component';
import { SsbodprojectListComponent } from './project/ssbodproject/ssbodproject-list/ssbodproject-list.component';
import { SsbodNavComponent } from './project/ssbodproject/ssbod/ssbod-nav/ssbod-nav.component';
import { DeleteprojectComponent } from './project/deleteproject/deleteproject.component';
import { MyprojectformComponent } from './quicklinks/myprojectform/myprojectform.component';
import { MylookbackformsComponent } from './quicklinks/mylookbackforms/mylookbackforms.component';
import { RegisteruserComponent } from './registeruser/registeruser.component';
import { Part5LookbackComponent } from './forms/part5-lookback/part5-lookback.component';
import { NewArNavComponent } from './project/ea/ea-newform/new-ar-nav/new-ar-nav.component';
import { NewArComponent } from './project/ea/ea-newform/new-ar/new-ar.component';
import { EaPart4LookbackComponent } from './project/ea/forms/ea-part4-lookback/ea-part4-lookback.component';
import { EaDeleteComponent } from './project/ea/forms/ea-delete/ea-delete.component';
import { OperatingcompanyListComponent } from './admin/asset/operatingcompany/operatingcompany-list/operatingcompany-list.component';
import { OperatingcompanyCreateComponent } from './admin/asset/operatingcompany/operatingcompany-create/operatingcompany-create.component';
import { StrategicbusinesunitListComponent } from './admin/asset/strategicbusinessunit/strategicbusinesunit-list/strategicbusinesunit-list.component';
import { StrategicbusinesunitCreateComponent } from './admin/asset/strategicbusinessunit/strategicbusinesunit-create/strategicbusinesunit-create.component';
import { BusinessunitListComponent } from './admin/asset/businessunit/businessunit-list/businessunit-list.component';
import { BusinessunitCreateComponent } from './admin/asset/businessunit/businessunit-create/businessunit-create.component';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { UseraccessListComponent } from './admin/asset/useraccess/useraccess-list/useraccess-list.component';
import { UseraccessCreateComponent } from './admin/asset/useraccess/useraccess-create/useraccess-create.component';
import { SupplementNavComponent } from './project/ea/ea-newform/supplement-nav/supplement-nav.component';
import { FormSettingsComponent } from './form-settings/form-settings.component';
import { LessonsLearnedDashboardNavComponent } from './project/go36project/lessons-learned-dashboard/lessons-learned-dashboard-nav/lessons-learned-dashboard-nav.component';
import { UseraccessViewComponent } from './admin/asset/useraccess/useraccess-view/useraccess-view.component';
import { UseraccessEditComponent } from './admin/asset/useraccess/useraccess-edit/useraccess-edit.component';
import { MyeaprojectformsComponent } from './quicklinks/myeaprojectforms/myeaprojectforms.component';
import { MyrsidprojectsComponent } from './quicklinks/myrsidprojects/myrsidprojects.component';
import { MyearsidprojectsComponent } from './quicklinks/myearsidprojects/myearsidprojects.component';
import { MyformsinworkflowComponent } from './quicklinks/myformsinworkflow/myformsinworkflow.component';
import { AuthGuard } from './admin/service/auth.guard';
import { PowerAndAdminGuard } from './admin/service/power-and-admin.guard';
import { OldTemplateDocuments } from './Model/OldTemplateDocuments.model';
import { OldTemplateFormsComponent } from './forms/old-template-forms/old-template-forms.component';
import { OldTemplateSupplementComponent } from './forms/old-template-supplement/old-template-supplement.component';
import { UserprofileComponent } from './admin/asset/userprofile/userprofile.component';

const routes: Routes = [
  { path: 'manage/assets', component: AssetListComponent, canActivate: [AuthGuard] },
  { path: 'manage/assets/create', component: AssetCreateComponent, data: { editMode: false }, canActivate: [AuthGuard] },
  { path: 'manage/assets/update', component: AssetCreateComponent, canActivate: [AuthGuard] },
  { path: 'project/go36project/create', component: Go36projectCreateComponent },
  { path: 'go36project/project', component: Go36projectlistComponent },
  { path: 'project/go36project/usersecurityaccess', component: UseraccessComponent },
  { path: 'project/go36project/update', component: Go36projectCreateComponent },
  { path: 'go36project/viewform', component: ViewformlistComponent },
  { path: 'forms/feed/:projectId/:formId/:formCase', component: FeedNavComponent },
  { path: 'forms/feed', component: FeedNavComponent },
  { path: '', component: HomeComponent },
  { path: 'ea/project', component: EaProjectListComponent },
  { path: 'project/eaproject/update', component: EaProjectCreateComponent },
  { path: 'project/eaproject/create', component: EaProjectCreateComponent },
  { path: 'project/eaproject/usersecurityaccess', component: EAUseraccessComponent },
  { path: 'project/copy', component: CopyProjectComponent },
  { path: '', component: HomeComponent },
  { path: 'loader', component: LoaderComponent },
  { path: 'forms/feed/delete', component: DeleteComponent },
  { path: 'forms/feed_Supplement/delete', component: DeleteComponent },
  { path: 'forms/fid/delete', component: DeleteComponent },
  { path: 'forms/fid_Supplement/delete', component: DeleteComponent },
  { path: 'forms/lessonlearned/delete', component: DeleteComponent },
  { path: 'forms/feed/archive', component: ArchiveDialogComponent },
  { path: 'forms/feed_Supplement/:projectId/:formId/:versionName/:formCase', component: FeedSupplementNavComponent },
  { path: 'forms/feed_Supplement', component: FeedSupplementNavComponent },
  { path: 'forms/fid/:projectId/:formId/:versionName/:formCase', component: FidNavComponent },
  { path: 'forms/fid', component: FidNavComponent },
  { path: 'forms/fid_Supplement/:projectId/:formId/:versionName/:formCase', component: FidSupplementNavComponent },
  { path: 'forms/fid_Supplement', component: FidSupplementNavComponent },
  { path: 'forms/lessonlearned/:projectId/:formId/:versionName/:formCase', component: LessonsLearnedNavComponent },
  { path: 'forms/lessonlearned', component: LessonsLearnedNavComponent },
  { path: 'forms/part4', component: Part4LookBackNavComponent },
  { path: 'eaproject/eaviewform', component: EaViewformlistComponent },
  { path: 'forms/ssbodProject', component: SsbodprojectListComponent },
  { path: 'forms/ssbod', component: SsbodNavComponent },
  { path: 'forms/deleteproject', component: DeleteprojectComponent },
  { path: 'forms/myprojectforms', component: MyprojectformComponent },
  { path: 'forms/mylookbackforms', component: MylookbackformsComponent },
  { path: 'registeruser', component: RegisteruserComponent },
  { path: 'forms/part5', component: Part5LookbackComponent },
  { path: 'ea/new-ar-nav', component: NewArNavComponent },
  { path: 'ea/new-ar/newar', component: NewArComponent },
  { path: 'ea/forms/eapart4', component: EaPart4LookbackComponent },
  { path: 'ea/forms/delete', component: EaDeleteComponent },
  { path: 'manage/operatingcompanies', component: OperatingcompanyListComponent, canActivate: [AuthGuard] },
  { path: 'manage/operatingcompanies/create', component: OperatingcompanyCreateComponent, data: { editMode: false }, canActivate: [AuthGuard] },
  { path: 'manage/operatingcompanies/update', component: OperatingcompanyCreateComponent, canActivate: [AuthGuard] },
  { path: 'manage/strategicBusinessUnits', component: StrategicbusinesunitListComponent, canActivate: [AuthGuard] },
  { path: 'manage/strategicBusinessUnits/create', component: StrategicbusinesunitCreateComponent, data: { editMode: false }, canActivate: [AuthGuard] },
  { path: 'manage/strategicBusinessUnits/update', component: StrategicbusinesunitCreateComponent, canActivate: [AuthGuard] },
  { path: 'manage/businessunits', component: BusinessunitListComponent, canActivate: [AuthGuard] },
  { path: 'manage/businessunits/create', component: BusinessunitCreateComponent, data: { editMode: false }, canActivate: [AuthGuard] },
  { path: 'manage/businessunits/update', component: BusinessunitCreateComponent, canActivate: [AuthGuard] },
  { path: 'manage/useraccesses', component: UseraccessListComponent, canActivate: [PowerAndAdminGuard] },
  { path: 'manage/useraccesses/create', component: UseraccessCreateComponent, data: { editMode: false }, canActivate: [PowerAndAdminGuard] },
  { path: 'manage/useraccesses/update', component: UseraccessEditComponent, canActivate: [PowerAndAdminGuard] },
  { path: 'manage/useraccesses/viewdetail', component: UseraccessViewComponent, canActivate: [PowerAndAdminGuard] },
  { path: 'errorpage', component: ErrorpageComponent },
  { path: 'ea/supplement-nav', component: SupplementNavComponent },
  { path: 'form-settings', component: FormSettingsComponent },
  { path: 'lessonslearned/dashboard', component: LessonsLearnedDashboardNavComponent },
  { path: 'forms/settings/edit', component: FormSettingsComponent },
  { path: 'forms/myeaprojectforms', component: MyeaprojectformsComponent },
  { path: 'forms/myrsidproject', component: MyrsidprojectsComponent },
  { path: 'forms/myearsidproject', component: MyearsidprojectsComponent },
  { path: 'forms/myformsinworkflow', component: MyformsinworkflowComponent },
  { path: 'forms/oldFID', component: OldTemplateFormsComponent },
  { path: 'forms/oldSupplement', component: OldTemplateSupplementComponent },
  { path: 'manage/userprofile', component: UserprofileComponent },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
export const routingComponents = [AssetListComponent] 
