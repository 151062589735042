import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CompleterService } from 'ng2-completer';
import { FormReviewerService } from 'src/app/forms/FEEDServices/form-reviewer.service';
import { ApprovalDialogComponent } from 'src/app/Modals/approval-dialog/approval-dialog.component';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { FormAction } from 'src/app/Model/FormAction.model';
import { FormsLog } from 'src/app/Model/FormsLog.model';
import { SignOffsModel } from 'src/app/Model/Signoffs.model';
import { User } from 'src/app/Model/User.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-supplement-formreviewer',
  templateUrl: './supplement-formreviewer.component.html',
  styleUrls: ['./supplement-formreviewer.component.css']
})
export class SupplementFormreviewerComponent implements OnInit {
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;
  @Input() canSubmitForReview: boolean = false;
  @Input() canSendBack: boolean = false;
  @Input() canApprove: boolean = false;
  @Input() projectName?: string = "";
  objectId: string = '';
  provId: string = '';
  emailId: string = '';
  @Input() formId: number = 0;
  projectId: number = 0;
  versionName: number = 0;
  @Input() isOnHold: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() supplement: boolean = false;
  // Form Reviewer Fields
  formActionModel = new FormAction();
  selectedUser: any;
  filteredUsers: any = [];
  searchuser: any;
  userinfo: any = [];
  data: any;
  name: any = '';
  fromPage: boolean = true;
  formLink: any = '/ar/request/index/';
  role: string = '';
  ProjectId: number = 0;
  reviewerData: FormAction[] = [];
  rowData: any = [];
  UserName = this.projectservice.GetuserName();
  showDelete: boolean = false;
  editMode: boolean = localStorage.getItem('editMode') == 'true';
  AreMultipleAdmins: boolean = true;
  @Input() formsLogData: FormsLog[] = [];
  reviewerCount: boolean = false;
  @Input() formReviewData: any = null;
  dataService: any;
  searchStr: string = '';
  placeholder: string = '';
  searchData: Array<User> = [];
  href: string = "";
  bUrl: string = "";
  signOffData: SignOffsModel[] = [];
  formType: string = '';
  selectedUserObjectId: string = '';
  selectedUserProvId: string = '';
  constructor(public dialog: MatDialog,
    private projectservice: Go36projectService,
    private formReviewerService: FormReviewerService,
    private completerService: CompleterService, public utilityService: UtilityService,
    private route: ActivatedRoute, private router: Router,
    private notifyService: NotificationService,
    private go36ProjectService: Go36projectService) {
    this.formReviewerService.GetUserBySearch("Hasa").subscribe(res => {
      this.filteredUsers = res;
      this.dataService = completerService.local(
        this.searchData,
        'name',
        'name'
      );
      for (let i = 0; i < res.length; i++) {
        let p = new User(i, res[i].lastName + ' ' + res[i].firstName, res[i].objectId, res[i].provId);
        this.searchData.push(p);
      }

    })
    this.placeholder = "last name, first name";
  }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.route.queryParams
      .subscribe(params => {
        this.formId = params['supplementId'];
        this.projectId = params['projectId'];
        this.versionName = params['versionName'];
      });

    const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")));
    this.data = { goprojectmodel: JSON.parse(projectDetail) };
    this.getReviewerData();
    this.getLogData();
    this.getSignData();
    this.href = this.router.url;
    this.bUrl = window.location.origin;
  }

  // Form Reviewer Methods

  getReviewerData() {
    if (this.supplement) {
      this.formType = "EA_SUPPLEMENT";
    }
    else {
      this.formType = "EARescope";
    }
    this.formReviewerService.getReviewerData(this.formId, this.projectId, this.versionName, this.objectId, this.provId, this.formType).subscribe(res => {
      this.reviewerData = res;
      if (this.reviewerData?.length != 0) {
        this.reviewerCount = true;
      }
    });
  }

  getLogData() {
    if (this.supplement) {
      this.formType = "EA_SUPPLEMENT";
    }
    else {
      this.formType = "EARescope";
    }
    this.formReviewerService.getEALogData(this.formId, this.projectId, this.versionName, this.formType).subscribe(res => {
      this.formsLogData = res;
    });
  }

  getSignData() {
    if (this.supplement) {
      this.formType = "EA_SUPPLEMENT";
    }
    else {
      this.formType = "EARescope";
    }
    this.formReviewerService.getLookBackSignOffEASupplementData(this.formId, this.versionName, this.formType).subscribe(res => {
      this.signOffData = res;
    }
    );
  }

  UpdateSignOff(signOff: SignOffsModel) {
    signOff.modifiedBy = this.provId;
    const date = new Date(signOff.signoffDate!);

    if (signOff.signoffDate != null) {
      signOff.signoffDate = this.notifyService.ConvertDateBeforeSave(date);
    }
    this.formReviewerService.postLookBackSignOffData(signOff).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
    if (signOff.signoffDate != null) {
      signOff.signoffDate = this.notifyService.ConvertDateAfterSave(date);
    }
  }

  SearchUser(event: any) {
    // // let name = this.searchStr;
  }

  addUserToAccessList(event: any) {
    this.selectedUserObjectId = event.originalObject.objectId;
    this.selectedUserProvId = event.originalObject.provId;
    this.AddNewUserAccess(this.projectId, this.formId, this.versionName, this.objectId, this.provId, this.selectedUserObjectId, this.selectedUserProvId);
  }

  AddNewUserAccess(ProjectId: number, formId: number, versionId: number, objectId: string, provId: string, selectedUserObjectId: string, selectedUserProvId: string) {
    if (this.supplement) {
      this.formType = "EA_SUPPLEMENT";
    }
    else {
      this.formType = "EARescope";
    }
    this.formReviewerService.PostUseraccess(ProjectId, formId, versionId, objectId, provId, selectedUserObjectId, selectedUserProvId, this.formType).subscribe(res => {
      this.getReviewerData()
      this.getLogData();
    });
  }

  UpdateReviewer(id: number, action: string,reviewerItem:FormAction, comments?: string) {
    if (comments == null || comments == '') {
      this.notifyService.showError("Please enter comments before Form Action", "Warning");
    }
    else {
      this.formActionModel.projectId = this.projectId;
      this.formActionModel.formId = this.formId;
      this.formActionModel.versionId = this.versionName;
      if (this.supplement) {
        this.formType = "EA_SUPPLEMENT";
      }
      else {
        this.formType = "EARescope";
      }
      this.formActionModel.formType = this.formType;
      this.formActionModel.comments = comments;
      this.formActionModel.formActionId = id;
      this.formActionModel.action = action;
      this.formActionModel.objectId = this.objectId;
      this.formActionModel.provId = this.provId;
      this.formActionModel.currentUrl = this.href;
      this.formActionModel.currentBUrl = this.bUrl;
      this.formActionModel.projectName = this.projectName;

      this.formActionModel.submitterUserId = reviewerItem.submitterUserId;
      this.formActionModel.reviewerUserId = reviewerItem.reviewerUserId;

      this.formReviewerService.approvalForm(this.formActionModel).subscribe(res => {
        this.getLogData();
        this.getReviewerData();
      });
    }
  }

  DeleteReviewer(formAction: FormAction, opertionType: string) {
    formAction.action = opertionType;
    formAction.active = false;

    formAction.submitterComments = formAction.submitterComments || '';
    this.formReviewerService.postDeleteReviewerData(formAction).subscribe(res => {
      this.getLogData();
      this.getReviewerData();
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
  }

  deleteRow(index: any) {
    // // let usertoRemove = this.reviewerData[index];
    this.reviewerData.splice(index, 1);
  }

  addRow(event: any) {
    this.AddNewUserAccess(this.projectId, this.formId, this.versionName, this.objectId, this.provId, this.selectedUserObjectId, this.selectedUserProvId);
  }

  ShowSignoffsPopUp() {
    const message = 'Outline approvals needed for investment appropriation. Wet signatures, where still applicable,'
      + '\n' + 'can be done in signature & date boxes on final printout';
    const dialogData = new InfoDialogModel("Signoffs", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  addDefaultUserAccess() {
    // // let text = this.UserName.split(",");
    // // let lname = text[0].trim();
    // // let fname = text[1].split('[')[0].trim();
    if (localStorage.getItem("goProject")) {
      const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")))
      this.data = { goprojectmodel: JSON.parse(projectDetail) };
    }
    this.AddNewUserAccess(this.projectId, this.formId, this.versionName, this.objectId, this.provId, this.selectedUserObjectId, this.selectedUserProvId);
  }

  GetUserAccesslist() {
    if (this.data.goprojectmodel.ID == null) {
      this.ProjectId = this.data.goprojectmodel.ProjectId;
    }
    else {
      this.ProjectId = this.data.goprojectmodel.ID;
    }

    this.projectservice.getUserAccess(this.ProjectId).subscribe(res => {
      this.reviewerData = res;
    }
    );
  }

  OpenApprovalDialog(reviewerId: number, reviewerComments?: string) {
    if (this.supplement) {
      this.formType = "EA_SUPPLEMENT";
    }
    else {
      this.formType = "EARescope";
    }
    if (reviewerComments == null || reviewerComments == '') {
      this.notifyService.showError("Please enter comments before Form Action", "Warning");
    }
    else {
      this.dialog.open(ApprovalDialogComponent, {
        width: "600px",
        data: { projectId: this.projectId, projectName: this.projectName, formId: this.formId, versionId: this.versionName, formType: this.formType, reviewerId: reviewerId, reviewerComments },
      });
    }
  }
}