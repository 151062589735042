<div class="box-container" id="page-body">
    <h3 *ngIf=!editMode>New Reporting Unit</h3>
    <h3 *ngIf=editMode>Edit Reporting Unit</h3>

    <div class="row">
        <div class="col-md-6 col-md-offset-3">
            <hr>

            <form [formGroup]='formValue'>
                <div class="form-horizontal">                   
                    <div class="form-group">
                        <label class="control-label col-xs-4"  for="OpcoId"> Segment</label>
                        <div class="col-xs-4">
                            <select formControlName="OpcoId" class="form-control" (change) = "fillOpcoDDL($any($event.target).value)">
                                <option *ngFor="let opco of OpcoList" [value]="opco.id">
                                    {{opco.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-4" for="Asset_Name">Name</label>
                        <div class="col-xs-4">
                            <input  class="form-control col-xs-4" formControlName="name"  id="Asset_Name" type="text" value="">
                        </div>
                    </div>
                    <div class="form-group" *ngIf=editMode>
                        <label class="control-label col-xs-4">Active</label>
                        <div class="col-xs-4 padding-top-5">
                            <input  class="form-checkbox" formControlName="active"   type="checkbox"  >
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-xs-6 padding-0 text-left">
                        <a class="btn btn-default" routerLink="/manage/strategicBusinessUnits">Cancel</a>
                    </div>
                    <div class="col-xs-6 padding-0 text-right" *ngIf=!editMode>
                        <button type="submit" class="btn btn-primary" style="max-width:15em;color:white !important;" [disabled]="!this.formValue.valid" (click)="postDetails()" >Create Reporting Unit</button>
                    </div>
                    <div class="col-xs-6 padding-0 text-right" *ngIf=editMode >                       
                        <button type="submit"  [disabled]="!this.formValue.valid" style="max-width:15em; color: white !important;" (click)="updateAssetDetails()" class="btn btn-primary">Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>