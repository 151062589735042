import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Form } from 'src/app/Model/Form.model';
import { ArchiveDialogComponent } from 'src/app/Modals/archive-dialog/archive-dialog.component';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { OverviewService } from 'src/app/forms/FEEDServices/overview.service';
import { FeedNavService } from 'src/app/forms/FEEDServices/feed-nav.service';
import { ArPart3Service } from 'src/app/project/ea/service/ar-part3.service';
@Component({
  selector: 'app-oldform-dialog',
  templateUrl: './oldform-dialog.component.html',
  styleUrls: ['./oldform-dialog.component.css']
})
export class OldformDialogComponent implements OnInit {
  form = new Form();
  formId: number = 0;
  oldVersionId: number = 0;
  newVersionId: number = 0;
  formType: string = '';
  public version: EventEmitter<any> = new EventEmitter();
  constructor(public dialogRef: MatDialogRef<OldFormArchiveDialogModel>, private router: Router, @Inject(MAT_DIALOG_DATA) public data: OldFormArchiveDialogModel, private overviewService: OverviewService, private go36ProjectService: Go36projectService, private feedNavService: FeedNavService,
  private arpart3: ArPart3Service, public dialog: MatDialog,) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  onContinue(): void {
    this.dialogRef.close();
    this.dialog.open(ArchiveDialogComponent, {
      width: "600px",
      data: { projectId: this.data.projectId, formId: this.data.formId, oldVersionId: this.data.oldVersionId, formType:  this.data.formType},
    });
  }

}

export class OldFormArchiveDialogModel {

  constructor(public projectId: number, public formId: number, public oldVersionId: string, public formType: string) {
  }
}
