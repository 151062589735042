import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from 'src/app/Shared/utility.service';
import { ArProjectModel } from 'src/app/project/go36project/go36project.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';

@Component({
  selector: 'app-myearsidprojects',
  templateUrl: './myearsidprojects.component.html',
  styleUrls: ['./myearsidprojects.component.css']
})
export class MyearsidprojectsComponent implements OnInit {
  objectId: string = '';
  provId: string = '';
  emailId: string = '';
  formData: any;
  selectedVersion: number = 0;
  pID: number = 0;
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  projects: ArProjectModel[] = [];
  data: any;
  userAccessDetails: any;
  isHeaderFooterVisible: boolean = false;
  userRole: string = '';
  userAccessListByProjectId: any;
  userRoleForProject: string = '';
  projectlength: number = 0;

  constructor(private go36ProjectService: Go36projectService, private route: ActivatedRoute,
    private router: Router,private utilityServcie: UtilityService) { }

  ngOnInit(): void {
    this.objectId = this.utilityServcie.GetuserObjectId();
    this.provId = this.utilityServcie.GetuserProvisioningId();
    this.emailId = this.utilityServcie.GetuserEmailId();
    this.getEAAllRSIDProjects();
  }
  getEAAllRSIDProjects() {
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);

    if (this.userAccessDetails?.data?.isPowerUser) {
      this.userRole = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      this.userRole = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      this.userRole = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      this.userRole = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      this.userRole = "Leadership";
    }
    this.route.queryParams
      .subscribe(params => {
        this.go36ProjectService.getMyEARSIDProject(this.objectId, this.provId, this.userRole).subscribe((res) => {
          this.formData = res;
          this.projectlength = this.formData.length;
          this.onShowEntryDropdownChange({ first: 0, rows: this.projectlength });
        });
      })
  }

  onShowEntryDropdownChange(event) {
    if (event.rows === this.projectlength)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "All";
    else if (event.rows === 10)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "10";
    else if (event.rows === 20)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "20";
    else if (event.rows === 50)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "50";
    else if (event.rows === 100)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "100";
  }
  
  edit(goproject: any, action: string) {
    localStorage.setItem('goProject', JSON.stringify(goproject));
    localStorage.setItem('editMode', 'true')

    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);
    //this.userRole : string = '';
    // we can create the constants of the users role
    if (this.userAccessDetails?.data?.isPowerUser) {
      this.userRole = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      this.userRole = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      this.userRole = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      this.userRole = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      this.userRole = "Leadership";
    }

    this.go36ProjectService.GetUserAccessListByProjectId(goproject.ProjectId, this.objectId, this.provId).subscribe(res => {
      if (res) {
        this.userAccessListByProjectId = res;
        if (action == 'EditProject') {
          this.isHeaderFooterVisible = this.userAccessListByProjectId?.data?.canEditProject;
          if (this.isHeaderFooterVisible) {
            this.router.navigate(['/project/eaproject/update/'], { queryParams: { projectId: goproject.ProjectId, from: 'rsid' } });
          }
        }
        if (this.isHeaderFooterVisible === false) {
          localStorage.setItem('errorpage', 'true');
          this.router.navigate(["/errorpage/true"]);
        }
      }
    })
  }
}

