<h2 mat-dialog-title>
  Concurrence Confirmation
</h2>

<div mat-dialog-content>
  <!-- <p>{{name}}</p> -->
  <!-- <text type="text" class="form-control" [(ngModel)]="name"></text> -->
  <!-- <input class="form-control" [(ngModel)]="currentDate" 
        (dateChange)="DateChange()" [matDatepicker]="picker" 
        placeholder="Choose a date" (click)="picker.open()">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker hidden="true" aria-hidden="true" #picker ></mat-datepicker> -->
  <span>Form will be locked after you concur. Be sure you have the proper TOCA level to concur the project. If not,
    please use Endorse or Send Back buttons</span>
</div>

<div mat-dialog-actions style="float: right;">
  <button mat-button (click)="onDismiss()" class="btn btn-default"> Cancel </button> &nbsp;&nbsp;
  <!-- <button mat-raised-button class="btn btn-primary" [disabled]=buttonflag   style="color: white !important;" (click)="onApprove()"> Approve </button> -->
  <button mat-raised-button class="btn btn-primary" [disabled]=buttonflag style="color: white !important;"
    (click)="onContinue()"> Continue </button>
</div>