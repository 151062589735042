import { CapitalAndExpenditures } from "./CapitalAndExpenditures.model";
import { KeyEconomicMetrics } from "./KeyEconomicMetrics.model";
import { Milestone } from "./Milestone.model";
import { Ownership } from "./Ownership.model";
import { FeaturesImplemented } from "./features-implemented";

export class FormStatus {
  public static readonly FORM_DRAFT_STATE: number = 1;
  public static readonly FORM_REVIEW_STATE: number = 2;
  public static readonly FORM_REVISION_STATE: number = 3;
  public static readonly FORM_APPROVED_STATE: number = 5;
  public static readonly FORM_HOLD_STATE: number = 6;
}

export class Form {
  id: number = 0;
  active: boolean = true;
  createdBy?: string = '';
  modifiedBy?: string = '';
  createdOn: Date = new Date();
  modifiedOn: Date = new Date();
  order: number = 0;
  formDate: Date = new Date();
  projectId: number = 0;
  operatorName?: string = '';
  location?: string = '';
  originalId: number = 0;
  version: number = 0;
  versionName?: string = '';
  status: number = 0;
  assetMegaFriendlyNameId?: number = 0;
  assetMegaFriendlyNameCustom?: string = '';
  decision?: string = '';
  characteristics?: string = '';
  formType: string = '';
  isReadOnly: boolean = false;
  subSurfaceBasisOfDesignId?: number = 0;
  isSsbodrequired?: boolean = false;
  ssbodimportDate: Date = new Date();
  isOnHold: boolean = false;
  approvedOn: Date = new Date();
  Name?: string = '';
  BUName?: string = '';
  AssetMegaFriendlyName?: string = '';
  ownership: Ownership[] = [];
  milestone: Milestone[] = [];
  keyEconomicMetrics: KeyEconomicMetrics[] = [];
  capitalAndExpenditures: CapitalAndExpenditures[] = [];
  optOutBusinessCase: boolean = false;
  optOutAlternativeSelection: boolean = false;
  optOutBasisOfEvaluation: boolean = false;
  optOutLessonsLearned: boolean = false;
  isUpstream: boolean = true;
  eur?: string = '';
  sixPCurrentToFuture?: string = '';
  maxTotalProductionCurrentToFuture?: string = '';
  originalEur?: string = '';
  originalSixPCurrentToFuture?: string = '';
  originalMaxTotalProductionCurrentToFuture?: string = '';
  isSsbodAvailable: boolean = true;
  approvalStatus: boolean = false;
  supplementCount?: number = 0;
  IsOldVersionOfForms: boolean = true;
  isNewCEisApplicableForms: boolean = true;
  features?: FeaturesImplemented = new FeaturesImplemented();
  supplementName?: string = '';
  IsSampleForm?: boolean = false;
}
