<div class="fid-form">
    <div class="row sub-section">
        <div class="text-right">
            <a [ngClass]="{'disabledNoOfCasesDiv': isReadOnly || isOnHold}"
                style="margin-top: -10px;margin-right: 5px!important;" class="btn btn-sm btn-primary"
                *ngIf="!form.IsOldVersionOfForms" href="assets/Content/template/AR Data Input Template v6_New.xlsx">
                Download Blank Template
            </a>
            <a [ngClass]="{'disabledNoOfCasesDiv': isReadOnly || isOnHold}"
                style="margin-top: -10px;margin-right: 5px!important;" class="btn btn-sm btn-primary"
                *ngIf="form.IsOldVersionOfForms" href="assets/Content/template/AR Data Input Template v6.xlsx">
                Download Blank Template
            </a>
            <button [ngClass]="{'disabledNoOfCasesDiv': isOnHold}" type="button"
                style="margin-top: -10px!important;color: white !important;margin-right: 5px!important;max-width: initial!important;margin-bottom: 0!important;"
                class="btn btn-sm btn-primary" *ngIf="showDownload" (click)="DownloadFile()">
                Download Excel With Data
            </button>
            <div class="fileUpload btn btn-primary" [ngClass]="{'disabledNoOfCasesDiv': isReadOnly || isOnHold}">
                <span>Import 20 Line Input from Excel</span>
                <input type="file" class="form-control-file upload" accept=".xlsx" (change)="uploadFile($event)"
                    #file />
            </div>
        </div>
        <div class="col-xs-12 horizontal-overflow">
            <table class="table table-bordered table-condensed">
                <tbody>
                    <tr class="bg-table-default">
                        <td><span style="width: 280px; display: inline-block">Metric</span></td>
                        <td *ngIf="!form.IsOldVersionOfForms"><span
                                style="width: 75px; display: inline-block">Units</span></td>
                        <td><span style="width: 75px; display: inline-block">Price Scenario</span></td>
                        <td><span style="width: 110px; display: inline-block">Project Phase</span></td>
                        <td class="text-center" *ngFor="let year of getYears(data.endYear,data.startYear)">{{year}}</td>
                    </tr>
                    <tr class="table-header">
                        <td class="bg-table-default bold"
                            [attr.colspan]="getYears(data.endYear,data.startYear).length + 4">Mid Price Inputs: Point
                            Forward</td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.liquidsProductionLabel != null">
                        <td>{{data.midPricePhase4Plus.liquidsProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.liquidsProductionUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.liquidsProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.nonLngGasProductionLabel != null">
                        <td>{{data.midPricePhase4Plus.nonLngGasProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.nonLngGasProductionUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.nonLngGasProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.lngGasProductionLabel != null">
                        <td>{{data.midPricePhase4Plus.lngGasProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.lngGasProductionUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.lngGasProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.pOneReservesAddsLabel != null">
                        <td>{{data.midPricePhase4Plus.pOneReservesAddsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.pOneReservesAddsUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.pOneReservesAdds">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.pOneReservesEoyBalanceLabel != null">
                        <td>{{data.midPricePhase4Plus.pOneReservesEoyBalanceLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.pOneReservesEoyBalanceUnit}}
                        </td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.pOneReservesEoyBalance">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.sixPNonProductionRelatedChangesLabel != null">
                        <td>{{data.midPricePhase4Plus.sixPNonProductionRelatedChangesLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.midPricePhase4Plus.sixPNonProductionRelatedChangesUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.midPricePhase4Plus.sixPNonProductionRelatedChanges">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.explorationResourceAddsLabel != null">
                        <td>{{data.midPricePhase4Plus.explorationResourceAddsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.explorationResourceAddsUnit}}
                        </td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.explorationResourceAdds">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.sixPResourceLabel != null">
                        <td>{{data.midPricePhase4Plus.sixPResourceLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.sixPResourceUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.sixPResource">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.explorationCapitalAndExpendituresLabel != null">
                        <td>{{data.midPricePhase4Plus.explorationCapitalAndExpendituresLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.midPricePhase4Plus.explorationCapitalAndExpendituresUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.midPricePhase4Plus.explorationCapitalAndExpenditures">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.totalCapitalAndExpendituresLabel != null">
                        <td>{{data.midPricePhase4Plus.totalCapitalAndExpendituresLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.midPricePhase4Plus.totalCapitalAndExpendituresUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.midPricePhase4Plus.totalCapitalAndExpenditures">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.dpiDenominatorLabel != null">
                        <td>{{data.midPricePhase4Plus.dpiDenominatorLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.dpiDenominatorUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.dpiDenominator">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.revenueLabel != null">
                        <td>{{data.midPricePhase4Plus.revenueLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.revenueUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.revenue">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.nonFuelOpexLabel != null">
                        <td>{{data.midPricePhase4Plus.nonFuelOpexLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.nonFuelOpexUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.nonFuelOpex">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.fuelOpexLabel != null">
                        <td>{{data.midPricePhase4Plus.fuelOpexLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.fuelOpexUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.fuelOpex">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.afterTaxCashFlowLabel != null">
                        <td>{{data.midPricePhase4Plus.afterTaxCashFlowLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.afterTaxCashFlowUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.afterTaxCashFlow">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.earningsBeforeInterestLabel != null">
                        <td>{{data.midPricePhase4Plus.earningsBeforeInterestLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.earningsBeforeInterestUnit}}
                        </td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.earningsBeforeInterest">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.volumeLabel != null">
                        <td>{{data.midPricePhase4Plus.volumeLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.volumeUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.volume">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.capitalEmployedLabel != null">
                        <td>{{data.midPricePhase4Plus.capitalEmployedLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.capitalEmployedUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.capitalEmployed">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.atReportedEarningsLabel != null">
                        <td>{{data.midPricePhase4Plus.atReportedEarningsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.atReportedEarningsUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.atReportedEarnings">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr
                        *ngIf="showDownload && data.midPricePhase4Plus.depreciationDepletionAndAmortizationLabel != null">
                        <td>{{data.midPricePhase4Plus.depreciationDepletionAndAmortizationLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.midPricePhase4Plus.depreciationDepletionAndAmortizationUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.midPricePhase4Plus.depreciationDepletionAndAmortization">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.taxExpenseTotalLabel != null">
                        <td>{{data.midPricePhase4Plus.taxExpenseTotalLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase4Plus.taxExpenseTotalUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase4Plus.taxExpenseTotal">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase4Plus.workingCapitalEffectOnCashflowLabel != null">
                        <td>{{data.midPricePhase4Plus.workingCapitalEffectOnCashflowLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.midPricePhase4Plus.workingCapitalEffectOnCashflowUnit}}</td>
                        <td>Mid Price</td>
                        <td>Point Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.midPricePhase4Plus.workingCapitalEffectOnCashflow">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>

                    <tr class="table-header">
                        <td class="bg-table-default bold"
                            [attr.colspan]="getYears(data.endYear,data.startYear).length + 4">Mid Price Inputs: FEED
                            Forward</td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.liquidsProductionLabel != null">
                        <td>{{data.midPricePhase3.liquidsProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.liquidsProductionUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.liquidsProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.nonLngGasProductionLabel != null">
                        <td>{{data.midPricePhase3.nonLngGasProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.nonLngGasProductionUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.nonLngGasProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.lngGasProductionLabel != null">
                        <td>{{data.midPricePhase3.lngGasProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.lngGasProductionUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.lngGasProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.pOneReservesAddsLabel != null">
                        <td>{{data.midPricePhase3.pOneReservesAddsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.pOneReservesAddsUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.pOneReservesAdds">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.pOneReservesEoyBalanceLabel != null">
                        <td>{{data.midPricePhase3.pOneReservesEoyBalanceLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.pOneReservesEoyBalanceUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.pOneReservesEoyBalance">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.sixPNonProductionRelatedChangesLabel != null">
                        <td>{{data.midPricePhase3.sixPNonProductionRelatedChangesLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.midPricePhase3.sixPNonProductionRelatedChangesUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.midPricePhase3.sixPNonProductionRelatedChanges">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.explorationResourceAddsLabel != null">
                        <td>{{data.midPricePhase3.explorationResourceAddsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.explorationResourceAddsUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.explorationResourceAdds">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.sixPResourceLabel != null">
                        <td>{{data.midPricePhase3.sixPResourceLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.sixPResourceUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.sixPResource">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.explorationCapitalAndExpendituresLabel != null">
                        <td>{{data.midPricePhase3.explorationCapitalAndExpendituresLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.midPricePhase3.explorationCapitalAndExpendituresUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.midPricePhase3.explorationCapitalAndExpenditures">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.totalCapitalAndExpendituresLabel != null">
                        <td>{{data.midPricePhase3.totalCapitalAndExpendituresLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.totalCapitalAndExpendituresUnit}}
                        </td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.totalCapitalAndExpenditures">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.dpiDenominatorLabel != null">
                        <td>{{data.midPricePhase3.dpiDenominatorLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.dpiDenominatorUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.dpiDenominator">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.revenueLabel != null">
                        <td>{{data.midPricePhase3.revenueLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.revenueUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.revenue">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.nonFuelOpexLabel != null">
                        <td>{{data.midPricePhase3.nonFuelOpexLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.nonFuelOpexUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.nonFuelOpex">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.fuelOpexLabel != null">
                        <td>{{data.midPricePhase3.fuelOpexLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.fuelOpexUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.fuelOpex">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.afterTaxCashFlowLabel != null">
                        <td>{{data.midPricePhase3.afterTaxCashFlowLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.afterTaxCashFlowUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.afterTaxCashFlow">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.earningsBeforeInterestLabel != null">
                        <td>{{data.midPricePhase3.earningsBeforeInterestLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.earningsBeforeInterestUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.earningsBeforeInterest">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.volumeLabel != null">
                        <td>{{data.midPricePhase3.volumeLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.volumeUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.volume">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.capitalEmployedLabel != null">
                        <td>{{data.midPricePhase3.capitalEmployedLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.capitalEmployedUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.capitalEmployed">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.atReportedEarningsLabel != null">
                        <td>{{data.midPricePhase3.atReportedEarningsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.atReportedEarningsUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.atReportedEarnings">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload  && data.midPricePhase3.depreciationDepletionAndAmortizationLabel != null">
                        <td>{{data.midPricePhase3.depreciationDepletionAndAmortizationLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.midPricePhase3.depreciationDepletionAndAmortizationUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.midPricePhase3.depreciationDepletionAndAmortization">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.taxExpenseTotalLabel != null">
                        <td>{{data.midPricePhase3.taxExpenseTotalLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.taxExpenseTotalUnit}}</td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.taxExpenseTotal">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePhase3.workingCapitalEffectOnCashflowLabel != null">
                        <td>{{data.midPricePhase3.workingCapitalEffectOnCashflowLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePhase3.workingCapitalEffectOnCashflowUnit}}
                        </td>
                        <td>Mid Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.midPricePhase3.workingCapitalEffectOnCashflow">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>

                    <tr class="table-header">
                        <td class="bg-table-default bold"
                            [attr.colspan]="getYears(data.endYear,data.startYear).length + 4">Mid Price Inputs: Full
                            Cycle</td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.liquidsProductionLabel != null">
                        <td>{{data.midPricePrePhase3.liquidsProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.liquidsProductionUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.liquidsProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.nonLngGasProductionLabel != null">
                        <td>{{data.midPricePrePhase3.nonLngGasProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.nonLngGasProductionUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.nonLngGasProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.lngGasProductionLabel != null">
                        <td>{{data.midPricePrePhase3.lngGasProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.lngGasProductionUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.lngGasProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.pOneReservesAddsLabel != null">
                        <td>{{data.midPricePrePhase3.pOneReservesAddsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.pOneReservesAddsUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.pOneReservesAdds">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.pOneReservesEoyBalanceLabel != null">
                        <td>{{data.midPricePrePhase3.pOneReservesEoyBalanceLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.pOneReservesEoyBalanceUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.pOneReservesEoyBalance">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.sixPNonProductionRelatedChangesLabel != null">
                        <td>{{data.midPricePrePhase3.sixPNonProductionRelatedChangesLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.midPricePrePhase3.sixPNonProductionRelatedChangesUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center"
                            *ngFor="let item of data.midPricePrePhase3.sixPNonProductionRelatedChanges">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.explorationResourceAddsLabel != null">
                        <td>{{data.midPricePrePhase3.explorationResourceAddsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.explorationResourceAddsUnit}}
                        </td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.explorationResourceAdds">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.sixPResourceLabel != null">
                        <td>{{data.midPricePrePhase3.sixPResourceLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.sixPResourceUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.sixPResource">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.explorationCapitalAndExpendituresLabel != null">
                        <td>{{data.midPricePrePhase3.explorationCapitalAndExpendituresLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.midPricePrePhase3.explorationCapitalAndExpendituresUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center"
                            *ngFor="let item of data.midPricePrePhase3.explorationCapitalAndExpenditures">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.totalCapitalAndExpendituresLabel != null">
                        <td>{{data.midPricePrePhase3.totalCapitalAndExpendituresLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.totalCapitalAndExpendituresUnit}}
                        </td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.totalCapitalAndExpenditures">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.dpiDenominatorLabel != null">
                        <td>{{data.midPricePrePhase3.dpiDenominatorLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.dpiDenominatorUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.dpiDenominator">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.revenueLabel != null">
                        <td>{{data.midPricePrePhase3.revenueLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.revenueUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.revenue">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.nonFuelOpexLabel != null">
                        <td>{{data.midPricePrePhase3.nonFuelOpexLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.nonFuelOpexUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.nonFuelOpex">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.fuelOpexLabel != null">
                        <td>{{data.midPricePrePhase3.fuelOpexLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.fuelOpexUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.fuelOpex">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.afterTaxCashFlowLabel != null">
                        <td>{{data.midPricePrePhase3.afterTaxCashFlowLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.afterTaxCashFlowUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.afterTaxCashFlow">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.earningsBeforeInterestLabel != null">
                        <td>{{data.midPricePrePhase3.earningsBeforeInterestLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.earningsBeforeInterestUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.earningsBeforeInterest">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.volumeLabel != null">
                        <td>{{data.midPricePrePhase3.volumeLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.volumeUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.volume">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.capitalEmployedLabel != null">
                        <td>{{data.midPricePrePhase3.capitalEmployedLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.capitalEmployedUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.capitalEmployed">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.atReportedEarningsLabel != null">
                        <td>{{data.midPricePrePhase3.atReportedEarningsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.atReportedEarningsUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.atReportedEarnings">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr
                        *ngIf="showDownload && data.midPricePrePhase3.depreciationDepletionAndAmortizationLabel != null">
                        <td>{{data.midPricePrePhase3.depreciationDepletionAndAmortizationLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.midPricePrePhase3.depreciationDepletionAndAmortizationUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center"
                            *ngFor="let item of data.midPricePrePhase3.depreciationDepletionAndAmortization">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.taxExpenseTotalLabel != null">
                        <td>{{data.midPricePrePhase3.taxExpenseTotalLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.midPricePrePhase3.taxExpenseTotalUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center" *ngFor="let item of data.midPricePrePhase3.taxExpenseTotal">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.midPricePrePhase3.workingCapitalEffectOnCashflowLabel != null">
                        <td>{{data.midPricePrePhase3.workingCapitalEffectOnCashflowLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.midPricePrePhase3.workingCapitalEffectOnCashflowUnit}}</td>
                        <td>Mid Price</td>
                        <td>Full Cycle</td>
                        <td class="text-center"
                            *ngFor="let item of data.midPricePrePhase3.workingCapitalEffectOnCashflow">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>

                    <tr class="table-header">
                        <td class="bg-table-default bold"
                            [attr.colspan]="getYears(data.endYear,data.startYear).length + 4">High Price Inputs: FEED
                            Forward</td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.liquidsProductionLabel != null">
                        <td>{{data.highPriceFeedForward.liquidsProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.liquidsProductionUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.liquidsProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.nonLngGasProductionLabel != null">
                        <td>{{data.highPriceFeedForward.nonLngGasProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.nonLngGasProductionUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.nonLngGasProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.lngGasProductionLabel != null">
                        <td>{{data.highPriceFeedForward.lngGasProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.lngGasProductionUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.lngGasProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.pOneReservesAddsLabel != null">
                        <td>{{data.highPriceFeedForward.pOneReservesAddsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.pOneReservesAddsUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.pOneReservesAdds">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.pOneReservesEoyBalanceLabel != null">
                        <td>{{data.highPriceFeedForward.pOneReservesEoyBalanceLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.pOneReservesEoyBalanceUnit}}
                        </td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.pOneReservesEoyBalance">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.sixPNonProductionRelatedChangesLabel != null">
                        <td>{{data.highPriceFeedForward.sixPNonProductionRelatedChangesLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.highPriceFeedForward.sixPNonProductionRelatedChangesUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.highPriceFeedForward.sixPNonProductionRelatedChanges">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.explorationResourceAddsLabel != null">
                        <td>{{data.highPriceFeedForward.explorationResourceAddsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.explorationResourceAddsUnit}}
                        </td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.explorationResourceAdds">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.sixPResourceLabel != null">
                        <td>{{data.highPriceFeedForward.sixPResourceLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.sixPResourceUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.sixPResource">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr
                        *ngIf="showDownload && data.highPriceFeedForward.explorationCapitalAndExpendituresLabel != null">
                        <td>{{data.highPriceFeedForward.explorationCapitalAndExpendituresLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.highPriceFeedForward.explorationCapitalAndExpendituresUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.highPriceFeedForward.explorationCapitalAndExpenditures">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.totalCapitalAndExpendituresLabel != null">
                        <td>{{data.highPriceFeedForward.totalCapitalAndExpendituresLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.highPriceFeedForward.totalCapitalAndExpendituresUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.highPriceFeedForward.totalCapitalAndExpenditures">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.dpiDenominatorLabel != null">
                        <td>{{data.highPriceFeedForward.dpiDenominatorLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.dpiDenominatorUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.dpiDenominator">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.revenueLabel != null">
                        <td>{{data.highPriceFeedForward.revenueLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.revenueUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.revenue">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.nonFuelOpexLabel != null">
                        <td>{{data.highPriceFeedForward.nonFuelOpexLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.nonFuelOpexUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.nonFuelOpex">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.fuelOpexLabel != null">
                        <td>{{data.highPriceFeedForward.fuelOpexLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.fuelOpexUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.fuelOpex">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.afterTaxCashFlowLabel != null">
                        <td>{{data.highPriceFeedForward.afterTaxCashFlowLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.afterTaxCashFlowUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.afterTaxCashFlow">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.earningsBeforeInterestLabel != null">
                        <td>{{data.highPriceFeedForward.earningsBeforeInterestLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.earningsBeforeInterestUnit}}
                        </td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.earningsBeforeInterest">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.volumeLabel != null">
                        <td>{{data.highPriceFeedForward.volumeLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.volumeUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.volume">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.capitalEmployedLabel != null">
                        <td>{{data.highPriceFeedForward.capitalEmployedLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.capitalEmployedUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.capitalEmployed">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.atReportedEarningsLabel != null">
                        <td>{{data.highPriceFeedForward.atReportedEarningsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.atReportedEarningsUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.atReportedEarnings">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr
                        *ngIf="showDownload && data.highPriceFeedForward.depreciationDepletionAndAmortizationLabel != null">
                        <td>{{data.highPriceFeedForward.depreciationDepletionAndAmortizationLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.highPriceFeedForward.depreciationDepletionAndAmortizationUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.highPriceFeedForward.depreciationDepletionAndAmortization">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.taxExpenseTotalLabel != null">
                        <td>{{data.highPriceFeedForward.taxExpenseTotalLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.highPriceFeedForward.taxExpenseTotalUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.highPriceFeedForward.taxExpenseTotal">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.highPriceFeedForward.workingCapitalEffectOnCashflowLabel != null">
                        <td>{{data.highPriceFeedForward.workingCapitalEffectOnCashflowLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.highPriceFeedForward.workingCapitalEffectOnCashflowUnit}}</td>
                        <td>High Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.highPriceFeedForward.workingCapitalEffectOnCashflow">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>

                    <tr class="table-header">
                        <td class="bg-table-default bold"
                            [attr.colspan]="getYears(data.endYear,data.startYear).length + 4">Low Price Inputs: FEED
                            Forward</td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.liquidsProductionLabel != null">
                        <td>{{data.lowPriceFeedForward.liquidsProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.liquidsProductionUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.liquidsProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.nonLngGasProductionLabel != null">
                        <td>{{data.lowPriceFeedForward.nonLngGasProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.nonLngGasProductionUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.nonLngGasProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.lngGasProductionLabel != null">
                        <td>{{data.lowPriceFeedForward.lngGasProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.lngGasProductionUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.lngGasProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.pOneReservesAddsLabel != null">
                        <td>{{data.lowPriceFeedForward.pOneReservesAddsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.pOneReservesAddsUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.pOneReservesAdds">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.pOneReservesEoyBalanceLabel != null">
                        <td>{{data.lowPriceFeedForward.pOneReservesEoyBalanceLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.pOneReservesEoyBalanceUnit}}
                        </td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.pOneReservesEoyBalance">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.sixPNonProductionRelatedChangesLabel != null">
                        <td>{{data.lowPriceFeedForward.sixPNonProductionRelatedChangesLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.lowPriceFeedForward.sixPNonProductionRelatedChangesUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.lowPriceFeedForward.sixPNonProductionRelatedChanges">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.explorationResourceAddsLabel != null">
                        <td>{{data.lowPriceFeedForward.explorationResourceAddsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.explorationResourceAddsUnit}}
                        </td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.explorationResourceAdds">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.sixPResourceLabel != null">
                        <td>{{data.lowPriceFeedForward.sixPResourceLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.sixPResourceUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.sixPResource">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.explorationCapitalAndExpendituresLabel != null">
                        <td>{{data.lowPriceFeedForward.explorationCapitalAndExpendituresLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.lowPriceFeedForward.explorationCapitalAndExpendituresUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.lowPriceFeedForward.explorationCapitalAndExpenditures">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.totalCapitalAndExpendituresLabel != null">
                        <td>{{data.lowPriceFeedForward.totalCapitalAndExpendituresLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.lowPriceFeedForward.totalCapitalAndExpendituresUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.lowPriceFeedForward.totalCapitalAndExpenditures">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.dpiDenominatorLabel != null">
                        <td>{{data.lowPriceFeedForward.dpiDenominatorLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.dpiDenominatorUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.dpiDenominator">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.revenueLabel != null">
                        <td>{{data.lowPriceFeedForward.revenueLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.revenueUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.revenue">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.nonFuelOpexLabel != null">
                        <td>{{data.lowPriceFeedForward.nonFuelOpexLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.nonFuelOpexUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.nonFuelOpex">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.fuelOpexLabel != null">
                        <td>{{data.lowPriceFeedForward.fuelOpexLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.fuelOpexUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.fuelOpex">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.afterTaxCashFlowLabel != null">
                        <td>{{data.lowPriceFeedForward.afterTaxCashFlowLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.afterTaxCashFlowUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.afterTaxCashFlow">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.earningsBeforeInterestLabel != null">
                        <td>{{data.lowPriceFeedForward.earningsBeforeInterestLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.earningsBeforeInterestUnit}}
                        </td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.earningsBeforeInterest">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.volumeLabel != null">
                        <td>{{data.lowPriceFeedForward.volumeLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.volumeUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.volume">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.capitalEmployedLabel != null">
                        <td>{{data.lowPriceFeedForward.capitalEmployedLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.capitalEmployedUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.capitalEmployed">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.atReportedEarningsLabel != null">
                        <td>{{data.lowPriceFeedForward.atReportedEarningsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.atReportedEarningsUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.atReportedEarnings">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr
                        *ngIf="showDownload && data.lowPriceFeedForward.depreciationDepletionAndAmortizationLabel != null">
                        <td>{{data.lowPriceFeedForward.depreciationDepletionAndAmortizationLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.lowPriceFeedForward.depreciationDepletionAndAmortizationUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.lowPriceFeedForward.depreciationDepletionAndAmortization">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.taxExpenseTotalLabel != null">
                        <td>{{data.lowPriceFeedForward.taxExpenseTotalLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.lowPriceFeedForward.taxExpenseTotalUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.lowPriceFeedForward.taxExpenseTotal">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.lowPriceFeedForward.workingCapitalEffectOnCashflowLabel != null">
                        <td>{{data.lowPriceFeedForward.workingCapitalEffectOnCashflowLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.lowPriceFeedForward.workingCapitalEffectOnCashflowUnit}}</td>
                        <td>Low Price</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.lowPriceFeedForward.workingCapitalEffectOnCashflow">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>

                    <tr class="table-header">
                        <td class="bg-table-default bold"
                            [attr.colspan]="getYears(data.endYear,data.startYear).length + 4">Supplement Economics at Original AR cost and price guidance</td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.liquidsProductionLabel != null">
                        <td>{{data.alternateScenario.liquidsProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.liquidsProductionUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.liquidsProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.nonLngGasProductionLabel != null">
                        <td>{{data.alternateScenario.nonLngGasProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.nonLngGasProductionUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.nonLngGasProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.lngGasProductionLabel != null">
                        <td>{{data.alternateScenario.lngGasProductionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.lngGasProductionUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.lngGasProduction">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.pOneReservesAddsLabel != null">
                        <td>{{data.alternateScenario.pOneReservesAddsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.pOneReservesAddsUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.pOneReservesAdds">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.pOneReservesEoyBalanceLabel != null">
                        <td>{{data.alternateScenario.pOneReservesEoyBalanceLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.pOneReservesEoyBalanceUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.pOneReservesEoyBalance">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.sixPNonProductionRelatedChangesLabel != null">
                        <td>{{data.alternateScenario.sixPNonProductionRelatedChangesLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.alternateScenario.sixPNonProductionRelatedChangesUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.alternateScenario.sixPNonProductionRelatedChanges">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.explorationResourceAddsLabel != null">
                        <td>{{data.alternateScenario.explorationResourceAddsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.explorationResourceAddsUnit}}
                        </td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.explorationResourceAdds">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.sixPResourceLabel != null">
                        <td>{{data.alternateScenario.sixPResourceLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.sixPResourceUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.sixPResource">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.explorationCapitalAndExpendituresLabel != null">
                        <td>{{data.alternateScenario.explorationCapitalAndExpendituresLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.alternateScenario.explorationCapitalAndExpendituresUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.alternateScenario.explorationCapitalAndExpenditures">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.totalCapitalAndExpendituresLabel != null">
                        <td>{{data.alternateScenario.totalCapitalAndExpendituresLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.totalCapitalAndExpendituresUnit}}
                        </td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.totalCapitalAndExpenditures">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.dpiDenominatorLabel != null">
                        <td>{{data.alternateScenario.dpiDenominatorLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.dpiDenominatorUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.dpiDenominator">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.revenueLabel != null">
                        <td>{{data.alternateScenario.revenueLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.revenueUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.revenue">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.nonFuelOpexLabel != null">
                        <td>{{data.alternateScenario.nonFuelOpexLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.nonFuelOpexUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.nonFuelOpex">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.fuelOpexLabel != null">
                        <td>{{data.alternateScenario.fuelOpexLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.fuelOpexUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.fuelOpex">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.afterTaxCashFlowLabel != null">
                        <td>{{data.alternateScenario.afterTaxCashFlowLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.afterTaxCashFlowUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.afterTaxCashFlow">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.earningsBeforeInterestLabel != null">
                        <td>{{data.alternateScenario.earningsBeforeInterestLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.earningsBeforeInterestUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.earningsBeforeInterest">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.volumeLabel != null">
                        <td>{{data.alternateScenario.volumeLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.volumeUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.volume">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.capitalEmployedLabel != null">
                        <td>{{data.alternateScenario.capitalEmployedLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.capitalEmployedUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.capitalEmployed">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.atReportedEarningsLabel != null">
                        <td>{{data.alternateScenario.atReportedEarningsLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.atReportedEarningsUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.atReportedEarnings">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr
                        *ngIf="showDownload && data.alternateScenario.depreciationDepletionAndAmortizationLabel != null">
                        <td>{{data.alternateScenario.depreciationDepletionAndAmortizationLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.alternateScenario.depreciationDepletionAndAmortizationUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.alternateScenario.depreciationDepletionAndAmortization">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.taxExpenseTotalLabel != null">
                        <td>{{data.alternateScenario.taxExpenseTotalLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.alternateScenario.taxExpenseTotalUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center" *ngFor="let item of data.alternateScenario.taxExpenseTotal">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.alternateScenario.workingCapitalEffectOnCashflowLabel != null">
                        <td>{{data.alternateScenario.workingCapitalEffectOnCashflowLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">
                            {{data.alternateScenario.workingCapitalEffectOnCashflowUnit}}</td>
                        <td>Original AR mid guidance</td>
                        <td>FEED Forward</td>
                        <td class="text-center"
                            *ngFor="let item of data.alternateScenario.workingCapitalEffectOnCashflow">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>

                    <tr class="table-header">
                        <td class="bg-table-default bold"
                            [attr.colspan]="getYears(data.endYear,data.startYear).length + 4">Green House Gas Inputs
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && form.IsOldVersionOfForms && !form.features?.BeforeGreenHouseGas">
                        <td>{{data.greenHouseGas.emissionLabel}}</td>
                        <td *ngIf="!form.features?.BeforeGreenHouseGas && !form.IsOldVersionOfForms">
                            {{data.greenHouseGas.emissionUnit}}</td>
                        <td>Mid Price</td>
                        <td></td>
                        <td class="text-center" *ngFor="let item of data.greenHouseGas.emission">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr *ngIf="showDownload && data.greenHouseGas.emissionLabel != null && form.features?.BeforeGreenHouseGas">
                        <td>{{data.greenHouseGas.emissionLabel}}</td>
                        <td *ngIf="!form.features?.BeforeGreenHouseGas && !form.IsOldVersionOfForms">
                            {{data.greenHouseGas.emissionUnit}}</td>
                        <td>Mid Price</td>
                        <td></td>
                        <td class="text-center" *ngFor="let item of data.greenHouseGas.emission">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>                  
                    <tr *ngIf="showDownload && data.greenHouseGas.carbonPriceLabel != null">
                        <td>{{data.greenHouseGas.carbonPriceLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.greenHouseGas.carbonPriceUnit}}</td>
                        <td>Mid Price</td>
                        <td></td>
                        <td class="text-center" *ngFor="let item of data.greenHouseGas.carbonPrice">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr
                        *ngIf="showDownload && data.greenHouseGas.totalEmissionLabel != null && !form.IsOldVersionOfForms">
                        <td>{{data.greenHouseGas.totalEmissionLabel}}</td>
                        <td *ngIf="!form.IsOldVersionOfForms">{{data.greenHouseGas.totalEmissionUnit}}</td>
                        <td>Mid Price</td>
                        <td></td>
                        <td class="text-center" *ngFor="let item of data.greenHouseGas.totalEmission">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr
                        *ngIf="showDownload && data.greenHouseGas.methaneEmissionLabel != null && !form.IsOldVersionOfForms">
                        <td>{{data.greenHouseGas.methaneEmissionLabel}}</td>
                        <td *ngIf = "!form.features?.BeforeGreenHouseGas">{{data.greenHouseGas.methaneEmissionUnit}}</td>
                        <td>Mid Price</td>
                        <td></td>
                        <td class="text-center" *ngFor="let item of data.greenHouseGas.methaneEmission">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr
                        *ngIf="showDownload && data.greenHouseGas.flaringEmissionLabel != null && !form.IsOldVersionOfForms">
                        <td>{{data.greenHouseGas.flaringEmissionLabel}}</td>
                        <td *ngIf = "!form.features?.BeforeGreenHouseGas">{{data.greenHouseGas.flaringEmissionUnit}}</td>
                        <td>Mid Price</td>
                        <td></td>
                        <td class="text-center" *ngFor="let item of data.greenHouseGas.flaringEmission">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr
                        *ngIf="showDownload && data.greenHouseGas.scope1EmissionLabel != null && !form.IsOldVersionOfForms">
                        <td>{{data.greenHouseGas.scope1EmissionLabel}}</td>
                        <td *ngIf = "!form.features?.BeforeGreenHouseGas">{{data.greenHouseGas.scope1EmissionUnit}}</td>
                        <td>Mid Price</td>
                        <td></td>
                        <td class="text-center" *ngFor="let item of data.greenHouseGas.scope1Emission">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr
                        *ngIf="showDownload && data.greenHouseGas.scope2EmissionLabel != null && !form.IsOldVersionOfForms">
                        <td>{{data.greenHouseGas.scope2EmissionLabel}}</td>
                        <td *ngIf = "!form.features?.BeforeGreenHouseGas">{{data.greenHouseGas.scope2EmissionUnit}}</td>
                        <td>Mid Price</td>
                        <td></td>
                        <td class="text-center" *ngFor="let item of data.greenHouseGas.scope2Emission">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                    <tr
                        *ngIf="showDownload && data.greenHouseGas.boeForGHGCalculationsLabel != null && !form.IsOldVersionOfForms">
                        <td>{{data.greenHouseGas.boeForGHGCalculationsLabel}}</td>
                        <td *ngIf = "!form.features?.BeforeGreenHouseGas">{{data.greenHouseGas.boeForGHGCalculationsUnit}}</td>
                        <td>Mid Price</td>
                        <td></td>
                        <td class="text-center" *ngFor="let item of data.greenHouseGas.boeForGHGCalculations">
                            <input type="text" class="form-control line-input input-sm compact" readonly
                                [value]="item | decimalComma">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>