export class AssetModel {
    id:number=0;
    active:boolean=false;
    name:string='';   
    createdBy:string='';
    modifiedBy:string='';
    createdOn:Date = new Date();
    modifiedOn:Date = new Date();
}

export class StrategicBusinessUnit extends AssetModel {
    OperatingCompanyId:number =0;
}

export class BusinessUnit extends StrategicBusinessUnit {
    StrategicBusinessUnitId:number =0;
}

export class UserAccess 
{
    UserId:number = 0;
    Email:string='';  
    FirstName:string='';  
    LastName:string='';  
    RoleDescription : string= '';
    CreatedBy:string='';
    CreatedDate:Date = new Date();
    Active:boolean=false;
    RoleId : number = 0;
    OrgData :  UserOrgMapping[] = [];
    ProjectData : any[] = [];
}

export class UserOrgMapping
{
    OpcoId : number = 0;
    SbuId : number = 0;
    BuId : number = 0;
    OpcoName :string='';
    SbuName :string='';
    BuName :string='';
}

export interface UserAccess 
{
    UserId:number;
    UserCai:string;  
    FirstName:string;  
    LastName:string;  
    RoleDescription: string;
    CreatedBy:string;
    CreatedDate:Date;
    Active:boolean;
    RoleId : number;
    OrgData :  UserOrgMapping[];
    ProjectData : any[];
}

export interface UserOrgMapping
{
    OpcoId : number;
    SbuId : number;
    BuId : number;
    OpcoName :string;
    SbuName :string;
    BuName :string;
}