<div class="box-container">
    <div class="row" *ngIf="parentData == undefined">
        <div class="col">
            <h3> Subsurface Basis of Design (SSBOD)</h3>
            <div class="row text-center">
                <a class="no-underline" [routerLink]="['/forms/ssbodProject']">
                    <h5 style="padding: 0; display:inline; font-size: 14px;">
                        {{projectName}}
                    </h5>
                </a>
            </div>
            <h5 class="text-danger margin-0 padding-0" *ngIf="viewModel.IsReadOnly">(Previous Version #{{viewModel.Version}} - {{viewModel.VersionName}} - Read-Only)</h5>
        </div>
    </div>
    <div class="row save-button" *ngIf="parentData == undefined" style="z-index: 1100 !important;">
        <!-- <button type="button" style="float:right;color: white !important;" class="text-right btn btn-sm btn-success btn-dark" [disabled]="this.ssbodAccessList?.data?.isLocked">Save</button> -->
    </div>
    <div class="row tabbed tabbed-content-control centered">
        <div class="filter-bar tab-filter-bar font-gotham centered-xs">
            <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)" animationDuration="0ms" style='min-height:300px' [disableRipple]='true'
                disablepagination="true">

                <mat-tab label="Reference">
                    <ng-template matTabContent>
                        <div style="margin-top: 25px;">
                            <app-reference *ngIf="(parentData!=undefined && parentData.ssbodId!=null && viewModel.Id!=0) || (parentData==undefined && viewModel.Id!=0)" [type]="constReference" [formType]="(parentData!=undefined)?parentData.formType:undefined" [viewModel]="viewModel"  [isReadOnlyGlobalProjectLevel]='this.ssbodAccessList?.data?.isLocked'></app-reference>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Total">
                    <ng-template matTabContent>
                        <div style="margin-top: 25px;">
                            <app-total *ngIf="(parentData!=undefined && parentData.ssbodId!=null && viewModel.Id!=0) || (parentData==undefined && viewModel.Id!=0)" [type]="constTotal" [formType]="(parentData!=undefined)?parentData.formType:undefined" [viewModel]="viewModel"  [isReadOnlyGlobalProjectLevel]='this.ssbodAccessList?.data?.isLocked'></app-total>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Incremental">
                    <ng-template matTabContent>
                        <div style="margin-top: 25px;">
                            <app-incremental *ngIf="(parentData!=undefined && parentData.ssbodId!=null && viewModel.Id!=0) || (parentData==undefined && viewModel.Id!=0)" [type]="constIncremental" [formType]="(parentData!=undefined)?parentData.formType:undefined" [viewModel]="viewModel"  [isReadOnlyGlobalProjectLevel]='this.ssbodAccessList?.data?.isLocked'></app-incremental>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Forecast">
                    <ng-template matTabContent>
                        <div style="margin-top: 25px;">
                            <app-forecast *ngIf="(parentData!=undefined && parentData.ssbodId!=null && viewModel.Id!=0) || (parentData==undefined && viewModel.Id!=0)" [viewModel]="viewModel"  [isReadOnlyGlobalProjectLevel]='this.ssbodAccessList?.data?.isLocked'></app-forecast>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="MOC">
                    <ng-template matTabContent>
                        <div style="margin-top: 25px;">
                            <app-management-of-change *ngIf="(parentData!=undefined && parentData.ssbodId!=null && viewModel.Id!=0) || (parentData==undefined && viewModel.Id!=0)" [type]="constMoc" [formType]="(parentData!=undefined)?parentData.formType:undefined" [viewModel]="viewModel"  [isReadOnlyGlobalProjectLevel]='this.ssbodAccessList?.data?.isLocked'></app-management-of-change>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Lessons Learned">
                    <ng-template matTabContent>
                        <div style="margin-top: 25px;">
                            <app-ssbod-lessons-learned *ngIf="(parentData!=undefined && parentData.ssbodId!=null && viewModel.Id!=0) || (parentData==undefined && viewModel.Id!=0)" [type]="constLL" [formType]="(parentData!=undefined)?parentData.formType:undefined" [viewModel]="viewModel"  [isReadOnlyGlobalProjectLevel]='this.ssbodAccessList?.data?.isLocked'></app-ssbod-lessons-learned>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Attach SSBOD Form" *ngIf="parentData!=undefined" [disabled]="parentData.isReadOnly || parentData.isOnHold">
                    <ng-template matTabContent>
                        <div style="margin-top: 25px;">
                            <app-attach-ssbod-form *ngIf="parentData!=undefined" [parentData]="parentData" [type]="''" [viewModel]="viewModel" (changeSelectedTab)="changeSelectedTab($event)"  [isReadOnlyGlobalProjectLevel]='this.ssbodAccessList?.data?.isLocked'></app-attach-ssbod-form>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>                   
        </div>
    </div>
    <div class="row" *ngIf="parentData == undefined">
        <hr>
    </div>
    <div class="row" style="margin-top: 15px;" *ngIf="parentData == undefined">
        <div class="col-xs-12 padding-0">
            <div class="row">
                <div class="form-group col-sm-6 col-xs-12 text-left padding-0 ">
                    <button type="button"  style="color: white !important;" class="btn btn-sm btn-primary" [routerLink]="['/forms/ssbodProject']">Close</button>
                </div>
                <div class="form-group col-sm-6 col-xs-12 text-right padding-0">
                    <!-- <button type="button" style="color: white !important;" class="btn btn-sm btn-primary btn-dark" (click)="clickEventPDF()">PDF</button> -->
                    <a [href]="pdfUrl" class="btn btn-sm btn-primary btn-dark" target="_blank" style="color: white !important;margin-right: 0.5em;margin-bottom: 0.5em;">PDF</a>
                    <button type="button" style="color: white !important;" *ngIf="!viewModel.IsReadOnly" class="btn btn-sm btn-success btn-dark" (click)="OpenArchiveDialog()"  [disabled]="!this.ssbodAccessList?.data?.canArchive">Archive</button>
                </div>
            </div>
        </div>
    </div>       
</div>