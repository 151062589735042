<!-- <div *ngIf="designParameterViewModel != null"> -->
    <tr  style="display: flex;width: 100%;"class="table-header" *ngFor="let item of designParameterViewModel; trackBy: trackByFunction; let i = index">
        <!-- <td class="bg-table-computed-clear bold"></td> -->
        <td style="width:35%;" class="bg-table-computed-clear">{{item.Name}}</td>
        <div style="width: 65%;" *ngIf="item.Name.indexOf('Date') == -1">
            <td style="width:15%;" class="bg-table-computed-clear text-center">
                <input class="form-control" [disabled]="readOnly" [(ngModel)]="item.P10" (change)="onDesignSectionUpdate(item)"/>
            </td>
            <td style="width:15%;" class="bg-table-computed-clear text-center">
                <input class="form-control" [disabled]="readOnly" [(ngModel)]="item.P50"  (change)="onDesignSectionUpdate(item)"/>
            </td>
            <td style="width:15%;" class="bg-table-computed-clear text-center">
                <input class="form-control" [disabled]="readOnly" [(ngModel)]="item.P90"  (change)="onDesignSectionUpdate(item)"/>
            </td>
            <td style="width:20%;" class="bg-table-computed-clear text-center">
                <input class="form-control" [disabled]="selectedEvFormula != 3 && (readOnly || rowValue != 4)"  [(ngModel)]="item.ExpectedValue" (change)="onDesignSectionUpdate(item)"/>
            </td>
        </div>
        <div style="width: 65%;" *ngIf="item.Name.indexOf('Date') > -1">
            <td style="width:15%;" class="bg-table-computed-clear text-center">
                <input class="form-control" [disabled]="readOnly" [(ngModel)]="item.P10date" (dateChange)="onDesignSectionUpdate(item)" [matDatepicker]="pickerP10" (click)="pickerP10.open();utilityService.removeCDKAnouncer()">
                <mat-datepicker #pickerP10></mat-datepicker>
            </td>
            <td style="width:15%;" class="bg-table-computed-clear text-center">
                <input class="form-control" [disabled]="readOnly" [(ngModel)]="item.P50date" (dateChange)="onDesignSectionUpdate(item)" [matDatepicker]="pickerP50" (click)="pickerP50.open();utilityService.removeCDKAnouncer()">
                <mat-datepicker #pickerP50></mat-datepicker>
            </td>
            <td style="width:15%;" class="bg-table-computed-clear text-center">
                <input class="form-control" [disabled]="readOnly" [(ngModel)]="item.P90date" (dateChange)="onDesignSectionUpdate(item)" [matDatepicker]="pickerP90" (click)="pickerP90.open();utilityService.removeCDKAnouncer()">
                <mat-datepicker #pickerP90></mat-datepicker>
            </td>
            <td style="width:20%;" class="bg-table-computed-clear text-center">
                <input class="form-control" [disabled]="selectedEvFormula != 3 && (readOnly || rowValue != 4)" [(ngModel)]="item.ExpectedValueDate" (dateChange)="onDesignSectionUpdate(item)" [matDatepicker]="pickerEV" (click)="pickerEV.open();utilityService.removeCDKAnouncer()" disabled>
                <mat-datepicker #pickerEV></mat-datepicker>
            </td>
        </div>
    </tr>
<!-- </div> -->
