
<div class="box-container">
	<div class="row">
		<div class="col-xs-12">
			<hr />
			<div class="form-horizontal">
				<div>
					<div class="row">

						<div class="col-xs-12 padding-0">
							<!-- <input type="text" class="form-control col-xs-6" placeholder="last name, first name" data-bind="
									   enable: !IsReadOnly(), ko_autocomplete: { source: function (request,response) { getNames($parent,request,response) },
									   select: function (event,item) { addName($parent,event,item) } }" /> -->
							<ng2-completer [(ngModel)]="searchStr" ngDefaultControl
								[disabled]="Searchform || isOnHold || isReadOnly || !formReviewData?.data?.canSubmit || isReadOnlyGlobalProjectLevel"
								[datasource]="dataService" [placeholder]="placeholder" [minSearchLength]="0"
								(selected)="addUserToAccessList($event)" [autoMatch]="true">

							</ng2-completer>
						</div>
						<div class="col-xs-3">
							&nbsp;
						</div>

					</div>
					<div class="row" *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">
						<div *ngIf="name.errors?.['minlength']" class="alert alert-danger" role="alert">
							Name must be at least 4 characters long.
						</div>
					</div>
					<div class="col-xs-3">
						&nbsp;
					</div>

					<table class="table border-bottom table-striped table-responsive table-bordered">
						<tbody>
							<tr class="border-top border-bottom">
								<th></th>
								<th class="text-center">
									<div>Sender</div>
								</th>
								<th class="text-center">
									<div>Receiver</div>
								</th>
								<th class="text-center">
									<div>Latest Status</div>
								</th>
								<th class="text-center">
									<div>Modified Date</div>
								</th>
								<th class="text-center">
									<div>Latest Version</div>
								</th>
								<th class="text-center">
									<div>Comments</div>
								</th>
								<th class="text-center">Form Action</th>
							</tr>
						</tbody>
						<tbody>
							<tr *ngFor="let reviewerItem of reviewerData let i = index"
								class="border-top border-bottom table-header">
								<td [(ngModel)]="reviewerItem.id" hidden class="ml-10">{{reviewerItem.id}}</td>
								<td class="text-center col-xs-offset-0">
									<button type="button" style="color: white !important;" class="btn btn-sm btn-danger"
										(click)="DeleteReviewer(reviewerItem,'delete')" [disabled]="isOnHold || isReadOnly 
									|| isReadOnlyGlobalProjectLevel || reviewerItem.deleteFlag">
										X
									</button>
								</td>
								<td class="text-center col-xs-1">
									<span>{{reviewerItem.submitterName}}</span>
								</td>
								<td class="text-center col-xs-1">
									<span>{{reviewerItem.reviewerName}}</span>
								</td>
								<td class="text-center col-xs-1">

									<span></span>
									<span>{{reviewerItem.currentStatusName}}</span>

								</td>
								<td class="text-center col-xs-1">
									<span>{{reviewerItem.modifiedOn}}</span>
								</td>
								<td class="text-center col-xs-1">
									<span>{{reviewerItem.versionId}}</span>
								</td>
								<td [(ngModel)]="reviewerItem.submitterUserId" hidden>{{reviewerItem.submitterUserId}}</td>
								<td [(ngModel)]="reviewerItem.reviewerUserId" hidden>{{reviewerItem.reviewerUserId}}</td>
								<td class="text-center col-xs-2">

									<textarea class="form-control" rows="2" [disabled]="isOnHold || 
									isReadOnly || isReadOnlyGlobalProjectLevel 
									|| reviewerItem.commentFlag" [(ngModel)]="reviewerItem.comments">
									</textarea>
								</td>
								<td class="text-center">
									<button type="button" style="color: white !important;" [disabled]="isOnHold || isReadOnly || 
									isReadOnlyGlobalProjectLevel || reviewerItem.submitterFlag || !formReviewData?.data?.canSubmit"
										class="btn btn-sm btn-success"
										(click)="UpdateReviewer(reviewerItem.id,'forreview',reviewerItem,reviewerItem.comments)">
										For Review
									</button>
									<button type="button" style="color: white !important;"
										[disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel || reviewerItem.reviewerFlag"
										class="btn btn-sm btn-warning"
										(click)="UpdateReviewer(reviewerItem.id,'sendback',reviewerItem,reviewerItem.comments)">
										Send Back
									</button>
									<button *ngIf="!isOldVersionOfForms" type="button" style="color: white !important;"
										[disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel || reviewerItem.reviewerFlag"
										class="btn btn-sm btn-success"
										(click)="UpdateReviewer(reviewerItem.id,'endorsed',reviewerItem,reviewerItem.comments)">
										Endorse
									</button>
									<button type="button" style="color: white !important;"
										[disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel || reviewerItem.reviewerFlag || !formReviewData?.data?.canApprove"
										class="btn btn-sm btn-success"
										(click)="OpenApprovalDialog(reviewerItem.id,reviewerItem.comments)">
										Concur
									</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div *ngIf="reviewerCount==false" class="col-xs-12 padding-0 text-center">
						&nbsp;
						<b>No reviewers available. Please use the search bar to add reviewers.</b>
					</div>
				</div>
			</div>
		</div>
	</div>
	<hr />

	<div class="row">
		<div class="col-xs-12">
			<div class="form-horizontal">
				<div>
					<div class="row" style="text-align:center; display:block">
						Form Logs
					</div>
					<table class="table border-bottom table-striped table-responsive table-bordered">
						<tbody>
							<tr class="border-top border-bottom">
								<th class="text-center">
									<div>Action Performed</div>
								</th>
								<th class="text-center">
									<div>Sender</div>
								</th>
								<th class="text-center">
									<div>Receiver</div>
								</th>
								<th class="text-center">
									<div>Status From</div>
								</th>
								<th class="text-center">
									<div>Status To</div>
								</th>
								<th class="text-center">
									<div>Sender Comments</div>
								</th>
								<th class="text-center">
									<div>Receiver Comments</div>
								</th>
								<th class="text-center">
									<div>Version</div>
								</th>
								<th class="text-center">
									<div>Date</div>
								</th>
							</tr>
						</tbody>
						<tbody>
							<tr *ngFor="let formsLogItem of formsLogData let i = index"
								class="border-top border-bottom table-header">
								<td class="text-center col-xs-1">
									<span>{{formsLogItem.formAction}}</span>
								</td>
								<td class="text-center col-xs-1">
									<span>{{formsLogItem.submittedByUserName}}</span>
								</td>
								<td class="text-center col-xs-1">
									<span>{{formsLogItem.submittedToUserName}}</span>
								</td>
								<td class="text-center col-xs-1">
									<span>{{formsLogItem.oldStatusName}}</span>
								</td>
								<td class="text-center col-xs-1">
									<span>{{formsLogItem.currentStatusName}}</span>
								</td>
								<td class="text-center col-xs-3">
									<span>{{formsLogItem.submitterComments}}</span>
								</td>
								<td class="text-center col-xs-3">
									<span>{{formsLogItem.reviewerComments}}</span>
								</td>
								<td class="text-center col-xs-1">
									<span>{{formsLogItem.versionNumber}}</span>
								</td>
								<td class="text-center col-xs-1">
									<span>{{formsLogItem.createdOn}}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

			</div>

		</div>
		<div class="col-xs-3">
			&nbsp;
		</div>
	</div>
</div>
<div class="col-xs-12" *ngIf="!isOldVersionOfForms">
	<div class="col-xs-12 form-group">
		<strong>Signoffs </strong>
		<span class="glyphicon glyphicon-question-sign" (click)="ShowSignoffsPopUp()"></span>
	</div>
	<div class="col-xs-12">
		<table class="table table-bordered">
			<tbody>
				<tr class="bg-table-default table-header">
					<td></td>
					<td width="20%">Name</td>
					<td width="20%">Position</td>
					<td width="20%">Signature</td>
					<td width="20%">Date</td>
				</tr>
				<tr *ngFor="let signOffItem of signOffData let i = index">
					<td [(ngModel)]="signOffItem.id" hidden class="ml-10">{{signOffItem.id}}</td>
					<td [(ngModel)]="signOffItem.signoffName" hidden class="ml-10">{{signOffItem.signoffName}}</td>
					<td>{{signOffItem.signoffName}}</td>
					<td>
						<textarea type="text" rows="3"
							[disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" class="form-control"
							[(ngModel)]="signOffItem.name" (change)="UpdateSignOff(signOffItem)">
                    </textarea>
					</td>
					<td>
						<textarea type="text" rows="3"
							[disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" class="form-control"
							[(ngModel)]="signOffItem.position" (change)="UpdateSignOff(signOffItem)">
                    </textarea>
					</td>
					<td>
						<textarea type="text" rows="3"
							[disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" class="form-control"
							[(ngModel)]="signOffItem.signature" (change)="UpdateSignOff(signOffItem)">
                    </textarea>
					</td>
					<td class="form-date">
						<!-- <mat-form-field>
                        <input matInput [matDatepicker]="picker" placeholder="Choose a date" 
                        (click)="picker.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker class="style:display:none" #picker></mat-datepicker>
                      </mat-form-field>       -->
						<input class="form-control" [(ngModel)]="signOffItem.signoffDate"
							(dateChange)="UpdateSignOff(signOffItem)" [matDatepicker]="picker"
							[disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
							(click)="picker.open();utilityService.removeCDKAnouncer()">
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</td>
				</tr>
			</tbody>
		</table>

	</div>
</div>