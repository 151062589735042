import { Component, OnInit,AfterViewInit } from '@angular/core';
import { AssetService } from '../../service/asset.service';

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.css']
})
export class AssetListComponent implements OnInit,AfterViewInit {
  title = 'datatables';

  dtOptions: DataTables.Settings = {};
  assetData:any;
  formDataLength:number=0;
 
  constructor(private assetService: AssetService) { }
  ngAfterViewInit():void{
    // // $('#tblOperatingCompany').DataTable();
  }
 
  ngOnInit(): void {
    this.getAllAsset();
   
  this.dtOptions = {

    pagingType: 'full_numbers',

    pageLength: 2,

    processing: true

  };
  }
  clear(table: any) {
    table.clear();
}
  getAllAsset() {
    this.assetService.get("asset").subscribe(res => {
      this.assetData = res;
      this.formDataLength = this.assetData.length;
      this.onShowEntryDropdownChange({first: 0, rows: this.formDataLength});
    })
  }
  onShowEntryDropdownChange(event){
    if(event.rows === this.formDataLength) 
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "All";
    else if(event.rows === 10)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "10" ;
    else if(event.rows === 20)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML  = "20" ;
    else if(event.rows === 50)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "50" ;
    else if(event.rows === 100)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "100" ;
  }
}
