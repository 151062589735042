import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppsettingsService } from 'src/app/Shared/appsettings.service';

@Injectable({
  providedIn: 'root'
})
export class DataImportService {

  constructor(private http: HttpClient,
    private appsettings: AppsettingsService) { }

  UploadUrl = 'api/DataImport/PostDataImport';
  CopyDataImportUrl='api/DataImport/copyDataImport';
  GetDataImportUrl='api/DataImport/GetDataImport';
  getFormSettingsUrl = "api/form/GetFormConfiguration?formId=";
  //GetUrl = 'api/AsyncExcelImport/;
  DownloadUrl = "api/DataImport/Download?id=";
  uploadFile(formId: number, file: FormData, provId: string,isOld?:boolean) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UploadUrl + '?formId=' + formId + '&' + 'provId=' + provId + '&' + 'isOld=' + isOld, file);
  }
  CopyDataImport(formId: number, userName: string)
  {
    const params = new HttpParams()
        .append('formId', formId)
        .append('userName', userName);
        return this.http.post<any>(this.appsettings.apiBaseUrl + this.CopyDataImportUrl, '', {
          params: params
        });
    }
  GetDataImport(formId: number, userName: string)
  {
  const params = new HttpParams()
      .append('formId', formId);
      return this.http.post<any>(this.appsettings.apiBaseUrl + this.GetDataImportUrl, '', {
        params: params
      });
  }

  DownloadExcelFile(id: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.DownloadUrl + id, httpOptions);
  }
}
