import { Injectable } from '@angular/core';
import { NotificationService } from 'src/app/Shared/notification.service';
import { HttpClient,HttpParams } from '@angular/common/http';
import { AppsettingsService } from 'src/app/Shared/appsettings.service';
import { Milestone } from 'src/app/Model/Milestone.model';
import { CapitalAndExpenditures } from 'src/app/Model/CapitalAndExpenditures.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class OverviewService {
  SaveOverViewData = 'api/OverView/SaveOverViewData';
  // // GetUrl = 'api/OverView/GetOverViewData?formId=';
  // //  GetOwnerShipUrl = 'api/OverView/GetOwnershipsData?formId=';
  // // GetMilestonesUrl = 'api/OverView/GetMilestonesData?formId=';
  // // GetKeyEconomicMetricsUrl = 'api/OverView/GetKeyEconomicMetricsData?formId=';
  // // GetCapitalAndExpendituresUrl = 'api/OverView/GetCapitalAndExpendituresData?formId=';
  // //  GetProjectDataUrl = 'api/OverView/GetProjectData?projectId=';
  // // GetFormDataUrl = 'api/OverView/GetFormData?formId=';
  SaveFormDataUrl = 'api/OverView/SaveFormOverViewData';
  UpdateOwnershipUrl = 'api/OverView/SaveOwnershipData';
  UpdateMileStoneUrl = 'api/OverView/SaveMileStoneData';
  UpdateCapitalAndExpendituresUrl = 'api/OverView/SaveCEData';
  UpdateKeyMetricUrl = 'api/OverView/SaveKeyMetricData';
  createDefaultDataUrl = 'api/OverView/CreateOverViewData?formId=';
  SaveFormItemUrl = 'api/OverView/SaveFormData';
  UpdateFormItemUrl = 'api/OverView/UpdateFormData';
  UpdateRiskFormUrl = 'api/OverView/SaveFromRiskData';
  SaveGreenGasEcoCommentUrl = 'api/OverView/SaveGreenGasEcoComment';
  GetUrl = 'api/OverView/GetOverViewData?formId=';
  UpdateOperationalUrl = 'api/OverView/SaveOperational';
  SaveGOperationalCommentUrl = 'api/OverView/SaveOperationalComment';
  UpdateyEconomicMetricsDataUrl = 'api/OverView/UpdateyEconomicMetrics';
  SaveOverviewChartImagesUrl = 'api/OverView/SaveOverviewChartImages';
  UpdateOldFormMileStoneUrl = 'api/OverView/SaveOldMileStoneData';
  UpdateFormConfigurationUrl = 'api/OverView/UpdateFormConfiguration';
  GetFormConfigurationUrl = 'api/OverView/GetFormsConfigurationByFormId?formId=';
  UpdateOldCapitalAndExpendituresUrl = 'api/OverView/SaveOldCEData';
  UpdateOldKeyEconomicUrl = 'api/OverView/UpdateOldEconomicMetrics';
  DeleteOldFormCEUrl = 'api/OverView/DeleteOldCEData';
  CheckFeedFIDFormExistUrl = "api/OverView/CheckFeedFIDFormExist";

  constructor(private notifyService: NotificationService, private http: HttpClient,
    private appsettings: AppsettingsService) {
  }

  postOwnershipData(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateOwnershipUrl, formData);
  }

  postMileStoneData(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateMileStoneUrl, formData);
  }

  postkeyEconomicData(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateKeyMetricUrl, formData);
  }

  postOverViewData(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.SaveOverViewData, formData);
  }

  postCapitalAndExpenditures(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateCapitalAndExpendituresUrl, formData);
  }

  createDefaultData(formData: any) {
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.SaveFormDataUrl, formData);
  }

  saveFormData(formData: any) {
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.SaveFormItemUrl, formData);
  }

  updateFormRisk(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateRiskFormUrl, formData);
  }

  updateFormData(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateFormItemUrl, formData);
  }

  postGreenGasEcoComment(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.SaveGreenGasEcoCommentUrl, formData);
  }

  postOperationalComment(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.SaveGOperationalCommentUrl, formData);
  }

  postOperational(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateOperationalUrl, formData);
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("Data shown successfully !!", "tutsmake.com")
  }

  showToasterError() {
    this.notifyService.showError("Something is wrong", "tutsmake.com")
  }

  showToasterInfo() {
    this.notifyService.showInfo("This is info", "tutsmake.com")
  }

  showToasterWarning() {
    this.notifyService.showWarning("This is warning", "tutsmake.com")
  }

  postKeyEconomicMetricsData(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateyEconomicMetricsDataUrl, formData);
  }

  SaveOverviewChartImages(chartData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.SaveOverviewChartImagesUrl, chartData);
  }

 
  postOldFormMileStoneData(formData: any) {
    return this.http.post<Milestone[]>(this.appsettings.apiBaseUrl + this.UpdateOldFormMileStoneUrl, formData);
  }

  getFormConfigurationData(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetFormConfigurationUrl + formId);
  }

  updateFormConfiguration(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateFormConfigurationUrl, formData);
  }

  postOldCapitalAndExpenditures(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateOldCapitalAndExpendituresUrl, formData);
  }

  postOldKeyEconomic(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateOldKeyEconomicUrl, formData);
  }

  postDeleteOldFormCEData(formData: any) {
    return this.http.post<CapitalAndExpenditures[]>(this.appsettings.apiBaseUrl + this.DeleteOldFormCEUrl, formData);
  }

  checkFeedFIDFormExist(projectId: number, formType: string): Observable<any[]> {
    const params = new HttpParams()
      .append('projectId', projectId)
      .append('formType', formType)
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.CheckFeedFIDFormExistUrl, {
      params: params
    }).pipe(map((res: any) => {
      return res;
    }))
  }
}