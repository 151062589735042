import { Component } from '@angular/core';
import { Go36projectService } from '../service/go36project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from 'src/app/Shared/utility.service';
import { FormReviewerService } from 'src/app/forms/FEEDServices/form-reviewer.service';
import { CompleterService } from 'ng2-completer';
import { User, UserForAccessSecurity } from 'src/app/Model/User.model';

@Component({
  selector: 'app-useraccess',
  templateUrl: './useraccess.component.html',
  styleUrls: ['./useraccess.component.css']
})
export class UseraccessComponent {
  selectedUser: any;
  filteredUsers: any = [];
  searchuser: any;
  userinfo: any = [];
  data: any;
  //editMode:boolean = false;
  projectname: any = 'Test Project';
  name: any = '';
  fromPage: boolean = true;
  formLink: any = '/ar/request/index/';
  objectId: string = '';
  provId: string = '';
  emailId: string = '';
  role: string = '';
  ProjectId: number = 0;
  assetid: number = 0;
  CreatedBy: string = '';
  ModifiedBy: string = '';
  UserAccessList: any = [];
  rowData: any = [];
  UserName = this.projectservice.GetuserName();
  showDelete: boolean = false;
  editMode: boolean = localStorage.getItem('editMode') == 'true';
  AreMultipleAdmins: boolean = true;
  userAccessDetails: any;
  userRole: string = '';
  userAccessListByProjectId: any;
  isDisabled: boolean = false;
  dataService: any;
  searchData: Array<UserForAccessSecurity> = [];
  placeholder: string = '';
  selectedUserObjectId: string = '';
  selectedUserProvId: string = '';
  searchStr: string = '';
  constructor(private projectservice: Go36projectService, private router: Router, private completerService: CompleterService,
     private route: ActivatedRoute, public utilityService: UtilityService,private formReviewerService: FormReviewerService) {
      this.projectservice.GetUserBySearch("Hasa").subscribe(res => {
        this.filteredUsers = res;
        this.dataService = completerService.local(
          this.searchData,
          'name',
          'name'
        );
        for (let i = 0; i < res.length; i++) {
          let p = new UserForAccessSecurity(i, res[i].lastName + ' ' + res[i].firstName,res[i].firstName,res[i].lastName, res[i].objectId, res[i].provId, res[i].emailId, res[i].userId);
          this.searchData.push(p);
        }
      })
      this.placeholder = "last name, first name";
      }

  ngOnInit() {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")));
    this.data = { goprojectmodel: JSON.parse(projectDetail) };
    this.GetUserAccesslist();
    this.GetUserAccessDetails();
  }
  deleteRow(index: any) {
    let usertoRemove = this.UserAccessList[index];
    this.UserAccessList.splice(index, 1);
    this.RemoveUserAccess(usertoRemove.ObjectId,usertoRemove.ProvisioningId , usertoRemove.ProjectId, this.provId);
    this.checkadmin();
  }

  addRow(event: any) {
    let arr = {
      FirstName: event.originalObject.FirstName,
      Hidefromlist: false,
      LastName: event.originalObject.LastName,
      ProjectAdmin: false,
      ProjectOwner: false,
      ProjectMember: true,
      Readonly: false,
      ObjectId: event.originalObject.ObjectId,
      ProvisioningId: event.originalObject.ProvisioningId,
      EmailId: event.originalObject.EmailId,
      UserId: event.originalObject.UserId,
      ProjectId: this.data.goprojectmodel.ProjectId
    };

    this.UserAccessList.push(arr);

  //   this.AddNewUserAccess(event.originalObject.ObjectId, event.originalObject.ProvisioningId
  //     , this.data.goprojectmodel.ProjectId
  //     , this.data.goprojectmodel.AssetClassId
  //     , this.data.goprojectmodel.CreatedBy
  //     , this.data.goprojectmodel.CreatedBy
  //     , this.role)
 }

  GetUserAccesslist() {
    this.ProjectId = this.data.goprojectmodel.ProjectId;

    this.projectservice.getUserAccess(this.ProjectId).subscribe(res => {
      this.UserAccessList = res;
      if (this.UserAccessList.length >= 1)
        this.checkadmin();
    }
    );
  }

  addUserToAccessList(event: any) {
    let dup = this.FilterAccessList(event.originalObject.ObjectId, event.originalObject.ProvisioningId);
    if (dup.length < 1) {
      this.role = 'member';
      this.addRow(event);
      if (localStorage.getItem("goProject")) {
        const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")))
        this.data = { goprojectmodel: JSON.parse(projectDetail) };
      }
      if (this.data.goprojectmodel.ProjectId == null) {
        this.ProjectId = this.data.goprojectmodel.ProjectId;
      }
      else {
        this.ProjectId = this.data.goprojectmodel.ProjectId;
      }
      this.assetid = this.data.goprojectmodel.AssetClassId;
      this.CreatedBy = this.data.goprojectmodel.CreatedBy;
      this.ModifiedBy = this.data.goprojectmodel.CreatedBy;

      this.AddNewUserAccess(event.originalObject.ObjectId, event.originalObject.ProvisioningId, this.ProjectId, this.assetid, this.CreatedBy, this.ModifiedBy, this.role);
    }
    else {
    }
  }

  FilterAccessList(objectId: any, provId: any) {
    return this.UserAccessList.filter((object: any) => {
      return object['ObjectId'] === objectId && object['ProvisioningId'] === provId;
    });
  }

  onRoleChange(event: any, item:any) {
    let target = event.target;
    this.role = target.value;
    let index = this.UserAccessList.findIndex((x: any) => x["ObjectId"] === item.ObjectId);
    this.UserAccessList[index]["ProjectOwner"] = false;

    if (this.role == "admin") {
      this.UserAccessList[index]["ProjectAdmin"] = true;
      this.UserAccessList[index]["ProjectMember"] = false;
      this.UserAccessList[index]["Readonly"] = false;
    }
    else if (this.role == "member") {
      this.UserAccessList[index]["ProjectAdmin"] = false;
      this.UserAccessList[index]["ProjectMember"] = true;
      this.UserAccessList[index]["Readonly"] = false;
    }
    else if (this.role == "readonly") {
      this.UserAccessList[index]["ProjectAdmin"] = false;
      this.UserAccessList[index]["ProjectMember"] = false;
      this.UserAccessList[index]["Readonly"] = true;
    }
    this.checkadmin();
    if (this.data.goprojectmodel.ProjectId == null) {
      this.ProjectId = this.data.goprojectmodel.ProjectId;
    }
    else {
      this.ProjectId = this.data.goprojectmodel.ProjectId;
    }
    this.ModifiedBy = this.provId;
    this.UpdateUserAccess(item.ObjectId,item.ProvisioningId, this.role, this.ProjectId, this.ModifiedBy);
  }

  UpdateUserAccess(objectId: string,provId: string, role: string, ProjectId: number, modifiedby: string) {
     this.projectservice.UpdateUserAccess(objectId,provId, role, ProjectId, modifiedby);
  }

  RemoveUserAccess(objectId: string,provId: string, ProjectId: number, modifiedby: string) {
     this.projectservice.RemoveUserAccess(objectId,provId, ProjectId, modifiedby);
  }

  AddNewUserAccess(objectId: string, provId: string, ProjectId: number, assetid: number, CreatedBy: string, ModifiedBy: string, role: string) {
    this.projectservice.PostUseraccess(objectId, provId, ProjectId, assetid, CreatedBy, ModifiedBy, role).subscribe(res => {
      });
  }
  SetEditData(goproject: any) {
    localStorage.setItem("goProject", JSON.stringify(goproject));
    localStorage.setItem('editMode', 'true')
  }
  checkadmin() {
    let adminList = this.UserAccessList.filter((e: any) => e.ProjectAdmin == true);
    this.UserAccessList.map((x: any) => x.Disabled = false)
    if (adminList.length === 1) {
      let index = this.UserAccessList.findIndex((x: any) => x.ObjectId == adminList[0].ObjectId);
      this.UserAccessList[index].Disabled = true;
    }
  }

  GetUserAccessDetails() {
    if (!this.route.snapshot.queryParams['projectId']) {
      this.isDisabled = (!this.userAccessListByProjectId?.data?.canAddOrRemoveProjectMember && false);
    }

    this.route.queryParams
      .subscribe(params => {
        const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
        this.userAccessDetails = JSON.parse(userData);

        if (this.userAccessDetails?.data?.isPowerUser) {
          this.userRole = "Power User";
        }
        else if (this.userAccessDetails?.data?.isAdminUser) {
          this.userRole = "Administrator";
        }
        else if (this.userAccessDetails?.data?.isBasicMember) {
          this.userRole = "Basic Member";
        }
        else if (this.userAccessDetails?.data?.isConsultantUser) {
          this.userRole = "Consultant";
        }
        else if (this.userAccessDetails?.data?.isLeadershipUser) {
          this.userRole = "Leadership";
        }
  });
}

}
