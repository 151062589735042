import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ea',
  templateUrl: './ea.component.html',
  styleUrls: ['./ea.component.css']
})
export class EaComponent implements OnInit {  
  ngOnInit(): void {
    //This is not implemented
  }
}