import { ProductionForecastModel } from "./productionForecast.model";

export class ProductionForecastViewModel {
    ProjectName:string='';
    Date:Date= new Date;
    Phase:string='';
    ProjectDevelopmentStages: any[] = [];
    ProductionForecastData: ProductionForecastModel[] = [];
}
export interface ProductionForecastViewModel
{
    ProjectName:string;
    Date:Date;
    Phase:string;
    ProjectDevelopmentStages: any[];
    ProductionForecastData: ProductionForecastModel[];
}
