<h2 mat-dialog-title>
    Lessons Learned - Phases
    </h2>
    
    <div mat-dialog-content>
      <select class="form-control" (change)="onLLPhaseChange($event)" >
         <option *ngIf="!allPhasesCreated" value="0">Please Select Phase</option>
          <option *ngFor="let phase of LLPhasesDropdown" [value]="phase.value">{{phase.text}}</option>
      </select>
    </div>
    
    <div mat-dialog-actions style="float: right;">
      <button mat-button (click)="onDismiss()" class="btn btn-default"> Cancel </button> &nbsp;&nbsp;
      <button *ngIf="!allPhasesCreated" style="color:white!important" mat-raised-button class="btn btn-primary" (click)="onCreate()"> Create </button>
    </div>