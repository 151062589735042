<div class="fid-form font-gotham" style="background-color: white;">
    <div class="row sub-section" style="padding-left: 16px; margin-bottom: 15px;" *ngIf="form.formType == 'EPC'">
        <span>
            <mat-slide-toggle (change)="onChangeOptOut($event)" [checked]="form.optOutLessonsLearned"
                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                <b>Opt Out</b>
            </mat-slide-toggle>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowOptOutModal()"></span>
        </span>
    </div>
    <div class="row sub-section">
        <div class="col-sm-6 col-xs-12">
            <div class="row form-group">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">Project Name</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" readonly="readonly" id="projectName" name="projectName"
                        [(ngModel)]="projectData.projectName" />
                </div>
            </div>
            <div class="row form-group">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">Segment</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" readonly="readonly" id="opcoName" name="opcoName"
                        [(ngModel)]="projectData.opcoName" />
                </div>
            </div>
            <div class="row form-group">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">BU</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" readonly="readonly" id="buName" name="buName"
                        [(ngModel)]="projectData.buName" />
                </div>
            </div>
            <div class="row form-group" *ngIf="formCase != 'LLCreate' && formCase != 'LLOpen'">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">Operator Name</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" id="operatorName" name="operatorName"
                        (change)="UpdateFormData()" [(ngModel)]="form.operatorName"
                        [disabled]="isOnHold || readOnly || form.optOutLessonsLearned || isReadOnlyGlobalProjectLevel" />
                </div>
            </div>
            <div class="row form-group" *ngIf="formCase == 'LLCreate' || formCase == 'LLOpen'">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">Phase</label>
                </div>
                <div [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="col-sm-8 col-xs-12">
                    <select class="form-control" [(ngModel)]="selectedPhase" (change)="onLLPhaseChange($event)"
                        [disabled]="isReadOnlyGlobalProjectLevel">
                        <option *ngFor="let phase of LLPhaseData" [value]="phase.value">{{phase.text}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xs-12">
            <div class="row form-group">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">AR Type</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" readonly="readonly" id="formType" name="formType"
                        [(ngModel)]="ARType" />
                </div>
            </div>
            <div class="row form-group">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">RU</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" readonly="readonly" id="sbuName" name="sbuName"
                        [(ngModel)]="projectData.sbuName" />
                </div>
            </div>
            <div class="row form-group">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">Asset Class</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" readonly="readonly" id="assetName" name="assetName"
                        [(ngModel)]="projectData.assetName" />
                </div>
            </div>
            <div class="row form-group" *ngIf="formCase != 'LLCreate' && formCase != 'LLOpen'">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">Project Location</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" id="location" name="location" (change)="UpdateFormData()"
                        [(ngModel)]="form.location"
                        [disabled]="isOnHold || readOnly || form.optOutLessonsLearned || isReadOnlyGlobalProjectLevel" />
                </div>
            </div>
            <div class="row form-group" *ngIf="formCase == 'LLCreate' || formCase == 'LLOpen'">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">Version</label>
                </div>
                <div [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="col-sm-8 col-xs-12">
                    <select class="form-control" [(ngModel)]="versionName" (change)="onLLPhaseVersionChange($event)"
                        [disabled]="isReadOnlyGlobalProjectLevel">
                        <option *ngFor="let version of LLPhaseVersions" [value]="version.value">{{version.text}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="row sub-section" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || readOnly}">
        <div class="col-xs-12 form-group">
            <strong><span *ngIf="formCase != 'LLCreate' && formCase != 'LLOpen'">8.1 </span>Lessons Learned:</strong>
            <i> Describe key insights identified during work on this project </i>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowLessonLearnedModal()"></span>
        </div>
        <form [formGroup]="formLL" *ngIf="!form.optOutLessonsLearned">
            <ng-container formArrayName="lessonsLearned">
                <div *ngFor="let control of lessonsLearned.controls ;let i = index;">
                    <div [formGroupName]="i">
                        <div class="row form-group">
                            <div [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}"
                                class="col-sm-6 col-xs-12">
                                <span class="col-sm-4 col-xs-12">
                                    <span>{{i+1}}</span>
                                    . Lesson Learned :
                                </span>
                                <span [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}"
                                    class="col-sm-8 col-xs-12" style="text-align:left">
                                    <select formControlName="lessonLearnedCategoryId" class="form-control"
                                        (change)="onCategoryChange($event,i+1,control)"
                                        [disabled]="isReadOnlyGlobalProjectLevel">
                                        <option value="-1" selected>Please select category for lesson learned</option>
                                        <option *ngFor="let category of LLCategoryData" [value]="category.id">
                                            {{category.name}}</option>
                                    </select>
                                </span>
                            </div>

                            <div class="col-sm-6 col-xs-12"
                                [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <span class="col-sm-8 col-xs-12" style="text-align:left">
                                    <input formControlName="title" placeholder="Enter title for lesson learned"
                                        class="form-control" (blur)="onBlurTitleUpdate($event,i+1,'LessonLearned')"
                                        style="text-align:left;width:104%!important"
                                        [disabled]="isReadOnlyGlobalProjectLevel" />
                                </span>
                                <span class="col-sm-4 col-xs-12" style="text-align:right">
                                    <span [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}"
                                        class="glyphicon glyphicon-trash glyphicon-custom-trash"
                                        (click)="Delete(i+1,'LessonLearned')">
                                        <span class="delete-btn-txt-font">Delete</span>
                                    </span>
                                </span>
                            </div>

                        </div>
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': LLTypeData[i].naLessonLearned || isReadOnlyGlobalProjectLevel}">
                                    <ckeditor formControlName="description" #lessonLearned [editor]="Editor"
                                        [config]="config" id="lessonLearned" name="lessonLearned"
                                        (ready)="onReady($event,i+1,'LessonLearned')"></ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xs-12">
                    <button type="button" class="btn btn-sm btn-primary" style="color: white !important;"
                        [disabled]="isOnHold || readOnly || isReadOnlyGlobalProjectLevel"
                        (click)="addNewLL('LessonLearned')">Add </button>
                </div>
            </ng-container>
        </form>
    </div>
    <div class="row sub-section" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || readOnly}"
        *ngIf="form.formType !== 'EPC'">
        <div class="col-xs-12 form-group">
            <strong><span *ngIf="formCase != 'LLCreate' && formCase != 'LLOpen'">8.2 </span>Plan:</strong>
            <i> Include a sharing plan for the insights, the target Chevron organizations for receiving the information
                and approximate timing. Also, if available, include a summary of how any significant gap will be
                closed</i>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowPlanModal()"></span>
        </div>
        <div>
            <div>
                <div class="row form-group" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                    <div class="col-sm-12 col-xs-12">
                        <span class="col-sm-12 col-xs-12" style="text-align:right"
                            [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                            <span class="glyphicon glyphicon-trash glyphicon-custom-trash" (click)="Delete(1,'Plan')">
                                <span class="delete-btn-txt-font">Delete</span>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="row form-group" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                    <div class="col-sm-12">
                        <div></div>
                        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                            <ckeditor #plan [editor]="Editor"
                                [config]="config" id="plan" name="plan" [(ngModel)]="PlanTypeData.description"
                                (ready)="onReady($event,1,'Plan')"></ckeditor>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row sub-section" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || readOnly}"
        *ngIf="form.formType !== 'EPC' && form.formType !== 'LLE'">
        <div class="col-xs-12 form-group">
            <!-- <div class="col-xs-11" style="padding: 0"> -->
            <strong><span *ngIf="formCase != 'LLCreate' && formCase != 'LLOpen'">8.3 </span>Key Decisions:</strong>
            <i> Describe key decisions taken during work on this project phase</i>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowKeyDecisionsModal()"></span>
            <!-- </div> -->
            <div class="row pl-16" *ngIf="!isOldVersionOfForms">
                <!-- <input type="checkbox" [checked]="naKeyDecisionDisabled" [(ngModel)]="naKeyDecisionDisabled"
                (change)="NAKeyDecisionChange($event)" class="form-check-input"  [disabled]="isOnHold || readOnly || isReadOnlyGlobalProjectLevel">
                <label style="padding-left: 8px;"><b>N/A</b></label> -->
                <span>
                    <mat-slide-toggle (change)="NAKeyDecisionChange($event)" [checked]="naKeyDecisionDisabled"
                        [disabled]="isOnHold || readOnly || isReadOnlyGlobalProjectLevel">
                        <span class="app-font">N/A</span>
                    </mat-slide-toggle>
                </span>
            </div>
        </div>
        <form [formGroup]="formKD" *ngIf="!naKeyDecisionDisabled">
            <ng-container formArrayName="keyDecision">
                <div *ngFor="let control of keyDecision.controls ;let i = index;">
                    <div [formGroupName]="i">
                        <div class="row form-group">
                            <div class="col-sm-6 col-xs-12"
                                [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <span class="col-sm-4 col-xs-12">
                                    <span>{{i+1}}</span>
                                    . Decision :
                                </span>
                                <span class="col-sm-8 col-xs-12" style="text-align:left"
                                    [ngClass]="{'disabledNoOfCasesDiv': naKeyDecisionDisabled || isReadOnlyGlobalProjectLevel}">
                                    <input placeholder="Enter title for key decision" formControlName="title"
                                        style="text-align:left;width:104%!important" class="form-control"
                                        (blur)="onBlurTitleUpdate($event,i+1,'KeyDecision')" />
                                </span>
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <div class="col-sm-8 col-xs-12"></div>
                                <span class="col-sm-4 col-xs-12" style="text-align:right"
                                    [ngClass]="{'disabledNoOfCasesDiv': naKeyDecisionDisabled || isReadOnlyGlobalProjectLevel}">
                                    <span class="glyphicon glyphicon-trash glyphicon-custom-trash"
                                        (click)="Delete(i+1,'KeyDecision')">
                                        <span class="delete-btn-txt-font">Delete</span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': naKeyDecisionDisabled || isReadOnlyGlobalProjectLevel}">
                                    <ckeditor formControlName="description" #keyDecision [editor]="Editor"
                                        [config]="config" id="keyDecision" name="keyDecision"
                                        (ready)="onReady($event,i+1,'KeyDecision')"></ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xs-12"
                    [ngClass]="{'disabledNoOfCasesDiv': naKeyDecisionDisabled || isReadOnlyGlobalProjectLevel}">
                    <button type="button" class="btn btn-sm btn-primary" style="color: white !important;"
                        [disabled]="isOnHold || readOnly || isReadOnlyGlobalProjectLevel"
                        (click)="addNewLL('KeyDecision')">Add </button>
                </div>
            </ng-container>
        </form>
    </div>
</div>