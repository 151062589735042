import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppsettingsService } from 'src/app/Shared/appsettings.service';

@Injectable({
  providedIn: 'root'
})
export class FormsettingsService {
  getFormSettingsUrl = "api/form/GetFormConfiguration?formId=";
  getFormSettingsByVersionUrl = "api/form/GetFormConfigurationByVersion?originalId=";
  saveFormSettingsUrl =  'api/Form/UpdateFormConfiguration';
  constructor(private http: HttpClient,  private appsettings: AppsettingsService) { }
  baseurl = environment.apiBaseUrl;

  saveFormSettings(formSettings: any, formId: number) {
   return this.http.post<any>(this.appsettings.apiBaseUrl + this.saveFormSettingsUrl, formSettings);
  }

  // // saveFormSettings1(formSettings: any, formId: number) {
  // //   const params = new HttpParams()      
  // //     .append('formSettings', formSettings)
  // //     .append('formId', formId)
  // //   return this.http.post<any>(this.appsettings.apiBaseUrl + this.saveFormSettingsUrl, '', {
  // //     params: params
  // //   });
  // // }

  getFormSettings(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getFormSettingsUrl + formId);
  }
  getFormSettingsByVersion(originalId: number,version: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getFormSettingsByVersionUrl + originalId +'&version='+version);
  }
}