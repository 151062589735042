<h2 mat-dialog-title>
    Select SSBOD Version
    </h2>    
    <div mat-dialog-content>
      <select class="form-control" [(ngModel)]="selectedVersion" (change)="onArchivedVersionChange($event)">
         <option value="0" *ngIf="SsbodArchivedVersions.length == 0">Archived Form(s) not found!</option>
         <option *ngFor="let version of SsbodArchivedVersions" [value]="version.Version">#{{version.Version}} - {{version.VersionName}}</option>
      </select>
    </div>    
    <div mat-dialog-actions style="float: right;">
      <button mat-button (click)="onDismiss()" class="btn btn-default"> Cancel </button> &nbsp;&nbsp;
      <button mat-raised-button class="btn btn-primary" style="color:white!important" [disabled]="SsbodArchivedVersions.length == 0 || btnAttach != 'Attach'" (click)="onSsbodImport()"> {{btnAttach}} </button>
    </div>