import { Component, OnInit } from '@angular/core';
import { AssetService } from 'src/app/admin/service/asset.service';

@Component({
  selector: 'app-businessunit-list',
  templateUrl: './businessunit-list.component.html',
  styleUrls: ['./businessunit-list.component.css']
})
export class BusinessunitListComponent implements OnInit {
  Data: any;
  formDataLength:number=0;
   constructor( private apiService: AssetService) { }
 
   ngOnInit(): void {
     this.getAll();
   }
 
   getAll() {
     this.apiService.get("businessunit/GetBUList").subscribe(res => {
       this.Data = res;
       this.formDataLength = this.Data.length;
       this.onShowEntryDropdownChange({first: 0, rows: this.formDataLength});
     })
   }
   
onShowEntryDropdownChange(event){
  if(event.rows === this.formDataLength) 
  document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "All";
    else if(event.rows === 10)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "10" ;
    else if(event.rows === 20)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML  = "20" ;
    else if(event.rows === 50)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "50" ;
    else if(event.rows === 100)
    document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "100" ;
  
}
}
