<div class="fid-form ssbod">
    <div class="row">
        <div class="col-xs-12 col-sm-6 moc-title">
            <h3 class="text-left">Subsurface Basis of Design - MOC</h3>
            <span>Changes in Incremental Case Values <br /> between the Phase 3 SSTR <br />and the FID/GO-36 AR Submittal</span><br /><br />
            <span class="project-title bold"> Project: </span><span class="bold project-title">{{SsbodProjectName}}</span>
        </div>
        <div class="col-xs-12 col-sm-6">
            <div class="row form-group">
                <div class="col-xs-6">
                    <label class="control-label form-label">Project Team MOC Initiator</label>
                </div>
                <div class="col-xs-6">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.Initiator" (change)="SaveManagementOfChangeData()"/>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-xs-6">
                    <label class="control-label form-label">Affiliation</label>
                </div>
                <div class="col-xs-6">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.Affiliation" (change)="SaveManagementOfChangeData()"/>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-xs-6">
                    <label class="control-label form-label">Title</label>
                </div>
                <div class="col-xs-6">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.Title" (change)="SaveManagementOfChangeData()"/>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-xs-6">
                    <label class="control-label form-label">Date</label>
                </div>
                <div class="col-xs-6">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.Date" [matDatepicker]="pickerProjectDate" (click)="pickerProjectDate.open();utilityService.removeCDKAnouncer()" (dateChange)="SaveManagementOfChangeData()">
                    <mat-datepicker #pickerProjectDate></mat-datepicker>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-xs-6">
                    <label class="control-label form-label">Summary Comments</label>
                </div>
                <div class="col-xs-6">
                    <textarea class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.SummaryComments" (change)="SaveManagementOfChangeData()"></textarea>
                </div>
            </div>
        </div>
    </div>
    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
                <a><b>Instructions for filling out the MOC form</b></a>
            </mat-panel-title>
          </mat-expansion-panel-header>
            <div style="padding-left:0px;padding-right:0px;" class="col-xs-12">
            <table class="table table-bordered">
                <tbody>
                    <tr class="bg-pale-green table-header">
                        <td>
                            The Project Team completes the information in this form to document changes in Incremental Case EUR volumes and Wells costs between the Phase 3 SSTR and the FID/GO-36 AR Submittal.
                            <br />
                            <br />
                            This form is automatically saved for each version of the SSBOD.
                            <br />
                            <br />
                            Note that the “Archive” button is only used when the full SSBOD version is required “archived” or stored for future reference. The archived version of the SSBO can no longer be edited. Following an “Archive” of the SSBOD, the SSBOD version is automatically incremented together with the current form data. Further edits can be done on the new version.
                        </td>
                    </tr>
                    <tr class="bg-pale-green table-header">
                        <td>
                            <span class="bold underline">Changes < 10%</span>
                            <br />
                            <span class="bold">
                                If there are no Differences of > 10% (Project EUR values, Program Drilling & Completion Cost) in the Incremental CASE EUR volumes or Wells costs for p10, p50, p90, or expected value, then no SSBOD MOC Functional Authority Sign-off is required, prior to documenting the SSBOD in the FID/GO-36 Submittal.
                            </span>
                            <br />
                            <br />
                            - Send a copy of the SSBOD used for the FID/GO36 Submittal to the Subsurface Assurance (SSA) Ph3 SSTR Leader for storage into the  SSA Project folder for future reference.
                        </td>
                    </tr>
                    <tr class=" bg-pale-green table-header">
                        <td>
                            <span class="bold underline">Changes > 10%</span>
                            <br />
                            <span class="bold">
                                If there are Differences of >10% (Project EUR values, Program Drilling & Completion Cost)  in the Incremental Case EUR volumes or Wells costs for p10, p50, p90, or expected value, then those changes must have SSBOD MOC Functional Authority Sign-off prior to documenting the SSBOD in the FID/GO-36 AR Submittal. Functional Authority for EUR changes is the Region RM Director.
                            </span>
                            <br />
                            <br />
                            - If required, the Project Team is responsible for initiating the SSBOD MOC Functional Authority Sign-off process in time to be completed prior to documenting the SSBOD in the FID/GO-36 AR Submittal.
                            <br />
                            <br />
                            <ol>
                                <li>
                                    Project Team to send an email** to the appropriate Functional Authority(s) and the SSA Ph3 SSTR Leader and inform them that SSBOD MOC Functional Authority Sign-off process is being initiated. Project Team to provide the updated/post Ph3 SSTR SSBOD, including populated MOC sheet, and any required supporting information, to the appropriate Functional Authority(s) and the SSA Ph3 SSTR Leader.
                                </li>
                                <li>
                                    The SSA Ph3 SSTR Leader will assist the Functional Authority(s) as needed, to review the updated SSBOD and supporting materials, and engage the Project Team as needed for clarifications.
                                </li>
                                <li>
                                    Once the review of the MOC is completed and alignment gained on the updated SSBOD, the Functional Authority(s) will:                                    <ol type="a">
                                        <li>Complete their Sign-off sections below in this form.</li>
                                        <li>Send an email including the FID/GO-36 SSBOD with Sign-off section completed, to the SSA Ph3 SSTR Leader and the Project Team MOC Initiator.</li>
                                    </ol>
                                </li>
                                <li>
                                    The SSA Ph3 SSTR Leader will store the FID/GO-36 SSBOD with MOC sheet, supporting materials, and email(s) from the Functional Authority(s) into the SSA Project Folder for future reference.
                                </li>
                            </ol>
                            <br />
                            **The SSA Ph3 SSTR Leader will inform the following Capital Projects/CTC representatives when the initiating email from the Project Team (1) is sent, and when the final emails from the Functional Authority(s) (3) are sent: CPMS General Manager, area appropriate Director and Deputy Director, and CTC Design and Technical Safety Engineering Manager.
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
                <a><b>Edit notes on completing the form</b></a>
            </mat-panel-title>
          </mat-expansion-panel-header>
            <div style="padding-left:0px;padding-right:0px;" class="col-xs-12">
            <table class="table table-bordered bg-pale-green table-header">
                <tbody>
                    <tr>
                        <td>
                            <span class="bold">Project Team MOC Initiator:</span>
                            <ul>
                                <li>Project Team to complete  name of Project Team member that initiates the MOC.</li>
                            </ul>
                            <span class="bold">Affiliation:</span>
                            <ul>
                                <li>Project Team to complete organization.</li>
                            </ul>
                            <span class="bold">Title:</span>
                            <ul>
                                <li>Project Team to complete job title.</li>
                            </ul>
                            <span class="bold">Date:</span>
                            <ul>
                                <li>Project Team to complete date MOC was submitted to FA's.</li>
                            </ul>
                            <span class="bold">Summary Comments:</span>
                            <ul>
                                <li>Project Team to complete:  Comment on what is prompting the need for FA Sign-off, or comment that no significant changes have occurred and therefore no FA Sign-off is required.</li>
                            </ul>
                            <span class="bold">EUR volumne MOC Required:</span>
                            <ul>
                                <li>Project Team to complete: YES or NO.</li>
                            </ul>
                            <span class="bold">EUR Functional Authority Name:</span>
                            <ul>
                                <li>Project Team to complete appropriate OPCO RM GM name.</li>
                            </ul>
                            <span class="bold">Affiliation:</span>
                            <ul>
                                <li>Project Team to complete appropriate OPCO .</li>
                            </ul>
                            <span class="bold">Title: </span>
                            <ul>
                                <li>Project Team to complete job title of Functional Authority.</li>
                            </ul>
                            <span class="bold">Functional Authority Sign-Off:</span>
                            <ul>
                                <li>Functional Authority to complete: YES or NO.</li>
                            </ul>
                            <span class="bold">Date:</span>
                            <ul>
                                <li>Functional Authority to complete:  Date of sign-off.</li>
                            </ul>
                            <span class="bold">Functional Authority Comments:</span>
                            <ul>
                                <li>Functional Authority to complete:  Brief comments to reflect approval of the changes that required Sign-off.</li>
                            </ul>
                            <span class="bold">Wells costs MOC Required:</span>
                            <ul>
                                <li>Project Team to complete: YES or NO.</li>
                            </ul>
                            <span class="bold">Wells Functional Authority Name:</span>
                            <ul>
                                <li>Project Team to complete: Wells Functional Authority name.</li>
                            </ul>
                            <span class="bold">Affiliation:</span>
                            <ul>
                                <li>Project Team to complete organization.</li>
                            </ul>
                            <span class="bold">Title:</span>
                            <ul>
                                <li>Project Team to complete job title of Wells Functional Authority.</li>
                            </ul>
                            <span class="bold">Functional Authority Sign-Off:</span>
                            <ul>
                                <li>Functional Authority to complete: YES or NO.</li>
                            </ul>
                            <span class="bold">Date:</span>
                            <ul>
                                <li>Functional Authority to complete: Date of sign-off.</li>
                            </ul>
                            <span class="bold">Functional Authority Comments:</span>
                            <ul>
                                <li>Functional Authority to complete:  Brief comments to reflect approval of the changes that required Sign-off.</li>
                            </ul>
                            <span class="bold">Subsurface BoD Parameter – EUR volumes – 8/8ths Volumes –</span>
                            <ul>
                                <li>Document probabilistic distributions of key parameters that define the Subsurface Basis of Design for the project work scope.</li>
                            </ul>
                            <span class="bold">Subsurface BoD Parameter – Wells costs – 8/8ths Volumes –</span>
                            <ul>
                                <li>Document probabilistic distributions of key parameters that define the Subsurface Basis of Design for the project work scope.</li>
                            </ul>
                            <span class="bold">Project EUR: (Stock Tank Conditions) – MMBO –</span>
                            <ul>
                                <li>Includes crude oil and condensate.</li>
                            </ul>
                            <span class="bold">Project EUR: (Stock Tank Conditions) – BCF (As Sold) –</span>
                            <ul>
                                <li>Gas volumes should be reported "As Sold", as defined in the RAC Manual (section 2.1) and consistent with the handling of fuel and inert gas as per section 2.1.5.</li>
                            </ul>
                            <span class="bold">Program Drilling & Completion Cost, MM$ – Total (Unescalated) –</span>
                            <ul>
                                <li>Refer to the SSTR Guide and Scorecard for a description of Wells cost expectations.</li>
                            </ul>
                            <span class="bold">Program Drilling & Completion Cost, MM$  – Total (Escalated) –</span>
                            <ul>
                                <li>Refer to the SSTR Guide and Scorecard for a description of Wells cost expectations.</li>
                            </ul>
                            <span class="bold">Summary explanation for all EUR volume differences >10%.  Attach supporting documents if appropriate to explain changes.</span>
                            <ul>
                                <li>Project Team to complete:  Summary explanation and justification for the changes that prompted the MOC.</li>
                            </ul>
                            <span class="bold">Summary explanation for all Wells cost differences >10%.  Attach supporting documents if appropriate to explain changes.</span>
                            <ul>
                                <li>Project Team to complete:  Summary explanation and justification for the changes that prompted the MOC.</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <br />
    <div class="row">
        <div class="col-xs-12">
            <div class="row form-group">
                <div class="col-xs-3">
                    <label class="control-label form-label underline">EUR volume MOC Required</label>
                </div>
                <div class="col-xs-3">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.EurManagementOfChangeRequired" (change)="SaveManagementOfChangeData()"/>
                </div>
                <div class="col-xs-3">
                    <label class="control-label form-label underline">Wells costs MOC Required</label>
                </div>
                <div class="col-xs-3">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.DccManagementOfChangeRequired" (change)="SaveManagementOfChangeData()"/>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-xs-3">
                    <label class="control-label form-label">EUR Functional Authority Name</label>
                </div>
                <div class="col-xs-3">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.EurFunctionalAuthorityName" (change)="SaveManagementOfChangeData()"/>
                </div>
                <div class="col-xs-3">
                    <label class="control-label form-label">Wells Functional AuthorityName</label>
                </div>
                <div class="col-xs-3">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.DccFunctionalAuthorityName" (change)="SaveManagementOfChangeData()"/>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-xs-3">
                    <label class="control-label form-label">Affiliation</label>
                </div>
                <div class="col-xs-3">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.EurAffiliation" (change)="SaveManagementOfChangeData()"/>
                </div>
                <div class="col-xs-3">
                    <label class="control-label form-label">Affiliation</label>
                </div>
                <div class="col-xs-3">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.DccAffiliation" (change)="SaveManagementOfChangeData()"/>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-xs-3">
                    <label class="control-label form-label">Title</label>
                </div>
                <div class="col-xs-3">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.EurTitle" (change)="SaveManagementOfChangeData()"/>
                </div>
                <div class="col-xs-3">
                    <label class="control-label form-label">Title</label>
                </div>
                <div class="col-xs-3">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.DccTitle" (change)="SaveManagementOfChangeData()"/>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-xs-3">
                    <label class="control-label form-label">Functional Authority Sign-Off</label>
                </div>
                <div class="col-xs-3">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.EurAuthoritySignOff" (change)="SaveManagementOfChangeData()"/>
                </div>
                <div class="col-xs-3">
                    <label class="control-label form-label">Functional Authority Sign-Off</label>
                </div>
                <div class="col-xs-3">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.DccAuthoritySignOff" (change)="SaveManagementOfChangeData()"/>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-xs-3">
                    <label class="control-label form-label">Date</label>
                </div>
                <div class="col-xs-3">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.EurDate" [matDatepicker]="eurDate" (click)="eurDate.open();utilityService.removeCDKAnouncer()" (dateChange)="SaveManagementOfChangeData()">
                    <mat-datepicker #eurDate></mat-datepicker>
                </div>
                <div class="col-xs-3">
                    <label class="control-label form-label">Date</label>
                </div>
                <div class="col-xs-3">
                    <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.DccDate" [matDatepicker]="dccDate" (click)="dccDate.open();utilityService.removeCDKAnouncer()" (dateChange)="SaveManagementOfChangeData()">
                    <mat-datepicker #dccDate></mat-datepicker>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-xs-3">
                    <label class="control-label form-label">Functional Authority Comments</label>
                </div>
                <div class="col-xs-3">
                    <textarea class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.EurAuthorityComments" (change)="SaveManagementOfChangeData()"></textarea>
                </div>
                <div class="col-xs-3">
                    <label class="control-label form-label">Functional Authority Comments</label>
                </div>
                <div class="col-xs-3">
                    <textarea class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="managementOfChangeViewModel.DccAuthorityComments" (change)="SaveManagementOfChangeData()"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div class="row form-group">
                <div class="col-xs-3">
                    <label class="control-label form-label">EV Formula</label>
                </div>
                <div class="col-xs-3">
                    <select class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="selectedEvFormula" (change)="onEvFormulaChange($event)">
                        <option *ngFor="let formula of evFormulaViewModel" [value]="formula.Id">{{formula.Name}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div [ngClass]="{'col-xs-6': (formType != 'AR_LOOKBACK_PART5')? true:false, 
        'col-xs-12' : (formType === 'AR_LOOKBACK_PART5')? true:false}">
            <table class="table table-bordered" *ngIf="eurViewModel.length > 0 && eurCalculated.length > 0">
                <tbody>
                    <tr class="bg-table-default table-header">
                        <td colspan="6">
                            Subsurface Bod Parameter - EUR volumes
                        </td>
                    </tr>
                    <tr class="bg-table-default table-header">
                        <td colspan="2">8/8ths Volumes</td>
                        <td class="text-center">p10</td>
                        <td class="text-center">p50</td>
                        <td class="text-center">p90</td>
                        <td class="text-center">EV</td>
                    </tr>
                    <tr><td colspan="6">&nbsp;</td></tr>
                    <tr class="bg-table-default">
                        <td rowspan="3" valign="middle">{{eurViewModel[0][0].Phase}}</td>
                        <td colspan="5">Project EUR: (Stock Tank Conditions)</td>
                    </tr>
                    <tr *ngFor="let item of eurViewModel[0] let i = index">
                        <td class="bg-table-computed-clear" width="17%">{{item.Name}}</td>

                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P10" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P50" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P90" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="selectedEvFormula!= 3 || viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.ExpectedValue" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                    </tr>
                    <tr><td colspan="6">&nbsp;</td></tr>
                    <tr class="bg-table-default">
                        <td rowspan="3" valign="middle">{{eurViewModel[1][0].Phase}}</td>
                        <td colspan="5">Project EUR: (Stock Tank Conditions)</td>
                    </tr>
                    <tr *ngFor="let item of eurViewModel[1] let i = index">
                        <td class="bg-table-computed-clear" width="17%">{{item.Name}}</td>

                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P10" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P50" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P90" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="selectedEvFormula != 3 || viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.ExpectedValue" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                    </tr>
                    <tr><td colspan="6">&nbsp;</td></tr>
                    <tr class="bg-table-default">
                        <td rowspan="3" valign="middle">{{eurCalculated[0][0].Phase}}</td>
                        <td colspan="5">Project EUR: (Stock Tank Conditions)</td>
                    </tr>
                    <tr *ngFor="let item of eurCalculated[0] let i = index">
                        <td class="bg-table-computed-clear" width="17%">{{item.Name}}</td>

                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [style]="(item.P10 > MocCalculationLimitValue)?'color:red !important;':'color:black !important;'" [(ngModel)]="item.P10" disabled (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [style]="(item.P50 > MocCalculationLimitValue)?'color:red !important;':'color:black !important;'" [(ngModel)]="item.P50" disabled (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [style]="(item.P90 > MocCalculationLimitValue)?'color:red !important;':'color:black !important;'" [(ngModel)]="item.P90" disabled (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [style]="(item.ExpectedValue > MocCalculationLimitValue)?'color:red !important;':'color:black !important;'" [(ngModel)]="item.ExpectedValue" disabled (change)="onDesignSectionUpdate(item)"/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div [ngClass]="{'col-xs-6': (formType != 'AR_LOOKBACK_PART5')? true:false, 
        'col-xs-12' : (formType === 'AR_LOOKBACK_PART5')? true:false}">
            <table class="table table-bordered" *ngIf="dccViewModel.length > 0 && dccCalculated.length > 0">
                <tbody>
                    <tr class="bg-table-default table-header">
                        <td colspan="6">
                            Subsurface Bod Parameter - Wells costs
                        </td>
                    </tr>
                    <tr class="bg-table-default table-header">
                        <td colspan="2">8/8ths Volumes</td>
                        <td class="text-center">p10</td>
                        <td class="text-center">p50</td>
                        <td class="text-center">p90</td>
                        <td class="text-center">EV</td>
                    </tr>
                    <tr><td colspan="6">&nbsp;</td></tr>
                    <tr class="bg-table-default">
                        <td rowspan="3" valign="middle">{{dccViewModel[0][0].Phase}}</td>
                        <td colspan="5">Program Drilling & Completion Cost, MM$</td>
                    </tr>
                    <tr *ngFor="let item of dccViewModel[0] let i = index">
                        <td class="bg-table-computed-clear" width="17%">{{item.Name}}</td>

                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P10" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P50" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P90" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="selectedEvFormula != 3 || viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.ExpectedValue" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                    </tr>
                    <tr><td colspan="6">&nbsp;</td></tr>
                    <tr class="bg-table-default">
                        <td rowspan="3" valign="middle">{{dccViewModel[1][0].Phase}}</td>
                        <td colspan="5">Program Drilling & Completion Cost, MM$</td>
                    </tr>
                    <tr *ngFor="let item of dccViewModel[1] let i = index">
                        <td class="bg-table-computed-clear" width="17%">{{item.Name}}</td>

                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P10" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P50" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P90" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="selectedEvFormula != 3 || viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.ExpectedValue" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                    </tr>
                    <tr><td colspan="6">&nbsp;</td></tr>
                    <tr class="bg-table-default">
                        <td rowspan="3" valign="middle">{{dccCalculated[0][0].Phase}}</td>
                        <td colspan="5">Program Drilling & Completion Cost, MM$</td>
                    </tr>
                    <tr *ngFor="let item of dccCalculated[0] let i = index">
                        <td class="bg-table-computed-clear" width="17%">{{item.Name}}</td>

                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [style]="(item.P10 > MocCalculationLimitValue)?'color:red !important;':'color:black !important;'" [(ngModel)]="item.P10" disabled (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [style]="(item.P50 > MocCalculationLimitValue)?'color:red !important;':'color:black !important;'" [(ngModel)]="item.P50" disabled (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [style]="(item.P90 > MocCalculationLimitValue)?'color:red !important;':'color:black !important;'" [(ngModel)]="item.P90" disabled (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [style]="(item.ExpectedValue > MocCalculationLimitValue)?'color:red !important;':'color:black !important;'" [(ngModel)]="item.ExpectedValue" disabled (change)="onDesignSectionUpdate(item)"/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div [ngClass]="{'col-xs-6': (formType != 'AR_LOOKBACK_PART5')? true:false, 
        'col-xs-12' : (formType === 'AR_LOOKBACK_PART5')? true:false}">
            <table class="table table-bordered" *ngIf="mmtViewModel.length > 0 && mmtCalculated.length > 0">
                <tbody>
                    <tr class="bg-table-default table-header">
                        <td colspan="6">
                            Project Carbon Capture and Storage Volumes (MMT=Million Metric Tons)
                        </td>
                    </tr>
                    <tr class="bg-table-default table-header">
                        <td colspan="2">8/8ths Volumes</td>
                        <td class="text-center">p10</td>
                        <td class="text-center">p50</td>
                        <td class="text-center">p90</td>
                        <td class="text-center">EV</td>
                    </tr>
                    <tr><td colspan="6">&nbsp;</td></tr>
                    <tr class="bg-table-default">
                        <td rowspan="3" valign="middle">{{mmtViewModel[0][0].Phase}}</td>
                        <td colspan="5">Project Volumes (MMT=Million Metric Tons)</td>
                    </tr>
                    <tr *ngFor="let item of mmtViewModel[0] let i = index">
                        <td class="bg-table-computed-clear" width="17%">{{item.Name}}</td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P10" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P50" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P90" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="selectedEvFormula != 3 || viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.ExpectedValue" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                    </tr>
                    <tr><td colspan="6">&nbsp;</td></tr>
                    <tr class="bg-table-default">
                        <td rowspan="3" valign="middle">{{mmtViewModel[1][0].Phase}}</td>
                        <td colspan="5">Project Volumes (MMT=Million Metric Tons)</td>
                    </tr>
                    <tr *ngFor="let item of mmtViewModel[1] let i = index">
                        <td class="bg-table-computed-clear" width="17%">{{item.Name}}</td>

                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P10" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P50" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.P90" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [disabled]="selectedEvFormula != 3 || viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.ExpectedValue" (change)="onDesignSectionUpdate(item)"/>
                        </td>
                    </tr>
                    <tr><td colspan="6">&nbsp;</td></tr>
                    <tr class="bg-table-default">
                        <td rowspan="3" valign="middle">{{mmtCalculated[0][0].Phase}}</td>
                        <td colspan="5">Project Volumes (MMT=Million Metric Tons)</td>
                    </tr>
                    <tr *ngFor="let item of mmtCalculated[0] let i = index">
                        <td class="bg-table-computed-clear" width="17%">{{item.Name}}</td>

                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [style]="(item.P10 > MocCalculationLimitValue)?'color:red !important;':'color:black !important;'" [(ngModel)]="item.P10" disabled (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [style]="(item.P50 > MocCalculationLimitValue)?'color:red !important;':'color:black !important;'" [(ngModel)]="item.P50" disabled (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [style]="(item.P90 > MocCalculationLimitValue)?'color:red !important;':'color:black !important;'" [(ngModel)]="item.P90" disabled (change)="onDesignSectionUpdate(item)"/>
                        </td>
                        <td class="bg-table-computed-clear text-center">
                            <input class="form-control" [style]="(item.ExpectedValue > MocCalculationLimitValue)?'color:red !important;':'color:black !important;'" [(ngModel)]="item.ExpectedValue" disabled (change)="onDesignSectionUpdate(item)"/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div class="row">
                <div class="col-xs-6">
                    <div class="row">
                        <div class="col-xs-12 form-group">
                            <strong>Summary explanation for all EUR volume differences > 10%. Attach supporting documents if appropriate to explain changes.</strong>
                        </div>
                        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel}">
                            <span>
                                <ckeditor #eurCk [editor]="Editor" [config]="config" [(ngModel)]="managementOfChangeViewModel.EurDifferenceSummaryComments" id="eurCk" name="eurCk"
                                (ready)="onReady($event)"></ckeditor>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6">
                    <div class="row">
                        <div class="col-xs-12 form-group">
                            <strong>Summary explanation for all Wells cost differences > 10%. Attach supporting documents if appropriate to explain changes.</strong>
                        </div>
                        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel}">
                            <span>
                                <ckeditor #dccCk [editor]="Editor" [config]="config" [(ngModel)]="managementOfChangeViewModel.DccDifferenceSummaryComments" id="dccCk" name="dccCk"
                                (ready)="onReady($event)"></ckeditor>
                            </span>
                        </div>
                    </div>
                  <br>
                </div>
            </div>
        </div>
    </div>
</div>