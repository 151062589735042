import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { Form,FormStatus } from 'src/app/Model/Form.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { FeedNavService } from '../../FEEDServices/feed-nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ArchiveDialogComponent } from 'src/app/Modals/archive-dialog/archive-dialog.component';
import { FormReviewerService } from '../../FEEDServices/form-reviewer.service';
import { FormsLog } from 'src/app/Model/FormsLog.model';
import { environment } from 'src/environments/environment';
import { OldformDialogComponent } from 'src/app/Modals/alertoldform-dialog/oldform-dialog.component';
import { FormAction } from 'src/app/Model/FormAction.model';
import { AuthorizationService } from 'src/app/admin/service/authorization.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-feed-nav',
  templateUrl: './feed-nav.component.html',
  styleUrls: ['./feed-nav.component.css']
})

export class FeedNavComponent implements OnInit {
  isOnHold = false;
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  formId: number = 0;
  projectId: number = 0;
  form = new Form();
  projectName: string = '';
  myParam: string = '';
  formCase: string | null = '';
  versionName: number = 0;
  userAccessList: any;
  userAccessDetails: any;
  formTableFormId: number = 0;
  formsLogData: FormsLog[] = [];
  versionId: number = 0;
  baseUrl: any = environment.apiBaseUrl;
  pdfUrl: any = "";
  isPowerUser?:boolean = false;


  sendBackLockByFormState:boolean = false;
  forReviewLockByFormState:boolean = false;
  concurLockByFormState:boolean = false;


  constructor(private go36ProjectService: Go36projectService,
    private feednavService: FeedNavService, public dialog: MatDialog, private route: ActivatedRoute,
    private router: Router, private location: Location, private formReviewerService: FormReviewerService,
    private userRoleService: AuthorizationService, public utilityService: UtilityService) { }

  ngOnInit(): void {
    this.isPowerUser = this.userRoleService.isPowerUser();
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formCase = (this.route.snapshot.paramMap.get("formCase"));
    if (this.formCase == 'Create') {
      this.projectId = Number(this.route.snapshot.paramMap.get("projectId"));
      this.formId = Number(this.route.snapshot.paramMap.get("formId"));
      this.versionName = Number(this.route.snapshot.paramMap.get("versionName"));
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          this.projectId = Number(params['projectId']);
          this.formId = Number(params['formId']);
          this.versionName = Number(params['versionName']);
        });
    }
    this.getFEEDData();
  }

  setGetFormStatus(formStatus:number){
    if(formStatus == FormStatus.FORM_DRAFT_STATE || formStatus == FormStatus.FORM_REVISION_STATE){
      this.forReviewLockByFormState = false;
      this.sendBackLockByFormState = true;
      this.concurLockByFormState = true;
    }else if(formStatus == FormStatus.FORM_REVIEW_STATE){
      this.forReviewLockByFormState = true;
      this.sendBackLockByFormState = false;
      this.concurLockByFormState = false;
    }else{
      this.forReviewLockByFormState = false;
      this.sendBackLockByFormState = false;
      this.concurLockByFormState = false;
    }
  }

  getFEEDData() {
    this.feednavService.getData(this.formId, this.versionName).subscribe((res: Form) => {
      this.form = res;
      this.setGetFormStatus(this.form.status);

      this.formTableFormId = this.form.id;
      // //this.projectName = this.form.BUName + ' - ' + megaFriendName + ' ' + this.form.Name;
      this.projectName = this.form.BUName + ' - ' + ' ' + this.form.Name + ' - ' + '(version #' + this.form.version + ')';
      this.GetUserAccessList();
      this.pdfUrl = `${this.baseUrl}api/Overview/GetFeedPDF?projectId=${this.projectId}&formId=${this.formId}&version=${this.form.version}`;
    });
  }

  onChange(value: MatSlideToggleChange) {
    this.isOnHold = value.checked;
    this.form.id = this.formId;
    this.form.modifiedBy = this.provId;
    this.form.isOnHold = this.isOnHold;
    this.form.projectId = this.projectId;

    this.feednavService.postIsOnHoldValue(this.form).subscribe(res => {
      //update value in form reviewer log
      this.getLogData();
    });
  }

  getLogData() {
    this.formCase = (this.route.snapshot.paramMap.get("formCase"));
    if (this.formCase == 'Create') {
      this.formId = Number(this.route.snapshot.paramMap.get("formId"));
      this.projectId = Number(this.route.snapshot.paramMap.get("projectId"));
      this.versionId = Number(this.route.snapshot.paramMap.get('versionName'));
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          this.formId = params['formId'];
          if (this.projectId != 0) {
            this.projectId = params['projectId'];
          }
          this.versionId = params['versionName'];
        })
    }

    this.formReviewerService.getLogData(this.formId, this.projectId, this.versionId, this.form.formType).subscribe(res => {
      this.formsLogData = res;
      // // if (this.UserAccessList.length >= 1)
      // //   this.checkadmin();
    }
    );
  }

  ShowOnHoldModal() {
    const message = "When the HOLD button is activated, the form for this project will be placed on a “Hold” status. Fields on the forms cannot be edited while form is on “HOLD” status. Only users with administrator rights can put a form on HOLD or remove it."
      + "<br/>" + "You should use HOLD status when you want to keep the information entered in a form for future use. Otherwise, you may consider deleting the form";
    const dialogData = new InfoDialogModel("Hold", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  OpenArchiveDialog() {
    this.dialog.open(ArchiveDialogComponent, {
      width: "600px",
      data: { projectId: this.projectId, formId: this.formId, oldVersionId: this.versionName, formType: this.form.formType},
    });
  }

  performFormAction(action:string){
    let formActionModel = new FormAction();
    formActionModel.projectId = this.projectId;
    formActionModel.formId = this.formId;
    formActionModel.versionId = this.versionName;
    formActionModel.action = action;
    formActionModel.formType = this.form.formType;
    formActionModel.loggedInUser = this.provId;
    this.formReviewerService.processFormAction(formActionModel).subscribe(res => {
      //getting form data again on successful for-action
      this.getFEEDData();
    })
  }

  GetUserAccessList() {
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);
    let userRole: string = '';
    // we can create the constants of the users role
    if (this.userAccessDetails?.data?.isPowerUser) {
      userRole = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      userRole = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      userRole = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      userRole = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      userRole = "Leadership";
    }
    this.feednavService.GetUserAccessList(this.formTableFormId, this.objectId, this.provId).subscribe(res => {
      this.userAccessList = res;

      // Making Form Editable for Power User.
      if(this.isPowerUser && this.form.IsSampleForm){
        this.form.isReadOnly = false;
        this.userAccessList.data.isLocked = false;
      }
      

      if(!this.userAccessList?.data?.canViewProject){
        this.router.navigate(["errorpage"]);
      }
      console.log(this.userAccessList);
    });
  }
  // // ExportPDF(){
  // //   this.feednavService.GetFeedPDF(this.projectId,this.formId,this.versionName).subscribe((response)=>{
  // //   let file = new Blob([response], { type: 'application/pdf' });            
  // //   let fileURL = URL.createObjectURL(file);
  // //   window.open(fileURL);
  // // })
  // // }

  OpenOldFormArchiveDialog() {
    this.dialog.open(OldformDialogComponent, {
      width: "600px",
      data: { projectId: this.projectId, formId: this.formId, oldVersionId: this.versionName, formType: this.form.formType},
    });
  }
}
