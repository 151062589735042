<h2 mat-dialog-title>
    Archive Confirmation
  </h2>
  
  <div mat-dialog-content>
    <span>WARNING Due to fundamental differences between previous and current Section 1.4 tables, 
      conversion from current form was not automated. You should use data from the form being archived to manually input it in the Section 1.4 table in the new version.
      </span>
      <br><br/>
      <span>Charts are now standardized on FEED Forward data - please verify the converted charts.</span>
  </div>
  
  <div mat-dialog-actions style="float: right;">
    <button mat-button (click)="onDismiss()" class="btn btn-default"> Cancel </button> &nbsp;&nbsp;
    <button mat-raised-button class="btn btn-primary" style="color: white !important;"
      (click)="onContinue()"> Continue </button>
  </div>