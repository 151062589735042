<div class="row sub-section" >
    <div class="col-xs-12 form-group">
        <strong>Place Images below that support Scope of Work on Sheet</strong><br />
        (e.g. Production Profile, Facility Map, Reservoir Map with wells)
    </div>
    <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel}">
        <ckeditor #supportingImage [editor]="Editor" [config]="config"  id="supportingImage" name="supportingImage"
                [(ngModel)]="viewModel.SupportingImage"
                (ready)="onReady($event,'SupportingImage')"></ckeditor>
    </div>
 </div>
    


