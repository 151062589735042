import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';
import { DesignParameterViewModel } from '../../Model/designParameterView.model';
import { SsbodService } from '../../service/ssbod.service';

@Component({
  selector: 'app-design-parameter-row',
  templateUrl: './design-parameter-row.component.html',
  styleUrls: ['./design-parameter-row.component.css']
})

export class DesignParameterRowComponent implements OnInit {
  @Input() rowValue: number = 0;
  @Input() type: string = '';
  @Input() designParameterViewModel: DesignParameterViewModel[] = [];
  @Input() selectedEvFormula: any = 1;
  @Input() readOnly: boolean = false;
  @Input() formId: number = 0;
  emailId: string = '';
  provId: string = '';
  @Output() getExpectedValue = new EventEmitter;
  constructor(private ssbodService: SsbodService, public utilityService: UtilityService, 
    private route: ActivatedRoute, private go36ProjectService: Go36projectService, private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.provId = this.utilityService.GetuserProvisioningId();
    // // this.route.queryParams
    // // .subscribe(params => {
    // //   this.formId = params['formId'];
    // // });
  }
  onDesignSectionUpdate(item: DesignParameterViewModel) {
    item.ModifiedBy = this.provId;
    let resarr = this.ssbodService.checkPrecision([item], this.rowValue);
    item = resarr[0];
    const date10 = new Date(item.P10date!);
    const date50 = new Date(item.P50date!);
    const date90 = new Date(item.P90date!);
    const dateEv = new Date(item.ExpectedValueDate!);

    if (item.P10date != null) {
      item.P10date = this.notifyService.ConvertDateBeforeSave(date10);
    }
    if (item.P50date != null) {
      item.P50date = this.notifyService.ConvertDateBeforeSave(date50);
    }
    if (item.P90date != null) {
      item.P90date = this.notifyService.ConvertDateBeforeSave(date90);
    }
    if (item.ExpectedValueDate != null) {
      item.ExpectedValueDate = this.notifyService.ConvertDateBeforeSave(dateEv);
    }

    this.ssbodService.SaveDesignSectionData(this.formId, item).subscribe((res: any) => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
      this.ssbodService.GetDesignSection(this.formId, this.type).subscribe(res => {
        let resArr: any[] = [];
        res.forEach((element, index) => {
          resArr.push(this.ssbodService.checkPrecision(element, index));
        });       
        this.getExpectedValue.emit(resArr);
      });
    });

    if (item.P10date != null) {
      item.P10date = this.notifyService.ConvertDateAfterSave(date10);
    }
    if (item.P50date != null) {
      item.P50date = this.notifyService.ConvertDateAfterSave(date50);
    }
    if (item.P90date != null) {
      item.P90date = this.notifyService.ConvertDateAfterSave(date90);
    }
    if (item.ExpectedValueDate != null) {
      item.ExpectedValueDate = this.notifyService.ConvertDateAfterSave(dateEv);
    }
  }

  trackByFunction(item: any): any {
    return item.Name;
  }
}
