export class ProjectData {
    id: number = 0;
    assetClassId: number = 0;
    sbuId: number = 0;
    opcoId: number = 0;
    buId: number = 0;
    assetMegaFriendlyName?: string = '';
    specialProjectType?: number = 0;
    rsid?: string = '';
    isSsbodavailable: boolean = true;
    projectName: string = '';
    buName?: string = '';
    opcoName?: string = '';
    sbuName?: string = '';
    assetName?: string = '';
    createdBy?: string = '';
    createdOn: Date=new Date();
    modifiiedDate: Date=new Date();
    specialProjectTypes?: string = '';
    formId: number = 0;
    ownershipName?: string = '';
    workingInterest?: string = '';
    netRevenueInterest?: string = '';
    isOperator: boolean = true;
    OverviewId?: number = 0;
    

}