export class ViewFormModel{
    Id:number=0;
    ProjectId :number=0;
    ProjectName:string="";
    OriginalId:number=0;
    SupplementId:number=0;
    Location:string="";
    RequestType:string="";
    DocumentType:string="";
    Order:number=0;
    Version:number=0;
    Status:string="";
    CreatedBy:string=""
    ModifiedDateTime:Date=new Date;
    ApprovedDateTime:Date=new Date;
    Versions:[]=[];
    IsOnHold:number=0;
    FormType:string="";
    selectedVersion:number=0;
  }

  export class Versions{
    Id:number=0;
    Name:string="";

  }