<!-- <app-asset></app-asset> -->
<app-header  *ngIf="!errorPage"></app-header>
<main role="main" id="main-content" tabindex="-1">
  <app-loader></app-loader>
  <router-outlet>  
  </router-outlet>
  <!-- <app-errorpage (parentFunction)="parentFunction($event)" hidden></app-errorpage> -->
</main>
 <app-footer  *ngIf="!errorPage"></app-footer>
  
