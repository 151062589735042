<div class="box-container">
    <div class="row">
        <div class="col">
            <h3>Part 4 Lookback</h3>
            <a class="no-underline" [routerLink]="['/eaproject/eaviewform']" [queryParams]="{projectId: projectId}"
                [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly}">
                <h5 style="padding: 0; font-size: 14px;">
                    {{projectName}}</h5>
            </a>
            <h5 class="text-danger margin-0 padding-0"></h5>
            <h5 class="text-danger margin-0 padding-0" style="font-size: 14px !important;" *ngIf="Part4Header.isOnHold">
                Form is on Hold. No editing can be performed</h5>
        </div>
    </div>

    <div class="row" *ngIf="featuresImplemented.hasGo_36_EA_FormHoldFeature">
        <span [ngClass]="{'disabledNoOfCasesDiv': this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit }">
            <mat-slide-toggle (change)="onChange($event, Part4Header)" [checked]="Part4Header.isOnHold">
                <b class="app-font">On Hold</b>
            </mat-slide-toggle>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowOnHoldModal()"></span>
        </span>
    </div>

    <div class="row">
        <div class="tabbed tabbed-content-control tabbed-side-nav clearfix">
            <mat-sidenav-container>
                <mat-sidenav mode="side" class="sidenav" opened>
                    <mat-nav-list class=" nav-tabs font-gotham">
                        <a mat-list-item (click)="showSection(1)" style="color:white">
                            <strong [ngClass]="{active:showSection1}">1. Completion Summary</strong></a>
                        <a mat-list-item (click)="showSection(2)" style="color:white; margin-top: 10px;">
                            <strong [ngClass]="{active:showSection2}">2. Performance Summary</strong></a>
                        <a mat-list-item (click)="showSection(3)" style="color:white">
                            <strong [ngClass]="{active:showSection3}">3. Endorsement</strong></a>
                        <a mat-list-item (click)="showSection(4)" class="menu-item-color">
                            <strong [ngClass]="{active:showSection4}">4. Attachments</strong></a>
                        <a mat-list-item (click)="showSection(5)" class="menu-item-color">
                            <strong [ngClass]="{active:showSection5}">5. Operational Excellence Results</strong></a>
                        <a mat-list-item (click)="showSection(6)" class="menu-item-color" style="margin-top: 10px;">
                          <strong [ngClass]="{active:showSection6}">6. {{ featuresImplemented.isNewCEisApplicableForms ? "Capex": "C&E" }} Expenditure Results</strong></a>
                        <a mat-list-item (click)="showSection(7)" class="menu-item-color">
                            <strong [ngClass]="{active:showSection7}">7. Schedule Results</strong></a>
                        <a mat-list-item (click)="showSection(8)" class="menu-item-color">
                            <strong [ngClass]="{active:showSection8}">8. Commercial Agreement Results</strong></a>
                        <a mat-list-item (click)="showSection(9)" class="menu-item-color" style="margin-top: 10px;">
                            <strong [ngClass]="{active:showSection9}">9. Early Operational Results</strong></a>
                        <a mat-list-item (click)="showSection(10)" class="menu-item-color" style="margin-top: 10px;">
                            <strong [ngClass]="{active:showSection10}">10. Appraisal Well/Program Results</strong></a>
                        <a mat-list-item (click)="showSection(11)" class="menu-item-color" style="margin-top: 20px;">
                            <strong [ngClass]="{active:showSection11}">11. Decision Executive, DRB & Project Manager
                            </strong></a>
                        <a mat-list-item (click)="showSection(12)" class="menu-item-color" style="margin-top: 10px;" *ngIf="featuresImplemented.hasWorkFlowProcessFeature">
                            <strong [ngClass]="{active:showSection12}">12. Form Reviewer </strong></a>
                    </mat-nav-list>
                </mat-sidenav>
                <div class="row" style="margin-left:2rem">
                    <!-- --------------------Section 1-------------------------------->
                    <div *ngIf="showSection1" class="tab-content tabs nav nav-tabs font-gotham">
                        <div class="content-container">
                            <div class="row">
                                <div class="col-xs-12">
                                    <hr />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>1. Completion Lookback</strong>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1a. Segment: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.opcoName" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1b. RU: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.sbuName" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1c. BU: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.buName" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1d. Asset Class: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.assetName" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1e. Project Location:</label>
                                    <input type="text" class="form-control"
                                        (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                        [(ngModel)]="Part4Header.projectLocation"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1f. Date (Part 4):</label>
                                    <input type="text" name="txtDate" [(ngModel)]="Part4Header.ardate"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                        (dateChange)=" UpdatePart4LookBackHeaderDates(Part4Header)" class="form-control"
                                        [matDatepicker]="picker5" (click)="picker5.open()" />
                                    <mat-datepicker #picker5></mat-datepicker>

                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1g. AR Date / Number:</label>
                                    <input type="text" name="txtDate" class="form-control"
                                        [(ngModel)]="Part4Header.originalDate"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                        (dateChange)=" UpdatePart4LookBackHeaderDates(Part4Header)" class="form-control"
                                        [matDatepicker]="picker6" (click)="picker6.open()" />
                                    <mat-datepicker #picker6></mat-datepicker>
                                </div>
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1h.Special Project Type: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.SpecialProjectTypeName" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-3">
                                    <label class="form-label">1i. Facility (megafriendly) Name: </label>
                                    <input type="text" class="form-control" readonly="readonly"
                                        [(ngModel)]="projectData.assetMegaFriendlyName" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    1j. Lookback Type
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xs-6">
                                    <div class="row">
                                        <div class="col-xs-1">
                                            <input type="checkbox" class="form-check-input" id="chkLevel1a"
                                                [checked]="Part4Header.lookbackType1"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'lookbackType1')" />
                                        </div>
                                        <div class="col-xs-11">
                                            <label class="form-check-label" for="chkLevel1a"> <strong>Level
                                                    1</strong>
                                                Total Chevron Commitment &ge;&nbsp;$500 MM</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-1">
                                            <input type="checkbox" class="form-check-input" id="chkLevel2a"
                                                [checked]="Part4Header.lookbackType3"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'lookbackType2')" />
                                        </div>
                                        <div class="col-xs-11">
                                            <label class="form-check-label" for="chkLevel2a"> <strong>Level
                                                    2</strong>
                                                Total Chevron Commitment &ge;&nbsp;$75 MM Level 1 not
                                                required</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-xs-6">
                                    <div class="row">
                                        <div class="col-xs-1">
                                            <input type="checkbox" class="form-check-input" id="chkLevel1b"
                                                [checked]="Part4Header.lookbackType2"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'lookbackType3')" />
                                        </div>
                                        <div class="col-xs-11">
                                            <label class="form-check-label" for="chkLevel1b"> <strong>Level
                                                    1</strong>
                                                Total Chevron Commitment &ge;&nbsp;$250 MM and substandard outcome
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-1">
                                            <input type="checkbox" class="form-check-input" id="chkLevel2b"
                                                [checked]="Part4Header.lookbackType4"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                                (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'lookbackType4')" />
                                        </div>
                                        <div class="col-xs-11">
                                            <label class="form-check-label" for="chkLevel2b"> <strong>Level
                                                    3</strong>
                                                Total Chevron Commitment is &lt;&nbsp;$75 MM.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-xs-2">
                                    <div class="col-xs-1">
                                        <input type="checkbox" class="form-check-input" id="chkSingleProject"
                                            [checked]="Part4Header.singleProject"
                                            [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                            (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'singleProject')" />
                                    </div>
                                    <label style="margin-left: 1rem;" class="form-check-label" for="chkSingleProject">
                                        Single Project
                                    </label>
                                </div>
                                <div class="col-xs-2">
                                    <input type="checkbox" class="form-check-input" id="chkNOJV"
                                        [checked]="Part4Header.nojv"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                        (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'nojv')" />
                                    <label style="margin-left: 1rem;" class="form-check-label"
                                        for="chkNOJV">NOJV</label>
                                </div>
                                <div class="col-xs-3">
                                    <input type="checkbox" class="form-check-input" id="chkMultiProgram"
                                        [checked]="Part4Header.multiProject"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                        (change)="IdentifiedLookBackTypeCheck($event, Part4Header,'multiProject')" />
                                    <label style="margin-left: 1rem;" class="form-check-label"
                                        for="chkMultiProgram">Multiple ARs or
                                        Program</label>
                                </div>
                            </div>
                            <div class="row">

                                <div class="form-group col-xs-3">
                                    <label for="operatorName">Operator Name:</label>
                                    <input type="text" [(ngModel)]="Part4Header.operatorName"
                                        (change)=" UpdatePart4LookBackHeaderData(Part4Header)" class="form-control"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                </div>
                                <div class="form-group col-xs-3">
                                    <label for="cvxShare">Chevron Share %:</label>
                                    <div class="input-group">
                                        <input type="text" [(ngModel)]="Part4Header.cvxShare"
                                            (change)=" UpdatePart4LookBackHeaderData(Part4Header)" class="form-control"
                                            [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                        <div class="input-group-addon"
                                            [ngClass]="{'disabledNoOfCasesDiv': this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit}">
                                            <i class="glyphicon glyphicon-comment-outline"
                                                *ngIf="!hasComment('Part4','CVXShare')"
                                                (click)="GetComments('Part4','CVXShare')"></i>
                                            <i class="glyphicon glyphicon-comment-filled commentColor"
                                                *ngIf="hasComment('Part4','CVXShare')"
                                                (click)="GetComments('Part4','CVXShare')"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12">
                                    <hr />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong> 2. Project Completion Summary. </strong> Summarize the project’s
                                    objectives, scope and high-level execution results versus expectations at the time
                                    of authorization. Highlight root causes for major deviations. Include a brief
                                    discussion of any significant scope changes since authorization.
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 padding-0">
                                    <div class="col-sm-12">
                                        <ckeditor #section8a [editor]="Editor" [config]="config" id="section1-2"
                                            name="section1-2" (ready)="onReady($event,'projectCompletionSummary')"
                                            [(ngModel)]="Part4Header.projectCompletionSummary"
                                            [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly  ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit}">
                                        </ckeditor>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <hr />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>
                                        3. Summarize: the top (3-5)
                                        key insights/recommended practices
                                    </strong> identified during project execution and the plan to share them.
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 padding-0">
                                    <div>
                                    </div>
                                    <ckeditor #section8a [editor]="Editor" [config]="config" id="section1-2"
                                        name="section1-2" (ready)="onReady($event,'keyInsights')"
                                        [(ngModel)]="Part4Header.keyInsights"
                                        [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly  ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit}">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <--------------------Section 2 Performance--------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection2">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <strong>2. Performance Measures (All Chevron Share)</strong>
                                <br /> &nbsp;
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">

                                <table class="table">
                                    <tbody>
                                        <tr class="bg-table-default">
                                            <td colspan="2" class="text-center col-xs-4">
                                                All Upstream volumes are net revenue interest
                                            </td>
                                            <td colspan="3" class="text-center">
                                                <strong>AR Data</strong>
                                            </td>
                                            <td class="text-center" rowspan="2">
                                                <strong>Actual outcomes / <br />updated estimates</strong>
                                            </td>
                                            <td class="text-center" rowspan="2">
                                                <strong>Normalized</strong> <br />Actual outcome with original AR
                                                price/fiscal terms/FX guidelines
                                            </td>
                                            <td class="text-center" rowspan="2">
                                                <strong>Benchmark Top Quartile</strong>
                                            </td>
                                            <td class="text-center" rowspan="2">
                                                <strong>Benchmark Industry Average</strong>
                                            </td>
                                        </tr>
                                        <tr class="bg-table-default">

                                            <td colspan="2">
                                                <div class="row">
                                                    <div class="col-xs-6">
                                                        <strong>Lookback Data Date:</strong>
                                                    </div>
                                                    <div class="col-xs-6">
                                                        <input type="text" name="txtDate" class="form-control"
                                                            (dateChange)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                            [(ngModel)]="Part4Performance.dataDate"
                                                            [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                                            [matDatepicker]="picker1" (click)="picker1.open()" />
                                                        <mat-datepicker #picker1></mat-datepicker>
                                                    </div>
                                                </div>
                                            </td>

                                            <td class="text-center text-bold">EV</td>
                                            <td class="text-center text-bold">P10</td>
                                            <td class="text-center text-bold">P90</td>
                                        </tr>

                                        <tr class="border-top">
                                            <td class="bg-table-default" colspan="2">
                                                4a. <strong>TRIR </strong> (total workforce incid. /200k hours)
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.trirActualOutcomes"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.trirBenchTopQuartile"
                                                    BenchmarkIndustryAverage
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.trirBenchIndustryAverage"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                        </tr>
                                        <tr class="border-top">
                                            <td class="bg-table-default" colspan="2">
                                                4b. <strong>DAFWR </strong> (total workforce incid. /200k hours)
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.dafwrActualOutcomes"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.dafwrBenchTopQuartile"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.dafwrBenchIndustryAverage"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                        </tr>
                                        <tr class="border-top">
                                            <td class="bg-table-default" colspan="2">
                                                4c. <strong>Execution schedule </strong> months
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.executionScheduleEv"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.executionScheduleP10"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.executionScheduleP90"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.executionScheduleActualOutcomes"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.executionScheduleBenchTopQuartile"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.executionScheduleBenchIndustryAverage"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                        </tr>
                                        <tr class="border-top">
                                            <td class="bg-table-default" colspan="2">
                                                4d. <strong>{{ featuresImplemented.isNewCEisApplicableForms ? "Capex": "C&amp;E" }} </strong> probabilistic $MM
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!featuresImplemented.isOldVersionOfForms; else noDollarceEv">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                        [(ngModel)]="Part4Performance.ceEv"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceEv>
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                        [(ngModel)]="Part4Performance.ceEv"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!featuresImplemented.isOldVersionOfForms; else noDollarceP10">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                        [(ngModel)]="Part4Performance.ceP10"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceP10>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.ceP10"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!featuresImplemented.isOldVersionOfForms; else noDollarceP90">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                        [(ngModel)]="Part4Performance.ceP90"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceP90>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.ceP90"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr class="border-top">
                                          <td class="bg-table-default" colspan="2">
                                            4e. <strong>Benchmarked</strong> {{ featuresImplemented.isNewCEisApplicableForms ? "Capex": "C&amp;E" }} &MM
                                          </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!featuresImplemented.isOldVersionOfForms; else noDollarbenchmarkExecutionEv">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                        [(ngModel)]="Part4Performance.benchmarkExecutionEv"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarbenchmarkExecutionEv>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.benchmarkExecutionEv"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <div class="input-icon" *ngIf="!featuresImplemented.isOldVersionOfForms; else noDollarbenchmarkExecutionActualOutcomes">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                        [(ngModel)]="Part4Performance.benchmarkExecutionActualOutcomes"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarbenchmarkExecutionActualOutcomes>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.benchmarkExecutionActualOutcomes"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!featuresImplemented.isOldVersionOfForms; else noDollarbenchmarkExecutionNormalized">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                        [(ngModel)]="Part4Performance.benchmarkExecutionNormalized"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarbenchmarkExecutionNormalized>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.benchmarkExecutionNormalized"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!featuresImplemented.isOldVersionOfForms; else noDollarbenchmarkExecutionTopQuartile">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                        [(ngModel)]="Part4Performance.benchmarkExecutionTopQuartile"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarbenchmarkExecutionTopQuartile>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.benchmarkExecutionTopQuartile"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!featuresImplemented.isOldVersionOfForms; else noDollarbenchmarkExecutionIndustryAverage">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                        [(ngModel)]="Part4Performance.benchmarkExecutionIndustryAverage"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly|| this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarbenchmarkExecutionIndustryAverage>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.benchmarkExecutionIndustryAverage"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly|| this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                        </tr>

                                        <tr class="border-top">
                                            <td class="bg-table-default" colspan="2">
                                                <div class="row">
                                                    4f. <strong>{{ featuresImplemented.isNewCEisApplicableForms ? "Capex": "C&amp;E" }} AR basis </strong> $MM
                                                </div>
                                                <div class="row">
                                                    Show Basis :
                                                </div>
                                                <div class="row">

                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!featuresImplemented.isOldVersionOfForms; else noDollarceArBasisEv">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                        [(ngModel)]="Part4Performance.ceArBasisEv"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceArBasisEv>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.ceArBasisEv"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <div class="input-icon" *ngIf="!featuresImplemented.isOldVersionOfForms; else noDollarceArBasisActualOutcomes">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                        [(ngModel)]="Part4Performance.ceArBasisActualOutcomes"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceArBasisActualOutcomes>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.ceArBasisActualOutcomes"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td>
                                                <div class="input-icon" *ngIf="!featuresImplemented.isOldVersionOfForms; else noDollarceArBasisNormalized">
                                                    <input type="number" class="form-control"
                                                        (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                        [(ngModel)]="Part4Performance.ceArBasisNormalized"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                    <i>$</i>    
                                                </div>
                                                <ng-template #noDollarceArBasisNormalized>
                                                    <input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.ceArBasisNormalized"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                                </ng-template>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr class="border-top">
                                            <td class="bg-table-default" rowspan="4">
                                                4i. <strong>Estimated Ultimate Recovery</strong>
                                                <br />(if available)
                                            </td>
                                            <td style="border: none" class="bg-table-default text-bold">MMBO</td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboEv"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboP10"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboP90"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboActualOutcomes"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style="border: none" class="bg-table-default text-bold">BCF</td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryBcfEv"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryBcfP10"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryBcfP90"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryBcfActualOutcomes"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td class="bg-table-default text-bold" style="border: none">MMBOE</td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboeEv"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboeP10"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboeP90"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"><input type="number" class="form-control"
                                                    (change)=" UpdatePart4LookBackPerformanceData(Part4Performance)"
                                                    [(ngModel)]="Part4Performance.estUltimateRecoveryMmboeActualOutcomes"
                                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" />
                                            </td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                            <td style="border: none"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- --------------------Section 3 Endorsement------------------>
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection3">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>3. Endorsement</strong>
                                    <br> &nbsp;
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-6">
                                    <label for="staticEmail" class="form-label">Reporting Officer:</label>
                                    <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.reportingOfficer"
                                        (change)="UpdatePart4LookBackHeaderData(Part4Header)"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit">
                                    </textarea>
                                </div>
                                <div class="col-xs-6">
                                    <label for="staticEmail" class="form-label">Individual with delegation of
                                        authority:</label>
                                    <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.individualDoa"
                                        (change)="UpdatePart4LookBackHeaderData(Part4Header)"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <--------------------Section 4 Performance------------------>
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection4">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>4. Attachments</strong>
                                    <br> &nbsp;
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xs-12">
                                    <input type="checkbox" class="form-check-input" id="chk7a"
                                        [(ngModel)]="Part4Header.attachIpa" [checked]="Part4Header.attachIpa"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part4Header,'IPA')" />
                                    <label class="form-check-label pl-5" for="chk7a"> IPA close out report </label>
                                </div>
                                <div class="form-group col-xs-12">
                                    <input type="checkbox" class="form-check-input" id="chk7b"
                                        [(ngModel)]="Part4Header.attachL1RootCause"
                                        [checked]="Part4Header.attachL1RootCause"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part4Header,'rootCause')" />
                                    <label class="form-check-label pl-5" for="chk7b"> Level 1 root cause analysis report
                                        of major variances </label>
                                </div>
                                <div class="form-group col-xs-12">
                                    <input type="checkbox" class="form-check-input" id="chk7d"
                                        [(ngModel)]="Part4Header.attachL1ReviewTeam"
                                        [checked]="Part4Header.attachL1ReviewTeam"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part4Header,'reviewTeam')" />
                                    <label class="form-check-label pl-5" for="chk7d"> Level 1 review team Lookback
                                        report
                                    </label>
                                </div>
                                <div class="form-group col-xs-12">
                                    <input type="checkbox" class="form-check-input" id="chk7e"
                                        [(ngModel)]="Part4Header.attachL1ExplorationProspect"
                                        [checked]="Part4Header.attachL1ExplorationProspect"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part4Header,'exploration')" />
                                    <label class="form-check-label pl-5" for="chk7e"> Exploration prospect data sheet
                                    </label>
                                </div>
                                <div class="form-group col-xs-12">
                                    <input type="checkbox" class="form-check-input" id="chk7f"
                                        [(ngModel)]="Part4Header.attachSubsurfaceBasisDesign"
                                        [checked]="Part4Header.attachSubsurfaceBasisDesign"
                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                        (change)="Section7Attachments($event, Part4Header,'basisOfDesign')" />
                                    <label class="form-check-label pl-5" for="chk7f"> Subsurface basis of design
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 5------------------------------>
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection5">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong class="heading">Project Completion Results</strong>
                                    <br> Summarize results versus AR expectations. Include a discussion of the root
                                    causes of outcomes with significant variance. Comment on the link between results
                                    and the decision quality at the time of AR sanction. Summarize key
                                    insights/recommended practices identified during project execution.
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong class="heading">5. Operational Excellence Results During Project Execution
                                        : </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit}">
                                    <ckeditor #section5 [editor]="Editor" [config]="config" id="section5"
                                        name="section5" [(ngModel)]="Part4Header.operationalResults"
                                        (ready)="onReady($event,'section5')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 6------------------------------>
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection6">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                  <strong class="heading">6. {{ featuresImplemented.isNewCEisApplicableForms ? "Capex": "C&amp;E" }} Expenditure Results : </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit}">
                                    <ckeditor #section6 [editor]="Editor" [config]="config" id="section6"
                                        name="section6" [(ngModel)]="Part4Header.expenditureResults"
                                        (ready)="onReady($event,'section6')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 7------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection7">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>7. Schedule Results : </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit}">
                                    <ckeditor #section7 [editor]="Editor" [config]="config" id="section7"
                                        name="section7" [(ngModel)]="Part4Header.scheduleResults"
                                        (ready)="onReady($event,'section7')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 8------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection8">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>8. Commercial Agreement Results : </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit}">
                                    <ckeditor #section8 [editor]="Editor" [config]="config" id="section8"
                                        name="section8" [(ngModel)]="Part4Header.commercialAgreementResults"
                                        (ready)="onReady($event,'section8')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 9------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection9">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>9. Early Operational Results (hydrocarbon recovery estimates) : </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit}">
                                    <ckeditor #section9 [editor]="Editor" [config]="config" id="section9"
                                        name="section9" [(ngModel)]="Part4Header.earlyOperationalResults"
                                        (ready)="onReady($event,'section9')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 10------------------------------>
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection10">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong> 10. Appraisal well/program results : </strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12"
                                    [ngClass]="{'disabledNoOfCasesDiv': Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit}">
                                    <ckeditor #section10 [editor]="Editor" [config]="config" id="section10"
                                        name="section10" [(ngModel)]="Part4Header.appraisalWellResults"
                                        (ready)="onReady($event,'section10')">
                                    </ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Section 11------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection11">
                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <strong>11. Decision Executive, DRB and Project Manager</strong>
                                <br> &nbsp;
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 text-bold">
                                Phase 1
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Decision Executive</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p1De"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Project Manager</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p1ProjectManager"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">DRB</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p1Drb"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 text-bold">
                                Phase 2
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Decision Executive</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p2De"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Project Manager</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p2ProjectManager"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">DRB</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p2Drb"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 text-bold">
                                Phase 3
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Decision Executive</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p3De"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Project Manager</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p3ProjectManager"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">DRB</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p3Drb"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 text-bold">
                                Phase 4
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Decision Executive</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p4De"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">Project Manager</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p4ProjectManager"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"></textarea>
                            </div>
                            <div class="col-xs-4">
                                <label for="staticEmail" class="form-label">DRB</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="Part4Header.p4Drb"
                                    (change)=" UpdatePart4LookBackHeaderData(Part4Header)"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"></textarea>
                            </div>
                        </div>
                    </div>

                    <!-- <---------------------Form Reviewer------------------------------>
                    <div class="form-group fs-16" *ngIf="showSection12" >
                        <div class="box-container">
                            <hr />
                            <div class="form-horizontal">
                                <div>
                                    <div class="row">
                                        <div class="col-xs-12 padding-0">
                                            <ng2-completer  [(ngModel)]="searchStr" ngDefaultControl
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canSubmit ? true : false"
                                                [datasource]="dataService" [placeholder]="placeholder"
                                                [minSearchLength]="0" (selected)="addUserToAccessList($event)"
                                                [autoMatch]="true">

                                            </ng2-completer>
                                        </div>
                                        <div class="col-xs-3">
                                            &nbsp;
                                        </div>

                                    </div>
                                    <div class="row" *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">
                                        <div *ngIf="name.errors?.['minlength']" class="alert alert-danger" role="alert">
                                            Name must be at least 4 characters long.
                                        </div>
                                    </div>
                                    <div class="col-xs-3">
                                        &nbsp;
                                    </div>
                                    <table class="table border-bottom table-striped table-responsive table-bordered">
                                        <tbody>
                                            <tr class="border-top border-bottom">
                                                <th></th>
                                                <th class="text-center">
                                                    <div>Sender</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Receiver</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Latest Status</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Modified Date</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Latest Version</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Comments</div>
                                                </th>
                                                <th class="text-center" style="width: 34% !important;">Form Action</th>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr *ngFor="let reviewerItem of reviewerData let i = index"
                                                class="border-top border-bottom table-header">
                                                <td [(ngModel)]="reviewerItem.id" hidden class="ml-10">
                                                    {{reviewerItem.id}}</td>
                                                <td class="text-center col-xs-offset-0">
                                                    <button type="button" style="color: white !important;"
                                                        class="btn btn-sm btn-danger"
                                                        (click)="DeleteReviewer(reviewerItem,'delete')" [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly
                                                                    || this.userAccessList?.data?.isLocked 
                                                                    || reviewerItem.deleteFlag">
                                                        X
                                                    </button>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{reviewerItem.submitterName}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{reviewerItem.reviewerName}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">

                                                    <span></span>
                                                    <span>{{reviewerItem.currentStatusName}}</span>

                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{reviewerItem.modifiedOn}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{reviewerItem.versionId}}</span>
                                                </td>
                                                <td class="text-center col-xs-2">

                                                    <textarea class="form-control" rows="2"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly
                                                                || this.userAccessList?.data?.isLocked
                                                                || reviewerItem.commentFlag"
                                                        [(ngModel)]="reviewerItem.comments">
                                                                </textarea>
                                                </td>
                                                <td style="width: 36% !important;">
                                                    <button type="button" style="color: white !important;"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly
                                                                    || this.userAccessList?.data?.isLocked || reviewerItem.submitterFlag || !this.userAccessList?.data?.canSubmit"
                                                        class="btn btn-sm btn-success"
                                                        (click)="UpdateReviewer(reviewerItem.id,'forreview',reviewerItem,reviewerItem.comments)">
                                                        For Review
                                                    </button>
                                                    <button type="button" style="color: white !important;"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly
                                                                    || this.userAccessList?.data?.isLocked || reviewerItem.reviewerFlag" class="btn btn-sm btn-warning"
                                                        (click)="UpdateReviewer(reviewerItem.id,'sendback',reviewerItem,reviewerItem.comments)">
                                                        Send Back
                                                    </button>
                                                    <button type="button" style="color: white !important;"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly
                                                                    || this.userAccessList?.data?.isLocked || reviewerItem.reviewerFlag" class="btn btn-sm btn-success"
                                                        (click)="UpdateReviewer(reviewerItem.id,'endorsed',reviewerItem,reviewerItem.comments)">
                                                        Endorse
                                                    </button>
                                                    <button type="button" style="color: white !important;"
                                                        [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly
                                                                    || this.userAccessList?.data?.isLocked || reviewerItem.reviewerFlag || !this.userAccessList?.data?.canApprove" class="btn btn-sm btn-success"
                                                        (click)="OpenApprovalDialog(reviewerItem.id,reviewerItem.comments)">
                                                        Concur
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngIf="reviewerCount==false" class="col-xs-12 padding-0 text-center">
                                        &nbsp;
                                        <b>No reviewers available. Please use the search bar to add
                                            reviewers.</b>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div class="row">
                                <div class="form-horizontal">
                                    <div class="row" style="text-align:center; display:block">
                                        Form Logs
                                    </div>
                                    <table class="table border-bottom table-striped table-responsive table-bordered">
                                        <tbody>
                                            <tr class="border-top border-bottom">
                                                <th class="text-center">
                                                    <div>Action Performed</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Sender</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Receiver</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Status From</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Status To</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Sender Comments</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Receiver Comments</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Version</div>
                                                </th>
                                                <th class="text-center">
                                                    <div>Date</div>
                                                </th>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr *ngFor="let formsLogItem of formsLogData let i = index"
                                                class="border-top border-bottom table-header">
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.formAction}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.submittedByUserName}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.submittedToUserName}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.oldStatusName}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.currentStatusName}}</span>
                                                </td>
                                                <td class="text-center col-xs-3">
                                                    <span>{{formsLogItem.submitterComments}}</span>
                                                </td>
                                                <td class="text-center col-xs-3">
                                                    <span>{{formsLogItem.reviewerComments}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.versionNumber}}</span>
                                                </td>
                                                <td class="text-center col-xs-1">
                                                    <span>{{formsLogItem.createdOn}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-xs-3">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 form-group" *ngIf="signOffData.length > 0">
                            <strong>Signoffs </strong>
                            <span class="glyphicon glyphicon-question-sign" (click)="ShowSignoffsPopUp()"></span>
                        </div>
                        <div class="col-xs-12 sub-section" *ngIf="signOffData.length > 0">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr class="bg-table-default table-header">
                                        <td></td>
                                        <td width="20%">Name</td>
                                        <td width="20%">Position</td>
                                        <td width="20%">Signature</td>
                                        <td width="20%">Date</td>
                                    </tr>
                                    <tr *ngFor="let signOffItem of signOffData let i = index">
                                        <td [(ngModel)]="signOffItem.id" hidden class="ml-10">{{signOffItem.id}}</td>
                                        <td>{{signOffItem.signoffName}}</td>
                                        <td>
                                            <textarea type="text" rows="3"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                                class="form-control" [(ngModel)]="signOffItem.name"
                                                (change)="UpdateSignOff(signOffItem)">
                                                </textarea>
                                        </td>
                                        <td>
                                            <textarea type="text" rows="3"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                                class="form-control" [(ngModel)]="signOffItem.position"
                                                (change)="UpdateSignOff(signOffItem)">
                                                </textarea>
                                        </td>
                                        <td>
                                            <textarea type="text" rows="3"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                                class="form-control" [(ngModel)]="signOffItem.signature"
                                                (change)="UpdateSignOff(signOffItem)">
                                                </textarea>
                                        </td>
                                        <td class="form-date">
                                            <input class="form-control" [(ngModel)]="signOffItem.signoffDate"
                                                (dateChange)="UpdateSignOff(signOffItem)" [matDatepicker]="picker"
                                                [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                                (click)="picker.open();utilityService.removeCDKAnouncer()">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-sidenav-container>
        </div>
    </div>


    <div class="row">
        <div class="col-xs-12">
            &nbsp;
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 padding-0">
            <div class="text-left">
                <h5 class="text-left">Upload Attachments :</h5>
                <input type="file" class="form-control-file upload" #file (change)="uploadFile($event)"
                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 padding-0 ">
            <table class="table table-bordered table-striped table-responsive">
                <thead>
                    <tr>
                        <td class="text-center text-bold">&nbsp;</td>
                        <td class="text-center text-bold">Document ID</td>
                        <td class="text-left text-bold">File Name</td>
                        <td class="text-left text-bold">Uploaded By</td>
                        <td class="text-left text-bold">&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-bordered" *ngFor="let document of part4Document let i = index">
                        <td class="text-center">
                            <button type="button" style="color: white !important;" class="btn btn-sm btn-primary"
                                (click)="DownloadFile(document)">
                                Download
                            </button>
                        </td>
                        <td class="text-center">
                            <span>{{document.documentId}}</span>
                        </td>
                        <td class="text-left">
                            <span>{{document.docName}}</span>
                        </td>
                        <td class="text-left">
                            <span>{{document.createdByName}}</span>
                        </td>
                        <td class="text-left">
                            <span>
                                <button type="button" style="color: white !important;" class="btn btn-sm btn-danger"
                                    [disabled]="Part4Header.isOnHold || Part4Header.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                                    (click)="RemoveFile(document, i)">X</button>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12">
            <hr>
        </div>
    </div>
    <div class="row" style="margin-top: 15px;">
        <div class="col-xs-12 padding-0">
            <div class="row">
                <div class="form-group col-sm-4 col-xs-12 text-left padding-0 ">
                    <button type="button" style="color: white !important;" class="btn btn-sm btn-primary"
                        [routerLink]="['/eaproject/eaviewform']" [queryParams]="{projectId: projectId}">Close</button>
                    <button type="button" style="color: white !important;" class="btn btn-sm btn-danger"
                        [disabled]="Part4Header.isOnHold || !this.userAccessList?.data?.canDeleteForm"
                        [routerLink]="['/ea/forms/delete']"
                        [queryParams]="{projectId: Part4Header.projectId,formId: Part4Header.arLookbackId,versionName: Part4Header.version,formType:'E&A LOOKBACK_PART4'}">
                        Delete</button>
                </div>
                <div class="form-group col-xs-4 text-center">
                </div>
                <div class="form-group col-sm-4 col-xs-12 text-right padding-0">
                    <!-- <button type="button" style="color: white !important;"
                    class="btn btn-sm btn-primary btn-dark" (click)="ExportPDF()">PDF</button> -->
                    <a [href]="pdfUrl" class="btn btn-sm btn-primary btn-dark" target="_blank"
                        style="color: white !important;margin-right: 0.5em;margin-bottom: 0.5em;">PDF</a>
                    <button type="button" style="color: white !important;" class="btn btn-sm btn-success btn-dark"
                        (click)="OpenArchiveDialog()"
                        [disabled]="Part4Header.isOnHold || !this.userAccessList?.data?.canArchive || this.userAccessList?.data?.isLocked">Archive</button>

                        <button *ngIf="!Part4Header.isReadOnly && !featuresImplemented.hasWorkFlowProcessFeature" type="button"
                        class="btn btn-sm btn-success btn-dark" (click)="performFormAction('forreview')"
                        style="color: white !important;"
                        [disabled]="Part4Header.isOnHold || !this.userAccessList?.data?.canSubmit || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || this.forReviewLockByFormState">Send For Review</button>
        
                        <button *ngIf="!Part4Header.isReadOnly && !featuresImplemented.hasWorkFlowProcessFeature" type="button"
                        class="btn btn-sm btn-warning btn-dark" (click)="performFormAction('sendback')"
                        style="color: white !important;"
                        [disabled]="Part4Header.isOnHold || !this.userAccessList?.data?.canSendBack || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || this.sendBackLockByFormState">Send Back</button>
        
                        <button *ngIf="!Part4Header.isReadOnly && !featuresImplemented.hasWorkFlowProcessFeature" type="button"
                        class="btn btn-sm btn-success btn-dark" (click)="performFormAction('approved')"
                        style="color: white !important;"
                        [disabled]="Part4Header.isOnHold || !this.userAccessList?.data?.canApprove || Part4Header.isReadOnly ||  this.userAccessList?.data?.isLocked || this.concurLockByFormState">Concur</button>
                </div>
            </div>
        </div>
    </div>
</div>
