
<div class="box-container" id="page-body">

    <h3 >Register New User</h3>
    
    <div class="row">
        <div class="col-md-6 col-md-offset-3">
            <hr>

            <form [formGroup]="formValue" >
                <div class="form-horizontal">
                    <div class="form-group">
                        <label class="control-label col-xs-4" for="email">Email</label>
                        <div class="col-xs-5">
                            <input  class="form-control col-xs-5" formControlName="email"  id="email" readonly="readonly" type="text" value="email">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-4" for="firstName">First Name</label>
                        <div class="col-xs-5">
                            <input  class="form-control col-xs-5"   formControlName="firstName"  id="firstName" readonly="readonly" type="text" value="firstName">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-4" for="lastName">Last Name</label>
                        <div class="col-xs-5">
                            <input  class="form-control col-xs-5"   formControlName="lastName"  id="lastName" readonly="readonly" type="text" value="lastName">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-4"  for="roleId">Role</label>
                        <div class="col-xs-5">
                            <select formControlName="roleId" class="form-control" [attr.disabled]="roleDisabled" (change) = "onRoleChange($event)">
                              <!-- <option disabled>Select OPCO</option> -->
                                <option *ngFor="let web of RoleList" [value]="web.roleId">
                                    {{web.roleType}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-4"  for="OpcoId">Segment</label>
                        <div class="col-xs-5">
                            <select formControlName="OpcoId" class="form-control" (change) = "fillSbuDDL($any($event.target).value)">
                              <!-- <option disabled>Select OPCO</option> -->
                                <option *ngFor="let web of OpcoList" [value]="web.OpcoId">
                                    {{web.OpcoName}}</option>
                            </select>
                        </div>
                    </div>
                  <div class="form-group">
                        <label class="control-label col-xs-4" for="SbuId">RU</label>
                        <div class="col-xs-5">
                            <select formControlName="SbuId" class="form-control" (change) = "fillBuDDL($any($event.target).value)">
                                <!-- <option disabled>Select SBU</option> -->
                                <option *ngFor="let web of SbuList"  [value]="web.SbuId" >{{web.SbuName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-4" for="BuId">BU</label>
                        <div class="col-xs-5">
                            <select formControlName="BuId" class="form-control">
                                <!-- <option disabled>Select BU</option> -->
                                <option *ngFor="let web of BuList"  [value]="web.BuId" >{{web.BuName}}</option>
                            </select>
                        </div>
                    </div>
                   
                    <!-- <div class="form-group">
                       
                            <div class="alert alert-danger col-xs-8" role="alert">
                                <span class="col-xs-4" data-bind="text: message">ghfghf</span>
                            </div>
                     
                    </div> -->
                    <!-- <span>{{errormessage}}</span> -->
                    <hr>
                    <div class="row">
                        
                        <div class="col-xs-6 padding-0 text-right">
                            <button  class="btn btn-light" (click)="RegisterNewUser()" style="width:8em"
                            [disabled]="!this.formValue.valid">Register</button>
                        </div>
                        
                    </div>
                    
                </div>
                    </form>
                </div> 
            </div>
        </div>
