<app-popup-active-users></app-popup-active-users>
<div class="box-container">
    <div class="row">
        <div class="col">
            <h3> GO-36 Exploration & Appraisal Form </h3>
            <div class="row text-center">
                <a class="no-underline" [routerLink]="['/eaproject/eaviewform']" [queryParams]="{projectId: projectId}">
                    <h5 style="padding: 0; display:inline; font-size: 14px;">{{projectName}}</h5>
                </a>
            </div>
            <h5 *ngIf="form.isReadOnly" class="text-danger margin-0 padding-0" style="font-size: 14px;">
                (Previous Version #{{Part1Model.version}} - {{Part1Model.versionName}} - Read-Only)</h5>
            <h5 class="text-danger margin-0 padding-0" style="font-size: 14px !important;" *ngIf="Part1Model.isOnHold">
                Form is on Hold. No editing can be performed
            </h5>
        </div>
    </div>

    <div class="row" *ngIf="!Part1Model.isReadOnly && featuresImplemented.hasGo_36_EA_FormHoldFeature">
        <span [ngClass]="{'disabledNoOfCasesDiv': this.userAccessList?.data?.isLocked}">
            <mat-slide-toggle (change)="onChange($event)" [checked]="Part1Model.isOnHold">
                <b class="app-font">On Hold</b>
            </mat-slide-toggle>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowOnHoldModal()"></span>
        </span>
        <!-- <button type="button" style="float:right;color: white !important;"
            class="text-right btn btn-sm btn-success btn-dark" *ngIf="!Part1Model.isOnHold || !Part1Model.isReadOnly"
            [disabled]="this.userAccessList?.data?.isLocked">Save</button> -->
    </div>

    <div class="row tabbed tabbed-content-control centered">
        <div class="filter-bar tab-filter-bar font-gotham centered-xs">
            <mat-tab-group [selectedIndex]="0" animationDuration="0ms" style='min-height:300px' [disableRipple]='true'
                disablepagination="true">
                <mat-tab label="Part 1">
                    <ng-template matTabContent>
                        <div>
                            <app-ar-part1 [comments]="Comments" [isOnHold]="Part1Model.isOnHold" 
                            [formId]="Part1Model.arId" (changeHeader)="changeHeader($event)" [IsOldVersionOfForms]="featuresImplemented.isOldVersionOfForms"
                            [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
                            </app-ar-part1>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Part 2">
                    <ng-template matTabContent>
                        <div>
                            <app-ar-part2 [comments]="Comments" [isOnHold]="Part1Model.isOnHold"  [IsOldVersionOfForms]="featuresImplemented.isOldVersionOfForms" [IsNewCEisApplicableForms]="featuresImplemented.isNewCEisApplicableForms"
                             [isReadOnly]="Part1Model.isReadOnly" [formId]="Part1Model.arId" [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
                            </app-ar-part2>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Part 3">
                    <ng-template matTabContent>
                        <div>
                            <app-ar-part3 [comments]="Comments" [isOnHold]="Part1Model.isOnHold" [IsOldVersionOfForms]="featuresImplemented.isOldVersionOfForms" [IsNewCEisApplicableForms]="featuresImplemented.isNewCEisApplicableForms" [isReadOnly]="Part1Model.isReadOnly" [formId]="Part1Model.arId" [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
                            </app-ar-part3>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Form Reviewer" *ngIf="featuresImplemented.hasWorkFlowProcessFeature">
                    <ng-template matTabContent>
                    <div>
                        <app-ar-formreviewer [isOnHold]="Part1Model.isOnHold" [projectName]="projectData.projectName"  [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked'  [canSubmitForReview]='this.userAccessList?.data?.canSubmit'  
                        [isReadOnly]="Part1Model.isReadOnly" [canSendBack]='this.userAccessList?.data?.canSendBack' [canEdit]='this.userAccessList?.data?.canEdit'  
                        [canApprove]='this.userAccessList?.data?.canApprove'  [formsLogData]='formsLogData'>
                        </app-ar-formreviewer>
                    </div>
                </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <hr style="margin-top: 0;"/>
    <div class="row">
        <div class="col-xs-12 padding-0">
            <div class="text-left">
                <h5 class="text-left">Upload Attachments :</h5>
                <input type="file" class="form-control-file upload" #file (change)="uploadFile($event)"
                    [disabled]="Part1Model.isOnHold || Part1Model.isReadOnly || !this.userAccessList?.data?.canEdit">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 padding-0 ">
            <table class="table table-bordered table-striped table-responsive">
                <thead>
                    <tr>
                        <td class="text-center text-bold">&nbsp;</td>
                        <td class="text-center text-bold">Document ID</td>
                        <td class="text-left text-bold">File Name</td>
                        <td class="text-left text-bold">Uploaded By</td>
                        <td class="text-left text-bold">&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-bordered" *ngFor="let document of NewARDocuments let i = index">
                        <td class="text-center">
                            <button type="button" style="color: white !important;" class="btn btn-sm btn-primary"
                                (click)="DownloadFile(document)">
                                Download
                            </button>
                        </td>
                        <td class="text-center">
                            <span>{{document.documentId}}</span>
                        </td>
                        <td class="text-left">
                            <span>{{document.docName}}</span>
                        </td>
                        <td class="text-left">
                            <span>{{document.createdBy}}</span>
                        </td>
                        <td class="text-left">
                            <span>
                                <button type="button" style="color: white !important;" class="btn btn-sm btn-danger"
                                    (click)="RemoveFile(document, i)"
                                    [disabled]="Part1Model.isOnHold || Part1Model.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit">X</button>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" style="margin-top: 15px;">
        <div class="col-xs-12 padding-0">
            <div class="row">
                <div class="form-group col-sm-4 col-xs-4 text-left padding-0 ">
                    <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/eaproject/eaviewform']"
                        [queryParams]="{projectId: projectId}"
                        style="color: white !important;background-color: #5bc0de !important;border-color: #46b8da!important;">Close</button>
                    <button *ngIf="!Part1Model.isReadOnly" type="button" class="btn btn-sm btn-danger"
                        [routerLink]="['/forms/feed/delete']" style="color: white !important;"
                        [queryParams]="{projectId: projectId,formId: formId,versionName: versionName, formType: 'NewAR'}" [disabled]="!this.userAccessList?.data?.canDeleteForm">Delete</button>
                </div>
                <div class="form-group col-sm-4 col-xs-4 text-center padding-0">
                    <button type="button" class="btn btn-sm btn-primary btn-dark" style="color: white !important;"
                        (click)="CreateEaNewRescope()" [disabled]="isOnHold || Part1Model.isReadOnly  || this.userAccessList?.data?.isLocked">
                        Rescope</button>
                    <button type="button" class="btn btn-sm btn-primary btn-dark" style="color: white !important;"
                        (click)="CreateEaNewSupplement()" [disabled]="isOnHold || Part1Model.isReadOnly  || this.userAccessList?.data?.isLocked" >
                        Supplement</button>
                    <button type="button" class="btn btn-sm btn-primary btn-dark" style="color: white !important;"
                        (click)="CreatePart4LookBack()" [disabled]="isOnHold || Part1Model.isReadOnly  || this.userAccessList?.data?.isLocked">Part
                        4 Lookback</button>
                </div>
                <div class="form-group col-sm-4 col-xs-4 text-right padding-0">
                    <!-- <button type="button" class="btn btn-sm btn-primary btn-dark"
                        style="color: white !important;" (click)="ExportPDF()">PDF</button> -->
                        <a [href]="pdfUrl" class="btn btn-sm btn-primary btn-dark" target="_blank"
                        style="color: white !important;margin-right: 0.5em;margin-bottom: 0.5em;">PDF</a>
                    <button type="button" class="btn btn-sm btn-success btn-dark" (click)="OpenArchiveDialog()"
                        [disabled]="isOnHold || Part1Model.isReadOnly || this.userAccessList?.data?.isLocked" *ngIf="!Part1Model.isReadOnly"
                        style="color: white !important;">Archive</button>

                        <button *ngIf="!Part1Model.isReadOnly && !featuresImplemented.hasWorkFlowProcessFeature" type="button"
                        class="btn btn-sm btn-success btn-dark" (click)="performFormAction('forreview')"
                        style="color: white !important;"
                        [disabled]="isOnHold || !this.userAccessList?.data?.canSubmit || form.isReadOnly ||  this.userAccessList?.data?.isLocked || this.forReviewLockByFormState">Send For Review</button>
        
                        <button *ngIf="!Part1Model.isReadOnly && !featuresImplemented.hasWorkFlowProcessFeature" type="button"
                        class="btn btn-sm btn-warning btn-dark" (click)="performFormAction('sendback')"
                        style="color: white !important;"
                        [disabled]="isOnHold || !this.userAccessList?.data?.canSendBack || form.isReadOnly ||  this.userAccessList?.data?.isLocked || this.sendBackLockByFormState">Send Back</button>
        
                        <button *ngIf="!Part1Model.isReadOnly && !featuresImplemented.hasWorkFlowProcessFeature" type="button"
                        class="btn btn-sm btn-success btn-dark" (click)="performFormAction('approved')"
                        style="color: white !important;"
                        [disabled]="isOnHold || !this.userAccessList?.data?.canApprove || form.isReadOnly ||  this.userAccessList?.data?.isLocked || this.concurLockByFormState">Concur</button>
                </div>
            </div>
        </div>
    </div>
</div>
