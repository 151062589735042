<div class="box-container" id="page-body">
  <div class="row text-center">
    <h3>
      <small class="dark-blue">Project Name</small>
      <br />
      <span>{{ data.projectmodel.ProjectName }}</span>
    </h3>
  </div>
  <div class="row">
    <div class="col-xs-3">&nbsp;</div>
    <div class="col-xs-6">
      <hr />
      <div class="form-horizontal" style="display: flex;justify-content: center;">
        <div class="form-group" style="width: 100%;">
          <label for="staticEmail" class="form-label"></label>
          <div class="col-xs-12 padding-0" style="display: flex;justify-content: center;align-items: center;width: 100%;">
            <!-- <input
              type="text"
              class="form-control col-xs-8"
              placeholder="last name, first name"      
              (keyup)="SearchUser($event)"
              minlength="1"
            /> -->
          <ng2-completer ngDefaultControl [(ngModel)]="searchStr" [style]="{'width': '100%'}"
          [datasource]="dataService" [placeholder]="placeholder" [minSearchLength]="0"
          (selected)="addUserToAccessList($event)" [autoMatch]="true">
          </ng2-completer>
          </div>
        </div>
        <div
          class="row"
          *ngIf="name.invalid && (name.dirty || name.touched)"
          class="alert"
        >
          <div
            *ngIf="name.errors?.['minlength']"
            class="alert alert-danger"
            role="alert"
          >
            Name must be at least 4 characters long.
          </div>
        </div>
      </div>
      <div class="form-row padding-0">
        <div style="display: flex;justify-content: center;align-items: center;" class="row padding-top-5">
          <div class="text-right">
            
          </div>
          <table style="font-family:AGothamNarrowBook, Helvetica, Arial, sans-seriff" class="table border-bottom table-striped table-responsive table-bordered">
            <tbody>
              <tr class="border-top border-bottom">
                <th colspan="3"></th>
                <th class="rotate" colspan="7" style="transform:rotate(360deg);height:auto">
                  <div><span>Project Admin</span></div>
                </th>
                <th class="rotate" colspan="7" style="transform:rotate(360deg);">
                  <div><span>Project Member</span></div>
                </th>
                <th class="rotate" colspan="7" style="transform:rotate(360deg);">
                  <div><span>Consultant</span></div>
                </th>
              </tr>
            </tbody>
            <tbody>
              <tr
                class="border-top border-bottom table-header"  
                *ngFor="let item of UserAccessList;let i = index" [ngClass]="{'disableRow':item.Disabled}">             

              
                <td class="text-center col-xs-1" *ngIf="(UserAccessList.length >1)">
                  <button
                    type="button" style="color: white !important;"
                    class="btn btn-sm btn-danger" (click)="deleteRow(i)" name="{{item.EmailId}}">
                    X
                  </button>
                </td>
                <td class="text-center col-xs-6">
                  <span>{{ item.EmailId}}</span>
                </td>
                <td class="text-center col-xs-4">
                  <span>{{ item.FirstName+ ' '+ item.LastName}}</span>
          
                </td>
                <td class="text-center" colspan="7">
                  <input type="radio" value="admin" name="{{item.EmailId}}"
                  [attr.checked]="item.ProjectAdmin ?'checked':null" 
                  (change)="onRoleChange($event,item)" />
                </td>
                <td class="text-center" colspan="7">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="member"
                    name="{{item.EmailId}}"
                    [attr.checked]="item.ProjectMember?'checked':null"
                    (change)="onRoleChange($event,item)"
                  />
                </td>
                <td class="text-center" colspan="7">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="readonly"
                    name="{{item.EmailId}}"
                    [attr.checked]="item.Readonly ?'checked':null"
                    (change)="onRoleChange($event,item)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <div class="row text-right">
        <a  routerLink="/eaproject/eaviewform/" style="margin-right:1rem" 
        (click)="SetEditData('editMode')"   [queryParams]="{projectId: data.projectmodel.ProjectId}"  role="button" class="btn btn-info">View Forms</a>
        <a    routerLink= "/ea/project" class="btn btn-info">Back</a>
      </div>
    </div>
    <div class="col-xs-3">&nbsp;</div>
  </div>
</div>
