import { Component, OnInit } from '@angular/core';
import { EaprojectService } from '../service/eaproject.service';
import { Router } from '@angular/router';
import { Go36projectService } from '../../go36project/service/go36project.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-ea-project-list',
  templateUrl: './ea-project-list.component.html',
  styleUrls: ['./ea-project-list.component.css']
})
export class EaProjectListComponent implements OnInit {
  editMode: boolean = localStorage.getItem('editMode') == 'true';
  constructor(private eaprojectservice: EaprojectService, private router: Router, private projectservice: Go36projectService,private utilityServcie: UtilityService) { }
  eaprojects: any;
  userAccessDetails: any;
  isHeaderFooterVisible: boolean = false;
  userRole: string = '';
  objectId: string = '';
  provId: string = '';
  emailId: string = '';
  userAccessListByProjectId: any;
  userRoleForProject: string = '';
  projectlength: number = 0;

  ngOnInit(): void {
    this.objectId = this.utilityServcie.GetuserObjectId();
    this.provId = this.utilityServcie.GetuserProvisioningId();
    this.emailId = this.utilityServcie.GetuserEmailId();
    localStorage.removeItem('errorpage');
    this.getAllProject();
  }

  getAllProject() {
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);
    // //this.userRole : string = '';
    // we can create the constants of the users role
    if (this.userAccessDetails?.data?.isPowerUser) {
      this.userRoleForProject = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      this.userRoleForProject = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      this.userRoleForProject = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      this.userRoleForProject = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      this.userRoleForProject = "Leadership";
    }
    this.eaprojectservice.getProject(this.objectId.trim(),this.provId.trim(), this.userRoleForProject).subscribe(res => {
      this.eaprojects = res;
      this.projectlength = this.eaprojects.length;
      this.onShowEntryDropdownChange({first: 0, rows: this.projectlength});
    })
  }

  onShowEntryDropdownChange(event) {
    if (event.rows === this.projectlength)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "All";
    else if (event.rows === 10)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "10";
    else if (event.rows === 20)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "20";
    else if (event.rows === 50)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "50";
    else if (event.rows === 100)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "100";
  }

  SetEditMode(editMode: string) {
    localStorage.setItem('editMode', 'false')
  }  

  edit(goproject: any, action: string) {
    localStorage.setItem('goProject', JSON.stringify(goproject));
    localStorage.setItem('editMode', 'true')

    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);
    // //this.userRole : string = '';
    // // we can create the constants of the users role
    if (this.userAccessDetails?.data?.isPowerUser) {
      this.userRole = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      this.userRole = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      this.userRole = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      this.userRole = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      this.userRole = "Leadership";
    }

    this.projectservice.GetUserAccessListByProjectId(goproject.ProjectId, this.objectId, this.provId).subscribe(res => {
      if (res) {
        this.userAccessListByProjectId = res;
        if (action == 'EditProject') {
          this.isHeaderFooterVisible = this.userAccessListByProjectId?.data?.canEditProject;
          if (this.isHeaderFooterVisible) {
            ///project/eaproject/update/
            this.router.navigate(['/project/eaproject/update/'], { queryParams: { projectId: goproject.ProjectId } });
          }
        }

        if (action == 'AccessSecurity') {
          this.isHeaderFooterVisible = this.userAccessListByProjectId?.data?.canAccessSecurityProject;
          if (this.isHeaderFooterVisible) {
            this.router.navigate(['/project/eaproject/usersecurityaccess/'], { queryParams: { projectId: goproject.ProjectId } });
          }
        }

        // // if(action == 'CopyProject')
        // // {
        // //   this.isHeaderFooterVisible = this.userAccessListByProjectId?.data?.canCopyProject;
        // //   if(this.isHeaderFooterVisible)
        // //   {
        // //     this.router.navigate(['/project/copy/']);
        // //   }
        // // }

        if (this.isHeaderFooterVisible === false) {
          localStorage.setItem('errorpage', 'true');
          this.router.navigate(["/errorpage"]);
        }
      }
    })
  }
}