<div class="row sub-section">
  <div class="col-sm-6 col-xs-12">
    <div class="row form-group">
      <div class="col-sm-4 col-xs-12">
        <label class="control-label form-label">Project Name</label>
      </div>
      <div class="col-sm-8 col-xs-12">
        <input type="text" readonly="readonly" id="projectName" name="projectName"
               [(ngModel)]="projectData.projectName" class="form-control" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4 col-xs-12">
        <label class="control-label form-label">Segment</label>
      </div>
      <div class="col-sm-8 col-xs-12">
        <input type="text" class="form-control" readonly="readonly" id="opcoName" name="opcoName"
               [(ngModel)]="projectData.opcoName" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4 col-xs-12">
        <label class="control-label form-label">BU</label>
      </div>
      <div class="col-sm-8 col-xs-12">
        <input type="text" class="form-control" readonly="readonly" id="buName" name="buName"
               [(ngModel)]="projectData.buName" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4 col-xs-12">
        <label class="control-label form-label">Operator Name</label>
      </div>
      <div class="col-sm-8 col-xs-12">
        <input type="text" class="form-control" id="operatorName" name="operatorName"
               [(ngModel)]="form.operatorName" [disabled]="isOnHold || isReadOnly" (change)="UpdateFormData()" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4 col-xs-12">
        <label class="control-label form-label">Facility (megafriendly) Name</label>
      </div>
      <div class="col-sm-8 col-xs-12">

        <input type="text" class="form-control" readonly="readonly" id="assetMegaFriendlyName"
               name="assetMegaFriendlyName" [(ngModel)]="projectData.assetMegaFriendlyName" />
      </div>
    </div>
    <div *ngIf="isSsbodAvailable==true" class="row form-group">
      <div class="col-sm-4 col-xs-12">
        <label class="control-label form-label">Is SSBOD Required?</label>
      </div>
      <div class="col-sm-8 col-xs-12">
        <select class="form-control" readonly="readonly" disabled=true>
          <option value="selected">Yes</option>
        </select>

      </div>
    </div>
    <div *ngIf="isSsbodAvailable==false" class="row form-group">
      <div class="col-sm-4 col-xs-12">
        <label class="control-label form-label">Is SSBOD Required?</label>
      </div>
      <div class="col-sm-8 col-xs-12">
        <select class="form-control" readonly="readonly" disabled=true>
          <option value="selected">No</option>
        </select>

      </div>
    </div>
  </div>
  <div class="col-sm-6 col-xs-12">
    <div class="row form-group">
      <div class="col-sm-4 col-xs-12">
        <label class="control-label form-label">AR Type</label>
      </div>
      <div class="col-sm-8 col-xs-12">
        <input type="text" class="form-control" readonly="readonly" id="formType" name="formType"
               [(ngModel)]="form.formType" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4 col-xs-12">
        <label class="control-label form-label">RU</label>
      </div>
      <div class="col-sm-8 col-xs-12">
        <input type="text" class="form-control" readonly="readonly" id="sbuName" name="sbuName"
               [(ngModel)]="projectData.sbuName" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4 col-xs-12">
        <label class="control-label form-label">Asset Class</label>
      </div>
      <div class="col-sm-8 col-xs-12">
        <input type="text" class="form-control" readonly="readonly" id="assetName" name="assetName"
               [(ngModel)]="projectData.assetName" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4 col-xs-12">
        <label class="control-label form-label">Project Location</label>
      </div>
      <div class="col-sm-8 col-xs-12">
        <input type="text" class="form-control" id="location" name="location" [(ngModel)]="form.location"
               [disabled]="isOnHold || isReadOnly" (change)="UpdateFormData()" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4 col-xs-12">
        <label class="control-label form-label">Special Project Type(s)</label>
      </div>
      <div class="col-sm-8 col-xs-12">
        <input type="text" class="form-control" readonly="readonly" id="specialProjectTypes"
               name="specialProjectTypes" [(ngModel)]="projectData.specialProjectTypes" />
      </div>
    </div>
  </div>
</div>
<div class="col-xs-12">
  <div class="row">
    <div class="col-xs-12 form-group">
      <strong>1.1 Short Summary of Scope and Request</strong>

      <span class="glyphicon glyphicon-question-sign" (click)="showShortSummaryInfo()"></span>
    </div>
  </div>
</div>

<div class="row sub-section">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold
                || overview.summaryNa || isReadOnly}" *ngIf="!overview.summaryNa">
        <span>
          <ckeditor #summaryeditor [editor]="Editor" [config]="config" [(ngModel)]="overview.summary"
                    id="summary" name="summary" (ready)="onReady($event,'Summary')">
          </ckeditor>
        </span>
      </div>
    </div>
  </div>
  <div class="row sub-section">
    
  </div>
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12 form-group">
        <strong>1.2 Ownership</strong>
        <span class="glyphicon glyphicon-question-sign" (click)="showOwnershipInfoPopUp()"></span>
      </div>
      <div>
        <div class="col-xs-12">

          <table class="table table-bordered">
            <tbody>
              <tr class="bg-table-default table-header">
                <td width="30%">Ownership</td>
                <td>Net Working Interest</td>
                <td>Net Revenue Interest</td>
                <td width="1%">Is Operator</td>
                <td width="1%"></td>
                <td class="trash-icon" width="1%"></td>
              </tr>
              <tr *ngFor="let owner of ownershipData let i = index">
                <ng-container>
                  <td [(ngModel)]="owner.id" hidden class="ml-10">{{owner.id}}</td>
                  <td [(ngModel)]="owner.isEditable" hidden class="ml-10">{{owner.isEditable}}</td>
                  <td *ngIf="owner.isEditable==false">
                    <div>
                      <input type="text" readonly="readonly" [disabled]="isOnHold || isReadOnly"
                             class="form-control" [(ngModel)]="owner.ownershipName"
                             (change)="UpdateOwner(owner)" />
                    </div>
                  </td>
                  <td *ngIf="owner.isEditable==true">
                    <div>
                      <input type="text" [disabled]="isOnHold || isReadOnly" class="form-control"
                             [(ngModel)]="owner.ownershipName" (change)="UpdateOwner(owner)" />
                    </div>
                  </td>
                  <td>
                    <input class="form-control" [disabled]="isOnHold || isReadOnly"
                           [(ngModel)]="owner.workingInterest" (change)="UpdateOwner(owner)" />
                  </td>
                  <td>
                    <input class="form-control" [disabled]="isOnHold || isReadOnly"
                           [(ngModel)]="owner.netRevenueInterest" (change)="UpdateOwner(owner)" />
                  </td>
                  <td class="text-center">
                    <input type="checkbox" [disabled]="isOnHold || isReadOnly"
                           class="form-check-input" [(ngModel)]="owner.isOperator"
                           (change)="UpdateOwner(owner)" />
                  </td>
                  <td>
                    <div>
                      <i class="glyphicon glyphicon-comment-outline" *ngIf="!owner.comment"
                         (click)="SaveCommentForOwnerships(owner)"></i>
                      <i class="glyphicon glyphicon-comment commentColor" *ngIf="owner.comment"
                         (click)="SaveCommentForOwnerships(owner)"></i>

                    </div>
                  </td>
                  <td>
                    <i *ngIf="owner.isEditable==true"
                       class="glyphicon glyphicon-trash glyphicon-custom-trash"
                       [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnly}"
                       (click)="DeleteOwnerships(owner,i)"></i>
                  </td>

                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-xs-12">
        <button type="button" [disabled]="isOnHold || isReadOnly" (click)="addOwnerShip()"
                style="color: white !important;" class="btn btn-sm btn-primary">
          Add New Row
        </button>
      </div>
    </div>
    <div class="row sub-section">
    </div>

  </div>
  <div class="col-xs-6">
    <div class="row">
      <div class="col-xs-12 form-group">
        <strong>1.3 Phase Gate Milestones</strong>
        <span class="glyphicon glyphicon-question-sign" (click)="showPhaseGateMilestonesPopUp()"></span>
      </div>
      <div class="col-xs-12">
        <table class="table table-bordered">
          <tbody>
            <tr class="bg-table-default table-header">
              <td></td>
              <td style="text-align:center;">Date</td>
              <td width="1%"></td>
              <td width="1%" class="trash-icon"></td>
            </tr>
            <tr *ngFor="let phasemile of phasemileStone let i = index">
              <td [(ngModel)]="phasemile.id" hidden class="ml-10">{{phasemile.id}}</td>
              <td [(ngModel)]="phasemile.isEditable" hidden class="ml-10">{{phasemile.isEditable}}</td>
              
              <td>
                <input type="text" [disabled]="isOnHold || isReadOnly" class="form-control"
                       [(ngModel)]="phasemile.milestoneName" (change)="UpdatePhaseMileStone(phasemile)" />
              </td>
              <td>
                <input class="form-control" [(ngModel)]="phasemile.date"
                       (dateChange)="UpdatePhaseMileStone(phasemile)" [matDatepicker]="picker"
                       [disabled]="isOnHold || isReadOnly"
                       (click)="picker.open();utilityService.removeCDKAnouncer()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </td>
              <td>
                <div>
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!phasemile.comment"
                     (click)="SaveCommentForMilestones(phasemile)"></i>
                  <i class="glyphicon glyphicon-comment commentColor" *ngIf="phasemile.comment"
                     (click)="SaveCommentForMilestones(phasemile)"></i>
                </div>
              </td>
              <td>
                <i class="glyphicon glyphicon-trash glyphicon-custom-trash"
                   [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnly}" 
                   (click)="DeletephaseGateMilestones(phasemile,i)"></i>
              </td>
            </tr>
            <!-- <tr>
                    <td>
                        <input type="text" class="form-control"[(ngModel)]="pahseMilestoneItem .milestoneName"
                        (change)="addPhaseGateMilestones(pahseMilestoneItem )" />

                    </td>
                    <td>
                        <input class="" [(ngModel)]="pahseMilestoneItem .currentDate"
                        (dateChange)="addPhaseGateMilestones(pahseMilestoneItem )" [matDatepicker]="picker"
                        placeholder="Choose a date" (click)="picker.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker ></mat-datepicker>
                    </td>
                    <td>
                        <div>
                            <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveCommentForMilestones(pahseMilestoneItem )"></i>
                        </div>
                    </td>
                    <td>
                        <i class="glyphicon glyphicon-trash glyphicon-custom-trash"
                        (click)="DeletephaseGateMilestones(pahseMilestoneItem)"></i>
                    </td>
                </tr> -->
          </tbody>
        </table>
      </div>
      <div class="col-xs-12" *ngIf="!isReadOnly">
        <button type="button" *ngIf="!isOnHold" (click)="addPhaseGateMilestones('Company')" style="color: white !important;" 
        class="btn btn-sm btn-primary">Add New Row</button>
     </div>
    </div>
  </div>
  <div class="col-xs-6">
    <div class="row">
      <div class="col-xs-12 form-group"><strong>Co-Owner View</strong></div>
      <div class="col-xs-12">
        <table class="table table-bordered">
          <tbody>
            <tr class="bg-table-default table-header">
              <td></td>
              <td style="text-align:center;">Date</td>
              <td width="1%"></td>
              <td width="1%" class="trash-icon"></td>
            </tr>
            <tr *ngFor="let coownermile of coownermileStone let i = index">
              <td [(ngModel)]="coownermile.id" hidden class="ml-10">{{coownermile.id}}</td>
              <td [(ngModel)]="coownermile.isEditable" hidden class="ml-10">
                {{coownermile.isEditable}}
              </td>
             
              <td >
                <input type="text"  [disabled]="isOnHold || isReadOnly"
                       class="form-control" [(ngModel)]="coownermile.milestoneName"
                       (change)="UpdatePhaseMileStone(coownermile)" />
              </td>
              <td>
                <input class="form-control" [(ngModel)]="coownermile.date"
                       (dateChange)="UpdatePhaseMileStone(coownermile)" [matDatepicker]="picker"
                       [disabled]="isOnHold || isReadOnly"
                       (click)="picker.open();utilityService.removeCDKAnouncer()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </td>
              <td>
                <div>
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!coownermile.comment"
                     (click)="SaveCommentForMilestones(coownermile)"></i>
                  <i class="glyphicon glyphicon-comment commentColor" *ngIf="coownermile.comment"
                     (click)="SaveCommentForMilestones(coownermile)"></i>
                </div>
              </td>
              <td>
                <i class="glyphicon glyphicon-trash glyphicon-custom-trash"
                   [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnly}" 
                   (click)="DeleteCoOwnerphaseGateMilestones(coownermile,i)"></i>
              </td>
            </tr>
            <!-- <tr>
                    <td>
                        <input type="text" class="form-control"[(ngModel)]="milestoneItem.milestoneName"
                        (change)="UpdatePhaseMileStone(milestoneItem)" />

                    </td>
                    <td>
                        <input class="" [(ngModel)]="milestoneItem.currentDate"
                        (dateChange)="UpdatePhaseMileStone(milestoneItem)" [matDatepicker]="picker"
                        placeholder="Choose a date" (click)="picker.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker ></mat-datepicker>
                    </td>
                    <td>
                        <div>
                            <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveCommentForMilestones(milestoneItem)"></i>
                        </div>
                    </td>
                    <td>
                        <i class="glyphicon glyphicon-trash glyphicon-custom-trash"
                        (click)="DeletephaseGateMilestones(milestoneItem)"></i>
                    </td>
                </tr> -->
          </tbody>
        </table>
      </div>
      <div class="col-xs-12" *ngIf="!isReadOnly">
        <button type="button" *ngIf="!isOnHold" (click)="addPhaseGateMilestones('Partner')" style="color: white !important;" 
        class="btn btn-sm btn-primary">Add New Row</button>
      </div>
    </div>
  </div>
</div>
<div class="col-xs-12">
  <div class="row">
    <div class="col-xs-12 form-group">
      <strong>
        1.4 Total Commitment Detail (Net Chevron share P50 - nominal $MM, Include co-owner {{ (form.features?.isNewCEisApplicableForms || form.isNewCEisApplicableForms) ? "Capex": "C&E" }} data if
        applicable)
      </strong>
      <span class="glyphicon glyphicon-question-sign" (click)="showCEDetailPopUp()"></span>
    </div>

    <div class="col-xs-12">
      <table class="table table-bordered">
        <tbody>
          <tr class="bg-table-default table-header">
            <td width="10%">$MM</td>
            <td width="10%">
              <span class="column-90">
                  <input type="text" [disabled]="isOnHold || isReadOnly" class="form-control"
                          [(ngModel)]="formConfigurationOverview.facilitiesName" (change)="UpdateFormConfiguration(formConfigurationOverview)" />
              </span>
            <span>+</span>
            </td>
            <td width="10%">
              <span class="column-90">
                  <input type="text" [disabled]="isOnHold || isReadOnly" class="form-control"
                          [(ngModel)]="formConfigurationOverview.drillingAndCompletionsName" (change)="UpdateFormConfiguration(formConfigurationOverview)" />
      </span>
      <span>+</span>
            </td>
            <td width="10%">
              <span class="column-90">
                  <input type="text" [disabled]="isOnHold || isReadOnly" class="form-control"
                          [(ngModel)]="formConfigurationOverview.contingencyName" (change)="UpdateFormConfiguration(formConfigurationOverview)" />
            </span>
            <span>+</span>
            </td>
            <td width="10%">
              <span class="column-90">
                  <input type="text" [disabled]="isOnHold || isReadOnly" class="form-control"
                          [(ngModel)]="formConfigurationOverview.projectGeneralAndAdministrativeName" (change)="UpdateFormConfiguration(formConfigurationOverview)" />
             </span>
            <span>+</span>
            </td>
            <td width="10%">
              <span class="column-90">
                  <input type="text" [disabled]="isOnHold || isReadOnly" class="form-control"
                          [(ngModel)]="formConfigurationOverview.additionalOwnersCostsName" (change)="UpdateFormConfiguration(formConfigurationOverview)" />
            </span>
            <span>=</span>
            
            </td>
            <td width="10%">
              <span class="column-90">
                  <td disabled readonly><strong> Total</strong></td>
            </span>
            </td>
            <td width="10%">
              <span class="column-90">
                  <td disabled readonly><strong> Alternate View (if applicable)</strong></td>
            </span>
            </td>
            <td width="1%">
              <span class="column-90">
            <td disabled readonly></td>
            </span>
            </td>
          </tr>
          <!-- ko foreach: CapitalAndExpenditures -->
          <tr *ngFor="let cAndEDetail of capitalAndExpendituresData let i = index">
              <td [(ngModel)]="cAndEDetail.id" hidden class="ml-10">{{cAndEDetail.id}}
              </td>
             <td  *ngIf="cAndEDetail.canEdit ==true">
              <input class="form-control" type="text" [disabled]="isOnHold || isReadOnly"
              [(ngModel)]="cAndEDetail.capitalAndExpenditureName" (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
            </td>
            <td *ngIf="cAndEDetail.canEdit ==false">
              
              <label *ngIf="cAndEDetail.capitalAndExpenditureName=='FID AR';else FIDAR_content" class="column-120"><strong>{{cAndEDetail.capitalAndExpenditureName}}</strong>

              </label>
              <ng-template #FIDAR_content>
                  <label class="column-120">{{cAndEDetail.capitalAndExpenditureName}}</label>
              </ng-template>
            </td>
            
            <td>
              <div class="input-group">
                  
                  <input class="form-control alignRight" type="text" [ngClass]="{'bold': cAndEDetail.capitalAndExpenditureName=='FID AR'}" [disabled]="isOnHold || isReadOnly"
                      [(ngModel)]="cAndEDetail.facilities" (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
                  
                      <div class="input-group-addon">
                      <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.facilitiesComment"
                          (click)="SaveCommentForcAndEDetails(cAndEDetail,'Facility')"></i>
                      <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.facilitiesComment"
                          (click)="SaveCommentForcAndEDetails(cAndEDetail,'Facility')"></i>
                     </div>
              </div>
          </td>
            <td>
              <div class="input-group">
                <input class="form-control alignRight" type="text" [ngClass]="{'bold': cAndEDetail.capitalAndExpenditureName=='FID AR'}" [disabled]="isOnHold || isReadOnly"
                       [(ngModel)]="cAndEDetail.drillingAndCompletions" (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.drillingComment"
                     (click)="SaveCommentForcAndEDetails(cAndEDetail,'Drilling')"></i>
                  <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.drillingComment"
                     (click)="SaveCommentForcAndEDetails(cAndEDetail,'Drilling')"></i>
                </div>
              </div>
            </td>
            <td>
              <div class="input-group">
                <input class="form-control alignRight" type="text" [ngClass]="{'bold': cAndEDetail.capitalAndExpenditureName=='FID AR'}" [disabled]="isOnHold || isReadOnly"
                       [(ngModel)]="cAndEDetail.contingencyEscalation" (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.contingencyComment"
                     (click)="SaveCommentForcAndEDetails(cAndEDetail,'Contingency')"></i>
                  <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.contingencyComment"
                     (click)="SaveCommentForcAndEDetails(cAndEDetail,'Contingency')"></i>
                </div>
              </div>
            </td>
            <td>

              <div class="input-group">
                <input class="form-control alignRight" type="text" [ngClass]="{'bold': cAndEDetail.capitalAndExpenditureName=='FID AR'}" [disabled]="isOnHold || isReadOnly"
                       [(ngModel)]="cAndEDetail.otherJointCosts" (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.otherJointComment"
                     (click)="SaveCommentForcAndEDetails(cAndEDetail,'JointCost')"></i>
                  <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.otherJointComment"
                     (click)="SaveCommentForcAndEDetails(cAndEDetail,'JointCost')"></i>
                </div>
              </div>
            </td>
            <td>

              <div class="input-group">
                <input class="form-control alignRight" type="text" [ngClass]="{'bold': cAndEDetail.capitalAndExpenditureName=='FID AR'}" [disabled]="isOnHold || isReadOnly"
                       [(ngModel)]="cAndEDetail.otherSoleCosts" (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.otherSoleComment"
                     (click)="SaveCommentForcAndEDetails(cAndEDetail,'SoleCost')"></i>
                  <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.otherSoleComment"
                     (click)="SaveCommentForcAndEDetails(cAndEDetail,'SoleCost')"></i>
                </div>
              </div>
            </td>
            <td>
  
              <div class="input-group">
                  <input class="form-control alignRight" readonly type="text" [ngClass]="{'bold': cAndEDetail.capitalAndExpenditureName=='FID AR'}" [disabled]="isOnHold || isReadOnly"
                      [(ngModel)]="cAndEDetail.totalAppropriated" />
                  <div class="input-group-addon">
                      <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.totalAppComment"
                          (click)="SaveCommentForcAndEDetails(cAndEDetail,'TotalAppropriate')"></i>
                      <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.totalAppComment"
                          (click)="SaveCommentForcAndEDetails(cAndEDetail,'TotalAppropriate')"></i>
                  </div>
              </div>
          </td>
            <td>
              <div class="input-group">
                <input class="form-control alignRight" type="text" [ngClass]="{'bold': cAndEDetail.capitalAndExpenditureName=='FID AR'}" [disabled]="isOnHold || isReadOnly"
                       [(ngModel)]="cAndEDetail.coOwnerView" (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.coOwnerViewComment"
                     (click)="SaveCommentForcAndEDetails(cAndEDetail,'CoOwnerView')"></i>
                  <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.coOwnerViewComment"
                     (click)="SaveCommentForcAndEDetails(cAndEDetail,'CoOwnerView')"></i>
                </div>
              </div>
            </td>
            <td colspan="1" *ngIf="cAndEDetail.canDelete ==true">
              <span class="glyphicon glyphicon-trash glyphicon-custom-trash" (click)="DeleteCEDetail(cAndEDetail,i)"></span>
            </td>
            <!-- <td>
                <div class="input-group">
                <i class="glyphicon glyphicon-trash glyphicon-custom-trash"
                    [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnly}"
                    ></i>
                </div>
            </td> -->
          </tr>

          <tr class="bg-table-default table-header">
            <td><strong>Total Commitment</strong></td>
            <td>
              <div class="input-group">
                <input class="form-control alignRight td-padding-border-radius" type="text" readonly
                       [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalFacilities" />
                <div class="input-group-addon" style="visibility: hidden;">
                  <i class="glyphicon glyphicon-comment-outline"></i>
                </div>
              </div>
            </td>
            <td>
              <div class="input-group">
                <input class="form-control alignRight td-padding-border-radius" type="text" readonly
                       [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalDrillingAndCompletions" />
                <div class="input-group-addon" style="visibility: hidden;">
                  <i class="glyphicon glyphicon-comment-outline"></i>
                </div>
              </div>
            </td>
            <td>
              <div class="input-group">
                <input class="form-control alignRight td-padding-border-radius" type="text" readonly
                       [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalContingencyEscalation" />
                <div class="input-group-addon" style="visibility: hidden;">
                  <i class="glyphicon glyphicon-comment-outline"></i>
                </div>
              </div>
            </td>
            <td>
              <div class="input-group">
                <input class="form-control alignRight td-padding-border-radius" type="text" readonly
                       [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalOtherJointCosts" />
                <div class="input-group-addon" style="visibility: hidden;">
                  <i class="glyphicon glyphicon-comment-outline"></i>
                </div>
              </div>
            </td>
            <td>
              <div class="input-group">
                <input class="form-control alignRight td-padding-border-radius" type="text" readonly
                       [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalOtherSoleCosts" />
                <div class="input-group-addon" style="visibility: hidden;">
                  <i class="glyphicon glyphicon-comment-outline"></i>
                </div>
              </div>
            </td>
             <td>
            <div class="input-group">
              <input class="form-control alignRight td-padding-border-radius" type="text" readonly
                     [disabled]="isOnHold || isReadOnly" [(ngModel)]="addtotalCommitment" />
              <div class="input-group-addon" style="visibility: hidden;">
                <i class="glyphicon glyphicon-comment-outline"></i>
              </div>
            </div>
          </td>
          <td>
            <div class="input-group">
              <input class="form-control alignRight td-padding-border-radius" type="text" readonly
                     [disabled]="isOnHold || isReadOnly" [(ngModel)]="addCoOwner" />
              <div class="input-group-addon" style="visibility: hidden;">
                <i class="glyphicon glyphicon-comment-outline"></i>
              </div>
            </div>
          </td>

          </tr>
        </tbody>
      </table>
   <div *ngIf="!isReadOnly">
    <button type="button" *ngIf="!isOnHold" class="btn btn-sm btn-primary" 
    style="color: white !important;" (click)="addCAndEDetails()">Add New Row</button>
   </div>
   </div>
</div>
</div>

<div class="col-xs-8">
  <div class="row ">
    <div class="col-xs-12">
      <strong>Provide additional comment on {{ (form.features?.isNewCEisApplicableForms || form.isNewCEisApplicableForms) ? "Capex": "C&E" }} as relevant and include co-owner data as applicable.</strong>
    </div>
  </div>
</div>
<div class="row sub-section">

</div>

<div class="row sub-section">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || overview.capAndExpNa
            || isReadOnly }" *ngIf="!overview.capAndExpNa">
        <span>
          <ckeditor #capitalAndExpenditureeditor [editor]="Editor" [config]="config"
                    [(ngModel)]="overview.capitalAndExpenditure" id="capitalAndExpenditure"
                    name="capitalAndExpenditure" (ready)="onReady($event,'CapitalAndExpenditure')">
          </ckeditor>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="col-xs-8">
  <div class="row ">
    <div class="col-xs-12 form-group">
      <strong>1.5 Key economic metrics at mid-price: Mid-Point and EV values</strong>
      <span class="glyphicon glyphicon-question-sign" (click)="ShowKeyEconomicPopUp()"></span>
    </div>
  </div>
</div>
<div class="col-xs-12">

  <div class="row">
    <div class="col-xs-12">
      <table class="table table-bordered">
        <tbody>
          <tr class="bg-table-default table-header">
            <td colspan="5" class="text-center" style=" padding: 10px 5px 10px 5px;">
               Mid-Point
            </td>
            <td colspan="3" class="text-center" style=" padding: 10px 5px 10px 5px;">EV</td>
            <td width="2%" rowspan="2"></td>

          </tr>
          <tr class="bg-table-default table-header">
            <td class="text-center" style="padding: 10px 5px 20px 5px;"></td>
            <td class="text-center" style="padding: 10px 5px 20px 5px;">Full Cycle</td>
            <td class="text-center" style="padding: 10px 5px 20px 5px;">FEED Forward</td>
            <td class="text-center" style="padding: 10px 5px 20px 5px;">Point Forward</td>
            <td class="text-center" style="padding: 10px 5px 20px 5px;">Point Forward Co-Owner View (if applicable)</td>
            <td class="text-center" style="padding: 10px 5px 20px 5px;">Full Cycle</td>
            <td class="text-center" style="padding: 10px 5px 20px 5px;">FEED Forward</td>
            <td class="text-center" style="padding: 10px 5px 20px 5px;">Point Forward</td>



          </tr>
          <tr class="table-header" *ngFor="let keyEco of keyEconomicMetricsData">
            <td [(ngModel)]="keyEco.id" hidden class="ml-10">{{keyEco.id}}</td>
            <td class="bg-table-computed-clear" style=" padding: 10px 5px 10px 5px;">
              {{keyEco.keyEconomicMetricName}}
            </td>
            <td class="bg-table-computed-clear text-center">
              <input type="text" [disabled]="isOnHold || isReadOnly" class="form-control" id="fullCycle"
                     name="fullCycle" readonly [(ngModel)]="keyEco.fullCycle" />
            </td>
            <td class="bg-table-computed-clear text-center">
              <input type="text" [disabled]="isOnHold || isReadOnly" class="form-control" id="feedForward"
                     name="feedForward" readonly [(ngModel)]="keyEco.feedForward" />
            </td>
            <td class="bg-table-computed-clear text-center">
              <input type="text" [disabled]="isOnHold || isReadOnly" class="form-control"
                     id="pointForward" name="pointForward" readonly [(ngModel)]="keyEco.pointForward" />
            </td>
            <td class="bg-table-computed-clear text-center">
              <input type="text" [disabled]="isOnHold || isReadOnly" class="form-control"
                  id="partnerView" name="partnerView" [(ngModel)]="keyEco.partnerView" 
                  (change)="UpdateyEconomicMetrics(keyEco)"/>
            </td>

            <td class="bg-table-computed-clear text-center">
              <input *ngIf="keyEco.evHideColumn==false" type="text" [disabled]="isOnHold || isReadOnly"
                     class="form-control" id="evFullCycle" name="evFullCycle" readonly
                     [(ngModel)]="keyEco.evFullCycle" />
            </td>
            <td class="bg-table-computed-clear text-center">
              <input *ngIf="keyEco.evHideColumn==false" type="text" [disabled]="isOnHold || isReadOnly"
                     class="form-control" id="evFeedForward" name="evFeedForward" readonly
                     [(ngModel)]="keyEco.evFeedForward" />
            </td>
            <td class="bg-table-computed-clear text-center">
              <input *ngIf="keyEco.evHideColumn==false" type="text" [disabled]="isOnHold || isReadOnly"
                     class="form-control" id="evPointForward" name="evPointForward" readonly
                     [(ngModel)]="keyEco.evPointForward" />
            </td>

            <td class="bg-table-computed-clear text-center">
              <div>

                <i class="glyphicon glyphicon-comment-outline" *ngIf="!keyEco.comment"
                   (click)="SaveCommentForKeyEconomic(keyEco)"></i>
                <i class="glyphicon glyphicon-comment commentColor" *ngIf="keyEco.comment"
                   (click)="SaveCommentForKeyEconomic(keyEco)"></i>


              </div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <div class="col-xs-12">
      <table class="table table-bordered" style="width: 60% !important">
        <tbody>
          <tr class="table-header">
            <td colspan="3" class="bold" style=" padding: 10px 5px 10px 5px;">
              <label class="padding-top-5">
                Project has a {{formRisks.npvFullCycle}}% chance of having an
                NPV &lt;0 in the mid-price
                scenario
              </label>
              <!-- <label class="padding-top-5"></label>
              <label class="padding-top-5"> % chance of having an NPV &lt;0 in the mid-price
                  scenario</label> -->
            </td>
          </tr>
          <tr class="bg-table-default table-header">
            <td style=" padding: 10px 5px 10px 5px;"></td>
            <td style=" padding: 10px 5px 10px 5px;" class="text-center bold">Point Forward</td>
            <td style=" padding: 10px 5px 10px 5px;" class="text-center bold">
              Commodity (e.g. Brent, WTI,
              HH) Varied
            </td>
          </tr>
          <tr class="table-header">
            <td style=" padding: 10px 5px 10px 5px;" class="bg-table-computed-clear">
              Breakeven Price or Margin
            </td>
            <td style=" padding: 10px 5px 10px 5px;"
                class=" bg-table-default bg-table-computed-clear text-center">
              <div>{{formRisks.flatRealPointForward}}</div>
            </td>
            <td style=" padding: 10px 5px 10px 5px;"
                class="bg-table-default bg-table-computed-clear text-center">
              <div>{{formRisks.commodityTestedBy}}</div>

            </td>
          </tr>
          <tr class="bg-table-default table-header">
            <td style=" padding: 10px 5px 20px 5px;"></td>
            <td style=" padding: 10px 5px 20px 5px;" class="text-center bold">FEED Forward</td>
            <td style=" padding: 10px 5px 20px 5px;" class="text-center bold">
              Commodity (e.g. Brent, WTI,
              HH) Varied
            </td>
          </tr>
          <tr class="table-header">
            <td style=" padding: 10px 5px 20px 5px;" class="bg-table-computed-clear">
              Breakeven Price or Margin
            </td>
            <td style=" padding: 10px 5px 20px 5px;"
                class=" bg-table-default bg-table-computed-clear text-center">
              <div>{{formRisks.flatRealFeedForward}}</div>
            </td>
            <td style=" padding: 10px 5px 20px 5px;"
                class=" bg-table-default bg-table-computed-clear text-center">
              <div>{{formRisks.feedForwardCommodityTestedBy}}</div>

            </td>
          </tr>
          <tr>
            <td colspan="3" class="bg-table-computed-clear">
              <label class="padding-top-5">
                Remaining {{ (form.features?.isNewCEisApplicableForms || form.isNewCEisApplicableForms) ? "Capex": "C&E" }} costs must be multiplied by a factor of
                {{formRisks.remainingCostIncrease}} &nbsp;
              </label>
              <label class="padding-top-5"></label>
              <label class="padding-top-5"> to get NPV10=0 with all other inputs at the mid-case.</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>

<div class="col-xs-8">
  <div class="row">
    <div class="col-xs-12 form-group">
      <strong>
        1.6 Project Background, Description and Current Status - any additional detail to that
        above
      </strong>
      <span class="glyphicon glyphicon-question-sign" (click)="showProjectBackgroundPopUp()"></span>
    </div>
  </div>
</div>
<div class="col-xs-4">
  <div class="text-right">
    <div class="col-xs-12 form-group">
    
    </div>
  </div>
</div>
<div class="row sub-section">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnly}">
        <span>
          <ckeditor #projectBackeditor [editor]="Editor" [config]="config"
                    [(ngModel)]="overview.projectBackgroundAndDescription" id="projectBackgroundAndDescription"
                    name="projectBackgroundAndDescription" (ready)="onReady($event,'projectBack')">
          </ckeditor>
        </span>
      </div>
    </div>
  </div>
</div>


<div class="col-xs-4">
  <div class="text-right">
    <div class="col-xs-12 form-group">

    </div>
  </div>
</div>


<div class="col-xs-12">
  <div class="row">
    <div class="col-xs-12 ">
      <strong>1.7 Project Changes from Last Reporting</strong>
      <span class="glyphicon glyphicon-question-sign" (click)="showProjectChangesPopUp()"></span>
    </div>
  </div>
  
</div>

<div class="col-xs-4">
  <div class="text-right">
    <div class="col-xs-12 form-group">

    </div>
  </div>
</div>

<div class="row sub-section">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold ||
                    overview.reservesFundFlag || isReadOnly}" *ngIf="!overview.projectLastChangesFlag">
        <span>
          <ckeditor #projectBackeditor [editor]="Editor" [config]="config"
                    [(ngModel)]="overview.projectChanges" id="projectChanges" name="projectChanges"
                    (ready)="onReady($event,'ProjectChanges')">
          </ckeditor>
        </span>
      </div>
    </div>

  </div>
</div>

<div class="col-xs-12" *ngIf="form.isUpstream">
  <div class="row">
    <div class="col-xs-12 ">
      <strong>1.8 Reserves and Resources</strong>
      <span class="glyphicon glyphicon-question-sign" (click)="showReservesandResourcesPopUp()"></span>
    </div>
  </div>
</div>
<div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold ||
                     overview.reservesFundFlag || isReadOnly}" *ngIf="((features?.isOldVersionOfForms && isUpstream) || !features?.isOldVersionOfForms) && !overview.reservesFundFlag">
  <div clas="row">
    <div class="col-xs-12">
      <table class="table table-bordered">
        <tbody>
          <tr class="table-header bg-table-default">
            <td class="bold">Operational (Net)</td>
            <td>Oil</td>
            <td>Gas</td>
            <td width="15%">Total</td>
            <td width="15%">Total 8/8ths</td>
            <td width="10%">Point Forward Co-Owner View (if applicable)</td>
          </tr>


          <tr class="table-header">
            <td [(ngModel)]="netReservebooking.id" hidden class="ml-10">{{netReservebooking.id}}</td>
            <td>{{netReservebooking.operationalName}}</td>
            <td>
              <div class="input-group">
                <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control"
                       name="oil" [(ngModel)]="netReservebooking.oil"
                       (change)="UpdateOperationalChanges(netReservebooking,'NetReserve')" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline"
                     *ngIf="!netReservebooking.oilComments"
                     (click)="SaveCommentForOperational(108,1,netReservebooking.id,netReservebooking.oilComments,'NetReserve')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="netReservebooking.oilComments"
                     (click)="SaveCommentForOperational(108,1,netReservebooking.id,netReservebooking.oilComments,'NetReserve')"></i>
                </div>
              </div>
            </td>
            <td>
              <div class="input-group">
                <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control"
                       name="gas" [(ngModel)]="netReservebooking.gas"
                       (change)="UpdateOperationalChanges(netReservebooking,'NetReserve')" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline"
                     *ngIf="!netReservebooking.gasComments"
                     (click)="SaveCommentForOperational(108,2,netReservebooking.id,netReservebooking.gasComments,'NetReserve')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="netReservebooking.gasComments"
                     (click)="SaveCommentForOperational(108,2,netReservebooking.id,netReservebooking.gasComments,'NetReserve')"></i>
                </div>
              </div>
            </td>
            <td class="bg-table-default">
              <div class="input-group">
                <input class="form-control" type="number" readonly name="total"
                       [(ngModel)]="netReservebooking.total" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline"
                     *ngIf="!netReservebooking.totalComments"
                     (click)="SaveCommentForOperational(108,3,netReservebooking.id,netReservebooking.totalComments,'NetReserve')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="netReservebooking.totalComments"
                     (click)="SaveCommentForOperational(108,3,netReservebooking.id,netReservebooking.totalComments,'NetReserve')"></i>
                </div>
              </div>
            </td>
            <td class="bg-table-default">
              <div class="input-group">
                <input class="form-control" readonly type="number" readonly name="total8"
                       [(ngModel)]="netReservebooking.total8" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline"
                     *ngIf="!netReservebooking.total8Comments"
                     (click)="SaveCommentForOperational(108,4,netReservebooking.id,netReservebooking.total8Comments,'NetReserve')"></i>
                  <i class="gglyphicon glyphicon-comment commentColor"
                     *ngIf="netReservebooking.total8Comments"
                     (click)="SaveCommentForOperational(108,4,netReservebooking.id,netReservebooking.total8Comments,'NetReserve')"></i>
                </div>
              </div>
            </td>
            <td class="bg-table-default">
              <div class="input-group">
                <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control"
                       name="partnerView" [(ngModel)]="netReservebooking.partnerView"
                       (change)="UpdateOperationalChanges(netReservebooking,'NetReserve')" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline"
                     *ngIf="!netReservebooking.partnerViewComments"
                     (click)="SaveCommentForOperational(108,6,netReservebooking.id,netReservebooking.partnerViewComments,'NetReserve')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="netReservebooking.partnerViewComments"
                     (click)="SaveCommentForOperational(108,6,netReservebooking.id,netReservebooking.partnerViewComments,'NetReserve')"></i>
                </div>
              </div>
            </td>
          </tr>

          <tr class="table-header">
            <td [(ngModel)]="eur.id" hidden class="ml-10">{{eur.id}}</td>
            <td>{{eur.operationalName}} </td>
            <td class="bg-table-default"></td>
            <td class="bg-table-default"></td>
            <td class="bg-table-default">
              <div class="input-group">

                <input class="form-control" readonly name="eur" [(ngModel)]="form.eur" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!eur.totalComments"
                     (click)="SaveCommentForOperational(108,3,eur.id,eur.totalComments,'Eur')"></i>
                  <i class="glyphicon glyphicon-comment commentColor" *ngIf="eur.totalComments"
                     (click)="SaveCommentForOperational(108,3,eur.id,eur.totalComments,'Eur')"></i>
                </div>
              </div>
            </td>
            <td class="bg-table-default">
              <div class="input-group">
                <input class="form-control" readonly name="total8" [(ngModel)]="eur.total8" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!eur.total8Comments"
                     (click)="SaveCommentForOperational(108,4,eur.id,eur.total8Comments,'Eur')"></i>
                  <i class="glyphicon glyphicon-comment commentColor" *ngIf="eur.total8Comments"
                     (click)="SaveCommentForOperational(108,4,eur.id,eur.total8Comments,'Eur')"></i>
                </div>
              </div>
            </td>
            <td class="bg-table-default">
              <div class="input-group">
                <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control"
                       name="partnerView" [(ngModel)]="eur.partnerView"
                       (change)="UpdateOperationalChanges(eur,'')" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!eur.partnerViewComments"
                     (click)="SaveCommentForOperational(108,6,eur.id,eur.partnerViewComments,'Eur')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="eur.partnerViewComments"
                     (click)="SaveCommentForOperational(108,6,eur.id,eur.partnerViewComments,'Eur')"></i>
                </div>
              </div>
            </td>
          </tr>
          <tr class="table-header">
            <td [(ngModel)]="futureAdd.id" hidden class="ml-10">{{futureAdd.id}}</td>
            <td>{{futureAdd.operationalName}}</td>
            <td class="bg-table-default"></td>
            <td class="bg-table-default"></td>
            <td class="bg-table-default">
              <div class="input-group">

                <input class="form-control" readonly name="sixPCurrentToFuture" [(ngModel)]="form.sixPCurrentToFuture" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!futureAdd.totalComments"
                     (click)="SaveCommentForOperational(108,3,futureAdd.id,futureAdd.totalComments,'FutureAdd')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="futureAdd.totalComments"
                     (click)="SaveCommentForOperational(108,3,futureAdd.id,futureAdd.totalComments,'FutureAdd')"></i>
                </div>
              </div>
            </td>
            <td class="bg-table-default">
              <div class="input-group">
                <input class="form-control" readonly name="total8" [(ngModel)]="futureAdd.total8" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!futureAdd.total8Comments"
                     (click)="SaveCommentForOperational(108,4,futureAdd.id,futureAdd.total8Comments,'FutureAdd')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="futureAdd.total8Comments"
                     (click)="SaveCommentForOperational(108,4,futureAdd.id,futureAdd.total8Comments,'FutureAdd')"></i>
                </div>
              </div>
            </td>
            <td class="bg-table-default">
              <div class="input-group">
                <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control"
                       name="partnerView" [(ngModel)]="futureAdd.partnerView"
                       (change)="UpdateOperationalChanges(futureAdd,'')" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline"
                     *ngIf="!futureAdd.partnerViewComments"
                     (click)="SaveCommentForOperational(108,6,futureAdd.id,futureAdd.partnerViewComments,'FutureAdd')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="futureAdd.partnerViewComments"
                     (click)="SaveCommentForOperational(108,6,futureAdd.id,futureAdd.partnerViewComments,'FutureAdd')"></i>
                </div>
              </div>
            </td>
          </tr>
          <tr class="table-header">
            <td [(ngModel)]="peakProduction.id" hidden class="ml-10">{{peakProduction.id}}</td>
            <td>{{peakProduction.operationalName}}</td>
            <td class="bg-table-default"></td>
            <td class="bg-table-default"></td>
            <td class="bg-table-default">
              <div class="input-group">

                <input class="form-control" readonly name="maxTotalProductionCurrentToFuture" [(ngModel)]="form.maxTotalProductionCurrentToFuture" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline"
                     *ngIf="!peakProduction.totalComments"
                     (click)="SaveCommentForOperational(108,3,peakProduction.id,peakProduction.totalComments,'PeakProduction')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="peakProduction.totalComments"
                     (click)="SaveCommentForOperational(108,3,peakProduction.id,peakProduction.totalComments,'PeakProduction')"></i>
                </div>
              </div>
            </td>
            <td class="bg-table-default">
              <div class="input-group">
                <input class="form-control" readonly name="total8" [(ngModel)]="peakProduction.total8" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline"
                     *ngIf="!peakProduction.total8Comments"
                     (click)="SaveCommentForOperational(108,4,peakProduction.id,peakProduction.total8Comments,'PeakProduction')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="peakProduction.total8Comments"
                     (click)="SaveCommentForOperational(108,4,peakProduction.id,peakProduction.total8Comments,'PeakProduction')"></i>
                </div>
              </div>
            </td>
            <td class="bg-table-default">
              <div class="input-group">
                <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control"
                       name="partnerView" [(ngModel)]="peakProduction.partnerView"
                       (change)="UpdateOperationalChanges(peakProduction,'')" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline"
                     *ngIf="!peakProduction.partnerViewComments"
                     (click)="SaveCommentForOperational(108,6,peakProduction.id,peakProduction.partnerViewComments,'PeakProduction')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="peakProduction.partnerViewComments"
                     (click)="SaveCommentForOperational(108,6,peakProduction.id,peakProduction.partnerViewComments,'PeakProduction')"></i>
                </div>
              </div>
            </td>
          </tr>
          <tr class="table-header">
            <td [(ngModel)]="firstProduction.id" hidden class="ml-10">{{firstProduction.id}}</td>
            <td>{{firstProduction.operationalName}}</td>
            <td class="bg-table-default"></td>
            <td class="bg-table-default"></td>
            <td class="bg-table-default">
              <div class="input-group">
                <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control"
                       name="total" [(ngModel)]="firstProduction.total"
                       (change)="UpdateOperationalChanges(firstProduction,'')" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline"
                     *ngIf="!firstProduction.totalComments"
                     (click)="SaveCommentForOperational(108,3,firstProduction.id,firstProduction.totalComments,'FirstProduction')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="firstProduction.totalComments"
                     (click)="SaveCommentForOperational(108,3,firstProduction.id,firstProduction.totalComments,'FirstProduction')"></i>
                </div>
              </div>
            </td>
            <td class="bg-table-default">
              <div class="input-group">
                <input class="form-control" readonly name="total8" [(ngModel)]="firstProduction.total8" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline"
                     *ngIf="!firstProduction.total8Comments"
                     (click)="SaveCommentForOperational(108,4,firstProduction.id,firstProduction.total8Comments,'FirstProduction')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="firstProduction.total8Comments"
                     (click)="SaveCommentForOperational(108,4,firstProduction.id,firstProduction.total8Comments,'FirstProduction')"></i>
                </div>
              </div>
            </td>
            <td class="bg-table-default">
              <div class="input-group">
                <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control"
                       name="partnerView" [(ngModel)]="firstProduction.partnerView"
                       (change)="UpdateOperationalChanges(firstProduction,'')" />
                <div class="input-group-addon">
                  <i class="glyphicon glyphicon-comment-outline"
                     *ngIf="!firstProduction.partnerViewComments"
                     (click)="SaveCommentForOperational(108,6,firstProduction.id,firstProduction.partnerViewComments,'FirstProduction')"></i>
                  <i class="glyphicon glyphicon-comment commentColor"
                     *ngIf="firstProduction.partnerViewComments"
                     (click)="SaveCommentForOperational(108,6,firstProduction.id,firstProduction.partnerViewComments,'FirstProduction')"></i>
                </div>
              </div>
            </td>
          </tr>


        </tbody>
      </table>
      <div class="form-group">
        <label>Comment:</label>
        <div class="input-group">
          <textarea class="form-control" rows="3" id="operationalComment" name="operationalComment"
                    [(ngModel)]="overview.operationalComment" [disabled]="isOnHold || isReadOnly"
                    (change)="SaveOperationalComment()">
                                </textarea>
          <div class="input-group-addon">
            <i class="glyphicon glyphicon-comment-outline" *ngIf="!operationalCommentItem.comments"
               (click)="SaveOperationalCommentPopUp(108,0,operationalCommentItem.id)"></i>
            <i class="glyphicon glyphicon-comment commentColor" *ngIf="operationalCommentItem.comments"
               (click)="SaveOperationalCommentPopUp(108,0,operationalCommentItem.id)"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-xs-12">
  <div class="row">
    <div class="col-xs-12 form-group">
      <strong *ngIf="!form.isUpstream">1.8 Greenhouse Gas Metrics</strong>
      <i>(Chevron net share, average over project life)</i>
      <span class="glyphicon glyphicon-question-sign" (click)="ShowGreenhouseGasPopUp()"></span>
    </div>


    <div class="col-xs-12 form-group">
      <div class="col-xs-4">
        <table class="table table-bordered">
          <tbody>
            <tr *ngIf="features?.BeforeGreenHouseGas">
              <td [(ngModel)]="totalEmission.id" hidden class="ml-10">{{totalEmission.id}}</td>
              <td colspan="2">
                <span>Total emissions (MM mtCO2e/yr)</span>
              </td>
              <td>
                <div class="input-group">
                  <input type="text" readonly [disabled]="isOnHold || isReadOnly"
                         class="form-control" name="greenHouseGasTotal"
                         [(ngModel)]="greenHouseGas.greenHouseGasTotal" />
                  <div class="input-group-addon">
                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!totalEmission.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,1,totalEmission.id,totalEmission.comments)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="totalEmission.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,1,totalEmission.id,totalEmission.comments)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">

                </div>
              </td>
            </tr>

            <tr *ngIf="features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Scope 1 emissions(MM mtCO2e/yr)" /> -->
                <span>Carbon Intensity (kgCO2e/BOE)</span>
              </td>

              <td [(ngModel)]="totalCarbonIntensity.id" hidden class="ml-10">
                {{totalCarbonIntensity.id}}
              </td>
              <td>

                <div class="input-group">

                  <input type="text" readonly [disabled]="isOnHold || isReadOnly"
                         class="form-control" name="greenHouseGasUnit"
                         [(ngModel)]="greenHouseGas.greenHouseGasUnit" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!totalCarbonIntensity.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,2,totalCarbonIntensity.id,totalCarbonIntensity.comments)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="totalCarbonIntensity.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,2,totalCarbonIntensity.id,totalCarbonIntensity.comments)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">

                </div>
              </td>
            </tr>

            <tr *ngIf="features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Scope 2 emissions(MM mtCO2e/yr)" /> -->
                <span>Carbon cost ($/BOE)</span>
              </td>

              <td [(ngModel)]="totalCarbonCost.id" hidden class="ml-10">
                {{totalCarbonCost.id}}
              </td>
              <td>

                <div class="input-group">

                  <input type="text" readonly [disabled]="isOnHold || isReadOnly"
                         class="form-control" name="greenHouseGasCost"
                         [(ngModel)]="greenHouseGas.greenHouseGasCost" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!totalCarbonCost.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,3,totalCarbonCost.id,totalCarbonCost.comments)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="totalCarbonCost.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,3,totalCarbonCost.id,totalCarbonCost.comments)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">

                </div>
              </td>
            </tr>


            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td [(ngModel)]="totalGHG.id" hidden class="ml-10">{{totalGHG.id}}</td>
              <td colspan="2">
                <span>Total emissions (MM mtCO2e/yr)</span>
              </td>
              <td>
                <div class="input-group">
                  <input type="text" readonly [disabled]="isOnHold || isReadOnly"
                         class="form-control" name="greenHouseGasTotalGHGEmission"
                         [(ngModel)]="greenHouseGas.greenHouseGasTotalGHGEmission" />
                  <div class="input-group-addon">
                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!totalGHG.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,4,totalGHG.id,totalGHG.comments)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="totalGHG.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,4,totalGHG.id,totalGHG.comments)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">

                </div>
              </td>
            </tr>

            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Scope 1 emissions(MM mtCO2e/yr)" /> -->
                <span>Scope 1 emissions(MM mtCO2e/yr)</span>
              </td>

              <td [(ngModel)]="scopeOneemissions.id" hidden class="ml-10">
                {{scopeOneemissions.id}}
              </td>
              <td>

                <div class="input-group">

                  <input type="text" readonly [disabled]="isOnHold || isReadOnly"
                         class="form-control" name="greenHouseGasScope1Emission"
                         [(ngModel)]="greenHouseGas.greenHouseGasScope1Emission" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!scopeOneemissions.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,5,scopeOneemissions.id,scopeOneemissions.comments)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="scopeOneemissions.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,5,scopeOneemissions.id,scopeOneemissions.comments)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">

                </div>
              </td>
            </tr>

            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Scope 2 emissions(MM mtCO2e/yr)" /> -->
                <span>Scope 2 emissions(MM mtCO2e/yr)</span>
              </td>

              <td [(ngModel)]="scopeTwoemissions.id" hidden class="ml-10">
                {{scopeTwoemissions.id}}
              </td>
              <td>

                <div class="input-group">

                  <input type="text" readonly [disabled]="isOnHold || isReadOnly"
                         class="form-control" name="greenHouseGasScope2Emission"
                         [(ngModel)]="greenHouseGas.greenHouseGasScope2Emission" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!scopeTwoemissions.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,6,scopeTwoemissions.id,scopeTwoemissions.comments)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="scopeTwoemissions.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,6,scopeTwoemissions.id,scopeTwoemissions.comments)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">

                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      <div class="col-xs-4">
        <table class="table table-bordered">
          <tbody>
            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Total Carbon Intensity(kgCO2e/boe)" /> -->
                <span>Total Carbon Intensity(kgCO2e/boe)</span>
              </td>

              <td [(ngModel)]="totalCarbon.id" hidden class="ml-10">{{totalCarbon.id}}</td>
              <td>

                <div class="input-group">

                  <input type="text" readonly [disabled]="isOnHold || isReadOnly"
                         class="form-control" name="greenHouseGasTotalCarbonIntensity"
                         [(ngModel)]="greenHouseGas.greenHouseGasTotalCarbonIntensity" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!totalCarbon.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,7,totalCarbon.id,totalCarbon.comments)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="totalCarbon.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,7,totalCarbon.id,totalCarbon.comments)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">

                </div>
              </td>
            </tr>

            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Methane Intensity(kgCO2e/boe)" /> -->
                <span>Methane Intensity(kgCO2e/boe)</span>
              </td>

              <td [(ngModel)]="methaneIntensity.id" hidden class="ml-10">{{methaneIntensity.id}}</td>
              <td>

                <div class="input-group">

                  <input type="text" readonly [disabled]="isOnHold || isReadOnly"
                         class="form-control" name="greenHouseGasMethaneIntensity"
                         [(ngModel)]="greenHouseGas.greenHouseGasMethaneIntensity" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!methaneIntensity.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,8,methaneIntensity.id,methaneIntensity.comments)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="methaneIntensity.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,8,methaneIntensity.id,methaneIntensity.comments)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">

                </div>
              </td>
            </tr>

            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Flaring Intensity(kgCO2e/boe)" /> -->
                <span>Flaring Intensity(kgCO2e/boe)</span>
              </td>

              <td [(ngModel)]="flaringIntensity.id" hidden class="ml-10">{{flaringIntensity.id}}</td>
              <td>

                <div class="input-group">

                  <input type="text" readonly [disabled]="isOnHold || isReadOnly"
                         class="form-control" name="greenHouseGasFlaringIntensity"
                         [(ngModel)]="greenHouseGas.greenHouseGasFlaringIntensity" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!flaringIntensity.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,9,flaringIntensity.id,flaringIntensity.comments)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="flaringIntensity.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,9,flaringIntensity.id,flaringIntensity.comments)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">

                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      <div class="col-xs-4">
        <table class="table table-bordered">
          <tbody>

            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Carbon cost ($/BOE)" /> -->
                <span>Carbon cost ($/BOE)</span>
              </td>

              <td [(ngModel)]="carbonCost.id" hidden class="ml-10">{{carbonCost.id}}</td>
              <td>

                <div class="input-group">

                  <input type="text" readonly [disabled]="isOnHold || isReadOnly"
                         class="form-control" name="greenHouseGasCarbonCost"
                         [(ngModel)]="greenHouseGas.greenHouseGasCarbonCost" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!carbonCost.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,10,carbonCost.id,carbonCost.comments)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="carbonCost.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,10,carbonCost.id,carbonCost.comments)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">

                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
    <!--charts-->
    <div class="row" *ngIf="(isUpstream && !features?.BeforeGreenHouseGas) ||
    (!isUpstream && !features?.BeforeGreenHouseGas) || (isUpstream && features?.BeforeGreenHouseGas)">
      <div class="col-sm-12 col-xs-12">
        <div class="row">
          <div class="form-group col-sm-2 col-xs-2" style="margin-top: 5px;">
            <label class="control-label form-label">&nbsp;</label>
            <label class="control-label form-label">Project data shown</label>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowChartModal()"></span>
          </div>
          <div class="form-group col-sm-2 col-xs-4">
            <div class="col-sm-3 col-xs-12" style="margin-top: 5px;">
              <label class="control-label form-label">From</label>
            </div>
            <div class="col-sm-9 col-xs-12" style="padding-left: 19px !important;">
              <input type="number" class="form-control" [(ngModel)]="formconfiguration.chartStartYear"
                     (change)="ChangeYears(formconfiguration)" [disabled]="isOnHold || isReadOnly" />
            </div>
          </div>
          <div class="form-group col-sm-2 col-xs-4">
            <div class="col-sm-1 col-xs-12" style="margin-top: 5px;">
              <label class="control-label form-label">To</label>
            </div>
            <div class="col-sm-9 col-xs-12">
              <input type="number" class="form-control" [(ngModel)]="formconfiguration.chartEndYear"
                     (change)="ChangeYears(formconfiguration)" [disabled]="isOnHold || isReadOnly" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="(isUpstream && !features?.BeforeGreenHouseGas) ||
    (!isUpstream && !features?.BeforeGreenHouseGas) || (isUpstream && features?.BeforeGreenHouseGas)">
      <div class="col-xs-6" *ngIf="isUpstream && features?.BeforeGreenHouseGas">
        <div class="col">
          <div class="row" style="border: 1px solid #efefef">
            <!-- <h5 style="color: black">Emissions and Carbon Intensity</h5> -->
            <p-chart #chartEmission type="line" [data]="EmissionAndCarbonIntensityChartData" height="400"
                     [options]="multiaxisEmissionOptions"></p-chart>
          </div>
        </div>
      </div>

      <div class="col-xs-6" *ngIf="!features?.BeforeGreenHouseGas">
        <div class="col">
          <div class="row" style="border: 1px solid #efefef">
            <!-- <h5 style="color: black">Total GHG Emission And Carbon Cost</h5> -->
            <p-chart #chartGHG type="line" [data]="TotalGHGEmissionAndCarbonCostChartData" height="400"
                     [options]="multiaxisOptions"></p-chart>
          </div>
        </div>
      </div>

      <div class="col-xs-6" *ngIf="isUpstream && features?.BeforeGreenHouseGas">
        <div class="col">
          <div class="row" style="border: 1px solid #efefef">
            <!-- <h5 style="color: black">Carbon Cost</h5> -->
            <p-chart #chartCarbon type="line" [data]="CarbonCostChartData" height="400"
                     [options]="multiaxisCarbonOptions"></p-chart>
          </div>
        </div>
      </div>

      <div class="col-xs-6" *ngIf="!features?.BeforeGreenHouseGas">
        <div class="col">
          <div class="row" style="border: 1px solid #efefef">
            <!-- <h5 style="color: black">Methane And Flaring And Total Intensity</h5> -->
            <p-chart #chartMethane type="line" [data]="MethaneAndFlaringAndTotalIntensityChartData" height="400"
                     [options]="IntensitymultiaxisOptions"></p-chart>
          </div>
        </div>
      </div>
    </div>



    <!--charts end-->
  </div>
</div>

<div class="col-xs-12 form-group">
</div>
<div class="row sub-section">
  <div class="col-xs-12">
    <div class="row" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnly}">
      <div class="col-xs-12">
        <span>
          <ckeditor #greenHousegaseditor [editor]="Editor" [config]="config"
                    [(ngModel)]="overview.greenHouseGasComment" id="greenHouseGasComment"
                    name="greenHouseGasComment" (ready)="onReady($event,'greenHouseGas')">
          </ckeditor>
        </span>
      </div>
    </div>
  </div>
</div>
<!-- <div class="col-xs-12 form-group">
</div> -->
<div class="col-xs-8">
  <div class="row ">
    <div class="col-xs-12 form-group">
      <strong *ngIf="form.isUpstream">1.9 Key Commercial, Co-Owner and Government Agreements and Issues</strong>
      <strong *ngIf="!form.isUpstream">1.9 Key Commercial, Co-Owner and Government Agreements and Issues</strong>
      <span class="glyphicon glyphicon-question-sign" (click)="ShowKeyCommercialPopUp()"></span>
    </div>
  </div>
 
</div>

<div class="row sub-section">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold ||
            overview.keyComAndPartneNa || isReadOnly}" *ngIf="!overview.keyComAndPartneNa">
        <span>
          <ckeditor #summaryeditor1 [editor]="Editor"
                    [(ngModel)]="overview.keyCommercialAndPartnerAgreements" [config]="config"
                    id="keyCommercialAndPartnerAgreements" name="keyCommercialAndPartnerAgreements"
                    (ready)="onReady($event,'KeyCommercial')">
          </ckeditor>

        </span>
      </div>
    </div>
  </div>
</div>

<div class="col-xs-8">
  <div class="row ">
    <div class="col-xs-12">
      <strong *ngIf="form.isUpstream">1.10 Environmental and Social Impact Considerations</strong>
      <strong *ngIf="!form.isUpstream">1.10  Environmental and Social Impact Considerations</strong>
      <span class="glyphicon glyphicon-question-sign" (click)="ShowEnvironmentalPopUp()"></span>

    </div>
  </div>
  <div class="col-xs-12">
    <i>Include highlights from ESHIA report and any other ESG considerations impacting opportunity.</i>
  </div>
</div>
<div class="row sub-section">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || overview.policyAppNa
                || isReadOnly}" *ngIf="!overview.policyAppNa">
        <span>
          <ckeditor #summaryeditor1 [editor]="Editor" [config]="config"
                    [(ngModel)]="overview.policyApplicable" id="policyApplicable" name="policyApplicable"
                    (ready)="onReady($event,'PolicyApplicable')">
          </ckeditor>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="col-xs-12 form-group">
</div>

<div class="col-xs-12">
  <div class="col-xs-12 form-group">
    <strong *ngIf="form.isUpstream">1.11 Signoffs </strong>
    <strong *ngIf="!form.isUpstream">1.11 Signoffs </strong>
    <span class="glyphicon glyphicon-question-sign" (click)="ShowSignoffsPopUp()"></span>
  </div>
  <div class="col-xs-12">
    <table class="table table-bordered">
      <tbody>
        <tr class="bg-table-default table-header">
          <td></td>
          <td width="20%">Name</td>
          <td width="20%">Position</td>
          <td width="20%">Signature</td>
          <td width="20%">Date</td>
        </tr>
        <tr *ngFor="let signOffItem of signOffData let i = index">
          <td [(ngModel)]="signOffItem.id" hidden class="ml-10">{{signOffItem.id}}</td>
          <td [(ngModel)]="signOffItem.signoffName" hidden class="ml-10">{{signOffItem.signoffName}}</td>
          <td>{{signOffItem.signoffName}}</td>
          <td>
            <textarea type="text" rows="3"
              [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" class="form-control"
              [(ngModel)]="signOffItem.name" (change)="UpdateSignOff(signOffItem)">
                    </textarea>
          </td>
          <td>
            <textarea type="text" rows="3"
              [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" class="form-control"
              [(ngModel)]="signOffItem.position" (change)="UpdateSignOff(signOffItem)">
                    </textarea>
          </td>
          <td>
            <textarea type="text" rows="3"
              [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" class="form-control"
              [(ngModel)]="signOffItem.signature" (change)="UpdateSignOff(signOffItem)">
                    </textarea>
          </td>
          <td class="form-date">
            <!-- <mat-form-field>
                        <input matInput [matDatepicker]="picker" placeholder="Choose a date" 
                        (click)="picker.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker class="style:display:none" #picker></mat-datepicker>
                      </mat-form-field>       -->
            <input class="form-control" [(ngModel)]="signOffItem.signoffDate"
              (dateChange)="UpdateSignOff(signOffItem)" [matDatepicker]="picker"
              [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
              (click)="picker.open();utilityService.removeCDKAnouncer()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</div>


<div class="col-xs-8">
  <div class="row">
    <div class="col-xs-12 form-group">
      <strong *ngIf="form.isUpstream">1.12 External Announcement Status</strong>
      <strong *ngIf="!form.isUpstream">1.12 External Announcement Status</strong>
      <span class="glyphicon glyphicon-question-sign" (click)="ShowExtAnnouncementPopUp()"></span>
    </div>
  </div>
</div>

<div class="row sub-section">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || overview.externalAnnouncementNa
            || isReadOnly}" *ngIf="!overview.externalAnnouncementNa">
        <span>
          <ckeditor #externaleditor [editor]="Editor" [config]="config"
                    [(ngModel)]="overview.externalAnnouncementStatus" id="externalAnnouncementStatus"
                    name="externalAnnouncementStatus" (ready)="onReady($event,'External')">
          </ckeditor>
        </span>
      </div>
    </div>
  </div>
</div>
