<div class="fid-form ssbod">
    <div class="row">
        <div class="col-md-12">
                <app-general [viewModel]="viewModel" [type]="type" [formType]="formType"  [isReadOnlyGlobalProjectLevel]="isReadOnlyGlobalProjectLevel"></app-general>
        </div>
    </div>
    <div class="row sub-section" [ngClass]="{'disabledNoOfCasesDiv': viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel}">
        <div class="col-xs-12 form-group">
            <strong><span>8.1 </span>Lessons Learned:</strong>
            <i> Describe key insights identified during work on this project </i> 
            <span class="glyphicon glyphicon-question-sign" (click)="ShowLessonLearnedModal()"></span>       
        </div>
        <form [formGroup]="formLL">
            <ng-container formArrayName="lessonsLearned">
                <div *ngFor="let control of lessonsLearned.controls ;let i = index;"> 
                    <div  [formGroupName]="i">
                        <div class="row form-group">
                            <div class="col-sm-6 col-xs-12">
                                <span class="col-sm-4 col-xs-12">
                                    <span>{{i+1}}</span>
                                    . Lesson Learned :
                                </span>
                                <span class="col-sm-8 col-xs-12" style="text-align:left">
                                    <select formControlName="lessonLearnedCategoryId" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" class="form-control" (change)="onCategoryChange($event,i+1,control)"> 
                                        <option value="-1" selected>Please select category for lesson learned</option>
                                        <option *ngFor="let category of LLCategoryData" [value]="category.id">{{category.name}}</option>
                                    </select>
                                </span>
                            </div>
                        
                            <div class="col-sm-6 col-xs-12">
                                <span class="col-sm-8 col-xs-12" style="text-align:left">
                                    <input formControlName="title" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" placeholder="Enter title for lesson learned" class="form-control" 
                                    (blur)="onBlurTitleUpdate($event,i+1,'LessonLearned')" style="text-align:left;width:104%!important" />
                                </span>
                                <span [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="col-sm-4 col-xs-12" style="text-align:right">
                                    <span class="glyphicon glyphicon-trash glyphicon-custom-trash" (click)="Delete(i+1,'LessonLearned')">
                                        <span class="delete-btn-txt-font">Delete</span>
                                    </span>
                                </span>
                            </div>
                    
                        </div>
                        <div class="row form-group">
                            <div class="col-sm-12">
                            <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': LLTypeData[i].naLessonLearned || viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel}">
                                    <ckeditor formControlName="description" #lessonLearned [editor]="Editor" [config]="config" id="lessonLearned" name="lessonLearned"
                                    (ready)="onReady($event,i+1,'LessonLearned')"></ckeditor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
            <div class="col-sm-6 col-xs-12">
                <button type="button" style="color: white !important;" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" class="btn btn-sm btn-primary" (click)="addNewLL('LessonLearned')" >Add </button>
            </div>
            </ng-container>
        </form>
    </div>
    <div class="row sub-section">
        <div class="col-xs-12 form-group">
            <strong><span>8.2 </span>Plan:</strong>
            <i> Include a sharing plan for the insights, the target Chevron organizations for receiving the information and approximate timing.  Also, if available, include a summary of how any significant gap will be closed</i>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowPlanModal()"></span>    
        </div>
        <div >
            <div>
                <div class="row form-group">
                    <div class="col-sm-12 col-xs-12">
                        <span [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="col-sm-12 col-xs-12" style="text-align:right">
                            <span class="glyphicon glyphicon-trash glyphicon-custom-trash" (click)="Delete(1,'Plan')">
                                <span class="delete-btn-txt-font">Delete</span>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-sm-12">
                        <div ></div>
                        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel}">
                            <ckeditor #plan [editor]="Editor" [config]="config" id="plan" name="plan" [(ngModel)]="PlanTypeData.description"
                            (ready)="onReady($event,1,'Plan')"></ckeditor>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row sub-section">
        <div class="col-xs-12 form-group">
            <div class="col-xs-12" style="padding: 0">
                <strong><span>8.3 </span>Key Decisions:</strong>
                <i> Describe key decisions taken during work on this project phase</i>
                <span class="glyphicon glyphicon-question-sign" (click)="ShowKeyDecisionsModal()"></span> 
                <div class="row pl-16" *ngIf="!viewModel.IsOldVersionOfForms">
                    <span>
                        <mat-slide-toggle (change)="NAKeyDecisionChange($event)" [checked]="naKeyDecisionDisabled"
                            [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel">
                            <span class="app-font">N/A</span>
                        </mat-slide-toggle>
                    </span>
                </div>
            </div>
        </div>
        <form [formGroup]="formKD" *ngIf="!naKeyDecisionDisabled">
            <ng-container formArrayName="keyDecision">
                <div  *ngFor="let control of keyDecision.controls ;let i = index;">
                    <div [formGroupName]="i">
                        <div class="row form-group">
                            <div [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="col-sm-6 col-xs-12">
                                <span class="col-sm-4 col-xs-12">
                                    <span>{{i+1}}</span>
                                    . Decision :
                                </span>
                                <span class="col-sm-8 col-xs-12" style="text-align:left">
                                    <input [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" placeholder="Enter title for key decision" formControlName="title" style="text-align:left;width:104%!important" class="form-control"
                                    (blur)="onBlurTitleUpdate($event,i+1,'KeyDecision')"/>
                                </span>
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <div class="col-sm-8 col-xs-12"></div>
                                <span [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="col-sm-4 col-xs-12" style="text-align:right">
                                    <span class="glyphicon glyphicon-trash glyphicon-custom-trash" (click)="Delete(i+1,'KeyDecision')">
                                        <span class="delete-btn-txt-font">Delete</span>
                                    </span>
                                </span>
                            </div>
                        </div>               
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel}">
                                    <ckeditor formControlName="description" #keyDecision [editor]="Editor" [config]="config" id="keyDecision" name="keyDecision" 
                                    (ready)="onReady($event,i+1,'KeyDecision')"></ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div class="col-sm-6 col-xs-12">
                    <button type="button" style="color: white !important;" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" class="btn btn-sm btn-primary" (click)="addNewLL('KeyDecision')" >Add </button>
                </div>
            </ng-container>
        </form>
    </div>
</div>
