<h3 mat-dialog-title><b>Session expired</b></h3>

<div mat-dialog-content *ngIf="displayModal">
    <p>Session is going to be expired. Do you want to continue?</p>
</div>
<div mat-dialog-content *ngIf="!displayModal">
    <p>Session is expired due to inactivity. Please Refresh</p>
</div>

<div mat-dialog-actions style="float:right;">
    <!-- <button *ngIf="displayModal" mat-button class="btn btn-primary" (click)="setTimer()">Continue</button> &nbsp; &nbsp; -->
    <button mat-button class="btn btn-primary" style="color:white !important"   (click)="refreshPage()">Refresh</button>
</div>