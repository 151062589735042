import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/Shared/notification.service';
import { Overview } from 'src/app/Model/Overview.model';
import { Ownership } from 'src/app/Model/Ownership.model';
import { Milestone } from 'src/app/Model/Milestone.model';
import { KeyEconomicMetrics } from 'src/app/Model/KeyEconomicMetrics.model';
import { CapitalAndExpenditures } from 'src/app/Model/CapitalAndExpenditures.model';
import { ProjectData } from 'src/app/Model/ProjectData.model';
import { Form } from 'src/app/Model/Form.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { OverviewService } from 'src/app/forms/FEEDServices/overview.service';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent, CommentDialogModel } from 'src/app/Modals/comment-dialog/comment-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/Modals/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormRisks } from 'src/app/Model/FormRisks.model';
import { FormComment } from 'src/app/Model/FormComment.model';
import { GreenHouseGas } from 'src/app/Model/GreenHouseGas.model';
import { Operational } from 'src/app/Model/Operational.model';
import { UtilityService } from 'src/app/Shared/utility.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ChartsService } from 'src/app/forms/FEEDServices/charts.service';
import { OverviewChartModel } from 'src/app/Model/Charts/OverviewCharts.model';
import { Chart } from 'src/app/Model/Charts/Chart.model';
import { FormConfigurationModel } from 'src/app/Model/Charts/FormConfiguration.model';
import { UIChart } from 'primeng/chart';
import { FormChart } from 'src/app/Model/Charts/FormChart.model';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})

export class OverviewComponent implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
  
    toolbar: [
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      // //  'outdent', 'indent', '|','insertTable','|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
    ],
    autosave: {
      save(editor) {
      }
    }
  }
  // // overViewForm!: FormGroup
  presentvalue!: string;
  overview = new Overview();
  formComment: FormComment[] = [];
  FormCommentItem = new FormComment();
  totalGHG = new FormComment();
  greenHouseGas = new GreenHouseGas();
  scopeOneemissions = new FormComment();
  scopeTwoemissions = new FormComment();
  totalCarbon = new FormComment();
  methaneIntensity = new FormComment();
  flaringIntensity = new FormComment();
  carbonCost = new FormComment();
  formRisks = new FormRisks();
  form = new Form();
  projectData = new ProjectData();
  phasemileStone: Milestone[] = [];
  coownermileStone: Milestone[] = [];
  ownershipData: Ownership[] = [];
  keyEconomicMetricsData: KeyEconomicMetrics[] = [];
  capitalAndExpendituresData: CapitalAndExpenditures[] = [];
  capitalAndExpendituresAfterCETotalData: CapitalAndExpenditures[] = [];
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  formId: number = 0;
  formCase: string | null = '';
  totalFacilities?: number = 0;
  totalDrillingAndCompletions?: number = 0;
  totalContingencyEscalation?: number = 0;
  totalOtherJointCosts?: number = 0;
  totalOtherSoleCosts?: number = 0;
  totalAppropriatedCal?: number = 0;
  totalExpenseOnCapital?: number = 0;
  totalCommitmentCal?: number = 0;
  totalCoOwner?: number = 0;
  ckeditorText: string = '';
  commentText?: string = '';
  @Input() isOnHold: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() projectId: number = 0;
  isLastRow: boolean = false;
  totalExpenseAfterCEOnCapitalSum: number = 0;
  totalCoOwnerAfterCESum: number = 0;
  totalCommitmentESum: number = 0;
  appAmtAPR?: number = 0;
  ARTotalCommitmen: number = 0;
  addExpenseOnCapital: number = 0;
  addtotalCommitment: number = 0;
  addCoOwner: number = 0;
  versionId: number = 0;
  operationalCommentItem = new FormComment();
  netReservebooking = new Operational();
  eur = new Operational();
  futureAdd = new Operational();
  peakProduction = new Operational();
  firstProduction = new Operational();
  TotalGHGEmissionAndCarbonCostChartData: any;
  MethaneAndFlaringAndTotalIntensityChartData: any;
  multiaxisOptions: any;
  netRevenueInterest: any;
  IntensitymultiaxisOptions: any;
  OverviewChartModel = new OverviewChartModel();
  Carbonchartdata = new Chart();
  TotalGHGEmmission = new Chart();
  Methaneintensitychartdata = new Chart();
  flaringintensitychartdata = new Chart();
  totalintensitychartdata = new Chart();
  formconfiguration = new FormConfigurationModel();
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;
  @ViewChild('chartGHG') chartGHG!: UIChart;
  @ViewChild('chartMethane') chartMethane!: UIChart;
  FormChartModel = new FormChart();
  formData = new Form();
  constructor(private go36ProjectService: Go36projectService, private notifyService: NotificationService, private overviewService: OverviewService, public dialog: MatDialog, private route: ActivatedRoute, private router: Router, public utilityService: UtilityService, private chartService: ChartsService) {
  }
  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    // // this.projectId= Number(this.route.snapshot.paramMap.get("projectId"));

    this.formCase = (this.route.snapshot.paramMap.get("formCase"));
    if (this.formCase == 'Create') {
      this.formId = Number(this.route.snapshot.paramMap.get("formId"));
      this.projectId = Number(this.route.snapshot.paramMap.get("projectId"));
      this.versionId = Number(this.route.snapshot.paramMap.get('versionName'));
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          this.formId = params['formId'];
          if (this.projectId != 0) {
            this.projectId = params['projectId'];
          }
          this.versionId = params['versionName'];
        })
    }
    this.LoadOverviewCharts();
    this.createDefaultData();
  }

  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }

  onReady(editor, name) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.UpdateckeditorChanges(editor.getData(), name);
        }
      }
    });
  }

  createDefaultData() {
    this.form.projectId = this.projectId;
    this.form.active = true;
    this.form.createdBy = this.provId;
    this.form.formType = 'FEED';
    this.form.order = 1;
    this.form.id = this.formId;
    this.form.version = this.versionId;
    this.form.ownership = [];
    this.form.milestone = [];
    this.form.keyEconomicMetrics = [];
    this.form.capitalAndExpenditures = [];
    this.form.version= this.versionId;
    this.overviewService.createDefaultData(this.form).subscribe(res => {

      this.form = res;
      this.ownershipData = res.ownershipsList;
      this.ownershipData.forEach(obj => {
        if (obj.workingInterest != null) {
          obj.workingInterest = Number(obj.workingInterest).toFixed(1).toString();
          obj.workingInterest = obj.workingInterest + '%';
        }
        if (obj.netRevenueInterest != null) {
          obj.netRevenueInterest = Number(obj.netRevenueInterest).toFixed(1).toString();
          obj.netRevenueInterest = obj.netRevenueInterest + '%';
        }
      });

      this.phasemileStone = res.milestoneList.filter((x: { milestoneType: string; }) => x.milestoneType == 'Company');
      this.coownermileStone = res.milestoneList.filter((y: { milestoneType: string; }) => y.milestoneType == 'Partner');
      this.keyEconomicMetricsData = res.keyEconomicMetricList;

      if (this.form.formType == 'EPC') {
        this.keyEconomicMetricsData.forEach(obj => {
          if (obj.order == 1 || obj.order == 5 || obj.order == 6) {
            if (obj.fullCycle != null) {
              obj.fullCycle = Number(obj.fullCycle).toFixed(1).toString();
              // //   obj.fullCycle = this.NumberWithCommas(obj.fullCycle);
              // //    obj.fullCycle = '$' + obj.fullCycle;
            }
            if (obj.feedForward != null) {
              obj.feedForward = Number(obj.feedForward).toFixed(1).toString();
              // //    obj.feedForward = this.NumberWithCommas(obj.feedForward);
              // //    obj.feedForward = '$' + obj.feedForward;
            }
            if (obj.pointForward != null) {
              obj.pointForward = Number(obj.pointForward).toFixed(1).toString();
              // //    obj.pointForward = this.NumberWithCommas(obj.pointForward);
              // //    obj.pointForward = '$' + obj.pointForward;
            }
          }
        });

        this.keyEconomicMetricsData.forEach(obj => {
          if (obj.order == 2) {
            if (obj.fullCycle != null) {
              obj.fullCycle = Number(obj.fullCycle).toFixed(1).toString();
              // //    obj.fullCycle = this.NumberWithCommas(obj.fullCycle);
              obj.fullCycle = obj.fullCycle + '%';
            }
            if (obj.feedForward != null) {
              obj.feedForward = Number(obj.feedForward).toFixed(1).toString();
              // //   obj.feedForward = this.NumberWithCommas(obj.feedForward);
              obj.feedForward = obj.feedForward + '%';
            }
            if (obj.pointForward != null) {
              obj.pointForward = Number(obj.pointForward).toFixed(1).toString();
              // //      obj.pointForward = this.NumberWithCommas(obj.pointForward);
              obj.pointForward = obj.pointForward + '%';
            }
          }
        });

        this.keyEconomicMetricsData.forEach(obj => {
          if (obj.order == 3) {
            if (obj.fullCycle != null) {
              // //    obj.fullCycle = this.NumberWithCommas(obj.fullCycle);
              obj.fullCycle = Number(obj.fullCycle).toFixed(2).toString();
            }
            if (obj.feedForward != null) {
              // //     obj.feedForward = this.NumberWithCommas(obj.feedForward);
              obj.feedForward = Number(obj.feedForward).toFixed(2).toString();
            }
            if (obj.pointForward != null) {
              // //       obj.pointForward = this.NumberWithCommas(obj.pointForward);
              obj.pointForward = Number(obj.pointForward).toFixed(2).toString();
            }
          }
        });

        this.keyEconomicMetricsData.forEach(obj => {
          if (obj.order == 4) {
            if (obj.fullCycle != '') {
              obj.fullCycle = Number(obj.fullCycle).toFixed(0).toString();
            }
            if (obj.feedForward != '') {
              obj.feedForward = Number(obj.feedForward).toFixed(0).toString();
            }
            if (obj.pointForward != '') {
              obj.pointForward = Number(obj.pointForward).toFixed(0).toString();
            }
          }
        });
      }
      this.projectData = res.projectData;
      this.overview = res.overview;
      if (this.overview.estimatedTotalCommit != null) {
        this.overview.estimatedTotalCommit = this.AllowOneDecimal(Number(this.overview.estimatedTotalCommit));
      }

      this.formRisks = res.formRisk;
      this.greenHouseGas = res.greenHouseGasModel;
      if (this.greenHouseGas.greenHouseGasTotalGHGEmission == 0) {
        this.greenHouseGas.greenHouseGasTotalGHGEmission = "0.000";
      }
      if (this.greenHouseGas.greenHouseGasScope1Emission == 0) {
        this.greenHouseGas.greenHouseGasScope1Emission = "0.000";
      }
      if (this.greenHouseGas.greenHouseGasScope2Emission == 0) {
        this.greenHouseGas.greenHouseGasScope2Emission = "0.000";
      }
     
      if(this.greenHouseGas.greenHouseGasTotalCarbonIntensity==0){
        this.greenHouseGas.greenHouseGasTotalCarbonIntensity="0.00";
      }
      if(this.greenHouseGas.greenHouseGasMethaneIntensity==0){
        this.greenHouseGas.greenHouseGasMethaneIntensity="0.00";
      }
      if(this.greenHouseGas.greenHouseGasFlaringIntensity==0){
        this.greenHouseGas.greenHouseGasFlaringIntensity="0.00";
      }
      if(this.greenHouseGas.greenHouseGasCarbonCost==0){
        this.greenHouseGas.greenHouseGasCarbonCost="0.00";
      }
      this.totalGHG = res.formComment.find((x: { formFieldId: number; }) => x.formFieldId == 4);
      if (!this.totalGHG) {
        this.totalGHG = new FormComment();
      }
      this.scopeOneemissions = res.formComment.find((x: { formFieldId: number; }) => x.formFieldId == 5);
      if (!this.scopeOneemissions) {
        this.scopeOneemissions = new FormComment();
      }
      this.scopeTwoemissions = res.formComment.find((x: { formFieldId: number; }) => x.formFieldId == 6);
      if (!this.scopeTwoemissions) {
        this.scopeTwoemissions = new FormComment();
      }
      this.totalCarbon = res.formComment.find((x: { formFieldId: number; }) => x.formFieldId == 7);
      if (!this.totalCarbon) {
        this.totalCarbon = new FormComment();
      }
      this.methaneIntensity = res.formComment.find((x: { formFieldId: number; }) => x.formFieldId == 8);
      if (!this.methaneIntensity) {
        this.methaneIntensity = new FormComment();
      }
      this.flaringIntensity = res.formComment.find((x: { formFieldId: number; }) => x.formFieldId == 9);
      if (!this.flaringIntensity) {
        this.flaringIntensity = new FormComment();
      }
      this.carbonCost = res.formComment.find((x: { formFieldId: number; }) => x.formFieldId == 10);
      if (!this.carbonCost) {
        this.carbonCost = new FormComment();
      }
      this.operationalCommentItem = res.formComment.find((x: { formFieldId: number; }) => x.formFieldId == 0);
      if (!this.operationalCommentItem) {
        this.operationalCommentItem = new FormComment();
      }

      // Start code is for upstream project 
      // // this.operationalList=res.operationalList
      this.netRevenueInterest = this.ownershipData[0].netRevenueInterest;
      if (this.netRevenueInterest == null || this.netRevenueInterest == '') {
        this.netRevenueInterest = 0;
      }
      else {
        this.netRevenueInterest = this.netRevenueInterest.replace('%', '');
        // //  this.netRevenueInterest=this.netRevenueInterest.toFixed(1);
      }
      this.netReservebooking = res.operationalList.find((x: { operationalName: string; }) => x.operationalName == "Net P1 Reserves booking at AR (mmboe)");

      if (!this.netReservebooking) {
        this.netReservebooking = new Operational();
      }
      else {
        if (this.netReservebooking.total == null) {
          this.netReservebooking.total = 0;
        }
        if (this.netRevenueInterest != 0 && this.netReservebooking.total != 0) {
          this.netReservebooking.total8 = (this.netReservebooking.total / this.netRevenueInterest)
          this.netReservebooking.total8 = (this.netReservebooking.total8 * 100);
          this.netReservebooking.total8 = Number(this.netReservebooking.total8).toFixed(1).toString();
        }
        if (this.netReservebooking.oil != null) {
          this.netReservebooking.oil = Number(this.netReservebooking.oil).toFixed(1).toString();
        }
        if (this.netReservebooking.gas != null) {
          this.netReservebooking.gas = Number(this.netReservebooking.gas).toFixed(1).toString();
        }
        if (this.netReservebooking.total != null) {
          this.netReservebooking.total = Number(this.netReservebooking.total).toFixed(1).toString();
        }
        if (this.netReservebooking.partnerView != null) {
          this.netReservebooking.partnerView = Number(this.netReservebooking.partnerView).toFixed(1).toString();
        }
      }
      this.eur = res.operationalList.find((x: { operationalName: string; }) => x.operationalName == "EUR (net mmboe)");
      if (!this.eur) {
        this.eur = new Operational();
      }
      else {
        if (this.eur.total == null) {
          this.eur.total = 0;
        }
        if (this.netRevenueInterest != 0 && this.eur.total != 0) {
          this.eur.total8 = (this.eur.total / this.netRevenueInterest)
          this.eur.total8 = (this.eur.total8 * 100);
          this.eur.total8 = Number(this.eur.total8).toFixed(1).toString();
        }
        if (this.eur.total != null) {
          this.eur.total = Number(this.eur.total).toFixed(1).toString();
        }
        if (this.eur.partnerView != null) {
          this.eur.partnerView = Number(this.eur.partnerView).toFixed(1).toString();
        }
      }
      this.futureAdd = res.operationalList.find((x: { operationalName: string; }) => x.operationalName == "EV6P + Future Adds (net mmboe)");
      if (!this.futureAdd) {
        this.futureAdd = new Operational();
      }
      else {
        if (this.futureAdd.total == null) {
          this.futureAdd.total = 0;
        }
        if (this.netRevenueInterest != 0 && this.futureAdd.total != 0) {
          this.futureAdd.total8 = (this.futureAdd.total / this.netRevenueInterest)
          this.futureAdd.total8 = (this.futureAdd.total8 * 100);
          this.futureAdd.total8 = Number(this.futureAdd.total8).toFixed(1).toString();
        }
        if (this.futureAdd.total != null) {
          this.futureAdd.total = Number(this.futureAdd.total).toFixed(1).toString();
        }
        if (this.futureAdd.partnerView != null) {
          this.futureAdd.partnerView = Number(this.futureAdd.partnerView).toFixed(1).toString();
        }
      }
      this.peakProduction = res.operationalList.find((x: { operationalName: string; }) => x.operationalName == "Peak Production Rate (mboed)");
      if (!this.peakProduction) {
        this.peakProduction = new Operational();
      }
      else {
        if (this.peakProduction.total == null) {
          this.peakProduction.total = 0;
        }
        if (this.netRevenueInterest != 0 && this.peakProduction.total != 0) {
          this.peakProduction.total8 = (this.peakProduction.total / this.netRevenueInterest)
          this.peakProduction.total8 = (this.peakProduction.total8 * 100);
          this.peakProduction.total8 = Number(this.peakProduction.total8).toFixed(1).toString();
        }
        if (this.peakProduction.total != null) {
          this.peakProduction.total = Number(this.peakProduction.total).toFixed(1).toString();
        }
        if (this.peakProduction.partnerView != null) {
          this.peakProduction.partnerView = Number(this.peakProduction.partnerView).toFixed(1).toString();
        }
      }
      this.firstProduction = res.operationalList.find((x: { operationalName: string; }) => x.operationalName == "First 12 months Production Attainment (mboed)");
      if (!this.firstProduction) {
        this.firstProduction = new Operational();
      }
      else {
        if (this.firstProduction.total == null) {
          this.firstProduction.total = 0;
        }
        if (this.netRevenueInterest != 0 && this.firstProduction.total != 0) {
          this.firstProduction.total8 = (this.firstProduction.total / this.netRevenueInterest)
          this.firstProduction.total8 = (this.firstProduction.total8 * 100);
          this.firstProduction.total8 = Number(this.firstProduction.total8).toFixed(1).toString();
        }
        if (this.firstProduction.total != null) {
          this.firstProduction.total = Number(this.firstProduction.total).toFixed(1).toString();
        }
        if (this.firstProduction.partnerView != null) {
          this.firstProduction.partnerView = Number(this.firstProduction.partnerView).toFixed(1).toString();
        }
      }
      //End code is for upstream project 

      this.capitalAndExpendituresAfterCETotalData = res.capitalAndExpenditureList.filter((x: { sectionAfterCetotal: any; }) => x.sectionAfterCetotal);
      this.capitalAndExpendituresData = res.capitalAndExpenditureList.filter((x: { sectionAfterCetotal: any; }) => x.sectionAfterCetotal == false);
      //Code to calculate total C&E data
      let facilitySum = 0;
      let drillingSum = 0;
      let contingencyEscSum = 0;
      let jointCostsSum = 0;
      let soleCostsSum = 0;
      let totalAppropriatedSum = 0;
      let totalExpenseOnCapitalSum = 0;
      let totalCommitmentSum = 0;
      let totalCoOwnerSum = 0;

      for (let data of this.capitalAndExpendituresData) {
        // //  res.capitalAndExpenditureList[i].totalCommitmentValue=Number(res.capitalAndExpenditureList[i].facilities) + Number(res.capitalAndExpenditureList[i].drillingAndCompletions) + Number(res.capitalAndExpenditureList[i].contingencyEscalation) + Number(res.capitalAndExpenditureList[i].otherJointCosts) + Number(res.capitalAndExpenditureList[i].otherSoleCosts) + Number(res.capitalAndExpenditureList[i].totalAppropriated) + Number(res.capitalAndExpenditureList[i].expenseOnCapital)
        data.totalAppropriated = Number(data.facilities) + Number(data.drillingAndCompletions) + Number(data.contingencyEscalation) + Number(data.otherJointCosts) + Number(data.otherSoleCosts);
        if (data.capitalAndExpenditureName == 'C&E Total' || data.capitalAndExpenditureName == 'Capex Total') {
          data.totalCommitmentValue = Number(data.totalAppropriated);
        } else {
          data.totalCommitmentValue = Number(data.totalAppropriated) + Number(data.expenseOnCapital);
        }
        if (data.capitalAndExpenditureName == 'C&E Total' || data.capitalAndExpenditureName == 'Capex Total') {
          totalCommitmentSum += Number(data.totalCommitmentValue);
        } else {
          totalCommitmentSum += Number(data.totalCommitmentValue) - Number(data.expenseOnCapital);
        }
        // //   totalCommitmentSum+= Number(data.totalCommitmentValue)-Number(data.expenseOnCapital);
      }

      this.totalCommitmentCal = totalCommitmentSum;

      for (let data of this.capitalAndExpendituresData) {
        if (data.facilities) {
          facilitySum += Number(data.facilities);

        }
        if (data.drillingAndCompletions) {
          drillingSum += Number(data.drillingAndCompletions);
        }
        if (data.contingencyEscalation) {
          contingencyEscSum += Number(data.contingencyEscalation);
        }
        if (data.otherJointCosts) {
          jointCostsSum += Number(data.otherJointCosts);
        }
        if (data.otherSoleCosts) {
          soleCostsSum += Number(data.otherSoleCosts);
        }
        if (data.totalAppropriated) {
          totalAppropriatedSum += Number(data.totalAppropriated);
        }
        if (data.expenseOnCapital) {
          if (data.capitalAndExpenditureName != 'C&E Total' && data.capitalAndExpenditureName != 'Capex Total') {
            totalExpenseOnCapitalSum += Number(data.expenseOnCapital);
          }
        }
        if (data.coOwnerView) {
          totalCoOwnerSum += Number(data.coOwnerView);
        }
      }
      this.totalFacilities = facilitySum;
      this.totalDrillingAndCompletions = drillingSum;
      this.totalContingencyEscalation = contingencyEscSum;
      this.totalOtherJointCosts = jointCostsSum;
      this.totalOtherSoleCosts = soleCostsSum;
      this.totalAppropriatedCal = totalAppropriatedSum;
      this.totalExpenseOnCapital = totalExpenseOnCapitalSum;
      this.totalCoOwner = totalCoOwnerSum;
      // // if(this.capitalAndExpendituresData[2].totalAppropriated){
      if (this.form.formType == 'EPC') {
        this.appAmtAPR = Number(this.capitalAndExpendituresData.find(i => i.capitalAndExpenditureName == "Early EPC")?.totalAppropriated);
      }
      else if (this.form.formType == 'LLE') {
        this.appAmtAPR = Number(this.capitalAndExpendituresData.find(i => i.capitalAndExpenditureName == "Long Leads")?.totalAppropriated);
      }
      else if (this.form.formType == 'FEED') {
        this.appAmtAPR = Number(this.capitalAndExpendituresData.find(i => i.capitalAndExpenditureName == "FEED")?.totalAppropriated);
      }

      this.checkListPrecision(this.capitalAndExpendituresData);

      // //  this.totalFacilities=facilitySum;​
      // Code to calulate data after C&E Total
      for (let i = 0; i < this.capitalAndExpendituresAfterCETotalData.length; i++) {
        if (this.capitalAndExpendituresAfterCETotalData[i].expenseOnCapital) {
          this.totalExpenseAfterCEOnCapitalSum += Number(this.capitalAndExpendituresAfterCETotalData[i].expenseOnCapital);
        }
        if (this.capitalAndExpendituresAfterCETotalData[i].coOwnerView) {
          this.totalCoOwnerAfterCESum += Number(this.capitalAndExpendituresAfterCETotalData[i].coOwnerView);
        }
        if (this.capitalAndExpendituresAfterCETotalData[i].totalCommitment) {
          this.totalCommitmentESum += Number(this.capitalAndExpendituresAfterCETotalData[i].totalCommitment);
        }
      }
      this.totalCommitmentESum = this.totalCommitmentESum + this.totalExpenseOnCapital;
      this.addExpenseOnCapital = this.totalExpenseOnCapital + this.totalExpenseAfterCEOnCapitalSum;
      this.addtotalCommitment = this.totalCommitmentCal + this.totalCommitmentESum;
      this.addCoOwner = this.totalCoOwner + this.totalCoOwnerAfterCESum;
      this.checkListPrecision(this.capitalAndExpendituresAfterCETotalData);
      this.totalFacilities = this.AllowOneDecimal(facilitySum);
      this.totalDrillingAndCompletions = this.AllowOneDecimal(drillingSum);
      this.totalContingencyEscalation = this.AllowOneDecimal(contingencyEscSum);
      this.totalOtherJointCosts = this.AllowOneDecimal(jointCostsSum);
      this.totalOtherSoleCosts = this.AllowOneDecimal(soleCostsSum);
      this.totalAppropriatedCal = this.AllowOneDecimal(totalAppropriatedSum);
      this.totalExpenseOnCapital = this.AllowOneDecimal(totalExpenseOnCapitalSum);
      this.totalCoOwner = this.AllowOneDecimal(totalCoOwnerSum);
      this.totalCommitmentESum = this.AllowOneDecimal(this.totalCommitmentESum);
      this.addExpenseOnCapital = this.AllowOneDecimal(this.addExpenseOnCapital);
      this.addtotalCommitment = this.AllowOneDecimal(this.addtotalCommitment);
      this.addCoOwner = this.AllowOneDecimal(this.addCoOwner);
      this.appAmtAPR = this.AllowOneDecimal(this.appAmtAPR);
      this.totalCommitmentCal = this.AllowOneDecimal(this.totalCommitmentCal);
      this.totalExpenseAfterCEOnCapitalSum = this.AllowOneDecimal(this.totalExpenseAfterCEOnCapitalSum);
      this.totalCoOwnerAfterCESum = this.AllowOneDecimal(this.totalCoOwnerAfterCESum);

      // code for showing totalAppropriated for opened form
      if (this.form.formType == 'EPC') {
        for (let data of this.capitalAndExpendituresData) {
          if (data.capitalAndExpenditureName == 'Early EPC') {
            data.totalAppropriated = this.AllowOneDecimal(Number(data.totalAppropriated));
          } else {
            data.totalAppropriated = 0;
          }
        }
      }
      else if (this.form.formType == 'LLE') {
        for (let data of this.capitalAndExpendituresData) {
          if (data.capitalAndExpenditureName == 'Long Leads') {
            data.totalAppropriated = this.AllowOneDecimal(Number(data.totalAppropriated));
          } else {
            data.totalAppropriated = 0;
          }
        }
      }
      else if (this.form.formType == 'FEED') {
        for (let data of this.capitalAndExpendituresData) {
          if (data.capitalAndExpenditureName == 'FEED') {
            data.totalAppropriated = this.AllowOneDecimal(Number(data.totalAppropriated));
          } else {
            data.totalAppropriated = 0;
          }
        }
      }
      // // this.totalExpenseAfterCEOnCapitalSum+=this.totalExpenseAfterCEOnCapitalSum+ Number(this.totalExpenseAfterCEOnCapitalSum)
      // // this.totalCoOwnerAfterCESum+=this.totalCoOwnerAfterCESum+ Number(this.totalExpenseOnCapital)
      // // this.capitalAndExpendituresData=res.capitalAndExpenditureList.filter((x: { sectionAfterCetotal: any; })=>x.sectionAfterCetotal==false);
      this.notifyService.showSuccess("Data Load/Save successfully", "Status");

      setTimeout(() => {
        if (this.chartGHG != undefined)
          this.FormChartModel.TotalGhgemissionAndCarbonCostChart = this.chartGHG.getBase64Image();
        if (this.chartMethane != undefined)
          this.FormChartModel.MethaneAndFlaringAndTotalIntensityChart = this.chartMethane.getBase64Image();
        this.UpdateChartdata(this.FormChartModel);
      }, 10000);
    });
  }
  UpdateChartdata(formchartinfo: FormChart) {
    formchartinfo.ModifiedBy = this.provId;
    formchartinfo.FormId = this.form.id;
    formchartinfo.Active = true;
    formchartinfo.CreatedBy = this.provId;
    this.overviewService.SaveOverviewChartImages(formchartinfo).subscribe(res => {
    });
  }
  checkListPrecision(res: any) {
    if (res != null) {
      res.forEach(item => {
        item.facilities = (item.facilities !== null && item.facilities !== "") ? Number(item.facilities).toFixed(1).toString() : null;
        item.drillingAndCompletions = (item.drillingAndCompletions !== null && item.drillingAndCompletions !== "") ? Number(item.drillingAndCompletions).toFixed(1).toString() : null;
        item.contingencyEscalation = (item.contingencyEscalation !== null && item.contingencyEscalation !== "") ? Number(item.contingencyEscalation).toFixed(1).toString() : null;
        item.otherJointCosts = (item.otherJointCosts !== null && item.otherJointCosts !== "") ? Number(item.otherJointCosts).toFixed(1).toString() : null;
        item.otherSoleCosts = (item.otherSoleCosts !== null && item.otherSoleCosts !== "") ? Number(item.otherSoleCosts).toFixed(1).toString() : null;
        item.expenseOnCapital = (item.expenseOnCapital !== null && item.expenseOnCapital !== "") ? Number(item.expenseOnCapital).toFixed(1).toString() : null;
        item.coOwnerView = (item.coOwnerView !== null && item.coOwnerView !== "") ? Number(item.coOwnerView).toFixed(1).toString() : null;
        item.totalAppropriated = (item.totalAppropriated !== null && item.totalAppropriated !== "") ? Number(item.totalAppropriated).toFixed(1).toString() : null;
        item.totalCommitmentValue = (item.totalCommitmentValue !== null && item.totalCommitmentValue !== "") ? Number(item.totalCommitmentValue).toFixed(1).toString() : null;
        item.totalCommitment = (item.totalCommitment !== null && item.totalCommitment !== "") ? Number(item.totalCommitment).toFixed(1).toString() : null;
      });
    }
    return res;
  }
  checkModelPrecision(item: any) {
    item.facilities = (item.facilities !== null && item.facilities !== "") ? Number(item.facilities).toFixed(1).toString() : null;
    item.drillingAndCompletions = (item.drillingAndCompletions !== null && item.drillingAndCompletions !== "") ? Number(item.drillingAndCompletions).toFixed(1).toString() : null;
    item.contingencyEscalation = (item.contingencyEscalation !== null && item.contingencyEscalation !== "") ? Number(item.contingencyEscalation).toFixed(1).toString() : null;
    item.otherJointCosts = (item.otherJointCosts !== null && item.otherJointCosts !== "") ? Number(item.otherJointCosts).toFixed(1).toString() : null;
    item.otherSoleCosts = (item.otherSoleCosts !== null && item.otherSoleCosts !== "") ? Number(item.otherSoleCosts).toFixed(1).toString() : null;
    item.expenseOnCapital = (item.expenseOnCapital !== null && item.expenseOnCapital !== "") ? Number(item.expenseOnCapital).toFixed(1).toString() : null;
    item.coOwnerView = (item.coOwnerView !== null && item.coOwnerView !== "") ? Number(item.coOwnerView).toFixed(1).toString() : null;
    item.totalAppropriated = (item.totalAppropriated !== null && item.totalAppropriated !== "") ? Number(item.totalAppropriated).toFixed(1).toString() : null;
    item.totalCommitmentValue = (item.totalCommitmentValue !== null && item.totalCommitmentValue !== "") ? Number(item.totalCommitmentValue).toFixed(1).toString() : null;
    item.totalCommitment = (item.totalCommitment !== null && item.totalCommitment !== "") ? Number(item.totalCommitment).toFixed(1).toString() : null;
  }
  AllowOneDecimal(value: any) {
    value = value.toFixed(1);
    return value;
  }
  NumberWithCommas(x: any) {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  RemoveModelCommas(item: any) {
    item.facilities = (item.facilities !== null && item.facilities !== "") ? (item.facilities.toString().indexOf(',') > -1 ? Number(item.facilities.toString().replace(/,/g, "")) : Number(item.facilities)) : null;
    item.drillingAndCompletions = (item.drillingAndCompletions !== null && item.drillingAndCompletions !== "") ? (item.drillingAndCompletions.toString().indexOf(',') > -1 ? Number(item.drillingAndCompletions.toString().replace(/,/g, "")) : Number(item.drillingAndCompletions)) : null;
    item.contingencyEscalation = (item.contingencyEscalation !== null && item.contingencyEscalation !== "") ? (item.contingencyEscalation.toString().indexOf(',') > -1 ? Number(item.contingencyEscalation.toString().replace(/,/g, "")) : Number(item.contingencyEscalation)) : null;
    item.otherJointCosts = (item.otherJointCosts !== null && item.otherJointCosts !== "") ? (item.otherJointCosts.toString().indexOf(',') > -1 ? Number(item.otherJointCosts.toString().replace(/,/g, "")) : Number(item.otherJointCosts)) : null;
    item.otherSoleCosts = (item.otherSoleCosts !== null && item.otherSoleCosts !== "") ? (item.otherSoleCosts.toString().indexOf(',') > -1 ? Number(item.otherSoleCosts.toString().replace(/,/g, "")) : Number(item.otherSoleCosts)) : null;
    item.expenseOnCapital = (item.expenseOnCapital !== null && item.expenseOnCapital !== "") ? (item.expenseOnCapital.toString().indexOf(',') > -1 ? Number(item.expenseOnCapital.toString().replace(/,/g, "")) : Number(item.expenseOnCapital)) : null;
    item.coOwnerView = (item.coOwnerView !== null && item.coOwnerView !== "") ? (item.coOwnerView.toString().indexOf(',') > -1 ? Number(item.coOwnerView.toString().replace(/,/g, "")) : Number(item.coOwnerView)) : null;
  }
  RemoveListCommas(res: any) {
    if (res != null) {
      res.forEach(item => {
        item.facilities = (item.facilities !== null && item.facilities !== "") ? (item.facilities.toString().indexOf(',') > -1 ? Number(item.facilities.toString().replace(/,/g, "")) : Number(item.facilities)) : null;
        item.drillingAndCompletions = (item.drillingAndCompletions !== null && item.drillingAndCompletions !== "") ? (item.drillingAndCompletions.toString().indexOf(',') > -1 ? Number(item.drillingAndCompletions.toString().replace(/,/g, "")) : Number(item.drillingAndCompletions)) : null;
        item.contingencyEscalation = (item.contingencyEscalation !== null && item.contingencyEscalation !== "") ? (item.contingencyEscalation.toString().indexOf(',') > -1 ? Number(item.contingencyEscalation.toString().replace(/,/g, "")) : Number(item.contingencyEscalation)) : null;
        item.otherJointCosts = (item.otherJointCosts !== null && item.otherJointCosts !== "") ? (item.otherJointCosts.toString().indexOf(',') > -1 ? Number(item.otherJointCosts.toString().replace(/,/g, "")) : Number(item.otherJointCosts)) : null;
        item.otherSoleCosts = (item.otherSoleCosts !== null && item.otherSoleCosts !== "") ? (item.otherSoleCosts.toString().indexOf(',') > -1 ? Number(item.otherSoleCosts.toString().replace(/,/g, "")) : Number(item.otherSoleCosts)) : null;
        item.expenseOnCapital = (item.expenseOnCapital !== null && item.expenseOnCapital !== "") ? (item.expenseOnCapital.toString().indexOf(',') > -1 ? Number(item.expenseOnCapital.toString().replace(/,/g, "")) : Number(item.expenseOnCapital)) : null;
        item.coOwnerView = (item.coOwnerView !== null && item.coOwnerView !== "") ? (item.coOwnerView.toString().indexOf(',') > -1 ? Number(item.coOwnerView.toString().replace(/,/g, "")) : Number(item.coOwnerView)) : null;
        item.totalAppropriated = (item.totalAppropriated !== null && item.totalAppropriated !== "") ? (item.totalAppropriated.toString().indexOf(',') > -1 ? Number(item.totalAppropriated.toString().replace(/,/g, "")) : Number(item.totalAppropriated)) : null;
        item.totalCommitmentValue = (item.totalCommitmentValue !== null && item.totalCommitmentValue !== "") ? (item.totalCommitmentValue.toString().indexOf(',') > -1 ? Number(item.totalCommitmentValue.toString().replace(/,/g, "")) : Number(item.totalCommitmentValue)) : null;
        item.totalCommitment = (item.totalCommitment !== null && item.totalCommitment !== "") ? (item.totalCommitment.toString().indexOf(',') > -1 ? Number(item.totalCommitment.toString().replace(/,/g, "")) : Number(item.totalCommitment)) : null;
      });
    }
  }
  MatSliderNAEventCheck(event: MatSlideToggleChange, textName: string) {
    this.overview.formId = this.form.id;
    this.overview.modifiedBy = this.provId;

    if (textName == 'Summary') {
      this.overview.summaryNa = event.checked;
    } else if (textName == 'External') {
      this.overview.externalAnnouncementNa = event.checked;
    } else if (textName == 'ProjectBack') {
      this.overview.projectBackNa = event.checked;
    }
    else if (textName == 'GreenHouseGas') {
      this.overview.greenHouseNa = event.checked;
    }
    else if (textName == 'KeyCommercial') {
      this.overview.keyComAndPartneNa = event.checked;
    }
    else if (textName == 'CapitalAndExpenditure') {
      this.overview.capAndExpNa = event.checked;
    }
    else if (textName == 'PolicyApplicable') {
      this.overview.policyAppNa = event.checked;
    }
    else if (textName == 'ProjectFundFlag') {
      this.overview.projectFundFlag = event.checked;
    }
    else if (textName == 'Reserves') {
      this.overview.reservesFundFlag = event.checked;
    }
    this.overviewService.postOverViewData(this.overview).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
  }

  NAEventCheck(event: any, textName: string) {
    this.overview.formId = this.form.id;
    this.overview.modifiedBy = this.provId;
    if (this.overview.estimatedTotalCommit != null) {
      this.overview.estimatedTotalCommit = this.AllowOneDecimal(Number(this.overview.estimatedTotalCommit));
    }
    if (textName == 'Summary') {
      this.overview.summaryNa = event.target.checked;
    } else if (textName == 'External') {
      this.overview.externalAnnouncementNa = event.target.checked;
    } else if (textName == 'ProjectBack') {
      this.overview.projectBackNa = event.target.checked;
    }
    else if (textName == 'GreenHouseGas') {
      this.overview.greenHouseNa = event.target.checked;
    }
    else if (textName == 'KeyCommercial') {
      this.overview.keyComAndPartneNa = event.target.checked;
    }
    else if (textName == 'CapitalAndExpenditure') {
      this.overview.capAndExpNa = event.target.checked;
    }
    else if (textName == 'PolicyApplicable') {
      this.overview.policyAppNa = event.target.checked;
    }
    else if (textName == 'ProjectFundFlag') {
      this.overview.projectFundFlag = event.target.checked;
    }
    else if (textName == 'Reserves') {
      this.overview.reservesFundFlag = event.target.checked;
    }
    this.overviewService.postOverViewData(this.overview).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
  }

  UpdateFormData() {
    this.formData.id=this.form.id;
    this.formData.location=this.form.location;
    this.formData.operatorName=this.form.operatorName;
    this.formData.createdBy=this.form.createdBy;
    this.formData.createdOn=this.form.createdOn;
    this.formData.modifiedBy=this.form.modifiedBy;
    this.overviewService.updateFormData(this.formData).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
  }
  UpdateckeditorChanges(event: string, textName: string) {
    this.overview.formId = this.form.id;
    this.ckeditorText = event;
    if (textName == 'Summary') {
      this.overview.summary = this.ckeditorText;
    } else if (textName == 'External') {
      this.overview.externalAnnouncementStatus = this.ckeditorText;
    } else if (textName == 'projectBack') {
      this.overview.projectBackgroundAndDescription = this.ckeditorText;
    }
    else if (textName == 'greenHouseGas') {
      this.overview.greenHouseGasComment = this.ckeditorText;
    }
    else if (textName == 'KeyCommercial') {
      this.overview.keyCommercialAndPartnerAgreements = this.ckeditorText;
    }
    else if (textName == 'CapitalAndExpenditure') {
      this.overview.capitalAndExpenditure = this.ckeditorText;
    }
    else if (textName == 'PolicyApplicable') {
      this.overview.policyApplicable = this.ckeditorText;
    }
    this.overviewService.postOverViewData(this.overview).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
  }

  UpdateFormRisk() {
    this.formRisks.modifiedBy = this.provId;
    this.formRisks.formId = this.form.id;
    this.overviewService.updateFormRisk(this.formRisks).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
  }

  UpdateOwner(ownership: Ownership) {
    ownership.modifiedBy = this.provId;
    if (ownership.workingInterest != null) {
      ownership.workingInterest = parseFloat(ownership.workingInterest);
    }
    else {
      ownership.workingInterest = null;
    }
    if (ownership.netRevenueInterest != null) {
      ownership.netRevenueInterest = parseFloat(ownership.netRevenueInterest);
    }
    else {
      ownership.netRevenueInterest = null;
    }
    this.overviewService.postOwnershipData(ownership).subscribe((res: any) => {
      if (res) {
        this.ownershipData = res;
        this.ownershipData.forEach(obj => {
          if (obj.workingInterest != null) {
            obj.workingInterest = Number(obj.workingInterest).toFixed(1).toString();
            obj.workingInterest = obj.workingInterest + '%';
          }
          if (obj.netRevenueInterest != null) {
            obj.netRevenueInterest = Number(obj.netRevenueInterest).toFixed(1).toString();
            obj.netRevenueInterest = obj.netRevenueInterest + '%';
          }
        });
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    });

    if (ownership.workingInterest != null) {
      ownership.workingInterest = Number(ownership.workingInterest).toFixed(1).toString();
      ownership.workingInterest = ownership.workingInterest + '%';
    }
    else {
      ownership.workingInterest = null;
    }

    if (ownership.netRevenueInterest != null) {
      ownership.netRevenueInterest = Number(ownership.netRevenueInterest).toFixed(1).toString();
      ownership.netRevenueInterest = ownership.netRevenueInterest + '%';
    }
    else {
      ownership.netRevenueInterest = null;
    }
    this.ReserveAndResourse();
  }

  UpdatePhaseMileStone(milestone: Milestone) {
    milestone.modifiedBy = this.provId;
    const date = new Date(milestone.currentDate!);
    if (milestone.currentDate != null) {
      milestone.currentDate = this.notifyService.ConvertDateBeforeSave(date);
    }
    this.overviewService.postMileStoneData(milestone).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
    if (milestone.currentDate != null) {
      milestone.currentDate = this.notifyService.ConvertDateAfterSave(date);
    }
  }

  UpdateCapitalAndExpenditures(capExp: CapitalAndExpenditures) {
    capExp.modifiedBy = this.provId;
    // // this.RemoveModelCommas(capExp);
    //total commitment calculation
    capExp.totalCommitmentValue = Number(capExp.facilities) + Number(capExp.drillingAndCompletions) + Number(capExp.contingencyEscalation) + Number(capExp.otherJointCosts) + Number(capExp.otherSoleCosts) + Number(capExp.totalAppropriated) + Number(capExp.expenseOnCapital)

    capExp.totalAppropriated = Number(capExp.facilities) + Number(capExp.drillingAndCompletions) + Number(capExp.contingencyEscalation) + Number(capExp.otherJointCosts) + Number(capExp.otherSoleCosts);
    capExp.totalCommitmentValue = capExp.totalAppropriated + Number(capExp.expenseOnCapital);
    // //this.checkModelPrecision(capExp);

    this.overviewService.postCapitalAndExpenditures(capExp).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
    //Code to calculate total C&E data
    let facilitySum = 0;
    let drillingSum = 0;
    let contingencyEscSum = 0;
    let jointCostsSum = 0;
    let soleCostsSum = 0;
    let totalAppropriatedSum = 0;
    let totalExpenseOnCapitalSum = 0;
    let totalCommitmentSum = 0;
    let totalCoOwnerSum = 0;
    // //this.RemoveListCommas(this.capitalAndExpendituresData);
    for (let data of this.capitalAndExpendituresData) {
      if (data.facilities) {
        facilitySum += Number(data.facilities);
      }
      if (data.drillingAndCompletions) {
        drillingSum += Number(data.drillingAndCompletions);
      }
      if (data.contingencyEscalation) {
        contingencyEscSum += Number(data.contingencyEscalation);
      }
      if (data.otherJointCosts) {
        jointCostsSum += Number(data.otherJointCosts);
      }
      if (data.otherSoleCosts) {
        soleCostsSum += Number(data.otherSoleCosts);
      }
      if (data.totalAppropriated) {
        totalAppropriatedSum += Number(data.totalAppropriated);
      }
      if (data.expenseOnCapital) {
        if (data.capitalAndExpenditureName != 'C&E Total' && data.capitalAndExpenditureName != 'Capex Total') {
          totalExpenseOnCapitalSum += Number(data.expenseOnCapital);
        }
      }
      if (data.coOwnerView) {
        totalCoOwnerSum += Number(data.coOwnerView);
      }
    }

    this.totalFacilities = facilitySum;
    this.totalDrillingAndCompletions = drillingSum;
    this.totalContingencyEscalation = contingencyEscSum;
    this.totalOtherJointCosts = jointCostsSum;
    this.totalOtherSoleCosts = soleCostsSum;
    this.totalAppropriatedCal = totalAppropriatedSum;
    this.totalExpenseOnCapital = totalExpenseOnCapitalSum;
    this.totalCoOwner = totalCoOwnerSum
    for (let data of this.capitalAndExpendituresData) {
      if (data.capitalAndExpenditureName == 'C&E Total' || data.capitalAndExpenditureName == 'Capex Total') {
        data.totalCommitmentValue = Number(data.facilities) + Number(data.drillingAndCompletions) + Number(data.contingencyEscalation) + Number(data.otherJointCosts) + Number(data.otherSoleCosts);
      }
      else {
        data.totalCommitmentValue = Number(data.facilities) + Number(data.drillingAndCompletions) + Number(data.contingencyEscalation) + Number(data.otherJointCosts) + Number(data.otherSoleCosts) + Number(data.expenseOnCapital);
      }
      if (data.capitalAndExpenditureName == 'C&E Total' || data.capitalAndExpenditureName == 'Capex Total') {
        totalCommitmentSum += Number(data.totalCommitmentValue);
      } else {
        totalCommitmentSum += Number(data.totalCommitmentValue) - Number(data.expenseOnCapital);
      }
    }

    this.totalCommitmentCal = totalCommitmentSum;
    if (this.form.formType == 'EPC') {
      this.appAmtAPR = Number(this.capitalAndExpendituresData.find(i => i.capitalAndExpenditureName == "Early EPC")?.totalAppropriated);
    }
    else if (this.form.formType == 'LLE') {
      this.appAmtAPR = Number(this.capitalAndExpendituresData.find(i => i.capitalAndExpenditureName == "Long Leads")?.totalAppropriated);
    }
    else if (this.form.formType == 'FEED') {
      this.appAmtAPR = Number(this.capitalAndExpendituresData.find(i => i.capitalAndExpenditureName == "FEED")?.totalAppropriated);
    }
    // Code to calulate data after C&E Total
    this.totalExpenseAfterCEOnCapitalSum = 0;
    this.totalCoOwnerAfterCESum = 0;
    this.totalCommitmentESum = 0;
    this.addExpenseOnCapital = 0;
    this.addtotalCommitment = 0;
    this.addCoOwner = 0;
    this.checkListPrecision(this.capitalAndExpendituresData);
    // //this.RemoveListCommas(this.capitalAndExpendituresAfterCETotalData);
    for (let i = 0; i < this.capitalAndExpendituresAfterCETotalData.length; i++) {
      if (this.capitalAndExpendituresAfterCETotalData[i].expenseOnCapital) {
        this.totalExpenseAfterCEOnCapitalSum += Number(this.capitalAndExpendituresAfterCETotalData[i].expenseOnCapital);
      }
      if (this.capitalAndExpendituresAfterCETotalData[i].coOwnerView) {
        this.totalCoOwnerAfterCESum += Number(this.capitalAndExpendituresAfterCETotalData[i].coOwnerView);
      }
      if (this.capitalAndExpendituresAfterCETotalData[i].totalCommitment) {
        this.totalCommitmentESum += Number(this.capitalAndExpendituresAfterCETotalData[i].totalCommitment);
      }
    }
    this.totalCommitmentESum = this.totalCommitmentESum + this.totalExpenseOnCapital
    this.addExpenseOnCapital = this.totalExpenseOnCapital + this.totalExpenseAfterCEOnCapitalSum;
    this.addtotalCommitment = this.totalCommitmentCal + this.totalCommitmentESum;
    this.addCoOwner = this.totalCoOwner + this.totalCoOwnerAfterCESum;
    this.checkListPrecision(this.capitalAndExpendituresAfterCETotalData);
    this.totalFacilities = this.AllowOneDecimal(facilitySum);
    this.totalDrillingAndCompletions = this.AllowOneDecimal(drillingSum);
    this.totalContingencyEscalation = this.AllowOneDecimal(contingencyEscSum);
    this.totalOtherJointCosts = this.AllowOneDecimal(jointCostsSum);
    this.totalOtherSoleCosts = this.AllowOneDecimal(soleCostsSum);
    this.totalAppropriatedCal = this.AllowOneDecimal(totalAppropriatedSum);
    this.totalExpenseOnCapital = this.AllowOneDecimal(totalExpenseOnCapitalSum);
    this.totalCoOwner = this.AllowOneDecimal(totalCoOwnerSum);
    this.totalCommitmentESum = this.AllowOneDecimal(this.totalCommitmentESum);
    this.addExpenseOnCapital = this.AllowOneDecimal(this.addExpenseOnCapital);
    this.addtotalCommitment = this.AllowOneDecimal(this.addtotalCommitment);
    this.addCoOwner = this.AllowOneDecimal(this.addCoOwner);
    this.appAmtAPR = this.AllowOneDecimal(this.appAmtAPR);
    this.totalCommitmentCal = this.AllowOneDecimal(this.totalCommitmentCal);
    this.totalExpenseAfterCEOnCapitalSum = this.AllowOneDecimal(this.totalExpenseAfterCEOnCapitalSum);
    this.totalCoOwnerAfterCESum = this.AllowOneDecimal(this.totalCoOwnerAfterCESum);
    // code for showing totalAppropriated for opened form
    if (this.form.formType == 'EPC') {
      for (let data of this.capitalAndExpendituresData) {
        if (data.capitalAndExpenditureName == 'Early EPC') {
          data.totalAppropriated = this.AllowOneDecimal(Number(data.totalAppropriated));
        } else {
          data.totalAppropriated = 0;
        }
      }
    }
    else if (this.form.formType == 'LLE') {
      for (let data of this.capitalAndExpendituresData) {
        if (data.capitalAndExpenditureName == 'Long Leads') {
          data.totalAppropriated = this.AllowOneDecimal(Number(data.totalAppropriated));
        } else {
          data.totalAppropriated = 0;
        }
      }
    }
    else if (this.form.formType == 'FEED') {
      for (let data of this.capitalAndExpendituresData) {
        if (data.capitalAndExpenditureName == 'FEED') {
          data.totalAppropriated = this.AllowOneDecimal(Number(data.totalAppropriated));
        } else {
          data.totalAppropriated = 0;
        }
      }
    }
  }

  UpdateShortSummary() {
    this.overview.createdBy = this.provId;
    this.overview.formId = 10;
  }

  public onChange(event: customBuild.EventInfo, test: string) {
    this.overview.createdBy = this.provId;
    this.overview.formId = 10;
    this.notifyService.showSuccess("Data Load/Save successfully !!", "Status")
  }

  SaveCommentForOwnerships(owner: Ownership) {
    const dialogData = new CommentDialogModel("Update Comment", owner.comment, this.isOnHold, this.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        owner.modifiedBy = this.provId;
        if(owner.workingInterest!=null){
          owner.workingInterest = owner.workingInterest.replace('%', '');
          owner.workingInterest = parseFloat(owner.workingInterest);
        }
        if(owner.netRevenueInterest!=null){
          owner.netRevenueInterest = owner.netRevenueInterest.replace('%', '');
          owner.netRevenueInterest = parseFloat(owner.netRevenueInterest);
        } 
        owner.comment = comment;
        this.overviewService.postOwnershipData(owner).subscribe(res => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  DeleteOwnerships(owner: Ownership, index: any) {
    const message = 'Are you sure you want to delete this item?';
    const dialogData = new ConfirmDialogModel("Confirm item deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.ownershipData.splice(index, 1);
        owner.modifiedBy = this.provId;
        owner.active = false;
        owner.workingInterest = owner.workingInterest.replace('%', '');
        owner.netRevenueInterest = owner.netRevenueInterest.replace('%', '');
        owner.workingInterest = parseFloat(owner.workingInterest);
        owner.netRevenueInterest = parseFloat(owner.netRevenueInterest);
        this.overviewService.postOwnershipData(owner).subscribe(res => {
          this.notifyService.showSuccess("Item deleted successfully...!", "");
        });
      }
    });
  }

  DeletephaseGateMilestones(milestone: Milestone, index: any) {
    const message = 'Are you sure you want to delete this item?';
    const dialogData = new ConfirmDialogModel("Confirm item deletion", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        // // this.phasemileStone.splice(index, 1);
        const milestoneItem = new Milestone();
        if (milestone.isEditable) {
          this.phasemileStone[index].milestoneName = '';
          this.phasemileStone[index].currentDate = milestoneItem.currentDate;
          milestoneItem.id = milestone.id;
          milestoneItem.modifiedBy = this.provId;
          milestoneItem.milestoneName = '';
          milestoneItem.order = milestone.order;
          milestoneItem.formId = milestone.formId;
          milestoneItem.milestoneType = milestone.milestoneType;
          milestoneItem.active = true;
          milestoneItem.isEditable = milestone.isEditable;
          this.overviewService.postMileStoneData(milestoneItem).subscribe(res => {
            this.notifyService.showSuccess("Item deleted successfully...!", "");
          });

        }
        else {
          this.phasemileStone.splice(index, 1);
          milestone.modifiedBy = this.provId;
          milestone.active = false;
          this.overviewService.postMileStoneData(milestone).subscribe(res => {
            this.notifyService.showSuccess("Item deleted successfully...!", "");
          });
        }
      }
    });
  }

  DeleteCoOwnerphaseGateMilestones(milestone: Milestone, index: any) {
    const message = 'Are you sure you want to delete this item?';
    const dialogData = new ConfirmDialogModel("Confirm item deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const milestoneItem = new Milestone();
        if (milestone.isEditable) {
          this.coownermileStone[index].milestoneName = '';
          this.coownermileStone[index].currentDate = milestoneItem.currentDate;
          milestoneItem.id = milestone.id;
          milestoneItem.modifiedBy = this.provId;
          milestoneItem.milestoneName = '';
          milestoneItem.order = milestone.order;
          milestoneItem.formId = milestone.formId;
          milestoneItem.milestoneType = milestone.milestoneType;
          milestoneItem.active = true;
          milestoneItem.isEditable = milestone.isEditable;
          this.overviewService.postMileStoneData(milestoneItem).subscribe(res => {
            this.notifyService.showSuccess("Item deleted successfully...!", "");
          });
        }
        else {
          this.coownermileStone.splice(index, 1);
          milestone.modifiedBy = this.provId;
          milestone.active = false;
          this.overviewService.postMileStoneData(milestone).subscribe(res => {
            this.notifyService.showSuccess("Item deleted successfully...!", "");
          });
        }
      }
    });
  }

  showShortSummaryInfo() {
    const message = 'Briefly describe the project context (especially if the AR is for a subset of a larger project or program) and how the project will create value.'
      + '\n' + 'Include key elements of project scope and what is being requested with this form. Use bullets as appropriate. Highlight appropriated amount and total commitment to make the request obvious.'
      + '\n<p></p><p>' + 'Throughout the forms there are sections available for highlighting NOJV co-owner views. Historically, there are examples of differences in the Chevron and Operator pre-investment views,'
      + '\n' + 'as well as differences in the Operator’s results compared to Chevron’s. The addition of these sections enable transparency at FID on the detail of Chevron’s view, and why it might be different than the Operator’s.'
      + '\n' + 'This will assist the decision making process to ensure we are investing in the most competitive projects overall.' + '</p>';
    const dialogData = new InfoDialogModel("Short summary of Scope & Request", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  showOwnershipInfoPopUp() {
    const message = 'Provide data by high level company (not specific corporate entity). NRI data is only required for Upstream projects and is used'
      + '\n' + 'to convert resources to 8/8ths.'
      + '\n<p></p><p>' + 'Add rows as needed for multiple co-owners. Select operator using the checkbox.' + '</p>';
    const dialogData = new InfoDialogModel("Ownership", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  showPhaseGateMilestonesPopUp() {
    const message = 'Include key decision making and project management milestones. The tool is prepopulated with common milestones; add,'
      + '\n' + 'rename, or delete as appropriate. If applicable, co-owner milestones can also be tracked.';
    const dialogData = new InfoDialogModel("Phase Gate Milestones", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  showProjectSensitivityPopUp() {
    const message = 'From the S-curve included in section 6.2, indicate here the likelihood of NPV<0 at the mid-price scenario. For supplement,'
      + '\n' + 'update sensitivity.';
    const dialogData = new InfoDialogModel("Project Sensitivity", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  showBreakevenSensitivitiesPopUp() {
    var CE_text = this.form.features?.isNewCEisApplicableForms || this.form.isNewCEisApplicableForms ? "Capex" : "C&E";
    const message = '<h4 class="text-left" >Breakeven Price – required for upstream projects, optional for others if relevant</h4>'
      + '\n<p></p>' + 'This is a sensitivity calculation to understand the breakeven price for development projects. The purpose is to provide decision'
      + '\n' + 'makers with additional insight into the economic risk inherent in the project. Higher breakeven prices imply less cushion in'
      + '\n' + 'future pricing volatility to preserve value creation for the project (NPV10=0 reflects a DPI of 1, or the point at which the project'
      + '\n' + 'just covers the investment and the nominal cost of capital). Upstream cash flows should include end-of-life (e.g.,'
      + '\n' + 'decommissioning and abandonment) costs. For DS&C projects that use shorter cash flows and terminal/in-perpetuity values,'
      + '\n' + 'consult with SP&T or CSP on a case-by-case basis.'
      + '\n<p></p><ol><li>' + 'The calculation is on a point forward, discounted cashflow basis. The normal rules apply about determining the discount'
      + '\n' + 'date (determined by before or after July 1st of the current year)</li>'
      + '\n<li>' + 'The calculation is on an after-tax basis to properly reflect the impact of fiscal terms</li>'
      + '\n<li>' + 'The calculations are carried out deterministically, with all uncertainties at their P50 values</li>'
      + '\n<li>' + 'For the primary product, develop the price time series as follows:'
      + '\n<ol><li>' + 'Start with the dollar value of the mid-price scenario in year 1 of the cashflow (e.g. WTI @ 60$/bbl)</li>'
      + '\n<li>' + 'Apply ONLY general inflation to the price for future values. The inflation rate should be consistent with the fundamental'
      + '\n' + 'inflation assumed in the corporate price model, based on USA inflation. For example, in 2019 that assumption is 2%</li></ol></li>'
      + '\n<li>' + 'For the secondary product, develop the price time series as follows:'
      + '\n<ol><li>' + 'If the secondary price is directly linked to the primary price, let it vary similarly with the primary price</li>'
      + '\n<li>' + 'If the secondary price is a local market contract, honor the contract and then inflate at the end of the contract. If there is'
      + '\n' + 'an inflation clause in the contract, reflect that in the price</li>'
      + '\n<li>' + 'If the secondary price is spot, apply inflation to year 1 price (same considerations as for primary product)</li></ol></li>'
      + '\n<li>' + 'To reflect the same assumptions in the cost basis for the project, develop project costs (capital and operating expense) as'
      + '\n' + 'follows:'
      + '\n<ol><li>' + 'Determine the P50 costs using year 1 as the cost basis</li>'
      + '\n<li>' + 'Apply ONLY general inflation to escalate the costs for future values</li>'
      + '\n<li>' + 'This preserves the ratio of revenue to revenue to cost, while the margin in dollars increases (this is intentional)</li>'
      + '\n<li>' + 'DO NOT APPLY COGS</li>'
      + '\n<li>' + 'If costs are already under contract, use contract pricing without further adjustment. Inflate at 2% if costs continue'
      + '\n' + 'beyond the contract period.</li></ol></li>'
      + '\n<li>' + 'To determine the breakeven price for NPV<sub>10</sub> = 0, use goal seek to vary the year 1 price.</li>'
      + '\n<p></p>' + '<h4 class="text-left">Breakeven Cost – required for all projects</h4>'
      + '\n' + 'This is a sensitivity calculation to test the vulnerability of the project to '+ CE_text +'overruns and is a required metric on the new'
      + '\n' + 'Digital GO-36 (DGO-36). The greater the tolerance to ' + CE_text +' overrun without resulting in an NPV10 equal to zero, the lower the'
      + '\n' + 'economic risk associated with the project.'
      + '\n<p></p><ol><li>' + 'The calculation is on a point forward, discounted cashflow basis. The normal rules apply about determining the discount'
      + '\n' + 'date (determined by before or after July 1st of current year)</li>'
      + '\n<li>' + 'The calculation is on an after-tax basis to properly reflect the impact of any fiscal terms (pre and post start-up)</li>'
      + '\n<li>' + 'The calculations are carried out deterministically, with all uncertainties at their P50 values. Utilize Mid-Case Investment'
      + '\n' + 'Price Guidance (with local adjustments) for the “current year” to develop product price forecasts.</li>'
      + '\n<li>' + 'Create and apply a multiplication factor to the total ' + CE_text +' (preserve the spend profile over time)</li>'
      + '\n<li>' + 'Use goal seek to determine the multiplier that results in NPV10 = 0</li>'
      + '\n<li>' + 'Express the resulting ' + CE_text +' as a percentage of the appropriation amount</li></ol>'
      + '\n<p></p>' + 'See the <a href="https://chevron.sharepoint.com/:x:/r/sites/CSP/Investment Analysis/June_2019_Breakeven_Generic.xlsx" target="_blank">example file on the CSP SharePoint</a> for an illustration of this methodology.</p></p>'
      ;
    const dialogData = new InfoDialogModel("Breakeven Sensitivities for NPV10 = 0", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  showCEDetailPopUp() {
    var CE_text = this.form.features?.isNewCEisApplicableForms || this.form.isNewCEisApplicableForms ? "Capex" : "C&E";
    const message = 'Detail P50 capital and capitalized expense at mid-price guidance in nominal dollars.'
      + '\n' + 'Use the text box below the table to provide any additional context – e.g. rationale for contingency,'
      + '\n' + 'use of retained earnings, NOJV funding constraints, etc. Nominal, or then-current, is money that is'
      + '\n' + 'transacted in the future and INCLUDES inflation and escalation relative to today. See section 5.12 in the'
      + '\n<a href="https://chevron.sharepoint.com/sites/CSP/Investment Analysis/IAM Rev Current Version.pdf" target="_blank">' + 'Investment Analysis Manual</a> for more detail.'
      + '\n<p></p>' + 'Discuss any EAV/CPO components of the project as applicable. Required CPO obligations can be added to the table by adding'
      + '\n' + 'a row at the bottom. See CPO guidance below.'
      + '\n<p></p>' +   CE_text +' detail table headings can be edited as applicable for project needs. Default headings are the commonly used categories'
      + '\n' + 'and the table is limited to a total of 5 columns (plus co-owner view), but multiple rows can be added to represent additional'
      + '\n' + 'line items. When any other elements of the Total Commitment (e.g., expense, working capital, CPOs, existing assets, etc.) are a'
      + '\n' + 'material part of the investment, footnote an explanation in the text box below the table to make the Total Commitment'
      + '\n' + 'buildup explicit.'
      + '\n<p></p>' + '<h4 class="text-left">' + CE_text +' basis for AR Governance:</h4>'
      + '\n<p></p>' + 'Use a P50 cost target, based on the results of Chevron’s standard integrated cost and schedule assurance (ICSA) process, to set the'
      + '\n' + 'appropriated amount for all ARs except the following:'
      + '\n<p></p><ul><li>' + 'Supplements, acquisitions, asset purchases, capital leases, Phases 1-3 ARs and Phase 5 development wells should'
      + '\n' + 'set the appropriated amount based on the P50 of the cost uncertainty. Note: In situations where ICSA has not been'
      + '\n' + 'completed, estimate the P50 using decision analysis principles.</li>'
      + '\n<li>' + 'NOJV projects are to use the operator’s ' + CE_text +' estimate (plus Chevron oversight costs) for the project appropriated amount.'
      + '\n' + 'All other elements of total commitment are Chevron estimates based on the operator’s scope. If there is a need to deviate,'
      + '\n' + 'clearly explain the rationale for the appropriated amount.</li></ul>'
      + '\n<p></p>' + '<h4 class="text-left">Contractual payment obligations (CPOs):</h4>'
      + '\n<p></p>' + 'When CPOs are like debt obligations (see criteria in the <a href="https://chevron.sharepoint.com/sites/CSP/Investment Analysis/IAM Rev Current Version.pdf" target="_blank">Investment Analysis Manual</a>'
      + '\n' + 'Section 4.6-9), the sum of CPO payments'
      + '\n' + 'must be included in the AR Total Commitment. Determine the amount as follows. Note: the capital equivalent (before-tax) of'
      + '\n' + 'capital leases (book basis) is not included in this calculation.'
      + '\n<p></p><ul><li>' + 'For Project financing amounts, the principal amount of loan or equity carries for partners and the expected costs of loan'
      + '\n' + 'guarantees should all be included. Contact the Corporate Treasury Staff for guidance (see <a href="https://chevron.sharepoint.com/sites/CSP/SitePages/Home.aspx" target="_blank">contact list</a> on SharePoint).</li>'
      + '\n<li>' + 'For all other CPOs sum the obligated payments per the following:</li>'
      + '\n<ul><li>' + 'If a CPO is needed for the full term of the project life (basis of economics), sum the payments for this full period. If'
      + '\n' + 'initially the CPO will be for a shorter term with an extension option, assume the option is exercised to match the full project term.</li>'
      + '\n<li>' + 'Cancellation clauses are not to be used to reduce the term of the CPO payments for governance, even though they may impact the economics.</li>'
      + '\n<li>' + 'Only include the sum of purchase or sales commitments of commodities (oil, gas, coal, power) when both the prices and volumes are fixed.</li></ul></ul>'
    //+ '\n<p></p>' + '<h4 class="text-left">Value of existing assets:</h4>'
    //+ '\n' + 'Estimate an expected fair market value (before-tax) of existing assets (including tax benefits that could be used by other'
    //+ '\n' + 'projects) directly incorporated into this project, considering sale value or other possible uses.'
      ;
    const dialogData = new InfoDialogModel( CE_text +" Detail", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowKeyEconomicPopUp() {
    var CE_text = this.form.features?.isNewCEisApplicableForms || this.form.isNewCEisApplicableForms ? "Capex" : "C&E";
    const message = 'Deterministic metrics are calculated from the data imported at the mid-price guidance. EV metrics are user-input on the 20-line template.'
      + '\n<p><p>' + 'Validate against project economic model; note that DPI rounds to two decimal places, all other cells round to one decimal. For'
      + '\n' + 'projects with ' + CE_text +' >$500MM Chevron share, recommend testing the need to calculate ROCE with the decision maker. See'
      + '\n' + 'section 5.11 in the <a href="https://chevron.sharepoint.com/sites/CSP/Investment Analysis/IAM Rev Current Version.pdf" target="_blank">Investment Analysis Manual</a> for more detail on calculating this metric.'
      + '\n<p></p>' + 'For NOJV Projects (Phase 3 and FID ARs), use the operator’s basis to set the ' + CE_text +' amount (plus Chevron costs) to be'
      + '\n' + 'appropriated in the AR. For project economics, use the operator’s scope (platform size, # of wells, etc.), and based on this'
      + '\n' + 'scope, Chevron input for all other assumptions (' + CE_text +', schedule, production/EUR, commodity prices, capital and operating costs…).'
      + '\n<p></p>' + 'Key Value Metrics in the Summary Table'
      + '\n<p></p><ul><li>' + 'NPV: net present value calculated using a 10% discount rate and mid-year discounting for both the P50 and expected value (EV) of the cash flow distribution</li>'
      + '\n<li>' + 'ROR: rate of return is the discount rate that drives the present value of the total after-tax cash flow to zero. If the NPV at a 0% discount rate is negative the ROR is not meaningful, show n/a.</li>'
      + '\n<li>' + 'DPI: use a 10% discount rate for the numerator and denominator. Reference the investment analysis manual, Section 4.3 for what should be included in the denominator.</li>'
      + '\n<li>' + 'Payout time: the time in years required to recover the initial investment, calculated from the first year of investment.</li>'
      + '\n<li>' + 'Maximum negative cumulative CF: the maximum negative value in the cash flow stream. This value is calculated based on the cumulative cash flow.</li>'
      + '\n<li>' + '' + CE_text + ' in project economics: The undiscounted ' + CE_text + ' (CVX share) should also include such items as future maintenance/replacement ' + CE_text +' not being appropriated now.</li>'
      + '\n<li>' + 'Various sensitivities and breakeven prices are also imported into this section from the Risks tab of the form to highlight their relevance in decision making.</li></ul>'
      + '\n' + 'Discount date for economics'
      + '\n<p></p><ul><li>' + 'For ARs expected to be endorsed before June 30, discount cash flows to calculate NPV for all economic cases (Point-'
      + '\n' + 'forward, FEED-forward and Full-cycle) to January 1 of the current year. For projects with endorsement expected after June'
      + '\n' + '30, move the discount start date to the following January 1. Examples for discounting cash flows are in the Investment Analysis SharePoint site.</li>'
      + '\n<li>' + 'For FEED-forward and Full-cycle NPV calculations, escalate all cash flows occurring before the discount date at a 10% per annum rate to the discount date.</li>'
      + '\n<li>' + 'Note: ROR and DPI calculations do not require discount-date adjustments. See the Investment Analysis Manual Sections 5.12.</li></ul>'
      + '\n<p></p>' + 'Economic cases for Phase 3 and FID ARs'
      + '\n<p></p><ul><li>' + 'Point-forward case cash flow:</li>'
      + '\n<ul><li>' + 'Includes all forward cash flows from the AR preparation date (the date when the economics were completed and'
      + '\n' + 'specified in the AR) – except the tax benefits from previous spending if the benefits can be realized by the Business Unit'
      + '\n' + 'if the project does not proceed.</li>'
      + '\n<li>' + 'Excludes the market value of existing assets to be used in the project.</li></ul>'
      + '\n<p></p><li>' + 'FEED-forward case cash flow:</li>'
      + '\n<ul><li>' + 'Include Phase 3 spent and committed ' + CE_text +' and expense costs (FEED, long-lead). Note: Exclude any FEED costs that had to be repeated due to a recycle or scope change.</li>'
      + '\n<li>' + 'Include any late Phase 2 costs for logistics, early infrastructure activities, early detailed design…</li>'
      + '\n<li>' + 'Include the tax benefits from a project’s Phases 1 and 2 spending only if this project must proceed in order for'
      + '\n' + 'these tax benefits to be realized. In addition, if long-lead items were purchased, include both their tax benefits and'
      + '\n' + 'associated costs within in the project economics.</li>'
      + '\n<li>' + 'Exclude all Phases 1 and 2 exploration, appraisal and project team sunk costs.</li>'
      + '\n<li>' + 'Include an estimate of the market value of any “material” assets acquired before Phase 3 that are being directly'
      + '\n' + 'incorporated into the project (e.g., land previously purchased), considering replacement costs and sale value or other'
      + '\n' + 'possible uses (include tax consequences, assuming the asset was purchased).</li>'
      + '\n<li>' + 'Exception: In Phases 3 and 4 ARs, per industry practice, the FEED-forward economics do not include the value of hydrocarbon resources we already own.</li></ul>'
      + '\n<ul><li>' + 'Full-cycle case cash flow:</li>'
      + '\n<ul><li>' + 'Base the economics on all of the material cash flows associated with developing an asset beginning with the first'
      + '\n' + 'investment through the end of the cash flow period defined for the project. This would include the costs to acquire the'
      + '\n' + 'lease and/or find/acquire the reserves being developed. It also includes any opportunity costs for existing assets'
      + '\n' + 'described above. Adjust the discount date to the FEED-forward date per guidance above.</li></ul></ul>'
      ;
    const dialogData = new InfoDialogModel("Key Economic Metrics at Mid-price: P50 and EV values", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  showProjectBackgroundPopUp() {
    const message = 'Briefly summarize relevant background, including the current status, to understand the investment being proposed. For supplement, how has the project'
      + '\n' + 'progressed and changed, and what is the current status?'
      + '\n<p></p>' + 'Explain how project supports business unit strategy / meets business unit needs. If part of a larger project or program, explain how this project'
      + '\n' + 'fits into the bigger picture; clearly explain what is and what is not included in the scope of this AR. Address if and how the project is reflected'
      + '\n' + 'in the business plan (i.e., whether funding been earmarked).'
      + '\n<p></p>' + 'Include images as relevant but limit the number to 6 or fewer to avoid degradation in tool performance. The data import template includes an image'
      + '\n' + 'sizing guide on the ‘plot sizing’ tab which produces good printing results.';
    const dialogData = new InfoDialogModel("Project Background, Description and Current Status", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowGreenhouseGasPopUp() {
    const message = 'This section uses data from the 20-line template and automatically calculates and charts GHG metrics for total emissions,'
      + '\n' + 'scope 1 emissions, scope 2 emissions, total carbon intensity, methane intensity, flaring intensity and carbon cost.'
      + '\n' + ' The metrics are Chevron net share averaged over field or project life. All emissions related data shall be based on'
      + '\n' + ' Net Working Interest (NWI). All production data is Net Revenue Interest (NRI) which is working interest net of Royalty.'
      + '\n' + 'Please include relevant carbon intensity benchmark data for your project if available. Please reach out to Corporate'
      + '\n' + 'Strategy and Sustainability or go to the'
      + '\n<a target="_blank" href="https://chevron.sharepoint.com/sites/CSSOrg/Investment Analysis/Forms/AllItems.aspx">' + ' Investment Analysis Sharepoint</a> to get the appropriate information as required.';
    const dialogData = new InfoDialogModel("Greenhouse Gas Metrics", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowKeyCommercialPopUp() {
    const message = 'Briefly summarize commercial, co-owner and government agreements, including outstanding issues, that are relevant to the'
      + '\n' + 'investment decision. Areas that could be project risks should also be included in section 6.1 (Risks).';
    const dialogData = new InfoDialogModel("Key Commercial, Co-owner, and Government Agreements and Issues", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowEnvironmentalPopUp() {
    const message = 'Include highlights from Environmental, Social, and Health Impact Assessment (ESHIA) report and any other ESG'
      + '\n' + 'considerations impacting opportunity.';
    const dialogData = new InfoDialogModel("Environmental and Social Impact Considerations", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowSignoffsPopUp() {
    const message = 'Outline approvals needed for investment appropriation. Wet signatures, where still applicable,'
      + '\n' + 'can be done in signature & date boxes on final printout';
    const dialogData = new InfoDialogModel("Signoffs", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowExtAnnouncementPopUp() {
    const message = 'Note any external announcements made to date or other public awareness/media spotlight.'
      + '\n' + 'Highlight any confidentiality concerns and timing of future public communications.';
    const dialogData = new InfoDialogModel("External Announcement Status", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  DeletecoOwnerViews(milstone: Milestone) {

    const message = 'Are you sure you want to delete this item?';
    const dialogData = new ConfirmDialogModel("Confirm item deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.notifyService.showSuccess("Item deleted successfully...!", "");
      }
    });
  }

  removeOwnerShip(index: number) {
    // // const control = <FormArray>this.overViewForm.controls['ownerShip'];
    // // control.removeAt(index);
  }

  addOwnerShip(): void {
    let ownership = this.ownershipData.slice(-1)[0];
    const newGuidance = new Ownership();
    newGuidance.id = 0;
    newGuidance.active = true;
    newGuidance.createdBy = this.provId;
    newGuidance.createdOn = new Date();
    newGuidance.order = ownership.order + 1;
    newGuidance.formId = ownership.formId;
    newGuidance.ownershipName = '';
    newGuidance.workingInterest = 0;
    newGuidance.netRevenueInterest = 0;
    newGuidance.isOperator = false;
    newGuidance.isEditable = true;
    this.ownershipData.splice(this.ownershipData.length + 1, 0, newGuidance);
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("Data shown successfully !!", "tutsmake.com")
  }

  showToasterError() {
    this.notifyService.showError("Something is wrong", "tutsmake.com")
  }

  showToasterInfo() {
    let msg: '';
    this.notifyService.showInfo('<div class="modal-dialog modal-lg"> <div class="modal-content"> <div class="modal-header"> <h4 class="modal-title text-left">Short summary of Scope &amp; Request</h4> </div> <div class="modal-body"> <h5 class="text-left">Guidance:</h5> <p data-bind="html: Description"><p>Briefly describe the project context (especially if the AR is for a subset of a larger project or program) and how the project will create value. Include key elements of project scope and what is being requested with this form. Use bullets as appropriate. Highlight appropriated amount and total commitment to make the request obvious.</p> <p>Throughout the forms there are sections available for highlighting NOJV co-owner views. Historically, there are examples of differences in the Chevron and Operator pre-investment views, as well as differences in the Operator’s results compared to Chevron’s. The addition of these sections enable transparency at FID on the detail of Chevron’s view, and why it might be different than the Operator’s. This will assist the decision making process to ensure we are investing in the most competitive projects overall.</p></p> </div> <div class="modal-footer"> <button type="button" style="color: white !important;" class="btn btn-default" data-bind="click:close,html:closeLabel">Close</button> </div> </div> </div>', "tutsmake.com")
  }

  showToasterWarning() {
    this.notifyService.showWarning("This is warning", "tutsmake.com")
  }

  SaveCommentForMilestones(milestone: Milestone) {
    const dialogData = new CommentDialogModel("Update Comment", milestone.comment, this.isOnHold, this.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        milestone.modifiedBy = this.provId;
        milestone.comment = comment;
        this.overviewService.postMileStoneData(milestone).subscribe(res => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  SaveCommentRiskForm(textName: string) {
    this.formRisks.modifiedBy = this.provId;
    this.formRisks.formId = this.form.id;
    if (textName == 'npvFullCycle') {
      this.commentText = this.formRisks.npvFullCycleComment;
    }
    else if (textName == 'RealPointForward') {
      this.commentText = this.formRisks.flatRealPointForwardComment;
    }
    else if (textName == 'FlatRealFeedForward') {
      this.commentText = this.formRisks.flatRealFeedForwardComment;
    }
    else if (textName == 'CommodityTestedBy') {
      this.commentText = this.formRisks.commodityTestedByComment;
    }
    else if (textName == 'FeedForwardCommodityTestedBy') {
      this.commentText = this.formRisks.feedForwardCommodityTestedByComment;
    }
    else if (textName == 'RemainingCostIncrease') {
      this.commentText = this.formRisks.remainingCostIncreaseComment;
    }

    if (this.commentText == null || this.commentText == 'undefined') {
      this.commentText = '';
    }
    const dialogData = new CommentDialogModel("Update Comment", this.commentText, this.isOnHold, this.isReadOnly);

    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (textName == 'npvFullCycle') {
          this.formRisks.npvFullCycleComment = comment;
        }
        else if (textName == 'RealPointForward') {
          this.formRisks.flatRealPointForwardComment = comment;
        }
        else if (textName == 'FlatRealFeedForward') {
          this.formRisks.flatRealFeedForwardComment = comment;
        }
        else if (textName == 'CommodityTestedBy') {
          this.formRisks.commodityTestedByComment = comment;
        }
        else if (textName == 'FeedForwardCommodityTestedBy') {
          this.formRisks.feedForwardCommodityTestedByComment = comment;
        }
        else if (textName == 'RemainingCostIncrease') {
          this.formRisks.remainingCostIncreaseComment = comment;
        }
        this.overviewService.updateFormRisk(this.formRisks).subscribe(res => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  SaveCommentForcAndEDetails(candEdata: CapitalAndExpenditures, textName: string) {
    if (textName == 'Facility') {
      this.commentText = candEdata.facilitiesComment;
    }
    else if (textName == 'Drilling') {
      this.commentText = candEdata.drillingComment;
    }
    else if (textName == 'Contingency') {
      this.commentText = candEdata.contingencyComment;
    }
    else if (textName == 'JointCost') {
      this.commentText = candEdata.otherJointComment;
    }
    else if (textName == 'SoleCost') {
      this.commentText = candEdata.otherSoleComment;
    }
    else if (textName == 'TotalAppropriate') {
      this.commentText = candEdata.totalAppComment;
    }
    else if (textName == 'ExpenseOnCapital') {
      this.commentText = candEdata.expCapComment;
    }
    else if (textName == 'TotalCommitment') {
      this.commentText = candEdata.totalComComment;
    }
    else if (textName == 'CoOwnerView') {
      this.commentText = candEdata.coOwnerViewComment;
    }
    if (this.commentText == null || this.commentText == 'undefined') {
      this.commentText = '';
    }
    const dialogData = new CommentDialogModel("Update Comment", this.commentText, this.isOnHold, this.isReadOnly);

    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        candEdata.modifiedBy = this.provId;
        if (textName == 'Facility') {
          candEdata.facilitiesComment = comment;
        }
        else if (textName == 'Drilling') {
          candEdata.drillingComment = comment;
        }
        else if (textName == 'Contingency') {
          candEdata.contingencyComment = comment;
        }
        else if (textName == 'JointCost') {
          candEdata.otherJointComment = comment;
        }
        else if (textName == 'SoleCost') {
          candEdata.otherSoleComment = comment;
        }
        else if (textName == 'TotalAppropriate') {
          candEdata.totalAppComment = comment;
        }
        else if (textName == 'ExpenseOnCapital') {
          candEdata.expCapComment = comment;
        }
        else if (textName == 'TotalCommitment') {
          candEdata.totalComComment = comment;
        }
        else if (textName == 'CoOwnerView') {
          candEdata.coOwnerViewComment = comment;
        }

        this.overviewService.postCapitalAndExpenditures(candEdata).subscribe(res => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  SaveCommentForGreenhouseGasMetrics(formComment: FormComment, sectionId: number, formFieldId: number) {
    formComment.active = true;
    formComment.createdBy = this.provId;
    formComment.order = 1;
    formComment.formId = this.form.id;
    formComment.sectionId = sectionId;
    formComment.formFieldId = formFieldId;
    formComment.columnId = 1;

    const dialogData = new CommentDialogModel("Update Comment", formComment.comments, this.isOnHold, this.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        formComment.modifiedBy = this.provId;
        formComment.comments = comment;

        this.overviewService.postGreenGasEcoComment(formComment).subscribe(res => {
          // //  this.formComment=res.data;
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  SaveCommentForGreenhouseGasMetricsItem(sectionId: number, formFieldId: number, id: number, commentText: string) {
    this.FormCommentItem.id = id;
    this.FormCommentItem.active = true;
    this.FormCommentItem.createdBy = this.provId;
    this.FormCommentItem.order = 1;
    this.FormCommentItem.formId = this.form.id;
    this.FormCommentItem.sectionId = sectionId;
    this.FormCommentItem.formFieldId = formFieldId;
    this.FormCommentItem.columnId = 1;

    const dialogData = new CommentDialogModel("Update Comment", commentText, this.isOnHold, this.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.FormCommentItem.modifiedBy = this.provId;
        this.FormCommentItem.comments = comment;
        if (formFieldId == 4) {
          this.totalGHG.comments = comment;
        }
        else if (formFieldId == 5) {
          this.scopeOneemissions.comments = comment;
        }
        else if (formFieldId == 6) {
          this.scopeTwoemissions.comments = comment;
        }
        else if (formFieldId == 7) {
          this.totalCarbon.comments = comment;
        }
        else if (formFieldId == 8) {
          this.methaneIntensity.comments = comment;
        }
        else if (formFieldId == 9) {
          this.flaringIntensity.comments = comment;
        }
        else if (formFieldId == 10) {
          this.carbonCost.comments = comment;
        }

        this.overviewService.postGreenGasEcoComment(this.FormCommentItem).subscribe(res => {
          // //  this.formComment=res.data;
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  SaveCommentForKeyEconomic(keyEconomic: KeyEconomicMetrics) {
    const dialogData = new CommentDialogModel("Update Comment", keyEconomic.comment, this.isOnHold, this.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        keyEconomic.modifiedBy = this.provId;
        keyEconomic.comment = comment;
        this.overviewService.postkeyEconomicData(keyEconomic).subscribe(res => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  SaveOperationalCommentPopUp(sectionId: number, formFieldId: number, id: number) {
    this.operationalCommentItem.id = id;
    this.operationalCommentItem.active = true;
    this.operationalCommentItem.createdBy = this.provId;
    this.operationalCommentItem.order = 1;
    this.operationalCommentItem.formId = this.form.id;
    this.operationalCommentItem.sectionId = sectionId;
    this.operationalCommentItem.formFieldId = formFieldId;
    this.operationalCommentItem.columnId = 1;

    const dialogData = new CommentDialogModel("Update Comment", this.operationalCommentItem.comments, this.isOnHold, this.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.operationalCommentItem.modifiedBy = this.provId;
        this.operationalCommentItem.comments = comment;
        this.overviewService.postOperationalComment(this.operationalCommentItem).subscribe(res => {
          // //  this.formComment=res.data;
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  UpdateOperationalChanges(OperationItem: Operational, rowName: string) {
    if (rowName == "NetReserve") {
      OperationItem.modifiedBy = this.provId;
      OperationItem.modifiedOn = new Date();
      OperationItem.total = Number(OperationItem.gas) + Number(OperationItem.oil);
      this.netReservebooking.total = OperationItem.total;
    }
    this.ReserveAndResourse();
    this.overviewService.postOperational(OperationItem).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
  }

  SaveCommentForOperational(sectionId: number, columnId: number, formFieldId: number, commentText: string, rowType: string) {
    // // this.FormCommentItem.id=id;
    this.FormCommentItem.active = true;
    this.FormCommentItem.createdBy = this.provId;
    this.FormCommentItem.order = 1;
    this.FormCommentItem.formId = this.form.id;
    this.FormCommentItem.sectionId = sectionId;
    this.FormCommentItem.formFieldId = formFieldId;
    this.FormCommentItem.columnId = columnId;

    const dialogData = new CommentDialogModel("Update Comment", commentText, this.isOnHold, this.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.FormCommentItem.modifiedBy = this.provId;
        this.FormCommentItem.comments = comment;
        if (rowType == "NetReserve") {
          // //   this.FormCommentItem.id=this.netReservebooking.id;
          if (columnId == 1) {
            this.netReservebooking.oilComments = comment;
          }
          if (columnId == 2) {
            this.netReservebooking.gasComments = comment;
          }
          if (columnId == 3) {
            this.netReservebooking.totalComments = comment;
          }
          if (columnId == 4) {
            this.netReservebooking.total8Comments = comment;
          }
          if (columnId == 6) {
            this.netReservebooking.partnerViewComments = comment;
          }
        }
        if (rowType == "Eur") {
          if (columnId == 1) {
            this.eur.oilComments = comment;
          }
          if (columnId == 2) {
            this.FormCommentItem.id = this.eur.id;
            this.eur.gasComments = comment;
          }
          if (columnId == 3) {
            this.eur.totalComments = comment;
          }
          if (columnId == 4) {
            this.eur.total8Comments = comment;
          }
          if (columnId == 6) {
            this.eur.partnerViewComments = comment;
          }
        }
        if (rowType == "FutureAdd") {
          // //  this.FormCommentItem.id=this.futureAdd.id;
          if (columnId == 1) {
            this.futureAdd.oilComments = comment;
          }
          if (columnId == 2) {
            this.futureAdd.gasComments = comment;
          }
          if (columnId == 3) {
            this.futureAdd.totalComments = comment;
          }
          if (columnId == 4) {
            this.futureAdd.total8Comments = comment;
          }
          if (columnId == 6) {
            this.futureAdd.partnerViewComments = comment;
          }
        }
        if (rowType == "PeakProduction") {
          // // this.FormCommentItem.id=this.peakProduction.id;
          if (columnId == 1) {
            this.peakProduction.oilComments = comment;
          }
          if (columnId == 2) {
            this.peakProduction.gasComments = comment;
          }
          if (columnId == 3) {
            this.peakProduction.totalComments = comment;
          }
          if (columnId == 4) {
            this.peakProduction.total8Comments = comment;
          }
          if (columnId == 6) {
            this.peakProduction.partnerViewComments = comment;
          }
        }
        if (rowType == "FirstProduction") {
          // // this.FormCommentItem.id=this.firstProduction.id;
          if (columnId == 1) {
            this.firstProduction.oilComments = comment;
          }
          if (columnId == 2) {
            this.firstProduction.gasComments = comment;
          }
          if (columnId == 3) {
            this.firstProduction.totalComments = comment;
          }
          if (columnId == 4) {
            this.firstProduction.total8Comments = comment;
          }
          if (columnId == 6) {
            this.firstProduction.partnerViewComments = comment;
          }
        }
        this.overviewService.postOperationalComment(this.FormCommentItem).subscribe(res => {
          // //  this.formComment=res.data;
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  SaveOperationalComment() {
    this.overview.formId = this.form.id;
    this.overviewService.postOverViewData(this.overview).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
  }

  showReservesandResourcesPopUp() {
    const message = 'This section is for Upstream projects only and captures key reserves, resource, and production metrics.'
      + '\n<p></p>' + 'User must input P1 reserves booking at AR and first 12 months production attainment; other metrics are calculated using'
      + '\n' + 'imported data and NRI percentages entered in section 1.2 (Ownership).'
      + '\n<p></p>' + 'Guidance for first 12 months production attainment:'
      + '\n<ul><li>' + 'Acceleration projects: Pure acceleration projects should result in zero incremental recovery. Acceleration would be'
      + '\n' + 'modeled in the economics by positive near-term production followed by offsetting negative production. If an acceleration'
      + '\n' + 'project moves volumes from beyond the concession timeframe to within the concession timeframe, those volumes may be'
      + '\n' + 'counted as incremental recovery and reserves.</li>'
      + '\n<li>' + 'First 12 months production attainment: determined as the total NRI production (P50 BOE basis) divided by 365 days per year.</li></ul>'
      + '\n<p></p><p></p>' + 'Use comment boxes to provide additional detail/narrative relevant to reserves/resources.';
    const dialogData = new InfoDialogModel("Reserves and Resources", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  UpdateKeyEconomicMetrics(keyEconomicMetrics: KeyEconomicMetrics) {
    keyEconomicMetrics.modifiedBy = this.provId;

    if (this.form.formType == 'EPC') {
      if (keyEconomicMetrics.order == 1 || keyEconomicMetrics.order == 5 || keyEconomicMetrics.order == 6) {
        // keyEconomicMetrics.feedForward = keyEconomicMetrics.feedForward.replace(0, '');
        // keyEconomicMetrics.pointForward = keyEconomicMetrics.pointForward.replace(0, '');
      }

      if (keyEconomicMetrics.order == 2 || keyEconomicMetrics.order == 3) {
        keyEconomicMetrics.fullCycle = parseFloat(keyEconomicMetrics.fullCycle);
        keyEconomicMetrics.feedForward = parseFloat(keyEconomicMetrics.feedForward);
        keyEconomicMetrics.pointForward = parseFloat(keyEconomicMetrics.pointForward);
      }
    }

    this.overviewService.postKeyEconomicMetricsData(keyEconomicMetrics).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });

    if (this.form.formType == 'EPC') {
      if (keyEconomicMetrics.order == 3) {
        keyEconomicMetrics.fullCycle = Number(keyEconomicMetrics.fullCycle).toFixed(2).toString();
        keyEconomicMetrics.feedForward = Number(keyEconomicMetrics.feedForward).toFixed(2).toString();
        keyEconomicMetrics.pointForward = Number(keyEconomicMetrics.pointForward).toFixed(2).toString();
      }
      if (keyEconomicMetrics.order == 2) {
        keyEconomicMetrics.fullCycle = Number(keyEconomicMetrics.fullCycle).toFixed(1).toString();
        keyEconomicMetrics.feedForward = Number(keyEconomicMetrics.feedForward).toFixed(1).toString();
        keyEconomicMetrics.pointForward = Number(keyEconomicMetrics.pointForward).toFixed(1).toString();
      }

      if (keyEconomicMetrics.order == 1 || keyEconomicMetrics.order == 5 || keyEconomicMetrics.order == 6) {
        keyEconomicMetrics.fullCycle = keyEconomicMetrics.fullCycle.replace('$', '');
        keyEconomicMetrics.feedForward = keyEconomicMetrics.feedForward.replace('$', '');
        keyEconomicMetrics.pointForward = keyEconomicMetrics.pointForward.replace('$', '');
        keyEconomicMetrics.fullCycle = keyEconomicMetrics.fullCycle.replaceAll(',', '');
        keyEconomicMetrics.feedForward = keyEconomicMetrics.feedForward.replaceAll(',', '');
        keyEconomicMetrics.pointForward = keyEconomicMetrics.pointForward.replaceAll(',', '');
        keyEconomicMetrics.fullCycle = Number(keyEconomicMetrics.fullCycle).toFixed(1).toString();
        keyEconomicMetrics.feedForward = Number(keyEconomicMetrics.feedForward).toFixed(1).toString();
        keyEconomicMetrics.pointForward = Number(keyEconomicMetrics.pointForward).toFixed(1).toString();
        // //  keyEconomicMetrics.fullCycle = '$'+String(keyEconomicMetrics.fullCycle);
        // //  keyEconomicMetrics.feedForward = '$'+String(keyEconomicMetrics.feedForward);
        // //  keyEconomicMetrics.pointForward = '$'+String(keyEconomicMetrics.pointForward);
      }

      if (keyEconomicMetrics.order == 2) {
        keyEconomicMetrics.fullCycle = keyEconomicMetrics.fullCycle + '%';
        keyEconomicMetrics.feedForward = keyEconomicMetrics.feedForward + '%';
        keyEconomicMetrics.pointForward = keyEconomicMetrics.pointForward + '%';
      }
      if (keyEconomicMetrics.order == 4) {
        if (keyEconomicMetrics.fullCycle != '') {
          keyEconomicMetrics.fullCycle = Number(keyEconomicMetrics.fullCycle).toFixed(0).toString();
        }
        if (keyEconomicMetrics.feedForward != '') {
          keyEconomicMetrics.feedForward = Number(keyEconomicMetrics.feedForward).toFixed(0).toString();
        }
        if (keyEconomicMetrics.pointForward != '') {
          keyEconomicMetrics.pointForward = Number(keyEconomicMetrics.pointForward).toFixed(0).toString();
        }
      }
    }
  }

  LoadOverviewCharts() {
    this.chartService.GetOverviewCharts(this.formId, this.versionId).subscribe((res: any) => {
      if (res) {
        this.OverviewChartModel = res;
        this.formconfiguration = res.formConfigurationModel;
        let carbonyears: any = [];
        let carbonvalues: any = [];
        this.OverviewChartModel.totalGHGEmissionAndCarbonCostChart.carbonCost.data.forEach(function (item) {
          carbonyears.push(item.year);
          carbonvalues.push(item.value);
        });
        this.Carbonchartdata.yearsdata = carbonyears;
        this.Carbonchartdata.chartvalues = carbonvalues;
        let totalghgemmissionyears: any = [];
        let totalghgemmisionvalues: any = [];
        this.OverviewChartModel.totalGHGEmissionAndCarbonCostChart.totalGHGEmission.data.forEach(function (item) {
          totalghgemmissionyears.push(item.year);
          totalghgemmisionvalues.push(item.value);
        });
        this.TotalGHGEmmission.yearsdata = totalghgemmissionyears;
        this.TotalGHGEmmission.chartvalues = totalghgemmisionvalues;
        this.TotalGHGEmissionAndCarbonCostChartData =
        {
          labels: this.Carbonchartdata.yearsdata,
          datasets: [
            {
              label: this.OverviewChartModel.totalGHGEmissionAndCarbonCostChart.totalGHGEmission.name,

              fill: false,
              borderColor: "#0066b2",
              yAxisID: 'y',
              tension: 0,
              backgroundColor: 'white',
              data: this.TotalGHGEmmission.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : e.valueOf().toFixed(3); })

            },
            {
              label: this.OverviewChartModel.totalGHGEmissionAndCarbonCostChart.carbonCost.name,
              fill: false,
              borderColor: "#e5601f",
              yAxisID: 'y1',
              tension: 0,
              backgroundColor: 'white',
              data: this.Carbonchartdata.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : e.valueOf().toFixed(3); })
            }]
        };
        this.multiaxisOptions = {
          stacked: false,
          plugins: {
            legend: {
              labels: {
                color: '#495057'
              }
            },
            title: {
              display: true,
              position: 'top',
              text: 'Total GHG Emission And Carbon Cost',
              font: {
                weight: '800',
                size:12,
              },
              color: 'rgba(0, 0, 0, 1)',
            },
          },
          scales: {
            x: {
              ticks: {
                maxTicksLimit: 10,
                maxRotation: 90,
                minRotation: 90,
                fontColor: 'rgba(0, 0, 0, 1)'
              },
              grid: {
                display: false
              }
            },
            y: {
              type: 'linear',
              display: true,
              position: 'left',
              ticks: {
                beginAtZero: true,
                fontColor: 'rgba(0, 0, 0, 1)',
              },
              title: {
                display: true,
                text: 'Total GHG emissions (MM mtCO2e/yr)',
                color: 'black'
              },
              grid: {
                color: '#ebedef'
              },
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              ticks: {
                color: '#495057'
              },
              title: {
                display: true,
                text: 'Carbon Cost ($/boe)',
                color: 'black'
              },
              grid: {
                display: false
              }
            }
          },
          elements: {
            point: {
              radius: 0
            }
          }
        };
        let flaringyears: any = [];
        let flaringvalues: any = [];
        this.OverviewChartModel.methaneAndFlaringAndTotalIntensityChart.flaringIntensity.data.forEach(function (item) {
          flaringyears.push(item.year);
          flaringvalues.push(item.value);
        });
        this.flaringintensitychartdata.yearsdata = flaringyears;
        this.flaringintensitychartdata.chartvalues = flaringvalues;

        let totalintensityyears: any = [];
        let totalintensityvalues: any = [];
        this.OverviewChartModel.methaneAndFlaringAndTotalIntensityChart.totalIntensity.data.forEach(function (item) {
          totalintensityyears.push(item.year);
          totalintensityvalues.push(item.value);
        });
        this.totalintensitychartdata.yearsdata = totalintensityyears;
        this.totalintensitychartdata.chartvalues = totalintensityvalues;

        let methaneintensityyears: any = [];
        let methaneintensityvalues: any = [];
        this.OverviewChartModel.methaneAndFlaringAndTotalIntensityChart.methaneIntensity.data.forEach(function (item) {
          methaneintensityyears.push(item.year);
          methaneintensityvalues.push(item.value);
        });
        this.Methaneintensitychartdata.yearsdata = methaneintensityyears;
        this.Methaneintensitychartdata.chartvalues = methaneintensityvalues;

        this.MethaneAndFlaringAndTotalIntensityChartData =
        {
          labels: this.totalintensitychartdata.yearsdata,
          datasets: [
            {
              label: this.OverviewChartModel.methaneAndFlaringAndTotalIntensityChart.methaneIntensity.name,
              fill: false,
              borderColor: "#444b0d",
              backgroundColor: 'white',
              data: this.Methaneintensitychartdata.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : e.valueOf().toFixed(3); })
            },
            {
              label: this.OverviewChartModel.methaneAndFlaringAndTotalIntensityChart.flaringIntensity.name,
              fill: false,
              borderColor: "#76923b",
              backgroundColor: 'white',
              data: this.flaringintensitychartdata.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : e.valueOf().toFixed(3); })
            },
            {
              label: this.OverviewChartModel.methaneAndFlaringAndTotalIntensityChart.totalIntensity.name,
              fill: false,
              borderColor: "#0066b2",
              backgroundColor: 'white',
              data: this.totalintensitychartdata.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : e.valueOf().toFixed(3); })
            },]
        };
        this.IntensitymultiaxisOptions = {
          stacked: false,
          plugins: {
            legend: {
              labels: {
                color: '#495057'
              }
            },
            title: {
              display: true,
              position: 'top',
              text: 'Methane And Flaring And Total Intensity',
              font: {
                weight: '800',
                size:12,
              },
              color: 'rgba(0, 0, 0, 1)',
            },
          },
          scales: {
            x: {
              ticks: {
                maxTicksLimit: 10,
                maxRotation: 90,
                minRotation: 90,
                fontColor: 'rgba(0, 0, 0, 1)'
              },
              grid: {
                display: false
              }
            },
            y: {
              type: 'linear',
              display: true,
              position: 'left',
              ticks: {
                beginAtZero: true,
                fontColor: 'rgba(0, 0, 0, 1)',
              },
              title: {
                display: true,
                text: 'Intensity (kgCO2e/boe)',
                color: 'black'
              },
              grid: {
                color: '#ebedef'
              },
            }
          },
          elements: {
            point: {
              radius: 0
            }
          }
        };
      }
    });
  }
  ChangeYears(formconfiguration: FormConfigurationModel) {
    this.chartService.ChangeYears(formconfiguration).subscribe(res => {
      this.LoadOverviewCharts();
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }
  ShowChartModal() {
    const message = "Charts are plotted showing the project at all 3 price cases based on the data imported to the tool. Provide a brief description in the comment box to assist interpretation of the plot; anticipate questions asked and provide answers. If a chart is blank, please describe why this is so. All charts are Chevron net." +
      "<br><br> User can select start and end dates to modify timeframe shown on plot by entering dates in the “Project Data Shown” fields:" +
      "<img src='/assets/Content/Images/help/chart_01.png' alt= 'img'>" +
      "<br><br>" +
      "Additional chart settings are available through the ‘settings’ button on the main project page. Clicking on the ‘settings’ button will bring you to the ‘Chart Display Settings” menu where charts can be retitled and hidden from the form if desired.      " +
      "<br><br>" +
      "<img src='/assets/Content/Images/help/chart_02.png' alt= 'img'>" +
      "<br>" +
      "<img src='/assets/Content/Images/help/chart_03.png' alt= 'img'>"
    const dialogData = new InfoDialogModel("Chart", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }

  ReserveAndResourse() {
    this.netRevenueInterest = this.ownershipData[0].netRevenueInterest;
    if (this.netRevenueInterest == null || this.netRevenueInterest == '') {
      this.netRevenueInterest = 0;
    }
    else {
      this.netRevenueInterest = this.netRevenueInterest.replace('%', '');
      // //  this.netRevenueInterest=this.netRevenueInterest.toFixed(1);
    }
    // //    this.netReservebooking=res.operationalList.find((x: { operationalName: string; })=>x.operationalName=="Net P1 Reserves booking at AR (mmboe)");

    if (!this.netReservebooking) {
      this.netReservebooking = new Operational();
    }
    else {
      if (this.netReservebooking.total == null) {
        this.netReservebooking.total = 0;
      }
      if (this.netRevenueInterest != 0 && this.netReservebooking.total != 0) {
        this.netReservebooking.total8 = (this.netReservebooking.total / this.netRevenueInterest)
        this.netReservebooking.total8 = (this.netReservebooking.total8 * 100);
        this.netReservebooking.total8 = Number(this.netReservebooking.total8).toFixed(1).toString();
      }
      if (this.netReservebooking.oil != null) {
        this.netReservebooking.oil = Number(this.netReservebooking.oil).toFixed(1).toString();
      }
      if (this.netReservebooking.gas != null) {
        this.netReservebooking.gas = Number(this.netReservebooking.gas).toFixed(1).toString();
      }
      if (this.netReservebooking.total != null) {
        this.netReservebooking.total = Number(this.netReservebooking.total).toFixed(1).toString();
      }
      if (this.netReservebooking.partnerView != null) {
        this.netReservebooking.partnerView = Number(this.netReservebooking.partnerView).toFixed(1).toString();
      }
    }
    // //  this.eur=res.operationalList.find((x: { operationalName: string; })=>x.operationalName=="EUR (net mmboe)");
    if (!this.eur) {
      this.eur = new Operational();
    }
    else {
      if (this.eur.total == null) {
        this.eur.total = 0;
      }
      if (this.netRevenueInterest != 0 && this.eur.total != 0) {
        this.eur.total8 = (this.eur.total / this.netRevenueInterest)
        this.eur.total8 = (this.eur.total8 * 100);
        this.eur.total8 = Number(this.eur.total8).toFixed(1).toString();
      }
      if (this.eur.total != null) {
        this.eur.total = Number(this.eur.total).toFixed(1).toString();
      }
      if (this.eur.partnerView != null) {
        this.eur.partnerView = Number(this.eur.partnerView).toFixed(1).toString();
      }
    }
    // //   this.futureAdd =res.operationalList.find((x: { operationalName: string; })=>x.operationalName=="EV6P + Future Adds (net mmboe)");
    if (!this.futureAdd) {
      this.futureAdd = new Operational();
    }
    else {
      if (this.futureAdd.total == null) {
        this.futureAdd.total = 0;
      }
      if (this.netRevenueInterest != 0 && this.futureAdd.total != 0) {
        this.futureAdd.total8 = (this.futureAdd.total / this.netRevenueInterest)
        this.futureAdd.total8 = (this.futureAdd.total8 * 100);
        this.futureAdd.total8 = Number(this.futureAdd.total8).toFixed(1).toString();
      }
      if (this.futureAdd.total != null) {
        this.futureAdd.total = Number(this.futureAdd.total).toFixed(1).toString();
      }
      if (this.futureAdd.partnerView != null) {
        this.futureAdd.partnerView = Number(this.futureAdd.partnerView).toFixed(1).toString();
      }
    }
    // //  this.peakProduction =res.operationalList.find((x: { operationalName: string; })=>x.operationalName=="Peak Production Rate (mboed)");
    if (!this.peakProduction) {
      this.peakProduction = new Operational();
    }
    else {
      if (this.peakProduction.total == null) {
        this.peakProduction.total = 0;
      }
      if (this.netRevenueInterest != 0 && this.peakProduction.total != 0) {
        this.peakProduction.total8 = (this.peakProduction.total / this.netRevenueInterest)
        this.peakProduction.total8 = (this.peakProduction.total8 * 100);
        this.peakProduction.total8 = Number(this.peakProduction.total8).toFixed(1).toString();
      }
      if (this.peakProduction.total != null) {
        this.peakProduction.total = Number(this.peakProduction.total).toFixed(1).toString();
      }
      if (this.peakProduction.partnerView != null) {
        this.peakProduction.partnerView = Number(this.peakProduction.partnerView).toFixed(1).toString();
      }
    }
    // //  this.firstProduction=res.operationalList.find((x: { operationalName: string; })=>x.operationalName=="First 12 months Production Attainment (mboed)");
    if (!this.firstProduction) {
      this.firstProduction = new Operational();
    }
    else {
      if (this.firstProduction.total == null) {
        this.firstProduction.total = 0;
      }
      if (this.netRevenueInterest != 0 && this.firstProduction.total != 0) {
        this.firstProduction.total8 = (this.firstProduction.total / this.netRevenueInterest)
        this.firstProduction.total8 = (this.firstProduction.total8 * 100);
        this.firstProduction.total8 = Number(this.firstProduction.total8).toFixed(1).toString();
      }
      if (this.firstProduction.total != null) {
        this.firstProduction.total = Number(this.firstProduction.total).toFixed(1).toString();
      }
      if (this.firstProduction.partnerView != null) {
        this.firstProduction.partnerView = Number(this.firstProduction.partnerView).toFixed(1).toString();
      }
    }
    //End code is for upstream project 
  }
}
