import { FormComment } from "../FormComment.model";
import { ChartSeries } from "./ChartSeries.model";
import{FormChart} from "./FormChart.model"
export class ChartModel 
{
    lowFeedForward: ChartSeries = new ChartSeries;
    highFeedForward: ChartSeries = new ChartSeries;
    midFeedForward: ChartSeries = new ChartSeries;
    originalMidFeedForward :ChartSeries=new ChartSeries;
    midFullCycle:ChartSeries=new ChartSeries;
    originalMidFullCycle:ChartSeries=new ChartSeries;
    isVisible: boolean = false;
}
export class NonUpstreamChartModel
{
    nonUpstreamCapitalAndExpenditureChart: ChartModel = new ChartModel();
    nonUpstreamAfterTaxCashFlowChart:ChartModel=new ChartModel();
    volumeChart:ChartModel=new ChartModel();
    nonUpstreamCumulativeCashChart:ChartModel=new ChartModel();
    nonUpstreamOperatingExpenseChart:ChartModel=new ChartModel();
    nonUpstreamEarningsBeforeInterestChart:ChartModel=new ChartModel();
    formchart:FormChart=new FormChart();
    formConfiguration:any;
    formComment:FormComment[]=[];
    isUpstream:boolean=false;
    nonUpstreamReturnOnCapitalEmployedChart:ChartModel=new ChartModel();
    nonUpstreamOperationalEarningsAfterTaxChart:ChartModel=new ChartModel();
}

