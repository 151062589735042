<app-popup-active-users></app-popup-active-users>
<div class="box-container">
  <div class="row">
    <div class="col">
      <h3> Appropriation Request for FID Supplement #{{form.order}} </h3>
      <div class="row text-center">
        <a class="no-underline" [routerLink]="['/go36project/viewform']" [queryParams]="{projectId: projectId}">
          <h5 style="padding: 0; display:inline; font-size: 14px;">
            {{projectName}}
          </h5>
        </a>
      </div>
      <h5 *ngIf="form.isReadOnly" class="text-danger margin-0 padding-0" style="font-size: 14px;">
        (Previous Version #{{form.version}} - {{form.versionName}} - Read-Only)
      </h5>
      <h5 class="text-danger margin-0 padding-0" style="font-size: 14px !important;" *ngIf="form.isOnHold">
        Form is on Hold. No editing can be performed
      </h5>
    </div>
  </div>

  <div class="row">
    <span *ngIf="!form.isReadOnly && form.features?.hasGo_36_FormHoldFeature"
      [ngClass]="{'disabledNoOfCasesDiv': !this.userAccessList?.data?.canHold}">
      <mat-slide-toggle (change)="onChange($event)" [checked]="form.isOnHold">
        <b class="app-font">On Hold</b>
      </mat-slide-toggle>
      <span class="glyphicon glyphicon-question-sign" (click)="ShowOnHoldModal()"></span>
    </span>
    <!-- <button type="button" style="float:right; color: white !important;"
      class="text-right btn btn-sm btn-success btn-dark" data-bind="visible: IsLatestVersion(), enable: !IsLocked()"
      [disabled]="this.userAccessList?.data?.isLocked">
      Save
    </button> -->
  </div>

  <div class="row tabbed tabbed-content-control centered">
    <div class="filter-bar tab-filter-bar font-gotham">
      <mat-tab-group [selectedIndex]="1" animationDuration="0ms" style='min-height:300px' [disableRipple]='true'
        disablepagination="true">

        <mat-tab label="Data Import">
          <ng-template matTabContent>
            <div style="margin-top: 25px;">
              <app-data-import
                [isReadOnly]="form.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                [form]='form'></app-data-import>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Overview">
          <ng-template matTabContent>
            <div style="margin-top: 25px;" *ngIf="!form.IsOldVersionOfForms">
              <app-fid-supplement-overview [isOnHold]='form.isOnHold' [projectId]="projectId"
                [isSsbodAvailable]="isSsbodAvailable"
                [isReadOnly]='form.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
              </app-fid-supplement-overview>
            </div>
            <div style="margin-top: 25px;" *ngIf="form.IsOldVersionOfForms">
              <app-fid-supplement-overview-old [isOnHold]='form.isOnHold' [projectId]="projectId"
                [isSsbodAvailable]="isSsbodAvailable"
                [isReadOnly]='form.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'
                [features]="form.features" [isUpstream]="form.isUpstream">
              </app-fid-supplement-overview-old>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Business Case" style="color: #0066B2;" *ngIf="!form.IsOldVersionOfForms">
          <ng-template matTabContent>
            <div style="margin-top: 25px;">
              <app-business-case [isOnHold]='form.isOnHold' [form]='form'
                [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
              </app-business-case>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Alternative Selection" *ngIf="!form.IsOldVersionOfForms">
          <ng-template matTabContent>
            <div style="margin-top: 25px;">
              <app-alternative-selection [isOnHold]='form.isOnHold' [form]='form'
                [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
              </app-alternative-selection>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Charts">
          <ng-template matTabContent>
            <div style="margin-top: 25px;">
              <app-feed-supplement-chart *ngIf="(form.id>0)" [form]='form' [isOnHold]='form.isOnHold'
                [isReadOnly]='form.isReadOnly'
                [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
              </app-feed-supplement-chart>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Basis of Evaluation">
          <ng-template matTabContent>
            <div style="margin-top: 25px;">
              <app-basis-of-evaluation [isOnHold]='form.isOnHold' [form]='form'
                [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
              </app-basis-of-evaluation>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Execution">
          <ng-template matTabContent>
            <div style="margin-top: 25px;" *ngIf="!form.IsOldVersionOfForms">
              <app-execution [isOnHold]='form.isOnHold' [form]='form'
                [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
              </app-execution>
            </div>
            <div style="margin-top: 25px;" *ngIf="form.IsOldVersionOfForms">
              <app-execution-old [isOnHold]='form.isOnHold' [form]='form'
                [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
              </app-execution-old>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Risks">
          <ng-template matTabContent>
            <div style="margin-top: 25px;">
              <app-feed-supplement-risks [isOnHold]='form.isOnHold' [form]='form'
                [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
              </app-feed-supplement-risks>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Lessons Learned" *ngIf="form.features?.hasLessonsLearnedFeature">
          <ng-template matTabContent>
            <div style="margin-top: 25px;">
              <app-lessons-learned [isOnHold]='form.isOnHold' [readOnly]='form.isReadOnly' [originalId]='form.id'
                [passedSelectedPhase]="0" [isOldVersionOfForms]="form.IsOldVersionOfForms"
                [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
              </app-lessons-learned>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Project Documentation">
          <ng-template matTabContent>
            <div style="margin-top: 25px;">
              <app-project-documentation [isOnHold]='form.isOnHold' [form]='form'
                [isReadOnlyGlobalProjectLevel]='form.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
              </app-project-documentation>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab label="Form Reviewer" *ngIf="form.features?.hasWorkFlowProcessFeature">
          <ng-template matTabContent>
            <div style="margin-top: 25px;">
              <app-form-reviewer [isOnHold]='form.isOnHold' [formType]='form.formType' [projectId]="projectId"
                [projectName]="form.Name" [formsLogData]='formsLogData' [isReadOnly]='form.isReadOnly'
                [isOldVersionOfForms]="form.IsOldVersionOfForms"
                [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked'
                [formReviewData]='this.userAccessList'>
              </app-form-reviewer>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <div class="row" style="margin-top: 15px;">
    <div class="col-xs-12 padding-0">
      <div class="row">
        <div class="form-group col-sm-6 col-xs-12 text-left padding-0 ">
          <button type="button" class="btn btn-sm btn-primary" [routerLink]="['/go36project/viewform']"
            [queryParams]="{projectId: projectId}" style="color: white !important;">
            Close
          </button>
          <button *ngIf="!form.isReadOnly" type="button" class="btn btn-sm btn-danger"
            [routerLink]="['/forms/fid_Supplement/delete']" style="color: white !important;"
            [queryParams]="{projectId: projectId,formId: form.id,versionName: versionName, formType: form.formType}"
            [disabled]="!this.userAccessList?.data?.canDeleteForm">
            Delete
          </button>
        </div>
        <div class="form-group col-sm-6 col-xs-12 text-right padding-0">
          <!-- <button type="button" class="btn btn-sm btn-primary btn-dark" style="color: white !important;" (click)="ExportPDF()">PDF</button> -->

          <a [href]="pdfUrl" class="btn btn-sm btn-primary btn-dark" target="_blank"
            style="color: white !important;margin-right: 0.5em;margin-bottom: 0.5em;">PDF</a>
          <button *ngIf="!form.isReadOnly && !form.IsOldVersionOfForms" type="button"
            class="btn btn-sm btn-success btn-dark" (click)="OpenArchiveDialog()" style="color: white !important;"
            [disabled]="isOnHold || !this.userAccessList?.data?.canArchive">
            Archive
          </button>

          <button *ngIf="!form.isReadOnly && form.IsOldVersionOfForms" type="button"
            class="btn btn-sm btn-success btn-dark" (click)="OpenArchiveDialog()" style="color: white !important;"
            [disabled]="isOnHold || !this.userAccessList?.data?.canArchive || this.userAccessList?.data?.isLocked">
            Archive
          </button>

          <button
            *ngIf="!form.isReadOnly && this.userAccessList?.data?.isLatestVersion && !form.features?.hasWorkFlowProcessFeature"
            type="button" class="btn btn-sm btn-success btn-dark" (click)="performFormAction('forreview')"
            style="color: white !important;"
            [disabled]="isOnHold || !this.userAccessList?.data?.canSubmit || form.isReadOnly ||  this.userAccessList?.data?.isLocked || this.forReviewLockByFormState">Send
            For Review</button>

          <button
            *ngIf="!form.isReadOnly && this.userAccessList?.data?.isLatestVersion && !form.features?.hasWorkFlowProcessFeature"
            type="button" class="btn btn-sm btn-warning btn-dark" (click)="performFormAction('sendback')"
            style="color: white !important;"
            [disabled]="isOnHold || !this.userAccessList?.data?.canSendBack || form.isReadOnly ||  this.userAccessList?.data?.isLocked || this.sendBackLockByFormState">Send
            Back</button>

          <button
            *ngIf="!form.isReadOnly && this.userAccessList?.data?.isLatestVersion && !form.features?.hasWorkFlowProcessFeature"
            type="button" class="btn btn-sm btn-success btn-dark" (click)="performFormAction('approved')"
            style="color: white !important;"
            [disabled]="isOnHold || !this.userAccessList?.data?.canApprove || form.isReadOnly ||  this.userAccessList?.data?.isLocked || this.concurLockByFormState">Concur</button>


        </div>
      </div>
    </div>
  </div>
</div>