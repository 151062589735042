import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { DesignParameterViewModel } from '../../Model/designParameterView.model';
import { EvFormulaViewModel } from '../../Model/evFormulaView.model';
import { SubsurfaceViewModel } from '../../Model/subsurfaceView.model';
import { SsbodService } from '../../service/ssbod.service';

@Component({
  selector: 'app-subsurface-basis',
  templateUrl: './subsurface-basis.component.html',
  styleUrls: ['./subsurface-basis.component.css']
})
export class SubsurfaceBasisComponent implements OnInit {
  designParameterViewModel:  any[] = [];
  evFormulaViewModel:  EvFormulaViewModel[] = [];
  projectValuesViewModel:  DesignParameterViewModel[] = [];
  formId:number=0;
  selectedEvFormula : any = 1;
  @Input() type: string = "";
  @Input() viewModel: SubsurfaceViewModel = new SubsurfaceViewModel();
  @Input() isReadOnlyGlobalProjectLevel : boolean = false;
  
  constructor(private ssbodService: SsbodService,private route: ActivatedRoute,private go36ProjectService: Go36projectService,private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.formId = this.viewModel.Id;
    this.GetDesignSection();
    this.GetEVFormula();
    this.GetDesignSectionCalculations();
  }
  GetDesignSection(){
    this.ssbodService.GetDesignSection(this.formId,this.type).subscribe(res => {
      res.forEach((element,index) => {
        this.designParameterViewModel.push(this.ssbodService.checkPrecision(element,index));
      });
    });
  }
  GetEVFormula(){
    this.ssbodService.GetEVFormula().subscribe(res => {
      this.evFormulaViewModel = res;
      this.GetSelectedEvFormula();
    });
  }
  GetSelectedEvFormula(){
    this.ssbodService.GetSelectedEvFormula(this.formId,this.type).subscribe((res: any) => {
      this.selectedEvFormula = res;
    });
  }
  onEvFormulaChange(event:any){
    this.selectedEvFormula = Number(event.target.value);
    this.ssbodService.SaveDesignSectionEVFormula(this.formId,this.type,event.target.value).subscribe((res: any) => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");  
      this.designParameterViewModel = [];
      res.forEach((element,index) => {
        this.designParameterViewModel.push(this.ssbodService.checkPrecision(element,index));
      });
    });
  }
  getExpectedValue($event:any) {  
    this.designParameterViewModel = $event;
    this.GetDesignSectionCalculations();
  } 
  GetDesignSectionCalculations(){
    this.ssbodService.GetDesignSectionCalculations(this.formId,this.type).subscribe(res => {
     this.projectValuesViewModel =  this.ssbodService.checkPrecision(res,0);
    });
  }

  trackByFunction(item: any): any {
    return item.Name;
  }
}
