import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppsettingsService } from 'src/app/Shared/appsettings.service';
import { RisksModel } from 'src/app/Model/Risks.model';

@Injectable({
  providedIn: 'root'
})

export class RisksService {
  GetUrl = 'api/Risks/getRisksData?formId=';
  FormRisksUrl = 'api/Risks/SaveFormRiskData';
  CreateDefaultUrl = 'api/Risks/CreateRisksData';
  UpdateRisksUrl = 'api/Risks/SaveRisksData';
  UpdateEconomicSensitivityUrl = 'api/Risks/SaveEconomicSensitivityData';

  constructor(private http: HttpClient,
    private appsettings: AppsettingsService,) { }

  getData(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetUrl + formId);
  }

  postFormRiskData(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.FormRisksUrl, formData);
  }

  createDefaultData(formId: number, provId: string, projectId: number, isSuppliment: boolean) {
    const params = new HttpParams()
      .append('formId', formId)
      .append('provId', provId)
      .append('projectId', projectId)
      .append('isSuppliment', isSuppliment);

    return this.http.post<any>(this.appsettings.apiBaseUrl + this.CreateDefaultUrl, '', {
      params: params
    });
  }

  postRisksData(formData: any) {
    return this.http.post<RisksModel[]>(this.appsettings.apiBaseUrl + this.UpdateRisksUrl, formData);
  }

  postEconomicSensitivityData(formData: any) {
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.UpdateEconomicSensitivityUrl, formData);
  }
}
