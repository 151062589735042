import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CommentDialogComponent, CommentDialogModel } from 'src/app/Modals/comment-dialog/comment-dialog.component';
import { EASupplementPart3Model } from 'src/app/Model/EASupplementPart3.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { ArSupplementPart3Service } from '../../service/ar-supplement-part3.service';
import { SupplementCommentModel } from 'src/app/Model/SupplementComment.model';

@Component({
  selector: 'app-supplement-part3',
  templateUrl: './supplement-part3.component.html',
  styleUrls: ['./supplement-part3.component.css']
})

export class SupplementPart3Component implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [
      // 'fontfamily','fontsize','fontColor','fontBackgroundColor', '|',
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
    ],
    autosave: {
      save(editor) {
      }
    }
  }
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  @Input() supplementId: number = 0;
  projectId: number = 0;
  versionName: number = 0;
  part3Data = new EASupplementPart3Model;
  ckeditorText: string = '';
  // section 8
  secondYear: number = 0;
  thirdYear: number = 0;
  fourthYear: number = 0;
  fifthYear: number = 0;
  fifthYearBeyond: number = 0;
  // section 3
  section1Total: any;
  // section 2
  section2bTotal: any;
  section2cTotal: any;
  section2dTotal: any;
  section2eTotal: any;
  section2Total: any;

  // section 3
  section3Total: any;

  @Input() comments: any = [];
  @Input() isOnHold: boolean = false;
  isSupplement = true;
  @Input() isReadOnly: boolean = false;
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;
  @Input() IsOldVersionOfForms: boolean = false;
  @Input() IsNewCEisApplicableForms: boolean = true;
  constructor(private route: ActivatedRoute,
    private notifyService: NotificationService,
    private go36ProjectService: Go36projectService,
    public dialog: MatDialog,
    private part3Service: ArSupplementPart3Service,
    public utilityService: UtilityService) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.route.queryParams
      .subscribe(params => {
        this.supplementId = params['supplementId'];
        this.projectId = params['projectId'];
        this.versionName = params['versionName'];
      });
    this.getARPart3Data();
  }

  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }

  onReady(editor, name) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.UpdateckeditorChanges(editor.getData(), name);
        }
      }
    });
  }

  getARPart3Data() {
    this.part3Service.GetPart3Data(this.supplementId, this.versionName).subscribe((res: any) => {
      this.part3Data = res;
      if (this.part3Data != null &&  this.part3Data.arChevronShare != null) {
        this.part3Data.arChevronShare = Number(this.part3Data.arChevronShare).toString();
        this.part3Data.arChevronShare =  this.part3Data.arChevronShare + '%';
      }
      if (this.part3Data == null) {
        this.part3Service.createDefaultData(this.supplementId, this.projectId, this.provId).subscribe((res: any) => {
          this.part3Data = res;

          this.SetYears(this.part3Data);
          this.CalculateSection1Total(this.part3Data);
          this.CalculateSection2bTotal(this.part3Data);
          this.CalculateSection2cTotal(this.part3Data);
          this.CalculateSection2dTotal(this.part3Data);
          this.CalculateSection2eTotal(this.part3Data);
          this.CalculateSection8Total(this.part3Data);
        })
      }
      else {
        this.SetYears(this.part3Data);
        this.CalculateSection1Total(this.part3Data);
        this.CalculateSection2bTotal(this.part3Data);
        this.CalculateSection2cTotal(this.part3Data);
        this.CalculateSection2dTotal(this.part3Data);
        this.CalculateSection2eTotal(this.part3Data);
        this.CalculateSection8Total(this.part3Data);
      }
    });
  }

  UpdateckeditorChanges(event: string, textName: string) {
    this.ckeditorText = event;
    this.part3Data.modifiedBy = this.provId;
    this.part3Data.brieflyDiscussBasic = this.ckeditorText;

    this.UpdatePart3Data(this.part3Data);
  }

  UpdatePart3Data(Part3data: EASupplementPart3Model) {
    Part3data.modifiedBy = this.provId;
    Part3data.arChevronShare = this.part3Data.arChevronShare == '' || this.part3Data.arChevronShare == null ? '' : parseFloat(Part3data.arChevronShare);
    this.CalculateSection2bTotal(Part3data);
    this.CalculateSection2cTotal(Part3data);
    this.CalculateSection2dTotal(Part3data);
    this.CalculateSection2eTotal(Part3data);

    this.part3Service.UpdatePart3Data(Part3data).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
    if (this.part3Data != null &&  this.part3Data.arChevronShare != null) {
      this.part3Data.arChevronShare = Number(this.part3Data.arChevronShare).toString();
      this.part3Data.arChevronShare =  this.part3Data.arChevronShare + '%';
    }
    Part3data.arAppAmount = Number(Part3data.arAppAmount).toFixed(1).toString();
    Part3data.arCapLeaseAmount = Number(Part3data.arCapLeaseAmount).toFixed(1).toString();
    Part3data.arFinAmountOther = Number(Part3data.arFinAmountOther).toFixed(1).toString();
    Part3data.arWorkingCapital = Number(Part3data.arWorkingCapital).toFixed(1).toString();
    Part3data.arContractualObl = Number(Part3data.arContractualObl).toFixed(1).toString();
    Part3data.arExistingAsset = Number(Part3data.arExistingAsset).toFixed(1).toString();
    Part3data.arCommitmentsReq = Number(Part3data.arCommitmentsReq).toFixed(1).toString();
  }

  UpdatePart3Section8Data(Part3data: EASupplementPart3Model) {
    this.CalculateSection8Total(Part3data);
    Part3data.modifiedBy = this.provId;

    this.part3Service.UpdatePart3Data(Part3data).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    Part3data.ceYear1 = Number(Part3data.ceYear1).toFixed(1).toString();
    Part3data.ceYear2 = Number(Part3data.ceYear2).toFixed(1).toString();
    Part3data.ceYear3 = Number(Part3data.ceYear3).toFixed(1).toString();
    Part3data.ceYear4 = Number(Part3data.ceYear4).toFixed(1).toString();
    Part3data.ceYear5 = Number(Part3data.ceYear5).toFixed(1).toString();
    Part3data.ceTotalBeyondYear5 = Number(Part3data.ceTotalBeyondYear5).toFixed(1).toString();
  }

  CalculateSection8Total(Part3data: EASupplementPart3Model) {
    Part3data.ceTotal = Number(Number(Part3data.ceYear1) + Number(Part3data.ceYear2) +
      Number(Part3data.ceYear3) + Number(Part3data.ceYear4) +
      Number(Part3data.ceYear5) + Number(Part3data.ceTotalBeyondYear5)).toFixed(1).toString();
  }

  UpdatePart3StartYear(Part3data: EASupplementPart3Model) {
    Part3data.modifiedBy = this.provId;
    this.SetYears(Part3data);

    this.part3Service.UpdatePart3Data(Part3data).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  SetYears(Part3data: EASupplementPart3Model) {
    this.secondYear = Part3data.startingYear + 1;
    this.thirdYear = this.secondYear + 1;
    this.fourthYear = this.thirdYear + 1;
    this.fifthYear = this.fourthYear + 1;
    this.fifthYearBeyond = this.fifthYear + 1;
  }

  UpdateDate(Part3data: EASupplementPart3Model, dateType: string) {
    Part3data.modifiedBy = this.provId;
    const date = new Date(Part3data.previousArDate1!);
    const date2 = new Date(Part3data.previousArDate2!);
    const date3 = new Date(Part3data.previousArDate3!);

    if (dateType == 'date1') {
      Part3data.previousArDate1 = this.notifyService.ConvertDateBeforeSave(date);
    }
    else if (dateType == 'date2') {
      Part3data.previousArDate2 = this.notifyService.ConvertDateBeforeSave(date2);
    }
    else if (dateType == 'date3') {
      Part3data.previousArDate3 = this.notifyService.ConvertDateBeforeSave(date3);
    }

    this.part3Service.UpdatePart3Data(Part3data).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    if (dateType == 'date1') {
      Part3data.previousArDate1 = this.notifyService.ConvertDateAfterSave(date);
    }
    else if (dateType == 'date2') {
      Part3data.previousArDate2 = this.notifyService.ConvertDateAfterSave(date2);
    }
    else if (dateType == 'date3') {
      Part3data.previousArDate3 = this.notifyService.ConvertDateAfterSave(date3);
    }
  }

  UpdatePart3Section1(Part3data: EASupplementPart3Model) {
    this.CalculateSection1Total(Part3data);
    Part3data.modifiedBy = this.provId;

    this.part3Service.UpdatePart3Data(Part3data).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    Part3data.previousArAppAmount1 = Number(Part3data.previousArAppAmount1).toFixed(1).toString();
    Part3data.previousArAppAmount2 = Number(Part3data.previousArAppAmount2).toFixed(1).toString();
    Part3data.previousArAppAmount3 = Number(Part3data.previousArAppAmount3).toFixed(1).toString();
  }

  CalculateSection1Total(Part3data: EASupplementPart3Model) {
    this.section1Total = Number(Number(Part3data.previousArAppAmount1) + Number(Part3data.previousArAppAmount2)
      + Number(Part3data.previousArAppAmount3)).toFixed(1).toString();
  }

  UpdatePart3CapLease(Part3data: EASupplementPart3Model) {
    this.CalculateSection1Total(Part3data);
    Part3data.modifiedBy = this.provId;

    this.part3Service.UpdatePart3Data(Part3data).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    Part3data.previousArCapLeaseAmount1 = Number(Part3data.previousArCapLeaseAmount1).toFixed(1).toString();
    Part3data.previousArCapLeaseAmount2 = Number(Part3data.previousArCapLeaseAmount2).toFixed(1).toString();
    Part3data.previousArCapLeaseAmount3 = Number(Part3data.previousArCapLeaseAmount3).toFixed(1).toString();
  }

  UpdatePart3FinAmount(Part3data: EASupplementPart3Model) {
    this.CalculateSection1Total(Part3data);
    Part3data.modifiedBy = this.provId;

    this.part3Service.UpdatePart3Data(Part3data).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    Part3data.previousArFinAmountOther1 = Number(Part3data.previousArFinAmountOther1).toFixed(1).toString();
    Part3data.previousArFinAmountOther2 = Number(Part3data.previousArFinAmountOther2).toFixed(1).toString();
    Part3data.previousArFinAmountOther3 = Number(Part3data.previousArFinAmountOther3).toFixed(1).toString();
  }

  basisChevronChange(type: string, Part3data: EASupplementPart3Model) {
    Part3data.modifiedBy = this.provId;

    if (type == 'Chevron') {
      Part3data.arBasisChevron = true;
    }
    else if (type == 'NOJV') {
      Part3data.arBasisChevron = false;
    }

    this.part3Service.UpdatePart3Data(Part3data).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  UpdatePart3Section2b(Part3data: EASupplementPart3Model) {
    this.CalculateSection2bTotal(Part3data);
    Part3data.modifiedBy = this.provId;

    this.part3Service.UpdatePart3Data(Part3data).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    Part3data.previousArWorkingCapital1 = Number(Part3data.previousArWorkingCapital1).toFixed(1).toString();
    Part3data.previousArWorkingCapital2 = Number(Part3data.previousArWorkingCapital2).toFixed(1).toString();
    Part3data.previousArWorkingCapital3 = Number(Part3data.previousArWorkingCapital3).toFixed(1).toString();
  }

  CalculateSection2bTotal(Part3data: EASupplementPart3Model) {
    this.section2bTotal = Number(Number(Part3data.previousArWorkingCapital1) + Number(Part3data.previousArWorkingCapital2)
      + Number(Part3data.previousArWorkingCapital3)).toFixed(1).toString();

    // 2f. Subtotal (2a-2e)
    Part3data.arSubtotal = Number(Number(Part3data.arWorkingCapital) +
      Number(Part3data.arContractualObl) + Number(Part3data.arExistingAsset) +
      Number(Part3data.arCommitmentsReq)).toFixed(1).toString();

    this.section2Total = Number(Number(this.section2bTotal) + Number(this.section2cTotal) +
      Number(this.section2dTotal) + Number(this.section2eTotal)).toFixed(1).toString();

    Part3data.previousArSubtotal1 = Number(Number(Part3data.previousArWorkingCapital1) +
      Number(Part3data.previousArContractualObl1) + Number(Part3data.previousArExistingAsset1) +
      Number(Part3data.previousArCommitmentsReq1)).toFixed(1).toString();;

    Part3data.previousArSubtotal2 = Number(Number(Part3data.previousArWorkingCapital2) +
      Number(Part3data.previousArContractualObl2) + Number(Part3data.previousArExistingAsset2) +
      Number(Part3data.previousArCommitmentsReq2)).toFixed(1).toString();;

    Part3data.previousArSubtotal3 = Number(Number(Part3data.previousArWorkingCapital3) +
      Number(Part3data.previousArContractualObl3) + Number(Part3data.previousArExistingAsset3) +
      Number(Part3data.previousArCommitmentsReq3)).toFixed(1).toString();

    // 3. Subtotal (1 + 2f)

    Part3data.arSubtotalCommit = Number(Number(Part3data.arSubtotal) + Number(Part3data.arAppAmount)).toFixed(1).toString();
    this.section3Total = Number(Number(this.section2Total) + Number(this.section1Total)).toFixed(1).toString();
    Part3data.previousArSubtotalCommit1 = Number(Number(Part3data.previousArSubtotal1) + Number(Part3data.previousArAppAmount1)).toFixed(1).toString();
    Part3data.previousArSubtotalCommit2 = Number(Number(Part3data.previousArSubtotal2) + Number(Part3data.previousArAppAmount2)).toFixed(1).toString();
    Part3data.previousArSubtotalCommit3 = Number(Number(Part3data.previousArSubtotal3) + Number(Part3data.previousArAppAmount3)).toFixed(1).toString();

    // Total Commitment (this AR + previous ARs)
    Part3data.totalCommitment = Number(Number(Part3data.arSubtotalCommit) + Number(this.section3Total)).toFixed(1).toString();
  }

  UpdatePart3Section2c(Part3data: EASupplementPart3Model) {
    this.CalculateSection2cTotal(Part3data);
    Part3data.modifiedBy = this.provId;

    this.part3Service.UpdatePart3Data(Part3data).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    Part3data.previousArContractualObl1 = Number(Part3data.previousArContractualObl1).toFixed(1).toString();
    Part3data.previousArContractualObl2 = Number(Part3data.previousArContractualObl2).toFixed(1).toString();
    Part3data.previousArContractualObl3 = Number(Part3data.previousArContractualObl3).toFixed(1).toString();
  }

  CalculateSection2cTotal(Part3data: EASupplementPart3Model) {
    this.section2cTotal = Number(Number(Part3data.previousArContractualObl1) + Number(Part3data.previousArContractualObl2)
      + Number(Part3data.previousArContractualObl3)).toFixed(1).toString();

    Part3data.arSubtotal = Number(Number(Part3data.arWorkingCapital) +
      Number(Part3data.arContractualObl) + Number(Part3data.arExistingAsset) +
      Number(Part3data.arCommitmentsReq)).toFixed(1).toString();

    this.section2Total = Number(Number(this.section2bTotal) + Number(this.section2cTotal) +
      Number(this.section2dTotal) + Number(this.section2eTotal)).toFixed(1).toString();

    // 3. Subtotal (1 + 2f)

    Part3data.arSubtotalCommit = Number(Number(Part3data.arSubtotal) + Number(Part3data.arAppAmount)).toFixed(1).toString();
    this.section3Total = Number(Number(this.section2Total) + Number(this.section1Total)).toFixed(1).toString();
    Part3data.previousArSubtotalCommit1 = Number(Number(Part3data.previousArSubtotal1) + Number(Part3data.previousArAppAmount1)).toFixed(1).toString();
    Part3data.previousArSubtotalCommit2 = Number(Number(Part3data.previousArSubtotal2) + Number(Part3data.previousArAppAmount2)).toFixed(1).toString();
    Part3data.previousArSubtotalCommit3 = Number(Number(Part3data.previousArSubtotal3) + Number(Part3data.previousArAppAmount3)).toFixed(1).toString();

    // Total Commitment (this AR + previous ARs)
    Part3data.totalCommitment = Number(Number(Part3data.arSubtotalCommit) + Number(this.section3Total)).toFixed(1).toString();
  }

  UpdatePart3Section2d(Part3data: EASupplementPart3Model) {
    this.CalculateSection2dTotal(Part3data);
    Part3data.modifiedBy = this.provId;

    this.part3Service.UpdatePart3Data(Part3data).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    Part3data.previousArExistingAsset1 = Number(Part3data.previousArExistingAsset1).toFixed(1).toString();
    Part3data.previousArExistingAsset2 = Number(Part3data.previousArExistingAsset2).toFixed(1).toString();
    Part3data.previousArExistingAsset3 = Number(Part3data.previousArExistingAsset3).toFixed(1).toString();
  }

  CalculateSection2dTotal(Part3data: EASupplementPart3Model) {
    this.section2dTotal = Number(Number(Part3data.previousArExistingAsset1) + Number(Part3data.previousArExistingAsset2)
      + Number(Part3data.previousArExistingAsset3)).toFixed(1).toString();

    Part3data.arSubtotal = Number(Number(Part3data.arWorkingCapital) +
      Number(Part3data.arContractualObl) + Number(Part3data.arExistingAsset) +
      Number(Part3data.arCommitmentsReq)).toFixed(1).toString();

    this.section2Total = Number(Number(this.section2bTotal) + Number(this.section2cTotal) +
      Number(this.section2dTotal) + Number(this.section2eTotal)).toFixed(1).toString();

    // 3. Subtotal (1 + 2f)

    Part3data.arSubtotalCommit = Number(Number(Part3data.arSubtotal) + Number(Part3data.arAppAmount)).toFixed(1).toString();
    this.section3Total = Number(Number(this.section2Total) + Number(this.section1Total)).toFixed(1).toString();
    Part3data.previousArSubtotalCommit1 = Number(Number(Part3data.previousArSubtotal1) + Number(Part3data.previousArAppAmount1)).toFixed(1).toString();
    Part3data.previousArSubtotalCommit2 = Number(Number(Part3data.previousArSubtotal2) + Number(Part3data.previousArAppAmount2)).toFixed(1).toString();
    Part3data.previousArSubtotalCommit3 = Number(Number(Part3data.previousArSubtotal3) + Number(Part3data.previousArAppAmount3)).toFixed(1).toString();

    // Total Commitment (this AR + previous ARs)
    Part3data.totalCommitment = Number(Number(Part3data.arSubtotalCommit) + Number(this.section3Total)).toFixed(1).toString();
  }

  UpdatePart3Section2e(Part3data: EASupplementPart3Model) {
    this.CalculateSection2eTotal(Part3data);
    Part3data.modifiedBy = this.provId;

    this.part3Service.UpdatePart3Data(Part3data).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    Part3data.previousArCommitmentsReq1 = Number(Part3data.previousArCommitmentsReq1).toFixed(1).toString();
    Part3data.previousArCommitmentsReq2 = Number(Part3data.previousArCommitmentsReq2).toFixed(1).toString();
    Part3data.previousArCommitmentsReq3 = Number(Part3data.previousArCommitmentsReq3).toFixed(1).toString();
  }

  CalculateSection2eTotal(Part3data: EASupplementPart3Model) {
    this.section2eTotal = Number(Number(Part3data.previousArCommitmentsReq1) + Number(Part3data.previousArCommitmentsReq2)
      + Number(Part3data.previousArCommitmentsReq3)).toFixed(1).toString();

    Part3data.arSubtotal = Number(Number(Part3data.arWorkingCapital) +
      Number(Part3data.arContractualObl) + Number(Part3data.arExistingAsset) +
      Number(Part3data.arCommitmentsReq)).toFixed(1).toString();

    this.section2Total = Number(Number(this.section2bTotal) + Number(this.section2cTotal) +
      Number(this.section2dTotal) + Number(this.section2eTotal)).toFixed(1).toString();

    // 3. Subtotal (1 + 2f)

    Part3data.arSubtotalCommit = Number(Number(Part3data.arSubtotal) + Number(Part3data.arAppAmount)).toFixed(1).toString();
    this.section3Total = Number(Number(this.section2Total) + Number(this.section1Total)).toFixed(1).toString();
    Part3data.previousArSubtotalCommit1 = Number(Number(Part3data.previousArSubtotal1) + Number(Part3data.previousArAppAmount1)).toFixed(1).toString();
    Part3data.previousArSubtotalCommit2 = Number(Number(Part3data.previousArSubtotal2) + Number(Part3data.previousArAppAmount2)).toFixed(1).toString();
    Part3data.previousArSubtotalCommit3 = Number(Number(Part3data.previousArSubtotal3) + Number(Part3data.previousArAppAmount3)).toFixed(1).toString();

    // Total Commitment (this AR + previous ARs)
    Part3data.totalCommitment = Number(Number(Part3data.arSubtotalCommit)
      + Number(this.section3Total)).toFixed(1).toString();
  }

  hasComment(section: string, controlId: string) {
    return this.comments.filter(x => x.section == section && x.controlId == controlId)[0] == undefined ? false : this.comments.filter(x => x.section == section && x.controlId == controlId)[0].comment.length > 0;
  }

  SaveComments(section: string, controlId: string) {
    let res = this.comments.filter(x => x.section == section && x.controlId == controlId)[0];
    res = res == undefined ? new SupplementCommentModel() : res;
    const dialogData = new CommentDialogModel("Update Comment", res.comment, this.isOnHold, this.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });
    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        res.createdBy = this.provId;
        res.modifiedBy = this.provId;
        res.comment = comment;
        res.supplementId = this.supplementId;
        res.section = section;
        res.controlId = controlId;
        this.part3Service.UpdateComments(res).subscribe(result => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
          this.comments = result;
        });
      }
    });
  }

}
