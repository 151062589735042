<div style="padding-left:0%" class="col-xs-12 form-group">
    <strong>Subsurface Basis of Design Parameters</strong>
</div>
<div class="row form-group">
    <div class="col-sm-4 col-xs-12">
        <label class="control-label form-label">EV Formula</label>
    </div>
    <div class="col-sm-8 col-xs-12">
        <select class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="selectedEvFormula" (change)="onEvFormulaChange($event)">
            <option *ngFor="let formula of evFormulaViewModel" [value]="formula.Id">{{formula.Name}}</option>
        </select>
    </div>
</div>
<div class="row sub-section">
    <div class="col-xs-12">
        <table class="table table-bordered" style="display: flex;padding: 0;">
            <tbody style="width: 100%;">
                <tr class="bg-table-default table-header" style="display: flex;width: 100%;">
                    <td class="text-center" style="width:35%;">8/8ths Volumes</td>
                    <td class="text-center" style="width:15%;">p10</td>
                    <td class="text-center" style="width:15%;">p50</td>
                    <td class="text-center" style="width:15%;">p90</td>
                    <td class="text-center" style="width:20%;">EV (Expected Value)</td>
                </tr>
                <tr class="table-header" style="display: flex;">
                    <td style="width: 100%;" class="bg-table-computed-clear bold" colspan="5">Project HCIP: (Stock Tank Conditions)</td>
                </tr>
                <div *ngIf="designParameterViewModel.length > 0">
                    <app-design-parameter-row [formId]="formId" [type]="type" [readOnly]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [rowValue]="0" [selectedEvFormula]="selectedEvFormula" [designParameterViewModel]="designParameterViewModel[0]" (getExpectedValue)="getExpectedValue($event)"></app-design-parameter-row>                
                    </div>
                <tr class="table-header" style="display: flex;">
                    <td style="width: 100%;" class="bg-table-computed-clear bold" colspan="5">Project EUR: (Stock Tank Conditions):</td>
                </tr>
                <div *ngIf="designParameterViewModel.length > 0">
                    <app-design-parameter-row [formId]="formId" [type]="type" [readOnly]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [selectedEvFormula]="selectedEvFormula" [rowValue]="1" [designParameterViewModel]="designParameterViewModel[1]" (getExpectedValue)="getExpectedValue($event)"></app-design-parameter-row>               
                </div>
                <tr class="table-header" style="display: flex;">
                    <td style="width: 100%;" class="bg-table-computed-clear bold" colspan="5">Project Peak Production Rate (Annual Average)</td>
                </tr>
                <div *ngIf="designParameterViewModel.length > 0">
                    <app-design-parameter-row [formId]="formId" [type]="type" [readOnly]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [selectedEvFormula]="selectedEvFormula" [rowValue]="2" [designParameterViewModel]="designParameterViewModel[2]" (getExpectedValue)="getExpectedValue($event)"></app-design-parameter-row>     
                </div>
                <tr class="bg-table-default table-header" style="display: flex;">
                    <td style="width: 100%;" colspan="5">Project Timing:</td>
                </tr>
                <div *ngIf="designParameterViewModel.length > 0">
                    <app-design-parameter-row [formId]="formId" [type]="type" [readOnly]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [selectedEvFormula]="selectedEvFormula" [rowValue]="3" [designParameterViewModel]="designParameterViewModel[3]" (getExpectedValue)="getExpectedValue($event)"></app-design-parameter-row>   
                </div>
                <tr class="bg-table-default table-header" style="display: flex;">
                    <td style="width: 100%;" colspan="5">Project Well Count:</td>
                </tr>
                <div *ngIf="designParameterViewModel.length > 0">
                    <app-design-parameter-row [formId]="formId" [type]="type" [readOnly]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [selectedEvFormula]="selectedEvFormula" [rowValue]="4" [designParameterViewModel]="designParameterViewModel[4]" (getExpectedValue)="getExpectedValue($event)"></app-design-parameter-row>  
                </div>
                <tr class="table-header bg-table-default" style="display: flex;">
                    <td style="width: 35%;" class="bg-table-computed-clear">8/8ths Cost</td>
                    <td style="width: 15%;" class="bg-table-computed-clear text-center">
                        p10
                    </td>
                    <td style="width: 15%;" class="bg-table-computed-clear text-center">
                        p50
                    </td>
                    <td style="width: 15%;" class="bg-table-computed-clear text-center">
                        p90
                    </td>
                    <td style="width: 20%;" class="bg-table-computed-clear text-center">
                        EV (Expected Value)
                    </td>
                </tr>

                <tr class="bg-table-default table-header" style="display: flex;">
                    <td style="width: 100%;" colspan="5">Program Drilling & Completion Cost ($MM):</td>
                </tr>
                <app-design-parameter-row [formId]="formId" [type]="type" [readOnly]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [selectedEvFormula]="selectedEvFormula" [rowValue]="5" [designParameterViewModel]="designParameterViewModel[5]" (getExpectedValue)="getExpectedValue($event)"></app-design-parameter-row>  
                <tr class="bg-table-default table-header" style="display: flex;">
                    <td style="width: 100%;" colspan="5">Facilities Cost ($MM):</td>
                </tr>
                <app-design-parameter-row [formId]="formId" [type]="type" [readOnly]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [selectedEvFormula]="selectedEvFormula" [rowValue]="6" [designParameterViewModel]="designParameterViewModel[6]" (getExpectedValue)="getExpectedValue($event)"></app-design-parameter-row>  
                <tr class="bg-table-default table-header" style="display: flex;">
                    <td style="width: 100%;" colspan="5">Overall Project Cost ($MM):</td>
                </tr>
                <app-design-parameter-row [formId]="formId" [type]="type" [readOnly]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [selectedEvFormula]="selectedEvFormula" [rowValue]="7" [designParameterViewModel]="designParameterViewModel[7]" (getExpectedValue)="getExpectedValue($event)"></app-design-parameter-row> 
                <tr class="bg-table-default table-header" style="display: flex;">
                    <td style="width: 100%;" colspan="5">Project Values Calculated From Above Inputs:</td>
                </tr>
                <tr class="table-header" style="display: flex;" *ngFor="let item of projectValuesViewModel; trackBy: trackByFunction; let i = index">
                    <td style="width: 35%;" class="bg-table-computed-clear">{{item.Name}}</td>
                    <td style="width: 15%;" class="bg-table-computed-clear text-center">
                        <input class="form-control" [(ngModel)]="item.P10" disabled/>
                    </td>
                    <td style="width: 15%;" class="bg-table-computed-clear text-center">
                        <input class="form-control" [(ngModel)]="item.P50" disabled/>
                    </td>
                    <td style="width: 15%;" class="bg-table-computed-clear text-center">
                        <input class="form-control" [(ngModel)]="item.P90" disabled/>
                    </td>
                    <td style="width: 20%;" class="bg-table-computed-clear text-center">
                        <input class="form-control" [(ngModel)]="item.ExpectedValue" disabled/>
                    </td>
                </tr>
                <tr class="bg-table-default table-header" style="display: flex;">
                    <td style="width: 100%;" colspan="5">Project Carbon Capture and Storage Volumes (MMT=Million Metric Tons):</td>
                </tr>
                <app-design-parameter-row [formId]="formId" [type]="type" [readOnly]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [selectedEvFormula]="selectedEvFormula" [rowValue]="8" [designParameterViewModel]="designParameterViewModel[8]" (getExpectedValue)="getExpectedValue($event)"></app-design-parameter-row> 
            </tbody>
        </table>
    </div>
</div>
