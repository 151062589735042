<div class="box-container box-shadow" id="page-body">

  <div class="row">
    <div class="col">
      <h3>My RSID Projects</h3>
    </div>
  </div>

  <div class="row">
    &nbsp;
  </div>
  <div class="row">
    <div class="col-sm-12">

      <p-table #formDatadt selectionMode="single" [value]="formData" [rows]="projectlength" [totalRecords]="projectlength" [paginator]="true"
        styleClass="p-datatable-projects p-datatable-sm" [rowsPerPageOptions]="[projectlength, 10, 20, 50, 100]"  stateStorage="session"
        stateKey="statedemo-rsid" paginatorPosition="both" (onPage)="onShowEntryDropdownChange($event)" [globalFilterFields]="[
          'Rsid',
              'ProjectName',
              'OpcoName',
              'SbuName',
              'BuName',
              'AssetName',
              'Name',
              'ModifiiedDate',
              'ProjectId',
              'global'
        ]">
        <ng-template pTemplate="caption">
          <span><b>Show Entries</b></span>
          <div class="flex" style="float:right;margin-bottom: 1%;">
            <span class="p-input-icon-left ml-auto">
              <b>Search: </b><input #searchText pInputText type="text"
                (input)="formDatadt.filterGlobal(searchText.value, 'contains')"
                [value]="formDatadt.filters['global']? $any(formDatadt.filters['global'])?.value :''" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr style="background-color: lightskyblue;height: 40px;">
            <th style="width: 15%;"></th>
            <th pSortableColumn="Rsid">
              RSID <p-sortIcon field="Rsid"></p-sortIcon>
            </th>
            <th pSortableColumn="ProjectName">
              ProjectName <p-sortIcon field="ProjectName"></p-sortIcon>
            </th>
            <th pSortableColumn="OpcoName">Segment <p-sortIcon field="OpcoName"></p-sortIcon>
            </th>
            <th pSortableColumn="SbuName">RU <p-sortIcon field="SbuName"></p-sortIcon>
            </th>
            <th pSortableColumn="BuName">
              BU <p-sortIcon field="BuName"></p-sortIcon>
            </th>
            <th pSortableColumn="AssetName">
              Asset <p-sortIcon field="AssetName"></p-sortIcon>
            </th>
            <th pSortableColumn="Name">
              CreatedBy <p-sortIcon field="Name"></p-sortIcon>
            </th>
            <th pSortableColumn="ModifiiedDate">
              ModifiedDate <p-sortIcon field="ModifiiedDate"></p-sortIcon>
            </th>
            <th pSortableColumn="ProjectId">
              ProjectID <p-sortIcon field="ProjectId"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th style=" background-color:#efefef !important;"></th>
            <th style="height:6rem; background-color:#efefef !important;">
              <input style="max-width:5em" pInputText type="text" #searchRSID
                (input)="formDatadt.filter(searchRSID.value,'Rsid', 'contains')"
                [value]="formDatadt.filters['Rsid']? $any(formDatadt.filters['Rsid'])?.value :''"
                placeholder="Search..." class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input style="max-width:5em" pInputText type="text" #searchProjectName
                (input)="formDatadt.filter(searchProjectName.value,'ProjectName', 'contains')"
                [value]="formDatadt.filters['ProjectName']? $any(formDatadt.filters['ProjectName'])?.value :''"
                placeholder="Search..." class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input style="max-width:5em" pInputText type="text" #searchOPCO
                (input)="formDatadt.filter(searchOPCO.value,'OpcoName', 'contains')"
                [value]="formDatadt.filters['OpcoName']? $any(formDatadt.filters['OpcoName'])?.value :''"
                placeholder="Search..." class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchSBU
                (input)="formDatadt.filter(searchSBU.value,'SbuName', 'contains')"
                [value]="formDatadt.filters['SbuName']? $any(formDatadt.filters['SbuName'])?.value :''"
                placeholder="Search..." class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchBU
                (input)="formDatadt.filter(searchBU.value,'BuName', 'contains')"
                [value]="formDatadt.filters['BuName']? $any(formDatadt.filters['BuName'])?.value :''"
                placeholder="Search..." class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchAsset
                (input)="formDatadt.filter(searchAsset.value,'AssetName', 'contains')"
                [value]="formDatadt.filters['AssetName']? $any(formDatadt.filters['AssetName'])?.value :''"
                placeholder="Search..." class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchCreatedBy
                (input)="formDatadt.filter(searchCreatedBy.value,'Name', 'contains')"
                [value]="formDatadt.filters['Name']? $any(formDatadt.filters['Name'])?.value :''"
                placeholder="Search..." class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchModifiedDate
                (input)="formDatadt.filter(searchModifiedDate.value,'ModifiiedDate', 'contains')"
                [value]="formDatadt.filters['ModifiiedDate']? $any(formDatadt.filters['ModifiiedDate'])?.value :''"
                placeholder="Search..." class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchProjectID
                (input)="formDatadt.filter(searchProjectID.value,'ProjectId', 'contains')"
                [value]="formDatadt.filters['ProjectId']? $any(formDatadt.filters['ProjectId'])?.value :''"
                placeholder="Search..." class="p-column-filter" />
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="100" style="text-align: center;">
              No records found in the table. Please search for other text
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-form>
          <tr>
            <td style="padding: 2px 0px;">
              <div>
                <a style="width:5em; margin-bottom: 0.5rem; background-color: #337ab7;border-color: #2e6da4;"
                  [routerLink]="['/go36project/viewform']" [queryParams]="{projectId: form.ProjectId}"
                  [state]="{project:form,editMode:true}" class="btn  btn-sm btn-info">
                  <div>Open </div>
                </a>
                <router-outlet></router-outlet>
                <button style="width:5em;margin-left: 0.5rem; color: white !important;" class="btn btn-sm btn-warning"
                  (click)="edit(form,'EditProject')">
                  <div>Edit </div>
                </button>
              </div>
            </td>
            <td>{{ form.Rsid }}</td>
            <td>{{ form.ProjectName }}</td>
            <td>{{ form.OpcoName }}</td>
            <td>{{ form.SbuName }}</td>
            <td>{{ form.BuName }}</td>
            <td>{{ form.AssetName }}</td>
            <td>{{ form.Name }}</td>
            <td>{{ form.ModifiiedDate | date: 'MM/dd/yyyy'}}</td>
            <td>{{ form.ProjectId }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorleft" let-state>
          Showing {{(state.first) + 1}} to {{(state.totalRecords >
            (state.rows * (state.page + 1))) ? state.rows * (state.page + 1) : state.totalRecords }}
            of {{state.totalRecords}} entries
        </ng-template>
      </p-table>
    </div>
  </div>
</div>