<div class="box-container box-shadow" id="page-body">

  <!-- <div class="row">
    <div class="col">
      <div class="dark-blue" style="text-align: center; color: #0B2D71; font-size: 65%;">
        <b>Project Name</b>
      </div>
      <div class="dark-blue" style="text-align: center; color: #0B2D71;font-size: larger;">
        <b>{{ projectName }}</b>
      </div>
    </div>
  </div> -->

  <div class="row">
    <h3>
      <small class="dark-blue">Project Name</small>
      <br>
      <span data-bind="text: projectName">{{ projectName }}</span>
    </h3>
  </div>

  <div class="row text-right">
    <button style="width:6em;color: white !important;" class="btn btn-sm btn-primary" [disabled]="!this.userAccessListByProjectId?.data?.canAddForm"
      (click)="CreateEaNewAR()">
      <div>AR </div>
    </button>
    <!-- <button style="width:6em;color: white !important;" class="btn btn-sm btn-primary">
      <div>Copy AR </div>
    </button> -->
    <button style="width:7em;color: white !important;" class="btn btn-sm btn-primary" [disabled]="!this.userAccessListByProjectId?.data?.canAddForm"
      (click)="CreateEaNewRescope()">
      <div>Rescope </div>
    </button>
    <button style="width:7em;color: white !important;" class="btn btn-sm btn-primary" [disabled]="!this.userAccessListByProjectId?.data?.canAddForm"
      (click)="CreateEaNewSupplement()">
      <!-- <div style="color: #0066B2;">Old Style </div>
        <div style="color: #0066B2;">Part4 Lookback</div> -->
      <div>Supplement </div>
    </button>
    <button style="width:9em;float: right; color: white !important;" class="btn btn-sm btn-primary" [disabled]="!this.userAccessListByProjectId?.data?.canAddForm"
      (click)="CreatePart4LookBack()">Part 4 Lookback
    </button>
  </div>

  <div class="row">
    &nbsp;
  </div>
  <div class="row">
    <div class="col-sm-12">

      <p-table #formDatadt selectionMode="single" [value]="formData" [rows]="10" [paginator]="false" stateStorage="local"
        stateKey="statedemo-local" styleClass="p-datatable-projects p-datatable-sm" [rowsPerPageOptions]="[10, 20, 50, 100]" 
        paginatorPosition="bottom" [globalFilterFields]="[
          'Version',
              'RequestType',
              'DocumentType',
              'Status',
              'ModifiedDateTime',
              'ApprovedDateTime',
              'CreatedBy',
              'Id'
        ]">
        <ng-template pTemplate="caption">
          <div class="flex" style="float:right;margin-bottom: 1%;">
            <span class="p-input-icon-left ml-auto">
              <b>Search: </b><input #searchText pInputText type="text"
                (input)="formDatadt.filterGlobal(searchText.value, 'contains')" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr style="background-color: lightskyblue;height: 40px;">
            <th></th>
            <th pSortableColumn="Version">
              Version <p-sortIcon field="Version"></p-sortIcon>
            </th>
            <th pSortableColumn="RequestType">
              RequestType <p-sortIcon field="RequestType"></p-sortIcon>
            </th>
            <th pSortableColumn="DocumentType">DocumentType <p-sortIcon field="DocumentType"></p-sortIcon>
            </th>
            <th pSortableColumn="Status">Status <p-sortIcon field="Status"></p-sortIcon>
            </th>
            <th pSortableColumn="ModifiedDateTime">
              DateModified <p-sortIcon field="ModifiedDateTime"></p-sortIcon>
            </th>
            <th pSortableColumn="ApprovedDateTime">
              ApprovedDate <p-sortIcon field="ApprovedDateTime"></p-sortIcon>
            </th>
            <th pSortableColumn="CreatedBy">
              CreatedBy <p-sortIcon field="CreatedBy"></p-sortIcon>
            </th>
            <th pSortableColumn="OriginalId">
              ID <p-sortIcon field="OriginalId"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th style=" background-color:#efefef !important;"></th>
            <th style="height:6rem; background-color:#efefef !important;">
              <input style="max-width:10em" pInputText type="text" #searchVersion
                (input)="formDatadt.filter(searchVersion.value,'Version', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input style="max-width:5em" pInputText type="text" #searchRequestType
                (input)="formDatadt.filter(searchRequestType.value,'RequestType', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input style="max-width:5em" pInputText type="text" #searchDocumentType
                (input)="formDatadt.filter(searchDocumentType.value,'DocumentType', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>


            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchStatus
                (input)="formDatadt.filter(searchStatus.value,'Status', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchDateModified
                (input)="formDatadt.filter(searchDateModified.value,'DateModified', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchApprovedDate
                (input)="formDatadt.filter(searchApprovedDate.value,'ApprovedDate', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchCreatedBy
                (input)="formDatadt.filter(searchCreatedBy.value,'CreatedBy', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchID
                (input)="formDatadt.filter(searchID.value,'OriginalId', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="100" style="text-align: center;">
              No records found in the table. Please search for other text
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-form>
          <tr>
            <td style="padding: 2px 0px;">
              <div>
                <a *ngIf="form.RequestType=='E\u0026A'" style="width:5em;background-color: royalblue"
                  class="btn btn-sm btn-info" [routerLink]="['/ea/new-ar-nav']"
                  [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion, formCase: 'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.RequestType =='E\u0026A Rescope' || form.RequestType =='E\u0026A Supplement'"
                  style="width:5em;background-color: royalblue" class="btn btn-sm btn-info"
                  [routerLink]="['/ea/supplement-nav']"
                  [queryParams]="{ projectId: pID,supplementId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <!--   <a  *ngIf="form.FormType=='FEED Supplement'" style="width:5em; font-size:0.75em; background-color: royalblue;margin-bottom: 0.5rem;" class="btn  btn-sm btn-info"
                  [routerLink]="['/forms/feed_Supplement']" [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                    <div>Open </div>
                </a>
                <a  *ngIf="form.FormType=='Phase 3 to 4 (FID)'" style="width:5em; font-size:0.75em; background-color: royalblue" class="btn  btn-sm btn-info"
                  [routerLink]="['/forms/fid']" [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                    <div>Open </div>
                </a>
                <a  *ngIf="form.FormType=='FID Supplement'" style="width:5em; font-size:0.75em; background-color: royalblue" class="btn  btn-sm btn-info"
                  [routerLink]="['/forms/fid_Supplement']" [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                    <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='LLE'" style="width:5em; font-size:0.75em; background-color: royalblue" class="btn  btn-sm btn-info"
                [routerLink]="['/forms/feed']" [queryParams]="{ projectId: pID,formId: form.Id,formCase:'Open'}">
                  <div>Open </div>
               </a>-->
                <a *ngIf="form.RequestType=='E\u0026A Lookback Part 4'"
                  style="width:5em;background-color: royalblue" class="btn btn-sm btn-info"
                  [routerLink]="['/ea/forms/eapart4']"
                  [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <router-outlet></router-outlet>
                <!-- <button style="width:5em; font-size:0.75em;margin-left: 0.5rem;" class="btn btn-sm btn-success">
                    <div>Settings </div>
                  </button> -->
              </div>
            </td>
            <td>
              <!-- <select formControlName="vId" class="form-control" style="width:8em;" (change)="onVersionChange($event)">
                  <option *ngFor="let item of form.Versions; let i = index"
                  [value]="item.Id">#{{item.Name}}
                  </option>
                </select> -->
              <select name="selectedType" #feedformType class="form-control" style="width:8em;"
                [(ngModel)]="form.selectedVersion" (change)="onVersionChange($event)">
                <option *ngFor="let item of form.versions; let i = index" [value]="item.Id">
                  <span *ngIf="item.Name == null">#{{item.Id}}</span>
                  <span *ngIf="(item.Name != null) && (item.Id==item.Name)">#{{item.Id}}</span>
                  <span *ngIf="(item.Name != null) && (item.Id!=item.Name)">#{{item.Id}} - {{item.Name}}</span>
                </option>
              </select>
            </td>
            <td>{{ form.RequestType }}</td>
            <td>{{ form.DocumentType }}</td>
            <td>{{ form.Status }}</td>
            <td>{{ form.ModifiedDateTime | date: 'MM/dd/yyyy'}}</td>
            <td>{{ form.ApprovedDateTime | date: 'MM/dd/yyyy'}}</td>
            <td>{{ form.CreatedBy }}</td>
            <td>{{ form.OriginalId }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div style="text-align: left;">
      <button style="width:10em; color: white !important;margin:50px 0px 0px 10px" [disabled]="!this.userAccessListByProjectId?.data?.canRemove" class="btn btn-sm btn-danger"
      (click)="DeleteProject('DeleteProject')" >
        <div>Delete Project </div>
      </button>
    </div>
  </div>
</div>