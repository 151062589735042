<div class="box-container box-shadow" id="page-body">

  <div class="row">
    <div class="col">
      <h3 *ngIf="role == 'Submitter'">Forms Submitted For Review</h3>
      <h3 *ngIf="role == 'Reviewer'">Forms Awaiting Review</h3>
    </div>
  </div>

  <div class="row">
    &nbsp;
  </div>
  <div class="row">
    <div class="col-sm-12">
      <p-table #formDatadt selectionMode="single" [value]="formData" [rows]="projectlength" [totalRecords]="projectlength" [paginator]="true"
        styleClass="p-datatable-projects p-datatable-sm" [rowsPerPageOptions]="[projectlength, 10, 20, 50, 100]"  stateStorage="session"
        stateKey="statedemo-forminworkflow" paginatorPosition="both" (onPage)="onShowEntryDropdownChange($event)" [globalFilterFields]="[
          'projectName',
              'requestType',
              'documentType',
              'status',
              'modifiedDateTime',
              'createdBy',
              'global'
        ]">
        <ng-template pTemplate="caption">
          <span><b>Show Entries</b></span>
          <div class="flex" style="float:right;margin-bottom: 1%;">
            <span class="p-input-icon-left ml-auto">
              <b>Search: </b><input #searchText pInputText type="text"
                (input)="formDatadt.filterGlobal(searchText.value, 'contains')"
                [value]="formDatadt.filters['global']? $any(formDatadt.filters['global'])?.value :''" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr style="background-color: lightskyblue;height: 40px;">
            <th></th>
            <th pSortableColumn="projectName">
              ProjectName <p-sortIcon field="projectName"></p-sortIcon>
            </th>
            <th pSortableColumn="requestType">
              RequestType <p-sortIcon field="requestType"></p-sortIcon>
            </th>
            <th pSortableColumn="documentType">DocumentType <p-sortIcon field="documentType"></p-sortIcon>
            </th>
            <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon>
            </th>
            <th pSortableColumn="modifiedDateTime">
              DateModified <p-sortIcon field="modifiedDateTime"></p-sortIcon>
            </th>
            <th pSortableColumn="createdBy" *ngIf="role == 'Submitter'">
              Reviewer <p-sortIcon field="createdBy"></p-sortIcon>
            </th>
            <th pSortableColumn="createdBy" *ngIf="role == 'Reviewer'">
              SentBy <p-sortIcon field="createdBy"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th style=" background-color:#efefef !important;"></th>
            <th style="height:6rem; background-color:#efefef !important;">
              <input style="max-width:10em" pInputText type="text" #searchProjectName
                (input)="formDatadt.filter(searchProjectName.value,'projectName', 'contains')" placeholder="Search..."
                [value]="formDatadt.filters['projectName']? $any(formDatadt.filters['projectName'])?.value :''"
                class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input style="max-width:5em" pInputText type="text" #searchRequestType
                (input)="formDatadt.filter(searchRequestType.value,'requestType', 'contains')" placeholder="Search..."
                [value]="formDatadt.filters['requestType']? $any(formDatadt.filters['requestType'])?.value :''"
                class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input style="max-width:5em" pInputText type="text" #searchDocumentType
                (input)="formDatadt.filter(searchDocumentType.value,'documentType', 'contains')" placeholder="Search..."
                [value]="formDatadt.filters['documentType']? $any(formDatadt.filters['documentType'])?.value :''"
                class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchStatus
                (input)="formDatadt.filter(searchStatus.value,'status', 'contains')" placeholder="Search..."
                [value]="formDatadt.filters['status']? $any(formDatadt.filters['status'])?.value :''"
                class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchDateModified
                (input)="formDatadt.filter(searchDateModified.value,'modifiedDateTime', 'contains')"
                placeholder="Search..."
                [value]="formDatadt.filters['modifiedDateTime']? $any(formDatadt.filters['modifiedDateTime'])?.value :''"
                class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchCreatedBy
                (input)="formDatadt.filter(searchCreatedBy.value,'createdBy', 'contains')" placeholder="Search..."
                [value]="formDatadt.filters['createdBy']? $any(formDatadt.filters['createdBy'])?.value :''"
                class="p-column-filter" />
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="100" style="text-align: center;">
              No records found in the table. Please search for other text
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-form>
          <tr>
            <td style="padding: 2px 0px;">
              <div>
                <a *ngIf="form.documentType=='FEED'"
                  style="width:5em;margin-bottom: 0.5rem; background-color: royalblue" class="btn  btn-sm btn-info"
                  [routerLink]="['/forms/feed']"
                  [queryParams]="{ projectId: form.projectId,formId: form.originalId,versionName:form.version,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.documentType.includes('FEED Supplement')"
                  style="width:5em;margin-bottom: 0.5rem; background-color: royalblue;margin-bottom: 0.5rem;"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/feed_Supplement']"
                  [queryParams]="{ projectId: form.projectId,formId: form.originalId,versionName:form.version,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.documentType.includes('Phase 3 to 4 (FID)')"
                  style="width:5em;margin-bottom: 0.5rem; background-color: royalblue" class="btn  btn-sm btn-info"
                  [routerLink]="['/forms/fid']"
                  [queryParams]="{ projectId: form.projectId,formId: form.originalId,versionName:form.version,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.documentType.includes('FID Supplement')"
                  style="width:5em;margin-bottom: 0.5rem; background-color: royalblue" class="btn  btn-sm btn-info"
                  [routerLink]="['/forms/fid_Supplement']"
                  [queryParams]="{ projectId: form.projectId,formId: form.originalId,versionName:form.version,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.documentType=='LLE'" style="width:5em;margin-bottom: 0.5rem; background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/feed']"
                  [queryParams]="{ projectId: form.projectId,formId: form.originalId,versionName:form.version,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.documentType=='EPC'" style="width:5em;margin-bottom: 0.5rem; background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/feed']"
                  [queryParams]="{ projectId: form.projectId,formId: form.originalId,versionName:form.version,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.requestType=='Lookback Part 4'"
                  style="width:5em; margin-bottom: 0.5rem;background-color: royalblue" class="btn  btn-sm btn-info"
                  [routerLink]="['/forms/part4']"
                  [queryParams]="{ projectId: form.projectId,formId: form.originalId,versionName:form.version,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.requestType=='Lookback Part 5'"
                  style="width:5em; margin-bottom: 0.5rem; background-color: royalblue" class="btn  btn-sm btn-info"
                  [routerLink]="['/forms/part5']"
                  [queryParams]="{ projectId: form.projectId,formId: form.originalId,versionName:form.version,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.requestType=='E\u0026A'" style="width:5em;background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/ea/new-ar-nav']"
                  [queryParams]="{ projectId: form.projectId,formId: form.originalId,versionName:form.version, formCase: 'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.requestType =='E\u0026A Rescope' || form.requestType =='E\u0026A Supplement'"
                  style="width:5em;background-color: royalblue" class="btn  btn-sm btn-info"
                  [routerLink]="['/ea/supplement-nav']"
                  [queryParams]="{ projectId: form.projectId,supplementId: form.originalId,versionName:form.version,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.requestType=='E\u0026A Lookback Part 4'" style="width:5em;background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/ea/forms/eapart4']"
                  [queryParams]="{ projectId: form.projectId,formId: form.originalId,versionName:form.version,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <router-outlet></router-outlet>
              </div>
            </td>
            <td>{{ form.projectName }}</td>
            <td>{{ form.requestType }}</td>
            <td>{{ form.documentType }}</td>
            <td>{{ form.status }}</td>
            <td>{{ form.modifiedDateTime | date: 'MM/dd/yyyy'}}</td>
            <td>{{ form.createdBy }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorleft" let-state>
          Showing {{(state.first) + 1}} to {{(state.totalRecords >
            (state.rows * (state.page + 1))) ? state.rows * (state.page + 1) : state.totalRecords }}
            of {{state.totalRecords}} entries
        </ng-template>
      </p-table>
    </div>
  </div>
</div>