import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router'
// //import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.css']
})
export class ErrorpageComponent implements OnInit {
@Output() parentFunction : EventEmitter<boolean> = new EventEmitter(); 
  constructor( private router: Router) {     
  }

  ngOnInit(): void {    
   
    let str = this.router.url; 
    let splitted = str.split("/", 3);
    let isHidden = true;
    if(splitted.length == 2)
    {
      isHidden = false;
    }
    this.parentFunction.emit(isHidden);
  }
}