import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Go36projectService } from '../go36project/service/go36project.service';

interface OpcoInfo {
  OpcoId: number;
  OpcoName: string;
}
interface SbuInfo {
  SbuId: number;
  SbuName: string;

}
interface BuInfo {
  BuId: number;
  BuName: string;
  OpcoId: number;
  SbuId: number;
}
@Component({
  selector: 'app-deleteproject',
  templateUrl: './deleteproject.component.html',
  styleUrls: ['./deleteproject.component.css']
})
export class DeleteprojectComponent implements OnInit {
  Dropdownprojectsdata: any;
  formValue: UntypedFormGroup;
  SelectedValue: any;
  OpcoList: Array<OpcoInfo> = [];
  SbuList: Array<SbuInfo> = [];
  BuList: Array<BuInfo> = [];
  data: any;
  buData: any;
  SbuData: any;
  projId: any;
  editMode: boolean = localStorage.getItem('editMode') == 'true';
  pID: any;
  projectName: string = "";
  butDisabled: boolean = false;
  userName: string = "";

  constructor(private projectservice: Go36projectService, private FormBuilder: UntypedFormBuilder, private route: ActivatedRoute, private router: Router) {
    this.formValue = this.FormBuilder.group({
      ProjectId: '',
      Name: '',
      OpcoId: '0',
      SbuId: '0',
      BuId: '0',
      CreatedBy: ""
    })
  }

  ngOnInit(): void {
    this.butDisabled = true;
    // // const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")))
    // // this.data = { goproject: JSON.parse(projectDetail) };

    this.getdropdown();
    this.route.queryParams
      .subscribe(params => {
        this.projId = params['projectId']
      });
    this.GetProjectDatabyId();
    // // if (this.editMode) {
    // //   this.GetEditData();
    // // }
  }
  GetProjectDatabyId() {
    this.projectservice.GetEditProjectData(this.projId).subscribe(res => {
      this.data = res;
      this.formValue.controls["ProjectId"].setValue(this.data.ProjectId);
      this.formValue.controls["CreatedBy"].setValue(this.data.CreatedBy);
      this.formValue.controls["Name"].setValue(this.data.Name);
      this.formValue.controls["SbuId"].setValue(this.data.SbuId);
      this.formValue.controls["OpcoId"].setValue(this.data.OpcoId);
      this.formValue.controls["BuId"].setValue(this.data.BuId);
      this.formValue.controls["ProjectId"].setValue(this.data.ProjectId);
      this.formValue.controls["CreatedBy"].setValue(this.data.CreatedBy);
      this.projId = this.data.ProjectId;
      this.userName = this.data.CreatedBy;
    })
  }
  getdropdown() {
    this.projectservice.getDropdownData().subscribe(res => {
      this.Dropdownprojectsdata = res; //master data
      this.OpcoList = res[3];
      this.SbuList = res[4];
      this.BuList = res[0];
    });
  }
  GetEditData() {
    {
      // //  this.GetProjectDatabyId();
      this.formValue.controls["Name"].setValue(this.data.Name);
      this.formValue.controls["SbuId"].setValue(this.data.SbuId);
      this.formValue.controls["OpcoId"].setValue(this.data.OpcoId);
      this.formValue.controls["BuId"].setValue(this.data.goproject.BuId);
      this.formValue.controls["ProjectId"].setValue(this.data.ProjectId);
      this.formValue.controls["CreatedBy"].setValue(this.data.CreatedBy);

      this.projId = this.data.ProjectId;
      this.userName = this.data.CreatedBy;
    }
  }

  DeleteProject() {
    this.projectservice.deleteProjectData(this.projId, this.userName).subscribe(res => {
      if (this.data.ProjectType == "2") {
        this.router.navigate(["/ea/project"]);
      }
      else {
        this.router.navigate(["/go36project/project"]);
      }
    })
  }
  // // SetEditData(goproject: any) {
  // //   localStorage.setItem('goProject', JSON.stringify(goproject));
  // //   localStorage.setItem('editMode', 'true')
  // // }
}