<div class="row sub-section">
    <div class="col-xs-12 form-group">
        <strong>Purpose</strong>
    </div>
    <div class="col-xs-12">
        <input type="radio" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" class="form-check-input" value="OptionalPlanningAssist" 
        [(ngModel)]="viewModel.SelectedRadioOption" name="purpose-radio" (change)="SaveSSBODCommonDetails()"/>
        Optional Planning Assist (SSPA)
    </div>
    <div class="col-xs-12">
        <input type="radio" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" class="form-check-input" value="Mandatory" 
        [(ngModel)]="viewModel.SelectedRadioOption" name="purpose-radio" (change)="SaveSSBODCommonDetails()"/>
        SSTR
    </div>
    <div class="col-xs-12">
        <input type="radio" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" class="form-check-input" value="Submittal" 
        [(ngModel)]="viewModel.SelectedRadioOption" name="purpose-radio" (change)="SaveSSBODCommonDetails()"/>
        FID/GO-36 AR Submittal (complete MOC sheet)
    </div>
    <div class="col-xs-12">
        <input type="radio" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" class="form-check-input" value="Part4Lookback" 
        [(ngModel)]="viewModel.SelectedRadioOption" name="purpose-radio" (change)="SaveSSBODCommonDetails()"/>
        Part 4 Lookback
    </div>
    <div class="col-xs-12">
        <input type="radio" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" class="form-check-input" value="Part5Lookback" 
        [(ngModel)]="viewModel.SelectedRadioOption" name="purpose-radio" (change)="SaveSSBODCommonDetails()"/>
        Part 5 Lookback
    </div>
    <div class="col-xs-12">
        <input type="radio" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" class="form-check-input" value="ConceptSelect" 
        [(ngModel)]="viewModel.SelectedRadioOption" name="purpose-radio" (change)="SaveSSBODCommonDetails()"/>
        Concept Select
    </div>
</div>
<div class="row sub-section">
    <div class="col-xs-12 form-group">
        <strong>Leading Alternative?</strong>
    </div>
    <div class="col-xs-12">
        <input type="checkbox" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [value]="viewModel.LeadingAlternative" 
        [(ngModel)]="viewModel.LeadingAlternative" class="form-check-input" (change)="SaveSSBODCommonDetails()"/>
        Yes
    </div>
    <div class="col-xs-12">
        Note - Complete the SSBOD Template for each Ph.2 alternative still under consideration
    </div>
</div>