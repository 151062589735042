import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Go36projectService } from '../service/go36project.service';
import { ArProjectModel } from '../go36project.model';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-go-36-project-list',
  templateUrl: './go-36-project-list.component.html',
  styleUrls: ['./go-36-project-list.component.css']
})

export class Go36projectlistComponent implements OnInit, AfterViewInit {
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  projects: ArProjectModel[] = [];
  data: any;
  userAccessDetails: any;
  isHeaderFooterVisible: boolean = false;
  userRole: string = '';
  objectId: string = '';
  provId: string = '';
  emailId: string = '';
  userAccessListByProjectId: any;
  userRoleForProject: string = '';
  projectlength: number = 0;
  // //@Output() parentFunction : EventEmitter<any> = new EventEmitter(); 

  constructor(private projectservice: Go36projectService,
    private primengConfig: PrimeNGConfig, private router: Router,private utilityServcie: UtilityService) { }

  ngAfterViewInit(): void {
    // This is not implemented
  }

  ngOnInit(): void {
    this.objectId = this.utilityServcie.GetuserObjectId();
    this.provId = this.utilityServcie.GetuserProvisioningId();
    this.emailId = this.utilityServcie.GetuserEmailId();
    localStorage.removeItem('errorpage');

    this.primengConfig.ripple = true;
    this.data = history.state;
    // //$('#projectdt').reset();

    // // let target = document.getElementById('#projectdt');
    // //target.reset();
    this.getAllProject();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2,
      processing: true
    };
  }
  clear(table: any) {
    table.clear();
  }

  getAllProject() {
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);
    // //this.userRole : string = '';
    // we can create the constants of the users role
    if (this.userAccessDetails?.data?.isPowerUser) {
      this.userRoleForProject = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      this.userRoleForProject = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      this.userRoleForProject = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      this.userRoleForProject = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      this.userRoleForProject = "Leadership";
    }
    this.projectservice.getProject(this.objectId.trim(),this.provId.trim(), this.userRoleForProject).subscribe(res => {
      this.projects = res;
      this.projectlength = this.projects.length;
      this.onShowEntryDropdownChange({first: 0, rows: this.projectlength});
    })
  }

  onShowEntryDropdownChange(event) {
    if (event.rows === this.projectlength)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "All";
    else if (event.rows === 10)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "10";
    else if (event.rows === 20)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "20";
    else if (event.rows === 50)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "50";
    else if (event.rows === 100)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "100";
  }
  
  edit(goproject: any, action: string) {
    localStorage.setItem('goProject', JSON.stringify(goproject));
    localStorage.setItem('editMode', 'true')

    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);
    // //this.userRole : string = '';
    // we can create the constants of the users role
    if (this.userAccessDetails?.data?.isPowerUser) {
      this.userRole = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      this.userRole = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      this.userRole = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      this.userRole = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      this.userRole = "Leadership";
    }

    this.projectservice.GetUserAccessListByProjectId(goproject.ProjectId, this.objectId, this.provId).subscribe(res => {
      if (res) {
        this.userAccessListByProjectId = res;
        if (action == 'EditProject') {
          this.isHeaderFooterVisible = this.userAccessListByProjectId?.data?.canEditProject;
          if (this.isHeaderFooterVisible) {
            this.router.navigate(['/project/go36project/update/'], { queryParams: { projectId: goproject.ProjectId } });
          }
        }

        if (action == 'AccessSecurity') {
          this.isHeaderFooterVisible = this.userAccessListByProjectId?.data?.canAccessSecurityProject;
          if (this.isHeaderFooterVisible) {
            this.router.navigate(['/project/go36project/usersecurityaccess/'], { queryParams: { projectId: goproject.ProjectId } });
          }
        }

        if (action == 'CopyProject') {
          this.isHeaderFooterVisible = this.userAccessListByProjectId?.data?.canCopyProject;
          if (this.isHeaderFooterVisible) {
            this.router.navigate(['/project/copy/']);
          }
        }

        if (this.isHeaderFooterVisible === false) {
          localStorage.setItem('errorpage', 'true');
          this.router.navigate(["errorpage"]);
        } 

      }
    })
  }

  SetAddtMode(editMode: string) {
    localStorage.setItem('editMode', 'false')
  }
  OpenLessonsLearnedDashboard(goproject: any) {
    this.router.navigate(['/lessonslearned/dashboard/'], { queryParams: { projectId: goproject.ProjectId } });
  }
}