import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs';
import { AssetModel } from '../asset/asset.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  getUserListByUserRole= "User/GetAdminUsersList/?objectId=";
  searchUsersURL = "user/SearchUsers";
  getViewDetailsRoleURL= "user/GetViewDetailsRolesForUser?userId="
  updateUserProfileURL = "user/EditUserProfile"

  constructor(private http: HttpClient) { }
  baseurl = environment.apiBaseUrl;
  get(controller : string ) {
    return this.http.get<any>(this.baseurl + controller)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getViewDetailsRole(id: any, viewerUserObjectId:string, viewerUserProvId){
    return this.http.get<any>(this.baseurl + this.getViewDetailsRoleURL + id + '&viewerUserObjectId=' + viewerUserObjectId + '&viewerUserProvId=' + viewerUserProvId)
      .pipe(map((res: any) => {
        return res;
      }))
  }


  getById(controller : string ,id: any) {
    return this.http.get<any>(this.baseurl + controller + id)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  post(controller : string , data: any) {
    return this.http.post<any>(this.baseurl + controller, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  update(controller : string ,id: number, data: any) {
    return this.http.put<any>(this.baseurl + controller, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  updateUserProfile(data: any) {
    return this.http.put<any>(this.baseurl + this.updateUserProfileURL, data)
    .pipe(map((res: any) => {
      return res;
    }))
  }

  delete(controller : string ,id: any) {
    return this.http.delete<any>(this.baseurl+ controller + id)
      .pipe(map((res: any) => res))
  }

  getSmartphone(id: number, data: any): Observable<HttpResponse<AssetModel>> {
    return this.http.get<AssetModel>(this.baseurl + id, { observe: 'response' });
  }

  
  getUserListByRole(objectId: string, provId: string, userRole: string) {
    return this.http.get<any>(this.baseurl + this.getUserListByUserRole + objectId+ "&provId="+provId+ "&userRole="+userRole)
  }
  
  
}
