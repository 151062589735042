import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Form } from 'src/app/Model/Form.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { OverviewService } from 'src/app/forms/FEEDServices/overview.service';
import { LessonsLearnedService } from 'src/app/forms/FEEDServices/lessons-learned.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-lessonlearned-dialog',
  templateUrl: './lessonlearned-dialog.component.html',
  styleUrls: ['./lessonlearned-dialog.component.css']
})
export class LessonlearnedDialogComponent implements OnInit {
  form = new Form();
  emailId: string = '';
  provId: string = '';
  LLPhasesDropdown:any[] = [];
  selectedPhase: number = 0;
  allPhasesCreated: boolean = false;
  constructor(public dialogRef: MatDialogRef<LessonLearnedDialogModel>,  private router: Router, @Inject(MAT_DIALOG_DATA) public data: any,private overviewService: OverviewService,private go36ProjectService: Go36projectService
  ,private lessonLearnedService:LessonsLearnedService, public utilityService: UtilityService)
  {
    dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.getLLPhases(this.data.projectId);
  }
  onDismiss(): void {
    this.dialogRef.close();
  }
  onLLPhaseChange(event: any){
    this.selectedPhase = event.target.value;
  }
  onCreate(): void {
    if(this.selectedPhase != 0){
      this.dialogRef.close();
      this.form.projectId=this.data.projectId;
      this.form.active=true;
      this.form.createdBy = this.provId;
      this.form.formType='LL';
      this.form.order=this.selectedPhase;
      this.form.id=0;
      this.form.ownership=[];
      this.form.milestone=[];
      this.form.keyEconomicMetrics=[];
      this.form.capitalAndExpenditures=[];
      this.overviewService.saveFormData(this.form).subscribe(res => {
        this.form.id=Number(res.id);
        setTimeout(() => {
        this.router.navigate(["/forms/lessonlearned", this.data.projectId,this.form.id,1,"LLCreate"]);
        },500);
      });
    }
  }
  getLLPhases(projectId:any){
    this.lessonLearnedService.getLLPhases(projectId).subscribe(res => {
        this.LLPhasesDropdown = res;
        if(this.LLPhasesDropdown.length > 1){
          this.allPhasesCreated = false;
          this.LLPhasesDropdown.pop();
        }
        else
          this.allPhasesCreated = true;
    });
  }
}
export class LessonLearnedDialogModel{
  
}
