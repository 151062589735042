import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  userAccessDetails:any;

  isPowerUser(){
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails =  JSON.parse(userData);
    if(this.userAccessDetails?.data?.isPowerUser)
     {
       return true;
     }else{
      return false;
     }
  }

  isAdminUser(){
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails =  JSON.parse(userData);
    if(this.userAccessDetails?.data?.isAdminUser)
     {
       return true;
     }else{
      return false;
     }
  }
}
