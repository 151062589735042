<div class="td-cell-padding">
  <div class="row sub-section">
    <div class="col-sm-6 col-xs-12">
      <div class="row form-group">
        <div class="col-sm-4 col-xs-12">
          <label class="control-label form-label">Project Name</label>
        </div>
        <div class="col-sm-8 col-xs-12">
          <input type="text" readonly="readonly" id="projectName" name="projectName"
                 [(ngModel)]="projectData.projectName" class="form-control" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-4 col-xs-12">
          <label class="control-label form-label">Segment</label>
        </div>
        <div class="col-sm-8 col-xs-12">
          <input type="text" class="form-control" readonly="readonly" id="opcoName" name="opcoName"
                 [(ngModel)]="projectData.opcoName" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-4 col-xs-12">
          <label class="control-label form-label">BU</label>
        </div>
        <div class="col-sm-8 col-xs-12">
          <input type="text" class="form-control" readonly="readonly" id="buName" name="buName"
                 [(ngModel)]="projectData.buName" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-4 col-xs-12">
          <label class="control-label form-label">Operator Name</label>
        </div>
        <div class="col-sm-8 col-xs-12">
          <input type="text" class="form-control" id="operatorName" name="operatorName"
                 [(ngModel)]="form.operatorName"
                 [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                 (change)="UpdateFormData()" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-4 col-xs-12">
          <label class="control-label form-label">Facility (megafriendly) Name</label>
        </div>
        <div class="col-sm-8 col-xs-12">

          <input type="text" class="form-control" readonly="readonly" id="assetMegaFriendlyName"
                 name="assetMegaFriendlyName" [(ngModel)]="projectData.assetMegaFriendlyName" />
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xs-12">
      <div class="row form-group">
        <div class="col-sm-4 col-xs-12">
          <label class="control-label form-label">AR Type</label>
        </div>
        <div class="col-sm-8 col-xs-12">
          <input type="text" class="form-control" readonly="readonly" id="formType" name="supplementName"
                 [(ngModel)]="form.supplementName" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-4 col-xs-12">
          <label class="control-label form-label">RU</label>
        </div>
        <div class="col-sm-8 col-xs-12">
          <input type="text" class="form-control" readonly="readonly" id="sbuName" name="sbuName"
                 [(ngModel)]="projectData.sbuName" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-4 col-xs-12">
          <label class="control-label form-label">Asset Class</label>
        </div>
        <div class="col-sm-8 col-xs-12">
          <input type="text" class="form-control" readonly="readonly" id="assetName" name="assetName"
                 [(ngModel)]="projectData.assetName" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-4 col-xs-12">
          <label class="control-label form-label">Project Location</label>
        </div>
        <div class="col-sm-8 col-xs-12">
          <input type="text" class="form-control" id="location" name="location" [(ngModel)]="form.location"
                 [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                 (change)="UpdateFormData()" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-4 col-xs-12">
          <label class="control-label form-label">Special Project Type(s)</label>
        </div>
        <div class="col-sm-8 col-xs-12">
          <input type="text" class="form-control" readonly="readonly" id="specialProjectTypes"
                 name="specialProjectTypes" [(ngModel)]="projectData.specialProjectTypes" />
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12 form-group">
        <strong>1.1 Supplement Amount and Key Changes or Drivers for Additional Funding</strong>

        <span class="glyphicon glyphicon-question-sign" (click)="showSupplementInfo()"></span>
      </div>
    </div>
  </div>

  <div class="col-xs-12">
    <div clas="row form-group pl-15">
      <div class="col-xs-12">
        <div class="col-xs-4">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="2">
                  <span>Appropriated Amount this AR ($MM)</span>
                </td>
                <td>
                  <div class="input-icon">
                    <input type="text" class="form-control" readonly="readonly" value="{{appAmtAPR}}">
                    <i>$</i>    
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-xs-4">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="2">
                  <span>Total Commitment to Date ($MM)</span>
                </td>
                <td>
                  <div class="input-icon">
                      <input type="text" class="form-control" readonly="readonly"
                            value="{{addtotalCommitment}}">
                      <i>$</i>    
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-xs-4">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="2">
                  <span>Estimated Total Commitment for Project ($MM)</span>
                </td>
                <td>
                  <div class="input-icon">
                      <input type="text" class="form-control" id="estimatedTotalCommit"
                            name="estimatedTotalCommit" [(ngModel)]="overview.estimatedTotalCommit"
                            [disabled]="isOnHold || isReadOnly"
                            (change)="NAEventCheck($event, 'EstimatedTotalCommit')" />
                      <i>$</i>    
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-8">
    <div class="row">
      <div class="col-xs-12" style="display: flex;">
        <span>Check the box if project funding is included in the Business Plan</span>
        <input type="checkbox" class="big-checkbox" [disabled]="isOnHold || isReadOnly"
               [checked]="overview.projectFundFlag" [(ngModel)]="overview.projectFundFlag"
               (change)="NAEventCheck($event, 'ProjectFundFlag')" style="margin-left: 7px;margin-right: 7px;" />
        <span>Provide additional comments if needed.</span>
      </div>
    </div>
  </div>
  <div class="row sub-section">
  </div>
  <div class="row sub-section">
    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold
                || overview.summaryNa || isReadOnly || isReadOnlyGlobalProjectLevel}" *ngIf="!overview.summaryNa">
          <span>
            <ckeditor #summaryeditor [editor]="Editor" [config]="config" [(ngModel)]="overview.summary"
                      id="summary" name="summary" (ready)="onReady($event,'Summary')">
            </ckeditor>
          </span>
        </div>
      </div>
      <div class="row sub-section">

        <div>
          <div class="col-xs-12 form-group">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12 form-group">
        <strong>1.2 Ownership</strong>
        <span class="glyphicon glyphicon-question-sign" (click)="showOwnershipInfoPopUp()"></span>
      </div>
      <div>
        <div class="col-xs-12">

          <table class="table table-bordered">
            <tbody>
              <tr class="bg-table-default table-header">
                <td width="30%">Ownership</td>
                <td>Net Working Interest</td>
                <td>Net Revenue Interest</td>
                <td width="1%">Is Operator</td>
                <td width="1%"></td>
                <td class="trash-icon" width="1%"></td>
              </tr>
              <tr *ngFor="let owner of ownershipData let i = index">
                <ng-container>
                  <td [(ngModel)]="owner.id" hidden class="ml-10">{{owner.id}}</td>
                  <td [(ngModel)]="owner.isEditable" hidden class="ml-10">{{owner.isEditable}}</td>
                  <td *ngIf="owner.isEditable==false">
                    <div>
                      <input type="text" readonly="readonly"
                             [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                             class="form-control" [(ngModel)]="owner.ownershipName"
                             (change)="UpdateOwner(owner)" />
                    </div>
                  </td>
                  <td *ngIf="owner.isEditable==true">
                    <div>
                      <input type="text"
                             [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                             class="form-control" [(ngModel)]="owner.ownershipName"
                             (change)="UpdateOwner(owner)" />
                    </div>
                  </td>
                  <td>
                    <input class="form-control"
                           [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                           [(ngModel)]="owner.workingInterest" (change)="UpdateOwner(owner)" />
                  </td>
                  <td>
                    <input class="form-control"
                           [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                           [(ngModel)]="owner.netRevenueInterest" (change)="UpdateOwner(owner)" />
                  </td>
                  <td class="text-center">
                    <input type="checkbox"
                           [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                           class="form-check-input" [(ngModel)]="owner.isOperator"
                           (change)="UpdateOwner(owner)" />
                  </td>
                  <td>
                    <div [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                      <i class="glyphicon glyphicon-comment-outline" *ngIf="!owner.comment"
                         (click)="SaveCommentForOwnerships(owner)"></i>
                      <i class="glyphicon glyphicon-comment commentColor" *ngIf="owner.comment"
                         (click)="SaveCommentForOwnerships(owner)"></i>

                    </div>
                  </td>
                  <td>
                    <i *ngIf="owner.isEditable==true"
                       class="glyphicon glyphicon-trash glyphicon-custom-trash"
                       [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel}"
                       (click)="DeleteOwnerships(owner,i)"></i>
                  </td>

                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-xs-12">
        <button type="button" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                (click)="addOwnerShip()" style="color: white !important;" class="btn btn-sm btn-primary">
          Add New
          Row
        </button>
      </div>
    </div>
    <div class="row sub-section">
    </div>

  </div>
  <div class="col-xs-8">
    <div class="row">
      <div class="col-xs-12">
        <strong>Any changes in ownerships? </strong>
      </div>
    </div>
    <div class="row pl-16">
      <span>
        <mat-slide-toggle (change)="MatSliderNAEventCheck($event, 'Ownerships')"
                          [checked]="overview.ownershipsFlag"
                          [disabled]="isOnHold  || form.isReadOnly || isReadOnlyGlobalProjectLevel">
          <span class="app-font">N/A</span>
        </mat-slide-toggle>
      </span>
    </div>
  </div>
  <div class="col-xs-4">
    <div class="text-right">
      <div class="col-xs-12 form-group">
      </div>
    </div>
  </div>

  <div class="row sub-section">
    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-12"
             [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel}"
             *ngIf="!overview.ownershipsFlag">
          <span>
            <ckeditor #ownershipschangeseditor [editor]="Editor" [config]="config"
                      [(ngModel)]="overview.ownershipChanges" id="ownershipChanges" name="ownershipChanges"
                      (ready)="onReady($event,'ownershipChanges')">
            </ckeditor>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-6">
    <div class="row">
      <div class="col-xs-12 form-group">
        <strong>1.3 Phase Gate Milestones</strong>
        <span class="glyphicon glyphicon-question-sign" (click)="showPhaseGateMilestonesPopUp()"></span>
      </div>
      <div class="col-xs-12">
        <table class="table table-bordered">
          <tbody>
            <tr class="bg-table-default table-header">
              <td></td>
              <td>Supplement Date</td>
              <td>Original FEED AR dates</td>
              <td width="1%"></td>
              <td width="1%" class="trash-icon"></td>
            </tr>
            <tr *ngFor="let phasemile of phasemileStone let i = index">
              <td [(ngModel)]="phasemile.id" hidden class="ml-10">{{phasemile.id}}</td>
              <td [(ngModel)]="phasemile.isEditable" hidden class="ml-10">{{phasemile.isEditable}}</td>
              <td *ngIf="phasemile.isEditable==false">
                <input type="text" readonly="readonly" [disabled]="isOnHold || isReadOnly" [ngClass]="{'read-only-ar-dates': phasemile.feedItem}"
                       class="form-control" [(ngModel)]="phasemile.milestoneName"
                       (change)="UpdatePhaseMileStone(phasemile, $event)" />
              </td>
              <td *ngIf="phasemile.isEditable==true">
                <input type="text" [disabled]="isOnHold || isReadOnly" class="form-control" [ngClass]="{'read-only-ar-dates': phasemile.feedItem}"
                       [(ngModel)]="phasemile.milestoneName"
                       (change)="UpdatePhaseMileStone(phasemile, $event)" />

              </td>
              <td>
                <input class="form-control" [(ngModel)]="phasemile.currentDate"
                       (dateChange)="UpdatePhaseMileStone(phasemile, $event)" [matDatepicker]="picker"
                       [disabled]="isOnHold || isReadOnly"
                       (click)="picker.open();utilityService.removeCDKAnouncer()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </td>
              <td>
                <input class="form-control read-only-ar-dates" [matDatepicker]="phasepicker" [(ngModel)]="phasemile.originalFeedArDate" readonly />
                <mat-datepicker #phasepicker></mat-datepicker>
              </td>

              <td>
                <div>
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!phasemile.comment"
                     (click)="SaveCommentForMilestones(phasemile)"></i>
                  <i class="glyphicon glyphicon-comment commentColor" *ngIf="phasemile.comment"
                     (click)="SaveCommentForMilestones(phasemile)"></i>
                </div>
              </td>
              <td>
                <i class="glyphicon glyphicon-trash glyphicon-custom-trash"
                   [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnly}"
                   *ngIf="phasemile.milestoneName == '1st Discovery' || phasemile.milestoneName == '1st Discovery / Phase 1-2 decision' || phasemile.isEditable==true"
                   (click)="DeletephaseGateMilestones(phasemile,i)"></i>
              </td>
            </tr>
            <!-- <tr>
                    <td>
                        <input type="text" class="form-control"[(ngModel)]="pahseMilestoneItem .milestoneName"
                        (change)="addPhaseGateMilestones(pahseMilestoneItem )" />

                    </td>
                    <td>
                        <input class="" [(ngModel)]="pahseMilestoneItem .currentDate"
                        (dateChange)="addPhaseGateMilestones(pahseMilestoneItem )" [matDatepicker]="picker"
                        placeholder="Choose a date" (click)="picker.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker ></mat-datepicker>
                    </td>
                    <td>
                        <div>
                            <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveCommentForMilestones(pahseMilestoneItem )"></i>
                        </div>
                    </td>
                    <td>
                        <i class="glyphicon glyphicon-trash glyphicon-custom-trash"
                        (click)="DeletephaseGateMilestones(pahseMilestoneItem)"></i>
                    </td>
                </tr> -->
          </tbody>
        </table>
      </div>
      <!-- <div class="col-xs-12">
              <button type="button"  (click)="addPhaseGateMilestones()" class="btn btn-sm btn-primary">Add New Row</button>
          </div> -->
    </div>
  </div>
  <div class="col-xs-6">
    <div class="row">
      <div class="col-xs-12 form-group"><strong>Co-Owner View</strong></div>
      <div class="col-xs-12">
        <table class="table table-bordered">
          <tbody>
            <tr class="bg-table-default table-header">
              <td></td>
              <td>Supplement Date</td>
              <td>Original FEED AR Dates</td>
              <td width="1%"></td>
              <td width="1%" class="trash-icon"></td>
            </tr>
            <tr *ngFor="let coownermile of coownermileStone let i = index">
              <td [(ngModel)]="coownermile.id" hidden class="ml-10">{{coownermile.id}}</td>
              <td [(ngModel)]="coownermile.isEditable" hidden class="ml-10">
                {{coownermile.isEditable}}
              </td>
              <td *ngIf="coownermile.isEditable==true">
                <input type="text" [disabled]="isOnHold || isReadOnly" class="form-control" [ngClass]="{'read-only-ar-dates': coownermile.feedItem}"
                       [(ngModel)]="coownermile.milestoneName"
                       (change)="UpdatePhaseMileStone(coownermile, $event)" />
              </td>
              <td *ngIf="coownermile.isEditable==false">
                <input type="text" readonly="readonly" [disabled]="isOnHold || isReadOnly" [ngClass]="{'read-only-ar-dates': coownermile.feedItem}"
                       class="form-control" [(ngModel)]="coownermile.milestoneName"
                       (change)="UpdatePhaseMileStone(coownermile, $event)" />
              </td>
              <td>
                <input class="form-control" [(ngModel)]="coownermile.currentDate"
                       (dateChange)="UpdatePhaseMileStone(coownermile, $event)" [matDatepicker]="picker"
                       [disabled]="isOnHold || isReadOnly"
                       (click)="picker.open();utilityService.removeCDKAnouncer()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </td>
              <td>
                <input class="form-control read-only-ar-dates" [matDatepicker]="coownerpicker" [(ngModel)]="coownermile.originalFeedArDate" readonly />
                <mat-datepicker #coownerpicker></mat-datepicker>
              </td>
              <td>
                <div>
                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!coownermile.comment"
                     (click)="SaveCommentForMilestones(coownermile)"></i>
                  <i class="glyphicon glyphicon-comment commentColor" *ngIf="coownermile.comment"
                     (click)="SaveCommentForMilestones(coownermile)"></i>
                </div>
              </td>
              <td>
                <i class="glyphicon glyphicon-trash glyphicon-custom-trash"
                   [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnly}"
                   *ngIf="coownermile.milestoneName == '1st Discovery' || coownermile.milestoneName == '1st Discovery / Phase 1-2 decision' || coownermile.isEditable==true"
                   (click)="DeleteCoOwnerphaseGateMilestones(coownermile,i)"></i>
              </td>
            </tr>
            <!-- <tr>
                    <td>
                        <input type="text" class="form-control"[(ngModel)]="milestoneItem.milestoneName"
                        (change)="UpdatePhaseMileStone(milestoneItem)" />

                    </td>
                    <td>
                        <input class="" [(ngModel)]="milestoneItem.currentDate"
                        (dateChange)="UpdatePhaseMileStone(milestoneItem)" [matDatepicker]="picker"
                        placeholder="Choose a date" (click)="picker.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker ></mat-datepicker>
                    </td>
                    <td>
                        <div>
                            <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveCommentForMilestones(milestoneItem)"></i>
                        </div>
                    </td>
                    <td>
                        <i class="glyphicon glyphicon-trash glyphicon-custom-trash"
                        (click)="DeletephaseGateMilestones(milestoneItem)"></i>
                    </td>
                </tr> -->
          </tbody>
        </table>
      </div>
      <!-- <div class="col-xs-12">
              <button type="button" class="btn btn-sm btn-primary" (click)="addCoOwnerViews()" >Add
                  New Row</button>
          </div> -->
    </div>
  </div>
</div>
<div class="col-xs-12">
  <div class="row">
    <div class="col-xs-12 form-group">
      <strong>
        1.4 Total Commitment Detail (Net Chevron share P50 - nominal $MM)
      </strong>
      <span class="glyphicon glyphicon-question-sign" (click)="showCEDetailPopUp()"></span>
    </div>

    <div class="col-xs-12">
      <table class="table table-bordered">
        <tbody>
          <tr class="bg-table-default table-header">
            <td width="10%">$MM</td>
            <td width="10%">
              <span class="column-90">
            <td disabled readonly>Facilities</td>
            </span>
            <span>+</span>

            <!-- <span class="column-90">
                    <td disabled readonly>Facilities</td>
                </span>
                <span>+</span> -->
            </td>
            <td width="10%">
              <span class="column-90">
            <td disabled readonly>Drilling & Completion</td>
            </span>
            <span>+</span>
            </td>
            <td width="10%">
              <span class="column-90">
            <td disabled readonly>Contingency & Escalation</td>
            </span>
            <span>+</span>
            </td>
            <td width="10%">
              <span class="column-90">
            <td disabled readonly>Other Joint Costs</td>
            </span>
            <span>+</span>
            </td>
            <td width="10%">
              <span class="column-90">
            <td disabled readonly>Other Sole Costs</td>
            </span>
            <span>+</span>
            </td>
            <td width="10%">
              <span class="column-90">
            <td disabled readonly> Total Appropriated This AR</td>
            </span>
            <span>+</span>
            </td>
            <td width="10%">
              <span class="column-90">
            <td disabled readonly>Expense on Capital</td>
            </span>
            <span>=</span>
            </td>
            <td width="10%">
              <span class="column-90">
            <td disabled readonly>Total Commitment</td>
            </span>

            </td>
            <td width="10%">
              <span class="column-90">
            <td disabled readonly> Chevron NOJV View (if applicable)</td>
            </span>
            </td>
          </tr>
          <!-- ko foreach: CapitalAndExpenditures -->
          <tr *ngFor="let cAndEDetail of capitalAndExpendituresData">
            <td [(ngModel)]="cAndEDetail.id" hidden class="ml-10">{{cAndEDetail.id}}</td>

            <td>
              <div *ngIf="(cAndEDetail.capitalAndExpenditureName=='C&E Total' || cAndEDetail.capitalAndExpenditureName=='Capex Total');else CE_content">
            <td>
              <label class="column-120"><strong>{{ (form.features?.isNewCEisApplicableForms || form.isNewCEisApplicableForms) ? (cAndEDetail.capitalAndExpenditureName | replace: 'C&E': 'Capex') : cAndEDetail.capitalAndExpenditureName }}</strong></label>
            </td>
    </div>

    <ng-template #CE_content>
      <td><label class="column-120">{{cAndEDetail.capitalAndExpenditureName}}</label></td>
    </ng-template>
    <!-- <input type="text" class="column-110"[(ngModel)]="cAndEDetail.capitalAndExpenditureName" />-->
    <!-- <label  class="column-90">{{cAndEDetail.capitalAndExpenditureName}}</label> -->
    </td>
    <td>
      <div class="input-group" *ngIf="(cAndEDetail.capitalAndExpenditureName=='C&E Total' || cAndEDetail.capitalAndExpenditureName=='Capex Total');else fac_content">
        <div class="input-icon" style="display:flex;">
        <td class="form-control alignRight td-padding-border-radius" disabled readonly>{{totalFacilities}}</td>
        <i style="z-index: 1111;">$</i>     
      </div>
        <div class="input-group-addon" style="visibility: hidden;">
      <i class="glyphicon glyphicon-comment-outline"></i>
    </div>
    <!-- <input class="form-control" type="text" readonly [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalFacilities" /> -->
  </div>
  <ng-template #fac_content>
    <div class="input-group">
      <div class="input-icon" style="display:flex;">
      <input class="form-control alignRight" type="text" [ngClass]="{'CE-read-only': cAndEDetail.itemReadyOnly}"
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="cAndEDetail.facilities"
             (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
             <i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon">
        <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.facilitiesComment"
           (click)="SaveCommentForcAndEDetails(cAndEDetail,'Facility')"></i>
        <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.facilitiesComment"
           (click)="SaveCommentForcAndEDetails(cAndEDetail,'Facility')"></i>

      </div>
    </div>
  </ng-template>
  </td>
  <td>
    <div class="input-group" *ngIf="(cAndEDetail.capitalAndExpenditureName=='C&E Total' || cAndEDetail.capitalAndExpenditureName=='Capex Total');else dri_content">
      <div class="input-icon" style="display:flex;">
      <td class="form-control alignRight td-padding-border-radius" disabled readonly>{{totalDrillingAndCompletions}}</td>
  <i style="z-index: 1111;">$</i>     
</div>
  <div class="input-group-addon" style="visibility: hidden;">
    <i class="glyphicon glyphicon-comment-outline"></i>
  </div>
</div>
<ng-template #dri_content>
  <div class="input-group">
    <div class="input-icon" style="display:flex;">
    <input class="form-control alignRight" [ngClass]="{'CE-read-only': cAndEDetail.itemReadyOnly}" type="text"
           [disabled]="isOnHold || isReadOnly" [(ngModel)]="cAndEDetail.drillingAndCompletions"
           (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
           <i style="z-index: 1111;">$</i>     
</div>
    <div class="input-group-addon">
      <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.drillingComment"
         (click)="SaveCommentForcAndEDetails(cAndEDetail,'Drilling')"></i>
      <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.drillingComment"
         (click)="SaveCommentForcAndEDetails(cAndEDetail,'Drilling')"></i>
    </div>
  </div>
</ng-template>
</td>
<td>
  <div class="input-group" *ngIf="(cAndEDetail.capitalAndExpenditureName=='C&E Total' || cAndEDetail.capitalAndExpenditureName=='Capex Total');else cont_content">
    <div class="input-icon" style="display:flex;">
    <td class="form-control alignRight td-padding-border-radius" disabled readonly>{{totalContingencyEscalation}}</td>
    <i style="z-index: 1111;">$</i>     
  </div>
    <div class="input-group-addon" style="visibility: hidden;">
  <i class="glyphicon glyphicon-comment-outline"></i>
</div>
</div>
<ng-template #cont_content>
  <div class="input-group">
    <div class="input-icon" style="display:flex;">
    <input class="form-control alignRight" [ngClass]="{'CE-read-only': cAndEDetail.itemReadyOnly}" type="text"
           [disabled]="isOnHold || isReadOnly" [(ngModel)]="cAndEDetail.contingencyEscalation"
           (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
           <i style="z-index: 1111;">$</i>     
          </div>
    <div class="input-group-addon">
      <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.contingencyComment"
         (click)="SaveCommentForcAndEDetails(cAndEDetail,'Contingency')"></i>
      <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.contingencyComment"
         (click)="SaveCommentForcAndEDetails(cAndEDetail,'Contingency')"></i>
    </div>
  </div>
</ng-template>
</td>
<td>
  <div class="input-group" *ngIf="(cAndEDetail.capitalAndExpenditureName=='C&E Total' || cAndEDetail.capitalAndExpenditureName=='Capex Total');else jointCosts_content">
    <div class="input-icon" style="display:flex;">
    <td class="form-control alignRight  td-padding-border-radius" disabled readonly>{{totalOtherJointCosts}}</td>
<i style="z-index: 1111;">$</i>     
</div>
<div class="input-group-addon" style="visibility: hidden;">
  <i class="glyphicon glyphicon-comment-outline"></i>
</div>
</div>
<ng-template #jointCosts_content>
  <div class="input-group">
    <div class="input-icon" style="display:flex;">
    <input class="form-control alignRight" [ngClass]="{'CE-read-only': cAndEDetail.itemReadyOnly}" type="text"
           [disabled]="isOnHold || isReadOnly" [(ngModel)]="cAndEDetail.otherJointCosts"
           (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
           <i style="z-index: 1111;">$</i>     
          </div>
    <div class="input-group-addon">
      <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.otherJointComment"
         (click)="SaveCommentForcAndEDetails(cAndEDetail,'JointCost')"></i>
      <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.otherJointComment"
         (click)="SaveCommentForcAndEDetails(cAndEDetail,'JointCost')"></i>
    </div>
  </div>
</ng-template>
</td>
<td>
  <div class="input-group" *ngIf="(cAndEDetail.capitalAndExpenditureName=='C&E Total' || cAndEDetail.capitalAndExpenditureName=='Capex Total');else otherSoleCosts_content">
    <div class="input-icon" style="display:flex;">
    <td class="form-control alignRight  td-padding-border-radius" disabled readonly>{{totalOtherSoleCosts}}</td>
    <i style="z-index: 1111;">$</i>     
  </div>
    <div class="input-group-addon" style="visibility: hidden;">
  <i class="glyphicon glyphicon-comment-outline"></i>
</div>
</div>
<ng-template #otherSoleCosts_content>
  <div class="input-group">
    <div class="input-icon" style="display:flex;">
    <input class="form-control alignRight" [ngClass]="{'CE-read-only': cAndEDetail.itemReadyOnly}" type="text"
           [disabled]="isOnHold || isReadOnly" [(ngModel)]="cAndEDetail.otherSoleCosts"
           (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
           <i style="z-index: 1111;">$</i>     
          </div>
    <div class="input-group-addon">
      <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.otherSoleComment"
         (click)="SaveCommentForcAndEDetails(cAndEDetail,'SoleCost')"></i>
      <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.otherSoleComment"
         (click)="SaveCommentForcAndEDetails(cAndEDetail,'SoleCost')"></i>
    </div>
  </div>
</ng-template>
</td>
<td>
  <div class="input-group" *ngIf="(cAndEDetail.capitalAndExpenditureName=='C&E Total' || cAndEDetail.capitalAndExpenditureName=='Capex Total');else totalAppropr_content">
    <div class="input-icon" style="display:flex;">
    <td class="form-control alignRight td-padding-border-radius" disabled readonly>{{appAmtAPR}}</td>
<i style="z-index: 1111;">$</i>     
          </div>
<div class="input-group-addon" style="visibility: hidden;">
  <i class="glyphicon glyphicon-comment-outline"></i>
</div>
</div>
<ng-template #totalAppropr_content>
  <div class="input-group">
    <div class="input-icon" style="display:flex;">
    <input class="form-control alignRight" type="text" readonly [disabled]="isOnHold || isReadOnly"
           [(ngModel)]="cAndEDetail.totalAppropriated" />
           <i style="z-index: 1111;">$</i>     
          </div>
    <div class="input-group-addon">
      <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.totalAppComment"
         (click)="SaveCommentForcAndEDetails(cAndEDetail,'TotalAppropriate')"></i>
      <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.totalAppComment"
         (click)="SaveCommentForcAndEDetails(cAndEDetail,'TotalAppropriate')"></i>
    </div>
  </div>
</ng-template>
</td>
<td>
  <div class="input-group" *ngIf="(cAndEDetail.capitalAndExpenditureName=='C&E Total' || cAndEDetail.capitalAndExpenditureName=='Capex Total');else exp_content">
    <!-- <input class="form-control" type="number" [disabled]="isOnHold" [(ngModel)]="cAndEDetail.expenseOnCapital"
                            (change)="UpdateCapitalAndExpenditures(cAndEDetail)" /> -->

  </div>
  <ng-template #exp_content>

    <div class="input-group">
      <div class="input-icon" style="display:flex;">
      <input class="form-control alignRight" [ngClass]="{'CE-read-only': cAndEDetail.itemReadyOnly}" type="text"
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="cAndEDetail.expenseOnCapital"
             (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
             <i style="z-index: 1111;">$</i>     
            </div>
      <div class="input-group-addon">
        <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.expCapComment"
           (click)="SaveCommentForcAndEDetails(cAndEDetail,'ExpenseOnCapital')"></i>
        <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.expCapComment"
           (click)="SaveCommentForcAndEDetails(cAndEDetail,'ExpenseOnCapital')"></i>
      </div>
    </div>
  </ng-template>
</td>
<td>
  <div class="input-group" *ngIf="(cAndEDetail.capitalAndExpenditureName=='C&E Total' || cAndEDetail.capitalAndExpenditureName=='Capex Total');else totalCommitment_content">
    <div class="input-icon" style="display:flex;">
    <input class="form-control alignRight td-padding-border-radius" type="text" readonly
           [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalCommitmentCal" />
            <i style="z-index: 1111;">$</i>     
          </div>
    <div class="input-group-addon" style="visibility: hidden;">
      <i class="glyphicon glyphicon-comment-outline"></i>
    </div>
  </div>
  <ng-template #totalCommitment_content>
    <div class="input-group">
      <div class="input-icon" style="display:flex;">
      <input class="form-control alignRight" type="text" [disabled]="isOnHold || isReadOnly" readonly
             [(ngModel)]="cAndEDetail.totalCommitmentValue" />
<i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon">
        <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.totalComComment"
           (click)="SaveCommentForcAndEDetails(cAndEDetail,'TotalCommitment')"></i>
        <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.totalComComment"
           (click)="SaveCommentForcAndEDetails(cAndEDetail,'TotalCommitment')"></i>
      </div>
    </div>
  </ng-template>
</td>
<td>
  <div class="input-group" *ngIf="(cAndEDetail.capitalAndExpenditureName=='C&E Total' || cAndEDetail.capitalAndExpenditureName=='Capex Total');else coOwnerView_content">
    <div class="input-icon" style="display:flex;">
    <input class="form-control alignRight td-padding-border-radius" type="text" readonly
           [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalCoOwner" />
           <i style="z-index: 1111;">$</i>     
</div>
    <div class="input-group-addon" style="visibility: hidden;">
      <i class="glyphicon glyphicon-comment-outline"></i>
    </div>
  </div>
  <ng-template #coOwnerView_content>

    <div class="input-group">
      <div class="input-icon" style="display:flex;">
      <input class="form-control alignRight" [ngClass]="{'CE-read-only': cAndEDetail.itemReadyOnly}" type="text"
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="cAndEDetail.coOwnerView"
             (change)="UpdateCapitalAndExpenditures(cAndEDetail)" />
             <i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon">
        <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetail.coOwnerViewComment"
           (click)="SaveCommentForcAndEDetails(cAndEDetail,'CoOwnerView')"></i>
        <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetail.coOwnerViewComment"
           (click)="SaveCommentForcAndEDetails(cAndEDetail,'CoOwnerView')"></i>
      </div>
    </div>
  </ng-template>
</td>

</tr>
<!-- <tr class="bg-table-default table-header">
                        <td width="10%">C&E Total</td>
                        <td width="10%">
                            <span class="column-90">
                                <td disabled readonly>{{totalFacilities}}</td>
                            </span>
                        </td>
                        <td width="10%">
                            <span class="column-90">
                                <td disabled readonly>{{totalDrillingAndCompletions}}</td>
                            </span>
                        </td>
                        <td width="10%">
                            <span class="column-90">
                                <td disabled readonly>{{totalContingencyEscalation}}</td>
                            </span>
                        </td>
                        <td width="10%">
                            <span class="column-90">
                                <td disabled readonly>{{totalOtherJointCosts}}</td>
                            </span>
                        </td>
                        <td width="10%">
                            <span class="column-90">
                                <td disabled readonly>{{totalOtherSoleCosts}}</td>
                            </span>
                        </td>
                        <td width="10%">
                            <span class="column-90">
                        <td disabled readonly> {{totalAppropriatedCal}}</td>
                           </span>
                        </td>
                        <td width="10%">
                            <span class="column-90">
                                <td disabled readonly>{{totalExpenseOnCapital}}</td>
                            </span>
                        </td>
                        <td width="10%">
                            <span class="column-90">
                                <td disabled readonly>{{totalCommitmentCal}}</td>
                            </span>

                        </td>
                        <td width="10%">
                            <span class="column-90">
                                <td disabled readonly>{{totalCoOwner}}</td>
                        </span>
                        </td>
                    </tr> -->

<tr height="30">
</tr>
<tr *ngFor="let cAndEDetailList of capitalAndExpendituresAfterCETotalData">
  <td [(ngModel)]="cAndEDetailList.id" hidden class="ml-10">{{cAndEDetailList.id}}</td>
  <td [(ngModel)]="cAndEDetailList.sectionAfterCetotal" hidden class="ml-10">
    {{cAndEDetailList.sectionAfterCetotal}}
  </td>
  <div *ngIf="(cAndEDetailList.capitalAndExpenditureName=='Non-C&E SubTotal' || cAndEDetailList.capitalAndExpenditureName=='Non-Capex SubTotal');else other_content">
  <td> <label class="column-90"><strong>{{ (form.features?.isNewCEisApplicableForms || form.isNewCEisApplicableForms) ? (cAndEDetailList.capitalAndExpenditureName | replace: 'C&E': 'Capex') : cAndEDetailList.capitalAndExpenditureName }}</strong></label></td>
  </div>
  <ng-template #other_content>
    <td><label class="column-90">{{cAndEDetailList.capitalAndExpenditureName}}</label></td>
  </ng-template>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td>
    <div class="input-group">
      <!-- <input class="form-control" type="number" [disabled]="isOnHold || isReadOnly" [(ngModel)]="cAndEDetailList.totalAppropriated"
                          (change)="UpdateCapitalAndExpenditures(cAndEDetailList)" />
                              <div class="input-group-addon">
                                  <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetailList.totalAppComment"
                                  (click)="SaveCommentForcAndEDetails(cAndEDetailList,'TotalAppropriate')"></i>
                                  <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetailList.totalAppComment"
                                  (click)="SaveCommentForcAndEDetails(cAndEDetailList,'TotalAppropriate')"></i>
                              </div> -->
    </div>
  </td>
  <td>
    <div class="input-group"
         *ngIf="(cAndEDetailList.capitalAndExpenditureName=='Non-C&E SubTotal' || cAndEDetailList.capitalAndExpenditureName=='Non-Capex SubTotal');else other_content1">
         <div class="input-icon" style="display:flex;">
         <input class="form-control alignRight td-padding-border-radius" type="text" readonly
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalExpenseOnCapital" />
             <i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon" style="visibility: hidden;">
        <i class="glyphicon glyphicon-comment-outline"></i>
      </div>
    </div>
    <ng-template #other_content1>
      <div class="input-group">
        <div class="input-icon" style="display:flex;">
        <input class="form-control alignRight" type="text" [disabled]="isOnHold || isReadOnly"
               [(ngModel)]="cAndEDetailList.expenseOnCapital"
               (change)="UpdateCapitalAndExpenditures(cAndEDetailList)" />
               <i style="z-index: 1111;">$</i>     
</div>
        <div class="input-group-addon">
          <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetailList.expCapComment"
             (click)="SaveCommentForcAndEDetails(cAndEDetailList,'ExpenseOnCapital')"></i>
          <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetailList.expCapComment"
             (click)="SaveCommentForcAndEDetails(cAndEDetailList,'ExpenseOnCapital')"></i>
        </div>
      </div>
    </ng-template>
  </td>
  <td>
    <div class="input-group"
         *ngIf="(cAndEDetailList.capitalAndExpenditureName=='Non-C&E SubTotal' || cAndEDetailList.capitalAndExpenditureName=='Non-Capex SubTotal');else other_content2">
         <div class="input-icon" style="display:flex;">
         <input class="form-control alignRight td-padding-border-radius" type="text" readonly
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalCommitmentESum" />
             <i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon" style="visibility: hidden;">
        <i class="glyphicon glyphicon-comment-outline"></i>
      </div>
    </div>
    <ng-template #other_content2>
      <div class="input-group">
        <div class="input-icon" style="display:flex;">
        <input class="form-control alignRight" type="text" [disabled]="isOnHold || isReadOnly"
               [(ngModel)]="cAndEDetailList.totalCommitment"
               (change)="UpdateCapitalAndExpenditures(cAndEDetailList)" />
               <i style="z-index: 1111;">$</i>     
              </div>
        <div class="input-group-addon">
          <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetailList.totalComComment"
             (click)="SaveCommentForcAndEDetails(cAndEDetailList,'TotalCommitment')"></i>
          <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetailList.totalComComment"
             (click)="SaveCommentForcAndEDetails(cAndEDetailList,'TotalCommitment')"></i>
        </div>
      </div>
    </ng-template>
  </td>
  <td>
    <div class="input-group"
         *ngIf="(cAndEDetailList.capitalAndExpenditureName=='Non-C&E SubTotal' || cAndEDetailList.capitalAndExpenditureName=='Non-Capex SubTotal');else other_content3">
         <div class="input-icon" style="display:flex;">
         <input class="form-control alignRight td-padding-border-radius" type="text" readonly
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalCoOwnerAfterCESum" />
             <i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon" style="visibility: hidden;">
        <i class="glyphicon glyphicon-comment-outline"></i>
      </div>
    </div>
    <ng-template #other_content3>
      <div class="input-group">
        <div class="input-icon" style="display:flex;">
        <input class="form-control alignRight" type="text" [disabled]="isOnHold || isReadOnly"
               [(ngModel)]="cAndEDetailList.coOwnerView"
               (change)="UpdateCapitalAndExpenditures(cAndEDetailList)" />
               <i style="z-index: 1111;">$</i>     
</div>
        <div class="input-group-addon">
          <i class="glyphicon glyphicon-comment-outline" *ngIf="!cAndEDetailList.coOwnerViewComment"
             (click)="SaveCommentForcAndEDetails(cAndEDetailList,'CoOwnerView')"></i>
          <i class="glyphicon glyphicon-comment commentColor" *ngIf="cAndEDetailList.coOwnerViewComment"
             (click)="SaveCommentForcAndEDetails(cAndEDetailList,'CoOwnerView')"></i>
        </div>
      </div>
    </ng-template>
  </td>
</tr>



<tr class="bg-table-default table-header">
  <td><strong>Total Commitment</strong></td>
  <td>
    <div class="input-group">
      <div class="input-icon" style="display:flex;">
      <input class="form-control alignRight td-padding-border-radius" type="text" readonly
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalFacilities" />
             <i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon" style="visibility: hidden;">
        <i class="glyphicon glyphicon-comment-outline"></i>
      </div>
    </div>
  </td>
  <td>
    <div class="input-group">
      <div class="input-icon" style="display:flex;">
      <input class="form-control alignRight td-padding-border-radius" type="text" readonly
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalDrillingAndCompletions" />
             <i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon" style="visibility: hidden;">
        <i class="glyphicon glyphicon-comment-outline"></i>
      </div>
    </div>
  </td>
  <td>
    <div class="input-group">
      <div class="input-icon" style="display:flex;">
      <input class="form-control alignRight td-padding-border-radius" type="text" readonly
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalContingencyEscalation" />
             <i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon" style="visibility: hidden;">
        <i class="glyphicon glyphicon-comment-outline"></i>
      </div>
    </div>
  </td>
  <td>
    <div class="input-group">
      <div class="input-icon" style="display:flex;">
      <input class="form-control alignRight td-padding-border-radius" type="text" readonly
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalOtherJointCosts" />
             <i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon" style="visibility: hidden;">
        <i class="glyphicon glyphicon-comment-outline"></i>
      </div>
    </div>
  </td>
  <td>
    <div class="input-group">
      <div class="input-icon" style="display:flex;">
      <input class="form-control alignRight td-padding-border-radius" type="text" readonly
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="totalOtherSoleCosts" />
             <i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon" style="visibility: hidden;">
        <i class="glyphicon glyphicon-comment-outline"></i>
      </div>
    </div>
  </td>
  <td>

    <div>
    </div>
  </td>
  <td>
    <div class="input-group">
      <div class="input-icon" style="display:flex;">
      <input class="form-control alignRight td-padding-border-radius" type="text" readonly
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="addExpenseOnCapital" />
             <i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon" style="visibility: hidden;">
        <i class="glyphicon glyphicon-comment-outline"></i>
      </div>
    </div>
  </td>
  <td>
    <div class="input-group">
      <div class="input-icon" style="display:flex;">
      <input class="form-control alignRight td-padding-border-radius" type="text" readonly
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="addtotalCommitment" />
             <i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon" style="visibility: hidden;">
        <i class="glyphicon glyphicon-comment-outline"></i>
      </div>
    </div>
  </td>
  <td>
    <div class="input-group">
      <div class="input-icon" style="display:flex;">
      <input class="form-control alignRight td-padding-border-radius" type="text" readonly
             [disabled]="isOnHold || isReadOnly" [(ngModel)]="addCoOwner" />
             <i style="z-index: 1111;">$</i>     
</div>
      <div class="input-group-addon" style="visibility: hidden;">
        <i class="glyphicon glyphicon-comment-outline"></i>
      </div>
    </div>
  </td>

</tr>
</tbody>
</table>
<!-- <button type="button" class="btn btn-sm btn-primary"
               (click)="addCAndEDetails()">Add
                New Row</button> -->
</div>
</div>
</div>

<div class="col-xs-8">
  <div class="row ">
    <div class="col-xs-12">
      <strong>Provide additional comment on {{ (form.features?.isNewCEisApplicableForms || form.isNewCEisApplicableForms) ? "Capex": "C&E" }} as relevant and include co-owner data as applicable.</strong>
      <!-- <span class="glyphicon glyphicon-question-sign" (click)="showShortSummaryInfo()"></span> -->
    </div>
  </div>
  <div class="row pl-16">
    <span>
      <mat-slide-toggle (change)="MatSliderNAEventCheck($event, 'CapitalAndExpenditure')"
                        [checked]="overview.capAndExpNa"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
        <span class="app-font">N/A</span>
      </mat-slide-toggle>
    </span>
  </div>
</div>

<div class="row sub-section">
  <!-- <div class="col-xs-12 form-group">
      <strong>Provide additional comment on C&E as relevant and include co-owner data as applicable.</strong>
  </div> -->
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || overview.capAndExpNa
            || isReadOnly || isReadOnlyGlobalProjectLevel }" *ngIf="!overview.capAndExpNa">
        <span>
          <ckeditor #capitalAndExpenditureeditor [editor]="Editor" [config]="config"
                    [(ngModel)]="overview.capitalAndExpenditure" id="capitalAndExpenditure"
                    name="capitalAndExpenditure" (ready)="onReady($event,'CapitalAndExpenditure')">
          </ckeditor>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="col-xs-8">
  <div class="row ">
    <div class="col-xs-12 form-group">
      <strong>1.5 Key economic metrics at mid-price: Mid-Point and EV values</strong>
      <span class="glyphicon glyphicon-question-sign" (click)="ShowKeyEconomicPopUp()"></span>
    </div>
  </div>
</div>
<div class="row sub-section">
  <div class="col-xs-12">
    <table class="table table-bordered" style="margin-left: 15px !important;">
      <tbody>
        <!-- <tr class="bg-table-default table-header">
            <td></td>
            <td colspan="7" class="text-center bold">Supplement</td>
            <td colspan="3" class="text-center bold">Original AR</td>
            <td></td>
        </tr> -->
        <tr class="bg-table-default table-header">
          <td></td>
          <td colspan="4" class="text-center" style=" padding: 10px 5px 10px 5px;">
            Supplement<br> Mid Point
          </td>
          <td colspan="3" class="text-center" style=" padding: 10px 5px 10px 5px;">Supplement<br> EV</td>
          <td colspan="3" class="text-center" style=" padding: 10px 5px 10px 5px;">
            Original FEED AR<br> EV
          </td>
          <td></td>
        </tr>
        <tr class="bg-table-default table-header">
          <td></td>
          <td class="text-center" style="padding: 10px 5px 20px 5px;">Full Cycle</td>
          <td class="text-center" style="padding: 10px 5px 20px 5px;">FEED Forward</td>
          <td class="text-center" style="padding: 10px 5px 20px 5px;">Point Forward</td>
          <td class="text-center" style="padding: 10px 5px 20px 5px;">FEED Forward @ original AR economics</td>
          <!-- <td class="text-center">Point Forward Co-Owner View (if applicable)</td> -->
          <td class="text-center" style="padding: 10px 5px 20px 5px;">Full Cycle</td>
          <td class="text-center" style="padding: 10px 5px 20px 5px;">FEED Forward</td>
          <td class="text-center" style="padding: 10px 5px 20px 5px;">Point Forward</td>
          <td class="text-center" style="padding: 10px 5px 20px 5px;">Full Cycle</td>
          <td class="text-center" style="padding: 10px 5px 20px 5px;">FEED Forward</td>
          <td class="text-center" style="padding: 10px 5px 20px 5px;">Point Forward at FID</td>
          <td width="1%"></td>
        </tr>
        <!-- ko foreach: KeyEconomicMetrics -->
        <tr class="table-header" *ngFor="let keyEco of keyEconomicMetricsData">
          <td [(ngModel)]="keyEco.id" hidden class="ml-10">{{keyEco.id}}</td>
          <td class="bg-table-computed-clear" data-bind="text: KeyEconomicMetricName">
            {{keyEco.keyEconomicMetricName}}
          </td>
          <td class="bg-table-computed-clear text-center">
            <div class="input-icon" *ngIf="keyEco.keyEconomicMetricName != undefined && keyEco.keyEconomicMetricName.includes('$MM'); else noDollarfullCycle">
                <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                      class="form-control" id="fullCycle" name="fullCycle" readonly
                      [(ngModel)]="keyEco.fullCycle" />
                <i>$</i>    
            </div>
            <ng-template #noDollarfullCycle>
              <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
              class="form-control" id="fullCycle" name="fullCycle" readonly
              [(ngModel)]="keyEco.fullCycle" />
            </ng-template>
          </td>
          <td class="bg-table-computed-clear text-center">
            <div class="input-icon" *ngIf="keyEco.keyEconomicMetricName != undefined && keyEco.keyEconomicMetricName.includes('$MM'); else noDollarfeedForward">
                <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                      class="form-control" id="feedForward" name="feedForward" readonly
                      [(ngModel)]="keyEco.feedForward" />
                <i>$</i>    
            </div>
            <ng-template #noDollarfeedForward>
              <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                      class="form-control" id="feedForward" name="feedForward" readonly
                      [(ngModel)]="keyEco.feedForward" />
            </ng-template>
          </td>
          <td class="bg-table-computed-clear text-center">
            <div class="input-icon" *ngIf="keyEco.keyEconomicMetricName != undefined && keyEco.keyEconomicMetricName.includes('$MM'); else noDollarpointForward">
              <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                    class="form-control" id="pointForward" name="pointForward" readonly
                    [(ngModel)]="keyEco.pointForward" />
              <i>$</i>    
            </div>
            <ng-template #noDollarpointForward>
              <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                    class="form-control" id="pointForward" name="pointForward" readonly
                    [(ngModel)]="keyEco.pointForward" />
            </ng-template>
          </td>
          <td class="bg-table-computed-clear text-center">
            <div class="input-icon" *ngIf="keyEco.keyEconomicMetricName != undefined && keyEco.keyEconomicMetricName.includes('$MM'); else noDollarSupplementOriginalAR">
              <input class="form-control" type="text" readonly [(ngModel)]="keyEco.supplementOriginalAR" />
              <i>$</i>    
            </div>
            <ng-template #noDollarSupplementOriginalAR>
              <input class="form-control" type="text" readonly [(ngModel)]="keyEco.supplementOriginalAR" />
            </ng-template>
          </td>
          <!-- <td class="bg-table-computed-clear text-center">
              <input class="form-control"
                     type="text"
                     data-bind="formText: PartnerView, isNumber: true, autoSave: $parent.formUpdated.bind($parent, $data, $parent.formSections.KeyEconomicMetric)
                     , attr: {readonly: $parent.IsLocked()}" />
          </td> -->
          <td class="bg-table-computed-clear text-center" *ngIf="keyEco.keyEconomicMetricName!='Payout Time (yrs)' && keyEco.keyEconomicMetricName!='Max negative cum CF ($MM)'; else noevFullCycleFields">
            <div class="input-icon" *ngIf="keyEco.keyEconomicMetricName != undefined && keyEco.keyEconomicMetricName.includes('$MM'); else noDollarevFullCycle">
              <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                    class="form-control" id="evFullCycle" name="evFullCycle" readonly
                    [(ngModel)]="keyEco.evFullCycle" />
              <i>$</i>    
            </div>
            <ng-template #noDollarevFullCycle>
              <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                    class="form-control" id="evFullCycle" name="evFullCycle" readonly
                    [(ngModel)]="keyEco.evFullCycle" />
            </ng-template>
          </td> 
          <ng-template #noevFullCycleFields>
            <td class="bg-table-computed-clear text-center"></td>
          </ng-template>
          <td class="bg-table-computed-clear text-center" *ngIf="keyEco.keyEconomicMetricName!='Payout Time (yrs)' && keyEco.keyEconomicMetricName!='Max negative cum CF ($MM)'; else noevFeedForwardFields">
            <div class="input-icon" *ngIf="keyEco.keyEconomicMetricName != undefined && keyEco.keyEconomicMetricName.includes('$MM'); else noDollarevFeedForward">
            <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                   class="form-control" id="evFeedForward" name="evFeedForward" readonly
                   [(ngModel)]="keyEco.evFeedForward" />
            <i>$</i>    
          </div>
          <ng-template #noDollarevFeedForward>
            <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                   class="form-control" id="evFeedForward" name="evFeedForward" readonly
                   [(ngModel)]="keyEco.evFeedForward" />
          </ng-template>
          </td>
          <ng-template #noevFeedForwardFields>
            <td class="bg-table-computed-clear text-center"></td>
          </ng-template>
          <td class="bg-table-computed-clear text-center" *ngIf="keyEco.keyEconomicMetricName!='Payout Time (yrs)' && keyEco.keyEconomicMetricName!='Max negative cum CF ($MM)'; else noevPointForwardFields">
            <div class="input-icon" *ngIf="keyEco.keyEconomicMetricName != undefined && keyEco.keyEconomicMetricName.includes('$MM'); else noDollarevPointForward">
            <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                   class="form-control" id="evPointForward" name="evPointForward" readonly
                   [(ngModel)]="keyEco.evPointForward" />
            <i>$</i>    
          </div>
          <ng-template #noDollarevPointForward>
            <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                   class="form-control" id="evPointForward" name="evPointForward" readonly
                   [(ngModel)]="keyEco.evPointForward" />
          </ng-template>
          </td> 
          <ng-template #noevPointForwardFields>
            <td class="bg-table-computed-clear text-center"></td>
          </ng-template>
          <td class="bg-table-computed-clear text-center" *ngIf="keyEco.keyEconomicMetricName!='Payout Time (yrs)' && keyEco.keyEconomicMetricName!='Max negative cum CF ($MM)'; else noaroriginalFullCycleFields">
            <div class="input-icon" *ngIf="keyEco.keyEconomicMetricName != undefined && keyEco.keyEconomicMetricName.includes('$MM'); else noDollaroriginalFullCycle">
            <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                   class="form-control read-only-ar-dates" id="originalFullCycle" name="originalFullCycle"
                   readonly [(ngModel)]="keyEco.originalFullCycle" />
            <i>$</i>    
          </div>
          <ng-template #noDollaroriginalFullCycle>
            <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
            class="form-control read-only-ar-dates" id="originalFullCycle" name="originalFullCycle"
            readonly [(ngModel)]="keyEco.originalFullCycle" />
          </ng-template>
          </td><ng-template #noaroriginalFullCycleFields>
            <td class="bg-table-computed-clear text-center"></td>
          </ng-template>
          <td class="bg-table-computed-clear text-center" *ngIf="keyEco.keyEconomicMetricName!='Payout Time (yrs)' && keyEco.keyEconomicMetricName!='Max negative cum CF ($MM)'; else nooriginalFeedForwardFields">
            <div class="input-icon" *ngIf="keyEco.keyEconomicMetricName != undefined && keyEco.keyEconomicMetricName.includes('$MM'); else noDollaroriginalFeedForward">
            <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                   class="form-control read-only-ar-dates" id="originalFeedForward" name="originalFeedForward"
                   readonly [(ngModel)]="keyEco.originalFeedForward" />
            <i>$</i>    
          </div>
          <ng-template #noDollaroriginalFeedForward>
            <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                   class="form-control read-only-ar-dates" id="originalFeedForward" name="originalFeedForward"
                   readonly [(ngModel)]="keyEco.originalFeedForward" />
          </ng-template>
          </td>
          <ng-template #nooriginalFeedForwardFields>
            <td class="bg-table-computed-clear text-center"></td>
          </ng-template>
          <td class="bg-table-computed-clear text-center"  *ngIf="keyEco.keyEconomicMetricName!='Payout Time (yrs)' && keyEco.keyEconomicMetricName!='Max negative cum CF ($MM)'; else nooriginalPointForwardFields">
            <div class="input-icon" *ngIf="keyEco.keyEconomicMetricName != undefined && keyEco.keyEconomicMetricName.includes('$MM'); else noDollaroriginalPointForward">
            <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                   class="form-control read-only-ar-dates" id="originalPointForward"
                   name="originalPointForward" readonly [(ngModel)]="keyEco.originalPointForward" />
            <i>$</i>    
          </div>
          <ng-template #noDollaroriginalPointForward>
            <input type="text" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                   class="form-control read-only-ar-dates" id="originalPointForward"
                   name="originalPointForward" readonly [(ngModel)]="keyEco.originalPointForward" />
          </ng-template>
          </td>
          <ng-template #nooriginalPointForwardFields>
            <td class="bg-table-computed-clear text-center"></td>
          </ng-template>
          <td class="bg-table-computed-clear text-center">
            <div [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel}">

              <i class="glyphicon glyphicon-comment-outline" *ngIf="!keyEco.comment"
                 (click)="SaveCommentForKeyEconomic(keyEco)"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="keyEco.comment"
                 (click)="SaveCommentForKeyEconomic(keyEco)"></i>


            </div>
          </td>
        </tr>
        <!-- /ko -->
      </tbody>
    </table>
    <div class="col-xs-12">
      <table class="table table-bordered" style="width: 60% !important">
        <tbody>
          <tr class="table-header">
            <td colspan="3" class="bold" style=" padding: 10px 5px 10px 5px;">
              <label class="padding-top-5">
                Project has a {{formRisks.npvFullCycle}}% chance of having an
                NPV &lt;0 in the mid-price
                scenario
              </label>
              <!-- <label class="padding-top-5"></label>
              <label class="padding-top-5"> % chance of having an NPV &lt;0 in the mid-price
                  scenario</label> -->
            </td>
          </tr>
          <tr class="bg-table-default table-header">
            <td style=" padding: 10px 5px 10px 5px;"></td>
            <td style=" padding: 10px 5px 10px 5px;" class="text-center bold">Point Forward</td>
            <td style=" padding: 10px 5px 10px 5px;" class="text-center bold">
              Commodity (e.g. Brent, WTI,
              HH) Varied
            </td>
          </tr>
          <tr class="table-header">
            <td style=" padding: 10px 5px 10px 5px;" class="bg-table-computed-clear">
              Breakeven Price or Margin
            </td>
            <td style=" padding: 10px 5px 10px 5px;"
                class=" bg-table-default bg-table-computed-clear text-center">
              <div>$ {{formRisks.flatRealPointForward}}</div>
            </td>
            <td style=" padding: 10px 5px 10px 5px;"
                class="bg-table-default bg-table-computed-clear text-center">
              <div>{{formRisks.commodityTestedBy}}</div>

            </td>
          </tr>
          <tr class="bg-table-default table-header">
            <td style=" padding: 10px 5px 20px 5px;"></td>
            <td style=" padding: 10px 5px 20px 5px;" class="text-center bold">FEED Forward</td>
            <td style=" padding: 10px 5px 20px 5px;" class="text-center bold">
              Commodity (e.g. Brent, WTI,
              HH) Varied
            </td>
          </tr>
          <tr class="table-header">
            <td style=" padding: 10px 5px 20px 5px;" class="bg-table-computed-clear">
              Breakeven Price or Margin
            </td>
            <td style=" padding: 10px 5px 20px 5px;"
                class=" bg-table-default bg-table-computed-clear text-center">
              <div>$ {{formRisks.flatRealFeedForward}}</div>
            </td>
            <td style=" padding: 10px 5px 20px 5px;"
                class=" bg-table-default bg-table-computed-clear text-center">
              <div>{{formRisks.feedForwardCommodityTestedBy}}</div>

            </td>
          </tr>
          <tr>
            <td colspan="3" class="bg-table-computed-clear">
              <label class="padding-top-5">
                Remaining {{ (form.features?.isNewCEisApplicableForms || form.isNewCEisApplicableForms) ? "Capex": "C&E" }} costs must be multiplied by a factor of
                {{formRisks.remainingCostIncrease}} &nbsp;
              </label>
              <label class="padding-top-5"></label>
              <label class="padding-top-5"> to get NPV10=0 with all other inputs at the mid-case.</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="col-xs-8">
  <div class="row">
    <div class="col-xs-12 form-group">
      <strong>1.6 Project Progress and Current Status </strong>
      <span class="glyphicon glyphicon-question-sign" (click)="showProjectBackgroundPopUp()"></span>
    </div>
  </div>
</div>
<div class="col-xs-4">
  <div class="text-right">
    <div class="col-xs-12 form-group">
      <!-- <input type="checkbox" class="form-check-input text-right" [checked]="overview.projectBackNa"
      [(ngModel)]="overview.projectBackNa" (change)="NAEventCheck($event, 'ProjectBack')" /><label>N/A</label> -->
    </div>
  </div>
</div>

<div class="row sub-section">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12"
           [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel}">
        <span>
          <ckeditor #projectBackeditor [editor]="Editor" [config]="config"
                    [(ngModel)]="overview.projectBackgroundAndDescription" id="projectBackgroundAndDescription"
                    name="projectBackgroundAndDescription" (ready)="onReady($event,'projectBack')">
          </ckeditor>
        </span>
      </div>
    </div>
  </div>
</div>


<!-- <div class="col-xs-12">
    <div class="row">
            <div class="col-xs-12 form-group bold">
                <strong>1.7 Describe Lessons Learned and Mitigations to Prevent Further Supplements</strong>
                <span class="glyphicon glyphicon-question-sign" (click)="ShowProjectChangesPopUp()"></span>

                <span>: report these Lessons Learned and Mitigations in the Lessons Learned form available at the top menu.</span>
            </div>
    </div>

</div>      -->
<div class="col-xs-8" *ngIf="(features?.BeforeGreenHouseGas && isUpstream && form.formType !== 'FEED Supplement') || !features?.BeforeGreenHouseGas">
  <div class="row">
    <div class="col-xs-12 ">
      <strong>1.7 Reserves and Resources</strong>
      <span class="glyphicon glyphicon-question-sign" (click)="showReservesandResourcesPopUp()"></span>
    </div>
  </div>
  <div class="row pl-16">
    <span>
      <mat-slide-toggle (change)="MatSliderNAEventCheck($event, 'Reserves')" [checked]="overview.reservesFundFlag"
                        [disabled]="isOnHold  || form.isReadOnly || isReadOnlyGlobalProjectLevel">
        <span class="app-font">N/A</span>
      </mat-slide-toggle>
    </span>
  </div>
</div>
<div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold ||
    overview.reservesFundFlag || isReadOnly}" *ngIf="(features?.BeforeGreenHouseGas && isUpstream && form.formType !== 'FEED Supplement') || (!features?.BeforeGreenHouseGas && !overview.reservesFundFlag)">
  <table class="table table-bordered">
    <tbody>
      <tr class="table-header bg-table-default">
        <td class="bold">Operational (Net)</td>
        <td>Oil Net Revenue Interest</td>
        <td>Gas Net Revenue Interest</td>
        <td width="10%">Total Net Revenue Interest</td>
        <td width="10%">Total 8/8ths</td>
        <td data-bind="css: { width: '10%' }">Point Forward Co-Owner View (if applicable)</td>
        <td width="10%">Original FEED AR Total</td>
      </tr>
      <!-- ko foreach: Operationals -->
      <!-- <tr data-bind="if: OperationalName() === 'Net P1 Reserves booking at AR (mmboe)'"> -->
      <tr>
        <td [(ngModel)]="netReservebooking.id" hidden class="ml-10">{{netReservebooking.id}}</td>
        <td>{{netReservebooking.operationalName}}</td>
        <td>
          <div class="input-group">
            <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control" name="oil"
                   [(ngModel)]="netReservebooking.oil"
                   (change)="UpdateOperationalChanges(netReservebooking,'NetReserve')" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!netReservebooking.oilComments"
                 (click)="SaveCommentForOperational(108,1,netReservebooking.id,netReservebooking.oilComments,'NetReserve')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="netReservebooking.oilComments"
                 (click)="SaveCommentForOperational(108,1,netReservebooking.id,netReservebooking.oilComments,'NetReserve')"></i>
            </div>
          </div>
        </td>
        <td>
          <div class="input-group">
            <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control" name="gas"
                   [(ngModel)]="netReservebooking.gas"
                   (change)="UpdateOperationalChanges(netReservebooking,'NetReserve')" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!netReservebooking.gasComments"
                 (click)="SaveCommentForOperational(108,2,netReservebooking.id,netReservebooking.gasComments,'NetReserve')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="netReservebooking.gasComments"
                 (click)="SaveCommentForOperational(108,2,netReservebooking.id,netReservebooking.gasComments,'NetReserve')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input class="form-control" type="number" readonly name="total"
                   [(ngModel)]="netReservebooking.total" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!netReservebooking.totalComments"
                 (click)="SaveCommentForOperational(108,3,netReservebooking.id,netReservebooking.totalComments,'NetReserve')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="netReservebooking.totalComments"
                 (click)="SaveCommentForOperational(108,3,netReservebooking.id,netReservebooking.totalComments,'NetReserve')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input class="form-control" readonly name="total8" [(ngModel)]="netReservebooking.total8" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!netReservebooking.total8Comments"
                 (click)="SaveCommentForOperational(108,4,netReservebooking.id,netReservebooking.total8Comments,'NetReserve')"></i>
              <i class="gglyphicon glyphicon-comment commentColor"
                 *ngIf="netReservebooking.total8Comments"
                 (click)="SaveCommentForOperational(108,4,netReservebooking.id,netReservebooking.total8Comments,'NetReserve')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control" name="partnerView"
                   [(ngModel)]="netReservebooking.partnerView"
                   (change)="UpdateOperationalChanges(netReservebooking,'NetReserve')" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline"
                 *ngIf="!netReservebooking.partnerViewComments"
                 (click)="SaveCommentForOperational(108,6,netReservebooking.id,netReservebooking.partnerViewComments,'NetReserve')"></i>
              <i class="glyphicon glyphicon-comment commentColor"
                 *ngIf="netReservebooking.partnerViewComments"
                 (click)="SaveCommentForOperational(108,6,netReservebooking.id,netReservebooking.partnerViewComments,'NetReserve')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input type="number" [(ngModel)]="netReservebooking.originalTotal"
                   class="form-control read-only-ar-dates" readonly />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline"
                 *ngIf="!netReservebooking.originalTotalComments"
                 (click)="SaveCommentForOperational(108,5,netReservebooking.id,netReservebooking.originalTotalComments,'NetReserve')"></i>
              <i class="glyphicon glyphicon-comment commentColor"
                 *ngIf="netReservebooking.originalTotalComments"
                 (click)="SaveCommentForOperational(108,5,netReservebooking.id,netReservebooking.originalTotalComments,'NetReserve')"></i>
            </div>
          </div>
        </td>
      </tr>
      <!-- <tr data-bind="if: OperationalName() !== 'Net P1 Reserves booking at AR (mmboe)' && OperationalName() !== 'First 12 months Production Attainment (mboed)'" class="table-header"> -->
      <tr class="table-header">
        <td [(ngModel)]="eur.id" hidden class="ml-10">{{eur.id}}</td>
        <td>{{eur.operationalName}} </td>
        <td class="bg-table-default"></td>
        <td class="bg-table-default"></td>
        <td class="bg-table-default">
          <div class="input-group">

            <input class="form-control" readonly name="eur" [(ngModel)]="form.eur" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!eur.totalComments"
                 (click)="SaveCommentForOperational(108,3,eur.id,eur.totalComments,'Eur')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="eur.totalComments"
                 (click)="SaveCommentForOperational(108,3,eur.id,eur.totalComments,'Eur')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input class="form-control" readonly name="total8" [(ngModel)]="eur.total8" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!eur.total8Comments"
                 (click)="SaveCommentForOperational(108,4,eur.id,eur.total8Comments,'Eur')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="eur.total8Comments"
                 (click)="SaveCommentForOperational(108,4,eur.id,eur.total8Comments,'Eur')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control" name="partnerView"
                   [(ngModel)]="eur.partnerView" (change)="UpdateOperationalChanges(eur,'')" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!eur.partnerViewComments"
                 (click)="SaveCommentForOperational(108,6,eur.id,eur.partnerViewComments,'Eur')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="eur.partnerViewComments"
                 (click)="SaveCommentForOperational(108,6,eur.id,eur.partnerViewComments,'Eur')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input type="number" name="originalTotal" [(ngModel)]="eur.originalTotal"
                   class="form-control read-only-ar-dates" readonly />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!eur.originalTotalComments"
                 (click)="SaveCommentForOperational(108,5,eur.id,eur.originalTotalComments,'Eur')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="eur.originalTotalComments"
                 (click)="SaveCommentForOperational(108,5,eur.id,eur.originalTotalComments,'Eur')"></i>
            </div>
          </div>
        </td>
      </tr>
      <tr class="table-header">
        <td [(ngModel)]="futureAdd.id" hidden class="ml-10">{{futureAdd.id}}</td>
        <td>{{futureAdd.operationalName}}</td>
        <td class="bg-table-default"></td>
        <td class="bg-table-default"></td>
        <td class="bg-table-default">
          <div class="input-group">

            <input class="form-control" readonly name="sixPCurrentToFuture"
                   [(ngModel)]="form.sixPCurrentToFuture" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!futureAdd.totalComments"
                 (click)="SaveCommentForOperational(108,3,futureAdd.id,futureAdd.totalComments,'FutureAdd')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="futureAdd.totalComments"
                 (click)="SaveCommentForOperational(108,3,futureAdd.id,futureAdd.totalComments,'FutureAdd')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input class="form-control" readonly name="total8" [(ngModel)]="futureAdd.total8" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!futureAdd.total8Comments"
                 (click)="SaveCommentForOperational(108,4,futureAdd.id,futureAdd.total8Comments,'FutureAdd')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="futureAdd.total8Comments"
                 (click)="SaveCommentForOperational(108,4,futureAdd.id,futureAdd.total8Comments,'FutureAdd')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control" name="partnerView"
                   [(ngModel)]="futureAdd.partnerView" (change)="UpdateOperationalChanges(futureAdd,'')" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!futureAdd.partnerViewComments"
                 (click)="SaveCommentForOperational(108,6,futureAdd.id,futureAdd.partnerViewComments,'FutureAdd')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="futureAdd.partnerViewComments"
                 (click)="SaveCommentForOperational(108,6,futureAdd.id,futureAdd.partnerViewComments,'FutureAdd')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input type="number" name="originalTotal"
                   [(ngModel)]="futureAdd.originalTotal" class="form-control read-only-ar-dates"
                   readonly />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!futureAdd.originalTotalComments"
                 (click)="SaveCommentForOperational(108,5,futureAdd.id,futureAdd.originalTotalComments,'FutureAdd')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="futureAdd.originalTotalComments"
                 (click)="SaveCommentForOperational(108,5,futureAdd.id,futureAdd.originalTotalComments,'FutureAdd')"></i>
            </div>

          </div>
        </td>
      </tr>
      <tr class="table-header">
        <td [(ngModel)]="peakProduction.id" hidden class="ml-10">{{peakProduction.id}}</td>
        <td>{{peakProduction.operationalName}}</td>
        <td class="bg-table-default"></td>
        <td class="bg-table-default"></td>
        <td class="bg-table-default">
          <div class="input-group">

            <input class="form-control" readonly name="maxTotalProductionCurrentToFuture"
                   [(ngModel)]="form.maxTotalProductionCurrentToFuture" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!peakProduction.totalComments"
                 (click)="SaveCommentForOperational(108,3,peakProduction.id,peakProduction.totalComments,'PeakProduction')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="peakProduction.totalComments"
                 (click)="SaveCommentForOperational(108,3,peakProduction.id,peakProduction.totalComments,'PeakProduction')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input class="form-control" readonly name="total8" [(ngModel)]="peakProduction.total8" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!peakProduction.total8Comments"
                 (click)="SaveCommentForOperational(108,4,peakProduction.id,peakProduction.total8Comments,'PeakProduction')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="peakProduction.total8Comments"
                 (click)="SaveCommentForOperational(108,4,peakProduction.id,peakProduction.total8Comments,'PeakProduction')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control" name="partnerView"
                   [(ngModel)]="peakProduction.partnerView"
                   (change)="UpdateOperationalChanges(peakProduction,'')" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!peakProduction.partnerViewComments"
                 (click)="SaveCommentForOperational(108,6,peakProduction.id,peakProduction.partnerViewComments,'PeakProduction')"></i>
              <i class="glyphicon glyphicon-comment commentColor"
                 *ngIf="peakProduction.partnerViewComments"
                 (click)="SaveCommentForOperational(108,6,peakProduction.id,peakProduction.partnerViewComments,'PeakProduction')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input type="number" name="originalTotal"
                   [(ngModel)]="peakProduction.originalTotal"
                   class="form-control read-only-ar-dates" readonly />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!peakProduction.originalTotalComments"
                 (click)="SaveCommentForOperational(108,5,peakProduction.id,peakProduction.originalTotalComments,'PeakProduction')"></i>
              <i class="glyphicon glyphicon-comment commentColor"
                 *ngIf="peakProduction.originalTotalComments"
                 (click)="SaveCommentForOperational(108,5,peakProduction.id,peakProduction.originalTotalComments,'PeakProduction')"></i>
            </div>
          </div>
        </td>
      </tr>
      <!-- <tr data-bind="if: OperationalName() === 'First 12 months Production Attainment (mboed)'" class="table-header"> -->
      <tr class="table-header">
        <td [(ngModel)]="firstProduction.id" hidden class="ml-10">{{firstProduction.id}}</td>
        <td>{{firstProduction.operationalName}}</td>
        <td class="bg-table-default"></td>
        <td class="bg-table-default"></td>
        <td class="bg-table-default">
          <div class="input-group">
            <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control" name="total"
                   [(ngModel)]="firstProduction.total"
                   (change)="UpdateOperationalChanges(firstProduction,'')" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!firstProduction.totalComments"
                 (click)="SaveCommentForOperational(108,3,firstProduction.id,firstProduction.totalComments,'FirstProduction')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="firstProduction.totalComments"
                 (click)="SaveCommentForOperational(108,3,firstProduction.id,firstProduction.totalComments,'FirstProduction')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input class="form-control" readonly name="total8" [(ngModel)]="firstProduction.total8" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!firstProduction.total8Comments"
                 (click)="SaveCommentForOperational(108,4,firstProduction.id,firstProduction.total8Comments,'FirstProduction')"></i>
              <i class="glyphicon glyphicon-comment commentColor" *ngIf="firstProduction.total8Comments"
                 (click)="SaveCommentForOperational(108,4,firstProduction.id,firstProduction.total8Comments,'FirstProduction')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input type="number" [disabled]="isOnHold || isReadOnly" class="form-control" name="partnerView"
                   [(ngModel)]="firstProduction.partnerView"
                   (change)="UpdateOperationalChanges(firstProduction,'')" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline" *ngIf="!firstProduction.partnerViewComments"
                 (click)="SaveCommentForOperational(108,6,firstProduction.id,firstProduction.partnerViewComments,'FirstProduction')"></i>
              <i class="glyphicon glyphicon-comment commentColor"
                 *ngIf="firstProduction.partnerViewComments"
                 (click)="SaveCommentForOperational(108,6,firstProduction.id,firstProduction.partnerViewComments,'FirstProduction')"></i>
            </div>
          </div>
        </td>
        <td class="bg-table-default">
          <div class="input-group">
            <input type="number" [(ngModel)]="firstProduction.originalTotal"
                   class="form-control read-only-ar-dates" readonly
                   data-bind="value: OriginalTotal, isNumber: true" />
            <div class="input-group-addon">
              <i class="glyphicon glyphicon-comment-outline"
                 *ngIf="!firstProduction.originalTotalComments"
                 (click)="SaveCommentForOperational(108,5,firstProduction.id,firstProduction.originalTotalComments,'FirstProduction')"></i>
              <i class="glyphicon glyphicon-comment commentColor"
                 *ngIf="firstProduction.originalTotalComments"
                 (click)="SaveCommentForOperational(108,5,firstProduction.id,firstProduction.originalTotalComments,'FirstProduction')"></i>
            </div>
          </div>
        </td>
      </tr>
      <!-- /ko -->
    </tbody>
  </table>
  <div class="form-group">
    <label>Comment:</label>
    <div class="input-group">
      <textarea class="form-control" rows="3" id="operationalComment" name="operationalComment"
                [(ngModel)]="overview.operationalComment" [disabled]="isOnHold || isReadOnly"
                (change)="SaveOperationalComment()">
                </textarea>
      <div class="input-group-addon">
        <i class="glyphicon glyphicon-comment-outline" *ngIf="!operationalCommentItem.comments"
           (click)="SaveOperationalCommentPopUp(108,0,operationalCommentItem.id)"></i>
        <i class="glyphicon glyphicon-comment commentColor" *ngIf="operationalCommentItem.comments"
           (click)="SaveOperationalCommentPopUp(108,0,operationalCommentItem.id)"></i>
      </div>
    </div>
  </div>
</div>


<div class="col-xs-12">
  <div class="row">
    <div class="col-xs-12 form-group">
      <strong>{{ (features?.BeforeGreenHouseGas && isUpstream && form.formType !== 'FEED Supplement') || !(features?.BeforeGreenHouseGas) ? "1.8 " :"1.7 " }} Greenhouse Gas Metrics</strong>
      <i>(Chevron net share, average over project life)</i>
      <span class="glyphicon glyphicon-question-sign" (click)="ShowGreenhouseGasPopUp()"></span>
    </div>
    <div class="col-xs-12 form-group">
      <div class="col-xs-4">
        <table class="table table-bordered">
          <tbody>
            <tr class="table-header bg-table-default">
              <td colspan="2"></td>
              <td>Supplement</td>
              <td>Original FEED AR</td>
            </tr>
            <tr *ngIf="features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Total emissions (MM mtCO2e/yr)" /> -->
                <span>Total emissions (MM mtCO2e/yr)</span>
              </td>
              <td [(ngModel)]="totalEmission.id" hidden class="ml-10">{{totalEmission.id}}</td>
              <td>
                <div class="input-group">
                  <input type="text" readonly
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                         class="form-control" name="greenHouseGasTotal"
                         [(ngModel)]="greenHouseGas.greenHouseGasTotal" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!totalEmission.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,1,totalEmission.id,totalEmission.comments,1)"></i>
                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="totalEmission.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,1,totalEmission.id,totalEmission.comments,1)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input type="text"
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" readonly
                         class="form-control read-only-ar-dates"
                         name="originalGreenHouseGasTotal"
                         [(ngModel)]="greenHouseGas.originalGreenHouseGasTotal" />
                  <div class="input-group-addon">
                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!totalEmissionAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,1,totalEmissionAR.id,totalGHGAR.comments,2)"></i>
                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="totalEmissionAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,1,totalEmissionAR.id,totalGHGAR.comments,2)"></i>
                  </div>
                </div>
              </td>
            </tr>

            <tr *ngIf="features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Scope 1 emissions(MM mtCO2e/yr)" /> -->
                <span>Carbon Intensity (kgCO2e/BOE)</span>
              </td>
              <td [(ngModel)]="totalCarbonIntensity.id" hidden class="ml-10">{{totalCarbonIntensity.id}}</td>
              <td>
                <div class="input-group">
                  <input type="text" readonly
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                         class="form-control" name="greenHouseGasUnit"
                         [(ngModel)]="greenHouseGas.greenHouseGasUnit" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!totalCarbonIntensity.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,2,totalCarbonIntensity.id,totalCarbonIntensity.comments,1)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="totalCarbonIntensity.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,2,totalCarbonIntensity.id,totalCarbonIntensity.comments,1)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input type="text"
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" readonly
                         class="form-control read-only-ar-dates"
                         name="originalGreenHouseGasUnit"
                         [(ngModel)]="greenHouseGas.originalGreenHouseGasUnit" />
                  <div class="input-group-addon">
                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!totalCarbonIntensityAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,2,totalCarbonIntensityAR.id,totalCarbonIntensityAR.comments,2)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="totalCarbonIntensityAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,2,totalCarbonIntensityAR.id,totalCarbonIntensityAR.comments,2)"></i>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Scope 2 emissions(MM mtCO2e/yr)" /> -->
                <span>Carbon cost ($/BOE)</span>
              </td>
              <td [(ngModel)]="totalCarbonCost.id" hidden class="ml-10">{{totalCarbonCost.id}}</td>
              <td>
                <div class="input-group">
                  <div class="input-icon" style="display:flex;">
                    <input type="text" readonly
                          [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                          class="form-control" name="greenHouseGasCost"
                          [(ngModel)]="greenHouseGas.greenHouseGasCost" />
                    <i style="z-index: 1111;">$</i>  
                  </div> 
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!totalCarbonCost.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,3,totalCarbonCost.id,totalCarbonCost.comments,1)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="totalCarbonCost.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,3,totalCarbonCost.id,totalCarbonCost.comments,1)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <div class="input-icon" style="display:flex;">
                    <input type="text"
                          [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" readonly
                          class="form-control read-only-ar-dates"
                          name="originalGreenHouseGasCost"
                          [(ngModel)]="greenHouseGas.originalGreenHouseGasCost" />
                    <i style="z-index: 1111;">$</i>  
                  </div> 
                  <div class="input-group-addon">
                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!totalCarbonCostAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,3,totalCarbonCostAR.id,scopeTwoemissionsAR.comments,2)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="totalCarbonCostAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,3,totalCarbonCostAR.id,scopeTwoemissionsAR.comments,2)"></i>
                  </div>
                </div>
              </td>
            </tr>

            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Total emissions (MM mtCO2e/yr)" /> -->
                <span>Total emissions (MM mtCO2e/yr)</span>
              </td>
              <td [(ngModel)]="totalGHG.id" hidden class="ml-10">{{totalGHG.id}}</td>
              <td>
                <div class="input-group">
                  <input type="text" readonly
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                         class="form-control" name="greenHouseGasTotalGHGEmission"
                         [(ngModel)]="greenHouseGas.greenHouseGasTotalGHGEmission" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!totalGHG.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,4,totalGHG.id,totalGHG.comments,1)"></i>
                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="totalGHG.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,4,totalGHG.id,totalGHG.comments,1)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input type="text"
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" readonly
                         class="form-control read-only-ar-dates"
                         name="originalGreenHouseGasTotalGHGEmission"
                         [(ngModel)]="greenHouseGas.originalGreenHouseGasTotalGHGEmission" />
                  <div class="input-group-addon">
                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!totalGHGAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,4,totalGHGAR.id,totalGHGAR.comments,2)"></i>
                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="totalGHGAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,4,totalGHGAR.id,totalGHGAR.comments,2)"></i>
                  </div>
                </div>
              </td>
            </tr>

            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Scope 1 emissions(MM mtCO2e/yr)" /> -->
                <span>Scope 1 emissions(MM mtCO2e/yr)</span>
              </td>
              <td [(ngModel)]="scopeOneemissions.id" hidden class="ml-10">{{scopeOneemissions.id}}</td>
              <td>
                <div class="input-group">
                  <input type="text" readonly
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                         class="form-control" name="greenHouseGasScope1Emission"
                         [(ngModel)]="greenHouseGas.greenHouseGasScope1Emission" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!scopeOneemissions.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,5,scopeOneemissions.id,scopeOneemissions.comments,1)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="scopeOneemissions.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,5,scopeOneemissions.id,scopeOneemissions.comments,1)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input type="text"
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" readonly
                         class="form-control read-only-ar-dates"
                         name="originalGreenHouseGasScope1Emission"
                         [(ngModel)]="greenHouseGas.originalGreenHouseGasScope1Emission" />
                  <div class="input-group-addon">
                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!scopeOneemissionsAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,5,scopeOneemissionsAR.id,scopeOneemissionsAR.comments,2)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="scopeOneemissionsAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,5,scopeOneemissionsAR.id,scopeOneemissionsAR.comments,2)"></i>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Scope 2 emissions(MM mtCO2e/yr)" /> -->
                <span>Scope 2 emissions(MM mtCO2e/yr)</span>
              </td>
              <td [(ngModel)]="scopeTwoemissions.id" hidden class="ml-10">{{scopeTwoemissions.id}}</td>
              <td>
                <div class="input-group">
                  <input type="text" readonly
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                         class="form-control" name="greenHouseGasScope2Emission"
                         [(ngModel)]="greenHouseGas.greenHouseGasScope2Emission" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!scopeTwoemissions.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,6,scopeTwoemissions.id,scopeTwoemissions.comments,1)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="scopeTwoemissions.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,6,scopeTwoemissions.id,scopeTwoemissions.comments,1)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input type="text"
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" readonly
                         class="form-control read-only-ar-dates"
                         name="originalGreenHouseGasScope2Emission"
                         [(ngModel)]="greenHouseGas.originalGreenHouseGasScope2Emission" />
                  <div class="input-group-addon">
                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!scopeTwoemissionsAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,6,scopeTwoemissionsAR.id,scopeTwoemissionsAR.comments,2)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="scopeTwoemissionsAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,6,scopeTwoemissionsAR.id,scopeTwoemissionsAR.comments,2)"></i>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-xs-4">
        <table class="table table-bordered">
          <tbody>
            <tr class="table-header bg-table-default">
              <td colspan="2"></td>
              <td>Supplement</td>
              <td>Original FEED AR</td>
            </tr>
            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Total Carbon Intensity(kgCO2e/boe)" /> -->
                <span>Total Carbon Intensity(kgCO2e/boe)</span>
              </td>
              <td [(ngModel)]="totalCarbon.id" hidden class="ml-10">{{totalCarbon.id}}</td>
              <td>
                <div class="input-group">
                  <input type="text" readonly
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                         class="form-control" name="greenHouseGasTotalCarbonIntensity"
                         [(ngModel)]="greenHouseGas.greenHouseGasTotalCarbonIntensity" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!totalCarbon.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,7,totalCarbon.id,totalCarbon.comments,1)"></i>
                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="totalCarbon.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,7,totalCarbon.id,totalCarbon.comments,1)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input type="text"
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" readonly
                         class="form-control read-only-ar-dates"
                         name="originalGreenHouseGasTotalCarbonIntensity"
                         [(ngModel)]="greenHouseGas.originalGreenHouseGasTotalCarbonIntensity" />
                  <div class="input-group-addon">
                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!totalCarbonAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,7,totalCarbonAR.id,totalCarbonAR.comments,2)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="totalCarbonAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,7,totalCarbonAR.id,totalCarbonAR.comments,2)"></i>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Methane Intensity(kgCO2e/boe)" /> -->
                <span>Methane Intensity(kgCO2e/boe)</span>
              </td>
              <td [(ngModel)]="methaneIntensity.id" hidden class="ml-10">{{methaneIntensity.id}}</td>
              <td>
                <div class="input-group">
                  <input type="text" readonly
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                         class="form-control" name="greenHouseGasMethaneIntensity"
                         [(ngModel)]="greenHouseGas.greenHouseGasMethaneIntensity" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!methaneIntensity.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,8,methaneIntensity.id,methaneIntensity.comments,1)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="methaneIntensity.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,8,methaneIntensity.id,methaneIntensity.comments,1)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input type="text"
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" readonly
                         class="form-control read-only-ar-dates"
                         name="originalGreenHouseGasMethaneIntensity"
                         [(ngModel)]="greenHouseGas.originalGreenHouseGasMethaneIntensity" />
                  <div class="input-group-addon">
                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!methaneIntensityAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,8,methaneIntensityAR.id,methaneIntensityAR.comments,2)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="methaneIntensityAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,8,methaneIntensityAR.id,methaneIntensityAR.comments,2)"></i>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Flaring Intensity(kgCO2e/boe)" /> -->
                <span>Flaring Intensity(kgCO2e/boe)</span>
              </td>
              <td [(ngModel)]="flaringIntensity.id" hidden class="ml-10">{{flaringIntensity.id}}</td>
              <td>
                <div class="input-group">
                  <input type="text" readonly
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                         class="form-control" name="greenHouseGasFlaringIntensity"
                         [(ngModel)]="greenHouseGas.greenHouseGasFlaringIntensity" />
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!flaringIntensity.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,9,flaringIntensity.id,flaringIntensity.comments,1)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="flaringIntensity.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,9,flaringIntensity.id,flaringIntensity.comments,1)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input type="text"
                         [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" readonly
                         class="form-control read-only-ar-dates"
                         name="originalGreenHouseGasFlaringIntensity"
                         [(ngModel)]="greenHouseGas.originalGreenHouseGasFlaringIntensity" />
                  <div class="input-group-addon">
                    <i class="glyphicon glyphicon-comment-outline"
                       *ngIf="!flaringIntensityAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,9,flaringIntensityAR.id,flaringIntensityAR.comments,2)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="flaringIntensityAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,9,flaringIntensityAR.id,flaringIntensityAR.comments,2)"></i>
                  </div>
                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      <div class="col-xs-4">
        <table class="table table-bordered">
          <tbody>
            <tr class="table-header bg-table-default">
              <td colspan="2"></td>
              <td>Supplement</td>
              <td>Original FEED AR</td>
            </tr>
            <tr *ngIf="!features?.BeforeGreenHouseGas">
              <td colspan="2">
                <!-- <input type="text" class="form-control" value="Carbon cost ($/BOE)" /> -->
                <span>Carbon cost ($/BOE)</span>
              </td>
              <td [(ngModel)]="carbonCost.id" hidden class="ml-10">{{carbonCost.id}}</td>
              <td>
                <div class="input-group">
                  <div class="input-icon" style="display:flex;">
                    <input type="text" readonly
                          [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                          class="form-control" name="greenHouseGasCarbonCost"
                          [(ngModel)]="greenHouseGas.greenHouseGasCarbonCost" />
                    <i style="z-index: 1111;">$</i>  
                  </div> 
                  <div class="input-group-addon">

                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!carbonCost.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,10,carbonCost.id,carbonCost.comments,1)"></i>
                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="carbonCost.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,10,carbonCost.id,carbonCost.comments,1)"></i>
                  </div>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <div class="input-icon" style="display:flex;">
                    <input type="text"
                          [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel" readonly
                          class="form-control read-only-ar-dates" name="originalGreenHouseGasCarbonCost"
                         [(ngModel)]="greenHouseGas.originalGreenHouseGasCarbonCost" />
                     <i style="z-index: 1111;">$</i>  
                  </div> 
                  <div class="input-group-addon">
                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!carbonCostAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,10,carbonCostAR.id,carbonCostAR.comments,2)"></i>
                    <i class="glyphicon glyphicon-comment commentColor"
                       *ngIf="carbonCostAR.comments"
                       (click)="SaveCommentForGreenhouseGasMetricsItem(109,10,carbonCostAR.id,carbonCostAR.comments,2)"></i>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!--charts-->
  <div class="row" *ngIf="(isUpstream && !features?.BeforeGreenHouseGas) ||
    (!isUpstream && !features?.BeforeGreenHouseGas) || (isUpstream && features?.BeforeGreenHouseGas)">
    <div class="col-sm-12 col-xs-12">
      <div class="row">
        <div class="form-group col-sm-2 col-xs-2" style="margin-top: 5px;">
          <label class="control-label form-label">&nbsp;</label>
          <label class="control-label form-label">Project data shown</label>
          <span class="glyphicon glyphicon-question-sign" (click)="ShowChartModal()"></span>
        </div>
        <div class="form-group col-sm-2 col-xs-4">
          <div class="col-sm-3 col-xs-12" style="margin-top: 5px;">
            <label class="control-label form-label">From</label>
          </div>
          <div class="col-sm-9 col-xs-12" style="padding-left: 19px !important;">
            <input type="number" class="form-control" [(ngModel)]="formconfiguration.chartStartYear"
                   (change)="ChangeYears(formconfiguration)" [disabled]="isOnHold || isReadOnly" />
          </div>
        </div>
        <div class="form-group col-sm-2 col-xs-4">
          <div class="col-sm-1 col-xs-12" style="margin-top: 5px;">
            <label class="control-label form-label">To</label>
          </div>
          <div class="col-sm-9 col-xs-12">
            <input type="number" class="form-control" [(ngModel)]="formconfiguration.chartEndYear"
                   (change)="ChangeYears(formconfiguration)" [disabled]="isOnHold || isReadOnly" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="(isUpstream && !features?.BeforeGreenHouseGas) ||
    (!isUpstream && !features?.BeforeGreenHouseGas) || (isUpstream && features?.BeforeGreenHouseGas)">
    <div class="col-xs-6" *ngIf="isUpstream && features?.BeforeGreenHouseGas">
      <div class="col">
        <div class="row" style="border: 1px solid #efefef">
          <!-- <h5 style="color: black">Total emissions (MM mtCO2e/yr)</h5> -->
          <p-chart #chartEmission type="line" [data]="EmissionAndCarbonIntensityChartData" height="500"
                   [options]="multiaxisEmissionOptions"></p-chart>
        </div>
      </div>
    </div>
    <div class="col-xs-6" *ngIf="!features?.BeforeGreenHouseGas">
      <div class="col">
        <div class="row" style="border: 1px solid #efefef">
          <!-- <h5 style="color: black">Total GHG Emission And Carbon Cost</h5> -->
          <p-chart #chartGHG type="line" [data]="TotalGHGEmissionAndCarbonCostChartData"height="500"
                   [options]="multiaxisOptions"></p-chart>
        </div>
      </div>
    </div>

    <div class="col-xs-6" *ngIf="isUpstream && features?.BeforeGreenHouseGas">
      <div class="col">
        <div class="row" style="border: 1px solid #efefef">
          <!-- <h5 style="color: black">Carbon Intensity (kgCO2e/BOE)</h5> -->
          <p-chart #chartCarbon type="line" [data]="CarbonCostChartData" height="500"
                   [options]="multiaxisCarbonOptions"></p-chart>
        </div>
      </div>
    </div>

    <div class="col-xs-6" *ngIf="!features?.BeforeGreenHouseGas">
      <div class="col">
        <div class="row" style="border: 1px solid #efefef">
          <!-- <h5 style="color: black">Methane And Flaring And Total Intensity</h5> -->
          <p-chart #chartMethane type="line" [data]="MethaneAndFlaringAndTotalIntensityChartData" height="500"
                   [options]="IntensitymultiaxisOptions"></p-chart>
        </div>
      </div>
    </div>
  </div>
  <!--charts end-->
</div>

<div class="col-xs-12 form-group">
</div>
<div class="row sub-section">
  <div class="col-xs-12">
    <div class="row" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel}">
      <div class="col-xs-12">
        <span>
          <ckeditor #greenHousegaseditor [editor]="Editor" [config]="config"
                    [(ngModel)]="overview.greenHouseGasComment" id="greenHouseGasComment"
                    name="greenHouseGasComment" (ready)="onReady($event,'greenHouseGas')">
          </ckeditor>
        </span>
      </div>
    </div>
  </div>
</div>
<!-- <div class="col-xs-12 form-group">
</div> -->
<div class="col-xs-8">
  <div class="row ">
    <div class="col-xs-12">
      <strong>{{ (features?.BeforeGreenHouseGas && isUpstream && form.formType !== 'FEED Supplement') || !(features?.BeforeGreenHouseGas) ? "1.9 " :"1.8 " }} Key Commercial, Co-Owner and Government Agreements and Issues</strong>
      <span class="glyphicon glyphicon-question-sign" (click)="ShowKeyCommercialPopUp()"></span>
    </div>
  </div>
  <div class="row pl-16">
    <span>
      <mat-slide-toggle (change)="MatSliderNAEventCheck($event, 'KeyCommercial')"
                        [checked]="overview.keyComAndPartneNa"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
        <span class="app-font">N/A</span>
      </mat-slide-toggle>
    </span>
  </div>
</div>
<div class="row sub-section">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold ||
            overview.keyComAndPartneNa || isReadOnly || isReadOnlyGlobalProjectLevel}"
           *ngIf="!overview.keyComAndPartneNa">
        <span>
          <ckeditor #keycommercialeditor [editor]="Editor" [config]="config"
                    [(ngModel)]="overview.keyCommercialAndPartnerAgreements" id="keyCommercialAndPartnerAgreements"
                    name="keyCommercialAndPartnerAgreements" (ready)="onReady($event,'KeyCommercial')">
          </ckeditor>
        </span>
      </div>
    </div>
  </div>
</div>

<div class="col-xs-8">
  <div class="row ">
    <div class="col-xs-12">
      <strong>{{ (features?.BeforeGreenHouseGas && isUpstream && form.formType !== 'FEED Supplement') || !(features?.BeforeGreenHouseGas) ? "1.10 " :"1.9 " }} Environmental and Social Impact Considerations</strong>
      <span class="glyphicon glyphicon-question-sign" (click)="ShowEnvironmentalPopUp()"></span>
    </div>
  </div>
  <div class="col-xs-12">
    <i>Include highlights from ESHIA report and any other ESG considerations impacting opportunity.</i>
  </div>
  <div class="row pl-16">
    <span>
      <mat-slide-toggle (change)="MatSliderNAEventCheck($event, 'PolicyApplicable')"
                        [checked]="overview.policyAppNa"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
        <span class="app-font">N/A</span>
      </mat-slide-toggle>
    </span>
  </div>
</div>

<div class="row sub-section">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || overview.policyAppNa
                || isReadOnly || isReadOnlyGlobalProjectLevel}" *ngIf="!overview.policyAppNa">
        <span>
          <ckeditor #summaryeditor1 [editor]="Editor" [config]="config"
                    [(ngModel)]="overview.policyApplicable" id="policyApplicable" name="policyApplicable"
                    (ready)="onReady($event,'PolicyApplicable')">
          </ckeditor>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="col-xs-12 form-group">
</div>

<div class="col-xs-8">
  <div class="row">
    <div class="col-xs-12">
      <strong>{{ (features?.BeforeGreenHouseGas && isUpstream && form.formType !== 'FEED Supplement') || !(features?.BeforeGreenHouseGas) ? "1.11 " :"1.10 " }} External Announcement Status</strong>
      <span class="glyphicon glyphicon-question-sign" (click)="ShowExtAnnouncementPopUp()"></span>
    </div>
  </div>
  <div class="row pl-16">
    <span>
      <mat-slide-toggle (change)="MatSliderNAEventCheck($event, 'External')"
                        [checked]="overview.externalAnnouncementNa"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
        <span class="app-font">N/A</span>
      </mat-slide-toggle>
    </span>
  </div>
</div>
<div class="row sub-section">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || overview.externalAnnouncementNa
            || isReadOnly || isReadOnlyGlobalProjectLevel}" *ngIf="!overview.externalAnnouncementNa">
        <span>
          <ckeditor #externaleditor [editor]="Editor" [config]="config"
                    [(ngModel)]="overview.externalAnnouncementStatus" id="externalAnnouncementStatus"
                    name="externalAnnouncementStatus" (ready)="onReady($event,'External')">
          </ckeditor>
        </span>
      </div>
    </div>
  </div>
</div>
