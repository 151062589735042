import { ExecutionMilestone } from "./ExecutionMilestone.model";
import { KeyMilestone } from "./KeyMilestone.model";
import { Milestone } from "./Milestone.model";

export class ExecutionModel {
    id: number = 0;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn?: Date;
    modifiedOn?: Date;
    formId: number = 0;
    projectExecutionPlan?: string = "";
    costEstimate?: string = "";
    scheduleSensitivity?: string = "";
    projectSchedule?: string = "";
    scheduleWaterfallImage?: string = "";
    capitalAndExpendituresWaterfallImage?: string = "";
    phaseGateMilestones: Milestone[] = [];
    keyMilestones: KeyMilestone[] = [];
    executionMilestones: ExecutionMilestone[] = [];
}