import { Component } from '@angular/core';
import { Go36projectService } from '../../go36project/service/go36project.service';
import { UtilityService } from 'src/app/Shared/utility.service';
import { FormReviewerService } from 'src/app/forms/FEEDServices/form-reviewer.service';
import { CompleterService } from 'ng2-completer';
import { User, UserForAccessSecurity } from 'src/app/Model/User.model';

@Component({
  selector: 'app-ea-useraccess',
  templateUrl: './ea-useraccess.component.html',
  styleUrls: ['./ea-useraccess.component.css']
})

export class EAUseraccessComponent {
  selectedUser: any;
  filteredUsers: any = [];
  searchuser: any;
  userinfo: any = [];
  data: any;
  //editMode:boolean = false;
  objectId: string = '';
  provId: string = '';
  emailId: string = '';
  projectname: any = 'Test Project';
  name: any = '';
  fromPage: boolean = true;
  formLink: any = '/ar/request/index/';
  role: string = '';
  projectId: number = 0;
  assetid: number = 0;
  CreatedBy: string = '';
  ModifiedBy: string = '';
  UserAccessList: any = [];
  rowData: any = [];
  UserName = this.projectservice.GetuserName();
  showDelete: boolean = false;
  editMode: boolean = localStorage.getItem('editMode') == 'true';
  dataService: any;
  searchData: Array<UserForAccessSecurity> = [];
  placeholder: string = '';
  selectedUserObjectId: string = '';
  selectedUserProvId: string = '';
  searchStr: string = '';
  constructor(private projectservice: Go36projectService, public utilityService: UtilityService,private formReviewerService: FormReviewerService, private completerService: CompleterService) {
    this.projectservice.GetUserBySearch("Hasa").subscribe(res => {
      this.filteredUsers = res;
      this.dataService = completerService.local(
        this.searchData,
        'name',
        'name'
      );
      for (let i = 0; i < res.length; i++) {
        let p = new UserForAccessSecurity(i, res[i].lastName + ' ' + res[i].firstName,res[i].firstName,res[i].lastName, res[i].objectId, res[i].provId, res[i].emailId, res[i].userId);
        this.searchData.push(p);
      }
    })
    this.placeholder = "last name, first name";
    }
   

  ngOnInit() {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")));
    this.data = { projectmodel: JSON.parse(projectDetail) };
    this.GetUserAccesslist();
  }

  deleteRow(index: any) {
    let usertoRemove = this.UserAccessList[index];
    this.UserAccessList.splice(index, 1);
    this.RemoveUserAccess(usertoRemove.ObjectId,usertoRemove.ProvisioningId , usertoRemove.ProjectId, this.provId);
    this.checkadmin();
  }

  addRow(event: any) {
    let arr = {
      FirstName: event.originalObject.FirstName,
      Hidefromlist: false,
      LastName: event.originalObject.LastName,
      ProjectAdmin: false,
      ProjectOwner: false,
      ProjectMember: true,
      Readonly: false,
      ObjectId: event.originalObject.ObjectId,
      ProvisioningId: event.originalObject.ProvisioningId,
      EmailId: event.originalObject.EmailId,
      UserId: event.originalObject.UserId,
      projectid: this.data.projectmodel.ProjectId
    };

    this.UserAccessList.push(arr);

    // this.AddNewUserAccess(event.originalObject.ObjectId, event.originalObject.ProvisioningId
    //   , this.data.projectmodel.ProjectId
    //   , this.data.projectmodel.AssetClassId
    //   , this.data.projectmodel.CreatedBy
    //   , this.data.projectmodel.CreatedBy
    //   , this.role)
  }

  GetUserAccesslist() {
    if (localStorage.getItem("goProject")) {
      const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")))
      this.data = { projectmodel: JSON.parse(projectDetail) };
    }
    if (this.data.projectmodel.ID == null) {
      this.projectId = this.data.projectmodel.ProjectId;
    }
    else {
      this.projectId = this.data.projectmodel.ID;
    }
    this.projectservice.getUserAccess(this.projectId).subscribe(res => {
      this.UserAccessList = res;
      if (this.UserAccessList.length >= 1)
        this.checkadmin();
    });
    // //   if(this.UserAccessList.length >1)   
    // // {
    // //   this.showDelete=true;
    // // }
  }

  addUserToAccessList(event: any) {
    let dup = this.FilterAccessList(event.originalObject.ObjectId, event.originalObject.ProvisioningId);
    if (dup.length < 1) {
      this.role = 'member';
      this.addRow(event);
      if (localStorage.getItem("goProject")) {
        const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")))
        this.data = { projectmodel: JSON.parse(projectDetail) };
      }
      if (this.data.projectmodel.ID == null) {
        this.projectId = this.data.projectmodel.ProjectId;
      }
      else {
        this.projectId = this.data.projectmodel.ID;
      }
      this.assetid = this.data.projectmodel.AssetClassId;
      this.CreatedBy = this.data.projectmodel.CreatedBy;
      this.ModifiedBy = this.data.projectmodel.CreatedBy;

      this.AddNewUserAccess(event.originalObject.ObjectId, event.originalObject.ProvisioningId, this.projectId, this.assetid, this.CreatedBy, this.ModifiedBy, this.role);
    }
    else {
    }
  }

  FilterAccessList(objectId: any, provId: any) {
    return this.UserAccessList.filter((object: any) => {
      return object['ObjectId'] === objectId && object['ProvisioningId'] === provId;
    });
  }

  onRoleChange(event: any, item:any) {
    let target = event.target;
    this.role = target.value;
    let index = this.UserAccessList.findIndex((x: any) => x["ObjectId"] === item.ObjectId);
    this.UserAccessList[index]["ProjectOwner"] = false;

    if (this.role == "admin") {
      this.UserAccessList[index]["ProjectAdmin"] = true;
      this.UserAccessList[index]["ProjectMember"] = false;
      this.UserAccessList[index]["Readonly"] = false;
    }
    else if (this.role == "member") {
      this.UserAccessList[index]["ProjectAdmin"] = false;
      this.UserAccessList[index]["ProjectMember"] = true;
      this.UserAccessList[index]["Readonly"] = false;
    }
    else if (this.role == "readonly") {
      this.UserAccessList[index]["ProjectAdmin"] = false;
      this.UserAccessList[index]["ProjectMember"] = false;
      this.UserAccessList[index]["Readonly"] = true;
    }
    this.checkadmin();
    if (this.data.projectmodel.ID == null) {
      this.projectId = this.data.projectmodel.ProjectId;
    }
    else {
      this.projectId = this.data.projectmodel.ID;
    }
    this.ModifiedBy = this.provId;
    this.UpdateUserAccess(item.ObjectId,item.ProvisioningId, this.role, this.projectId, this.ModifiedBy);
  }

  UpdateUserAccess(objectId: string,provId: string, role: string, ProjectId: number, modifiedby: string) {
    this.projectservice.UpdateUserAccess(objectId,provId, role, ProjectId, modifiedby);
 }

 RemoveUserAccess(objectId: string,provId: string, ProjectId: number, modifiedby: string) {
    this.projectservice.RemoveUserAccess(objectId,provId, ProjectId, modifiedby);
 }

 AddNewUserAccess(objectId: string, provId: string, ProjectId: number, assetid: number, CreatedBy: string, ModifiedBy: string, role: string) {
   this.projectservice.PostUseraccess(objectId, provId, ProjectId, assetid, CreatedBy, ModifiedBy, role).subscribe(res => {
     });
 }
  checkadmin() {
    let adminList = this.UserAccessList.filter((e: any) => e.ProjectAdmin == true);
    this.UserAccessList.map((x: any) => x.Disabled = false)
    if (adminList.length === 1) {
      let index = this.UserAccessList.findIndex((x: any) => x.ObjectId == adminList[0].ObjectId);
      this.UserAccessList[index].Disabled = true;
    }
  }
  SetEditData(goproject: any) {
    localStorage.setItem('goProject', JSON.stringify(goproject));
    localStorage.setItem('editMode', 'true')
  }
}
