export class LessonLearnedType {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn: Date=new Date();
    modifiedOn: Date=new Date();
    order: number = 0;
    name?: string = '';
    description?: string = '';  
}