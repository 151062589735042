export class BasisOfEvaluation {
    keyAssumptions: KeyAssumptions[] = [];
    guidance: Guidances[] = [];
    leadership: Leaderships[] = [];
}

export class Guidances {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn?: Date;
    modifiedOn?: Date;
    order: number = 0;
    formId: number = 0;
    guidanceName: string = '';
    name?: string = '';
    dateOfIssuance?: Date;
    comment: string = '';
}

export class KeyAssumptions {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn?: Date;
    modifiedOn?: Date;
    order: number = 0;
    formId: number = 0;
    assumptionName: string = "";
    assumption?: string = "";
    comment: string = '';
    naStatus: boolean = false;
    isLastRow: boolean = false;
}

export class Leaderships {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn?: Date;
    modifiedOn?: Date;
    order: number = 0;
    formId: number = 0;
    leadershipName?: string = '';
    decisionExecutive?: string = '';
    projectLeader?: string = '';
}