import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Go36ArSupplementModel } from 'src/app/Model/Go36ArSupplement.model';
import { OldTemplateDocuments } from 'src/app/Model/OldTemplateDocuments.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { OldTemplateService } from '../FEEDServices/old-template.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-old-template-supplement',
  templateUrl: './old-template-supplement.component.html',
  styleUrls: ['./old-template-supplement.component.css']
})
export class OldTemplateSupplementComponent implements OnInit {
  formId: number = 0;
  projectId: number = 0;
  versionName: number = 0;
  projectData: any;
  projectName: string = '';
  documentList: OldTemplateDocuments[] = [];
  pdfUrl: any = "";
  supplementData = new Go36ArSupplementModel;
  emailId: string = '';
  constructor(private route: ActivatedRoute, private router: Router,
    private go36ProjectService: Go36projectService, private projectservice: Go36projectService,
    private oldTemplateService: OldTemplateService, private notifyService: NotificationService, public utilityService: UtilityService) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.route.queryParams
      .subscribe(params => {
        this.projectId = params['projectId'];
        this.formId = params['formId'];
        this.versionName = params['versionName'];
      });

    this.getProjectInfobyId();
    this.getSupplementData();
    this.LoadAttachments();
  }

  getProjectInfobyId() {
    this.oldTemplateService.GetProjectSupplementInfobyId(this.projectId, this.formId, this.versionName).subscribe(res => {
      this.projectData = res;
      if (res) {
        this.projectName = this.projectData.projectName + ' - ' + '(version #' + this.versionName + ')';
      }
    });
  }

  getSupplementData() {
    this.oldTemplateService.GetSupplementData(this.formId, this.versionName).subscribe(res => {
      this.supplementData = res;
    });
  }

  LoadAttachments() {
    this.oldTemplateService.GetSupplementDocumentList(this.formId, this.versionName).subscribe((res: any) => {
      if (res) {
        this.documentList = res;
      }
      this.notifyService.showSuccess("Data Load/Save successfully", "Status");
    });
  }

  DownloadFile(oldDocument: OldTemplateDocuments) {
    this.oldTemplateService.downloadSupplementFile(oldDocument.documentId).subscribe((data: any) => {
      var blob = new Blob([data], { type: oldDocument.docContentType });
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = oldDocument.docName;
      link.click();
    });
  }

  uploadFile = (event: any) => {
    const file = event.target.files[0];
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.oldTemplateService.UploadSupplementDocument(this.supplementData.supplementId, formData).subscribe((res: any) => {
      this.notifyService.showSuccess("File uploaded successfully.", "");

    });
  }

  DownloadSupplementPDF(header: Go36ArSupplementModel) {
    this.oldTemplateService.DownloadSupplementPDF(header.supplementId).subscribe((data: any) => {
      var blob = new Blob([data], { type: header.docContentType });
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = header.docName;
      link.click();
    });
  }

}
