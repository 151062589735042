export class RegisterUser {
    UserCai: string= '';
    Email: string= '';
    FirstName: string = '';
    LastName: string = '';
    RoleId: number = 0;
    CreatedBy: string='';
    CreatedDate: Date=new Date();
    IsActive: boolean = true;
    OpcoId: number = 0;
    SbuId: number=0;
    BuId: number=0;
    UserOrgDetails:string='';
    ObjectId:string='';
    ProvisioningId:string='';
}