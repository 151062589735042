export class Ownership {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn: Date=new Date();
    modifiedOn: Date=new Date();
    order: number = 0;
    formId: number = 0;
    ownershipName?: string = '';
    workingInterest:any;
    netRevenueInterest:any;
    isOperator: boolean = true;
    OverviewId?: number = 0;
    naStatus: boolean = false;
    comment: string = '';
    isEditable: boolean = false;
}