export class LessonLearnedDetail {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn: Date=new Date();
    modifiedOn: Date=new Date();
    order: number = 0;
    lessonLearnedTypeId: number = 0;
    formId: number = 0;
    description?: string = '';
    title?: string = '';
    projectId: number = 0;
    lessonLearnedCategoryId?: number = 0;
    formType?: string = '';
    nakeyDecision: boolean = false;  
    naLessonLearned: boolean = true;     
}