<style>
    .btn .caret {
        position: absolute;
        left: 90%;
        top: 40%;
    }

    .btn.btn-default.dropdown-toggle {
        width: 100%;
        height: 35px;
        text-align: center !important;
    }
</style>
<div class="box-container">
    <h3>Edit User Profile</h3>
    <br>
    <div class="row">
        <div class="col-md-7 col-md-offset-2">
            <div class="validation-summary-errors text-danger" data-valmsg-summary="true" *ngIf="errorMessage != ''">
                <ul>
                    <li> {{ errorMessage }}</li>
                </ul>
            </div>
        <div class="form-horizontal">
            <div class="form-group">
                <label class="control-label col-xs-2">First name</label>
                <div class="col-xs-4">
                    <input type="text" [(ngModel)]="user.firstName" class="form-control col-xs-2" >
                </div>
                <label class="control-label col-xs-2">Last name</label>
                <div class="col-xs-4">
                    <input type="text" [(ngModel)]="user.lastName" class="form-control col-xs-2" >
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-xs-2">Email</label>
                <div class="col-xs-4">
                    <input type="text" [(ngModel)]="user.emailId" class="form-control col-xs-2" >
                </div>
                <label class="control-label col-xs-2">Role</label>
                <div class="col-xs-4">
                    <select class="form-control col-xs-4" [(ngModel)]="user.roleId" readonly disabled>
                        <option *ngFor="let role of roles" [value]="role.roleId">{{role.roleDescription}}</option>
                    </select>
                </div>
            </div>
            <div id="powerUserSelection" *ngIf="powerUserSelection" class="form-group text-center">
                <br>
                <span>Power User will be granted access across all Segments's, RU's and BU's in the organization</span>
            </div>
            <div id="commonSelectionAdministrator" *ngIf="commonSelectionAdministrator" class="form-group">
                <label class="control-label col-xs-2">Segment</label>
                <div class="col-xs-4">
                    <div class="dropdown dropdown-tree" id="ddlOpcoAdministrator"></div>
                </div>
                <label class="control-label col-xs-2">RU & BU</label>
                <div class="col-xs-4">
                    <div class="dropdown dropdown-tree" id="ddlSbuBuAdministrator"></div>
                    <div class="dropdown dropdown-tree" id="ddlSbuBuHiddenAdministrator" style="display:none"></div>
                </div>
            </div>
            <div id="commonSelectionLeadership" *ngIf="commonSelectionLeadership" class="form-group">
                <label class="control-label col-xs-2">Segment</label>
                <div class="col-xs-4">
                    <div class="dropdown dropdown-tree" id="ddlOpcoLeadership"></div>
                </div>
                <label class="control-label col-xs-2">RU & BU</label>
                <div class="col-xs-4">
                    <div class="dropdown dropdown-tree" id="ddlSbuBuLeadership"></div>
                    <div class="dropdown dropdown-tree" id="ddlSbuBuHiddenLeadership" style="display:none"></div>
                </div>
            </div>
            <div id="commonSelectionConsultant" *ngIf="commonSelectionConsultant" class="form-group">
                <label class="control-label col-xs-2">Segment</label>
                <div class="col-xs-4">
                    <div class="dropdown dropdown-tree" id="ddlOpcoConsultant"></div>
                </div>
                <label class="control-label col-xs-2">RU & BU</label>
                <div class="col-xs-4">
                    <div class="dropdown dropdown-tree" id="ddlSbuBuConsultant"></div>
                    <div class="dropdown dropdown-tree" id="ddlSbuBuHiddenConsultant" style="display:none"></div>
                </div>
            </div>
            <div id="basicSelection" *ngIf="basicSelection" class="form-horizontal">
                <div class="form-group">
                    <label class="control-label col-xs-2">Segment</label>
                    <div class="col-xs-4">
                        <select class="form-control col-xs-4" [(ngModel)]="opcoId" (change)="fillSbuFilteredList($any($event.target).value)" disabled>
                            <option *ngFor="let opco of dropdowns.opco" [value]="opco.id">{{opco.name}}</option>
                        </select>
                    </div>
                    <label class="control-label col-xs-2">RU</label>
                    <div class="col-xs-4">
                        <select class="form-control col-xs-4" [(ngModel)]="sbuId" (change)="fillBuFilteredList($any($event.target).value)" disabled>
                            <option *ngFor="let sbu of sbuListFiltered" [value]="sbu.id">{{sbu.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-xs-4">BU</label>
                    <div class="col-xs-4">
                        <select class="form-control col-xs-4" [(ngModel)]="buId" disabled>
                            <option *ngFor="let bu of buListFiltered" [value]="bu.id">{{bu.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div *ngIf="!powerUserSelection" class="form-group text-center">
                <br>
                <span>Contact the administrator to update your Segment, RU and BU access.</span>
            </div>
        </div>
                <br>
                <div class="row">
                    <div class="padding-0" style="float: right;">
                        <button type="submit" (click)="updateDetails()"style="color:white !important;" class="btn btn-primary">Update</button>
                    </div>
                </div>
        </div>
    </div>
</div>
<style>
    .disable-button {
        background-color: #eee !important;
        border-color: #808080 !important;
        opacity: 1;
        cursor: not-allowed !important;
    }
</style>