import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LessonLearnedCategory } from 'src/app/Model/LessonLearnedCategory.model';
import { LessonLearnedDetail } from 'src/app/Model/LessonsLearned.model';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/Shared/notification.service';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/Modals/confirm-dialog/confirm-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ArchiveDialogComponent } from 'src/app/Modals/archive-dialog/archive-dialog.component';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { LessonsLearnedService } from '../FEEDServices/lessons-learned.service';
import { Go36ArP5LookbackHeaderModel, Go36ArP5LookbackPerformance, Go36P5LookbackDocument, Part5ViewModel } from 'src/app/Model/Part5LookBack.model';
import { Part5LookbackService } from '../FEEDServices/part5-lookback.service';
import { UtilityService } from 'src/app/Shared/utility.service';
import { FormAction } from 'src/app/Model/FormAction.model';
import { FormsLog } from 'src/app/Model/FormsLog.model';
import { User } from 'src/app/Model/User.model';
// //import { SignOffsModel } from 'src/app/Model/Signoffs.model';
import { CompleterService } from 'ng2-completer';
import { FormReviewerService } from '../FEEDServices/form-reviewer.service';
import { ApprovalDialogComponent } from 'src/app/Modals/approval-dialog/approval-dialog.component';
import { LookBackFormSignoffs } from 'src/app/Model/LookBackFormSignoffs.model';
import { environment } from 'src/environments/environment';
import { FeaturesImplemented } from 'src/app/Model/features-implemented';
import { FormStatus } from 'src/app/Model/Form.model';

import { AuthorizationService } from 'src/app/admin/service/authorization.service';



@Component({
  selector: 'app-part5-lookback',
  templateUrl: './part5-lookback.component.html',
  styleUrls: ['./part5-lookback.component.css']
})

export class Part5LookbackComponent implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [

      // 'fontfamily','fontsize','fontColor','fontBackgroundColor', '|',
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
    ],
    autosave: {
      save(editor) {
        // The saveData() function must return a promise
        // which should be resolved when the data is successfully saved.
      }
    }
  }

  ckeditorText: string = '';
  checkbox: boolean = false;
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  formId: number = 0;
  isOnHold = false;
  versionName: number = 0;
  formType: string = '';
  options = this._formBuilder.group({
    bottom: 0,
    fixed: true,
    top: 0,
  });

  LLMaxOrder: number = 0;
  KDMaxOrder: number = 0;
  showSection1: boolean = true;
  showSection2: boolean = false;
  showSection3: boolean = false;
  showSection4: boolean = false;
  showSection5: boolean = false;
  showSection6: boolean = false;
  showSection7: boolean = false;
  showSection8: boolean = false;
  showSection9: boolean = false;
  showSection10: boolean = false;
  showSection11: boolean = false;
  showSection12: boolean = false;
  showSection13: boolean = false;
  showSection14: boolean = false;

  LLCategoryData: LessonLearnedCategory[] = [];
  formLL: UntypedFormGroup;
  formKD: UntypedFormGroup;
  lessonForm: UntypedFormGroup;
  keyDecisionForm: UntypedFormGroup;
  naKeyDecisionDisabled: boolean = false;
  LLTypeData: LessonLearnedDetail[] = [];
  KeyTypeData: LessonLearnedDetail[] = [];
  PlanTypeData = new LessonLearnedDetail();
  LLSaveModel = new LessonLearnedDetail();
  LLUpdateModel = new LessonLearnedDetail();
  KDUpdateModel = new LessonLearnedDetail();
  KDSaveModel = new LessonLearnedDetail();
  PlanUpdateModel = new LessonLearnedDetail();
  projectId: number = 0;
  Part5Model = new Part5ViewModel;
  Part5Header = new Go36ArP5LookbackHeaderModel;
  Part5Performance = new Go36ArP5LookbackPerformance;
  projectData: any;
  Part5Performancedata: any;
  part5Document: Go36P5LookbackDocument[] = []
  parentData: any = undefined;
  userAccessDetails: any;
  userRole: string = '';
  features?:FeaturesImplemented;
  part5LookBackAccessList: any;
  projectName: string = '';

  // Form Reviewer Fields
  formActionModel = new FormAction();
  selectedUser: any;
  filteredUsers: any = [];
  searchuser: any;
  userinfo: any = [];
  data: any;
  name: any = '';
  fromPage: boolean = true;
  formLink: any = '/ar/request/index/';
  role: string = '';
  ProjectId: number = 0;
  reviewerData: FormAction[] = [];
  rowData: any = [];
  LoggedInUser: string = '';
  UserName = this.projectservice.GetuserName();
  showDelete: boolean = false;
  editMode: boolean = localStorage.getItem('editMode') == 'true';
  AreMultipleAdmins: boolean = true;
  @Input() isReadOnly: boolean = false;
  @Input() formsLogData: FormsLog[] = [];
  reviewerCount: boolean = false;
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;
  @Input() formReviewData: any = null;
  dataService: any;
  searchStr: string = '';
  placeholder: string = '';
  searchData: Array<User> = [];
  href: string = "";
  bUrl: string = "";
  signOffData: LookBackFormSignoffs[] = [];
  baseUrl: any = environment.apiBaseUrl;
  pdfUrl: any = "";
  isPowerUser?:boolean = false;
  selectedUserObjectId: string = '';
  selectedUserProvId: string = '';
  sendBackLockByFormState:boolean = false;
  forReviewLockByFormState:boolean = false;
  concurLockByFormState:boolean = false;

  constructor(private _formBuilder: UntypedFormBuilder, private lessonLearnedService: LessonsLearnedService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute, private router: Router,
    private part5lookbackService: Part5LookbackService,
    private projectservice: Go36projectService,
    private notifyService: NotificationService,
    private go36ProjectService: Go36projectService,
    public dialog: MatDialog, public utilityService: UtilityService,
    private formReviewerService: FormReviewerService,
    private completerService: CompleterService,
    private userRoleService: AuthorizationService) {

    this.formLL = this.fb.group({
      lessonsLearned: this.fb.array([])
    })
    this.lessonForm = this.fb.group({
    })
    this.formKD = this.fb.group({
      keyDecision: this.fb.array([])
    })
    this.keyDecisionForm = this.fb.group({
    })

    this.formReviewerService.GetUserBySearch("Hasa").subscribe(res => {
      this.filteredUsers = res;
      this.dataService = completerService.local(
        this.searchData,
        'name',
        'name'
      );
      for (let i = 0; i < res.length; i++) {
        let p = new User(i, res[i].lastName + ' ' + res[i].firstName, res[i].objectId, res[i].provId);
        this.searchData.push(p);
      }

    })
    this.placeholder = "last name, first name";
  }

  ngOnInit(): void {
    this.isPowerUser = this.userRoleService.isPowerUser();
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.route.queryParams
      .subscribe(params => {
        this.projectId = params['projectId'];
        this.formId = params['formId'];
        this.versionName = params['versionName'];
        this.formType = params['formType']
      });
    this.getProjectInfobyId();
    this.LoadPart5LookBack();

    // Form Reviewer
    const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")));
    this.data = { goprojectmodel: JSON.parse(projectDetail) };
    this.href = this.router.url;
    this.bUrl = window.location.origin;
  }

  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }

  ClearLLData() {
    this.LLTypeData = [];
    this.KeyTypeData = [];
    this.clearFormArray(this.lessonsLearned);
    this.clearFormArray(this.keyDecision);
  }

  setGetFormStatus(formStatus:number){
    if(formStatus == FormStatus.FORM_DRAFT_STATE || formStatus == FormStatus.FORM_REVISION_STATE){
      this.forReviewLockByFormState = false;
      this.sendBackLockByFormState = true;
      this.concurLockByFormState = true;
    }else if(formStatus == FormStatus.FORM_REVIEW_STATE){
      this.forReviewLockByFormState = true;
      this.sendBackLockByFormState = false;
      this.concurLockByFormState = false;
    }else{
      this.forReviewLockByFormState = false;
      this.sendBackLockByFormState = false;
      this.concurLockByFormState = false;
    }
  }

  performFormAction(action:string){
    let formActionModel = new FormAction();
    formActionModel.projectId = this.projectId;
    formActionModel.formId = this.formId;
    formActionModel.versionId = this.versionName;
    formActionModel.action = action;
    formActionModel.formType = "AR_LOOKBACK_PART5";
    formActionModel.loggedInUser = this.provId;
    this.formReviewerService.processFormAction(formActionModel).subscribe(res => {
      //getting form data again on successful for-action
      this.getProjectInfobyId();
      this.LoadPart5LookBack();
    })
  }

  getProjectInfobyId() {
    this.projectservice.GetProjectInfobyId(this.projectId).subscribe(res => {
      this.projectData = res;
      if (this.projectData != null || this.projectData != undefined) {
        this.projectName = this.projectData.buName + ' - ' + ' ' + this.projectData.projectName + ' - ' + '(version #' + this.versionName + ')';
      }
    });
  }

  LoadPart5LookBack() {
    this.part5lookbackService.GetPart5LookBack(this.formId, this.versionName, this.objectId, this.provId).subscribe((res: any) => {

      if (res) {
        this.Part5Model = res;
        this.Part5Header = res.go36ArP5LookbackHeaders;
        // //this.projectName = this.projectData.buName + ' - ' + ' ' + this.projectData.projectName + ' - ' + '(version #' + this.Part5Header.version + ')';


        this.setGetFormStatus(this.Part5Header.statusId);

        if (this.Part5Header != null &&  this.Part5Header.cvxShare != null) {
          this.Part5Header.cvxShare = Number(this.Part5Header.cvxShare).toString();
          this.Part5Header.cvxShare =  this.Part5Header.cvxShare + '%';
        }
        this.Part5Performance = res.go36ArP5LookbackPerformance;
        this.LLTypeData = res.lessonLearned;
        this.part5Document = res.go36ArP5LookbackDocument;

        this.features = res.go36ArP5LookbackHeaders.features;

        this.Part5Performance.fidArEv = Number(this.Part5Performance.fidArEv).toFixed(1).toString();
        this.Part5Performance.fidArActualOutcomes = Number(this.Part5Performance.fidArActualOutcomes).toFixed(1).toString();
        this.Part5Performance.keyPerformanceDriversEv = Number(this.Part5Performance.keyPerformanceDriversEv).toFixed(1).toString();
        this.Part5Performance.keyPerformanceDriversP10 = Number(this.Part5Performance.keyPerformanceDriversP10).toFixed(1).toString();
        this.Part5Performance.keyPerformanceDriversP90 = Number(this.Part5Performance.keyPerformanceDriversP90).toFixed(1).toString();
        this.Part5Performance.keyPerformanceDriversActualOutcomes = Number(this.Part5Performance.keyPerformanceDriversActualOutcomes).toFixed(1).toString();
        // //this.Part5Performance.keyPerformanceDriversUnit2 = Number(this.Part5Performance.keyPerformanceDriversUnit2).toFixed(1).toString();
        this.Part5Performance.keyPerformanceDriversEV1 = Number(this.Part5Performance.keyPerformanceDriversEV1).toFixed(1).toString();
        this.Part5Performance.keyPerformanceDriversP101 = Number(this.Part5Performance.keyPerformanceDriversP101).toFixed(1).toString();
        this.Part5Performance.keyPerformanceDriversP901 = Number(this.Part5Performance.keyPerformanceDriversP901).toFixed(1).toString();
        this.Part5Performance.keyPerformanceDriversActualOutcomes1 = Number(this.Part5Performance.keyPerformanceDriversActualOutcomes1).toFixed(1).toString();
        this.Part5Performance.dpiFeedEv = Number(this.Part5Performance.dpiFeedEv).toFixed(2).toString();
        this.Part5Performance.dpiFeedActualOutcomes = Number(this.Part5Performance.dpiFeedActualOutcomes).toFixed(2).toString();
        this.Part5Performance.dpiFeedP10 = Number(this.Part5Performance.dpiFeedP10).toFixed(2).toString();
        this.Part5Performance.dpiFeedP90 = Number(this.Part5Performance.dpiFeedP90).toFixed(2).toString();
        this.Part5Performance.dpiFullEv = Number(this.Part5Performance.dpiFullEv).toFixed(2).toString();
        this.Part5Performance.dpiFullP10 = Number(this.Part5Performance.dpiFullP10).toFixed(2).toString();
        this.Part5Performance.dpiFullNormalized = Number(this.Part5Performance.dpiFullNormalized).toFixed(2).toString();
        this.Part5Performance.dpiFullActualOutcomes = Number(this.Part5Performance.dpiFullActualOutcomes).toFixed(2).toString();
        this.Part5Performance.dpiFeedNormalized = Number(this.Part5Performance.dpiFeedNormalized).toFixed(2).toString();
        this.Part5Performance.dpiFullP90 = Number(this.Part5Performance.dpiFullP90).toFixed(2).toString();
        if (this.Part5Performance != null &&  this.Part5Performance.rorFeedEv != null) {
          this.Part5Performance.rorFeedEv = Number(this.Part5Performance.rorFeedEv).toFixed(1).toString();
          this.Part5Performance.rorFeedEv =  this.Part5Performance.rorFeedEv + '%';
        }
        if (this.Part5Performance != null &&  this.Part5Performance.rorFeedP10 != null) {
          this.Part5Performance.rorFeedP10 = Number(this.Part5Performance.rorFeedP10).toFixed(1).toString();
          this.Part5Performance.rorFeedP10 =  this.Part5Performance.rorFeedP10 + '%';
        }
        if (this.Part5Performance != null &&  this.Part5Performance.rorFeedP90 != null) {
          this.Part5Performance.rorFeedP90 = Number(this.Part5Performance.rorFeedP90).toFixed(1).toString();
          this.Part5Performance.rorFeedP90 =  this.Part5Performance.rorFeedP90 + '%';
        }
        if (this.Part5Performance != null &&  this.Part5Performance.rorFeedNormalized != null) {
          this.Part5Performance.rorFeedNormalized = Number(this.Part5Performance.rorFeedNormalized).toFixed(1).toString();
          this.Part5Performance.rorFeedNormalized =  this.Part5Performance.rorFeedNormalized + '%';
        }
        if (this.Part5Performance != null &&  this.Part5Performance.rorFeedActualOutcomes != null) {
          this.Part5Performance.rorFeedActualOutcomes = Number(this.Part5Performance.rorFeedActualOutcomes).toFixed(1).toString();
          this.Part5Performance.rorFeedActualOutcomes =  this.Part5Performance.rorFeedActualOutcomes + '%';
        }
        if (this.Part5Performance != null &&  this.Part5Performance.rorFullEv != null) {
          this.Part5Performance.rorFullEv = Number(this.Part5Performance.rorFullEv).toFixed(1).toString();
          this.Part5Performance.rorFullEv =  this.Part5Performance.rorFullEv + '%';
        }
        if (this.Part5Performance != null &&  this.Part5Performance.rorFullP10 != null) {
          this.Part5Performance.rorFullP10 = Number(this.Part5Performance.rorFullP10).toFixed(1).toString();
          this.Part5Performance.rorFullP10 =  this.Part5Performance.rorFullP10 + '%';
        }
        if (this.Part5Performance != null &&  this.Part5Performance.rorFullP90 != null) {
          this.Part5Performance.rorFullP90 = Number(this.Part5Performance.rorFullP90).toFixed(1).toString();
          this.Part5Performance.rorFullP90 =  this.Part5Performance.rorFullP90 + '%';
        }
        if (this.Part5Performance != null &&  this.Part5Performance.rorFullNormalized != null) {
          this.Part5Performance.rorFullNormalized = Number(this.Part5Performance.rorFullNormalized).toFixed(1).toString();
          this.Part5Performance.rorFullNormalized =  this.Part5Performance.rorFullNormalized + '%';
        }
        if (this.Part5Performance != null &&  this.Part5Performance.rorFullActualOutcomes != null) {
          this.Part5Performance.rorFullActualOutcomes = Number(this.Part5Performance.rorFullActualOutcomes).toFixed(1).toString();
          this.Part5Performance.rorFullActualOutcomes =  this.Part5Performance.rorFullActualOutcomes + '%';
        }
        if (this.Part5Performance != null &&  this.Part5Performance.rorFullTestCase != null) {
          this.Part5Performance.rorFullTestCase = Number(this.Part5Performance.rorFullTestCase).toFixed(1).toString();
          this.Part5Performance.rorFullTestCase =  this.Part5Performance.rorFullTestCase + '%';
        }
        this.Part5Performance.npvFeedActualOutcomes = Number(this.Part5Performance.npvFeedActualOutcomes).toFixed(1).toString();
        this.Part5Performance.npvFeedEv = Number(this.Part5Performance.npvFeedEv).toFixed(1).toString();
        this.Part5Performance.npvFeedP10 = Number(this.Part5Performance.npvFeedP10).toFixed(1).toString();
        this.Part5Performance.npvFeedP90 = Number(this.Part5Performance.npvFeedP90).toFixed(1).toString();
        this.Part5Performance.npvFeedNormalized = Number(this.Part5Performance.npvFeedNormalized).toFixed(1).toString();
        this.Part5Performance.npvFullEv = Number(this.Part5Performance.npvFullEv).toFixed(1).toString();
        this.Part5Performance.npvFullP10 = Number(this.Part5Performance.npvFullP10).toFixed(1).toString();
        this.Part5Performance.npvFullP90 = Number(this.Part5Performance.npvFullP90).toFixed(1).toString();
        this.Part5Performance.npvFullActualOutcomes = Number(this.Part5Performance.npvFullActualOutcomes).toFixed(1).toString();
        this.Part5Performance.npvFullNormalized = Number(this.Part5Performance.npvFullNormalized).toFixed(1).toString();
        this.Part5Performance.ceFeedEv = Number(this.Part5Performance.ceFeedEv).toFixed(1).toString();
        this.Part5Performance.ceFeedP10 = Number(this.Part5Performance.ceFeedP10).toFixed(1).toString();
        this.Part5Performance.ceFeedP90 = Number(this.Part5Performance.ceFeedP90).toFixed(1).toString();
        this.Part5Performance.ceFeedNormalized = Number(this.Part5Performance.ceFeedNormalized).toFixed(1).toString();
        this.Part5Performance.ceFeedActualOutcomes = Number(this.Part5Performance.ceFeedActualOutcomes).toFixed(1).toString();
        this.Part5Performance.ceFullEv = Number(this.Part5Performance.ceFullEv).toFixed(1).toString();
        this.Part5Performance.ceFullP10 = Number(this.Part5Performance.ceFullP10).toFixed(1).toString();
        this.Part5Performance.ceFullP90 = Number(this.Part5Performance.ceFullP90).toFixed(1).toString();
        this.Part5Performance.ceFullActualOutcomes = Number(this.Part5Performance.ceFullActualOutcomes).toFixed(1).toString();
        this.Part5Performance.ceFullNormalized = Number(this.Part5Performance.ceFullNormalized).toFixed(1).toString();
        this.Part5Performance.ceFullTestCase = Number(this.Part5Performance.ceFullTestCase).toFixed(1).toString();

      //  this.signOffData = res.lookBackFormSignoff;
        this.reviewerData = res.formActionList;
        this.formsLogData = res.formLog;
        this.pdfUrl = `${this.baseUrl}api/LookbackPart5/GetPart5PDF?projectId=${this.projectId}&formId=${this.formId}&version=${this.versionName}&objectId=${this.objectId}&provId=${this.provId}`;

        if (this.reviewerData?.length != 0) {
          this.reviewerCount = true;
        }

        this.GetUserRoleFromLocalStorage();
        let map = new Map<string, string>();
        map.set("objectId", this.objectId);
        map.set("provId", this.provId);
        map.set("emailId", this.emailId);
        map.set("projectId", this.Part5Header.projectId.toString());
        map.set("originalId", this.Part5Header.originalId.toString());
        map.set("formStatus", String(this.Part5Header.statusId));
        map.set("formVersion", String(this.Part5Header.version));
        map.set("formVersionName", String(this.Part5Header.versionName));
        map.set("formIsReadOnly", String(this.Part5Header.isReadOnly));
        map.set("formIsOnHold", String(this.Part5Header.isOnHold));
        map.set("formCreatedBy", this.Part5Header.createdBy);
        map.set("formCreatedOn", String(this.Part5Header.createdDateTime));
        map.set("userRole", this.userRole);
        map.set("part4LookBack", "false");
        map.set("part5LookBack", "true");

        let jsonObject = {};
        map.forEach((value, key) => { jsonObject[key] = value; });
        this.projectservice.GetUserAccessListForLookBackForms(JSON.stringify(jsonObject)).subscribe((res: any) => {
          if (res) { this.part5LookBackAccessList = res; 

            // Making Form Editable for Power User.
            if(this.isPowerUser && this.projectData.IsSampleForm){
              this.Part5Header.isReadOnly = false;
              this.part5LookBackAccessList.data.isLocked = false;
            }

            if(!this.part5LookBackAccessList?.data?.canViewProject){
              this.router.navigate(["errorpage"]);
            }
          }
        });

        this.parentData = {
          isOnHold: this.isOnHold,

          ssbodId: res.go36ArP5LookbackHeaders.subsurfaceBasisOfDesignId,
          formType: "AR_LOOKBACK_PART5",
          projectId: this.projectId,
          fidId: res.go36ArP5LookbackHeaders.arP5LookbackId,
          isReadOnly: res.go36ArP5LookbackHeaders.isReadOnly,
        }
      }
      this.notifyService.showSuccess("Data Load/Save successfully", "Status");

    });
    // // this.LoadLLCategoryData();
    // // this.LoadLLData();
    // // this.LoadPlanData();
    // // this.LoadKeyData();
  }
  LoadLessonLearnedData() {
    this.LoadLLCategoryData();
    this.LoadLLData();
    this.LoadPlanData();
    this.LoadKeyData();
  }
  showSection(sectionNumber: number) {
    this.showSection1 = this.showSection3 = this.showSection4 = this.showSection5 = this.showSection6 =
      this.showSection7 = this.showSection8 = this.showSection9 = this.showSection10 = this.showSection11 =
      this.showSection12 = this.showSection13 = this.showSection14 = false;

    if (sectionNumber == 1) {
      this.showSection1 = true;
    }
    else if (sectionNumber == 3) {
      this.showSection3 = true;
      this.ClearLLData();
      this.LoadLessonLearnedData();
    }
    else if (sectionNumber == 4) {
      this.showSection4 = true;
    }
    else if (sectionNumber == 5) {
      this.showSection5 = true;
    }
    else if (sectionNumber == 6) {
      this.showSection6 = true;
    }
    else if (sectionNumber == 7) {
      this.showSection7 = true;
    }
    else if (sectionNumber == 8) {
      this.showSection8 = true;
    }
    else if (sectionNumber == 9) {
      this.showSection9 = true;
    }
    else if (sectionNumber == 10) {
      this.showSection10 = true;
    }
    else if (sectionNumber == 11) {
      this.showSection11 = true;
    }
    else if (sectionNumber == 12) {
      this.showSection12 = true;
    }
    else if (sectionNumber == 13) {
      this.showSection13 = true;
    }
    else if (sectionNumber == 14) {
      this.showSection14 = true;
      this.getSignData();
    }
  }

  ShowPlanModal() {
    const message = "It is extremely relevant to capture information and lessons learned but if these are not shared within the BU or across SBUs and OPCOs, their potential value is not maximized." +
      "<br><br> This field is reserved for the Project Team to share what actions have already be taken and the ones that will be taken to share the Lessons Learned and Key Insights above with the rest of the Chevron organization."
    const dialogData = new InfoDialogModel("Plan", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }

  ShowKeyDecisionsModal() {
    const message = "Throughout the lifecycle of the project, there are several decisions that are taken and registered into the project’s Decision Register. Some of these decisions are critical for the outcome of the project as well as to enable the project lookback to be performed using the appropriate amount of information." +
      "<br><br> These fields are to be utilized to capture the key Decision that were taken recently during the project Phase and led to the current status of the project or the request being made." +
      "<br><br> Use these fields to share the more relevant and the strategical decisions that influenced the opportunity being pursued, alternative selected, execution strategy and any other project team feel relevant."
    const dialogData = new InfoDialogModel("Key Decisions", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }

  UpdateLLCkeditorChanges(event: string, order: any, type: any) {
    const value = event;
    if (type == "LessonLearned") {
      this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
      if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && (value.trim().length > 0 || this.LLUpdateModel.id > 0)) {
        this.LLUpdateModel.modifiedBy = this.provId;
        this.LLUpdateModel.description = value;
        this.UpdateLLData(this.LLUpdateModel);
      }
    }
    else if (type == "Plan") {
      if (this.PlanTypeData != undefined && this.PlanTypeData != null && (value.trim().length > 0 || this.PlanTypeData.id > 0)) {
        this.PlanTypeData.modifiedBy = this.provId;
        this.PlanTypeData.description = value;
        this.UpdateLLData(this.PlanTypeData);
      }
    }
    else if (type == "KeyDecision") {
      this.KDUpdateModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
      if (this.KDUpdateModel != undefined && this.KDUpdateModel != null && this.KDUpdateModel.id > 0) {
        this.KDUpdateModel.modifiedBy = this.provId;
        this.KDUpdateModel.description = value;
        this.UpdateLLData(this.KDUpdateModel);
      }
      else {
        this.KDSaveModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
        this.KDSaveModel.description = value;
        if (value.trim().length != 0) {
          this.KDSaveModel.order = this.KDMaxOrder + 1;
          this.SaveLLData(this.KDSaveModel);
        }
      }
    }
  }

  UpdateckeditorChanges(event: string, textName: string) {
    this.ckeditorText = event;
    this.Part5Header.modifiedBy = this.provId;

    if (textName == 'section1-2') {
      // //this.Part5Header.projectCompletionSummary = this.ckeditorText;
    }
    else if (textName == 'projectCompletionSummary') {
      // //this.businessCase.primaryBusinessCase = this.ckeditorText;
    }
    else if (textName == 'section8') {
      this.Part5Header.operationalResults = this.ckeditorText;
    }
    else if (textName == 'section9') {
      this.Part5Header.commercialAgreementResults = this.ckeditorText;
    }

    //Section 5
    else if (textName == 'asset') {
      this.Part5Header.defintionAsset = this.ckeditorText;
    }
    else if (textName == 'FD') {
      this.Part5Header.definitionFacilities = this.ckeditorText;
    }
    else if (textName == 'WD') {
      this.Part5Header.definitionWells = this.ckeditorText;
    }
    else if (textName == 'RD') {
      this.Part5Header.definitionReservoir = this.ckeditorText;
    }

    this.UpdatePart5LookBackHeaderData(this.Part5Header);
  }

  ShowLessonLearnedModal() {
    const message = "Through the lifecycle of the project, there are several opportunities to capture leanings that can be valuable for future projects. These fields are to be utilized to capture Lessons Learned / Key Insights that were identified in a certain Phase of the project." +
      "<br><br> Majority of Lessons Learned are usually more technical or tactical and they are captured in the project’s Lessons Learned Register / iPIMS" +
      "<br><br> Use these fields to share the more relevant and the strategical learnings that can either have the potential to be incorporated in the future into Chevron Project Management IP or learnings that are more strategic in their nature, and valuable for future generations of projects to come"
    const dialogData = new InfoDialogModel("Lesson Learned", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }

  RemoveOnCategoryChange(order: any) {
    this.lessonsLearned.controls[order - 1].get("title")?.setValue("");
    this.lessonsLearned.controls[order - 1].get("description")?.setValue("");
    this.lessonsLearned.controls[order - 1].get("title")?.disable();
    this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
    this.LLUpdateModel.naLessonLearned = true;
    this.LLUpdateModel.title = "";
    this.LLUpdateModel.description = "";
    this.LLUpdateModel.lessonLearnedCategoryId = undefined;
    this.UpdateLLData(this.LLUpdateModel);
  }

  UpdateLLData(UpdateModel: LessonLearnedDetail) {
    // //UpdateModel.formType = this.form.formType;
    this.lessonLearnedService.saveLessonsLearnedData(UpdateModel).subscribe(res => {
      if (UpdateModel.lessonLearnedTypeId == 1) {
        this.clearFormArray(this.lessonsLearned);
        this.LoadLLData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      if (UpdateModel.lessonLearnedTypeId == 3) {
        this.clearFormArray(this.keyDecision);
        this.LoadKeyData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      if (UpdateModel.lessonLearnedTypeId == 2) {
        this.LoadPlanData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    })
  }

  clearFormArray = (formArray: UntypedFormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  LoadLLData() {
    this.lessonLearnedService.getLessonLearnedData(this.Part5Header.arP5LookbackId, this.projectId, 1).subscribe(res => {
      this.LLTypeData = res as LessonLearnedDetail[];
      this.lessonLearnedService.getLLCategoryData().subscribe((res: LessonLearnedCategory[]) => {
        this.LLCategoryData = res;
        if (this.LLTypeData.length > 0) {
          this.LLTypeData.forEach((element, index) => {
            let formLLNew = this.fb.group({
              lessonLearnedCategoryId: (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) ? -1 : element.lessonLearnedCategoryId,
              title: element.title,
              description: element.description
            });
            this.lessonsLearned.push(formLLNew);
            this.LLMaxOrder = element.order;
            if (this.LLTypeData[index].lessonLearnedCategoryId == undefined || this.LLTypeData[index].lessonLearnedCategoryId == null) {
              this.LLTypeData[index].lessonLearnedCategoryId = -1;
              this.lessonsLearned.controls[index].get("title")?.disable();
              this.LLTypeData[index].naLessonLearned = true;
            } else
              this.LLTypeData[index].naLessonLearned = false;
          });
        }
        else {
          this.LLDataIntialization(1);
          let formLLNew = this.fb.group({
            lessonLearnedCategoryId: this.LLTypeData[0].lessonLearnedCategoryId,
            title: this.LLTypeData[0].title,
            description: this.LLTypeData[0].description
          });
          this.lessonsLearned.push(formLLNew);
          this.getMaxOrder(1);
          this.lessonsLearned.controls[0].get("title")?.disable();
        }
      });
    });
  }
  NAKeyDecisionChange(event: MatSlideToggleChange) {
    this.KDUpdateModel = this.KeyTypeData[0];
    if (this.KDUpdateModel != undefined && this.KDUpdateModel != null) {
      this.KDUpdateModel.modifiedBy = this.provId;
      this.KDUpdateModel.nakeyDecision = event.checked;
      this.UpdateLLData(this.KDUpdateModel);
    }
  }
  LoadPlanData() {
    this.lessonLearnedService.getLessonLearnedData(this.Part5Header.arP5LookbackId, this.projectId, 2).subscribe(res => {
      this.PlanTypeData = res[0] as LessonLearnedDetail;
      if (this.PlanTypeData == undefined && this.PlanTypeData == null)
        this.PlanDataIntialization();
    })
  }

  LoadKeyData() {
    this.lessonLearnedService.getLessonLearnedData(this.Part5Header.arP5LookbackId, this.projectId, 3).subscribe(res => {
      this.KeyTypeData = res as LessonLearnedDetail[];
      if (this.KeyTypeData.length > 0) {
        if (this.KeyTypeData[0].nakeyDecision)
          this.naKeyDecisionDisabled = true;
        else
          this.naKeyDecisionDisabled = false;
        this.KeyTypeData.forEach(element => {
          let formKDNew = this.fb.group({
            title: element.title,
            description: element.description
          });
          this.keyDecision.push(formKDNew);
          this.KDMaxOrder = element.order;
        });
      }
      else {
        this.KeyDataIntialization(1);
        if (this.KeyTypeData[0].nakeyDecision)
          this.naKeyDecisionDisabled = true;
        else
          this.naKeyDecisionDisabled = false;
        let formKDNew = this.fb.group({
          title: this.KeyTypeData[0].title,
          description: this.KeyTypeData[0].description
        });
        this.keyDecision.push(formKDNew);
        this.getMaxOrder(3);
      }
    })
  }

  getMaxOrder(typeId: any) {
    this.lessonLearnedService.getMaxOrder(this.Part5Header.arP5LookbackId, this.projectId, typeId).subscribe(res => {
      if (typeId == 1)
        this.LLMaxOrder = res;
      else if (typeId == 3)
        this.KDMaxOrder = res;
    });
  }

  onCategoryChange(event: any, order: any, control: UntypedFormControl) {
    const value = event.target.value;
    this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order && x.active == true)[0];
    if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && this.LLUpdateModel.id > 0) {
      this.LLUpdateModel.modifiedBy = this.provId;
      this.LLUpdateModel.lessonLearnedCategoryId = value;
      if (value <= -1)
        this.RemoveOnCategoryChange(order);
      else {
        this.lessonsLearned.controls[order - 1].get("title")?.enable();
        this.LLUpdateModel.naLessonLearned = false;
        this.UpdateLLData(this.LLUpdateModel);
      }
    }
    else {
      this.LLSaveModel = this.LLTypeData.filter(x => x.order == order && x.active == true)[0];
      this.LLSaveModel.lessonLearnedCategoryId = value;
      if (value <= -1) {
        this.lessonsLearned.controls[order - 1].get("title")?.disable();
        this.LLSaveModel.naLessonLearned = true;
      }
      else {
        this.lessonsLearned.controls[order - 1].get("title")?.enable();
        this.LLSaveModel.naLessonLearned = false;
        this.LLSaveModel.order = this.LLMaxOrder + 1;
        this.SaveLLData(this.LLSaveModel);
      }
    }
  }

  SaveLLData(SaveModel: LessonLearnedDetail) {
    // // SaveModel.formType = this.form.formType;
    this.lessonLearnedService.saveLessonsLearnedData(SaveModel).subscribe(res => {
      if (SaveModel.lessonLearnedTypeId == 1) {
        this.clearFormArray(this.lessonsLearned);
        this.LoadLLData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      else if (SaveModel.lessonLearnedTypeId == 3) {
        this.clearFormArray(this.keyDecision);
        this.LoadKeyData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    })
  }

  LoadLLCategoryData() {
    this.lessonLearnedService.getLLCategoryData().subscribe((res: LessonLearnedCategory[]) => {
      this.LLCategoryData = res;
      ;
    });
  }

  onBlurTitleUpdate(event: any, order: any, type: any) {
    const value = event.target.value;
    if (type == "LessonLearned") {
      this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
      if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && (value.trim().length != 0 || this.LLUpdateModel.id > 0)) {
        this.LLUpdateModel.modifiedBy = this.provId;
        this.LLUpdateModel.title = value;
        this.UpdateLLData(this.LLUpdateModel);
      }
    }
    else if (type == "KeyDecision") {
      this.KDUpdateModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
      if (this.KDUpdateModel != undefined && this.KDUpdateModel != null && this.KDUpdateModel.id > 0) {
        this.KDUpdateModel.modifiedBy = this.provId;
        this.KDUpdateModel.title = value;
        this.UpdateLLData(this.KDUpdateModel);
      }
      else {
        this.KDSaveModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
        this.KDSaveModel.title = value;
        if (value.trim().length != 0) {
          this.KDSaveModel.order = this.KDMaxOrder + 1;
          this.SaveLLData(this.KDSaveModel);
        }
      }
    }
  }

  get lessonsLearned(): any {
    return this.formLL.get('lessonsLearned') as UntypedFormArray;
  }

  DeleteLLData(DeleteModel: LessonLearnedDetail) {
    this.lessonLearnedService.saveLessonsLearnedData(DeleteModel).subscribe(res => {
      if (DeleteModel.lessonLearnedTypeId == 1) {
        this.getMaxOrder(1);
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      else if (DeleteModel.lessonLearnedTypeId == 3) {
        this.getMaxOrder(3);
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    })
  }

  DeleteEmptyLLTypeData(order: any) {
    this.clearFormArray(this.lessonsLearned);
    this.LLTypeData.forEach((element, index) => {
      if (index > order - 1) {
        element.order = element.order - 1;
        if (element.id > 0) {
          if (element.lessonLearnedCategoryId == -1) { element.lessonLearnedCategoryId = undefined; }
          this.DeleteLLData(element);
          if (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) { element.lessonLearnedCategoryId = -1; }
        }
      }
      if (index == order - 1 && element.id > 0) {
        element.active = false;
        if (element.lessonLearnedCategoryId == -1) { element.lessonLearnedCategoryId = undefined; }
        this.DeleteLLData(element);
        if (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) { element.lessonLearnedCategoryId = -1; }
      }
      if (index != order - 1) {
        let formLLNew = this.fb.group({
          lessonLearnedCategoryId: (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) ? -1 : element.lessonLearnedCategoryId,
          title: element.title,
          description: element.description
        });
        this.lessonsLearned.push(formLLNew);
      }
    });
  }

  DeleteLLTypeData(order: any) {
    this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
    if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && this.LLUpdateModel.lessonLearnedCategoryId != -1) {
      this.LLUpdateModel.active = false;
      this.DeleteLLData(this.LLUpdateModel);
      this.clearFormArray(this.lessonsLearned);
      this.LLTypeData.forEach((element, index) => {
        if (index > order - 1) {
          element.order = element.order - 1;
          if (element.id > 0) {
            if (element.lessonLearnedCategoryId == -1) { element.lessonLearnedCategoryId = undefined; }
            this.DeleteLLData(element);
            if (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) { element.lessonLearnedCategoryId = -1; }
          }
        }
        if (index != order - 1) {
          let formLLNew = this.fb.group({
            lessonLearnedCategoryId: (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) ? -1 : element.lessonLearnedCategoryId,
            title: element.title,
            description: element.description
          });
          this.lessonsLearned.push(formLLNew);
        }
      });
    }
  }

  DeleteEmptyKeyTypeData(order: any) {
    this.clearFormArray(this.keyDecision);
    this.KeyTypeData.forEach((element, index) => {
      if (index > order - 1) {
        element.order = element.order - 1;
        if (element.id > 0) {
          this.DeleteLLData(element);
        }
      }
      if (index == order - 1 && element.id > 0) {
        element.active = false;
        this.DeleteLLData(element);
      }
      if (index != order - 1) {
        let formKDNew = this.fb.group({
          title: element.title,
          description: element.description
        });
        this.keyDecision.push(formKDNew);
      }
    });
  }

  DeleteKeyTypeData(order: any) {
    this.KDUpdateModel = this.KeyTypeData.filter(x => x.order == order)[0];
    if (this.KDUpdateModel != undefined && this.KDUpdateModel != null) {
      this.KDUpdateModel.active = false;
      this.DeleteLLData(this.KDUpdateModel);
      this.clearFormArray(this.keyDecision);
      this.KeyTypeData.forEach((element, index) => {
        if (index > order - 1) {
          element.order = element.order - 1;
          this.DeleteLLData(element);
        }
        if (index != order - 1) {
          let formKDNew = this.fb.group({
            title: element.title,
            description: element.description
          });
          this.keyDecision.push(formKDNew);
        }
      });
    }
  }

  Delete(order: any, type: any) {
    const message = 'Are you sure you want to delete this item?';
    const dialogData = new ConfirmDialogModel("Confirm item deletion", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (type == "LessonLearned") {
          if (this.LLTypeData.length >= 1 && this.LLTypeData[order - 1].lessonLearnedCategoryId == -1)
            this.DeleteEmptyLLTypeData(order);
          else if (this.LLTypeData.length >= 1 && this.LLTypeData[order - 1].lessonLearnedCategoryId != -1)
            this.DeleteLLTypeData(order);
          if (this.LLTypeData.length == 1) {
            this.LLTypeData.splice(order - 1, 1);
            this.LLDataIntialization(1);
            let formLLNew = this.fb.group({
              lessonLearnedCategoryId: this.LLTypeData[0].lessonLearnedCategoryId,
              title: this.LLTypeData[0].title,
              description: this.LLTypeData[0].description
            });
            this.lessonsLearned.push(formLLNew);
            this.lessonsLearned.controls[0].get("title")?.disable();
          }
          else if (this.LLTypeData.length > 1) {
            this.LLTypeData.splice(order - 1, 1);
            this.LLTypeData.forEach((element: any, index: any) => {
              if (element.lessonLearnedCategoryId == -1)
                this.lessonsLearned.controls[index].get("title")?.disable();
            });
          }
        }
        else if (type == "Plan") {
          if (this.PlanTypeData != undefined && this.PlanTypeData != null && (this.PlanTypeData.description?.trim().length != 0 || this.PlanTypeData.id > 0)) {
            this.PlanTypeData.active = false;
            this.UpdateLLData(this.PlanTypeData);
          }
        }
        else if (type == "KeyDecision") {
          if (this.KeyTypeData.length >= 1 && this.KeyTypeData[order - 1].description?.trim().length == 0 && this.KeyTypeData[order - 1].title?.trim().length == 0)
            this.DeleteEmptyKeyTypeData(order);
          else if (this.KeyTypeData.length >= 1 && (this.KeyTypeData[order - 1].description?.trim().length != 0 || this.KeyTypeData[order - 1].title?.trim().length != 0))
            this.DeleteKeyTypeData(order);
          if (this.KeyTypeData.length == 1) {
            this.KeyTypeData.splice(order - 1, 1);
            this.KeyDataIntialization(1);
            let formKDNew = this.fb.group({
              title: this.KeyTypeData[0].title,
              description: this.KeyTypeData[0].description
            });
            this.keyDecision.push(formKDNew);
          }
          else if (this.KeyTypeData.length > 1)
            this.KeyTypeData.splice(order - 1, 1);
        }
      }
    });
  }

  get keyDecision(): any {
    return this.formKD.get('keyDecision') as UntypedFormArray;
  }

  LLDataIntialization(order: any) {
    this.LLTypeData.push(new LessonLearnedDetail());
    this.LLTypeData[order - 1].lessonLearnedCategoryId = -1;
    this.LLTypeData[order - 1].formId = this.Part5Header.arP5LookbackId;
    this.LLTypeData[order - 1].projectId = this.projectId;
    this.LLTypeData[order - 1].lessonLearnedTypeId = 1;
    this.LLTypeData[order - 1].createdBy = this.provId;
    this.LLTypeData[order - 1].order = order;
    this.LLTypeData[order - 1].formType = "AR_LOOKBACK_PART5";
    this.LLTypeData[order - 1].title = "";
    this.LLTypeData[order - 1].description = "";
    this.LLTypeData[order - 1].naLessonLearned = true;
  }

  PlanDataIntialization() {
    this.PlanTypeData = new LessonLearnedDetail();
    this.PlanTypeData.formId = this.Part5Header.arP5LookbackId;
    this.PlanTypeData.lessonLearnedCategoryId = undefined;
    this.PlanTypeData.projectId = this.projectId;
    this.PlanTypeData.lessonLearnedTypeId = 2;
    this.PlanTypeData.createdBy = this.provId;
    this.PlanTypeData.order = 1;
    this.PlanTypeData.description = "";
    this.PlanTypeData.formType = "AR_LOOKBACK_PART5";
  }

  KeyDataIntialization(order: any) {
    this.KeyTypeData.push(new LessonLearnedDetail());
    this.KeyTypeData[order - 1].lessonLearnedCategoryId = undefined;
    this.KeyTypeData[order - 1].formId = this.Part5Header.arP5LookbackId;
    this.KeyTypeData[order - 1].projectId = this.projectId;
    this.KeyTypeData[order - 1].lessonLearnedTypeId = 3;
    this.KeyTypeData[order - 1].createdBy = this.provId;
    this.KeyTypeData[order - 1].order = order;
    this.KeyTypeData[order - 1].formType = "AR_LOOKBACK_PART5";
    this.KeyTypeData[order - 1].title = "";
    this.KeyTypeData[order - 1].description = "";
    this.KeyTypeData[order - 1].nakeyDecision = false;
  }

  addNewLL(type: any): void {
    if (type == "LessonLearned") {
      this.lessonForm = this.fb.group({
        lessonLearnedCategoryId: [-1],
        title: [''],
        description: ['']
      });
      this.LLDataIntialization(this.LLTypeData.length + 1);
      this.lessonsLearned.push(this.lessonForm);
      this.lessonsLearned.controls[this.lessonsLearned.length - 1].get("title")?.disable();
    }
    else if (type == "KeyDecision") {
      this.keyDecisionForm = this.fb.group({
        title: [''],
        description: ['']
      });
      this.KeyDataIntialization(this.KeyTypeData.length + 1);
      this.keyDecision.push(this.keyDecisionForm);
    }
  }

  UpdatePart5LookBackHeaderData(Part5Headerdata: Go36ArP5LookbackHeaderModel) {
    Part5Headerdata.modifiedBy = this.provId;
    Part5Headerdata.cvxShare = this.Part5Header.cvxShare == '' || this.Part5Header.cvxShare == null ? '' : parseFloat(Part5Headerdata.cvxShare);
    this.part5lookbackService.UpdatePart5LookBack(Part5Headerdata).subscribe(res => {
      res;
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
    if (this.Part5Header != null &&  this.Part5Header.cvxShare != null) {
      this.Part5Header.cvxShare = Number(this.Part5Header.cvxShare).toString();
      this.Part5Header.cvxShare =  this.Part5Header.cvxShare + '%';
    }
  }

  IdentifiedLookBackTypeCheck(event: any, Part5Header: Go36ArP5LookbackHeaderModel, checktype: string) {
    Part5Header.modifiedBy = this.provId;
    if (checktype == "lookbackType1") { Part5Header.lookbackType1 = event.target.checked; }
    else if (checktype == "lookbackType2") { Part5Header.lookbackType2 = event.target.checked; }
    else if (checktype == "lookbackType3") { Part5Header.lookbackType3 = event.target.checked; }
    else if (checktype == "lookbackType4") { Part5Header.lookbackType4 = event.target.checked; }
    else if (checktype == "program") { Part5Header.program = event.target.checked; }
    else if (checktype == "singleProject") { Part5Header.singleProject = event.target.checked; }
    else if (checktype == "nojv") { Part5Header.nojv = event.target.checked; }
    this.part5lookbackService.UpdatePart5LookBack(Part5Header).subscribe(res => {

      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  ShowOnHoldModal() {
    const message = "When the HOLD button is activated, the form for this project will be placed on a “Hold” status. Fields on the forms cannot be edited while form is on “HOLD” status. Only users with administrator rights can put a form on HOLD or remove it."
      + "<br/>" + "You should use HOLD status when you want to keep the information entered in a form for future use. Otherwise, you may consider deleting the form";
    const dialogData = new InfoDialogModel("Hold", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  onChange(value: MatSlideToggleChange, Part5Headerdata: Go36ArP5LookbackHeaderModel) {
    this.isOnHold = value.checked;
    Part5Headerdata.modifiedBy = this.provId;
    Part5Headerdata.isOnHold = this.isOnHold;
    this.parentData.isOnHold = this.isOnHold;

    this.part5lookbackService.UpdateOnHoldPart5LookBack(Part5Headerdata).subscribe(res => {
      //update value in form reviewer log
      this.getLogData();
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  OpenArchiveDialog() {
    this.dialog.open(ArchiveDialogComponent, {
      width: "600px",
      data: { projectId: this.projectId, formId: this.Part5Header.originalId, oldVersionId: this.Part5Header.version, formType: 'Part5' },
    });
  }

  Section7Attachments(event: any, Part5Header: Go36ArP5LookbackHeaderModel, checktype: string) {
    Part5Header.modifiedBy = this.provId;
    if (checktype == "IPA") { Part5Header.attachIpa = event.target.checked; }
    else if (checktype == "basisOfDesign") { Part5Header.attachL1SubsurfaceBasisDesign = event.target.checked; }
    else if (checktype == "rootCause") { Part5Header.attachL1RootCause = event.target.checked; }
    else if (checktype == "reviewTeam") { Part5Header.attachL1ReviewTeam = event.target.checked; }

    this.part5lookbackService.UpdatePart5LookBack(Part5Header).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  UpdatePart5LookBacPerformanceData(Part5Performance: Go36ArP5LookbackPerformance) {
    Part5Performance.modifiedBy = this.provId;
    Part5Performance.p5LookbackId = this.Part5Header.arP5LookbackId;
    const startupDate = new Date(Part5Performance.startupDate!);
    Part5Performance.startupDate = this.notifyService.ConvertDateBeforeSave(startupDate);
    const fidpDate = new Date(Part5Performance.fidDate!);
    Part5Performance.fidDate = this.notifyService.ConvertDateBeforeSave(fidpDate);
    const lookbackDate = new Date(Part5Performance.lookbackDate!);
    Part5Performance.lookbackDate = this.notifyService.ConvertDateBeforeSave(lookbackDate);

    Part5Performance.fidArEv = Part5Performance.fidArEv == '' || Part5Performance.fidArEv == null ? '' : parseFloat(Part5Performance.fidArEv);
    Part5Performance.fidArActualOutcomes = Part5Performance.fidArActualOutcomes == '' || Part5Performance.fidArActualOutcomes == null ? '' : parseFloat(Part5Performance.fidArActualOutcomes);
    // //Part5Performance.keyPerformanceDriversUnit1 = Part5Performance.keyPerformanceDriversUnit1 == '' || Part5Performance.keyPerformanceDriversUnit1 == null ? '' : parseFloat(Part5Performance.keyPerformanceDriversUnit1);
    Part5Performance.keyPerformanceDriversEv = Part5Performance.keyPerformanceDriversEv == '' || Part5Performance.keyPerformanceDriversEv == null ? '' : parseFloat(Part5Performance.keyPerformanceDriversEv);
    Part5Performance.keyPerformanceDriversP10 = Part5Performance.keyPerformanceDriversP10 == '' || Part5Performance.keyPerformanceDriversP10 == null ? '' : parseFloat(Part5Performance.keyPerformanceDriversP10);
    Part5Performance.keyPerformanceDriversP90 = Part5Performance.keyPerformanceDriversP90 == '' || Part5Performance.keyPerformanceDriversP90 == null ? '' : parseFloat(Part5Performance.keyPerformanceDriversP90);
    Part5Performance.keyPerformanceDriversActualOutcomes = Part5Performance.keyPerformanceDriversActualOutcomes == '' || Part5Performance.keyPerformanceDriversActualOutcomes == null ? '' : parseFloat(Part5Performance.keyPerformanceDriversActualOutcomes);
    Part5Performance.keyPerformanceDriversEV1 = Part5Performance.keyPerformanceDriversEV1 == '' || Part5Performance.keyPerformanceDriversEV1 == null ? '' : parseFloat(Part5Performance.keyPerformanceDriversEV1);
    Part5Performance.keyPerformanceDriversP101 = Part5Performance.keyPerformanceDriversP101 == '' || Part5Performance.keyPerformanceDriversP101 == null ? '' : parseFloat(Part5Performance.keyPerformanceDriversP101);
    Part5Performance.keyPerformanceDriversP901 = Part5Performance.keyPerformanceDriversP901 == '' || Part5Performance.keyPerformanceDriversP901 == null ? '' : parseFloat(Part5Performance.keyPerformanceDriversP901);
    Part5Performance.keyPerformanceDriversActualOutcomes1 = Part5Performance.keyPerformanceDriversActualOutcomes1 == '' || Part5Performance.keyPerformanceDriversActualOutcomes1 == null ? '' : parseFloat(Part5Performance.keyPerformanceDriversActualOutcomes1);
    this.Part5Performance.dpiFeedEv = Part5Performance.dpiFeedEv == '' || Part5Performance.dpiFeedEv == null ? '' : parseFloat(Part5Performance.dpiFeedEv);
    this.Part5Performance.dpiFeedActualOutcomes = Part5Performance.dpiFeedActualOutcomes == '' || Part5Performance.dpiFeedActualOutcomes == null ? '' : parseFloat(Part5Performance.dpiFeedActualOutcomes);
    this.Part5Performance.dpiFeedP10 = Part5Performance.dpiFeedP10 == '' || Part5Performance.dpiFeedP10 == null ? '' : parseFloat(Part5Performance.dpiFeedP10);
    this.Part5Performance.dpiFeedP90 = Part5Performance.dpiFeedP90 == '' || Part5Performance.dpiFeedP90 == null ? '' : parseFloat(Part5Performance.dpiFeedP90);
    this.Part5Performance.dpiFullEv = Part5Performance.dpiFullEv == '' || Part5Performance.dpiFullEv == null ? '' : parseFloat(Part5Performance.dpiFullEv);
    this.Part5Performance.dpiFullP10 = Part5Performance.dpiFullP10 == '' || Part5Performance.dpiFullP10 == null ? '' : parseFloat(Part5Performance.dpiFullP10);
    this.Part5Performance.dpiFullNormalized = Part5Performance.dpiFullNormalized == '' || Part5Performance.dpiFullNormalized == null ? '' : parseFloat(Part5Performance.dpiFullNormalized);
    this.Part5Performance.dpiFullActualOutcomes = Part5Performance.dpiFullActualOutcomes == '' || Part5Performance.dpiFullActualOutcomes == null ? '' : parseFloat(Part5Performance.dpiFullActualOutcomes);
    this.Part5Performance.dpiFeedNormalized = Part5Performance.dpiFeedNormalized == '' || Part5Performance.dpiFeedNormalized == null ? '' : parseFloat(Part5Performance.dpiFeedNormalized);
    this.Part5Performance.dpiFullP90 = Part5Performance.dpiFullP90 == '' || Part5Performance.dpiFullP90 == null ? '' : parseFloat(Part5Performance.dpiFullP90);
    this.Part5Performance.rorFeedEv = Part5Performance.rorFeedEv == '' || Part5Performance.rorFeedEv == null ? '' : parseFloat(Part5Performance.rorFeedEv);
    this.Part5Performance.rorFeedP10 = Part5Performance.rorFeedP10 == '' || Part5Performance.rorFeedP10 == null ? '' : parseFloat(Part5Performance.rorFeedP10);
    this.Part5Performance.rorFeedP90 = Part5Performance.rorFeedP90 == '' || Part5Performance.rorFeedP90 == null ? '' : parseFloat(Part5Performance.rorFeedP90);
    this.Part5Performance.rorFeedNormalized = Part5Performance.rorFeedNormalized == '' || Part5Performance.rorFeedNormalized == null ? '' : parseFloat(Part5Performance.rorFeedNormalized);
    this.Part5Performance.rorFeedActualOutcomes = Part5Performance.rorFeedActualOutcomes == '' || Part5Performance.rorFeedActualOutcomes == null ? '' : parseFloat(Part5Performance.rorFeedActualOutcomes);
    this.Part5Performance.rorFullEv = Part5Performance.rorFullEv == '' || Part5Performance.rorFullEv == null ? '' : parseFloat(Part5Performance.rorFullEv);
    this.Part5Performance.rorFullP10 = Part5Performance.rorFullP10 == '' || Part5Performance.rorFullP10 == null ? '' : parseFloat(Part5Performance.rorFullP10);
    this.Part5Performance.rorFullP90 = Part5Performance.rorFullP90 == '' || Part5Performance.rorFullP90 == null ? '' : parseFloat(Part5Performance.rorFullP90);
    this.Part5Performance.rorFullNormalized = Part5Performance.rorFullNormalized == '' || Part5Performance.rorFullNormalized == null ? '' : parseFloat(Part5Performance.rorFullNormalized);
    this.Part5Performance.rorFullActualOutcomes = Part5Performance.rorFullActualOutcomes == '' || Part5Performance.rorFullActualOutcomes == null ? '' : parseFloat(Part5Performance.rorFullActualOutcomes);
    this.Part5Performance.rorFullTestCase = Part5Performance.rorFullTestCase == '' || Part5Performance.rorFullTestCase == null ? '' : parseFloat(Part5Performance.rorFullTestCase);
    this.Part5Performance.npvFeedActualOutcomes = Part5Performance.npvFeedActualOutcomes == '' || Part5Performance.npvFeedActualOutcomes == null ? '' : parseFloat(Part5Performance.npvFeedActualOutcomes);
    this.Part5Performance.npvFeedEv = Part5Performance.npvFeedEv == '' || Part5Performance.npvFeedEv == null ? '' : parseFloat(Part5Performance.npvFeedEv);
    this.Part5Performance.npvFeedP10 = Part5Performance.npvFeedP10 == '' || Part5Performance.npvFeedP10 == null ? '' : parseFloat(Part5Performance.npvFeedP10);
    this.Part5Performance.npvFeedP90 = Part5Performance.npvFeedP90 == '' || Part5Performance.npvFeedP90 == null ? '' : parseFloat(Part5Performance.npvFeedP90);
    this.Part5Performance.npvFeedNormalized = Part5Performance.npvFeedNormalized == '' || Part5Performance.npvFeedNormalized == null ? '' : parseFloat(Part5Performance.npvFeedNormalized);
    this.Part5Performance.npvFullEv = Part5Performance.npvFullEv == '' || Part5Performance.npvFullEv == null ? '' : parseFloat(Part5Performance.npvFullEv);
    this.Part5Performance.npvFullP10 = Part5Performance.npvFullP10 == '' || Part5Performance.npvFullP10 == null ? '' : parseFloat(Part5Performance.npvFullP10);
    this.Part5Performance.npvFullP90 = Part5Performance.npvFullP90 == '' || Part5Performance.npvFullP90 == null ? '' : parseFloat(Part5Performance.npvFullP90);
    this.Part5Performance.npvFullActualOutcomes = Part5Performance.npvFullActualOutcomes == '' || Part5Performance.npvFullActualOutcomes == null ? '' : parseFloat(Part5Performance.npvFullActualOutcomes);
    this.Part5Performance.npvFullNormalized = Part5Performance.npvFullNormalized == '' || Part5Performance.npvFullNormalized == null ? '' : parseFloat(Part5Performance.npvFullNormalized);
    this.Part5Performance.ceFeedEv = Part5Performance.ceFeedEv == '' || Part5Performance.ceFeedEv == null ? '' : parseFloat(Part5Performance.ceFeedEv);
    this.Part5Performance.ceFeedP10 = Part5Performance.ceFeedP10 == '' || Part5Performance.ceFeedP10 == null ? '' : parseFloat(Part5Performance.ceFeedP10);
    this.Part5Performance.ceFeedP90 = Part5Performance.ceFeedP90 == '' || Part5Performance.ceFeedP90 == null ? '' : parseFloat(Part5Performance.ceFeedP90);
    this.Part5Performance.ceFeedNormalized = Part5Performance.ceFeedNormalized == '' || Part5Performance.ceFeedNormalized == null ? '' : parseFloat(Part5Performance.ceFeedNormalized);
    this.Part5Performance.ceFeedActualOutcomes = Part5Performance.ceFeedActualOutcomes == '' || Part5Performance.ceFeedActualOutcomes == null ? '' : parseFloat(Part5Performance.ceFeedActualOutcomes);
    this.Part5Performance.ceFullEv = Part5Performance.ceFullEv == '' || Part5Performance.ceFullEv == null ? '' : parseFloat(Part5Performance.ceFullEv);
    this.Part5Performance.ceFullP10 = Part5Performance.ceFullP10 == '' || Part5Performance.ceFullP10 == null ? '' : parseFloat(Part5Performance.ceFullP10);
    this.Part5Performance.ceFullP90 = Part5Performance.ceFullP90 == '' || Part5Performance.ceFullP90 == null ? '' : parseFloat(Part5Performance.ceFullP90);
    this.Part5Performance.ceFullActualOutcomes = Part5Performance.ceFullActualOutcomes == '' || Part5Performance.ceFullActualOutcomes == null ? '' : parseFloat(Part5Performance.ceFullActualOutcomes);
    this.Part5Performance.ceFullNormalized = Part5Performance.ceFullNormalized == '' || Part5Performance.ceFullNormalized == null ? '' : parseFloat(Part5Performance.ceFullNormalized);
    this.Part5Performance.ceFullTestCase = Part5Performance.ceFullTestCase == '' || Part5Performance.ceFullTestCase == null ? '' : parseFloat(Part5Performance.ceFullTestCase);

    Part5Performance.estUltimateRecoveryMmboActualOutcomes = (Part5Performance.estUltimateRecoveryMmboActualOutcomes == '' || Part5Performance.estUltimateRecoveryMmboActualOutcomes == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecoveryMmboActualOutcomes));
    Part5Performance.estUltimateRecoveryMmboEv = (Part5Performance.estUltimateRecoveryMmboEv == '' || Part5Performance.estUltimateRecoveryMmboEv == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecoveryMmboEv));
    Part5Performance.estUltimateRecoveryMmboP10 = (Part5Performance.estUltimateRecoveryMmboP10 == '' || Part5Performance.estUltimateRecoveryMmboP10 == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecoveryMmboP10));
    Part5Performance.estUltimateRecoveryMmboP90 = (Part5Performance.estUltimateRecoveryMmboP90 == '' || Part5Performance.estUltimateRecoveryMmboP90 == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecoveryMmboP90));

    Part5Performance.estUltimateRecoveryMmboeActualOutcomes = (Part5Performance.estUltimateRecoveryMmboeActualOutcomes == '' || Part5Performance.estUltimateRecoveryMmboeActualOutcomes == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecoveryMmboeActualOutcomes));
    Part5Performance.estUltimateRecoveryMmboeEv = (Part5Performance.estUltimateRecoveryMmboeEv == '' || Part5Performance.estUltimateRecoveryMmboeEv == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecoveryMmboeEv));
    Part5Performance.estUltimateRecoveryMmboeP10 = (Part5Performance.estUltimateRecoveryMmboeP10 == '' || Part5Performance.estUltimateRecoveryMmboeP10 == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecoveryMmboeP10));
    Part5Performance.estUltimateRecoveryMmboeP90 = (Part5Performance.estUltimateRecoveryMmboeP90 == '' || Part5Performance.estUltimateRecoveryMmboeP90 == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecoveryMmboeP90));

    Part5Performance.estUltimateRecoveryBcfActualOutcomes = (Part5Performance.estUltimateRecoveryBcfActualOutcomes == '' || Part5Performance.estUltimateRecoveryBcfActualOutcomes == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecoveryBcfActualOutcomes));
    Part5Performance.estUltimateRecoveryBcfEv = (Part5Performance.estUltimateRecoveryBcfEv == '' || Part5Performance.estUltimateRecoveryBcfEv == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecoveryBcfEv));
    Part5Performance.estUltimateRecoveryBcfP10 = (Part5Performance.estUltimateRecoveryBcfP10 == '' || Part5Performance.estUltimateRecoveryBcfP10 == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecoveryBcfP10));
    Part5Performance.estUltimateRecoveryBcfP90 = (Part5Performance.estUltimateRecoveryBcfP90 == '' || Part5Performance.estUltimateRecoveryBcfP90 == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecoveryBcfP90));

    Part5Performance.estUltimateRecovery88MmboeActualOutcomes = (Part5Performance.estUltimateRecovery88MmboeActualOutcomes == '' || Part5Performance.estUltimateRecovery88MmboeActualOutcomes == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecovery88MmboeActualOutcomes));
    Part5Performance.estUltimateRecovery88MmboeP10 = (Part5Performance.estUltimateRecovery88MmboeP10 == '' || Part5Performance.estUltimateRecovery88MmboeP10 == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecovery88MmboeP10));
    Part5Performance.estUltimateRecovery88MmboeEv = (Part5Performance.estUltimateRecovery88MmboeEv == '' || Part5Performance.estUltimateRecovery88MmboeEv == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecovery88MmboeEv));
    Part5Performance.estUltimateRecovery88MmboeP90 = (Part5Performance.estUltimateRecovery88MmboeP90 == '' || Part5Performance.estUltimateRecovery88MmboeP90 == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.estUltimateRecovery88MmboeP90));

    Part5Performance.peakProductionMbpdActualOutcomes = (Part5Performance.peakProductionMbpdActualOutcomes == '' || Part5Performance.peakProductionMbpdActualOutcomes == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.peakProductionMbpdActualOutcomes));
    Part5Performance.peakProductionMbpdEv = (Part5Performance.peakProductionMbpdEv == '' || Part5Performance.peakProductionMbpdEv == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.peakProductionMbpdEv));
    Part5Performance.peakProductionMbpdP10 = (Part5Performance.peakProductionMbpdP10 == '' || Part5Performance.peakProductionMbpdP10 == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.peakProductionMbpdP10));
    Part5Performance.peakProductionMbpdP90 = (Part5Performance.peakProductionMbpdP90 == '' || Part5Performance.peakProductionMbpdP90 == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.peakProductionMbpdP90));

    Part5Performance.peakProductionMmcfdActualOutcomes = (Part5Performance.peakProductionMmcfdActualOutcomes == '' || Part5Performance.peakProductionMmcfdActualOutcomes == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.peakProductionMmcfdActualOutcomes));
    Part5Performance.peakProductionMmcfdEv = (Part5Performance.peakProductionMmcfdEv == '' || Part5Performance.peakProductionMmcfdEv == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.peakProductionMmcfdEv));
    Part5Performance.peakProductionMmcfdP10 = (Part5Performance.peakProductionMmcfdP10 == '' || Part5Performance.peakProductionMmcfdP10 == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.peakProductionMmcfdP10));
    Part5Performance.peakProductionMmcfdP90 = (Part5Performance.peakProductionMmcfdP90 == '' || Part5Performance.peakProductionMmcfdP90 == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.peakProductionMmcfdP90));

    Part5Performance.month12MmboActualOutcomes = (Part5Performance.month12MmboActualOutcomes == '' || Part5Performance.month12MmboActualOutcomes == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.month12MmboActualOutcomes));
    Part5Performance.month12MmboEv = (Part5Performance.month12MmboEv == '' || Part5Performance.month12MmboEv == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.month12MmboEv));
    Part5Performance.month1288MmboActualOutcomes = (Part5Performance.month1288MmboActualOutcomes == '' || Part5Performance.month1288MmboActualOutcomes == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.month1288MmboActualOutcomes));
    Part5Performance.month1288MmboEv = (Part5Performance.month1288MmboEv == '' || Part5Performance.month1288MmboEv == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.month1288MmboEv));

    Part5Performance.month12BcfActualOutcomes = (Part5Performance.month12BcfActualOutcomes == '' || Part5Performance.month12BcfActualOutcomes == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.month12BcfActualOutcomes));
    Part5Performance.month12BcfEv = (Part5Performance.month12BcfEv == '' || Part5Performance.month12BcfEv == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.month12BcfEv));
    Part5Performance.month1288BcfActualOutcomes = (Part5Performance.month1288BcfActualOutcomes == '' || Part5Performance.month1288BcfActualOutcomes == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.month1288BcfActualOutcomes));
    Part5Performance.month1288BcfEv = (Part5Performance.month1288BcfEv == '' || Part5Performance.month1288BcfEv == null) ? null : this.utilityService.AllowOneDecimal(Number(Part5Performance.month1288BcfEv));
    this.part5lookbackService.UpdatePart5LookBackPerformance(this.Part5Performance).subscribe(res => {
      res;
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    Part5Performance.startupDate = this.notifyService.ConvertDateAfterSave(startupDate);
    Part5Performance.fidDate = this.notifyService.ConvertDateAfterSave(fidpDate);
    Part5Performance.lookbackDate = this.notifyService.ConvertDateAfterSave(lookbackDate);

    Part5Performance.keyPerformanceDriversEv = Number(Part5Performance.keyPerformanceDriversEv).toFixed(1).toString();
    Part5Performance.keyPerformanceDriversP10 = Number(Part5Performance.keyPerformanceDriversP10).toFixed(1).toString();
    Part5Performance.keyPerformanceDriversP90 = Number(Part5Performance.keyPerformanceDriversP90).toFixed(1).toString();
    Part5Performance.keyPerformanceDriversActualOutcomes = Number(Part5Performance.keyPerformanceDriversActualOutcomes).toFixed(1).toString();
    Part5Performance.keyPerformanceDriversEV1 = Number(Part5Performance.keyPerformanceDriversEV1).toFixed(1).toString();
    Part5Performance.keyPerformanceDriversP101 = Number(Part5Performance.keyPerformanceDriversP101).toFixed(1).toString();
    Part5Performance.keyPerformanceDriversP901 = Number(Part5Performance.keyPerformanceDriversP901).toFixed(1).toString();
    Part5Performance.keyPerformanceDriversActualOutcomes1 = Number(Part5Performance.keyPerformanceDriversActualOutcomes1).toFixed(1).toString();
    Part5Performance.fidArEv = Number(Part5Performance.fidArEv).toFixed(1).toString();
    Part5Performance.fidArActualOutcomes = Number(Part5Performance.fidArActualOutcomes).toFixed(1).toString();
    this.Part5Performance.dpiFeedEv = Number(this.Part5Performance.dpiFeedEv).toFixed(2).toString();
    this.Part5Performance.dpiFeedActualOutcomes = Number(this.Part5Performance.dpiFeedActualOutcomes).toFixed(2).toString();
    this.Part5Performance.dpiFeedP10 = Number(this.Part5Performance.dpiFeedP10).toFixed(2).toString();
    this.Part5Performance.dpiFeedP90 = Number(this.Part5Performance.dpiFeedP90).toFixed(2).toString();
    this.Part5Performance.dpiFullEv = Number(this.Part5Performance.dpiFullEv).toFixed(2).toString();
    this.Part5Performance.dpiFullP10 = Number(this.Part5Performance.dpiFullP10).toFixed(2).toString();
    this.Part5Performance.dpiFullNormalized = Number(this.Part5Performance.dpiFullNormalized).toFixed(2).toString();
    this.Part5Performance.dpiFullP90 = Number(this.Part5Performance.dpiFullP90).toFixed(2).toString();
    this.Part5Performance.dpiFullActualOutcomes = Number(this.Part5Performance.dpiFullActualOutcomes).toFixed(2).toString();
    this.Part5Performance.dpiFeedNormalized = Number(this.Part5Performance.dpiFeedNormalized).toFixed(2).toString();
    if (this.Part5Performance != null &&  this.Part5Performance.rorFeedEv != null) {
      this.Part5Performance.rorFeedEv = Number(this.Part5Performance.rorFeedEv).toFixed(1).toString();
      this.Part5Performance.rorFeedEv =  this.Part5Performance.rorFeedEv + '%';
    }
    if (this.Part5Performance != null &&  this.Part5Performance.rorFeedP10 != null) {
      this.Part5Performance.rorFeedP10 = Number(this.Part5Performance.rorFeedP10).toFixed(1).toString();
      this.Part5Performance.rorFeedP10 =  this.Part5Performance.rorFeedP10 + '%';
    }
    if (this.Part5Performance != null &&  this.Part5Performance.rorFeedP90 != null) {
      this.Part5Performance.rorFeedP90 = Number(this.Part5Performance.rorFeedP90).toFixed(1).toString();
      this.Part5Performance.rorFeedP90 =  this.Part5Performance.rorFeedP90 + '%';
    }
    if (this.Part5Performance != null &&  this.Part5Performance.rorFeedNormalized != null) {
      this.Part5Performance.rorFeedNormalized = Number(this.Part5Performance.rorFeedNormalized).toFixed(1).toString();
      this.Part5Performance.rorFeedNormalized =  this.Part5Performance.rorFeedNormalized + '%';
    }
    if (this.Part5Performance != null &&  this.Part5Performance.rorFeedActualOutcomes != null) {
      this.Part5Performance.rorFeedActualOutcomes = Number(this.Part5Performance.rorFeedActualOutcomes).toFixed(1).toString();
      this.Part5Performance.rorFeedActualOutcomes =  this.Part5Performance.rorFeedActualOutcomes + '%';
    }
    if (this.Part5Performance != null &&  this.Part5Performance.rorFullEv != null) {
      this.Part5Performance.rorFullEv = Number(this.Part5Performance.rorFullEv).toFixed(1).toString();
      this.Part5Performance.rorFullEv =  this.Part5Performance.rorFullEv + '%';
    }
    if (this.Part5Performance != null &&  this.Part5Performance.rorFullP10 != null) {
      this.Part5Performance.rorFullP10 = Number(this.Part5Performance.rorFullP10).toFixed(1).toString();
      this.Part5Performance.rorFullP10 =  this.Part5Performance.rorFullP10 + '%';
    }
    if (this.Part5Performance != null &&  this.Part5Performance.rorFullP90 != null) {
      this.Part5Performance.rorFullP90 = Number(this.Part5Performance.rorFullP90).toFixed(1).toString();
      this.Part5Performance.rorFullP90 =  this.Part5Performance.rorFullP90 + '%';
    }
    if (this.Part5Performance != null &&  this.Part5Performance.rorFullNormalized != null) {
      this.Part5Performance.rorFullNormalized = Number(this.Part5Performance.rorFullNormalized).toFixed(1).toString();
      this.Part5Performance.rorFullNormalized =  this.Part5Performance.rorFullNormalized + '%';
    }
    if (this.Part5Performance != null &&  this.Part5Performance.rorFullActualOutcomes != null) {
      this.Part5Performance.rorFullActualOutcomes = Number(this.Part5Performance.rorFullActualOutcomes).toFixed(1).toString();
      this.Part5Performance.rorFullActualOutcomes =  this.Part5Performance.rorFullActualOutcomes + '%';
    }
    if (this.Part5Performance != null &&  this.Part5Performance.rorFullTestCase != null) {
      this.Part5Performance.rorFullTestCase = Number(this.Part5Performance.rorFullTestCase).toFixed(1).toString();
      this.Part5Performance.rorFullTestCase =  this.Part5Performance.rorFullTestCase + '%';
    }
    this.Part5Performance.npvFeedActualOutcomes = Number(this.Part5Performance.npvFeedActualOutcomes).toFixed(1).toString();
    this.Part5Performance.npvFeedEv = Number(this.Part5Performance.npvFeedEv).toFixed(1).toString();
    this.Part5Performance.npvFeedP10 = Number(this.Part5Performance.npvFeedP10).toFixed(1).toString();
    this.Part5Performance.npvFeedP90 = Number(this.Part5Performance.npvFeedP90).toFixed(1).toString();
    this.Part5Performance.npvFeedNormalized = Number(this.Part5Performance.npvFeedNormalized).toFixed(1).toString();
    this.Part5Performance.npvFullEv = Number(this.Part5Performance.npvFullEv).toFixed(1).toString();
    this.Part5Performance.npvFullP10 = Number(this.Part5Performance.npvFullP10).toFixed(1).toString();
    this.Part5Performance.npvFullP90 = Number(this.Part5Performance.npvFullP90).toFixed(1).toString();
    this.Part5Performance.npvFullActualOutcomes = Number(this.Part5Performance.npvFullActualOutcomes).toFixed(1).toString();
    this.Part5Performance.npvFullNormalized = Number(this.Part5Performance.npvFullNormalized).toFixed(1).toString();
    this.Part5Performance.ceFeedEv = Number(this.Part5Performance.ceFeedEv).toFixed(1).toString();
    this.Part5Performance.ceFeedP10 = Number(this.Part5Performance.ceFeedP10).toFixed(1).toString();
    this.Part5Performance.ceFeedP90 = Number(this.Part5Performance.ceFeedP90).toFixed(1).toString();
    this.Part5Performance.ceFeedNormalized = Number(this.Part5Performance.ceFeedNormalized).toFixed(1).toString();
    this.Part5Performance.ceFeedActualOutcomes = Number(this.Part5Performance.ceFeedActualOutcomes).toFixed(1).toString();
    this.Part5Performance.ceFullEv = Number(this.Part5Performance.ceFullEv).toFixed(1).toString();
    this.Part5Performance.ceFullP10 = Number(this.Part5Performance.ceFullP10).toFixed(1).toString();
    this.Part5Performance.ceFullP90 = Number(this.Part5Performance.ceFullP90).toFixed(1).toString();
    this.Part5Performance.ceFullActualOutcomes = Number(this.Part5Performance.ceFullActualOutcomes).toFixed(1).toString();
    this.Part5Performance.ceFullNormalized = Number(this.Part5Performance.ceFullNormalized).toFixed(1).toString();
    this.Part5Performance.ceFullTestCase = Number(this.Part5Performance.ceFullTestCase).toFixed(1).toString();
  }

  uploadFile = (event: any) => {
    const file = event.target.files[0];
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.part5lookbackService.saveDocument(this.Part5Header.arP5LookbackId, this.provId, formData).subscribe((res: any) => {
      this.notifyService.showSuccess("File uploaded successfully.", "");
      this.part5Document = res;
    });
  }

  DownloadFile(lookbackDocument: Go36P5LookbackDocument) {

    this.part5lookbackService.downloadFile(lookbackDocument.documentId).subscribe((data: any) => {
      let downloadURL = window.URL.createObjectURL(data);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = lookbackDocument.docName; // //name == null ? '' : name;
      link.click();
    });
  }

  RemoveFile(document: Go36P5LookbackDocument, index: any) {
    document.modifiedBy = this.provId;
    document.hidefromlist = true;
    this.part5Document.splice(index, 1);

    this.part5lookbackService.DeleteDocument(document).subscribe(res => {
      // //this.part4Document = res;
    });
  }
  GetUserRoleFromLocalStorage() {
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);

    // we can create the constants of the users role
    if (this.userAccessDetails?.data?.isPowerUser) {
      this.userRole = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      this.userRole = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      this.userRole = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      this.userRole = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      this.userRole = "Leadership";
    }
  }

  onReady(editor, name) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.UpdateckeditorChanges(editor.getData(), name);
        }
      }
    });
  }

  onReadyLLCK(editor, name, type) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        this.UpdateLLCkeditorChanges(editor.getData(), name, type);
      }
    });
  }

  NAAssetEventCheck(event: MatSlideToggleChange, row: Go36ArP5LookbackHeaderModel) {
    row.modifiedBy = this.provId;
    row.assetNa = event.checked;
    this.part5lookbackService.UpdatePart5LookBack(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  NAFDEventCheck(event: MatSlideToggleChange, row: Go36ArP5LookbackHeaderModel) {
    row.modifiedBy = this.provId;
    row.facilitiesDefinitionNa = event.checked;
    this.part5lookbackService.UpdatePart5LookBack(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  NAWDEventCheck(event: MatSlideToggleChange, row: Go36ArP5LookbackHeaderModel) {
    row.modifiedBy = this.provId;
    row.wellsDifinitionNa = event.checked;
    this.part5lookbackService.UpdatePart5LookBack(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  NARDEventCheck(event: MatSlideToggleChange, row: Go36ArP5LookbackHeaderModel) {
    row.modifiedBy = this.provId;
    row.reservoirDifinitionNa = event.checked;
    this.part5lookbackService.UpdatePart5LookBack(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  // Form Reviewer Methods

  getReviewerData() {
    this.formReviewerService.getReviewerData(this.formId, this.projectId, this.versionName, this.objectId, this.provId, "AR_LOOKBACK_PART5").subscribe(res => {
      this.reviewerData = res;
      if (this.reviewerData?.length != 0) {
        this.reviewerCount = true;
      }
    });
  }

  getLogData() {
    this.formReviewerService.getLogData(this.formId, this.projectId, this.versionName, "AR_LOOKBACK_PART5").subscribe(res => {
      this.formsLogData = res;
    });
  }

  getSignData() {
    this.formReviewerService.getPartFiveSignOffData(this.Part5Header.arP5LookbackId, this.Part5Header.version).subscribe(res => {
      this.signOffData = res;
      this.signOffData.forEach(obj => {
        this.UpdateSignOff(obj,1);
      });
    }
    );
  }

  UpdateSignOff(signOff: LookBackFormSignoffs,count:any) {
    signOff.modifiedBy = this.provId;
    const date = new Date(signOff.signoffDate!);

    if (signOff.signoffDate != null) {
      signOff.signoffDate = this.notifyService.ConvertDateBeforeSave(date);
    }
    this.formReviewerService.postLookBackSignOffData(signOff).subscribe(res => {
      if(count==2){
        this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
      }
    });
    if (signOff.signoffDate != null) {
      signOff.signoffDate = this.notifyService.ConvertDateAfterSave(date);
    }
  }

  SearchUser(event: any) {
    // // let name = this.searchStr;
  }

  addUserToAccessList(event: any) {
    this.selectedUserObjectId = event.originalObject.objectId;
    this.selectedUserProvId = event.originalObject.provId;
    this.AddNewUserAccess(this.projectId, this.formId, this.versionName, this.objectId, this.provId, this.selectedUserObjectId, this.selectedUserProvId);
  }

  AddNewUserAccess(ProjectId: number, formId: number, versionId: number, objectId: string, provId: string, selectedUserObjectId: string, selectedUserProvId: string) {
    this.formReviewerService.PostUseraccess(ProjectId, formId, versionId, objectId, provId, selectedUserObjectId, selectedUserProvId, "AR_LOOKBACK_PART5").subscribe(res => {
      this.getReviewerData()
      this.getLogData();
    });
  }

  UpdateReviewer(id: number, action: string,reviewerItem:FormAction, comments?: string) {
    if (comments == null || comments == '') {
      this.notifyService.showError("Please enter comments before Form Action", "Warning");
    }
    else {
      this.formActionModel.projectId = this.projectId;
      this.formActionModel.projectName = this.projectData.projectName;
      this.formActionModel.formId = this.formId;
      this.formActionModel.versionId = this.versionName;
      this.formActionModel.formType = "AR_LOOKBACK_PART5";
      this.formActionModel.comments = comments;
      this.formActionModel.formActionId = id;
      this.formActionModel.action = action;
      this.formActionModel.objectId = this.objectId;
      this.formActionModel.provId = this.provId;
      this.formActionModel.currentUrl = this.href;
      this.formActionModel.currentBUrl = this.bUrl;

      this.formActionModel.submitterUserId = reviewerItem.submitterUserId;
      this.formActionModel.reviewerUserId = reviewerItem.reviewerUserId;

      this.formReviewerService.approvalForm(this.formActionModel).subscribe(res => {
        this.getLogData();
        this.getReviewerData();
      });
    }
  }

  DeleteReviewer(formAction: FormAction, opertionType: string) {
    formAction.action = opertionType;
    formAction.active = false;

    formAction.submitterComments = formAction.submitterComments || '';
    this.formReviewerService.postDeleteReviewerData(formAction).subscribe(res => {
      this.getLogData();
      this.getReviewerData();
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
  }

  deleteRow(index: any) {
    // // let usertoRemove = this.reviewerData[index];
    this.reviewerData.splice(index, 1);
  }

  addRow(event: any) {
    this.AddNewUserAccess(this.projectId, this.formId, this.versionName, this.objectId, this.provId, this.selectedUserObjectId, this.selectedUserProvId);
  }
  ShowSignoffsPopUp() {
    const message = 'Outline approvals needed for investment appropriation. Wet signatures, where still applicable,'
      + '\n' + 'can be done in signature & date boxes on final printout';
    const dialogData = new InfoDialogModel("Signoffs", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  addDefaultUserAccess() {
    // //let text = this.UserName.split(",");
    // // let lname = text[0].trim();
    // // let fname = text[1].split('[')[0].trim();
    if (localStorage.getItem("goProject")) {
      const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")))
      this.data = { goprojectmodel: JSON.parse(projectDetail) };
    }
    this.AddNewUserAccess(this.projectId, this.formId, this.versionName , this.objectId, this.provId, this.selectedUserObjectId, this.selectedUserProvId);
  }

  GetUserAccesslist() {
    if (this.data.goprojectmodel.ID == null) {
      this.ProjectId = this.data.goprojectmodel.ProjectId;
    }
    else {
      this.ProjectId = this.data.goprojectmodel.ID;
    }

    this.projectservice.getUserAccess(this.ProjectId).subscribe(res => {
      this.reviewerData = res;
    }
    );
  }

  OpenApprovalDialog(reviewerId: number, reviewerComments?: string) {
    if (reviewerComments == null || reviewerComments == '') {
      this.notifyService.showError("Please enter comments before Form Action", "Warning");
    }
    else {
      this.dialog.open(ApprovalDialogComponent, {
        width: "600px",
        data: { projectId: this.projectId, projectName: this.projectData.projectName, formId: this.formId, versionId: this.versionName, formType: "AR_LOOKBACK_PART5", reviewerId: reviewerId, reviewerComments },
      });
    }
  }

  UpdatePart4LookBackHeaderDates(Part5Headerdata: Go36ArP5LookbackHeaderModel) {
    const date = new Date(Part5Headerdata.lookbackDate!);
    Part5Headerdata.lookbackDate = this.notifyService.ConvertDateBeforeSave(date);
    const ardate = new Date(Part5Headerdata.arDate!);
    Part5Headerdata.arDate = this.notifyService.ConvertDateBeforeSave(ardate);

    this.part5lookbackService.UpdatePart5LookBack(Part5Headerdata).subscribe(res => {
      res;
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    Part5Headerdata.lookbackDate = this.notifyService.ConvertDateAfterSave(date);
    Part5Headerdata.arDate = this.notifyService.ConvertDateAfterSave(ardate);
  }

  ExportPDF() {
    this.part5lookbackService.GetPart5PDF(this.projectId, this.formId, this.versionName, this.objectId, this.provId).subscribe((response) => {
      let file = new Blob([response], { type: 'application/pdf' });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    })
  }
  refreshAfterSsbodLink() {
    this.LoadPart5LookBack();
  }
}
