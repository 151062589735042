import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormsettingsService } from 'src/app/admin/service/formsettings.service';
import { Form } from 'src/app/Model/Form.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { DataImportService } from '../../FEEDServices/data-import.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.css']
})

export class DataImportComponent implements OnInit {
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  formCase: string | null = '';
  formId: number = 0;
  @Input() isOnHold: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() form: Form = new Form();
  showDownload: boolean = false;
  data: any;
  formConfig: any;
  constructor(private notifyService: NotificationService,
    private dataImportService: DataImportService,
    private go36ProjectService: Go36projectService,
    private route: ActivatedRoute,
    private formsettingsService: FormsettingsService,
    private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formCase = (this.route.snapshot.paramMap.get("formCase"));
    if (this.formCase == 'Create') {
      this.formId = this.form.id; // //Number(this.route.snapshot.paramMap.get("formId"));
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          this.formId = this.form.id;// //params['formId'];
        });
    }
    this.GetFormConfiguration(this.form.id);
  }

  uploadFile = (event: any) => {
    const file = event.target.files[0];
    if (file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {

      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      let isOld = false;
      this.dataImportService.uploadFile(this.formId, formData, this.provId,this.form.features?.BeforeGreenHouseGas).subscribe((fileUpload: any) => {
        if (fileUpload == "Success") {
          this.notifyService.showSuccess("File uploaded successfully.", "");
          this.showDownload = true;
        }
        else if (fileUpload == "Invalid File") {
          this.notifyService.showError("File is not in the proper format, kindly refer the Template.", "");
          this.showDownload = false;
        }
        else {
          this.notifyService.showError("There is error while import, please try again.", "");
          this.showDownload = false;
        }
        this.GetDataImport(this.formId, this.provId);
      });
    }
    else {
      this.notifyService.showError("File is not in the proper format.", "");
      this.showDownload = false;
      this.GetDataImport(this.formId, this.provId);
    }
  }
  GetDataImport(formId: number, provId: string) {
    this.dataImportService.GetDataImport(formId, provId).subscribe(res => {
      this.data = res;
      this.formsettingsService.getFormSettings(formId).subscribe(res => {
        if (res != undefined && res != null && res.priceInputFileName != null && res.priceInputFileName != undefined && res.priceInputFileName != "") {
          this.formConfig = res;
          this.showDownload = true;
        }
      });
    });
  }
  getYears(endYear, startYear): number[] {
    return Array(endYear - startYear + 1).fill(startYear).map((value, index) => value + index)
  }
  GetFormConfiguration(formId: number) {
    this.formsettingsService.getFormSettings(formId).subscribe(res => {
      if (res != undefined && res != null && res.priceInputFileName != null && res.priceInputFileName != undefined && res.priceInputFileName != "") {
        this.formConfig = res;
        this.showDownload = true;
      }
      this.GetDataImport(formId, this.provId);
    });
  }
  DownloadFile() {
    this.dataImportService.DownloadExcelFile(this.formId).subscribe((data: any) => {
      let downloadURL = window.URL.createObjectURL(data);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = this.formConfig.priceInputFileName;
      link.click();
    });
  }
}