import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { Form,FormStatus } from 'src/app/Model/Form.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { FeedNavService } from 'src/app/forms/FEEDServices/feed-nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ArchiveDialogComponent } from 'src/app/Modals/archive-dialog/archive-dialog.component';
import { NewARDocumentModel } from 'src/app/Model/NewARDocument.model';
import { EaprojectService } from '../../service/eaproject.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { EAPart1Model } from 'src/app/Model/EAPart1.model';
import { EaSupplementService } from '../../service/ea-supplement.service';
import { EaPart4LookbackService } from '../../service/ea-part4-lookback.service';
import { EAP4LookbackDocument, EAP4LookbackHeaderModel, EAP4LookbackPerformance, EAPart4ViewModel } from 'src/app/Model/EAPart4LookBack.model';
import { FormsLog } from 'src/app/Model/FormsLog.model';
import { FormReviewerService } from 'src/app/forms/FEEDServices/form-reviewer.service';
import { environment } from 'src/environments/environment';
import { FeaturesImplemented } from 'src/app/Model/features-implemented';
import { FormAction } from 'src/app/Model/FormAction.model';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-new-ar-nav',
  templateUrl: './new-ar-nav.component.html',
  styleUrls: ['./new-ar-nav.component.css']
})

export class NewArNavComponent implements OnInit {
  isOnHold = false;
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  formId: number = 0;
  projectId: number = 0;
  form = new Form();
  projectName: string = '';
  myParam: string = '';
  formCase: string | null = '';
  versionName: number = 0;
  userAccessList: any;
  userAccessDetails: any;
  formTableFormId: number = 0;
  featuresImplemented = new FeaturesImplemented();
  NewARDocuments: NewARDocumentModel[] = [];
  Part1Model = new EAPart1Model;
  Comments: any = [];
  part4Data: EAPart4ViewModel[] = [];
  Part4Header = new EAP4LookbackHeaderModel;
  Part4Performance: EAP4LookbackPerformance[] = [];
  Part4Document: EAP4LookbackDocument[] = [];
  formsLogData: FormsLog[] = [];
  versionId: number = 0;
  projectData: any;
  baseUrl: any = environment.apiBaseUrl;
  pdfUrl: any = "";
  loggedInUser: string = '';
  sendBackLockByFormState:boolean = false;
  forReviewLockByFormState:boolean = false;
  concurLockByFormState:boolean = false;

  constructor(private go36ProjectService: Go36projectService,
    private feednavService: FeedNavService, public dialog: MatDialog, private route: ActivatedRoute,
    private router: Router, private location: Location, public utilityService: UtilityService,
    private eaService: EaprojectService, private notifyService: NotificationService,
    private supplementService: EaSupplementService, private eapart4lookbackService: EaPart4LookbackService, private formReviewerService: FormReviewerService) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formCase = (this.route.snapshot.paramMap.get("formCase"));
    if (this.formCase == 'Create') {
      this.projectId = Number(this.route.snapshot.paramMap.get("projectId"));
      this.formId = Number(this.route.snapshot.paramMap.get("formId"));
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          this.projectId = Number(params['projectId']);
          this.formId = Number(params['formId']);
          this.versionName = Number(params['versionName']);
          this.GetUserAccessListForEAProject();
        });
    }
    this.getProjectInfobyId();
  }
  getProjectInfobyId() {
    this.go36ProjectService.GetProjectInfobyId(this.projectId).subscribe(res => {
      this.projectData = res;
      this.LoadPart1Data();
    });
  }

  OpenArchiveDialog() {
    this.dialog.open(ArchiveDialogComponent, {
      width: "600px",
      data: { projectId: this.projectId, formId: this.formId, oldVersionId: this.versionName, formType: "EA" },
    });
  }

  setGetFormStatus(formStatus:number){
    if(formStatus == FormStatus.FORM_DRAFT_STATE || formStatus == FormStatus.FORM_REVISION_STATE){
      this.forReviewLockByFormState = false;
      this.sendBackLockByFormState = true;
      this.concurLockByFormState = true;
    }else if(formStatus == FormStatus.FORM_REVIEW_STATE){
      this.forReviewLockByFormState = true;
      this.sendBackLockByFormState = false;
      this.concurLockByFormState = false;
    }else{
      this.forReviewLockByFormState = false;
      this.sendBackLockByFormState = false;
      this.concurLockByFormState = false;
    }
  }

  onChange(value: MatSlideToggleChange) {
    this.isOnHold = value.checked;
    this.Part1Model.isOnHold = this.isOnHold;
    this.Part1Model.modifiedBy = this.provId;
    this.eaService.postIsOnHoldValue(this.Part1Model).subscribe(res => {
      //update value in form reviewer log
      this.getLogData();
    });
  }

  getLogData() {
    this.formCase = (this.route.snapshot.paramMap.get("formCase"));
    if (this.formCase == 'Create') {
      this.formId = Number(this.route.snapshot.paramMap.get("formId"));
      this.projectId = Number(this.route.snapshot.paramMap.get("projectId"));
      this.versionId = Number(this.route.snapshot.paramMap.get('versionName'));
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          this.formId = params['formId'];
          if (this.projectId != 0) {
            this.projectId = params['projectId'];
          }
          this.versionId = params['versionName'];
        })
    }

    this.formReviewerService.getLogData(this.formId, this.projectId, this.versionId, "EA").subscribe(res => {
      this.formsLogData = res;
      // // if (this.UserAccessList.length >= 1)
      // //   this.checkadmin();
    }
    );
  }
  ShowOnHoldModal() {
    const message = "When the HOLD button is activated, the form for this project will be placed on a “Hold” status. Fields on the forms cannot be edited while form is on “HOLD” status. Only users with administrator rights can put a form on HOLD or remove it."
      + "<br/>" + "You should use HOLD status when you want to keep the information entered in a form for future use. Otherwise, you may consider deleting the form";
    const dialogData = new InfoDialogModel("Hold", message);
    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }
  GetNewARDocuments() {
    this.eaService.GetNewARDocuments(this.Part1Model.arId).subscribe(res => {
      this.NewARDocuments = res;
    });
  }
  uploadFile = (event: any) => {
    const file = event.target.files[0];
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    this.eaService.saveDocument(this.Part1Model.arId, this.provId, formData).subscribe((res: any) => {
      this.notifyService.showSuccess("File uploaded successfully.", "");
      this.NewARDocuments = res;
    });
  }
  DownloadFile(newARdocument: NewARDocumentModel) {
    this.eaService.downloadFile(newARdocument.documentId).subscribe((data: any) => {
      let downloadURL = window.URL.createObjectURL(data);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = newARdocument.docName;
      link.click();
    });
  }
  RemoveFile(document: NewARDocumentModel, index: any) {
    document.modifiedBy = this.provId;
    document.hidefromlist = true;
    this.NewARDocuments.splice(index, 1);
    this.eaService.DeleteDocument(document).subscribe(res => {
      // //this.NewARDocuments = res;
    });
  }

  LoadPart1Data() {

    this.eaService.getEAFormFeatures(this.formId,this.versionName).subscribe((res:FeaturesImplemented) => {
      if (res){
        this.featuresImplemented = res;
      }
    });

    this.eaService.GetPart1Data(this.formId, this.versionName).subscribe((res: any) => {
      if (res) {
        this.Part1Model = res.part1HeaderData;
        this.Comments = res.resultComments;

        this.setGetFormStatus(this.Part1Model.statusId);

        this.pdfUrl = `${this.baseUrl}api/part1/GetNewARPDF?projectId=${this.projectId}&formId=${this.formId}&version=${this.versionName}`;
        this.GetNewARDocuments();
        this.projectName = this.projectData.buName + ' - ' + ' ' + this.projectData.projectName + ' - ' + '(version #' + this.Part1Model.version + ')';
      }
    });
  }
  CreateEaNewRescope() {
    this.supplementService.CreateEaNewRescope(this.projectId, 0, 0, this.provId).subscribe((res) => {
      this.router.navigate(["/ea/supplement-nav"], { queryParams: { projectId: this.projectId, supplementId: res.part1.originalId, versionName: res.part1.version, formCase: 'Create' } });
    });
  }
  CreateEaNewSupplement() {
    this.supplementService.CreateEaNewSupplement(this.projectId, this.provId, false).subscribe((res) => {
      this.router.navigate(["/ea/supplement-nav"], { queryParams: { projectId: this.projectId, supplementId: res.part1.originalId, versionName: res.part1.version, formCase: 'Create' } });
    });
  }
  CreatePart4LookBack() {
    this.eapart4lookbackService.CreatePart4LookBack(this.projectId,this.objectId,this.provId).subscribe((res) => {
      if (res.go36EaP4LookbackHeaders != null) {
        this.part4Data = res;
        this.Part4Header = res.go36EaP4LookbackHeaders;
        this.Part4Performance = res.go36EaP4LookbackPerformance;
        this.router.navigate(["/ea/forms/eapart4"], { queryParams: { projectId: this.projectId, formId: this.Part4Header.originalId, versionName: this.Part4Header.version, formCase: 'Create' } });
      }
    }
    );
  }
  GetUserAccessListForEAProject() {
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);
    let userRole: string = '';
    // we can create the constants of the users role
    if (this.userAccessDetails?.data?.isPowerUser) {
      userRole = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      userRole = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      userRole = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      userRole = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      userRole = "Leadership";
    }
    this.eaService.GetUserAccessListForEAProject(this.projectId.toString(), this.formId.toString(), this.versionName.toString(), userRole, this.objectId, this.provId).subscribe(res => {
      this.userAccessList = res;
      if(!this.userAccessList?.data?.canViewProject){
        this.router.navigate(["errorpage"]);
      }
    });
  }

  performFormAction(action:string){
    let formActionModel = new FormAction();
    formActionModel.projectId = this.projectId;
    formActionModel.formId = this.formId;
    formActionModel.versionId = this.versionName;
    formActionModel.action = action;
    formActionModel.formType = "EA";
    formActionModel.loggedInUser = this.provId;
    this.formReviewerService.processFormAction(formActionModel).subscribe(res => {
      //getting form data again on successful for-action
      this.GetUserAccessListForEAProject();
      this.getProjectInfobyId();
    })
  }

  changeHeader($event: any) {
    this.Part1Model.projectLocation = $event;
  }

  ExportPDF() {
    this.eaService.GetNewARPDF(this.projectId, this.formId, this.versionName).subscribe((response) => {
      let file = new Blob([response], { type: 'application/pdf' });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    })
  }
}
