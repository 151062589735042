<div class="box-container" id="page-body">

    <h3 *ngIf=!editMode>New Project</h3>
    <h3 *ngIf=editMode><strong>Projects Header Information</strong>  </h3>

    
    <div class="row">
        <div class="col-md-6 col-md-offset-3">
            <hr>

            <form [formGroup]="formValue" (ngSubmit)="onSubmit()">
                <div class="form-horizontal">
                    <div class="form-group" *ngIf=editMode>
                        <label class="control-label col-xs-4" for="ProjectId">Project Id:</label>
                        <div class="col-xs-2">
                            <input   readonly="readonly"class="form-control col-xs-4" formControlName="ProjectId"  id="ProjectId" type="text" value="">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-4"  for="OpcoId">Segment</label>
                        <div class="col-xs-4">
                            <select formControlName="OpcoId" class="form-control" (change) = "OnOpcoChange($any($event.target).value)">
                              <!-- <option disabled>Select OPCO</option> -->
                                <option *ngFor="let web of OpcoList" [value]="web.OpcoId">
                                    {{web.OpcoName}}</option>
                            </select>
                        </div>
                    </div>
                  <div class="form-group">
                        <label class="control-label col-xs-4" for="SbuId">RU</label>
                        <div class="col-xs-4">
                            <select formControlName="SbuId" class="form-control" (change) = "OnSBuChange($any($event.target).value)">
                                <!-- <option disabled>Select SBU</option> -->
                                <option *ngFor="let web of SbuList"  [value]="web.SbuId" >{{web.SbuName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-4" for="BuId">BU</label>
                        <div class="col-xs-4">
                            <select formControlName="BuId" class="form-control">
                                <!-- <option disabled>Select BU</option> -->
                                <option *ngFor="let web of BuList"  [value]="web.BuId" >{{web.BuName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-4" for="AssetClassId">Asset Class</label>
                        <div class="col-xs-4">
                            <select formControlName="AssetClassId" class="form-control">
                                <!-- <option disabled >Select Asset</option> -->
                                <option *ngFor="let web of AssetList" [selected]="web.AssetId=== 1"  [value]="web.AssetId">{{web.AssetName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-4" for="SpecialProjectType">Special Project Type</label>
                        <div class="col-xs-4">
                            <select formControlName="SpecialProjectType" class="form-control">
                                <option  disabled value="0"></option>
                                <option *ngFor="let web of SpecialProjectList"  [value]="web.SpclPId">{{web.SpclPName}} </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-4">Project requires SSBOD:</label>
                        <div class="col-xs-4 padding-top-5">
                            <input  class="form-checkbox" formControlName="IsSsbodavailable"  type="checkbox"
                              [attr.disabled]="isDisabled ? true : null" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-4" for="ProjectName">Name</label>
                        <div class="col-xs-8">
                            <input  class="form-control col-xs-4" formControlName="ProjectName" placeholder="Type Project name here" id="Project_Name" type="text" value="">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-xs-4" for="AssetMegaFriendlyName">Asset Megafriendly Name:</label>
                        <div class="col-xs-8">
                            <input  class="form-control col-xs-4"  placeholder="Type asset mega friendly name here" formControlName="AssetMegaFriendlyName"  id="Asset_Megafriendly" type="text" value="">
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label col-xs-4" for="Rsid">RSID (Value to be populated for Capital Projects)</label>
                        <div class="col-xs-8">
                            <input  class="form-control col-xs-4" placeholder="Type RSID here" formControlName="Rsid"  id="Rsid_Value" type="text" value="">
                        </div>
                    </div>
                    <div class="form-group" *ngIf="errormessage">
                       
                            <div class="alert alert-danger col-xs-12" role="alert">
                                <span class="col-xs-8">This RSID is already in use by other project.</span>
                            </div>
                     
                    </div>
                    <!-- <span>{{errormessage}}</span> -->
                    <hr>
                    <div class="row">
                        <div class="col-xs-6 padding-0 text-left" *ngIf= "from == undefined">
                            <a class="btn btn-default"  routerLink= "/go36project/project">Cancel</a>
                        </div>
                        <div class="col-xs-6 padding-0 text-left" *ngIf= "from == 'rsid'">
                            <a class="btn btn-default"  routerLink= "/forms/myrsidproject">Cancel</a>
                        </div>
                        <div class="col-xs-6 padding-0 text-right" *ngIf=!editMode>

                            <button type="submit" class="btn btn-primary" style="width:8em;color: white !important" [disabled]="!this.formValue.valid"   >Create Project</button>
                        </div>
                        <div class="col-xs-6 padding-0 text-right" *ngIf=editMode>
                            <button type="submit"  class="btn btn-primary"  routerlink="/project/go36project/project" style="width:8em;color: white !important" 
                              [disabled]="!this.formValue.valid" >Update Project</button>
                        </div>
                    </div>
                    
                </div>
                    </form>
                </div> 
            </div>
        </div>
                   