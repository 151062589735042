import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guidances } from 'src/app/Model/BasisOfEvaluation.model';
import { AppsettingsService } from 'src/app/Shared/appsettings.service';

@Injectable({
  providedIn: 'root'
})

export class BasisofEvaluationService {
  CreateUrl = 'api/BasisofEvaluation/SaveBasisofEvaluation';
  GetUrl = 'api/BasisofEvaluation/getBasisofEvaluationData?formId=';
  UpdateKeyAssumptionUrl = 'api/BasisofEvaluation/SaveKeyAssumptionsData';
  UpdateGuidanceUrl = 'api/BasisofEvaluation/SaveGuidanceData';
  UpdateLeadershipUrl = 'api/BasisofEvaluation/SaveLeadershipData';
  CreateDefaultUrl = 'api/BasisofEvaluation/CreateBasisEvaluationData';

  constructor(private http: HttpClient,
    private appsettings: AppsettingsService,
  ) { }

  getData(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetUrl + formId);

    // // return this.http.get(this.appsettings.localhostUrl + this.GetUrl + formId).map(res => {
    // //   return res.json();
    // // });

    // // return this.http.get<any>(this.appsettings.localhostUrl + this.GetUrl + formId)
    // //   .pipe(map((res: any) => {
    // //     return res;
    // //   }))
  }

  postData(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateKeyAssumptionUrl, formData);
  }

  postGuidanceData(formData: any) {
    return this.http.post<Guidances[]>(this.appsettings.apiBaseUrl + this.UpdateGuidanceUrl, formData);
  }

  postLeadershipData(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UpdateLeadershipUrl, formData);
  }

  createDefaultData(formId: number, provId: string, projectId: number, isSuppliment: boolean) {
    const params = new HttpParams()
      .append('formId', formId)
      .append('provId', provId)
      .append('projectId', projectId)
      .append('isSuppliment', isSuppliment);

    return this.http.post<any>(this.appsettings.apiBaseUrl + this.CreateDefaultUrl, '', {
      params: params
    });
  }
}