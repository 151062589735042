


<div class="errorPage">
    <div style="background-color: white">
        <h2 style='font-size: x-large; font-weight: bold; padding: 2rem;'>You do not have the permission to access this page. Kindly contact with your admin. </h2>
        <p style='font-size: 2rem; padding: 2rem;' >Error Code: 401 </p>
        <p style='font-weight: bold; font-size: large;  padding: 2rem;'>Please click<a class="link-text" href="/"> here </a> to go back to homepage</p>
    </div>

</div>

<!-- <div class="errorPage" style="margin-left: -45px; margin-bottom: -100px; margin-top: -15px; margin-right: -50px;">
    <div style="background-color: white">

        <p style='font-size: large; font-weight: bold; margin-left: 15px; color: white;'>Request Id: 5706107078402060412
        </p>

        <p style='font-size: xx-large; font-weight: bold; margin-left: 15px;'>Request Id: 5706107078402060412</p>

        <p style='font-size: x-large; font-weight: bold; margin-left: 15px;; margin-top: 30px;'> You do not have the
            permission to access this page. Kindly contact with your admin. </p>

        <p style='font-size: 16px; margin-left: 15px; margin-top: 15px;'>Controller Name: project</p>

        <p style='font-size: 16px; margin-left: 15px; margin-top: 15px;'>Action Name: edit</p>

        <p style='font-size: 16px; margin-left: 15px; margin-top: 15px;'>Error Code: 401 </p>

        <p style='font-weight: bold; margin-left: 15px; margin-top: 15px; font-size: large;'>Please click<a
                class="link-text" href="/"> here </a> to go back to homepage</p>

    </div>
    </div> -->
