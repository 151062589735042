<div  *ngIf="isLoading | async" class="box-container">
    <div id="spinner" class="loading">
    
    </div>
    <div id="loading" class="col-md-4 text-center">
        <hr />
        <h4>Loading Data. Please Wait.</h4>
        <hr />
    </div>
</div>
