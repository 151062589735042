<div class="box-container box-shadow" id="page-body">
    <h3>Reporting Units</h3>
    <div class="row">
        <div class="col text-right">
            <a class="btn btn-sm btn-success" routerLink="/manage/strategicBusinessUnits/create"
                [state]="{editMode:false}">New Reporting Unit
            </a>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-sm-12">
            <p-table #dt1 [value]="Data" dataKey="id"
                [globalFilterFields]="['name','operatingCompany.name','createdBy','modifiedBy','global','createdOn','modifiedOn']"
                stateStorage="session" stateKey="statedemo-sbu1" [paginator]="true" class="assetTable"
                [rowsPerPageOptions]="[formDataLength, 10, 20, 50, 100]" (onPage)="onShowEntryDropdownChange($event)"
                [rows]="formDataLength" [totalRecords]="formDataLength" paginatorPosition="both"
                styleClass="p-datatable-projects p-datatable-sm">
                <ng-template pTemplate="caption">
                    <span><b>Show Entries</b></span>
                    <div class="flex" style="float:right;margin-bottom: 1%;">
                        <span class="p-input-icon-left ml-auto">
                            <b>Search: </b><input #searchText pInputText type="text"
                                (input)="dt1.filterGlobal(searchText.value, 'contains')"
                                [value]="dt1.filters['global']? $any(dt1.filters['global'])?.value :''" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr style="background-color: lightskyblue;height: 40px;">
                        <th style="width:63px;">&nbsp;</th>
                        <th class="text-bold" style="width:270px;" pSortableColumn="name">Name<p-sortIcon field="name">
                            </p-sortIcon>
                        </th>
                        <th class="text-bold" pSortableColumn="operatingCompany.name"> Segment<p-sortIcon
                                field="operatingCompany.name"></p-sortIcon>
                        </th>
                        <th class="text-bold" pSortableColumn="createdBy">Created By<p-sortIcon field="createdBy">
                            </p-sortIcon>
                        </th>
                        <th class="text-bold" pSortableColumn="modifiedBy">Modified By<p-sortIcon field="modifiedBy">
                            </p-sortIcon>
                        </th>
                        <th class="text-bold" pSortableColumn="createdOn">Created Date<p-sortIcon>
                            </p-sortIcon>
                        </th>
                        <th class="text-bold" pSortableColumn="modifiedOn">Modified Date<p-sortIcon></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-asset>
                    <tr>
                        <td>
                            <button class="btn btn-sm btn-warning" style="color: white !important;"
                                routerLink="/manage/strategicBusinessUnits/update"
                                [queryParams]="{Id: asset.id}">Edit</button>
                        </td>
                        <td class="text-bold"><strong>{{asset.name}}</strong></td>
                        <td class="text-bold"><strong>{{asset.operatingCompany.name}}</strong></td>
                        <td class="text-bold">{{asset.createdBy}}</td>
                        <td class="text-bold">{{asset.modifiedBy}}</td>
                        <td class="text-bold">{{asset.createdOn| date: 'MM/dd/yyyy' }}</td>
                        <td class="text-bold">{{asset.modifiedOn| date: 'MM/dd/yyyy' }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorleft" let-state>
                    Showing {{(state.first) + 1}} to {{(state.totalRecords >
                    (state.rows * (state.page + 1))) ? state.rows * (state.page + 1) : state.totalRecords }}
                    of {{state.totalRecords}} entries
                </ng-template>
            </p-table>
        </div>
    </div>