import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AssetService } from 'src/app/admin/service/asset.service';

@Component({
  selector: 'app-useraccess-view',
  templateUrl: './useraccess-view.component.html',
  styleUrls: ['./useraccess-view.component.css']
})

export class UseraccessViewComponent implements OnInit {
  OrgData: any = [];
  ProjectData: any = [];
  formValue!: UntypedFormGroup;
  userRole: string = "";
  userId: number = 0;

  constructor(private formbuilder: UntypedFormBuilder, private apiService: AssetService, private route: ActivatedRoute) {
    this.formValue = this.formbuilder.group({
      firstname: [''],
      lastname: [''],
      email: [''],
      roleDescription: [],
    })
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.userId = Number(params['userId']);
      });

    this.apiService.getById("user/GetViewDetailsRoles?userId=", this.userId).subscribe(res => {
      console.log(res.user);
      this.userRole = res.user.role.roleDescription;
      this.OrgData = res.orgData;
      this.ProjectData = res.projectData;
      this.formValue.controls['email'].setValue(res.user.emailId);
      this.formValue.controls['roleDescription'].setValue(res.user.role.roleDescription);
      this.formValue.controls["firstname"].setValue(res.user.firstName);
      this.formValue.controls["lastname"].setValue(res.user.lastName);
    })
  }

}
