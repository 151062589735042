export class GreenHouseGas {
  greenHouseGasTotalGHGEmission: any;
  greenHouseGasScope1Emission: any;
  greenHouseGasScope2Emission: any;
  greenHouseGasTotalCarbonIntensity: any;
  greenHouseGasMethaneIntensity: any;
  greenHouseGasFlaringIntensity: any;
  greenHouseGasCarbonCost: any;

  originalGreenHouseGasTotalGHGEmission: any;
  originalGreenHouseGasScope1Emission: any;
  originalGreenHouseGasScope2Emission: any;
  originalGreenHouseGasTotalCarbonIntensity: any;
  originalGreenHouseGasMethaneIntensity: any;
  originalGreenHouseGasFlaringIntensity: any;
  originalGreenHouseGasCarbonCost: any;

  greenHouseGasTotal: any;
  greenHouseGasUnit: any;
  greenHouseGasCost: any;
  originalGreenHouseGasTotal: any;
  originalGreenHouseGasUnit: any;
  originalGreenHouseGasCost: any;
}
