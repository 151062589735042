
export class Go36ArP4LookbackHeaderModel {
  arP4LookbackId: number = 0;
  ArId: number = 0;
  supplementId?: number;
  lookbackDate?: Date;
  cvxShare?: any;
  lookbackType1: boolean = false;
  lookbackType2: boolean = false;
  lookbackType3: boolean = false;
  lookbackType4: boolean = false;
  singleProject: boolean = false;
  multiProject: boolean = false;
  projectCompletionSummary: string = '';
  keyInsights: string = '';
  defintionAsset: string = ''
  definitionFacilities: string = '';
  definitionWells: string = '';
  definitionReservoir: string = '';
  attachIpa: boolean = false;
  attachL1RootCause: boolean = false;
  attachL1MidPhase: boolean = false;
  attachL1ReviewTeam: boolean = false;
  attachL1ExplorationProspect: boolean = false;
  attachL1SubsurfaceBasisDesign: boolean = false;
  operationalResults: string = '';
  expenditureResults: string = '';
  scheduleResults: string = '';
  commercialAgreementResults: string = '';
  earlyOperationalResults: string = '';
  midPhase4Results: string = '';
  createdBy: string = '';
  createdDateTime: Date = new Date;
  modifiedBy: string = '';
  modifiedDateTime: Date = new Date;
  hideFromList: boolean = false;
  reportingOfficer: string = '';
  delegationOfAuthority: string = '';
  p1De: string = '';
  p1ProjectLead: string = '';
  p1Drb: string = '';
  p2De: string = '';
  p2ProjectLead: string = '';
  p2Drb: string = '';
  p3De: string = '';
  p3ProjectLead: string = '';
  p3Drb: string = '';
  p4De: string = '';
  p4ProjectLead: string = '';
  p4Drb: string = '';
  teamMember1Name: string = '';
  teamMember1Title: string = '';
  teamMember1Affiliation: string = '';
  teamMember2Name: string = '';
  teamMember2Title: string = '';
  teamMember2Affiliation: string = '';
  teamMember3Name: string = '';
  teamMember3Title: string = '';
  teamMember3Affiliation: string = '';
  teamMember4Name: string = '';
  teamMember4Title: string = '';
  teamMember4Affiliation: string = '';
  teamMember5Name: string = '';
  teamMember5Title: string = '';
  teamMember5Affiliation: string = '';
  teamMember6Name: string = '';
  teamMember6Title: string = '';
  teamMember6Affiliation: string = '';
  teamMember7Name: string = '';
  teamMember7Title: string = '';
  teamMember7Affiliation: string = '';
  teamMember8Name: string = '';
  teamMember8Title: string = '';
  teamMember8Affiliation: string = '';
  teamMember9Name: string = '';
  teamMember9Title: string = '';
  teamMember9Affiliation: string = '';
  teamMember10Name: string = '';
  teamMember10Title: string = '';
  teamMember10Affiliation: string = '';
  projectId: number = 0;
  projectLocation: string = '';
  arDate?: Date = new Date;
  nojv: boolean = false;
  operatorName: string = '';
  statusId: number = 0;
  program: boolean = false;
  businessResults: string = '';
  economicResults: string = '';
  originalId: number = 0;
  version: number = 0;
  versionName: string = '';
  approvedDateTime?: Date = new Date;
  isOnHold: boolean = false;
  isReadOnly: boolean = false;
  active: boolean = true;
  subsurfaceBasisofDesignId?: number;
  midPhase4Na: boolean = false;
  assetNa: boolean = false;
  facilitiesDefinitionNa: boolean = false;
  wellsDifinitionNa: boolean = false;
  reservoirDifinitionNa: boolean = false;
  isOldVersionOfForms: boolean = false;
  isNewCEisApplicableForms: boolean = true;
}
