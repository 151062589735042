<div id="tab3" class="panel tab-pane active">
    <div class="row text-center">
        <h4  style="font-size: 1.13em;line-height: 1.22em;">supporting details</h4>
    </div>
    <hr>
    <div>
        <div class="row">
            <table class="table table-bordered table-striped table-condensed">
                <tbody>
                    <tr>
                        <td>
                            <strong>Appropriated Amount $MM</strong>
                        </td>
                        <td rowspan="2" class="text-center">
                            This AR
                        </td>
                        <td>
                            &nbsp;
                        </td>
                        <td rowspan="2" class="text-center">
                            Previous ARs <br> Subtotal
                        </td>
                        <td colspan="3" class="text-center">
                            Previous ARs
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="row">
                                <div class="col-xs-12 padding-0">
                                    Chevron Share % = <br>
                                    <div class="col-xs-4 padding-0">
                                        <div class="input-group" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                            <input  [disabled]="isReadOnlyGlobalProjectLevel" type="text" class="form-control" [disabled]="isOnHold || isReadOnly"
                                                [(ngModel)]="part3Data.arChevronShare"
                                                (change)="UpdatePart3Data(part3Data)">
                                            <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                                <i class="glyphicon glyphicon-comment-outline"
                                                    *ngIf="!hasComment('Part3','AppropriatedAmount.ChevronShare')"
                                                    (click)="SaveComments('Part3','AppropriatedAmount.ChevronShare')">
                                                </i>
                                                <i class="glyphicon glyphicon-comment-filled commentColor"
                                                    *ngIf="hasComment('Part3','AppropriatedAmount.ChevronShare')"
                                                    (click)="SaveComments('Part3','AppropriatedAmount.ChevronShare')">
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            &nbsp;
                        </td>
                        <td>
                            <div class="input-group" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="text" maxlength="50" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArType1" (change)="UpdatePart3Data(part3Data)">
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR1.ARType')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.ARType')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR1.ARType')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.ARType')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="text" maxlength="50" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArType2" (change)="UpdatePart3Data(part3Data)">
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR2.ARType')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.ARType')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR2.ARType')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.ARType')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="text" maxlength="50" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArType3" (change)="UpdatePart3Data(part3Data)">
                                    <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR3.ARType')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.ARType')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR3.ARType')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.ARType')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" class="text-right">
                            Dates of previous ARs/supplements --&gt;
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <input type="text"  [disabled]="isReadOnlyGlobalProjectLevel" name="txtDate" class="form-control hasDatepicker" [disabled]="isOnHold || isReadOnly"
                                    [matDatepicker]="picker1" [(ngModel)]="part3Data.previousArDate1"
                                    (dateChange)=" UpdateDate(part3Data, 'date1')" (click)="picker1.open();utilityService.removeCDKAnouncer()">
                                <mat-datepicker #picker1></mat-datepicker>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR1.ARDate')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.ARDate')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR1.ARDate')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.ARDate')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <input  [disabled]="isReadOnlyGlobalProjectLevel" type="text" name="txtDate" class="form-control hasDatepicker" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArDate2" [matDatepicker]="picker2"
                                    (dateChange)=" UpdateDate(part3Data, 'date2')" (click)="picker2.open();utilityService.removeCDKAnouncer()">
                                <mat-datepicker #picker2></mat-datepicker>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR2.ARDate')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.ARDate')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR2.ARDate')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.ARDate')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <input  [disabled]="isReadOnlyGlobalProjectLevel" type="text" name="txtDate" class="form-control hasDatepicker" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArDate3" [matDatepicker]="picker3"
                                    (dateChange)=" UpdateDate(part3Data, 'date3')" (click)="picker3.open();utilityService.removeCDKAnouncer()">
                                <mat-datepicker #picker3></mat-datepicker>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR3.ARDate')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.ARDate')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR3.ARDate')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.ARDate')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="row">
                                1. Appropriated amount: 1a. or 1b. <br>
                            </div>
                            <div class="row">
                                <div class="col-xs-2 padding-1 text-bold">
                                    Basis:&nbsp;
                                </div>
                                <div class="col-xs-10 padding-0">
                                    <div class="form-check form-check-inline"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" class="form-check-input" type="radio" name="basisChevron" value="true"
                                            [checked]="part3Data.arBasisChevron" [disabled]="isOnHold || isReadOnly"
                                            (change)="basisChevronChange('Chevron', part3Data)">
                                        <label class="form-check-label" for="inlineRadio1" style="padding-left: 3px;">
                                            Chevron &nbsp;
                                        </label>
                                        &nbsp;
                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-check-input" type="radio" name="basisChevron" value="false"
                                            [checked]="!part3Data.arBasisChevron" [disabled]="isOnHold || isReadOnly"
                                            (change)="basisChevronChange('NOJV', part3Data)">
                                        <label class="form-check-label" for="inlineRadio2" style="padding-left: 3px;">
                                            NOJV &nbsp;
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollararAppAmount">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [(ngModel)]="part3Data.arAppAmount"
                                        (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div> 
                                <ng-template #noDollararAppAmount>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [(ngModel)]="part3Data.arAppAmount"
                                        (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.ThisAR.AppropriatedAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.AppropriatedAmount')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.ThisAR.AppropriatedAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.AppropriatedAmount')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarsection1Total">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="section1Total" [disabled]="isOnHold">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarsection1Total>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="section1Total" [disabled]="isOnHold">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','totalARAmount')"
                                        (click)="SaveComments('Part3','totalARAmount')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','totalARAmount')"
                                        (click)="SaveComments('Part3','totalARAmount')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArAppAmount1">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [(ngModel)]="part3Data.previousArAppAmount1"
                                        (change)="UpdatePart3Section1(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArAppAmount1>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [(ngModel)]="part3Data.previousArAppAmount1"
                                    (change)="UpdatePart3Section1(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}"  class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR1.AppropriatedAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.AppropriatedAmount')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR1.AppropriatedAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.AppropriatedAmount')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArAppAmount2">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [(ngModel)]="part3Data.previousArAppAmount2"
                                        (change)="UpdatePart3Section1(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArAppAmount2>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [(ngModel)]="part3Data.previousArAppAmount2"
                                        (change)="UpdatePart3Section1(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR2.AppropriatedAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.AppropriatedAmount')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR2.AppropriatedAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.AppropriatedAmount')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArAppAmount3">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [(ngModel)]="part3Data.previousArAppAmount3"
                                        (change)="UpdatePart3Section1(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArAppAmount3>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [(ngModel)]="part3Data.previousArAppAmount3"
                                    (change)="UpdatePart3Section1(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR3.AppropriatedAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.AppropriatedAmount')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR3.AppropriatedAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.AppropriatedAmount')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td><i>Memo: capital lease amount</i></td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollararCapLeaseAmount">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [(ngModel)]="part3Data.arCapLeaseAmount"
                                        (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollararCapLeaseAmount>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [(ngModel)]="part3Data.arCapLeaseAmount"
                                        (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.ThisAR.CapitalLeaseAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.CapitalLeaseAmount')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.ThisAR.CapitalLeaseAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.CapitalLeaseAmount')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArCapLeaseAmount1">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control"
                                        [(ngModel)]="part3Data.previousArCapLeaseAmount1"
                                        (change)="UpdatePart3CapLease(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArCapLeaseAmount1>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control"
                                        [(ngModel)]="part3Data.previousArCapLeaseAmount1"
                                        (change)="UpdatePart3CapLease(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR1.CapitalLeaseAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.CapitalLeaseAmount')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR1.CapitalLeaseAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.CapitalLeaseAmount')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArCapLeaseAmount2">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArCapLeaseAmount2"
                                        (change)="UpdatePart3CapLease(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArCapLeaseAmount2>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArCapLeaseAmount2"
                                        (change)="UpdatePart3CapLease(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR2.CapitalLeaseAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.CapitalLeaseAmount')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR2.CapitalLeaseAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.CapitalLeaseAmount')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArCapLeaseAmount3">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArCapLeaseAmount3"
                                        (change)="UpdatePart3CapLease(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArCapLeaseAmount3>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArCapLeaseAmount3"
                                    (change)="UpdatePart3CapLease(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR3.CapitalLeaseAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.CapitalLeaseAmount')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR3.CapitalLeaseAmount')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.CapitalLeaseAmount')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td><i>Memo: amount financed by others</i></td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollararFinAmountOther">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [(ngModel)]="part3Data.arFinAmountOther"
                                        (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollararFinAmountOther>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [(ngModel)]="part3Data.arFinAmountOther"
                                    (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.ThisAR.FinancialAmountOther')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.FinancialAmountOther')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.ThisAR.FinancialAmountOther')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.FinancialAmountOther')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArFinAmountOther1">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArFinAmountOther1"
                                        (change)="UpdatePart3FinAmount(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArFinAmountOther1>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArFinAmountOther1"
                                    (change)="UpdatePart3FinAmount(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR1.FinancialAmountOther')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.FinancialAmountOther')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR1.FinancialAmountOther')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.FinancialAmountOther')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArFinAmountOther2">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArFinAmountOther2"
                                        (change)="UpdatePart3FinAmount(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArFinAmountOther2>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArFinAmountOther2"
                                        (change)="UpdatePart3FinAmount(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR2.FinancialAmountOther')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.FinancialAmountOther')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR2.FinancialAmountOther')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.FinancialAmountOther')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArFinAmountOther3">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArFinAmountOther3"
                                        (change)="UpdatePart3FinAmount(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArFinAmountOther3>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArFinAmountOther3"
                                    (change)="UpdatePart3FinAmount(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR3.FinancialAmountOther')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.FinancialAmountOther')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR3.FinancialAmountOther')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.FinancialAmountOther')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="table table-bordered">
                        <td colspan="7">
                            <strong>Associated amounts $MM Chevron share</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>2b. Working capital</td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollararWorkingCapital">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.arWorkingCapital"
                                        (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollararWorkingCapital>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.arWorkingCapital"
                                        (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.ThisAR.WorkingCapital')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.WorkingCapital')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.ThisAR.WorkingCapital')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.WorkingCapital')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarsection2bTotal">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="section2bTotal" [disabled]="isOnHold">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarsection2bTotal>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                    [(ngModel)]="section2bTotal" [disabled]="isOnHold">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','totalWorkingCapital')"
                                        (click)="SaveComments('Part3','totalWorkingCapital')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','totalWorkingCapital')"
                                        (click)="SaveComments('Part3','totalWorkingCapital')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArWorkingCapital1">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArWorkingCapital1"
                                        (change)="UpdatePart3Section2b(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArWorkingCapital1>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArWorkingCapital1"
                                    (change)="UpdatePart3Section2b(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR1.WorkingCapital')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.WorkingCapital')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR1.WorkingCapital')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.WorkingCapital')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArWorkingCapital2">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArWorkingCapital2"
                                        (change)="UpdatePart3Section2b(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArWorkingCapital2>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArWorkingCapital2"
                                        (change)="UpdatePart3Section2b(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR2.WorkingCapital')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.WorkingCapital')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR2.WorkingCapital')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.WorkingCapital')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArWorkingCapital3">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArWorkingCapital3"
                                        (change)="UpdatePart3Section2b(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArWorkingCapital3>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArWorkingCapital3"
                                    (change)="UpdatePart3Section2b(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR3.WorkingCapital')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.WorkingCapital')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR3.WorkingCapital')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.WorkingCapital')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>2c. Contractual obligations, <br><small>sum of payments</small></td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollararContractualObl">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.arContractualObl"
                                        (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollararContractualObl>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.arContractualObl"
                                        (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.ThisAR.ContractualObligations')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.ContractualObligations')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.ThisAR.ContractualObligations')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.ContractualObligations')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarsection2cTotal">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="section2cTotal">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarsection2cTotal>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                    [(ngModel)]="section2cTotal">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','totalContractual')"
                                        (click)="SaveComments('Part3','totalContractual')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','totalContractual')"
                                        (click)="SaveComments('Part3','totalContractual')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArContractualObl1">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArContractualObl1"
                                        (change)="UpdatePart3Section2c(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArContractualObl1>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArContractualObl1"
                                    (change)="UpdatePart3Section2c(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR1.ContractualObligations')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.ContractualObligations')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR1.ContractualObligations')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.ContractualObligations')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArContractualObl2">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArContractualObl2"
                                        (change)="UpdatePart3Section2c(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArContractualObl2>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArContractualObl2"
                                    (change)="UpdatePart3Section2c(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR2.ContractualObligations')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.ContractualObligations')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR2.ContractualObligations')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.ContractualObligations')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArContractualObl3">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArContractualObl3"
                                        (change)="UpdatePart3Section2c(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArContractualObl3>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArContractualObl3"
                                    (change)="UpdatePart3Section2c(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR3.ContractualObligations')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.ContractualObligations')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR3.ContractualObligations')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.ContractualObligations')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>2d. Value of existing assets used in projects</td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollararExistingAsset">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.arExistingAsset"
                                        (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollararExistingAsset>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.arExistingAsset"
                                    (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.ThisAR.ExistingAssets')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.ExistingAssets')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.ThisAR.ExistingAssets')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.ExistingAssets')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarsection2dTotal">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="section2dTotal">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarsection2dTotal>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                    [(ngModel)]="section2dTotal">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','totalExistingAssets')"
                                        (click)="SaveComments('Part3','totalExistingAssets')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','totalExistingAssets')"
                                        (click)="SaveComments('Part3','totalExistingAssets')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArExistingAsset1">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArExistingAsset1"
                                        (change)="UpdatePart3Section2d(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArExistingAsset1>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArExistingAsset1"
                                    (change)="UpdatePart3Section2d(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR1.ExistingAssets')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.ExistingAssets')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR1.ExistingAssets')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.ExistingAssets')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArExistingAsset2">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArExistingAsset2"
                                        (change)="UpdatePart3Section2d(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArExistingAsset2>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArExistingAsset2"
                                    (change)="UpdatePart3Section2d(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR2.ExistingAssets')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.ExistingAssets')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR2.ExistingAssets')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.ExistingAssets')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArExistingAsset3">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArExistingAsset3"
                                        (change)="UpdatePart3Section2d(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArExistingAsset3>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArExistingAsset3"
                                    (change)="UpdatePart3Section2d(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR3.ExistingAssets')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.ExistingAssets')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR3.ExistingAssets')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.ExistingAssets')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>2e. Other commitments required to realize this AR's benefits</td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollararCommitmentsReq">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.arCommitmentsReq"
                                        (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollararCommitmentsReq>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.arCommitmentsReq"
                                    (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.ThisAR.CommitmentsRequired')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.CommitmentsRequired')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.ThisAR.CommitmentsRequired')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.CommitmentsRequired')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            &nbsp;
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarsection2eTotal">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="section2eTotal">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarsection2eTotal>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                    [(ngModel)]="section2eTotal">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','totalCommitment')"
                                        (click)="SaveComments('Part3','totalCommitment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','totalCommitment')"
                                        (click)="SaveComments('Part3','totalCommitment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArCommitmentsReq1">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArCommitmentsReq1"
                                        (change)="UpdatePart3Section2e(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArCommitmentsReq1>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArCommitmentsReq1"
                                    (change)="UpdatePart3Section2e(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR1.CommitmentsRequired')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.CommitmentsRequired')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR1.CommitmentsRequired')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.CommitmentsRequired')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArCommitmentsReq2">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArCommitmentsReq2"
                                        (change)="UpdatePart3Section2e(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArCommitmentsReq2>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArCommitmentsReq2"
                                    (change)="UpdatePart3Section2e(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR2.CommitmentsRequired')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.CommitmentsRequired')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR2.CommitmentsRequired')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.CommitmentsRequired')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArCommitmentsReq3">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                        [(ngModel)]="part3Data.previousArCommitmentsReq3"
                                        (change)="UpdatePart3Section2e(part3Data)">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArCommitmentsReq3>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [disabled]="isOnHold || isReadOnly"
                                    [(ngModel)]="part3Data.previousArCommitmentsReq3"
                                    (change)="UpdatePart3Section2e(part3Data)">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR3.CommitmentsRequired')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.CommitmentsRequired')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR3.CommitmentsRequired')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.CommitmentsRequired')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>2f. Subtotal (2a-2e)</strong></td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollararSubtotal">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="part3Data.arSubtotal">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollararSubtotal>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="part3Data.arSubtotal">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.ThisAR.Subtotal')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.Subtotal')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.ThisAR.Subtotal')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.Subtotal')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarsection2Total">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="section2Total">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarsection2Total>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                    [(ngModel)]="section2Total">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','totalSub')"
                                        (click)="SaveComments('Part3','totalSub')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','totalSub')"
                                        (click)="SaveComments('Part3','totalSub')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArSubtotal1">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="part3Data.previousArSubtotal1">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArSubtotal1>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                    [(ngModel)]="part3Data.previousArSubtotal1">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR1.Subtotal')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.Subtotal')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR1.Subtotal')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.Subtotal')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArSubtotal2">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="part3Data.previousArSubtotal2">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArSubtotal2>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="part3Data.previousArSubtotal2">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR2.Subtotal')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.Subtotal')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR2.Subtotal')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.Subtotal')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArSubtotal3">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="part3Data.previousArSubtotal3">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArSubtotal3>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                    [(ngModel)]="part3Data.previousArSubtotal3">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR3.Subtotal')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.Subtotal')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR3.Subtotal')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.Subtotal')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr class="table table-bordered">
                        <td colspan="7">
                            <strong>Total commitment $MM Chevron share</strong>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>3. Subtotal (1 + 2f)</strong></td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollararSubtotalCommit">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="part3Data.arSubtotalCommit">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollararSubtotalCommit>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="part3Data.arSubtotalCommit">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}"  class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.ThisAR.SubtotalCommitment')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.SubtotalCommitment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.ThisAR.SubtotalCommitment')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.ThisAR.SubtotalCommitment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarsection3Total">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="section3Total">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarsection3Total>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                    [(ngModel)]="section3Total">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','totalSubCommitment')"
                                        (click)="SaveComments('Part3','totalSubCommitment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','totalSubCommitment')"
                                        (click)="SaveComments('Part3','totalSubCommitment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArSubtotalCommit1">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="part3Data.previousArSubtotalCommit1">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArSubtotalCommit1>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="part3Data.previousArSubtotalCommit1">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR1.SubtotalCommitment')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.SubtotalCommitment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR1.SubtotalCommitment')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR1.SubtotalCommitment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArSubtotalCommit2">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="part3Data.previousArSubtotalCommit2">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArSubtotalCommit2>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                    [(ngModel)]="part3Data.previousArSubtotalCommit2">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR2.SubtotalCommitment')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.SubtotalCommitment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR2.SubtotalCommitment')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR2.SubtotalCommitment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarpreviousArSubtotalCommit3">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="part3Data.previousArSubtotalCommit3">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarpreviousArSubtotalCommit3>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly"
                                        [(ngModel)]="part3Data.previousArSubtotalCommit3">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.AR3.SubtotalCommitment')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.SubtotalCommitment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.AR3.SubtotalCommitment')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.AR3.SubtotalCommitment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>4. Total Commitment (this AR + previous ARs)</strong></td>
                        <td colspan="3">
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollartotalCommitment">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control col-xs-12" readonly="readonly"
                                        [(ngModel)]="part3Data.totalCommitment">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollartotalCommitment>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control col-xs-12" readonly="readonly"
                                        [(ngModel)]="part3Data.totalCommitment">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','AppropriatedAmount.TotalCommitment')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.TotalCommitment')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','AppropriatedAmount.TotalCommitment')"
                                        (click)="SaveComments('Part3','AppropriatedAmount.TotalCommitment')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td colspan="3">
                            &nbsp;
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row">
            <table class="table table-bordered table-striped">
                <tbody>
                    <tr>
                        <td><strong>5. Briefly discuss the basis for items 1 and 2</strong></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="form-row padding-0" id="ckeditor">
                                <div class="col-xs-12">
                                    <div class="row">
                                        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isReadOnly}">
                                            <ckeditor [disabled]="isReadOnlyGlobalProjectLevel"  #section5 [editor]="Editor" [config]="config" id="section5"
                                                name="section5" [(ngModel)]="part3Data.brieflyDiscussBasic"
                                                (ready)="onReady($event,'section5')" [disabled]="isOnHold || isReadOnlyGlobalProjectLevel">
                                            </ckeditor>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-4 padding-0" style="display: none;">
                                    <div class="form-row">
                                        <div class="text-center">
                                            <hr>
                                            <span>There are no comments.</span>
                                            <hr>
                                        </div>
                                        <table class="col-xs-12 table-striped">
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row">
            <table class="table table-bordered table-striped">
                <tbody>
                    <tr>
                        <td class="border-dark col-xs-6">
                            <strong>6. Currency</strong> % of non-US $ currencies in the investment
                        </td>
                        <td class="border-dark col-xs-6">
                            <strong>7. Exchange rates</strong> used to convert US $
                        </td>
                    </tr>
                    <tr>
                        <td class="col-xs-6">
                            <textarea  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" rows="2" [(ngModel)]="part3Data.currencyPercentage"
                                (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"></textarea>
                        </td>
                        <td class="col-xs-6">
                            <textarea  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" rows="2" [(ngModel)]="part3Data.exchangeRate"
                                (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"></textarea>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row">
            <table class="table table-bordered table-striped">
                <tbody>
                    <tr>
                        <td colspan="8">
                          <strong>
                            8. {{ IsNewCEisApplicableForms ? "Capex": "C&amp;E" }} and expense to execute the appropriated CPDEP Phase of the Project,
                            Chevron share in USD
                          </strong>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-center text-bold col-xs-1">Year</td>
                        <td class="text-center">
                            <span class="text-bold">1st Year</span> <br>
                            <div class="input-group">
                                <input  [disabled]="isReadOnlyGlobalProjectLevel"  type="number" class="form-control input-year" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                                    [(ngModel)]="part3Data.startingYear" (change)="UpdatePart3StartYear(part3Data)">
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','StartYear')"
                                        (click)="SaveComments('Part3','StartYear')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','StartYear')"
                                        (click)="SaveComments('Part3','StartYear')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td class="text-center text-bold"><span class="text-bold">2nd Year</span><br>
                            <span *ngIf="part3Data.startingYear">{{ secondYear }}</span>
                        </td>
                        <td class="text-center text-bold"><span class="text-bold">3rd Year</span><br>
                            <span *ngIf="part3Data.startingYear">{{ thirdYear }}</span>
                        </td>
                        <td class="text-center text-bold"><span class="text-bold">4th Year</span><br>
                            <span *ngIf="part3Data.startingYear">{{ fourthYear }}</span>
                        </td>
                        <td class="text-center text-bold"><span class="text-bold">5th Year</span><br>
                            <span *ngIf="part3Data.startingYear">{{ fifthYear }}</span>
                        </td>
                        <td class="text-center text-bold">
                            <span class="text-bold">5th Year &amp; Beyond</span><br>
                            <span *ngIf="part3Data.startingYear">{{ fifthYearBeyond }}+</span>
                        </td>
                        <td class="text-center text-bold">TOTAL</td>
                    </tr>
                    <tr>
                      <td>
                        {{ IsNewCEisApplicableForms ? "Capex": "C&amp;E" }}
                      </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarceYear1">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.ceYear1"
                                        (change)="UpdatePart3Section8Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div> 
                                <ng-template #noDollarceYear1>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.ceYear1"
                                        (change)="UpdatePart3Section8Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','CE.Year1')"
                                        (click)="SaveComments('Part3','CE.Year1')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','CE.Year1')"
                                        (click)="SaveComments('Part3','CE.Year1')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarceYear2">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.ceYear2"
                                        (change)="UpdatePart3Section8Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarceYear2>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.ceYear2"
                                    (change)="UpdatePart3Section8Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','CE.Year2')"
                                        (click)="SaveComments('Part3','CE.Year2')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','CE.Year2')"
                                        (click)="SaveComments('Part3','CE.Year2')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarceYear3">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.ceYear3"
                                        (change)="UpdatePart3Section8Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarceYear3>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.ceYear3"
                                    (change)="UpdatePart3Section8Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','CE.Year3')"
                                        (click)="SaveComments('Part3','CE.Year3')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','CE.Year3')"
                                        (click)="SaveComments('Part3','CE.Year3')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarceYear4">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.ceYear4"
                                        (change)="UpdatePart3Section8Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarceYear4>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.ceYear4"
                                        (change)="UpdatePart3Section8Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','CE.Year4')"
                                        (click)="SaveComments('Part3','CE.Year4')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','CE.Year4')"
                                        (click)="SaveComments('Part3','CE.Year4')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarceYear5">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.ceYear5"
                                        (change)="UpdatePart3Section8Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarceYear5>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.ceYear5"
                                    (change)="UpdatePart3Section8Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','CE.Year5')"
                                        (click)="SaveComments('Part3','CE.Year5')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','CE.Year5')"
                                        (click)="SaveComments('Part3','CE.Year5')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarceTotalBeyondYear5">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.ceTotalBeyondYear5"
                                        (change)="UpdatePart3Section8Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarceTotalBeyondYear5>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="part3Data.ceTotalBeyondYear5"
                                        (change)="UpdatePart3Section8Data(part3Data)" [disabled]="isOnHold || isReadOnly">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','CE.YearBeyond')"
                                        (click)="SaveComments('Part3','CE.YearBeyond')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','CE.YearBeyond')"
                                        (click)="SaveComments('Part3','CE.YearBeyond')">
                                    </i>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="input-group"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarceTotal">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly || isReadOnly"
                                        [(ngModel)]="part3Data.ceTotal">
                                    <i style="z-index: 1111;">$</i>  
                                </div>
                                <ng-template #noDollarceTotal>
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" readonly="readonly || isReadOnly"
                                        [(ngModel)]="part3Data.ceTotal">
                                </ng-template>
                                <div  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" class="input-group-addon">
                                    <i class="glyphicon glyphicon-comment-outline"
                                        *ngIf="!hasComment('Part3','CE.SubTotal')"
                                        (click)="SaveComments('Part3','CE.SubTotal')">
                                    </i>
                                    <i class="glyphicon glyphicon-comment-filled commentColor"
                                        *ngIf="hasComment('Part3','CE.SubTotal')"
                                        (click)="SaveComments('Part3','CE.SubTotal')">
                                    </i>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row">
            <table class="table table-bordered table-striped padding-5">
                <tbody>
                    <tr>
                        <td colspan="3" class="row text-left text-bold">
                            9. Decision executive, DRB and Project Lead
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <div class="row text-center text-bold">
                                Phase 1
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label for="staticEmail" class="form-label">Decision Executive:</label>
                            <textarea  [disabled]="isReadOnlyGlobalProjectLevel" rows="3" class="form-control" maxlength="500" [(ngModel)]="part3Data.p1De"
                                (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"></textarea>
                        </td>
                        <td>
                            <label for="staticEmail" class="form-label">Project Manager or Lead:</label>
                            <textarea  [disabled]="isReadOnlyGlobalProjectLevel" rows="3" class="form-control" maxlength="500" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                                [(ngModel)]="part3Data.p1ProjectLead" (change)="UpdatePart3Data(part3Data)"></textarea>
                        </td>
                        <td>
                            <label for="staticEmail" class="form-label">DRB:</label>
                            <textarea [disabled]="isReadOnlyGlobalProjectLevel"  rows="3" class="form-control" maxlength="500" [(ngModel)]="part3Data.p1Drb"
                                (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"></textarea>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <div class="row text-center text-bold">
                                Phase 2
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label for="staticEmail" class="form-label">Decision Executive:</label>
                            <textarea  [disabled]="isReadOnlyGlobalProjectLevel" rows="3" class="form-control" maxlength="500" [(ngModel)]="part3Data.p2De"
                                (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"></textarea>
                        </td>
                        <td>
                            <label for="staticEmail" class="form-label">Project Manager or Lead:</label>
                            <textarea  [disabled]="isReadOnlyGlobalProjectLevel" rows="3" class="form-control" maxlength="500" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                                [(ngModel)]="part3Data.p2ProjectLead" (change)="UpdatePart3Data(part3Data)"></textarea>
                        </td>
                        <td>
                            <label for="staticEmail" class="form-label">DRB:</label>
                            <textarea  [disabled]="isReadOnlyGlobalProjectLevel" rows="3" class="form-control" maxlength="500" [(ngModel)]="part3Data.p2Drb"
                                (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"></textarea>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <div class="row text-center text-bold">
                                Phase 3
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label for="staticEmail" class="form-label">Decision Executive:</label>
                            <textarea  [disabled]="isReadOnlyGlobalProjectLevel" rows="3" class="form-control" maxlength="500" [(ngModel)]="part3Data.p3De"
                                (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"></textarea>
                        </td>
                        <td>
                            <label for="staticEmail" class="form-label">Project Manager or Lead:</label>
                            <textarea  [disabled]="isReadOnlyGlobalProjectLevel" rows="3" class="form-control" maxlength="500" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"
                                [(ngModel)]="part3Data.p3ProjectLead" (change)="UpdatePart3Data(part3Data)"></textarea>
                        </td>
                        <td>
                            <label for="staticEmail" class="form-label">DRB:</label>
                            <textarea  [disabled]="isReadOnlyGlobalProjectLevel" rows="3" class="form-control" maxlength="500" [(ngModel)]="part3Data.p3Drb"
                                (change)="UpdatePart3Data(part3Data)" [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"></textarea>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <hr>
</div>
