<div class="custom-modal-header">
  <button type="button" (click)="onDismiss()" class="close-btn" aria-hidden="true">×</button>
  <h4 mat-dialog-title>{{title}}</h4>
</div>
<hr>
<div mat-dialog-content>
  <textarea rows="3" type="text" class="form-control" [(ngModel)]="comment"
  [disabled]="isOnHold || isReadOnly"></textarea>
</div>
<hr>
<div mat-dialog-actions style="float: right;">
  <button mat-button (click)="onDismiss()" style="color: black !important;" class="btn btn-default"> Cancel </button> &nbsp;&nbsp;
  <button mat-raised-button  style="color: white !important;" class="btn btn-primary" (click)="onSave()" [disabled]="isOnHold || isReadOnly"> Save </button>
</div>