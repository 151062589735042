export class EAPart4ViewModel {
    go36EaP4LookbackHeaders = new EAP4LookbackHeaderModel();
    Part4Performance = new EAP4LookbackPerformance();
    Part4Document: EAP4LookbackDocument[] = [];
}

export class EAP4LookbackHeaderModel {
    arLookbackId: number = 0;
    arId: number = 0;
    lookbackType1: boolean = false;
    lookbackType2: boolean = false;
    lookbackType3: boolean = false;
    lookbackType4: boolean = false;
    singleProject: boolean = false;
    multiProject: boolean = false;
    reportingOfficer: string = '';
    individualDoa: string = '';
    projectCompletionSummary: string = '';
    keyInsights: string = '';
    operationalResults: string = '';
    expenditureResults: string = '';
    scheduleResults: string = '';
    commercialAgreementResults: string = '';
    earlyOperationalResults: string = '';
    appraisalWellResults: string = '';
    attachIpa: boolean = false;
    attachL1RootCause: boolean = false;
    attachL1ReviewTeam: boolean = false;
    attachL1ExplorationProspect: boolean = false;
    attachSubsurfaceBasisDesign: boolean = false;
    p1De: string = '';
    p1ProjectManager: string = '';
    p1Drb: string = '';
    p2De: string = '';
    p2ProjectManager: string = '';
    p2Drb: string = '';
    p3De: string = '';
    p3ProjectManager: string = '';
    p3Drb: string = '';
    p4De: string = '';
    p4ProjectManager: string = '';
    p4Drb: string = '';
    teamMembers: string = '';
    createdBy: string = '';
    createdDateTime: Date = new Date;
    modifiedBy: string = '';
    modifiedDateTime: Date = new Date;
    hideFromList: boolean = false;
    statusId: number = 0;
    projectId: number = 0;
    projectLocation: string = '';
    exploration: boolean = false;
    appraisal: boolean = false;
    nojv: boolean = false;
    originalDate?: Date = new Date;
    ardate?: Date = new Date;
    cvxShare?: any;
    operatorName: string = '';
    originalId: number = 0;
    version: number = 0;
    versionName: string = '';
    approvedDateTime?: Date = new Date;
    isOnHold: boolean = false;
    isReadOnly: boolean = false;
    active: boolean = true;
}

export class EAP4LookbackPerformance {
    lookbackPerformanceId: number = 0;
    lookbackId: number = 0;
    dataDate?: Date = new Date;
    trirActualOutcomes?: any;
    trirBenchTopQuartile?: any;
    trirBenchIndustryAverage?: any;
    dafwrActualOutcomes?: any;
    dafwrBenchTopQuartile?: any;
    dafwrBenchIndustryAverage?: any;
    executionScheduleEv?: any;
    executionScheduleP10?: any;
    executionScheduleP90?: any;
    executionScheduleActualOutcomes?: any;
    executionScheduleBenchTopQuartile?: any;
    executionScheduleBenchIndustryAverage?: any;
    ceEv?:any;
    ceP10?: any;
    ceP90?:  any;
    ceArBasisNojv: boolean = false;
    ceArBasisEv?: any;
    ceArBasisActualOutcomes?: any;
    ceArBasisNormalized?: any;
    estUltimateRecoveryMmboEv?:any;
    estUltimateRecoveryMmboP10?:any;
    estUltimateRecoveryMmboP90?:any;
    estUltimateRecoveryMmboActualOutcomes?:any;
    estUltimateRecoveryBcfEv?:any;
    estUltimateRecoveryBcfP10?:any;
    estUltimateRecoveryBcfP90?:any;
    estUltimateRecoveryBcfActualOutcomes?:any;
    estUltimateRecoveryMmboeEv?:any;
    estUltimateRecoveryMmboeP10?:any;
    estUltimateRecoveryMmboeP90?:any;
    estUltimateRecoveryMmboeActualOutcomes?:any;
    createdBy: string = '';
    createdDatetime: Date = new Date;
    modifiedBy: string = '';
    modifiedDatetime: Date = new Date;
    hidefromlist: boolean = false;
    benchmarkExecutionEv?:  any;
    benchmarkExecutionActualOutcomes?: any;
    benchmarkExecutionNormalized?:  any;
    benchmarkExecutionTopQuartile?: any;
    benchmarkExecutionIndustryAverage?: any;
}

export class LessonLearnedDetail {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn: Date = new Date();
    modifiedOn: Date = new Date();
    order: number = 0;
    lessonLearnedTypeId: number = 0;
    formId: number = 0;
    description?: string = '';
    title?: string = '';
    projectId: number = 0;
    lessonLearnedCategoryId?: number = 0;
    formType?: string = '';
    nakeyDecision: boolean = false;
    naLessonLearned: boolean = true;
}

export class EAP4LookbackDocument {
    documentId: number = 0;
    p4LookbackId: number = 0;
    docName: string = '';
    docContentType: string = '';
    docContent?: Blob;
    createdBy: string = '';
    createdByName: string = '';
    createdDatetime: Date = new Date();
    modifiedBy: string = '';
    modifiedDatetime: Date = new Date();
    hidefromlist: boolean = false;
}