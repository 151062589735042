import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Versions, ViewFormModel } from '../Model/viewform.model';
import { Go36projectService } from '../service/go36project.service';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/Shared/utility.service';

interface OpcoInfo {
  OpcoId: number;
  OpcoName: string;
}
interface SbuInfo {
  SbuId: number;
  SbuName: string;
}
interface BuInfo {
  BuId: number;
  BuName: string;
  OpcoId: number;
  SbuId: number;
}
interface AssetInfo {
  AssetId: number;
  AssetName: string;
}
@Component({
  selector: 'app-copy-project',
  templateUrl: './copy-project.component.html',
  styleUrls: ['./copy-project.component.css']
})
export class CopyProjectComponent implements OnInit {
  Dropdownprojectsdata: any;
  formValue: UntypedFormGroup;
  SelectedValue: any;
  OpcoList: Array<OpcoInfo> = [];
  SbuList: Array<SbuInfo> = [];
  BuList: Array<BuInfo> = [];
  data: any;
  AssetList: Array<AssetInfo> = [];
  buData: any;
  SbuData: any;
  ProjectId: any;
  editMode: boolean = localStorage.getItem('editMode') == 'true';
  projects: ViewFormModel[] = [];
  pID: any;
  projectName: string = "";
  versionList: Versions[] = [];
  butDisabled: boolean = false;
  isChecked: boolean = true;
  currentProject: any;
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  formIds: any;
  formIds1: any;
  currentProjectId: number = 0;
  selectedFormsDictionary = new Map<string, string>();
  jsonObjectSelectedForms = {}
  constructor(private projectservice: Go36projectService, private FormBuilder: UntypedFormBuilder, 
    private router: Router, public utilityService: UtilityService) {
    this.formValue = this.FormBuilder.group({
      ProjectId: '',
      ProjectName: '',
      OpcoId: '0',
      SbuId: '0',
      BuId: '0',
      CreatedBy: "",
      AssetClassId: '',
      ModifiedBy: '',
      IsSsbodavailable: false,
      AssetMegaFriendlyName: '',
      Rsid: '',
      SpecialProjectType: 0
    })
  }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.butDisabled = true;
    // //this.isChecked = true;
    this.getdropdown();
    if (this.editMode) {
      this.GetEditData();
    }
    this.getAllViewForms();
  }
  getdropdown() {
    this.projectservice.getDropdownData().subscribe(res => {
      this.Dropdownprojectsdata = res; //master data
      this.OpcoList = res[3];
      this.SbuList = res[4];
      this.BuList = res[0];
      this.AssetList = res[2];
    });
  }

  GetEditData() {
    {
      if (localStorage.getItem("goProject")) {
        const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")))
        this.data = { project: JSON.parse(projectDetail) };
      }
      this.formValue.controls["ProjectName"].setValue(this.data.project.ProjectName);
      this.formValue.controls["SbuId"].setValue(this.data.project.SbuId);
      this.formValue.controls["OpcoId"].setValue(this.data.project.OpcoId);
      this.formValue.controls["BuId"].setValue(this.data.project.BuId);
      this.formValue.controls["AssetClassId"].setValue(this.data.project.AssetClassId);
      this.formValue.controls["Rsid"].setValue(this.data.project.Rsid);
      this.formValue.controls["ProjectId"].setValue(this.data.project.ProjectId);

      this.ProjectId = this.data.project.ProjectId;
    }
  }
  getAllViewForms() {
    const project = JSON.parse(JSON.stringify(localStorage.getItem("goProject")))
    this.currentProject = JSON.parse(project);


    this.pID = this.currentProject.ProjectId;
    this.ProjectId = this.data.project.ProjectId;
    this.projectName = this.data.project.ProjectName;
    this.projectservice.GetFormsByProjectIdCopyForm(this.pID).subscribe(res => {
      this.projects = res;
      if (res) {
        this.currentProjectId = this.projects[0].ProjectId

        let map = new Map<string, string>();
        for (let i = 0; i < this.projects.length; i++) {
          map.set(this.projects[i].RequestType.toString() + i, this.projects[i].OriginalId.toString());

          if (this.projects[i].RequestType.startsWith('Lookback')) {
            this.selectedFormsDictionary.set(this.projects[i].DocumentType.toString() + '|' + this.projects[i].OriginalId.toString() + '|' + this.projects[i].Version.toString(), this.projects[i].OriginalId.toString());
          }
          else {
            this.selectedFormsDictionary.set(this.projects[i].DocumentType.toString() + '|' + this.projects[i].OriginalId.toString(), this.projects[i].OriginalId.toString());
          }
         
        }

        let jsonObject = {};
        map.forEach((value, key) => { jsonObject[key] = value; });
        this.formIds = jsonObject;

        let defaultSelectedForms = {};
        this.selectedFormsDictionary.forEach((value, key) => { defaultSelectedForms[key] = value; });
        this.formIds1 = defaultSelectedForms;
      }
    })
  }

  SaveCopyForm() {
    this.projectservice.SaveCopyForm(0, this.formValue.value.ProjectName, this.objectId, this.provId, this.currentProjectId, JSON.stringify(this.formIds1)).subscribe(res => {
      this.projects = res;
      this.router.navigate(["/go36project/project"]);
    })
  }
  onCheckboxChange(e: any, formId: string, requestType: string, documentType: string, version: number) {
    let jsonObject = {};
    if (e.target.checked) {
      if (requestType.startsWith('Lookback')) {
        this.selectedFormsDictionary.set(documentType + '|' + formId + '|' + version, formId);
      }
      else {
        this.selectedFormsDictionary.set(documentType + '|' + formId, formId);
      }
      // // if(requestType == 'LL')
      // // {
      // // this.selectedFormsDictionary.set(documentType + '|' + formId, formId); 
      // // }
      // // else
      // // this.selectedFormsDictionary.set(requestType + '|' + formId, formId); 
      // //let jsonObject = {};
      this.selectedFormsDictionary.forEach((value, key) => { jsonObject[key] = value; });
    }
    else {
      if (requestType.startsWith('Lookback')) {
        this.selectedFormsDictionary.delete(documentType + '|' + formId + '|' + version);
      }
      else {
        this.selectedFormsDictionary.delete(documentType + '|' + formId);
      }
      // // if(requestType == 'LL')
      // //   {
      // //   this.selectedFormsDictionary.delete(documentType + '|' + formId);
      // //   }
      // //   else
      // //  {
      // //   this.selectedFormsDictionary.delete(requestType + '|' + formId);
      // //  }
      // //let jsonObject = {};
      this.selectedFormsDictionary.forEach((value, key) => { jsonObject[key] = value; });

    }
    this.formIds1 = jsonObject;
  }

  submitForm() {
    //This is not Impmemented   
  }
}