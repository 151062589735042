import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from 'src/app/Shared/utility.service';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';

@Component({
  selector: 'app-mylookbackforms',
  templateUrl: './mylookbackforms.component.html',
  styleUrls: ['./mylookbackforms.component.css']
})
export class MylookbackformsComponent implements OnInit {
  formData: any;
  selectedVersion: number = 0;
  pID: number = 0;
  userAccessDetails: any;
  userRole: string = '';
  projectlength: number = 0;
  objectId: string = '';
  provId: string = '';
  constructor(private go36ProjectService: Go36projectService, private route: ActivatedRoute,private utilityServcie: UtilityService, private router: Router) { }

  ngOnInit(): void {
    this.objectId = this.utilityServcie.GetuserObjectId();
    this.provId = this.utilityServcie.GetuserProvisioningId();
    this.getAllLookBackForms();
  }

  getAllLookBackForms() {
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);

    if (this.userAccessDetails?.data?.isPowerUser) {
      this.userRole = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      this.userRole = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      this.userRole = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      this.userRole = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      this.userRole = "Leadership";
    }
    this.route.queryParams
      .subscribe(params => {
        this.go36ProjectService.getMyLookBackForms(this. objectId, this.provId, this.userRole).subscribe((res) => {
          this.formData = res;
          this.projectlength = this.formData.length;
          this.onShowEntryDropdownChange({ first: 0, rows: this.projectlength });
        });
      })
  }

  onShowEntryDropdownChange(event) {
    if (event.rows === this.projectlength)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "All";
    else if (event.rows === 10)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "10";
    else if (event.rows === 20)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "20";
    else if (event.rows === 50)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "50";
    else if (event.rows === 100)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "100";
  }
  
  OpenFormSettings(projectId: number, originalId: number, version: number, formType: string, location: string, opcoId: string) {
    localStorage.setItem("locationForFormSettings", location);
    localStorage.setItem("opcoIdForFormSettings", opcoId);
    this.router.navigate(["/forms/settings/edit"], { queryParams: { projectId, originalId, version, formType } });
  }
}
