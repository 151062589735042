<h4 mat-dialog-title style="color:#0B2D71;font-size: 16px;margin-bottom: 15px;">
    <div style="text-align:center;">
        Alert
    </div>
</h4>
<hr style="margin: 0!important;">
<div mat-dialog-content>
    <p [innerHTML]="message" style="margin: 0!important;"></p>
</div>
<hr style="margin: 0!important;">
<div mat-dialog-actions style="float: right;padding: 0!important;">
    <button mat-button (click)="onDismiss()" class="btn btn-default"> Close </button> &nbsp;&nbsp;
</div>