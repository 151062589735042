import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsettingsService } from '../admin/service/formsettings.service';
import { FormConfigurationModel, FormSettingConstant } from '../Model/Charts/FormConfiguration.model';
import { Go36projectService } from '../project/go36project/service/go36project.service';
@Component({
  selector: 'app-form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: ['./form-settings.component.css']
})
export class FormSettingsComponent implements OnInit {
  data: any;
  currentProject: any;
  formValue!: UntypedFormGroup;
  FormType: string = '';
  projectId: number = 0;
  originalId: number = 0;
  isUpStream: boolean = false;
  opcoId: number = 0;
  projectLocation: string = '';
  version: number = 0;
  // // IsShowNonUpstreamAfterTaxCashFlowChart : boolean = false;
  // // IsShowNonUpstreamCapitalAndExpenditureChart : boolean = false;
  // // IsShowNonUpstreamCumulativeCashChart : boolean = false;
  // // IsShowNonUpstreamEarningsBeforeInterestChart : boolean = false;
  // // IsShowNonUpstreamOperatingExpenseChart : boolean = false;
  // // IsShowNonUpstreamOperationalEarningsAfterTaxChart : boolean = false;
  // // IsShowNonUpstreamReturnOnCapitalEmployedChart : boolean = false;
  // // IsShowNonUpstreamVolumeChart : boolean = false;
  // // IsShowAfterTaxCashflowChart : boolean = false;
  // // IsShowCapitalAndExpenditureChart: boolean = false;
  // // IsShowCumulativeATCashflowChart: boolean = false;
  // // IsShowEarningPerBarrelChart: boolean = false;
  // // IsShowEarningsChart: boolean = false;
  // // IsShowNriReservesBookingsChart: boolean = false;
  // // IsShowOperatingCashMarginChart: boolean = false;
  // // IsShowProductionChart: boolean = false;

  formSettings: FormConfigurationModel = new FormConfigurationModel();
  constructor(private route: ActivatedRoute, private router: Router, private FormBuilder: UntypedFormBuilder, private formsettingsService: FormsettingsService, private projectservice: Go36projectService) {

    this.formValue = this.FormBuilder.group({
      ProjectId: '',
      ProjectLocation: '',
      ProjectName: '',
      FormType: '',
      CapitalAndExpenditureChartName: '',
      DefaultCapitalAndExpenditureChartName: '',
      ProductionChartName: '',
      DefaultProductionChartName: '',
      AfterTaxCashflowChartName: '',
      DefaultAfterTaxCashflowChartName: '',
      EarningPerBarrelChartName: '',
      DefaultEarningPerBarrelChartName: '',
      CumulativeATCashflowChartName: '',
      DefaultCumulativeATCashflowChartName: '',
      NriReservesBookingsChartName: '',
      DefaultNriReservesBookingsChartName: '',
      EarningsChartName: '',
      DefaultEarningsChartName: '',
      OperatingCashMarginChartName: '',
      DefaultOperatingCashMarginChartName: '',
      NonUpstreamVolumeChartName: '',
      DefaultNonUpstreamVolumeChartName: '',
      NonUpstreamOperatingExpenseChartName: '',
      DefaultNonUpstreamOperatingExpenseChartName: '',
      NonUpstreamCapitalAndExpenditureChartName: '',
      DefaultNonUpstreamCapitalAndExpenditureChartName: '',
      NonUpstreamEarningsBeforeInterestChartName: '',
      DefaultNonUpstreamEarningsBeforeInterestChartName: '',
      NonUpstreamOperationalEarningsAfterTaxChartName: '',
      DefaultNonUpstreamOperationalEarningsAfterTaxChartName: '',
      NonUpstreamReturnOnCapitalEmployedChartName: '',
      DefaultNonUpstreamReturnOnCapitalEmployedChartName: '',
      NonUpstreamCumulativeCashChartName: '',
      DefaultNonUpstreamCumulativeCashChartName: '',
      DefaultNonUpstreamAfterTaxCashFlowChartName: '',
      NonUpstreamAfterTaxCashFlowChartName: '',
      ShowNonUpstreamCumulativeCashChart: '',
      ShowCapitalAndExpenditureChart: '',
      ShowProductionChart: '',
      ShowAfterTaxCashflowChart: '',
      ShowEarningPerBarrelChart: '',
      ShowCumulativeATCashflowChart: '',
      ShowNriReservesBookingsChart: '',
      ShowEarningsChart: '',
      ShowOperatingCashMarginChart: '',
      ShowNonUpstreamVolumeChart: '',
      ShowNonUpstreamOperatingExpenseChart: '',
      ShowNonUpstreamCapitalAndExpenditureChart: '',
      ShowNonUpstreamEarningsBeforeInterestChart: '',
      ShowNonUpstreamAfterTaxCashFlowChart: '',
      ShowNonUpstreamOperationalEarningsAfterTaxChart: '',
      ShowNonUpstreamReturnOnCapitalEmployedChart: '',
    })
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.FormType = params['formType'];
        this.originalId = params['originalId'];
        this.projectId = params['projectId'];
        this.version = params['version'];
        localStorage.getItem('locationForFormSettings');
        this.opcoId = Number(localStorage.getItem('opcoIdForFormSettings'));
        this.projectservice.GetOpcoNameById(this.opcoId).subscribe((res) => {
          if (res.name == 'Upstream') {
            this.isUpStream = true;
          }
        });

        this.getFormSettings();
      });
  }

  onSubmit() {
  }

  getFormSettings() {
    this.projectservice.getProjectNameByProjectId(this.projectId).subscribe((res) => {
      this.formValue.controls["ProjectName"].setValue(res);
    });

    this.formsettingsService.getFormSettingsByVersion(this.originalId, this.version).subscribe(res => {
      this.data = res;

      this.formValue.controls["FormType"].setValue(this.FormType);
      // //  this.formValue.controls["ProjectName"].setValue(this.currentProject.goproject.ProjectName);
      this.formValue.controls["ProjectId"].setValue(this.projectId);
      // //this.projectLocation = JSON.stringify(localStorage.getItem('locationForFormSettings'));
      if (localStorage.getItem('locationForFormSettings') == "null" || localStorage.getItem('locationForFormSettings') == "undefined") {
        // //this.projectLocation ='';
        this.formValue.controls["ProjectLocation"].setValue("");
      }
      else {
        this.formValue.controls["ProjectLocation"].setValue(localStorage.getItem('locationForFormSettings'));
      }
      // //this.formValue.controls["ProjectLocation"].setValue(this.projectLocation);

      this.formValue.controls["NonUpstreamAfterTaxCashFlowChartName"].setValue(this.data?.nonUpstreamAfterTaxCashFlowChartName);
      this.formValue.controls["NonUpstreamCapitalAndExpenditureChartName"].setValue(this.data?.nonUpstreamCapitalAndExpenditureChartName);
      this.formValue.controls["NonUpstreamCumulativeCashChartName"].setValue(this.data?.nonUpstreamCumulativeCashChartName);
      this.formValue.controls["NonUpstreamEarningsBeforeInterestChartName"].setValue(this.data?.nonUpstreamEarningsBeforeInterestChartName);
      this.formValue.controls["NonUpstreamOperatingExpenseChartName"].setValue(this.data?.nonUpstreamOperatingExpenseChartName);
      this.formValue.controls["NonUpstreamOperationalEarningsAfterTaxChartName"].setValue(this.data?.nonUpstreamOperationalEarningsAfterTaxChartName);
      this.formValue.controls["NonUpstreamReturnOnCapitalEmployedChartName"].setValue(this.data?.nonUpstreamReturnOnCapitalEmployedChartName);
      this.formValue.controls["NonUpstreamVolumeChartName"].setValue(this.data?.nonUpstreamVolumeChartName);

      // //this.formValue.controls["NonUpstreamAfterTaxCashFlowChartName"].setValue(this.data.nonUpstreamAfterTaxCashFlowChartName);

      this.formValue.controls["ShowNonUpstreamAfterTaxCashFlowChart"].setValue(this.data?.showNonUpstreamAfterTaxCashFlowChart);
      this.formValue.controls["ShowNonUpstreamCapitalAndExpenditureChart"].setValue(this.data?.showNonUpstreamCapitalAndExpenditureChart);
      this.formValue.controls["ShowNonUpstreamCumulativeCashChart"].setValue(this.data?.showNonUpstreamCumulativeCashChart);
      this.formValue.controls["ShowNonUpstreamEarningsBeforeInterestChart"].setValue(this.data?.showNonUpstreamEarningsBeforeInterestChart);
      this.formValue.controls["ShowNonUpstreamOperatingExpenseChart"].setValue(this.data?.showNonUpstreamOperatingExpenseChart);
      this.formValue.controls["ShowNonUpstreamOperationalEarningsAfterTaxChart"].setValue(this.data?.showNonUpstreamOperationalEarningsAfterTaxChart);
      this.formValue.controls["ShowNonUpstreamReturnOnCapitalEmployedChart"].setValue(this.data?.showNonUpstreamReturnOnCapitalEmployedChart);
      this.formValue.controls["ShowNonUpstreamVolumeChart"].setValue(this.data?.showNonUpstreamVolumeChart);

      this.formValue.controls["AfterTaxCashflowChartName"].setValue(this.data?.afterTaxCashflowChartName);
      this.formValue.controls["CapitalAndExpenditureChartName"].setValue(this.data?.capitalAndExpenditureChartName);
      this.formValue.controls["CumulativeATCashflowChartName"].setValue(this.data?.cumulativeAtcashflowChartName);
      this.formValue.controls["EarningPerBarrelChartName"].setValue(this.data?.earningPerBarrelChartName);
      this.formValue.controls["EarningsChartName"].setValue(this.data?.earningsChartName);
      this.formValue.controls["NriReservesBookingsChartName"].setValue(this.data?.nriReservesBookingsChartName);
      this.formValue.controls["OperatingCashMarginChartName"].setValue(this.data?.operatingCashMarginChartName);
      this.formValue.controls["ProductionChartName"].setValue(this.data?.productionChartName);
      //RoceAccretionDilutionChartName

      this.formValue.controls["ShowAfterTaxCashflowChart"].setValue(this.data?.showAfterTaxCashflowChart);
      this.formValue.controls["ShowCapitalAndExpenditureChart"].setValue(this.data?.showCapitalAndExpenditureChart);
      this.formValue.controls["ShowCumulativeATCashflowChart"].setValue(this.data?.showCumulativeAtcashflowChart);
      this.formValue.controls["ShowEarningPerBarrelChart"].setValue(this.data?.showEarningPerBarrelChart);
      this.formValue.controls["ShowEarningsChart"].setValue(this.data?.showEarningsChart);
      this.formValue.controls["ShowNriReservesBookingsChart"].setValue(this.data?.showNriReservesBookingsChart);
      this.formValue.controls["ShowOperatingCashMarginChart"].setValue(this.data?.showOperatingCashMarginChart);
      this.formValue.controls["ShowProductionChart"].setValue(this.data?.showProductionChart);
      //ShowRoceAccretionDilutionChart

      // //this.formValue.controls["ShowNonUpstreamCumulativeCashChart"].setValue(this.data.showNonUpstreamCumulativeCashChart);
      this.formValue.controls["DefaultCapitalAndExpenditureChartName"].setValue(FormSettingConstant.DefaultCapitalAndExpenditureChartName);

      this.formValue.controls["DefaultProductionChartName"].setValue(FormSettingConstant.DefaultProductionChartName);
      this.formValue.controls["DefaultAfterTaxCashflowChartName"].setValue(FormSettingConstant.DefaultAfterTaxCashflowChartName);
      this.formValue.controls["DefaultEarningPerBarrelChartName"].setValue(FormSettingConstant.DefaultEarningPerBarrelChartName);

      this.formValue.controls["DefaultCumulativeATCashflowChartName"].setValue(FormSettingConstant.DefaultCumulativeATCashflowChartName);
      this.formValue.controls["DefaultNriReservesBookingsChartName"].setValue(FormSettingConstant.DefaultNriReservesBookingsChartName);

      this.formValue.controls["DefaultEarningsChartName"].setValue(FormSettingConstant.DefaultEarningsChartName);
      this.formValue.controls["DefaultOperatingCashMarginChartName"].setValue(FormSettingConstant.DefaultOperatingCashMarginChartName);

      this.formValue.controls["DefaultNonUpstreamVolumeChartName"].setValue(FormSettingConstant.DefaultNonUpstreamVolumeChartName);
      this.formValue.controls["DefaultNonUpstreamOperatingExpenseChartName"].setValue(FormSettingConstant.DefaultNonUpstreamOperatingExpenseChartName);
      this.formValue.controls["DefaultNonUpstreamCapitalAndExpenditureChartName"].setValue(FormSettingConstant.DefaultNonUpstreamCapitalAndExpenditureChartName);
      this.formValue.controls["DefaultNonUpstreamEarningsBeforeInterestChartName"].setValue(FormSettingConstant.DefaultNonUpstreamEarningsBeforeInterestChartName);
      this.formValue.controls["DefaultNonUpstreamAfterTaxCashFlowChartName"].setValue(FormSettingConstant.DefaultNonUpstreamAfterTaxCashFlowChartName);
      this.formValue.controls["DefaultNonUpstreamOperationalEarningsAfterTaxChartName"].setValue(FormSettingConstant.DefaultNonUpstreamOperationalEarningsAfterTaxChartName);
      this.formValue.controls["DefaultNonUpstreamReturnOnCapitalEmployedChartName"].setValue(FormSettingConstant.DefaultNonUpstreamReturnOnCapitalEmployedChartName);
      this.formValue.controls["DefaultNonUpstreamCumulativeCashChartName"].setValue(FormSettingConstant.DefaultNonUpstreamCumulativeCashChartName);
    })
  }
  saveFormSettings() {
    this.formSettings.ShowNonUpstreamAfterTaxCashFlowChart = this.formValue.value.ShowNonUpstreamAfterTaxCashFlowChart;
    this.formSettings.ShowNonUpstreamCapitalAndExpenditureChart = this.formValue.value.ShowNonUpstreamCapitalAndExpenditureChart;
    this.formSettings.ShowNonUpstreamCumulativeCashChart = this.formValue.value.ShowNonUpstreamCumulativeCashChart;
    this.formSettings.ShowNonUpstreamEarningsBeforeInterestChart = this.formValue.value.ShowNonUpstreamEarningsBeforeInterestChart;
    this.formSettings.ShowNonUpstreamOperatingExpenseChart = this.formValue.value.ShowNonUpstreamOperatingExpenseChart;
    this.formSettings.ShowNonUpstreamOperationalEarningsAfterTaxChart = this.formValue.value.ShowNonUpstreamOperationalEarningsAfterTaxChart;
    this.formSettings.ShowNonUpstreamReturnOnCapitalEmployedChart = this.formValue.value.ShowNonUpstreamReturnOnCapitalEmployedChart;
    this.formSettings.ShowNonUpstreamVolumeChart = this.formValue.value.ShowNonUpstreamVolumeChart;
    this.formSettings.showAfterTaxCashflowChart = this.formValue.value.ShowAfterTaxCashflowChart;
    this.formSettings.showCapitalAndExpenditureChart = this.formValue.value.ShowCapitalAndExpenditureChart;
    this.formSettings.showCumulativeATCashflowChart = this.formValue.value.ShowCumulativeATCashflowChart;
    this.formSettings.showEarningPerBarrelChart = this.formValue.value.ShowEarningPerBarrelChart;
    this.formSettings.showEarningsChart = this.formValue.value.ShowEarningsChart;
    this.formSettings.showNriReservesBookingsChart = this.formValue.value.ShowNriReservesBookingsChart;
    this.formSettings.showOperatingCashMarginChart = this.formValue.value.ShowOperatingCashMarginChart;
    this.formSettings.showProductionChart = this.formValue.value.ShowProductionChart;
    this.formSettings.nonUpstreamAfterTaxCashFlowChartName = this.formValue.value.NonUpstreamAfterTaxCashFlowChartName;
    // //this.formSettings.DefaultNonUpstreamAfterTaxCashFlowChartName = this.formValue.value.DefaultNonUpstreamAfterTaxCashFlowChartName;

    this.formSettings.nonUpstreamCapitalAndExpenditureChartName = this.formValue.value.NonUpstreamCapitalAndExpenditureChartName;
    // //this.formSettings.DefaultNonUpstreamCapitalAndExpenditureChartName = this.formValue.value.DefaultNonUpstreamCapitalAndExpenditureChartName;

    this.formSettings.nonUpstreamCumulativeCashChartName = this.formValue.value.NonUpstreamCumulativeCashChartName;
    // //this.formSettings.DefaultNonUpstreamCumulativeCashChartName = this.formValue.value.DefaultNonUpstreamCumulativeCashChartName;

    this.formSettings.nonUpstreamEarningsBeforeInterestChartName = this.formValue.value.NonUpstreamEarningsBeforeInterestChartName;
    // //this.formSettings.DefaultNonUpstreamEarningsBeforeInterestChartName = this.formValue.value.DefaultNonUpstreamEarningsBeforeInterestChartName;

    this.formSettings.nonUpstreamOperatingExpenseChartName = this.formValue.value.NonUpstreamOperatingExpenseChartName;
    // //this.formSettings.DefaultNonUpstreamOperatingExpenseChartName = this.formValue.value.DefaultNonUpstreamOperatingExpenseChartName;

    this.formSettings.nonUpstreamOperationalEarningsAfterTaxChartName = this.formValue.value.NonUpstreamOperationalEarningsAfterTaxChartName;
    // //this.formSettings.DefaultNonUpstreamOperationalEarningsAfterTaxChartName = this.formValue.value.DefaultNonUpstreamOperationalEarningsAfterTaxChartName;

    this.formSettings.nonUpstreamReturnOnCapitalEmployedChartName = this.formValue.value.NonUpstreamReturnOnCapitalEmployedChartName;
    // //this.formSettings.DefaultNonUpstreamReturnOnCapitalEmployedChartName = this.formValue.value.DefaultNonUpstreamReturnOnCapitalEmployedChartName;

    this.formSettings.nonUpstreamVolumeChartName = this.formValue.value.NonUpstreamVolumeChartName;
    // //this.formSettings.DefaultNonUpstreamVolumeChartName = this.formValue.value.DefaultNonUpstreamVolumeChartName;

    this.formSettings.afterTaxCashflowChartName = this.formValue.value.AfterTaxCashflowChartName;
    // //this.formSettings.DefaultAfterTaxCashflowChartName = this.formValue.value.DefaultAfterTaxCashflowChartName;

    this.formSettings.capitalAndExpenditureChartName = this.formValue.value.CapitalAndExpenditureChartName;
    // //this.formSettings.DefaultCapitalAndExpenditureChartName = this.formValue.value.DefaultCapitalAndExpenditureChartName;

    this.formSettings.cumulativeATCashflowChartName = this.formValue.value.CumulativeATCashflowChartName;
    // //this.formSettings.DefaultCumulativeATCashflowChartName = this.formValue.value.DefaultCumulativeATCashflowChartName;

    this.formSettings.earningPerBarrelChartName = this.formValue.value.EarningPerBarrelChartName;
    // //this.formSettings.DefaultEarningPerBarrelChartName = this.formValue.value.DefaultEarningPerBarrelChartName;

    this.formSettings.earningsChartName = this.formValue.value.EarningsChartName;
    // //this.formSettings.DefaultEarningsChartName = this.formValue.value.DefaultEarningsChartName;

    this.formSettings.nriReservesBookingsChartName = this.formValue.value.NriReservesBookingsChartName;
    // //this.formSettings.DefaultNriReservesBookingsChartName = this.formValue.value.DefaultNriReservesBookingsChartName;

    this.formSettings.operatingCashMarginChartName = this.formValue.value.OperatingCashMarginChartName;
    // //this.formSettings.DefaultOperatingCashMarginChartName = this.formValue.value.DefaultOperatingCashMarginChartName;

    this.formSettings.productionChartName = this.formValue.value.ProductionChartName;
    // //this.formSettings.DefaultProductionChartName = this.formValue.value.DefaultProductionChartName;
    this.formSettings.formId = this.data.formId;
    this.formsettingsService.saveFormSettings(this.formSettings, this.originalId).subscribe((res) => {
      this.router.navigateByUrl('/go36project/viewform?projectId=' + this.projectId);
    });
  }

  onCheckboxChangeCapitalAndExpenditureChart(e) {
    this.formSettings.showCapitalAndExpenditureChart = e.target.checked;
  }

  onCheckboxShowProductionChart(e) {
    this.formSettings.showProductionChart = e.target.checked;
  }

  onCheckboxShowAfterTaxCashflowChart(e) {
    this.formSettings.showAfterTaxCashflowChart = e.target.checked;
  }

  onCheckboxShowEarningPerBarrelChart(e) {
    this.formSettings.showEarningPerBarrelChart = e.target.checked;
  }

  onCheckboxShowCumulativeATCashflowChart(e) {
    this.formSettings.showCumulativeATCashflowChart = e.target.checked;
  }

  onCheckboxShowNriReservesBookingsChart(e) {
    this.formSettings.showNriReservesBookingsChart = e.target.checked;
  }

  onCheckboxShowEarningsChart(e) {
    this.formSettings.showEarningsChart = e.target.checked;
  }

  onCheckboxShowOperatingCashMarginChart(e) {
    this.formSettings.showOperatingCashMarginChart = e.target.checked;
  }

  onCheckboxShowNonUpstreamVolumeChart(e) {
    this.formSettings.ShowNonUpstreamVolumeChart = e.target.checked;
  }

  onCheckboxShowNonUpstreamOperatingExpenseChart(e) {
    this.formSettings.ShowNonUpstreamOperatingExpenseChart = e.target.checked;
  }

  onCheckboxShowNonUpstreamCapitalAndExpenditureChart(e) {
    this.formSettings.ShowNonUpstreamCapitalAndExpenditureChart = e.target.checked;
  }

  onCheckboxShowNonUpstreamEarningsBeforeInterestChart(e) {
    this.formSettings.ShowNonUpstreamEarningsBeforeInterestChart = e.target.checked;
  }

  onCheckboxShowNonUpstreamAfterTaxCashFlowChart(e) {
    this.formSettings.ShowNonUpstreamAfterTaxCashFlowChart = e.target.checked;
  }

  onCheckboxShowNonUpstreamOperationalEarningsAfterTaxChart(e) {
    this.formSettings.ShowNonUpstreamOperationalEarningsAfterTaxChart = e.target.checked;
  }

  onCheckboxShowNonUpstreamReturnOnCapitalEmployedChart(e) {
    this.formSettings.ShowNonUpstreamReturnOnCapitalEmployedChart = e.target.checked;
  }

  onCheckboxShowNonUpstreamCumulativeCashChart(e) {
    this.formSettings.ShowNonUpstreamCumulativeCashChart = e.target.checked;
  }
  cancelFormSettings() {
    this.router.navigateByUrl('/go36project/viewform?projectId=' + this.projectId);
  }
}