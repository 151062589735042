import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Versions, ViewFormModel } from '../../Model/viewform.model';
import { Go36projectService } from '../../service/go36project.service';
import { FeedDialogComponent } from 'src/app/Modals/feed-dialog/feed-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Form } from 'src/app/Model/Form.model';
import { OverviewService } from 'src/app/forms/FEEDServices/overview.service';
import { LessonlearnedDialogComponent } from 'src/app/Modals/lessonlearned-dialog/lessonlearned-dialog.component';
import { Part4LookbackService } from 'src/app/forms/FEEDServices/Part4LookBack_Service/part4-lookback.service';
import { Go36ArP4LookbackHeaderModel, Go36ArP4LookbackPerformance, Part4ViewModel } from 'src/app/Model/Part4LookBack/Part4LookBack.model';
import { Part5LookbackService } from 'src/app/forms/FEEDServices/part5-lookback.service';
import { Go36ArP5LookbackHeaderModel, Part5ViewModel } from 'src/app/Model/Part5LookBack.model';
import { AlertDialogComponent, AlertDialogModel } from 'src/app/Modals/alert-dialog/alert-dialog.component';
import { AlertFormexistDialogComponent, FormExistialogModel} from 'src/app/Modals/alert-formexist-dialog/alert-formexist-dialog.component';  
import { UtilityService } from 'src/app/Shared/utility.service';
@Component({
  selector: 'app-viewformlist',
  templateUrl: './viewformlist.component.html',
  styleUrls: ['./viewformlist.component.css']
})

export class ViewformlistComponent implements OnInit, AfterViewInit {
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  formData: ViewFormModel[] = [];
  pID: any;
  projectName: string = "";
  formId: number = 0;
  actualformId: number = 0;
  versionList: Versions[] = [];
  form = new Form();
  objectId: string = '';
  provId: string = '';
  emailId: string = '';
  openRoutePath: string = '';
  selectedVersion: any = 0;
  formDataItem?= new ViewFormModel();
  part4Data: Part4ViewModel[] = [];
  Part4Header = new Go36ArP4LookbackHeaderModel;
  Part4Performance: Go36ArP4LookbackPerformance[] = [];
  newData: any;
  part5Data: Part5ViewModel[] = [];
  Part5Header = new Go36ArP5LookbackHeaderModel;
  userAccessDetails: any;
  userRole: string = '';
  userAccessListByProjectId: any;

  constructor(private projectservice: Go36projectService, public dialog: MatDialog, private route: ActivatedRoute,
    private overviewService: OverviewService, private router: Router, private go36ProjectService: Go36projectService,
    private part4lookbackService: Part4LookbackService,
    private part5lookbackService: Part5LookbackService,
    private utilityServcie: UtilityService) { }

  ngAfterViewInit(): void {
    // // $('#tblOperatingCompany').DataTable();
  }

  ngOnInit(): void {
    localStorage.removeItem('errorpage');
    this.getAllViewForms();
    this.objectId = this.utilityServcie.GetuserObjectId();
    this.provId = this.utilityServcie.GetuserProvisioningId();
    this.emailId = this.utilityServcie.GetuserEmailId();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2,
      processing: true
    };

    this.projectservice.GetUserAccessListByProjectId(this.pID, this.objectId, this.provId).subscribe(res => {
      if(res)
      {
        this.userAccessListByProjectId = res;
      }
    })
  }

  clear(table: any) {
    table.clear();
  }

  getAllViewForms() {
    this.route.queryParams
      .subscribe(params => {
        this.pID = params['projectId'];
        this.projectservice.getViewForm(this.pID).subscribe((res) => {
          this.formData = res;
          if (this.formData.length > 0) {
            this.formData.forEach(obj => {
              obj.selectedVersion = obj.Version;
            });
          }
        });
        this.projectservice.getProjectNameByProjectId(this.pID).subscribe((res) => {
          this.projectName = res;
        });
      })
  }

  OpenFeedDialog() {
    this.projectservice.getFormDetails(this.pID).subscribe((res) => {
      this.projectName = res;
    });

    this.go36ProjectService.getViewFormTypes(this.pID).subscribe((res) => {
      // // this.formData = res;
      if (this.formData.length > 0) {
        this.formDataItem = this.formData.find((x: { FormType: string; }) => x.FormType == "FEED");
        if (this.formDataItem) {
          const message = "FEED AR already exists."
          const dialogData = new AlertDialogModel(message);
          this.dialog.open(AlertDialogComponent, {
            width: "400px",
            height: "160px",
            data: dialogData
          });
        } else {
          this.dialog.open(FeedDialogComponent, {
            width: "600px",
            data: { projectId: this.pID, formId: 0 },
          });
        }
      }
      else {
        this.dialog.open(FeedDialogComponent, {
          width: "600px",
          data: { projectId: this.pID, formId: 0 },
        });
      }
    });
  }

  OpenLessonLearnedDialog() {
    this.dialog.open(LessonlearnedDialogComponent, {
      width: "600px",
      data: { projectId: this.pID, formId: 0 },
    });
  }

  CreateFeedSupplement() {
    this.form.projectId = this.pID;
    this.form.active = true;
    this.form.createdBy = this.provId;
    this.form.formType = 'FEED Supplement';
    this.form.order = 1;
    this.form.id = 0
    this.form.ownership = [];
    this.form.milestone = [];
    this.form.keyEconomicMetrics = [];
    this.form.capitalAndExpenditures = [];
    this.overviewService.checkFeedFIDFormExist(this.form.projectId,'FEED').subscribe(res => {
      if(res){
        this.overviewService.saveFormData(this.form).subscribe(res => {
          this.form.id = Number(res.id);
          this.form.version = Number(res.version);
          this.router.navigate(["/forms/feed_Supplement", this.pID, this.form.id, this.form.version, 'Create']);
        });
      }
      else{
        const message = "FEED form should be created before creating FEED supplement."
        const dialogData = new FormExistialogModel(message);
        this.dialog.open(AlertFormexistDialogComponent, {
          width: "600px",
          data: dialogData
        });
      }
      
    });
  }

  CreateFID() {
    this.form.projectId = this.pID;
    this.form.active = true;
    this.form.createdBy = this.provId;
    this.form.formType = 'Phase 3 to 4 (FID)';
    this.form.order = 1;
    this.form.id = 0
    this.form.ownership = [];
    this.form.milestone = [];
    this.form.keyEconomicMetrics = [];
    this.form.capitalAndExpenditures = [];
    this.overviewService.saveFormData(this.form).subscribe(res => {
      this.form.id = Number(res.id);
      this.form.version = Number(res.version);
      this.router.navigate(["/forms/fid", this.pID, this.form.id, this.form.version, 'Create']);
    });
  }

  CreateFIDSupplement() {
    this.form.projectId = this.pID;
    this.form.active = true;
    this.form.createdBy = this.provId;
    this.form.formType = 'FID Supplement';
    this.form.order = 1;
    this.form.id = 0
    this.form.ownership = [];
    this.form.milestone = [];
    this.form.keyEconomicMetrics = [];
    this.form.capitalAndExpenditures = [];
    this.overviewService.checkFeedFIDFormExist(this.form.projectId,'Phase 3 to 4 (FID)').subscribe(res => {
      if(res){
      this.overviewService.saveFormData(this.form).subscribe(res => {
        this.form.id = Number(res.id);
        this.form.version = Number(res.version);
        this.router.navigate(["/forms/fid_Supplement", this.pID, this.form.id, this.form.version, 'Create']);
      });
    }
    else{
      const message = "FID form should be created before creating FID supplement."
      const dialogData = new FormExistialogModel(message);
      this.dialog.open(AlertFormexistDialogComponent, {
        width: "600px",
        data: dialogData
      });
    }
    
  });
  }

  onVersionChange(event: any) {
    this.selectedVersion = Number(event.target.value);
  }

  CreatePart4LookBack() {
    this.route.queryParams
      .subscribe(params => {
        this.pID = params['projectId'];
        this.part4lookbackService.CreatePart4LookBack(this.pID, this.objectId, this.provId).subscribe((res) => {
          if (res.go36ArP4LookbackHeaders != null) {
            this.part4Data = res;
            this.Part4Header = res.go36ArP4LookbackHeaders;
            this.Part4Performance = res.go36ArP4LookbackPerformance;
            this.router.navigate(["/forms/part4"], { queryParams: { projectId: this.pID, formId: this.Part4Header.originalId, versionName: this.Part4Header.version, formCase: 'Create' } });
          }
        });
      });
  }

  CreatePart5LookBack() {
    this.route.queryParams
      .subscribe(params => {
        this.pID = params['projectId'];
        this.part5lookbackService.CreatePart5LookBack(this.pID, this.objectId, this.provId).subscribe((res) => {
          if (res.go36ArP5LookbackHeaders != null) {
            this.part5Data = res;
            this.Part5Header = res.go36ArP5LookbackHeaders;
            this.router.navigate(["/forms/part5"], { queryParams: { projectId: this.pID, formId: this.Part5Header.originalId, versionName: this.Part5Header.version, formCase: 'Create' } });
          }
        });
      });
  }

  DeleteProject(action: string) {
    this.route.queryParams
      .subscribe(params => {
        const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
        this.userAccessDetails = JSON.parse(userData);

        if (this.userAccessDetails?.data?.isPowerUser) {
          this.userRole = "Power User";
        }
        else if (this.userAccessDetails?.data?.isAdminUser) {
          this.userRole = "Administrator";
        }
        else if (this.userAccessDetails?.data?.isBasicMember) {
          this.userRole = "Basic Member";
        }
        else if (this.userAccessDetails?.data?.isConsultantUser) {
          this.userRole = "Consultant";
        }
        else if (this.userAccessDetails?.data?.isLeadershipUser) {
          this.userRole = "Leadership";
        }

    
     if(this.userAccessListByProjectId?.data?.canRemove)
      {
        this.router.navigate(['/forms/deleteproject'], { queryParams: { projectId: this.pID } });
      }else{
        localStorage.setItem('errorpage', 'true');
        this.router.navigate(["errorpage"]);
      } 
  });
}

  OpenFormSettings(projectId: number, originalId: number, version: number, formType: string, location: string, opcoId: string) {
    localStorage.setItem("locationForFormSettings", location);
    localStorage.setItem("opcoIdForFormSettings", opcoId);
    // //this.router.navigate(["/forms/settings/edit"]);
    // //this.router.navigate(["/forms/settings/edit"], { queryParams: { projectId: 123, formId: 5555, versionName: 2 } });
    this.router.navigate(["/forms/settings/edit"], { queryParams: { projectId, originalId, version, formType } });
  }
}
