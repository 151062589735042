import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-lessons-learned-dashboard-nav',
  templateUrl: './lessons-learned-dashboard-nav.component.html',
  styleUrls: ['./lessons-learned-dashboard-nav.component.css']
})
export class LessonsLearnedDashboardNavComponent implements OnInit {
  Phase = 'Phase';
  Feed = 'FEED';
  FeedSupplement = 'FEED Supplement';
  Fid = 'Phase 3 to 4 (FID)';
  FidSupplement = 'FID Supplement';
  Ssbod= 'SSBOD';
  LookbackPart4= 'Lookback Part 4';
  LookbackPart5= 'Lookback Part 5';
  projectId: number = 0;


  baseUrl: any = environment.apiBaseUrl;
  pdfUrl: any ="";
  constructor(
    private router: Router, private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      this.projectId = Number(params['projectId']);
    });
    this.pdfUrl = `${this.baseUrl}api/LessonLearned/GetLLDashboardPDF?projectId=${this.projectId}`;
  }

}
