import { Injectable } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UtilityService {
  isLoaderShown = new Subject<boolean>();
  isFontSizeDropdownOpen: boolean = false;
  pendingRequests: number[] = []
  constructor(private authService: CalAngularService){}
  public getNowUTC(selectedDate:Date) {
    const activationDate = new Date(selectedDate);
    let date = new Date(activationDate.getUTCFullYear(),
                                            activationDate.getUTCMonth(),
                                            activationDate.getUTCDate(),
                                            activationDate.getUTCHours(),
                                            activationDate.getUTCMinutes(),
                                            activationDate.getUTCSeconds()
                                            );
                                            return date;
  }
 public showLoader(){
    return this.isLoaderShown.next(true);
 }
 public hideLoader(){
    return this.isLoaderShown.next(false);
 }
 removeCDKAnouncer(){
  setTimeout(() => {
    let element = document.querySelector(".cdk-live-announcer-element");
    element?.setAttribute("style","display:none!important;");
  },0)
}
public CkEditorFontSizeScroll(){
  setTimeout(() => {
    const ckFontSizeDropdownMainElement = document.querySelectorAll(".ck-font-size-dropdown .ck-dropdown__button");
    ckFontSizeDropdownMainElement.forEach((eachCkElement,i) => {
      eachCkElement.addEventListener("click", ()=>{
        const fontSizeDropdownOverlayElement = document.createElement("div");
        fontSizeDropdownOverlayElement.setAttribute("id","font-size-dropdown-overlay"+i);
        fontSizeDropdownOverlayElement.style.cssText = `width: 100%;height: 100vh;position: fixed;top: 0;right: 0;left: 0;bottom: 0;background-color: transperent;display: none;`;
        document.querySelectorAll(".ck-font-size-dropdown")[i]?.appendChild(fontSizeDropdownOverlayElement);
        document.querySelector("#font-size-dropdown-overlay"+i)?.addEventListener("click", ()=>{
          document.querySelector("#font-size-dropdown-overlay"+i)?.remove();
          document.querySelectorAll(".ck-font-size-dropdown .ck-dropdown__panel")[i]?.removeAttribute("style");
          document.querySelectorAll(".ck-font-size-dropdown style")[i]?.remove();
            this.isFontSizeDropdownOpen = false;
        })
        if(!this.isFontSizeDropdownOpen){
          this.isFontSizeDropdownOpen = true;
          const openFontSizeDropdownStyleElement = document.createElement("style");
          openFontSizeDropdownStyleElement.innerHTML = `
            .openCkFontSizeDropdownOverlay{
              display: block!important;
            }
          `;
          document.querySelectorAll(".ck-font-size-dropdown")[i]?.appendChild(openFontSizeDropdownStyleElement);
          document.querySelector("#font-size-dropdown-overlay"+i)?.classList.add("openCkFontSizeDropdownOverlay");
          document.querySelectorAll(".ck-font-size-dropdown .ck-dropdown__panel")[i]?.setAttribute("style","display: block;")
          document.querySelectorAll(".ck-font-size-dropdown .ck-dropdown__panel ul")[i]?.addEventListener("click",()=>{
            document.querySelector("#font-size-dropdown-overlay"+i)?.remove();
            document.querySelectorAll(".ck-font-size-dropdown .ck-dropdown__panel")[i]?.removeAttribute("style");
            document.querySelectorAll(".ck-font-size-dropdown style")[i]?.remove();
            this.isFontSizeDropdownOpen = false;
          })
        }else{
          this.isFontSizeDropdownOpen = false;
          document.querySelectorAll(".ck-font-size-dropdown .ck-dropdown__panel")[i]?.removeAttribute("style");
          document.querySelector("#font-size-dropdown-overlay"+i)?.remove();
          document.querySelectorAll(".ck-font-size-dropdown style")[i]?.remove();
        }
      });
    });
  }, 1000);
}
public AllowNoDecimal(value: any) {
  if(value == "" || value == null || value == undefined)
    value= "";
  else
    ////value = parseInt(value).toString().replace(/\.0+$/,""); 
    value = value.toFixed(0);
  return value;
}
public AllowOneDecimal(value: any) {
  if(value != null && value != undefined)
    value = value.toFixed(1);
  return value;
}
public AllowTwoDecimal(value: any) {
  if(value != null && value != undefined)
    value = value.toFixed(2);
  return value;
}
public OnlyNumber(value: any,decimal:number) {
  if(value != null && value != undefined)
  {
    const newValue = parseFloat(value).toString().replace(/[^0-9\.]*/g, '');
    if(newValue!=""){
        value = parseFloat(newValue);
    }
    else{
        value = 0;
    }
  }
  return value;
}
GetuserEmailId() {
  var currentUserProfile = this.authService.cvxClaimsPrincipal;
  return currentUserProfile.email;
}
GetuserProvisioningId() {
  var currentUserProfile = this.authService.cvxClaimsPrincipal;
  return currentUserProfile.provisioningId;
}
GetuserObjectId() {
  var currentUserProfile = this.authService.cvxClaimsPrincipal;
  return currentUserProfile.objectId;
}
}