import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { AppsettingsService } from '../../../Shared/appsettings.service';
import { NewARDocumentModel } from 'src/app/Model/NewARDocument.model';
import { EAPart1Model } from 'src/app/Model/EAPart1.model';
import { EaCommentModel } from 'src/app/Model/EaComment.model';
import { OperationModel } from 'src/app/Model/EAPart2/Operation.model';
import { EconomicModel } from 'src/app/Model/EAPart2/Economic.model';
import { ExecutionModel } from 'src/app/Model/EAPart2/Execution.model';
import { BusinessModel } from 'src/app/Model/EAPart2/Business.model';
import { DesignCommercialModel } from 'src/app/Model/EAPart2/DesignCommercial.model';
import { ExecutionTargetAssuranceModel } from 'src/app/Model/EAPart2/ExecutionTargetAssurance.model';
import { FeaturesImplemented } from 'src/app/Model/features-implemented';
@Injectable({
  providedIn: 'root'
})
export class EaprojectService {
  Listurl = "api/ea/Project?objectId=";
  CreatePart1Url = "api/part1/CreateEaNewARPart1";
  GetPart1DataUrl = "api/part1/GetPart1Data?formId=";

  GetEAFeaturesImplemented = "api/part1/GetPart1FeaturesImplemented?formId=";
  GetEAFeaturesImplementedForSup = "api/part1/GetEAFeaturesImplementedForSupp?formId=";
  GetEAFeaturesImplementedForEAP4Lookback = "api/part1/getEAFormFeaturesForEAP4Lookback?formId="

  GetNewARDocumentsUrl = "api/part1/GetNewARDocuments?formId=";
  UploadDocumentUrl = "api/part1/Upload";
  DownloadUrl = "api/part1/Download?id=";
  DeleteDocumentUrl = "api/part1/DeleteDocument";
  UpdatePart1DataUrl = "api/part1/UpdateEaPart1Data";
  UpdateCommentsUrl = "api/part1/UpdateComments";
  GetPart2DataUrl = "api/part2/GetPart2Data?formId=";
  UpdatePart2OperationDataUrl = "api/part2/UpdateEaPart2OperationData";
  UpdatePart2EconomicDataUrl = "api/part2/UpdateEaPart2EconomicData";
  UpdatePart2ExecutionDataUrl = "api/part2/UpdateEaPart2ExecutionData";
  UpdatePart2BusinessDataUrl = "api/part2/UpdateEaPart2BusinessData";
  UpdatePart2DesignCommercialDataUrl = "api/part2/UpdateEaPart2DesignCommercialData";
  UpdatePart2ExecutionTargetAssuranceDataUrl = "api/part2/UpdateEaPart2ExecutionTargetAssuranceData";
  postIsOnHoldValueUrl = "api/part1/SaveIsOnHoldValue";
  deleteFormUrl = "api/part1/DeleteForm?formId=";
  GetUserAccessListForEAProjectURL = "api/UserAccess/GetUserAccessListForEAProjects?paramProjectId=";
  getUserAccessListByProjectIdURL = "api/UserAccess/GetUserAccessListByProjectId?projectId=";
  GetNewARPDFUrl = 'api/part1/GetNewARPDF?projectId=';

  constructor(private http: HttpClient,
    private appsettings: AppsettingsService) { }
  getProject(objectId: string,provIdId: string, userRole: string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.Listurl + objectId+ "&provId=" + provIdId + "&userRole=" + userRole)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  CreateEaNewARPart1(projectId: number, objectId: string, provId: string) {
    const params = new HttpParams()
      .append('projectId', projectId)
      .append('objectId', objectId)
      .append('provId', provId)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.CreatePart1Url, '', {
      params: params
    });
  }
  GetPart1Data(formId: number, versionName: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetPart1DataUrl + formId + '&version=' + versionName);
  }

  getEAFormFeatures(formId:number, versionName: number){
    return this.http.get<FeaturesImplemented>(this.appsettings.apiBaseUrl + this.GetEAFeaturesImplemented + formId + '&version=' + versionName);
  }

  getEAFormFeaturesForSupplement(formId:number, versionName: number){
    return this.http.get<FeaturesImplemented>(this.appsettings.apiBaseUrl + this.GetEAFeaturesImplementedForSup + formId+ '&version=' + versionName);
  }

  getEAFormFeaturesForEAP4Lookback(formId:number, versionName: number){
    return this.http.get<FeaturesImplemented>(this.appsettings.apiBaseUrl + this.GetEAFeaturesImplementedForEAP4Lookback + formId+ '&version=' + versionName);
  }

  GetNewARDocuments(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetNewARDocumentsUrl + formId);
  }
  saveDocument(formId: number, provId: string, file: FormData) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UploadDocumentUrl + '?formId=' + formId + '&' + 'provId=' + provId, file);
  }
  downloadFile(id: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get<NewARDocumentModel>(this.appsettings.apiBaseUrl + this.DownloadUrl + id, httpOptions);
  }
  DeleteDocument(document: any) {
    return this.http.post<NewARDocumentModel[]>(this.appsettings.apiBaseUrl + this.DeleteDocumentUrl, document);
  }
  UpdatePart1Data(Part1Data: any) {
    return this.http.post<EAPart1Model[]>(this.appsettings.apiBaseUrl + this.UpdatePart1DataUrl, Part1Data);
  }
  UpdateComments(go36EaComment: any) {
    return this.http.post<EaCommentModel[]>(this.appsettings.apiBaseUrl + this.UpdateCommentsUrl, go36EaComment);
  }
  GetPart2Data(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetPart2DataUrl + formId);
  }
  UpdatePart2OperationData(OperationModel: any) {
    return this.http.post<OperationModel[]>(this.appsettings.apiBaseUrl + this.UpdatePart2OperationDataUrl, OperationModel);
  }
  UpdatePart2EconomicData(EconomicModel: any) {
    return this.http.post<EconomicModel[]>(this.appsettings.apiBaseUrl + this.UpdatePart2EconomicDataUrl, EconomicModel);
  }
  UpdatePart2ExecutionData(ExecutionModel: any) {
    return this.http.post<ExecutionModel[]>(this.appsettings.apiBaseUrl + this.UpdatePart2ExecutionDataUrl, ExecutionModel);
  }
  UpdatePart2BusinessData(BusinessModel: any) {
    return this.http.post<BusinessModel[]>(this.appsettings.apiBaseUrl + this.UpdatePart2BusinessDataUrl, BusinessModel);
  }
  UpdatePart2DesignCommercialData(DesignCommercialModel: any) {
    return this.http.post<DesignCommercialModel[]>(this.appsettings.apiBaseUrl + this.UpdatePart2DesignCommercialDataUrl, DesignCommercialModel);
  }
  UpdatePart2ExecutionTargetAssuranceData(ExecutionTargetAssuranceModel: any) {
    return this.http.post<ExecutionTargetAssuranceModel[]>(this.appsettings.apiBaseUrl + this.UpdatePart2ExecutionTargetAssuranceDataUrl, ExecutionTargetAssuranceModel);
  }
  postIsOnHoldValue(Part1Model: EAPart1Model) {
    return this.http.post<EAPart1Model>(this.appsettings.apiBaseUrl + this.postIsOnHoldValueUrl, Part1Model);
  }
  deleteForm(formId: number, versionName: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.deleteFormUrl + formId + "&version=" + versionName);
  }
  GetUserAccessListForEAProject(paramProjectId: string, paramOriginalId: string, paramVersion: string, userRole: string, objectId: string, provId: string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetUserAccessListForEAProjectURL + paramProjectId + "&paramOriginalId=" + paramOriginalId + "&paramVersion=" + paramVersion + "&userRole=" + userRole + "&objectId=" + objectId + "&provId=" + provId);
  }

  GetNewARPDF(projectId: number, formId: number, version: number) {
    const url = this.appsettings.apiBaseUrl + this.GetNewARPDFUrl + projectId + "&formId=" + formId + "&version=" + version;
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }
}
