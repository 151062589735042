


<div >
    <div>
        <button type="button" (click)="onDismiss()" class="buttonclose" aria-hidden="true">×</button>
        <h4 class="title">{{title}}</h4>
    </div>
    <hr>
    <div>
        <p class="pbody">{{message}}</p>
    </div>
    
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onDismiss()">Close</button>
        <a class="btn btn-sm btn-danger" (click)="onConfirm()">Delete</a>
    </div>

</div>


<!-- 
<div id="warningModal" class="modal in" tabindex="-1" role="dialog" aria-hidden="false" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" id="warningMessage">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <h5 class="modal-title">Delete User</h5>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to delete the user?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <a class="btn btn-sm btn-danger" href="/Manage/UserAccesses/Delete/1">Delete</a>
            </div>

        </div>
    </div>
</div> -->