<div id="page-body">
  <div class="box-container">
    <div class="row text-center">
      <h3>Delete Project</h3>
    </div>

    <div class="row">
      <hr />
    </div>

    <div class="row text-center">
      <h4>Careful:  deleting the project will delete the project and ALL associated FORMS</h4>
    </div>

    <div class="row">
      <div class="col-xs-6 text-right">
        <div class="col-xs-8">
        </div>
        <div class="col-xs-4"  *ngIf="data.ProjectType=='1'">
          <a [routerLink]="['/go36project/project/']" class="btn btn-block btn-info">Cancel</a>
        </div>
        <div class="col-xs-4" *ngIf="data.ProjectType=='2'">
          <a [routerLink]="['/ea/project/']" class="btn btn-block btn-info">Cancel</a>
        </div>
      </div>
      <div class="col-xs-6 text-left">
        <div class="col-xs-4">
          <button class="btn btn-block btn-danger" style="color: white !important" (click)="DeleteProject()">Confirm Delete</button>
        </div>
        <div class="col-xs-8">
        </div>
      </div>
    </div>

    <div class="row">
      <hr />
    </div>
    <form [formGroup]="formValue">
    <div class="row">
      <div class="form-row">
        <div class="form-group col-xs-2">
          <label for="ProjectId">Project #:</label>
          <input class="form-control" formControlName="ProjectId" id="ProjectId_Value" readonly="readonly"
            type="text" value="">
        </div>
        <div class="form-group col-xs-2">
          <label for="Name">Project Name:</label>
          <input class="form-control" formControlName="Name" id="Name_Value" readonly="readonly"
            type="text" value="">
        </div>
        <div class="form-group col-xs-2">
          <label for="OpcoId">Segment:</label>
          <select formControlName="OpcoId" [attr.disabled]="butDisabled" class="form-control">
            <!-- <option disabled>Select OPCO</option> -->
            <option *ngFor="let web of OpcoList" [value]="web.OpcoId">
              {{web.OpcoName}}</option>
          </select>
        </div>
        <div class="form-group col-xs-2">
          <label class="form-label" for="SbuId">RU:</label>
          <select formControlName="SbuId" [attr.disabled]="butDisabled" class="form-control">
            <!-- <option disabled>Select SBU</option> -->
            <option *ngFor="let web of SbuList" [value]="web.SbuId">{{web.SbuName}}</option>
          </select>
        </div>
        <div class="form-group col-xs-2">
          <label class="form-label" for="BuId">BU:</label>
          <select formControlName="BuId" [attr.disabled]="butDisabled" class="form-control">
            <!-- <option disabled>Select BU</option> -->
            <option *ngFor="let web of BuList" [value]="web.BuId">{{web.BuName}}</option>
          </select>
        </div>
        <div class="form-group col-xs-2">
          <label class="form-label" for="CreatedBy">Created By:</label>
          <input class="form-control" formControlName="CreatedBy" id="CreatedBy_Value" readonly="readonly"
            type="text" value="">
        </div>
      </div>
    </div>
    </form>
    <div class="row">
      <hr />
    </div>
  </div>
</div>