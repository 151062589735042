import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Go36ArP4LookbackHeaderModel } from 'src/app/Model/Part4LookBack/Part4LookBackHeader.model';
import { Go36P4LookbackDocument } from 'src/app/Model/Part4LookBack/Part4LookBack.model';
import { AppsettingsService } from 'src/app/Shared/appsettings.service';
import { Go36ArP5LookbackPerformance } from 'src/app/Model/Part5LookBack.model';

@Injectable({
  providedIn: 'root'
})

export class Part5LookbackService {
  GetPart5LookBacktUrl = 'api/LookbackPart5/GetPart5LookBack?formId=';
  CreatePart5LookBackUrl = 'api/LookbackPart5/CreatePart5LookBack';
  UpdatePart5LookBackUrl = 'api/LookbackPart5/UpdatePart5HeaderData';
  UpdatePart5LookBackPerformanceUrl='api/LookbackPart5/UpdatePart5PerformanceData';
  getPart5FormDataUrl='api/LookbackPart5/GetPart5FormData?formId=';
  deletePart5FormUrl='api/LookbackPart5/DeletePart5Form?formId=';
  UploadDocumentUrl = 'api/LookbackPart5/Upload';
  DownloadUrl = 'api/LookbackPart5/Download?id=';
  DeleteDocumentUrl = 'api/LookbackPart5/DeleteDocument';
  UpdateOnHoldPart5LookBackUrl = 'api/LookbackPart5/UpdateOnHoldPart5HeaderData';
  GetPart5PDFUrl = 'api/LookbackPart5/GetPart5PDF?projectId=';

  constructor(private http: HttpClient, private appsettings: AppsettingsService) { }

  GetPart5LookBack(formId: number, versionName: number,objectId:string,provId:string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetPart5LookBacktUrl + formId + '&versionName=' + versionName + '&objectId=' + objectId+ '&provId=' + provId);
  }

  CreatePart5LookBack(projectId: number, objectId: string, provId: string) {
    const params = new HttpParams()
      .append('projectId', projectId)
      .append('objectId', objectId)
      .append('provId', provId)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.CreatePart5LookBackUrl, '', {
      params: params
    });
  }

  UpdatePart5LookBack(LookBack4Data: any) {
    return this.http.post<Go36ArP4LookbackHeaderModel[]>(this.appsettings.apiBaseUrl + this.UpdatePart5LookBackUrl, LookBack4Data);
  }

  saveDocument(p5LookbackId: number, provId: string, file: FormData) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UploadDocumentUrl + '?p5LookbackId=' + p5LookbackId + '&provId=' + provId, file);
  }

  downloadFile(id: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get<Go36P4LookbackDocument>(this.appsettings.apiBaseUrl + this.DownloadUrl + id, httpOptions);
  }

  DeleteDocument(document: any) {
    return this.http.post<Go36P4LookbackDocument[]>(this.appsettings.apiBaseUrl + this.DeleteDocumentUrl, document);
  }
  
  UpdatePart5LookBackPerformance(LookBack5PerformanceData: any) {
    return this.http.post<Go36ArP5LookbackPerformance[]>(this.appsettings.apiBaseUrl + this.UpdatePart5LookBackPerformanceUrl, LookBack5PerformanceData);
  }
  getPart5FormData(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getPart5FormDataUrl + formId);
  }

  deletePart5Form(formId: number,versionName: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.deletePart5FormUrl + formId + "&version="+versionName);
  }

  UpdateOnHoldPart5LookBack(LookBack4Data: any) {
    return this.http.post<Go36ArP4LookbackHeaderModel[]>(this.appsettings.apiBaseUrl + this.UpdateOnHoldPart5LookBackUrl, LookBack4Data);
  }

  GetPart5PDF(projectId: number,formId:number,version:number, objectId: string, provId: string){
    const url = this.appsettings.apiBaseUrl + this.GetPart5PDFUrl + projectId+"&formId="+formId+"&version="+version+"&objectId="+objectId+"&provId="+provId;
    return this.http.get<any>(url, {responseType: 'blob' as 'json'});      
  }
}
