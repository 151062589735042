import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { ActivatedRoute, Router } from '@angular/router';
import { Go36P4LookbackDocument } from 'src/app/Model/Part4LookBack/Part4LookBack.model';
import { NotificationService } from 'src/app/Shared/notification.service';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ArchiveDialogComponent } from 'src/app/Modals/archive-dialog/archive-dialog.component';
import { LessonsLearnedService } from 'src/app/forms/FEEDServices/lessons-learned.service';
import { EaPart4LookbackService } from '../../service/ea-part4-lookback.service';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { EAP4LookbackDocument, EAP4LookbackHeaderModel, EAP4LookbackPerformance, EAPart4ViewModel } from 'src/app/Model/EAPart4LookBack.model';
import { CommentDialogComponent, CommentDialogModel } from 'src/app/Modals/comment-dialog/comment-dialog.component';
import { EaCommentModel } from 'src/app/Model/EaComment.model';
import { EaprojectService } from '../../service/eaproject.service';
import { FormAction } from 'src/app/Model/FormAction.model';
import { FormsLog } from 'src/app/Model/FormsLog.model';
import { User } from 'src/app/Model/User.model';
import { SignOffsModel } from 'src/app/Model/Signoffs.model';
import { UtilityService } from 'src/app/Shared/utility.service';
import { FormReviewerService } from 'src/app/forms/FEEDServices/form-reviewer.service';
import { CompleterService } from 'ng2-completer';
import { ApprovalDialogComponent } from 'src/app/Modals/approval-dialog/approval-dialog.component';
import { environment } from 'src/environments/environment';
import { FormStatus } from 'src/app/Model/Form.model';
import { FeaturesImplemented } from 'src/app/Model/features-implemented';

@Component({
  selector: 'app-ea-part4-lookback',
  templateUrl: './ea-part4-lookback.component.html',
  styleUrls: ['./ea-part4-lookback.component.css']
})

export class EaPart4LookbackComponent implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [
      // 'fontfamily','fontsize','fontColor','fontBackgroundColor', '|',
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
    ],
    autosave: {
      save(editor) {
        // The saveData() function must return a promise
        // which should be resolved when the data is successfully saved.
      }
    }
  }

  ckeditorText: string = '';
  checkbox: boolean = false;
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  formId: number = 0;
  isOptOut = false;
  isOnHold = false;
  versionName: number = 0;
  formType: string = '';
  options = this._formBuilder.group({
    bottom: 0,
    fixed: true,
    top: 0,
  });

  LLMaxOrder: number = 0;
  KDMaxOrder: number = 0;
  showSection1: boolean = true;
  showSection2: boolean = false;
  showSection3: boolean = false;
  showSection4: boolean = false;
  showSection5: boolean = false;
  showSection6: boolean = false;
  showSection7: boolean = false;
  showSection8: boolean = false;
  showSection9: boolean = false;
  showSection10: boolean = false;
  showSection11: boolean = false;
  showSection12: boolean = false;


  featuresImplemented = new FeaturesImplemented();


  projectId: number = 0;
  Part4Model = new EAPart4ViewModel;
  Part4Header = new EAP4LookbackHeaderModel;
  Part4Performance = new EAP4LookbackPerformance;
  projectData: any;
  Part4Performancedata: any;
  part4Document: EAP4LookbackDocument[] = [];
  projectName: string = '';
  Comments: any = [];
  userAccessList: any;
  userAccessDetails: any;

  // Form Reviewer Fields
  formActionModel = new FormAction();
  selectedUser: any;
  filteredUsers: any = [];
  searchuser: any;
  userinfo: any = [];
  data: any;
  name: any = '';
  fromPage: boolean = true;
  formLink: any = '/ar/request/index/';
  role: string = '';
  ProjectId: number = 0;
  reviewerData: FormAction[] = [];
  rowData: any = [];
  UserName = this.projectservice.GetuserName();
  showDelete: boolean = false;
  editMode: boolean = localStorage.getItem('editMode') == 'true';
  AreMultipleAdmins: boolean = true;
  @Input() formsLogData: FormsLog[] = [];
  reviewerCount: boolean = false;
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;
  @Input() formReviewData: any = null;
  dataService: any;
  searchStr: string = '';
  placeholder: string = '';
  searchData: Array<User> = [];
  href: string = "";
  bUrl: string = "";
  signOffData: SignOffsModel[] = [];
  baseUrl: any = environment.apiBaseUrl;
  pdfUrl: any = "";

  sendBackLockByFormState:boolean = false;
  forReviewLockByFormState:boolean = false;
  concurLockByFormState:boolean = false;
  selectedUserObjectId: string = '';
  selectedUserProvId: string = '';

  constructor(private _formBuilder: UntypedFormBuilder, private lessonLearnedService: LessonsLearnedService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute, private router: Router,
    private part4lookbackService: EaPart4LookbackService,
    private projectservice: Go36projectService,
    private notifyService: NotificationService,
    private go36ProjectService: Go36projectService,
    public dialog: MatDialog, private eaprojectservice: EaprojectService,
    private formReviewerService: FormReviewerService,
    private completerService: CompleterService, public utilityService: UtilityService) {
    this.formReviewerService.GetUserBySearch("Hasa").subscribe(res => {
      this.filteredUsers = res;
      this.dataService = completerService.local(
        this.searchData,
        'name',
        'name'
      );
      for (let i = 0; i < res.length; i++) {
        let p = new User(i, res[i].lastName + ' ' + res[i].firstName, res[i].objectId, res[i].provId);
        this.searchData.push(p);
      }

    })
    this.placeholder = "last name, first name";
  }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.route.queryParams
      .subscribe(params => {
        this.projectId = params['projectId'];
        this.formId = params['formId'];
        this.versionName = params['versionName'];
        this.formType = params['requestType']
      });

    this.getProjectInfobyId();
    this.LoadPart4LookBack();
    this.GetUserAccessListForEAP4();
    this.href = this.router.url;
    this.bUrl = window.location.origin;
  }

  setGetFormStatus(formStatus:number){
    if(formStatus == FormStatus.FORM_DRAFT_STATE || formStatus == FormStatus.FORM_REVISION_STATE){
      this.forReviewLockByFormState = false;
      this.sendBackLockByFormState = true;
      this.concurLockByFormState = true;
    }else if(formStatus == FormStatus.FORM_REVIEW_STATE){
      this.forReviewLockByFormState = true;
      this.sendBackLockByFormState = false;
      this.concurLockByFormState = false;
    }else{
      this.forReviewLockByFormState = false;
      this.sendBackLockByFormState = false;
      this.concurLockByFormState = false;
    }
  }

  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }

  LoadPart4LookBack() {

    this.eaprojectservice.getEAFormFeaturesForEAP4Lookback(this.formId,this.versionName).subscribe((res:FeaturesImplemented) => {
      if (res){
        this.featuresImplemented = res;
      }
    });


    this.part4lookbackService.GetPart4LookBack(this.formId, this.versionName, this.objectId, this.provId).subscribe((res: any) => {

      if (res) {
        this.Part4Model = res;
        this.Part4Header = res.go36EaP4LookbackHeaders;

        this.setGetFormStatus(this.Part4Header.statusId);

        if (this.Part4Header != null &&  this.Part4Header.cvxShare != null) {
          this.Part4Header.cvxShare = Number(this.Part4Header.cvxShare).toString();
          this.Part4Header.cvxShare =  this.Part4Header.cvxShare + '%';
        }
        // //this.projectName = this.projectData.buName + ' - ' + ' ' + this.projectData.projectName + ' - ' + '(version #' + this.Part4Header.version + ')';
        this.Part4Performance = res.go36EaP4LookbackPerformance;
        this.part4Document = res.go36EaP4LookbackDocument;
        this.Comments = res.comments;
        this.Part4Performance.ceEv = Number(this.Part4Performance.ceEv).toFixed(1).toString();
        this.Part4Performance.ceP10 = Number(this.Part4Performance.ceP10).toFixed(1).toString();
        this.Part4Performance.ceP90 = Number(this.Part4Performance.ceP90).toFixed(1).toString();
        this.Part4Performance.ceArBasisEv = Number(this.Part4Performance.ceArBasisEv).toFixed(1).toString();
        this.Part4Performance.ceArBasisActualOutcomes = Number(this.Part4Performance.ceArBasisActualOutcomes).toFixed(1).toString();
        this.Part4Performance.ceArBasisNormalized = Number(this.Part4Performance.ceArBasisNormalized).toFixed(1).toString();
        this.Part4Performance.benchmarkExecutionEv = Number(this.Part4Performance.benchmarkExecutionEv).toFixed(1).toString();
        this.Part4Performance.benchmarkExecutionIndustryAverage = Number(this.Part4Performance.benchmarkExecutionIndustryAverage).toFixed(1).toString();
        this.Part4Performance.benchmarkExecutionActualOutcomes = Number(this.Part4Performance.benchmarkExecutionActualOutcomes).toFixed(1).toString();
        this.Part4Performance.benchmarkExecutionTopQuartile = Number(this.Part4Performance.benchmarkExecutionTopQuartile).toFixed(1).toString();
        this.Part4Performance.benchmarkExecutionNormalized = Number(this.Part4Performance.benchmarkExecutionNormalized).toFixed(1).toString();
        this.signOffData = res.lookBackFormSignoff;
        this.reviewerData = res.formActionList;
        this.formsLogData = res.formLog;
        this.pdfUrl = `${this.baseUrl}api/EAPart4/GetEAPart4PDF?projectId=${this.projectId}&formId=${this.formId}&version=${this.versionName}&objectId=${this.objectId}&provId=${this.provId}`;
        if (this.reviewerData?.length != 0) {
          this.reviewerCount = true;
        }
      }
      this.notifyService.showSuccess("Data Load/Save successfully", "Status");

    });
  }

  getProjectInfobyId() {
    this.projectservice.GetProjectInfobyId(this.projectId).subscribe(res => {
      this.projectData = res;
      this.projectName = this.projectData.buName + ' - ' + ' ' + this.projectData.projectName + ' - ' + '(version #' + this.versionName + ')';
    });
  }

  showSection(sectionNumber: number) {
    this.showSection1 = this.showSection2 = this.showSection3 = this.showSection4 = this.showSection5 =
      this.showSection6 = this.showSection7 = this.showSection8 = this.showSection9 = this.showSection10 =
      this.showSection11 = this.showSection12 = false;

    if (sectionNumber == 1) {
      this.showSection1 = true;
    }
    else if (sectionNumber == 2) {
      this.showSection2 = true;
    }
    else if (sectionNumber == 3) {
      this.showSection3 = true;
    }
    else if (sectionNumber == 4) {
      this.showSection4 = true;
    }
    else if (sectionNumber == 5) {
      this.showSection5 = true;
    }
    else if (sectionNumber == 6) {
      this.showSection6 = true;
    }
    else if (sectionNumber == 7) {
      this.showSection7 = true;
    }
    else if (sectionNumber == 8) {
      this.showSection8 = true;
    }
    else if (sectionNumber == 9) {
      this.showSection9 = true;
    }
    else if (sectionNumber == 10) {
      this.showSection10 = true;
    }
    else if (sectionNumber == 11) {
      this.showSection11 = true;
    }
    else if (sectionNumber == 12) {
      this.showSection12 = true;
    }
  }

  onReady(editor, name) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.UpdateckeditorChanges(editor.getData(), name);
        }
      }
    });
  }
  hasComment(section: string, controlId: string) {
    return this.Comments.filter(x => x.section == section && x.controlId == controlId)[0] == undefined ? false : this.Comments.filter(x => x.section == section && x.controlId == controlId)[0].comment.length > 0;
  }
  GetComments(section: string, controlId: string) {
    let res = this.Comments.filter(x => x.section == section && x.controlId == controlId)[0];
    res = res == undefined ? new EaCommentModel() : res;
    const dialogData = new CommentDialogModel("Update Comment", res.comment, this.isOnHold, this.Part4Header.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });
    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        res.modifiedBy = this.provId;
        res.comment = comment;
        res.arId = this.formId;
        res.section = section;
        res.controlId = controlId;
        this.eaprojectservice.UpdateComments(res).subscribe(result => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
          this.Comments = result;
        });
      }
    });
  }
  UpdateckeditorChanges(event: customBuild.EventInfo, textName: string) {
    this.ckeditorText = event;
    this.Part4Header.modifiedBy = this.provId;

    if (textName == 'projectCompletionSummary') {
      this.Part4Header.projectCompletionSummary = this.ckeditorText;
    }
    else if (textName == 'keyInsights') {
      this.Part4Header.keyInsights = this.ckeditorText;
    }
    else if (textName == 'section5') {
      this.Part4Header.operationalResults = this.ckeditorText;
    }
    else if (textName == 'section6') {
      this.Part4Header.expenditureResults = this.ckeditorText;
    }
    else if (textName == 'section7') {
      this.Part4Header.scheduleResults = this.ckeditorText;
    }
    else if (textName == 'section8') {
      this.Part4Header.commercialAgreementResults = this.ckeditorText;
    }
    else if (textName == 'section9') {
      this.Part4Header.earlyOperationalResults = this.ckeditorText;
    }
    else if (textName == 'section10') {
      this.Part4Header.appraisalWellResults = this.ckeditorText;
    }

    this.UpdatePart4LookBackHeaderData(this.Part4Header);
  }

  UpdatePart4LookBackHeaderData(Part4Headerdata: EAP4LookbackHeaderModel) {
    Part4Headerdata.modifiedBy = this.provId;
    Part4Headerdata.cvxShare = this.Part4Header.cvxShare == '' || this.Part4Header.cvxShare == null ? '' : parseFloat(Part4Headerdata.cvxShare);
    this.part4lookbackService.UpdatePart4LookBack(Part4Headerdata).subscribe(res => {
      res;
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
    if (this.Part4Header != null &&  this.Part4Header.cvxShare != null) {
      this.Part4Header.cvxShare = Number(this.Part4Header.cvxShare).toString();
      this.Part4Header.cvxShare =  this.Part4Header.cvxShare + '%';
    }
  }

  IdentifiedLookBackTypeCheck(event: any, Part4Header: EAP4LookbackHeaderModel, checktype: string) {
    Part4Header.modifiedBy = this.provId;
    if (checktype == "lookbackType1") { Part4Header.lookbackType1 = event.target.checked; }
    else if (checktype == "lookbackType2") { Part4Header.lookbackType2 = event.target.checked; }
    else if (checktype == "lookbackType3") { Part4Header.lookbackType3 = event.target.checked; }
    else if (checktype == "lookbackType4") { Part4Header.lookbackType4 = event.target.checked; }

    else if (checktype == "singleProject") { Part4Header.singleProject = event.target.checked; }
    else if (checktype == "multiProject") { Part4Header.multiProject = event.target.checked; }
    else if (checktype == "nojv") { Part4Header.nojv = event.target.checked; }
    this.part4lookbackService.UpdatePart4LookBack(Part4Header).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  ShowOnHoldModal() {
    const message = "When the HOLD button is activated, the form for this project will be placed on a “Hold” status. Fields on the forms cannot be edited while form is on “HOLD” status. Only users with administrator rights can put a form on HOLD or remove it."
      + "<br/>" + "You should use HOLD status when you want to keep the information entered in a form for future use. Otherwise, you may consider deleting the form";
    const dialogData = new InfoDialogModel("Hold", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  onChange(value: MatSlideToggleChange, Part4Headerdata: EAP4LookbackHeaderModel) {
    this.isOnHold = value.checked;

    Part4Headerdata.modifiedBy = this.provId;
    Part4Headerdata.isOnHold = this.isOnHold;

    this.part4lookbackService.UpdateOnHoldPart4LookBack(Part4Headerdata).subscribe(res => {
      //update value in form reviewer log
      this.getLogData();
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  OpenArchiveDialog() {
    this.dialog.open(ArchiveDialogComponent, {
      width: "600px",
      data: { projectId: this.projectId, formId: this.Part4Header.originalId, oldVersionId: this.Part4Header.version, formType: 'EAPart4' },
    });
  }

  Section7Attachments(event: any, Part4Header: EAP4LookbackHeaderModel, checktype: string) {
    Part4Header.modifiedBy = this.provId;

    if (checktype == "IPA") { Part4Header.attachIpa = event.target.checked; }
    else if (checktype == "rootCause") { Part4Header.attachL1RootCause = event.target.checked; }
    else if (checktype == "reviewTeam") { Part4Header.attachL1ReviewTeam = event.target.checked; }
    else if (checktype == "exploration") { Part4Header.attachL1ExplorationProspect = event.target.checked; }
    else if (checktype == "basisOfDesign") { Part4Header.attachSubsurfaceBasisDesign = event.target.checked; }

    this.part4lookbackService.UpdatePart4LookBack(Part4Header).subscribe(res => {
      this.notifyService.showSuccess("", "");
    });
  }

  UpdatePart4LookBackPerformanceData(Part4Performance: EAP4LookbackPerformance) {
    Part4Performance.modifiedBy = this.provId;
    Part4Performance.lookbackId = this.Part4Header.arLookbackId;
    Part4Performance.createdBy = this.Part4Performance.createdBy;
    const ardate = new Date(Part4Performance.dataDate!);
    Part4Performance.dataDate = this.notifyService.ConvertDateBeforeSave(ardate);
    this.Part4Performance.ceEv = Part4Performance.ceEv == '' || Part4Performance.ceEv == null ? '' : parseFloat(Part4Performance.ceEv);
    this.Part4Performance.ceP10 = Part4Performance.ceP10 == '' || Part4Performance.ceP10 == null ? '' : parseFloat(Part4Performance.ceP10);
    this.Part4Performance.ceP90 = Part4Performance.ceP90 == '' || Part4Performance.ceP90 == null ? '' : parseFloat(Part4Performance.ceP90);
    this.Part4Performance.ceArBasisEv = Part4Performance.ceArBasisEv == '' || Part4Performance.ceArBasisEv == null ? '' : parseFloat(Part4Performance.ceArBasisEv);
    this.Part4Performance.ceArBasisActualOutcomes = Part4Performance.ceArBasisActualOutcomes == '' || Part4Performance.ceArBasisActualOutcomes == null ? '' : parseFloat(Part4Performance.ceArBasisActualOutcomes);
    this.Part4Performance.ceArBasisNormalized = Part4Performance.ceArBasisNormalized == '' || Part4Performance.ceArBasisNormalized == null ? '' : parseFloat(Part4Performance.ceArBasisNormalized);
    this.Part4Performance.benchmarkExecutionEv = Part4Performance.benchmarkExecutionEv == '' || Part4Performance.benchmarkExecutionEv == null ? '' : parseFloat(Part4Performance.benchmarkExecutionEv);
    this.Part4Performance.benchmarkExecutionIndustryAverage = Part4Performance.benchmarkExecutionIndustryAverage == '' || Part4Performance.benchmarkExecutionIndustryAverage == null ? '' : parseFloat(Part4Performance.benchmarkExecutionIndustryAverage);
    this.Part4Performance.benchmarkExecutionActualOutcomes = Part4Performance.benchmarkExecutionActualOutcomes == '' || Part4Performance.benchmarkExecutionActualOutcomes == null ? '' : parseFloat(Part4Performance.benchmarkExecutionActualOutcomes);
    this.Part4Performance.benchmarkExecutionTopQuartile = Part4Performance.benchmarkExecutionTopQuartile == '' || Part4Performance.benchmarkExecutionTopQuartile == null ? '' : parseFloat(Part4Performance.benchmarkExecutionTopQuartile);
    this.Part4Performance.benchmarkExecutionNormalized = Part4Performance.benchmarkExecutionNormalized == '' || Part4Performance.benchmarkExecutionNormalized == null ? '' : parseFloat(Part4Performance.benchmarkExecutionNormalized);

    this.Part4Performance.estUltimateRecoveryMmboActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboActualOutcomes));
    this.Part4Performance.estUltimateRecoveryMmboEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboEv));
    this.Part4Performance.estUltimateRecoveryMmboP10 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboP10));
    this.Part4Performance.estUltimateRecoveryMmboP90 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboP90));

    this.Part4Performance.estUltimateRecoveryMmboeActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboeActualOutcomes));
    this.Part4Performance.estUltimateRecoveryMmboeEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboeEv));
    this.Part4Performance.estUltimateRecoveryMmboeP10 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboeP10));
    this.Part4Performance.estUltimateRecoveryMmboeP90 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryMmboeP90));

    this.Part4Performance.estUltimateRecoveryBcfActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryBcfActualOutcomes));
    this.Part4Performance.estUltimateRecoveryBcfEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryBcfEv));
    this.Part4Performance.estUltimateRecoveryBcfP10 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryBcfP10));
    this.Part4Performance.estUltimateRecoveryBcfP90 = this.utilityService.AllowNoDecimal(Number(Part4Performance.estUltimateRecoveryBcfP90));

    this.Part4Performance.trirActualOutcomes = this.utilityService.AllowTwoDecimal(Number(Part4Performance.trirActualOutcomes));
    this.Part4Performance.trirBenchTopQuartile = this.utilityService.AllowTwoDecimal(Number(Part4Performance.trirBenchTopQuartile));
    this.Part4Performance.trirBenchIndustryAverage = this.utilityService.AllowTwoDecimal(Number(Part4Performance.trirBenchIndustryAverage));

    this.Part4Performance.dafwrActualOutcomes = this.utilityService.AllowTwoDecimal(Number(Part4Performance.dafwrActualOutcomes));
    this.Part4Performance.dafwrBenchTopQuartile = this.utilityService.AllowTwoDecimal(Number(Part4Performance.dafwrBenchTopQuartile));
    this.Part4Performance.dafwrBenchIndustryAverage = this.utilityService.AllowTwoDecimal(Number(Part4Performance.dafwrBenchIndustryAverage));

    this.Part4Performance.executionScheduleEv = this.utilityService.AllowNoDecimal(Number(Part4Performance.executionScheduleEv));
    this.Part4Performance.executionScheduleP10 = this.utilityService.AllowNoDecimal(Number(Part4Performance.executionScheduleP10));
    this.Part4Performance.executionScheduleP90 = this.utilityService.AllowNoDecimal(Number(Part4Performance.executionScheduleP90));
    this.Part4Performance.executionScheduleActualOutcomes = this.utilityService.AllowNoDecimal(Number(Part4Performance.executionScheduleActualOutcomes));
    this.Part4Performance.executionScheduleBenchTopQuartile = this.utilityService.AllowNoDecimal(Number(Part4Performance.executionScheduleBenchTopQuartile));
    this.Part4Performance.executionScheduleBenchIndustryAverage = this.utilityService.AllowNoDecimal(Number(Part4Performance.executionScheduleBenchIndustryAverage));

    this.part4lookbackService.UpdatePart4LookBackPerformance(this.Part4Performance).subscribe(res => {
      res;
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    Part4Performance.dataDate = this.notifyService.ConvertDateAfterSave(ardate);
    Part4Performance.ceEv = Number(Part4Performance.ceEv).toFixed(1).toString();
    this.Part4Performance.ceEv = Number(this.Part4Performance.ceEv).toFixed(1).toString();
    this.Part4Performance.ceP10 = Number(this.Part4Performance.ceP10).toFixed(1).toString();
    this.Part4Performance.ceP90 = Number(this.Part4Performance.ceP90).toFixed(1).toString();
    this.Part4Performance.ceArBasisEv = Number(this.Part4Performance.ceArBasisEv).toFixed(1).toString();
    this.Part4Performance.ceArBasisActualOutcomes = Number(this.Part4Performance.ceArBasisActualOutcomes).toFixed(1).toString();
    this.Part4Performance.ceArBasisNormalized = Number(this.Part4Performance.ceArBasisNormalized).toFixed(1).toString();
    this.Part4Performance.benchmarkExecutionEv = Number(this.Part4Performance.benchmarkExecutionEv).toFixed(1).toString();
    this.Part4Performance.benchmarkExecutionIndustryAverage = Number(this.Part4Performance.benchmarkExecutionIndustryAverage).toFixed(1).toString();
    this.Part4Performance.benchmarkExecutionActualOutcomes = Number(this.Part4Performance.benchmarkExecutionActualOutcomes).toFixed(1).toString();
    this.Part4Performance.benchmarkExecutionTopQuartile = Number(this.Part4Performance.benchmarkExecutionTopQuartile).toFixed(1).toString();
    this.Part4Performance.benchmarkExecutionNormalized = Number(this.Part4Performance.benchmarkExecutionNormalized).toFixed(1).toString();



  }

  uploadFile = (event: any) => {
    const file = event.target.files[0];
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.part4lookbackService.saveDocument(this.Part4Header.arLookbackId, this.provId, formData).subscribe((res: any) => {
      this.notifyService.showSuccess("File uploaded successfully.", "");
      this.part4Document = res;
    });
  }

  DownloadFile(lookbackDocument: Go36P4LookbackDocument) {

    this.part4lookbackService.downloadFile(lookbackDocument.documentId).subscribe((data: any) => {
      let downloadURL = window.URL.createObjectURL(data);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = lookbackDocument.docName;// //name == null ? '' : name;
      link.click();
    });
  }

  RemoveFile(document: Go36P4LookbackDocument, index: any) {
    document.modifiedBy = this.provId;
    document.hidefromlist = true;
    this.part4Document.splice(index, 1);

    this.part4lookbackService.DeleteDocument(document).subscribe(res => {
      // //this.part4Document = res;
    });
  }

  GetUserAccessListForEAP4() {
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);
    let userRole: string = '';
    // we can create the constants of the users role
    if (this.userAccessDetails?.data?.isPowerUser) {
      userRole = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      userRole = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      userRole = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      userRole = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      userRole = "Leadership";
    }
    this.part4lookbackService.GetUserAccessListForEAP4(this.projectId.toString(), this.formId.toString(), this.versionName.toString(), userRole, this.objectId, this.provId).subscribe(res => {
      this.userAccessList = res;
      
      if(!this.userAccessList?.data?.canViewProject){
        this.router.navigate(["errorpage"]);
      }
    });
  }

  // Form Reviewer Methods

  getReviewerData() {
    this.formReviewerService.getReviewerData(this.formId, this.projectId, this.versionName, this.objectId, this.provId, "EA_LOOKBACK_PART4").subscribe(res => {
      this.reviewerData = res;
      if (this.reviewerData?.length != 0) {
        this.reviewerCount = true;
      }
    });
  }

  getLogData() {
    this.formReviewerService.getLogData(this.formId, this.projectId, this.versionName, "EA_LOOKBACK_PART4").subscribe(res => {
      this.formsLogData = res;
    });
  }

  getSignData() {
    this.formReviewerService.getSignOffData(this.formId, this.versionName).subscribe(res => {
      this.signOffData = res;
    }
    );
  }

  UpdateSignOff(signOff: SignOffsModel) {
    signOff.modifiedBy = this.provId;
    const date = new Date(signOff.signoffDate!);

    if (signOff.signoffDate != null) {
      signOff.signoffDate = this.notifyService.ConvertDateBeforeSave(date);
    }
    this.formReviewerService.postLookBackSignOffData(signOff).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
    if (signOff.signoffDate != null) {
      signOff.signoffDate = this.notifyService.ConvertDateAfterSave(date);
    }
  }

  addUserToAccessList(event: any) {
    this.selectedUserObjectId = event.originalObject.objectId;
    this.selectedUserProvId = event.originalObject.provId;
    this.AddNewUserAccess(this.projectId, this.formId, this.versionName, this.objectId, this.provId, this.selectedUserObjectId, this.selectedUserProvId);
  }

  AddNewUserAccess(ProjectId: number, formId: number, versionId: number, objectId: string, provId: string, selectedUserObjectId: string, selectedUserProvId: string) {
    this.formReviewerService.PostUseraccess(ProjectId, formId, versionId, objectId, provId, selectedUserObjectId, selectedUserProvId, "EA_LOOKBACK_PART4").subscribe(res => {
      this.getReviewerData()
      this.getLogData();
    });
  }

  UpdateReviewer(id: number, action: string,reviewerItem:FormAction ,comments?: string) {
    if (comments == null || comments == '') {
      this.notifyService.showError("Please enter comments before Form Action", "Warning");
    }
    else {
      this.formActionModel.projectId = this.projectId;
      this.formActionModel.formId = this.formId;
      this.formActionModel.versionId = this.versionName;
      this.formActionModel.formType = "EA_LOOKBACK_PART4";
      this.formActionModel.comments = comments;
      this.formActionModel.formActionId = id;
      this.formActionModel.action = action;
      this.formActionModel.objectId = this.objectId;
      this.formActionModel.provId = this.provId;
      this.formActionModel.currentUrl = this.href;
      this.formActionModel.currentBUrl = this.bUrl;
      this.formActionModel.projectName = this.projectData.projectName;

      this.formActionModel.submitterUserId = reviewerItem.submitterUserId;
      this.formActionModel.reviewerUserId = reviewerItem.reviewerUserId;
      
      this.formReviewerService.approvalForm(this.formActionModel).subscribe(res => {
        this.getLogData();
        this.getReviewerData();
      });
    }
  }

  DeleteReviewer(formAction: FormAction, opertionType: string) {
    formAction.action = opertionType;
    formAction.active = false;

    formAction.submitterComments = formAction.submitterComments || '';
    this.formReviewerService.postDeleteReviewerData(formAction).subscribe(res => {
      this.getLogData();
      this.getReviewerData();
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
  }

  deleteRow(index: any) {
    // // let usertoRemove = this.reviewerData[index];
    this.reviewerData.splice(index, 1);
  }

  addRow(event: any) {
    this.AddNewUserAccess(this.projectId, this.formId, this.versionName, this.objectId, this.provId, this.selectedUserObjectId, this.selectedUserProvId);
  }

  ShowSignoffsPopUp() {
    const message = 'Outline approvals needed for investment appropriation. Wet signatures, where still applicable,'
      + '\n' + 'can be done in signature & date boxes on final printout';
    const dialogData = new InfoDialogModel("Signoffs", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  performFormAction(action:string){
    let formActionModel = new FormAction();
    formActionModel.projectId = this.projectId;
    formActionModel.formId = this.formId;
    formActionModel.versionId = this.versionName;
    formActionModel.action = action;
    formActionModel.formType = "EA_LOOKBACK_PART4";
    formActionModel.loggedInUser = this.provId;
    this.formReviewerService.processFormAction(formActionModel).subscribe(res => {
      //getting form data again on successful for-action
      this.getProjectInfobyId();
      this.LoadPart4LookBack();
      this.GetUserAccessListForEAP4();
    })
  }

  addDefaultUserAccess() {
    // // let text = this.UserName.split(",");
    // // let lname = text[0].trim();
    // // let fname = text[1].split('[')[0].trim();
    if (localStorage.getItem("goProject")) {
      const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")))
      this.data = { goprojectmodel: JSON.parse(projectDetail) };
    }
    this.AddNewUserAccess(this.projectId, this.formId, this.versionName, this.objectId, this.provId, this.selectedUserObjectId, this.selectedUserProvId);
  }

  GetUserAccesslist() {
    if (this.data.goprojectmodel.ID == null) {
      this.ProjectId = this.data.goprojectmodel.ProjectId;
    }
    else {
      this.ProjectId = this.data.goprojectmodel.ID;
    }

    this.projectservice.getUserAccess(this.ProjectId).subscribe(res => {
      this.reviewerData = res;
    }
    );
  }

  OpenApprovalDialog(reviewerId: number, reviewerComments?: string) {
    if (reviewerComments == null || reviewerComments == '') {
      this.notifyService.showError("Please enter comments before Form Action", "Warning");
    }
    else {
      this.dialog.open(ApprovalDialogComponent, {
        width: "600px",
        data: { projectId: this.projectId, projectName: this.projectData.projectName, formId: this.formId, versionId: this.versionName, formType: "EA_LOOKBACK_PART4", reviewerId: reviewerId, reviewerComments },
      });
    }
  }

  UpdatePart4LookBackHeaderDates(Part4Headerdata: EAP4LookbackHeaderModel) {
    const date = new Date(Part4Headerdata.ardate!);
    Part4Headerdata.ardate = this.notifyService.ConvertDateBeforeSave(date);
    const ardate = new Date(Part4Headerdata.originalDate!);
    Part4Headerdata.originalDate = this.notifyService.ConvertDateBeforeSave(ardate);
    this.part4lookbackService.UpdatePart4LookBack(Part4Headerdata).subscribe(res => {
      res;
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
    Part4Headerdata.ardate = this.notifyService.ConvertDateAfterSave(date);
    Part4Headerdata.originalDate = this.notifyService.ConvertDateAfterSave(ardate);
  }

  ExportPDF() {
    this.part4lookbackService.GetPart4PDF(this.projectId, this.formId, this.versionName, this.objectId, this.provId).subscribe((response) => {
      let file = new Blob([response], { type: 'application/pdf' });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    })
  }
}