export class CapitalAndExpenditures {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn: Date=new Date();
    modifiedOn: Date=new Date();
    order: number = 0;
    formId: number = 0;
    capitalAndExpenditureName?: string = '';
    facilities?: number  = 0;
    drillingAndCompletions?: number  = 0;
    contingencyEscalation?: number  = 0;
    otherJointCosts?: number  = 0;
    otherSoleCosts?: number  = 0;
    totalAppropriated?: number  = 0;
    totalCommitment?: number  = 0;
    expenseOnCapital?: number  = 0;
    coOwnerView?: number  = 0;
    generalAndAdministrative?: number  = 0;
    ownerCost?: number  = 0;
    partnerView?: number  = 0;
    canDelete: boolean = true;
    canEdit: boolean = true;
    overviewId: number = 0;
    totalCommitmentValue?: number  = 0;
    facilitiesComment?: string = '';
    drillingComment?: string = ''; 
    contingencyComment?: string = '';
    otherJointComment?: string = ''; 
    otherSoleComment?: string = ''; 
    totalAppComment?: string = '';  
    expCapComment?: string = ''; 
    totalComComment?: string = '';  
    coOwnerViewComment?: string = '';
    sectionAfterCetotal: boolean = true;
    itemReadyOnly: boolean = false;
    
}