export class OperationModel {
    performanceId: number = 0;
    aRId: number = 0;
    explorationProspectData: boolean = false;        
    subsurfaceBasisOfDesign: boolean = false;   
    footNotes: string = "";
    modifiedBy: string = "";
    ultimateRecovery: OperationalPerformanceFeature1 = new OperationalPerformanceFeature1();
}
export class OperationalPerformanceFeature1
{
    netMmbo: OperationalPerformanceFeature2 = new OperationalPerformanceFeature2();
    netBcf: OperationalPerformanceFeature2 = new OperationalPerformanceFeature2();
    netMmboe: OperationalPerformanceFeature2 = new OperationalPerformanceFeature2();
    mmboe8: OperationalPerformanceFeature2  = new OperationalPerformanceFeature2();
}
export class OperationalPerformanceFeature2
{
    ev: any = null;
    p10: any = null;
    p50: any = null;
    p90: any = null;
}