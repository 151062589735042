import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ArProjectModel } from '../../go36project/go36project.model';
import { HttpClient } from '@angular/common/http';
import { EaprojectService } from '../service/eaproject.service';
import { Go36projectService } from '../../go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { catchError ,Observable,throwError} from 'rxjs';
import { UtilityService } from 'src/app/Shared/utility.service';

interface OpcoInfo {
  OpcoId: number;
  OpcoName: string;
}
interface SbuInfo {
  SbuId: number;
  SbuName: string;
}
interface BuInfo {
  BuId: number;
  BuName: string;
  OpcoId: number;
  SbuId: number;
}
interface AssetInfo {
  AssetId: number;
  AssetName: string;
}
interface SpecialProjectInfo {
  SpclPId: number;
  SpclPName: string;
}
@Component({
  selector: 'app-ea-project-create',
  templateUrl: './ea-project-create.component.html',
  styleUrls: ['./ea-project-create.component.css']
})
export class EaProjectCreateComponent {
  Dropdownprojectsdata: any;
  SelectedValue: any;
  formValue: UntypedFormGroup;
  OpcoList: Array<OpcoInfo> = [];
  SbuList: Array<SbuInfo> = [];
  BuList: Array<BuInfo> = [];
  data: any;
  AssetList: Array<AssetInfo> = [];
  SpecialProjectList: Array<SpecialProjectInfo> = [];
  buData: any;
  SbuData: any;
  isDisabled: boolean = true;
  editMode: boolean = false;
  projectid: any;
  projectModelObj: ArProjectModel = new ArProjectModel();
  ProjectDetails: any;
  from: string = '';
  errormessage: string = '';
  objectId: string = '';
  provId: string = '';
  emailId: string = '';

  constructor(private eaprojectservice: EaprojectService, private projectservice: Go36projectService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router, private FormBuilder: UntypedFormBuilder, private notifyService: NotificationService,
    private utilityServcie: UtilityService
  ) {
    this.formValue = this.FormBuilder.group({
      ProjectName: ['', Validators.required],
      ProjectId: '',
      OpcoId: '9',
      SbuId: '0',
      BuId: '0',
      CreatedBy: "QHSS",
      AssetClassId: 1,
      ModifiedBy: '',
      IsSsbodavailable: false,
      AssetMegaFriendlyName: '',
      Rsid: '',
      SpecialProjectType: 0
    })
  }
  ngOnInit() {
    this.objectId = this.utilityServcie.GetuserObjectId();
    this.provId = this.utilityServcie.GetuserProvisioningId();
    this.emailId = this.utilityServcie.GetuserEmailId();
    let cur_url :string = this.router.url;
    if(this.router.url.includes('update')){
      this.editMode
       = true;
    }else if(this.router.url.includes('create')){
      this.editMode = false;
    }else{
      this.router.navigate(["errorpage"]);
    }

    this.route.queryParams
      .subscribe(params => {
        this.from = params['from'];
      })
    this.getdropdown();

    this.route.queryParams
    .subscribe(params => {
      this.projectid = params['projectId']


      // call user access page here
      this.projectservice.GetUserAccessListByProjectId(this.projectid, this.objectId, this.provId).subscribe(res => {
        let useraccess = res;
        if(!useraccess?.data?.canEditProject){
          this.router.navigate(["errorpage"]);
        }
      })

    // const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")))
    // this.data = { project: JSON.parse(projectDetail) };

    if (this.editMode) {
      this.GetProjectbyId();
    }
    });


  }
  getdropdown() {
    this.projectservice.getDropdownData().subscribe(res => {
      this.Dropdownprojectsdata = res; //master data
      this.OpcoList = res[3];
      this.SbuList = res[4];
      this.BuList = res[0];
      this.AssetList = res[2];
      this.SpecialProjectList = res[1];
      this.fillSbuDDL(this.formValue.value.OpcoId);
    });

  }

  fillSbuDDL(OpcoId: any) {
    this.projectservice.getDropdownData().subscribe(res => {
      this.Dropdownprojectsdata = res;
    });
    // //this.isDisabled = OpcoId == 11 ? false: true ;
    if (OpcoId == 11) {
      this.isDisabled = false;
    }
    else {
      this.isDisabled = true;
      this.formValue.controls["IsSsbodavailable"].setValue(false);
    }
    this.SbuList = this.Dropdownprojectsdata[4].filter((res: any) => { return res.OpcoId == OpcoId });
    if (this.editMode) {
      this.formValue.controls['SbuId'].setValue(this.data.project.SbuId);
      this.fillBuDDL(this.data.project.SbuId);
    }
    else {
      this.formValue.controls['SbuId'].setValue(this.SbuList[0].SbuId);
      this.fillBuDDL(this.SbuList[0].SbuId);
    }
  }
  fillBuDDL(SbuId: any) {

    this.BuList = this.Dropdownprojectsdata[0].filter((res: any) => res.SbuId == SbuId);
    if (this.editMode) {
      this.formValue.controls['BuId'].setValue(this.data.project.BuId);
    }
    else {
      this.formValue.controls['BuId'].setValue(this.BuList[0].BuId);
    }
  }
  GetEditData() {
    {
      // if (localStorage.getItem("goProject")) {
      //   const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")))
      //   this.data = { project: JSON.parse(projectDetail) };
      // }
      this.formValue.controls["ProjectName"].setValue(this.data.project.Name);
      this.formValue.controls["SbuId"].setValue(this.data.project.SbuId);
      this.formValue.controls["OpcoId"].setValue(this.data.project.OpcoId);
      this.formValue.controls["BuId"].setValue(this.data.project.BuId);
      this.formValue.controls["CreatedBy"].setValue(this.data.project.CreatedBy);
      this.formValue.controls["AssetClassId"].setValue(this.data.project.AssetClassId);
      this.formValue.controls["SpecialProjectType"].setValue(this.data.project.SpecialProjectType);
      this.formValue.controls["AssetMegaFriendlyName"].setValue(this.data.project.AssetMegaFriendlyName);

      this.formValue.controls["Rsid"].setValue(this.data.project.Rsid);
      // // this.projectModelObj.SbuId=this.data.project.SbuId;
      // // this.projectModelObj.Rsid=this.data.project.Rsid;
      this.projectModelObj.ProjectId = this.data.project.ProjectId;
      this.formValue.controls["ProjectId"].setValue(this.data.project.ProjectId);
    }
  }
  onSubmit() {
    if (this.editMode) {
      this.updateProjectDetails();
    }
    else {
      if (this.formValue.valid) {
        this.projectModelObj.ProjectName = this.formValue.value.ProjectName;
        this.projectModelObj.SbuId = this.formValue.value.SbuId;
        this.projectModelObj.BuId = this.formValue.value.BuId;
        this.projectModelObj.OpcoId = this.formValue.value.OpcoId;
        this.projectModelObj.AssetClassId = this.formValue.value.AssetClassId == 0 ? null : this.formValue.value.AssetClassId;
        this.projectModelObj.IsSsbodavailable = this.formValue.value.IsSsbodavailable;
        this.projectModelObj.AssetMegaFriendlyName = this.formValue.value.AssetMegaFriendlyName;
        this.projectModelObj.Rsid = this.formValue.value.Rsid;
        this.projectModelObj.CreatedBy = this.provId;
        this.projectModelObj.ModifiedBy = this.provId;
        this.projectModelObj.SpecialProjectType = this.formValue.value.SpecialProjectType == 0 ? null : this.formValue.value.SpecialProjectType;
        this.projectModelObj.ProjectType = "2";
        this.projectservice.postProject(this.projectModelObj).subscribe(res => {
          if (res == "Duplicate RSID") {
            this.errormessage = "Duplicate RSID";
          }
          else {
            this.projectid = res;
            this.projectModelObj.ProjectId = this.projectid;
            this.SetAddData(this.projectModelObj);
            this.notifyService.showSuccess("Project created successfully!", "");
            this.router.navigate(['/project/eaproject/usersecurityaccess']
            );
          }
        });
      }
    }

  }
  updateProjectDetails() {
    if (this.formValue.valid) {
      this.projectModelObj.ProjectName = this.formValue.value.ProjectName;
      this.projectModelObj.SbuId = this.formValue.value.SbuId;
      this.projectModelObj.BuId = this.formValue.value.BuId;
      this.projectModelObj.OpcoId = this.formValue.value.OpcoId;
      this.projectModelObj.AssetClassId = this.formValue.value.AssetClassId == 0 ? null : this.formValue.value.AssetClassId;
      this.projectModelObj.IsSsbodavailable = this.formValue.value.IsSsbodavailable;
      this.projectModelObj.AssetMegaFriendlyName = this.formValue.value.AssetMegaFriendlyName;
      this.projectModelObj.Rsid = this.formValue.value.Rsid;
      if (localStorage.getItem("goProject")) {
        const projectDetail = JSON.parse(JSON.stringify(localStorage.getItem("goProject")))
        const projectData = { goproject: JSON.parse(projectDetail) };
        this.projectModelObj.CreatedBy = projectData.goproject.CreatedBy;
      }
      this.projectModelObj.ProjectType = "2";
      this.projectModelObj.ModifiedBy = this.provId;
      this.projectModelObj.SpecialProjectType = this.formValue.value.SpecialProjectType == 0 ? null : this.formValue.value.SpecialProjectType;
      this.projectservice.updateProject(this.projectModelObj).subscribe(
        res => {
          if (res == "Duplicate RSID") {
            this.errormessage = "Duplicate RSID";
          }
          else {
            this.SetEditData(this.projectModelObj);
            this.notifyService.showSuccess("Project updated successfully!", "");
            this.router.navigate(['/project/eaproject/usersecurityaccess']);
          }
        });
    }
  }

  OnOpcoChange(OpcoId: any) {
    this.projectservice.getDropdownData().subscribe(res => {
      this.Dropdownprojectsdata = res;
    });
    // //this.isDisabled = OpcoId == 11 ? false: true ;
    if (OpcoId == 11) {
      this.isDisabled = false;
    }
    else {
      this.isDisabled = true;
      this.formValue.controls["IsSsbodavailable"].setValue(false);
    }
    this.SbuList = this.Dropdownprojectsdata[4].filter((res: any) => { return res.OpcoId == OpcoId });
    if (this.SbuList.length >= 1) {
      this.formValue.controls["SbuId"].setValue(this.SbuList[0].SbuId);
      this.OnSBuChange(this.SbuList[0].SbuId);
    }
    else {
      this.formValue.controls["SbuId"].setValue("");
      this.BuList = [];
      this.OnSBuChange("");
    }
  }
  OnSBuChange(SbuId: any) {
    if (SbuId != "" || SbuId >= 1) {
      this.BuList = this.Dropdownprojectsdata[0].filter((res: any) => res.SbuId == SbuId);
      if (this.BuList.length >= 1) {
        this.formValue.controls["BuId"].setValue(this.BuList[0].BuId);
      }
    }
    else {
      this.formValue.controls["BuId"].setValue("");
    }
  }

  SetAddData(project: any) {
    localStorage.setItem('goProject', JSON.stringify(project));
    localStorage.setItem('editMode', 'false');
  }
  SetEditData(project: any) {
    localStorage.setItem('goProject', JSON.stringify(project));
    localStorage.setItem('editMode', 'true')
  }

  GetProjectbyId() {
    this.route.queryParams
      .subscribe(params => {
        this.projectid = params['projectId']
      });

      this.projectservice.GetEditProjectData(this.projectid).pipe(
        catchError((error:any) => {
          this.router.navigate(["errorpage"]);
          return throwError(() => new Error('invalid project id'));
        })
      ).subscribe(
        res => {
        this.data = {project : res}; 
        this.GetEditData();
      })
  }
}
