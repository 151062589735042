export class SeriesData
{
      year:number=0; 
      value:number=0; 
}
export class ChartSeries
    {     
          name:string=''; 
         data: SeriesData[]=[]; 
    }

