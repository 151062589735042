import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';
import { ProductionForecastViewModel } from '../../Model/productionForecastView.model';
import { SubsurfaceViewModel } from '../../Model/subsurfaceView.model';
import { SsbodService } from '../../service/ssbod.service';

@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.css']
})
export class ForecastComponent implements OnInit {
  @Input() viewModel: SubsurfaceViewModel = new SubsurfaceViewModel();
  productionForecastViewModel: ProductionForecastViewModel = new ProductionForecastViewModel();
  formId: number = 0;
  emailId: string = '';
  provId: string = '';
  version: number = 0;
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;

  constructor(private ssbodService: SsbodService, private route: ActivatedRoute, private go36ProjectService: Go36projectService, private notifyService: NotificationService, public utilityService: UtilityService) { }
  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formId = this.viewModel.Id;
    this.GetProductionForecastData();
  }
  GetProductionForecastData() {
    this.ssbodService.GetProductionForecastData(this.formId).subscribe(res => {
      this.productionForecastViewModel.ProjectName = res.ProjectName;
      this.productionForecastViewModel.Date = res.Date;
      this.productionForecastViewModel.Phase = res.Phase;
      this.productionForecastViewModel.ProjectDevelopmentStages = res.ProjectDevelopmentStages;
      this.productionForecastViewModel.ProductionForecastData = [];
      res.ProductionForecastData.forEach(element => {
        this.productionForecastViewModel.ProductionForecastData.push(this.ssbodService.checkForecastPrecision(element));
      });
    });
  }
  SaveProductionForecastData(item: any) {
    const date = new Date(this.productionForecastViewModel.Date!);

    if (this.productionForecastViewModel.Date != null) {
      this.productionForecastViewModel.Date = this.notifyService.ConvertDateBeforeSave(date);
    }
    let viewModel: ProductionForecastViewModel = {
      ProjectName: this.productionForecastViewModel.ProjectName,
      Date: this.productionForecastViewModel.Date,
      Phase: this.productionForecastViewModel.Phase,
      ProjectDevelopmentStages: [],
      ProductionForecastData: item === null ? [] : [item]
    }
    if (item !== null) {
      viewModel.ProductionForecastData[0] = this.ssbodService.checkForecastPrecision(viewModel.ProductionForecastData[0]);
      viewModel.ProductionForecastData[0].ModifiedBy = this.provId;
      viewModel.ProductionForecastData[0].Comment = viewModel.ProductionForecastData[0].Comment === null ? "" : viewModel.ProductionForecastData[0].Comment;
    }
    this.ssbodService.SaveProductionForecastData(this.formId, viewModel, this.provId).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
      this.productionForecastViewModel.Date = res.Date;
      this.productionForecastViewModel.Phase = res.Phase;
      this.productionForecastViewModel.ProductionForecastData = [];
      res.ProductionForecastData.forEach(element => {
        this.productionForecastViewModel.ProductionForecastData.push(this.ssbodService.checkForecastPrecision(element));
      });
    });

    if (this.productionForecastViewModel.Date != null) {
      this.productionForecastViewModel.Date = this.notifyService.ConvertDateAfterSave(date);
    }
  }
}