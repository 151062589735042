import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ChartsService } from '../../FEEDServices/charts.service';
import { ChartModel, UpstreamChartModel } from "../../../Model/Charts/UpStreamCharts.model";
import { ChartSeries, SeriesData } from "../../../Model/Charts/ChartSeries.model";
import { Chart } from 'src/app/Model/Charts/Chart.model';
import { ActivatedRoute } from '@angular/router';
import { Form } from 'src/app/Model/Form.model';
import { FormConfigurationModel } from 'src/app/Model/Charts/FormConfiguration.model';
import { FormChart } from 'src/app/Model/Charts/FormChart.model';
import { CommentDialogComponent, CommentDialogModel } from 'src/app/Modals/comment-dialog/comment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/Shared/notification.service';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { FormComment } from 'src/app/Model/FormComment.model';
import { MainChartModels } from 'src/app/Model/Charts/MainChartModels.model';
import { NonUpstreamChartModel } from 'src/app/Model/Charts/NonUpStreamCharts.model';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { UIChart } from 'primeng/chart';
import { AppConstant } from 'src/app/AppConstant';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.css']
})
export class ChartsComponent implements OnInit {
  capitalAndExpenditureChartData: any;
  ProductionChartData: any;
  ATcashflowChartData: any;
  earningsPerBarrelChartData: any;
  cumulativeATCashflowChartData: any;
  nriReservesBookingChartData: any;
  earningsChartData: any;
  operatingCashMarginChartData: any;
  VolumeChartData: any;
  chartsOptions: any;
  NonUpstreamCapitalAndExpenditureChartData: any;
  NonUpstreamAfterTaxCashFlowChartData: any;
  NonUpstreamCumulativeCashChartData: any;
  NonUpstreamEarningsBeforeInterestChartData: any;
  NonUpstreamOperatingExpenseChartData: any;
  NonUpstreamReturnOnCapitalEmployedChartData: any;
  NonUpstreamOperationalEarningsAfterTaxChartData: any;
  formconfiguration = new FormConfigurationModel();
  formchartinfo = new FormChart()
  formComments: FormComment[] = [];
  CeComment = new FormComment();
  ProductionComment = new FormComment();
  AfterCashflowComment = new FormComment();
  EarningPerBarComment = new FormComment();
  CumulativeComment = new FormComment();
  nriReservesComment = new FormComment();
  cumulativeComment = new FormComment();
  EarningsComment = new FormComment();
  operationComment = new FormComment();
  noncapxComment = new FormComment();
  nonATFComment = new FormComment();
  nonvolumeComment = new FormComment();
  noncumuComment = new FormComment();
  ebitdaComment = new FormComment();
  opexComment = new FormComment();
  rocecomment = new FormComment();
  oeatcomment = new FormComment();
  FormComment = new FormComment();
  formId: number = 0;
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  ErrorMsg: string = '';
  highFeedForward = new ChartSeries();
  lowFeedForward = new ChartSeries();
  chartHighFeedForward = new Chart();
  chartLowFeedForward = new Chart();
  chartMidFeedForward = new Chart();
  chartMidFullCycle = new Chart();
  chartModel = new Chart();
  chartvalues: number[] = [];
  VarupstreamchartsModel = new UpstreamChartModel();
  VarnonupstreamchartsModel = new NonUpstreamChartModel();
  MainChartsModel = new MainChartModels();
  ChartModelData = new ChartModel();
  ChartData = new SeriesData();
  @Input() form: Form = new Form();
  formCase: string | null = '';
  IsUpstream: boolean = false;
  @Input() isOnHold: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;
  @ViewChild('chartCE') chartCE!: UIChart;
  @ViewChild('chartProd') chartProd!: UIChart;
  @ViewChild('chartAT') chartAT!: UIChart;
  @ViewChild('chartCAT') chartCAT!: UIChart;
  @ViewChild('chartEarn') chartEarn!: UIChart;
  @ViewChild('chartBarrel') chartBarrel!: UIChart;
  @ViewChild('chartOperate') chartOperate!: UIChart;
  @ViewChild('chartNri') chartNri!: UIChart;
  @ViewChild('chartNCE') chartNCE!: UIChart;
  @ViewChild('chartNVolume') chartNVolume!: UIChart;
  @ViewChild('chartNAT') chartNAT!: UIChart;
  @ViewChild('chartNCAT') chartNCAT!: UIChart;
  @ViewChild('chartNEarn') chartNEarn!: UIChart;
  @ViewChild('chartNOperate') chartNOperate!: UIChart;
  @ViewChild('chartRoce') chartRoce!: UIChart;
  @ViewChild('chartOeat') chartOeat!: UIChart;
  ChartStartYear:number=0;
  ChartEndYear:number=0;
  YearErrorMsg:any;
  constructor(private chartService: ChartsService, private route: ActivatedRoute, public dialog: MatDialog,
    private notifyService: NotificationService, private go36ProjectService: Go36projectService,
    private utilityService: UtilityService) {
  }
  chartOptions: any;
  ngOnInit() {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.LoadChartsData();
  }
  ShowChartModal() {
    const message = "Charts are plotted showing the project at all 3 price cases based on the data imported to the tool. Provide a brief description in the comment box to assist interpretation of the plot; anticipate questions asked and provide answers. If a chart is blank, please describe why this is so. All charts are Chevron net." +
      "<br><br> User can select start and end dates to modify timeframe shown on plot by entering dates in the “Project Data Shown” fields:" +
      "<img src='/assets/Content/Images/help/chart_01.png' alt= 'img'>" +
      "<br><br>" +
      "Additional chart settings are available through the ‘settings’ button on the main project page. Clicking on the ‘settings’ button will bring you to the ‘Chart Display Settings” menu where charts can be retitled and hidden from the form if desired.      " +
      "<br><br>" +
      "<img src='/assets/Content/Images/help/chart_02.png' alt= 'img'>" +
      "<br>" +
      "<img src='/assets/Content/Images/help/chart_03.png' alt= 'img'>"

    const dialogData = new InfoDialogModel("Chart", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }

  //Save charts comments 
  SaveFormComments(sectionId: number, columnId: number, commentText: string) {
    this.FormComment.active = true;
    this.FormComment.createdBy = this.provId;
    this.FormComment.order = 1;
    this.FormComment.formId = this.form.id;
    this.FormComment.sectionId = sectionId;
    this.FormComment.formFieldId = 0;
    this.FormComment.comments = commentText;
    this.FormComment.columnId = columnId;
    const dialogData = new CommentDialogModel("Update Comment", commentText, this.form.isOnHold, this.form.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.FormComment.modifiedBy = this.provId;
        this.FormComment.comments = comment;
        this.chartService.UpdateChartsComment(this.FormComment).subscribe(res => {
          this.LoadChartsData();
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }
  //save charts description 
  UpdateChartdata(formchartinfo: FormChart) {
    formchartinfo.ModifiedBy = this.provId;
    this.chartService.UpdateChartsData(formchartinfo).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }
  SaveChartImagesForPDF(formchartinfo: FormChart) {
    formchartinfo.ModifiedBy = this.provId;
    formchartinfo.FormId = this.form.id;
    formchartinfo.Active = true;
    formchartinfo.CreatedBy = this.provId;
    this.chartService.SaveChartImagesForPDF(formchartinfo).subscribe(res => {
    });
  }
  ChangeYears(formconfiguration: FormConfigurationModel) {
if(formconfiguration.chartStartYear!=null && formconfiguration.chartEndYear!=null)
{
  if(this.ChartStartYear > formconfiguration.chartStartYear || this.ChartEndYear < formconfiguration.chartEndYear)
{
  this.YearErrorMsg="true";
}
else
{
  this.YearErrorMsg="";
  this.chartService.ChangeYears(formconfiguration).subscribe(res => {
    this.LoadChartsData();
    this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
  });
}
}
  }
  LoadFormComment() {
    this.chartService.GetFormCommentsData(this.form.id).subscribe((res: any) => {
      if (res) {
        this.formComments = res;
        this.CeComment = res.find((x: { columnId: number; }) => x.columnId == 1);
        if (!this.CeComment) {
          this.CeComment = new FormComment();
        }
        this.ProductionComment = res.find((x: { columnId: number; }) => x.columnId == 2);
        if (!this.ProductionComment) {
          this.ProductionComment = new FormComment();
        }
        this.AfterCashflowComment = res.find((x: { columnId: number; }) => x.columnId == 3);
        if (!this.AfterCashflowComment) {
          this.AfterCashflowComment = new FormComment();
        }
        this.EarningPerBarComment = res.find((x: { columnId: number; }) => x.columnId == 4);
        if (!this.EarningPerBarComment) {
          this.EarningPerBarComment = new FormComment();
        }
        this.CumulativeComment = res.find((x: { columnId: number; }) => x.columnId == 5);
        if (!this.CumulativeComment) {
          this.CumulativeComment = new FormComment();
        }
        this.nriReservesComment = res.find((x: { columnId: number; }) => x.columnId == 6);
        if (!this.nriReservesComment) {
          this.nriReservesComment = new FormComment();
        }
        this.EarningsComment = res.find((x: { columnId: number; }) => x.columnId == 7);
        if (!this.EarningsComment) {
          this.EarningsComment = new FormComment();
        }
        this.operationComment = res.find((x: { columnId: number; }) => x.columnId == 8);
        if (!this.operationComment) {
          this.operationComment = new FormComment();
        }
        //nonup
        this.noncapxComment = res.find((x: { columnId: number; }) => x.columnId == 9);
        if (!this.noncapxComment) {
          this.noncapxComment = new FormComment();
        }
        this.nonvolumeComment = res.find((x: { columnId: number; }) => x.columnId == 10);
        if (!this.nonvolumeComment) {
          this.nonvolumeComment = new FormComment();
        }
        this.nonATFComment = res.find((x: { columnId: number; }) => x.columnId == 11);
        if (!this.nonATFComment) {
          this.nonATFComment = new FormComment();
        }
        this.noncumuComment = res.find((x: { columnId: number; }) => x.columnId == 12);
        if (!this.noncumuComment) {
          this.noncumuComment = new FormComment();
        }
        this.ebitdaComment = res.find((x: { columnId: number; }) => x.columnId == 13);
        if (!this.ebitdaComment) {
          this.ebitdaComment = new FormComment();
        }
        this.opexComment = res.find((x: { columnId: number; }) => x.columnId == 14);
        if (!this.opexComment) {
          this.opexComment = new FormComment();
        }
        this.rocecomment = res.find((x: { columnId: number; }) => x.columnId == 15);
        if (!this.rocecomment) {
          this.rocecomment = new FormComment();
        }
        this.oeatcomment = res.find((x: { columnId: number; }) => x.columnId == 16);
        if (!this.oeatcomment) {
          this.oeatcomment = new FormComment();
        }
        // //this.LoadFormComment();
      }
    });
  }
  LoadChartsData() {
    // Setting the options for charts Display
    this.chartsOptions = {
      scales: {
        x: {
          ticks: {
            maxTicksLimit: 10,
            maxRotation: 90,
            minRotation: 90,
            fontColor: 'rgba(0, 0, 0, 1)'
          },
          grid: {
            display: false
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      },
      elements: {
        point: {
          radius: 0
        }
      },
      plugins: {
        legend: {

          display: false
        },
        title: {
          display: true,
          text: 'Temporary name chart',
          font: {
            weight: '800',
            size: 12,
          },
          color: 'rgba(0, 0, 0, 1)'
        },
      },
    }
    this.chartService.GetChartsData(this.form.id, this.form.IsOldVersionOfForms).subscribe((res: any) => {
      if (res.upStreamCharts == null && res.nonUpStreamChart == null) {
        this.ErrorMsg = "true";
        // //console.error("NOT IMPORTED 20 LINE INPUT FILE");
      }
      else {
        {
          this.MainChartsModel = res;
          this.MainChartsModel.isUpstream = res.isUpstream;
          if (this.MainChartsModel.isUpstream && res.nonUpStreamChart == null) {
            this.VarupstreamchartsModel = res.upStreamCharts;
            this.formconfiguration = res.upStreamCharts.formConfiguration;
            this.VarnonupstreamchartsModel = res.nonUpStreamChart;
            this.formchartinfo = res.upStreamCharts.chartComments;
            this.TrimChartDescription();
            this.ChartStartYear=res.upStreamCharts.formConfiguration.startYear;
            this.ChartEndYear=res.upStreamCharts.formConfiguration.endYear;
            let highyears: any = [];
            let highvalues: any = [];
            let lowvalues: any = [];
            let midvalues: any = [];
            let midfull: any[];
            this.VarupstreamchartsModel.capitalAndExpenditureChart.highFeedForward.data.forEach(function (item) {
              highyears.push(item.year);
              highvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = highyears;
            this.chartHighFeedForward.chartvalues = highvalues;
            this.VarupstreamchartsModel.capitalAndExpenditureChart.lowFeedForward.data.forEach(function (item) {
              lowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = lowvalues;
            if (this.form.IsOldVersionOfForms) {
              this.VarupstreamchartsModel.capitalAndExpenditureChart.midFullCycle.data.forEach(function (item) {
                midvalues.push(item.value / 1000);
              });

              this.chartMidFeedForward.chartvalues = midvalues;
            }
            else {
              this.VarupstreamchartsModel.capitalAndExpenditureChart.midFeedForward.data.forEach(function (item) {
                midvalues.push(item.value / 1000);
              });

              this.chartMidFeedForward.chartvalues = midvalues;
            }

            this.capitalAndExpenditureChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarupstreamchartsModel.capitalAndExpenditureChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 2,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : (e.valueOf() / 1000).toFixed(0); }),
                }, {
                  type: 'line',
                  label: this.VarupstreamchartsModel.capitalAndExpenditureChart.lowFeedForward.name,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : (e.valueOf() / 1000).toFixed(0); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarupstreamchartsModel.capitalAndExpenditureChart.midFullCycle.name : this.VarupstreamchartsModel.capitalAndExpenditureChart.midFeedForward.name,
                  backgroundColor: "#B22222",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues,
                  borderColor: '#B22222',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },
              plugins: {
                legend: {

                  display: false
                }
              }
            };
            let productionhighyears: any = [];
            let productionmidyears: any = [];
            let productionhighvalues: any = [];
            let productionlowvalues: any = [];
            let productionmidvalues: any = [];
            this.VarupstreamchartsModel.productionChart.highFeedForward.data.forEach(function (item) {
              productionhighyears.push(item.year);
              productionhighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = highyears;
            this.chartHighFeedForward.chartvalues = productionhighvalues;
            this.VarupstreamchartsModel.productionChart.lowFeedForward.data.forEach(function (item) {
              productionmidyears.push(item.year);
              productionlowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = productionlowvalues;
            this.chartLowFeedForward.yearsdata = productionmidyears;
            if (this.form.IsOldVersionOfForms) {
              this.VarupstreamchartsModel.productionChart.midFullCycle.data.forEach(function (item) {
                productionmidvalues.push(item.value / 1000);
              });
            }
            else {
              this.VarupstreamchartsModel.productionChart.midFeedForward.data.forEach(function (item) {
                productionmidvalues.push(item.value / 1000);
              });
            }
            this.chartMidFeedForward.chartvalues = productionmidvalues;
            this.ProductionChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarupstreamchartsModel.productionChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return (e.valueOf() / 1000).toFixed(1); }),

                }, {
                  type: 'line',
                  label: this.VarupstreamchartsModel.productionChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return (e.valueOf() / 1000).toFixed(1); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarupstreamchartsModel.productionChart.midFullCycle.name : this.VarupstreamchartsModel.productionChart.midFeedForward.name,
                  backgroundColor: "#FF4500",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues,
                  borderColor: '#FF4500',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {
              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {
                  display: false
                }
              }
            };
            let atcashflowhighyears: any = [];
            let atcashflowhighvalues: any = [];
            let atcashflowlowvalues: any = [];
            let atcashflowmidvalues: any = [];
            this.VarupstreamchartsModel.afterCashFlowChart.highFeedForward.data.forEach(function (item) {
              atcashflowhighyears.push(item.year);
              atcashflowhighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = highyears;
            this.chartHighFeedForward.chartvalues = atcashflowhighvalues;
            this.VarupstreamchartsModel.afterCashFlowChart.lowFeedForward.data.forEach(function (item) {
              atcashflowlowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = atcashflowlowvalues;
            if (this.form.IsOldVersionOfForms) {
              this.VarupstreamchartsModel.afterCashFlowChart.midFullCycle.data.forEach(function (item) {
                atcashflowmidvalues.push(item.value / 1000);
              });
              this.chartMidFeedForward.chartvalues = atcashflowmidvalues;
            }
            else {
              this.VarupstreamchartsModel.afterCashFlowChart.midFeedForward.data.forEach(function (item) {
                atcashflowmidvalues.push(item.value / 1000);
              });

              this.chartMidFeedForward.chartvalues = atcashflowmidvalues;
            }
            this.ATcashflowChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarupstreamchartsModel.afterCashFlowChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return (e.valueOf() / 1000).toFixed(1); }),
                }, {
                  type: 'line',
                  label: this.VarupstreamchartsModel.afterCashFlowChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return (e.valueOf() / 1000).toFixed(1); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarupstreamchartsModel.afterCashFlowChart.midFullCycle.name : this.VarupstreamchartsModel.afterCashFlowChart.midFeedForward.name,
                  backgroundColor: "#FFD700",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues,
                  borderColor: '#FFD700',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {

                  display: false
                }
              }
            };

            //Earning per brrel
            let Epbhighyears: any = [];
            let Epblowyears: any = [];
            let Epbhighvalues: any = [];
            let Epblowvalues: any = [];
            let Epbmidvalues: any = [];
            this.VarupstreamchartsModel.earningsPerBarrelChart.highFeedForward.data.forEach(function (item) {
              Epbhighyears.push(item.year);
              Epbhighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = highyears;
            this.chartHighFeedForward.chartvalues = Epbhighvalues;
            this.VarupstreamchartsModel.earningsPerBarrelChart.lowFeedForward.data.forEach(function (item) {
              Epblowyears.push(item.year);
              Epblowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = Epblowvalues;
            this.chartLowFeedForward.yearsdata = Epblowyears;
            if (this.form.IsOldVersionOfForms) {
              this.VarupstreamchartsModel.earningsPerBarrelChart.midFullCycle.data.forEach(function (item) {
                Epbmidvalues.push(item.value);

              });
              this.chartMidFeedForward.chartvalues = Epbmidvalues;
            }
            else {
              this.VarupstreamchartsModel.earningsPerBarrelChart.midFeedForward.data.forEach(function (item) {
                Epbmidvalues.push(item.value);

              });
              this.chartMidFeedForward.chartvalues = Epbmidvalues;
            }

            this.earningsPerBarrelChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarupstreamchartsModel.earningsPerBarrelChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : e.valueOf().toFixed(2); }),

                }, {
                  type: 'line',
                  label: this.VarupstreamchartsModel.earningsPerBarrelChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : e.valueOf().toFixed(2); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarupstreamchartsModel.earningsPerBarrelChart.midFullCycle.name : this.VarupstreamchartsModel.earningsPerBarrelChart.midFeedForward.name,
                  backgroundColor: "#1E90FF",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : e.valueOf().toFixed(2); }),
                  borderColor: '#1E90FF',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {

                  display: false
                }
              }
            };

            let CumuAThighyears: any = [];
            let CumuATlowyears: any = [];
            let CumuAThighvalues: any = [];
            let CumuATlowvalues: any = [];
            let CumuATmidvalues: any = [];
            this.VarupstreamchartsModel.cumulativeATCashflowChart.highFeedForward.data.forEach(function (item) {
              CumuAThighyears.push(item.year);
              CumuAThighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = highyears;
            this.chartHighFeedForward.chartvalues = CumuAThighvalues;
            this.VarupstreamchartsModel.cumulativeATCashflowChart.lowFeedForward.data.forEach(function (item) {
              CumuATlowyears.push(item.year);
              CumuATlowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = CumuATlowvalues;
            this.chartLowFeedForward.yearsdata = CumuATlowyears;
            if (this.form.IsOldVersionOfForms) {
              this.VarupstreamchartsModel.cumulativeATCashflowChart.midFullCycle.data.forEach(function (item) {
                CumuATmidvalues.push(item.value);
              });
              this.chartMidFeedForward.chartvalues = CumuATmidvalues;
            }
            else {
              this.VarupstreamchartsModel.cumulativeATCashflowChart.midFeedForward.data.forEach(function (item) {
                CumuATmidvalues.push(item.value);
              });
              this.chartMidFeedForward.chartvalues = CumuATmidvalues;
            }
            this.cumulativeATCashflowChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarupstreamchartsModel.cumulativeATCashflowChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : (e.valueOf() / 1000).toFixed(0); }),

                }, {
                  type: 'line',
                  label: this.VarupstreamchartsModel.cumulativeATCashflowChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : (e.valueOf() / 1000).toFixed(0); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarupstreamchartsModel.cumulativeATCashflowChart.midFullCycle.name : this.VarupstreamchartsModel.cumulativeATCashflowChart.midFeedForward.name,
                  backgroundColor: "#CD853F",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : (e.valueOf() / 1000).toFixed(0); }),
                  borderColor: '#CD853F',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {

                  display: false
                }
              }
            };
            //NRI CHARTS 
            let Nrimidvalues: any = [];
            let Nrihighyears: any = [];
            let Nrilowyears: any = [];
            let Nriighvalues: any = [];
            let Nrilowvalues: any = [];

            this.VarupstreamchartsModel.nriReservesBookingChart.highFeedForward.data.forEach(function (item) {
              Nrihighyears.push(item.year);
              Nriighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = Nrihighyears;
            this.chartHighFeedForward.chartvalues = Nriighvalues;
            this.VarupstreamchartsModel.nriReservesBookingChart.lowFeedForward.data.forEach(function (item) {
              Nrilowyears.push(item.year);
              Nrilowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = Nrilowvalues;
            this.chartLowFeedForward.yearsdata = Nrilowyears;
            if (this.form.IsOldVersionOfForms) {
              this.VarupstreamchartsModel.nriReservesBookingChart.midFullCycle.data.forEach(function (item) {
                Nrimidvalues.push(item.value);
              });
            }
            else {
              this.VarupstreamchartsModel.nriReservesBookingChart.midFeedForward.data.forEach(function (item) {
                Nrimidvalues.push(item.value);
              });
            }
            this.chartMidFeedForward.chartvalues = Nrimidvalues;
            this.nriReservesBookingChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarupstreamchartsModel.nriReservesBookingChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return (e.valueOf() / 1000) === 0 ? null : (e.valueOf()).toFixed(0); }),

                }, {
                  type: 'line',
                  label: this.VarupstreamchartsModel.nriReservesBookingChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return (e.valueOf() / 1000) === 0 ? null : (e.valueOf()).toFixed(0); }),
                },
                {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarupstreamchartsModel.nriReservesBookingChart.midFullCycle.name : this.VarupstreamchartsModel.nriReservesBookingChart.midFeedForward.name,
                  backgroundColor: "#663399",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues.map(function (e) { return (e.valueOf() / 1000) === 0 ? null : (e.valueOf()).toFixed(0); }),
                  borderColor: '#663399',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {

                  display: false
                }
              }
            };
            //Earning chart
            let earnhighyears: any = [];
            let earnlowyears: any = [];
            let earnhighvalues: any = [];
            let earnlowvalues: any = [];
            let earnmidvalues: any = [];
            this.VarupstreamchartsModel.earningsChart.highFeedForward.data.forEach(function (item) {
              earnhighyears.push(item.year);
              earnhighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = earnhighyears;
            this.chartHighFeedForward.chartvalues = earnhighvalues;
            this.VarupstreamchartsModel.earningsChart.lowFeedForward.data.forEach(function (item) {
              earnlowyears.push(item.year);
              earnlowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = earnlowvalues;
            this.chartLowFeedForward.yearsdata = earnlowyears;
            if (this.form.IsOldVersionOfForms) {
              this.VarupstreamchartsModel.earningsChart.midFullCycle.data.forEach(function (item) {
                earnmidvalues.push(item.value);
              });
            }
            else {
              this.VarupstreamchartsModel.earningsChart.midFeedForward.data.forEach(function (item) {
                earnmidvalues.push(item.value);
              });
            }

            this.chartMidFeedForward.chartvalues = earnmidvalues;
            this.earningsChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarupstreamchartsModel.earningsChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : (e.valueOf() / 1000).toFixed(0); }),

                }, {
                  type: 'line',
                  label: this.VarupstreamchartsModel.earningsChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : (e.valueOf() / 1000).toFixed(0); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarupstreamchartsModel.earningsChart.midFullCycle.name : this.VarupstreamchartsModel.earningsChart.midFeedForward.name,
                  backgroundColor: "#4169E1",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : (e.valueOf() / 1000).toFixed(0); }),
                  borderColor: '#4169E1',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {

                  display: false
                }
              }
            };

            //Operating chart
            let operatinghighyears: any = [];
            let operatinglowyears: any = [];
            let operatinghighvalues: any = [];
            let operatinglowvalues: any = [];
            let operatingmidvalues: any = [];
            this.VarupstreamchartsModel.operatingCashMarginChart.highFeedForward.data.forEach(function (item) {
              operatinghighyears.push(item.year);
              operatinghighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = operatinghighyears;
            this.chartHighFeedForward.chartvalues = operatinghighvalues;
            this.VarupstreamchartsModel.operatingCashMarginChart.lowFeedForward.data.forEach(function (item) {
              operatinglowyears.push(item.year);
              operatinglowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = operatinglowvalues;
            this.chartLowFeedForward.yearsdata = operatinglowyears;
            if(this.form.IsOldVersionOfForms)
            {
              this.VarupstreamchartsModel.operatingCashMarginChart.midFullCycle.data.forEach(function (item) {
                operatingmidvalues.push(item.value);
              });  
            }
            else{
              this.VarupstreamchartsModel.operatingCashMarginChart.midFeedForward.data.forEach(function (item) {
                operatingmidvalues.push(item.value);
              });  
            }

            this.chartMidFeedForward.chartvalues = operatingmidvalues;
            this.operatingCashMarginChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarupstreamchartsModel.operatingCashMarginChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : (e.valueOf()).toFixed(2); }),

                }, {
                  type: 'line',
                  label: this.VarupstreamchartsModel.operatingCashMarginChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : (e.valueOf()).toFixed(2); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarupstreamchartsModel.operatingCashMarginChart.midFullCycle.name : this.VarupstreamchartsModel.operatingCashMarginChart.midFeedForward.name,
                  backgroundColor: "#FFA500",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : (e.valueOf()).toFixed(2); }),
                  borderColor: '#FFA500',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {

                  display: false
                }
              }
            };
            setTimeout(() => {
              if (this.chartCE != undefined)
                this.formchartinfo.CapitalAndExpenditureChart = this.chartCE.getBase64Image();
              if (this.chartProd != undefined)
                this.formchartinfo.ProductionChart = this.chartProd.getBase64Image();
              if (this.chartAT != undefined)
                this.formchartinfo.AfterTaxCashflowChart = this.chartAT.getBase64Image();
              if (this.chartCAT != undefined)
                this.formchartinfo.CumulativeAtcashflowChart = this.chartCAT.getBase64Image();
              if (this.chartEarn != undefined)
                this.formchartinfo.EarningsChart = this.chartEarn.getBase64Image();
              if (this.chartBarrel != undefined)
                this.formchartinfo.EarningsPerBarrelChart = this.chartBarrel.getBase64Image();
              if (this.chartOperate != undefined)
                this.formchartinfo.OperatingCashMarginChart = this.chartOperate.getBase64Image();
              if (this.chartNri != undefined)
                this.formchartinfo.NriReservesBookingChart = this.chartNri.getBase64Image();
              this.SaveChartImagesForPDF(this.formchartinfo);
            }, 1200);
          }
          //-------------------Charts for Non Upstream---------
          //volume chart
          else {
            this.VarnonupstreamchartsModel = res.nonUpStreamChart;
            this.TrimChartDescription();
            this.formconfiguration = res.nonUpStreamChart.formConfiguration;
            this.formchartinfo = res.nonUpStreamChart.chartComments;
            this.ChartStartYear=res.nonUpStreamChart.formConfiguration.startYear;
            this.ChartEndYear=res.nonUpStreamChart.formConfiguration.endYear;
            let highyears: any = [];
            let highvalues: any = [];
            let lowvalues: any = [];
            let midvalues: any = [];
            this.VarnonupstreamchartsModel.volumeChart.highFeedForward.data.forEach(function (item) {
              highyears.push(item.year);
              highvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = highyears;
            this.chartHighFeedForward.chartvalues = highvalues;
            this.VarnonupstreamchartsModel.volumeChart.lowFeedForward.data.forEach(function (item) {
              lowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = lowvalues;
            if(this.form.IsOldVersionOfForms){
              this.VarnonupstreamchartsModel.volumeChart.midFullCycle.data.forEach(function (item) {
                midvalues.push(item.value);
              });
            }
            else{
              this.VarnonupstreamchartsModel.volumeChart.midFeedForward.data.forEach(function (item) {
                midvalues.push(item.value);
              });
            }


            this.chartMidFeedForward.chartvalues = midvalues;
            this.VolumeChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.volumeChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 2,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : e.valueOf().toFixed(0); }),
                }, {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.volumeChart.lowFeedForward.name,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : e.valueOf().toFixed(0); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarnonupstreamchartsModel.volumeChart.midFullCycle.name : this.VarnonupstreamchartsModel.volumeChart.midFeedForward.name,
                  backgroundColor:this.form.IsOldVersionOfForms.valueOf() == true ?'#B22222': "#FF4500",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues.map(function (e) { return e.valueOf() === 0 ? null : e.valueOf().toFixed(0); }),
                  borderColor: this.form.IsOldVersionOfForms.valueOf() == true ?'#B22222':'#FF4500',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },
              plugins: {
                legend: {

                  display: false
                }
              }
            };
            //------NonUpstreamAfterTaxCashFlowChart----------
            let nonACFhighyears: any = [];
            let nonACFlowyears: any = [];
            let nonACFhighvalues: any = [];
            let nonACFlowvalues: any = [];
            let nonACFmidvalues: any = [];
            this.VarnonupstreamchartsModel.nonUpstreamAfterTaxCashFlowChart.highFeedForward.data.forEach(function (item) {
              nonACFhighyears.push(item.year);
              nonACFhighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = nonACFhighyears;
            this.chartHighFeedForward.chartvalues = nonACFhighvalues;
            this.VarnonupstreamchartsModel.nonUpstreamAfterTaxCashFlowChart.lowFeedForward.data.forEach(function (item) {
              nonACFlowyears.push(item.year);
              nonACFlowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = nonACFlowvalues;
            this.chartLowFeedForward.yearsdata = nonACFlowyears;
            if(this.form.IsOldVersionOfForms)
            {
              this.VarnonupstreamchartsModel.nonUpstreamAfterTaxCashFlowChart.midFullCycle.data.forEach(function (item) {
                nonACFmidvalues.push(item.value);
              });
            }
            else{
              this.VarnonupstreamchartsModel.nonUpstreamAfterTaxCashFlowChart.midFeedForward.data.forEach(function (item) {
                nonACFmidvalues.push(item.value);
              });
            }
            this.chartMidFeedForward.chartvalues = nonACFmidvalues;
            this.NonUpstreamAfterTaxCashFlowChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamAfterTaxCashFlowChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),

                }, {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamAfterTaxCashFlowChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarnonupstreamchartsModel.nonUpstreamAfterTaxCashFlowChart.midFullCycle.name : this.VarnonupstreamchartsModel.nonUpstreamAfterTaxCashFlowChart.midFeedForward.name,
                  backgroundColor: this.form.IsOldVersionOfForms.valueOf() == true ?'#B22222':"#FFD700",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues,
                  borderColor: this.form.IsOldVersionOfForms.valueOf() == true ?'#B22222':'#FFD700',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {

                  display: false
                }
              }
            };
            //------NonUpstreamCapitalAndExpenditureChartData---------
            let capxhighyears: any = [];
            let capxmidyears: any = [];
            let capxhighvalues: any = [];
            let capxlowvalues: any = [];
            let capxmidvalues: any = [];
            this.VarnonupstreamchartsModel.nonUpstreamCapitalAndExpenditureChart.highFeedForward.data.forEach(function (item) {
              capxhighyears.push(item.year);
              capxhighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = highyears;
            this.chartHighFeedForward.chartvalues = capxhighvalues;
            this.VarnonupstreamchartsModel.nonUpstreamCapitalAndExpenditureChart.lowFeedForward.data.forEach(function (item) {
              capxmidyears.push(item.year);
              capxlowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = capxlowvalues;
            this.chartLowFeedForward.yearsdata = capxmidyears;
            if(this.form.IsOldVersionOfForms)
            {
              this.VarnonupstreamchartsModel.nonUpstreamCapitalAndExpenditureChart.midFullCycle.data.forEach(function (item) {
                capxmidvalues.push(item.value);
              });
            }
            else{
              this.VarnonupstreamchartsModel.nonUpstreamCapitalAndExpenditureChart.midFeedForward.data.forEach(function (item) {
                capxmidvalues.push(item.value);
              });
            }
            this.chartMidFeedForward.chartvalues = capxmidvalues;
            this.NonUpstreamCapitalAndExpenditureChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamCapitalAndExpenditureChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),

                }, {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamCapitalAndExpenditureChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarnonupstreamchartsModel.nonUpstreamCapitalAndExpenditureChart.midFullCycle.name : this.VarnonupstreamchartsModel.nonUpstreamCapitalAndExpenditureChart.midFeedForward.name,
                  backgroundColor: "#B22222",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues,
                  borderColor: '#B22222',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {

                  display: false
                }
              }
            };
            //------------------nonUpstreamCumulativeCashChart-----------
            let noncumuhighyears: any = [];
            let noncumulowyears: any = [];
            let noncumuhighvalues: any = [];
            let noncumulowvalues: any = [];
            let noncumumidvalues: any = [];
            this.VarnonupstreamchartsModel.nonUpstreamCumulativeCashChart.highFeedForward.data.forEach(function (item) {
              noncumuhighyears.push(item.year);
              noncumuhighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = noncumuhighyears;
            this.chartHighFeedForward.chartvalues = noncumuhighvalues;
            this.VarnonupstreamchartsModel.nonUpstreamCumulativeCashChart.lowFeedForward.data.forEach(function (item) {
              noncumulowyears.push(item.year);
              noncumulowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = noncumulowvalues;
            this.chartLowFeedForward.yearsdata = noncumulowyears;
            if(this.form.IsOldVersionOfForms)
            {
              this.VarnonupstreamchartsModel.nonUpstreamCumulativeCashChart.midFullCycle.data.forEach(function (item) {
                noncumumidvalues.push(item.value);
              });
            }
            else{
              this.VarnonupstreamchartsModel.nonUpstreamCumulativeCashChart.midFeedForward.data.forEach(function (item) {
                noncumumidvalues.push(item.value);
              });
            }
            this.chartMidFeedForward.chartvalues = noncumumidvalues;
            this.NonUpstreamCumulativeCashChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamCumulativeCashChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),

                }, {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamCumulativeCashChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarnonupstreamchartsModel.nonUpstreamCumulativeCashChart.midFullCycle.name : this.VarnonupstreamchartsModel.nonUpstreamCumulativeCashChart.midFeedForward.name,
                  backgroundColor: this.form.IsOldVersionOfForms.valueOf() == true ?'#B22222':"#CD853F",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues,
                  borderColor: this.form.IsOldVersionOfForms.valueOf() == true ?'#B22222':'#CD853F',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {

                  display: false
                }
              }
            };
            //------nonUpstreamEarningsBeforeInterestChart------------------------
            let nonebitdahighyears: any = [];
            let nonebitdalowyears: any = [];
            let nonebitdahighvalues: any = [];
            let nonebitdalowvalues: any = [];
            let nonebitdamidvalues: any = [];
            this.VarnonupstreamchartsModel.nonUpstreamEarningsBeforeInterestChart.highFeedForward.data.forEach(function (item) {
              nonebitdahighyears.push(item.year);
              nonebitdahighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = nonebitdahighyears;
            this.chartHighFeedForward.chartvalues = nonebitdahighvalues;
            this.VarnonupstreamchartsModel.nonUpstreamEarningsBeforeInterestChart.lowFeedForward.data.forEach(function (item) {
              nonebitdalowyears.push(item.year);
              nonebitdalowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = nonebitdalowvalues;
            this.chartLowFeedForward.yearsdata = nonebitdalowyears;
            if(this.form.IsOldVersionOfForms)
            {
              this.VarnonupstreamchartsModel.nonUpstreamEarningsBeforeInterestChart.midFullCycle.data.forEach(function (item) {
                nonebitdamidvalues.push(item.value);
              });
            }
            else{
              this.VarnonupstreamchartsModel.nonUpstreamEarningsBeforeInterestChart.midFeedForward.data.forEach(function (item) {
                nonebitdamidvalues.push(item.value);
              });
            }


            this.chartMidFeedForward.chartvalues = nonebitdamidvalues;
            this.NonUpstreamEarningsBeforeInterestChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamEarningsBeforeInterestChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),

                }, {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamEarningsBeforeInterestChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarnonupstreamchartsModel.nonUpstreamEarningsBeforeInterestChart.midFullCycle.name : this.VarnonupstreamchartsModel.nonUpstreamEarningsBeforeInterestChart.midFeedForward.name,
                  backgroundColor:this.form.IsOldVersionOfForms.valueOf() == true ?'#B22222': "#FF4500",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues,
                  borderColor: this.form.IsOldVersionOfForms.valueOf() == true ?'#B22222':'#FF4500',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {

                  display: false
                }
              }
            };

            //------nonUpstreamOperatingExpenseChart-----
            let nonopexhighyears: any = [];
            let nonopexlowyears: any = [];
            let nonopexhighvalues: any = [];
            let nonopexlowvalues: any = [];
            let nonopexmidvalues: any = [];
            this.VarnonupstreamchartsModel.nonUpstreamOperatingExpenseChart.highFeedForward.data.forEach(function (item) {
              nonopexhighyears.push(item.year);
              nonopexhighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = nonopexhighyears;
            this.chartHighFeedForward.chartvalues = nonopexhighvalues;
            this.VarnonupstreamchartsModel.nonUpstreamOperatingExpenseChart.lowFeedForward.data.forEach(function (item) {
              nonopexlowyears.push(item.year);
              nonopexlowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = nonopexlowvalues;
            this.chartLowFeedForward.yearsdata = nonopexlowyears;
         if(this.form.IsOldVersionOfForms)
         {
          this.VarnonupstreamchartsModel.nonUpstreamOperatingExpenseChart.midFullCycle.data.forEach(function (item) {
            nonopexmidvalues.push(item.value);
          });
         }
         else{
          this.VarnonupstreamchartsModel.nonUpstreamOperatingExpenseChart.midFeedForward.data.forEach(function (item) {
            nonopexmidvalues.push(item.value);
          });

         }
            this.chartMidFeedForward.chartvalues = nonopexmidvalues;
            this.NonUpstreamOperatingExpenseChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamOperatingExpenseChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),

                }, {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamOperatingExpenseChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarnonupstreamchartsModel.nonUpstreamOperatingExpenseChart.midFullCycle.name : this.VarnonupstreamchartsModel.nonUpstreamOperatingExpenseChart.midFeedForward.name,
                  backgroundColor: this.form.IsOldVersionOfForms.valueOf() == true ?'#B22222':"#1E90FF",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues,
                  borderColor:this.form.IsOldVersionOfForms.valueOf() == true ?'#B22222':'#1E90FF',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {

                  display: false
                }
              }
            };
            ////--------ROCE Chart-----------

            let nonrocehighyears: any = [];
            let nonrocelowyears: any = [];
            let nonrocexhighvalues: any = [];
            let nonrocelowvalues: any = [];
            let nonrocemidvalues: any = [];
            this.VarnonupstreamchartsModel.nonUpstreamReturnOnCapitalEmployedChart.highFeedForward.data.forEach(function (item) {
              nonrocehighyears.push(item.year);
              nonrocexhighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = nonrocehighyears;
            this.chartHighFeedForward.chartvalues = nonrocexhighvalues;
            this.VarnonupstreamchartsModel.nonUpstreamReturnOnCapitalEmployedChart.lowFeedForward.data.forEach(function (item) {
              nonrocelowyears.push(item.year);
              nonrocelowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = nonrocelowvalues;
            this.chartLowFeedForward.yearsdata = nonrocelowyears;
            if(this.form.IsOldVersionOfForms)
            {
              this.VarnonupstreamchartsModel.nonUpstreamReturnOnCapitalEmployedChart.midFullCycle.data.forEach(function (item) {
                nonrocemidvalues.push(item.value);
              });
            }
            else{
              this.VarnonupstreamchartsModel.nonUpstreamReturnOnCapitalEmployedChart.midFeedForward.data.forEach(function (item) {
                nonrocemidvalues.push(item.value);
              });
            }

            this.chartMidFeedForward.chartvalues = nonrocemidvalues;
            this.NonUpstreamReturnOnCapitalEmployedChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamReturnOnCapitalEmployedChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),

                }, {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamReturnOnCapitalEmployedChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarnonupstreamchartsModel.nonUpstreamReturnOnCapitalEmployedChart.midFullCycle.name : this.VarnonupstreamchartsModel.nonUpstreamReturnOnCapitalEmployedChart.midFeedForward.name,
                  backgroundColor:'#B22222',
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(2); }),
                  borderColor:'#B22222',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {

                  display: false
                }
              }
            };
            ////--------nonUpstreamOperationalEarningsAfterTaxChart Chart-----------

            let nonoeatehighyears: any = [];
            let nonoeatlowyears: any = [];
            let nonoeathighvalues: any = [];
            let nonoeatlowvalues: any = [];
            let nonoeatmidvalues: any = [];
            this.VarnonupstreamchartsModel.nonUpstreamOperationalEarningsAfterTaxChart.highFeedForward.data.forEach(function (item) {
              nonoeatehighyears.push(item.year);
              nonoeathighvalues.push(item.value);
            });
            this.chartHighFeedForward.yearsdata = nonoeatehighyears;
            this.chartHighFeedForward.chartvalues = nonoeathighvalues;
            this.VarnonupstreamchartsModel.nonUpstreamOperationalEarningsAfterTaxChart.lowFeedForward.data.forEach(function (item) {
              nonoeatlowyears.push(item.year);
              nonoeatlowvalues.push(item.value);
            });
            this.chartLowFeedForward.chartvalues = nonoeatlowvalues;
            this.chartLowFeedForward.yearsdata = nonoeatlowyears;
            if(this.form.IsOldVersionOfForms)
            {
              this.VarnonupstreamchartsModel.nonUpstreamOperationalEarningsAfterTaxChart.midFullCycle.data.forEach(function (item) {
                nonoeatmidvalues.push(item.value);
              });
            }
            else{
              this.VarnonupstreamchartsModel.nonUpstreamOperationalEarningsAfterTaxChart.midFeedForward.data.forEach(function (item) {
                nonoeatmidvalues.push(item.value);
              });
            }

            this.chartMidFeedForward.chartvalues = nonoeatmidvalues;
            this.NonUpstreamOperationalEarningsAfterTaxChartData = {
              labels: this.chartHighFeedForward.yearsdata,
              datasets: [
                {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamOperationalEarningsAfterTaxChart.highFeedForward.name,
                  borderColor: 'black',
                  borderWidth: 1,
                  fill: false,
                  borderDash: [5, 5],
                  data: this.chartHighFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),

                }, {
                  type: 'line',
                  label: this.VarnonupstreamchartsModel.nonUpstreamOperationalEarningsAfterTaxChart.lowFeedForward.name,
                  fill: false,
                  borderColor: 'black',
                  borderDash: [10, 5],
                  data: this.chartLowFeedForward.chartvalues.map(function (e) { return e.valueOf().toFixed(0); }),
                }, {
                  type: 'bar',
                  label: this.form.IsOldVersionOfForms.valueOf() == true ? this.VarnonupstreamchartsModel.nonUpstreamOperationalEarningsAfterTaxChart.midFullCycle.name : this.VarnonupstreamchartsModel.nonUpstreamOperationalEarningsAfterTaxChart.midFeedForward.name,
                  backgroundColor: "#B22222",
                  width: 10,
                  data: this.chartMidFeedForward.chartvalues,
                  borderColor: '#B22222',
                  borderWidth: 0
                }]
            };
            this.chartOptions = {

              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                    maxRotation: 90,
                    minRotation: 90,
                    fontColor: 'rgba(0, 0, 0, 1)'
                  },
                  grid: {
                    display: false
                  }
                },
                y: {
                  ticks: {
                    color: '#495057'
                  },
                  grid: {
                    color: '#ebedef'
                  }
                }
              },

              elements: {
                point: {
                  radius: 0
                }
              },

              plugins: {
                legend: {

                  display: false
                }
              }
            };

            setTimeout(() => {
              if (this.chartNCE != undefined)
                this.formchartinfo.NonUpstreamCapitalAndExpenditureChart = this.chartNCE.getBase64Image();
              if (this.chartNVolume != undefined)
                this.formchartinfo.NonUpstreamVolumeChart = this.chartNVolume.getBase64Image();
              if (this.chartNAT != undefined)
                this.formchartinfo.NonUpstreamAfterTaxCashFlowChart = this.chartNAT.getBase64Image();
              if (this.chartNCAT != undefined)
                this.formchartinfo.nonUpstreamCumulativeCashChart = this.chartNCAT.getBase64Image();
              if (this.chartNEarn != undefined)
                this.formchartinfo.nonUpstreamEarningsBeforeInterestChart = this.chartNEarn.getBase64Image();
              if (this.chartNOperate != undefined)
                this.formchartinfo.NonUpstreamOperatingExpenseChart = this.chartNOperate.getBase64Image();
              if (this.chartRoce != undefined)
                this.formchartinfo.NonUpstreamReturnOnCapitalEmployedChart = this.chartRoce.getBase64Image();
              if (this.chartOeat != undefined)
                this.formchartinfo.NonUpstreamOperationalEarningsAfterTaxChart = this.chartOeat.getBase64Image();
              this.SaveChartImagesForPDF(this.formchartinfo);
            }, 1000);
          }
        }
        this.LoadFormComment();

      }

    });
  }

  ReturnChartOtions(title: string) {
    let OptionsObject = this.chartsOptions;
    OptionsObject["plugins"]["title"]["text"] = title;
    return OptionsObject;
  }

  limitTextToShow(e:any){
    let max = AppConstant.MaxWordCountForChartDesc;
    let keynum = null;
    e.target.value = e.target.value.replace(/\n/g," ");
    let words = e.target.value.trim().split(" ").length;
    if(window.event) {
      keynum = e.keyCode;
    } else if(e.which) {
      keynum = e.which;
    }
    if(keynum === 13){
      e.preventDefault();
    }
    if(words >= max) {
      e.preventDefault();
     // this.notifyService.showWarning(`Cannot add more than ${max} words`,"Words limit");
    }

  }
  TrimChartDescription(){
    this.formchartinfo.capitalAndExpenditureChartDescription = this.TrimToThreeLines(this.formchartinfo.capitalAndExpenditureChartDescription);
    this.formchartinfo.productionChartDescription = this.TrimToThreeLines(this.formchartinfo.productionChartDescription);
    this.formchartinfo.afterTaxCashflowChartDescription = this.TrimToThreeLines(this.formchartinfo.afterTaxCashflowChartDescription);
    this.formchartinfo.cumulativeAtcashflowChartDescription = this.TrimToThreeLines(this.formchartinfo.cumulativeAtcashflowChartDescription);
    this.formchartinfo.earningsChartDescription = this.TrimToThreeLines(this.formchartinfo.earningsChartDescription);
    this.formchartinfo.earningsPerBarrelChartDescription = this.TrimToThreeLines(this.formchartinfo.earningsPerBarrelChartDescription);
    this.formchartinfo.operatingCashMarginChartDescription = this.TrimToThreeLines(this.formchartinfo.operatingCashMarginChartDescription);
    this.formchartinfo.nriReservesBookingChartDescription = this.TrimToThreeLines(this.formchartinfo.nriReservesBookingChartDescription);
    this.formchartinfo.nonUpstreamCapitalAndExpenditureChartDescription = this.TrimToThreeLines(this.formchartinfo.nonUpstreamCapitalAndExpenditureChartDescription);
    this.formchartinfo.nonUpstreamVolumeChartDescription = this.TrimToThreeLines(this.formchartinfo.nonUpstreamVolumeChartDescription);
    this.formchartinfo.nonUpstreamAfterTaxCashFlowChartDescription = this.TrimToThreeLines(this.formchartinfo.nonUpstreamAfterTaxCashFlowChartDescription);
    this.formchartinfo.nonUpstreamCumulativeCashChartDescription = this.TrimToThreeLines(this.formchartinfo.nonUpstreamCumulativeCashChartDescription);
    this.formchartinfo.nonUpstreamEarningsBeforeInterestChartDescription = this.TrimToThreeLines(this.formchartinfo.nonUpstreamEarningsBeforeInterestChartDescription);
    this.formchartinfo.nonUpstreamOperatingExpenseChartDescription = this.TrimToThreeLines(this.formchartinfo.nonUpstreamOperatingExpenseChartDescription);
    this.formchartinfo.nonUpstreamReturnOnCapitalEmployedChartDescription = this.TrimToThreeLines(this.formchartinfo.nonUpstreamReturnOnCapitalEmployedChartDescription);
    this.formchartinfo.nonUpstreamOperationalEarningsAfterTaxChartDescription = this.TrimToThreeLines(this.formchartinfo.nonUpstreamOperationalEarningsAfterTaxChartDescription);
  }
  getPrintOptions(options) {
    let printOptions = $.extend(true, {}, options);
    printOptions.options.title.fontSize = 26;
    printOptions.options.legend.labels.fontSize = 26;
    printOptions.options.scales.xAxes[0].ticks.fontSize = 26;
    printOptions.options.scales.yAxes[0].ticks.fontSize = 26;
    return printOptions;
  };
  TrimToThreeLines(input:string)
  {
      if(input != null && input != undefined && input.trim() != "")
      {
          input = input.replace(/\n/g," ").trim();
          if(input.split(" ").length > 0)
          {
              var words = AppConstant.MaxWordCountForChartDesc;
              if(input.split(" ").length > words)
              {
                  var totalWords = input.split(" ",words);
                  var finalLines = totalWords.join(" ");
                  return finalLines;
              }else
              {
                  var totalWords = input.split(" ",words);
                  var finalLines = totalWords.join(" ");
                  return finalLines;
              }
          }
      }
      return input;
  }
}