import { Injectable } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { lastValueFrom } from "rxjs";
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationInitializerService {
  scopes: string[] = [];
  constructor(private authService: CalAngularService) { }

  async checkAccount() : Promise<boolean> {

    const isUserLoggedIn = await lastValueFrom(this.authService.isUserSignedIn())

    if(isUserLoggedIn.valueOf()) {
        let currentUserProfile = this.authService.cvxClaimsPrincipal;  
        // console.log("Got user details");
        // console.log(currentUserProfile);
    }
     return isUserLoggedIn;
  }

  async getCALAccessToken() {
    this.scopes = environment.scopes;
    const accessToken = await lastValueFrom(this.authService.getAADToken(this.scopes))
    // console.log("this is access token")
    // console.log(accessToken)
    return accessToken
  }
}
