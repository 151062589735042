import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppsettingsService } from 'src/app/Shared/appsettings.service';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {
  GetChartsDataUrl = 'api/Charts/getchart?formid=';
  postChartsDataUrl ='api/Charts/savedescription';
  postchartcomment='api/Charts/savecomments';
  postChartsyearUrl='api/Charts/updatechartyears';
  getoverviewchartsUrl='api/OverView/getoverviewcharts?formid='
GetFormComment='api/Charts/getformcomments?formid=';
GetSupplementCharts='api/Charts/getusSupplementchart?formid=';
SaveChartImagesForPDFUrl ='api/Charts/SaveChartImagesForPDF';
  constructor(private http: HttpClient, private appsettings: AppsettingsService) { }

  GetChartsData(formId: number,isold:boolean) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetChartsDataUrl + formId+"&isold="+isold);
  }
  UpdateChartsData(chartdata: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.postChartsDataUrl, chartdata);
  }
  UpdateChartsComment(chartcomment: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.postchartcomment, chartcomment);
  }
  ChangeYears(chartdata: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.postChartsyearUrl, chartdata);
  }
  GetFormCommentsData(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetFormComment + formId);
  }
  GetOverviewCharts(formId: number,version:number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getoverviewchartsUrl + formId +
      "&version="+version);
  }
  GetSupplementChartsData(formId: number,isold:boolean) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetSupplementCharts + + formId+"&isold="+isold);
  }
  SaveChartImagesForPDF(chartdata: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.SaveChartImagesForPDFUrl, chartdata);
  }
}
