<div class="fid-form ssbod">
    <div class="row">
        <div [ngClass]="{'col-xs-12':true, 
        'col-sm-6': (formType != 'AR_LOOKBACK_PART5')? true:false, 
        'col-sm-12' : (formType === 'AR_LOOKBACK_PART5')? true:false}">
            <div class="row">
                <div class="col-xs-12">
                    <app-general [viewModel]="viewModel" [type]="type" [formType]="formType"  [isReadOnlyGlobalProjectLevel]="isReadOnlyGlobalProjectLevel"></app-general>
                </div>
                <div class="col-xs-12">
                    <app-subsurface-basis [viewModel]="viewModel" [type]="type"  [isReadOnlyGlobalProjectLevel]="isReadOnlyGlobalProjectLevel"></app-subsurface-basis>
                </div>
            </div>
        </div>
        <div [ngClass]="{'col-xs-12':true, 
        'col-sm-6': (formType != 'AR_LOOKBACK_PART5')? true:false, 
        'col-sm-12' : (formType === 'AR_LOOKBACK_PART5')? true:false}">
            <div class="row">
                <div class="col-xs-12">
                    <app-purpose-and-leading-alternative [viewModel]="viewModel"  [isReadOnlyGlobalProjectLevel]="isReadOnlyGlobalProjectLevel"></app-purpose-and-leading-alternative>
                </div>
                <div class="col-xs-12">
                    <div>
                        <app-facilities-concept-summary [viewModel]="viewModel"  [isReadOnlyGlobalProjectLevel]="isReadOnlyGlobalProjectLevel"></app-facilities-concept-summary>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div class="row sub-section">
                <div class="col-xs-12 form-group">
                    <strong>Other Comments:</strong>
                </div>
                <div class="col-xs-12">
                    <textarea class="form-control" [disabled]="viewModel.IsReadOnly || isReadOnlyGlobalProjectLevel" [(ngModel)]="viewModel.Comments" (change)="SaveSSBODCommonDetails()"></textarea>
                </div>
            </div>
        </div>
        <div class="col-xs-12">
            <div>
                <app-scope-of-work [viewModel]="viewModel"  [isReadOnlyGlobalProjectLevel]="isReadOnlyGlobalProjectLevel"></app-scope-of-work>
            </div>
        </div>
    </div>
</div>

