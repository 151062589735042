import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { ProjectData } from 'src/app/Model/ProjectData.model';
import { OverviewService } from '../FEEDServices/overview.service';
import { ActivatedRoute } from '@angular/router';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { Form } from 'src/app/Model/Form.model';
import { LessonsLearnedService } from '../FEEDServices/lessons-learned.service';
import { LessonLearnedCategory } from 'src/app/Model/LessonLearnedCategory.model';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { LessonLearnedDetail } from 'src/app/Model/LessonsLearned.model';
import { NotificationService } from 'src/app/Shared/notification.service';
import { LessonLearnedType } from 'src/app/Model/LessonLearnedType.model';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/Modals/confirm-dialog/confirm-dialog.component';
import { FeedNavService } from '../FEEDServices/feed-nav.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-lessons-learned',
  templateUrl: './lessons-learned.component.html',
  styleUrls: ['./lessons-learned.component.css']
})
export class LessonsLearnedComponent implements OnInit {
  public Editor = customBuild;
  public config = {

    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [

      // 'fontfamily','fontsize','fontColor','fontBackgroundColor', '|',
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',

    ],
    autosave: {
      save(editor) {

      }
    }

  }
  projectData = new ProjectData();
  LLCategoryData: LessonLearnedCategory[] = [];
  formCase: string | null = '';
  formId: number = 0;
  projectId: number = 0;
  @Input() isOnHold: boolean = false;
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  form = new Form();
  LLTypeData: LessonLearnedDetail[] = [];
  LLSaveModel = new LessonLearnedDetail();
  LLUpdateModel = new LessonLearnedDetail();
  KDUpdateModel = new LessonLearnedDetail();
  KDSaveModel = new LessonLearnedDetail();
  PlanUpdateModel = new LessonLearnedDetail();
  LLType = new LessonLearnedType();
  PlanType = new LessonLearnedType();
  KeyType = new LessonLearnedType();
  PlanTypeData = new LessonLearnedDetail();
  KeyTypeData: LessonLearnedDetail[] = [];
  formLL: UntypedFormGroup;
  formKD: UntypedFormGroup;
  lessonForm: UntypedFormGroup;
  keyDecisionForm: UntypedFormGroup;
  naKeyDecisionDisabled: boolean = false;
  LLMaxOrder: number = 0;
  KDMaxOrder: number = 0;
  projectName: string = '';
  LLPhaseData: any[] = [];
  LLPhaseVersions: any[] = [];
  selectedPhase: any = 0;
  selectedPhaseHeader: any = "";
  ARType: string | undefined = '';
  versionName: number = 0;
  isOptOut: boolean = false;
  @Input() versionOptionText: any = "";
  @Input() readOnly: boolean = false;
  @Input() originalId: number = 0;
  @Output() changeHeader = new EventEmitter;
  @Output() changeQueryData = new EventEmitter;
  @Input() passedSelectedPhase: number = 0;
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;
  @Input() isOldVersionOfForms: boolean = false;
  constructor(public dialog: MatDialog, private overviewService: OverviewService, private route: ActivatedRoute, private go36ProjectService: Go36projectService
    , private lessonLearnedService: LessonsLearnedService, private notifyService: NotificationService,
    private fb: UntypedFormBuilder, private feednavService: FeedNavService, private projectservice: Go36projectService, public utilityService: UtilityService) {
    this.formLL = this.fb.group({
      lessonsLearned: this.fb.array([])
    })
    this.lessonForm = this.fb.group({
    })
    this.formKD = this.fb.group({
      keyDecision: this.fb.array([])
    })
    this.keyDecisionForm = this.fb.group({
    })
  }
  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formCase = (this.route.snapshot.paramMap.get("formCase"));
    if (this.formCase == 'Create' || this.formCase == 'LLCreate') {
      this.formId = this.originalId;
      this.projectId = Number(this.route.snapshot.paramMap.get("projectId"));
      this.getLLPhasesByProjectId();
      this.versionName = Number(this.route.snapshot.paramMap.get("versionName"));
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          this.formId = this.originalId;
          this.projectId = params['projectId'];
          this.formCase = params['formCase'];
          this.getLLPhasesByProjectId();
          this.versionName = params['versionName'];
          // // this.selectedVersion = params['versionName'];
        })
    }
    this.LoadLLData();
    this.LoadPlanData();
    this.LoadKeyData();
    this.getProjectData();
  }
  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }
  onChangeOptOut(value: MatSlideToggleChange) {
    this.isOptOut = value.checked;
    this.form.id = this.formId;
    this.form.modifiedBy = this.provId;
    this.form.optOutLessonsLearned = this.isOptOut;
    this.form.projectId = this.projectId;
    this.feednavService.postOptOutLessonsLearned(this.form).subscribe(res => {
    });
  }

  onReady(editor, order, name) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.UpdateCkeditorChanges(editor.getData(), order, name);
        }
      }
    });
  }
  ShowOptOutModal() {
    const message = "If a discussion on alternative selection was included in a previous AR and there have been no significant changes, the project team can opt to exclude this section. When Opt Out is selected, this tab will be disabled."
    const dialogData = new InfoDialogModel("Opt Out", message);
    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  getFEEDData() {
    this.feednavService.getData(this.formId, this.versionName).subscribe((res: Form) => {
      this.form = res;
      let megaFriendName = this.form.AssetMegaFriendlyName != null ? this.form.AssetMegaFriendlyName + ' - ' : '';
      this.projectName = this.form.BUName + ' - ' + megaFriendName + ' ' + this.form.Name;
    });
  }
  getLLPhasesByProjectId() {
    if (this.passedSelectedPhase > 0) {
      this.lessonLearnedService.getLLPhasesByProjectId(this.projectId, this.formId, this.passedSelectedPhase).subscribe((res: any) => {
        if (this.LLPhaseData == undefined || this.LLPhaseData == null || this.LLPhaseData.length <= 0)
          this.LLPhaseData = res.phases;
        this.LLPhaseVersions = res.versions;
      });
    }
  }
  getLLSelectedPhase() {
    this.lessonLearnedService.getLLSelectedPhase(this.projectId, this.formId).subscribe((res: any) => {
      this.selectedPhaseHeader = res.text;
      this.changeHeader.emit({ headerText: res.text, versionText: "#" + this.versionName + " - " + this.versionOptionText.replace("#", ""), readOnly: this.form.isReadOnly });
      this.selectedPhase = Number(res.value);
    });
  }
  onLLPhaseChange(event: any) {
    this.selectedPhase = Number(event.target.value);
    this.ARType = "";
    this.lessonLearnedService.getLLFormIdOnPhaseChange(this.projectId, this.versionName, event.target.value).subscribe((res: any) => {
      this.formId = Number(res.formId);
      this.LLPhaseVersions = res.versions;
      this.versionName = Number(this.LLPhaseVersions[0].value);
      this.getProjectData();
      this.ClearLLData();
      this.LoadLLData();
      this.LoadPlanData();
      this.LoadKeyData();
    });
  }
  onLLPhaseVersionChange(event: any) {
    this.versionName = Number(event.target.value);
    this.versionOptionText = event.target['options'][event.target['options'].selectedIndex].text;;
    this.lessonLearnedService.getLLFormIdOnVersionChange(this.projectId, this.formId, this.versionName, this.selectedPhase).subscribe((res: any) => {
      this.formId = Number(res.formId);
      this.getProjectData();
      this.ClearLLData();
      this.LoadLLData();
      this.LoadPlanData();
      this.LoadKeyData();
    });
  }
  ClearLLData() {
    this.LLTypeData = [];
    this.KeyTypeData = [];
    this.clearFormArray(this.lessonsLearned);
    this.clearFormArray(this.keyDecision);
  }
  get lessonsLearned(): any {
    return this.formLL.get('lessonsLearned') as UntypedFormArray;
  }
  get keyDecision(): any {
    return this.formKD.get('keyDecision') as UntypedFormArray;
  }
  UpdateFormData() {
    this.form.modifiedBy = this.provId;
    // //this.form.id = this.form.id;
    this.overviewService.updateFormData(this.form).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
  }
  addNewLL(type: any): void {
    if (type == "LessonLearned") {
      this.lessonForm = this.fb.group({
        lessonLearnedCategoryId: [-1],
        title: [''],
        description: ['']
      });
      this.LLDataIntialization(this.LLTypeData.length + 1);
      this.lessonsLearned.push(this.lessonForm);
      this.lessonsLearned.controls[this.lessonsLearned.length - 1].get("title")?.disable();
    }
    else if (type == "KeyDecision") {
      this.keyDecisionForm = this.fb.group({
        title: [''],
        description: ['']
      });
      this.KeyDataIntialization(this.KeyTypeData.length + 1);
      this.keyDecision.push(this.keyDecisionForm);
    }
  }
  ShowLessonLearnedModal() {
    const message = "Through the lifecycle of the project, there are several opportunities to capture leanings that can be valuable for future projects. These fields are to be utilized to capture Lessons Learned / Key Insights that were identified in a certain Phase of the project." +
      "<br><br> Majority of Lessons Learned are usually more technical or tactical and they are captured in the project’s Lessons Learned Register / iPIMS" +
      "<br><br> Use these fields to share the more relevant and the strategical learnings that can either have the potential to be incorporated in the future into Chevron Project Management IP or learnings that are more strategic in their nature, and valuable for future generations of projects to come"
    const dialogData = new InfoDialogModel("Lesson Learned", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }
  ShowPlanModal() {
    const message = "It is extremely relevant to capture information and lessons learned but if these are not shared within the BU or across SBUs and OPCOs, their potential value is not maximized." +
      "<br><br> This field is reserved for the Project Team to share what actions have already be taken and the ones that will be taken to share the Lessons Learned and Key Insights above with the rest of the Chevron organization."
    const dialogData = new InfoDialogModel("Plan", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }
  ShowKeyDecisionsModal() {
    const message = "Throughout the lifecycle of the project, there are several decisions that are taken and registered into the project’s Decision Register. Some of these decisions are critical for the outcome of the project as well as to enable the project lookback to be performed using the appropriate amount of information." +
      "<br><br> These fields are to be utilized to capture the key Decision that were taken recently during the project Phase and led to the current status of the project or the request being made." +
      "<br><br> Use these fields to share the more relevant and the strategical decisions that influenced the opportunity being pursued, alternative selected, execution strategy and any other project team feel relevant."
    const dialogData = new InfoDialogModel("Key Decisions", message);
    this.dialog.open(InfoDialogComponent, {
      width: "900px",
      data: dialogData
    });
  }
  LoadLLData() {
    this.lessonLearnedService.getLessonLearnedData(this.formId, this.projectId, 1).subscribe(res => {
      this.LLTypeData = res as LessonLearnedDetail[];
      this.lessonLearnedService.getLLCategoryData().subscribe((res: LessonLearnedCategory[]) => {
        this.LLCategoryData = res;
        if (this.LLTypeData.length > 0) {
          this.LLTypeData.forEach((element, index) => {
            let formLLNew = this.fb.group({
              lessonLearnedCategoryId: (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) ? -1 : element.lessonLearnedCategoryId,
              title: element.title,
              description: element.description
            });
            this.lessonsLearned.push(formLLNew);
            this.LLMaxOrder = element.order;
            if (this.LLTypeData[index].lessonLearnedCategoryId == undefined || this.LLTypeData[index].lessonLearnedCategoryId == null) {
              this.LLTypeData[index].lessonLearnedCategoryId = -1;
              this.lessonsLearned.controls[index].get("title")?.disable();
              this.LLTypeData[index].naLessonLearned = true;
            } else
              this.LLTypeData[index].naLessonLearned = false;
          });
        }
        else {
          this.LLDataIntialization(1);
          let formLLNew = this.fb.group({
            lessonLearnedCategoryId: this.LLTypeData[0].lessonLearnedCategoryId,
            title: this.LLTypeData[0].title,
            description: this.LLTypeData[0].description
          });
          this.lessonsLearned.push(formLLNew);
          this.getMaxOrder(1);
          this.lessonsLearned.controls[0].get("title")?.disable();
        }
      });
    });
  }
  LoadPlanData() {
    this.lessonLearnedService.getLessonLearnedData(this.formId, this.projectId, 2).subscribe(res => {
      this.PlanTypeData = res[0] as LessonLearnedDetail;
      if (this.PlanTypeData == undefined && this.PlanTypeData == null)
        this.PlanDataIntialization();
    })
  }
  LoadKeyData() {
    this.lessonLearnedService.getLessonLearnedData(this.formId, this.projectId, 3).subscribe(res => {
      this.KeyTypeData = res as LessonLearnedDetail[];
      if (this.KeyTypeData.length > 0) {
        if (this.KeyTypeData[0].nakeyDecision)
          this.naKeyDecisionDisabled = true;
        else
          this.naKeyDecisionDisabled = false;
        this.KeyTypeData.forEach(element => {
          let formKDNew = this.fb.group({
            title: element.title,
            description: element.description
          });
          this.keyDecision.push(formKDNew);
          this.KDMaxOrder = element.order;
        });
      }
      else {
        this.KeyDataIntialization(1);
        if (this.KeyTypeData[0].nakeyDecision)
          this.naKeyDecisionDisabled = true;
        else
          this.naKeyDecisionDisabled = false;
        let formKDNew = this.fb.group({
          title: this.KeyTypeData[0].title,
          description: this.KeyTypeData[0].description
        });
        this.keyDecision.push(formKDNew);
        this.getMaxOrder(3);
      }
    })
  }
  LLDataIntialization(order: any) {
    this.LLTypeData.push(new LessonLearnedDetail());
    this.LLTypeData[order - 1].lessonLearnedCategoryId = -1;
    this.LLTypeData[order - 1].formId = this.formId;
    this.LLTypeData[order - 1].projectId = this.projectId;
    this.LLTypeData[order - 1].lessonLearnedTypeId = 1;
    this.LLTypeData[order - 1].createdBy = this.provId;
    this.LLTypeData[order - 1].order = order;
    this.LLTypeData[order - 1].formType = "FEED";
    this.LLTypeData[order - 1].title = "";
    this.LLTypeData[order - 1].description = "";
    this.LLTypeData[order - 1].naLessonLearned = true;
  }
  PlanDataIntialization() {
    this.PlanTypeData = new LessonLearnedDetail();
    this.PlanTypeData.formId = this.formId;
    this.PlanTypeData.lessonLearnedCategoryId = undefined;
    this.PlanTypeData.projectId = this.projectId;
    this.PlanTypeData.lessonLearnedTypeId = 2;
    this.PlanTypeData.createdBy = this.provId;
    this.PlanTypeData.order = 1;
    this.PlanTypeData.description = "";
    this.PlanTypeData.formType = "FEED";
  }
  KeyDataIntialization(order: any) {
    this.KeyTypeData.push(new LessonLearnedDetail());
    this.KeyTypeData[order - 1].lessonLearnedCategoryId = undefined;
    this.KeyTypeData[order - 1].formId = this.formId;
    this.KeyTypeData[order - 1].projectId = this.projectId;
    this.KeyTypeData[order - 1].lessonLearnedTypeId = 3;
    this.KeyTypeData[order - 1].createdBy = this.provId;
    this.KeyTypeData[order - 1].order = order;
    this.KeyTypeData[order - 1].formType = "FEED";
    this.KeyTypeData[order - 1].title = "";
    this.KeyTypeData[order - 1].description = "";
    this.KeyTypeData[order - 1].nakeyDecision = false;
  }
  getProjectData() {
    this.form.projectId = this.projectId;
    this.form.active = true;
    this.form.createdBy = this.provId;
    this.form.order = 1;
    this.form.id = this.formId;
    this.overviewService.createDefaultData(this.form).subscribe(res => {
      this.form = res;
      this.readOnly = res.isReadOnly;
      this.projectData = res.projectData;
      this.ARType = this.form.formType == 'LL' ? (this.form.order == 5 ? `${this.form.formType} Phase-5 - P4`
        : (this.form.order == 6 ? `${this.form.formType} Phase-5 - P5` : `${this.form.formType} Phase-${this.form.order}`)) : this.form.formType;
      if (this.form.formType == "LL") {
        this.getLLSelectedPhase();
      }
      this.changeQueryData.emit({
        formId: this.form.originalId,
        versionName: this.versionName,
        formToDeleteId: this.form.id
      })
    });
  }
  onCategoryChange(event: any, order: any, control: UntypedFormControl) {
    const value = event.target.value;
    this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order && x.active == true)[0];
    if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && this.LLUpdateModel.id > 0) {
      this.LLUpdateModel.modifiedBy = this.provId;
      this.LLUpdateModel.lessonLearnedCategoryId = value;
      if (value <= -1)
        this.RemoveOnCategoryChange(order);
      else {
        this.lessonsLearned.controls[order - 1].get("title")?.enable();
        this.LLUpdateModel.naLessonLearned = false;
        this.UpdateLLData(this.LLUpdateModel);
      }
    }
    else {
      this.LLSaveModel = this.LLTypeData.filter(x => x.order == order && x.active == true)[0];
      this.LLSaveModel.lessonLearnedCategoryId = value;
      if (value <= -1) {
        this.lessonsLearned.controls[order - 1].get("title")?.disable();
        this.LLSaveModel.naLessonLearned = true;
      }
      else {
        this.lessonsLearned.controls[order - 1].get("title")?.enable();
        this.LLSaveModel.naLessonLearned = false;
        this.LLSaveModel.order = this.LLMaxOrder + 1;
        this.SaveLLData(this.LLSaveModel);
      }
    }
  }
  RemoveOnCategoryChange(order: any) {
    this.lessonsLearned.controls[order - 1].get("title")?.setValue("");
    this.lessonsLearned.controls[order - 1].get("description")?.setValue("");
    this.lessonsLearned.controls[order - 1].get("title")?.disable();
    this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
    this.LLUpdateModel.naLessonLearned = true;
    this.LLUpdateModel.title = "";
    this.LLUpdateModel.description = "";
    this.LLUpdateModel.lessonLearnedCategoryId = undefined;
    this.UpdateLLData(this.LLUpdateModel);
  }
  UpdateCkeditorChanges(event: customBuild.EventInfo, order: any, type: any) {
    const value = event;
    if (type == "LessonLearned") {
      this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
      if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && (value.trim().length > 0 || this.LLUpdateModel.id > 0)) {
        this.LLUpdateModel.modifiedBy = this.provId;
        this.LLUpdateModel.description = value;
        this.UpdateLLData(this.LLUpdateModel);
      }
    }
    else if (type == "Plan") {
      if (this.PlanTypeData != undefined && this.PlanTypeData != null && (value.trim().length > 0 || this.PlanTypeData.id > 0)) {
        this.PlanTypeData.modifiedBy = this.provId;
        this.PlanTypeData.description = value;
        this.UpdateLLData(this.PlanTypeData);
      }
    }
    else if (type == "KeyDecision") {
      this.KDUpdateModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
      if (this.KDUpdateModel != undefined && this.KDUpdateModel != null && this.KDUpdateModel.id > 0) {
        this.KDUpdateModel.modifiedBy = this.provId;
        this.KDUpdateModel.description = value;
        this.UpdateLLData(this.KDUpdateModel);
      }
      else {
        this.KDSaveModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
        this.KDSaveModel.description = value;
        if (value.trim().length != 0) {
          this.KDSaveModel.order = this.KDMaxOrder + 1;
          this.SaveLLData(this.KDSaveModel);
        }
      }
    }
  }
  SaveLLData(SaveModel: LessonLearnedDetail) {
    SaveModel.formType = this.form.formType;
    this.lessonLearnedService.saveLessonsLearnedData(SaveModel).subscribe(res => {
      if (SaveModel.lessonLearnedTypeId == 1) {
        this.clearFormArray(this.lessonsLearned);
        this.LoadLLData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      else if (SaveModel.lessonLearnedTypeId == 3) {
        this.clearFormArray(this.keyDecision);
        this.LoadKeyData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    })
  }
  UpdateLLData(UpdateModel: LessonLearnedDetail) {
    UpdateModel.formType = this.form.formType;
    this.lessonLearnedService.saveLessonsLearnedData(UpdateModel).subscribe(res => {
      if (UpdateModel.lessonLearnedTypeId == 1) {
        this.clearFormArray(this.lessonsLearned);
        this.LoadLLData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      if (UpdateModel.lessonLearnedTypeId == 3) {
        this.clearFormArray(this.keyDecision);
        this.LoadKeyData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      if (UpdateModel.lessonLearnedTypeId == 2) {
        this.LoadPlanData();
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    })
  }
  DeleteLLData(DeleteModel: LessonLearnedDetail) {
    this.lessonLearnedService.saveLessonsLearnedData(DeleteModel).subscribe(res => {
      if (DeleteModel.lessonLearnedTypeId == 1) {
        this.getMaxOrder(1);
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
      else if (DeleteModel.lessonLearnedTypeId == 3) {
        this.getMaxOrder(3);
        this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
      }
    })
  }
  clearFormArray = (formArray: UntypedFormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }
  onBlurTitleUpdate(event: any, order: any, type: any) {
    const value = event.target.value;
    if (type == "LessonLearned") {
      this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
      if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && (value.trim().length != 0 || this.LLUpdateModel.id > 0)) {
        this.LLUpdateModel.modifiedBy = this.provId;
        this.LLUpdateModel.title = value;
        this.UpdateLLData(this.LLUpdateModel);
      }
    }
    else if (type == "KeyDecision") {
      this.KDUpdateModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
      if (this.KDUpdateModel != undefined && this.KDUpdateModel != null && this.KDUpdateModel.id > 0) {
        this.KDUpdateModel.modifiedBy = this.provId;
        this.KDUpdateModel.title = value;
        this.UpdateLLData(this.KDUpdateModel);
      }
      else {
        this.KDSaveModel = this.KeyTypeData.filter(x => x.order == order && x.active == true)[0];
        this.KDSaveModel.title = value;
        if (value.trim().length != 0) {
          this.KDSaveModel.order = this.KDMaxOrder + 1;
          this.SaveLLData(this.KDSaveModel);
        }
      }
    }
  }
  DeleteEmptyLLTypeData(order: any) {
    this.clearFormArray(this.lessonsLearned);
    this.LLTypeData.forEach((element, index) => {
      if (index > order - 1) {
        element.order = element.order - 1;
        if (element.id > 0) {
          if (element.lessonLearnedCategoryId == -1) { element.lessonLearnedCategoryId = undefined; }
          this.DeleteLLData(element);
          if (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) { element.lessonLearnedCategoryId = -1; }
        }
      }
      if (index == order - 1 && element.id > 0) {
        element.active = false;
        if (element.lessonLearnedCategoryId == -1) { element.lessonLearnedCategoryId = undefined; }
        this.DeleteLLData(element);
        if (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) { element.lessonLearnedCategoryId = -1; }
      }
      if (index != order - 1) {
        let formLLNew = this.fb.group({
          lessonLearnedCategoryId: (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) ? -1 : element.lessonLearnedCategoryId,
          title: element.title,
          description: element.description
        });
        this.lessonsLearned.push(formLLNew);
      }
    });
  }
  DeleteLLTypeData(order: any) {
    this.LLUpdateModel = this.LLTypeData.filter(x => x.order == order)[0];
    if (this.LLUpdateModel != undefined && this.LLUpdateModel != null && this.LLUpdateModel.lessonLearnedCategoryId != -1) {
      this.LLUpdateModel.active = false;
      this.DeleteLLData(this.LLUpdateModel);
      this.clearFormArray(this.lessonsLearned);
      this.LLTypeData.forEach((element, index) => {
        if (index > order - 1) {
          element.order = element.order - 1;
          if (element.id > 0) {
            if (element.lessonLearnedCategoryId == -1) { element.lessonLearnedCategoryId = undefined; }
            this.DeleteLLData(element);
            if (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) { element.lessonLearnedCategoryId = -1; }
          }
        }
        if (index != order - 1) {
          let formLLNew = this.fb.group({
            lessonLearnedCategoryId: (element.lessonLearnedCategoryId == undefined || element.lessonLearnedCategoryId == null) ? -1 : element.lessonLearnedCategoryId,
            title: element.title,
            description: element.description
          });
          this.lessonsLearned.push(formLLNew);
        }
      });
    }
  }
  DeleteEmptyKeyTypeData(order: any) {
    this.clearFormArray(this.keyDecision);
    this.KeyTypeData.forEach((element, index) => {
      if (index > order - 1) {
        element.order = element.order - 1;
        if (element.id > 0) {
          this.DeleteLLData(element);
        }
      }
      if (index == order - 1 && element.id > 0) {
        element.active = false;
        this.DeleteLLData(element);
      }
      if (index != order - 1) {
        let formKDNew = this.fb.group({
          title: element.title,
          description: element.description
        });
        this.keyDecision.push(formKDNew);
      }
    });
  }
  DeleteKeyTypeData(order: any) {
    this.KDUpdateModel = this.KeyTypeData.filter(x => x.order == order)[0];
    if (this.KDUpdateModel != undefined && this.KDUpdateModel != null) {
      this.KDUpdateModel.active = false;
      this.DeleteLLData(this.KDUpdateModel);
      this.clearFormArray(this.keyDecision);
      this.KeyTypeData.forEach((element, index) => {
        if (index > order - 1) {
          element.order = element.order - 1;
          this.DeleteLLData(element);
        }
        if (index != order - 1) {
          let formKDNew = this.fb.group({
            title: element.title,
            description: element.description
          });
          this.keyDecision.push(formKDNew);
        }
      });
    }
  }
  Delete(order: any, type: any) {
    const message = 'Are you sure you want to delete this item?';
    const dialogData = new ConfirmDialogModel("Confirm item deletion", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        if (type == "LessonLearned") {
          if (this.LLTypeData.length >= 1 && this.LLTypeData[order - 1].lessonLearnedCategoryId == -1)
            this.DeleteEmptyLLTypeData(order);
          else if (this.LLTypeData.length >= 1 && this.LLTypeData[order - 1].lessonLearnedCategoryId != -1)
            this.DeleteLLTypeData(order);
          if (this.LLTypeData.length == 1) {
            this.LLTypeData.splice(order - 1, 1);
            this.LLDataIntialization(1);
            let formLLNew = this.fb.group({
              lessonLearnedCategoryId: this.LLTypeData[0].lessonLearnedCategoryId,
              title: this.LLTypeData[0].title,
              description: this.LLTypeData[0].description
            });
            this.lessonsLearned.push(formLLNew);
            this.lessonsLearned.controls[0].get("title")?.disable();
          }
          else if (this.LLTypeData.length > 1) {
            this.LLTypeData.splice(order - 1, 1);
            this.LLTypeData.forEach((element: any, index: any) => {
              if (element.lessonLearnedCategoryId == -1)
                this.lessonsLearned.controls[index].get("title")?.disable();
            });
          }
        }
        else if (type == "Plan") {
          if (this.PlanTypeData != undefined && this.PlanTypeData != null && (this.PlanTypeData.description?.trim().length != 0 || this.PlanTypeData.id > 0)) {
            this.PlanTypeData.active = false;
            this.UpdateLLData(this.PlanTypeData);
          }
        }
        else if (type == "KeyDecision") {
          if (this.KeyTypeData.length >= 1 && this.KeyTypeData[order - 1].description?.trim().length == 0 && this.KeyTypeData[order - 1].title?.trim().length == 0)
            this.DeleteEmptyKeyTypeData(order);
          else if (this.KeyTypeData.length >= 1 && (this.KeyTypeData[order - 1].description?.trim().length != 0 || this.KeyTypeData[order - 1].title?.trim().length != 0))
            this.DeleteKeyTypeData(order);
          if (this.KeyTypeData.length == 1) {
            this.KeyTypeData.splice(order - 1, 1);
            this.KeyDataIntialization(1);
            let formKDNew = this.fb.group({
              title: this.KeyTypeData[0].title,
              description: this.KeyTypeData[0].description
            });
            this.keyDecision.push(formKDNew);
          }
          else if (this.KeyTypeData.length > 1)
            this.KeyTypeData.splice(order - 1, 1);
        }
      }
    });
  }

  NAKeyDecisionChange(event: MatSlideToggleChange) {
    // // const value = event.checked;
    this.KDUpdateModel = this.KeyTypeData[0];
    if (this.KDUpdateModel != undefined && this.KDUpdateModel != null) {
      this.KDUpdateModel.modifiedBy = this.provId;
      this.KDUpdateModel.nakeyDecision = event.checked;
      this.UpdateLLData(this.KDUpdateModel);
    }
  }

  getMaxOrder(typeId: any) {
    this.lessonLearnedService.getMaxOrder(this.formId, this.projectId, typeId).subscribe(res => {
      if (typeId == 1)
        this.LLMaxOrder = res;
      else if (typeId == 3)
        this.KDMaxOrder = res;
    });
  }
}
