export class Go36ArP4LookbackPerformance {
    p4LookbackPerformanceId: number = 0;
    p4LookbackId: number = 0;
    dataDate: Date = new Date;
    trirActualOutcomes: any;
    dafwrActualOutcomes: any;
    executionScheduleEv?:  any;
    executionScheduleP10?:  any;
    executionScheduleP90?:  any;
    executionScheduleActualOutcomes?:  any;
    executionScheduleBenchTopQuartile?:  any;
    executionScheduleBenchIndustryAverage?:  any;
    startupEv?:Date = new Date;
    startupP10?: Date = new Date;
    startupP90?: Date = new Date;
    startupActualOutcomes?: Date = new Date;
    ceProbabilisticEv?: any;
    ceProbabilisticP10?: any;
    ceProbabilisticP90?: any;
    fullCeCostTargetFid:any;
    fullCeCostTargetPhase3:any;
    fullCeCostTargetTotal:any;
    fullCeCostTargetActualOutcomes:any;
    fullCeCostTargetNormalized:any;
    benchCeEv:any;
    benchCeActualOutcomes:any;
    benchCeNormalized:any;
    benchCeBenchTopQuartile:any;
    benchCeBenchIndustryAverage:any;
    expenseExecuteProjectEv:any;
    expenseExecuteProjectP10:any;
    expenseExecuteProjectP90:any;
    expenseExecuteProjectActualOutcomes:any;
    estUltimateRecoveryMmboEv:any;
    estUltimateRecoveryMmboP10:any;
    estUltimateRecoveryMmboP90:any;
    estUltimateRecoveryMmboActualOutcomes:any;
    estUltimateRecoveryBcfEv:any;
    estUltimateRecoveryBcfP10:any;
    estUltimateRecoveryBcfP90:any;
    estUltimateRecoveryBcfActualOutcomes:any;
    estUltimateRecoveryMmboeEv:any;
    estUltimateRecoveryMmboeP10:any;
    estUltimateRecoveryMmboeP90:any;
    estUltimateRecoveryMmboeActualOutcomes:any;
    estUltimateRecovery88MmboeEv:any;
    estUltimateRecovery88MmboeP10:any;
    estUltimateRecovery88MmboeP90:any;
    estUltimateRecovery88MmboeActualOutcomes:any;
    peakProductionMbpdEv:any;
    peakProductionMbpdP10:any;
    peakProductionMbpdP90:any;
    peakProductionMbpdActualOutcomes:any;
    peakProductionMmcfdEv:any;
    peakProductionMmcfdP10:any;
    peakProductionMmcfdP90:any;
    peakProductionMmcfdActualOutcomes:any;
    month12MmboEv:any;
    month12MmboActualOutcomes:any;
    month1288MmboEv:any;
    month1288MmboActualOutcomes:any;
    month12BcfEv:any;
    month12BcfActualOutcomes:any;
    month1288BcfEv:any;
    month1288BcfActualOutcomes:any;
    key_Performance_Drivers_EV:any;
    key_Performance_Drivers_P10:any;
    key_Performance_Drivers_P90:any;
    key_Performance_Drivers_ACTUAL_OUTCOMES:any;
    footnotes: string = '';
    createdBy?: string = '';
    createdDatetime: Date = new Date;
    modifiedBy: string = '';
    modifiedDatetime: Date = new Date;
    hidefromlist: boolean = false;
    keyPerformanceDriversUnit1: any;
    keyPerformanceDriversUnit2: any;
    keyPerformanceDrivers1:  any;
    keyPerformanceDrivers2:  any;
    keyPerformanceDriversP101:  any;
    keyPerformanceDriversEV1: any;
    keyPerformanceDriversP901:  any;
    keyPerformanceDriversActualOutcomes1:  any;
}
