import { Component, OnInit } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { BnNgIdleService } from 'bn-ng-idle';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'MCPGO36';
  scope: any;
  displayModal: boolean = false;
  userProperty: any;
  isRefresh: boolean = true;
  isHidden: boolean = false;
  userAccessDetails: any;
  errorPage: boolean = false;
  parentFunctionData: boolean = false;

  constructor(
    private authService: CalAngularService, private bnIdle: BnNgIdleService,
    public dialog: MatDialog, private router: Router
  ) {
    this.scope = environment.scopes;
  }

  ngAfterViewInit() {
    
    this.errorPage = localStorage.getItem('errorpage') == 'true';
    if (this.errorPage) {
      this.parentFunctionData = this.isHidden = this.errorPage;
    }
  }

  ngDoCheck() {
    if (localStorage.getItem('errorpage')) {
      this.errorPage = localStorage.getItem('errorpage') == 'true';
    }
    else {
      this.errorPage = false;
    }

  }

  ngOnInit() {
    // // this.getUserProperty();
    //this.getTokenParameter();

    // // Refresh Modal is now shifted to auth interceptor. ** please keep below code for future reference **
    // // this.bnIdle.startWatching(3540).subscribe((isTimedOut: boolean) => { 
    // //   if (isTimedOut == true) {
    // //     if (this.isRefresh == true) {
    // //       this.bnIdle.stopTimer();
    // //       const dialogRef = this.dialog.open(RefreshDialogComponent);
    // //       dialogRef.componentInstance.displayModal = true;

    // //       const sub = dialogRef.componentInstance.onContinue.subscribe((data) => {
    // //         if (data.isContinue == true) {
    // //           this.dialog.closeAll();
    // //           this.bnIdle.resetTimer();
    // //         }
    // //       });
    // //     }
    // //   }
    // //   return;
    // // });

    this.authService.getUserPropertiesFromMsGraph(this.userProperty).subscribe(items => {
      this.userProperty = items;
    });
  }

  getUserProperty() {
    this.authService.getUserPropertiesFromMsGraph(["cai", "jobTitle"])
      .subscribe(graphProperties => {
        this.scope = graphProperties.graphScopes;
      });
  }

  getTokenParameter() {
    this.authService.getAADToken(this.scope)
      .subscribe(tokenProperty => {
      });
  }

  parentFunction(data: boolean) {
    this.parentFunctionData = data;
  }
}