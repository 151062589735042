<div class="row font-gotham fid-form" style="background-color: white;">

    <div class="row" style="padding-left: 16px; margin-bottom: 15px;"
        *ngIf="form.formType === 'FEED Supplement' || form.formType === 'FID Supplement'">
        <span *ngIf="!form.IsOldVersionOfForms">
            <mat-slide-toggle (change)="onChange($event)" [checked]="form.optOutBasisOfEvaluation"
                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                <b>Opt Out</b>
            </mat-slide-toggle>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowOptOutModal()"></span>
        </span>
    </div>

    <div class="row sub-section">
        <div class="col-xs-12 form-group">
            <strong>5.1 Guidance</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowGuidanceModal()"></span>
        </div>
        <div class="col-xs-12">
            <table class="table table-bordered">
                <tbody>
                    <tr class="bg-table-default table-header">
                        <td width="30%"></td>
                        <td>Name</td>
                        <td width="25%">Date of Issuance</td>
                        <td width="2%"></td>
                        <td class="trash-icon"></td>
                    </tr>
                    <tr *ngFor="let guide of guidance let i = index">
                        <td [(ngModel)]="guide.id" hidden class="ml-10">{{guide.id}}</td>
                        <td>
                            <span *ngIf="guide.id !== 0 && guide.order < 4"> {{guide.guidanceName}} </span>
                            <input type="text" *ngIf="guide.order > 3" [name]="'guide.guidanceName' +i"
                                class="form-control" [(ngModel)]="guide.guidanceName" name="guidanceName"
                                (change)="UpdateGuidance(guide)"
                                [disabled]="isOnHold || form.optOutBasisOfEvaluation || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="guide.name"
                                (change)="UpdateGuidance(guide)"
                                [disabled]="isOnHold || form.optOutBasisOfEvaluation || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                        </td>
                        <td>
                            <input class="form-control" [(ngModel)]="guide.dateOfIssuance"
                                (dateChange)="UpdateDateOfIssuance(guide)" [matDatepicker]="picker"
                                (click)="picker.open();utilityService.removeCDKAnouncer()"
                                [disabled]="isOnHold || form.optOutBasisOfEvaluation || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </td>
                        <td>
                            <div *ngIf="!form.optOutBasisOfEvaluation"
                                [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <i class="glyphicon glyphicon-comment-outline" *ngIf="!guide.comment"
                                    (click)="SaveCommentForGuidance(guide)"></i>
                                <i class="glyphicon glyphicon-comment commentColor" *ngIf="guide.comment"
                                    (click)="SaveCommentForGuidance(guide)"></i>
                            </div>
                        </td>
                        <td>
                            <div *ngIf="!form.optOutBasisOfEvaluation"
                                [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <div *ngIf="!isOnHold || form.isReadOnly">
                                    <i class="glyphicon glyphicon-trash glyphicon-custom-trash trash-button"
                                        (click)="DeleteGuidance(guide, i)"></i>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-xs-12" *ngIf="!form.isReadOnly">
            <button type="button" style="width:10em; color: white !important;" class="btn btn-sm btn-primary"
                (click)="AddNewGuidance()"
                [disabled]="isOnHold || form.optOutBasisOfEvaluation || isReadOnlyGlobalProjectLevel">Add New
                Row</button>
        </div>
    </div>

    <div class="row sub-section" id="Key_Assumptions">
        <div class="col-xs-12 form-group">
            <strong>5.2 Key Assumptions in P50 case</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowKeyAssumptionsModal()"></span>
        </div>
        <div class="col-xs-12">
            <table class="table table-bordered">
                <tbody>
                    <tr class="bg-table-default table-header">
                        <td colspan="2">
                            Describe briefly the technical, commercial market and geopolitical assumptions which form
                            the basis of the project. Note which are independent and which
                            are contingent on other assumptions. Note which of these are described as major risks in
                            Section 6 (Risks). Include any relevant differences from the
                            reference case. Highlight divergent co-owner views as applicable.
                        </td>
                        <td width="2%"></td>
                        <td width="6%" *ngIf="!form.IsOldVersionOfForms"></td>
                    </tr>
                    <tr *ngFor="let key of keyAssumptions">
                        <td [(ngModel)]="key.id" hidden class="ml-10">{{key.id}}</td>
                        <td width="28%" class="text-left">
                            <span *ngIf="!key.isLastRow"> {{key.assumptionName}} </span>
                            <span *ngIf="!key.isLastRow" class="glyphicon glyphicon-question-sign" (click)="ShowKeyAssumptionsModalEach(key)"></span>
                            <textarea rows="3" type="text" class="form-control;" *ngIf="key.isLastRow"
                                [(ngModel)]="key.assumptionName" (change)="UpdateKeyAssumption(key)"
                                [disabled]="key.naStatus || isOnHold || form.optOutBasisOfEvaluation || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                                style="width: 100%;">
                            </textarea>
                        </td>
                        <td>
                            <textarea rows="3" type="text" class="form-control" [(ngModel)]="key.assumption"
                                (change)="UpdateKeyAssumption(key)"
                                [disabled]="key.naStatus || isOnHold || form.optOutBasisOfEvaluation || form.isReadOnly || isReadOnlyGlobalProjectLevel"></textarea>
                        </td>
                        <td>
                            <div *ngIf="!form.optOutBasisOfEvaluation"
                                [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <i class="glyphicon glyphicon-comment-outline" *ngIf="!key.comment"
                                    (click)="SaveCommentKeyAssumptions(key)"></i>
                                <i class="glyphicon glyphicon-comment commentColor" *ngIf="key.comment"
                                    (click)="SaveCommentKeyAssumptions(key)"></i>
                            </div>
                        </td>
                        <td class="text-center" *ngIf="!form.IsOldVersionOfForms">
                            <!-- <input type="checkbox" class="form-check-input" [checked]="key.naStatus"
                                [(ngModel)]="key.naStatus" (change)="NAEventCheck($event, key)"
                                [disabled]="isOnHold || form.optOutBasisOfEvaluation || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                            <label style="padding-left: 8px;"><b>N/A</b></label> -->

                            <span style="font-size: 13px !important;">
                                <mat-slide-toggle (change)="NAEventCheck($event, key)" [checked]="key.naStatus"
                                    [disabled]="isOnHold || form.optOutBasisOfEvaluation || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                                    <span class="app-font">N/A</span>
                                </mat-slide-toggle>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row sub-section" *ngIf="form.IsOldVersionOfForms && leadership.length > 0">
        <div class="col-xs-12 form-group">
            <strong>5.3 Leadership</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowLeadershipModal()"></span>
        </div>
        <div class="col-xs-12">
            <table class="table table-bordered">
                <tbody>
                    <tr class="table-header bg-table-default">
                        <td></td>
                        <td width="45%"><strong>Decision Executive - Position</strong></td>
                        <td width="45%"><strong>Project Leader - Position</strong></td>
                    </tr>
                    <tr *ngFor="let leader of leadership">
                        <td [(ngModel)]="leader.id" hidden class="ml-10">{{leader.id}}</td>
                        <td>{{leader.leadershipName}}</td>
                        <td>
                            <textarea rows="3" type="text" class="form-control" [(ngModel)]="leader.decisionExecutive"
                                (change)="UpdateLeadership(leader)"
                                [disabled]="isOnHold || form.optOutBasisOfEvaluation || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                            </textarea>
                        </td>
                        <td>
                            <textarea rows="3" type="text" class="form-control" [(ngModel)]="leader.projectLeader"
                                (change)="UpdateLeadership(leader)"
                                [disabled]="isOnHold || form.optOutBasisOfEvaluation || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                        </textarea>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>