<div class="box-container box-shadow" id="page-body">

  <!-- <div class="row">
    <div class="col">
      <div class="dark-blue" style="text-align: center; color: #0B2D71; font-size: 65%;">
        <h3><b>Project Name</b></h3>
        </div>
      <div class="dark-blue" style="text-align: center; color: #0B2D71;">
        <h3>{{ projectName }}</h3>
      </div>
    </div>
  </div> -->

  <div class="row">
    <h3>
      <small class="dark-blue">Project Name</small>
      <br>
      <span data-bind="text: projectName">{{ projectName }}</span>
    </h3>
  </div>

  <div class="row">
    <div class="row col-md-10">
      <button style="width:7em;height: 4.8rem; color: white !important;"
        [disabled]="!this.userAccessListByProjectId?.data?.canAddForm" class="btn btn-sm btn-success"
        (click)="OpenFeedDialog()">
        <div></div>
        <div>FEED</div>
      </button>
      <button style="width:7em;height: 4.8rem; color: white !important;" class="btn btn-sm btn-success"
        [disabled]="!this.userAccessListByProjectId?.data?.canAddForm" (click)="CreateFeedSupplement()">
        <div>FEED</div>
        <div>Supplement</div>
      </button>
      <button style="width:7em;height: 4.8rem; color: white !important;"
        [disabled]="!this.userAccessListByProjectId?.data?.canAddForm" class="btn btn-sm btn-success"
        (click)="CreateFID()">
        <!-- <div>New Simple </div> -->
        <div>FID</div>
      </button>
      <button style="width:7em;height: 4.8rem; color: white !important;" class="btn btn-sm btn-success"
        [disabled]="!this.userAccessListByProjectId?.data?.canAddForm" (click)="CreateFIDSupplement()">
        <div>FID </div>
        <div>Supplement</div>
      </button>
      <button style="width:9em;height: 4.8rem; color: white !important;" class="btn btn-sm btn-success"
        [disabled]="!this.userAccessListByProjectId?.data?.canAddForm" (click)="OpenLessonLearnedDialog()">
        <div>Lesson Learned </div>
        <div>Phases</div>
      </button>


      <button style="width:8em;height: 4.8rem; background-color:#ebebeb;margin-bottom: 0.5rem"
        [disabled]="!this.userAccessListByProjectId?.data?.canAddForm" class="btn btn-sm btn btn-light"
        (click)="CreatePart4LookBack()">
        <div style="color: #0066B2;">Part 4</div>
        <div style="color: #0066B2;">Lookback</div>
      </button>
      <button style="width:8em;height: 4.8rem;background-color:#ebebeb;;margin-bottom: 0.5rem;margin-left: 0.1rem;"
        [disabled]="!this.userAccessListByProjectId?.data?.canAddForm" class="btn btn-sm btn btn-light"
        (click)="CreatePart5LookBack()">
        <div style="color: #0066B2;">Part 5</div>
        <div style="color: #0066B2;">Lookback</div>
      </button>
    </div>
  </div>

  <div class="row">
    &nbsp;
  </div>
  <div class="row">
    <div class="col-sm-12">

      <p-table #formDatadt selectionMode="single" [value]="formData" [rows]="10" [paginator]="false"
        styleClass="p-datatable-projects p-datatable-sm" [rowsPerPageOptions]="[10, 20, 50, 100]"  paginatorPosition="bottom"
        [globalFilterFields]="[
        'Version',
            'RequestType',
            'DocumentType',
            'Status',
            'ModifiedDateTime',
            'ApprovedDateTime',
            'CreatedBy',
            'Id'
      ]">
        <ng-template pTemplate="caption">
          <div class="flex" style="float:right;margin-bottom: 1%;">
            <span class="p-input-icon-left ml-auto">
              <b>Search: </b><input #searchText pInputText type="text"
                (input)="formDatadt.filterGlobal(searchText.value, 'contains')" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr style="background-color: lightskyblue;height: 40px;">
            <th></th>
            <th pSortableColumn="Version">
              Version <p-sortIcon field="Version"></p-sortIcon>
            </th>
            <th pSortableColumn="RequestType">
              RequestType <p-sortIcon field="RequestType"></p-sortIcon>
            </th>
            <th pSortableColumn="DocumentType">DocumentType <p-sortIcon field="DocumentType"></p-sortIcon>
            </th>
            <th pSortableColumn="Status">Status <p-sortIcon field="Status"></p-sortIcon>
            </th>
            <th pSortableColumn="ModifiedDateTime">
              DateModified <p-sortIcon field="ModifiedDateTime"></p-sortIcon>
            </th>
            <th pSortableColumn="ApprovedDateTime">
              ApprovedDate <p-sortIcon field="ApprovedDateTime"></p-sortIcon>
            </th>
            <th pSortableColumn="CreatedBy">
              CreatedBy <p-sortIcon field="CreatedBy"></p-sortIcon>
            </th>
            <th pSortableColumn="OriginalId">
              ID <p-sortIcon field="OriginalId"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th style=" background-color:#efefef !important;"></th>
            <th style="height:6rem; background-color:#efefef !important;">
              <input style="max-width:10em" pInputText type="text" #searchVersion
                (input)="formDatadt.filter(searchVersion.value,'Version', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input style="max-width:5em" pInputText type="text" #searchRequestType
                (input)="formDatadt.filter(searchRequestType.value,'RequestType', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input style="max-width:5em" pInputText type="text" #searchDocumentType
                (input)="formDatadt.filter(searchDocumentType.value,'DocumentType', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>

            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchStatus
                (input)="formDatadt.filter(searchStatus.value,'Status', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchDateModified
                (input)="formDatadt.filter(searchDateModified.value,'DateModified', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchApprovedDate
                (input)="formDatadt.filter(searchApprovedDate.value,'ApprovedDate', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchCreatedBy
                (input)="formDatadt.filter(searchCreatedBy.value,'CreatedBy', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>
            <th style=" background-color:#efefef !important;">
              <input pInputText style="max-width:5em" type="text" #searchID
                (input)="formDatadt.filter(searchID.value,'ID', 'contains')" placeholder="Search..."
                class="p-column-filter" />
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="100" style="text-align: center;">
              No records found in the table. Please search for other text
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-form>
          <tr>
            <td style="padding: 2px 0px;">
              <div>
                <a *ngIf="form.FormType=='FEED'" style="width:5em; margin-bottom: 0.5rem; background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/feed']"
                  [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='LL'" style="width:5em;  margin-bottom: 0.5rem; background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/lessonlearned']"
                  [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'LLOpen'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='FEED Supplement'"
                  style="width:5em; margin-bottom: 0.5rem; background-color: royalblue;margin-bottom: 0.5rem;"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/feed_Supplement']"
                  [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='Phase 3 to 4 (FID)'"
                  style="width:5em;margin-bottom: 0.5rem; background-color: royalblue" class="btn  btn-sm btn-info"
                  [routerLink]="['/forms/fid']"
                  [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='FID Supplement'"
                  style="width:5em;margin-bottom: 0.5rem; background-color: royalblue" class="btn  btn-sm btn-info"
                  [routerLink]="['/forms/fid_Supplement']"
                  [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                  <div>Open </div>

                </a>
                <a *ngIf="form.FormType=='LLE'" style="width:5em; margin-bottom: 0.5rem; background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/feed']"
                  [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='EPC'" style="width:5em; margin-bottom: 0.5rem;background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/feed']"
                  [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='Part4'" style="width:5em;  margin-bottom: 0.5rem;background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/part4']"
                  [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="form.FormType=='Part5'" style="width:5em; margin-bottom: 0.5rem;background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/part5']"
                  [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="(form.FormType=='Old FEED' || form.FormType=='FID' || form.FormType=='Long Lead' || form.FormType=='Acquisition' || form.FormType=='Rescope' || form.FormType=='') && form.RequestType == 'AR'" 
                  style="width:5em;margin-bottom: 0.5rem; background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/oldFID']"
                  [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                  <div>Open </div>
                </a>
                <a *ngIf="(form.FormType=='Old FEED' || form.FormType=='FID' || form.FormType=='Long Lead' || form.FormType=='Acquisition' || form.FormType=='') && form.RequestType == 'Supplement'" 
                  style="width:5em;margin-bottom: 0.5rem; background-color: royalblue"
                  class="btn  btn-sm btn-info" [routerLink]="['/forms/oldSupplement']"
                  [queryParams]="{ projectId: pID,formId: form.OriginalId,versionName:form.selectedVersion,formCase:'Open'}">
                  <div>Open </div>
                </a>

                <router-outlet></router-outlet>
                <button style="margin-left: 0.5rem; color: white !important;" class="btn btn-sm btn-success"
                  (click)="OpenFormSettings( pID, form.OriginalId, form.selectedVersion, form.FormType, form.Location, form.OpcoId)"
                  [disabled]="form.FormType=='FID' || form.FormType=='Long Lead' || form.FormType=='Old FEED' || form.FormType==''">
                  <div>Settings </div>
                </button>
              </div>
            </td>
            <td>
              <!-- <select formControlName="vId" class="form-control" style="width:8em;" (change)="onVersionChange($event)">
                <option *ngFor="let item of form.Versions; let i = index"
                [value]="item.Id">#{{item.Name}}
                </option>
              </select> -->
              <select name="selectedType" #feedformType class="form-control" style="width:8em;"
                [(ngModel)]="form.selectedVersion" (change)="onVersionChange($event)"
                [disabled]="form.FormType=='FID' || form.FormType=='Long Lead' || form.FormType=='Old FEED' || form.FormType==''">
                <option *ngFor="let item of form.Versions; let i = index" [value]="item.Id">
                  <span *ngIf="item.Name == null">#{{item.Id}}</span>
                  <span *ngIf="(item.Name != null) && (item.Id==item.Name)">#{{item.Id}}</span>
                  <span *ngIf="(item.Name != null) && (item.Id!=item.Name)">#{{item.Id}} - {{item.Name}}</span>
                </option>
              </select>
            </td>
            <td>{{ form.RequestType }}</td>
            <td>{{ form.DocumentType }}</td>
            <td>{{ form.Status }}</td>
            <td>{{ form.ModifiedDateTime | date: 'MM/dd/yyyy'}}</td>
            <td>{{ form.ApprovedDateTime | date: 'MM/dd/yyyy'}}</td>
            <td>{{ form.CreatedBy }}</td>
            <td>{{ form.OriginalId }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div style="text-align: left;">
      <button style="width:10em;color: white !important;margin:50px 0px 0px 10px" [disabled]="!this.userAccessListByProjectId?.data?.canRemove"
        class="btn btn-sm btn-danger" (click)="DeleteProject('DeleteProject')">
        <div>Delete Project </div>
      </button>
    </div>
  </div>
</div>