import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Form } from 'src/app/Model/Form.model';
import { FeedNavService } from '../../FEEDServices/feed-nav.service';
import { Part4LookbackService } from 'src/app/forms/FEEDServices/Part4LookBack_Service/part4-lookback.service';
import { Part5LookbackService } from '../../FEEDServices/part5-lookback.service';
import { EaprojectService } from 'src/app/project/ea/service/eaproject.service';
import { EaSupplementService } from 'src/app/project/ea/service/ea-supplement.service';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {
  formId: number = 0;
  projectId: number = 0;
  form = new Form();
  versionName: number = 0;
  formType: string = "";
  projectData: any;
  displayDocumentType : string = "";
  constructor(private route: ActivatedRoute,
    private projectservice: Go36projectService, private feednavService: FeedNavService, private router: Router, private part4service: Part4LookbackService,
    private part5lookbackService: Part5LookbackService,private eaService: EaprojectService,private eaSupplementService: EaSupplementService) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.projectId = params['projectId'];
        this.formId = params['formId'];
        this.versionName = Number(params['versionName']);
        this.formType = params['formType'];
      });
    this.getProjectInfobyId();
    this.getFormData();

  }
  getProjectInfobyId() {
    this.projectservice.GetProjectInfobyId(this.projectId).subscribe(res => {
      this.projectData = res;
      if (res) {
        this.projectData = res;
        this.displayDocumentType =  this.formType;
      }
    });
  }
  getFormData() {
    if (this.formType != "AR_LOOKBACK_PART4" && this.formType != "NewAR" && this.formType != "EA Supplement" && this.formType!=="AR_LOOKBACK_PART5") {
      this.feednavService.getFormData(this.formId).subscribe((res: any) => {
        this.form = res;
      });
    }
    else if(this.formType == "NewAR"){
      this.eaService.GetPart1Data(this.formId, this.versionName).subscribe((res: any) => {
        if (res) {
          this.form.id = res.part1HeaderData.arId; 
          this.form.createdOn = res.part1HeaderData.createdDatetime;  
          this.form.createdBy = res.part1HeaderData.createdByName;  
          this.form.modifiedBy = res.part1HeaderData.modifiedBy_FullName; 
          this.form.originalId = res.part1HeaderData.originalId;  
        }
      });
    }
    else if(this.formType == "EA Supplement"){
      this.eaSupplementService.GetPart1Data(this.formId, this.versionName).subscribe((res: any) => {
        if (res) {
          if(res.part1.supplement)
            this.displayDocumentType = "EA Supplement";
          else
            this.displayDocumentType = "EA Rescope";
          this.form.id = res.part1.supplementId; 
          this.form.location = res.part1.projectLocation;
          this.form.createdOn = res.part1.createdDateTime; 
          this.form.createdBy = res.part1.createdBy_FullName;  
          this.form.modifiedBy = res.part1.modifiedBy_FullName; 
          this.form.originalId = res.part1.originalId;  
        }
      });
    }
   else if(this.formType=="AR_LOOKBACK_PART5")
    {
      this.part5lookbackService.getPart5FormData(this.formId).subscribe((res: Form) => {
        this.form = res;
      });
    }
    else {
      this.part4service.getPart4FormData(this.formId).subscribe((res: Form) => {
        this.form = res;
      });
    }
  }


  DeleteForm() {
    if (this.formType != "AR_LOOKBACK_PART4" && this.formType != "AR_LOOKBACK_PART5" && this.formType != "NewAR" && this.formType != "EA Supplement") {
      this.feednavService.deleteForm(this.formId, this.versionName).subscribe(res => {
        this.router.navigateByUrl('/go36project/viewform?projectId=' + this.projectId);
      }
      )
    }
    else if (this.formType == "AR_LOOKBACK_PART4") {
      this.part4service.deletePart4Form(this.formId, this.versionName).subscribe(res => {
        this.router.navigateByUrl('/go36project/viewform?projectId=' + this.projectId);
      }
      )
    }
    else if (this.formType == "AR_LOOKBACK_PART5") {
      this.part5lookbackService.deletePart5Form(this.formId, this.versionName).subscribe(res => {
        this.router.navigateByUrl('/go36project/viewform?projectId=' + this.projectId);
      }
      )
    }
    else if (this.formType == "NewAR") {
      this.eaService.deleteForm(this.formId, this.versionName).subscribe(res => {
        this.router.navigateByUrl('/eaproject/eaviewform?projectId=' + this.projectId);
      })
    }
    else if (this.formType == "EA Supplement") {
      this.eaSupplementService.deleteForm(this.formId, this.versionName).subscribe(res => {
        this.router.navigateByUrl('/eaproject/eaviewform?projectId=' + this.projectId);
      })
    }
  }
}
