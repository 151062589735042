import { Component, OnInit } from '@angular/core';
import { Go36projectService } from '../project/go36project/service/go36project.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(private projectservice: Go36projectService) { }

  ngOnInit(): void {
    localStorage.removeItem('errorpage');
  }
  SetEditMode(editMode: string) {
    localStorage.setItem('editMode', 'false')
  }
  GenerateRsidReport() {
    this.projectservice.generateRsidReport().subscribe(data => {
      let downloadURL = window.URL.createObjectURL(data);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = "RSID_Projects_Extract.xlsx";
      link.click();
    });
  }
}
