import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AppsettingsService {
  public apiBaseUrl: string = environment.apiBaseUrl;
  // public localhostUrl:string= 'https://localhost:7080/';
  // public Baseurl:string= 'https://go36-api-stage.azure.chevron.com/';
  //public Baseurl:string='https://go36-api-dev.azure.chevron.com/';  
}
