import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/Shared/notification.service';

@Component({
  selector: 'app-alert-formexist-dialog',
  templateUrl: './alert-formexist-dialog.component.html',
  styleUrls: ['./alert-formexist-dialog.component.css']
})
export class AlertFormexistDialogComponent implements OnInit {

  title: string = '';
  message: string = '';
  constructor(public dialogRef: MatDialogRef<AlertFormexistDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormExistialogModel,
    private notifyService: NotificationService) {
      this.message = data.message;
    dialogRef.disableClose = true;
    setTimeout(() => {
      let element = document.querySelector(".mat-dialog-container");
      element?.setAttribute("style","padding: 0!important;border-radius: 6px!important;");
      let element2 = document.querySelector("html");
      element2?.setAttribute("style","top: 0!important;");
    },0);
   }

  ngOnInit(): void {
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class FormExistialogModel {
  constructor(public message: string) {
  }
}
