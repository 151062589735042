import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AttachSsbodFormDialogComponent } from '../../modal/attach-ssbod-form-dialog/attach-ssbod-form-dialog.component';
import { SubsurfaceViewModel } from '../../Model/subsurfaceView.model';

@Component({
  selector: 'app-attach-ssbod-form',
  templateUrl: './attach-ssbod-form.component.html',
  styleUrls: ['./attach-ssbod-form.component.css']
})
export class AttachSsbodFormComponent {

  @Input() viewModel: SubsurfaceViewModel = new SubsurfaceViewModel();
  @Input() type : string = '';
  @Input() parentData: any;
  formId: number = 0;
  @Output() changeSelectedTab= new EventEmitter;
  @Input() isReadOnlyGlobalProjectLevel : boolean = false;
  
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.OpenAttachSsbodFormDialog();   
  }
  OpenAttachSsbodFormDialog() {
      const dialogRef = this.dialog.open(AttachSsbodFormDialogComponent, {
        width: "600px",
        data:{fidId: this.parentData.fidId,formId: this.parentData.ssbodId, formType:this.parentData.formType,projectId: this.parentData.projectId},
      });
      dialogRef.componentInstance.changeSelectedTab.subscribe(data=> {
        this.formId = data; 
        this.changeSelectedTab.emit(this.formId);   
      })
  }
}
