<div class="custom-modal-header">
  <button type="button" (click)="onDismiss()" class="close-btn" aria-hidden="true">×</button>
  <h4 mat-dialog-title>{{title}}</h4>
</div>
<hr>
<div mat-dialog-content>{{message}}</div>
<hr>
<div mat-dialog-actions style="float: right;">
  <button mat-button (click)="onDismiss()" class="btn btn-default"> No </button> &nbsp;&nbsp;
  <button mat-raised-button  style="color: white !important;" class="btn btn-primary" (click)="onConfirm()">Yes</button>
</div>