import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AssetService } from 'src/app/admin/service/asset.service';
import { BusinessUnit } from 'src/app/admin/asset/asset.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-businessunit-create',
  templateUrl: './businessunit-create.component.html',
  styleUrls: ['./businessunit-create.component.css']
})

export class BusinessunitCreateComponent implements OnInit {
  OpcoList: any;
  sbuList: any;
  formValue!: UntypedFormGroup;
  assetData: BusinessUnit = new BusinessUnit();
  data: any;
  editMode: boolean = false;
  assetId: number = 0;
  constructor(private formbuilder: UntypedFormBuilder, private apiService: AssetService, private router: Router,
    private projectservice: Go36projectService,
    private route: ActivatedRoute, private utilityServcie: UtilityService) {
    this.formValue = this.formbuilder.group({
      OpcoId: ['', Validators.required],
      sbuId: ['', Validators.required],
      name: ['', Validators.required],
      active: ['']
    })
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.assetId = Number(params['Id']);
        if (this.assetId > 0)
          this.editMode = true;
      });

    this.apiService.get("operatingcompanies").subscribe(resOpcoList => {
      this.OpcoList = resOpcoList;
      if (this.editMode) {
        this.apiService.getById("BusinessUnit/GetBUDetailsById?Id=", this.assetId).subscribe(res => {
        this.fillSbuDDL(res.operatingCompanyId);
        this.formValue.controls['sbuId'].setValue(res.strategicBusinessUnitId);
        this.formValue.controls['OpcoId'].setValue(res.operatingCompanyId);
        this.formValue.controls["name"].setValue(res.name);
        this.formValue.controls["active"].setValue(res.active);
        this.assetData.id = res.id;
        this.data = res;
      })
    }
    })
  }

  fillOpcoDDL(OpcoId: any) {
    if (OpcoId == 0) {
      this.apiService.get("operatingcompanies").subscribe(res => {
        this.OpcoList = res;
      })
    }
    else {
      this.formValue.controls['OpcoId'].setValue(OpcoId);
    }
  }

  fillSbuDDL(opcoId: any) {
    this.apiService.get("strategicbu").subscribe(res => {
      this.sbuList = res.filter(r => r.operatingCompanyId == opcoId);
      if (this.sbuList.length == 1)
        this.formValue.controls['sbuId'].setValue(this.sbuList[0].id);
    })
  }

  postDetails() {
    this.assetData.StrategicBusinessUnitId = this.formValue.value.sbuId;
    this.assetData.OperatingCompanyId = this.formValue.value.OpcoId;
    this.assetData.active = true;
    this.assetData.name = this.formValue.value.name;
    this.assetData.createdBy = this.utilityServcie.GetuserProvisioningId();
    this.apiService.post("businessunit", this.assetData).subscribe((res) => {
      let ref = document.getElementById('cancel');
      ref?.click();
      this.formValue.reset();
      this.router.navigateByUrl("/manage/businessunits");
    });
  }

  updateAssetDetails() {
    this.assetData.StrategicBusinessUnitId = this.formValue.value.sbuId;
    this.assetData.OperatingCompanyId = this.formValue.value.OpcoId;
    this.assetData.active = this.formValue.value.active;
    this.assetData.name = this.formValue.value.name;
    this.assetData.modifiedBy = this.utilityServcie.GetuserProvisioningId();
    this.apiService.update("businessunit", this.assetData.id, this.assetData).subscribe(res => {     
      let ref = document.getElementById('cancel');
      ref?.click();
      this.formValue.reset();
      this.router.navigateByUrl("/manage/businessunits");
    });
  }
}
