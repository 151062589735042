    export  class FormChart
    {
        Id :number=0;
          Active : boolean = false;
    CreatedBy : string = '';
         ModifiedBy : string = '';
          CreatedOn : Date = new Date;
          ModifiedOn : Date = new Date;
        FormId :number=0;
         ProjectChanges : string = '';
         GreenHouseGasTotal : number=0;
         GreenHouseGasUnit : number = 0;
         GreenHouseGasCost : number = 0;
         OperationalComment : string = '';
         EffectOfKeyRiskOnPointForwardProjectEconomics : string = '';
         OriginalAppropriationRequest : number = 0;
         NpvPointForward : number = 0;
         NpvCommodityTestedBy : string = '';
         CapitalAndExpenditureChart : string = '';
         ProductionChart : string = '';
         AfterTaxCashflowChart : string = '';
         EarningsPerBarrelChart : string = '';
         CumulativeAtcashflowChart : string = '';
         NriReservesBookingChart : string = '';
         EarningsChart : string = '';
         OperatingCashMarginChart : string = '';
         AccretionDilutionChart : string = '';
         capitalAndExpenditureChartDescription: string = ''; 
         productionChartDescription : string = '';
         afterTaxCashflowChartDescription : string = '';
         earningsPerBarrelChartDescription : string = '';
         cumulativeAtcashflowChartDescription : string = '';
         nriReservesBookingChartDescription : string = '';
         earningsChartDescription : string = '';
         operatingCashMarginChartDescription : string = '';
         AccretionDilutionChartDescription : string = '';
         WaterfallIllustrationImage : string = '';
         FeedForwardNetPresentValueSensitivityHigh: string = ''; 
         FeedForwardNetPresentValueSensitivityLow : string = '';
         FeedForwardNetPresentValueSensitivityMid : string = '';
         FeedForwardNetPresentValueTornadoImage : string = '';
         FeedForwardEconomicSensitivityEurTitle : string = '';
         BusinessUnitApproval : boolean = false;
         CarbonCostChart : string = '';
         EmissionAndCarbonIntensityChart: string = ''; 
         NonUpstreamAfterTaxCashFlowChart : string = '';
         nonUpstreamAfterTaxCashFlowChartDescription : string = '';
         NonUpstreamCapitalAndExpenditureChart : string = '';
         nonUpstreamCapitalAndExpenditureChartDescription : string = '';
         nonUpstreamCumulativeCashChart : string = '';
         nonUpstreamCumulativeCashChartDescription : string = '';
         nonUpstreamEarningsBeforeInterestChart : string = '';
         nonUpstreamEarningsBeforeInterestChartDescription : string = '';
         NonUpstreamOperatingExpenseChart : string = '';
         nonUpstreamOperatingExpenseChartDescription : string = '';
         NonUpstreamOperationalEarningsAfterTaxChart : string = '';
         nonUpstreamOperationalEarningsAfterTaxChartDescription : string = '';
         NonUpstreamReturnOnCapitalEmployedChart : string = '';
         nonUpstreamReturnOnCapitalEmployedChartDescription : string = '';
         NonUpstreamVolumeChart : string = '';
         nonUpstreamVolumeChartDescription : string = '';
         CapitalAndExpendituresWaterfallImage : string = '';
         NpvWaterfallImage : string = '';
         ScheduleWaterfallImage : string = '';
         TotalGhgemissionAndCarbonCostChart : string = '';
         MethaneAndFlaringAndTotalIntensityChart : string = '';
         capitalAndExpenditureChartComment : string = '';
         afterTaxCashflowChartComment : string = '';
         productionChartComment : string = '';
    }
