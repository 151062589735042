<div class="fid-form font-gotham" style="background-color: white;" *ngIf="formId>0">
    <div class="row sub-section">
        <div class="col-sm-6 col-xs-12">
            <div class="row form-group">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">Project Name</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" readonly="readonly" id="projectName" name="projectName"
                        [(ngModel)]="projectData.projectName" />
                </div>
            </div>
            <div class="row form-group">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">Segment</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" readonly="readonly" id="opcoName" name="opcoName"
                        [(ngModel)]="projectData.opcoName" />
                </div>
            </div>
            <div class="row form-group">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">BU</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" readonly="readonly" id="buName" name="buName"
                        [(ngModel)]="projectData.buName" />
                </div>
            </div>
            <div class="row form-group" *ngIf="ARType.includes('Phase-')">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">Project Phase</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <select class="form-control" [(ngModel)]="selectedPhase" (change)="onLLPhaseChange($event)">
                        <option *ngFor="let phase of LLPhaseData" [value]="phase.value">{{phase.text}}</option>
                    </select>
                </div>
            </div>
            <div class="row form-group" *ngIf="ARType.includes('Supplement')">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">Supplement</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <select class="form-control" [(ngModel)]="selectedSupplement" (change)="onSupplementChange($event)">
                        <option *ngFor="let supplement of SupplementData" [value]="supplement.value">{{supplement.text}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xs-12">
            <div class="row form-group">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">AR Type</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" readonly="readonly" id="formType" name="formType"
                        [(ngModel)]="ARType" />
                </div>
            </div>
            <div class="row form-group">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">RU</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" readonly="readonly" id="sbuName" name="sbuName"
                        [(ngModel)]="projectData.sbuName" />
                </div>
            </div>
            <div class="row form-group">
                <div class="col-sm-4 col-xs-12">
                    <label class="control-label form-label">Asset Class</label>
                </div>
                <div class="col-sm-8 col-xs-12">
                    <input type="text" class="form-control" readonly="readonly" id="assetName" name="assetName"
                        [(ngModel)]="projectData.assetName" />
                </div>
            </div>
        </div>
    </div>
    <fieldset [disabled]="true">
        <div class="row sub-section">
            <div class="col-xs-12 form-group">
                <strong>Lessons Learned:</strong>
                <i> Describe key insights identified during work on this project </i>
                <span class="glyphicon glyphicon-question-sign" (click)="ShowLessonLearnedModal()"></span>
            </div>
            <form [formGroup]="formLL">
                <ng-container formArrayName="lessonsLearned">
                    <div *ngFor="let control of lessonsLearned.controls ;let i = index;">
                        <div [formGroupName]="i">
                            <div class="row form-group">
                                <div
                                    class="col-sm-6 col-xs-12">
                                    <span class="col-sm-4 col-xs-12">
                                        <span>{{i+1}}</span>
                                        . Lesson Learned :
                                    </span>
                                    <span class="col-sm-8 col-xs-12" style="text-align:left">
                                        <select formControlName="lessonLearnedCategoryId" class="form-control">
                                            <option value="-1" selected>Please select category for lesson learned</option>
                                            <option *ngFor="let category of LLCategoryData" [value]="category.id">
                                                {{category.name}}</option>
                                        </select>
                                    </span>
                                </div>
                                <div class="col-sm-6 col-xs-12">
                                    <span class="col-sm-8 col-xs-12" style="text-align:left">
                                        <input formControlName="title" placeholder="Enter title for lesson learned"
                                            class="form-control" style="text-align:left;width:104%!important" [disabled]=true />
                                    </span>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-sm-12">
                                    <div class="col-xs-12">
                                        <ckeditor formControlName="description" #lessonLearned [editor]="Editor"
                                            [config]="config" id="lessonLearned" name="lessonLearned"
                                            [disabled]=true></ckeditor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </form>
        </div>
        <div class="row sub-section">
            <div class="col-xs-12 form-group">
                <strong>Plan:</strong>
                <i> Include a sharing plan for the insights, the target Chevron organizations for receiving the information
                    and approximate timing. Also, if available, include a summary of how any significant gap will be
                    closed</i>
                <span class="glyphicon glyphicon-question-sign" (click)="ShowPlanModal()"></span>
            </div>
            <div>
                <div>
                    <div class="row form-group">
                        <div class="col-sm-12">
                            <div></div>
                            <div class="col-xs-12">
                                <ckeditor [disabled]="true" #plan [editor]="Editor"
                                    [config]="config" id="plan" name="plan" [(ngModel)]="PlanTypeData.description"></ckeditor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row sub-section" *ngIf="!isNAEnabled">
            <div class="col-xs-12">
                <strong>Key Decisions:</strong>
                <i> Describe key decisions taken during work on this project phase</i>
                <span class="glyphicon glyphicon-question-sign" (click)="ShowKeyDecisionsModal()"></span>
            </div>
            <form [formGroup]="formKD">
                <ng-container formArrayName="keyDecision">
                    <div *ngFor="let control of keyDecision.controls ;let i = index;">
                        <div [formGroupName]="i">
                            <div class="row form-group">
                                <div class="col-sm-6 col-xs-12">
                                    <span class="col-sm-4 col-xs-12">
                                        <span>{{i+1}}</span>
                                        . Decision :
                                    </span>
                                    <span class="col-sm-8 col-xs-12" style="text-align:left">
                                        <input placeholder="Enter title for key decision" formControlName="title"
                                            style="text-align:left;width:104%!important" class="form-control" [disabled]="true" />
                                    </span>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-sm-12">
                                    <div class="col-xs-12">
                                        <ckeditor formControlName="description" #keyDecision [editor]="Editor"
                                            [config]="config" id="keyDecision" name="keyDecision"
                                            [disabled]="true"></ckeditor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </form>
        </div>
    </fieldset>
</div>
<div style="padding: 100px;" *ngIf="formId==0">
    <h3>Form not yet created</h3>
</div>