import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppsettingsService } from 'src/app/Shared/appsettings.service';

@Injectable({
  providedIn: 'root'
})
export class ExecutionService {
  getUrl = 'api/Execution/getExecutionData?formId=';
  createUrl = 'api/Execution/SaveExecutionData';
  updateKeyMileStoneUrl = 'api/Execution/SaveKeyMileStoneData';
  CopyUrl = 'api/Execution/copyExecutionData';
  updateExecutionMileStoneUrl = 'api/Execution/SaveExecutionMileStoneData';
  constructor(private http: HttpClient,
    private appsettings: AppsettingsService) { }

    getData(formId: number) {
      return this.http.get<any>(this.appsettings.apiBaseUrl + this.getUrl + formId);
    }
    postData(formData: any) {
      return this.http.post(this.appsettings.apiBaseUrl + this.createUrl, formData);
    }
    postKeyMileStoneData(formData: any) {
      return this.http.post(this.appsettings.apiBaseUrl + this.updateKeyMileStoneUrl, formData);
    }
    copyData(formId: number, userName: string, projectId: number, isSuppliment: boolean) {
      const params = new HttpParams()
        .append('formId', formId)
        .append('userName', userName)
        .append('projectId', projectId)  
      return this.http.post<any>(this.appsettings.apiBaseUrl + this.CopyUrl, '', {
        params: params
      });
    }
    postExecutionMileStoneData(formData: any) {
      return this.http.post(this.appsettings.apiBaseUrl + this.updateExecutionMileStoneUrl, formData);
    }
}
