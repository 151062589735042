<nav class="navbar navbar-default navbar-default-subsite centered" role="navigation">
    <div class="navbar-collapse js-navbar-collapse centered row font-gotham" id="navbar-brand-centered">
        <ul class="nav navbar-nav navbar-left">
            <li class="dropdown dropdown-large hamburger pull-left">
                <button type="button" class="navbar-toggle dropdown-toggle dark-blue" (click)="showMenu()" >
                    <span class="sr-only">Toggle navigation</span>
                    <span class="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span>
                    <span class="sr-only visible-xs">menu</span>
                    <span class="icon-text hidden-xs">menu</span>
                </button>
                <div *ngIf="showArrow" class="main-menu-overlay" (click)="showMenu()">
                    <div class="top-arrow-menu"></div>
                    <div class="main-menu">
                        <h3 class="h3-quick-menu">quick menu</h3>
                        <a href="/" class="go36-home-quick-menu">go-36 home</a>
                        <hr class="hr-quick-menu">
                        <a href="https://inside.chevron.com/" target="_blank" class="inside-quick-menu">inside chevron.com</a>
                    </div>
                </div>
            </li>
        </ul> 
        <span class="nav navbar-nav navbar-right light-blue font-gotham text-right header-css"
            style="margin-top:75px; margin-right:50px">
            Welcome, {{userName}}
        </span>
        <div class="navbar-brand navbar-brand-centered">
            <div class="hallmark-container">
                <a class="hallmark" href="/" title="Chevron"><img alt="Chevron" src="../../../assets/Chevron_icons/hallmark.png"
                        width="42" height="46"></a>
            </div>         
               <h5 *ngIf="this.SetEnv=='test'"style="color:red !important;margin: -8px 0 -10px;"> 
                    TEST ENVIRONMENT
               </h5>
            <div  class="sitename-container vertical-align">
                <a class="sitename light-blue font-gotham header-css" href="/">
                    Chevron Strategy and Sustainability
                </a>
            </div>
           
        </div>
    </div>
</nav>

<!-- Site-specific navigation -->
<nav class="navbar navbar-subsite centered" role="navigation">
    <div class="container-fluid">
        <div class="collapse navbar-collapse js-navbar-collapse centered row font-gotham navbar-mobile dropdown-menu"
            id="navbar-brand-centered-subsite">
            <div class="mobile-nav-container vertical-align flex-center centered">
                <ul *ngIf="projectservice.isHeaderVisible" class="nav navbar-nav primary">

                    <!-- home  -->
                    <li
                        class="dropdown level-2 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a class="dropdown-toggle offcanvas-link vertical-align ie10-flex-start" href="/"><span
                                class="link-text">Home</span></a>
                    </li>
                    <!-- ar project  -->
                    <li
                        class="dropdown level-2 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a class="dropdown-toggle offcanvas-link vertical-align ie10-flex-start"
                        routerLink= "/go36project/project"><span class="link-text">GO-36 Projects</span></a>
                    </li>
                    <!-- ea project  -->
                    <li
                        class="dropdown level-2 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a class="dropdown-toggle offcanvas-link vertical-align ie10-flex-start"
                        routerLink= "/ea/project"><span class="link-text">GO-36 E&amp;A Projects</span></a>
                    </li>
                    <li
                        class="dropdown level-2 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a class="dropdown-toggle offcanvas-link vertical-align ie10-flex-start"
                        routerLink="/forms/ssbodProject"><span class="link-text">SSBOD</span></a>
                    </li>
                    <!-- help -->
                    <li
                        class="dropdown level-2 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a class="dropdown-toggle offcanvas-link vertical-align ie10-flex-start"><span
                                class="link-text">Help</span></a>
                        <ul class="nav navbar-subsite dropdown-menu sub-menu-font dropdown-menu-right">
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                    href="https://chevron.sharepoint.com/sites/CSSOrg/Investment%20Analysis/Forms/AllItems.aspx?id=%2Fsites%2FCSSOrg%2FInvestment%20Analysis%2FDigital%20GO%2D36%2FDigital%20GO%2D36%20instructions%20and%20help%20file%2Epdf&amp;parent=%2Fsites%2FCSSOrg%2FInvestment%20Analysis%2FDigital%20GO%2D36"
                                    target="_blank">DGO-36 Instructions</a></li>
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                    href="https://chevron.sharepoint.com/sites/CSSOrg/Investment%20Analysis/Forms/AllItems.aspx?id=%2Fsites%2FCSSOrg%2FInvestment%20Analysis%2FDigital%20GO%2D36%2FSSBODFormUserGuide%2Epdf&amp;parent=%2Fsites%2FCSSOrg%2FInvestment%20Analysis%2FDigital%20GO%2D36"
                                    target="_blank">SSBOD User Guide</a></li>
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                    href="https://chevron.sharepoint.com/sites/CSSOrg/Investment%20Analysis/Forms/AllItems.aspx?id=%2Fsites%2FCSSOrg%2FInvestment%20Analysis%2FInvestment%20Analysis%20Manual%2FInvestment%20Analysis%20Manual%2Epdf&parent=%2Fsites%2FCSSOrg%2FInvestment%20Analysis%2FInvestment%20Analysis%20Manual"
                                    target="_blank">Investment Analysis Manual</a></li>
                            <!-- <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                    href="https://chevron.sharepoint.com/:w:/r/sites/CSSOrg/_layouts/15/Doc.aspx?sourcedoc=%7B6C180027-5490-48A7-979E-3FF5778BA163%7D&amp;file=DGO-36%20role%20definitions.docx&amp;wdLOR=c21D4A0B7-F715-4EF7-AB74-63C9C3507B07&amp;action=default&amp;mobileredirect=true"
                                    target="_blank">DGO-36 Role Definitions</a></li> -->
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                    href="https://chevron.sharepoint.com/:w:/r/sites/CSSOrg/_layouts/15/Doc.aspx?sourcedoc=%7BD5394289-B12D-4DF5-90E0-E604CE4539AD%7D&amp;file=DGO-36%20FAQs.docx&amp;wdLOR=c7E7066D3-03AC-4BF6-BBC2-C0DA72E9AE6A&amp;action=default&amp;mobileredirect=true"
                                    target="_blank">DGO-36 FAQs</a></li>
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"><span
                                        class="link-text">Sample Projects</span></a></li>
                            <li class="menu-hover"><a
                                    class="menu-hover vertical-align ie10-flex-start padding-5 padding-left-25"
                                    href="https://go36.chevron.com/#/go36project/viewform?projectId=624" target="_blank">Upstream Example
                                    Project</a></li>
                            <li class="menu-hover"><a
                                    class="menu-hover vertical-align ie10-flex-start padding-5 padding-left-25"
                                    href="https://go36.chevron.com/#/go36project/viewform?projectId=625" target="_blank">Non-Upstream
                                    Example Project</a></li>
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                    href="https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=9c08a21edb126450182e1dca48961981" target="_blank">Contact
                                    Support</a></li>
                        </ul>
                    </li>
                    <!-- admin tool -->
                    <li *ngIf="this.userAccessDetails?.data?.isPowerUser || this.userAccessDetails?.data?.isAdminUser"
                        class="dropdown level-2 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a class="dropdown-toggle offcanvas-link vertical-align ie10-flex-start"><span
                                class="link-text">Admin</span></a>
                        <ul class="nav navbar-subsite dropdown-menu sub-menu-font dropdown-menu-right">
                            <li *ngIf="this.userAccessDetails.data.featuresAccess['Assets'] || this.userAccessDetails.data.isPowerUser" class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"

                                routerLink="/manage/assets">Assets</a></li>
                            <li *ngIf="this.userAccessDetails.data.featuresAccess['Operating Companies'] || this.userAccessDetails.data.isPowerUser" class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                routerLink="/manage/operatingcompanies">Segments</a></li>

                            <li *ngIf="this.userAccessDetails.data.featuresAccess['Strategic Business Units'] || this.userAccessDetails.data.isPowerUser" class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                routerLink="/manage/strategicBusinessUnits">Reporting Units</a></li>
                            <li *ngIf="this.userAccessDetails.data.featuresAccess['Business Units'] || this.userAccessDetails.data.isPowerUser " class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                routerLink="/manage/businessunits">Business Units</a></li>
                            <li *ngIf="this.userAccessDetails.data.featuresAccess['User Access'] || this.userAccessDetails.data.isPowerUser" class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                routerLink="/manage/useraccesses">User Access</a></li>
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                routerLink="/manage/userprofile" [queryParams]="{userId: userId}">User Profile</a></li>
                        </ul>
                    </li>
                    <!-- User Profile -->
                    <li *ngIf="!this.userAccessDetails?.data?.isPowerUser && !this.userAccessDetails?.data?.isAdminUser"
                        class="dropdown level-2 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a class="dropdown-toggle offcanvas-link vertical-align ie10-flex-start"
                                routerLink="/manage/userprofile" [queryParams]="{userId: userId}"><span class="link-text">User Profile</span></a>
                    </li>
                </ul>
                <ul *ngIf="!projectservice.isHeaderVisible" class="nav navbar-nav primary">
                  
                    <!-- help -->
                    <li
                        class="dropdown level-2 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a class="dropdown-toggle offcanvas-link vertical-align ie10-flex-start"><span
                                class="link-text">Help</span></a>
                        <ul class="nav navbar-subsite dropdown-menu">
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                    href="https://chevron.sharepoint.com/sites/CSSOrg/Investment%20Analysis/Forms/AllItems.aspx?id=%2Fsites%2FCSSOrg%2FInvestment%20Analysis%2FDigital%20GO%2D36%2FDigital%20GO%2D36%20instructions%20and%20help%20file%2Epdf&amp;parent=%2Fsites%2FCSSOrg%2FInvestment%20Analysis%2FDigital%20GO%2D36"
                                    target="_blank">DGO-36 Instructions</a></li>
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                    href="https://chevron.sharepoint.com/sites/CSSOrg/Investment%20Analysis/Forms/AllItems.aspx?id=%2Fsites%2FCSSOrg%2FInvestment%20Analysis%2FDigital%20GO%2D36%2FSSBODFormUserGuide%2Epdf&amp;parent=%2Fsites%2FCSSOrg%2FInvestment%20Analysis%2FDigital%20GO%2D36"
                                    target="_blank">SSBOD User Guide</a></li>
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                    href="https://chevron.sharepoint.com/sites/CSSOrg/Investment%20Analysis/Forms/AllItems.aspx?id=%2Fsites%2FCSSOrg%2FInvestment%20Analysis%2FInvestment%20Analysis%20Manual%2FInvestment%20Analysis%20Manual%2Epdf&parent=%2Fsites%2FCSSOrg%2FInvestment%20Analysis%2FInvestment%20Analysis%20Manual"
                                    target="_blank">Investment Analysis Manual</a></li>
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                    href="https://chevron.sharepoint.com/:w:/r/sites/CSSOrg/_layouts/15/Doc.aspx?sourcedoc=%7B6C180027-5490-48A7-979E-3FF5778BA163%7D&amp;file=DGO-36%20role%20definitions.docx&amp;wdLOR=c21D4A0B7-F715-4EF7-AB74-63C9C3507B07&amp;action=default&amp;mobileredirect=true"
                                    target="_blank">DGO-36 Role Definitions</a></li>
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                    href="https://chevron.sharepoint.com/:w:/r/sites/CSSOrg/_layouts/15/Doc.aspx?sourcedoc=%7BD5394289-B12D-4DF5-90E0-E604CE4539AD%7D&amp;file=DGO-36%20FAQs.docx&amp;wdLOR=c7E7066D3-03AC-4BF6-BBC2-C0DA72E9AE6A&amp;action=default&amp;mobileredirect=true"
                                    target="_blank">DGO-36 FAQs</a></li>
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"><span
                                        class="link-text">Sample Projects</span></a></li>
                            <li class="menu-hover"><a
                                    class="menu-hover vertical-align ie10-flex-start padding-5 padding-left-25"
                                    href="http://go36.chevron.com/ar/request/index/358" target="_blank">Upstream Example
                                    Project</a></li>
                            <li class="menu-hover"><a
                                    class="menu-hover vertical-align ie10-flex-start padding-5 padding-left-25"
                                    href="http://go36.chevron.com/ar/request/index/356" target="_blank">Non-Upstream
                                    Example Project</a></li>
                            <li class="menu-hover"><a class="menu-hover vertical-align ie10-flex-start padding-5"
                                    href="https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=9c08a21edb126450182e1dca48961981" target="_blank">Contact
                                    Support</a></li>
                        </ul>
                    </li>
                   
                </ul>
                <div role="dialog" tabindex="-1" aria-label="Utility Menu" id="navbar-hamburger-menu"
                    class="utility-nav dropdown">
                    <div class="dropdown-menu show-xs">
                        <div class="small-nav-container">
                            <div class="small-nav small-nav-corp-links">
                                <!-- visible-xs -->
                                <hr class="hr-mobile-nav visible-xs" />
                                <h3>quick menu</h3>
                                <ul class="list-unstyled icon-list">
                                    <li class="menu-hover">
                                        <a href="/">go-36 home</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="small-nav small-nav-corp-links small-nav-right">
                                <hr class="hr-mobile-nav" />
                                <ul class="list-unstyled  icon-list">
                                    <li class="menu-hover">
                                        <a href="https://inside.chevron.com/" target="_blank">inside chevron.com</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile-nav-backdrop visible-xs"></div>

        </div>
    </div>
</nav>

