import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})

export class ConfirmDialogComponent implements OnInit {
  title: string = '';
  message: string = '';

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    this.title = data.title;
    this.message = data.message;
    dialogRef.disableClose = true;
    setTimeout(() => {
      let element = document.querySelector(".mat-dialog-container");
      element?.setAttribute("style","padding: 0!important;border-radius: 6px!important;");
      let element2 = document.querySelector("html");
      element2?.setAttribute("style","top: 0!important;overflow:hidden!important;");
      let element3 = document.querySelector(".cdk-overlay-pane");
      element3?.setAttribute("style","max-width: 80vw;width: 600px;position: static;");
    },0);
  }

  ngOnInit(): void {
    // This will be used on initialization
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class ConfirmDialogModel {
  constructor(public title: string, public message: string) {
  }
}
