import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetService } from 'src/app/admin/service/asset.service';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UserAccess } from '../../asset.model';
import { UtilityService } from 'src/app/Shared/utility.service';

declare let $;

@Component({
  selector: 'app-useraccess-create',
  templateUrl: './useraccess-create.component.html',
  styleUrls: ['./useraccess-create.component.css']
})

export class UseraccessCreateComponent implements OnInit {
  OpcoList: any;
  sbuList: any;
  buList: any;
  opcofields: Object = {};
  RoleList: any;
  formValue!: UntypedFormGroup;
  userdata: UserAccess = new UserAccess();
  basicmemberSection: boolean = false;
  poweruserSection: boolean = false;
  adminSection: boolean = false;
  commonSelectionAdministrator = false;
  commonSelectionLeadership = false;
  commonSelectionConsultant = false;
  data: any;
  userExist: boolean = false;
  errorMessage: string = '';
  currentRole: any;
  public selecteddataToPost: any = [];
  userAccessDetails: any;
  userRole: string = '';
  emailId: string = '';
  objectId: string = '';
  provId: string = '';

  constructor(private formbuilder: UntypedFormBuilder,
    private apiService: AssetService,
    private projectservice: Go36projectService,
    private router: Router, private notifyService: NotificationService, private route: ActivatedRoute, private utilityService: UtilityService) {

    this.formValue = this.formbuilder.group({
      // // OpcoId :['', Validators.required],
      // // sbuId : ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      active: [true],
      roleId: [],
      buId: [],
      sbuId: [],
      OpcoId: []
    })
  }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.userExist = false;
    this.GetAllDropdowns();
    this.fillRolesDDL();
  }

  fillRolesDDL() {
    this.apiService.get("user/GetRoles").subscribe(res => {
      const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
      this.userAccessDetails = JSON.parse(userData);
      if (this.userAccessDetails?.data?.isPowerUser) {
        this.userRole = "Power User";
      }
      else if (this.userAccessDetails?.data?.isAdminUser) {
        this.userRole = "Administrator";
      }
      if (this.userRole == "Administrator") {
        res.splice(0, 1);
      }
      this.RoleList = res;
      this.formValue.controls['roleId'].setValue(res[0].roleId);
      this.onRoleChange(res[0].roleId);
    })
  }
  GetAllDropdowns() {
    this.apiService.get("user/GetAllDropdowns?objectId=" + this.objectId + "&provId=" + this.provId).subscribe(res => {
      this.OpcoList = res.opco;
    })
  }

  onRoleChange(roleId: any) {
    this.currentRole = roleId;
    this.basicmemberSection = false;
    this.poweruserSection = false;
    this.commonSelectionAdministrator = false;
    this.commonSelectionLeadership = false;
    this.commonSelectionConsultant = false;
    if (roleId == "8") {
      this.poweruserSection = true;
    }
    if (roleId == "12") {
      this.basicmemberSection = true;
    }
    if (roleId == "9") {
      this.commonSelectionAdministrator = true;
      setTimeout(() => { this.fillOpcoDDL(0, "#ddlOpcoAdministrator", "#ddlSbuBuAdministrator", "#ddlSbuBuHiddenAdministrator"); }, 1)
    }
    if (roleId == "10") {
      this.commonSelectionLeadership = true;
      setTimeout(() => { this.fillOpcoDDL(0, "#ddlOpcoLeadership", "#ddlSbuBuLeadership", "#ddlSbuBuHiddenLeadership"); }, 1)
    }
    if (roleId == "11") {
      this.commonSelectionConsultant = true;
      setTimeout(() => { this.fillOpcoDDL(0, "#ddlOpcoConsultant", "#ddlSbuBuConsultant", "#ddlSbuBuHiddenConsultant"); }, 1)
    }
  }



  fillBuDdl(sbuId: any) {
    this.apiService.get("BusinessUnit/GetBUByObjProv?objectId="+this.objectId+"&provId=" + this.provId).subscribe(res => {
      this.buList = res.filter(r => r.strategicBusinessUnitId == sbuId && r.operatingCompanyId == this.formValue.value.OpcoId);
      if (this.buList.length >= 1)
        this.formValue.controls['buId'].setValue(this.buList[0].id);
    })
  }

  fillSbuDDL(opcoId: any) {
    this.apiService.get("StrategicBU/GetSBUByObjProv?objectId="+this.objectId+"&provId=" + this.provId).subscribe(res => {
      this.sbuList = res.filter(r => r.operatingCompanyId == opcoId);
      if (this.sbuList.length >= 1)
        this.formValue.controls['sbuId'].setValue(this.sbuList[0].id);
      this.fillBuDdl(this.sbuList[0].id);
    })
  }

  postDetails() {
    if (this.currentRole == 12) {
      let orgDataToPost: any[] = [];
      orgDataToPost.push({
        opcoId: this.formValue.value.OpcoId,
        sbuId: this.formValue.value.sbuId,
        buId: this.formValue.value.buId
      });
      this.SetValues(orgDataToPost);
      if (this.formValue.value.OpcoId == undefined || this.formValue.value.sbuId == undefined || this.formValue.value.buId == undefined) {
        this.notifyService.showError("Segment, RU & BU field is required", "");
        return;
      }
    }
    if (this.selecteddataToPost.length == 0 && this.currentRole != 8) {
      this.notifyService.showError("Segment, RU & BU field is required", "");
      return;
    }
    this.userdata.Active = this.formValue.value.active;
    this.userdata.FirstName = this.formValue.value.firstname;
    this.userdata.LastName = this.formValue.value.lastname;
    this.userdata.CreatedBy = this.utilityService.GetuserProvisioningId();
    this.userdata.RoleId = this.formValue.value.roleId;
    this.userdata.Email = this.formValue.value.email;
    this.userdata.OrgData = this.selecteddataToPost;

    this.apiService.post("user", this.userdata).subscribe((res) => {
      if (!res.userExist) {
        let ref = document.getElementById('cancel');
        ref?.click();
        this.formValue.reset();
        this.router.navigateByUrl("/manage/useraccesses");
      }
      else {
        this.userExist = true;
        this.errorMessage = res.errorMessage;
      }
    });
  }

  fillOpcoDDL(OpcoId: any, ddlOpco, ddlSbuBu, ddlSbuBuHidden) {
    //clear previous html
    $(ddlOpco).empty();
    $(ddlSbuBu).empty();
    $(ddlSbuBuHidden).empty();
    const component = this;
    if (OpcoId == 0) {
      this.apiService.get("OperatingCompanies/GetOperatingCompanyByObjProv?objectId="+this.objectId+"&provId=" + this.provId).subscribe(res => {
        this.OpcoList = res;
        const select_options = {
          title: " ",
          data: this.GetOpcos(),
          closedArrow: '<i class="fa fa-caret-right" aria-hidden="true"></i>',
          openedArrow: '<i class="fa fa-caret-down" aria-hidden="true"></i>',
          maxHeight: 300,
          maxWidth: 98,
          multiSelect: true,
          selectChildren: true,
          clickHandler: function (element) {
          },
          checkHandler: function (element, checked) {
            let opcoValue = element.data("opcoid");
            let isOpcoChecked = checked.currentTarget.classList.contains("fa-check-square-o");
            let getSelectedOPCO = $(ddlOpco).GetSelected();
            if (getSelectedOPCO.length == 1) {
              $(ddlOpco).SetTitle(getSelectedOPCO[0].text());
            } else if (getSelectedOPCO.length > 1) {
              $(ddlOpco).SetTitle("Multiple Selected");
            } else {
              $(ddlOpco).SetTitle(" ");
            }
            component.GetSBU(opcoValue, isOpcoChecked, ddlOpco, ddlSbuBu, ddlSbuBuHidden);
          }
        }
        $(ddlOpco).DropDownTree(select_options);
        $(ddlSbuBu).DropDownTree({
          title: "",
          multiSelect: true,
          maxWidth: 98,
          selectChildren: true,
          clickHandler: function (element) { },
          checkHandler: function (element, checked) {
            let dataToPost: any = [];
            this.selecteddataToPost = [];
            let getSelectedSbu = $(ddlSbuBu + ">ul>li>ul>li> .fa-check-square-o")
            if (getSelectedSbu.length == 1) {
              $(ddlSbuBu).SetTitle($($(ddlSbuBu + ">ul>li>ul>li> .fa-check-square-o")).next().text());
            } else if (getSelectedSbu.length > 1) {
              $(ddlSbuBu).SetTitle("Multiple Selected");
            } else {
              $(ddlSbuBu).SetTitle(" ");
            }
            let selectedData = $(ddlSbuBu).GetSelected();
            for (let data of selectedData) {
              if (data.prevObject.length == 3) {
                let item = data.prevObject;
                dataToPost.push({
                  OpcoId: $(item[1]).data("opcoid"),
                  SbuId: $(item[1]).data("sbuid"),
                  BuId: $(item[0]).data("buid")
                });
                // // this.selecteddataToPost.push({ OpcoId: $(item[1]).data("opcoid"), SbuId: $(item[1]).data("sbuid"), BuId: $(item[0]).data("buid") });
              }
            }
            component.SetValues(dataToPost);
          }
        });
        $(ddlSbuBu + " button").prop('disabled', true).addClass("disable-button");
      })
    }
    else {
      this.formValue.controls['OpcoId'].setValue(OpcoId);
    }

    this.opcofields = { dataSource: this.OpcoList, text: "text", value: "value" };
  }

  GetOpcos() {
    const _arrOpco: any = [];
    $.each(this.OpcoList, function (i, businessUnit) {
      _arrOpco.push({ title: businessUnit.name, href: "#" + (i + 1), dataAttrs: [{ title: "opcoid", data: businessUnit.id }] });
    });
    return _arrOpco;
  };

  GetSBU(selectedOpco, isOpcoChecked, ddlOpco, ddlSbuBu, ddlSbuBuHidden) {
    const component = this;
    let _arrOpcoSbu: any = [];
    this.apiService.get("StrategicBU/GetSBUByObjProv?objectId="+this.objectId+"&provId=" + this.provId).subscribe(res => {
      this.sbuList = res.filter(r => r.operatingCompanyId == selectedOpco);
      this.apiService.get("BusinessUnit/GetBUByObjProv?objectId="+this.objectId+"&provId=" + this.provId).subscribe(res => {
        this.buList = res.filter(r => r.operatingCompanyId == selectedOpco);
        $.each(this.sbuList, (i, strategicBusinessUnit) => {
          _arrOpcoSbu.push(
            {
              title: strategicBusinessUnit.name,
              href: "#" + (i + 1),
              dataAttrs: [
                { title: "opcoid", data: selectedOpco },
                { title: "sbuid", data: strategicBusinessUnit.id }],
              data: component.GetBU(strategicBusinessUnit.id)
            });
        });
        let arrSbuBu = _arrOpcoSbu;
        if (!isOpcoChecked) {
          $.each(arrSbuBu, function (i, sbuData) {
            $(ddlSbuBu + " a").each(function (j, fdata) {
              if ($(fdata).text() == sbuData.title) {
                $(fdata).parent().remove();
                let getSelectedSbu = $(ddlSbuBu + ">ul>li>ul>li> .fa-check-square-o")
                if (getSelectedSbu.length == 1) {
                  $(ddlSbuBu).SetTitle($($(ddlSbuBu + ">ul>li>ul>li> .fa-check-square-o")).next().text());
                } else if (getSelectedSbu.length > 1) {
                  $(ddlSbuBu).SetTitle("Multiple Selected");
                } else {
                  $(ddlSbuBu).SetTitle("");
                }
                // Remove the Select All element
                if ($(ddlSbuBu).find("ul li").length == 1) {
                  $(ddlSbuBu).find("ul li").remove();
                  $(ddlSbuBu).SetTitle(" ");
                  $(ddlSbuBu + " button").prop('disabled', true).addClass("disable-button");
                }
              }
            });
          });
          return false;
        }
        let arrOpcoSbu1: any = [];
        if ($(ddlSbuBu).find("li").length == 0) {
          arrOpcoSbu1.push({ title: "All", href: "#", dataAttrs: [{ title: "sbuid", data: "All" }], data: arrSbuBu });
        }
        else {
          arrOpcoSbu1 = arrSbuBu;
        }
        if ($(ddlSbuBu).html.length > 0) {
          $(ddlSbuBuHidden).DropDownTree({
            data: arrOpcoSbu1,
            multiSelect: true,
            selectChildren: true,
          });
        }
        if ($(ddlSbuBu).find("ul").length == 1) {
          $(ddlSbuBu).find("ul").first().append($(ddlSbuBuHidden).find("ul").first().html());
          $(ddlSbuBuHidden).empty();
        }
        else if ($(ddlSbuBu).find("ul").length > 1) {
          $(ddlSbuBu).find("ul").eq(1).append($(ddlSbuBuHidden).find("ul").eq(0).html());
          $(ddlSbuBuHidden).empty();
        }
        if ($(ddlOpco + " i").hasClass("fa-check-square-o")) {
          $(ddlSbuBu + " button").prop('disabled', false).removeClass("disable-button");
          $(ddlSbuBu + " ul a i").first().addClass("fa-caret-down").removeClass("fa-caret-right");
        }
        $(ddlSbuBu).find("li ul").first().css("display", "block");
        $(ddlSbuBu).addClass("open");
        return true;
      });
    });
  }

  GetBU(selectedSbu) {
    let _arrSbuBu: any = [];
    let bunitsList = this.buList.filter(r => r.strategicBusinessUnitId == selectedSbu);
    $.each(bunitsList, function (i, businessUnit) {
      _arrSbuBu.push({ title: businessUnit.name, dataAttrs: [{ title: "buid", data: businessUnit.id }] });
    });
    return _arrSbuBu;
  }


  SetValues(dataToPost: any) {
    this.selecteddataToPost = dataToPost;
  }
}
