export class AppendicesModel {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn?: Date;
    modifiedOn?: Date;
    order: number = 0;
    formId: number = 0;
    phase: number = 0;
    sectionName?: string = '';
    fileContent?: Blob;
    fileName: string = '';
    fileContentType?: string = '';
    version: number = 0;
    isEditable: boolean = true;
    originalId: number = 0;
    versions: Versions[] = [];
}
export class Versions {
    id: number = 0;
    name: string = "";
    rowId: number = 0;
    fileName: string = "";
    dateUploaded?: Date;
}