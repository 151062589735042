export class BusinessCaseModel {
    id: number = 0;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn?: Date;
    modifiedOn?: Date;
    formId: number = 0;
    primaryBusinessCase?: string = "";
    competitiveness?: string = "";
    futureOpportunities?: string = '';
    naStatusCompetitiveness: boolean = false
    naStatusFutureopportunities: boolean = false
}