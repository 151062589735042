import { Injectable } from '@angular/core';
import { AppsettingsService } from '../../../Shared/appsettings.service'
import { HttpClient, HttpParams } from '@angular/common/http';
import { Go36ArP4LookbackHeaderModel } from 'src/app/Model/Part4LookBack/Part4LookBackHeader.model';
import { Go36P4LookbackDocument, Go36ArP4LookbackPerformance } from 'src/app/Model/Part4LookBack/Part4LookBack.model';

@Injectable({
  providedIn: 'root'
})

export class Part4LookbackService {
  GetPart4LookBacktUrl = 'api/LookbackPart4/GetPart4LookBack?formId=';
  CreatePart4LookBackUrl = 'api/LookbackPart4/CreatePart4LookBack';
  UpdatePart4LookBackUrl = 'api/LookbackPart4/UpdatePart4HeaderData';
  UpdatePart4LookBackPerformanceUrl='api/LookbackPart4/UpdatePart4PerformanceData';
  getPart4FormDataUrl='api/LookbackPart4/GetPart4FormData?formId=';
  deletePart4FormUrl='api/LookbackPart4/DeletePart4Form?formId=';
  UploadDocumentUrl = 'api/LookbackPart4/Upload';
  DownloadUrl = 'api/LookbackPart4/Download?id=';
  DeleteDocumentUrl = 'api/LookbackPart4/DeleteDocument';
  UpdateOnHoldPart4LookBackUrl = 'api/LookbackPart4/UpdateOnHoldPart4HeaderData';
  GetPart4PDFUrl = 'api/LookbackPart4/GetPart4PDF?projectId=';

  constructor(private http: HttpClient, private appsettings: AppsettingsService) { }

  GetPart4LookBack(formId: number, versionName: number, objectId: string, provId: string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetPart4LookBacktUrl + formId + '&versionName=' + versionName+ '&objectId=' + objectId+ '&provId=' + provId);
  }

  CreatePart4LookBack(projectId: number, objectId: string, provId: string) {
    const params = new HttpParams()
      .append('projectId', projectId)
      .append('objectId', objectId)
      .append('provId', provId)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.CreatePart4LookBackUrl, '', {
      params: params
    });
  }

  UpdatePart4LookBack(LookBack4Data: any) {
    return this.http.post<Go36ArP4LookbackHeaderModel[]>(this.appsettings.apiBaseUrl + this.UpdatePart4LookBackUrl, LookBack4Data);
  }

  saveDocument(p4LookbackId: number, provId: string, file: FormData) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UploadDocumentUrl + '?p4LookbackId=' + p4LookbackId + '&' + 'provId=' + provId, file);
  }

  downloadFile(id: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get<Go36P4LookbackDocument>(this.appsettings.apiBaseUrl + this.DownloadUrl + id, httpOptions);
  }

  DeleteDocument(document: any) {
    return this.http.post<Go36P4LookbackDocument[]>(this.appsettings.apiBaseUrl + this.DeleteDocumentUrl, document);
  }
  
  UpdatePart4LookBackPerformance(LookBack4PerformanceData: any) {
    return this.http.post<Go36ArP4LookbackPerformance[]>(this.appsettings.apiBaseUrl + this.UpdatePart4LookBackPerformanceUrl, LookBack4PerformanceData);
  }
  getPart4FormData(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getPart4FormDataUrl + formId);
  }

  deletePart4Form(formId: number,versionName: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.deletePart4FormUrl + formId + "&version="+versionName);
  }

  UpdateOnholdPart4LookBack(LookBack4Data: any) {
    return this.http.post<Go36ArP4LookbackHeaderModel[]>(this.appsettings.apiBaseUrl + this.UpdateOnHoldPart4LookBackUrl, LookBack4Data);
  }

  GetPart4PDF(projectId: number,formId:number,version:number, objectId: string, provId: string){
    const url = this.appsettings.apiBaseUrl + this.GetPart4PDFUrl + projectId+"&formId="+formId+"&version="+version+"&objectId="+objectId+"&provId="+provId;
    return this.http.get<any>(url, {responseType: 'blob' as 'json'});      
  }
}
