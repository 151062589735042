export class Overview {
    id: number = 0;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn: Date=new Date();
    modifiedOn: Date=new Date();
    formId: number = 0;
    location?: string = '';
    operatorName?: string = '';
    capitalAndExpenditure?: string = '';
    summary?: string = '';
    projectBackgroundAndDescription?: string = '';
    keyCommercialAndPartnerAgreements?: string = '';
    policyApplicable?: string = '';
    externalAnnouncementStatus?: string = '';
    ownershipChanges?: string = '';
    parentId: number = 0;
    greenHouseGasComment?: string = '';
    summaryNa: boolean = false;
    capAndExpNa: boolean = false;
    projectBackNa: boolean = false;
    keyComAndPartneNa: boolean = false;
    policyAppNa: boolean = false;
    externalAnnouncementNa: boolean = false;
    ownershipChangesNa: boolean = false;
    greenHouseNa: boolean = false;
    estimatedTotalCommit: number = 0;
    projectFundFlag: boolean = false;
    projectChanges?: string = '';
    operationalComment?: string = '';
    reservesFundFlag: boolean = false;
    ownershipsFlag: boolean = false;
    projectLastChangesFlag: boolean = false;

}