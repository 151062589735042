import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { AlternativeSelectionModel } from 'src/app/Model/AlternativeSelection.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';
import { AlternativeSelectionService } from '../../FEEDServices/alternative-selection.service';
import { ActivatedRoute } from '@angular/router';
import { Form } from 'src/app/Model/Form.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FeedNavService } from '../../FEEDServices/feed-nav.service';

@Component({
  selector: 'app-alternative-selection',
  templateUrl: './alternative-selection.component.html',
  styleUrls: ['./alternative-selection.component.css']
})

export class AlternativeSelectionComponent implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",

    toolbar: [
      // // 'fontfamily','fontsize','fontColor','fontBackgroundColor', '|',
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
    ],
    autosave: {
      save(editor) {
        // The saveData() function must return a promise
        // which should be resolved when the data is successfully saved.
      }
    }
  }
  @Input() isOnHold: boolean = false;
  ckeditorText: string = '';
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  formId: number = 0;
  alternateSelection = new AlternativeSelectionModel();
  formCase: string | null = '';
  @Input() form: Form = new Form();
  projectId: number = 0;
  isOptOut = false;
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;

  constructor(private alternativeSelectionService: AlternativeSelectionService,
    private go36ProjectService: Go36projectService,
    public dialog: MatDialog, private utilityService: UtilityService,
    private notifyService: NotificationService,
    private route: ActivatedRoute,
    private feednavService: FeedNavService,private utilityServcie: UtilityService) { }

  ngOnInit(): void {
    this.emailId = this.utilityServcie.GetuserEmailId();
    this.objectId = this.utilityServcie.GetuserObjectId();
    this.provId = this.utilityServcie.GetuserProvisioningId();
    this.formCase = (this.route.snapshot.paramMap.get("formCase"));
    if (this.formCase == 'Create') {
      this.formId = this.form.id;
      // //Number(this.route.snapshot.paramMap.get("formId"));
      this.projectId = this.form.projectId;
      // //Number(this.route.snapshot.paramMap.get("projectId"));
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          this.formId = this.form.id;
          // //params['formId'];
          this.projectId = this.form.projectId;
          // //params['projectId'];
        })
    }
    this.getAlternativeSelectionData();
  }

  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }

  onReady(editor, name) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.UpdateckeditorChanges(editor.getData(), name);
        }
      }
    });
  }

  getAlternativeSelectionData() {
    this.alternativeSelectionService.getData(this.formId).subscribe((res: AlternativeSelectionModel) => {
      this.alternateSelection = res;
      if (this.alternateSelection.id == 0) {
        let supplement = (this.form.formType == "FEED Supplement" || this.form.formType == "FID Supplement")
          ? true : false;

        let oldFormId = 0;
        if (this.formCase == 'Create') {
          oldFormId = Number(this.route.snapshot.paramMap.get("formId"));
        }
        else {
          this.route.queryParams
            .subscribe(params => {
              oldFormId = this.form.id;
            });
        }

        if (supplement) {
          this.alternativeSelectionService.copyData(supplement ? oldFormId : this.formId, this.provId, this.projectId, supplement).subscribe((res: any) => {
            this.alternateSelection = res;
          });
        }
      }
    });
  }

  UpdateckeditorChanges(event: string, textName: string) {
    this.ckeditorText = event;
    this.alternateSelection.createdBy = this.provId;
    this.alternateSelection.modifiedBy = this.provId;
    this.alternateSelection.formId = this.formId;

    if (textName == 'AlternativeDescription') {
      this.alternateSelection.alterativePreferredDescription = this.ckeditorText;
    } else if (textName == 'MFC') {
      this.alternateSelection.alternativeMinimumFunctionalityCase = this.ckeditorText;
    } else if (textName == 'PreferredAlternative') {
      this.alternateSelection.alternativeReferenceCase = this.ckeditorText;
    }
    else if (textName == 'Tradeoffs') {
      this.alternateSelection.tradeoffsAndConcept = this.ckeditorText;
    }
    else if (textName == 'WalkawayPoints') {
      this.alternateSelection.walkawayPoint = this.ckeditorText;
    }
    this.UpdateAlternativeSelectionData(this.alternateSelection);
  }

  UpdateAlternativeSelectionData(AltSelection: AlternativeSelectionModel) {
    this.alternativeSelectionService.postAlternativeSelectionData(AltSelection).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  NAAlternativeDescriptionEventCheck(event: MatSlideToggleChange, row: AlternativeSelectionModel) {
    row.modifiedBy = this.provId;
    row.naalternatDescription = event.checked;
    this.alternativeSelectionService.postAlternativeSelectionData(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  ShowAltDescModal() {
    const message = /*"For more information on framing and alternative generation, see section 2 in the "
      + '<a href="https://chevron.sharepoint.com/sites/CSP/Investment%20Analysis/IAM%20Rev%20Current%20Version.pdf" target="_blank">' + 'Investment Analysis Manual</a>'
      + "<br/> <br/>" + */"Reference case = for an existing asset, the operational plan that would yield the highest value if this investment proposal were not adopted. If no investment is not an option (e.g., compliance projects), the reference case should be selected based on the lowest investment required to support continued safe operations. For further detail on the reference case, see "
      + '<a href="https://chevron.sharepoint.com/sites/CSP/Investment%20Analysis/IAM%20Rev%20Current%20Version.pdf" target="_blank">' + 'Investment Analysis Manual</a> section 2.3.';
    const dialogData = new InfoDialogModel("Description of Reference Case", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowMFCModal() {
    //const message = "Minimum functionality case (MFC) = lowest capital/complexity that meets project objectives (e.g., capturing value, mitigating threats, etc.)."
    const message = "For more information on framing and alternative generation, see chapter 2 in the " + '<a href= "https://chevron.sharepoint.com/sites/CSP/Investment%20Analysis/IAM%20Rev%20Current%20Version.pdf" target="_blank"> Investment Analysis Manual</a>' 
      + '<p>Minimum functionality case (MFC) = the lowest capital/complexity that meets project essential objectives (e.g., capturing value, mitigating threats, etc.).</p>'
    const dialogData = new InfoDialogModel("Description of Minimum Funcitionality Case (MFC)", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowPreferredAltModal() {
    const message = '<p></p>' + '<h4 class="text-left">Preferred alternative:</h4>'
      + '<p></p><ul><li>' + 'Describe scope in the preferred alternative that is beyond the MFC and explain rationale for the additional scope using economic and non-economic terms. Why is this additional scope justified?</li>'
      + '\n<li>' + 'Describe the facility sizing philosophy assumed in the preferred alternative (early fill, accommodate peaks, gap fill later, etc.).</li>'
      + '\n<li>' + 'Describe the preferred alternative’s alignment on dimensions of strategic fit.</li>'
      + '\n<li>' + 'Explain how the project leverages existing design, experience, and infrastructure.</li>'
      + '\n<li>' + 'Describe insights from analysis</li>'
      + '\n<li>' + 'What portfolio considerations were evaluated in selecting the preferred alternative? From a portfolio standpoint, why go with preferred alternative instead of MFC?</li>'
      + "<br/>" + ""
      + '<p></p>' + '<h4 class="text-left">Tradeoff analysis:</h4>'
      + '\n<li>' + 'Describe economic and non-economic drivers used to differentiate between alternatives. What additional value is being created above and beyond the MFC?</li>'
      + '\n<li>' + 'What economic drivers were used to differentiate between alternatives and what insight did that generate? Include comparative economic charts illustrating tradeoffs between alternatives. Include insights from the Investment efficiency chart</li>'
      + '\n<li>' + 'What non-economic drivers were used to differentiate between alternatives and what insight did that generate? What methods were used to evaluate non-economic tradeoffs? Discuss tradeoffs made to protect against downside risk or pursue upside potential.</li>';

    const dialogData = new InfoDialogModel("Description of Preferred Alternative", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  onChange(value: MatSlideToggleChange) {
    this.isOptOut = value.checked;
    this.form.id = this.formId;
    this.form.modifiedBy = this.provId;
    this.form.optOutAlternativeSelection = this.isOptOut;
    this.form.projectId = this.projectId;
    this.feednavService.postOptOutAlternativeSelection(this.form).subscribe(res => {
    });
  }

  ShowOptOutModal() {
    const message = "If a discussion on alternative selection was included in a previous AR and there have been no significant changes, the project team can opt to exclude this section. When Opt Out is selected, this tab will be disabled."
    const dialogData = new InfoDialogModel("Opt Out", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowAltDescOldModal() {
    const message = "For more information on framing and alternative generation, see section 2 in the "
      + '<a href="https://chevron.sharepoint.com/sites/CSP/Investment%20Analysis/IAM%20Rev%20Current%20Version.pdf" target="_blank">' + 'Investment Analysis Manual</a>'
      + "<br/> <br/>" + "Reference case = for an existing asset, the operational plan that would yield the highest value if this investment proposal were not adopted. If no investment is not an option (e.g., compliance projects), the reference case should be selected based on the lowest investment required to support continued safe operations. For further detail on the reference case, see "
      + '<a href="https://chevron.sharepoint.com/sites/CSP/Investment%20Analysis/IAM%20Rev%20Current%20Version.pdf" target="_blank">' + 'Investment Analysis Manual</a> section 2.3.'
      + "<br/> <br/>" + "Minimum functionality case (MFC) = lowest capital/complexity that meets project objectives (e.g., capturing value, mitigating threats, etc.)."
      + "<br/> <br/>" + "For preferred alternative:"
      
      + '<p></p><ul><li>' + 'Describe scope in the preferred alternative that is beyond the MFC and explain rationale for the additional scope using economic and non-economic terms. Why is this additional scope justified?</li>'
      + '\n<li>' + 'Describe the facility sizing philosophy assumed in the preferred alternative (early fill, accommodate peaks, gap fill later, etc.).</li>'
      + '\n<li>' + 'Describe the preferred alternative’s alignment on dimensions of strategic fit.</li>'
      + '\n<li>' + 'Explain how the project leverages existing design, experience, and infrastructure.</li>'
      + '\n<li>' + 'Describe insights from analysis</li>'
      + '\n<li>' + 'Include investment efficiency chart.</li>'
      + '\n<li>' + 'What portfolio considerations were evaluated in selecting the preferred alternative? From a portfolio standpoint, why go with preferred alternative instead of MFC?</li>';

    const dialogData = new InfoDialogModel("Alternative Description", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowMFCOldModal() {
    const message = "Economic and non-economic drivers used to differentiate between alternatives; what additional value is being created above and beyond the MFC?"
    + "<br/> <br/>" + "Economic drivers: what economic drivers were used to differentiate between alternatives and what insight did that generate? Include comparative economic charts illustrating tradeoffs between alternatives."
    + "<br/> <br/>" + "Non-economic drivers: what non-economic drivers were used to differentiate between alternatives and what insight did that generate? What methods were used to evaluate non-economic tradeoffs? Discuss tradeoffs made to protect against downside risk or pursue upside potential."
    + "<br/> <br/>" + "How robust is the preferred alternative to changing conditions? What would cause the preferred alternative to change? In the context of key risks and uncertainties, how would a different outcome change your alternative? Knowledge of future conditions impacting selection of alternatives?"
    + "<br/> <br/>" + "What assumptions are critical to making the alternative preferred, and what aspects of the alternative would change if the assumptions changed? When is this not the preferred alternative?"
    + "<br/> <br/>" + "Is there something that you know would cause you to change alternatives? What are the signposts for those conditions?"
    + "<br/> <br/>" + "Resilience testing to demonstrate that the economics for a given scope/concept are robust under the “low” price trend (see "
    + '<a href="https://chevron.sharepoint.com/sites/CSP/Investment%20Analysis/IAM%20Rev%20Current%20Version.pdf" target="_blank">' + 'Investment Analysis Manual</a> section 5.7), and to understand upside potential under the “high” price trend.'
    + "<br/> <br/>" + "Include risk visualization matrix if used.";

    const dialogData = new InfoDialogModel("Tradeoffs and Concept Robustness", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowWalkawayPointsModal() {
    const message = "What uncertainties, risks, events, or exposures in terms of cost, value, business environment, strategic fit, etc., could materialize and drive a decision to walk away? When is this no longer a good idea? what would we need to be wrong about for this to not be an attractive/viable project? What needs to be true to operate this safely, reliably, profitably? What are we doing to preserve decision making freedom on the go/no-go decision?"
    + "<br/> <br/>" + "Exposure to value at risk (i.e., regrets). Explain the change in value at risk until FID (e.g., long lead items, engineering, contracting, etc.) Include potential offramps and residual value. How will value at risk be mitigated during Phase 3?";

    const dialogData = new InfoDialogModel("Walkaway Points and Value at Risk", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }
}