export class FormConfigurationModel {
    formId : number = 0;
    id: number = 0;
    startYear: number = 0;
    endYear: number = 0;
    DiscountYear: number = 0;
    FeedForwardYear: number = 0;
    PointForwardYear: number = 0;
    StartDiscountVector: number = 0;
    StartRealConversionVector: number = 0;
    afterTaxCashflowChartName: string = '';
    capitalAndExpenditureChartName: string = '';
    cumulativeATCashflowChartName: string = '';
    earningPerBarrelChartName: string = '';
    earningsChartName: string = '';
    nriReservesBookingsChartName: string = '';
    operatingCashMarginChartName: string = '';
    productionChartName: string = '';
    RoceAccretionDilutionChartName?: string = '';
    DefaultAfterTaxCashflowChartName?: string = '';
    DefaultCapitalAndExpenditureChartName?: string = '';
    DefaultCumulativeATCashflowChartName?: string = '';
    DefaultEarningPerBarrelChartName?: string = '';
    DefaultEarningsChartName?: string = '';
    DefaultNriReservesBookingsChartName?: string = '';
    DefaultOperatingCashMarginChartName?: string = '';
    DefaultProductionChartName?: string = '';
    DefaultRoceAccretionDilutionChartName?: string = '';

    showAfterTaxCashflowChart: boolean = false;
    showCapitalAndExpenditureChart: boolean = false;
    showCumulativeATCashflowChart: boolean = false;
    showEarningPerBarrelChart: boolean = false;
    showEarningsChart: boolean = false;
    showNriReservesBookingsChart: boolean = false;
    showOperatingCashMarginChart: boolean = false;
    showProductionChart: boolean = false;
    showRoceAccretionDilutionChart: boolean = false;

    nonUpstreamAfterTaxCashFlowChartName: string = '';
    nonUpstreamCapitalAndExpenditureChartName: string = '';
    nonUpstreamCumulativeCashChartName: string = '';
    nonUpstreamEarningsBeforeInterestChartName: string = '';
    nonUpstreamOperatingExpenseChartName: string = '';
    nonUpstreamOperationalEarningsAfterTaxChartName: string = '';
    nonUpstreamReturnOnCapitalEmployedChartName: string = '';
    nonUpstreamVolumeChartName: string = '';


//Upstream is named as Default charts
    DefaultNonUpstreamAfterTaxCashFlowChartName?: string = '';
    DefaultNonUpstreamCapitalAndExpenditureChartName?: string = '';
    DefaultNonUpstreamCumulativeCashChartName?: string = '';
    DefaultNonUpstreamEarningsBeforeInterestChartName?: string = '';
    DefaultNonUpstreamOperatingExpenseChartName?: string = '';
    DefaultNonUpstreamOperationalEarningsAfterTaxChartName?: string = '';
    DefaultNonUpstreamReturnOnCapitalEmployedChartName?: string = '';
    DefaultNonUpstreamVolumeChartName?: string = '';

    ShowNonUpstreamAfterTaxCashFlowChart: boolean = false;
    ShowNonUpstreamCapitalAndExpenditureChart: boolean = false;
    ShowNonUpstreamCumulativeCashChart: boolean = false;
    ShowNonUpstreamEarningsBeforeInterestChart: boolean = false;
    ShowNonUpstreamOperatingExpenseChart: boolean = false;
    ShowNonUpstreamOperationalEarningsAfterTaxChart: boolean = false;
    ShowNonUpstreamReturnOnCapitalEmployedChart: boolean = false;
    ShowNonUpstreamVolumeChart: boolean = false;
    GreenHouseGasTotalLabel: string = '';
    GreenHouseGasIntensityLabel: string = '';
    GreenHouseGasCostLabel: string = '';

    chartStartYear?: number = 0;
    chartEndYear?: number = 0;
    GreenHouseGasTotalGHGEmissionLabel: string = '';
    GreenHouseGasScope1EmissionLabel: string = '';
    GreenHouseGasScope2EmissionLabel: string = '';
    GreenHouseGasTotalCarbonIntensityLabel: string = '';
    GreenHouseGasMethaneIntensityLabel: string = '';
    GreenHouseGasFlaringIntensityLabel: string = '';
    PriceInputFile? :any;
    ProjectGeneralAndAdministrativeName?:string='';
    AdditionalOwnersCostsName?:string='';
    PriceInputFileName?:string='';
    PriceInputFileType?:string='';
    UnitOfMeasure?:string='';
    FacilitiesName?:string='';
    DrillingAndCompletionsName?:string='';
    ContingencyName?:string='';
    CapitalAndExpenditureExtraName?:string='';
    Form : Form = new Form();
}
export class Form
{
    formId : number = 0;
}

export class FormSettingConstant
{
  static readonly DefaultCapitalAndExpenditureChartName = 'Capex ($MM)';
    static readonly DefaultProductionChartName= 'Production (mboed) ';
    static readonly DefaultAfterTaxCashflowChartName= 'After-tax Cashflow ($MM)';
    static readonly DefaultEarningPerBarrelChartName= 'Earnings per Barrel ($/boe)';
    static readonly DefaultCumulativeATCashflowChartName= 'Cumulative AT Cashflow ($MM)';
    static readonly DefaultNriReservesBookingsChartName= 'NRI Reserves Bookings (mmboe)';
    static readonly DefaultEarningsChartName= 'Earnings After Tax ($MM)';
    static readonly DefaultOperatingCashMarginChartName= 'Operating Cash Margin per Barrel ($/boe)';


    static readonly DefaultNonUpstreamVolumeChartName= 'Volume';
    static readonly DefaultNonUpstreamOperatingExpenseChartName= 'OpEx';
  static readonly DefaultNonUpstreamCapitalAndExpenditureChartName = 'Capex ($MM)';
    static readonly DefaultNonUpstreamEarningsBeforeInterestChartName= 'EBITDA';
    static readonly DefaultNonUpstreamAfterTaxCashFlowChartName= 'After-tax Cashflow ($MM)';
    static readonly DefaultNonUpstreamOperationalEarningsAfterTaxChartName= 'OEAT';
    static readonly DefaultNonUpstreamReturnOnCapitalEmployedChartName= 'ROCE';
    static readonly DefaultNonUpstreamCumulativeCashChartName = 'Cumulative AT Cashflow ($MM)';
};

// byte[] PriceInputFile 
// string PriceInputFileName 
// string PriceInputFileType 
// string UnitOfMeasure 
// string FacilitiesName 
// string DrillingAndCompletionsName 
// string ContingencyName 
// string ProjectGeneralAndAdministrativeName 
// string AdditionalOwnersCostsName 
// string CapitalAndExpenditureExtraName 









