<div class="row fid-form font-gotham" style="background-color: white;">
    <div class="row sub-section">
        <div class="col-xs-12">
            <span [(ngModel)]="execution.id" style="display: none;"></span>
            <strong>
                6.1 Changes in Project Execution Plan including key contracting strategy, staffing and complexity impacting supplement</strong>            
            <span class="glyphicon glyphicon-question-sign" (click)="ShowGuidanceModal()"></span>
        </div>
        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <span>
                <ckeditor #projectExecutionPlan [editor]="Editor" [config]="config" id="projectExecutionPlan" name="projectExecutionPlan"
                [(ngModel)]="execution.projectExecutionPlan" (ready)="onReady($event,'ProjectExecutionPlan')"
                ></ckeditor>
            </span>
        </div>
    </div>
    <div class="row sub-section" *ngIf="form.formType =='FEED' || form.formType =='Phase 3 to 4 (FID)';else supplementsecondsection">
        <div class="col-xs-12">
            <span  style="display: none;"></span>
            <strong>6.2 Cost Estimate Overview and Basis:</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowCostEstimateOverviewModal()"></span>
        </div>
        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <table class="table table-bordered margin-bottom-0">
                <tbody>
                    <tr class="table-header">
                        <td class="bg-table-default">
                            <i>(Include cost and schedule sensitivity cumulative probability charts here.) If not attaching a CSRA or ESAR, please summarize here. Summarize highlights, including diverging co-owner views as applicable</i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <span>
                <ckeditor #costEstimate [editor]="Editor" [config]="config" id="costEstimate" name="costEstimate"
                    [(ngModel)]="execution.costEstimate"  (ready)="onReady($event,'CostEstimate')"
                     ></ckeditor>
            </span>
        </div>
    </div>
    <ng-template #supplementsecondsection>
        <div class="row sub-section">
            <div class="col-xs-12">
                <span  style="display: none;"></span>
                <strong>6.2 Cost Estimate Overview and Basis:</strong>
                <span class="glyphicon glyphicon-question-sign" (click)="ShowCostEstimateOverviewModal()"></span>
            </div>
            <div class="col-xs-12">
                Not applicable to supplement - covered in section 6.2
            </div>
        </div>
        <div class="row sub-section">
            <div class="col-xs-12">
              <strong>{{ (form.features?.isNewCEisApplicableForms || form.isNewCEisApplicableForms) ? "Capex": "C&E" }} Waterfall</strong>
            </div>
            <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
                <span>
                    <ckeditor #capitalAndExpendituresWaterfallImage [editor]="Editor" [config]="config" id="capitalAndExpendituresWaterfallImage" name="capitalAndExpendituresWaterfallImage"
                        [(ngModel)]="execution.capitalAndExpendituresWaterfallImage"  (ready)="onReady($event,'CapitalAndExpendituresWaterfallImage')"></ckeditor>
                </span>
            </div>
        </div>
    </ng-template>
    <div class="row sub-section" *ngIf="form.formType =='FEED' || form.formType =='Phase 3 to 4 (FID)';else supplementthirdsection">
            <div class="col-xs-12">
                <strong>6.3 Schedule Estimate and Basis</strong>
                <span class="glyphicon glyphicon-question-sign" (click)="ShowScheduleEstimateModal()"></span>
            </div>
            <div class="col-xs-12 col-sm-6">
                <table class="table table-bordered">
                    <tbody>
                        <tr class="bg-table-default table-header">
                            <td><strong>Phase Gate Milestones</strong></td>
                            <td style="text-align:center;">Date</td>
                            <td width="1%"></td>
                        </tr>
                        <tr *ngFor="let phasemile of phasemileStone let i = index">
                            <td>
                                <span>{{phasemile.milestoneName}}</span>                              
                            </td>
                            <td>
                                <input class="form-control" [matDatepicker]="picker" 
                                [(ngModel)]="phasemile.date" readonly [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"/>
                                <mat-datepicker #picker ></mat-datepicker>
                            </td>
                            <td>
                                <div [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!phasemile.comment"
                                        (click)="SaveCommentForPhaseMilestone(phasemile)"></i>
                                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="phasemile.comment"
                                        (click)="SaveCommentForPhaseMilestone(phasemile)"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-xs-12 col-sm-6">
                <div class="row">
                    <div class="col-xs-12">
                        <table class="table table-bordered">
                            <tbody>
                                <tr class="bg-table-default table-header">
                                    <td><strong>Execution Milestones</strong></td>
                                    <td style="text-align:center;">Date</td>
                                    <td width="1%"></td>
                                </tr>
                                <tr *ngFor="let execmile of executionMileStone let i = index">
                                    <td class="no-border-td" [(ngModel)]="execmile.id" hidden class="ml-10">{{execmile.id}}</td>
                                    <td class="no-border-td">
                                        <input type="text" class="form-control" [(ngModel)]="execmile.projectMilestoneName"
                                        (change)="UpdateExecutionMileStone(execmile)" [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"/>
                                    </td>
                                    <td class="no-border-td">
                                        <input class="form-control" [(ngModel)]="execmile.projectMilestoneDate" 
                                        (dateChange)="UpdateExecutionMileStone(execmile)" [matDatepicker]="picker"
                                        (click)="picker.open();utilityService.removeCDKAnouncer()"  [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                                        <mat-datepicker #picker></mat-datepicker>
                                    </td>
                                    <td [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel,'no-border-td':true}">
                                        <div>
                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!execmile.comment"
                                                (click)="SaveCommentForExecutionMilestone(execmile)"></i>
                                            <i class="glyphicon glyphicon-comment commentColor" *ngIf="execmile.comment"
                                                (click)="SaveCommentForExecutionMilestone(execmile)"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-xs-12">
                            <button type="button" (click)="addNewExecutionMilestone()" [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                             class="btn btn-sm btn-primary" style="color: white !important;">Add New Row</button>
                        </div>
                    </div>
                </div>
            </div> 
    </div>
    <ng-template #supplementthirdsection>
        <div class="row sub-section">
            <div class="col-xs-12">
                <span  style="display: none;"></span>
                <strong>6.3 Updated Schedule Estimate and Milestones</strong>
                <span class="glyphicon glyphicon-question-sign" (click)="ShowScheduleEstimateModal()"></span>
            </div>
            <div class="col-xs-12 col-sm-6">
                <table class="table table-bordered">
                    <tbody>
                        <tr class="bg-table-default table-header">
                            <td><strong>Phase Gate Milestones</strong></td>
                            <td style="text-align:center;">Supplement Date</td>
                            <td style="text-align:center;" *ngIf="form.order > 1 && form.formType =='FEED Supplement'">FEED Supplement #{{form.order -1}}</td>
                            <td style="text-align:center;" *ngIf="form.order > 1 && form.formType =='FID Supplement'">FID Supplement #{{form.order -1}}</td>
                            <td style="text-align:center;" *ngIf="form.formType =='FEED Supplement'">Original FEED AR Date</td>
                            <td style="text-align:center;" *ngIf="form.formType =='FID Supplement'">Original FID AR Date</td>
                            <td width="1%"></td>
                        </tr>
                        <tr *ngFor="let phasemile of phasemileStone let i = index">
                            <td>
                                <span>{{phasemile.milestoneName}}</span>                              
                            </td>
                            <td>
                                <input class="form-control" [matDatepicker]="picker" 
                                [(ngModel)]="phasemile.date" readonly [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"/>
                                <mat-datepicker #picker ></mat-datepicker>
                            </td>
                            <td *ngIf="form.order > 1">
                                <input class="form-control read-only-ar-dates" [matDatepicker]="suppicker" [(ngModel)]="phasemile.originalSuppArDate" readonly/>
                                <mat-datepicker #suppicker ></mat-datepicker>
                            </td>
                            <td>
                                <input class="form-control read-only-ar-dates" [matDatepicker]="arpicker" [(ngModel)]="phasemile.originalFeedArDate" readonly/>
                                <mat-datepicker #arpicker ></mat-datepicker>
                            </td>
                            <td>
                                <div [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                    <i class="glyphicon glyphicon-comment-outline" *ngIf="!phasemile.comment"
                                        (click)="SaveCommentForPhaseMilestone(phasemile)"></i>
                                    <i class="glyphicon glyphicon-comment commentColor" *ngIf="phasemile.comment"
                                        (click)="SaveCommentForPhaseMilestone(phasemile)"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-xs-12 col-sm-6">
                <div class="row">
                    <div class="col-xs-12">
                        <table class="table table-bordered">
                            <tbody>
                                <tr class="bg-table-default table-header">
                                    <td><strong>Execution Milestones</strong></td>
                                    <td style="text-align:center;">Supplement Date</td>
                                    <td style="text-align:center;" *ngIf="form.formType =='FEED Supplement'">Original FEED AR Date</td>
                                    <td style="text-align:center;" *ngIf="form.formType =='FID Supplement'">Original FID AR Date</td>
                                    <td width="1%"></td>
                                </tr>
                                <tr *ngFor="let execmile of executionMileStone let i = index">
                                    <td class="no-border-td" [(ngModel)]="execmile.id" hidden class="ml-10">{{execmile.id}}</td>
                                    <td class="no-border-td">
                                        <input type="text" class="form-control" [(ngModel)]="execmile.projectMilestoneName"
                                        (change)="UpdateExecutionMileStone(execmile)" [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"/>
                                    </td>
                                    <td class="no-border-td">
                                        <input class="form-control" [(ngModel)]="execmile.projectMilestoneDate" 
                                        (dateChange)="UpdateExecutionMileStone(execmile)" [matDatepicker]="picker"
                                        (click)="picker.open();utilityService.removeCDKAnouncer()"  [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                                        <mat-datepicker #picker></mat-datepicker>
                                    </td>
                                    <td>
                                        <input class="form-control read-only-ar-dates" [matDatepicker]="arpicker" [(ngModel)]="execmile.originalProjectMilestoneDate" readonly/>
                                        <mat-datepicker #arpicker ></mat-datepicker>
                                    </td>
                                    <td [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel,'no-border-td':true}">
                                        <div>
                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!execmile.comment"
                                                (click)="SaveCommentForExecutionMilestone(execmile)"></i>
                                            <i class="glyphicon glyphicon-comment commentColor" *ngIf="execmile.comment"
                                                (click)="SaveCommentForExecutionMilestone(execmile)"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-xs-12">
                            <button type="button" (click)="addNewExecutionMilestone()" [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel"
                             class="btn btn-sm btn-primary" style="color: white !important;">Add New Row</button>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </ng-template>
    <div class="row sub-section" *ngIf="form.formType =='FEED' || form.formType =='Phase 3 to 4 (FID)';else supplementfourthsection">
        <div class="col-xs-12">
            <span  style="display: none;"></span>
            <strong>6.4 Project Schedule or Gantt Chart. Include co-owner views as applicable</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowProjectScheduleModal()"></span>
        </div>
        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <span>
                <ckeditor #projectSchedule [editor]="Editor" [config]="config" id="projectSchedule" name="projectSchedule"
                [(ngModel)]="execution.projectSchedule" (ready)="onReady($event,'ProjectSchedule')"
                 ></ckeditor>
            </span>
        </div>
    </div>
    <ng-template #supplementfourthsection>
        <div class="row sub-section">
            <div class="col-xs-12">
                <span  style="display: none;"></span>
                <strong>6.4 Updated Project Schedule or Gantt Chart. Include co-owner views as applicable</strong>
                <span class="glyphicon glyphicon-question-sign" (click)="ShowProjectScheduleModal()"></span>
            </div>
            <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
                <span>
                    <ckeditor #projectSchedule [editor]="Editor" [config]="config" id="projectSchedule" name="projectSchedule"
                    [(ngModel)]="execution.projectSchedule" (ready)="onReady($event,'ProjectSchedule')"
                     ></ckeditor>
                </span>
            </div>
        </div>
        <div class="row sub-section">
            <div class="col-xs-12">
                <strong>Schedule Waterfall</strong>
            </div>
            <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
                <span>
                    <ckeditor #scheduleWaterFallImage [editor]="Editor" [config]="config" id="scheduleWaterFallImage" name="scheduleWaterFallImage"
                    [(ngModel)]="execution.scheduleWaterfallImage" (ready)="onReady($event,'ScheduleWaterFallImage')"
                     ></ckeditor>
                </span>
            </div>
        </div>
    </ng-template>
</div>
