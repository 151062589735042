export class EconomicModel {
    economicPerformanceId: number = 0;
    arId: number = 0;
    footNotes: string = "";
    cashFlowAR?: Date = new Date;
    npv: EconomicPerformanceFeature1 = new EconomicPerformanceFeature1();
    dpi: EconomicPerformanceFeature1 = new EconomicPerformanceFeature1();
    ror: EconomicPerformanceFeature1 = new EconomicPerformanceFeature1();
    ce: EconomicPerformanceFeature1 = new EconomicPerformanceFeature1();
    ceStartYear: number = 0;
    ceEndYear: number = 0;
    explorationWellSuccess: number = 0;
    explorationWellDevelopmentSuccess: number = 0;
    explorationWellDevelopment: number = 0;
    appraisalWellDevelopment: number = 0;
    modifiedBy: string = "";
}
export class EconomicPerformanceFeature1 
{
    riskCasePointForward: EconomicPerformanceFeature2 = new EconomicPerformanceFeature2();
    developCasePointForward: EconomicPerformanceFeature2 = new EconomicPerformanceFeature2(); 
    developCaseFullCycle: EconomicPerformanceFeature2 = new EconomicPerformanceFeature2();
}
export class EconomicPerformanceFeature2
{
    ev: any = null;
    p10: any = null;
    p90: any = null;
}