import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { BusinessCaseModel } from 'src/app/Model/BusinessCase.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';
import { BusinessCaseService } from '../../FEEDServices/business-case.service';
import { ActivatedRoute } from '@angular/router';
import { Form } from 'src/app/Model/Form.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FeedNavService } from '../../FEEDServices/feed-nav.service';

@Component({
  selector: 'app-business-case',
  templateUrl: './business-case.component.html',
  styleUrls: ['./business-case.component.css']
})

export class BusinessCaseComponent implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [
      // // 'fontfamily','fontsize','fontColor','fontBackgroundColor', '|',
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
    ],
    autosave: {
      save(editor) {
      }
    }
  }

  ckeditorText: string = '';
  businessCase = new BusinessCaseModel();
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  @Input() isOnHold: boolean = false;
  formId: number = 0;
  formCase: string | null = '';
  @Input() form: Form = new Form();
  projectId: number = 0;
  isOptOut = false;
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;

  constructor(private businessCaseService: BusinessCaseService,
    private go36ProjectService: Go36projectService,
    public dialog: MatDialog, private utilityService: UtilityService,
    private notifyService: NotificationService,
    private route: ActivatedRoute,
    private feednavService: FeedNavService) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formCase = (this.route.snapshot.paramMap.get("formCase"));
    if (this.formCase == 'Create') {
      this.formId = this.form.id;
      // //Number(this.route.snapshot.paramMap.get("formId"));
      this.projectId = this.form.projectId;
      // //Number(this.route.snapshot.paramMap.get("projectId"));
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          this.formId = this.form.id; // //params['formId'];
          this.projectId = this.form.projectId; // //params['projectId'];
        });
    }
    this.getBusinessCaseData();
  }

  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }

  onReady(editor, name) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.UpdateckeditorChanges(editor.getData(), name);
        }
      }
    });
  }

  getBusinessCaseData() {
    this.businessCaseService.getData(this.formId).subscribe((res: BusinessCaseModel) => {
      this.businessCase = res;
      if (this.businessCase.id == 0) {
        let supplement = (this.form.formType == "FEED Supplement" || this.form.formType == "FID Supplement")
          ? true : false;

        let oldFormId = 0;
        if (this.formCase == 'Create') {
          oldFormId = Number(this.route.snapshot.paramMap.get("formId"));
        }
        else {
          this.route.queryParams
            .subscribe(params => {
              oldFormId = this.form.id;
            });
        }
        if (supplement) {
          this.businessCaseService.copyData(supplement ? oldFormId : this.formId, this.provId, this.projectId, supplement).subscribe((res: any) => {
            this.businessCase = res;
          });
        }
      }
    });
  }

  UpdateckeditorChanges(event: string, textName: string) {
    this.ckeditorText = event;
    this.businessCase.createdBy = this.provId;
    this.businessCase.modifiedBy = this.provId;
    this.businessCase.formId = this.formId;

    if (textName == 'PrimaryBusinessCase') {
      this.businessCase.primaryBusinessCase = this.ckeditorText;
    } else if (textName == 'ExternalCompetitiveness') {
      this.businessCase.competitiveness = this.ckeditorText;
    } else if (textName == 'FutureOpportunities') {
      this.businessCase.futureOpportunities = this.ckeditorText;
    }
    this.UpdateBusinessCaseData(this.businessCase);
  }

  UpdateBusinessCaseData(businessCase: BusinessCaseModel) {
    this.businessCaseService.postBusinessCaseData(businessCase).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  NACompetitivenessEventCheck(event: MatSlideToggleChange, row: BusinessCaseModel) {
    row.modifiedBy = this.provId;
    row.naStatusCompetitiveness = event.checked;
    this.businessCaseService.postBusinessCaseData(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  NAFutureOpportunitiesEventCheck(event: MatSlideToggleChange, row: BusinessCaseModel) {
    row.modifiedBy = this.provId;
    row.naStatusFutureopportunities = event.checked;
    this.businessCaseService.postBusinessCaseData(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  ShowPrimaryBusinessCaseModal() {
    const message = "Describe the rationale for this project. Include regional and asset class competitiveness, diversification, compliance, government requirement, other goals, etc."
      + "<br/> <br/>" + "Certain regional and competitiveness data to be developed by Segment staff";
    const dialogData = new InfoDialogModel("Primary Business Case", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowCompetitivenessModal() {
    //const message = "Describe the external competitiveness of the opportunity based on publicly available benchmark data; include project-specific drivers or a justification to answer the question, “why is this so?”. Describe how the major risks identified in Section 6.1 (Risks) impact competitiveness. Work with Cost Engineering and Benchmarking for internal benchmarking and historical probability of NPV<0 data. Consult with PRC for use of Project Complexity tool. Strategic regional and competitiveness data to be developed by Segment planning staff."
    const message = "";
    const dialogData = new InfoDialogModel("External Competitiveness and Internal Benchmarking", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowfutureOpportunitiesModal() {
    const message = "Does the project include elements of pre-investment for future opportunities or establish a market presence that can be leveraged later? Is this part of a program of investment, or a staged project? Does it help to derisk or enable other opportunities?"
      + "<br/> <br/>" + "If the investment facilitates a hub or base facility that will enable future higher return investments, please describe those benefits.";
    const dialogData = new InfoDialogModel("Future opportunities enabled by this investment", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  onChange(value: MatSlideToggleChange) {
    this.isOptOut = value.checked;

    this.form.id = this.formId;
    this.form.modifiedBy = this.provId;
    this.form.optOutBusinessCase = this.isOptOut;
    this.form.projectId = this.projectId;

    this.feednavService.postOptOutBusinessCase(this.form).subscribe(res => {
    });
  }

  ShowOptOutModal() {
    const message = "If a discussion on alternative selection was included in a previous AR and there have been no significant changes, the project team can opt to exclude this section. When Opt Out is selected, this tab will be disabled."
    const dialogData = new InfoDialogModel("Opt Out", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }
}