import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/Shared/notification.service';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.css']
})

export class CommentDialogComponent implements OnInit {
  title: string = '';
  comment: string = '';
  isOnHold:boolean = false;
  isReadOnly:boolean = false;
  public Comment: EventEmitter<any> = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<CommentDialogModel>,
    @Inject(MAT_DIALOG_DATA) public data: CommentDialogModel,
    private notifyService: NotificationService) {
    this.title = data.title;
    this.comment = data.comment;
    this.isOnHold = data.isOnHold;
    this.isReadOnly = data.isReadOnly;
    dialogRef.disableClose = true;
    setTimeout(() => {
      let element = document.querySelector(".mat-dialog-container");
      element?.setAttribute("style","padding: 0!important;border-radius: 6px!important;");
      let element2 = document.querySelector("html");
      element2?.setAttribute("style","top: 0!important;");
    },0);
  }

  ngOnInit(): void {
    // This will be used on initialization
  }
  
  onSave(): void {
    // // if (this.comment == undefined) {
    // //   this.notifyService.showWarning("Comment should not be empty...!", "");
    // // }
    // // else {
      this.dialogRef.close(true);
      this.Comment.emit(this.comment);
    // //}
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class CommentDialogModel {
  constructor(public title: string, public comment: string, public isOnHold: boolean, public isReadOnly: boolean) {
  }
}
