import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppsettingsService } from '../../../Shared/appsettings.service';
import { SupplementDocumentModel } from 'src/app/Model/SupplementDocument.model';
import { SupplementPart1Model } from 'src/app/Model/SupplementPart1.model';
@Injectable({
  providedIn: 'root'
})
export class EaSupplementService {
  CreateRescopeUrl = "api/supplementPart1/CreateEaRescopePart1";
  GetSupplementPart1DataUrl = "api/supplementPart1/GetSupplementPart1Data?supplementId=";
  GetSupplementDocumentsUrl= "api/supplementPart1/GetSupplemenDocuments?formId=";
  UploadDocumentUrl = "api/supplementPart1/Upload";
  DownloadUrl = "api/supplementPart1/Download?id=";
  DeleteDocumentUrl = "api/supplementPart1/DeleteDocument";
  UpdatePart1DataUrl = "api/supplementPart1/UpdateEaSupplementPart1Data";
  UpdateCommentsUrl = "api/supplementPart1/UpdateComments";
  postIsOnHoldValueUrl= "api/supplementPart1/SaveIsOnHoldValue";
  GetPart2DataUrl= "api/supplementPart2/GetSupplementPart2Data?supplementId=";
  UpdatePart2OperationDataUrl = "api/supplementPart2/UpdateEaPart2OperationData";
  UpdatePart2EconomicDataUrl= "api/supplementPart2/UpdateEaPart2EconomicData";
  UpdatePart2ExecutionDataUrl= "api/supplementPart2/UpdateEaPart2ExecutionData";
  UpdatePart2BusinessDataUrl= "api/supplementPart2/UpdateEaPart2BusinessData";
  UpdatePart2DesignCommercialDataUrl= "api/supplementPart2/UpdateEaPart2DesignCommercialData";
  UpdatePart2ExecutionTargetAssuranceDataUrl= "api/supplementPart2/UpdateEaPart2ExecutionTargetAssuranceData";
  deleteFormUrl= "api/supplementPart1/DeleteForm?supplementId=";
  CreateSupplementUrl = "api/supplementPart1/CreateEaSupplement";
  GetUserAccessListForEAProjectURL = "api/UserAccess/GetUserAccessListForEAProjects?paramProjectId=";
  GetUserAccessListForEASuppAndRescope = "api/UserAccess/GetUserAccessListForEASupplementAndRescope?paramProjectId=";
  
  constructor(private http: HttpClient,private appsettings: AppsettingsService) { }

  CreateEaNewRescope(projectId: number,originalId: number,version: number, provId: string) {
        const params = new HttpParams()
            .append('projectId', projectId)
            .append('originalId', originalId)
            .append('version', version)
            .append('provId', provId)
        return this.http.post<any>(this.appsettings.apiBaseUrl + this.CreateRescopeUrl, '', {
            params: params
        });
  }
  GetPart1Data(supplementId: number, versionName: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetSupplementPart1DataUrl + supplementId+ '&version=' + versionName);
  }
  GetDocuments(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetSupplementDocumentsUrl + formId);
  }
  saveDocument(formId: number, provId: string, file: FormData) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UploadDocumentUrl + '?formId=' + formId + '&' + 'provId=' + provId, file);
  }
  downloadFile(id: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get<SupplementDocumentModel>(this.appsettings.apiBaseUrl + this.DownloadUrl + id, httpOptions);
  }
  DeleteDocument(document: any) {
    return this.http.post<SupplementDocumentModel[]>(this.appsettings.apiBaseUrl + this.DeleteDocumentUrl, document);
  } 
  UpdatePart1Data(Part1Data: any) {
    return this.http.post<SupplementPart1Model[]>(this.appsettings.apiBaseUrl + this.UpdatePart1DataUrl, Part1Data);
  }
  UpdateComments(go36EaComment : any) {
    return this.http.post<SupplementPart1Model[]>(this.appsettings.apiBaseUrl + this.UpdateCommentsUrl, go36EaComment);
  }
  postIsOnHoldValue(Part1Model: SupplementPart1Model) {
    return this.http.post<SupplementPart1Model>(this.appsettings.apiBaseUrl + this.postIsOnHoldValueUrl, Part1Model);
  }
  GetPart2Data(supplementId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetPart2DataUrl + supplementId);
  }
  UpdatePart2OperationData(OperationModel: any) {
    return this.http.post<any[]>(this.appsettings.apiBaseUrl + this.UpdatePart2OperationDataUrl, OperationModel);
  }
  UpdatePart2EconomicData(EconomicModel: any) {
    return this.http.post<any[]>(this.appsettings.apiBaseUrl + this.UpdatePart2EconomicDataUrl, EconomicModel);
  }
  UpdatePart2ExecutionData(ExecutionModel: any) {
    return this.http.post<any[]>(this.appsettings.apiBaseUrl + this.UpdatePart2ExecutionDataUrl, ExecutionModel);
  }
  UpdatePart2BusinessData(BusinessModel: any) {
    return this.http.post<any[]>(this.appsettings.apiBaseUrl + this.UpdatePart2BusinessDataUrl, BusinessModel);
  }
  UpdatePart2DesignCommercialData(DesignCommercialModel: any) {
    return this.http.post<any[]>(this.appsettings.apiBaseUrl + this.UpdatePart2DesignCommercialDataUrl, DesignCommercialModel);
  }
  UpdatePart2ExecutionTargetAssuranceData(ExecutionTargetAssuranceModel: any) {
    return this.http.post<any[]>(this.appsettings.apiBaseUrl + this.UpdatePart2ExecutionTargetAssuranceDataUrl, ExecutionTargetAssuranceModel);
  }
  deleteForm(supplementId: number,versionName: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.deleteFormUrl + supplementId + "&version="+versionName);
  }
  CreateEaNewSupplement(projectId: number, provId: string,createNew: boolean) {
    const params = new HttpParams()
        .append('projectId', projectId)
        .append('provId', provId)
        .append('createNew', createNew)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.CreateSupplementUrl, '', {
        params: params
    });
}

GetUserAccessListForEAProject(paramProjectId: string, paramOriginalId : string, paramVersion:string, userRole: string, objectId : string, provId : string) {
  return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetUserAccessListForEAProjectURL + paramProjectId + "&paramOriginalId="+paramOriginalId + "&paramVersion="+paramVersion + "&userRole="+userRole + "&objectId="+objectId+ "&provId="+provId);
}


GetUserAccessListForEASupplementAndRescope(paramProjectId: string, paramOriginalId : string, paramVersion:string, userRole: string, objectId : string, provId : string){
  return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetUserAccessListForEASuppAndRescope + paramProjectId + "&paramOriginalId="+paramOriginalId + "&paramVersion="+paramVersion + "&userRole="+userRole + "&objectId="+objectId+ "&provId="+provId);
}

}
