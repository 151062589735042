export class FormComment {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn: Date=new Date();
    modifiedOn: Date=new Date();
    order: number = 0;
    formId: number = 0;
    sectionId: number = 0;
    formFieldId: number = 0;
    comments: string = '';
    columnId: number = 0;
}