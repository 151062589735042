<div class="box-container box-shadow" id="page-body">

  <div class="row">
    <div class="col text-right">
      <a class="btn btn-sm btn-success" style="color:white !important;" routerLink="/manage/useraccesses/create"
        [state]="{editMode:false}">New User
      </a> &nbsp;
      <span>
        <a class="btn btn-sm btn btn-success" href="#" style="color:white !important;"
            (click)="exportToExcel()">Export</a>
      </span> &nbsp;&nbsp;&nbsp;
    </div>
    
  </div>
  <h3> Users</h3>
  <div class="row" style="text-align: right">
    <div class="col-xs-2"></div>
    <label class="control-label col-xs-1" for="OpcoId">Segment</label>
    <div class="col-xs-2">
      <!-- <select formControlName="OpcoId" class="form-control" (change) = "fillSbuDDL($any($event.target).value)">
                    <option *ngFor="let opco of OpcoList" [value]="opco.id">
                        {{opco.name}}</option>
                </select> -->
      <!-- <mat-select class="form-control" multiple>
        <mat-option *ngFor="let opco of OpcoList" [value]="opco.id">
          {{opco.name}}
        </mat-option>
      </mat-select> -->

      <!-- <input id="OpcoId" ej-dropdownlist [dataSource]="OpcoList" [fields]="opcofields" /> -->
      <div class="dropdown dropdown-tree" id="ddlOpco"></div>
    </div>
    <label class="control-label col-xs-1" for="sbuId">RU & BU </label>
    <div class="col-xs-2">
      <div class="dropdown dropdown-tree" id="ddlSbuBu"></div>
      <div class="dropdown dropdown-tree" id="ddlSbuBuHidden" style="display:none"></div>
    </div>
    <div class="col-xs-1 padding-0 text-left">
      <button type="submit" class="btn btn-primary" style="color:white !important;" (click)="OnSearch()">Search</button>
      <input type="hidden" id="h1" name="UserOrgDetails" value="">
    </div>
    <div class="col-xs-2 padding-0 text-left">
      <a class="btn btn-mm btn-primary" (click)="OnReset()">Reset</a>
    </div>
  </div>
  <br>
  <!-- <div class="row" style="text-align: center; color: #5cb85c; font-weight:bold" *ngIf="isDisplaytotalCount"> Displaying
    {{displaytotalCount}} entries , kindly click on reset to refresh the filter </div> -->
  <br>
  <div class="row">
    <div class="col-sm-12">
      <p-table #dt1 [value]="Data" dataKey="id" selectionMode="single"
        [globalFilterFields]="['emailId','name','roleDescription','createdBy','createdDate','Active','global']"
       [paginator]="true" stateStorage="session" stateKey="statedemo-session10"
        [rowsPerPageOptions]="[formDataLength, 10, 20, 50, 100]" (onPage)="onShowEntryDropdownChange($event)" 
        [rows]="formDataLength" [totalRecords]="displaytotalCount"  paginatorPosition="both" styleClass="p-datatable-projects p-datatable-sm" #TABLE>
        <ng-template pTemplate="caption">
          <span><b>Show Entries</b></span>
          <div class="flex" style="float:right;margin-bottom: 1%;">
            <span class="p-input-icon-left ml-auto">
              <b>Search: </b><input #searchText pInputText type="text"
                (input)="dt1.filterGlobal(searchText.value, 'contains')"
                [value]="dt1.filters['global']? $any(dt1.filters['global'])?.value :''" />
            </span>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr style="background-color: lightskyblue;height: 40px;">
            <th style="width:126px"></th>
            <th pSortableColumn="emailId" style="width: 116px;">
              Email <p-sortIcon field="emailId"></p-sortIcon>
            </th>
            <th pSortableColumn="name" style="width: 116px;">
              Name <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="roleDescription" style="width: 116px;">
              Global Role <p-sortIcon field="roleDescription"></p-sortIcon>
            </th>
            <th pSortableColumn="createdBy" style="width: 116px;">
              Created By <p-sortIcon field="createdBy"></p-sortIcon>
            </th>
            <th pSortableColumn="createdDate" style="width: 116px;">
              Created Date <p-sortIcon field="createdDate"></p-sortIcon>
            </th>
            <th pSortableColumn="active" style="width: 61px;">
              Active <p-sortIcon field="active"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th style=" background-color:#efefef !important;"></th>
            <th style="height:6rem; background-color:#efefef !important;width: 126px;">
              <input style="max-width:9em" pInputText type="text" #searchEmail
                (input)="dt1.filter(searchEmail.value,'emailId', 'contains')"
                [value]="dt1.filters['emailId']? $any(dt1.filters['emailId'])?.value :''" placeholder="Search..."
                class="form-control" />
            </th>

            <th style=" background-color:#efefef !important;width:116px;">
              <input style="max-width:9em" pInputText type="text" #searchName
                (input)="dt1.filter(searchName.value,'name', 'contains')"
                [value]="dt1.filters['name']? $any(dt1.filters['name'])?.value :''" placeholder="Search..."
                class="form-control" />
            </th>

            <th style=" background-color:#efefef !important;width:116px;">
              <input style="max-width:9em" pInputText type="text" #searchRole
                (input)="dt1.filter(searchRole.value,'roleDescription', 'contains')"
                [value]="dt1.filters['roleDescription']? $any(dt1.filters['roleDescription'])?.value :''"
                placeholder="Search..." class="form-control" />
            </th>


            <th style=" background-color:#efefef !important;width:116px;">
              <input pInputText style="max-width:9em" type="text" #searchCreatedby
                (input)="dt1.filter(searchCreatedby.value,'createdBy', 'contains')"
                [value]="dt1.filters['createdBy']? $any(dt1.filters['createdBy'])?.value :''" placeholder="Search..."
                class="form-control" />
            </th>
            <th style=" background-color:#efefef !important;width:116px;">
              <input pInputText style="max-width:9em" type="text" #searchCreatedDate
                (input)="dt1.filter(searchCreatedDate.value,'createdDate', 'contains')" placeholder="Search..."
                [value]="dt1.filters['createdDate']? $any(dt1.filters['createdDate'])?.value :''"
                class="form-control" />
            </th>
            <th style=" background-color:#efefef !important;width:61px;">
              <input pInputText style="max-width:5em" type="text" #searchActive
                (input)="dt1.filter(searchActive.value,'active', 'contains')"
                [value]="dt1.filters['active']? $any(dt1.filters['active'])?.value :''" placeholder="Search..."
                class="form-control" />
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-asset>
          <tr>
            <td>
              <button class="btn btn-sm btn-primary" routerLink="/manage/useraccesses/viewdetail"
                style="color:white !important;" [queryParams]="{userId: asset.userId}">View</button>
              <button class="btn btn-sm btn btn-warning" routerLink="/manage/useraccesses/update"
                style="color:white !important;" [queryParams]="{userId: asset.userId}">Edit</button>
              <button class="btn btn-sm btn btn-danger" style="color:white !important;"
                (click)="Delete(asset.userId)">Delete</button>
            </td>
            <td class="text-bold" style="padding-left: 10px"><strong>{{asset.emailId}}</strong></td>
            <td class="text-bold" style="padding-left: 10px"><strong>{{asset.name}}</strong></td>
            <td class="text-bold" style="padding-left: 10px"><strong>{{asset.roleDescription}}</strong></td>
            <td class="text-bold" style="padding-left: 10px">{{asset.createdBy}}</td>
            <td class="text-bold" style="padding-left: 10px">{{asset.createdDate| date: 'MM/dd/yyyy' }}</td>
            <td class="text-bold" style="padding-left: 10px">{{asset.active}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorleft" let-state>
          Showing {{(state.first) + 1}} to {{(state.totalRecords >
            (state.rows * (state.page + 1))) ? state.rows * (state.page + 1) : state.totalRecords }}
            of {{state.totalRecords}} entries
        </ng-template>
        <!-- <ng-template pTemplate="paginatorleft">
          <button class="btn btn-sm btn btn-success" style="color:white !important;"
              (click)="ExportUserList()">Export</button>
        </ng-template> -->
      </p-table>
    </div>
  </div>
  <!-- <div>
  
  <h5 mat-dialog-title>Delete User</h5>
  <div mat-dialog-content>
    <p>Are you sure you want to delete the user?</p>
  </div>
  
  <div mat-dialog-actions style="float: right;">
    <button mat-button (click)="on
    Dismiss()" class="btn btn-default"> No </button> &nbsp;&nbsp;
    <button mat-raised-button class="btn btn-primary" (click)="onConfirm()">Yes</button>
  </div>
</div> -->