import { FormComment } from "../FormComment.model";
import { ChartSeries } from "./ChartSeries.model";
import{FormChart} from "./FormChart.model"
export class ChartModel 
{
    lowFeedForward: ChartSeries = new ChartSeries;
    highFeedForward: ChartSeries = new ChartSeries;
    midFeedForward: ChartSeries = new ChartSeries;
    originalMidFeedForward :ChartSeries=new ChartSeries;
    isVisible: boolean = false;
    midFullCycle:ChartSeries=new ChartSeries;
    originalMidFullCycle:ChartSeries=new ChartSeries;
}
export class UpstreamChartModel
{
    capitalAndExpenditureChart: ChartModel = new ChartModel();
    productionChart:ChartModel=new ChartModel();
    afterCashFlowChart:ChartModel=new ChartModel();
    earningsPerBarrelChart:ChartModel=new ChartModel();
    cumulativeATCashflowChart:ChartModel=new ChartModel();
    nriReservesBookingChart:ChartModel=new ChartModel();
    earningsChart:ChartModel=new ChartModel();
    operatingCashMarginChart:ChartModel=new ChartModel();
    formchart:FormChart=new FormChart();
    formConfiguration:any;
    formComment:FormComment[]=[];
    isUpstream:boolean=true;
}

