import { ChartSeries } from "./ChartSeries.model";
export class ChartModel {
  totalGHGEmission: ChartSeries = new ChartSeries;
  carbonCost: ChartSeries = new ChartSeries;
  isVisible: boolean = false;
}
export class MethaneModel {
  methaneIntensity: ChartSeries = new ChartSeries;
  flaringIntensity: ChartSeries = new ChartSeries;
  totalIntensity: ChartSeries = new ChartSeries;
  isVisible: boolean = false;
}

export class EmissionAndCarbonIntensityChartModel {
  emission: ChartSeries = new ChartSeries;
  carbonIntensity: ChartSeries = new ChartSeries;
  isVisible: boolean = false;
}

export class CarbonCostChartModel {
  carbonCost: ChartSeries = new ChartSeries;
  isVisible: boolean = false;
}

export class OverviewChartModel {
  formConfiguration: any;
  totalGHGEmissionAndCarbonCostChart: ChartModel = new ChartModel();
  methaneAndFlaringAndTotalIntensityChart = new MethaneModel();
  emissionAndCarbonIntensityChart = new EmissionAndCarbonIntensityChartModel();
  carbonCostChart = new CarbonCostChartModel();
}
