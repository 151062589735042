import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { SsbodService } from '../../service/ssbod.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-attach-ssbod-form-dialog',
  templateUrl: './attach-ssbod-form-dialog.component.html',
  styleUrls: ['./attach-ssbod-form-dialog.component.css']
})
export class AttachSsbodFormDialogComponent implements OnInit {
  SsbodArchivedVersions:any[] = [];
  formType: any = "";
  projectId: number = 0;
  versionName: string = '';
  selectedVersion:  number = 1;
  provId: string = '';
  btnAttach: string = 'Attach';
  fidId: number = 0;
  formId: number = 0;
  @Output() changeSelectedTab = new EventEmitter;  
  constructor(public dialogRef: MatDialogRef<AttachSsbodFormDialogModel>, private router: Router, 
    private route: ActivatedRoute,private ssbodService: SsbodService,
    private go36ProjectService: Go36projectService, public utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: AttachSsbodFormDialogModel) {
    dialogRef.disableClose = true;
   }
  ngOnInit(): void {
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formType = this.data.formType;
    this.projectId = this.data.projectId;
    this.fidId = this.data.fidId;
    this.formId = (this.data.formId == null) ? 0: this.data.formId;
    this.GetArchivedVersions();
  }
  onDismiss(): void {
    this.dialogRef.close();
  }
  GetArchivedVersions(){
      this.ssbodService.GetArchivedVersions(this.formType,this.projectId).subscribe(res => {
        this.SsbodArchivedVersions = res;
        if(this.SsbodArchivedVersions.length == 0){
          this.selectedVersion = 0;
        }
        if(this.formId > 0){
          this.SsbodArchivedVersions.forEach(item => {
            if(item.Id == this.formId)
              this.selectedVersion = item.Version;
          })
          this.SsbodArchivedVersions.forEach(item => {
            if(item.Version == this.selectedVersion)
              this.formId = item.Id;
          })
        }
        else{
          this.selectedVersion = this.SsbodArchivedVersions[0].Version;
          this.formId = this.SsbodArchivedVersions[0].Id;
        }
      });
  }
  onArchivedVersionChange(event: any){
    this.selectedVersion = event.target.value;
    this.SsbodArchivedVersions.forEach(item => {
      if(item.Version == this.selectedVersion){
        this.formId = item.Id;
      }
    })
  }
  onSsbodImport(){
    this.btnAttach = "Attaching SSBOD! Please wait...";
    this.ssbodService.LinkSsbod(this.fidId, this.formId, this.formType,this.provId).subscribe(res => {
      this.btnAttach = "Attach";      
      this.onDismiss();
      this.changeSelectedTab.emit(this.formId);  
    });
  }
}
export class AttachSsbodFormDialogModel {
  
  constructor(public fidId: number,public formId: any,public formType: string,public projectId: number) {

  }

}