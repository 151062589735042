import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Form } from 'src/app/Model/Form.model';
import { SsbodConstants } from '../../constants/SsbodConstants';
import { SsbodArchiveDialogComponent } from '../../modal/ssbod-archive-dialog/ssbod-archive-dialog.component';
import { SubsurfaceViewModel } from '../../Model/subsurfaceView.model';
import { SsbodService } from '../../service/ssbod.service';
import { Go36projectService } from '../../../go36project/service/go36project.service';
import { environment } from 'src/environments/environment';
import { ArchiveDialogComponent } from 'src/app/Modals/archive-dialog/archive-dialog.component';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-ssbod-nav',
  templateUrl: './ssbod-nav.component.html',
  styleUrls: ['./ssbod-nav.component.css']
})
export class SsbodNavComponent implements OnInit {
  isOnHold = false;
  objectId: string = '';
  provId: string = '';
  emailId: string = '';
  formId: number = 0;
  projectId: number = 0;
  form = new Form();
  projectName: string = '';
  myParam: string = '';
  formCase: string | null = '';
  versionName: number = 0;
  constReference = SsbodConstants.Reference;
  constTotal = SsbodConstants.Total;
  constIncremental = SsbodConstants.Incremental;
  constMoc = SsbodConstants.Moc;
  constLL = SsbodConstants.LL;
  SsbodVersionsDropdown: any[] = [];
  viewModel: SubsurfaceViewModel = new SubsurfaceViewModel();
  @Input() parentData: any;
  selectedTabIndex: number = 0;
  selected = new UntypedFormControl(0);
  userAccessDetails: any;
  userRole: string = '';
  ssbodAccessList: any;
  ssbodProjectId: number = 0;
  @Output() refreshAfterSsbodLink = new EventEmitter;
  baseUrl: any = environment.apiBaseUrl;
  pdfUrl: any = "";
  constructor(public dialog: MatDialog, private ssbodService: SsbodService, 
    private route: ActivatedRoute, private go36ProjectService: Go36projectService,public utilityService: UtilityService) { }

  ngOnInit(): void {
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.emailId = this.utilityService.GetuserEmailId();
    if (this.parentData != undefined && this.parentData.ssbodId != null) {
      this.formId = this.parentData.ssbodId;
      this.getSubsurfaceData();
    } else if (this.parentData == undefined) {
      this.route.queryParams
        .subscribe(params => {
          this.formId = params['formId'];
        })
      this.getSubsurfaceData();
    }
  }
  OpenArchiveDialog() {
    if(this.viewModel.hasNewSSbodArchiveFeature){
      this.dialog.open(SsbodArchiveDialogComponent, {
        width: "600px",
        data: { formId: this.formId, oldVersionId: this.versionName },
      });
    }else{
      this.dialog.open(ArchiveDialogComponent, {
        width: "600px",
        data: { formId: this.formId, oldVersionId: this.versionName, formType: "SSBOD_OLDSTYLE_ARCHIVE"},
      });
    }
  }
  getSubsurfaceData() {
    this.ssbodService.getSubsurfaceData(this.formId).subscribe((res: SubsurfaceViewModel) => {
      this.viewModel = res;
      let buName = res.BusinessUnits.filter(r => r.Id == res.BusinessUnitId)[0].Name;
      this.projectName = buName + ' - ' + ' ' + res.ProjectName + ' - ' + '(version #' + res.Version + ')';

      this.ssbodProjectId = this.viewModel.SsbodProjectId;
      this.pdfUrl = `${this.baseUrl}api/Ssbod/CreateSSBODPdf?formId=${this.formId}&ssbodProjectId=${this.ssbodProjectId}`;
      if (res) {
        this.GetUserRoleFromLocalStorage();
        let map = new Map<string, string>();
        map.set("objectId", this.objectId);
        map.set("provId", this.provId);
        map.set("emailId", this.emailId);
        map.set("projectId", this.viewModel.SsbodProjectId.toString());
        map.set("originalId", this.viewModel.OriginalId.toString());
        map.set("formVersion", String(this.viewModel.Version));
        map.set("formVersionName", String(this.viewModel.VersionName));
        map.set("formIsReadOnly", String(this.viewModel.IsReadOnly));
        map.set("formCreatedBy", this.viewModel.CreatedBy);
        map.set("userRole", this.userRole);

        let jsonObject = {};
        map.forEach((value, key) => { jsonObject[key] = value; });
        this.ssbodService.GetUserAccessListForSSBOD(JSON.stringify(jsonObject)).subscribe((res: any) => {
          if (res) { this.ssbodAccessList = res; }
        });
      }
    })
  }
  changeSelectedTab($event: any) {
    this.selectedTabIndex = 0;
    this.selected.setValue(this.selectedTabIndex);
    this.formId = $event;
    this.parentData.ssbodId = $event;
    this.getSubsurfaceData();
    this.refreshAfterSsbodLink.emit();
  }
  GetUserRoleFromLocalStorage() {
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);

    // we can create the constants of the users role
    if (this.userAccessDetails?.data?.isPowerUser) {
      this.userRole = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      this.userRole = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      this.userRole = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      this.userRole = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      this.userRole = "Leadership";
    }
  }

  // // clickEventPDF(){
  // //   this.ssbodService.getPDF(this.formId, this.ssbodProjectId).subscribe((response)=>{
  // //   let file = new Blob([response], { type: 'application/pdf' });            
  // //   let fileURL = URL.createObjectURL(file);
  // //   window.open(fileURL);
  // // })
  // // }

}