<app-popup-active-users></app-popup-active-users>
<div class="box-container">
    <div class="row">
        <div class="col">
            <h3>{{selectedPhaseHeader}}</h3>
            <div class="row text-center">
                <a class="no-underline" [routerLink]="['/go36project/viewform']" [queryParams]="{projectId: projectId}">
                    <h5 style="padding: 0; display:inline;font-size: 14px;">{{projectName}}</h5>
                </a>
            </div>
            <h5 *ngIf="readOnly" class="text-danger margin-0 padding-0" style="font-size: 14px;">
                (Previous Version {{versionText}} - Read-Only)</h5>
        </div>
    </div>

    <!-- <div class="row" style="margin-bottom:5%">
        <button type="button" style="float:right;color: white !important;" class="text-right btn btn-sm btn-success btn-dark">Save</button>
    </div> -->
    <div class="row" style="margin-top: 25px;">
        <app-lessons-learned *ngIf="selectedPhase > 0 && form.id > 0" [isOnHold]="false" [readOnly]="form.isReadOnly" (changeHeader)="changeHeader($event)" (changeQueryData)="changeQueryData($event)" [originalId]='form.id' [passedSelectedPhase]="selectedPhase" [versionOptionText]="form.versionName" [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked'  [isOldVersionOfForms]="form.IsOldVersionOfForms">
        </app-lessons-learned>
    </div>
    <div class="row">
        <div class="col-xs-12 padding-0">
            <hr>
        </div>
    </div>
    <div class="row" style="margin-top: 15px;">
        <div class="col-xs-12 padding-0">
            <div class="row">
                <div class="form-group col-sm-6 col-xs-12 text-left padding-0 ">
                    <button type="button" style="color: white !important;" class="btn btn-sm btn-primary" [routerLink]="['/go36project/viewform']" [queryParams]="{projectId: projectId}">Close</button>
                    <button *ngIf="!readOnly" type="button" style="color: white !important;" class="btn btn-sm btn-danger" [routerLink]="['/forms/lessonlearned/delete']" 
                    [queryParams]="{projectId: projectId,formId: formToDeleteId,versionName: versionName, formType: form.formType}" [disabled]="!this.userAccessList?.data?.canDeleteForm">Delete</button>
                </div>
                <div class="form-group col-sm-6 col-xs-12 text-right padding-0">
                    <!-- <button type="button" style="color: white !important;" class="btn btn-sm btn-primary btn-dark" (click)="ExportPDF()" >PDF</button> -->
                    <a [href]="pdfUrl" class="btn btn-sm btn-primary btn-dark" target="_blank" style="color: white !important;margin-right: 0.5em;margin-bottom: 0.5em;">PDF</a>
                    <button *ngIf="!readOnly" type="button" style="color: white !important;" class="btn btn-sm btn-success btn-dark" (click)="OpenArchiveDialog()" [disabled]="!this.userAccessList?.data?.canArchive">Archive</button>
                </div>
            </div>
        </div>
    </div>
</div>
