import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-ar',
  templateUrl: './new-ar.component.html',
  styleUrls: ['./new-ar.component.css']
})

export class NewArComponent implements OnInit {  
  ngOnInit(): void {
    // This is not implemented
  }
}