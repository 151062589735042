import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppsettingsService } from '../../../Shared/appsettings.service'
import { EAPart3Model } from 'src/app/Model/EAPart3.model';
import { EaCommentModel } from 'src/app/Model/EaComment.model';

@Injectable({
  providedIn: 'root'
})

export class ArPart3Service {

  constructor(private http: HttpClient,
    private appsettings: AppsettingsService) { }

  CreatePart3Url = "api/part3/CreateNewARPart3?formId=";
  GetPart3DataUrl = "api/part3/GetPart3Data?formId=";
  UpdatePart3Url = 'api/part3/UpdatePart3Data';
  UpdateCommentsUrl = "api/part1/UpdateComments";
  archiveEANewARUrl = "api/part1/ArchiveEANewAR"
  archiveEASupplementUrl = "api/supplementPart1/ArchiveEASupplement"
  createDefaultData(arId: number, provId: string, version: number) {
    const params = new HttpParams()
      .append('arId', arId)
      .append('provId', provId)
      .append('version', version)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.CreatePart3Url, '', {
      params: params
    });
  }

  GetPart3Data(formId: number, version: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetPart3DataUrl + formId + '&version=' + version);
  }

  UpdatePart3DataService(part3Data: any) {
    return this.http.post<EAPart3Model[]>(this.appsettings.apiBaseUrl + this.UpdatePart3Url, part3Data);
  }

  UpdateComments(go36EaComment: any) {
    return this.http.post<EaCommentModel[]>(this.appsettings.apiBaseUrl + this.UpdateCommentsUrl, go36EaComment);
  }
  ArchiveEANewAR(formId: number, oldVersionId: number, versionName: string, objectId: string, provId: string) {
    const params = new HttpParams()
      .append('formId', formId)
      .append('oldVersionId', oldVersionId)
      .append('versionName', versionName)
      .append('objectId', objectId)
      .append('provId', provId)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.archiveEANewARUrl, '', {
      params: params
    });
  }
  ArchiveEASupplement(formId: number, oldVersionId: number, versionName: string, objectId: string, provId: string) {
    const params = new HttpParams()
      .append('formId', formId)
      .append('oldVersionId', oldVersionId)
      .append('versionName', versionName)
      .append('objectId', objectId)
      .append('provId', provId)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.archiveEASupplementUrl, '', {
      params: params
    });
  }
}
