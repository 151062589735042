import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AssetService } from 'src/app/admin/service/asset.service';
import { AssetModel } from 'src/app/admin/asset/asset.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-operatingcompany-create',
  templateUrl: './operatingcompany-create.component.html',
  styleUrls: ['./operatingcompany-create.component.css']
})

export class OperatingcompanyCreateComponent implements OnInit 
{
  formValue!: UntypedFormGroup
  assetData: AssetModel = new AssetModel();
  showAdd!: boolean;
  showUpdate!: boolean;
  data:any;
  editMode: boolean = false;
  assetId: number=0;
  constructor(private formbuilder: UntypedFormBuilder,
    private apiService: AssetService, 
    private router: Router,
    private projectservice: Go36projectService,
    private route: ActivatedRoute, private utilityServcie: UtilityService)  {
    this.formValue = this.formbuilder.group({
        name: ['', Validators.required],
        active: ['']
      })
  }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {    
      this.assetId = Number(params['Id']);
      if(this.assetId > 0)
      this.editMode = true;
    });
    if (this.editMode) 
    {
      this.apiService.getById("OperatingCompanies/GetOpcoDetailsById?Id=",this.assetId).subscribe(res => {
      this.formValue.controls["name"].setValue(res.name);
      this.formValue.controls["active"].setValue(res.active);
      this.assetData.id=res.id;
      this.data = res;
    });
  }
}

  postDetails() {
    this.assetData.active = true;
    this.assetData.name = this.formValue.value.name;
    this.assetData.createdBy = this.utilityServcie.GetuserProvisioningId();
    this.apiService.post("operatingcompanies",this.assetData).subscribe((res) => {
      let ref = document.getElementById('cancel');
      ref?.click();
      this.formValue.reset();
      this.router.navigateByUrl("/manage/operatingcompanies");
    });
  }

  updateAssetDetails() {
    this.assetData.active = this.formValue.value.active;
    this.assetData.name = this.formValue.value.name;    
    this.assetData.modifiedBy = this.utilityServcie.GetuserProvisioningId();
    this.apiService.update("operatingcompanies",this.assetData.id, this.assetData).subscribe(res => {
      let ref = document.getElementById('cancel');
      ref?.click();
      this.formValue.reset();
      this.router.navigateByUrl("/manage/operatingcompanies");
    });
  }
}
