import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { CommentDialogComponent, CommentDialogModel } from 'src/app/Modals/comment-dialog/comment-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/Modals/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent, InfoDialogModel } from 'src/app/Modals/info-dialog/info-dialog.component';
import { Form } from 'src/app/Model/Form.model';
import { EconomicSensitivitiesModel, FormRisksModel, RisksModel, RisksViewModel } from 'src/app/Model/Risks.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';
import { RisksService } from '../../FEEDServices/risks.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-risks',
  templateUrl: './risks.component.html',
  styleUrls: ['./risks.component.css']
})

export class RisksComponent implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [
      // 'fontfamily','fontsize','fontColor','fontBackgroundColor', '|',
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
    ],
    autosave: {
      save(editor) {

      }
    }
  }

  riskViewModel = new RisksViewModel();
  ckeditorText: string = '';
  risks: RisksModel[] = [];
  formRisks = new FormRisksModel();
  economicSensitivity: EconomicSensitivitiesModel[] = [];
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  @Input() isOnHold: boolean = false;
  formId: number = 0;
  formCase: string | null = '';
  NPV: EconomicSensitivitiesModel[] = [];
  DPI: EconomicSensitivitiesModel[] = [];
  ROR: EconomicSensitivitiesModel[] = [];
  projectId: number = 0;
  @Input() form: Form = new Form();
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;

  constructor(private riskService: RisksService,
    private go36ProjectService: Go36projectService,
    public dialog: MatDialog, private utilityService: UtilityService,
    private notifyService: NotificationService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formCase = (this.route.snapshot.paramMap.get("formCase"));

    if (this.formCase == 'Create') {
      this.formId = this.form.id;// //Number(this.route.snapshot.paramMap.get("formId"));
      this.projectId = this.form.projectId;// //Number(this.route.snapshot.paramMap.get("projectId"));
    }
    else {
      this.route.queryParams
        .subscribe(params => {
          this.formId = this.form.id;// //params['formId'];
          this.projectId = this.form.projectId;// //params['projectId'];
        });
    }
    this.getRisksData();
  }

  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }

  onReady(editor, name) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          this.UpdateckeditorChanges(editor.getData(), name);
        }
      }
    });
  }

  getRisksData() {
    this.riskService.getData(this.formId).subscribe((res: RisksViewModel) => {
      this.riskViewModel = res;
      this.risks = res.risks;

      this.formRisks = res.formRisks;
      if (this.formRisks != null) {
        this.SetFormRisksData();
      }

      this.economicSensitivity = res.economicSensitivity;
      if (this.economicSensitivity.length > 0) {
        this.SetEconomicSensitivityData();
      }

      if (this.risks.length == 0) {
        this.riskService.createDefaultData(this.formId, this.provId, this.projectId, false).subscribe((res: any) => {
          this.risks = res.risks;
          this.economicSensitivity = res.economicSensitivity;
          this.formRisks = res.formRisks;

          if (this.formRisks != null) {
            this.SetFormRisksData();
          }

          if (this.economicSensitivity.length > 0) {
            this.SetEconomicSensitivityData();
          }
        });
      }
    });
    setTimeout(() => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    }, 3000);
  }

  SetEconomicSensitivityData() {
    this.NPV = this.economicSensitivity.filter(x => x.economicSensitivityType == 4);
    if (this.NPV.length > 0) {
      this.NPV[0].title = 'Low Price';
      this.NPV[1].title = 'Mid Price';
      this.NPV[2].title = 'High Price';
    }
    this.NPV.forEach(obj => {
      if (obj.lowPrice != null) {
        obj.lowPrice = Number(obj.lowPrice).toFixed(1).toString();
      }
      if (obj.midPrice != null) {
        obj.midPrice = Number(obj.midPrice).toFixed(1).toString();
      }
      if (obj.highPrice != null) {
        obj.highPrice = Number(obj.highPrice).toFixed(1).toString();
      }
    });

    this.DPI = this.economicSensitivity.filter(x => x.economicSensitivityType == 5);
    this.DPI.forEach(obj => {
      if (obj.lowPrice != null) {
        obj.lowPrice = Number(obj.lowPrice).toFixed(2).toString();
      }
      if (obj.midPrice != null) {
        obj.midPrice = Number(obj.midPrice).toFixed(2).toString();
      }
      if (obj.highPrice != null) {
        obj.highPrice = Number(obj.highPrice).toFixed(2).toString();
      }
    });

    this.ROR = this.economicSensitivity.filter(x => x.economicSensitivityType == 6);
    this.ROR.forEach(obj => {
      if (obj.lowPrice != null) {
        obj.lowPrice = Number(obj.lowPrice).toFixed(1).toString();
        obj.lowPrice = obj.lowPrice + '%';
      }
      if (obj.midPrice != null) {
        obj.midPrice = Number(obj.midPrice).toFixed(1).toString();
        obj.midPrice = obj.midPrice + '%';
      }
      if (obj.highPrice != null) {
        obj.highPrice = Number(obj.highPrice).toFixed(1).toString();
        obj.highPrice = obj.highPrice + '%';
      }
    });
  }

  SetFormRisksData() {
    if (this.formRisks.npvFullCycle != null) {
      this.formRisks.npvFullCycle = Number(this.formRisks.npvFullCycle).toFixed(1).toString();
      this.formRisks.npvFullCycle = this.formRisks.npvFullCycle + '%';

      this.formRisks.flatRealPointForward = Number(this.formRisks.flatRealPointForward).toFixed(1).toString();
      this.formRisks.flatRealFeedForward = Number(this.formRisks.flatRealFeedForward).toFixed(1).toString();
      this.formRisks.remainingCostIncrease = Number(this.formRisks.remainingCostIncrease).toFixed(1).toString();

      this.formRisks.netPresentValueSensitivityLow = Number(this.formRisks.netPresentValueSensitivityLow).toFixed(1).toString();
      this.formRisks.netPresentValueSensitivityMid = Number(this.formRisks.netPresentValueSensitivityMid).toFixed(1).toString();
      this.formRisks.netPresentValueSensitivityHigh = Number(this.formRisks.netPresentValueSensitivityHigh).toFixed(1).toString();

      // // this.formRisks.flatRealPointForward = this.NumberWithCommas(this.formRisks.flatRealPointForward);
      // // this.formRisks.flatRealFeedForward = this.NumberWithCommas(this.formRisks.flatRealFeedForward);
      // // this.formRisks.flatRealPointForward = '$'+String(this.formRisks.flatRealPointForward);
      // // this.formRisks.flatRealFeedForward = '$'+String(this.formRisks.flatRealFeedForward);
    }
  }

  UpdateckeditorChanges(event: string, textName: string) {
    this.ckeditorText = event;
    this.formRisks.createdBy = this.provId;
    this.formRisks.modifiedBy = this.provId;
    this.formRisks.formId = this.formId;

    if (textName == 'netPresentValueScurveImage') {
      this.formRisks.netPresentValueScurveImage = this.ckeditorText;
    } else if (textName == 'netPresentValueTornadoImage') {
      this.formRisks.netPresentValueTornadoImage = this.ckeditorText;
    } else if (textName == 'discountedProfitabilityIndexScurveImage') {
      this.formRisks.discountedProfitabilityIndexScurveImage = this.ckeditorText;
    }
    else if (textName == 'riskUncertainty') {
      this.formRisks.riskUncertainty = this.ckeditorText;
    }
    else if (textName == 'noveltyRisk') {
      this.formRisks.noveltyRisk = this.ckeditorText;
    }
    this.UpdateFormRisksData(this.formRisks);
  }

  UpdateFormRisksData(formRisks: FormRisksModel) {
    formRisks.modifiedBy = this.provId;

    // // formRisks.flatRealPointForward = formRisks.flatRealPointForward.replace('$', '');
    // // formRisks.flatRealFeedForward = formRisks.flatRealFeedForward.replace('$', '');
    // // formRisks.flatRealPointForward = formRisks.flatRealPointForward.replaceAll(',', '');
    // // formRisks.flatRealFeedForward = formRisks.flatRealFeedForward.replaceAll(',', '');

    formRisks.npvFullCycle = formRisks.npvFullCycle == ''
      || formRisks.npvFullCycle == null ? '' : parseFloat(formRisks.npvFullCycle);
    formRisks.flatRealPointForward = formRisks.flatRealPointForward == ''
      || formRisks.flatRealPointForward == null ? '' : parseFloat(formRisks.flatRealPointForward);
    formRisks.flatRealFeedForward = formRisks.flatRealFeedForward == ''
      || formRisks.flatRealFeedForward == null ? '' : parseFloat(formRisks.flatRealFeedForward);
    formRisks.remainingCostIncrease = formRisks.remainingCostIncrease == ''
      || formRisks.remainingCostIncrease == null ? '' : parseFloat(formRisks.remainingCostIncrease);

    formRisks.netPresentValueSensitivityLow = Number(formRisks.netPresentValueSensitivityLow).toFixed(1).toString();
    formRisks.netPresentValueSensitivityMid = Number(formRisks.netPresentValueSensitivityMid).toFixed(1).toString();
    formRisks.netPresentValueSensitivityHigh = Number(formRisks.netPresentValueSensitivityHigh).toFixed(1).toString();

    this.riskService.postFormRiskData(formRisks).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    formRisks.npvFullCycle = Number(formRisks.npvFullCycle).toFixed(1).toString();
    formRisks.flatRealPointForward = Number(formRisks.flatRealPointForward).toFixed(1).toString();
    formRisks.flatRealFeedForward = Number(formRisks.flatRealFeedForward).toFixed(1).toString();
    formRisks.remainingCostIncrease = Number(formRisks.remainingCostIncrease).toFixed(1).toString();
    formRisks.npvFullCycle = formRisks.npvFullCycle + '%';

    // // formRisks.flatRealPointForward = this.NumberWithCommas(formRisks.flatRealPointForward);
    // // formRisks.flatRealFeedForward = this.NumberWithCommas(formRisks.flatRealFeedForward);
    // // formRisks.flatRealPointForward = '$'+String(formRisks.flatRealPointForward);
    // // formRisks.flatRealFeedForward = '$'+String(formRisks.flatRealFeedForward);
  }

  UpdateRisksData(risk: RisksModel) {
    risk.modifiedBy = this.provId;
    this.riskService.postRisksData(risk).subscribe((res: RisksModel[]) => {
      this.risks = res;
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  ShowInsightsModal() {
    const message = "Discuss insights and implications of probabilistic analysis. Highlight key value and risk drivers, relative upside and downside risk, sensitivity to probability on high impact uncertainties, etc. Discuss tradeoffs or disconnects between decision drivers."
    const dialogData = new InfoDialogModel("Insights of Economic Risk Analysis", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowNoveltyRiskModal() {
    //const message = "This is a new section designed to highlight what’s novel about this opportunity. Consider OC, technology, scope (scale), and geography. Provide historical context based on Chevron and external data to demonstrate whether/how this project is a step-out in any of these dimensions. Leverage PRC cost engineering to provide historical framework using the Project Complexity tool."
    const message = "<p>This is a new section designed to highlight what’s novel about this opportunity. Consider OC, technology, scope (scale), and geography. Provide historical context based on Chevron and external data to demonstrate whether/how this project is a step-out in any of these dimensions. Leverage Capital Projects cost engineering to provide historical framework using the Project Complexity tool.</p>"
    const dialogData = new InfoDialogModel("Novelty Risk", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowEcoSensitivityModal() {
    const message = "Illustrate the impact of the largest uncertainties on FEED forward Project Economic Returns: These matrices indicate how your opportunity performs with changes in price and the other top uncertainty from the tornado. Impacts are calculated on a point-forward basis. Include images of your NPV tornado plot, NPV S-curve in the relevant sections, noting that the data import template includes an image sizing guide which produces good printing results. Enter the name of your top non-price-related uncertainty from the tornado in the ‘NPV sensitivity’ box, and the P10, P50, & P90 values in the first row of the ‘NPV sensitivity’ table. All other uncertainties should be kept at their P50 values for these calculations. The grey-highlighted P50 values are automatically calculated based upon your imported data, but you will need to provide the P10 and P90 values for NPV, DPI and ROR. Capture all changes to economics that have occurred since concept select. If EUR is n/a, highlight the other most significant uncertainty. For supplement, provide updated economic sensitivity."
      + "<br/> <br/>" + "NPV tornado should use the Deterministic mid case";

    const dialogData = new InfoDialogModel("Economic Sensitivity", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowRisksModal() {
    const message = "Include all identified major risk items and any potential mitigations, "
      + '<b>that have not already been covered in other sections.</b>'
      + " Consider risks around foreign exchange exposure, labor, OC, OE, subsurface, project execution, new technology, regulatory, permitting, geopolitical, cultural, commercial, NOJV co-owner alignment, other stakeholders, greenhouse gas, commodity pricing, other business environmental effects, key assumptions that are critical to business success, etc. For supplement, provide updated risk assessment."
      + "<br/> <br/>" + "GHG exposure is included by default.";

    const dialogData = new InfoDialogModel("Economic Sensitivity", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  NANoveltyRiskEventCheck(event: MatSlideToggleChange, formRisks: FormRisksModel) {
    formRisks.modifiedBy = this.provId;
    formRisks.nanoveltyRisk = event.checked;

    // // formRisks.flatRealPointForward = formRisks.flatRealPointForward.replace('$', '');
    // // formRisks.flatRealFeedForward = formRisks.flatRealFeedForward.replace('$', '');
    // // formRisks.flatRealPointForward = formRisks.flatRealPointForward.replaceAll(',', '');
    // // formRisks.flatRealFeedForward = formRisks.flatRealFeedForward.replaceAll(',', '');

    formRisks.npvFullCycle = formRisks.npvFullCycle == ''
      || formRisks.npvFullCycle == null ? '' : parseFloat(formRisks.npvFullCycle);
    formRisks.flatRealPointForward = formRisks.flatRealPointForward == ''
      || formRisks.flatRealPointForward == null ? '' : parseFloat(formRisks.flatRealPointForward);
    formRisks.flatRealFeedForward = formRisks.flatRealFeedForward == ''
      || formRisks.flatRealFeedForward == null ? '' : parseFloat(formRisks.flatRealFeedForward);
    formRisks.remainingCostIncrease = formRisks.remainingCostIncrease == ''
      || formRisks.remainingCostIncrease == null ? '' : parseFloat(formRisks.remainingCostIncrease);

    this.riskService.postFormRiskData(formRisks).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    formRisks.npvFullCycle = Number(formRisks.npvFullCycle).toFixed(1).toString();
    formRisks.flatRealPointForward = Number(formRisks.flatRealPointForward).toFixed(1).toString();
    formRisks.flatRealFeedForward = Number(formRisks.flatRealFeedForward).toFixed(1).toString();
    formRisks.remainingCostIncrease = Number(formRisks.remainingCostIncrease).toFixed(1).toString();
    formRisks.npvFullCycle = formRisks.npvFullCycle + '%';

    // // formRisks.flatRealPointForward = this.NumberWithCommas(formRisks.flatRealPointForward);
    // // formRisks.flatRealFeedForward = this.NumberWithCommas(formRisks.flatRealFeedForward);
    // // formRisks.flatRealPointForward = '$'+String(formRisks.flatRealPointForward);
    // // formRisks.flatRealFeedForward = '$'+String(formRisks.flatRealFeedForward);
  }

  IncludedInEconomicsCheck(event: any, risk: RisksModel) {
    risk.modifiedBy = this.provId;
    risk.includedInEconomics = event.target.checked;
    this.riskService.postRisksData(risk).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  IdentifiedInAssuranceReviewCheck(event: any, risk: RisksModel) {
    risk.modifiedBy = this.provId;
    risk.identifiedInAssuranceReview = event.target.checked;
    this.riskService.postRisksData(risk).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }

  SaveCommentForRisks(risk: RisksModel) {
    const dialogData = new CommentDialogModel("Update Comment", risk.comment, this.isOnHold, this.form.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        risk.modifiedBy = this.provId;
        risk.comment = comment;
        this.riskService.postRisksData(risk).subscribe(res => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  DeleteRisk(risk: RisksModel, index: any) {

    const message = 'Are you sure you want to delete this item?';
    const dialogData = new ConfirmDialogModel("Confirm item deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.risks.splice(index, 1);
        risk.modifiedBy = this.provId;
        risk.active = false;
        this.riskService.postRisksData(risk).subscribe(res => {
          this.notifyService.showSuccess("Item deleted successfully...!", "");
        });
      }
    });
  }

  AddNewRisk() {
    if (this.risks != null && this.risks != undefined && this.risks.length > 0) {
    let risk = this.risks.slice(-1)[0];
    const newRisk = new RisksModel();
    newRisk.id = 0;
    newRisk.active = true;
    newRisk.createdBy = this.provId;
    newRisk.createdOn = new Date();
    newRisk.order = risk.order + 1;
    newRisk.formId = risk.formId;
    newRisk.formRiskId = 0;
    newRisk.riskName = '';
    newRisk.mitigation = '';
    newRisk.includedInEconomics = false;
    newRisk.reflectedInEconomics = '';
    newRisk.potentialImpact = '';
    newRisk.identifiedInAssuranceReview = false;
    newRisk.reviewType = '';
    newRisk.status = '';
    newRisk.comment = '';
    this.risks.splice(this.risks.length + 1, 0, newRisk);
  }else{
    const newRisk = new RisksModel();
    newRisk.id = 0;
    newRisk.active = true;
    newRisk.createdBy = this.provId;
    newRisk.createdOn = new Date();
    newRisk.order =  1;
    newRisk.formId = this.formId;
    newRisk.formRiskId = 0;
    newRisk.riskName = '';
    newRisk.mitigation = '';
    newRisk.includedInEconomics = false;
    newRisk.reflectedInEconomics = '';
    newRisk.potentialImpact = '';
    newRisk.identifiedInAssuranceReview = false;
    newRisk.reviewType = '';
    newRisk.status = '';
    newRisk.comment = '';
    this.risks.splice(this.risks.length + 1, 0, newRisk);
  }
}

  UpdateEcoSensitivityData(row: EconomicSensitivitiesModel) {
    row.modifiedBy = this.provId;
    row.lowPrice = row.lowPrice == '' || row.lowPrice == null ? '' : parseFloat(row.lowPrice);
    row.midPrice = row.midPrice == '' || row.midPrice == null ? '' : parseFloat(row.midPrice);
    row.highPrice = row.highPrice == '' || row.highPrice == null ? '' : parseFloat(row.highPrice);

    this.riskService.postEconomicSensitivityData(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    row.lowPrice = Number(row.lowPrice).toFixed(1).toString();
    row.highPrice = Number(row.highPrice).toFixed(1).toString();
  }

  UpdateEcoSensitivityDataDPI(row: EconomicSensitivitiesModel) {
    row.modifiedBy = this.provId;
    row.lowPrice = row.lowPrice == '' || row.lowPrice == null ? '' : parseFloat(row.lowPrice);
    row.midPrice = row.midPrice == '' || row.midPrice == null ? '' : parseFloat(row.midPrice);
    row.highPrice = row.highPrice == '' || row.highPrice == null ? '' : parseFloat(row.highPrice);

    this.riskService.postEconomicSensitivityData(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    row.lowPrice = Number(row.lowPrice).toFixed(2).toString();
    row.highPrice = Number(row.highPrice).toFixed(2).toString();
  }

  UpdateEcoSensitivityDataROR(row: EconomicSensitivitiesModel) {
    row.modifiedBy = this.provId;
    row.lowPrice = row.lowPrice == '' || row.lowPrice == null ? '' : parseFloat(row.lowPrice);
    row.midPrice = row.midPrice == '' || row.midPrice == null ? '' : parseFloat(row.midPrice);
    row.highPrice = row.highPrice == '' || row.highPrice == null ? '' : parseFloat(row.highPrice);

    this.riskService.postEconomicSensitivityData(row).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    row.lowPrice = Number(row.lowPrice).toFixed(1).toString();
    row.midPrice = Number(row.midPrice).toFixed(1).toString();
    row.highPrice = Number(row.highPrice).toFixed(1).toString();
    row.lowPrice = row.lowPrice + '%';
    row.midPrice = row.midPrice + '%';
    row.highPrice = row.highPrice + '%';
  }

  SaveCommentForFormRisks(row: FormRisksModel, uiComment: string, commentType: string) {

    const dialogData = new CommentDialogModel("Update Comment", uiComment, this.isOnHold, this.form.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        row.modifiedBy = this.provId;
        if (commentType == 'netPresentValueSensitivityLowComment') {
          row.netPresentValueSensitivityLowComment = comment;
        } else if (commentType == 'netPresentValueSensitivityMidComment') {
          row.netPresentValueSensitivityMidComment = comment;
        } else if (commentType == 'netPresentValueSensitivityHighComment') {
          row.netPresentValueSensitivityHighComment = comment;
        } else if (commentType == 'npvFullCycleComment') {
          row.npvFullCycleComment = comment;
        } else if (commentType == 'flatRealPointForwardComment') {
          row.flatRealPointForwardComment = comment;
        } else if (commentType == 'commodityTestedByComment') {
          row.commodityTestedByComment = comment;
        } else if (commentType == 'flatRealFeedForwardComment') {
          row.flatRealFeedForwardComment = comment;
        } else if (commentType == 'feedForwardCommodityTestedByComment') {
          row.feedForwardCommodityTestedByComment = comment;
        } else if (commentType == 'remainingCostIncreaseComment') {
          row.remainingCostIncreaseComment = comment;
        }

        row.npvFullCycle = row.npvFullCycle == ''
      || row.npvFullCycle == null ? '' : parseFloat(row.npvFullCycle);
      row.flatRealPointForward = row.flatRealPointForward == ''
      || row.flatRealPointForward == null ? '' : parseFloat(row.flatRealPointForward);
      row.flatRealFeedForward = row.flatRealFeedForward == ''
      || row.flatRealFeedForward == null ? '' : parseFloat(row.flatRealFeedForward);
      row.remainingCostIncrease = row.remainingCostIncrease == ''
      || row.remainingCostIncrease == null ? '' : parseFloat(row.remainingCostIncrease);

        this.riskService.postFormRiskData(row).subscribe(res => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  SaveCommentForEcoSensitivity(row: EconomicSensitivitiesModel, uiComment: string, commentType: string) {

    const dialogData = new CommentDialogModel("Update Comment", uiComment, this.isOnHold, this.form.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });

    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        row.modifiedBy = this.provId;
        if (commentType == 'lowPriceComment') {
          row.lowPriceComment = comment;
        } else if (commentType == 'midPriceComment') {
          row.midPriceComment = comment;
        } else if (commentType == 'highPriceComment') {
          row.highPriceComment = comment;
        }

        this.riskService.postEconomicSensitivityData(row).subscribe(res => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
        });
      }
    });
  }

  ShowProjectSensitivityModal() {
    const message = "From the S-curve included in section 6.2, indicate here the likelihood of NPV<0 at the mid-price scenario. For supplement, update sensitivity.";

    const dialogData = new InfoDialogModel("Project Sensitivity", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  ShowBreakevenSensitivityModal() {
    var CE_text = this.form.features?.isNewCEisApplicableForms || this.form.isNewCEisApplicableForms ? "Capex" : "C&E";
    const message = ""
      + '\n<p></p>' + '<h4 class="text-left">Breakeven Price – required for upstream projects, optional for others if relevant</h4>'
      + "This is a sensitivity calculation to understand the breakeven price for development projects. The purpose is to provide decision makers with additional insight into the economic risk inherent in the project. Higher breakeven prices imply less cushion in future pricing volatility to preserve value creation for the project (NPV10=0 reflects a DPI of 1, or the point at which the project just covers the investment and the nominal cost of capital). Upstream cash flows should include end-of-life (e.g., decommissioning and abandonment) costs. For DS&C projects that use shorter cash flows and terminal/in-perpetuity values, consult with SP&T or CSP on a case-by-case basis."
      + "<br/> <br/>" + "1. The calculation is on a point forward, discounted cashflow basis. The normal rules apply about determining the discount date (determined by before or after July 1st of the current year)"
      + "<br/>" + "2. The calculation is on an after-tax basis to properly reflect the impact of fiscal terms"
      + "<br/>" + "3. The calculations are carried out deterministically, with all uncertainties at their P50 values"
      + "<br/>" + "4. For the primary product, develop the price time series as follows:"
      + "<br/>" + '<span class="pl-25"></span>' + "1. Start with the dollar value of the mid-price scenario in year 1 of the cashflow (e.g. WTI @ 60$/bbl)"
      + "<br/>" + '<span class="pl-25"></span>' + "2. Apply ONLY general inflation to the price for future values. The inflation rate should be consistent with the"
      + "<br/>" + '<span class="pl-25"></span>' + "fundamental inflation assumed in the corporate price model, based on USA inflation. For example, in 2019 that"
      + "<br/>" + '<span class="pl-25"></span>' + "assumption is 2%"
      + "<br/>" + "5. For the secondary product, develop the price time series as follows:"
      + "<br/>" + '<span class="pl-25"></span>' + "1. If the secondary price is directly linked to the primary price, let it vary similarly with the primary price"
      + "<br/>" + '<span class="pl-25"></span>' + "2. If the secondary price is a local market contract, honor the contract and then inflate at the end of the contract. If there"
      + "<br/>" + '<span class="pl-25"></span>' + "is an inflation clause in the contract, reflect that in the price"
      + "<br/>" + '<span class="pl-25"></span>' + "3. If the secondary price is spot, apply inflation to year 1 price (same considerations as for primary product)"
      + "<br/>" + "6. To reflect the same assumptions in the cost basis for the project, develop project costs (capital and operating expense) as follows:"
      + "<br/>" + '<span class="pl-25"></span>' + "1. Determine the P50 costs using year 1 as the cost basis"
      + "<br/>" + '<span class="pl-25"></span>' + "2. Apply ONLY general inflation to escalate the costs for future values"
      + "<br/>" + '<span class="pl-25"></span>' + "3. This preserves the ratio of revenue to revenue to cost, while the margin in dollars increases (this is intentional)"
      + "<br/>" + '<span class="pl-25"></span>' + "4. DO NOT APPLY COGS"
      + "<br/>" + '<span class="pl-25"></span>' + "5. If costs are already under contract, use contract pricing without further adjustment. Inflate at 2% if costs continue"
      + "<br/>" + '<span class="pl-25"></span>' + "beyond the contract period."
      + "<br/>" + "7. To determine the breakeven price for NPV10 = 0, use goal seek to vary the year 1 price."
      + '\n<p></p>' + '<h4 class="text-left">Breakeven Cost – required for all projects</h4>'
      + "This is a sensitivity calculation to test the vulnerability of the project to " + CE_text + " overruns and is a required metric on the new Digital GO-36 (DGO-36). The greater the tolerance to " + CE_text +" overrun without resulting in an NPV10 equal to zero, the lower the economic risk associated with the project."
      + "<br/> <br/>" + "1. The calculation is on a point forward, discounted cashflow basis. The normal rules apply about determining the discount date (determined by before or after July 1st of current year)"
      + "<br/>" + "2. The calculation is on an after-tax basis to properly reflect the impact of any fiscal terms (pre and post start-up)"
      + "<br/>" + "3. The calculations are carried out deterministically, with all uncertainties at their P50 values. Utilize Mid-Case Investment Price Guidance (with local adjustments) for the “current year” to develop product price forecasts."
      + "<br/>" + "4. Create and apply a multiplication factor to the total " + CE_text +" (preserve the spend profile over time)"
      + "<br/>" + "5. Use goal seek to determine the multiplier that results in NPV10 = 0"
      + "<br/>" + "6. Express the resulting " + CE_text +" as a percentage of the appropriation amount"
      + '<br/> <br/>' + 'See the <a href="https://chevron.sharepoint.com/:x:/r/sites/CSP/Investment%20Analysis/June_2019_Breakeven_Generic.xlsx" target="_blank">example file on the CSP SharePoint</a>'
      + '\n' + 'for an illustration of this methodology.';

    const dialogData = new InfoDialogModel("Breakeven Sensitivities for NPV10 = 0", message);

    this.dialog.open(InfoDialogComponent, {
      width: "750px",
      data: dialogData
    });
  }

  NumberWithCommas(x: any) {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
}
