import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalComma'
})
export class DecimalCommaPipe implements PipeTransform {

  transform(value: any): any {
    return this.NumberWithCommas(Number(value).toFixed(1).toString());
  }
  NumberWithCommas(x: any) {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
}
