<div class="box-container" data-bind="visible: queueTask().length == 0">
    <div class="row">
        <div class="col" data-bind="with: part1">
            <h3>Supplement</h3>
            <a class="no-underline" [routerLink]="['/go36project/viewform']" [queryParams]="{projectId: projectId}">
                <h5 style="padding: 0; font-size: 14px;">
                    {{projectName}}
                </h5>
            </a>
        </div>
    </div>
    <br />
    <br />

    <div class="row">
        <div class="form-group col-xs-2">
            <label class="form-label">Project ID:</label>
            <input type="text" class="form-control" readonly="readonly" [(ngModel)]="projectData.id">
        </div>
        <div class="form-group col-xs-4">
            <label class="form-label">Project Name / Title:</label>
            <input type="text" class="form-control" [(ngModel)]="projectData.projectName" readonly="readonly">
        </div>
        <div class="form-group col-xs-3">
            <label class="form-label">AR Created By:</label>
            <input type="text" class="form-control" readonly="readonly" [(ngModel)]="projectData.createdBy">
        </div>
        <div class="form-group col-xs-3">
            <label class="form-label">AR Last Modified By:</label>
            <input type="text" class="form-control" readonly="readonly" [(ngModel)]="projectData.modifiedBy">
        </div>
    </div>

    <div class="row">
        <div class="form-group col-xs-2">
            <label for="opco">1a. OPCO:</label>
            <input type="text" class="form-control" [(ngModel)]="projectData.opcoName" readonly="readonly">
        </div>
        <div class="form-group col-xs-2">
            <label for="businessUnit">1b. SBU:</label>
            <input type="text" class="form-control" [(ngModel)]="projectData.sbuName" readonly="readonly">
        </div>
        <div class="form-group col-xs-3">
            <label for="projectLocation">1c. Project Location:</label>
            <input type="text" class="form-control" maxlength="50" readonly="readonly"
                [(ngModel)]="projectData.projectLocation">
        </div>
        <div class="form-group col-xs-2">
            <label for="opco">Asset Class:</label>
            <input type="text" class="form-control" [(ngModel)]="projectData.assetName" readonly="readonly">
        </div>
        <div class="form-group col-xs-2">
            <label for="date">1d. Date:</label>
            <input type="button" name="txtDate" [(ngModel)]="projectData.arDate" style="display: flex;        
                cursor: auto; border-radius: 0.5rem;" [disabled]="true" class="form-control"
                [matDatepicker]="picker5" />
            <mat-datepicker #picker5></mat-datepicker>
        </div>
        <div class="form-group col-xs-1 padding-0">
            <label for="arNumber">1e. AR #:</label>
            <input type="text" class="form-control" [(ngModel)]="projectData.arId" maxlength="50" readonly="readonly">
        </div>
    </div>
    <br />

    <div class="row" *ngIf="(emailId == 'pavanimaddurivenkatanaga@chevron.com') && supplementData.docName == null">
        <div class="col-xs-12 padding-0">
            <div class="text-left">
                <h5 style="color: #0B2D71;
            font-size: 14px;" class="text-left">Upload Attachments :</h5>
                <input type="file" class="form-control-file upload" #file (change)="uploadFile($event)" >
            </div>
        </div>
    </div>
    <br/>
    <br/>
    
    <div class="row" *ngIf="supplementData.docName != null">
        <div class="col-xs-12">
            <div class="text-left" style="font-size: 16px;">
                <b>
                    <a target="_blank" class="border-heading" (click)="DownloadSupplementPDF(supplementData)">
                        Download Form (PDF)
                    </a>
                </b>
            </div>
        </div>
    </div>
    <br />
    <br/>
    <div class="row" *ngIf="supplementData.docName == null">
        <div class="col-xs-12">
            <div class="text-left" style="font-size: 16px;">
                <b>
                    <a target="_blank" class="border-heading">
                        No File Available
                    </a>
                </b>
            </div>
        </div>
    </div>
    <br />
    <br />

    <div class="row">
        <div class="col-xs-12">
            <div class="text-left">
                <h5 style="color: #0B2D71;
            font-size: 14px;" class="text-left">Uploaded Attachments :</h5>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <table class="table table-bordered table-striped table-responsive">
                <thead>
                    <tr>
                        <td class="text-center text-bold">&nbsp;</td>
                        <td class="text-center text-bold">Document ID</td>
                        <td class="text-left text-bold">File Name</td>
                        <td class="text-left text-bold" style="width: 17% !important;">Uploaded By</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-bordered" *ngFor="let document of documentList let i = index">
                        <td class="text-center">
                            <button type="button" style="color: white !important;" class="btn btn-sm btn-primary"
                                (click)="DownloadFile(document)">
                                Download
                            </button>
                        </td>
                        <td class="text-center">
                            <span>{{document.documentId}}</span>
                        </td>
                        <td class="text-left">
                            <span>{{document.docName}}</span>
                        </td>
                        <td class="text-left">
                            <span>{{document.createdByName}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row" style="margin-top: 15px;">
        <div class="col-xs-12 padding-0">
            <div class="row">
                <div class="form-group col-sm-6 col-xs-12 text-left padding-0 ">
                </div>
                <div class="form-group col-sm-6 col-xs-12 text-right padding-0">
                    <button type="button" style="color: white !important;" class="btn btn-sm btn-primary"
                        style="color: white !important" [routerLink]="['/go36project/viewform']"
                        [queryParams]="{projectId: projectId}">Close</button>
                </div>
            </div>
        </div>
    </div>

</div>