
export class FormAction {
    id: number = 0;
    projectId: number = 0;
    formId: number = 0;
    formType?: string = '';
    currentStatus: number = 0;
    currentStatusName?: string = '';
    CurrentStatusName
    createdOn?: Date;
    modifiedOn?: Date;
    active: boolean = true;
    submitterComments?: string = '';
    reviewerComments?: string = '';
    originalId: number = 0;
    submitterName?: string = '';
    reviewerName?: string = '';
    version: number = 0;
    versionName?: string = "";
    submitterUserId: number = 0;
    reviewerUserId: number = 0;
    submitterFlag: boolean = false;
    reviewerFlag: boolean = false;
    revisionFlag: boolean = false;
    action?: string = "";
    comments?: string = '';
    versionId: number = 0;
    formActionId: number = 0;
    modifiedBy?: string = '';
    currentDate: Date = new Date();
    loggedInUser?: string = '';
    objectId?: string = '';
    provId?: string = '';
    reviewFlag: boolean = false;
    deleteFlag: boolean = false;
    currentUrl?: string = '';
    currentBUrl?: string = '';
    projectName?: string = '';
    commentFlag: boolean = false;
 
}