import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { AppsettingsService } from '../../../Shared/appsettings.service'
import { CalAngularService } from '@cvx/cal-angular';
import { BnNgIdleService } from 'bn-ng-idle';
import { SubsurfaceViewModel } from '../Model/subsurfaceView.model';
import { DesignParameterViewModel } from '../Model/designParameterView.model';
import { ProductionForecastViewModel } from '../Model/productionForecastView.model';
import { ProductionForecastModel } from '../Model/productionForecast.model';
import { ManagementOfChangeModel } from '../Model/managementOfChange.model';
import { MocDesignParameterViewModel } from '../Model/mocDesignParameterView.model';
// //import { saveAs } from 'file-saver'

@Injectable({
  providedIn: 'root'
})
export class SsbodService {
  constructor(private http: HttpClient,
    private appsettings: AppsettingsService,
    private authService: CalAngularService,
    private bnIdle: BnNgIdleService) { }
  GetAllSSBODProjectsUrl = "api/Ssbod/GetAllSSBODProjects?objectId=";
  GetSSBODUrl = "api/Ssbod/GetSSBOD?formId=";
  SaveSSBODCommonDetailsUrl = "api/Ssbod/SaveSSBODCommonDetails";
  GetCKEditorTextUrl = "api/Ssbod/GetCKEditorText?ssbodeFormId=";
  UpdateCkeditorChangesUrl = "api/Ssbod/UpdateCkeditorChanges";
  GetDesignSectionsUrl = "api/Ssbod/GetDesignSections?ssbodFormId=";
  GetEVFormulaUrl = "api/Ssbod/GetEVFormula";
  SaveDesignSectionEVFormulaUrl= "api/Ssbod/SaveDesignSectionEVFormula";
  GetSelectedEvFormulaUrl= "api/Ssbod/GetSelectedEvFormula?formId=";
  SaveDesignSectionDataUrl= "api/Ssbod/SaveDesignSectionData";
  GetDesignSectionCalculationsUrl = "api/Ssbod/GetDesignSectionCalculations?formId=";
  GetProductionForecastDataUrl= "api/Ssbod/GetProductionForecastData?formId=";
  SaveProductionForecastDataUrl = "api/Ssbod/SaveProductionForecastData";
  GetManagementOfChangeDataUrl= "api/Ssbod/GetManagementOfChangeData?formId=";
  SaveManagementOfChangeDataUrl= "api/Ssbod/SaveManagementOfChangeData";
  GetMOCDesignSectionUrl = "api/Ssbod/GetMOCDesignSection?formId=";
  GetMOCDesignSectionCalculationsUrl = "api/Ssbod/GetMOCDesignSectionCalculations?formId=";
  SaveMOCDesignSectionDataUrl = "api/Ssbod/SaveMOCDesignSectionData";
  GetSSBODVersionsUrl= "api/Ssbod/GetSSBODVersions?formId=";
  CreateNamedVersionUrl = "api/Ssbod/CreateNamedVersion";
  GetArchivedVersionsUrl = "api/Ssbod/GetArchivedVersions";
  LinkSsbodUrl = "api/Ssbod/LinkSsbod";
  getUserAccessListForSSBODUrl = "api/UserAccess/GetSSBODAccessList?inputData=";
  getSSBODPDF = "api/Ssbod/CreateSSBODPdf?formId=";

  checkPrecision(res:any, rowValue:any):any{
    if(res!=null){
      res.forEach(item => {
        if(rowValue != 4){
          item.P10 = (item.P10 !== null && item.P10 !== "")?Number(item.P10).toFixed(1).toString() : null;
          item.P50 = (item.P50 !== null && item.P50 !== "")?Number(item.P50).toFixed(1).toString() : null;
          item.P90 = (item.P90 !== null && item.P90 !== "")?Number(item.P90).toFixed(1).toString() : null;
          item.ExpectedValue = (item.ExpectedValue !== null && item.ExpectedValue !== "")?Number(item.ExpectedValue).toFixed(1).toString() : null;
        }
        else{
          item.P10 = (item.P10 !== null && item.P10 !== "")?Number(item.P10).toFixed(0) : null;
          item.P50 = (item.P50 !== null && item.P50 !== "")?Number(item.P50).toFixed(0) : null;
          item.P90 = (item.P90 !== null && item.P90 !== "")?Number(item.P90).toFixed(0) : null;
          item.ExpectedValue = (item.ExpectedValue !== null && item.ExpectedValue !== "")?Number(item.ExpectedValue).toFixed(0) : null;
        }
      });
    }
    return res;
  }
  checkForecastPrecision(item:ProductionForecastModel):ProductionForecastModel{
    if(item!=null){
      item.ProductionVolumeMbo = (item.ProductionVolumeMbo !== null && item.ProductionVolumeMbo !== "") ? Number(item.ProductionVolumeMbo).toFixed(3).toString() : (0).toFixed(3).toString();
      item.ProjectionVolumeMmcf = (item.ProjectionVolumeMmcf !== null && item.ProjectionVolumeMmcf !== "") ? Number(item.ProjectionVolumeMmcf).toFixed(3).toString() : (0).toFixed(3).toString();
      item.ProductionVolumeNglMbo = (item.ProductionVolumeNglMbo !== null && item.ProductionVolumeNglMbo !== "") ? Number(item.ProductionVolumeNglMbo).toFixed(3).toString() : (0).toFixed(3).toString();
      item.ProductionVolumeLpgMbo = (item.ProductionVolumeLpgMbo !== null && item.ProductionVolumeLpgMbo !== "") ? Number(item.ProductionVolumeLpgMbo).toFixed(3).toString() : null;
      item.InjectionVolumeMbw = (item.InjectionVolumeMbw !== null && item.InjectionVolumeMbw !== "") ? Number(item.InjectionVolumeMbw).toFixed(3).toString() : (0).toFixed(3).toString();
      item.InjectionVolumeMmcf = (item.InjectionVolumeMmcf !== null && item.InjectionVolumeMmcf !== "") ? Number(item.InjectionVolumeMmcf).toFixed(3).toString() : (0).toFixed(3).toString();
      item.InjectionMillionMetricTons = (item.InjectionMillionMetricTons !== null && item.InjectionMillionMetricTons !== "") ? Number(item.InjectionMillionMetricTons).toFixed(3).toString() : (0).toFixed(3).toString();
      item.Producers = (item.Producers !== null && item.Producers !== "") ? Number(item.Producers).toFixed(0).toString() : 0;
      item.Injectors = (item.Injectors !== null && item.Injectors !== "") ? Number(item.Injectors).toFixed(0).toString() : 0;
    
      item.NetProductionVolumeMbo = (item.NetProductionVolumeMbo !== null && item.NetProductionVolumeMbo !== "") ? Number(item.NetProductionVolumeMbo).toFixed(3).toString() : (0).toFixed(3).toString();
      item.NetProductionVolumeMmcf = (item.NetProductionVolumeMmcf !== null && item.NetProductionVolumeMmcf !== "") ? Number(item.NetProductionVolumeMmcf).toFixed(3).toString() : (0).toFixed(3).toString();
      item.NetProductionVolumeNglMbo = (item.NetProductionVolumeNglMbo !== null && item.NetProductionVolumeNglMbo !== "") ? Number(item.NetProductionVolumeNglMbo).toFixed(3).toString() : (0).toFixed(3).toString();
      item.NetProductionVolumeLpgMbo = (item.NetProductionVolumeLpgMbo !== null && item.NetProductionVolumeLpgMbo !== "") ? Number(item.NetProductionVolumeLpgMbo).toFixed(3).toString() : null;
    }
    return item;
  }
  checkMocPrecision(res:any,isCalculation: any):any{
    if(res!=null){
      if(isCalculation){
        res.forEach(item => {
          item.P10 = (item.P10 !== null && item.P10 !== "")?Number(item.P10).toFixed(0).toString() : (0).toFixed(0).toString();
          item.P50 = (item.P50 !== null && item.P50 !== "")?Number(item.P50).toFixed(0).toString() : (0).toFixed(0).toString();
          item.P90 = (item.P90 !== null && item.P90 !== "")?Number(item.P90).toFixed(0).toString() : (0).toFixed(0).toString();
          item.ExpectedValue = (item.ExpectedValue !== null && item.ExpectedValue !== "")?Number(item.ExpectedValue).toFixed(0).toString() : (0).toFixed(0).toString();
        });
      }else{
        res.forEach(item => {
            item.P10 = (item.P10 !== null && item.P10 !== "")?Number(item.P10).toFixed(3).toString() : (0).toFixed(3).toString();
            item.P50 = (item.P50 !== null && item.P50 !== "")?Number(item.P50).toFixed(3).toString() : (0).toFixed(3).toString();
            item.P90 = (item.P90 !== null && item.P90 !== "")?Number(item.P90).toFixed(3).toString() : (0).toFixed(3).toString();
            item.ExpectedValue = (item.ExpectedValue !== null && item.ExpectedValue !== "")?Number(item.ExpectedValue).toFixed(3).toString() : (0).toFixed(3).toString();
        });
      }
    }
    return res;
  }
  getSsbodProject(objectId:string, provId : string, userRole : string) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetAllSSBODProjectsUrl +   objectId + "&provId="+provId + "&userRole="+userRole)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  getSubsurfaceData(formId:number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetSSBODUrl +formId)
      .pipe(map((res: any) => {
        return res;
      }));
  }
  SaveSSBODCommonDetails(formId: number, ssbodModel: SubsurfaceViewModel) {
    const params = new HttpParams()
    .append('ssbodeFormId', formId);
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.SaveSSBODCommonDetailsUrl, ssbodModel, {
      params: params
    });
  }
  GetCKEditorText(ssbodeFormId: number, sectionId: any) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetCKEditorTextUrl +ssbodeFormId+ "&sectionId="+sectionId)
  }
  UpdateCkeditorChanges(ssbodeFormId: number, ssbodModel: SubsurfaceViewModel){
    const params = new HttpParams()
    .append('ssbodeFormId', ssbodeFormId);
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.UpdateCkeditorChangesUrl,ssbodModel, {
      params: params
    });
  }
  GetDesignSection(ssbodFormId: number, type: any) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetDesignSectionsUrl +ssbodFormId+ "&type="+type);
  }
  GetEVFormula() {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetEVFormulaUrl);
  }
  SaveDesignSectionEVFormula(formId: number, type: any, evId: number) {
    const params = new HttpParams()
    .append('formId', formId)
    .append('type', type)
    .append('evId', evId)
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.SaveDesignSectionEVFormulaUrl, '', {
      params: params
    });
  }
  GetSelectedEvFormula(formId: number, type: any){
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetSelectedEvFormulaUrl +formId+ "&type="+type);
  }
  SaveDesignSectionData(formId: number, designParameterModel: DesignParameterViewModel){
    const params = new HttpParams()
    .append('formId', formId);
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.SaveDesignSectionDataUrl, designParameterModel, {
      params: params
    });
  }
  GetDesignSectionCalculations(formId: number, type: any){
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetDesignSectionCalculationsUrl +formId+ "&type="+type);
  }
  GetProductionForecastData(formId:number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetProductionForecastDataUrl +formId )
      .pipe(map((res: any) => {
        return res;
      }));
  }
  SaveProductionForecastData(formId: number, productionForecastViewModel: ProductionForecastViewModel,modifiedBy: string) {
    const params = new HttpParams()
    .append('formid', formId)
    .append('modifiedBy', modifiedBy);
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.SaveProductionForecastDataUrl, productionForecastViewModel, {
      params: params
    });
  }
  GetManagementOfChangeData(formId:number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetManagementOfChangeDataUrl +formId )
      .pipe(map((res: any) => {
        return res;
      }));
  }
  SaveManagementOfChangeData(formId: number, managementOfChangeViewModel: ManagementOfChangeModel,modifiedBy: string) {
    const params = new HttpParams()
    .append('formid', formId)
    .append('modifiedBy', modifiedBy);
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.SaveManagementOfChangeDataUrl, managementOfChangeViewModel, {
      params: params
    });
  }
  GetMOCDesignSection(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetMOCDesignSectionUrl +formId);
  }
  GetMOCDesignSectionCalculations(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetMOCDesignSectionCalculationsUrl +formId);
  }
  SaveMOCDesignSectionData(formId: number, mocDesignParameterModel: MocDesignParameterViewModel) {
    const params = new HttpParams()
    .append('formId', formId);
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.SaveMOCDesignSectionDataUrl, mocDesignParameterModel, {
      params: params
    });
  }
  GetSSBODVersions(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetSSBODVersionsUrl +formId);
  }
  CreateNamedVersion(formId: number, selectedVersion: string, provId: string) {
    const params = new HttpParams()
    .append('formId', formId)
    .append('versionName', selectedVersion)
    .append('provId', provId);
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.CreateNamedVersionUrl,'', {
      params: params
    });
  }
  GetArchivedVersions(formType: any, projectId: number){
    const params = new HttpParams()
    .append('formType', formType)
    .append('projectId', projectId)
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetArchivedVersionsUrl, {
      params: params
    });
  }
  LinkSsbod(fidId: number, formId: number,formType: string, provId: string) {
    const params = new HttpParams()
    .append('fidId', fidId)
    .append('formId', formId)
    .append('formType', formType)
    .append('provId', provId);
    return this.http.post<any>(this.appsettings.apiBaseUrl + this.LinkSsbodUrl, '', {
      params: params
    });
  }
  GetUserAccessListForSSBOD(inputData: any) {    
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getUserAccessListForSSBODUrl + inputData);    
    }

    getPDF(formId:number, ssbodProjectId : number){
      const url = this.appsettings.apiBaseUrl + this.getSSBODPDF + formId  + "&ssbodProjectId="+ssbodProjectId;
      return this.http.get<any>(url, {responseType: 'blob' as 'json'});      
      }

}
