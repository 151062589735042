<div id="page-body">
    <div class="box-container">
        <div class="row text-center">
            <h3>Delete GO-36</h3>
        </div>

        <div class="row">
            <hr />
        </div>

        <div class="row text-center">
            <h4>Are you sure you want to delete the GO-36 Form below?</h4>
        </div>

        <div class="row">
            <div class="col-xs-6 text-right">
                <div class="col-xs-8">
                </div>
                <div class="col-xs-4">
                    <a *ngIf="formType == 'E&A LOOKBACK_PART4'" [routerLink]="['/eaproject/eaviewform/']"
                     [queryParams]="{ projectId: projectId,formId: form.originalId,versionName: versionName}" class="btn btn-block btn-info" >Cancel</a>
                     
                </div>
            </div>
            <div class="col-xs-6 text-left">
                <div class="col-xs-4">
                    <button class="btn btn-block btn-danger" style="color:white !important"  (click)="DeleteForm()">Confirm Delete</button>
                </div>
                <div class="col-xs-8">
                </div>
            </div>
        </div>

        <div class="row">
            <hr />
        </div>

        <div class="row">
            <div class="form-row">
                <div class="form-group col-xs-1">
                    <label for="arNumber">AR #:</label>
                    <input type="text" class="form-control" [(ngModel)]="form.id" maxlength="50" readonly="readonly" />
                </div>
                <div class="form-group col-xs-3">
                    <label for="projectName">Project Name:</label>
                    <input type="text" class="form-control" [(ngModel)]="projectData.projectName" maxlength="50" readonly="readonly" />
                </div>
                <div class="form-group col-xs-2">
                    <label for="date">Date:</label>
                    <input name="txtDate" class="form-control" [matDatepicker]="picker" [(ngModel)]="form.createdOn" readonly="readonly" />
                    <mat-datepicker #picker ></mat-datepicker>
                </div>
                <div class="form-group col-xs-3">
                    <label class="form-label">Created By:</label>
                    <input type="text" class="form-control" [(ngModel)]="form.createdBy" readonly="readonly"/>
                </div>
                <div class="form-group col-xs-3">
                    <label class="form-label">Document Type:</label>
                    <input type="text" class="form-control" [(ngModel)]="formType" readonly="readonly" />
                </div>
            </div>
        </div>

        <div class="row">
            <hr />
        </div>
    </div>
</div>