export class SupplementCommentModel {
    id: number = 0;
    supplementId: number = 0;
    section: string = '';
    controlId: string = '';
    comment?: string = '';
    createdBy: string = '';
    createdDatetime?: Date = new Date;
    modifiedBy: string = '';
    modifiedDatetime?: Date = new Date;
    hidefromlist: boolean = false; 
}