import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CommentDialogComponent, CommentDialogModel } from 'src/app/Modals/comment-dialog/comment-dialog.component';
import { EaCommentModel } from 'src/app/Model/EaComment.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';
import * as customBuild from 'src/ckCustomBuild/build/ckeditor';
import { EaSupplementService } from '../../service/ea-supplement.service';

@Component({
  selector: 'app-supplement-part2',
  templateUrl: './supplement-part2.component.html',
  styleUrls: ['./supplement-part2.component.css']
})

export class SupplementPart2Component implements OnInit {
  public Editor = customBuild;
  public config = {
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36
      ],
      supportAllValues: true
    },
    licenseKey:"0lg5p72elA9l/jCp9tnYJLwAuxkmfHB3cCnj9K2zN9YYPeyoKCS8oQBjxoSdE60=",
    toolbar: [
      // 'fontfamily','fontsize','fontColor','fontBackgroundColor', '|',
      'fontColor', 'fontBackgroundColor', '|', 'fontSize',
      'undo', 'redo', '|',
      'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
      'bulletedList', 'numberedList', '|',
      'outdent', 'indent', '|',
      'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',

    ],
    autosave: {
      save(editor) {
      }
    }

  }
  @Input() supplementId: number = 0;
  projectId: number = 0;
  versionName: number = 0;
  emailId: string = "";
  provId: string = "";
  showSection1: boolean = true;
  showSection2: boolean = false;
  showSection3: boolean = false;
  showSection4: boolean = false;
  showSection5: boolean = false;
  showSection6: boolean = false;
  showSection7: boolean = false;
  showSection8: boolean = false;
  showSection9: boolean = false;
  showSection10: boolean = false;
  OperationModel: any;
  EconomicModel: any
  ExecutionModel: any
  BusinessModel: any
  DesignCommercialModel: any
  ExecutionTargetAssuranceModel: any
  @Input() comments: any = [];
  @Input() isOnHold: boolean = false;
  ArId: number = 0;
  @Input() isReadOnly: boolean = false;
  @Input() isReadOnlyGlobalProjectLevel: boolean = false;
  @Input() IsOldVersionOfForms: boolean = false;
  @Input() IsNewCEisApplicableForms: boolean = true;
  constructor(private eaSupplementService: EaSupplementService, private route: ActivatedRoute
    , private projectservice: Go36projectService,
    public dialog: MatDialog, private notifyService: NotificationService,
    private go36ProjectService: Go36projectService, public utilityService: UtilityService) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.route.queryParams
      .subscribe(params => {
        this.projectId = params['projectId'];
        this.versionName = params['versionName'];
      });
    this.LoadPart2Data();
  }
  ngAfterViewInit() {
    this.utilityService.CkEditorFontSizeScroll();
  }
  showSection(sectionNumber: number) {
    this.showSection1 = this.showSection2 = this.showSection3 = this.showSection4 = this.showSection5 =
      this.showSection6 = this.showSection7 = this.showSection8 = this.showSection9 = this.showSection10 = false;
    if (sectionNumber == 1) {
      this.showSection1 = true;
    }
    else if (sectionNumber == 2) {
      this.showSection2 = true;
    }
    else if (sectionNumber == 3) {
      this.showSection3 = true;
    }
    else if (sectionNumber == 4) {
      this.showSection4 = true;
    }
    else if (sectionNumber == 5) {
      this.showSection5 = true;
    }
    else if (sectionNumber == 6) {
      this.showSection6 = true;
    }
    else if (sectionNumber == 7) {
      this.showSection7 = true;
    }
    else if (sectionNumber == 8) {
      this.showSection8 = true;
    }
    else if (sectionNumber == 9) {
      this.showSection9 = true;
    }
    else if (sectionNumber == 10) {
      this.showSection10 = true;
    }
  }
  LoadPart2Data() {
    this.eaSupplementService.GetPart2Data(this.supplementId).subscribe((res: any) => {
      if (res) {
        this.OperationModel = res.operation;
        this.OperationModel.arUltimateRecovery.netMmbo.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmbo.ev));
        this.OperationModel.arUltimateRecovery.netMmbo.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmbo.p10));
        this.OperationModel.arUltimateRecovery.netMmbo.p50 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmbo.p50));
        this.OperationModel.arUltimateRecovery.netMmbo.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmbo.p90));
        this.OperationModel.arUltimateRecovery.netBcf.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netBcf.ev));
        this.OperationModel.arUltimateRecovery.netBcf.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netBcf.p10));
        this.OperationModel.arUltimateRecovery.netBcf.p50 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netBcf.p50));
        this.OperationModel.arUltimateRecovery.netBcf.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netBcf.p90));
        this.OperationModel.arUltimateRecovery.netMmboe.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmboe.ev));
        this.OperationModel.arUltimateRecovery.netMmboe.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmboe.p10));
        this.OperationModel.arUltimateRecovery.netMmboe.p50 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmboe.p50));
        this.OperationModel.arUltimateRecovery.netMmboe.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmboe.p90));
        this.OperationModel.arUltimateRecovery.mmboe8.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.mmboe8.ev));
        this.OperationModel.arUltimateRecovery.mmboe8.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.mmboe8.p10));
        this.OperationModel.arUltimateRecovery.mmboe8.p50 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.mmboe8.p50));
        this.OperationModel.arUltimateRecovery.mmboe8.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.mmboe8.p90));
        this.OperationModel.ultimateRecovery.netMmbo.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netMmbo.ev));
        this.OperationModel.ultimateRecovery.netMmbo.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netMmbo.p10));
        this.OperationModel.ultimateRecovery.netMmbo.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netMmbo.p90));
        this.OperationModel.ultimateRecovery.netBcf.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netBcf.ev));
        this.OperationModel.ultimateRecovery.netBcf.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netBcf.p10));
        this.OperationModel.ultimateRecovery.netBcf.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netBcf.p90));
        this.OperationModel.ultimateRecovery.netMmboe.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netMmboe.ev));
        this.OperationModel.ultimateRecovery.netMmboe.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netMmboe.p10));
        this.OperationModel.ultimateRecovery.netMmboe.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netMmboe.p90));
        this.OperationModel.ultimateRecovery.mmboe8.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.mmboe8.ev));
        this.OperationModel.ultimateRecovery.mmboe8.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.mmboe8.p10));
        this.OperationModel.ultimateRecovery.mmboe8.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.mmboe8.p90));

        this.EconomicModel = res.economic;
        this.EconomicModel.explorationWellSuccess = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.explorationWellSuccess));
        this.EconomicModel.explorationWellDevelopmentSuccess = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.explorationWellDevelopmentSuccess));
        this.EconomicModel.explorationWellDevelopment = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.explorationWellDevelopment));
        this.EconomicModel.appraisalWellDevelopment = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.appraisalWellDevelopment));
        this.EconomicModel.arNpv.riskCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arNpv.riskCasePointForward.ev));
        this.EconomicModel.arNpv.riskCasePointForward.p10 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arNpv.riskCasePointForward.p10));
        this.EconomicModel.arNpv.riskCasePointForward.p90 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arNpv.riskCasePointForward.p90));
        this.EconomicModel.npv.riskCasePointForward.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.npv.riskCasePointForward.arev));
        this.EconomicModel.npv.riskCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.npv.riskCasePointForward.ev));
        this.EconomicModel.npv.riskCasePointForward.p10 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.npv.riskCasePointForward.p10));
        this.EconomicModel.npv.riskCasePointForward.p90 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.npv.riskCasePointForward.p90));
        this.EconomicModel.arNpv.developCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arNpv.developCasePointForward.ev));
        this.EconomicModel.npv.developCasePointForward.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.npv.developCasePointForward.arev));
        this.EconomicModel.npv.developCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.npv.developCasePointForward.ev));

        this.EconomicModel.arDpi.riskCasePointForward.ev = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.arDpi.riskCasePointForward.ev));
        this.EconomicModel.arDpi.riskCasePointForward.p10 = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.arDpi.riskCasePointForward.p10));
        this.EconomicModel.arDpi.riskCasePointForward.p90 = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.arDpi.riskCasePointForward.p90));
        this.EconomicModel.dpi.riskCasePointForward.arev = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.dpi.riskCasePointForward.arev));
        this.EconomicModel.arDpi.developCasePointForward.ev = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.arDpi.developCasePointForward.ev));
        this.EconomicModel.dpi.developCasePointForward.arev = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.dpi.developCasePointForward.arev));
        this.EconomicModel.dpi.riskCasePointForward.ev = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.dpi.riskCasePointForward.ev));
        this.EconomicModel.dpi.riskCasePointForward.p10 = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.dpi.riskCasePointForward.p10));
        this.EconomicModel.dpi.riskCasePointForward.p90 = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.dpi.riskCasePointForward.p90));
        this.EconomicModel.dpi.developCasePointForward.ev = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.dpi.developCasePointForward.ev));
        if (this.EconomicModel.arRor.riskCasePointForward.ev != null) {
          this.EconomicModel.arRor.riskCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arRor.riskCasePointForward.ev));
          this.EconomicModel.arRor.riskCasePointForward.ev = this.EconomicModel.arRor.riskCasePointForward.ev + '%';
        }
        if (this.EconomicModel.arRor.riskCasePointForward.p10 != null) {
          this.EconomicModel.arRor.riskCasePointForward.p10 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arRor.riskCasePointForward.p10));
          this.EconomicModel.arRor.riskCasePointForward.p10 = this.EconomicModel.arRor.riskCasePointForward.p10 + '%';
        }
        if (this.EconomicModel.arRor.riskCasePointForward.p90 != null) {
        this.EconomicModel.arRor.riskCasePointForward.p90 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arRor.riskCasePointForward.p90));
        this.EconomicModel.arRor.riskCasePointForward.p90 = this.EconomicModel.arRor.riskCasePointForward.p90 + '%';
        }
        if (this.EconomicModel.ror.riskCasePointForward.arev != null) {
        this.EconomicModel.ror.riskCasePointForward.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.riskCasePointForward.arev));
        this.EconomicModel.ror.riskCasePointForward.arev = this.EconomicModel.ror.riskCasePointForward.arev + '%';
        }
        if (this.EconomicModel.arRor.developCasePointForward.ev != null) {
        this.EconomicModel.arRor.developCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arRor.developCasePointForward.ev));
        this.EconomicModel.arRor.developCasePointForward.ev = this.EconomicModel.arRor.developCasePointForward.ev + '%';
        }
        if (this.EconomicModel.ror.developCasePointForward.arev != null) {
        this.EconomicModel.ror.developCasePointForward.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.developCasePointForward.arev));
        this.EconomicModel.ror.developCasePointForward.arev = this.EconomicModel.ror.developCasePointForward.arev + '%';
        }
        if (this.EconomicModel.arRor.developCaseFullCycle.ev != null) {
        this.EconomicModel.arRor.developCaseFullCycle.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arRor.developCaseFullCycle.ev));
        this.EconomicModel.arRor.developCaseFullCycle.ev = this.EconomicModel.arRor.developCaseFullCycle.ev + '%';
        }
        if (this.EconomicModel.ror.developCaseFullCycle.arev != null) {
        this.EconomicModel.ror.developCaseFullCycle.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.developCaseFullCycle.arev));
        this.EconomicModel.ror.developCaseFullCycle.arev = this.EconomicModel.ror.developCaseFullCycle.arev + '%';
        }
        if (this.EconomicModel.ror.developCaseFullCycle.ev != null) {
        this.EconomicModel.ror.developCaseFullCycle.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.developCaseFullCycle.ev));
        this.EconomicModel.ror.developCaseFullCycle.ev = this.EconomicModel.ror.developCaseFullCycle.ev + '%';
        }
        if (this.EconomicModel.ror.riskCasePointForward.ev != null) {
        this.EconomicModel.ror.riskCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.riskCasePointForward.ev));
        this.EconomicModel.ror.riskCasePointForward.ev = this.EconomicModel.ror.riskCasePointForward.ev + '%';
        }
        if (this.EconomicModel.ror.riskCasePointForward.p10 != null) {
        this.EconomicModel.ror.riskCasePointForward.p10 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.riskCasePointForward.p10));
        this.EconomicModel.ror.riskCasePointForward.p10 = this.EconomicModel.ror.riskCasePointForward.p10 + '%';
        }
        if (this.EconomicModel.ror.riskCasePointForward.p90 != null) {
        this.EconomicModel.ror.riskCasePointForward.p90 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.riskCasePointForward.p90));
        this.EconomicModel.ror.riskCasePointForward.p90 = this.EconomicModel.ror.riskCasePointForward.p90 + '%';
        }
        if (this.EconomicModel.ror.developCasePointForward.ev != null) {
        this.EconomicModel.ror.developCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.developCasePointForward.ev));
        this.EconomicModel.ror.developCasePointForward.ev = this.EconomicModel.ror.developCasePointForward.ev + '%';
        }
        this.EconomicModel.arce.riskCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arce.riskCasePointForward.ev));
        this.EconomicModel.arce.riskCasePointForward.p10 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arce.riskCasePointForward.p10));
        this.EconomicModel.arce.riskCasePointForward.p90 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arce.riskCasePointForward.p90));
        this.EconomicModel.ce.riskCasePointForward.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.riskCasePointForward.arev));
        this.EconomicModel.arce.developCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arce.developCasePointForward.ev));
        this.EconomicModel.ce.developCasePointForward.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.developCasePointForward.arev));
        this.EconomicModel.arce.developCaseFullCycle.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arce.developCaseFullCycle.ev));
        this.EconomicModel.ce.developCaseFullCycle.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.developCaseFullCycle.arev));
        this.EconomicModel.ce.riskCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.riskCasePointForward.ev));
        this.EconomicModel.ce.riskCasePointForward.p10 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.riskCasePointForward.p10));
        this.EconomicModel.ce.riskCasePointForward.p90 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.riskCasePointForward.p90));
        this.EconomicModel.ceStartYear = this.utilityService.AllowNoDecimal(Number(this.EconomicModel.ceStartYear));
        this.EconomicModel.ce.developCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.developCasePointForward.ev));
        this.EconomicModel.ceEndYear = this.utilityService.AllowNoDecimal(Number(this.EconomicModel.ceEndYear));
        this.EconomicModel.ce.developCaseFullCycle.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.developCaseFullCycle.ev));

        this.ExecutionModel = res.execution;
        this.ExecutionModel.arceProbabilistic.ev = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.arceProbabilistic.ev));
        this.ExecutionModel.arceProbabilistic.p10 = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.arceProbabilistic.p10));
        this.ExecutionModel.arceProbabilistic.p50 = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.arceProbabilistic.p50));
        this.ExecutionModel.arceProbabilistic.p90 = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.arceProbabilistic.p90));
        this.ExecutionModel.arExecuteSchedule.ev = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.arExecuteSchedule.ev));
        this.ExecutionModel.arExecuteSchedule.p10 = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.arExecuteSchedule.p10));
        this.ExecutionModel.arExecuteSchedule.p90 = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.arExecuteSchedule.p90));
        this.ExecutionModel.ceAppropriatedAmount = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.ceAppropriatedAmount));
        this.ExecutionModel.ceProbabilistic.ev = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.ceProbabilistic.ev));
        this.ExecutionModel.ceProbabilistic.p10 = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.ceProbabilistic.p10));
        this.ExecutionModel.ceProbabilistic.p90 = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.ceProbabilistic.p90));
        this.ExecutionModel.executeSchedule.ev = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.executeSchedule.ev));
        this.ExecutionModel.executeSchedule.p10 = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.executeSchedule.p10));
        this.ExecutionModel.executeSchedule.p90 = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.executeSchedule.p90));
        this.ExecutionModel.benchCapitalCost.ar = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.benchCapitalCost.ar));
        this.ExecutionModel.benchCapitalCost.topQuartile = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.benchCapitalCost.topQuartile));
        this.ExecutionModel.benchCapitalCost.industryAverage = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.benchCapitalCost.industryAverage));
        this.ExecutionModel.benchExecute.ar = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.benchExecute.ar));
        this.ExecutionModel.benchExecute.topQuartile = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.benchExecute.topQuartile));
        this.ExecutionModel.benchExecute.industryAverage = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.benchExecute.industryAverage));
        this.BusinessModel = res.business;
        this.DesignCommercialModel = res.design;
        this.ExecutionTargetAssuranceModel = res.target;
        this.ArId = res.intArId;
      }
    });
  }

  onReady(editor, name) {
    editor.ui.focusTracker.on('change:isFocused', (evt, name1, isFocused) => {
      if (!isFocused) {
        if (!this.utilityService.isFontSizeDropdownOpen) {
          if (name == 'Business')
            this.UpdatePart2BusinessData();
          else if (name == 'Design')
            this.UpdatePart2DesignCommercialData();
          else if (name == 'Target')
            this.UpdatePart2ExecutionTargetAssuranceData();
        }
      }
    });
  }
  hasComment(section: string, controlId: string) {
    return this.comments.filter(x => x.section == section && x.controlId == controlId)[0] == undefined ? false : this.comments.filter(x => x.section == section && x.controlId == controlId)[0].comment.length > 0;
  }
  GetComments(section: string, controlId: string) {
    let res = this.comments.filter(x => x.section == section && x.controlId == controlId)[0];
    res = res == undefined ? new EaCommentModel() : res;
    const dialogData = new CommentDialogModel("Update Comment", res.comment, this.isOnHold, this.isReadOnly);
    let comment = '';
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: "600px",
      data: dialogData
    });
    dialogRef.componentInstance.Comment.subscribe((data) => {
      comment = data;
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        res.modifiedBy = this.provId;
        res.comment = comment;
        res.supplementId = this.supplementId;
        res.section = section;
        res.controlId = controlId;
        this.eaSupplementService.UpdateComments(res).subscribe(result => {
          this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
          this.comments = result;
        });
      }
    });
  }
  UpdatePart2OperationData() {
    this.OperationModel.arUltimateRecovery.netMmbo.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmbo.ev));
    this.OperationModel.arUltimateRecovery.netMmbo.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmbo.p10));
    this.OperationModel.arUltimateRecovery.netMmbo.p50 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmbo.p50));
    this.OperationModel.arUltimateRecovery.netMmbo.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmbo.p90));
    this.OperationModel.arUltimateRecovery.netBcf.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netBcf.ev));
    this.OperationModel.arUltimateRecovery.netBcf.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netBcf.p10));
    this.OperationModel.arUltimateRecovery.netBcf.p50 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netBcf.p50));
    this.OperationModel.arUltimateRecovery.netBcf.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netBcf.p90));
    this.OperationModel.arUltimateRecovery.netMmboe.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmboe.ev));
    this.OperationModel.arUltimateRecovery.netMmboe.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmboe.p10));
    this.OperationModel.arUltimateRecovery.netMmboe.p50 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmboe.p50));
    this.OperationModel.arUltimateRecovery.netMmboe.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.netMmboe.p90));
    this.OperationModel.arUltimateRecovery.mmboe8.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.mmboe8.ev));
    this.OperationModel.arUltimateRecovery.mmboe8.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.mmboe8.p10));
    this.OperationModel.arUltimateRecovery.mmboe8.p50 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.mmboe8.p50));
    this.OperationModel.arUltimateRecovery.mmboe8.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.arUltimateRecovery.mmboe8.p90));
    this.OperationModel.ultimateRecovery.netMmbo.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netMmbo.ev));
    this.OperationModel.ultimateRecovery.netMmbo.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netMmbo.p10));
    this.OperationModel.ultimateRecovery.netMmbo.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netMmbo.p90));
    this.OperationModel.ultimateRecovery.netBcf.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netBcf.ev));
    this.OperationModel.ultimateRecovery.netBcf.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netBcf.p10));
    this.OperationModel.ultimateRecovery.netBcf.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netBcf.p90));
    this.OperationModel.ultimateRecovery.netMmboe.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netMmboe.ev));
    this.OperationModel.ultimateRecovery.netMmboe.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netMmboe.p10));
    this.OperationModel.ultimateRecovery.netMmboe.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.netMmboe.p90));
    this.OperationModel.ultimateRecovery.mmboe8.ev = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.mmboe8.ev));
    this.OperationModel.ultimateRecovery.mmboe8.p10 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.mmboe8.p10));
    this.OperationModel.ultimateRecovery.mmboe8.p90 = this.utilityService.AllowNoDecimal(Number(this.OperationModel.ultimateRecovery.mmboe8.p90));
    this.OperationModel.modifiedBy = this.provId;
    this.eaSupplementService.UpdatePart2OperationData(this.OperationModel).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }
  UpdateOperationCheckboxes(event: any, checktype: string) {
    if (checktype == "Exploration") { this.OperationModel.explorationProspectData = event.target.checked; }
    else if (checktype == "Subsurface") { this.OperationModel.subsurfaceBasisOfDesign = event.target.checked; }
    this.UpdatePart2OperationData();
  }
  UpdatePart2EconomicData() {
    const date = new Date(this.EconomicModel.cashFlowAR!);
    if (this.EconomicModel.cashFlowAR != null) {
      this.EconomicModel.cashFlowAR = this.notifyService.ConvertDateBeforeSave(date);
    }
    this.EconomicModel.explorationWellSuccess = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.explorationWellSuccess));
    this.EconomicModel.explorationWellDevelopmentSuccess = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.explorationWellDevelopmentSuccess));
    this.EconomicModel.explorationWellDevelopment = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.explorationWellDevelopment));
    this.EconomicModel.appraisalWellDevelopment = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.appraisalWellDevelopment));
    this.EconomicModel.arNpv.riskCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arNpv.riskCasePointForward.ev));
    this.EconomicModel.arNpv.riskCasePointForward.p10 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arNpv.riskCasePointForward.p10));
    this.EconomicModel.arNpv.riskCasePointForward.p90 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arNpv.riskCasePointForward.p90));
    this.EconomicModel.npv.riskCasePointForward.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.npv.riskCasePointForward.arev));
    this.EconomicModel.npv.riskCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.npv.riskCasePointForward.ev));
    this.EconomicModel.npv.riskCasePointForward.p10 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.npv.riskCasePointForward.p10));
    this.EconomicModel.npv.riskCasePointForward.p90 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.npv.riskCasePointForward.p90));
    this.EconomicModel.arNpv.developCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arNpv.developCasePointForward.ev));
    this.EconomicModel.npv.developCasePointForward.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.npv.developCasePointForward.arev));
    this.EconomicModel.npv.developCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.npv.developCasePointForward.ev));

    this.EconomicModel.arDpi.riskCasePointForward.ev = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.arDpi.riskCasePointForward.ev));
    this.EconomicModel.arDpi.riskCasePointForward.p10 = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.arDpi.riskCasePointForward.p10));
    this.EconomicModel.arDpi.riskCasePointForward.p90 = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.arDpi.riskCasePointForward.p90));
    this.EconomicModel.dpi.riskCasePointForward.arev = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.dpi.riskCasePointForward.arev));
    this.EconomicModel.arDpi.developCasePointForward.ev = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.arDpi.developCasePointForward.ev));
    this.EconomicModel.dpi.developCasePointForward.arev = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.dpi.developCasePointForward.arev));
    this.EconomicModel.dpi.riskCasePointForward.ev = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.dpi.riskCasePointForward.ev));
    this.EconomicModel.dpi.riskCasePointForward.p10 = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.dpi.riskCasePointForward.p10));
    this.EconomicModel.dpi.riskCasePointForward.p90 = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.dpi.riskCasePointForward.p90));
    this.EconomicModel.dpi.developCasePointForward.ev = this.utilityService.AllowTwoDecimal(Number(this.EconomicModel.dpi.developCasePointForward.ev));

    this.EconomicModel.arRor.riskCasePointForward.ev = this.EconomicModel.arRor.riskCasePointForward.ev == '' || this.EconomicModel.arRor.riskCasePointForward.ev == null ? '' : parseFloat(this.EconomicModel.arRor.riskCasePointForward.ev);
    this.EconomicModel.arRor.riskCasePointForward.p10 = this.EconomicModel.arRor.riskCasePointForward.p10 == '' || this.EconomicModel.arRor.riskCasePointForward.p10 == null ? '' : parseFloat(this.EconomicModel.arRor.riskCasePointForward.p10);
    this.EconomicModel.arRor.riskCasePointForward.p90 = this.EconomicModel.arRor.riskCasePointForward.p90 == '' || this.EconomicModel.arRor.riskCasePointForward.p90 == null ? '' : parseFloat(this.EconomicModel.arRor.riskCasePointForward.p90);
    this.EconomicModel.ror.riskCasePointForward.arev = this.EconomicModel.ror.riskCasePointForward.arev == '' || this.EconomicModel.ror.riskCasePointForward.arev == null ? '' : parseFloat(this.EconomicModel.ror.riskCasePointForward.arev);
    this.EconomicModel.arRor.developCasePointForward.ev = this.EconomicModel.arRor.developCasePointForward.ev == '' || this.EconomicModel.arRor.developCasePointForward.ev == null ? '' : parseFloat(this.EconomicModel.arRor.developCasePointForward.ev);
    this.EconomicModel.ror.developCasePointForward.arev = this.EconomicModel.ror.developCasePointForward.arev == '' || this.EconomicModel.ror.developCasePointForward.arev == null ? '' : parseFloat(this.EconomicModel.ror.developCasePointForward.arev);
    this.EconomicModel.arRor.developCaseFullCycle.ev = this.EconomicModel.arRor.developCaseFullCycle.ev == '' || this.EconomicModel.arRor.developCaseFullCycle.ev == null ? '' : parseFloat(this.EconomicModel.arRor.developCaseFullCycle.ev);
    this.EconomicModel.ror.developCaseFullCycle.arev = this.EconomicModel.ror.developCaseFullCycle.arev == '' || this.EconomicModel.ror.developCaseFullCycle.arev == null ? '' : parseFloat(this.EconomicModel.ror.developCaseFullCycle.arev);
    this.EconomicModel.ror.developCaseFullCycle.ev  = this.EconomicModel.ror.developCaseFullCycle.ev == '' || this.EconomicModel.ror.developCaseFullCycle.ev == null ? '' : parseFloat(this.EconomicModel.ror.developCaseFullCycle.ev);
    this.EconomicModel.ror.riskCasePointForward.ev =  this.EconomicModel.ror.riskCasePointForward.ev == '' || this.EconomicModel.ror.riskCasePointForward.ev == null ? '' : parseFloat(this.EconomicModel.ror.riskCasePointForward.ev);
    this.EconomicModel.ror.riskCasePointForward.p10 =  this.EconomicModel.ror.riskCasePointForward.p10 == '' || this.EconomicModel.ror.riskCasePointForward.p10 == null ? '' : parseFloat(this.EconomicModel.ror.riskCasePointForward.p10);
    this.EconomicModel.ror.riskCasePointForward.p90 =  this.EconomicModel.ror.riskCasePointForward.p90 == '' || this.EconomicModel.ror.riskCasePointForward.p90 == null ? '' : parseFloat(this.EconomicModel.ror.riskCasePointForward.p90);
    this.EconomicModel.ror.developCasePointForward.ev =  this.EconomicModel.ror.developCasePointForward.ev == '' || this.EconomicModel.ror.developCasePointForward.ev == null ? '' : parseFloat(this.EconomicModel.ror.developCasePointForward.ev);

    this.EconomicModel.arce.riskCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arce.riskCasePointForward.ev));
    this.EconomicModel.arce.riskCasePointForward.p10 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arce.riskCasePointForward.p10));
    this.EconomicModel.arce.riskCasePointForward.p90 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arce.riskCasePointForward.p90));
    this.EconomicModel.ce.riskCasePointForward.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.riskCasePointForward.arev));
    this.EconomicModel.arce.developCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arce.developCasePointForward.ev));
    this.EconomicModel.ce.developCasePointForward.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.developCasePointForward.arev));
    this.EconomicModel.arce.developCaseFullCycle.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arce.developCaseFullCycle.ev));
    this.EconomicModel.ce.developCaseFullCycle.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.developCaseFullCycle.arev));
    this.EconomicModel.ce.riskCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.riskCasePointForward.ev));
    this.EconomicModel.ce.riskCasePointForward.p10 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.riskCasePointForward.p10));
    this.EconomicModel.ce.riskCasePointForward.p90 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.riskCasePointForward.p90));
    this.EconomicModel.ceStartYear = this.utilityService.AllowNoDecimal(Number(this.EconomicModel.ceStartYear));
    this.EconomicModel.ce.developCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.developCasePointForward.ev));
    this.EconomicModel.ceEndYear = this.utilityService.AllowNoDecimal(Number(this.EconomicModel.ceEndYear));
    this.EconomicModel.ce.developCaseFullCycle.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ce.developCaseFullCycle.ev));
    this.EconomicModel.modifiedBy = this.provId;
    this.eaSupplementService.UpdatePart2EconomicData(this.EconomicModel).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
    if (this.EconomicModel.arRor.riskCasePointForward.ev != null) {
      this.EconomicModel.arRor.riskCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arRor.riskCasePointForward.ev));
      this.EconomicModel.arRor.riskCasePointForward.ev = this.EconomicModel.arRor.riskCasePointForward.ev + '%';
    }
    if (this.EconomicModel.arRor.riskCasePointForward.p10 != null) {
      this.EconomicModel.arRor.riskCasePointForward.p10 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arRor.riskCasePointForward.p10));
      this.EconomicModel.arRor.riskCasePointForward.p10 = this.EconomicModel.arRor.riskCasePointForward.p10 + '%';
    }
    if (this.EconomicModel.arRor.riskCasePointForward.p90 != null) {
    this.EconomicModel.arRor.riskCasePointForward.p90 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arRor.riskCasePointForward.p90));
    this.EconomicModel.arRor.riskCasePointForward.p90 = this.EconomicModel.arRor.riskCasePointForward.p90 + '%';
    }
    if (this.EconomicModel.ror.riskCasePointForward.arev != null) {
    this.EconomicModel.ror.riskCasePointForward.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.riskCasePointForward.arev));
    this.EconomicModel.ror.riskCasePointForward.arev = this.EconomicModel.ror.riskCasePointForward.arev + '%';
    }
    if (this.EconomicModel.arRor.developCasePointForward.ev != null) {
    this.EconomicModel.arRor.developCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arRor.developCasePointForward.ev));
    this.EconomicModel.arRor.developCasePointForward.ev = this.EconomicModel.arRor.developCasePointForward.ev + '%';
    }
    if (this.EconomicModel.ror.developCasePointForward.arev != null) {
    this.EconomicModel.ror.developCasePointForward.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.developCasePointForward.arev));
    this.EconomicModel.ror.developCasePointForward.arev = this.EconomicModel.ror.developCasePointForward.arev + '%';
    }
    if (this.EconomicModel.arRor.developCaseFullCycle.ev != null) {
    this.EconomicModel.arRor.developCaseFullCycle.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.arRor.developCaseFullCycle.ev));
    this.EconomicModel.arRor.developCaseFullCycle.ev = this.EconomicModel.arRor.developCaseFullCycle.ev + '%';
    }
    if (this.EconomicModel.ror.developCaseFullCycle.arev != null) {
    this.EconomicModel.ror.developCaseFullCycle.arev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.developCaseFullCycle.arev));
    this.EconomicModel.ror.developCaseFullCycle.arev = this.EconomicModel.ror.developCaseFullCycle.arev + '%';
    }
    if (this.EconomicModel.ror.developCaseFullCycle.ev != null) {
    this.EconomicModel.ror.developCaseFullCycle.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.developCaseFullCycle.ev));
    this.EconomicModel.ror.developCaseFullCycle.ev = this.EconomicModel.ror.developCaseFullCycle.ev + '%';
    }
    if (this.EconomicModel.ror.riskCasePointForward.ev != null) {
    this.EconomicModel.ror.riskCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.riskCasePointForward.ev));
    this.EconomicModel.ror.riskCasePointForward.ev = this.EconomicModel.ror.riskCasePointForward.ev + '%';
    }
    if (this.EconomicModel.ror.riskCasePointForward.p10 != null) {
    this.EconomicModel.ror.riskCasePointForward.p10 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.riskCasePointForward.p10));
    this.EconomicModel.ror.riskCasePointForward.p10 = this.EconomicModel.ror.riskCasePointForward.p10 + '%';
    }
    if (this.EconomicModel.ror.riskCasePointForward.p90 != null) {
    this.EconomicModel.ror.riskCasePointForward.p90 = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.riskCasePointForward.p90));
    this.EconomicModel.ror.riskCasePointForward.p90 = this.EconomicModel.ror.riskCasePointForward.p90 + '%';
    }
    if (this.EconomicModel.ror.developCasePointForward.ev != null) {
    this.EconomicModel.ror.developCasePointForward.ev = this.utilityService.AllowOneDecimal(Number(this.EconomicModel.ror.developCasePointForward.ev));
    this.EconomicModel.ror.developCasePointForward.ev = this.EconomicModel.ror.developCasePointForward.ev + '%';
    }
  }
  UpdatePart2ExecutionData() {
    const date = new Date(this.ExecutionModel.arStartupProduction.ev!);
    const date1 = new Date(this.ExecutionModel.arStartupProduction.p10!);
    const date2 = new Date(this.ExecutionModel.arStartupProduction.p90!);
    const date3 = new Date(this.ExecutionModel.startupProduction.ev!);
    const date4 = new Date(this.ExecutionModel.startupProduction.p10!);
    const date5 = new Date(this.ExecutionModel.startupProduction.p90!);
    if (this.ExecutionModel.arStartupProduction.ev != null) {
      this.ExecutionModel.arStartupProduction.ev = this.notifyService.ConvertDateBeforeSave(date);
    }
    if (this.ExecutionModel.arStartupProduction.p10 != null) {
      this.ExecutionModel.arStartupProduction.p10 = this.notifyService.ConvertDateBeforeSave(date1);
    }
    if (this.ExecutionModel.arStartupProduction.p90 != null) {
      this.ExecutionModel.arStartupProduction.p90 = this.notifyService.ConvertDateBeforeSave(date2);
    }
    if (this.ExecutionModel.startupProduction.ev != null) {
      this.ExecutionModel.startupProduction.ev = this.notifyService.ConvertDateBeforeSave(date3);
    }
    if (this.ExecutionModel.startupProduction.p10 != null) {
      this.ExecutionModel.startupProduction.p10 = this.notifyService.ConvertDateBeforeSave(date4);
    }
    if (this.ExecutionModel.startupProduction.p90 != null) {
      this.ExecutionModel.startupProduction.p90 = this.notifyService.ConvertDateBeforeSave(date5);
    }
    this.ExecutionModel.arceProbabilistic.ev = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.arceProbabilistic.ev));
    this.ExecutionModel.arceProbabilistic.p10 = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.arceProbabilistic.p10));
    this.ExecutionModel.arceProbabilistic.p50 = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.arceProbabilistic.p50));
    this.ExecutionModel.arceProbabilistic.p90 = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.arceProbabilistic.p90));
    this.ExecutionModel.arExecuteSchedule.ev = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.arExecuteSchedule.ev));
    this.ExecutionModel.arExecuteSchedule.p10 = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.arExecuteSchedule.p10));
    this.ExecutionModel.arExecuteSchedule.p90 = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.arExecuteSchedule.p90));
    this.ExecutionModel.ceAppropriatedAmount = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.ceAppropriatedAmount));
    this.ExecutionModel.ceProbabilistic.ev = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.ceProbabilistic.ev));
    this.ExecutionModel.ceProbabilistic.p10 = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.ceProbabilistic.p10));
    this.ExecutionModel.ceProbabilistic.p90 = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.ceProbabilistic.p90));
    this.ExecutionModel.executeSchedule.ev = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.executeSchedule.ev));
    this.ExecutionModel.executeSchedule.p10 = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.executeSchedule.p10));
    this.ExecutionModel.executeSchedule.p90 = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.executeSchedule.p90));
    this.ExecutionModel.benchCapitalCost.ar = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.benchCapitalCost.ar));
    this.ExecutionModel.benchCapitalCost.topQuartile = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.benchCapitalCost.topQuartile));
    this.ExecutionModel.benchCapitalCost.industryAverage = this.utilityService.AllowOneDecimal(Number(this.ExecutionModel.benchCapitalCost.industryAverage));
    this.ExecutionModel.benchExecute.ar = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.benchExecute.ar));
    this.ExecutionModel.benchExecute.topQuartile = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.benchExecute.topQuartile));
    this.ExecutionModel.benchExecute.industryAverage = this.utilityService.AllowNoDecimal(Number(this.ExecutionModel.benchExecute.industryAverage));
    this.ExecutionModel.modifiedBy = this.provId;
    this.eaSupplementService.UpdatePart2ExecutionData(this.ExecutionModel).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });

    if (this.ExecutionModel.arStartupProduction.ev != null) {
      this.ExecutionModel.arStartupProduction.ev = this.notifyService.ConvertDateAfterSave(date);
    }
    if (this.ExecutionModel.arStartupProduction.p10 != null) {
      this.ExecutionModel.arStartupProduction.p10 = this.notifyService.ConvertDateAfterSave(date1);
    }
    if (this.ExecutionModel.arStartupProduction.p90 != null) {
      this.ExecutionModel.arStartupProduction.p90 = this.notifyService.ConvertDateAfterSave(date2);
    }
    if (this.ExecutionModel.startupProduction.ev != null) {
      this.ExecutionModel.startupProduction.ev = this.notifyService.ConvertDateAfterSave(date3);
    }
    if (this.ExecutionModel.startupProduction.p10 != null) {
      this.ExecutionModel.startupProduction.p10 = this.notifyService.ConvertDateAfterSave(date4);
    }
    if (this.ExecutionModel.startupProduction.p90 != null) {
      this.ExecutionModel.startupProduction.p90 = this.notifyService.ConvertDateAfterSave(date5);
    }
  }
  UpdatePart2BusinessData() {
    this.BusinessModel.modifiedBy = this.provId;
    this.eaSupplementService.UpdatePart2BusinessData(this.BusinessModel).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }
  UpdatePart2DesignCommercialData() {
    this.DesignCommercialModel.modifiedBy = this.provId;
    this.eaSupplementService.UpdatePart2DesignCommercialData(this.DesignCommercialModel).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }
  UpdatePart2ExecutionTargetAssuranceData() {
    this.ExecutionTargetAssuranceModel.modifiedBy = this.provId;
    this.eaSupplementService.UpdatePart2ExecutionTargetAssuranceData(this.ExecutionTargetAssuranceModel).subscribe(res => {
      this.notifyService.showSuccess("Data Loaded/Saved Successfully !!", "");
    });
  }
  UpdateTargetAssuranceCheckboxes(event: any, checktype: string) {
    if (checktype == "CostEstimates") { this.ExecutionTargetAssuranceModel.attachmentsCostEstimates = event.target.checked; }
    else if (checktype == "MilestoneProject") { this.ExecutionTargetAssuranceModel.attachmentsMilestoneProject = event.target.checked; }
    this.UpdatePart2ExecutionTargetAssuranceData();
  }
}
