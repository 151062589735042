<h3 *ngIf="ErrorMsg=='true'"> 20 Line input data have not yet been imported!</h3>

<div class="fid-form" *ngIf="ErrorMsg==''">
    <div class="row">
        <div class="col-sm-3 col-xs-12">
            <div class="row">
                <ol class="no-bullet">
                    <li>
                        <div class="bar"></div> Mid Price case
                    </li>
                    <li>
                        <div class="dash-short"></div> High Price case
                    </li>
                    <li>
                        <div class="dash-long"></div> Low Price case
                    </li>
                </ol>
            </div>
        </div>
            <div class="col-sm-4 msg">
                <strong style="color: black;">Maximum number of words allowed in each comment text box is 60. In the case more words were migrated
                    from previous version,
                    please check as the text could be truncated.</strong>
            </div>
            <div class="col-sm-4 col-xs-12">
            <div class="row">
                <h5 *ngIf="this.YearErrorMsg=='true'" style="color:red"> From and To Dates are not within the limits of
                    Import data</h5>
                <h5 *ngIf="this.YearErrorMsg==''" style="color:red"></h5>

                <div class="form-group col-sm-4 col-xs-4">
                    <label class="control-label form-label">&nbsp;</label>
                    <label class="control-label form-label">Project data shown</label>
                    <span class="glyphicon glyphicon-question-sign" (click)="ShowChartModal()"></span>
                    <label *ngIf="!form.IsOldVersionOfForms" class="control-label form-label">FEED Forward Basis</label>
                </div>
                <div class="form-group col-sm-4 col-xs-4">
                    <label class="control-label form-label">From</label>

                    <input type="number" [(ngModel)]="formconfiguration.chartStartYear"
                        (change)="ChangeYears(formconfiguration)" class="form-control"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel" />
                </div>
                <div class="form-group col-sm-4 col-xs-4">
                    <label class="control-label form-label">To</label>
                    <input type="number" class="form-control" (change)="ChangeYears(formconfiguration)"
                        [(ngModel)]="formconfiguration.chartEndYear"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel" />
                </div>
            </div>
        </div>
    </div>
    <br />
    <!-- New Form-->
    <div *ngIf="!form.IsOldVersionOfForms">
        <!-- Upstream Charts -->

        <div *ngIf="MainChartsModel.isUpstream">
            <!--capitalAndExpenditureChart--->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarupstreamchartsModel.capitalAndExpenditureChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="chartCapitalAndExpenditure"></div>
                    <div class="col-xs-12">
                        <p-chart #chartCE type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="capitalAndExpenditureChartData"
                            [options]="ReturnChartOtions(formconfiguration.capitalAndExpenditureChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.capitalAndExpenditureChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,1,CeComment.comments)" *ngIf="!CeComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="CeComment.comments"
                            (click)="SaveFormComments(301,1,CeComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--Production Chart -->
            <div class="col-sm-6 col-xs-12 chart-container" *ngIf="VarupstreamchartsModel.productionChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartProd type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="ProductionChartData"
                            [options]="ReturnChartOtions(formconfiguration.productionChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.productionChartDescription" (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,2,ProductionComment.comments)"
                            *ngIf="!ProductionComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="ProductionComment.comments"
                            (click)="SaveFormComments(301,2,ProductionComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!---afterTaxCashflow Chart--->
            <div class="col-sm-6 col-xs-12 chart-container" *ngIf="VarupstreamchartsModel.afterCashFlowChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="chartCapitalAndExpenditure"></div>
                    <div class="col-xs-12">
                        <p-chart #chartAT type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="ATcashflowChartData"
                            [options]="ReturnChartOtions(formconfiguration.afterTaxCashflowChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.afterTaxCashflowChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,3,AfterCashflowComment.comments)"
                            *ngIf="!AfterCashflowComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="AfterCashflowComment.comments"
                            (click)="SaveFormComments(301,3,AfterCashflowComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--cumulativeAtcashflow Chart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarupstreamchartsModel.cumulativeATCashflowChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="cumulativeAtcashflowChartDescription"></div>
                    <div class="col-xs-12">
                        <p-chart #chartCAT type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="cumulativeATCashflowChartData"
                            [options]="ReturnChartOtions(formconfiguration.cumulativeATCashflowChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.cumulativeAtcashflowChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,5,CumulativeComment.comments)"
                            *ngIf="!CumulativeComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="CumulativeComment.comments"
                            (click)="SaveFormComments(301,5,CumulativeComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--Earnings Charts-->
            <div class="col-sm-6 col-xs-12 chart-container" *ngIf="VarupstreamchartsModel.earningsChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="cumulativeAtcashflowChartDescription"></div>
                    <div class="col-xs-12">
                        <p-chart #chartEarn type="bar" height="450" class="chart-width" style="display: block;"
                            width="735" [data]="earningsChartData"
                            [options]="ReturnChartOtions(formconfiguration.earningsChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.earningsChartDescription" (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,7,EarningsComment.comments)"
                            *ngIf="!EarningsComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="EarningsComment.comments"
                            (click)="SaveFormComments(301,7,EarningsComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--earningPerBarrel Chart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarupstreamchartsModel.earningsPerBarrelChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="earningPerBarrelChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartBarrel type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="earningsPerBarrelChartData"
                            [options]="ReturnChartOtions(formconfiguration.earningPerBarrelChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.earningsPerBarrelChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,4,EarningPerBarComment.comments)"
                            *ngIf="!EarningPerBarComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="EarningPerBarComment.comments"
                            (click)="SaveFormComments(301,4,EarningPerBarComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--Operating chart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarupstreamchartsModel.operatingCashMarginChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="cumulativeAtcashflowChartDescription"></div>
                    <div class="col-xs-12">
                        <p-chart #chartOperate type="bar" class="chart-width" height="450" style="display: block;"
                            width="700" [data]="operatingCashMarginChartData"
                            [options]="ReturnChartOtions(formconfiguration.operatingCashMarginChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.operatingCashMarginChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,8,operationComment.comments)"
                            *ngIf="!operationComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="operationComment.comments"
                            (click)="SaveFormComments(301,8,operationComment.comments)"></i>
                    </div>
                </div>
            </div>

            <!--NRI Booking Chart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarupstreamchartsModel.nriReservesBookingChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="cumulativeAtcashflowChartDescription"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNri type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="nriReservesBookingChartData"
                            [options]="ReturnChartOtions(formconfiguration.nriReservesBookingsChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.nriReservesBookingChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,6,nriReservesComment.comments)"
                            *ngIf="!nriReservesComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="nriReservesComment.comments"
                            (click)="SaveFormComments(301,6,nriReservesComment.comments)"></i>
                    </div>
                </div>
            </div>
        </div>
        <!--NOP USPtream-->
        <div *ngIf="!MainChartsModel.isUpstream">

            <!--NonUpstreamCapitalAndExpenditureChart Chart -->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamCapitalAndExpenditureChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNCE type="bar" height="450" class="chart-width" style="display: block;"
                            width="735" [data]="NonUpstreamCapitalAndExpenditureChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamCapitalAndExpenditureChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.nonUpstreamCapitalAndExpenditureChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,9,noncapxComment.comments)"
                            *ngIf="!noncapxComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="noncapxComment.comments"
                            (click)="SaveFormComments(301,9,noncapxComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--Volume Chart -->
            <div class="col-sm-6 col-xs-12 chart-container" *ngIf="VarnonupstreamchartsModel.volumeChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNVolume type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="VolumeChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamVolumeChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.nonUpstreamVolumeChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,10,nonvolumeComment.comments)"
                            *ngIf="!nonvolumeComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="nonvolumeComment.comments"
                            (click)="SaveFormComments(301,10,nonvolumeComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!---NonUpstreamAfterTaxCashFlowChartData-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamAfterTaxCashFlowChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNAT type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="NonUpstreamAfterTaxCashFlowChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamAfterTaxCashFlowChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.nonUpstreamAfterTaxCashFlowChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,11,nonATFComment.comments)"
                            *ngIf="!nonATFComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="nonATFComment.comments"
                            (click)="SaveFormComments(301,11,nonATFComment.comments)"></i>
                    </div>
                </div>
            </div>

            <!---NonUpstreamCumulativeCashChart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamCumulativeCashChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNCAT type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="NonUpstreamCumulativeCashChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamCumulativeCashChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.nonUpstreamCumulativeCashChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,12,noncumuComment.comments)"
                            *ngIf="!noncumuComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="noncumuComment.comments"
                            (click)="SaveFormComments(301,12,noncumuComment.comments)"></i>
                    </div>
                </div>
            </div>

            <!---NonUpstreamEarningsBeforeInterestChart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamEarningsBeforeInterestChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNEarn type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="NonUpstreamEarningsBeforeInterestChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamEarningsBeforeInterestChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.nonUpstreamEarningsBeforeInterestChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,13,ebitdaComment.comments)"
                            *ngIf="!ebitdaComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="ebitdaComment.comments"
                            (click)="SaveFormComments(301,13,ebitdaComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!---  NonUpstreamOperatingExpenseChart     --->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamOperatingExpenseChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNOperate type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="NonUpstreamOperatingExpenseChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamOperatingExpenseChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.nonUpstreamOperatingExpenseChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,14,opexComment.comments)" *ngIf="!opexComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="opexComment.comments"
                            (click)="SaveFormComments(301,14,opexComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--ROCE Chart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamReturnOnCapitalEmployedChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartRoce type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="NonUpstreamReturnOnCapitalEmployedChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamReturnOnCapitalEmployedChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.nonUpstreamReturnOnCapitalEmployedChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
             </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,15,rocecomment.comments)" *ngIf="!rocecomment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="rocecomment.comments"
                            (click)="SaveFormComments(301,15,rocecomment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--NonUpstreamOperationalEarningsAfterTaxChart Chart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamOperationalEarningsAfterTaxChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartOeat type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="NonUpstreamOperationalEarningsAfterTaxChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamOperationalEarningsAfterTaxChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width"
                        style="resize: none;overflow: hidden;height: 80px" placeholder="Description" rows="3"
                        (keypress)="limitTextToShow($event)" (input)="TrimChartDescription()"
                        [(ngModel)]="formchartinfo.nonUpstreamOperationalEarningsAfterTaxChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
          </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,16,oeatcomment.comments)" *ngIf="!oeatcomment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="oeatcomment.comments"
                            (click)="SaveFormComments(301,16,oeatcomment.comments)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--OLD form charts-->
    <div *ngIf="form.IsOldVersionOfForms">
        <!-- Upstream Charts -->
        <div *ngIf="MainChartsModel.isUpstream">
            <!--capitalAndExpenditureChart--->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarupstreamchartsModel.capitalAndExpenditureChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="chartCapitalAndExpenditure"></div>
                    <div class="col-xs-12">
                        <p-chart #chartCE type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="capitalAndExpenditureChartData"
                            [options]="ReturnChartOtions(formconfiguration.capitalAndExpenditureChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.capitalAndExpenditureChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,1,CeComment.comments)" *ngIf="!CeComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="CeComment.comments"
                            (click)="SaveFormComments(301,1,CeComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--Production Chart -->
            <div class="col-sm-6 col-xs-12 chart-container" *ngIf="VarupstreamchartsModel.productionChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartProd type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="ProductionChartData"
                            [options]="ReturnChartOtions(formconfiguration.productionChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.productionChartDescription" (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,2,ProductionComment.comments)"
                            *ngIf="!ProductionComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="ProductionComment.comments"
                            (click)="SaveFormComments(301,2,ProductionComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!---afterTaxCashflow Chart--->
            <div class="col-sm-6 col-xs-12 chart-container" *ngIf="VarupstreamchartsModel.afterCashFlowChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="chartCapitalAndExpenditure"></div>
                    <div class="col-xs-12">
                        <p-chart #chartAT type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="ATcashflowChartData"
                            [options]="ReturnChartOtions(formconfiguration.afterTaxCashflowChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.afterTaxCashflowChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,3,AfterCashflowComment.comments)"
                            *ngIf="!AfterCashflowComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="AfterCashflowComment.comments"
                            (click)="SaveFormComments(301,3,AfterCashflowComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--earningPerBarrel Chart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarupstreamchartsModel.earningsPerBarrelChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="earningPerBarrelChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartBarrel type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="earningsPerBarrelChartData"
                            [options]="ReturnChartOtions(formconfiguration.earningPerBarrelChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.earningsPerBarrelChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,4,EarningPerBarComment.comments)"
                            *ngIf="!EarningPerBarComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="EarningPerBarComment.comments"
                            (click)="SaveFormComments(301,4,EarningPerBarComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--cumulativeAtcashflow Chart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarupstreamchartsModel.cumulativeATCashflowChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="cumulativeAtcashflowChartDescription"></div>
                    <div class="col-xs-12">
                        <p-chart #chartCAT type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="cumulativeATCashflowChartData"
                            [options]="ReturnChartOtions(formconfiguration.cumulativeATCashflowChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.cumulativeAtcashflowChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,5,CumulativeComment.comments)"
                            *ngIf="!CumulativeComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="CumulativeComment.comments"
                            (click)="SaveFormComments(301,5,CumulativeComment.comments)"></i>
                    </div>
                </div>
            </div>

            <!--NRI Booking Chart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarupstreamchartsModel.nriReservesBookingChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="cumulativeAtcashflowChartDescription"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNri type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="nriReservesBookingChartData"
                            [options]="ReturnChartOtions(formconfiguration.nriReservesBookingsChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.nriReservesBookingChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,6,nriReservesComment.comments)"
                            *ngIf="!nriReservesComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="nriReservesComment.comments"
                            (click)="SaveFormComments(301,6,nriReservesComment.comments)"></i>
                    </div>
                </div>
            </div>

            <!--Earnings Charts-->
            <div class="col-sm-6 col-xs-12 chart-container" *ngIf="VarupstreamchartsModel.earningsChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="cumulativeAtcashflowChartDescription"></div>
                    <div class="col-xs-12">
                        <p-chart #chartEarn type="bar" height="450" class="chart-width" style="display: block;"
                            width="735" [data]="earningsChartData"
                            [options]="ReturnChartOtions(formconfiguration.earningsChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.earningsChartDescription" (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,7,EarningsComment.comments)"
                            *ngIf="!EarningsComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="EarningsComment.comments"
                            (click)="SaveFormComments(301,7,EarningsComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--Operating chart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarupstreamchartsModel.operatingCashMarginChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="cumulativeAtcashflowChartDescription"></div>
                    <div class="col-xs-12">
                        <p-chart #chartOperate type="bar" class="chart-width" height="450" style="display: block;"
                            width="700" [data]="operatingCashMarginChartData"
                            [options]="ReturnChartOtions(formconfiguration.operatingCashMarginChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.operatingCashMarginChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,8,operationComment.comments)"
                            *ngIf="!operationComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="operationComment.comments"
                            (click)="SaveFormComments(301,8,operationComment.comments)"></i>
                    </div>
                </div>
            </div>
        </div>
        <!--NOP USPtream-->
        <div *ngIf="!MainChartsModel.isUpstream">
            <!--Volume Chart -->
            <div class="col-sm-6 col-xs-12 chart-container" *ngIf="VarnonupstreamchartsModel.volumeChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNVolume type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="VolumeChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamVolumeChartName)"></p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.nonUpstreamVolumeChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
        </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,10,nonvolumeComment.comments)"
                            *ngIf="!nonvolumeComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="nonvolumeComment.comments"
                            (click)="SaveFormComments(301,10,nonvolumeComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!---  NonUpstreamOperatingExpenseChart     --->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamOperatingExpenseChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNOperate type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="NonUpstreamOperatingExpenseChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamOperatingExpenseChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.nonUpstreamOperatingExpenseChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,14,opexComment.comments)" *ngIf="!opexComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="opexComment.comments"
                            (click)="SaveFormComments(301,14,opexComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--NonUpstreamCapitalAndExpenditureChart Chart -->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamCapitalAndExpenditureChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNCE type="bar" height="450" class="chart-width" style="display: block;"
                            width="735" [data]="NonUpstreamCapitalAndExpenditureChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamCapitalAndExpenditureChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.nonUpstreamCapitalAndExpenditureChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                        </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,9,noncapxComment.comments)"
                            *ngIf="!noncapxComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="noncapxComment.comments"
                            (click)="SaveFormComments(301,9,noncapxComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!---NonUpstreamEarningsBeforeInterestChart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamEarningsBeforeInterestChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNEarn type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="NonUpstreamEarningsBeforeInterestChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamEarningsBeforeInterestChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.nonUpstreamEarningsBeforeInterestChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,13,ebitdaComment.comments)"
                            *ngIf="!ebitdaComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="ebitdaComment.comments"
                            (click)="SaveFormComments(301,13,ebitdaComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!---NonUpstreamAfterTaxCashFlowChartData-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamAfterTaxCashFlowChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNAT type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="NonUpstreamAfterTaxCashFlowChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamAfterTaxCashFlowChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.nonUpstreamAfterTaxCashFlowChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,11,nonATFComment.comments)"
                            *ngIf="!nonATFComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="nonATFComment.comments"
                            (click)="SaveFormComments(301,11,nonATFComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--ROCE Chart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamReturnOnCapitalEmployedChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartRoce type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="NonUpstreamReturnOnCapitalEmployedChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamReturnOnCapitalEmployedChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.nonUpstreamReturnOnCapitalEmployedChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
             </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,15,rocecomment.comments)" *ngIf="!rocecomment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="rocecomment.comments"
                            (click)="SaveFormComments(301,15,rocecomment.comments)"></i>
                    </div>
                </div>
            </div>
            <!---NonUpstreamCumulativeCashChart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamCumulativeCashChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartNCAT type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="NonUpstreamCumulativeCashChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamCumulativeCashChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.nonUpstreamCumulativeCashChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,12,noncumuComment.comments)"
                            *ngIf="!noncumuComment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="noncumuComment.comments"
                            (click)="SaveFormComments(301,12,noncumuComment.comments)"></i>
                    </div>
                </div>
            </div>
            <!--NonUpstreamOperationalEarningsAfterTaxChart Chart-->
            <div class="col-sm-6 col-xs-12 chart-container"
                *ngIf="VarnonupstreamchartsModel.nonUpstreamOperationalEarningsAfterTaxChart.isVisible">
                <div class="row">
                    <div class="col-xs-12" id="productionChart"></div>
                    <div class="col-xs-12">
                        <p-chart #chartOeat type="bar" class="chart-width" height="450" style="display: block;"
                            width="735" [data]="NonUpstreamOperationalEarningsAfterTaxChartData"
                            [options]="ReturnChartOtions(formconfiguration.nonUpstreamOperationalEarningsAfterTaxChartName)">
                        </p-chart>
                    </div>
                </div>
                <div class="input-group" style="width: 70%;">
                    <textarea type="text" class="form-control chart-width" placeholder="Description" rows="3"
                        [(ngModel)]="formchartinfo.nonUpstreamOperationalEarningsAfterTaxChartDescription"
                        (change)="UpdateChartdata(formchartinfo)"
                        [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
          </textarea>
                    <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                        <i class="glyphicon glyphicon-comment-outline"
                            (click)="SaveFormComments(301,16,oeatcomment.comments)" *ngIf="!oeatcomment.comments"></i>
                        <i class="glyphicon glyphicon-comment commentColor" *ngIf="oeatcomment.comments"
                            (click)="SaveFormComments(301,16,oeatcomment.comments)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>