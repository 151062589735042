import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Form } from 'src/app/Model/Form.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { OverviewService } from 'src/app/forms/FEEDServices/overview.service';
import { FeedNavService } from 'src/app/forms/FEEDServices/feed-nav.service';
import { FormReviewerService } from 'src/app/forms/FEEDServices/form-reviewer.service';
import { FormAction } from 'src/app/Model/FormAction.model';
import { SubmitApprovalDialogComponent } from '../submit-approval-dialog/submit-approval-dialog.component';
@Component({
  selector: 'app-approval-dialog',
  templateUrl: './approval-dialog.component.html',
  styleUrls: ['./approval-dialog.component.css']
})
export class ApprovalDialogComponent implements OnInit {
  form = new Form();
  formAction = new FormAction();
  versionName: string = '';
  formId: number = 0;
  versionId: number = 0;
  reviewerId: number = 0;
  reviewerComments?: string = '';
  formType: string = '';
  buttonText: string = '';
  buttonflag: boolean = true;
  currentDate: Date = new Date();
  href: string = "";
  bUrl: string = "";
  public version: EventEmitter<any> = new EventEmitter();
  projectId: number = 0;
 

  constructor(public dialogRef: MatDialogRef<ApprovalDialogModel>,
    private router: Router, @Inject(MAT_DIALOG_DATA) public data: ApprovalDialogModel, private overviewService: OverviewService,
    private go36ProjectService: Go36projectService, private feedNavService: FeedNavService,
    private formReviewerService: FormReviewerService, public dialog: MatDialog,
    private route: ActivatedRoute,) {
    dialogRef.disableClose = true;

  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.projectId = params['projectId'];
      });

    this.href = this.router.url;
    this.bUrl = window.location.origin;
    if (this.currentDate) {
      this.buttonflag = false;
    }
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  onContinue(): void {
    this.dialogRef.close();
    this.dialog.open(SubmitApprovalDialogComponent, {
      width: "600px",
      data: { projectId:this.data.projectId,projectName: this.data.projectName, formId: this.data.formId, versionId: this.data.versionId, formType: this.data.formType, reviewerId: this.data.reviewerId, reviewerComments: this.data.reviewerComments },
    });
  }

  DateChange() {
    if (this.currentDate) {
      this.buttonflag = false;
    }
  }
}

export class ApprovalDialogModel {

  constructor(public projectId: number,
    public projectName: string,
    public formId: number,
    public versionId: number,
    public formType: string,
    public reviewerId: number,
    public reviewerComments: string) {
  }
}