import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppsettingsService } from 'src/app/Shared/appsettings.service';
import { OldTemplateDocuments } from 'src/app/Model/OldTemplateDocuments.model';
import { Go36ArHeaderModel } from 'src/app/Model/Go36ArHeader.model';
import { Go36ArSupplementModel } from 'src/app/Model/Go36ArSupplement.model';

@Injectable({
  providedIn: 'root'
})

export class OldTemplateService {
  GetDocumentsUrl = 'api/Documents/getDocuments?arId=';
  DownloadUrl = 'api/Documents/Download?id=';
  getProjectInfoUrl = "api/Documents/GetProjectInfoById?projectId=";
  downloadARPDFUrl = 'api/Documents/DownloadARPDF?arId=';
  headerDataUrl = 'api/Documents/GetGo36ArHeader?arId=';
  UploadARDocumentUrl = 'api/Documents/UploadAr';

  GetSupplementDocumentsUrl = 'api/Documents/getSupplementDocuments?supplementId=';
  SupplementDownloadUrl = 'api/Documents/DownloadSupplement?id=';
  getProjectSupplementInfoUrl = "api/Documents/GetProjectSupplementInfoById?projectId=";
  downloadSupplementPDFUrl = 'api/Documents/DownloadSupplementPDF?supplementId=';
  supplementDataUrl = 'api/Documents/GetGo36ArSupplement?supplementId=';
  UploadSupplementDocumentUrl = 'api/Documents/UploadSupplement';

  constructor(private http: HttpClient, private appsettings: AppsettingsService) { }

  GetDocumentList(arId: number, version:number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetDocumentsUrl + arId + "&version=" + version);
  }

  downloadFile(id: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get<OldTemplateDocuments>(this.appsettings.apiBaseUrl + this.DownloadUrl + id, httpOptions);
  }

  GetProjectInfobyId(projectId: number, arId: number, version:number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getProjectInfoUrl + projectId + "&arId=" + arId + "&version=" + version);
  }

  DownloadARPDF(arId: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get<Go36ArHeaderModel>(this.appsettings.apiBaseUrl + this.downloadARPDFUrl + arId, httpOptions);
  }

  GetHeaderData(arId: number, version:number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.headerDataUrl + arId + '&version=' + version);
  }

  UploadARDocument(arId: number, file: FormData) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UploadARDocumentUrl + '?arId=' + arId, file);
  }

  ////Supplement Functions

  GetSupplementDocumentList(supplementId: number, version:number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetSupplementDocumentsUrl + supplementId + "&version=" + version);
  }

  downloadSupplementFile(id: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get<OldTemplateDocuments>(this.appsettings.apiBaseUrl + this.SupplementDownloadUrl + id, httpOptions);
  }

  GetProjectSupplementInfobyId(projectId: number, supplementId: number, version:number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.getProjectSupplementInfoUrl + projectId + "&supplementId=" + supplementId + "&version=" + version);
  }

  DownloadSupplementPDF(supplementId: number) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get<Go36ArSupplementModel>(this.appsettings.apiBaseUrl + this.downloadSupplementPDFUrl + supplementId, httpOptions);
  }

  GetSupplementData(supplementId: number, version:number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.supplementDataUrl + supplementId + '&version=' + version);
  }

  UploadSupplementDocument(supplementId: number, file: FormData) {
    return this.http.post(this.appsettings.apiBaseUrl + this.UploadSupplementDocumentUrl + '?supplementId=' + supplementId, file);
  }
}
