<div class="row text-center">
    <h4  style="font-size: 1.13em;line-height: 1.22em;">performance details, alternative selection &amp; execution plans</h4>
</div>
<hr style="margin-bottom: 0;"/>
<div class="row">
    <div class="tabbed tabbed-content-control tabbed-side-nav clearfix">
        <mat-sidenav-container>
            <mat-sidenav mode="side" class="sidenav" opened>
                <mat-nav-list class=" nav-tabs font-gotham">
                    <a mat-list-item (click)="showSection(1)" style="color:white">
                        <strong [ngClass]="{active:showSection1}">1. Operational Performance</strong></a>
                    <a mat-list-item (click)="showSection(2)" style="color:white">
                        <strong [ngClass]="{active:showSection2}">2. Economic Performance</strong></a>
                    <a mat-list-item (click)="showSection(3)" style="color:white">
                        <strong [ngClass]="{active:showSection3}">3. Execution Performance</strong></a>
                    <a mat-list-item (click)="showSection(4)" style="color:white">
                        <strong [ngClass]="{active:showSection4}">4. Business & Financial Performance</strong></a>
                    <a mat-list-item (click)="showSection(5)" style="color:white">
                        <strong [ngClass]="{active:showSection5}">5. Greenhouse Gas Emissions</strong></a>
                    <a mat-list-item (click)="showSection(6)" style="color:white">
                        <strong [ngClass]="{active:showSection6}">6. Alternative Selection</strong></a>
                    <a mat-list-item (click)="showSection(7)" style="color:white">
                        <strong [ngClass]="{active:showSection7}">7. Economics & risk for the proposed alternative</strong></a>
                    <a mat-list-item (click)="showSection(8)" style="color:white">
                        <strong [ngClass]="{active:showSection8}">8. Design Basis/Commercial Agreements</strong></a>
                    <a mat-list-item (click)="showSection(9)" style="color:white">
                        <strong [ngClass]="{active:showSection9}">9. Execution Plans, Performance Targets & Assurance Reviews</strong></a>
                    <a mat-list-item (click)="showSection(10)" style="color:white">
                        <strong [ngClass]="{active:showSection10}">10. Incorporating & Sharing Key Insights</strong></a>                
                </mat-nav-list>
            </mat-sidenav>
            <fieldset [disabled]="isOnHold || isReadOnly || isReadOnlyGlobalProjectLevel"> 
                <div class="row" style="padding: 20px 15px;">
                    <!-- --------------------Section 1---------------------------------->
                    <div *ngIf="showSection1" class="tab-content tabs nav nav-tabs font-gotham">
                        <div class="content-container">
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>1. EUR Performance</strong>
                                </div>
                                <div class="form-group col-xs-2">
                                    <span><strong>Attachments</strong></span>
                                </div>
                                <div class="form-group col-xs-4">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="checkbox" class="form-check-input" id="chkExplorationProspectData" [checked]="OperationModel.explorationProspectData" (change)="UpdateOperationCheckboxes($event,'Exploration')">
                                    <label class="form-check-label" style="padding-left: 4px;" for="chkExplorationProspectData">Exploration prospect data</label>
                                </div>
                                <div class="form-group col-xs-4">
                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" type="checkbox" class="form-check-input" id="chkSubsurfaceBasisOfDesign" [checked]="OperationModel.subsurfaceBasisOfDesign" (change)="UpdateOperationCheckboxes($event,'Subsurface')">
                                    <label class="form-check-label" style="padding-left: 4px;" for="chkSubsurfaceBasisOfDesign">Subsurface basis of design</label>
                                </div>
                            </div>
                            <div class="row scroll-content">
                                <div class="col-xs-12 form-min-width">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-table-default">
                                                <td colspan="2" rowspan="2" class="col-xs-4" style="border-right: 1px solid rgb(221, 221, 221)!important;">
                                                    Chevron share except 8/8<sup>ths</sup> cases <br />
                                                    Volumes are based on the develop case
                                                </td>
                                                <td colspan="4" class="text-center col-xs-4" style="border-right: 1px solid rgb(221, 221, 221)!important;">
                                                    AR Performance
                                                </td>
                                                <td colspan="3" class="text-center col-xs-4">
                                                    Supplement Performance <br>
                                                    Actual outcome and updated estimates
                                                </td>
                                            </tr>
                                            <tr class="bg-table-default">
                                                <td class="text-center col-xs-1">EV</td>
                                                <td class="text-center col-xs-1">P10</td>
                                                <td class="text-center col-xs-1">P50</td>
                                                <td class="text-center col-xs-1" style="border-right: 1px solid rgb(221, 221, 221)!important;">P90</td>
                                                <td class="text-center col-xs-1">EV</td>
                                                <td class="text-center col-xs-1">P10</td>
                                                <!-- <td class="text-center col-xs-1">P50</td> -->
                                                <td class="text-center col-xs-1">P90</td>
                                            </tr>
                                            <tr class="border-top">
                                                <td rowspan="4">1a. Estimated ultimate recovery</td>
                                                <td>Net MMBO</td>
                                                <td>
                                                    <div class="input-group">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="OperationModel.arUltimateRecovery.netMmbo.ev" [disabled]="ArId > 0" (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.NetMmbo.EV')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmbo.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.NetMmbo.EV')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmbo.EV')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="OperationModel.arUltimateRecovery.netMmbo.p10" [disabled]="ArId > 0"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.NetMmbo.P10')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmbo.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.NetMmbo.P10')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmbo.P10')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="OperationModel.arUltimateRecovery.netMmbo.p50" [disabled]="ArId > 0"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.NetMmbo.P50')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmbo.P50')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.NetMmbo.P50')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmbo.P50')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" type="number" class="form-control" [(ngModel)]="OperationModel.arUltimateRecovery.netMmbo.p90" [disabled]="ArId > 0"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.NetMmbo.P90')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmbo.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.NetMmbo.P90')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmbo.P90')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number" [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="OperationModel.ultimateRecovery.netMmbo.ev" (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.NetMmbo.EV')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmbo.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.NetMmbo.EV')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmbo.EV')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number" [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="OperationModel.ultimateRecovery.netMmbo.p10"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.NetMmbo.P10')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmbo.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.NetMmbo.P10')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmbo.P10')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td>
                                                    <div class="input-group">
                                                        <input type="number" [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="OperationModel.ultimateRecovery.netMmbo.p50"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.NetMmbo.P50')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmbo.P50')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.NetMmbo.P50')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmbo.P50')"></i>
                                                        </div>
                                                    </div>
                                                </td> -->
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="OperationModel.ultimateRecovery.netMmbo.p90"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.NetMmbo.P90')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmbo.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.NetMmbo.P90')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmbo.P90')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Net BCF</td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control"  [(ngModel)]="OperationModel.arUltimateRecovery.netBcf.ev" [disabled]="ArId > 0" (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.NetBcf.EV')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetBcf.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.NetBcf.EV')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetBcf.EV')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="OperationModel.arUltimateRecovery.netBcf.p10" [disabled]="ArId > 0" (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.NetBcf.P10')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetBcf.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.NetBcf.P10')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetBcf.P10')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="OperationModel.arUltimateRecovery.netBcf.p50" [disabled]="ArId > 0" (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"   [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.NetBcf.P50')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetBcf.P50')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.NetBcf.P50')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetBcf.P50')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number" [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="OperationModel.arUltimateRecovery.netBcf.p90" [disabled]="ArId > 0" (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.NetBcf.P90')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetBcf.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.NetBcf.P90')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetBcf.P90')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control"  [(ngModel)]="OperationModel.ultimateRecovery.netBcf.ev"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.NetBcf.EV')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetBcf.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.NetBcf.EV')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetBcf.EV')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="OperationModel.ultimateRecovery.netBcf.p10"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.NetBcf.P10')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetBcf.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.NetBcf.P10')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetBcf.P10')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="OperationModel.ultimateRecovery.netBcf.p50"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.NetBcf.P50')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetBcf.P50')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.NetBcf.P50')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetBcf.P50')"></i>
                                                        </div>
                                                    </div>
                                                </td> -->
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="OperationModel.ultimateRecovery.netBcf.p90"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.NetBcf.P90')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetBcf.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.NetBcf.P90')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetBcf.P90')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Net MMBOE</td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number" [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="OperationModel.arUltimateRecovery.netMmboe.ev" [disabled]="ArId > 0"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.NetMmboe.EV')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmboe.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.NetMmboe.EV')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmboe.EV')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="OperationModel.arUltimateRecovery.netMmboe.p10" [disabled]="ArId > 0" (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.NetMmboe.P10')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmboe.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.NetMmboe.P10')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmboe.P10')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="OperationModel.arUltimateRecovery.netMmboe.p50" [disabled]="ArId > 0" (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.NetMmboe.P50')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmboe.P50')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.NetMmboe.P50')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmboe.P50')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="OperationModel.arUltimateRecovery.netMmboe.p90" [disabled]="ArId > 0" (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.NetMmboe.P90')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmboe.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.NetMmboe.P90')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.NetMmboe.P90')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="OperationModel.ultimateRecovery.netMmboe.ev"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.NetMmboe.EV')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmboe.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.NetMmboe.EV')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmboe.EV')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="OperationModel.ultimateRecovery.netMmboe.p10"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.NetMmboe.P10')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmboe.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.NetMmboe.P10')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmboe.P10')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="OperationModel.ultimateRecovery.netMmboe.p50"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.NetMmboe.P50')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmboe.P50')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.NetMmboe.P50')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmboe.P50')"></i>
                                                        </div>
                                                    </div>
                                                </td> -->
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="OperationModel.ultimateRecovery.netMmboe.p90"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.NetMmboe.P90')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmboe.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.NetMmboe.P90')" (click)="GetComments('OperationalPerformance','UltimateRecovery.NetMmboe.P90')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>8/8<sup>ths</sup> MMBOE</td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="OperationModel.arUltimateRecovery.mmboe8.ev" [disabled]="ArId > 0" (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.MMBOE8E.EV')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.MMBOE8E.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.MMBOE8E.EV')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.MMBOE8E.EV')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control"  [(ngModel)]="OperationModel.arUltimateRecovery.mmboe8.p10" [disabled]="ArId > 0" (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.MMBOE8E.P10')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.MMBOE8E.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.MMBOE8E.P10')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.MMBOE8E.P10')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control"  [(ngModel)]="OperationModel.arUltimateRecovery.mmboe8.p50" [disabled]="ArId > 0"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.MMBOE8E.P50')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.MMBOE8E.P50')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.MMBOE8E.P50')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.MMBOE8E.P50')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input  type="number" [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="OperationModel.arUltimateRecovery.mmboe8.p90" [disabled]="ArId > 0" (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','ArUltimateRecovery.MMBOE8E.P90')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.MMBOE8E.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','ArUltimateRecovery.MMBOE8E.P90')" (click)="GetComments('OperationalPerformance','ArUltimateRecovery.MMBOE8E.P90')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="OperationModel.ultimateRecovery.mmboe8.ev"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.MMBOE8E.EV')" (click)="GetComments('OperationalPerformance','UltimateRecovery.MMBOE8E.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.MMBOE8E.EV')" (click)="GetComments('OperationalPerformance','UltimateRecovery.MMBOE8E.EV')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-group">
                                                        <input type="number"  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control"  [(ngModel)]="OperationModel.ultimateRecovery.mmboe8.p10"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.MMBOE8E.P10')" (click)="GetComments('OperationalPerformance','UltimateRecovery.MMBOE8E.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.MMBOE8E.P10')" (click)="GetComments('OperationalPerformance','UltimateRecovery.MMBOE8E.P10')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td>
                                                    <div class="input-group">
                                                        <input type="number" [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control"  [(ngModel)]="OperationModel.ultimateRecovery.mmboe8.p50"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.MMBOE8E.P50')" (click)="GetComments('OperationalPerformance','UltimateRecovery.MMBOE8E.P50')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.MMBOE8E.P50')" (click)="GetComments('OperationalPerformance','UltimateRecovery.MMBOE8E.P50')"></i>
                                                        </div>
                                                    </div>
                                                </td> -->
                                                <td>
                                                    <div class="input-group">
                                                        <input  type="number" [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="OperationModel.ultimateRecovery.mmboe8.p90"  (change)="UpdatePart2OperationData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('OperationalPerformance','UltimateRecovery.MMBOE8E.P90')" (click)="GetComments('OperationalPerformance','UltimateRecovery.MMBOE8E.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('OperationalPerformance','UltimateRecovery.MMBOE8E.P90')" (click)="GetComments('OperationalPerformance','UltimateRecovery.MMBOE8E.P90')"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>                    
                            <div class="row">
                                <div class="col-xs-12">
                                    <strong>Footnotes</strong>
                                </div>
                            </div>                    
                            <div class="row">
                                <div class="col-xs-12">
                                    <textarea  [disabled]="isReadOnlyGlobalProjectLevel" rows="5" class="col-xs-12 form-control" [(ngModel)]="OperationModel.footNotes"  (change)="UpdatePart2OperationData()"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- --------------------Section 2 --------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection2">
                        <div class="row">
                            <div class="col-xs-12">
                                <span>
                                    <strong>2. Economic Performance.</strong>
                                </span>
                            </div>
                        </div>                
                        <div class="row scroll-content">
                            <div class="col-xs-12 form-min-width">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr class="bg-table-default">
                                            <td colspan="6">
                                                <p><strong>Economic Performance </strong> (Chevron Share) for the likely development case.</p>
                                                <p><strong>Definition of economics cases:</strong></p>
                                                <p><strong>Risked case:</strong> Economics include all of the modeling scenarios we would choose to not develop due to poor exploration results, or uneconomic development drives.</p>
                                                <p><strong>Develop case:</strong> Economics exclude all of the modeling scenarios we would not develop.</p>
                                            </td>
                                            <td colspan="7">
                                                <div class="row">
                                                    <div class="col-xs-12 text-bold border-left-1">
                                                        <div class="text-left text-bold">
                                                            2a. Exploration Well:
                                                        </div>
                                                        <div class="form-horizontal padding-0">
                                                            <div class="form-group">
                                                                <label class="col-sm-8 control-label">Probability of exploration success :</label>
                                                                <div class="col-sm-4 text-left">
                                                                    <div class="input-group">
                                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.explorationWellSuccess" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ExplorationWellSuccess')" (click)="GetComments('EconomicPerformance','ExplorationWellSuccess')"></i>
                                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ExplorationWellSuccess')" (click)="GetComments('EconomicPerformance','ExplorationWellSuccess')"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label class="col-sm-8 control-label">Probability of development given exploration success :</label>
                                                                <div class="col-sm-4 text-left">
                                                                    <div class="input-group">
                                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.explorationWellDevelopmentSuccess" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                                            <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                                                <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ExplorationWellDevelopmentSuccess')" (click)="GetComments('EconomicPerformance','ExplorationWellDevelopmentSuccess')"></i>
                                                                                <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ExplorationWellDevelopmentSuccess')" (click)="GetComments('EconomicPerformance','ExplorationWellDevelopmentSuccess')"></i>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label class="col-sm-8 control-label text-bold">Probability of development :</label>
                                                                <div class="col-sm-4 text-left">
                                                                    <div class="input-group">
                                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.explorationWellDevelopment" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                                            <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                                                <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ExplorationWellDevelopment')" (click)="GetComments('EconomicPerformance','ExplorationWellDevelopment')"></i>
                                                                                <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ExplorationWellDevelopment')" (click)="GetComments('EconomicPerformance','ExplorationWellDevelopment')"></i>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>                
                                                        <div class="text-left text-bold border-top">
                                                            2a. Appraisal Well
                                                        </div>
                                                        <div class="form-horizontal padding-0">
                                                            <div class="form-group">
                                                                <label class="col-sm-8 control-label text-bold">Probability of development :</label>
                                                                <div class="col-sm-4 text-left">
                                                                    <div class="input-group">
                                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.appraisalWellDevelopment" type="number" class="form-control" (change)="UpdatePart2EconomicData()" />
                                                                            <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                                                <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','AppraisalWellDevelopment')" (click)="GetComments('EconomicPerformance','AppraisalWellDevelopment')"></i>
                                                                                <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','AppraisalWellDevelopment')" (click)="GetComments('EconomicPerformance','AppraisalWellDevelopment')"></i>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>                
                                        <tr class="bg-table-default border-top">
                                            <td colspan="6" rowspan="2">
                                                <div class="row">
                                                    <div class="col-xs-6">
                                                        2b. Cash flow discount date :
                                                    </div>
                                                    <div class="col-xs-6">
                                                        <div class="input-group">
                                                            <input  [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="EconomicModel.cashFlowAR" [matDatepicker]="cashFlowARpicker"
                                                            (click)="cashFlowARpicker.open();utilityService.removeCDKAnouncer()" (dateChange)="UpdatePart2EconomicData()">
                                                            <mat-datepicker #cashFlowARpicker></mat-datepicker>
                                                            <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                                    <i class="glyphicon glyphicon-comment-outline"  *ngIf="!hasComment('EconomicPerformance','CashFlowAR')" (click)="GetComments('EconomicPerformance','CashFlowAR')"></i>
                                                                    <i class="glyphicon glyphicon-comment-filled commentColor"  *ngIf="hasComment('EconomicPerformance','CashFlowAR')" (click)="GetComments('EconomicPerformance','CashFlowAR')"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td colspan="3" rowspan="2" class="text-center">
                                                <strong>AR Economics</strong>
                                            </td>
                                            <td colspan="4" class="text-center">
                                                <strong>Supplement Economics</strong>
                                            </td>
                                        </tr>   
                                        <tr class="bg-table-default">
                                            <td class="text-center col-xs-1">
                                                At AR conditions / discount date
                                            </td>
                                            <td colspan="3" class="text-center">
                                                <strong>Actual outcomes and updated estimates with current guidelines</strong>
                                            </td>
                                        </tr>             
                                        <tr class="bg-table-default border-top">
                                            <td colspan="6">
                                                <strong>Economics Metrics</strong>
                                            </td>
                                            <td class="text-center col-xs-1">
                                                <strong>EV</strong>
                                            </td>
                                            <td class="text-center col-xs-1">
                                                <strong>P10</strong>
                                            </td>
                                            <td class="text-center col-xs-1">
                                                <strong>P90</strong>
                                            </td>
                                            <td class="text-center col-xs-1">
                                                <strong>EV</strong>
                                            </td>
                                            <td class="text-center col-xs-1">
                                                <strong>EV</strong>
                                            </td>
                                            <td class="text-center col-xs-1">
                                                <strong>P10</strong>
                                            </td>
                                            <td class="text-center col-xs-1">
                                                <strong>P90</strong>
                                            </td>
                                        </tr>                
                                        <tr class="border-top">
                                            <td colspan="4" rowspan="2" class="text-center">
                                                <strong>2c. NPV </strong> @ 10% $MM
                                            </td>
                                            <td colspan="2">Risked case: Point Forward</td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArNpvRiskCasePointForwardEV">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arNpv.riskCasePointForward.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>      
                                                    <ng-template #noDollarArNpvRiskCasePointForwardEV>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arNpv.riskCasePointForward.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template> 
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ArNpv.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','ArNpv.RiskCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ArNpv.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','ArNpv.RiskCasePointForward.EV')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArNpvRiskCasePointForwardP10">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arNpv.riskCasePointForward.p10" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div> 
                                                    <ng-template #noDollarArNpvRiskCasePointForwardP10>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arNpv.riskCasePointForward.p10" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>     
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ArNpv.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','ArNpv.RiskCasePointForward.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ArNpv.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','ArNpv.RiskCasePointForward.P10')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArNpvRiskCasePointForwardP90">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arNpv.riskCasePointForward.p90" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>   
                                                    <ng-template #noDollarArNpvRiskCasePointForwardP90>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arNpv.riskCasePointForward.p90" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>   
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ArNpv.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','ArNpv.RiskCasePointForward.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ArNpv.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','ArNpv.RiskCasePointForward.P90')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarNpvRiskCasePointForwardArev">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.npv.riskCasePointForward.arev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>  
                                                    <ng-template #noDollarNpvRiskCasePointForwardArev>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.npv.riskCasePointForward.arev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>     
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Npv.RiskCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','Npv.RiskCasePointForward.Arev')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Npv.RiskCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','Npv.RiskCasePointForward.Arev')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarNpvRiskCasePointForwardEV">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.npv.riskCasePointForward.ev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>   
                                                    <ng-template #noDollarNpvRiskCasePointForwardEV>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.npv.riskCasePointForward.ev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>   
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Npv.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Npv.RiskCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Npv.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Npv.RiskCasePointForward.EV')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarNpvRiskCasePointForwardP10">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.npv.riskCasePointForward.p10" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div> 
                                                    <ng-template #noDollarNpvRiskCasePointForwardP10>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.npv.riskCasePointForward.p10" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>      
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Npv.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','Npv.RiskCasePointForward.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Npv.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','Npv.RiskCasePointForward.P10')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarNpvRiskCasePointForwardP90">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.npv.riskCasePointForward.p90" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div> 
                                                    <ng-template #noDollarNpvRiskCasePointForwardP90>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.npv.riskCasePointForward.p90" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>        
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Npv.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','Npv.RiskCasePointForward.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Npv.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','Npv.RiskCasePointForward.P90')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">Develop case: Point Forward</td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArNpvDevelopCasePointForwardEV">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arNpv.developCasePointForward.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>
                                                    <ng-template #noDollarArNpvDevelopCasePointForwardEV>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arNpv.developCasePointForward.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>       
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ArNpv.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','ArNpv.DevelopCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ArNpv.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','ArNpv.DevelopCasePointForward.EV')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarNpvDevelopCasePointForwardArev">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.npv.developCasePointForward.arev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>  
                                                    <ng-template #noDollarNpvDevelopCasePointForwardArev>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.npv.developCasePointForward.arev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>    
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Npv.DevelopCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','Npv.DevelopCasePointForward.Arev')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Npv.DevelopCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','Npv.DevelopCasePointForward.Arev')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarNpvDevelopCasePointForwardEV">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.npv.developCasePointForward.ev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div> 
                                                    <ng-template #noDollarNpvDevelopCasePointForwardEV>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.npv.developCasePointForward.ev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>    
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Npv.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Npv.DevelopCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Npv.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Npv.DevelopCasePointForward.EV')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>                
                                        <tr class="border-top">
                                            <td colspan="4" rowspan="2" class="text-center">
                                                <strong>2d. DPI </strong> @ 10%
                                            </td>
                                            <td colspan="2">Risked case: Point Forward</td>
                                            <td>
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.arDpi.riskCasePointForward.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ArDpi.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','ArDpi.RiskCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ArDpi.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','ArDpi.RiskCasePointForward.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.arDpi.riskCasePointForward.p10" type="number" [disabled]="ArId > 0" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ArDpi.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','ArDpi.RiskCasePointForward.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ArDpi.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','ArDpi.RiskCasePointForward.P10')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.arDpi.riskCasePointForward.p90" type="number" [disabled]="ArId > 0" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ArDpi.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','ArDpi.RiskCasePointForward.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ArDpi.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','ArDpi.RiskCasePointForward.P90')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.dpi.riskCasePointForward.arev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Dpi.RiskCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','Dpi.RiskCasePointForward.Arev')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Dpi.RiskCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','Dpi.RiskCasePointForward.Arev')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.dpi.riskCasePointForward.ev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Dpi.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Dpi.RiskCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Dpi.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Dpi.RiskCasePointForward.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.dpi.riskCasePointForward.p10" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Dpi.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','Dpi.RiskCasePointForward.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Dpi.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','Dpi.RiskCasePointForward.P10')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.dpi.riskCasePointForward.p90" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Dpi.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','Dpi.RiskCasePointForward.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Dpi.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','Dpi.RiskCasePointForward.P90')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">Develop case: Point Forward</td>
                                            <td>
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arDpi.developCasePointForward.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ArDpi.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','ArDpi.DevelopCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ArDpi.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','ArDpi.DevelopCasePointForward.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.dpi.developCasePointForward.arev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Dpi.DevelopCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','Dpi.DevelopCasePointForward.Arev')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Dpi.DevelopCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','Dpi.DevelopCasePointForward.Arev')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.dpi.developCasePointForward.ev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Dpi.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Dpi.DevelopCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Dpi.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Dpi.DevelopCasePointForward.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>                
                                        <tr class="border-top">
                                            <td colspan="4" rowspan="3" class="text-center">
                                                <strong>2e. ROR %</strong>
                                            </td>
                                            <td colspan="2">Risked case: Point Forward</td>
                                            <td>
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.arRor.riskCasePointForward.ev" type="text" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ArRor.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','ArRor.RiskCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ArRor.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','ArRor.RiskCasePointForward.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.arRor.riskCasePointForward.p10" type="text" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ArRor.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','ArRor.RiskCasePointForward.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ArRor.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','ArRor.RiskCasePointForward.P10')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.arRor.riskCasePointForward.p90" type="text" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                        <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ArRor.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','ArRor.RiskCasePointForward.P90')"></i>
                                                        <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ArRor.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','ArRor.RiskCasePointForward.P90')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ror.riskCasePointForward.arev" type="text" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Ror.RiskCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','Ror.RiskCasePointForward.Arev')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Ror.RiskCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','Ror.RiskCasePointForward.Arev')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ror.riskCasePointForward.ev" type="text" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Ror.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Ror.RiskCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Ror.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Ror.RiskCasePointForward.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ror.riskCasePointForward.p10" type="text" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Ror.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','Ror.RiskCasePointForward.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Ror.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','Ror.RiskCasePointForward.P10')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ror.riskCasePointForward.p90" type="text" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                        <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Ror.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','Ror.RiskCasePointForward.P90')"></i>
                                                        <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Ror.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','Ror.RiskCasePointForward.P90')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">Develop case: Point Forward</td>
                                            <td>
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.arRor.developCasePointForward.ev" type="text" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ArRor.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','ArRor.DevelopCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ArRor.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','ArRor.DevelopCasePointForward.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.ror.developCasePointForward.arev" type="text" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Ror.DevelopCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','Ror.DevelopCasePointForward.Arev')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Ror.DevelopCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','Ror.DevelopCasePointForward.Arev')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.ror.developCasePointForward.ev" type="text" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Ror.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Ror.DevelopCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Ror.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Ror.DevelopCasePointForward.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">Develop case: Full cycle</td>
                                            <td>
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arRor.developCaseFullCycle.ev" type="text" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','ArRor.DevelopCaseFullCycle.EV')" (click)="GetComments('EconomicPerformance','ArRor.DevelopCaseFullCycle.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','ArRor.DevelopCaseFullCycle.EV')" (click)="GetComments('EconomicPerformance','ArRor.DevelopCaseFullCycle.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.ror.developCaseFullCycle.arev" type="text" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Ror.DevelopCaseFullCycle.Arev')" (click)="GetComments('EconomicPerformance','Ror.DevelopCaseFullCycle.Arev')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Ror.DevelopCaseFullCycle.Arev')" (click)="GetComments('EconomicPerformance','Ror.DevelopCaseFullCycle.Arev')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ror.developCaseFullCycle.ev" type="text" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Ror.DevelopCaseFullCycle.EV')" (click)="GetComments('EconomicPerformance','Ror.DevelopCaseFullCycle.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Ror.DevelopCaseFullCycle.EV')" (click)="GetComments('EconomicPerformance','Ror.DevelopCaseFullCycle.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>                
                                        <tr class="border-top">
                                            <td colspan="4" class="text-center"><strong>2f. {{ IsNewCEisApplicableForms ? "Capex": "C&amp;E" }}, $MM</strong> in econs</td>
                                            <td colspan="2">Risked case: Point Forward</td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArceRiskCasePointForwardEV">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arce.riskCasePointForward.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div> 
                                                    <ng-template #noDollarArceRiskCasePointForwardEV>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arce.riskCasePointForward.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>  
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Arce.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Arce.RiskCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Arce.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Arce.RiskCasePointForward.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArceRiskCasePointForwardP10">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arce.riskCasePointForward.p10" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>  
                                                    <ng-template #noDollarArceRiskCasePointForwardP10>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arce.riskCasePointForward.p10" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>     
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Arce.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','Arce.RiskCasePointForward.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Arce.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','Arce.RiskCasePointForward.P10')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArceRiskCasePointForwardP90">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arce.riskCasePointForward.p90" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"(change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div> 
                                                    <ng-template #noDollarArceRiskCasePointForwardP90>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.arce.riskCasePointForward.p90" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"(change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>      
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Arce.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','Arce.RiskCasePointForward.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Arce.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','Arce.RiskCasePointForward.90')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td  class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarCERiskCasePointForwardArev">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.ce.riskCasePointForward.arev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>  
                                                    <ng-template #noDollarCERiskCasePointForwardArev>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.ce.riskCasePointForward.arev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>      
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','CE.RiskCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','CE.RiskCasePointForward.Arev')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','CE.RiskCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','CE.RiskCasePointForward.Arev')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td  class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarCERiskCasePointForwardEV">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.ce.riskCasePointForward.ev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>  
                                                    <ng-template #noDollarCERiskCasePointForwardEV>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.ce.riskCasePointForward.ev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>      
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','CE.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','CE.RiskCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','CE.RiskCasePointForward.EV')" (click)="GetComments('EconomicPerformance','CE.RiskCasePointForward.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td  class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarCERiskCasePointForwardP10">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.ce.riskCasePointForward.p10" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>  
                                                    <ng-template #noDollarCERiskCasePointForwardP10>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.ce.riskCasePointForward.p10" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>      
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','CE.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','CE.RiskCasePointForward.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','CE.RiskCasePointForward.P10')" (click)="GetComments('EconomicPerformance','CE.RiskCasePointForward.P10')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td  class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarCERiskCasePointForwardP90">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ce.riskCasePointForward.p90" type="number" class="form-control" (change)="UpdatePart2EconomicData()"(change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>  
                                                    <ng-template #noDollarCERiskCasePointForwardP90>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ce.riskCasePointForward.p90" type="number" class="form-control" (change)="UpdatePart2EconomicData()"(change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>      
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','CE.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','CE.RiskCasePointForward.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','CE.RiskCasePointForward.P90')" (click)="GetComments('EconomicPerformance','CE.RiskCasePointForward.90')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                              <small>Start year point-forward {{ IsNewCEisApplicableForms ? "Capex": "C&amp;E" }}</small>
                                            </td>
                                            <td colspan="2">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="EconomicModel.ceStartYear" type="number" class="form-control input-year" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','CEStartYear')" (click)="GetComments('EconomicPerformance','CEStartYear')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','CEStartYear')" (click)="GetComments('EconomicPerformance','CEStartYear')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td colspan="2">Develop case: Point Forward</td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArceDevelopCasePointForwardEV">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.arce.developCasePointForward.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>   
                                                    <ng-template #noDollarArceDevelopCasePointForwardEV>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.arce.developCasePointForward.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>    
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Arce.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Arce.DevelopCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor"  *ngIf="hasComment('EconomicPerformance','Arce.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','Arce.DevelopCasePointForward.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarCEDevelopCasePointForwardArev">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ce.developCasePointForward.arev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>  
                                                    <ng-template #noDollarCEDevelopCasePointForwardArev>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ce.developCasePointForward.arev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>    
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','CE.DevelopCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','CE.DevelopCasePointForward.Arev')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor"  *ngIf="hasComment('EconomicPerformance','CE.DevelopCasePointForward.Arev')" (click)="GetComments('EconomicPerformance','CE.DevelopCasePointForward.Arev')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarCEDevelopCasePointForwardEV">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ce.developCasePointForward.ev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>    
                                                    <ng-template #noDollarCEDevelopCasePointForwardEV>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ce.developCasePointForward.ev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>  
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','CE.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','CE.DevelopCasePointForward.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor"  *ngIf="hasComment('EconomicPerformance','CE.DevelopCasePointForward.EV')" (click)="GetComments('EconomicPerformance','CE.DevelopCasePointForward.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                              <small>Start year full-cycle {{ IsNewCEisApplicableForms ? "Capex": "C&amp;E" }}</small>
                                            </td>
                                            <td colspan="2">
                                                <div class="input-group">
                                                    <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ceEndYear" type="number" class="form-control input-year" (change)="UpdatePart2EconomicData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','CEEndYear')" (click)="GetComments('EconomicPerformance','CEEndYear')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','CEEndYear')" (click)="GetComments('EconomicPerformance','CEEndYear')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td colspan="2">Develop case: Full Cycle</td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArceDevelopCaseFullCycleEV">
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.arce.developCaseFullCycle.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>   
                                                    <ng-template #noDollarArceDevelopCaseFullCycleEV>
                                                        <input  [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.arce.developCaseFullCycle.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>    
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Arce.DevelopCaseFullCycle.EV')" (click)="GetComments('EconomicPerformance','Arce.DevelopCaseFullCycle.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Arce.DevelopCaseFullCycle.EV')" (click)="GetComments('EconomicPerformance','Arce.DevelopCaseFullCycle.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArevDevelopCaseFullCycleEV">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ce.developCaseFullCycle.arev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>  
                                                    <ng-template #noDollarArevDevelopCaseFullCycleEV>
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ce.developCaseFullCycle.arev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>      
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','Arev.DevelopCaseFullCycle.EV')" (click)="GetComments('EconomicPerformance','Arev.DevelopCaseFullCycle.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','Arev.DevelopCaseFullCycle.EV')" (click)="GetComments('EconomicPerformance','Arev.DevelopCaseFullCycle.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td class="col-xs-1">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarCEDevelopCaseFullCycleEV">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ce.developCaseFullCycle.ev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>   
                                                    <ng-template #noDollarCEDevelopCaseFullCycleEV>
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.ce.developCaseFullCycle.ev" type="number" class="form-control" (change)="UpdatePart2EconomicData()"/>
                                                    </ng-template>    
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('EconomicPerformance','CE.DevelopCaseFullCycle.EV')" (click)="GetComments('EconomicPerformance','CE.DevelopCaseFullCycle.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('EconomicPerformance','CE.DevelopCaseFullCycle.EV')" (click)="GetComments('EconomicPerformance','CE.DevelopCaseFullCycle.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>                
                        <div class="row">
                            <div class="col-xs-12">
                                <strong>Footnotes</strong>
                            </div>
                        </div>                
                        <div class="row">
                            <div class="col-xs-12">
                                <textarea [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="EconomicModel.footNotes" rows="5" class="form-control" (change)="UpdatePart2EconomicData()"></textarea>
                            </div>
                        </div>               
                    </div>
                    <!-- <--------------------Section 3---------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection3">
                        <div class="row">
                            <div class="col-xs-12 text-bold">
                                3. Execution Performance
                            </div>
                        </div>                
                        <div class="row scroll-content">
                            <div class="col-xs-12 form-min-width">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr class="bg-table-default border-top">
                                            <td colspan="2"><strong>3. Execution performance</strong> (Chevron share)</td>
                                            <td colspan="4" class="text-center" style="border-right: 1px solid rgb(221, 221, 221)!important;border-left: 1px solid rgb(221, 221, 221)!important;"><strong>AR</strong></td>
                                            <td colspan="3" class="text-center"><strong>Supplement</strong><br>Actual outcomes and updated estimates with current guidelines</td>
                                        </tr>
                                        <tr class="bg-table-default">
                                            <td>
                                              <strong>3a. {{ IsNewCEisApplicableForms ? "Capex": "C&amp;E" }} appropriated amount $MM</strong> <br />
                                                (Fully escalated, nominal U.S. dollars)
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarCEAppropriatedAmount">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.ceAppropriatedAmount" type="number" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div> 
                                                    <ng-template #noDollarCEAppropriatedAmount>
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.ceAppropriatedAmount" type="number" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                    </ng-template>      
                                                    <div class="input-group-addon" >
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','CEAppropriatedAmount')" (click)="GetComments('ExecutionPerformance','CEAppropriatedAmount')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','CEAppropriatedAmount')" (click)="GetComments('ExecutionPerformance','CEAppropriatedAmount')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td rowspan="2" class="text-center text-bold" style="border-left: 1px solid rgb(221, 221, 221)!important;">EV</td>
                                            <td rowspan="2" class="text-center text-bold">P10</td>
                                            <td rowspan="2" class="text-center text-bold">P50</td>
                                            <td rowspan="2" class="text-center text-bold" style="border-right: 1px solid rgb(221, 221, 221)!important;">P90</td>
                                            <td rowspan="2" class="text-center text-bold">EV</td>
                                            <td rowspan="2" class="text-center text-bold">P10</td>
                                            <td rowspan="2" class="text-center text-bold">P90</td>
                                        </tr>
                                        <tr class="bg-table-default">
                                            <td><small>Indicate if P50, EV, or NOJV operator basis </small></td>
                                            <td>
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="ExecutionModel.ceAppropriatedBasis" type="text" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','CEAppropriatedBasis')" (click)="GetComments('ExecutionPerformance','CEAppropriatedBasis')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','CEAppropriatedBasis')" (click)="GetComments('ExecutionPerformance','CEAppropriatedBasis')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                        </tr>                
                                        <tr class="border-top">
                                            <td><strong>3b. {{ IsNewCEisApplicableForms ? "Capex": "C&amp;E" }} probabilistic basis this AR</strong></td>
                                            <td>$MM</td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArceProbabilisticEV">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="ExecutionModel.arceProbabilistic.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2ExecutionData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>  
                                                    <ng-template #noDollarArceProbabilisticEV>
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="ExecutionModel.arceProbabilistic.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2ExecutionData()"/>
                                                    </ng-template>     
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','ArceProbabilistic.EV')" (click)="GetComments('ExecutionPerformance','ArceProbabilistic.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','ArceProbabilistic.EV')" (click)="GetComments('ExecutionPerformance','ArceProbabilistic.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArceProbabilisticP10">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="ExecutionModel.arceProbabilistic.p10" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2ExecutionData()" />
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>     
                                                    <ng-template #noDollarArceProbabilisticP10>
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="ExecutionModel.arceProbabilistic.p10" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2ExecutionData()" />
                                                    </ng-template>  
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','ArceProbabilistic.P10')" (click)="GetComments('ExecutionPerformance','ArceProbabilistic.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','ArceProbabilistic.P10')" (click)="GetComments('ExecutionPerformance','ArceProbabilistic.P10')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArceProbabilisticP50">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.arceProbabilistic.p50" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2ExecutionData()" />
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div> 
                                                    <ng-template #noDollarArceProbabilisticP50>
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.arceProbabilistic.p50" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2ExecutionData()" />
                                                    </ng-template>      
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','ArceProbabilistic.P50')" (click)="GetComments('ExecutionPerformance','ArceProbabilistic.P50')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','ArceProbabilistic.P50')" (click)="GetComments('ExecutionPerformance','ArceProbabilistic.P50')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarArceProbabilisticP90">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.arceProbabilistic.p90" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2ExecutionData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>
                                                    <ng-template #noDollarArceProbabilisticP90>
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.arceProbabilistic.p90" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2ExecutionData()"/>
                                                    </ng-template>      
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','ArceProbabilistic.P90')" (click)="GetComments('ExecutionPerformance','ArceProbabilistic.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','ArceProbabilistic.P90')" (click)="GetComments('ExecutionPerformance','ArceProbabilistic.P90')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarCEProbabilisticEV">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.ceProbabilistic.ev" type="number" class="form-control"  (change)="UpdatePart2ExecutionData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>    
                                                    <ng-template #noDollarCEProbabilisticEV>
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.ceProbabilistic.ev" type="number" class="form-control"  (change)="UpdatePart2ExecutionData()"/>
                                                    </ng-template>  
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','CEProbabilistic.EV')" (click)="GetComments('ExecutionPerformance','CEProbabilistic.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','CEProbabilistic.EV')" (click)="GetComments('ExecutionPerformance','CEProbabilistic.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarCEProbabilisticP10">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="ExecutionModel.ceProbabilistic.p10" type="number" class="form-control" (change)="UpdatePart2ExecutionData()" />
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>     
                                                    <ng-template #noDollarCEProbabilisticP10>
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="ExecutionModel.ceProbabilistic.p10" type="number" class="form-control" (change)="UpdatePart2ExecutionData()" />
                                                    </ng-template>  
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','CEProbabilistic.P10')" (click)="GetComments('ExecutionPerformance','CEProbabilistic.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','CEProbabilistic.P10')" (click)="GetComments('ExecutionPerformance','CEProbabilistic.P10')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarCEProbabilisticP90">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.ceProbabilistic.p90" type="number" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>   
                                                    <ng-template #noDollarCEProbabilisticP90>
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.ceProbabilistic.p90" type="number" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                    </ng-template>    
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','CEProbabilistic.P90')" (click)="GetComments('ExecutionPerformance','CEProbabilistic.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','CEProbabilistic.P90')" (click)="GetComments('ExecutionPerformance','CEProbabilistic.P90')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="border-top">
                                            <td><strong>3d. Execution schedule</strong></td>
                                            <td>Months</td>
                                            <td>
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="ExecutionModel.arExecuteSchedule.ev" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2ExecutionData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','ArExecuteSchedule.EV')" (click)="GetComments('ExecutionPerformance','ArExecuteSchedule.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','ArExecuteSchedule.EV')" (click)="GetComments('ExecutionPerformance','ArExecuteSchedule.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.arExecuteSchedule.p10" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2ExecutionData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','ArExecuteSchedule.P10')" (click)="GetComments('ExecutionPerformance','ArExecuteSchedule.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','ArExecuteSchedule.P10')" (click)="GetComments('ExecutionPerformance','ArExecuteSchedule.P10')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.arExecuteSchedule.p90" type="number" class="form-control" [disabled]="ArId > 0" (change)="UpdatePart2ExecutionData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','ArExecuteSchedule.P90')" (click)="GetComments('ExecutionPerformance','ArExecuteSchedule.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','ArExecuteSchedule.P90')" (click)="GetComments('ExecutionPerformance','ArExecuteSchedule.P90')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.executeSchedule.ev" type="number" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','ExecuteSchedule.EV')" (click)="GetComments('ExecutionPerformance','ExecuteSchedule.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','ExecuteSchedule.EV')" (click)="GetComments('ExecutionPerformance','ExecuteSchedule.EV')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.executeSchedule.p10" type="number" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','ExecuteSchedule.P10')" (click)="GetComments('ExecutionPerformance','ExecuteSchedule.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','ExecuteSchedule.P10')" (click)="GetComments('ExecutionPerformance','ExecuteSchedule.P10')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel" [(ngModel)]="ExecutionModel.executeSchedule.p90" type="number" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','ExecuteSchedule.P90')" (click)="GetComments('ExecutionPerformance','ExecuteSchedule.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','ExecuteSchedule.P90')" (click)="GetComments('ExecutionPerformance','ExecuteSchedule.P90')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                        </tr>                
                                        <tr class="border-top">
                                            <td><strong>3e. Startup or first oil/gas production (sustained)</strong></td>
                                            <td>Date</td>
                                            <td>
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="ExecutionModel.arStartupProduction.ev" [disabled]="ArId > 0" [matDatepicker]="arStartupProductionEvpicker"
                                                    (click)="arStartupProductionEvpicker.open();utilityService.removeCDKAnouncer()" (dateChange)="UpdatePart2ExecutionData()">
                                                <mat-datepicker #arStartupProductionEvpicker></mat-datepicker>
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','ArStartupProduction.EV')" (click)="GetComments('ExecutionPerformance','ArStartupProduction.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','ArStartupProduction.EV')" (click)="GetComments('ExecutionPerformance','ArStartupProduction.EV')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="ExecutionModel.arStartupProduction.p10" [disabled]="ArId > 0" [matDatepicker]="arStartupProductionP10picker"
                                                    (click)="arStartupProductionP10picker.open();utilityService.removeCDKAnouncer()" (dateChange)="UpdatePart2ExecutionData()">
                                                <mat-datepicker #arStartupProductionP10picker></mat-datepicker>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','ArStartupProduction.P10')" (click)="GetComments('ExecutionPerformance','ArStartupProduction.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','ArStartupProduction.P10')" (click)="GetComments('ExecutionPerformance','ArStartupProduction.P10')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="ExecutionModel.arStartupProduction.p90" [disabled]="ArId > 0" [matDatepicker]="arStartupProductionP90picker"
                                                    (click)="arStartupProductionP90picker.open();utilityService.removeCDKAnouncer()" (dateChange)="UpdatePart2ExecutionData()">
                                                <mat-datepicker #arStartupProductionP90picker></mat-datepicker>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','ArStartupProduction.P90')" (click)="GetComments('ExecutionPerformance','ArStartupProduction.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','ArStartupProduction.P90')" (click)="GetComments('ExecutionPerformance','ArStartupProduction.P90')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="ExecutionModel.startupProduction.ev" [matDatepicker]="startupProductionEvpicker"
                                                    (click)="startupProductionEvpicker.open();utilityService.removeCDKAnouncer()" (dateChange)="UpdatePart2ExecutionData()">
                                                <mat-datepicker #startupProductionEvpicker></mat-datepicker>
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','StartupProduction.EV')" (click)="GetComments('ExecutionPerformance','StartupProduction.EV')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','StartupProduction.EV')" (click)="GetComments('ExecutionPerformance','StartupProduction.EV')"></i>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel" class="form-control" [(ngModel)]="ExecutionModel.startupProduction.p10" [matDatepicker]="startupProductionP10picker"
                                                    (click)="startupProductionP10picker.open();utilityService.removeCDKAnouncer()" (dateChange)="UpdatePart2ExecutionData()">
                                                <mat-datepicker #startupProductionP10picker></mat-datepicker>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','StartupProduction.P10')" (click)="GetComments('ExecutionPerformance','StartupProduction.P10')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','StartupProduction.P10')" (click)="GetComments('ExecutionPerformance','StartupProduction.P10')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel"  class="form-control" [(ngModel)]="ExecutionModel.startupProduction.p90" [matDatepicker]="startupProductionP90picker"
                                                    (click)="startupProductionP90picker.open();utilityService.removeCDKAnouncer()" (dateChange)="UpdatePart2ExecutionData()">
                                                <mat-datepicker #startupProductionP90picker></mat-datepicker>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','StartupProduction.P90')" (click)="GetComments('ExecutionPerformance','StartupProduction.P90')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','StartupProduction.P90')" (click)="GetComments('ExecutionPerformance','StartupProduction.P90')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                        </tr>                
                                        <tr class="border-top">
                                            <td colspan="2"><strong>3.f Industry benchmark data</strong></td>
                                            <td colspan="2" class="text-center"><strong>AR EV</strong></td>
                                            <td colspan="2" class="text-center"><strong>Industry Top Quartile</strong></td>
                                            <td colspan="3" class="text-center"><strong>Industry Average</strong></td>
                                        </tr>                
                                        <tr class="border-top">
                                            <td><strong>Benchmarkable capital cost</strong></td>
                                            <td>$MM</td>
                                            <td colspan="2" class="text-center">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarBenchCapitalCostAR">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  type="number" [(ngModel)]="ExecutionModel.benchCapitalCost.ar" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>
                                                    <ng-template #noDollarBenchCapitalCostAR>
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  type="number" [(ngModel)]="ExecutionModel.benchCapitalCost.ar" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                    </ng-template>       
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','BenchCapitalCost.AR')" (click)="GetComments('ExecutionPerformance','BenchCapitalCost.AR')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','BenchCapitalCost.AR')" (click)="GetComments('ExecutionPerformance','BenchCapitalCost.AR')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td colspan="2" class="text-center">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarBenchCapitalCostTopQuartile">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" type="number" [(ngModel)]="ExecutionModel.benchCapitalCost.topQuartile" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>  
                                                    <ng-template #noDollarBenchCapitalCostTopQuartile>
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" type="number" [(ngModel)]="ExecutionModel.benchCapitalCost.topQuartile" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                    </ng-template>      
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','BenchCapitalCost.TopQuartile')" (click)="GetComments('ExecutionPerformance','BenchCapitalCost.TopQuartile')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','BenchCapitalCost.TopQuartile')" (click)="GetComments('ExecutionPerformance','BenchCapitalCost.TopQuartile')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td colspan="3" class="text-center">
                                                <div class="input-group">
                                                    <div class="input-icon" style="display:flex;" *ngIf="!IsOldVersionOfForms; else noDollarBenchCapitalCostIndustryAverage">
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" type="number" [(ngModel)]="ExecutionModel.benchCapitalCost.industryAverage" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                        <i style="z-index: 1111;">$</i>  
                                                    </div>  
                                                    <ng-template #noDollarBenchCapitalCostIndustryAverage>
                                                        <input [disabled]="isReadOnlyGlobalProjectLevel" type="number" [(ngModel)]="ExecutionModel.benchCapitalCost.industryAverage" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                    </ng-template>     
                                                    <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','BenchCapitalCost.IndustryAverage')" (click)="GetComments('ExecutionPerformance','BenchCapitalCost.IndustryAverage')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','BenchCapitalCost.IndustryAverage')" (click)="GetComments('ExecutionPerformance','BenchCapitalCost.IndustryAverage')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                        </tr>                
                                        <tr class="border-top">
                                            <td><strong>Benchmarkable execution schedule</strong></td>
                                            <td>Months</td>
                                            <td colspan="2" class="text-center">
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel" type="number" [(ngModel)]="ExecutionModel.benchExecute.ar" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','BenchExecute.AR')" (click)="GetComments('ExecutionPerformance','BenchExecute.AR')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','BenchExecute.AR')" (click)="GetComments('ExecutionPerformance','BenchExecute.AR')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td colspan="2" class="text-center">
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel" type="number" [(ngModel)]="ExecutionModel.benchExecute.topQuartile" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','BenchExecute.TopQuartile')" (click)="GetComments('ExecutionPerformance','BenchExecute.TopQuartile')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','BenchExecute.TopQuartile')" (click)="GetComments('ExecutionPerformance','BenchExecute.TopQuartile')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                            <td colspan="3" class="text-center">
                                                <div class="input-group">
                                                    <input [disabled]="isReadOnlyGlobalProjectLevel" type="number" [(ngModel)]="ExecutionModel.benchExecute.industryAverage" class="form-control" (change)="UpdatePart2ExecutionData()"/>
                                                        <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                            <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionPerformance','BenchExecute.IndustryAverage')" (click)="GetComments('ExecutionPerformance','BenchExecute.IndustryAverage')"></i>
                                                            <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionPerformance','BenchExecute.IndustryAverage')" (click)="GetComments('ExecutionPerformance','BenchExecute.IndustryAverage')"></i>
                                                        </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>                
                        <div class="row">
                            <div class="col-xs-12">
                                <strong>Footnotes</strong>
                            </div>
                        </div>                
                        <div class="row">
                            <div class="col-xs-12">
                                <textarea [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="ExecutionModel.footNotes" rows="5" class="col-xs-12 form-control" (change)="UpdatePart2ExecutionData()"></textarea>
                            </div>
                        </div>
                    </div>
                    <!-- <---------------------Section 4--------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection4">
                        <div class="row">
                            <div class="col-xs-12">
                              <p>
                                <strong>4. Business and financial performance.</strong>
                                Briefly summarize the expected performance. Include graphs of production or product yields, operating expense, cash flow, {{ IsNewCEisApplicableForms ? "Capex": "C&E" }} expenditures and other performance indicators.
                              </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <ckeditor [disabled]="isReadOnlyGlobalProjectLevel"  #businessFinancial [editor]="Editor" [config]="config" id="businessFinancial" name="businessFinancial"
                                [(ngModel)]="BusinessModel.businessFinancial" (ready)="onReady($event,'Business')" [disabled]="isOnHold || isReadOnly"></ckeditor>
                            </div>
                        </div>
                    </div>
                    <!-- <---------------------Section 5--------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection5">
                        <div class="row">
                            <div class="col-xs-12">
                                <p><strong>5. Greenhouse gas emissions.</strong>
                                Summarize assumptions used to estimate emissions and assess the financial impact of GHG regulations on the project economics. Include an annual profile of project GHG emissions. Summarize the options considered and implemented to reduce GHG emissions.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-xs-12"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <ckeditor [disabled]="isReadOnlyGlobalProjectLevel"  #greenhouseGasEmissions [editor]="Editor" [config]="config" id="greenhouseGasEmissions" name="greenhouseGasEmissions"
                                [(ngModel)]="BusinessModel.greenhouseGasEmissions" (ready)="onReady($event,'Business')" [disabled]="isOnHold || isReadOnly"></ckeditor>
                            </div>
                        </div>
                    </div>
                    <!-- <---------------------Section 6--------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection6">
                        <div class="row">
                            <div class="col-xs-12">
                                <p><strong>6. Alternative selection.</strong>
                                Summarize the reference case, alternatives considered and decision processes/decision criteria/trade-offs used to select the preferred alternative. Include (as appropriate) an investment efficiency chart, probability curves, value of information/control assessments and summarized results of stakeholder analysis using game theory.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-xs-12"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <ckeditor [disabled]="isReadOnlyGlobalProjectLevel"  #alternateSelection [editor]="Editor" [config]="config" id="alternateSelection" name="alternateSelection"
                                [(ngModel)]="BusinessModel.alternateSelection" (ready)="onReady($event,'Business')" [disabled]="isOnHold || isReadOnly"></ckeditor>
                            </div>
                        </div>
                    </div>
                    <!-- <---------------------Section 7--------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection7">
                        <div class="row">
                            <div class="col-xs-12">
                                <p><strong>7. Economics and risk for the proposed alternative.</strong>
                                Describe the economics included in the economic performance data in Part 2, Section 2, clarifying the basis for the economics presented. Summarize the basis for the assumptions of key downside risks and upside opportunities. For significant quantifiable risks, use a tornado diagram and probability curves to describe their economic impact. Discuss risks that are not quantified. Summarize risk mitigation and contingency actions that could be taken to improve project results if these risks occur.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-xs-12"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <ckeditor [disabled]="isReadOnlyGlobalProjectLevel"  #economicsRiskProposedAlternative [editor]="Editor" [config]="config" id="economicsRiskProposedAlternative" name="economicsRiskProposedAlternative"
                                [(ngModel)]="BusinessModel.economicsRiskProposedAlternative" (ready)="onReady($event,'Business')" [disabled]="isOnHold || isReadOnly"></ckeditor>
                            </div>
                        </div>
                    </div>
                    <!-- <---------------------Section 8--------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection8">
                        <div class="row">
                            <div class="col-xs-12">
                                <strong>8. Design basis/commercial agreements.</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <p><strong>8c. Commercial agreements and intellectual property.</strong>
                                Summarize the rationale and status for any key operating/financial agreements. Summarize systems used to identify, assess and manage intellectual property risks/opportunities.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-xs-12"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <ckeditor [disabled]="isReadOnlyGlobalProjectLevel"  #commercialIntellectual [editor]="Editor" [config]="config" id="commercialIntellectual" name="commercialIntellectual"
                                [(ngModel)]="DesignCommercialModel.commercialIntellectual" (ready)="onReady($event,'Design')" [disabled]="isOnHold || isReadOnly"></ckeditor>
                            </div>
                        </div>
                    </div>
                    <!-- <---------------------Section 9--------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection9">
                        <div class="row">
                            <div class="col-xs-12">
                                <span>
                                    <strong>9. Execution plans, performance targets and assurance reviews.</strong>
                                </span>
                            </div>
                            <div class="form-group col-xs-2">
                                <span><strong>Attachments</strong></span>
                            </div>
                            <div class="form-group col-xs-4">
                                <input [disabled]="isReadOnlyGlobalProjectLevel" type="checkbox" class="form-check-input" id="chkAttachmentsCostEstimates"
                                [checked]="ExecutionTargetAssuranceModel.attachmentsCostEstimates"  (change)="UpdateTargetAssuranceCheckboxes($event,'CostEstimates')">
                                <label class="form-check-label" style="padding-left: 4px;" for="chkAttachmentsCostEstimates">Cost estimate summary</label>
                            </div>
                            <div class="form-group col-xs-4" style="width: 322px">
                                <input [disabled]="isReadOnlyGlobalProjectLevel"  type="checkbox" class="form-check-input" id="chkAttachmentsMilestoneProject"
                                [checked]="ExecutionTargetAssuranceModel.attachmentsMilestoneProject"   (change)="UpdateTargetAssuranceCheckboxes($event,'MilestoneProject')">
                                <label class="form-check-label" style="padding-left: 4px;" for="chkAttachmentsMilestoneProject">Milestone project schedule</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <span>
                                    <strong>9a. Project assurance reviews.</strong>
                                </span>
                            </div>
                        </div>                
                        <div class="row">
                            <div class="col-xs-12">
                                <table class="table table-bordered table-striped table-condensed table-responsive-sm">
                                    <tbody>
                                        <tr>
                                            <td>Phases</td>
                                            <td>Review Type</td>
                                            <td>Date</td>
                                            <td>Names, SBUs</td>
                                        </tr>
                                        <tr>
                                            <td>Phase 1</td>
                                            <td><textarea class="form-control" [(ngModel)]="ExecutionTargetAssuranceModel.phase1.reviewType" rows="5" (change)="UpdatePart2ExecutionTargetAssuranceData()"></textarea></td>
                                            <td><textarea class="form-control" [(ngModel)]="ExecutionTargetAssuranceModel.phase1.reviewDate" rows="5"  (change)="UpdatePart2ExecutionTargetAssuranceData()"></textarea></td>
                                            <td><textarea class="form-control" [(ngModel)]="ExecutionTargetAssuranceModel.phase1.nameSbu" rows="5" (change)="UpdatePart2ExecutionTargetAssuranceData()"></textarea></td>
                                        </tr>
                                        <tr>
                                            <td>Phase 2</td>
                                            <td><textarea class="form-control" [(ngModel)]="ExecutionTargetAssuranceModel.phase2.reviewType" rows="5" (change)="UpdatePart2ExecutionTargetAssuranceData()"></textarea></td>
                                            <td><textarea class="form-control" [(ngModel)]="ExecutionTargetAssuranceModel.phase2.reviewDate" rows="5" (change)="UpdatePart2ExecutionTargetAssuranceData()"></textarea></td>
                                            <td><textarea class="form-control" [(ngModel)]="ExecutionTargetAssuranceModel.phase2.nameSbu" rows="5" (change)="UpdatePart2ExecutionTargetAssuranceData()"></textarea></td>
                                        </tr>
                                        <tr *ngIf="IsOldVersionOfForms">
                                            <td>Phase 3</td>
                                            <td><textarea class="form-control" [(ngModel)]="ExecutionTargetAssuranceModel.phase3.reviewType" rows="5" (change)="UpdatePart2ExecutionTargetAssuranceData()"></textarea></td>
                                            <td><textarea class="form-control" [(ngModel)]="ExecutionTargetAssuranceModel.phase3.reviewDate" rows="5" (change)="UpdatePart2ExecutionTargetAssuranceData()"></textarea></td>
                                            <td><textarea class="form-control" [(ngModel)]="ExecutionTargetAssuranceModel.phase3.nameSbu" rows="5" (change)="UpdatePart2ExecutionTargetAssuranceData()"></textarea></td>
                                        </tr>
                                        <tr *ngIf="IsOldVersionOfForms">
                                            <td>Phase 4</td>
                                            <td><textarea class="form-control" [(ngModel)]="ExecutionTargetAssuranceModel.phase4.reviewType" rows="5" (change)="UpdatePart2ExecutionTargetAssuranceData()"></textarea></td>
                                            <td><textarea class="form-control" [(ngModel)]="ExecutionTargetAssuranceModel.phase4.reviewDate" rows="5" (change)="UpdatePart2ExecutionTargetAssuranceData()"></textarea></td>
                                            <td><textarea class="form-control" [(ngModel)]="ExecutionTargetAssuranceModel.phase4.nameSbu" rows="5" (change)="UpdatePart2ExecutionTargetAssuranceData()"></textarea></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <ckeditor [disabled]="isReadOnlyGlobalProjectLevel"  #performanceTarget [editor]="Editor" [config]="config" id="performanceTarget" name="performanceTarget"
                                [(ngModel)]="ExecutionTargetAssuranceModel.performanceTarget"  (ready)="onReady($event,'Target')" [disabled]="isOnHold || isReadOnly"></ckeditor>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-xs-12">
                                <strong>9b. Project execution plan.</strong> Summarize key elements of the project execution and contracting plans.  Focus on project OE management, contracting/procurement strategy and staffing.  Also, as appropriate, summarize the community engagement plan.  Summarize how insights from assurance reviews have been incorporated in the execution plan.
                            </div>
                        </div>                
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="col-xs-4">
                                    <label class="col-form-label">
                                        Target date for completing Part 4 Lookback:
                                    </label>
                                </div>
                                <div class="col-xs-2">
                                    <div class="input-group">
                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="ExecutionTargetAssuranceModel.projectExecutionPart4Date" maxlength="50" type="text" class="form-control"  (change)="UpdatePart2ExecutionTargetAssuranceData()"/>
                                                <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                                <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionTargetAssurance','ProjectExecutionPart4Date')" (click)="GetComments('ExecutionTargetAssurance','ProjectExecutionPart4Date')"></i>
                                                <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionTargetAssurance','ProjectExecutionPart4Date')" (click)="GetComments('ExecutionTargetAssurance','ProjectExecutionPart4Date')"></i>
                                            </div>
                                    </div>
                                </div>
                                <div class="col-xs-4">
                                    <label class="col-form-label">
                                        Target date for completing Part 5 Lookback:
                                    </label>
                                </div>
                                <div class="col-xs-2">
                                    <div class="input-group">
                                        <input [disabled]="isReadOnlyGlobalProjectLevel"  [(ngModel)]="ExecutionTargetAssuranceModel.projectExecutionPart5Date" maxlength="50" type="text" class="form-control"  (change)="UpdatePart2ExecutionTargetAssuranceData()"/>
                                                <div class="input-group-addon"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}" >
                                                <i class="glyphicon glyphicon-comment-outline" *ngIf="!hasComment('ExecutionTargetAssurance','ProjectExecutionPart5Date')" (click)="GetComments('ExecutionTargetAssurance','ProjectExecutionPart5Date')"></i>
                                                <i class="glyphicon glyphicon-comment-filled commentColor" *ngIf="hasComment('ExecutionTargetAssurance','ProjectExecutionPart5Date')" (click)="GetComments('ExecutionTargetAssurance','ProjectExecutionPart5Date')"></i>
                                            </div>
                                    </div>
                                </div>                
                            </div>
                        </div>                
                        <div class="row">
                            <div class="col-xs-12"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <ckeditor [disabled]="isReadOnlyGlobalProjectLevel" #projectExecutionPlan [editor]="Editor" [config]="config" id="projectExecutionPlan" name="projectExecutionPlan"
                                [(ngModel)]="ExecutionTargetAssuranceModel.projectExecutionPlan" (ready)="onReady($event,'Target')" [disabled]="isOnHold || isReadOnly"></ckeditor>
                            </div>
                        </div>                
                        <div class="row">
                            <div class="col-xs-12">
                                <strong>9c. Permitting plan.</strong> As applicable, summarize the permitting strategy and plans including key milestones.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <ckeditor [disabled]="isReadOnlyGlobalProjectLevel" #permittingPlan [editor]="Editor" [config]="config" id="permittingPlan" name="permittingPlan"
                                [(ngModel)]="ExecutionTargetAssuranceModel.permittingPlan" (ready)="onReady($event,'Target')" [disabled]="isOnHold || isReadOnly"></ckeditor>
                            </div>
                        </div>                
                        <div class="row">
                            <div class="col-xs-12">
                                <strong>9d. Schedule estimate.</strong> Summarize the methodology and key assumptions used to develop the project execution schedule.  Include start of detailed engineering, start of execution, mechanical completion and sustained startup/”first oil-gas” dates.  Discuss key uncertainties and dependencies of key activities.  Summarize the benchmarking results and insights from assurance reviews.  Include a probability distribution (S-curve) of the risked execution schedule and attach a milestone schedule summary.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <ckeditor [disabled]="isReadOnlyGlobalProjectLevel" #scheduleEstimate [editor]="Editor" [config]="config" id="scheduleEstimate" name="scheduleEstimate"
                                [(ngModel)]="ExecutionTargetAssuranceModel.scheduleEstimate" (ready)="onReady($event,'Target')" [disabled]="isOnHold || isReadOnly"></ckeditor>
                            </div>
                        </div>                
                        <div class="row">
                            <div class="col-xs-12">
                              <strong>9e. {{ IsNewCEisApplicableForms ? "Capex": "C&amp;E" }} cost estimate.</strong> Summarize the key assumptions used to develop the cost estimate, cost target and set the level of contingency.  Show probability distribution (S-curve).  Summarize benchmarking results and insights from assurance reviews.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <ckeditor [disabled]="isReadOnlyGlobalProjectLevel" #ceCostEstimate [editor]="Editor" [config]="config" id="ceCostEstimate" name="ceCostEstimate"
                                [(ngModel)]="ExecutionTargetAssuranceModel.ceCostEstimate" (ready)="onReady($event,'Target')" [disabled]="isOnHold || isReadOnly"></ckeditor>
                            </div>
                        </div>
                    </div>
                    <!-- <---------------------Section 10--------------------------------->
                    <div class="row panel tab-pane mt-20 fs-16" *ngIf="showSection10">
                        <div class="row">
                            <div class="col-xs-12">
                                <p><strong>10. Incorporating and sharing key insights.</strong>
                                Summarize how key insights from other projects were used in the development of this investment proposal. Describe key insights identified during work on this project that have been shared with others.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-xs-12"  [ngClass]="{'disabledNoOfCasesDiv': isReadOnlyGlobalProjectLevel}">
                                <ckeditor [disabled]="isReadOnlyGlobalProjectLevel" #sharingKeyInsights [editor]="Editor" [config]="config" id="sharingKeyInsights" name="sharingKeyInsights"
                                [(ngModel)]="ExecutionTargetAssuranceModel.sharingKeyInsights" (ready)="onReady($event,'Target')" [disabled]="isOnHold || isReadOnly"></ckeditor>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </mat-sidenav-container>
    </div>
</div>
