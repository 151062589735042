import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { SsbodProjectModel } from '../Model/SsbodProject.model';
import { SsbodService } from '../service/ssbod.service';
import { Go36projectService } from '../../go36project/service/go36project.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-ssbodproject-list',
  templateUrl: './ssbodproject-list.component.html',
  styleUrls: ['./ssbodproject-list.component.css']
})
export class SsbodprojectListComponent implements OnInit {
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  projects: SsbodProjectModel[] = [];
  data: any;
  selectedVersion: any = 0;
  objectId: string = '';
  provId: string = '';
  emailId: string = '';
  userAccessDetails: any;
  userRoleForProject: string = '';
  projectlength: number = 0;
  
  constructor(private primengConfig: PrimeNGConfig, private ssbodService: SsbodService, 
    private projectservice: Go36projectService,private utilityServcie: UtilityService) { }
  ngOnInit(): void {
    this.objectId = this.utilityServcie.GetuserObjectId();
    this.provId = this.utilityServcie.GetuserProvisioningId();
    this.emailId = this.utilityServcie.GetuserEmailId();
    this.primengConfig.ripple = true;
    this.data = history.state;
    this.getSsbodProject();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2,
      processing: true
    };
  }
  clear(table: any) {
    table.clear();
  }

  getSsbodProject() {
    const userData = JSON.parse(JSON.stringify(localStorage.getItem("userAccessDetails")))
    this.userAccessDetails = JSON.parse(userData);
    //this.userRole : string = '';
    // we can create the constants of the users role
    if (this.userAccessDetails?.data?.isPowerUser) {
      this.userRoleForProject = "Power User";
    }
    else if (this.userAccessDetails?.data?.isAdminUser) {
      this.userRoleForProject = "Administrator";
    }
    else if (this.userAccessDetails?.data?.isBasicMember) {
      this.userRoleForProject = "Basic Member";
    }
    else if (this.userAccessDetails?.data?.isConsultantUser) {
      this.userRoleForProject = "Consultant";
    }
    else if (this.userAccessDetails?.data?.isLeadershipUser) {
      this.userRoleForProject = "Leadership";
    }
    this.ssbodService.getSsbodProject(this.objectId.trim(),this.provId.trim(), this.userRoleForProject).subscribe(res => {
      this.projects = res;
      this.projectlength = this.projects.length;
      this.onShowEntryDropdownChange({first: 0, rows: this.projectlength});
      if (this.projects.length > 0) {
        this.projects.forEach(obj => {
          obj.selectedVersion = obj.Version;
        });
      }
    })
  }

  onShowEntryDropdownChange(event) {
    if (event.rows === this.projectlength)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "All";
    else if (event.rows === 10)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "10";
    else if (event.rows === 20)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "20";
    else if (event.rows === 50)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "50";
    else if (event.rows === 100)
      document.querySelector("p-paginator > div > p-dropdown > div > span")!.innerHTML = "100";
  }

  edit(goproject: any) {
    localStorage.setItem('goProject', JSON.stringify(goproject));
    localStorage.setItem('editMode', 'true')
  }

  SetAddtMode(editMode: string) {
    localStorage.setItem('editMode', 'false')
  }
  onVersionChange(event: any, projectId: any) {
    this.selectedVersion = Number(event.target.value);
    this.projects.forEach((item) => {
      if (item.ProjectId == projectId) {
        item.FormVersions.forEach(element => {
          if (element.FormVersion == this.selectedVersion) {
            item.ProjectId = element.FormId;
          }
        });
      }
    })
  }
}
