export class EAPart3Model {
    supportingDetailsId: number = 0;
    arId: number = 0;
    arChevronShare?: any = 0;
    arBasisChevron: boolean = false;
    arAppAmount?: any;
    arCapLeaseAmount?: any;
    arFinAmountOther?: any;
    arWorkingCapital?: any;
    arContractualObl?: any;
    arExistingAsset?: any;
    arCommitmentsReq?: any;
    arSubtotal?: any;
    arSubtotalCommit?: any;
    previousArType1: string = '';
    previousArType2: string = '';
    previousArType3: string = '';
    previousArDate1?: Date = new Date;
    previousArDate2?: Date = new Date;
    previousArDate3?: Date = new Date;
    previousArAppAmount1: any;
    previousArAppAmount2?: any;
    previousArAppAmount3?: any;
    previousArCapLeaseAmount1?: any;
    previousArCapLeaseAmount2?: any;
    previousArCapLeaseAmount3?: any;
    previousArFinAmountOther1?: any;
    previousArFinAmountOther2?: any;
    previousArFinAmountOther3?: any;
    previousArWorkingCapital1?: any;
    previousArWorkingCapital2?: any;
    previousArWorkingCapital3?: any;
    previousArContractualObl1?: any;
    previousArContractualObl2?: any;
    previousArContractualObl3?: any;
    previousArExistingAsset1?: any;
    previousArExistingAsset2?: any;
    previousArExistingAsset3?: any;
    previousArCommitmentsReq1?: any;
    previousArCommitmentsReq2?: any;
    previousArCommitmentsReq3?: any;

    previousArSubtotal1?: any;
    previousArSubtotal2?: any;
    previousArSubtotal3?: any;
    previousArSubtotalCommit1?: any;
    previousArSubtotalCommit2?: any;
    previousArSubtotalCommit3?: any;
    totalCommitment: any;
    brieflyDiscussBasic: string = '';
    currencyPercentage: string = '';
    exchangeRate: string = '';
    ceYear1: any;
    ceYear2: any;
    ceYear3: any;
    ceYear4: any;
    ceYear5: any;
    ceTotalBeyondYear5: any;
    ceTotal?: any;
    p1De: string = '';
    p1ProjectLead: string = '';
    p1Drb: string = '';
    p2De: string = '';
    p2ProjectLead: string = '';
    p2Drb: string = '';
    p3De: string = '';
    p3ProjectLead: string = '';
    p3Drb: string = '';
    startingYear: any;
    createdBy: string = '';
    createdDatetime?: Date = new Date;
    modifiedBy: string = '';
    modifiedDatetime?: Date = new Date;
    hidefromlist : boolean = false;
}