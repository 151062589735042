<h2 mat-dialog-title>
  AR Type-Phases
</h2>

<div mat-dialog-content>
  <!-- <select class="form-control" #feedformType (change)="onSelected(feedformType.value)">
    <option value="selected">Please Select Phase</option>
    <option value="1">Front-End Engineering & Design (FEED)</option>
    <option value="2">Long-Lead Equipment</option>
    <option value="3">Early EPC</option>
  </select> -->
  <select name="selectedType" #feedformType class="form-control" (change)="onSelected(feedformType.value)">
    <option *ngFor="let option of options" [value]="option.id">{{option.name}}</option>
  </select>
  <!-- <textarea rows="3" type="text" class="form-control" [(ngModel)]="comment"></textarea> -->
</div>

<div mat-dialog-actions style="float: right;">
  <button mat-button (click)="onDismiss()" class="btn btn-default"> Cancel </button> &nbsp;&nbsp;
  <button mat-raised-button class="btn btn-primary" style="color:white !important" (click)="onCreate()"> Create </button>
</div>