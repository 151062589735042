<div class="row fid-form" *ngIf="!form.IsOldVersionOfForms">
    <div class="row" style="padding-left: 16px; margin-bottom: 15px;" *ngIf="form.formType === 'FEED Supplement' || 
    form.formType === 'Phase 3 to 4 (FID)' || form.formType === 'FID Supplement'">
        <span>
            <mat-slide-toggle (change)="onChange($event)" [checked]="form.optOutAlternativeSelection"
                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                <b>Opt Out</b>
                <!-- <b *ngIf="form.optOutAlternativeSelection">Opt In</b> -->
            </mat-slide-toggle>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowOptOutModal()"></span>
        </span>
    </div>

    <div class="row sub-section">
        <div class="col-xs-12">
            <!-- <div class="col-xs-11"> -->
            <strong>3.1 Description of reference case</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowAltDescModal()"></span>
            <!-- </div> -->
            <div class="row pl-16">
                <span>
                    <mat-slide-toggle (change)="NAAlternativeDescriptionEventCheck($event, alternateSelection)"
                        [checked]="alternateSelection.naalternatDescription"
                        [disabled]="isOnHold || form.optOutAlternativeSelection || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                        <span class="app-font">N/A</span>
                    </mat-slide-toggle>
                </span>
            </div>
        </div>
        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly ||
                alternateSelection.naalternatDescription || form.optOutAlternativeSelection}"
            *ngIf="!alternateSelection.naalternatDescription">
            <span *ngIf="!form.optOutAlternativeSelection">
                <ckeditor #alternativeDescription [editor]="Editor" [config]="config" id="alternativeDescription"
                    name="alternativeDescription" [(ngModel)]="alternateSelection.alterativePreferredDescription"
                    [disabled]="isOnHold || isReadOnlyGlobalProjectLevel"
                    (ready)="onReady($event,'AlternativeDescription')">
                </ckeditor>
            </span>
        </div>
    </div>

    <div class="row sub-section">
        <div class="col-xs-12">
            <strong>3.2 Description of minimum functionality case (MFC)</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowMFCModal()"></span>
        </div>
        <div class="col-xs-12" *ngIf="!form.optOutAlternativeSelection"
            [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.optOutAlternativeSelection || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <span>
                <ckeditor #MFC [editor]="Editor" [config]="config" id="MFC" name="MFC"
                    [(ngModel)]="alternateSelection.alternativeMinimumFunctionalityCase"
                    [disabled]="isOnHold" (ready)="onReady($event,'MFC')">
                </ckeditor>
            </span>
        </div>
    </div>

    <div class="row sub-section">
        <div class="col-xs-12">
            <strong>3.3 Description of preferred alternative</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowPreferredAltModal()"></span>
        </div>
        <div class="col-xs-12" *ngIf="!form.optOutAlternativeSelection"
            [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.optOutAlternativeSelection || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <span>
                <ckeditor #preferredAlternative [editor]="Editor" [config]="config" id="preferredAlternative"
                    name="preferredAlternative" [(ngModel)]="alternateSelection.alternativeReferenceCase"
                    [disabled]="isOnHold" (ready)="onReady($event,'PreferredAlternative')">
                </ckeditor>
            </span>
        </div>
    </div>
</div>
<!-- old style -->
<div class="row fid-form" *ngIf="form.IsOldVersionOfForms">
    <div class="row" style="padding-left: 16px; margin-bottom: 15px;" *ngIf="form.formType === 'FEED Supplement' || 
    form.formType === 'Phase 3 to 4 (FID)' || form.formType === 'FID Supplement'">
        <span *ngIf="!form.IsOldVersionOfForms">
            <mat-slide-toggle (change)="onChange($event)" [checked]="form.optOutAlternativeSelection"
                [disabled]="isOnHold || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                <b>Opt Out</b>
            </mat-slide-toggle>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowOptOutModal()"></span>
        </span>
    </div>

    <div class="row sub-section">
        <div class="col-xs-12">
            <strong>3.1 Alternative description:</strong>
            <i>describe the scope, complexity, and value drivers of the reference case and alternatives.</i>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowAltDescOldModal()"></span>
            <div class="row pl-16" *ngIf="!form.IsOldVersionOfForms">
                <span>
                    <mat-slide-toggle (change)="NAAlternativeDescriptionEventCheck($event, alternateSelection)"
                        [checked]="alternateSelection.naalternatDescription"
                        [disabled]="isOnHold || form.optOutAlternativeSelection || form.isReadOnly || isReadOnlyGlobalProjectLevel">
                        <span class="app-font">N/A</span>
                    </mat-slide-toggle>
                </span>
            </div>
        </div>
        <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.isReadOnly ||
                alternateSelection.naalternatDescription || form.optOutAlternativeSelection}"
            *ngIf="!alternateSelection.naalternatDescription">
            <span *ngIf="!form.optOutAlternativeSelection">
                <ckeditor #alternativeDescription [editor]="Editor" [config]="config" id="alternativeDescription"
                    name="alternativeDescription" [(ngModel)]="alternateSelection.alterativePreferredDescription"
                    [disabled]="isOnHold || isReadOnlyGlobalProjectLevel"
                    (ready)="onReady($event,'AlternativeDescription')">
                </ckeditor>
            </span>
        </div>
    </div>

    <div class="row sub-section">
        <div class="col-xs-12">
            <strong>Minimum functionality case (MFC)</strong>
        </div>
        <div class="col-xs-12" *ngIf="!form.optOutAlternativeSelection"
            [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.optOutAlternativeSelection || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <span>
                <ckeditor #MFC [editor]="Editor" [config]="config" id="MFC" name="MFC"
                    [(ngModel)]="alternateSelection.alternativeMinimumFunctionalityCase"
                    [disabled]="isOnHold" (ready)="onReady($event,'MFC')">
                </ckeditor>
            </span>
        </div>
    </div>

    <div class="row sub-section">
        <div class="col-xs-12">
            <strong>Description of preferred alternative</strong>
        </div>
        <div class="col-xs-12" *ngIf="!form.optOutAlternativeSelection"
            [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.optOutAlternativeSelection || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <span>
                <ckeditor #PreferredAlternative [editor]="Editor" [config]="config" id="PreferredAlternative" name="PreferredAlternative"
                    [(ngModel)]="alternateSelection.alternativeReferenceCase"
                    [disabled]="isOnHold" (ready)="onReady($event,'PreferredAlternative')">
                </ckeditor>
            </span>
        </div>
    </div>

    <div class="row sub-section">
        <div class="col-xs-12">
            <strong>3.2 Tradeoffs and concepts robustness</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowMFCOldModal()"></span>
        </div>
        <div class="col-xs-12" *ngIf="!form.optOutAlternativeSelection"
            [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.optOutAlternativeSelection || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <span>
                <ckeditor #Tradeoffs [editor]="Editor" [config]="config" id="Tradeoffs" name="Tradeoffs"
                    [(ngModel)]="alternateSelection.tradeoffsAndConcept"
                    [disabled]="isOnHold" (ready)="onReady($event,'Tradeoffs')">
                </ckeditor>
            </span>
        </div>
    </div>

    <div class="row sub-section">
        <div class="col-xs-12">
            <strong>3.3 Walkaway points and value at risk</strong>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowWalkawayPointsModal()"></span>
        </div>
        <div class="col-xs-12" *ngIf="!form.optOutAlternativeSelection"
            [ngClass]="{'disabledNoOfCasesDiv': isOnHold || form.optOutAlternativeSelection || form.isReadOnly || isReadOnlyGlobalProjectLevel}">
            <span>
                <ckeditor #WalkawayPoints [editor]="Editor" [config]="config" id="WalkawayPoints"
                    name="WalkawayPoints" [(ngModel)]="alternateSelection.walkawayPoint"
                    [disabled]="isOnHold" (ready)="onReady($event,'WalkawayPoints')">
                </ckeditor>
            </span>
        </div>
    </div>
</div>