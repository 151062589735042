import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';
import { SsbodConstants } from '../../constants/SsbodConstants';
import { SubsurfaceViewModel } from '../../Model/subsurfaceView.model';
import { SsbodService } from '../../service/ssbod.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {
  @Input() viewModel: SubsurfaceViewModel = new SubsurfaceViewModel();
  @Input() type: string = "";
  @Input() formType: any = undefined;
  formId:number=0;
  emailId: string = '';
  provId: string = '';
  constIncremental = SsbodConstants.Incremental;
  constLL = SsbodConstants.LL;
  @Input() isReadOnlyGlobalProjectLevel : boolean = false;
  
  constructor(private ssbodService: SsbodService,private route: ActivatedRoute,private go36ProjectService: Go36projectService,private notifyService: NotificationService,public utilityService: UtilityService) { }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.formId = this.viewModel.Id;
  }
  SaveSSBODCommonDetails() {
    let postModel = {...this.viewModel};
    postModel.ModifiedBy = this.provId;
    postModel.Id = this.formId;
    postModel.BusinessUnits = [];
    postModel.Countries = [];
    postModel.Events = [];
    postModel.ProjectDevelopmentStages = [];
    const date =  new Date(postModel.Date!);
    if(postModel.Date != null){      
      postModel.Date = this.notifyService.ConvertDateBeforeSave(date);
    }
    postModel.Comments = postModel.Comments == null ? "": postModel.Comments;
    postModel.Event = postModel.Event == null ? "": postModel.Event;
    postModel.Phase = postModel.Phase == null ? "": postModel.Phase;
    postModel.VersionName= postModel.VersionName == null ? "": postModel.VersionName;
    this.ssbodService.SaveSSBODCommonDetails(this.formId,postModel).subscribe(res => {
      this.notifyService.showSuccess("Data Load/Save successfully !!", "Status");
    });
    if(postModel.Date != null){      
      postModel.Date = this.notifyService.ConvertDateAfterSave(date);
    }
  }
}
