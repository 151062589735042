<h2 mat-dialog-title>
    Set concurrence date
    </h2>
    
    <div mat-dialog-content>
        <input class="form-control" [(ngModel)]="currentDate" 
        (dateChange)="DateChange()" [matDatepicker]="picker" 
        placeholder="Choose a date" (click)="picker.open()">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker hidden="true" aria-hidden="true" #picker ></mat-datepicker>      
    </div>
    
    <div mat-dialog-actions style="float: right;">
      <button mat-button (click)="onDismiss()" class="btn btn-default"> Cancel </button> &nbsp;&nbsp;
      <button mat-raised-button class="btn btn-primary" [disabled]=buttonflag   style="color: white !important;" (click)="onApprove()"> Concur </button>
    </div>
