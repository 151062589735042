import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {
  message: string = '';
  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogModel) {
      this.message = data.message;
      setTimeout(() => {
        let element = document.querySelector(".mat-dialog-container");
        element?.setAttribute("style","padding: 0!important;border-radius: 4px!important;box-shadow: 0 5px 15px rgb(0 0 0 / 50%);");
        let element2 = document.querySelector("html");
        element2?.setAttribute("style","top: 0!important;");
        let element3 = document.querySelector(".cdk-overlay-container");
        element3?.setAttribute("style","z-index: 10040!important;");
        let element4 = document.querySelector(".cdk-global-overlay-wrapper");
        element4?.setAttribute("style","justify-content: center!important;align-items: center!important;");
        let element6 = document.querySelector(".cdk-overlay-dark-backdrop");
        element6?.setAttribute("style","background: rgba(0,0,0,.55)!important;");
      },0);
     }

  ngOnInit(): void {
    // This will be used on initialization
  }
  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
export class AlertDialogModel {
  constructor(public message: string) {
  }
}