export class KeyMilestone {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn: Date=new Date();
    modifiedOn: Date=new Date();
    order: number = 0;
    formId: number = 0;
    keyMilestoneName?: string = '';
    date?: Date=new Date();
    currentDate?: Date;
    partnerView?: Date=new Date();
    keyMilestoneType?: string = '';
    overviewId: number = 0;
    comment: string = '';
    isEditable: boolean = true;
}