<footer class="row">
    <div class="col-xs-12">
        <ul class="list-inline">
            <li>Last update: {{DateTime| date:'EEEE, MMMM d, y'}}</li>
            <li>Version : 0.1.8222.9007</li>
            <li>Environment : {{SetEnv}}</li>
            <li>© 2001-2023 Chevron Corporation</li>
            <li>Company Restricted</li>
            <li>OpCo : SPD-CSS</li>
        </ul>

        <ul class="list-inline">
            <li>Content contact: <a href="mailto:GopeshRana@chevron.com">Gopesh Rana</a></li>
            <li>Technical contact: <a
                    href="https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&amp;sys_id=9c08a21edb126450182e1dca48961981"
                    target="_blank">Application Support Request Form</a></li>
        </ul>
    </div>
</footer>