import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppsettingsService } from 'src/app/Shared/appsettings.service';

@Injectable({
  providedIn: 'root'
})

export class BusinessCaseService {

  GetUrl = 'api/BusinessCase/getBusinessCaseData?formId=';
  CreateUrl = 'api/BusinessCase/SaveBusinessCaseData';
  CopyUrl = 'api/BusinessCase/copyBusinessCaseData';

  constructor(private http: HttpClient,
    private appsettings: AppsettingsService,) { }

  getData(formId: number) {
    return this.http.get<any>(this.appsettings.apiBaseUrl + this.GetUrl + formId);
  }

  postBusinessCaseData(formData: any) {
    return this.http.post(this.appsettings.apiBaseUrl + this.CreateUrl, formData);
  }

  copyData(formId: number, userName: string, projectId: number, isSuppliment: boolean) {
    const params = new HttpParams()
      .append('formId', formId)
      .append('userName', userName)
      .append('projectId', projectId)

    return this.http.post<any>(this.appsettings.apiBaseUrl + this.CopyUrl, '', {
      params: params
    });
  }
}
