export class RisksViewModel {
    risks: RisksModel[] = [];
    formRisks = new FormRisksModel();
    economicSensitivity: EconomicSensitivitiesModel[] = [];
}

export class RisksModel {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn?: Date;
    modifiedOn?: Date;
    order: number = 0;
    formId: number = 0;
    formRiskId: number = 0;
    riskName?: string = '';
    mitigation?: string = '';
    includedInEconomics: boolean = false;
    reflectedInEconomics?: string = '';
    potentialImpact?: string = '';
    identifiedInAssuranceReview: boolean = false;
    reviewType?: string = '';
    status?: string = '';
    comment: string = '';
}

export class FormRisksModel {
    id: number = 0;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn?: Date;
    modifiedOn?: Date;
    formId: number = 0;
    economicSensitivityEurTitle?: string = '';
    netPresentValueSensitivityLow?: string = '';
    netPresentValueSensitivityMid?: string = '';
    netPresentValueSensitivityHigh?: string = '';
    netPresentValueTornadoImage?: string = '';
    netPresentValueScurveImage?: string = '';
    discountedProfitabilityIndexScurveImage?: string = '';
    npvFullCycle: any;
    flatRealPointForward: any;
    flatRealFeedForward: any;
    remainingCostIncrease: any;
    commodityTestedBy?: string = '';
    feedForwardCommodityTestedBy?: string = '';
    riskUncertainty?: string = '';
    noveltyRisk?: string = '';
    nanoveltyRisk: boolean = false;
    netPresentValueSensitivityLowComment: string = '';
    netPresentValueSensitivityMidComment: string = '';
    netPresentValueSensitivityHighComment: string = '';
    npvFullCycleComment: string = '';
    flatRealPointForwardComment: string = '';
    flatRealFeedForwardComment: string = '';
    remainingCostIncreaseComment: string = '';
    commodityTestedByComment: string = '';
    feedForwardCommodityTestedByComment: string = '';
    npvWaterfallImage?: string = '';
}

export class EconomicSensitivitiesModel {
    id: number = 0;
    active: boolean = true;
    createdBy?: string = '';
    modifiedBy?: string = '';
    createdOn?: Date;
    modifiedOn?: Date;
    order: number = 0;
    formId: number = 0;
    formRiskId: number = 0;
    economicSensitivityType: number = 0;
    economicSensitivityInvestPrice?: string = '';
    lowPrice?:any;
    midPrice?: any;
    highPrice?: any;
    title?: string = '';
    lowPriceComment: string = '';
    midPriceComment: string = '';
    highPriceComment: string = '';
}