<div class="row sub-section">
    <div class="col-xs-12 form-group">
        <strong>Summary of Facilities Concept:</strong>
    </div>
    <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel}">
        <ckeditor #facilitiesConceptSummary [editor]="Editor" [config]="config" id="facilitiesConceptSummary" name="facilitiesConceptSummary"
                [(ngModel)]="viewModel.FacilitiesConceptSummary"
                (ready)="onReady($event,'FacilitiesConceptSummary')"></ckeditor>
    </div>
</div>
<div class="row sub-section">
    <div class="col-xs-12 form-group">
        <strong>Describe the Development Plan:</strong>
    </div>
    <div class="col-xs-12" [ngClass]="{'disabledNoOfCasesDiv': viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel}">
        <ckeditor #developmentPlan [editor]="Editor" [config]="config" id="developmentPlan" name="developmentPlan"
                [(ngModel)]="viewModel.DevelopmentPlanDescription"
                (ready)="onReady($event,'DevelopmentPlanDescription')"></ckeditor>
    </div>
</div>