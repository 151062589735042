import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICvxClaimsPrincipal } from '@cvx/cal';
import { CalAngularService } from '@cvx/cal-angular';
import { RegisterUser } from '../Model/RegisterUser.model';
import { Go36projectService } from '../project/go36project/service/go36project.service';
import { NotificationService } from '../Shared/notification.service';

interface OpcoInfo {
  OpcoId: number;
  OpcoName: string;
}
interface SbuInfo {
  SbuId: number;
  SbuName: string;

}
interface BuInfo {
  BuId: number;
  BuName: string;
  OpcoId: number;
  SbuId: number;
}
interface RoleInfo {
  roleId: number;
  roleType: string;
}
@Component({
  selector: 'app-registeruser',
  templateUrl: './registeruser.component.html',
  styleUrls: ['./registeruser.component.css']
})
export class RegisteruserComponent implements OnInit {
  Dropdownprojectsdata: any;
  SelectedValue: any;
  formValue: UntypedFormGroup;
  OpcoList: Array<OpcoInfo> = [];
  SbuList: Array<SbuInfo> = [];
  BuList: Array<BuInfo> = [];
  RoleList: Array<RoleInfo> = [];
  isDisabled: boolean = true;
  roleDisabled: boolean = false;
  email: any;
  projectid: any;
  userModelObj: RegisterUser = new RegisterUser();
  currentUserProfile!: ICvxClaimsPrincipal;
  // any;
  constructor(public projectservice: Go36projectService,
    private authService: CalAngularService,
    private route: ActivatedRoute,
    private router: Router, private FormBuilder: UntypedFormBuilder,
    private notifyService: NotificationService) {
    this.formValue = this.FormBuilder.group({
      //  ProjectName: ['', Validators.required],
      email: '',
      firstName: '',
      lastName: '',
      roleId: 0,
      createdBy: '',
      createdDate: new Date(),
      isActive: true,
      OpcoId: [0, Validators.required],
      SbuId: 0,
      BuId: 0,
      UserOrgDetails: '',

    })
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.getdropdown();
    this.fillRoles();
  }
  getUserInfo() {
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    let email = this.currentUserProfile.email;
    let uName = this.currentUserProfile.name.split("[")[0];
    uName = uName.replace(/\s/g, "");
    this.formValue.controls['email'].setValue(email);
    this.formValue.controls['firstName'].setValue(uName.substring(uName.indexOf(',') + 1));
    this.formValue.controls['lastName'].setValue(uName.substring(0, uName.indexOf(',')));

  }
  getdropdown() {
    this.projectservice.getDropdownData().subscribe(res => {
      this.Dropdownprojectsdata = res; //master data
      this.OpcoList = res[3];
      this.SbuList = res[4];
      this.BuList = res[0];
      this.fillSbuDDL(this.formValue.value.OpcoId);
    });
  }


  fillSbuDDL(OpcoId: any) {
    this.projectservice.getDropdownData().subscribe(res => {
      this.Dropdownprojectsdata = res;
    });

    if (OpcoId == 11) {
      this.isDisabled = false;
    }
    else {
      this.isDisabled = true;
    }

    this.SbuList = this.Dropdownprojectsdata[4].filter((res: any) => { return res.OpcoId == OpcoId });

    this.formValue.controls['SbuId'].setValue(this.SbuList[0].SbuId);
    this.fillBuDDL(this.SbuList[0].SbuId);
  }
  fillBuDDL(SbuId: any) {
    this.BuList = this.Dropdownprojectsdata[0].filter((res: any) => res.SbuId == SbuId);
    this.formValue.controls['BuId'].setValue(this.BuList[0].BuId);
  }
  fillRoles() {
    this.projectservice.getRolesData().subscribe(res => {
      this.RoleList = res; //master data
      this.formValue.controls["roleId"].setValue(this.RoleList[4].roleId);
      this.roleDisabled = true;
    });
  }
  onRoleChange(event: any) {
    this.formValue.controls['roleId'].setValue(event.target.value);
  }
  RegisterNewUser() {
    if (this.formValue.valid) {
      this.userModelObj.Email = this.formValue.value.email;
      this.userModelObj.FirstName = this.formValue.value.firstName;
      this.userModelObj.LastName = this.formValue.value.lastName;
      this.userModelObj.RoleId = this.formValue.value.roleId;
      this.userModelObj.CreatedBy = this.currentUserProfile.provisioningId;
      this.userModelObj.ProvisioningId = this.currentUserProfile.provisioningId;
      this.userModelObj.ObjectId = this.currentUserProfile.objectId;
      this.userModelObj.CreatedDate = this.formValue.value.createdDate;
      this.userModelObj.IsActive = this.formValue.value.isActive;
      this.userModelObj.OpcoId = this.formValue.value.OpcoId;
      this.userModelObj.SbuId = this.formValue.value.SbuId;
      this.userModelObj.BuId = this.formValue.value.BuId;
      this.userModelObj.UserOrgDetails = this.formValue.value.UserOrgDetails;
      this.projectservice.NewUserRegister(this.userModelObj).subscribe(res => {

        this.notifyService.showSuccess("User registered successfully!", "");
        this.projectservice.isHeaderVisible = true;
        this.router.navigate([""]);

      },
        err => {
          console.log('HTTP Error', err)
        }
      );
    }
  }

}
