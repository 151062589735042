<app-popup-active-users></app-popup-active-users>
<div class="box-container">
    <div class="row">
        <div class="col">
            <h3> GO-36 Exploration & Supplement Form </h3>
            <div class="row text-center">
                <a class="no-underline" [routerLink]="['/eaproject/eaviewform']" [queryParams]="{projectId: projectId}">
                    <h5 style="padding: 0; display:inline; font-size: 14px;">{{projectName}}</h5>
                </a>
            </div>
            <h5 *ngIf="form.isReadOnly" class="text-danger margin-0 padding-0" style="font-size: 14px;">
                (Previous Version #{{Part1Model.version}} - {{Part1Model.versionName}} - Read-Only)</h5>
            <h5 class="text-danger margin-0 padding-0" style="font-size: 14px !important;" *ngIf="Part1Model.isOnHold">
                Form is on Hold. No editing can be performed
            </h5>
        </div>
    </div>

    <div class="row" *ngIf="!Part1Model.isReadOnly && featuresImplemented.hasGo_36_EA_FormHoldFeature">
        <span [ngClass]="{'disabledNoOfCasesDiv': this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit}">
            <mat-slide-toggle (change)="onChange($event)" [disabled]="this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit"
                [checked]="Part1Model.isOnHold">
                <b class="app-font">On Hold</b>
            </mat-slide-toggle>
            <span class="glyphicon glyphicon-question-sign" (click)="ShowOnHoldModal()"></span>
        </span>
        <!-- <button type="button" style="float:right;color: white !important;"
            class="text-right btn btn-sm btn-success btn-dark" *ngIf="!Part1Model.isOnHold || !Part1Model.isReadOnly"
            [disabled]="this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit">Save</button> -->
    </div>

    <div class="row tabbed tabbed-content-control centered">
        <div class="filter-bar tab-filter-bar font-gotham centered-xs">
            <mat-tab-group [selectedIndex]="0" animationDuration="0ms" style='min-height:300px' [disableRipple]='true'
                disablepagination="true">
                <mat-tab label="Part 1">
                    <ng-template matTabContent>
                        <div>
                            <app-supplement-part1 [comments]="Comments" [isOnHold]="Part1Model.isOnHold" [IsOldVersionOfForms]="featuresImplemented.isOldVersionOfForms"
                                (changeHeader)="changeHeader($event)" [supplementId]="Part1Model.supplementId"
                                [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
                            </app-supplement-part1>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Part 2">
                    <ng-template matTabContent>
                        <div>
                            <app-supplement-part2 [comments]="Comments" [isOnHold]="Part1Model.isOnHold" [IsOldVersionOfForms]="featuresImplemented.isOldVersionOfForms" [IsNewCEisApplicableForms]="featuresImplemented.isNewCEisApplicableForms"
                                [isReadOnly]="Part1Model.isReadOnly" [supplementId]="Part1Model.supplementId"
                                [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
                            </app-supplement-part2>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Part 3">
                    <ng-template matTabContent>
                        <div>
                            <app-supplement-part3 [comments]="Comments" [isOnHold]="Part1Model.isOnHold" [IsOldVersionOfForms]="featuresImplemented.isOldVersionOfForms" [IsNewCEisApplicableForms]="featuresImplemented.isNewCEisApplicableForms"
                                [isReadOnly]="Part1Model.isReadOnly" [supplementId]="Part1Model.supplementId"
                                [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit'>
                            </app-supplement-part3>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Form Reviewer" *ngIf="featuresImplemented.hasWorkFlowProcessFeature">
                    <ng-template matTabContent>
                        <div>
                            <app-supplement-formreviewer [isOnHold]="Part1Model.isOnHold"
                                [projectName]="projectData.projectName"
                                [supplement]="Part1Model.supplement"
                                [isReadOnlyGlobalProjectLevel]='this.userAccessList?.data?.isLocked'
                                [canSubmitForReview]='this.userAccessList?.data?.canSubmit'
                                [isReadOnly]="Part1Model.isReadOnly"
                                [canSendBack]='this.userAccessList?.data?.canSendBack'
                                [canApprove]='this.userAccessList?.data?.canApprove' [formsLogData]='formsLogData'>
                            </app-supplement-formreviewer>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <hr style="margin-top: 0;" />
    <div class="row">
        <div class="col-xs-12 padding-0">
            <div class="text-left">
                <h5 class="text-left">Upload Attachments :</h5>
                <input type="file" class="form-control-file upload" #file (change)="uploadFile($event)"
                    [disabled]="Part1Model.isOnHold || Part1Model.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 padding-0 ">
            <table class="table table-bordered table-striped table-responsive">
                <thead>
                    <tr>
                        <td class="text-center text-bold">&nbsp;</td>
                        <td class="text-center text-bold">Document ID</td>
                        <td class="text-left text-bold">File Name</td>
                        <td class="text-left text-bold">Uploaded By</td>
                        <td class="text-left text-bold">&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-bordered" *ngFor="let document of NewARDocuments let i = index">
                        <td class="text-center">
                            <button type="button" style="color: white !important;" class="btn btn-sm btn-primary"
                                (click)="DownloadFile(document)">
                                Download
                            </button>
                        </td>
                        <td class="text-center">
                            <span>{{document.documentId}}</span>
                        </td>
                        <td class="text-left">
                            <span>{{document.docName}}</span>
                        </td>
                        <td class="text-left">
                            <span>{{document.createdBy}}</span>
                        </td>
                        <td class="text-left">
                            <span>
                                <button type="button" style="color: white !important;" class="btn btn-sm btn-danger"
                                    (click)="RemoveFile(document, i)"
                                    [disabled]="Part1Model.isOnHold || Part1Model.isReadOnly || this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit">X</button>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" style="margin-top: 15px;">
        <div class="col-xs-12 padding-0">
            <div class="row">
                <div class="form-group col-sm-4 col-xs-4 text-left padding-0 ">
                    <button type="button"
                        style="color: white !important;background-color: #5bc0de !important;border-color: #46b8da!important;"
                        class="btn btn-sm btn-primary" [routerLink]="['/eaproject/eaviewform']"
                        [queryParams]="{projectId: projectId}">Close</button>
                    <button [disabled]="this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canDeleteForm" *ngIf="!Part1Model.isReadOnly"
                        type="button" style="color: white !important;" class="btn btn-sm btn-danger"
                        [routerLink]="['/forms/feed/delete']"
                        [queryParams]="{projectId: projectId,formId: supplementId,versionName: versionName, formType: 'EA Supplement'}">Delete</button>
                </div>
                <div class="form-group col-sm-4 col-xs-4 text-center padding-0">
                    <button [disabled]="this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" type="button"
                        class="btn btn-sm btn-primary btn-dark" style="color: white !important;"
                        (click)="CreateEaNewRescope()">Rescope</button>
                    <button [disabled]="this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" type="button"
                        class="btn btn-sm btn-primary btn-dark" style="color: white !important;"
                        (click)="CreateEaNewSupplement()">Supplement</button>
                    <button [disabled]="this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" type="button"
                        class="btn btn-sm btn-primary btn-dark" style="color: white !important;"
                        (click)="CreatePart4LookBack()">Part 4 Lookback</button>
                </div>
                <div class="form-group col-sm-4 col-xs-4 text-right padding-0">
                    <!-- <button type="button" style="color: white !important;" class="btn btn-sm btn-primary btn-dark">PDF</button> -->
                    <a [href]="pdfUrl" class="btn btn-sm btn-primary btn-dark" target="_blank"
                        style="color: white !important;margin-right: 0.5em;margin-bottom: 0.5em;">PDF</a>
                    <button  [disabled]="this.userAccessList?.data?.isLocked || !this.userAccessList?.data?.canEdit" type="button" style="color: white !important;" class="btn btn-sm btn-success btn-dark" (click)="OpenArchiveDialog()" *ngIf="!Part1Model.isReadOnly">Archive</button>

                    <button *ngIf="!Part1Model.isReadOnly && !featuresImplemented.hasWorkFlowProcessFeature" type="button"
                    class="btn btn-sm btn-success btn-dark" (click)="performFormAction('forreview')"
                    style="color: white !important;"
                    [disabled]="Part1Model.isOnHold || !this.userAccessList?.data?.canSubmit || Part1Model.isReadOnly ||  this.userAccessList?.data?.isLocked || this.forReviewLockByFormState">Send For Review</button>
    
                    <button *ngIf="!Part1Model.isReadOnly && !featuresImplemented.hasWorkFlowProcessFeature" type="button"
                    class="btn btn-sm btn-warning btn-dark" (click)="performFormAction('sendback')"
                    style="color: white !important;"
                    [disabled]="Part1Model.isOnHold || !this.userAccessList?.data?.canSendBack || Part1Model.isReadOnly ||  this.userAccessList?.data?.isLocked || this.sendBackLockByFormState">Send Back</button>
    
                    <button *ngIf="!Part1Model.isReadOnly && !featuresImplemented.hasWorkFlowProcessFeature" type="button"
                    class="btn btn-sm btn-success btn-dark" (click)="performFormAction('approved')"
                    style="color: white !important;"
                    [disabled]="Part1Model.isOnHold || !this.userAccessList?.data?.canApprove || Part1Model.isReadOnly ||  this.userAccessList?.data?.isLocked || this.concurLockByFormState">Concur</button>
                </div>
            </div>
        </div>
    </div>
</div>
