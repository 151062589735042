<div class="row">
    <div class="col col-xs-12 col-md-3 left-column" style="padding-right: 0">
        <!-- Side Navigation -->
        <nav class="col hidden-xs hidden-sm nav-column left-column">
            <div class="nav-module box-container box-shadow">
                <h4 class="nav-level-2">quick links</h4>
                <ul class="nav-list nav-level-3">
                    <li class="drawers panel-group">
                        <div id="nav-level-4-item1Header" class="panel-heading">
                            <ul>
                                <li><a [routerLink]="['/project/go36project/create']"  (click)="SetEditMode('editMode')" >Create
                                        new Project</a></li>
                                <li><a
                                    [routerLink]="['/forms/myrsidproject']">My
                                        RSID Projects</a></li>
                                <li><a
                                    [routerLink]="['/forms/myprojectforms']">My
                                        Project Forms</a></li>

                                <li><a
                                    [routerLink]="['/forms/mylookbackforms']">My
                                        Lookback Forms</a></li>
                                <li><a [routerLink]="['/project/eaproject/create']" (click)="SetEditMode('editMode')" >Create
                                        New E&amp;A Projects</a></li>
                                <li><a
                                    [routerLink]="['/forms/myearsidproject']">My
                                        RSID E&amp;A Projects</a></li>
                                <li><a
                                    [routerLink]="['/forms/myeaprojectforms']">My
                                        E&amp;A Project Forms</a></li>
                            </ul>
                        </div>
                        <div id="nav-level-4-item1Header" class="panel-heading">
                            <ul>
                                <li><a
                                    [routerLink]="['/forms/myformsinworkflow']"  [queryParams]="{ role:'Submitter'}">Forms
                                        Submitted For Review</a></li>
                                <li><a
                                    [routerLink]="['/forms/myformsinworkflow']" [queryParams]="{ role:'Reviewer'}">Forms
                                        Awaiting Review</a></li>
                            </ul>
                        </div>
                        <div id="nav-level-4-item1Header" class="panel-heading">
							<ul>
								<li><a href="#0" (click)="GenerateRsidReport()">RSID Report Download</a></li>
							</ul>
						</div>
                    </li>
                   
                </ul>               
            </div>
        </nav>
        <nav class="col hidden-xs hidden-sm nav-column left-column">
                <div class="nav-module box-container box-shadow" style="background-color:#face26">
                 <h4 style="color:black">Note to Application Users:</h4>
                        <h5 style="color:black">
                           For creating example projects, or testing GO-36 application functionality, use the following test site:
                        <br/>
                            <u>
                               <a style="text-decoration:none;color:black" 
                                href="https://go36-test.chevron.com/" target="_blank" rel="noreferrer noopener">GO-36 Test</a>
                            </u>
                            <br />
                            <br />
                            Save frequently to avoid data loss.
                            <br/>
                            Please close the application session when not using it.
                        </h5>
                        &nbsp;
                    </div>
        </nav>
    </div>

    <div class="col col-xs-12 col-md-9 main-column">

        <div class="row">
            <div class="col col-xs-12 col-sm-3 padding-5 match-height-generic">
                <!-- Upstream -->
                <div
                    class="callout callout-color-promo box-container box-shadow theme-bg-dark-green theme-text-white theme-bg-color padding-0">
                    <img class="img-responsive centered" src="../../assets/Chevron_icons/Upstream.jpg"
                        alt="Upstream">
                    <div class="callout-content theme-text-color padding-5">
                        <h4 class="header theme-text-color padding-top-1">upstream</h4>
                    </div>
                </div>
            </div>
            <div class="col col-xs-12 col-sm-3 padding-5 match-height-generic">
                <!-- Midstream -->
                <div
                    class="callout callout-color-promo box-container box-shadow theme-bg-dark-red theme-text-white theme-bg-color padding-0">
                    <div class="row theme-bg-color">
                        <img class="img-responsive centered" src="../../assets/Chevron_icons/Midstream.jpg"
                            alt="Midstream">
                        <div class="callout-content theme-text-color padding-5">
                            <h4 class="header theme-text-color padding-top-1">midstream</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col col-xs-12 col-sm-3 padding-5 match-height-generic">
                <!-- Downstream -->
                <div
                    class="callout callout-color-promo box-container box-shadow theme-bg-dark-purple theme-text-white theme-bg-color padding-0">
                    <div class="row theme-bg-color">
                        <img class="img-responsive centered" src="../../assets/Chevron_icons/Downstream.jpg"
                            alt="Downstream">
                        <div class="callout-content theme-text-color padding-5">
                            <h4 class="header theme-text-color padding-top-1">downstream</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col col-xs-12 col-sm-3 padding-5 match-height-generic">
                <!-- NewEnergies -->
                <div
                    class="callout callout-color-promo box-container box-shadow theme-bg-dark-teal theme-text-white theme-bg-color padding-0">
                    <div class="row theme-bg-color">
                        <img class="img-responsive centered" src="../../assets/Chevron_icons/NewEnergies.jpg"
                            alt="NewEnergies">
                        <div class="callout-content theme-text-color padding-5">
                            <h4 class="header theme-text-color padding-top-1">new energies</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box-container box-shadow">



            <div class="row">
                <h4>Chevron Financial Appropriations - GO-36 Forms</h4>
                <p style="margin-bottom: 20px;">
                    Welcome to the Digital GO-36 application. This tool is an integral part of Chevron’s
                    digital transformation mindset and journey applied to the financial appropriations
                    process, facilitating the
                    transition from paper-based GO-36 forms to a full-featured online version of these
                    forms. This transition will enable us to capture key insights and analysis from the
                    appropriations process to
                    further improve future project spend &amp; returns.
                </p>
                <p style="margin-bottom: 20px;">
                    Detailed instructions and documentation on the creation and management of GO-36
                    projects and forms are available through the HELP link on the main menu.
                    Additionally, several example projects have
                    been created with graphs and economic calculations to assist users in understanding
                    the layout &amp; structure of the GO-36 forms in this tool.
                </p>
                <p style="margin-bottom: 20px;">
                    The documentation and instructions are structured for upstream, midstream and
                    downstream projects. The forms available within the tool will vary depending on
                    whether the project is an upstream or
                    a non-upstream project. Continuous improvements are being made to enhance the
                    functionality, adaptability, look &amp; feel, performance and ease-of-use of the
                    tool; please send your feedback to any of
                    the contacts below.
                </p>

            </div>

            <div class="row">
                <h4>Contact Information</h4>
                <div class="col col-xs-12 col-sm-6 padding-5 match-height-generic"
                    style="width: 20%; height: 154px;">
                    <div>
                        <div class="row theme-bg-color">

                            <span class="glyphicon-user"></span>

                            <strong>
                                <a href="mailto:britolg@chevron.com" target="_blank" style="padding-left: 5px;"
                                    title="Contact for enhancements, new forms, new applications and clarifications on how to use the tool.">Luiz
                                    Brito</a>
                            </strong><br>
                            <span>(925-842-8670)</span><br>
                            <span>Chevron Strategy and Sustainability</span> <br>
                            <span>Product Owner</span>
                        </div>
                    </div>
                </div>
                <div class="col col-xs-12 col-sm-6 padding-5 match-height-generic"
                    style="width: 20%; height: 154px;">
                    <div>
                        <div class="row theme-bg-color">

                            <span class="glyphicon-user"></span>

                            <strong>
                                <a href="mailto:GopeshRana@chevron.com" target="_blank" style="padding-left: 5px;"
                                    title="Contact for enhancements, new forms, new applications and clarifications on how to use the tool.">Gopesh
                                    Rana</a>
                            </strong><br>
                            <span>(713-372-9895)</span><br>
                            <span>Upstream HQ</span> <br>
                            <span>Content Owner</span>
                        </div>
                    </div>
                </div>
                <div class="col col-xs-12 col-sm-6 padding-5 match-height-generic"
                    style="width: 20%; height: 154px;">
                    <div>
                        <div class="row theme-bg-color">

                            <span class="glyphicon-user"></span>

                            <strong>
                                <a href="mailto:Paul.Fjerstad@chevron.com" target="_blank" style="padding-left: 5px;"
                                    title="Contact for enhancements, new forms, new applications and clarifications on how to use the tool.">Paul
                                    Fjerstad</a>
                            </strong><br>
                            <span>(713-372-2046)</span><br>
                            <span>Subsurface Assurance</span> <br>
                            <span>SSBOD Contact</span>
                        </div>
                    </div>
                </div>
                <div class="col col-xs-12 col-sm-6 padding-5 match-height-generic"
                    style="width: 20%; height: 154px;">
                    <div>
                        <div class="row theme-bg-color">

                            <span class="glyphicon-user"></span>

                            <strong>
                                <a href="mailto:SHood@tengizchevroil.com" target="_blank" style="padding-left: 5px;"
                                    title="Contact for enhancements, new forms, new applications and clarifications on how to use the tool.">Stephen
                                    Hood</a>
                            </strong><br>
                            <span>(281-750-9986)</span><br>
                            <span>Downstream HQ</span> <br>
                            <span>DS&amp;C Contact</span>
                        </div>
                    </div>
                </div>
                <div class="col col-xs-12 col-sm-6 padding-5 match-height-generic"
                    style="width: 20%; height: 154px;">
                    <div>
                        <div class="row theme-bg-color">
                            <span class="glyphicon-user"></span>
                            <strong><a style="padding-left: 5px;"
                                    href="https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&amp;sys_id=9c08a21edb126450182e1dca48961981"
                                    target="_blank" title="Raise a support ticket.">Application Support
                                    Request Form</a></strong> <br>
                            <span>Select "Digital GO-36" application</span> <br>
                            <span>Technical Support</span>
                        </div>
                    </div>
                </div>

            </div>


        </div>

    </div>
</div>