<h2 mat-dialog-title>
    Set Version Name
    </h2>    
    <div mat-dialog-content>
      <select class="form-control" [(ngModel)]="selectedVersion" (change)="onSsbodVersionChange($event)">
         <option value="0">Select SSBOD version name from below</option>
         <option *ngFor="let version of SsbodVersionsDropdown" [value]="version.value">{{version.text}}</option>
      </select>
    </div>    
    <div mat-dialog-actions style="float: right;">
      <button mat-button (click)="onDismiss()" class="btn btn-default"> Cancel </button> &nbsp;&nbsp;
      <button mat-raised-button class="btn btn-primary" style="color:white!important" (click)="OnCreate()"> Create </button>
    </div>