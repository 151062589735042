export class FormConfigurationOverviewModel {
    formId : number = 0;
    id: number = 0;
    modifiedBy?: string = '';
    modifiedOn: Date=new Date();
    facilitiesName?:string='';
    drillingAndCompletionsName?:string='';
    contingencyName?:string='';
    projectGeneralAndAdministrativeName?:string='';
    additionalOwnersCostsName?
}