export class ArProjectModel {
  ProjectId: number=0;
  OpcoId?: number;
  BuId?: number;
  ProjectName: string='';
  AssetClassId?: number;
  SpecialProjectType?:number;
  SbuId?:number;
  Rsid?:string;
  IsSsbodavailable:boolean=false;
  CreatedBy:string='';
  CreatedDatetime:Date= new Date;
  ModifiedBy:string='';
  ModifiedDatetime:Date= new Date;
  ProjectType:string="";
  AssetMegaFriendlyName?:string;
  Order:number=0;
  //Hidefromlist:Boolean=false;

}
export interface ArProjectModel
{
  ProjectId: number;
  ProjectType:string;
  OpcoId?: number;
  BuId?: number;
  ProjectName: string;
  AssetClassId?: number;
  SpecialProjectType?:number;
  SbuId?:number;
  Rsid?:string;
  IsSsbodavailable:boolean;
  CreatedBy:string;
  CreatedDatetime:Date;
  ModifiedBy:string;
  AssetMegaFriendlyName?:string;
  ModifiedDatetime:Date;
  Order:number;
  //Hidefromlist:Boolean;
}