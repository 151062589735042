<div class="fid-form ssbod">
    <div class="row">
        <div class="col-xs-12">
            <div class="row sub-section">
                <div class="col-xs-8">
                    <div class="row">
                        <div class="col-xs-12 form-group">
                            <strong>Incremental Case (required for Brownfield and Greenfield)</strong>
                        </div>
                        <div class="col-xs-12">
                            <div class="row form-group">
                                <div class="col-sm-3 col-xs-12">
                                    <label class="control-label form-label">Project Name</label>
                                </div>
                                <div class="col-sm-9 col-xs-12">
                                    <input class="form-control" disabled [(ngModel)]="productionForecastViewModel.ProjectName"/>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-sm-3 col-xs-12">
                                    <label class="control-label form-label">Date:</label>
                                </div>
                                <div class="col-sm-9 col-xs-12">
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="productionForecastViewModel.Date" (dateChange)="SaveProductionForecastData(null)" [matDatepicker]="pickerProjectDate" (click)="pickerProjectDate.open();utilityService.removeCDKAnouncer()">
                                    <mat-datepicker #pickerProjectDate></mat-datepicker>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-sm-3 col-xs-12">
                                    <label class="control-label form-label">CPDEP Phase: </label>
                                </div>
                                <div class="col-sm-3 col-xs-12">
                                    <select class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="productionForecastViewModel.Phase" (change)="SaveProductionForecastData(null)">
                                        <option *ngFor="let CPDEP of productionForecastViewModel.ProjectDevelopmentStages" [value]="CPDEP.Name">{{CPDEP.Name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-xs-4" style="color:red">
                    The SSBOD Forecast form is recommended for
                    Phase 2 SSTRs and is required for Phase 3 SSTRs, GO-36 submittals, &amp; Part 4 or 5 Lookbacks.
                    It is intended to provide an additional level of detail beyond the Reference, Total and Incremental forms for
                    comparison of actual performance and project predictions.
                </div>
            </div>

            <div class="row sub-section">
                <div class="col-xs-12 form-group">
                    <strong>EV Production Forecast for First 2 Years of the Project: </strong>
                </div>
                <div class="col-xs-12">
                    <table class="table table-bordered">
                        <tbody>
                            <tr class="bg-table-default table-header">
                                <td class="text-center" rowspan="2">Month</td>
                                <td class="text-center" colspan="4">8/8th Production<br />Monthly Volumes</td>
                                <td class="text-center" colspan="3">Injection<br />Monthly Volumes</td>
                                <td class="text-center" colspan="2">Active Well Count</td>
                                <td class="text-center" rowspan="2">Comment</td>
                            </tr>
                            <tr class="bg-table-default table-header">
                                <td class="text-center">MBO</td>
                                <td class="text-center">MMCF</td>
                                <td class="text-center">NGL MBO</td>
                                <td class="text-center">LPG MBO</td>
                                <td class="text-center">MBW</td>
                                <td class="text-center">MMCF</td>
                                <td class="text-center">MTons</td>
                                <td class="text-center">#Producers</td>
                                <td class="text-center">#Injectors</td>
                            </tr>
                            <tr class="table-header" *ngFor="let item of productionForecastViewModel.ProductionForecastData let i = index">
                                <td class="text-center" width="10%">{{item.Month}}</td>
                                <td width="10%">
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.ProductionVolumeMbo" (change)="SaveProductionForecastData(item)"/>
                                </td>
                                <td width="10%">
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.ProjectionVolumeMmcf" (change)="SaveProductionForecastData(item)"/>
                                </td>
                                <td width="10%">
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.ProductionVolumeNglMbo" (change)="SaveProductionForecastData(item)"/>
                                </td>
                                <td width="10%">
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.ProductionVolumeLpgMbo" (change)="SaveProductionForecastData(item)"/>
                                </td>
                                <td width="10%">
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.InjectionVolumeMbw" (change)="SaveProductionForecastData(item)"/>
                                </td>
                                <td width="10%">
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.InjectionVolumeMmcf" (change)="SaveProductionForecastData(item)"/>
                                </td>
                                <td width="10%">
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.InjectionMillionMetricTons" (change)="SaveProductionForecastData(item)"/>
                                </td>
                                <td width="10%">
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.Producers" (change)="SaveProductionForecastData(item)"/>
                                </td>
                                <td width="10%">
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.Injectors" (change)="SaveProductionForecastData(item)"/>
                                </td>
                                <td>
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.Comment" (change)="SaveProductionForecastData(item)"/>
                                </td>
                            </tr>
                         </tbody>
                    </table>
                </div>

                <div class="col-xs-12">
                    <table class="table table-bordered">
                        <tbody>
                            <tr class="table-header">
                                <td rowspan="2" class="bg-table-default text-center">Month</td>
                                <td colspan="4" class="bg-table-default text-center">Net Production<br />Monthly Volumes</td>
                                <td rowspan="26" colspan="5" class="text-center">
                                    <div class="bordered">
                                        This section should reflect net production as reflected in the DA model and input into the GO-36.
                                        <br />
                                        "Net" as used herein is synonymous with Net Revenue Interest (NRI) as referenced in the GO-36
                                        template and the Corporate Reserves Manual. The definition below is a paraphrase from Section 3.1
                                        of the Reserves Manual.

                                        <div class="blue-bordered">
                                            The Net Revenue Interest (NRI) share is Chevron's portion of future production streams. For example:
                                            <div class="text-left">
                                                <ul>
                                                    <li>
                                                        <u>For royalty situations</u>, the NRI is the Gross Working Interest minus the lessor's
                                                        royalty share and minus royalties paid to others. Chevron's NRI for a property would include
                                                        any appicable overriding royalty that may be reported separately
                                                    </li>
                                                    <li>
                                                        <u>For Production Sharing Contracts (PSC)</u> the NRI is the Gross Working Interest
                                                        minus the government take under terms of the PSC. NRI typically varies depending on historical and future
                                                        investments, operatig costs, production volumes and product prices (see Section 3.1.2 of the
                                                        Reserves Manual for guidance).
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr class="bg-table-default table-header">
                                <td class="text-center">MBO</td>
                                <td class="text-center">MMCF</td>
                                <td class="text-center">NGL MBO</td>
                                <td class="text-center">LPG MBO</td>
                            </tr>
                            <tr class="table-header" *ngFor="let item of productionForecastViewModel.ProductionForecastData let i = index">
                                <td class="text-center"width="10%">{{item.Month}}</td>
                                <td width="12%">
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.NetProductionVolumeMbo" (change)="SaveProductionForecastData(item)"/>
                                </td>
                                <td width="12%">
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.NetProductionVolumeMmcf" (change)="SaveProductionForecastData(item)"/>
                                </td>
                                <td width="12%">
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.NetProductionVolumeNglMbo" (change)="SaveProductionForecastData(item)"/>
                                </td>
                                <td width="12%"> 
                                    <input class="form-control" [disabled]="viewModel.IsReadOnly  || isReadOnlyGlobalProjectLevel" [(ngModel)]="item.NetProductionVolumeLpgMbo" (change)="SaveProductionForecastData(item)"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>


