import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FeedNavService } from 'src/app/forms/FEEDServices/feed-nav.service';
import { FormReviewerService } from 'src/app/forms/FEEDServices/form-reviewer.service';
import { OverviewService } from 'src/app/forms/FEEDServices/overview.service';
import { Form } from 'src/app/Model/Form.model';
import { FormAction } from 'src/app/Model/FormAction.model';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';
import { NotificationService } from 'src/app/Shared/notification.service';
import { UtilityService } from 'src/app/Shared/utility.service';

@Component({
  selector: 'app-submit-approval-dialog',
  templateUrl: './submit-approval-dialog.component.html',
  styleUrls: ['./submit-approval-dialog.component.css']
})
export class SubmitApprovalDialogComponent implements OnInit {
  form = new Form();
  formAction = new FormAction();
  emailId: string = '';
  objectId: string = '';
  provId: string = '';
  versionName: string = '';
  formId: number = 0;
  versionId: number = 0;
  reviewerId: number = 0;
  reviewerComments?: string = '';
  formType: string = '';
  buttonText: string = '';
  buttonflag: boolean = true;
  currentDate: Date = new Date();
  href: string = "";
  bUrl: string = "";
  public version: EventEmitter<any> = new EventEmitter();
  projectId: number = 0;

  constructor(public dialogRef: MatDialogRef<SubmitApprovalDialogModel>,
    private router: Router, @Inject(MAT_DIALOG_DATA) public data: SubmitApprovalDialogModel,
    private overviewService: OverviewService, private route: ActivatedRoute,
    private go36ProjectService: Go36projectService, private feedNavService: FeedNavService,
    private formReviewerService: FormReviewerService,private notifyService: NotificationService,
    public dialog: MatDialog, public utilityService: UtilityService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.emailId = this.utilityService.GetuserEmailId();
    this.objectId = this.utilityService.GetuserObjectId();
    this.provId = this.utilityService.GetuserProvisioningId();
    this.href = this.router.url;
    this.bUrl = window.location.origin;
    if (this.currentDate) {
      this.buttonflag = false;
    }
  }

  onDismiss(): void {
    this.dialogRef.close();
  }
  
  onApprove(): void {
    this.dialogRef.close();
    this.formAction.projectId = this.data.projectId;
    this.formAction.projectName = this.data.projectName;
    this.formAction.formId = this.data.formId;
    this.formAction.versionId = this.data.versionId;
    this.formAction.formType = this.data.formType;
    this.formAction.comments = this.data.reviewerComments;
    this.formAction.currentDate = this.currentDate;

    const date = new Date(this.formAction.currentDate!);
    if (this.formAction.currentDate != null) {
      this.formAction.currentDate = this.notifyService.ConvertDateBeforeSave(date);
    }

    this.formAction.formActionId = this.data.reviewerId;
    this.formAction.objectId = this.objectId;
    this.formAction.provId = this.provId;
    this.formAction.action = 'approved';
    this.formAction.currentBUrl = this.bUrl;
    this.formAction.currentUrl = this.href;
    this.version.emit(this.versionName);
    this.buttonText = "Approving Form! Please wait...";
    this.formReviewerService.approvalForm(this.formAction).subscribe(res => {
    
      if (this.data.formType == "FEED" || this.data.formType == "EPC" || this.data.formType == "LLE") {
        this.router.navigateByUrl('/forms/feed?projectId=' + this.data.projectId + '&formId=' + this.data.formId + '&versionName=' + this.data.versionId + '&formCase=Open').then(() => {
          window.location.reload();
        });
      }
      else if (this.data.formType == "FEED Supplement") {
        this.router.navigateByUrl('/forms/feed_Supplement?projectId=' + this.data.projectId + '&formId=' + this.data.formId + '&versionName=' + this.data.versionId + '&formCase=Open').then(() => {
          window.location.reload();
        });
      }
      else if (this.data.formType == "Phase 3 to 4 (FID)" || this.data.formType == "FID") {
        this.router.navigateByUrl('/forms/fid?projectId=' + this.data.projectId + '&formId=' + this.data.formId + '&versionName=' + this.data.versionId + '&formCase=Open').then(() => {
          window.location.reload();
        });
      }
      else if (this.data.formType == "FID Supplement") {
        this.router.navigateByUrl('/forms/fid_Supplement?projectId=' + this.data.projectId + '&formId=' + this.data.formId + '&versionName=' + this.data.versionId + '&formCase=Open').then(() => {
          window.location.reload();
        });
      }
      else if (this.data.formType == "Part4" || this.data.formType == "AR_LOOKBACK_PART4") {
        this.router.navigate(["/forms/part4"], { queryParams: { projectId: this.data.projectId, formId: this.data.formId, versionName: this.data.versionId, formCase: 'Open' } }).then(() => {
          window.location.reload();
        });
      }
      else if (this.data.formType == "Part5" || this.data.formType == "AR_LOOKBACK_PART5") {
        this.router.navigate(["/forms/part5"], { queryParams: { projectId: this.data.projectId, formId: this.data.formId, versionName: this.data.versionId, formCase: 'Open' } }).then(() => {
          window.location.reload();
        });
      }
      else if (this.data.formType == "EAPart4" || this.data.formType == "EA_LOOKBACK_PART4") {
        this.router.navigate(["/ea/forms/eapart4"], { queryParams: { projectId: this.data.projectId, formId: this.data.formId, versionName: this.data.versionId, formCase: 'Open' } }).then(() => {
          window.location.reload();
        });
      }
      else if (this.data.formType == "EA") {
        this.router.navigate(["/ea/new-ar-nav"], { queryParams: { projectId: this.data.projectId, formId: this.data.formId, versionName: this.data.versionId, formCase: 'Open' } }).then(() => {
          window.location.reload();
        });
      }
      else if (this.data.formType == "EA Supplement" || this.data.formType == "EA_SUPPLEMENT") {
        this.router.navigate(["/ea/supplement-nav"], { queryParams: { projectId: this.data.projectId, formId: this.data.formId, versionName: this.data.versionId, formCase: 'Open' } }).then(() => {
          window.location.reload();
        });
      }
    })
  }

  DateChange() {
    if (this.currentDate) {
      this.buttonflag = false;
    }
  }
}

export class SubmitApprovalDialogModel {

  constructor(public projectId: number,
    public projectName: string,
    public formId: number,
    public versionId: number,
    public formType: string,
    public reviewerId: number,
    public reviewerComments: string) {
  }
}
