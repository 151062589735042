<div class="box-container box-shadow" id="page-body">

  <div class="row">
    <div class="col">
      <h3>Subsurface Basis of Design (SSBOD)</h3>
    </div>
  </div>

  <div class="row">
    &nbsp;
  </div>
  <div class="row">
    <div class="col-sm-12">
    <p-table #projectdt selectionMode="single" [value]="projects" [rows]="projectlength" [paginator]="true" [totalRecords]="projectlength"
    stateStorage="session" stateKey="statedemo-session7" styleClass="p-datatable-projects p-datatable-sm"
    [rowsPerPageOptions]="[projectlength, 10, 20, 50, 100]" (onPage)="onShowEntryDropdownChange($event)"
    paginatorPosition="both" [globalFilterFields]="[
  'ProjectName',
  'OpcoName',
  'SbuName',
  'buName',
  'AssetName',
  'Name',
  'ModifiedDateTime',
  'global'
]">
<ng-template pTemplate="caption">
  <span><b>Show Entries</b></span>
  <div class="flex" style="float:right;margin-bottom: 1%;">
    <span class="p-input-icon-left ml-auto">
      <b>Search: </b><input #searchText pInputText type="text"
        (input)="projectdt.filterGlobal($any($event.target).value, 'contains')"
        [value]="projectdt.filters['global']? $any(projectdt.filters['global'])?.value :''"
        />
    </span>
  </div>
</ng-template>
<ng-template pTemplate="header">
  <tr style="background-color: lightskyblue;height: 40px;">
    <th style="width:10%"></th>
    <th pSortableColumn="Version" style="width: 10%;">
      Version <p-sortIcon field="Version"></p-sortIcon>
    </th>
    <th pSortableColumn="ProjectName" style="width: 13%;">
      Project Name <p-sortIcon field="ProjectName"></p-sortIcon>
    </th>
    <th pSortableColumn="OpcoName" style="width: 10%;">
      Segment <p-sortIcon field="OpcoName"></p-sortIcon>
    </th>
    <th pSortableColumn="SbuName" style="width: 10%;">RU <p-sortIcon field="SbuName"></p-sortIcon>
    </th>
    <th pSortableColumn="BuName" style="width: 9%;">BU <p-sortIcon field="BuName"></p-sortIcon>
    </th>
    <th pSortableColumn="AssetName" style="width: 10%;">
      Asset <p-sortIcon field="AssetName"></p-sortIcon>
    </th>
    <th pSortableColumn="Name" style="width: 10%;">
      Created By <p-sortIcon field="Name"></p-sortIcon>
    </th>
    <th pSortableColumn="ModifiedDateTime" style="width: 11%;">
      Modified Date <p-sortIcon field="ModifiedDateTime"></p-sortIcon>
    </th>
  </tr>
  <tr>
    <th style=" background-color:#efefef !important;"></th>
    <th style="height:6rem; background-color:#efefef !important;">
      <input style="max-width:10em" pInputText type="text" #searchVersion
        (input)="projectdt.filter(searchVersion.value,'Version', 'contains')" placeholder="Search..."
        class="p-column-filter" />
    </th>

    <th style="height:6rem; background-color:#efefef !important;">
      <input style="max-width:10em" pInputText type="text" #searchProject
        (input)="projectdt.filter(searchProject.value,'ProjectName', 'contains')"
        [value]="projectdt.filters['ProjectName']? $any(projectdt.filters['ProjectName'])?.value :''"
        placeholder="Search..." class="p-column-filter" />
    </th>

    <th style=" background-color:#efefef !important;">
      <input style="max-width:5em" pInputText type="text" #searchOpco
        (input)="projectdt.filter(searchOpco.value,'OpcoName', 'contains')"
        [value]="projectdt.filters['OpcoName']? $any(projectdt.filters['OpcoName'])?.value :''"
        placeholder="Search..." class="p-column-filter" />
    </th>

    <th style=" background-color:#efefef !important;">
      <input style="max-width:5em" pInputText type="text" #searchsbu
        (input)="projectdt.filter(searchsbu.value,'SbuName', 'contains')"
        [value]="projectdt.filters['SbuName']? $any(projectdt.filters['SbuName'])?.value :''"
        placeholder="Search..." class="p-column-filter" />
    </th>


    <th style=" background-color:#efefef !important;">
      <input pInputText style="max-width:5em" type="text" #searchbu
        (input)="projectdt.filter(searchbu.value,'BuName', 'contains')"
        [value]="projectdt.filters['BuName']? $any(projectdt.filters['BuName'])?.value :''"
        placeholder="Search..." class="p-column-filter" />
    </th>
    <th style=" background-color:#efefef !important;">
      <input pInputText style="max-width:5em" type="text" #searchasset
        (input)="projectdt.filter(searchasset.value,'AssetName', 'contains')" placeholder="Search..."
        [value]="projectdt.filters['AssetName']? $any(projectdt.filters['AssetName'])?.value :''"
        class="p-column-filter" />
    </th>
    <th style=" background-color:#efefef !important;">
      <input pInputText style="max-width:5em" type="text" #searchcreatedby
        (input)="projectdt.filter(searchcreatedby.value,'Name', 'contains')"
        [value]="projectdt.filters['Name']? $any(projectdt.filters['Name'])?.value :''"
        placeholder="Search..." class="p-column-filter" />
    </th>
    <th style=" background-color:#efefef !important;">
      <input pInputText style="max-width:5em" type="text" #searchModified
        (input)="projectdt.filter(searchModified.value,'ModifiedDateTime', 'contains')"
        [value]="projectdt.filters['ModifiedDateTime']? $any(projectdt.filters['ModifiedDateTime'])?.value :''"
        placeholder="Search..." class="p-column-filter" />
    </th>
  </tr>
</ng-template>
<ng-template pTemplate="emptymessage" let-columns>
  <tr>
    <td [attr.colspan]="100" style="text-align: center;">
      No records found in the table. Please search for other text
    </td>
  </tr>
</ng-template>
<ng-template pTemplate="body" let-project>
  <tr>
    <td style="padding: 2px 0px;">
      <div>
        <a style="width:10em;background-color: royalblue" class="btn  btn-sm btn-info"
          [routerLink]="['/forms/ssbod']" [queryParams]="{formId: project.ProjectId}">
          <div>Open Form</div>
      </a>
      <router-outlet></router-outlet>
      </div>
    </td>
    <td>
      <select name="selectedType" class="form-control" style="width:8em;" [(ngModel)]="project.selectedVersion" (change)="onVersionChange($event,project.ProjectId)">
        <option *ngFor="let item of project.FormVersions; let i = index" [value]="item.FormVersion">
          <span *ngIf="item.FormVersionName == null">#{{item.FormVersion}}</span>
          <span *ngIf="(item.FormVersionName != null) && (item.FormVersion==item.FormVersionName)">#{{item.FormVersion}}</span>
          <span *ngIf="(item.FormVersionName != null) && (item.FormVersion!=item.FormVersionName)">#{{item.FormVersion}} - {{item.FormVersionName}}</span></option>
      </select>
    </td>
    <td class="coldata"><strong>{{ project.ProjectName }}</strong></td>
    <td class="coldata">{{ project.OpcoName}}</td>
    <td class="coldata">{{ project.SbuName }}</td>
    <td class="coldata">{{ project.BuName }}</td>
    <td class="coldata">{{ project.AssetName }}</td>
    <td class="coldata">{{ project.Name }}</td>
    <td class="coldata">{{ project.ModifiedDateTime | date: 'MM/dd/yyyy' }}</td>
  </tr>
</ng-template>
<ng-template pTemplate="paginatorleft" let-state>
  Showing {{(state.first) + 1}} to {{(state.totalRecords >
    (state.rows * (state.page + 1))) ? state.rows * (state.page + 1) : state.totalRecords }}
    of {{state.totalRecords}} entries
</ng-template>
  </p-table>
    </div>
  </div>
</div>