import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Form } from '../../../../Model/Form.model';
import { FeedNavService } from '../../../../forms/FEEDServices/feed-nav.service';
import { EaPart4LookbackService } from '../../service/ea-part4-lookback.service';
import { Go36projectService } from 'src/app/project/go36project/service/go36project.service';

@Component({
  selector: 'app-ea-delete',
  templateUrl: './ea-delete.component.html',
  styleUrls: ['./ea-delete.component.css']
})
export class EaDeleteComponent implements OnInit {
  formId: number = 0;
  projectId: number = 0;
  form = new Form();
  versionName: number = 0;
  formType: string = "";
  projectData:any;
  constructor(private route: ActivatedRoute,
    private projectservice: Go36projectService, private feednavService: FeedNavService, private router: Router, private part4service: EaPart4LookbackService) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.projectId = params['projectId'];
        this.formId = params['formId'];
        this.versionName = Number(params['versionName']);
        this.formType = params['formType'];
      });
      this.getProjectInfobyId();
    this.getFormData();

  }
  getProjectInfobyId() {
    this.projectservice.GetProjectInfobyId(this.projectId).subscribe(res => {
      this.projectData = res;
      if (res) {
        this.projectData = res;
      }
    });
  }
  getFormData() {
    if (this.formType != "E&A LOOKBACK_PART4") {
      this.feednavService.getFormData(this.formId).subscribe((res: any) => {
        this.form = res;
        this.projectData.projectName = res.name;
      });
    }

    else {
      this.part4service.getPart4FormData(this.formId).subscribe((res: Form) => {
        this.form = res;
        this.form.Name = this.projectData.projectName;
      });
    }
  }
  DeleteForm() {
      
   if (this.formType == "E&A LOOKBACK_PART4") {
      this.part4service.deletePart4Form(this.formId, this.versionName).subscribe(res => {
        this.router.navigateByUrl('/eaproject/eaviewform?projectId=' + this.projectId);
      }
      )
    }
    else{
      this.feednavService.deleteForm(this.formId, this.versionName).subscribe(res => {
        this.router.navigateByUrl('/eaproject/eaviewform?projectId=' + this.projectId);
      }
      )
    }

  }
}
