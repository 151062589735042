import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssetService } from 'src/app/admin/service/asset.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {
  title: string = '';
  message: string = '';
  userId : number = 0;
  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>, private apiService: AssetService,
    @Inject(MAT_DIALOG_DATA) public data: DeleteDialogModel) {
    this.title = data.title;
    this.message = data.message;
    dialogRef.disableClose = true;

    setTimeout(() => {
      let element = document.querySelector(".mat-dialog-container");
      element?.setAttribute("style", "overflow: unset;");
      // // let element2 = document.querySelector("html");
      // // element2?.setAttribute("style","top: 0!important;");
      // // let element3 = document.querySelector(".cdk-overlay-container");
      // // element3?.setAttribute("style","z-index: 10040!important;");
      // // let element4 = document.querySelector(".cdk-global-overlay-wrapper");
      // // element4?.setAttribute("style","justify-content: center!important;align-items: center!important;");
      let element5 = document.querySelector(".cdk-overlay-pane");
      element5?.setAttribute("style", "max-width: 80vw;width: 600px;height:200px;position: static;");
      // // let element6 = document.querySelector(".cdk-overlay-dark-backdrop");
      // // element6?.setAttribute("style","background: rgba(0,0,0,.55)!important;");
    }, 0);
  }

  ngOnInit(): void {
    // This will be used on initialization
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }


}

export class DeleteDialogModel {
  constructor(public title: string, public message: string ) {
  }
}