export class FeaturesImplemented {
  hasFeedSupplementFeature: boolean = true;
  hasLessonsLearnedFeature: boolean = true;
  hasSSBODFeature: boolean = true;
  hasWorkFlowProcessFeature: boolean = true;
  hasGo_36_FormHoldFeature: boolean = true;
  hasGo_36_EA_FormHoldFeature: boolean = true;
  hasGo_36_EA_FormArchiveFeature: boolean = true;
  hasSSBODArchiveFeature: boolean = true;
  hasLookbacksLLFeature: boolean = true;
  isOldVersionOfForms: boolean = true;
  BeforeGreenHouseGas: boolean = true;
  isNewCEisApplicableForms: boolean = true;

}
