export class SubsurfaceViewModel {
  Id: number = 0;
  Active: boolean = false;
  CreatedBy: string = '';
  ModifiedBy: string = '';
  CreatedOn: Date = new Date;
  ModifiedOn: Date = new Date;
  Order: number = 0;
  OriginalId: number = 0;
  Version: number = 0;
  VersionName: string = '';
  ProjectName: string = '';
  Date: Date = new Date;
  ActiveWells: number = 0;
  Phase: string = '';
  Penetrations: number = 0;
  CountryId: number = 0;
  BusinessUnitId: number = 0;
  StrategicBusinessUnit: string = '';
  OperatingCompany: string = '';
  OptionalPlanningAssist: boolean = false;
  Mandatory: boolean = false;
  PeerReview: boolean = false;
  Submittal: boolean = false;
  Part4Lookback: boolean = false;
  Part5Lookback: boolean = false;
  LeadingAlternative: boolean = false;
  Comments: string = '';
  Event: string = '';
  MinimumFunctionalityCase: boolean = false;
  SelectedRadioOption: string = '';
  BusinessUnits: any[] = [];
  Events: any[] = [];
  Countries: any[] = [];
  ProjectDevelopmentStages: any[] = [];
  FacilitiesConceptSummary: string = '';
  DevelopmentPlanDescription: string = '';
  SupportingImage: string = '';
  SsbodProjectId: number = 0;
  IsReadOnly: boolean = false;
  hasNewSSbodArchiveFeature: boolean = true;
  IsOldVersionOfForms: boolean = false;
  isNewCEisApplicableForms: boolean = true;
}
export interface SubsurfaceViewModel {
  Id: number;
  Active: boolean;
  CreatedBy: string;
  ModifiedBy: string;
  CreatedOn: Date;
  ModifiedOn: Date;
  Order: number;
  OriginalId: number;
  Version: number;
  VersionName: string;
  ProjectName: string;
  Date: Date;
  ActiveWells: number;
  Phase: string;
  Penetrations: number;
  CountryId: number;
  BusinessUnitId: number;
  StrategicBusinessUnit: string;
  OperatingCompany: string;
  OptionalPlanningAssist: boolean;
  Mandatory: boolean;
  PeerReview: boolean;
  Submittal: boolean;
  Part4Lookback: boolean;
  Part5Lookback: boolean;
  LeadingAlternative: boolean;
  Comments: string;
  Event: string;
  MinimumFunctionalityCase: boolean;
  SelectedRadioOption: string;
  BusinessUnits: any[];
  Events: any[];
  Countries: any[];
  ProjectDevelopmentStages: any[];
  FacilitiesConceptSummary: string;
  DevelopmentPlanDescription: string;
  SupportingImage: string;
  SsbodProjectId: number;
  IsReadOnly: boolean;
  hasNewSSbodArchiveFeature: boolean;
  IsOldVersionOfForms: boolean;
}
